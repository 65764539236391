// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useRef } from 'react';
import { Box } from 'grommet';

const FileUpload = ({ disabled = false, ...props }) => {
  const { children, handleFile } = props;
  const hiddenFileInput = useRef();

  const handleClick = () => {
    if (!disabled) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile) {
      handleFile(null, 'Please select image.');
      return false;
    }

    if (!['image/png', 'image/jpeg'].includes(imageFile.type)) {
      handleFile(null, 'Please select a PNG or JPG file.');
      return false;
    }

    if (imageFile.size > 8000000) {
      handleFile(null, 'Images must be less than 8MB.');
      return false;
    }
    return handleFile(imageFile, null);
  };
  return (
    <Box onClick={handleClick}>
      {children}
      <input type="file" ref={hiddenFileInput} onChange={handleChange} hidden />
    </Box>
  );
};

export default FileUpload;
