// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Text, Select } from 'grommet';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import Toast from '../../components/Toast';
import { GenericScoringContext } from './context';

const GenericSettings = observer(() => {
  const scoringStore = useContext(GenericScoringContext);

  const [toast, setToast] = useState(null);

  return (
    <Box pad="medium">
      <Box>
        <Text weight="bold">Scoreboard Position</Text>
        <Text>Which side should we show your team on?</Text>
        <Select
          labelKey="label"
          valueKey={{ key: 'key', reduce: true }}
          value={scoringStore.scoreboard.scoreboardTeamPos}
          options={[
            {
              label: 'Left',
              key: 'L',
            },
            {
              label: 'Right',
              key: 'R',
            },
          ]}
          onChange={({ option }) => {
            scoringStore.handleEvent('scoreboardPosition', {
              position: option.key,
            });
            setToast({
              label: 'Scoreboard position updated!',
              background: 'status-ok',
            });
          }}
        />
      </Box>
      {toast && (
        <Toast
          full="horizontal"
          label={toast.label}
          background={toast.background}
          duration={2000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Box>
  );
});

export default GenericSettings;
