// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Text } from 'grommet';
import { Clone } from 'grommet-icons';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SHDButton from '../components/SHD/Button';

const CopiableText = ({ label, value, onCopy }) => (
  <Box
    direction="row"
    justify="between"
    align="center"
    width="100%"
    alignSelf="center"
  >
    {label && (
      <Text basis="1/3" size="medium">
        {label}
      </Text>
    )}
    <Box
      direction="row"
      justify="end"
      gap="medium"
      basis={label && '2/3'}
      align="center"
    >
      <Box
        overflow={{ horizontal: 'scroll', vertical: 'hidden' }}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Text color="status-ok" size="medium" wordBreak="keep-all">
          {value}
        </Text>
      </Box>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <SHDButton icon={<Clone color="black" />} hoverIndicator />
      </CopyToClipboard>
    </Box>
  </Box>
);

export default CopiableText;
