// Packages
import React from 'react';
import { paywallFeaturesCopy } from '../paywall.copy';
// Components
import { Checkmark } from 'grommet-icons';
import { Text } from 'grommet';

// Types
import { PaywallFeatureListProps } from '../paywall.types';

const PaywallFeaturesList: React.FC<PaywallFeatureListProps> = ({
  iconColor = '#ccff50',
}) => {
  return (
    <ul style={{ listStyle: 'none', padding: '0' }}>
      {paywallFeaturesCopy.map((item, index) => (
        <li
          key={index}
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            marginBottom: '1rem',
          }}
        >
          <Checkmark color={iconColor} height="24px" width="24px" />

          <Text
            className="list-item"
            margin={{ left: '1rem' }}
            style={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
          >
            <strong style={{ fontWeight: '900' }}>{item.title}</strong>
            &nbsp;
            {item.copy}
          </Text>
        </li>
      ))}
    </ul>
  );
};

export default PaywallFeaturesList;
