// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneLines, faMicrophoneLinesSlash } from '@fortawesome/free-solid-svg-icons';
import { MicButton } from '../../styles';

export default function MicrophoneButton({ isEnabled, handleToggleMicro }) {
  const icon = isEnabled ? faMicrophoneLines : faMicrophoneLinesSlash;

  return (
    <MicButton isEnabled={isEnabled} onClick={handleToggleMicro}>
      <FontAwesomeIcon icon={icon} />
    </MicButton>
  );
}

MicrophoneButton.propTypes = {
  isEnabled: PropTypes.bool,
  handleToggleMicro: PropTypes.func,
};

MicrophoneButton.defaultProps = {
  isEnabled: true,
  handleToggleMicro: null,
};
