import React from 'react';
import { Box, Button, Image, Text } from 'grommet';
import CopyToClipboard from 'react-copy-to-clipboard';
import { GEO_US_CA, GEO_US_NY, GEO_US_TX } from '../../constants/strings';
import { shd } from '@shd/jslib/models';

interface Props {
  capture: shd.TeamCapture;
  goProOption: string;
  onCopied?: () => void;
}

const GoProRTMPInfo: React.FC<Props> = ({ capture, goProOption, onCopied }) => {
  const [copied, setCopied] = React.useState(false);

  if (!capture.rtmp) {
    return null;
  }

  const goProUrls: Record<string, string> = {
    [GEO_US_NY]: capture.rtmp.fullUrl_GoPro_NY || '',
    [GEO_US_TX]: capture.rtmp.fullUrl_GoPro_TX || '',
    [GEO_US_CA]: capture.rtmp.fullUrl_GoPro_CA || '',
  };
  return (
    <Box pad="medium" gap="small" flex={{ shrink: 0, grow: 1 }}>
      <Box
        background={'light-5'}
        margin={{ top: 'small' }}
        gap="8px"
        pad="16px"
        round="8px"
        border
      >
        <Text weight={'bold'}>GoPro RTMP URL</Text>
        <Box
          direction="row"
          justify="between"
          margin={{ top: '4px' }}
          align="center"
          gap="8px"
          flex
        >
          <Text size="small" truncate>
            {goProUrls[goProOption]}
          </Text>
          <CopyToClipboard
            text={goProUrls[goProOption]}
            onCopy={() => {
              setCopied(true);
              onCopied && onCopied();
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
          >
            <Box flex={{ grow: 1, shrink: 0 }}>
              <Button
                secondary
                size="small"
                label={copied ? 'Copied!' : 'Copy to Clipboard'}
                disabled={copied}
              />
            </Box>
          </CopyToClipboard>
        </Box>
      </Box>
      <Text>
        Enter the above RTMP URL and the other information into the correct
        fields. Then, press Set Up Live Stream.
      </Text>
      <Box round="4px" overflow="hidden">
        <Image src="/gopro_rtmpinfo.png" fit="contain" />
      </Box>
    </Box>
  );
};

export default GoProRTMPInfo;
