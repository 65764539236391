// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default class BitRateQueue {
  constructor({ length = 4 }) {
    this.length = length;
    this.items = [];
  }

  pushItem(item) {
    this.items =
      this.items.length < this.length
        ? [...this.items, item]
        : [...this.items.slice(1), item];
    return this;
  }

  getMedian() {
    const sum = this.items.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
    return Math.round(sum / this.items.length);
  }

  getMean() {
    const sum = this.items.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
    return sum / this.items.length;
  }

  get queue() {
    return this.items;
  }
}
