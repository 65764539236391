// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Heading, RadioButton, Text } from 'grommet';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import { Add } from 'grommet-icons';
import { insertDisplayName } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import QuickAddPlayer from './QuickAddPlayer';
import axios from '../../helpers/axios';

const StartingPitcher = ({ teamPlayers = [], match, history }) => {
  const userStore = useContext(UserContext);
  const { gameId } = match.params;
  const team = userStore.selectedTeam || userStore.scoringTeam;
  const [players, setPlayers] = useState(teamPlayers);
  const [startingPitcher, setStartingPitcher] = useState();
  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [error, setError] = useState(false);
  const [loading, toggleLoading] = useState(false);

  const addPlayer = (player) => {
    const newPlayer = insertDisplayName(player);
    const newTeamPlayers = [...teamPlayers, newPlayer];
    setPlayers(newTeamPlayers);
    setStartingPitcher(newPlayer._id);
  };

  const confirmStartingPitcher = (pitcherId) => {
    toggleLoading(true);
    let newStartingPitcher = null;
    if (pitcherId) {
      [newStartingPitcher] = players.filter((player) => {
        if (player._id === pitcherId) {
          return true;
        }
        return false;
      });
    }

    const body = {
      teamId: team._id,
      teamGameId: gameId,
      teamGameUpdate: {
        scoreOurPitcher: newStartingPitcher,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        toggleLoading(false);
        history.replace('/scoring');
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
  };

  return (
    <Box gap="medium" pad="medium">
      <Heading level="3">Choose starting pitcher</Heading>
      {error ? <Text color="status-critical">{error}</Text> : null}
      {players.length ? (
        <Box gap="medium" height={{ min: 'initial' }}>
          {players.map((player) => (
            <Box
              key={player._id}
              background={
                startingPitcher === player._id ? 'secondary-1' : 'secondary-7'
              }
              pad="medium"
              style={{ borderRadius: '8px' }}
              onClick={() => setStartingPitcher(player._id)}
            >
              <RadioButton
                checked={startingPitcher === player._id}
                label={player.displayName}
                readOnly
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Text size="large" textAlign="center">
          No players yet. Create one to get started.
        </Text>
      )}
      {showAddPlayer ? (
        <QuickAddPlayer
          addPlayerLocal={addPlayer}
          onClose={() => setShowAddPlayer(false)}
        />
      ) : null}
      <Box align="start">
        <Box
          hoverIndicator
          onClick={setShowAddPlayer}
          direction="row"
          align="center"
          gap="small"
          fill={false}
          pad="medium"
        >
          <Add color="tertiary-1" />
          <Text color="tertiary-1">Create player</Text>
        </Box>
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        justify="between"
        margin={{ top: 'large' }}
        align="center"
        pad="medium"
        height={{ min: 'small' }}
      >
        <Text
          onClick={() => confirmStartingPitcher(null)}
          hoverIndicator
          weight="bold"
          color="tertiary-1"
          size="large"
        >
          Skip
        </Text>
        <SHDButton
          primary
          size="large"
          label={!loading ? 'Finish' : '...Finishing'}
          onClick={() => confirmStartingPitcher(startingPitcher)}
          disabled={!startingPitcher || loading}
          color="tertiary-1"
        />
      </Box>
    </Box>
  );
};

export default compose(withRouter)(StartingPitcher);
