import React, { useContext } from 'react';
import { Box, Text, Button, ResponsiveContext } from 'grommet';
import { FormNext } from 'grommet-icons';
import { BoxSideType } from 'grommet/utils';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface SettingsMenuItemProps extends RouteComponentProps {
  title: string;
  subtitle: string;
  href: string;
  selected?: boolean;
}

const SettingsMenuItem: React.FC<SettingsMenuItemProps> = ({
  title,
  subtitle,
  href,
  selected = false,
  history,
}) => {
  const screenSize = useContext(ResponsiveContext);
  const isMobile = screenSize === 'small';
  const showArrow = screenSize === 'small';

  const getBackgroundColor = () => {
    if (isMobile) {
      return 'white';
    } else {
      if (selected) {
        return 'white';
      } else {
        return 'light-5';
      }
    }
  };

  const getBorder = (): { side: BoxSideType; color: string } => {
    if (isMobile) {
      return { side: 'bottom', color: 'light-1' };
    } else if (selected) {
      return { side: 'all', color: 'light-1' };
    } else {
      return { side: 'all', color: 'light-5' };
    }
  };

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      pad="medium"
      onClick={() => {
        history.push(href);
      }}
      background={getBackgroundColor()}
      border={getBorder()}
      round={isMobile ? 'none' : 'small'}
    >
      <Box>
        <Text weight="bold">{title}</Text>
        <Text>{subtitle}</Text>
      </Box>
      {showArrow && (
        <Button
          plain
          icon={<FormNext />}
          onClick={() => {
            history.push(href);
          }}
        />
      )}
    </Box>
  );
};

export default withRouter(SettingsMenuItem);
