import { useContext } from 'react';
import { UserContext } from '../../services/Session/context';
import {
  isFirebaseAuthError,
  theFirebase,
} from '../../services/Firebase/firebase';
import axios from '../../helpers/axios';

import * as Sentry from '@sentry/browser';
import { dtos, shd } from '@shd/jslib/models';
import {
  AuthCredential,
  AuthProvider,
  User,
  getAdditionalUserInfo,
} from 'firebase/auth';
import { RouteComponentProps } from 'react-router-dom';
import { errorUtil, strogging } from '@shd/jslib/infra';
import { AxiosResponse, isAxiosError } from 'axios';
import { useQuery } from '../../helpers/utils';
import { analytics } from '../../services/analytics';
import { ONBOARDING, SIGN_IN, WAITING } from '../../constants/routes';
import { parsedUserAgent } from '../../helpers/browserDetect';
import { ERROR_MSG_ACCOUNT_EXISTS } from '../../constants/strings';
import { AppHostContext } from '../../services/appHost';

interface AuthProps extends RouteComponentProps {
  errorHandler: (error: {
    message: string;
    urlText?: string;
    url?: string;
  }) => void;
  linkAccountHandler?: (credential: AuthCredential | null) => void;
  onSuccess?: (data: dtos.UserPostResponse) => void;
  postUrl: string;
  claimPlayer?: string;
  claimPlayerKey?: string;
  claimPhoneNumber?: string;
  toggleLoading: (loading: boolean) => void;
  provider: AuthProvider;
}

const useAuthentication = (props: AuthProps) => {
  const {
    errorHandler,
    linkAccountHandler,
    onSuccess,
    postUrl,
    claimPlayer,
    claimPlayerKey: key,
    claimPhoneNumber,
    history,
    toggleLoading,
    provider,
  } = props;

  const userStore = useContext(UserContext);
  const appHost = useContext(AppHostContext);
  const query = useQuery();

  const doRedirect = () => {
    const redirect = query.get('redirectUrl');
    const selectedTeamId = query.get('selectedTeamId');

    if (redirect) {
      history.push(
        {
          pathname: `/${redirect.trim()}`,
        },
        {
          selectedTeamId,
          redirect,
        }
      );
      // why are we doing this?
      window.location.reload();
    }
  };

  const authenticate = async (
    credential?: AuthCredential | null,
    existingUser?: shd.User,
    extraPostParams?: Partial<dtos.UserPostRequest>,
    providerOverride?: AuthProvider
  ) => {
    const authProvider = providerOverride || provider;

    const shouldUseRedirect = false;
    // (provider.providerId === 'facebook.com' && isMobile.iOS()) ||
    // isMobile.Android() && provider.providerId === 'facebook.com';

    try {
      strogging.log('signin-start', { provider: authProvider.providerId });
      let auth;
      if (
        authProvider === theFirebase().getAllAuthProviders().phoneProvider &&
        credential
      ) {
        auth = await theFirebase().doSignInWithCredential(credential);
      } else {
        if (appHost.isHosted) {
          try {
            const response =
              await appHost.service.requests.signInWithProviderId(
                authProvider.providerId
              );
            strogging.log(
              'signInWithProviderId-response',
              response.userResponse ? 'success' : 'failure'
            );

            if (response.userResponse) {
              // handleSignInSuccess(response.userResponse, response.data);
              // TEMP hack to force a reload. When we need to handle invite / claim links,
              // think we will need to call handleSignInSuccess
              // https://app.zenhub.com/workspaces/wksp-parent-squad-65efe62717028a0dd3a58a9f/issues/gh/diamondkinetics/sbot/1773
              window.location.reload();
            }
          } catch (e) {
            strogging.error('signInWithProviderId-error', {
              providerId: authProvider.providerId,
              error: e,
            });
          }

          return;
        } else {
          auth = await (shouldUseRedirect
            ? theFirebase().doSignInWithRedirect(authProvider)
            : theFirebase().doSignInWithPopup(authProvider));
        }
      }

      toggleLoading(true);

      let email;
      let name;
      if (existingUser) {
        email = existingUser.email || '';
        name = existingUser.nameFirst + ' ' + existingUser.nameLast;
      } else {
        email = auth.user.email;
        if (!email) {
          email =
            (getAdditionalUserInfo(auth)?.profile?.email as string) ?? null;
        }

        name =
          auth.user.displayName ||
          (getAdditionalUserInfo(auth)?.profile?.name as string) ||
          'Unknown Signin';
      }

      // strogging.log('doSignInWithPopup-complete', {
      //   email: auth.user.email,
      //   displayName: auth.user.displayName,
      //   name,
      //   k: Object.keys(getAdditionalUserInfo(auth) || {}),
      //   additionalProfile: getAdditionalUserInfo(auth)?.profile,
      // });
      if (name === 'Unknown Signin') {
        strogging.warn('Unknown Signin', {
          provider: authProvider.providerId,
          email,
        });
      }
      const firstName = name?.split(' ')[0];
      const lastName = name?.split(' ').slice(-1).join(' ');
      const authUserId = auth.user.uid;
      const photoUrl = auth.user.photoURL;

      let phoneNumber;
      if (claimPhoneNumber) {
        phoneNumber = claimPhoneNumber.replace(' ', '+');
      } else if (extraPostParams?.phone) {
        phoneNumber = extraPostParams.phone;
      }

      // // TEMP: for testing linking facebook / google account
      // const providerId = 'google.com';
      // auth.user.unlink(providerId).then(() => {
      //   console.log('unlink success');
      // }).catch((err) => {
      //   console.log('unlink error', err);
      // });

      try {
        const userResponse = await axios.post<
          dtos.UserPostResponse,
          AxiosResponse<dtos.UserPostResponse>,
          dtos.UserPostRequest
        >(postUrl, {
          email,
          first_name: firstName,
          last_name: lastName,
          auth_user_id: authUserId,
          photo_url: photoUrl,
          claim_player: claimPlayer,
          key,
          phone: phoneNumber,
          ...extraPostParams,
        });
        analytics.identify(userResponse.data.userId);
        analytics.track('Logged In');
        analytics.setUserProperties({
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
          $phone: phoneNumber,
          $created: userResponse.data.user.createdTs,
        });
        handleSignInSuccess(userResponse.data, auth.user, credential);
      } catch (respError) {
        strogging.error('userPostError', {
          postUrl,
          err: errorUtil.errorMessage(respError),
        });
        userStore.clearStore();
        try {
          await theFirebase().doSignOut();
          if (
            isAxiosError(respError) &&
            respError.response &&
            (respError.response.status === 404 ||
              respError.response.status === 401 ||
              respError.response.status === 400)
          ) {
            errorHandler({
              message: `We could not find an existing account with this email: ${email}.`,
              urlText: 'Go to https://sidelinehd.com/get-started to sign up',
              url: 'https://sidelinehd.com/get-started',
            });
          } else if (
            isAxiosError(respError) &&
            respError.response &&
            respError.response.status === 409
          ) {
            const { trueUserId } = respError.response.data;
            if (trueUserId) {
              props.history.push(`/claim_user/${trueUserId}`);
            } else {
              props.history.push(SIGN_IN);
            }
          } else {
            Sentry.captureException(respError);
            errorHandler({
              message: 'Something went wrong. Please try again.',
            });
          }
          toggleLoading(false);
        } catch (signOutError) {
          toggleLoading(false);
          Sentry.captureException(signOutError);
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      if (isFirebaseAuthError(e)) {
        strogging.error('FirebaseAuthError', {
          message: e.message,
          code: e.code,
          name: e.name,
          provider: authProvider.providerId,
          customData: e.customData,
          deviceType: parsedUserAgent().device.type,
          deviceModel: parsedUserAgent().device.model,
          browserName: parsedUserAgent().browser.name,
          osName: parsedUserAgent().os.name,
        });
      }
      if (
        isFirebaseAuthError(e) &&
        e.code ===
          errorUtil.fbAuthErrorCodes.accountExistsWithDifferentCredential
      ) {
        try {
          const providers = await theFirebase().doFetchSignInMethodsForEmail(
            e.customData.email || ''
          );
          if (providers.includes('google.com')) {
            e.message = `You previously signed in with a Google account with the email address ${
              e.customData.email || '<unknown email>'
            }.`;
            const authCred = theFirebase().getErrorCredential(
              e,
              authProvider.providerId
            );
            linkAccountHandler?.(authCred);
          } else {
            e.message = ERROR_MSG_ACCOUNT_EXISTS;
          }

          errorHandler(e);
        } catch (err) {
          e.message = ERROR_MSG_ACCOUNT_EXISTS;
          errorHandler(e);
        }
      } else {
        errorHandler({ message: 'Something went wrong. Please try again.' });
      }
    }
  };

  const handleSignInSuccess = async (
    userResponseData: dtos.UserPostResponse,
    authUser: User,
    credential?: AuthCredential | null
  ) => {
    theFirebase().setAnalyticsUserId(userResponseData.userId);
    theFirebase().setAnalyticsUserProperties({
      shd_user_id: userResponseData.userId,
    });

    try {
      const token = await theFirebase().auth.currentUser?.getIdToken(true); // Force token refresh

      const { isValidClaimPlayer, isNewUser, invite } = userResponseData;

      // hack goes to 11 : if the user tries to score a game (clicks score game)
      // and they have a scoreDelegationKey, then they will just get logged out.
      // This is a hack to prevent that. The right fix is to fix scoring delegation
      // correctly
      if (localStorage.getItem('scoreDelegationKey')) {
        strogging.log('clearing scoreDelegationKey');
        localStorage.removeItem('scoreDelegationKey');
      }

      if (isValidClaimPlayer) {
        props.history.push(`/claim_player/${claimPlayer}?key=${key}`);
      } else if (isNewUser && onSuccess) {
        onSuccess?.(userResponseData);
      } else {
        if (appHost.isHosted && token && credential) {
          const credentialJson = credential ? credential.toJSON() : undefined;

          try {
            const res = await axios.get('/api/remote_login/webview_token');
            const webviewToken = res.data.token;
            appHost.service.notifications.setAuthFromPhoneSignIn(
              webviewToken,
              credentialJson as shd.PhoneAuthCredential
            );
          } catch (e) {
            Sentry.captureException(e);
          }
        }
        doRedirect();

        try {
          const { sendToOnboarding } = (await userStore.apiSetTeams(true)) || {
            sendToOnboarding: false,
          };
          if (!sendToOnboarding) {
            userStore.setAuthUser(authUser);

            const redirect = query.get('redirectUrl');
            if (redirect) {
              return;
            }
            if (invite) {
              if (invite.teamId) {
                if (Object.keys(userResponseData.acceptanceInfo).length !== 0) {
                  const { title, message } = userResponseData.acceptanceInfo;
                  props.history.push(
                    `/home?msg=${encodeURIComponent(message)}&title=${encodeURIComponent(title)}`
                  );
                } else {
                  props.history.push(
                    `/${invite.teamId}?from_invite=${invite.id}`
                  );
                }
              } else if (invite.claimedPlayerId) {
                if (Object.keys(userResponseData.acceptanceInfo).length !== 0) {
                  const { title, message } = userResponseData.acceptanceInfo;
                  props.history.push(
                    `/home?msg=${encodeURIComponent(message)}&title=${encodeURIComponent(title)}`
                  );
                } else {
                  props.history.push(`/${invite.claimedPlayerId}`);
                }
              } else {
                props.history.push('');
              }
            } else if (userStore.selectedTeam) {
              props.history.push(`/${userStore.selectedTeam.nameHandle}`);
            } else if (userStore.selectedPlayer) {
              props.history.push(`/${userStore.selectedPlayer._id}`);
            } else {
              props.history.push(WAITING);
            }
          } else if (!window.location.pathname.includes(ONBOARDING)) {
            props.history.push(ONBOARDING);
          } else {
            toggleLoading(false);
          }
        } catch (respError) {
          // this is an error from the user post
          Sentry.captureException(respError);
          errorHandler({
            message: 'Something went wrong. Please try again.',
          });
          toggleLoading(false);
        }
      }
    } catch (getTokenError) {
      // this is when the user auth'd with firebase but then there was
      // an error getting a token. This seems like it should be rare.
      toggleLoading(false);
      strogging.error('getTokenError', {
        err: errorUtil.errorMessage(getTokenError),
      });
      Sentry.captureException(getTokenError);
    }
  };

  // const handleSignInError = (error: any) => {
  //   errorHandler({ message: 'Something went wrong. Please try again.' });
  // };

  return { authenticate };
};

export default useAuthentication;
