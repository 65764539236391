// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Heading,
} from 'grommet';
import GameList from './GameList';
import SHDButton from '../../components/SHD/Button';

const GameListContainer = (props) => {
  const { gameList, onComplete } = props;
  const [selectedTeam, onSelectTeam] = useState(null);

  const onConfirmSelectTeam = () => {
    onComplete(selectedTeam);
  };

  return (
    <Box align="center" gap="large">
      <Heading level={3} margin={{ top: 'none', bottom: 'none' }}>Select your team</Heading>
      <GameList gameList={gameList} selectedTeam={selectedTeam} onSelectTeam={onSelectTeam} />
      <SHDButton primary label="Select team" onClick={onConfirmSelectTeam} disabled={!selectedTeam} />
      <br />
      <br />
    </Box>
  );
};

export default GameListContainer;
