// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Firebase, theFirebase } from './firebase';

export const FirebaseContext = React.createContext(theFirebase());

export const FirebaseContextProvider = ({ children }) => {
  const fb = theFirebase();
  const [firebase, setFirebase] = React.useState(fb);
  React.useEffect(() => {
    setFirebase(fb);
  }, [fb]);
  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export interface FirebaseComponentProps {
  firebase: Firebase;
}

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);
