// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  useContext,
} from 'react';
import { Grid, ResponsiveContext } from 'grommet';

const ResponsiveGrid = ({ children, areas, ...props }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Grid areas={areas[size]} {...props}>
      {children}
    </Grid>
  );
};

export default ResponsiveGrid;
