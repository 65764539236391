import React from 'react';
import { Box, Video, Text, Button, Image } from 'grommet';
import { LessonCardListProps } from './dkplayerRecommentdations.types';

import {
  MetricList,
  ListItem,
  buttonStyle,
  titleStyle,
  iconStyle,
} from './dkplayerRecommendations.styles';

import { LessonCardProps } from './dkplayerRecommentdations.types';
import { RecommendedActivity } from '@shd/jslib/models/generated/docStorage/model/recommendedActivity';

import {
  onMixpanelActivity,
  MixpanelActivityEvents,
} from './dkplayerRecommendations.mixpanel';

const LessonCard: React.FC<LessonCardProps> = ({
  recommendation,
  category,
  sport,
  gradYear,
  link,
}) => {
  const onHandlePlay = () => {
    onMixpanelActivity(MixpanelActivityEvents.ACTIVITY_VIEWED, {
      activityUuid: recommendation.uuid,
      activityEngineType: category,
      activityName: recommendation.name,
      sport: sport,
      gradYear: gradYear,
    });
  };

  const onHandleStart = () => {
    onMixpanelActivity(MixpanelActivityEvents.START_ACTIVITY, {
      activityUuid: recommendation.uuid,
      activityEngineType: category,
      activityName: recommendation.name,
      sport: sport,
      gradYear: gradYear,
    });
  };

  return (
    <Box key={recommendation.uuid} margin={{ bottom: '1.5rem' }}>
      <Box id="header" direction="row" justify="between">
        <Box id="iconcontent" direction="row" alignSelf="center">
          <Box id="icon" style={iconStyle} round={true} overflow="hidden">
            {recommendation.thumbnailUrl !== '' ? (
              <Image src={recommendation.thumbnailUrl} fit="cover" />
            ) : (
              <Image src={'/dk_icon.png'} fit="cover" />
            )}
          </Box>
          <Box id="content" style={{ marginLeft: '.5rem' }} alignSelf="center">
            <Box id="title">
              <Text style={titleStyle} weight="bold">
                {recommendation.name}
              </Text>
            </Box>
            <MetricList>
              <ListItem>{recommendation.swing_count} Swings</ListItem>
              <ListItem>{category}</ListItem>
            </MetricList>
          </Box>
        </Box>
        <Box id="button" align="center" alignSelf="center">
          <Button
            href={link}
            style={buttonStyle}
            label={'Start'}
            onClick={onHandleStart}
          />
          <Text size="xsmall" style={{ opacity: '0.4', marginTop: '.5rem' }}>
            App Required
          </Text>
        </Box>
      </Box>
      <Box margin={{ top: '1rem' }}>
        <Box
          id="lesson-video"
          height="medium"
          width="large"
          round="small"
          overflow="hidden"
        >
          <Video
            controls={'over'}
            fit="cover"
            key={'video'}
            onPlay={onHandlePlay}
            preload="auto"
          >
            <source
              key="video"
              src={`${recommendation.introVideoUrl}#t=0.001`}
              type="video/mp4"
            />
          </Video>
        </Box>
        <Box margin={{ top: '0.5rem' }}>
          <Text style={{ ...titleStyle, opacity: '0.8' }}>
            {recommendation.description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const LessonCardList: React.FC<LessonCardListProps> = ({
  recommendations,
  category,
  sport,
  gradYear,
  fetchActivityLink,
}) => {
  const formatCategory = (cat?: string) => {
    if (!cat) {
      return undefined;
    }
    return cat
      .split(/[-_\s]+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Box id="recommendation-list" pad={'1rem'}>
      {recommendations?.map((recommendation: RecommendedActivity) => (
        <LessonCard
          key={recommendation.uuid}
          recommendation={recommendation}
          category={formatCategory(category)}
          sport={sport}
          gradYear={gradYear}
          link={fetchActivityLink(recommendation.uuid) || ''}
        />
      ))}
    </Box>
  );
};

export default LessonCardList;
