import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardHeader, Text } from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatPhoneNumber } from 'react-phone-number-input';
import { AuthError } from 'firebase/auth';
import { MoonLoader } from 'react-spinners';
import SixDigitCodeInput from './SixDigitCodeInput';

interface Props extends RouteComponentProps {
  phoneNumber: string;
  onVerify: (
    verificationCode: string
  ) => Promise<{ success: boolean; error?: AuthError }>;
  onBack: () => void;
  onResend: () => void;
}

export const OTPVerification: React.FC<Props> = ({
  phoneNumber,
  onVerify,
  onBack,
  onResend,
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState<AuthError | null>(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [lastResendTime, setLastResendTime] = useState(Date.now());
  const [resendTimeLeft, setResendTimeLeft] = useState(60);
  const [showTimeLeft, setShowTimeLeft] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeElapsed = (Date.now() - lastResendTime) / 1000;
      const timeLeft = Math.max(0, 60 - timeElapsed);
      setResendTimeLeft(Math.floor(timeLeft));
      if (timeLeft === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastResendTime]);

  const handleVerify = async () => {
    setVerifyLoading(true);
    const result = await onVerify(verificationCode);
    if (result.success) {
    } else if (result.error) {
      setError(result.error);
      setVerifyLoading(false);
    }
  };

  return (
    <Box gap="16px">
      <Card pad="large" gap="small" background="white" fill="horizontal">
        <Box gap="small">
          <CardHeader align="start" direction="column" gap="8px">
            <Text weight="bold" size="20px">
              Please verify your identity
            </Text>
            <Text>
              A verification code was sent to{' '}
              <Text weight="bold">{formatPhoneNumber(phoneNumber)}</Text>.
              Please enter it below. The code is valid for 5 minutes.
            </Text>
          </CardHeader>
          <Box gap="16px">
            <SixDigitCodeInput error={error} onChange={setVerificationCode} />
            <Text weight="bold">Not receiving a code?</Text>
            <Button
              plain
              label={
                <Text
                  weight="bold"
                  color={resendLoading ? 'gray-2' : 'tertiary-1'}
                >
                  {resendLoading ? 'Resending...' : 'Resend code'}
                </Text>
              }
              disabled={resendLoading}
              onClick={() => {
                if (resendTimeLeft > 0) {
                  setShowTimeLeft(true);
                } else {
                  setResendLoading(true);
                  onResend();
                  setLastResendTime(Date.now());
                  setTimeout(() => {
                    setResendLoading(false);
                  }, 1500);
                  setShowTimeLeft(false);
                }
              }}
            />
            {showTimeLeft && resendTimeLeft > 0 && (
              <Text>You can resend code in {resendTimeLeft} seconds</Text>
            )}
          </Box>
        </Box>
      </Card>
      <Card
        background={'white'}
        pad="16px"
        elevation="none"
        direction="row"
        flex={{ shrink: 0 }}
      >
        <Box flex="grow" style={{ width: '50%' }} pad={{ right: '8px' }}>
          <Button secondary label="Back" onClick={onBack} fill />
        </Box>
        <Box flex="grow" style={{ width: '50%' }} pad={{ left: '8px' }}>
          <Button
            primary
            label={
              verifyLoading ? <Text color={'transparent'}></Text> : 'Next Step'
            }
            disabled={verificationCode.length !== 6}
            onClick={handleVerify}
            icon={verifyLoading ? <MoonLoader size={20} /> : undefined}
            fill
          />
        </Box>
      </Card>
    </Box>
  );
};

export default withRouter(OTPVerification);
