import React, { useContext } from 'react';
import { Box, Button, Footer, Image, Layer, Text } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TeamCapture } from '@shd/jslib/models/shd';
import { isMobile } from '../../helpers/browserDetect';
import { AppHostContext } from '../../services/appHost';

interface StepOneProps {
  capture: TeamCapture;
  copied: boolean;
  setCopied: (copied: boolean) => void;
}

const StepOneContent: React.FC<StepOneProps> = ({
  capture,
  copied,
  setCopied,
}) => {
  const appHost = useContext(AppHostContext);
  const appHostService = appHost.isHosted ? appHost.service : undefined;
  return (
    <Box pad="medium" gap="small" flex={{ shrink: 0, grow: 1 }}>
      <Text weight="bold">Add SidelineHD as a streaming destination</Text>
      <Text size="medium">
        You may get many prompts - approve with
        YES/INSTALL/SAVE/LAUNCH/DOWNLOAD/etc
      </Text>
      <Button
        primary
        label="Open in Mevo App"
        disabled={!capture.rtmp?.mevoAppUrl}
        onClick={() => {
          if (appHost.isHosted) {
            appHostService?.notifications.openAppLink(
              capture.rtmp?.mevoAppUrl || ''
            );
          } else {
            window.open(capture.rtmp?.mevoAppUrl || '', '_blank');
          }
        }}
      />
      <Box
        background={'white'}
        margin={{ top: 'small' }}
        gap="8px"
        pad="16px"
        round="8px"
        border
      >
        <Text weight={'bold'}>Setup not working?</Text>
        <Text>
          Copy the URL and transfer it to your mobile device by text or email.
        </Text>
        <Box
          direction="row"
          justify="between"
          margin={{ top: '4px' }}
          align="center"
          gap="8px"
          flex
        >
          <Text size="small" truncate>
            {capture.rtmp?.mevoAppUrl}
          </Text>
          <CopyToClipboard
            text={capture.rtmp?.mevoAppUrl || ''}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
          >
            <Box flex={{ grow: 1, shrink: 0 }}>
              <Button
                secondary
                size="small"
                label={copied ? 'Copied!' : 'Copy to Clipboard'}
                disabled={copied}
              />
            </Box>
          </CopyToClipboard>
        </Box>
      </Box>
    </Box>
  );
};

interface StepTwoProps {}

const StepTwoContent: React.FC<StepTwoProps> = () => {
  return (
    <Box pad="medium" gap="small" flex={{ shrink: 0, grow: 1 }}>
      <Text weight="bold">Click the red RECORD button</Text>
      <Box round="4px" overflow="hidden" height={{ max: '200px' }}>
        <Image src="/mevo_redbutton.png" fit="contain" />
      </Box>
      <Text weight="bold">
        Start a RTMP stream to sidelineHD (see ball left of the RTMP option)
      </Text>
      <Box round="4px" overflow="hidden" height={{ max: '200px' }}>
        <Image src="/mevo_app_go_live2.png" fit="contain" />
      </Box>
      <Box margin={{ top: 'small' }} gap="8px" pad={{ left: '8px' }}>
        <Text>1. Select RTMP (do not select Facebook or YouTube)</Text>
        <Text>2. Select your sidelineHD camera</Text>
        <Text>3. Set resolution to 720p or lower</Text>
        <Text>4. Press GO LIVE</Text>
      </Box>
    </Box>
  );
};

interface MevoSingleSetupModalProps {
  capture: TeamCapture;
  onClose: () => void;
}

const MevoSingleSetupModal: React.FC<MevoSingleSetupModalProps> = ({
  capture,
  onClose,
}) => {
  const [copied, setCopied] = React.useState(false);
  const [step, setStep] = React.useState(1);

  return (
    <Layer
      full={isMobile.any() === true}
      responsive={false}
      style={{ maxHeight: '100svh' }}
      animation={false}
      background={'light-5'}
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Box height={'100%'} flex>
        <Box
          flex={{ grow: 0, shrink: 1 }}
          direction="row"
          pad="16px"
          align="center"
          justify="between"
        >
          <Box
            alignSelf="start"
            onClick={() => {
              if (step === 1) {
                onClose();
              } else {
                setStep(step - 1);
              }
            }}
          >
            <LinkPrevious size="24px" />
          </Box>
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              Pair your Mevo app
            </Text>
          </Box>
          <Box width="24px" />
        </Box>
        <Box overflow={'scroll'} flex={{ grow: 1, shrink: 1 }}>
          {step === 1 && (
            <StepOneContent
              capture={capture}
              copied={copied}
              setCopied={setCopied}
            />
          )}
          {step === 2 && <StepTwoContent />}
        </Box>
        <Footer
          pad={'16px'}
          background="white"
          border={{
            color: 'light-2',
            side: 'top',
            size: '1px',
            style: 'solid',
          }}
          flex={{ shrink: 0, grow: 0 }}
        >
          <Box fill="horizontal" align="center" gap="8px">
            <Text weight="bold">Step {step} of 2</Text>
            <Button
              fill
              primary
              size="large"
              label={step === 1 ? 'I finished this step' : 'Done'}
              onClick={() => {
                if (step === 2) {
                  onClose();
                } else {
                  setStep(step + 1);
                }
              }}
            />
          </Box>
        </Footer>
      </Box>
    </Layer>
  );
};

export default MevoSingleSetupModal;
