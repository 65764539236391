// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { Close, FormEdit } from 'grommet-icons';

import { TextInput } from 'grommet';
import { isMobile } from '../../helpers/browserDetect';
import axios from '../../helpers/axios';

import {
  Action,
  BottomModal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ModalButton,
  ModalTopRight,
} from './styles';

export default function EditClipButton({
  playlistId,
  clip,
  onEdited,
  onOpen,
  onClose,
}) {
  const [value, setValue] = useState(clip);
  const [isOpen, setIsOpen] = useState(false);
  const position = isMobile.any() ? 'bottom' : 'center';

  useEffect(() => {
    setValue(clip);
  }, [clip]);

  const handleClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
    onOpen();
  };

  const close = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      <Action onClick={handleClick}>
        <FormEdit color="primary-1" height="24px" width="24px" />
      </Action>

      {isOpen && (
        <BottomModal
          $isMobile={isMobile.any()}
          position={position}
          responsive={false}
          onClickOutside={close}
        >
          <ModalContent>
            <ModalHeader>
              <ModalTitle>Edit Clip</ModalTitle>
              <ModalTopRight onClick={close}>
                <Close height="24px" width="24px" />
              </ModalTopRight>
            </ModalHeader>
            <ModalBody gap="medium">
              <TextInput
                value={value.titleMain}
                placeholder="Title"
                onChange={(event) => {
                  setValue({ ...value, titleMain: event.target.value });
                }}
              />
              <TextInput
                value={value.titleSub}
                placeholder="Subtitle"
                onChange={(event) => {
                  setValue({ ...value, titleSub: event.target.value });
                }}
              />
            </ModalBody>
            <ModalFooter>
              <ModalButton
                label="Save"
                onClick={() => {
                  axios
                    .put(`/api/reels/${playlistId}/clip/${clip.id}`, {
                      clip: value,
                    })
                    .then((response) => {
                      setIsOpen(false);
                      onEdited(response.data);
                      onClose();
                    });
                }}
              />
            </ModalFooter>
          </ModalContent>
        </BottomModal>
      )}
    </>
  );
}
