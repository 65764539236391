import React from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Layer,
  Text,
} from 'grommet';
import { isMobile } from '../../helpers/browserDetect';
import { shd } from '@shd/jslib/models';
import { Checkmark, Close } from 'grommet-icons';
import axios from '../../helpers/axios';
import * as Sentry from '@sentry/browser';

interface ClaimRequestCardProps {
  claimRequest: shd.ClaimRequest;
  onUpdated: (claimRequest: shd.ClaimRequest) => void;
}
const ClaimRequestCard: React.FC<ClaimRequestCardProps> = ({
  claimRequest: initialClaimRequest,
  onUpdated,
}) => {
  const [claimRequest, setClaimRequest] = React.useState(initialClaimRequest);
  const [loading, setLoading] = React.useState(false);
  const updateClaim = (accepted: boolean) => {
    setLoading(true);
    axios
      .put('/api/claim_request', {
        accepted,
        claim: claimRequest,
      })
      .then((response) => {
        setClaimRequest(response.data.claim);
        onUpdated(response.data.claim);
        setLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
      });
  };
  return (
    <Box
      background={'white'}
      border={{ side: 'all', color: 'light-1', size: '1px' }}
      pad="16px"
      round="8px"
      flex={{ shrink: 0 }}
    >
      <Box direction="row" gap="small" justify="between">
        <Box>
          <Text>
            <Text weight="bold">{`${claimRequest.claimerNameFirst} ${claimRequest.claimerNameLast}`}</Text>
            <Text> wants to claim </Text>
            <Text weight="bold">{`${claimRequest.nameFirst} ${claimRequest.nameLast} #${claimRequest.jerseyNum}`}</Text>
          </Text>
        </Box>
        {claimRequest.isVisible && (
          <Box direction="row" gap="8px " flex={{ shrink: 0 }}>
            <Box
              round="full"
              border={{ color: loading ? 'light-1' : 'green', size: '1px' }}
              flex={{ shrink: 0 }}
              pad="8px"
              align="center"
              justify="center"
              onClick={() => updateClaim(true)}
            >
              <Checkmark color={loading ? 'light-1' : 'green'} />
            </Box>
            <Box
              round="full"
              border={{
                color: loading ? 'light-1' : 'status-error',
                size: '1px',
              }}
              flex={{ shrink: 0 }}
              pad="8px"
              align="center"
              justify="center"
              onClick={() => updateClaim(false)}
            >
              <Close color={loading ? 'light-1' : 'status-error'} />
            </Box>
          </Box>
        )}
        {!claimRequest.isVisible && (
          <Box direction="row" gap="8px " align="center" flex={{ shrink: 0 }}>
            {claimRequest.isAccepted ? (
              <Text weight="bold" color="green">
                Accepted
              </Text>
            ) : (
              <Text weight="bold" color="status-error">
                Declined
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface PlayerClaimRequestsModalProps {
  claimRequests: shd.ClaimRequest[];
  onClose: () => void;
}

const PlayerClaimRequestsModal: React.FC<PlayerClaimRequestsModalProps> = ({
  claimRequests: initialClaimRequests,
  onClose,
}) => {
  const [claimRequests, setClaimRequests] =
    React.useState(initialClaimRequests);
  return (
    <Layer
      onEsc={() => onClose()}
      onClickOutside={() => onClose()}
      responsive={false}
      margin={isMobile.any() ? 'large' : 'none'}
      full={isMobile.any() === true}
      background="white"
    >
      <Card elevation="none" fill>
        <CardHeader
          pad="16px"
          border={{ side: 'bottom', color: 'light-1', size: '1px' }}
        >
          <Text size="xlarge" weight="bold">
            Player Claim Requests
          </Text>
        </CardHeader>
        <CardBody pad="16px" gap="16px" overflow="auto">
          {claimRequests.map((claimRequest, i) => (
            <ClaimRequestCard
              key={i}
              claimRequest={claimRequest}
              onUpdated={(updatedClaimRequest) => {
                setClaimRequests(
                  claimRequests.map((c) =>
                    c._id === updatedClaimRequest._id ? updatedClaimRequest : c
                  )
                );
              }}
            />
          ))}
        </CardBody>
        <CardFooter>
          <Box
            direction="row"
            pad="16px"
            gap="8px"
            border={{ side: 'top', color: 'light-1', size: '1px' }}
            justify="between"
            flex
          >
            <Box flex={{ grow: 1, shrink: 0 }}>
              <Button secondary label="Cancel" onClick={() => onClose()} />
            </Box>
            <Box flex={{ grow: 1, shrink: 0 }}>
              <Button primary label="Done" onClick={() => onClose()} />
            </Box>
          </Box>
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default PlayerClaimRequestsModal;
