// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';

import {
  Box,
  ResponsiveContext,
  Text,
  Card,
  CardHeader,
  CardBody,
  Image,
  Button,
  Layer,
  Avatar,
} from 'grommet';
import { User } from 'grommet-icons';
import { HiPencil } from 'react-icons/hi';
import { MdLocationOn } from 'react-icons/md';
import { FaGraduationCap } from 'react-icons/fa';
import * as Sentry from '@sentry/browser';
import CopyToClipboard from 'react-copy-to-clipboard';
import { observer } from 'mobx-react';
import SHDButton from '../../components/SHD/Button';
import EditPlayerProfile from './EditPlayerProfile';
import Toast from '../../components/Toast';
import ShareDropdown from '../../helpers/ShareDropdown';
import {
  SPORTS_MAP,
  COMBINED_BBSB_KEY,
  BASKETBALL,
  SOCCER,
  VOLLEYBALL,
  BASEBALL,
  SOFTBALL,
  BBSB_SPORTS_KEYS,
} from '../../constants/strings';
import { UserContext } from '../../services/Session';

const PlayerCard = observer(
  ({
    image,
    name,
    teams,
    claimed,
    isAdmin,
    history,
    compilation,
    openPlayerSettings,
    setShowSRCTAModal,
    setShowSRInfoModal,
    // showSRBadge, showDKBadge,
    ...props
  }) => {
    const userStore = useContext(UserContext);
    const screen = useContext(ResponsiveContext);
    const [player, setPlayer] = useState(props.player);
    const [showMoreTeams, setShowMoreTeams] = useState(false);
    const [showEditProfile, setShowEditProfile] = useState(
      openPlayerSettings && isAdmin
    );
    const [showEditSuccess, setShowEditSuccess] = useState(false);
    const [copied, setCopied] = useState(false);

    // const sport = teams[0].attrib_sportType;
    // const sports = teams.map((team) => team.attrib_sportType);
    let { selectedSport } = props;
    if (props.selectedSport === COMBINED_BBSB_KEY) {
      // disambiguate between baseball and softball
      const baseballTeamCount = teams.filter(
        (team) => team.attrib_sportType === BASEBALL.display
      ).length;
      const softballTeamCount = teams.filter(
        (team) => team.attrib_sportType === SOFTBALL.display
      ).length;
      // dont really care if someone is on the same number of baseball and softball teams
      // very rare situation, just default baseball in that case
      if (baseballTeamCount >= softballTeamCount) {
        selectedSport = BASEBALL.key;
      } else {
        selectedSport = SOFTBALL.key;
      }
    } else if (props.selectedSport === 'vb') {
      selectedSport = VOLLEYBALL.key;
    }

    const biographyDiv = (
      <Box gap="medium" direction="row">
        {player.hometown ? (
          <>
            <MdLocationOn />
            <Text size="large">{player.hometown}</Text>
          </>
        ) : null}
        {player && player.yearGraduation ? (
          <>
            <Text size="large">Class of&nbsp;</Text>
            <Text size="large" weight="bold">{`${player.yearGraduation}`}</Text>
          </>
        ) : null}
      </Box>
    );

    const renderSportInfo = () => {
      let primaryPosition = '';
      let handednessDiv = null;
      if ([BBSB_SPORTS_KEYS].includes(selectedSport)) {
        primaryPosition = player.positionPrimary;

        handednessDiv = (
          <Box gap="small" direction="row">
            {player && player.handednessThrowing ? (
              <>
                <Text size="large">Throws:&nbsp;</Text>
                <Text size="large" weight="bold">
                  {player.handednessThrowing}
                </Text>
              </>
            ) : null}
            {player && player.handednessBatting ? (
              <>
                <Text size="large">Bats:&nbsp;</Text>
                <Text size="large" weight="bold">
                  {player.handednessBatting}
                </Text>
              </>
            ) : null}
          </Box>
        );
      } else if (selectedSport === VOLLEYBALL.key) {
        primaryPosition = player.positionPrimaryVB;

        handednessDiv = (
          <>
            {player && player.handedness ? (
              <>
                <Text size="large">Handedness:&nbsp;</Text>
                <Text size="large" weight="bold">
                  {player.handedness}
                </Text>
              </>
            ) : null}
          </>
        );
      } else if (selectedSport === BASKETBALL.key) {
        primaryPosition = player.positionPrimaryBasketball;

        handednessDiv = (
          <>
            {player && player.handedness ? (
              <>
                <Text size="large">Handedness:&nbsp;</Text>
                <Text size="large" weight="bold">
                  {player.handedness}
                </Text>
              </>
            ) : null}
          </>
        );
      } else if (selectedSport === SOCCER.key) {
        primaryPosition = player.positionPrimarySoccer;

        handednessDiv = (
          <>
            {player && player.handedness ? (
              <>
                <Text size="large">Footedness:&nbsp;</Text>
                <Text size="large" weight="bold">
                  {player.handedness}
                </Text>
              </>
            ) : null}
          </>
        );
      }

      return (
        <Box gap="medium" direction="row">
          <Text size="large" weight="bold">
            {primaryPosition}
          </Text>
          {handednessDiv}
        </Box>
      );
    };

    const renderMorePlayerInfo = () => (
      <Box gap="medium" direction="row">
        {player && player.height ? (
          <Text size="large" weight="bold">
            {player.height}
          </Text>
        ) : null}
        {player && player.weight ? (
          <>
            <Text size="large" weight="bold">
              {player.weight}
            </Text>
            <Text size="large">lbs</Text>
          </>
        ) : null}
        {player && player.gpa ? (
          <>
            <Text size="large" weight="bold">
              {player.gpa}
            </Text>
            <Text size="large">&nbsp;GPA</Text>
          </>
        ) : null}
      </Box>
    );

    const renderPlayerInfoCard = () => {
      const hasInfo =
        player &&
        (player.hometown ||
          player.yearGraduation ||
          player.positionPrimary ||
          player.positionPrimaryVB ||
          player.handednessThrowing ||
          player.handednessBatting ||
          player.height ||
          player.weight ||
          player.gpa);

      if (!hasInfo) {
        return null;
      }

      return (
        <Box
          pad="medium"
          gap="small"
          background={{ color: 'white' }}
          round="medium"
        >
          {biographyDiv}
          {renderSportInfo()}
          {renderMorePlayerInfo()}
        </Box>
      );
    };

    const renderCollegeCommitStatus = () => {
      if (!player) {
        return null;
      }

      if (
        !player.collegeCommitStatus ||
        player.collegeCommitStatus === 0 ||
        player.collegeCommitStatus === 20
      ) {
        return null;
      }

      let commitText;

      if (player.collegeCommitStatus === 5) {
        commitText = 'Uncommitted';
      } else if (player.collegeCommitStatus === 10) {
        commitText = 'Committed';
      }

      return (
        <Box pad="medium" background="white" round="medium">
          <Box direction="row" gap="small">
            <FaGraduationCap />
            <Box>
              <Text size="large" weight="bold">
                {commitText}
              </Text>
              <Text>
                {player && player.collegeCommitStatus === 10
                  ? player.collegeCommitName
                  : null}
              </Text>
            </Box>
          </Box>
        </Box>
      );
    };

    const renderPlayerLocker = () => {
      let equipmentDiv;
      let heroDiv;
      let faveTeamDiv;
      if (BBSB_SPORTS_KEYS.includes(selectedSport)) {
        if (player.equipment && player.equipment.bat) {
          equipmentDiv = (
            <Box direction="row" gap="small">
              <>
                <Text size="large" weight="bold">
                  Swings:{' '}
                </Text>
                <Text size="large">
                  &nbsp;
                  {player.equipment.bat}
                </Text>
              </>
            </Box>
          );
        }

        if (player.favoriteTeams && player.favoriteTeams[selectedSport]) {
          faveTeamDiv = (
            <Box direction="row" gap="small">
              <>
                <Text size="large" weight="bold">
                  Favorite Team:{' '}
                </Text>
                &nbsp;
                <Box height="20px" width="20px">
                  <Image
                    fit="contain"
                    src={`https://midfield.mlbstatic.com/v1/team/${player.favoriteTeams[selectedSport].id}/spots`}
                  />
                </Box>
                <Text size="large">
                  &nbsp;
                  {player.favoriteTeams[selectedSport].name}
                </Text>
              </>
            </Box>
          );
        }
      } else {
        equipmentDiv = null;
      }

      if (player.playerHero && player.playerHero[selectedSport]) {
        heroDiv = (
          <Box direction="row" gap="small">
            <>
              <Text size="large" weight="bold">
                Favorite Player:{' '}
              </Text>
              <Text size="large">
                &nbsp;
                {player.playerHero[selectedSport]}
              </Text>
            </>
          </Box>
        );
      } else {
        heroDiv = null;
      }

      if (!equipmentDiv && !heroDiv && !faveTeamDiv) {
        return null;
      }

      return (
        <Box pad="medium" background="white" round="medium">
          <Box direction="row" gap="small">
            <Box>
              {equipmentDiv}
              {heroDiv}
              {faveTeamDiv}
            </Box>
          </Box>
        </Box>
      );
    };

    const viewMoreTeamsClicked = () => {
      setShowMoreTeams(true);
    };

    const handleShare = async () => {
      if (navigator.share) {
        navigator
          .share({
            title: name,
            text: `Check out ${name} on sidelineHD`,
            url: `https://sidelinehd.com/${player._id}`,
          })
          .catch((respError) => {
            Sentry.captureException(respError);
          });
      }
    };

    const renderShareOptions = () => (
      <Box pad="medium" gap="small">
        {screen === 'small' && (
          <SHDButton label="Share profile via ..." onClick={handleShare} />
        )}
        <CopyToClipboard
          text={`https://sidelinehd.com/${player._id}?s=0`}
          onCopy={() => setCopied(true)}
        >
          <SHDButton label="Copy link to profile" />
        </CopyToClipboard>
      </Box>
    );

    const renderSRBadge = () => {
      if (player.srProfileUrl) {
        return (
          <Box
            onClick={() => {
              if (isAdmin) {
                // Player is already linked and the viewer is an admin
                window.open(player.srProfileUrl, '_blank');
              } else if (!isAdmin && !userStore.authUser) {
                // Player is linked but the viewer is not logged in
                // Show an educational modal
                setShowSRInfoModal(true);
              } else if (!isAdmin && userStore.authUser) {
                // Player is already linked and the viewer is not an admin
                // This is a CTA opportunity to link to SR
                setShowSRCTAModal(true);
              }
            }}
          >
            <Image src="/sr_logo.png" width="24px" />
          </Box>
        );
      }
      return null;
    };

    const playerNameHeader = (
      <Box direction="row" gap="small">
        <Text id="player-name-header" size="xlarge" weight="bold">
          {name}
        </Text>
        {renderSRBadge()}
        {/* {showDKBadge && <Image src="/dk_green_logo.png" width="24px" />} */}
        {/* {showSRBadge && <Image src="/sr_logo.png" width="24px" />} */}
      </Box>
    );

    const playerCardColor = claimed ? 'primary-8' : 'light-3';

    const playerCard = (
      <Box gap="medium" id="playerheader" pad="medium" background="white">
        <Box align="center">
          <Card fill pad="medium" gap="medium" background={playerCardColor}>
            <CardHeader direction="row">
              {!compilation && playerNameHeader}
              <Box direction="row" gap="medium" align="center">
                <ShareDropdown
                  id="share-button"
                  alignSelf="end"
                  plain
                  size="small"
                  label={
                    <Text size="small" weight="bold">
                      Share
                    </Text>
                  }
                  color="tertiary-1"
                  renderShareOptions={renderShareOptions}
                />
                {isAdmin && player ? (
                  <Box
                    pad="xsmall"
                    round="full"
                    overflow="hidden"
                    background="primary-3"
                  >
                    <Button
                      plain
                      icon={<HiPencil />}
                      onClick={() => setShowEditProfile(true)}
                    />
                  </Box>
                ) : null}
              </Box>
            </CardHeader>
            <CardBody>
              <Box
                margin={{ bottom: 'medium' }}
                gap="medium"
                align="end"
                direction="row"
              >
                <Box basis="1/3" round="medium">
                  {image ? (
                    <Image
                      fill
                      fit="cover"
                      src={image}
                      size="medium"
                      style={{ borderRadius: '8px' }}
                    />
                  ) : (
                    <Avatar
                      background={claimed ? 'dark-1' : 'background-back'}
                      size="large"
                    >
                      <User color="white" size="large" />
                    </Avatar>
                  )}
                </Box>
                <Box fill gap="medium">
                  <Card
                    pad="medium"
                    background="white"
                    onClick={() => {
                      history.push(`/${teams[0]._id}`);
                    }}
                  >
                    <Text weight="bold" size="small">
                      Primary team
                    </Text>
                    <Text weight="bold" size="large">
                      {teams.length ? (
                        <Box direction="row" gap="xsmall" align="center">
                          {SPORTS_MAP[teams[0].attrib_sportType].icon}
                          <Text>{teams[0].nameLong}</Text>
                        </Box>
                      ) : null}
                    </Text>
                  </Card>
                  {teams.length > 1 ? (
                    <Button
                      primary
                      color="primary-3"
                      label={
                        <Text size="small" weight="bold" alignSelf="start">
                          View more teams
                        </Text>
                      }
                      onClick={viewMoreTeamsClicked}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box gap="medium">
                {renderPlayerInfoCard()}
                {renderCollegeCommitStatus()}
                {renderPlayerLocker()}
              </Box>
            </CardBody>
          </Card>
        </Box>
      </Box>
    );

    return (
      <Box>
        {playerCard}
        {showMoreTeams && (
          <Layer
            modal
            margin="large"
            position="bottom"
            responsive={false}
            onClickOutside={() => {
              setShowMoreTeams(false);
            }}
            onEsc={() => {
              setShowMoreTeams(false);
            }}
          >
            <Box pad="medium" gap="medium">
              <Text>{`${name} is on`}</Text>
              {teams.map((team) => (
                <Card
                  key={team._id}
                  pad="large"
                  background="primary-7"
                  width="medium"
                  onClick={() => {
                    setShowMoreTeams(false);
                    history.push(`/${team._id}`);
                  }}
                >
                  <Box direction="row" gap="xsmall" align="center">
                    {SPORTS_MAP[team.attrib_sportType].icon}
                    <Text weight="bold">{team.nameLong}</Text>
                  </Box>
                </Card>
              ))}
            </Box>
          </Layer>
        )}
        {!!showEditProfile && (
          <EditPlayerProfile
            teams={teams}
            closeLayer={() => setShowEditProfile(false)}
            setShowEditSuccess={(updatedPlayer) => {
              setPlayer(updatedPlayer);
              setShowEditSuccess(true);
            }}
            claimedPlayer={player}
          />
        )}
        {showEditSuccess && (
          <Toast
            label="Profile changes saved!"
            background="status-ok"
            duration={3000}
            onClose={() => setShowEditSuccess(false)}
          />
        )}
        {copied && (
          <Toast
            label="Copied to clipboard!"
            background="status-ok"
            duration={3000}
            onClose={() => {
              setCopied(false);
            }}
          />
        )}
      </Box>
    );
  }
);

export default PlayerCard;
