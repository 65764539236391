import { Box, Text } from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import React from 'react';
import styles from './Admin.module.css';

interface AdminMenuItemProps extends RouteComponentProps {
  title: string;
  subtitle: string;
  href: string;
}

const AdminMenuItem: React.FC<AdminMenuItemProps> = ({
  title,
  subtitle,
  href,
  history,
}) => {
  return (
    <Box
      align="center"
      background="white"
      border={{ side: 'all', color: 'light-1' }}
      className={styles['admin-menu-item']}
      direction="row"
      justify="between"
      onClick={() => {
        history.push(href);
      }}
      pad="medium"
      round="small"
    >
      <Box>
        <Text margin={{ bottom: '0.5rem' }} weight="bold">
          {title}
        </Text>
        <Text>{subtitle}</Text>
      </Box>
    </Box>
  );
};

export default withRouter(AdminMenuItem);
