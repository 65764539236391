import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import { shd } from '@shd/jslib/models';

import axios from '../../helpers/axios';
import { CaptureStatus } from './GoLiveFlow';
import { TeamCapture } from '@shd/jslib/models/shd';

import {
  MEVO,
  MEVO_SINGLE,
  MEVO_MULTI,
  SMARTPHONE,
  SMARTPHONE_LEGACY,
  GOPRO,
  GEO_US_TX,
} from '../../constants/strings';
import * as Sentry from '@sentry/browser';
import { isMobile } from '../../helpers/browserDetect';
import { BasicLoader } from '../../helpers/Loaders';
import GoProRTMPInfo from './GoProRTMPInfo';
import { analytics } from '../../services/analytics';
import { AppHostContext } from '../../services/appHost';

interface CaptureActionModalProps {
  team: shd.Team;
  capture: TeamCapture;
  status: CaptureStatus;
  onClose: () => void;
  onEnabledCapture: (capture: TeamCapture) => void;
}
const CaptureActionModal: React.FC<CaptureActionModalProps> = ({
  status,
  team,
  capture,
  onClose,
  onEnabledCapture,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const [goProRTMPCopied, setGoProRTMPCopied] = useState(false);
  const appHost = useContext(AppHostContext);
  const appHostService = appHost.isHosted ? appHost.service : undefined;
  const enableCamera = async () => {
    const newCapture = { ...capture };
    newCapture.targetTeamId = team._id;
    setLoading(true);
    axios
      .put(`/api/capture/${newCapture._id}`, {
        captureInfo: newCapture,
        force: true,
      })
      .then((response) => {
        setLoading(false);
        setError(undefined);
        onEnabledCapture(response.data);
        onClose();
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError('Could not enable camera. Please try again.');
        }
        setLoading(false);
      });
  };

  const handleGoPro = () => {
    analytics.track('GoPro Quik Clicked', {
      captureId: capture._id,
    });
    if (appHost.isHosted) {
      appHostService?.notifications.openAppLink('http://onelink.to/3rv9dw');
    } else {
      window.location.href = 'http://onelink.to/3rv9dw';
    }
  };

  const updateMevoType = (mevoType: string) => {
    const newCapture = { ...capture };
    newCapture.equipCamera = mevoType;
    setLoading(true);
    axios
      .put(`/api/capture/${newCapture._id}`, {
        captureInfo: newCapture,
      })
      .then((response) => {
        setLoading(false);
        setError(undefined);

        onEnabledCapture(response.data);
        onClose();
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError('Could not edit camera. Please try again.');
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const getRtmpKey = async () => {
      if (status === CaptureStatus.Ready) {
        try {
          const rtmpResponse = await axios.get(`/api/rtmp_key/${capture._id}`);
          const { rtmp } = rtmpResponse.data;
          if (capture.equipCamera === MEVO_MULTI) {
            if (appHost.isHosted) {
              appHostService?.notifications.openAppLink(rtmp.mevoMulticamUrl);
              onClose();
            } else {
              window.location.href = rtmp.mevoMulticamUrl;
            }
          } else if (capture.equipCamera === MEVO_SINGLE) {
            if (appHost.isHosted) {
              appHostService?.notifications.openAppLink(rtmp.mevoAppUrl);
              onClose();
            } else {
              window.location.href = rtmp.mevoAppUrl;
            }
          } else if (
            capture.equipCamera &&
            [SMARTPHONE, SMARTPHONE_LEGACY].includes(capture.equipCamera)
          ) {
            if (appHost.isHosted) {
              appHostService?.notifications.openAppLink(rtmp.larixUrl);
              onClose();
            } else {
              const now = new Date().valueOf();
              setTimeout(function () {
                if (new Date().valueOf() - now > 100) {
                  return;
                }
                window.location.href = 'https://softvelum.com/larix/';
              }, 60);

              window.location.href = rtmp.larixUrl;
            }
          }
        } catch (e) {
          Sentry.captureException(e);
          setLoading(false);
        }
      }
    };
    getRtmpKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capture, status, onClose]);

  if (
    status === CaptureStatus.Ready &&
    capture.equipCamera !== MEVO &&
    capture.equipCamera !== GOPRO &&
    isMobile.any()
  ) {
    // Go directly to destination
    return null;
  }

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
      modal
      responsive={false}
      full="horizontal"
      background={'transparent'}
    >
      {loading && <BasicLoader />}
      <Box
        pad="16px"
        gap={'8px'}
        margin="medium"
        background={'white'}
        width={{ max: '344px' }}
        alignSelf="center"
        round="8px"
      >
        {error && <Text color={'status-critical'}>{error}</Text>}
        {status === CaptureStatus.Disabled && (
          <Box gap="8px">
            <Text weight={'bold'}>This camera is disabled</Text>
            <Text>
              Enable <Text weight={'bold'}>{capture.captureName}</Text> to
              livestream?
            </Text>
          </Box>
        )}
        {status === CaptureStatus.OtherTeam && (
          <Box gap="8px">
            <Text weight={'bold'}>Go live for this team?</Text>
            <Text>
              {capture.captureName} is currently enabled for{' '}
              <Text weight={'bold'}>{capture.targetTeamName}</Text>. Enabled{' '}
              {capture.captureName} for this team to continue going live.
            </Text>
          </Box>
        )}
        {status === CaptureStatus.TeamLive && (
          <Box gap="8px">
            <Text weight={'bold'}>{`${team.nameMed} is already live`}</Text>
            <Text>You cannot stream to a camera that is currently live.</Text>
          </Box>
        )}
        {status === CaptureStatus.Live && (
          <Box gap="8px">
            <Text weight={'bold'}>This camera is currently live</Text>
            <Text>You cannot stream to a camera that is currently live.</Text>
          </Box>
        )}
        {/* warning for desktop */}
        {status === CaptureStatus.Ready &&
          !(isMobile.any() || appHost.isHosted) && (
            <Box gap="8px">
              <Text weight={'bold'}>You cannot go live from desktop</Text>
              <Text>
                You can enable and point cameras, but must go live from a mobile
                device.
              </Text>
            </Box>
          )}
        {/* special case for Mevo */}
        {status === CaptureStatus.Ready && capture.equipCamera === MEVO && (
          <Box gap="8px" width={{ min: '240px' }}>
            <Text weight={'bold'}>Which Mevo?</Text>
            <Text>Select your Mevo type</Text>
            <Box margin={{ top: '16px' }} gap="8px">
              <Button
                primary
                label={MEVO_SINGLE}
                onClick={() => updateMevoType(MEVO_SINGLE)}
              />
              <Button
                primary
                label={MEVO_MULTI}
                onClick={() => updateMevoType(MEVO_MULTI)}
              />
            </Box>
          </Box>
        )}
        {/* special case for GoPro */}
        {status === CaptureStatus.Ready &&
          capture.equipCamera === GOPRO &&
          (isMobile.any() || appHost.isHosted) && (
            <Box gap="8px">
              <GoProRTMPInfo
                capture={capture}
                goProOption={GEO_US_TX} // TODO: should probably not just default to TX. Maybe show GoProSelectServer?
                onCopied={() => {
                  setGoProRTMPCopied(true);
                }}
              />
              <Box gap="4px">
                {!goProRTMPCopied && (
                  <Text size="small" weight="bold">
                    Copy the RTMP URL before opening GoPro Quik
                  </Text>
                )}
                <Button
                  primary
                  label="Open GoPro Quik"
                  disabled={!goProRTMPCopied}
                  onClick={() => handleGoPro()}
                />
              </Box>
            </Box>
          )}
        {/* buttons */}
        {status === CaptureStatus.Disabled && (
          <Button
            label="Enable Camera"
            primary
            onClick={() => {
              enableCamera();
            }}
          />
        )}
        {status === CaptureStatus.OtherTeam && (
          <Button
            label={`Enable ${capture.captureName}`}
            primary
            onClick={() => {
              enableCamera();
            }}
          />
        )}
        {(status === CaptureStatus.TeamLive ||
          status === CaptureStatus.Live) && (
          <Button
            label={'Go Back'}
            primary
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          />
        )}
        {status !== CaptureStatus.TeamLive && status !== CaptureStatus.Live && (
          <Button
            secondary
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          />
        )}
      </Box>
    </Layer>
  );
};

export default CaptureActionModal;
