import { useContext, useEffect, useState } from 'react';
import { shd } from '@shd/jslib/models';
import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { ViewerEntitlementStatus } from '../../components/Paywall/paywall.types';
import { Purchases } from '@revenuecat/purchases-js';

export const useIsEntitledToViewGame = (game: shd.TeamGame) => {
  const userStore = useContext(UserContext);
  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);

  const [status, setStatus] = useState<ViewerEntitlementStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkViewEntitlement = async () => {
      if (!isMonetizationEnabled) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      // in the future, may need to check sport. currently hook is only used for BBSB games.

      // logged out
      if (!userStore.authUser) {
        setStatus(ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT);
        setLoading(false);
        return;
      }

      // check team manager or coach
      const teamIds = userStore.userTeams
        .map((userTeam) => userTeam._id)
        .concat(userStore.coachTeamIds);

      if (teamIds.includes(game.DNOR_teamId)) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      // check subscriber
      try {
        setLoading(true);

        let userIsEntitled = false;
        const customerInfo =
          await Purchases.getSharedInstance().getCustomerInfo();
        const activeEntitlements = customerInfo.entitlements.active;
        userIsEntitled = activeEntitlements['Pro Access'] !== undefined;

        if (userIsEntitled) {
          setStatus(ViewerEntitlementStatus.ENTITLED);
        } else {
          setStatus(ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
        setStatus(null);
      } finally {
        setLoading(false);
      }
    };

    checkViewEntitlement();
  }, [
    game.DNOR_teamId,
    isMonetizationEnabled,
    userStore.userTeams,
    userStore.coachTeamIds,
    userStore.authUser,
  ]);

  return { status, loading, error };
};
