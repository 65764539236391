// Packages
import React, { useState } from 'react';

// Components
import AdminPlayerSearch from '../../components/AdminPlayerSearch';
import AdminStepButtons from '../../components/AdminStepButtons';

// Helpers and utils
import { useMergePlayers } from '../../admin.hooks';

// Types
import {
  MergePlayersStepOneProps,
  ClaimedPlayerProps,
} from '../../admin.types';

const MergePlayersStepOne: React.FC<MergePlayersStepOneProps> = ({
  onLinkClicked,
  onProgressStep,
}) => {
  const [cardIsSelected, setCardIsSelected] = useState<boolean>(false);

  const [selectedPlayer, setSelectedPlayer] =
    useState<ClaimedPlayerProps>(null);

  const { clearPlayers } = useMergePlayers();

  const handleNextClick = () => {
    if (cardIsSelected && selectedPlayer) {
      onProgressStep(selectedPlayer);
    }
  };

  const handleBackClick = () => {
    clearPlayers();
    setCardIsSelected(false);
    onLinkClicked && onLinkClicked('/admin');
  };

  return (
    <>
      <AdminPlayerSearch
        id="mergePlayersSearch"
        label="Search for player ID"
        placeholder="Search for player ID"
        isCardSelected={(isSelected) => {
          setCardIsSelected(isSelected);
        }}
        onCardSelected={(payload) => {
          setSelectedPlayer(payload);
        }}
      />
      <AdminStepButtons
        isFixed={true}
        backLabel="Cancel"
        isNextActive={cardIsSelected}
        nextLabel="Next Step"
        onBack={() => {
          handleBackClick();
        }}
        onNext={() => {
          handleNextClick();
        }}
      />
    </>
  );
};

export default MergePlayersStepOne;
