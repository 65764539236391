import { Box, Image, ResponsiveContext, Skeleton } from 'grommet';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../services/Session';
import TeamInfoForm from './TeamInfoForm';
import TeamActionButtons from './TeamActionButtons';
import { ImageCategory } from './teamInformation.types';

import { useHistory } from 'react-router-dom';

interface TeamInformationProps {}

const TeamInformation: React.FC<TeamInformationProps> = () => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);
  const history = useHistory();

  const [teamLogoUploading, setTeamLogoUploading] = useState(false);
  let teamLogoSize = '120px';
  let marginTop = -24;

  let teamLogoPaddingHorizontal = '0px';
  if (screenSize === 'small') {
    teamLogoSize = '80px';
    marginTop = -40;
    teamLogoPaddingHorizontal = 'medium';
  }

  const onUploadImage = (category: string, url: string) => {
    if (userStore.selectedTeam && category === ImageCategory.LOGO) {
      userStore.setSelectedTeam({
        ...userStore.selectedTeam,
        image: {
          urlT150: url,
        },
      });
    }

    if (userStore.selectedTeam && category === ImageCategory.COVER) {
      userStore.setSelectedTeam({
        ...userStore.selectedTeam,
        coverImage: {
          urlOrig: url,
        },
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormUpdate = (updatedData: any) => {
    const handleChanged = Object.hasOwn(updatedData, 'nameHandle');
    userStore.setSelectedTeam({
      ...userStore.selectedTeam,
      ...updatedData,
    });
    if (handleChanged) {
      history.replace(`/${updatedData.nameHandle}` || '/team_settings');
    } else {
      history.replace(
        `/${userStore.selectedTeam?.nameHandle}` || '/team_settings'
      );
    }
  };

  return (
    <Box
      style={{ position: 'relative' }}
      margin={{ horizontal: screenSize === 'medium' ? 'medium' : '0px' }}
      flex
    >
      <Box
        round={screenSize === 'small' ? false : '8px'}
        overflow={'hidden'}
        width={'100%'}
      >
        <Image
          fit="cover"
          style={{ height: '200px', width: '100%' }}
          src={
            userStore.selectedTeam?.coverImage?.urlOrig ||
            '/cover_placeholder.png'
          }
        />
      </Box>
      <Box
        fill="horizontal"
        direction="row"
        align="end"
        gap="medium"
        margin={{ top: `${marginTop}px` }}
        pad={{ horizontal: teamLogoPaddingHorizontal }}
      >
        <Box
          round="full"
          border={{
            side: 'all',
            color: 'white',
            size: '2px',
          }}
          height={teamLogoSize}
          width={teamLogoSize}
          overflow={'hidden'}
          flex={{ shrink: 0 }}
          background={'light-1'}
        >
          {teamLogoUploading ? (
            <Skeleton round="full" height={teamLogoSize} width={teamLogoSize} />
          ) : (
            <Image
              fit="contain"
              src={
                userStore.selectedTeam?.image?.urlT150 || 'image_default.jpg'
              }
            />
          )}
        </Box>
        <TeamActionButtons
          teamId={userStore.selectedTeam?._id}
          onUploadImage={onUploadImage}
          setTeamLogoUploading={setTeamLogoUploading}
        />
      </Box>
      <TeamInfoForm
        selectedTeam={userStore.selectedTeam}
        onFormUpdate={onFormUpdate}
      />
    </Box>
  );
};

export default TeamInformation;
