// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { compose } from 'recompose';
import withLayout from '../helpers/withLayout';
import SHDButton from './SHD/Button';

const Crash = () => {
  const retry = React.useCallback(() => {
    window.location.href = '/';
  }, []);
  return (
    <Box align="center" justify="center" margin="medium">
      <Heading level={3} textAlign="center" color="text-xweak">
        We&apos;re sorry — something has gone wrong.☹️
      </Heading>
      <Text as="p" pad="large">
        Our team has been notified. We will do our best to resolve the issue as
        soon as possible.{' '}
      </Text>
      <SHDButton
        primary
        label="Reset"
        mixtrack={['Crash Retry']}
        onClick={retry}
      />
    </Box>
  );
};

export default compose(withLayout)(Crash);
