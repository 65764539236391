import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, ResponsiveContext, Text } from 'grommet';
import { Clipboard, Video } from 'grommet-icons';
import GoLiveWarningModal, {
  GoLiveWarningErrorType,
} from '../TeamPage/GoLiveWarningModal';
import { dtos, shd } from '@shd/jslib/models';
import SHDLink from '../../helpers/SHDLink';
import axios from '../../helpers/axios';
import GoLiveFlow, { CaptureStatus } from './GoLiveFlow';
import { Capture } from '@shd/jslib/models/generated/docStorage/model/capture';
import { TeamCapture } from '@shd/jslib/models/shd';
import { strogging } from '@shd/jslib/infra';
import { SIDELINE_APP_URLS } from '../../constants/strings';
import CaptureActionModal from './CaptureActionModal';

import { AppHostContext } from '../../services/appHost';
import { analytics } from '../../services/analytics';

interface Props {
  team: shd.Team;
  teamPlayers?: shd.TeamPlayer[];
}
const GoLiveBar: React.FC<Props> = ({ team, teamPlayers }) => {
  const screenSize = useContext(ResponsiveContext);
  const appHostContext = React.useContext(AppHostContext);
  const [showGoLiveFlow, setShowGoLiveFlow] = React.useState(false);
  const [showGoLiveWarningModal, setShowGoLiveWarningModal] = useState(false);
  const [hasRoster, setHasRoster] = useState(false);
  const [hasDestinations, setHasDestinations] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [captures, setCaptures] = useState<TeamCapture[]>([]);
  const [errorType, setErrorType] = useState<GoLiveWarningErrorType>();

  const [showCaptureActionModal, setShowCaptureActionModal] = useState(false);
  const [selectedCapture, setSelectedCapture] = useState<Capture>();

  const onClose = () => {
    setShowGoLiveWarningModal(false);
  };

  const getTeamData = useCallback(async () => {
    setLoading(true);

    axios
      .get<dtos.TeamCaptureGetResponse>(`api/team/${team._id}/capture`)
      .then((capturesRes) => {
        const captureList = capturesRes.data.captureList;
        setCaptures(captureList);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });

    try {
      const [fbRes, ytRes] = await Promise.all([
        axios
          .get('/api/facebook_auth', { params: { teamId: team._id } })
          .catch((e) => {
            strogging.log('facebook_auth error', e);
            return { data: null, error: e };
          }),
        axios
          .get('/api/youtube_auth', { params: { teamId: team._id } })
          .catch((e) => {
            strogging.log('youtube_auth error', e);
            return { data: null, error: e };
          }),
      ]);

      const streamWithoutRoster =
        localStorage.getItem(`${team._id}_streamWithoutRoster`) === 'true';
      if (teamPlayers) {
        setHasRoster(
          teamPlayers.filter((p) => p.isActive).length > 0 ||
            streamWithoutRoster
        );
      } else {
        const res = await axios.get(`api/team/${team._id}/players_details`);
        setHasRoster(
          res.data.teamPlayers.filter((p: shd.TeamPlayer) => p.isActive)
            .length > 0 || streamWithoutRoster
        );
      }

      const streamWithoutDestination =
        localStorage.getItem(`${team._id}_streamWithoutDestination`) === 'true';
      setHasDestinations(
        !!(fbRes.data || ytRes.data) || streamWithoutDestination
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [team._id, teamPlayers]);

  useEffect(() => {
    getTeamData();
  }, [team._id, teamPlayers, getTeamData]);

  useEffect(() => {
    if (!hasRoster) {
      setErrorType(GoLiveWarningErrorType.NO_ROSTER);
    } else if (!hasDestinations) {
      setErrorType(GoLiveWarningErrorType.NO_DESTINATION);
    } else {
      setErrorType(undefined);
    }
  }, [hasRoster, hasDestinations]);

  const getStatus = (capture: Capture) => {
    if (capture.targetTeamId === 'disabled') {
      return CaptureStatus.Disabled;
    } else if (capture.targetTeamId !== team._id) {
      return CaptureStatus.OtherTeam;
    } else if (capture.state_isLive) {
      return CaptureStatus.Live;
    }
    return CaptureStatus.Ready;
  };

  const goLiveAppClicked = async () => {
    if (appHostContext.isHosted) {
      appHostContext.service.notifications.appGoLive(team._id);
    } else {
      strogging.log('goLiveAppClicked');
      const result = await axios.post('/api/remote_login/preauthorize', {});
      window.location.href = `${SIDELINE_APP_URLS.team}/${team._id}?statusKey=${result.data.statusKey}`;
      strogging.log('goLiveAppClicked-after');
    }
  };

  return (
    <Box
      direction="row"
      gap="small"
      pad="medium"
      width={screenSize === 'small' ? '100%' : { max: '376px' }}
      alignSelf="center"
      fill
    >
      <Box flex height={'48px'}>
        <Button
          primary
          label="Go Live"
          icon={<Video />}
          onClick={() => {
            analytics.track('Go Live Button Clicked', {
              teamId: team._id,
              hasRoster: hasRoster,
              hasDestinations: hasDestinations,
              errorType: errorType,
            });
            if (errorType) {
              setShowGoLiveWarningModal(true);
            } else {
              setShowGoLiveFlow(true);
            }
          }}
          style={{
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
          }}
          fill
          reverse
          disabled={loading}
        />
      </Box>
      <Box flex height={'48px'}>
        <SHDLink style={{ height: '100%' }} to={'/scoring'}>
          <Button
            primary
            label="Score Game"
            icon={<Clipboard />}
            style={{
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
            }}
            fill
            reverse
          />
        </SHDLink>
      </Box>
      {error && <Text>{error}</Text>}
      {showGoLiveWarningModal && errorType && (
        <GoLiveWarningModal
          team={team}
          errorType={errorType}
          onClose={onClose}
          onBypassWarning={() => {
            setShowGoLiveFlow(true);
          }}
        />
      )}
      {showGoLiveFlow && (
        <GoLiveFlow
          team={team}
          captures={captures}
          onClose={() => {
            setShowGoLiveFlow(false);

            analytics.track('Go Live Modal Closed', {
              teamId: team._id,
              hasRoster: hasRoster,
              hasDestinations: hasDestinations,
            });
          }}
          onCaptureSelected={(capture) => {
            setShowGoLiveFlow(false);
            if (capture._id === 'sidelinehd-app') {
              goLiveAppClicked();
            } else {
              setTimeout(() => {
                setSelectedCapture(capture);
                setShowCaptureActionModal(true);
              }, 10);
            }

            const eventName = capture.isOwnedByActiveUser
              ? 'Your Camera Clicked'
              : 'Team Manager Camera Clicked';
            analytics.track(eventName, {
              teamId: team._id,
              captureName: capture.captureName,
              captureId: capture._id,
              equipCamera: capture.equipCamera,
            });
          }}
        />
      )}
      {showCaptureActionModal && selectedCapture && (
        <CaptureActionModal
          status={getStatus(selectedCapture)}
          team={team}
          capture={selectedCapture}
          onClose={() => {
            setShowCaptureActionModal(false);
            setTimeout(() => {
              setShowGoLiveFlow(true);
            }, 10);
          }}
          onEnabledCapture={(enabledCapture) => {
            // we always "force point" the capture, so we disable any other captures
            const newCaptures = captures.map((c) => {
              if (c._id === enabledCapture._id) {
                return enabledCapture;
              }
              if (c.targetTeamId === team._id) {
                return {
                  ...c,
                  targetTeamId: 'disabled',
                };
              }
              return c;
            });
            setCaptures(newCaptures);
          }}
        />
      )}
    </Box>
  );
};

export default GoLiveBar;
