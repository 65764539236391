// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_STATUS, STATUSES } from '../useWebRTCStream/constants';
import { strogging } from '@shd/jslib/infra';
import { PlayerButton } from '../../styles';

export default function StreamControlButton({
  status,
  onStart,
  onStop,
  onError,
  isDisabled,
}) {
  const button = useMemo(() => {
    const variants = {
      [STATUSES.connecting]: {
        color: 'info',
        label: 'Connecting...',
        action: null,
      },
      [STATUSES.reconnecting]: {
        color: 'info',
        label: 'Reconnecting...',
        action: null,
      },
      [STATUSES.disconnected]: {
        color: 'primary',
        label: 'Go live',
        action: onStart,
      },
      [STATUSES.disconnecting]: {
        color: 'info',
        label: 'Disconnecting...',
        action: null,
      },
      [STATUSES.error]: {
        color: 'danger',
        label: 'Reconnect',
        action: onError,
      },
      [STATUSES.connected]: {
        color: 'danger',
        label: 'Disconnect',
        action: onStop,
      },
    };
    return variants[status] ?? variants[DEFAULT_STATUS];
  }, [onStart, onStop, onError, status]);

  const isDisabledButton = isDisabled && status !== STATUSES.error;

  return (
    <PlayerButton
      onClick={(ev) => {
        strogging.log('StreamControlButton', { label: button.label });
        button.action(ev);
      }}
      color={button.color}
      disabled={isDisabledButton}
    >
      {button.label}
    </PlayerButton>
  );
}

StreamControlButton.propTypes = {
  isDisabled: PropTypes.bool,
  status: PropTypes.string,
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  onError: PropTypes.func,
};

StreamControlButton.defaultProps = {
  isDisabled: false,
  status: DEFAULT_STATUS,
  onStart: null,
  onStop: null,
  onError: null,
};
