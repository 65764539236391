import { Anchor, Box, Heading, Layer, Paragraph } from 'grommet';
import React from 'react';
import SHDButton from '../components/SHD/Button';

interface Props {
  onAcceptTos: () => void;
}

const TermsOfServiceLayer: React.FC<Props> = ({ onAcceptTos }) => (
  <Layer margin="large" style={{ overflow: 'auto' }}>
    <Box pad="large" gap="medium">
      <Heading level={3}>Welcome to sidelineHD BETA!</Heading>
      <Box margin={{ left: 'large' }}>
        <Paragraph fill>
          <b>1. We get better with every game. </b>
          Like any livestreaming setup, we may experience outages or
          lost footage. Always record to a local SD card as backup.
        </Paragraph>
        <Paragraph fill>
          <b>2. We take privacy seriously. </b>
          As a livestreamer or team manager, you confirm that you have
          permission from all participants and own the videos you are
          livestreaming. Our service uses your livestreams and game data,
          transfers team information / videos / roster to external sites of your
          choice (like YouTube and Facebook) and publishes clips / videos /
          compilations to sidelineHD and Diamond Kinetics sites. Our settings 
          allow you to restrict or block publishing of player names.
        </Paragraph>
        <Paragraph fill>
          <b>3. Help us change how America watches youth sports! </b>
          As a BETA user, please confirm that you are OK with us using portions
          of your videos for promotional purposes.
        </Paragraph>
        <Anchor
          size="small"
          label="Privacy policy"
          href="https://sidelinehd.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        />
        <Anchor
          size="small"
          label="Terms of Service"
          href="https://sidelinehd.com/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
        />
        <Anchor
          size="small"
          label="End user license agreement"
          href="https://sidelinehd.com/eula"
          target="_blank"
          rel="noopener noreferrer"
        />
        <Anchor
          margin={{ vertical: 'medium' }}
          size="small"
          label="No thanks"
          href="https://home.sidelinehd.com/"
        />
      </Box>
      <Box margin="medium" alignSelf="end" height={{ min: 'small' }}>
        <Box>
          <SHDButton
            size="large"
            primary
            label="Yes, let's do this!"
            onClick={onAcceptTos}
            margin="auto 0"
            alignSelf="center"
          />
        </Box>
      </Box>
    </Box>
  </Layer>
);

export default TermsOfServiceLayer;
