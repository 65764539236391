import React, { useState } from 'react';
import styles from '../Admin.module.css';
import { clipLabels } from '../../../constants/clips';
import { toTitleCase } from '../../../helpers/utils';

// Components
import { Box, FormField, Heading, Layer, Select, Text } from 'grommet';
import AdminStepButtons from './AdminStepButtons';

// Types
import {
  AdminNewClipLabelModalProps,
  RelabelClipsOptions,
} from '../admin.types';

const AdminNewClipLabelModal: React.FC<AdminNewClipLabelModalProps> = ({
  sport,
  oldLabel,
  onCloseModal,
  onChangeClipLabel,
}) => {
  // States
  const [selectedLabel, setSelectedLabel] = useState<RelabelClipsOptions>({
    label: '',
    value: '',
  });

  // Methods
  const populateSelectOptions = (clipSport: string) => {
    const selectOptions: Array<RelabelClipsOptions> = [];

    clipLabels[clipSport].labels.forEach((label) => {
      selectOptions.push({ label: toTitleCase(label), value: label });
    });

    return selectOptions;
  };

  // Handlers
  const handleNextClick = () => {
    onChangeClipLabel(selectedLabel);
  };

  return (
    <Layer
      className={styles['admin-modal-secondary-id']}
      onEsc={() => onCloseModal()}
      onClickOutside={() => onCloseModal()}
    >
      <Heading level={2} className={styles['modal-title']}>
        Change Label
      </Heading>
      <Box className={styles['modal-content']}>
        <Box>
          <Heading
            color={'#212121'}
            level={3}
            size="0.625rem"
            weight={600}
            margin={{ bottom: '0.5rem', top: '0' }}
          >
            Current Label
          </Heading>
          <Text size="1rem" className={styles['modal-label-value']}>
            {toTitleCase(oldLabel)}
          </Text>
        </Box>

        <FormField label="New Label" margin={{ top: '1rem' }}>
          <Select
            options={populateSelectOptions(sport)}
            value={selectedLabel}
            onChange={({ option }) => {
              setSelectedLabel(option);
            }}
          />
        </FormField>
      </Box>
      <Box className={styles['modal-buttons']}>
        <AdminStepButtons
          isFixed={false}
          backLabel="Cancel"
          isNextActive={selectedLabel.value !== ''}
          nextLabel="Change"
          onBack={() => {
            onCloseModal();
          }}
          onNext={() => {
            handleNextClick();
          }}
        />
      </Box>
    </Layer>
  );
};

export default AdminNewClipLabelModal;
