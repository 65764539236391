// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useContext } from 'react';
import {
  Box,
  Text,
  Button,
  Image,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  RadioButtonGroup,
  ResponsiveContext,
} from 'grommet';
import { AppleAppStore, Close, GooglePlay } from 'grommet-icons';
import Toast from '../../components/Toast';
import CopiableText from '../../helpers/CopiableText';
import { ANDROID, IPHONE } from '../../constants/strings';
import { isMobile, isiOS } from '../../helpers/browserDetect';

const SmartphoneSetup = ({ rtmp, onClose = () => {} }) => {
  const screenSize = useContext(ResponsiveContext);

  const [phoneType, setPhoneType] = useState(isiOS() ? IPHONE : ANDROID);

  const [copied, setCopied] = useState(false);
  const [step, setStep] = useState(0);

  const stepOne = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text>Download the Larix Broadcaster mobile app</Text>
      <Button
        secondary
        color="dark-4"
        size="large"
        label="Go to the iOS App Store"
        onClick={() => {
          window.open(
            'https://apps.apple.com/us/app/larix-broadcaster/id1042474385',
            '_blank'
          );
        }}
        icon={<AppleAppStore color="plain" />}
      />
      <Button
        secondary
        color="dark-4"
        size="large"
        label="Go to the Google Play Store"
        onClick={() => {
          window.open(
            'https://play.google.com/store/apps/details?id=com.wmspanel.larix_broadcaster&hl=en_US&gl=US',
            '_blank'
          );
        }}
        icon={<GooglePlay color="plain" />}
      />
      <Text>Open the app. Allow access to your Camera and Microphone.</Text>
    </Box>
  );

  const iPhoneDirections = (
    <Box gap="small">
      <Text>
        Make sure you are on your streaming device. Click Set up iPhone.
      </Text>
      <Text>Press OK when asked if you want to import settings.</Text>
      <Box>
        <Image fit="contain" src="import_settings.png" />
      </Box>
      {screenSize === 'small' ? (
        <Box gap="small">
          <Box>
            <Button
              fill
              primary
              size="large"
              label="Set up Phone"
              onClick={() => {
                window.open(rtmp.larixUrl, '_blank');
              }}
            />
          </Box>
          <Text>
            Alternatively, you can manually copy the Larix URL below:{' '}
          </Text>
          <CopiableText
            label="Larix URL"
            value={rtmp.larixUrl}
            onCopy={() => setCopied(true)}
          />
        </Box>
      ) : (
        <CopiableText
          label="Or, open this link on your phone."
          value={rtmp.larixUrl}
          onCopy={() => setCopied(true)}
        />
      )}
    </Box>
  );

  const androidDirections = (
    <Box gap="small">
      <Text>
        After clicking Set up iPhone, you will be taken to the Larix Broadcaster
        app.
      </Text>
      <Text>Press OK when asked if you want to import settings.</Text>
      <Box>
        <Image fit="contain" src="import_settings.png" />
      </Box>
      {screenSize === 'small' ? (
        <Box gap="small">
          <Box>
            <Button
              fill
              primary
              size="large"
              label="Set up Phone"
              onClick={() => {
                window.open(rtmp.larixUrl, '_blank');
              }}
            />
          </Box>
          <Text>
            Alternatively, you can manually copy the Larix URL below:{' '}
          </Text>
          <CopiableText
            label="Larix URL"
            value={rtmp.larixUrl}
            onCopy={() => setCopied(true)}
          />
        </Box>
      ) : (
        <CopiableText
          label="Or, open this link on your iPhone."
          value={rtmp.larixUrl}
          onCopy={() => setCopied(true)}
        />
      )}
    </Box>
  );

  const stepTwo = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <RadioButtonGroup
        name="radio"
        direction="row"
        gap="none"
        options={[IPHONE, ANDROID]}
        value={phoneType}
        onChange={(event) => setPhoneType(event.target.value)}
        alignSelf="center"
      >
        {(option, { checked, hover }) => {
          let background;
          if (checked) {
            background = 'brand';
          } else if (hover) {
            background = 'light-4';
          } else {
            background = 'light-2';
          }
          return (
            <Box
              background={background}
              pad="small"
              width={{ min: 'small' }}
              justify="center"
              align="center"
            >
              <Text textAlign="center">{option}</Text>
            </Box>
          );
        }}
      </RadioButtonGroup>
      {phoneType === IPHONE && iPhoneDirections}
      {phoneType === ANDROID && androidDirections}
    </Box>
  );

  const renderStep = () => {
    let buttonText = '';
    let buttonAction = () => {};
    if (step === 0) {
      buttonText = 'Next';
      buttonAction = () => {
        setStep(1);
      };
    }

    return (
      <Card
        round={false}
        elevation="none"
        fill
        margin={{ bottom: isMobile.any() ? '64px' : 'none' }}
      >
        <CardHeader
          direction="row"
          pad="small"
          align="center"
          justify="between"
        >
          <Box width="24px" />
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              Pair your phone camera
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} justify="end" />
        </CardHeader>
        <CardBody fill>
          {step === 0 && stepOne}
          {step === 1 && stepTwo}
        </CardBody>
        {buttonText && (
          <CardFooter
            pad={{ horizontal: 'small', vertical: 'large' }}
            background="light-2"
          >
            <Button
              fill
              primary
              size="large"
              label={buttonText}
              onClick={buttonAction}
            />
          </CardFooter>
        )}
      </Card>
    );
  };

  return (
    <Box fill>
      {renderStep()}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};

export default SmartphoneSetup;
