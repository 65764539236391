// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box } from 'grommet';

import { PromptNote, PromptText, TextContainer } from '../styles';
import { PhoneInputStyle } from './style';
import PhoneNumberInput from '../../../../helpers/PhoneNumberInput';
import SuccessIcon from '../SuccessIcon';

const NoPhoneNumber = ({ enteredPhone, setEnteredPhone, setSelectedPhone }) => (
  <Box gap="medium">
    <TextContainer>
      <PromptText color="dark-1">
        Receive highlights by text message when they play in a sidelineHD stream.
      </PromptText>
      <PromptNote color="dark-4">Free: message rates are on us!</PromptNote>
    </TextContainer>
    <PhoneNumberInput
      successIcon={<SuccessIcon />}
      style={PhoneInputStyle}
      value={enteredPhone}
      id="phone-input"
      placeholder="(000)-000-0000"
      onValid={(number) => {
        if (number) {
          setEnteredPhone(number);
        } else {
          setSelectedPhone(null);
        }
      }}
      onError={() => {
        setEnteredPhone(null);
      }}
    />
  </Box>
);

export default NoPhoneNumber;
