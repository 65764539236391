import { grommet } from '@shd/jslib/models';
import { BoxSideType } from 'grommet/utils';

// Returns the number of columns for a card grid based on the screen size.
export const setCardColumns = (
  screenSize: grommet.GrommetScreenSize
): string => {
  return screenSize === 'medium'
    ? '1/2'
    : screenSize === 'large'
      ? '1/3'
      : '100%';
};

export const getBackgroundColor = (
  isMobile: boolean = false,
  isSelected: boolean = false
): string => {
  return isMobile || isSelected ? 'white' : 'light-5';
};

export const getBorder = (
  isMobile: boolean = false,
  isSelected: boolean = false
): { color: string; side: BoxSideType } => {
  return isMobile
    ? { color: 'light-1', side: 'bottom' }
    : isSelected
      ? { color: 'light-1', side: 'all' }
      : { color: 'light-5', side: 'all' };
};
