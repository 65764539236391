// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';

import SetUpScreen from './SetUpScreen';
import SetLinkScreen from './SetLinkScreen';

const StreamingCard = ({
  facebookLinked,
  youtubeLinked,
  userStore,
  setFacebookLinked,
  setYoutubeLinked,
  nextStep,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const goNext = () => setCurrentStep((prevState) => prevState + 1);

  const goPrev = () => setCurrentStep((prevState) => prevState - 1);

  if (currentStep === 0) {
    return <SetUpScreen nextStep={goNext} />;
  }

  if (currentStep === 1) {
    return (
      <SetLinkScreen
        facebookLinked={facebookLinked}
        youtubeLinked={youtubeLinked}
        userStore={userStore}
        setFacebookLinked={setFacebookLinked}
        setYoutubeLinked={setYoutubeLinked}
        nextStep={nextStep}
        prevStep={goPrev}
      />
    );
  }

  return <SetUpScreen />;
};

export default StreamingCard;
