// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Box, TextInput } from 'grommet';
import { StatusGood } from 'grommet-icons';

const CustomInput = React.forwardRef((props, ref) => (
  <TextInput ref={ref} plain {...props} />
));

const PhoneNumberInput = ({
  value,
  onValid,
  onError,
  placeholder,
  plain = false,
  successIcon = null,
  style = {},
  ...rest
}) => {
  const [phoneNumberValue, setPhoneNumberValue] = useState(value);
  const [showCheck, setShowCheck] = useState(false);

  useEffect(() => {
    setPhoneNumberValue(value);
  }, [value, rest.disabled]);

  const onChange = (val = '') => {
    setPhoneNumberValue(val);

    if (val.slice(2).length >= 10) {
      if (isPossiblePhoneNumber(val) && isValidPhoneNumber(val)) {
        onValid(val);
        setShowCheck(true);
      } else {
        onError(true);
        setShowCheck(false);
      }
    } else {
      onValid(null);
      onError(null);
      setShowCheck(false);
    }
  };

  let inputComponentProps = {};
  if (plain) {
    inputComponentProps = { inputComponent: CustomInput };
  }

  return (
    <Box direction="row" align="center" gap="xsmall" {...rest}>
      <PhoneInput
        style={{
          border: '1px solid #CCCCCC',
          backgroundColor: '#f2f2f5',
          padding: '8px',
          color: '#333333',
          width: '100%',
          ...style,
        }}
        country="US"
        placeholder={placeholder}
        value={phoneNumberValue}
        key="phone-input"
        onChange={onChange}
        {...inputComponentProps}
      />
      {showCheck && (successIcon ?? <StatusGood color="status-ok" />)}
    </Box>
  );
};

export default PhoneNumberInput;
