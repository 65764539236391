// Packages
import { toTitleCase } from '../../../../helpers/utils';
import React, { useState } from 'react';
import styles from '../../Admin.module.css';

// Components
import { Box, Button, Card, Heading, Text } from 'grommet';
import AdminClipCard from '../../components/AdminClipCard';
import AdminNewClipLabelModal from '../../components/AdminNewClipLabelModal';
import AdminStepButtons from '../../components/AdminStepButtons';

// Types
import {
  RelabelClipsStepTwoProps,
  RelabelClipsOptions,
} from '../../admin.types';

const RelabelClipsStepTwo: React.FC<RelabelClipsStepTwoProps> = ({
  clip,
  oldLabel,
  onRelabelClip,
  onBack,
}) => {
  // States
  const [newLabel, setNewLabel] = useState<string>(oldLabel);
  const [showModal, setShowModal] = useState<boolean>(false);

  // Handlers
  const handleChangeClicked = () => {
    setShowModal(true);
  };

  const handleIsNextActive = () => {
    return newLabel !== oldLabel;
  };

  const handleBackClick = () => {
    onBack();
  };

  const handleNextClick = () => {
    onRelabelClip(newLabel);
  };

  return (
    <>
      <AdminClipCard payload={clip} />
      <Card
        className={`
            ${styles['admin-player-card']}
            ${styles['admin-player-card--no-hover']}
          `}
        pad="medium"
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          className={styles['player-info']}
        >
          <Box>
            <Heading
              color={'#212121'}
              level={3}
              size="0.625rem"
              weight={600}
              margin={{ bottom: '0.5rem', top: '0' }}
            >
              Label
            </Heading>
            <Text size="1rem" weight={600}>
              {toTitleCase(newLabel)}
            </Text>
          </Box>
          <Button
            className={styles['change-button']}
            label="Change"
            onClick={() => handleChangeClicked()}
          />
        </Box>
      </Card>
      <AdminStepButtons
        isFixed={true}
        backLabel="Cancel"
        isNextActive={handleIsNextActive()}
        nextLabel="Relabel"
        onBack={() => {
          handleBackClick();
        }}
        onNext={() => {
          handleNextClick();
        }}
      />

      {showModal && (
        <AdminNewClipLabelModal
          oldLabel={oldLabel}
          sport={clip?.game.sportType || 'bbsb'}
          onCloseModal={() => setShowModal(false)}
          onChangeClipLabel={(selectedLabel: RelabelClipsOptions) => {
            setNewLabel(selectedLabel.value);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default RelabelClipsStepTwo;
