// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useRef } from 'react';

export function useDebounce(callback: unknown, delay: number) {
  const timer = useRef();

  const debouncedCallback = useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
}

export const useDebouncedCallback = (
  callback: (arg: string) => void,
  delay: number,
  opts: _.DebounceSettings
) => {
  const callbackRef = useRef<(arg: string) => void>();
  callbackRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    _.debounce(
      (text) => {
        if (callbackRef.current) {
          callbackRef.current(text);
        }
      },
      delay,
      opts
    ),
    []
  );
};
