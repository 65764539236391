// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import { Anchor, Box } from 'grommet';

export const HeaderWrapper = styled(Box)`
  margin: 16px 0 28px;
`;

export const BackButton = styled(Anchor)`
  textDecoration: 'none';
  fontSize: 14px;
  font-weight: 700;
  line-height: 14px;
  vertical-align: center;
`;
