// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from 'grommet';

import {
  CardButton, CardStyled, PlayerName, TeamName,
} from './styles';

const PlayerCard = ({ player }) => {
  const history = useHistory();
  const claimPlayer = (playerId) => {
    // claim player
    // auto-open the claim modal
    // if phone number is matched, auto-send the text
    history.push(`/${playerId}?open_claim_modal=1&from_player_list=1`);
  };

  return (
    <CardStyled background="secondary-7">
      <Box flex direction="row">
        <Box flex direction="column">
          <PlayerName color="dark-1">{player.name}</PlayerName>
          <TeamName>{player.teamName}</TeamName>
        </Box>
        <CardButton color="secondary-1" label="Claim" onClick={() => claimPlayer(player.id)} />
      </Box>
    </CardStyled>
  );
};

export default PlayerCard;
