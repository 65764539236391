// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

export default function ShuffleIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="shuffle">
        <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0 5.25C0 5.05109 0.0790176 4.86032 0.21967 4.71967C0.360322 4.57902 0.551088 4.5 0.75 4.5H1.5C4.803 4.5 7.2405 6.36 8.811 8.127C9.546 8.955 10.1085 9.78 10.5 10.425C10.89 9.78 11.454 8.955 12.189 8.127C13.7595 6.36 16.197 4.5 19.5 4.5V6C16.803 6 14.7405 7.515 13.311 9.123C12.538 9.9985 11.8742 10.9646 11.334 12C11.8737 13.0354 12.5371 14.0014 13.3095 14.877C14.742 16.485 16.806 18 19.5 18V19.5C16.197 19.5 13.7595 17.64 12.189 15.873C11.5583 15.1592 10.993 14.3901 10.5 13.575C10.11 14.22 9.546 15.045 8.811 15.873C7.2405 17.64 4.803 19.5 1.5 19.5H0.75C0.551088 19.5 0.360322 19.421 0.21967 19.2803C0.0790176 19.1397 0 18.9489 0 18.75C0 18.5511 0.0790176 18.3603 0.21967 18.2197C0.360322 18.079 0.551088 18 0.75 18H1.5C4.197 18 6.2595 16.485 7.689 14.877C8.46195 14.0015 9.1258 13.0354 9.666 12C9.12627 10.9646 8.46292 9.99858 7.6905 9.123C6.258 7.515 4.194 6 1.5 6H0.75C0.551088 6 0.360322 5.92098 0.21967 5.78033C0.0790176 5.63968 0 5.44891 0 5.25Z" />
        <path id="Vector_2" d="M19.5 8.19904V2.30104C19.5 2.22978 19.5204 2.16001 19.5586 2.09989C19.5969 2.03977 19.6514 1.99179 19.716 1.96157C19.7805 1.93135 19.8523 1.92014 19.923 1.92926C19.9936 1.93837 20.0602 1.96743 20.115 2.01304L23.655 4.96204C23.835 5.11204 23.835 5.38804 23.655 5.53804L20.115 8.48704C20.0602 8.53264 19.9936 8.5617 19.923 8.57082C19.8523 8.57993 19.7805 8.56872 19.716 8.5385C19.6514 8.50829 19.5969 8.46031 19.5586 8.40019C19.5204 8.34007 19.5 8.27029 19.5 8.19904ZM19.5 21.699V15.801C19.5 15.7298 19.5204 15.66 19.5586 15.5999C19.5969 15.5398 19.6514 15.4918 19.716 15.4616C19.7805 15.4314 19.8523 15.4201 19.923 15.4293C19.9936 15.4384 20.0602 15.4674 20.115 15.513L23.655 18.462C23.835 18.612 23.835 18.888 23.655 19.038L20.115 21.987C20.0602 22.0326 19.9936 22.0617 19.923 22.0708C19.8523 22.0799 19.7805 22.0687 19.716 22.0385C19.6514 22.0083 19.5969 21.9603 19.5586 21.9002C19.5204 21.8401 19.5 21.7703 19.5 21.699Z" />
      </g>
    </svg>
  );
}
