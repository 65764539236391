import React, { useState } from 'react';
import { Text, Box, Anchor, Layer, Button } from 'grommet';
import { Add, LinkPrevious, Subtract } from 'grommet-icons';
import axios from '../../helpers/axios';
import { TeamCapture } from '@shd/jslib/models/shd';
import CopiableText from '../../helpers/CopiableText';
import Toast from '../../components/Toast';
import { isMobile } from '../../helpers/browserDetect';

interface Props {
  onSaved: (capture: TeamCapture) => void;
  onClose: () => void;
  capture: TeamCapture;
}
const AdvancedSettings: React.FC<Props> = ({
  capture: initialCapture,
  onSaved,
  onClose,
}) => {
  const [capture, setCapture] = useState(initialCapture);
  const [error, setError] = useState<string | undefined>();
  const [copied, setCopied] = useState(false);

  const saveOffset = () => {
    axios
      .put(`/api/capture/${capture._id}`, {
        captureInfo: capture,
      })
      .then((response) => {
        setCapture(response.data);
        onSaved(response.data);
      })
      .catch(() => {
        setError('Error adjusting offset');
      });
  };

  const editOffset = (increment = true) => {
    let newOffset;
    if (increment) {
      newOffset = (capture.overlayRenderTimeOffsetMs ?? 0) + 100;
    } else {
      newOffset = (capture.overlayRenderTimeOffsetMs ?? 0) - 100;
    }
    const newCapture = {
      ...capture,
      overlayRenderTimeOffsetMs: newOffset,
    };
    setCapture(newCapture);
  };

  return (
    <Layer
      full={isMobile.any() === true}
      responsive={false}
      style={{ maxHeight: '100svh' }}
      animation={false}
      background={'light-5'}
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Box direction="row" pad="16px" align="center" justify="between">
        <Box alignSelf="start" onClick={onClose}>
          <LinkPrevious size="24px" />
        </Box>
        <Box justify="center" align="center">
          <Text alignSelf="center" size="large" weight="bold">
            Advanced Settings
          </Text>
        </Box>
        <Box width="24px" />
      </Box>
      <Box pad="medium" gap="xsmall">
        <Text weight="bold">RTMP Info</Text>
        <Box gap="medium" width={{ max: 'large' }}>
          <CopiableText
            label={<Text>Name</Text>}
            value={capture?.captureName}
            onCopy={() => setCopied(true)}
          />
          <CopiableText
            label={<Text>Stream URL</Text>}
            value={capture?.rtmp?.streamUrl}
            onCopy={() => setCopied(true)}
          />
          <CopiableText
            label={<Text>Stream Key</Text>}
            value={capture?.rtmp?.rtmpKey}
            onCopy={() => setCopied(true)}
          />
        </Box>
      </Box>
      <Box pad="medium" gap="xsmall">
        {error && <Text color="status-critical">{error}</Text>}
        <Text weight="bold">Overlay time offset</Text>
        <Box direction="row" align="center" gap="xsmall">
          <Button
            plain={false}
            icon={<Subtract size="small" />}
            onClick={() => {
              editOffset(false);
            }}
          />
          <Text weight="bold">
            {`${((capture.overlayRenderTimeOffsetMs ?? 0) / 1000).toFixed(1)}s`}
          </Text>
          <Button
            plain={false}
            icon={<Add size="small" />}
            onClick={() => {
              editOffset();
            }}
          />
        </Box>
        <Box gap="xsmall" margin={{ vertical: 'medium' }}>
          <Text>
            Adjust if scoreboards/velo are consistently too early or too late.
            Most users should use a setting of 0.
          </Text>
          <Text margin={{ left: 'small' }}>
            <b>+</b> Make scoreboard/velo show later
          </Text>
          <Text margin={{ left: 'small' }}>
            <b>-</b> Make scoreboard/velo show earlier
          </Text>
          <Anchor
            label="Read more"
            href="https://sidelinehd.zendesk.com/hc/en-us/articles/23007594728599-Calibrating-your-camera"
          />
        </Box>

        {copied && (
          <Toast
            label="Copied to clipboard!"
            background="status-ok"
            duration={3000}
            onClose={() => {
              setCopied(false);
            }}
          />
        )}
        <Box gap="small">
          <Button primary label="Save" onClick={saveOffset} />
        </Box>
      </Box>
    </Layer>
  );
};

export default AdvancedSettings;
