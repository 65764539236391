import { useEffect, useState, useCallback } from 'react';
import axios from '../../../helpers/axios';
import { shd } from '@shd/jslib/models';
import {
  TaggedPlayer,
  InningDisplay,
  Clips,
  Players,
  outcomeMapping,
} from './playByPlay.types';
import { getPrivacyName } from '../../../helpers/utils';

const usePlayByPlay = (gameId?: string) => {
  const [clips, setClips] = useState();

  const fetchTaggedPlayers = useCallback(
    (
      teamPlayerList: string[],
      teamGameId: string,
      playerRole: string,
      players: { [key: string]: shd.TeamPlayer }
    ) => {
      return teamPlayerList.map((pId: string) => {
        if (pId) {
          const playerName =
            getPrivacyName(
              players[pId].nameFirst,
              players[pId].nameLast,
              players[pId].embedNameFirstLevel,
              players[pId].embedNameLastLevel
            ) || '';
          const jerseyNum = players[pId].jerseyNum || '0';
          const destination = `${pId}#${teamGameId}-${playerRole}`;
          const teamPlayerId = pId;
          return { playerName, jerseyNum, destination, teamPlayerId };
        } else {
          return {
            playerName: 'Unknown',
            jerseyNum: '0',
            destination: 'Unknown',
            teamPlayerId: 'Unknown',
          };
        }
      });
    },
    []
  );

  const resolvePrimaryTaggedPlayer = useCallback(
    (
      player: TaggedPlayer,
      clipRole: string,
      isViewable: boolean,
      clipId: string
    ) => {
      const primarryPlayerString = `${clipRole === 'offense_batter' ? '' : 'P:'} #${
        player.jerseyNum
      } ${player.playerName}`;
      return {
        primaryPlayerString: primarryPlayerString,
        destination: player.destination,
        teamPlayerId: player.teamPlayerId,
        isViewable,
        clipId,
      };
    },
    []
  );

  const formatInningDisplay = useCallback(
    (teamClips: Clips, players: Players) => {
      return Object.entries(teamClips).reduce(
        (acc: InningDisplay, [key, value]) => {
          const plays = (value as shd.Clip[]).map((clip: shd.Clip) => {
            const taggedPlayers: TaggedPlayer[] = fetchTaggedPlayers(
              clip.J_teamPlayerList,
              clip.J_teamGameId,
              clip.playerRole,
              players
            );

            const taggedPrimaryPlayer = resolvePrimaryTaggedPlayer(
              taggedPlayers[0],
              clip.playerRole,
              clip.J_claimedPlayerList.length > 0 ||
                clip.J_teamPlayerList.length === 0,
              clip.id
            );

            const outcome =
              // @ts-expect-error: no idea why this is not working
              outcomeMapping[clip.textDescriptionBrief] ||
              clip.textDescriptionBrief;

            return {
              taggedPrimaryPlayer,
              outcome,
              taggedPlayers,
              link: clip.fileDownloadUrlFull,
              playerRole: clip.playerRole,
              hasDkData: clip.isDkData || false,
            };
          });

          acc[key] = plays;
          return acc;
        },
        {} as InningDisplay
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const fetchClips = async () => {
      axios
        .get(`/api/team_game/${gameId}/clips`)
        .then((result) => {
          setClips(result.data.clips);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
    if (gameId) {
      fetchClips();
    }
  }, [gameId]);

  return {
    clips,
    setClips,
    formatInningDisplay,
  };
};

export default usePlayByPlay;
