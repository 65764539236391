import { shd } from '@shd/jslib/models';

export enum StaffRole {
  Coach = 'coach',
  Manager = 'manager',
}

export interface StaffMember {
  email?: string;
  isLivestreamer?: boolean;
  isPermittedToCreateTeam?: boolean;
  nameFirst?: string;
  nameLast?: string;
  phoneList?: string[];
  roles?: StaffRole[];
  _id?: string;
  pending?: boolean;
  inviteCode?: string;
  updateRoles?: boolean;
  teamId?: string;
}

export interface InviteBody {
  invitedUserPhone: string;
  invitedUserName: string;
  teamId: string;
  teamRole: StaffRole;
}

export enum StaffMode {
  EDIT = 'Edit',
  ADD = 'Add',
}

export interface StaffModalProps {
  mode: StaffMode;
  team: shd.Team;
  staff: StaffMember[];
  selectedStaffMember?: StaffMember | null;
  onClose: () => void;
  onInviteSent: (invteBody: InviteBody) => void;
  onRemoveStaff: (updatedStaffMember: StaffMember) => void;
  onUpdateStaff: (updatedStaffMemeber: StaffMember) => void;
  setToast?: (toast: { label: string; background: string }) => void;
}

export enum StaffLimit {
  Admin = 3,
  Coach = 2,
}

export interface SettingsDeleteAccountProps {
  userId: string | null;
}

export interface SettingsRevenueCatProps {}
