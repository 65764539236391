// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { string } from 'yup';
import { FieldStyled, FieldWrapper, RadioGroupStyled } from './style';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';

const validationScheme = string()
  .required('Field is required');

const FieldTeamType = ({ value, handleForward }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validationScheme.validate(value.teamType).then(() => {
      setIsValid(true);
    }).catch(() => {
      setIsValid(false);
    });
  }, [value]);

  // club / travel team -> age
  // school team -> level
  // rec team -> age, league

  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">What type of team are you streaming for?</FieldLabel>
          <FieldTip color="dark-4">Required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled name="teamType">
          <RadioGroupStyled
            name="teamType"
            placeholder="Select"
            valueKey={{ key: 'value', reduce: true }}
            options={['Travel team', 'School team', 'Rec team']}
          />
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton disabled={!isValid} label="Next" onClick={handleForward} />
    </>
  );
};

export default FieldTeamType;
