// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import useState from 'react-usestateref';

import { withRouter } from 'react-router-dom';
import { Box, Button, DataTable, Grommet, Text } from 'grommet';
import { grommet } from 'grommet/themes';
import { FormNext } from 'grommet-icons';
import { analytics } from '../services/analytics';

const ClickableDataTable = withRouter(({ data, ...props }) => {
  const [selectedId, setSelectedId] = useState(props.selectedId);

  useEffect(() => {
    setSelectedId(props.selectedId);
  }, [props.selectedId]);

  const columns = [
    {
      property: 'date',
      header: <Text>Date</Text>,
      render: (datum) =>
        datum.date &&
        new Date(datum.date).toLocaleDateString('en-US', {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
        }),
    },
    {
      property: 'opponent',
      header: 'Opponent',
    },
    {
      property: 'outcome',
      header: 'Outcome',
    },
    {
      property: 'more',
      header: 'More',
      align: 'start',
      render: (datum) => (
        <Button
          alignSelf="center"
          justify="end"
          plain
          reverse
          gap="xxsmall"
          label={<Text size="xsmall">Full AB</Text>}
          icon={<FormNext size="small" />}
          onClick={(e) => {
            analytics.track('HitScope Full AB Click', {
              clipId: datum.id,
            });
            e.stopPropagation();
            if (props.playerId) {
              window.open(`/${props.playerId}/c/${datum.id}`, '_blank');
            } else {
              window.open(`/${datum.playerId}/c/${datum.id}`, '_blank');
            }
          }}
        />
      ),
    },
  ];

  const rowProps = {};
  rowProps[selectedId] = { background: 'light-5' };

  return (
    <Grommet theme={grommet}>
      <Box align="center">
        <DataTable
          fill
          border={false}
          columns={columns}
          data={data}
          primaryKey="id"
          rowProps={rowProps}
          onClickRow={(event) => {
            setSelectedId(event.datum.id);
            props.onSelected(event.datum);
          }}
        />
      </Box>
    </Grommet>
  );
});

export default ClickableDataTable;
