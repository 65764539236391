// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */

import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { getCurrentSetScoreAndIndex, getMatchScores } from '../helpers/utils';
import { strogging } from '@shd/jslib/infra';

function EventHistoryParser(
  eventHistory,
  gameInfoImportant,
  homeLineup,
  awayLineup,
  liberos,
  promptUser,
  isQuickStartMode
) {
  const gameInfoLegacy = {
    scoreboardType: 400,
  };

  const gameInfo = { ...gameInfoImportant, ...gameInfoLegacy };
  // eslint-disable-next-line no-unused-vars
  const awayHome = gameInfo.teamHScoreTeamId ? 1 : 0;

  this.homeLineup = homeLineup;
  this.awayLineup = awayLineup;

  this.liberos = liberos || [];
  this.liberoMapping = {};

  // const awayHome = gameInfo.teamHScoreTeamId ? 1 : 0;
  this.isQuickStartMode = isQuickStartMode;
  this.activeScoreboard = {
    curHPosByScorePlayerId: this.homeLineup
      .map((player) => player._id)
      .slice(0, 6),

    curAPosByScorePlayerId: this.awayLineup
      .map((player) => player._id)
      .slice(0, 6),

    curPosByScorePlayerSchema: 'vb6',
    curPosByScorePlayerData: {
      0: 'server',
    },

    teamHSetScores: [-1],
    teamASetScores: [-1],

    teamWinnerNAH: 0,
    teamServingNAH: 0,

    isBallInPlay: false,
    isPendingData: false,

    isGameEnd: false,

    scoreboardTeamPos: awayHome ? 'R' : 'L',
    gameState: {},
  };

  this.activePoint = {
    _sport: 'vb',
    _ver: 100,

    // Clip info
    clipType: 'completePlay', // at this point, only completePlay is supported;
    description: '',
    scorerRating: 0,

    // These specify the clip location
    scoreGameId: gameInfo.scoreGameId,
    startTs: 0,
    endTs: 0,
    setNum: 1, // 0=unknown, 1..n=set #
    teamHScore: 0, // the overall result (set score)
    teamAScore: 0,

    // These identify the team and work in conjunction with the scoreDoc home/away fields
    awayHomeIndex: awayHome, // we are tracking 0=away, 1=home
    teamScoredNAH: 0, // team that scored 0=unknown or not applicable, 1=away, 2=home
    teamServingNAH: this.activeScoreboard.teamServingNAH, // 0=unknown, 1=away, 2=home,

    // Players in the clip by teamPlayerId
    // Position index is 0..n-1.  Use FINAL position of player in the clip.  If unknown use “”
    // curAPosByScorePlayerId: [],
    // curHPosByScorePlayerId: [],
    curHPosByScorePlayerId: this.homeLineup
      .map((player) => player._id)
      .slice(0, 6),
    curAPosByScorePlayerId: this.awayLineup
      .map((player) => player._id)
      .slice(0, 6),

    // teamPlayer tagged actions
    // integer indicates the number of times a tagged action was taken
    // note: We are not tracking event timestamps
    scorePlayerActionDict: {},
    // 'PlIs01-slhd_qeyc-tpsh01-1-xd4vcckc9p': { serve: 1, block: 1 },
    // 'PlIs01-slhd_6bsj-tpsh01-23-fw8cd1phoc': { save: 2 },

    isFinalized: false,

    highlightDescription: '', // Default to empty string. Could change in the future
  };

  this.scoreboardList = [];
  this.clipEventsList = [];
  this.eventHistory = _.cloneDeep(eventHistory);

  this.isHomeBatting = () => (this.activeScoreboard.inningNum % 1 ? 1 : 0);

  this.promptLiberoSub = null;
  this.liberoSubData = null;

  this.isShdTeamBatting = () => {
    if (gameInfo.teamHScoreTeamId && this.isHomeBatting()) {
      return true;
    }
    if (gameInfo.teamAScoreTeamId && !this.isHomeBatting()) {
      return true;
    }
    return false;
  };

  this.undoEvent = () => {
    const lastEvent = _.last(this.eventHistory);

    this.eventHistory.splice(-1, 1);
    if (
      ['resultPromptResponse', 'wipePlateAppearance'].includes(
        lastEvent.eventType
      )
    ) {
      // Users do not perceive these events as events
      this.eventHistory.splice(-1, 1);
    } else if (
      lastEvent.eventType === 'sub' &&
      lastEvent.eventInfo.index === -1 &&
      this.isQuickStartMode &&
      lastEvent.eventInfo.isQuickStartModePrompt &&
      !lastEvent.eventInfo.isOnDeck
    ) {
      this.undoEvent();
    } else if (lastEvent.eventType === 'forceRun') {
      this.undoEvent();
    } else if (lastEvent.eventType === 'liberoAutoSub') {
      this.promptLiberoSub = null;
      this.undoEvent();
    }

    // else if (lastEvent.eventType === 'subLiberos') {
    //   this.undoEvent();
    // }

    // if ((lastEvent.eventType === 'home' || lastEvent.eventType === 'away')
    // && lastEvent.eventInfo.isPlus) {
    //   console.log('YES', this.activePoint);
    //   // this.endPoint(lastEvent);
    //   this.undoEvent();
    // }
  };

  this.parseEventHistory = () => {
    const promptPromise = new Promise((resolve) => {
      const lastEvent = _.last(this.eventHistory);
      if (lastEvent.eventType === 'undo' && this.eventHistory.length > 2) {
        this.eventHistory.splice(-1, 1); // remove "undo" event
        this.undoEvent();
        this.generateGameInfo();
        resolve(true);
        return;
      }
      this.generateGameInfo();

      if (lastEvent.eventType === 'forceRun') {
        resolve(true);
        return;
      }

      resolve(true);
    })
      .then((resp) => {
        if (resp) {
          const latestScoreboard = _.last(this.scoreboardList);
          return {
            scoreboardList: this.scoreboardList,
            clipEventsList: this.clipEventsList,
            latestScoreboard,
            homeLineup: this.homeLineup,
            awayLineup: this.awayLineup,
            eventHistory: this.eventHistory,
            isQuickStartMode: this.isQuickStartMode,
            promptLiberoSub: this.promptLiberoSub,
            liberoSubData: this.liberoSubData,
            liberos: this.liberos,
          };
        }
        return { eventHistory: this.eventHistory };
      })
      .catch((parseErr) => {
        strogging.exception(
          'parseEventHistoryVB: error parsing event history',
          parseErr
        );
        Sentry.captureException(parseErr);
      });

    return promptPromise;
  };

  this.startPoint = (event) => {
    // remove timeout if it exists
    this.activeScoreboard.gameState.timeout = {};

    if (this.activePoint.startTs > 0) {
      // dont add empty first event to list
      this.activePoint.isFinalized = true;
      this.clipEventsList.splice(-1, 1, { ...this.activePoint });
    }

    this.activePoint.startTs = event.ts;
    this.activePoint.endTs = 0;
    this.activePoint.scorePlayerActionDict = {};

    this.activePoint.teamServingNAH = this.activeScoreboard.teamServingNAH;
  };

  this.endPoint = (event) => {
    const { currentScore: currentHScore } = getCurrentSetScoreAndIndex(
      this.activeScoreboard.teamHSetScores
    );

    const { currentScore: currentAScore } = getCurrentSetScoreAndIndex(
      this.activeScoreboard.teamASetScores
    );

    const { teamAMatchScore, teamHMatchScore } = getMatchScores(
      this.activeScoreboard.teamASetScores,
      this.activeScoreboard.teamHSetScores
    );

    // change endTs when there is a new event that could end the point
    if (event.eventInfo.isPlus) {
      this.activePoint.endTs = event.ts;
      if (currentHScore > this.activePoint.teamHScore) {
        // home scored
        this.activePoint.teamScoredNAH = 2;

        // rotate if won point when receiving
        if (this.activePoint.teamServingNAH === 1) {
          this.homeLineup = this.rotateLineup(this.homeLineup);
          this.activeScoreboard.teamServingNAH = 2;
        } else if (this.activePoint.teamServingNAH === 0) {
          // unknown serving team, so make the point winner the new serving team
          this.activeScoreboard.teamServingNAH = 2;
        }
      } else {
        this.activePoint.teamScoredNAH = 1;
        if (this.activePoint.teamServingNAH === 2) {
          this.awayLineup = this.rotateLineup(this.awayLineup);
          this.activeScoreboard.teamServingNAH = 1;
        } else if (this.activePoint.teamServingNAH === 0) {
          // unknown serving team, so make the point winner the new serving team
          this.activeScoreboard.teamServingNAH = 1;
        }
      }
    }

    this.activePoint.setNum = teamAMatchScore + teamHMatchScore + 1;
    this.activePoint.teamHScore = currentHScore;
    this.activePoint.teamAScore = currentAScore;

    this.activePoint.curHPosByScorePlayerId = this.homeLineup
      .map((player) => player._id)
      .slice(0, 6);
    this.activePoint.curAPosByScorePlayerId = this.awayLineup
      .map((player) => player._id)
      .slice(0, 6);

    this.activeScoreboard.curHPosByScorePlayerId =
      this.activePoint.curHPosByScorePlayerId;
    this.activeScoreboard.curAPosByScorePlayerId =
      this.activePoint.curAPosByScorePlayerId;

    this.activePoint.isFinalized = false;

    const lastPoint = this.clipEventsList.slice(-1)[0];
    if (
      this.clipEventsList.length > 0 &&
      this.activePoint.startTs === lastPoint.startTs &&
      (this.activePoint.endTs === lastPoint.endTs || lastPoint.endTs === 0)
    ) {
      // replace last event of clip events
      this.clipEventsList.splice(-1, 1, { ...this.activePoint });
      this.activeScoreboard.isPendingData = false;
    } else {
      // add to clip events
      // if we are adding event, finalize previous one
      if (this.clipEventsList.length > 0) {
        lastPoint.isFinalized = true;
        this.clipEventsList.splice(-1, 1, { ...lastPoint });

        this.activeScoreboard.isPendingData = false;
      }
      this.clipEventsList.push({ ...this.activePoint });

      if (event.eventInfo.isPendingData) {
        this.activeScoreboard.isPendingData = true;
      }
    }

    // logging
    if (
      this.activePoint.isFinalized &&
      this.activePoint.endTs - this.activePoint.startTs < 0
    ) {
      Sentry.captureMessage('Invalid clipEvent. tsEnd < tsStart', {
        level: 'error',
      });
    }
  };

  this.addStat = (event) => {
    const { eventInfo: statInfo } = event;
    if (statInfo.player) {
      let dictKey = statInfo.player._id;
      if (statInfo.player._id.includes('placeholder-')) {
        dictKey = 'placeholder';
      }
      let playerActionDict = this.activePoint.scorePlayerActionDict[dictKey];

      if (playerActionDict) {
        if (playerActionDict[statInfo.stat]) {
          playerActionDict[statInfo.stat] += 1;
        } else {
          playerActionDict = { ...playerActionDict, ...{ [statInfo.stat]: 1 } };
        }
      } else {
        playerActionDict = { [statInfo.stat]: 1 };
      }

      this.activePoint.scorePlayerActionDict[dictKey] = playerActionDict;
    }

    // for stats tagging multiple players at once
    if (statInfo.players) {
      const newScorePlayerActionDict = {
        ...this.activePoint.scorePlayerActionDict,
      };

      statInfo.players.forEach((player) => {
        let dictKey = player._id;
        if (player._id.includes('placeholder-')) {
          dictKey = 'placeholder';
        }
        let playerActionDict = this.activePoint.scorePlayerActionDict[dictKey];

        if (playerActionDict) {
          if (playerActionDict[statInfo.stat]) {
            playerActionDict[statInfo.stat] += 1;
          } else {
            playerActionDict = {
              ...playerActionDict,
              ...{ [statInfo.stat]: 1 },
            };
          }
        } else {
          playerActionDict = { [statInfo.stat]: 1 };
        }

        newScorePlayerActionDict[dictKey] = playerActionDict;
      });

      this.activePoint.scorePlayerActionDict = newScorePlayerActionDict;
    }
  };

  this.validateLineup = (rotatedLineup) => {
    // front row indices are 3, 2, 1
    const liberoIds = this.liberos.map((libero) => libero._id);

    if (liberoIds.includes(rotatedLineup[3]._id)) {
      // libero rotating to front row. trigger auto libero sub
      const liberoId = rotatedLineup[3]._id;
      // const playerIn = this.liberoMapping[liberoId];
      let playerIn;
      if (
        this.liberoMapping[liberoId] &&
        this.liberoMapping[liberoId].players?.length > 1
      ) {
        // eslint-disable-next-line prefer-destructuring
        playerIn = this.liberoMapping[liberoId].players[0];
      } else if (this.liberoMapping[liberoId]) {
        // eslint-disable-next-line prefer-destructuring
        playerIn = this.liberoMapping[liberoId].players[0];
      }

      if (playerIn) {
        // this.promptLiberoSub = null;

        // automatically sub playerIn for libero
        const playerOut = rotatedLineup[3];
        this.liberoSubData = {
          playerIn,
          playerOut,
        };
      } else {
        this.promptLiberoSub = {
          subOutIndex: 0,
        };
      }
    }

    // we need know if a libero serves or swaps after the serve
    // if the libero serves, we want to use rotateLineup[0] and prompt to sub index = 5
    // if the libero does not serve, we want to use rotateLineup[5] and prompt to sub index = 4
    const potentialLiberoSwapIds = Object.values(this.liberoMapping)
      .map((x) => x.players.map((p) => p._id))
      .flat();
    if (potentialLiberoSwapIds.includes(rotatedLineup[0]._id)) {
      // player who replaced libero (tracked in liberoMapping) is coming to back row

      // find the specific libero that rotatedLineup[0] is associated with
      let theLibero;
      Object.entries(this.liberoMapping).every(([liberoId, libero]) => {
        if (libero.players.map((p) => p._id).includes(rotatedLineup[0]._id)) {
          theLibero = libero;
          theLibero.id = liberoId;
          return false;
        }

        return true;
      });

      if (theLibero.serves) {
        // libero serves

        // make sure libero is not already on the court
        if (
          ![
            rotatedLineup[0]._id,
            rotatedLineup[5]._id,
            rotatedLineup[4]._id,
          ].includes(theLibero.id)
        ) {
          this.promptLiberoSub = {
            subOutIndex: 5,
            suggestedPlayerIds: liberoIds,
          };
        }
      }
    } else if (potentialLiberoSwapIds.includes(rotatedLineup[5]._id)) {
      // player who replaced libero (tracked in liberoMapping) is coming to back row

      // find the specific libero that rotatedLineup[5] is associated with
      let theLibero;
      Object.values(this.liberoMapping).every((libero) => {
        if (libero.players.map((p) => p._id).includes(rotatedLineup[5]._id)) {
          theLibero = libero;
          return false;
        }

        return true;
      });

      if (theLibero && !theLibero.serves) {
        // libero does not serve
        this.promptLiberoSub = {
          subOutIndex: 4,
          suggestedPlayerIds: liberoIds,
        };
      }
    } else {
      this.promptLiberoSub = null;
    }
  };

  this.rotateLineup = (lineup, isClockwise = true) => {
    if (lineup.length === 0) {
      return lineup;
    }

    if (isClockwise) {
      const rotatedLineup = [...lineup.slice(1, 6), lineup[0]].concat(
        lineup.slice(6)
      );

      this.validateLineup(rotatedLineup);

      return rotatedLineup;
    }
    const rotatedLineup = [lineup[5], ...lineup.slice(0, 5)].concat(
      lineup.slice(6)
    );

    this.validateLineup(rotatedLineup);

    return rotatedLineup;
  };

  this.generateGameInfo = () => {
    this.eventHistory.forEach((event) => {
      this.activeScoreboard.scoreboardTs = event.ts;
      if (event.eventType === 'home') {
        const { currentScore: currentHScore, currentIndex } =
          getCurrentSetScoreAndIndex(this.activeScoreboard.teamHSetScores);
        if (event.eventInfo.isPlus) {
          this.activeScoreboard.isBallInPlay = false;
          this.activeScoreboard.teamHSetScores[currentIndex] =
            currentHScore + 1;

          // once there is scoring, make sure neither of the scores is 0
          const { currentScore: currentAScore } = getCurrentSetScoreAndIndex(
            this.activeScoreboard.teamASetScores
          );
          if (currentAScore === 0) {
            this.activeScoreboard.teamASetScores[currentIndex] = 0;
          }

          // end point after score is updated
          if (event.eventInfo.stat) {
            this.addStat(event);
          }

          if (!event.eventInfo.isManual) {
            this.endPoint(event);
          }
        } else if (!event.eventInfo.isPlus && currentHScore > 0) {
          this.activeScoreboard.teamHSetScores[currentIndex] =
            currentHScore - 1;
        }
      } else if (event.eventType === 'away') {
        const { currentScore: currentAScore, currentIndex } =
          getCurrentSetScoreAndIndex(this.activeScoreboard.teamASetScores);
        if (event.eventInfo.isPlus) {
          this.activeScoreboard.isBallInPlay = false;
          this.activeScoreboard.teamASetScores[currentIndex] =
            currentAScore + 1;

          // once there is scoring, make sure neither of the scores is 0
          const { currentScore: currentHScore } = getCurrentSetScoreAndIndex(
            this.activeScoreboard.teamHSetScores
          );
          if (currentHScore === 0) {
            this.activeScoreboard.teamHSetScores[currentIndex] = 0;
          }

          // end point after score is updated
          if (event.eventInfo.stat) {
            this.addStat(event);
          }
          if (!event.eventInfo.isManual) {
            this.endPoint(event);
          }
        } else if (!event.eventInfo.isPlus && currentAScore > 0) {
          this.activeScoreboard.teamASetScores[currentIndex] =
            currentAScore - 1;
        }
      } else if (event.eventType === 'addStat') {
        // handle stat -- does this go here???
        this.addStat(event);
        this.endPoint(event);
      } else if (event.eventType === 'startNextPoint') {
        this.activeScoreboard.isBallInPlay = true;
        this.startPoint(event);
      } else if (event.eventType === 'toggleServe') {
        const { teamClickedNAH } = event.eventInfo;
        this.activeScoreboard.teamServingNAH = teamClickedNAH;
        this.activePoint.teamServingNAH = teamClickedNAH;
      } else if (event.eventType === 'sub') {
        const { playerOut, playerIn, position } = event.eventInfo;
        if (awayHome) {
          this.homeLineup = this.homeLineup.map((p) => {
            let newPlayer;
            if (p._id === playerOut._id) {
              newPlayer = playerIn;
            } else if (p._id === playerIn._id) {
              newPlayer = playerOut;
            } else {
              newPlayer = p;
            }

            return newPlayer;
          });

          this.activeScoreboard.curHPosByScorePlayerId = this.homeLineup
            .map((player) => player._id)
            .slice(0, 6);

          this.validateLineup(this.homeLineup);
        } else {
          this.awayLineup = this.awayLineup.map((p) => {
            let newPlayer;
            if (p._id === playerOut._id) {
              newPlayer = playerIn;
            } else if (p._id === playerIn._id) {
              newPlayer = playerOut;
            } else {
              newPlayer = p;
            }

            return newPlayer;
          });
          this.activeScoreboard.curAPosByScorePlayerId = this.awayLineup
            .map((player) => player._id)
            .slice(0, 6);

          this.validateLineup(this.awayLineup);
        }

        // keep track of who the libero replaces (libero IN, back row player OUT)
        const liberoIds = this.liberos.map((libero) => libero._id);
        const isLiberoSub = liberoIds.includes(playerIn._id);
        if (isLiberoSub) {
          if (playerIn._id in this.liberoMapping) {
            // eslint-disable-next-line max-len
            this.liberoMapping[playerIn._id].players = this.liberoMapping[
              playerIn._id
            ].players.filter((p) => p._id !== playerOut._id);

            this.liberoMapping[playerIn._id].players.unshift(playerOut);
            if (position === 0) {
              this.liberoMapping[playerIn._id].serves = true;
            }
          } else {
            this.liberoMapping[playerIn._id] = {
              players: [playerOut],
              serves: position === 0, // this means that a libero was subbed in for the server
            };
          }

          // console.log('this.liberoMapping', this.liberoMapping);
        }
      } else if (event.eventType === 'cancelLiberoSub') {
        const { playerOut, position } = event.eventInfo;
        if (position === 0) {
          // the libero that was prompted to swap in for the server should now have serve = false
          Object.values(this.liberoMapping).forEach((libero) => {
            if (libero.players.map((p) => p._id).includes(playerOut._id)) {
              // eslint-disable-next-line no-param-reassign
              libero.serves = false;
            }
          });
        }
        this.promptLiberoSub = null;
      } else if (event.eventType === 'liberoAutoSub') {
        const { playerOut, playerIn } = event.eventInfo;
        if (awayHome) {
          this.homeLineup = this.homeLineup.map((p) => {
            let newPlayer;
            if (p._id === playerOut._id) {
              newPlayer = playerIn;
            } else if (p._id === playerIn._id) {
              newPlayer = playerOut;
            } else {
              newPlayer = p;
            }

            return newPlayer;
          });

          this.activeScoreboard.curHPosByScorePlayerId = this.homeLineup
            .map((player) => player._id)
            .slice(0, 6);
        } else {
          this.awayLineup = this.awayLineup.map((p) => {
            let newPlayer;
            if (p._id === playerOut._id) {
              newPlayer = playerIn;
            } else if (p._id === playerIn._id) {
              newPlayer = playerOut;
            } else {
              newPlayer = p;
            }

            return newPlayer;
          });
          this.activeScoreboard.curAPosByScorePlayerId = this.awayLineup
            .map((player) => player._id)
            .slice(0, 6);
        }
        // this.activeScoreboard.isBallInPlay = false;
        this.liberoSubData = null;
      } else if (event.eventType === 'subLiberos') {
        const { liberos: newLiberos } = event.eventInfo;
        this.liberos = newLiberos;
      } else if (event.eventType === 'rotate') {
        // start
        // 3 2 1
        // 4 5 0

        // post clockwise rotation
        // 4 3 2
        // 5 0 1

        // new index 0 is previous index 1
        if (awayHome) {
          this.homeLineup = this.rotateLineup(
            this.homeLineup,
            event.eventInfo.isClockwise
          );

          this.activeScoreboard.curHPosByScorePlayerId = this.homeLineup
            .map((player) => player._id)
            .slice(0, 6);
        } else {
          this.awayLineup = this.rotateLineup(
            this.awayLineup,
            event.eventInfo.isClockwise
          );

          this.activeScoreboard.curAPosByScorePlayerId = this.awayLineup
            .map((player) => player._id)
            .slice(0, 6);
        }
      } else if (event.eventType === 'changeGameState') {
        const gameStateType = event.eventInfo.name;
        if (event.eventInfo.enable) {
          // add game state
          this.activeScoreboard.gameState[gameStateType] = {
            teamNAH: event.eventInfo.teamNAH,
            startTs: event.ts,
          };
        } else {
          // remove game state
          delete this.activeScoreboard.gameState[gameStateType];
        }
      } else if (event.eventType === 'scoreboardPosition') {
        this.activeScoreboard.scoreboardTeamPos = event.eventInfo.position;
      } else if (event.eventType === 'endSet') {
        // this finalizes the final clip of a set
        if (this.activePoint.startTs > 0) {
          // dont add empty first event to list
          this.activePoint.isFinalized = true;
          this.clipEventsList.splice(-1, 1, { ...this.activePoint });
        }

        const { currentScore: currentHScore } = getCurrentSetScoreAndIndex(
          this.activeScoreboard.teamHSetScores
        );

        const { currentScore: currentAScore, currentIndex } =
          getCurrentSetScoreAndIndex(this.activeScoreboard.teamASetScores);

        this.activeScoreboard.teamASetScores[currentIndex] = currentAScore;
        this.activeScoreboard.teamHSetScores[currentIndex] = currentHScore;

        this.activeScoreboard.teamHSetScores.push(-1);
        this.activeScoreboard.teamASetScores.push(-1);
      } else if (event.eventType === 'endGame') {
        // this finalizes the final clip of a set
        if (this.activePoint.startTs > 0) {
          // dont add empty first event to list
          this.activePoint.isFinalized = true;
          this.clipEventsList.splice(-1, 1, { ...this.activePoint });
        }

        this.activeScoreboard.isGameEnd = true;
        const { teamAMatchScore, teamHMatchScore } = getMatchScores(
          this.activeScoreboard.teamASetScores,
          this.activeScoreboard.teamHSetScores
        );

        if (teamHMatchScore > teamAMatchScore) {
          this.activeScoreboard.teamWinnerNAH = 2;
        } else if (teamAMatchScore > teamHMatchScore) {
          this.activeScoreboard.teamWinnerNAH = 1;
        } else {
          this.activeScoreboard.teamWinnerNAH = 0;
        }
      }
      this.scoreboardList.push({
        ..._.cloneDeep(this.activeScoreboard),
        ...gameInfo,
      });
    });
  };
}

export default EventHistoryParser;
