// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { Box, Button, CardBody } from 'grommet';

import FacebookSettings from './FacebookSettings';
import YoutubeSettings from './YouTubeSettings';
import OnboardingButton from '../OnboardingButton';

import { CardHeading, CardStyled, CardSubTitle } from './style';

import FormHeader from '../FormHeader';
import { analytics } from '../../../services/analytics';

const SetLinkScreen = ({
  facebookLinked,
  youtubeLinked,
  userStore,
  setFacebookLinked,
  setYoutubeLinked,
  nextStep,
  prevStep,
}) => (
  <Box pad={{ horizontal: 'large' }}>
    <FormHeader currentStep="4" totalSteps="5" buttonAction={prevStep} />
    <Box pad="small" gap="small" width="large" margin={{ bottom: 'small' }}>
      <CardHeading level="3" margin={{ vertical: 'small' }} color="dark-1">
        Where would you like to livestream?
      </CardHeading>
    </Box>
    <CardStyled
      width="large"
      pad="none"
      direction="column"
      background="primary-7"
    >
      <CardBody pad="medium" gap="medium">
        <FacebookSettings
          teamId={userStore.activeOnboardingTeam}
          onUpdatedLinkedChannel={(channel) => {
            if (channel) {
              setFacebookLinked(true);
            }
          }}
        />
        <YoutubeSettings
          teamId={userStore.activeOnboardingTeam}
          onUpdatedLinkedChannel={(channel) => {
            if (channel) {
              setYoutubeLinked(true);
            } else {
              setYoutubeLinked(false);
            }
          }}
        />
        <CardSubTitle color="dark-4">
          You can change this later in the Streaming tab.
        </CardSubTitle>
      </CardBody>
    </CardStyled>
    <Box width="large" margin={{ vertical: 'large' }} gap="medium">
      <OnboardingButton
        disabled={!(youtubeLinked || facebookLinked)}
        label="Set up my camera"
        onClick={() => {
          nextStep();
        }}
      />
      <Button
        secondary
        color="tertiary-1"
        label="I will set this up later"
        onClick={() => {
          analytics.track('Skip Stream Destination Setup Clicked');
          nextStep();
        }}
        size="large"
      />
    </Box>
  </Box>
);

export default SetLinkScreen;
