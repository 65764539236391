// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Accordion,
  Box,
  Text,
  Button,
  Card,
  AccordionPanel,
  Layer,
  Anchor,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Heading,
  Tab,
  Tabs,
} from 'grommet';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Close,
  Edit,
  FormDown,
  FormLock,
  FormUp,
  LinkPrevious,
} from 'grommet-icons';

import { MdOutlineSportsVolleyball } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import VBPlayerClip from '../Player/VBPlayerClip';
import { formatDateShort, getPrivacyName } from '../../helpers/utils';
import ClaimPlayerLayer from '../Player/ClaimPlayerLayer';
import { withFirebase } from '../../services/Firebase';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';
import BoxScore from './BoxScore';
import Recap from './Recap';
import ShareBar from './ShareBar';
import Referrals from '../Settings/Referrals';
import PlayerPreview from '../Player/PlayerPreview';
import Toast from '../../components/Toast';
import useGame from './useGame';
import HlsPlayer from '../../components/HLS/HlsPlayer';
import PrivacyAwareYTLink from '../../components/PrivacyAwareYTLink';
import { analytics } from '../../services/analytics';

const Game = ({ history, match, ...props }) => {
  const {
    size,
    gameData,
    activeTabIndex,
    setActiveTabIndex,
    availableTabs,
    players,
    isTeamAdmin,
    setActiveClip,
    setShowVideo,
    setShowReassignClip,
    gameId,
    userStore,
    loading,
    activeIndex,
    accordionRef,
    setActiveIndex,
    boxScoreData,
    recapData,
    clips,
    activeClip,
    closeVideo,
    setClips,
    showReassignClip,
    toast,
    setToast,
    showVideo,
    showPlayerPreview,
    highlightPlayerId,
    setShowPlayerPreview,
    hlsResponse,
  } = useGame(history, match, 'vb', props.gameData);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [showUnclaimedModal, setShowUnclaimedModal] = useState(false);
  const [showClaim, setShowClaim] = useState(false);

  const renderScoringBySet = (data) => {
    const { J_opponentName, scoreOurName, scoreWeAreHome } = data;

    const teamASetScores = data.teamASetScores || [];
    const teamHSetScores = data.teamHSetScores || [];

    let homeTeamName;
    let awayTeamName;

    if (scoreWeAreHome) {
      homeTeamName = scoreOurName;
      awayTeamName = J_opponentName;
    } else {
      homeTeamName = J_opponentName;
      awayTeamName = scoreOurName;
    }

    const trimmedTeamHSetScores =
      teamHSetScores[teamHSetScores.length - 1] === -1
        ? teamHSetScores.slice(0, -1)
        : teamHSetScores;
    const trimmedTeamASetScores =
      teamASetScores[teamASetScores.length - 1] === -1
        ? teamASetScores.slice(0, -1)
        : teamASetScores;

    let headers = [''];
    if (teamHSetScores && teamASetScores) {
      headers = ['']
        .concat(trimmedTeamHSetScores.map((_, set) => set + 1))
        .concat(['T']);
    }

    const homeGameScore = trimmedTeamHSetScores
      .map((score, i) => score > trimmedTeamASetScores[i])
      .reduce((a, b) => (b ? a + 1 : a), 0);
    const awayGameScore = trimmedTeamASetScores
      .map((score, i) => score > trimmedTeamHSetScores[i])
      .reduce((a, b) => (b ? a + 1 : a), 0);

    const homeRow = [homeTeamName]
      .concat(trimmedTeamHSetScores)
      .concat(homeGameScore);
    const awayRow = [awayTeamName]
      .concat(trimmedTeamASetScores)
      .concat(awayGameScore);

    const scoresTable = (
      <Table>
        <TableHeader>
          <TableRow>
            {headers.map((set) => (
              <TableCell border="bottom" key={`set-header-${set}`} scope="col">
                <Text size="small" weight="bold">
                  {set}
                </Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow key="away-row">
            {awayRow.map((x, i) => {
              const isBold =
                (i === awayRow.length - 1 || x > homeRow[i]) && i !== 0;
              const color = i === 0 || i === awayRow.length - 1 ? '' : 'dark-4';
              let label;
              if (size === 'small' && i === 0 && !scoreWeAreHome) {
                label = (
                  <Anchor href={`/${gameData.DNOR_teamId}`}>
                    <Text
                      size="small"
                      weight={isBold ? 'bold' : 'normal'}
                      color={color}
                    >
                      {x}
                    </Text>
                  </Anchor>
                );
              } else {
                label = (
                  <Text
                    size="small"
                    weight={isBold ? 'bold' : 'normal'}
                    color={color}
                  >
                    {x}
                  </Text>
                );
              }
              return (
                <TableCell border={false} key={`runs-${i + 1}`}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow key="home-row">
            {homeRow.map((x, i) => {
              const isBold =
                (i === homeRow.length - 1 || x > awayRow[i]) && i !== 0;
              const color = i === 0 || i === homeRow.length - 1 ? '' : 'dark-4';
              let label;
              if (size === 'small' && i === 0 && scoreWeAreHome) {
                label = (
                  <Anchor href={`/${gameData.DNOR_teamId}`}>
                    <Text
                      size="small"
                      weight={isBold ? 'bold' : 'normal'}
                      color={color}
                    >
                      {x}
                    </Text>
                  </Anchor>
                );
              } else {
                label = (
                  <Text
                    size="small"
                    weight={isBold ? 'bold' : 'normal'}
                    color={color}
                  >
                    {x}
                  </Text>
                );
              }
              return (
                <TableCell border={false} key={`runs-${i + 1}`}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    );

    return <Box margin={{ top: 'medium' }}>{scoresTable}</Box>;
  };

  const renderHeader = (data) => {
    const {
      J_opponentName,
      J_tsStart,
      fb,
      yt,
      scoreOurName,
      scoreWeAreHome,
      teamWinnerNAH,
    } = data;

    const teamASetScores = data.teamASetScores || [];
    const teamHSetScores = data.teamHSetScores || [];

    let awayGameScore = 0;
    let homeGameScore = 0;

    for (let i = teamHSetScores.length; i > 0; i -= 1) {
      if (i - 1 >= 0 && i !== -1) {
        if (teamHSetScores[i - 1] > teamASetScores[i - 1]) {
          homeGameScore += 1;
        }
        if (teamHSetScores[i - 1] < teamASetScores[i - 1]) {
          awayGameScore += 1;
        }
      }
    }

    const awayTeamDiv = (
      <Box
        direction="row"
        alignSelf="center"
        align="center"
        justify="end"
        gap="medium"
        margin={{ right: 'medium' }}
        width="200px"
      >
        {scoreWeAreHome ? (
          <Text
            weight="bold"
            size="xlarge"
            alignSelf="center"
            margin={{ right: 'small' }}
          >
            {scoreWeAreHome ? J_opponentName : scoreOurName}
          </Text>
        ) : (
          <Anchor
            label={
              <Text
                weight="bold"
                size="xlarge"
                alignSelf="center"
                margin={{ right: 'small' }}
              >
                {scoreWeAreHome ? J_opponentName : scoreOurName}
              </Text>
            }
            href={`/${gameData.DNOR_teamId}`}
          />
        )}
        <Heading color={teamWinnerNAH !== 1 ? 'dark-4' : ''}>
          {awayGameScore}
        </Heading>
        {/* {teamWinnerNAH === 1 && <CaretLeftFill />} */}
      </Box>
    );

    const homeTeamDiv = (
      <Box
        direction="row"
        alignSelf="center"
        align="center"
        gap="medium"
        margin={{ left: 'medium' }}
        width="200px"
      >
        {/* {teamWinnerNAH === 2 && <CaretRightFill />} */}
        <Heading color={teamWinnerNAH !== 2 ? 'dark-4' : ''}>
          {homeGameScore}
        </Heading>
        {scoreWeAreHome ? (
          <Anchor
            label={
              <Text
                weight="bold"
                size="xlarge"
                alignSelf="center"
                margin={{ left: 'small' }}
              >
                {scoreWeAreHome ? scoreOurName : J_opponentName}
              </Text>
            }
            href={`/${gameData.DNOR_teamId}`}
          />
        ) : (
          <Text
            weight="bold"
            size="xlarge"
            alignSelf="center"
            margin={{ left: 'small' }}
            onClick={() => {
              if (scoreWeAreHome) {
                history.push(`/${gameData.DNOR_teamId}`);
              }
            }}
          >
            {scoreWeAreHome ? scoreOurName : J_opponentName}
          </Text>
        )}
      </Box>
    );

    return (
      <Box>
        <Box pad={{ top: '16px', left: '16px' }} margin={{ bottom: '-24px' }}>
          <Box
            alignSelf="start"
            round="full"
            elevation="small"
            pad="small"
            background="white"
            onClick={() => history.goBack()}
          >
            <LinkPrevious size="24px" />
          </Box>
        </Box>
        <Box direction="row" gap="medium" margin="0 auto">
          {size !== 'small' && awayTeamDiv}
          <Box pad={{ top: 'small' }}>
            <Box direction="row" gap="small">
              <Text>{formatDateShort(J_tsStart)}</Text>
              <Text weight="bold">
                {data.scoreGameState === 50 ? 'FINAL' : 'Live'}
              </Text>
            </Box>
            {data.scoreGameState > 10 && renderScoringBySet(data)}
          </Box>
          {size !== 'small' && homeTeamDiv}
        </Box>
        <Box margin="0 auto" pad="small">
          <Box direction="row" gap="small">
            {yt && (
              <PrivacyAwareYTLink
                teamId={gameData.DNOR_teamId}
                gameId={gameData._id}
                ytAsset={yt}
              >
                <Button primary color="#ff0000" label="Watch on YouTube" />
              </PrivacyAwareYTLink>
            )}
            {fb && (
              <Button
                primary
                color="#4267B2"
                label="Watch on Facebook"
                href={fb.cdnUrl}
                target="_blank"
              />
            )}
          </Box>
        </Box>
        <Tabs
          activeIndex={activeTabIndex}
          onActive={(nextIndex) => {
            setActiveTabIndex(nextIndex);
          }}
        >
          {availableTabs.map((tab) => {
            switch (tab) {
              case 'boxScore':
                return <Tab key={tab} title="Box Score" />;
              case 'pbp':
                return <Tab key={tab} title="Play-by-Play" />;
              case 'recap':
                return <Tab key={tab} title="Recap" />;
              case 'vod':
                return <Tab title="VOD" />;
              default:
                return null;
            }
          })}
        </Tabs>
      </Box>
    );
  };

  const renderPanelHeader = (title, active) => (
    <Box
      id="123"
      key={title}
      background="secondary-7"
      direction="row"
      align="center"
      pad="medium"
      gap="small"
      justify="between"
    >
      <strong>
        <Text>Set {title}</Text>
      </strong>
      <Text color="brand">{active ? <FormUp /> : <FormDown />}</Text>
    </Box>
  );

  const renderSummary = (clipsBySet, data) => {
    const setKeys = Array.from(Array(15).keys()).map((i) => i + 1);

    const setDivs = [];

    let maxSet = 1;
    if (clipsBySet) {
      maxSet =
        Math.max(
          ...Object.keys(clipsBySet)
            .map((setStr) => parseInt(setStr))
            .sort()
        ) + 1;
    }

    setKeys.forEach((set, i) => {
      if (set < maxSet) {
        const clipsInSet = clipsBySet[set.toFixed(1)] || [];
        // Note: an early version had clipStartTs "stuck", to preserve order sort by clipStopTs
        const sortedClips = clipsInSet.sort(
          (a, b) => a.clipStopTs - b.clipStopTs
        );

        const clipsDivs = sortedClips.map((clip) => {
          // const taggedPlayers = clip.J_teamPlayerList.map((pId) => players[pId]);
          const taggedPlayers = clip.playerScorePlayerList;
          const onTheCourt = taggedPlayers.slice();

          // need to find spotlight player
          // what they are for
          // then take the rest for on the court

          // set highlight players when there is a highlightDescription

          const spotlightPlayers = [];
          const highlightPlayers = [];
          const otherPlayers = [];
          if (onTheCourt.filter((x) => !x.includes('placeholder')).length > 0) {
            for (let k = 0; k < onTheCourt.length; k += 1) {
              const playerId = onTheCourt[k];
              if (playerId.includes('placeholder')) {
                // eslint-disable-next-line no-continue
                continue;
              }
              if (clip.teamPlayerActionDict[playerId]?.ace) {
                spotlightPlayers.push({
                  pl: players[playerId],
                  title: 'Ace',
                });
              } else if (clip.teamPlayerActionDict[playerId]?.block) {
                spotlightPlayers.push({
                  pl: players[playerId],
                  title: 'Block',
                });
              } else if (clip.teamPlayerActionDict[playerId]?.kill) {
                spotlightPlayers.push({
                  pl: players[playerId],
                  title: 'Kill',
                });
              }

              // if (clip.teamPlayerActionDict[playerId]?.highlight && clip.highlightDescription) {
              if (clip.teamPlayerActionDict[playerId]?.highlight) {
                highlightPlayers.push({
                  pl: players[playerId],
                  title: clip.highlightDescription,
                });
              }

              const tags = [];

              if (
                (clip.teamServingNAH === 2 && data.scoreWeAreHome === true) ||
                (clip.teamServingNAH === 1 && data.scoreWeAreHome === false)
              ) {
                if (k === 0) {
                  tags.push('S');
                }
              }

              if (clip.teamPlayerActionDict[playerId]?.dig) {
                tags.push('D');
              }

              if (clip.teamPlayerActionDict[playerId]?.assist) {
                tags.push('A');
              }

              if (clip.teamPlayerActionDict[playerId]?.highlight) {
                tags.push('H');
              }

              otherPlayers.push({
                tags,
                pl: players[playerId],
              });
            }
          }

          let primaryPlayerTitle;
          if (
            taggedPlayers.filter((x) => !x.includes('placeholder')).length >
              0 &&
            spotlightPlayers.length > 0
          ) {
            primaryPlayerTitle = spotlightPlayers.map((player, j) => (
              <Button
                key={`${j + 1}-spotlight-player-button`}
                plain
                color="secondary-2"
                label={
                  player.pl
                    ? `${player.pl.nameFirst} ${player.pl.nameLast.slice(
                        0,
                        1
                      )}${j < spotlightPlayers.length - 1 ? ',' : ''}`
                    : null
                }
                onClick={() => {
                  history.push(
                    `/${player.pl._id}?sport=vb&gameIds=${clip.J_teamGameId}`
                  );
                }}
              />
            ));
          }

          let highlightPlayersTitle;
          if (highlightPlayers.length > 0) {
            highlightPlayersTitle = highlightPlayers.map((player, j) => (
              <Button
                key={`${j + 1}-highlight-player-button`}
                plain
                color="secondary-2"
                label={
                  player.pl
                    ? `${player.pl.nameFirst} ${player.pl.nameLast.slice(
                        0,
                        1
                      )}${j < highlightPlayers.length - 1 ? ',' : ''}`
                    : null
                }
                onClick={() => {
                  history.push(
                    `/${player.pl._id}?sport=vb&gameIds=${clip.J_teamGameId}`
                  );
                }}
              />
            ));

            if (isTeamAdmin()) {
              const editDiv = (
                <Button
                  style={{ padding: '2px' }}
                  icon={<Edit size="18px" />}
                  onClick={() => {
                    setActiveClip(clip);
                    setShowVideo(true);
                    setShowReassignClip(true);
                  }}
                />
              );

              highlightPlayersTitle.push(editDiv);
            }
          }

          let secondaryPlayers = [];
          if (otherPlayers) {
            secondaryPlayers = otherPlayers.map((player, j) => (
              <Button
                key={`${j + 1}-button`}
                plain
                margin={{ right: 'xsmall' }}
                color="secondary-2"
                label={
                  <Box direction="row" align="center">
                    {player.tags.map((tag) => {
                      let badge = <Text size="small">{tag}</Text>;
                      let background = 'primary-7';
                      if (tag === 'H') {
                        badge = <Text size="small">🌟</Text>;
                        background = 'secondary-2';
                      } else if (tag === 'S') {
                        badge = <MdOutlineSportsVolleyball color="white" />;
                        background = 'tertiary-2';
                      }

                      return (
                        <Box
                          key={tag}
                          background={background}
                          width="1.4rem"
                          height="1.4rem"
                          round="full"
                          align="center"
                          justify="center"
                          margin={{ right: 'small' }}
                        >
                          {badge}
                        </Box>
                      );
                    })}
                    {player.pl ? (
                      <Text size="medium">
                        {` ${player.pl.nameFirst} ${player.pl.nameLast.slice(
                          0,
                          1
                        )}${j < otherPlayers.length - 1 ? ',' : ''}`}
                      </Text>
                    ) : null}
                  </Box>
                }
                onClick={() => {
                  history.push(
                    `/${player.pl._id}?sport=vb&gameIds=${clip.J_teamGameId}`
                  );
                }}
              />
            ));
          }
          const viewButton = (
            <Box>
              <Button
                plain
                color="secondary-2"
                label="View"
                onClick={() => {
                  setActiveClip(clip);
                  setShowVideo(true);
                  analytics.track('Game Page Clip Clicked', {
                    gameId,
                    clipId: clip.id,
                    loggedIn: !!userStore.authUser,
                    sport: 'vb',
                  });
                }}
              />
            </Box>
          );

          const lockButton = (
            <Button
              plain
              color="secondary-2"
              icon={<FormLock />}
              onClick={() => {
                setSelectedPlayerId(clip.J_teamPlayerList[0]);
                setShowUnclaimedModal(true);
              }}
            />
          );

          return (
            // eslint-disable-next-line react/jsx-key
            <Box
              pad={{ vertical: 'medium' }}
              border={{
                color: 'light-2',
                size: 'medium',
                side: 'bottom',
              }}
              background="white"
              direction="row"
              align="center"
            >
              <Box
                width="25%"
                align="center"
                justify="between"
                direction="row"
                pad={{ horizontal: 'small' }}
              >
                <Box align="center" direction="row">
                  <MdOutlineSportsVolleyball
                    style={{
                      color: clip.teamServingNAH === 1 ? 'black' : 'white',
                    }}
                  />
                  {clip.teamAScore}
                </Box>{' '}
                <span>-</span>{' '}
                <Box align="center" direction="row">
                  {clip.teamHScore}
                  <MdOutlineSportsVolleyball
                    style={{
                      color: clip.teamServingNAH === 2 ? 'black' : 'white',
                    }}
                  />
                </Box>
              </Box>
              <Box width="80%">
                <Box justify="between" align="baseline" gap="xsmall">
                  {spotlightPlayers.length > 0 && (
                    <Box direction="row" gap="small" align="baseline">
                      <Text key={clip.id} weight="bold" size="large">
                        {spotlightPlayers[0].title}
                      </Text>
                      {primaryPlayerTitle}
                    </Box>
                  )}
                  {highlightPlayers.length > 0 && (
                    <Box align="baseline" direction="row" gap="small" wrap>
                      <Text key={clip.id} weight="bold" size="large">
                        {clip.highlightDescription || 'Highlight'}
                      </Text>
                      <Box direction="row" gap="small" align="baseline">
                        {highlightPlayersTitle}
                      </Box>
                    </Box>
                  )}
                </Box>
                {secondaryPlayers.length > 0 && (
                  <Card elevation="none" background="light-3" pad="small">
                    <Text size="xsmall">On The Court:</Text>
                    <Box direction="row" wrap>
                      {secondaryPlayers}
                    </Box>
                  </Card>
                )}
              </Box>
              <Box width="18%" align="center">
                {clip.J_claimedPlayerList.length > 0 ||
                clip.J_teamPlayerList.length === 0
                  ? viewButton
                  : lockButton}
              </Box>
            </Box>
          );
        });

        const noClips = loading ? (
          <Box pad="medium">
            <Text>Loading</Text>
          </Box>
        ) : (
          <Box pad="medium">
            <Text>No clips for this set.</Text>
          </Box>
        );

        setDivs.push(
          <AccordionPanel
            key={set}
            header={renderPanelHeader(set, activeIndex.includes(i))}
          >
            <Box>{clipsDivs.length ? clipsDivs : noClips}</Box>
          </AccordionPanel>
        );
      }
    });

    if (setDivs.length === 0) {
      return (
        <Box
          direction="row"
          justify="center"
          align="center"
          height={{ min: 'large' }}
          fill
        >
          {loading ? (
            <Box pad="medium" fill>
              <Skeleton height={68} count={8} />
            </Box>
          ) : (
            <Text>No clips for this game.</Text>
          )}
        </Box>
      );
    }

    const { J_opponentName, scoreOurName } = gameData;

    const accordion = (
      <Box fill gap="medium">
        <ShareBar
          title={`${scoreOurName} vs ${J_opponentName} on sidelineHD`}
          text="Check out the play-by-play highlights from this game on sidelineHD."
          path={window.location.href}
        />
        <Accordion
          ref={accordionRef}
          activeIndex={activeIndex}
          onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
        >
          {setDivs}
        </Accordion>
      </Box>
    );

    return accordion;
  };

  const renderBoxScoreData = () => {
    if (boxScoreData.boxScore.length === 0) {
      return (
        <Box
          direction="row"
          justify="center"
          align="center"
          height={{ min: 'large' }}
          fill
        >
          <Text>No players were tagged in this game</Text>
        </Box>
      );
    }

    return (
      <BoxScore
        players={players}
        columns={boxScoreData.columns}
        data={boxScoreData.boxScore}
        gameData={gameData}
      />
    );
  };

  const renderMain = (data) => (
    <Box height="100%">
      {availableTabs[activeTabIndex] === 'recap' && (
        <Box background="light-1">
          {recapData && players && (
            <Recap players={players} data={recapData} canEdit={isTeamAdmin()} />
          )}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'boxScore' && (
        <Box background="light-1">
          {boxScoreData && players && renderBoxScoreData()}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'pbp' && (
        <Box background="light-1">
          <Box margin="small">
            {/* {(clips && gameData && players) ? renderSummary(clips, data) : <BasicLoader />} */}
            {renderSummary(clips, data)}
          </Box>
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'vod' && (
        <Box background="light-1">
          <Box margin="small" fill="vertical">
            <HlsPlayer
              hlsInfo={{ type: 'data', data: hlsResponse.m3u8 }}
              gameId={gameId}
            />
          </Box>
        </Box>
      )}
      {isTeamAdmin() && Object.entries(clips || {}).length > 0 && (
        <Referrals cardBackground="white" showSuccesses={false} />
      )}
    </Box>
  );

  const onClipChange = (newClip) => {
    const clipsBySet = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [set, clipsInSet] of Object.entries(clips)) {
      const newClips = clipsInSet.map((clip) => {
        if (clip.id === newClip.id) {
          return newClip;
        }
        return clip;
      });

      clipsBySet[set] = newClips;
    }

    setClips(clipsBySet);

    closeVideo();
  };

  const renderVideoLayer = () => {
    const taggedPlayers = activeClip.J_teamPlayerList.map(
      (pId) => players[pId]
    ).filter((x) => x);
    const inactivePlayerIds = Object.values(players)
      .filter((player) => !player.isActive)
      .map((player) => player.claimedPlayerId);
    const userPlayerIds = userStore.userPlayers.map((p) => p._id);
    const isInactive =
      inactivePlayerIds.filter((_id) => userPlayerIds.includes(_id)).length > 0;

    const claimedTaggedPlayers = taggedPlayers.filter(
      (player) => player.claimedPlayerId !== ''
    );

    let { publicPublishLevel } = gameData.team;
    if (claimedTaggedPlayers.length > 0) {
      // there are tagged claimed players, so use the lowest publicPublishLevel
      publicPublishLevel = Math.min(
        ...claimedTaggedPlayers.map((player) => player.publicPublishLevel)
      );
    } else if (taggedPlayers.length > 0) {
      // no claimed players tagged, so use the team players' publicPublishLevel
      publicPublishLevel = Math.min(
        ...taggedPlayers.map((player) => player.publicPublishLevel)
      );
    }

    if (isTeamAdmin()) {
      // team ADMINS can always see stuff
      publicPublishLevel = 10;
    }

    // console.log(claimedTaggedPlayers, anyPlayerPrivate, isTeamAdmin(), (anyPlayerPrivate && !isTeamAdmin()) ? 5 : 10);

    return (
      <Layer modal style={{ touchAction: 'none' }} onEsc={closeVideo}>
        <Box width="large">
          <Box direction="row" justify="end" fill="horizontal">
            <Button icon={<Close />} onClick={closeVideo} hoverIndicator />
          </Box>
          {/* {!!isTeamAdmin() && (
          <Card
            elevation="none"
            gap="small"
            background="light-3"
            pad="medium"
          >
            <Text>
              {`There are
                ${Object.entries(gameData.clips).map((obj) => obj[1].length)
                .reduce((a, b) => a + b, 0)} video clips for this game.
                At-bat videos are only available to ${gameData.scoreOurName} players, coaches, and team managers.`}
            </Text>
            <Button primary label="Login" onClick={() => { history.push('/signin'); }} />
          </Card>
          )} */}
          <VBPlayerClip
            key={activeClip.id}
            clip={activeClip}
            game={gameData}
            playerId={activeClip.J_claimedPlayerList[0]}
            isInactive={isInactive}
            teamPlayerName={
              taggedPlayers.length > 0
                ? getPrivacyName(
                    taggedPlayers[0].nameFirst,
                    taggedPlayers[0].nameLast,
                    taggedPlayers[0].embedNameFirstLevel,
                    taggedPlayers[0].embedNameLastLevel
                  )
                : ''
            }
            publicPublishLevel={publicPublishLevel}
            onChange={onClipChange}
            hideBottomBar={!isTeamAdmin()}
            showReassignClip={showReassignClip}
            setToast={setToast}
            inModal
          />
        </Box>
      </Layer>
    );
  };

  const unclaimedModal = (
    <Layer onEsc={() => setShowUnclaimedModal(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <Button
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowUnclaimedModal(false)}
          />
        </Box>
        <Box margin="medium" gap="large">
          <Text>
            This player is not claimed yet. Players must be claimed by their
            families before highlights can be viewed, shared, or downloaded.
          </Text>
          {selectedPlayerId && (
            <Button
              primary
              color="tertiary-0"
              id="claim-this-player"
              size="large"
              label={<Text weight="bold">Claim this player</Text>}
              onClick={() => {
                setShowClaim(true);
                setShowUnclaimedModal(false);
              }}
            />
          )}
        </Box>
      </Box>
    </Layer>
  );

  return (
    <Box fill>
      <ResponsiveGrid
        fill="horizontal"
        responsive
        rows={['auto', 'auto']}
        columns={['10%', '10%', '60%', '10%', '10%']}
        areas={{
          small: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'main', start: [0, 1], end: [4, 1] },
          ],
          medium: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [3, 1] },
            { name: 'right-bar', start: [4, 1], end: [4, 1] },
          ],
          large: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [1, 1] },
            { name: 'main', start: [2, 1], end: [2, 1] },
            { name: 'right-bar', start: [3, 1], end: [4, 1] },
          ],
        }}
      >
        <Box gridArea="header">{gameData && renderHeader(gameData)}</Box>
        <Box gridArea="left-bar" background="light-1" />
        <Box gridArea="main" background="light-1">
          {gameData && renderMain(gameData)}
        </Box>
        <Box gridArea="right-bar" background="light-1" />
      </ResponsiveGrid>
      {showVideo && renderVideoLayer()}
      {showUnclaimedModal && unclaimedModal}
      {showClaim && (
        <ClaimPlayerLayer
          teamPlayerId={selectedPlayerId}
          closeLayer={() => setShowClaim(false)}
        />
      )}
      {showPlayerPreview && (
        <PlayerPreview
          sport="vb"
          playerId={highlightPlayerId}
          gameId={gameId}
          onClose={() => {
            setShowPlayerPreview(false);
          }}
        />
      )}
      {toast && (
        <Toast
          icon={toast.icon}
          full="horizontal"
          label={toast.text}
          background="tertiary-0"
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Box>
  );
};

const VBGamePage = compose(withRouter, withFirebase)(Game);

export default VBGamePage;
