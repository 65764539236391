// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box } from 'grommet';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import BounceLoader from 'react-spinners/BounceLoader';
import { GROMMETTHEME } from '../constants/grommetTheme';

const BasicLoader = ({
  size = 100,
  fullPage = false,
  color = undefined,
  ...props
}) => {
  let cssOverride = { margin: '0 auto' };
  if (fullPage) {
    cssOverride = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  } else if (props.style) {
    cssOverride = { ...props.style };
  }
  return (
    <BounceLoader
      size={fullPage ? 200 : size}
      color={color || GROMMETTHEME.global.colors.brand.dark}
      cssOverride={cssOverride}
      {...props}
    />
  );
};

const FullScreenSkeleton = () => (
  <Box pad="medium" gap="large" margin="0 auto" width={{ max: 'large' }}>
    <Skeleton height={120} count={10} />
  </Box>
);

export { BasicLoader, FullScreenSkeleton };
