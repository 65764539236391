// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Box, Button, Card, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import { FormNext } from 'grommet-icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import Toast from '../../components/Toast';
import HighlightVideoPlayer from '../../helpers/HighlightVideoPlayer';
import axios from '../../helpers/axios';
import withLayout from '../../helpers/withLayout';
import ShareDropdown from '../../helpers/ShareDropdown';
import { analytics } from '../../services/analytics';

const HitMatch = ({ history }) => {
  const { id } = useParams();
  const [hitMatchData, setHitMatchData] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchHitMatchData = async () => {
      try {
        const response = await axios.get(`/api/hitmatch/${id}`);
        setHitMatchData(response.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchHitMatchData();

    analytics.track('HitMatch Page Viewed');
  }, [id]);

  const renderShareOptions = () => (
    <Box pad="medium" gap="small">
      <CopyToClipboard
        text={window.location.href}
        onCopy={() => {
          analytics.track('HitMatch Share Link Copied');
          setCopied(true);
        }}
      >
        <Button label="Copy link to clip" />
      </CopyToClipboard>
    </Box>
  );

  if (!hitMatchData) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box margin="large" gap="medium">
      <Box>
        <Card
          pad="medium"
          gap="medium"
          background="primary-8"
          onClick={() => {
            history.push(`/${hitMatchData.claimedPlayerId}`);
          }}
        >
          <Box direction="row" justify="between">
            <Text id="player-name-header" size="xlarge" weight="bold">
              {hitMatchData.playerName}
            </Text>
            <Button
              alignSelf="center"
              justify="end"
              plain
              reverse
              gap="xxsmall"
              label={<Text size="small">More videos</Text>}
              icon={<FormNext />}
              onClick={() => {
                analytics.track('HitMatch More Videos Clicked');
                history.push(`/${hitMatchData.claimedPlayerId}`);
              }}
            />
          </Box>
        </Card>
      </Box>
      <Box>
        <Text>
          <Text weight="bold">{hitMatchData.playerName}</Text>
          <Text>{' and '}</Text>
          <Text weight="bold">{hitMatchData.heroName}</Text>
        </Text>
      </Box>
      <Box fill>
        <HighlightVideoPlayer
          id={id}
          videoSrc={hitMatchData.fileUrl720}
          category="player"
          location="hitmatch_page"
          isHitMatch
        />
        <Box fill align="start">
          <ShareDropdown
            plain
            label={<Text size="small">Share</Text>}
            color="secondary-1"
            renderShareOptions={renderShareOptions}
          />
        </Box>
      </Box>
      {/* <Box fill margin={{ top: 'large' }}>
        <Button
          size="large"
          primary
          color="tertiary-1"
          label="See full clip"
          onClick={() => {
            history.push(`/${hitMatchData.claimedPlayerId}/c/${hitMatchData.claimedPlayerId}`);
          }}
        />
      </Box> */}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};

export default withRouter(withLayout(HitMatch));
