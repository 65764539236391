"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserPath = exports.getStreamHealthStatsPath = exports.getTelemetryPath = exports.getTeamAppCameraPath = exports.getUserStatusPath = exports.getRemoteLoginWebviewTokenPath = exports.getRemoteLoginPreauthorizePath = exports.getRemoteLoginAuthorizePath = exports.getRemoteLoginStatusPath = exports.getRemoteLoginAskPath = void 0;
const getRemoteLoginAskPath = () => "/api/remote_login/ask";
exports.getRemoteLoginAskPath = getRemoteLoginAskPath;
const getRemoteLoginStatusPath = (statusKey) => `/api/remote_login/status/${statusKey}`;
exports.getRemoteLoginStatusPath = getRemoteLoginStatusPath;
const getRemoteLoginAuthorizePath = () => "/api/remote_login/authorize";
exports.getRemoteLoginAuthorizePath = getRemoteLoginAuthorizePath;
const getRemoteLoginPreauthorizePath = () => "/api/remote_login/preauthorize";
exports.getRemoteLoginPreauthorizePath = getRemoteLoginPreauthorizePath;
const getRemoteLoginWebviewTokenPath = () => "/api/remote_login/webview_token";
exports.getRemoteLoginWebviewTokenPath = getRemoteLoginWebviewTokenPath;
const getUserStatusPath = () => "/api/user_status";
exports.getUserStatusPath = getUserStatusPath;
const getTeamAppCameraPath = (teamId, platform) => `/api/team/${teamId}/app_camera?platform=${platform}`;
exports.getTeamAppCameraPath = getTeamAppCameraPath;
const getTelemetryPath = () => "/api/logging/send_telemetry";
exports.getTelemetryPath = getTelemetryPath;
const getStreamHealthStatsPath = (teamId) => `/api/stream_health/${teamId}`;
exports.getStreamHealthStatsPath = getStreamHealthStatsPath;
const getUserPath = (userId) => userId ? `/api/user/${userId}` : "/api/user";
exports.getUserPath = getUserPath;
__exportStar(require("./generated/apis/api/apis"), exports);
