/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Text, Box, Form } from 'grommet';
import * as Sentry from '@sentry/browser';
import { Close } from 'grommet-icons';
import axios from '../../helpers/axios';
import {
  FieldCaptureName,
  FieldCameraType,
  FieldTargetTeam,
} from '../../helpers/FormFields';
import { BasicLoader } from '../../helpers/Loaders';
import SHDButton from '../../components/SHD/Button';
import { dtos, docStorage } from '@shd/jslib/models';

interface Props {
  editCapture: (capture: docStorage.models.Capture) => void;
  closeLayer: () => void;
  capture: docStorage.models.Capture;
}
interface ThinnedTeam {
  label: string;
  value: string;
}

const EditCapture: React.FC<Props> = (props) => {
  const { editCapture, closeLayer, capture } = props;
  const [loading, toggleLoading] = useState(true);
  const [teamList, setTeamList] = useState<ThinnedTeam[]>([]);
  const [value, setValue] = useState({ ...capture });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get<dtos.CaptureGetResponse>(`/api/capture/${capture._id}`)
      .then((response) => {
        const { teamList: newTeamList } = response.data;
        const thinnedTeamList = newTeamList.map((team) => ({
          label: team.nameLong,
          value: team._id,
        }));
        setTeamList(thinnedTeamList);
        toggleLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError(
          'Cannot edit camera at this time.  Make sure your camera is not currently streaming.'
        );
        toggleLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveEdit = () => {
    editCapture(value);
    closeLayer();
  };

  if (loading) {
    return (
      <Box fill align="center" justify="center" gap="medium" pad="medium">
        <BasicLoader />
        <Text>Loading your camera info...</Text>
      </Box>
    );
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Card round={false} elevation="none" fill>
      <CardHeader direction="row" pad="small" align="center" justify="between">
        <Box width="24px" />
        <Box justify="center" align="center">
          <Text alignSelf="center" size="large" weight="bold">
            Choose your livestreaming camera
          </Text>
        </Box>
        <SHDButton icon={<Close />} onClick={closeLayer} justify="end" />
      </CardHeader>
      <CardBody fill>
        <Box gap="small" pad="medium">
          <Form
            value={value}
            onChange={(nextValue) => setValue(nextValue)}
            onReset={() => setValue(capture)}
            onSubmit={saveEdit}
          >
            <FieldCaptureName />
            <FieldCameraType equipCamera={value.equipCamera} />
            <FieldTargetTeam teamList={teamList} />
            <Box fill="horizontal" direction="row" margin={{ top: 'large' }}>
              <SHDButton
                fill
                primary
                size="large"
                type="submit"
                label="Save"
                disabled={JSON.stringify(capture) === JSON.stringify(value)}
              />
            </Box>
          </Form>
        </Box>
      </CardBody>
    </Card>
  );
};

export default EditCapture;
