/* eslint-disable no-underscore-dangle */
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  EffectCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import md5 from 'md5';
import { shd } from '@shd/jslib/models';
import UserStore from '../services/Session/store';
import { remoteFlags } from '@shd/jslib/ui';
import {
  FlagsContext,
  WebRemoteConfigParam,
} from '../services/FlagsProvider/FlagsProvider';
import { format } from 'date-fns';
import { isMobile } from './browserDetect';
import { UserType } from '../services/analytics';
import { strogging } from '@shd/jslib/infra';
import * as Sentry from '@sentry/browser';

export const round5 = (x: number) =>
  x % 5 >= 2.5 ? (x / 5) * 5 + 5 : (x / 5) * 5;

export const formatDuration = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedHrs = hrs < 10 ? `0${hrs}` : hrs;
  let formattedMins = mins < 10 ? `0${mins}` : mins;
  const formattedSecs = secs < 10 ? `0${secs}` : secs;

  if (hrs === 0) {
    formattedMins = mins;
    return `${formattedMins}:${formattedSecs}`;
  }
  return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
};

export const formatDateShort = (ts: number) => {
  const date = new Date(ts * 1000);
  // November 18, 2020 @ 12:00 pm
  return format(date, 'MMM d, yyyy @ h:mm a');
};

export const getTimeAgoTxt = (seconds: number) => {
  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
};

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
};

export const getPrivacyName = (
  nameFirst: string,
  nameLast: string,
  embedNameFirstLevel: number,
  embedNameLastLevel: number
) => {
  let displayName;
  if (embedNameFirstLevel + embedNameLastLevel < 2) {
    displayName = '';
  } else {
    let displayNameFirst;
    let displayNameLast;
    if (!nameFirst) {
      displayNameFirst = '';
    } else if (embedNameFirstLevel === 2) {
      displayNameFirst = nameFirst;
    } else if (embedNameFirstLevel === 1) {
      displayNameFirst = `${nameFirst.slice(0, 1)}.`;
    } else {
      displayNameFirst = '';
    }

    if (!nameLast) {
      displayNameLast = '';
    } else if (embedNameLastLevel === 2) {
      displayNameLast = nameLast;
    } else if (embedNameLastLevel === 1) {
      displayNameLast = `${nameLast.slice(0, 1)}.`;
    } else {
      displayNameLast = '';
    }

    if (displayNameFirst.includes('.') && displayNameLast.includes('.')) {
      displayName = `${displayNameFirst}${displayNameLast}`;
    } else if (displayNameFirst && displayNameLast) {
      displayName = `${displayNameFirst} ${displayNameLast}`;
    } else if (displayNameFirst || displayNameLast) {
      displayName = displayNameFirst || displayNameLast;
    } else {
      displayName = '';
    }
  }
  return displayName;
};

export const formatInningNum = (inningNum: number) => {
  const num = Math.round(Math.floor(inningNum));
  let txt;
  if (num === 1) {
    txt = 'st';
  } else if (num === 2) {
    txt = 'nd';
  } else if (num === 3) {
    txt = 'rd';
  } else if (num > 3) {
    txt = 'th';
  } else {
    txt = 'th';
  }
  return `${num}${txt}`;
};

export const formatHalfInningNum = (inningNum: number | string) => {
  const num = Math.round(Math.floor(Number(inningNum)));
  let txt;
  if (num === 1) {
    txt = 'st';
  } else if (num === 2) {
    txt = 'nd';
  } else if (num === 3) {
    txt = 'rd';
  } else if (num > 3) {
    txt = 'th';
  } else {
    txt = 'th';
  }

  let prefix;
  if (inningNum.toString().includes('.5')) {
    prefix = '🔽';
  } else {
    prefix = '🔼';
  }
  return `${prefix} ${num}${txt}`;
};

export const compareJerseyNum = (a: shd.TeamPlayer, b: shd.TeamPlayer) => {
  const parsedA = parseInt(a.jerseyNum);
  const parsedB = parseInt(b.jerseyNum);

  const first = Number.isNaN(parsedA) ? Number.MAX_SAFE_INTEGER : parsedA;
  const second = Number.isNaN(parsedB) ? Number.MAX_SAFE_INTEGER : parsedB;
  if (first < second) {
    return -1;
  }
  if (first > second) {
    return 1;
  }

  return 0;
};

export const calculateTimeLeft = (endTs: number) => {
  const diff = endTs - new Date().getTime() / 1000;
  let timeLeft = {};

  if (diff > 0) {
    timeLeft = {
      days: Math.floor(diff / (60 * 60 * 24)),
      hours: Math.floor((diff / (60 * 60)) % 24),
      minutes: Math.floor((diff / 60) % 60),
      seconds: Math.floor(diff % 60),
    };
  }
  return timeLeft;
};

export const insertDisplayName = (player: shd.TeamPlayer) => {
  let displayName = '';
  let displayNameFull = '';
  if (player.nameFirst && player.nameLast) {
    displayName = `${player.nameFirst} ${player.nameLast}`;
  } else {
    displayName = player.nameFirst || player.nameLast;
  }
  if (displayName && player.jerseyNum) {
    displayNameFull = `#${player.jerseyNum} ${displayName}`;
  } else if (player.jerseyNum) {
    displayNameFull = `#${player.jerseyNum}`;
  } else {
    displayNameFull = displayName;
  }

  return { ...player, displayName: displayNameFull };
};

export const getReadableInning = (i: number) => {
  const remainder = i % 2;
  const readable = (i - remainder) / 2 + 1;
  return { topBottom: remainder, inning: readable };
};

export const getInternalInning = (inningNum: number, topBottom: number) =>
  (inningNum - 1) * 2 + topBottom;

export const removeItemOnce = <T>(arr: T[], value: T) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const secondsToDaysAndHours = (seconds: string | number) => {
  let s = typeof seconds === 'string' ? parseInt(seconds) : seconds;

  const days = Math.floor(s / (3600 * 24));
  s -= days * 3600 * 24;
  const hours = Math.floor(s / 3600);
  return { days, hours };
};

export const hashClipFilenameToKey = (fileName: string) => {
  const encodedId = Buffer.from(fileName, 'utf8');
  const hashed = Uint8Array.from(Buffer.from(md5(encodedId), 'hex'));

  const alphabet =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXYZ';
  const hashOut = Array(hashed.length)
    .fill(0)
    .map((_, i) => alphabet[hashed[i] % alphabet.length])
    .join('');
  return hashOut;
};

export const getReferralCode = (userId: string) => {
  const encodedId = Buffer.from(userId, 'utf8');
  const hashed = Uint8Array.from(Buffer.from(md5(encodedId), 'hex'));

  const alphabet =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXYZ';
  const hashOut = Array(hashed.length)
    .fill(0)
    .map((_, i) => alphabet[hashed[i] % alphabet.length])
    .join('');
  return hashOut.slice(6);
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useRemoteConfigValue = (name: WebRemoteConfigParam) => {
  const context = useContext(FlagsContext);
  const { flags } = context;
  return flags[name];
};

export const useIsFeatureEnabled = <K extends WebRemoteConfigParam>(
  feature: K,
  userContext: UserStore,
  teamIds: string[] = []
) => {
  return remoteFlags.useFeatureEnabled(
    feature,
    FlagsContext,
    userContext.authUser?.claims?.shd_user_id as string | undefined,
    userContext.user.email,
    userContext.selectedTeam?._id,
    teamIds
  );
};

export const useIsFeatureEnabledValue = <K extends WebRemoteConfigParam>(
  feature: K,
  userContext: UserStore,
  teamIds: string[] = []
) => {
  return remoteFlags.useFeatureEnabledValue(
    feature,
    FlagsContext,
    userContext.authUser?.claims?.shd_user_id as string | undefined,
    userContext.user.email,
    userContext.selectedTeam?._id,
    teamIds
  );
};

export const getCurrentSetScoreAndIndex = (setScores: number[]) => {
  let currentIndex = 0;
  // TODO: maybe refactor -- is it just the last item in the array?
  // eslint-disable-next-line no-unreachable-loop
  for (let i = 0; i < setScores.length; i += 1) {
    const index = setScores.length - 1 - i;

    currentIndex = index;
    break;
  }

  let currentScore = setScores[currentIndex];
  if (currentScore === -1) {
    currentScore = 0;
  }

  return { currentScore, currentIndex };
};

export const getCurrentPeriodScoreAndIndex = (periodScores: number[]) => {
  let currentIndex = 0;
  // TODO: maybe refactor -- is it just the last item in the array?
  // eslint-disable-next-line no-unreachable-loop
  for (let i = 0; i < periodScores.length; i += 1) {
    const index = periodScores.length - 1 - i;

    currentIndex = index;
    break;
  }

  let currentScore = periodScores[currentIndex];
  if (currentScore === -1) {
    currentScore = 0;
  }

  return { currentScore, currentIndex };
};

export const getMatchScores = (
  teamASetScores: number[],
  teamHSetScores: number[]
) => {
  let homeScore = 0;
  let awayScore = 0;
  if (teamASetScores && teamHSetScores) {
    Array.from(Array(teamASetScores.length - 1).keys()).forEach((i) => {
      const awaySetScore = teamASetScores[i];
      const homeSetScore = teamHSetScores[i];

      if (homeSetScore >= 0 && awaySetScore >= 0) {
        if (homeSetScore > awaySetScore) {
          homeScore += 1;
        } else if (homeSetScore < awaySetScore) {
          awayScore += 1;
        }
      }
    });
  }

  return {
    teamAMatchScore: awayScore,
    teamHMatchScore: homeScore,
  };
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return () => {};
    }

    function tick() {
      savedCallback.current?.();
    }
    savedCallback.current?.();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useEffectOnce = (effect: EffectCallback) => useEffect(effect, []);

export const arraysEqual = <T>(a: T[], b: T[]) => {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

export const getRequestFullScreenKey = () => {
  let goFullScreen = 'requestFullscreen';
  if ('mozRequestFullScreen' in document.documentElement) {
    goFullScreen = 'mozRequestFullScreen';
  } else if ('webkitRequestFullscreen' in document.documentElement) {
    goFullScreen = 'webkitRequestFullscreen';
  } else if ('msRequestFullscreen' in document.documentElement) {
    goFullScreen = 'msRequestFullscreen';
  }
  return goFullScreen;
};

export const isPortraitMode = () =>
  window.matchMedia('(orientation: portrait)').matches;

export const getOrientation = () =>
  isPortraitMode() ? 'portrait' : 'landscape';

export function useOrientation(opts: {
  onPortrait?: () => void;
  onLandscape?: () => void;
}) {
  const { onPortrait, onLandscape } = opts;
  const orientation = getOrientation();
  const orientationRef = useRef(orientation);

  const [isPortrait, setIsPortrait] = useState(isPortraitMode());

  const onResize = useCallback(() => {
    if (getOrientation() === orientationRef.current) {
      return;
    }

    orientationRef.current = getOrientation();
    setIsPortrait(isPortraitMode());

    const callback = isPortraitMode() ? onPortrait : onLandscape;
    callback?.();
  }, [onPortrait, onLandscape]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    if (!isPortraitMode()) {
      return;
    }
    onPortrait?.();
  }, [onPortrait]);

  return { isPortrait };
}

export function updateAggregateValues(
  stats: {
    count: number;
    mean: number;
    m2: number;
    stddev: number;
    min: number;
    max: number;
  },
  value: number | null | undefined
) {
  if (value === null || value === undefined) {
    return stats;
  }

  let { count, mean, m2, stddev, min, max } = stats;

  if (value < min) {
    min = value;
  }
  if (value > max) {
    max = value;
  }
  count += 1;
  const delta = value - mean;
  mean += delta / count;
  const delta2 = value - mean;
  m2 += delta * delta2;
  if (count < 2) {
    stddev = 0;
  } else {
    stddev = Math.sqrt(m2 / (count - 1));
  }
  return {
    count,
    mean,
    m2,
    stddev,
    min,
    max,
  };
}

export function renderAsync<T, U, TError = Error>(
  loading: boolean,
  error: TError,
  result: T | undefined,
  renderings: {
    loading: () => U;
    error: (err: TError) => U;
    result: (res: T) => U;
    notRequested?: () => U;
  }
): U | null {
  if (loading) {
    return renderings.loading();
  }
  if (error) {
    return renderings.error(error);
  }
  if (result) {
    return renderings.result(result);
  }
  // if we don't handle the not requested case, we should return null
  return renderings.notRequested ? renderings.notRequested() : null;
}

export const getVeWorkerOverlayUrl = (
  assignedUrlOvl = '',
  nginxAddrLocal = '',
  nginxAddrExternal = ''
) => {
  const overlayUrl = assignedUrlOvl.replace(nginxAddrLocal, nginxAddrExternal);
  // if we are on mobile, we create a vlc url, else on desktop the
  // rtmp url works ok
  if (isMobile.any()) {
    return `vlc-x-callback://x-callback-url/stream?url=${overlayUrl}`;
  }
  return overlayUrl;
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getTeamAgeType = (age: number | string, level: string): string => {
  let result = `${age}U`;

  if (age === 0 || age === -1 || age === '') {
    result = level ? toTitleCase(level) : '';
  } else if (age === 99) {
    result = 'Adult League';
  }

  return result;
};

export interface ShareData {
  title?: string;
  text?: string;
  url: string;
}

export const sharePage = (shareData: ShareData) => {
  const { title, text, url } = shareData;
  if (navigator.canShare({ title, text, url })) {
    navigator.share({ title, text, url }).catch((error) => {
      strogging.log('sharePage utils - navigator.share error', error);
      Sentry.captureException(error);
    });
  }
};

export const getUserTypeRelativeToTeam = (
  teamId: string,
  userStore: UserStore
) => {
  const teamIds = userStore.userTeams.map((userTeam) => userTeam._id);
  const isAdmin = teamIds.includes(teamId);

  let userType;
  if (!userStore.authUser) {
    userType = UserType.LoggedOut;
  } else if (isAdmin) {
    userType = UserType.Admin;
  } else if (userStore.teamFollowerIds.concat(teamIds).includes(teamId)) {
    userType = UserType.CommunityMember;
  } else {
    userType = UserType.LoggedIn;
  }
  return userType;
};

export const isFirefox = navigator.userAgent.includes('Firefox');

export const toSentenceCase = (str: string) => {
  return str !== null ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

export const pluralize = (count: number, singular: string, plural: string) => {
  return count === 1 ? `${count} ${singular}` : `${count} ${plural}`;
};

export const shouldPromptForAccountInfo = (
  displayName?: string | null,
  nameFirst?: string,
  nameLast?: string
) => {
  let tokenizedName;
  if (displayName) {
    tokenizedName = displayName.split(' ');
  } else {
    tokenizedName = [nameFirst || '', nameLast || ''];
  }
  const tokenizedNameFirst = tokenizedName.length > 0 ? tokenizedName[0] : '';
  const tokenizedNameLast = tokenizedName.length > 1 ? tokenizedName[1] : '';

  const unknownName =
    tokenizedNameFirst.toLocaleLowerCase() === 'unknown' ||
    tokenizedNameLast.toLocaleLowerCase() === 'signin';

  if (unknownName) {
    return true;
  }
  return false;
};

export const queryParamToBool = (param: string | null): boolean => {
  return param === 'true' || param === '1';
};

export const getRevenueCatUserId = (userId: string | undefined): string => {
  if (!userId) {
    return '';
  }
  const isProd = process.env.REACT_APP_ENV === 'production';
  return isProd ? userId : `dev__${userId}`;
};
