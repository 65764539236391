import { CURRENT_SPORTS } from '../../../constants/strings';
import { shd } from '@shd/jslib/models';
import { TeamsProps } from './home';
import { UserContext } from '../../../services/Session';
import React, { useContext, useMemo } from 'react';
import TeamCard from '../../../components/TeamCard/TeamCard';

const Teams: React.FC<TeamsProps> = ({ teams, selected }) => {
  const userStore = useContext(UserContext);
  const filteredTeams = useMemo(() => {
    return teams.filter((team) => {
      const selectedSports = selected.map(
        (index) => CURRENT_SPORTS[index].display
      );
      if (selectedSports.includes(team.attrib_sportType) || !selected.length) {
        return true;
      } else {
        return false;
      }
    });
  }, [teams, selected]);

  const getSelectedTeamInStore = (team: shd.Team): shd.Team => {
    return userStore.selectedTeam?._id === team._id
      ? userStore.selectedTeam
      : team;
  };

  // NOTE: I don't think this is necessary, but I am unsure (NKS 2024-05-28)
  if (userStore.selectedTeam) {
    const selectedTeamIndex = filteredTeams.findIndex(
      (team) => team._id === userStore.selectedTeam?._id
    );
    if (selectedTeamIndex > -1) {
      const [selectedTeam] = filteredTeams.splice(selectedTeamIndex, 1);
      filteredTeams.unshift(selectedTeam);
    }
  }

  return filteredTeams.map((team, i) => {
    return (
      <TeamCard key={`team-${i + 1}`} team={getSelectedTeamInStore(team)} />
    );
  });
};

export default Teams;
