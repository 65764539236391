import React from 'react';
import Hls from 'hls.js';
import { strogging } from '@shd/jslib/infra';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import '../../helpers/clipVideoPlayer.css';

import * as Sentry from '@sentry/react';
import { isiOS } from '../../helpers/browserDetect';
import { HlsInfo } from './types';

function getIosUriSource(uri: string) {
  return uri;
}
function getIosDataSource(
  data: string,
  hlsMimeType: string = 'application/vnd.apple.mpegurl'
) {
  return `data:${hlsMimeType};base64,${btoa(data)}`;
}

function getHlsJsUriSource(uri: string) {
  return uri;
}
function getHlsJsDataSource(data: string) {
  const enc = new TextEncoder();
  const encoded = enc.encode(data);
  const blob = new Blob([encoded]);
  return URL.createObjectURL(blob);
}
function getHlsJsSource(hlsInfo: HlsInfo) {
  return hlsInfo.type === 'uri'
    ? getHlsJsUriSource(hlsInfo.uri)
    : getHlsJsDataSource(hlsInfo.data);
}
function getIosVideoSource(
  hlsInfo: HlsInfo,
  hlsMimeType: string = 'application/vnd.apple.mpegurl'
) {
  return hlsInfo.type === 'uri'
    ? getIosUriSource(hlsInfo.uri)
    : getIosDataSource(hlsInfo.data, hlsMimeType);
}

export function useHls(
  videoRef: React.RefObject<HTMLVideoElement>,
  hlsInfo: HlsInfo | undefined,
  initialError: string | undefined
) {
  const hls = React.useRef<Hls | null>(null);
  const [error, setError] = React.useState<string | undefined>(initialError);
  const [playerEvent, setPlayerEvent] = React.useState<string | undefined>();
  const heavyHlsLogging = true;
  React.useEffect(() => {
    if (videoRef.current && hlsInfo) {
      const video = videoRef.current;
      if (isiOS() && video.canPlayType('application/vnd.apple.mpegurl')) {
        strogging.log('setting up hls for iOS');
        video.src = getIosVideoSource(hlsInfo);
      } else if (Hls.isSupported()) {
        hls.current = new Hls();
        hls.current.loadSource(getHlsJsSource(hlsInfo));
        hls.current.attachMedia(video);
        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
          if (heavyHlsLogging) {
            strogging.log('hls manifest parsed');
          }
          // threw a runtime exception
          // video.play();
        });
        hls.current.on(Hls.Events.MEDIA_ATTACHING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsMediaAttaching');
          }
        });
        hls.current.on(Hls.Events.MEDIA_ATTACHED, () => {
          setPlayerEvent('hlsMediaAttached');
          if (heavyHlsLogging) {
            strogging.log('hlsMediaAttached');
          }
        });
        hls.current.on(Hls.Events.MEDIA_DETACHING, () => {
          setPlayerEvent('hlsMediaDetaching');
          if (heavyHlsLogging) {
            strogging.log('hlsMediaDetaching');
          }
        });
        hls.current.on(Hls.Events.MEDIA_DETACHED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsMediaDetached');
          }
        });
        hls.current.on(Hls.Events.BUFFER_RESET, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferReset');
          }
        });
        hls.current.on(Hls.Events.BUFFER_CODECS, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferCodecs');
          }
        });
        hls.current.on(Hls.Events.BUFFER_CREATED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferCreated');
          }
        });
        hls.current.on(Hls.Events.BUFFER_APPENDING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferAppending');
          }
        });
        hls.current.on(Hls.Events.BUFFER_APPENDED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferAppended');
          }
        });
        hls.current.on(Hls.Events.BUFFER_EOS, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferEos');
          }
        });
        hls.current.on(Hls.Events.BUFFER_FLUSHING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferFlushing');
          }
        });
        hls.current.on(Hls.Events.BUFFER_FLUSHED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBufferFlushed');
          }
        });
        hls.current.on(Hls.Events.MANIFEST_LOADING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsManifestLoading');
          }
        });
        hls.current.on(Hls.Events.MANIFEST_LOADED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsManifestLoaded');
          }
        });
        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsManifestParsed');
          }
        });
        hls.current.on(Hls.Events.LEVEL_SWITCHING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelSwitching');
          }
        });
        hls.current.on(Hls.Events.LEVEL_SWITCHED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelSwitched');
          }
        });
        hls.current.on(Hls.Events.LEVEL_LOADING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelLoading');
          }
        });
        hls.current.on(Hls.Events.LEVEL_LOADED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelLoaded');
          }
        });
        hls.current.on(Hls.Events.LEVEL_UPDATED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelUpdated');
          }
        });
        hls.current.on(Hls.Events.LEVEL_PTS_UPDATED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelPtsUpdated');
          }
        });
        hls.current.on(Hls.Events.LEVELS_UPDATED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLevelsUpdated');
          }
        });
        hls.current.on(Hls.Events.AUDIO_TRACKS_UPDATED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsAudioTracksUpdated');
          }
        });
        hls.current.on(Hls.Events.AUDIO_TRACK_SWITCHING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsAudioTrackSwitching');
          }
        });
        hls.current.on(Hls.Events.AUDIO_TRACK_SWITCHED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsAudioTrackSwitched');
          }
        });
        hls.current.on(Hls.Events.AUDIO_TRACK_LOADING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsAudioTrackLoading');
          }
        });
        hls.current.on(Hls.Events.AUDIO_TRACK_LOADED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsAudioTrackLoaded');
          }
        });
        hls.current.on(Hls.Events.SUBTITLE_TRACKS_UPDATED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsSubtitleTracksUpdated');
          }
        });
        hls.current.on(Hls.Events.SUBTITLE_TRACKS_CLEARED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsSubtitleTracksCleared');
          }
        });
        hls.current.on(Hls.Events.SUBTITLE_TRACK_SWITCH, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsSubtitleTrackSwitch');
          }
        });
        hls.current.on(Hls.Events.SUBTITLE_TRACK_LOADING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsSubtitleTrackLoading');
          }
        });
        hls.current.on(Hls.Events.SUBTITLE_TRACK_LOADED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsSubtitleTrackLoaded');
          }
        });
        hls.current.on(Hls.Events.SUBTITLE_FRAG_PROCESSED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsSubtitleFragProcessed');
          }
        });
        hls.current.on(Hls.Events.CUES_PARSED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsCuesParsed');
          }
        });
        hls.current.on(Hls.Events.NON_NATIVE_TEXT_TRACKS_FOUND, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsNonNativeTextTracksFound');
          }
        });
        hls.current.on(Hls.Events.INIT_PTS_FOUND, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsInitPtsFound');
          }
        });
        hls.current.on(Hls.Events.FRAG_LOADING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragLoading');
          }
        });
        hls.current.on(Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragLoadEmergencyAborted');
          }
        });
        hls.current.on(Hls.Events.FRAG_LOADED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragLoaded');
          }
        });
        hls.current.on(Hls.Events.FRAG_DECRYPTED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragDecrypted');
          }
        });
        hls.current.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragParsingInitSegment');
          }
        });
        hls.current.on(Hls.Events.FRAG_PARSING_USERDATA, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragParsingUserdata');
          }
        });
        hls.current.on(Hls.Events.FRAG_PARSING_METADATA, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragParsingMetadata');
          }
        });
        hls.current.on(Hls.Events.FRAG_PARSED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragParsed');
          }
        });
        hls.current.on(Hls.Events.FRAG_BUFFERED, (event, data) => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragBuffered', { url: data?.frag?.url });
          }
        });
        hls.current.on(Hls.Events.FRAG_CHANGED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFragChanged');
          }
        });
        hls.current.on(Hls.Events.FPS_DROP, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFpsDrop');
          }
        });
        hls.current.on(Hls.Events.FPS_DROP_LEVEL_CAPPING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsFpsDropLevelCapping');
          }
        });
        hls.current.on(Hls.Events.DESTROYING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsDestroying');
          }
        });
        hls.current.on(Hls.Events.KEY_LOADING, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsKeyLoading');
          }
        });
        hls.current.on(Hls.Events.KEY_LOADED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsKeyLoaded');
          }
        });
        hls.current.on(Hls.Events.LIVE_BACK_BUFFER_REACHED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsLiveBackBufferReached');
          }
        });
        hls.current.on(Hls.Events.BACK_BUFFER_REACHED, () => {
          if (heavyHlsLogging) {
            strogging.log('hlsBackBufferReached');
          }
        });
        hls.current.on(Hls.Events.ERROR, (event, data) => {
          strogging.error('hls error', {
            event,
            data,
            hlsInfo,
          });
          if (data.fatal) {
            if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
              setError('Failed to load the manifest');
            }
          }
        });
      } else {
        Sentry.captureException(
          "This is a legacy browser that doesn't support MSE"
        );
        video.src = getIosVideoSource(hlsInfo, 'audio/x-mpegURL');
      }
    }

    return () => {
      if (hls.current) {
        hls.current.destroy();
      }
    };
  }, [heavyHlsLogging, hlsInfo, videoRef]);

  return React.useMemo(
    () => ({ error, setError, playerEvent, setPlayerEvent }),
    [error, setError, playerEvent, setPlayerEvent]
  );
}
