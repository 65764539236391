import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Offering,
  Offerings,
  Purchases,
  PurchasesError,
  ErrorCode,
} from '@revenuecat/purchases-js';
import {
  ActiveSubscription,
  PackageName,
  SubscriptionOffering,
  Terms,
  PaywallContext,
  PaywallEvents,
} from './paywall.types';
import { strogging } from '@shd/jslib/infra';
import { AppHostContext } from '../../services/appHost';
import { analytics, Dict, UserType } from '../../services/analytics';
import { UserContext } from '../../services/Session';
import { usePlayerPrimaryInfo } from '../../features/Player/playerHooks';
import axios from '../../helpers/axios';
import { getUserTypeRelativeToTeam } from '../../helpers/utils';

export const useRevenueCat = () => {
  const packageName = PackageName.PRO;
  const isConfigured = Purchases.isConfigured();

  const [isLoadingRc, setIsLoadingRc] = useState<boolean>(true);
  const [monthlyPrice, setMonthlyPrice] = useState<string>('');
  const [annualPrice, setAnnualPrice] = useState<string>('');
  const [isEntitled, setIsEntitled] = useState<boolean>(false);
  const [offerings, setOfferings] = useState<Offerings>();
  const [activeSubscription, setActiveSubscription] =
    useState<ActiveSubscription>({
      productIdentifier: '',
      productName: '',
      productPrice: '',
      productTerm: {
        short: '',
        long: '',
      },
    });

  const setDefaultPurchase = useCallback(async () => {
    if (!isConfigured) {
      Purchases.configure(
        process.env.REACT_APP_REVENUECAT_BILLING_KEY || '',
        'dk_internal'
      );
    }
  }, [isConfigured]);

  const fetchPrices = useCallback(async () => {
    if (!isConfigured) {
      setDefaultPurchase();
    }

    const slhdOfferings: Offerings =
      await Purchases.getSharedInstance().getOfferings();
    const slhdOffering: Offering | null = slhdOfferings.current;

    setOfferings(slhdOfferings);

    if (slhdOffering) {
      const monthly =
        slhdOffering.monthly?.rcBillingProduct.currentPrice.formattedPrice;
      const annual =
        slhdOffering.annual?.rcBillingProduct.currentPrice.formattedPrice;
      setMonthlyPrice(monthly !== undefined ? monthly : '');
      setAnnualPrice(annual !== undefined ? annual : '');
    } else {
      strogging.log('[useRevenueCat] No offering found');
    }
  }, [isConfigured, setDefaultPurchase]);

  const fetchCustomerInfo = useCallback(async () => {
    if (!isConfigured) {
      setDefaultPurchase();
    }
    try {
      await Purchases.getSharedInstance()
        .getCustomerInfo()
        .then((customerInfo) => {
          const productIdentifier =
            customerInfo.entitlements.active[packageName].productIdentifier;

          const productTerm = productIdentifier.includes('monthly')
            ? { short: Terms.MONTHLY_SHORT, long: Terms.MONTHLY }
            : { short: Terms.ANNUAL_SHORT, long: Terms.ANNUAL };
          const productPrice = productIdentifier.includes('monthly')
            ? monthlyPrice
            : annualPrice;
          const productName = productIdentifier.includes('monthly')
            ? PackageName.PRO_MONTHLY
            : PackageName.PRO_ANNUAL;

          setIsEntitled(
            customerInfo.entitlements.active[packageName] ? true : false
          );

          setActiveSubscription({
            productIdentifier,
            productName,
            productPrice,
            productTerm,
          });
        });
    } catch (e) {
      strogging.log('[useRevenueCat] Error checking entitlement:', e);
      return false;
    }
  }, [
    packageName,
    annualPrice,
    monthlyPrice,
    setDefaultPurchase,
    isConfigured,
  ]);

  const purchaseSubscription = async (rcPackage: SubscriptionOffering) => {
    const selectedPackage = offerings?.all[packageName][rcPackage.term];

    if (!selectedPackage) {
      strogging.log('[useRevenueCat] Selected package is undefined');
      return;
    }

    try {
      await Purchases.getSharedInstance().purchase({
        rcPackage: selectedPackage,
      });
    } catch (e) {
      if (
        e instanceof PurchasesError &&
        e.errorCode == ErrorCode.UserCancelledError
      ) {
        strogging.log('[useRevenueCat] User cancelled the purchase');
      } else {
        strogging.log(`[useRevenueCat]: Error purchasing package: ${e}`);
      }
    }
  };

  useEffect(() => {
    async function fetchRcData() {
      await fetchPrices();
      await fetchCustomerInfo();
      setIsLoadingRc(false);
    }
    fetchRcData();
  }, [fetchCustomerInfo, fetchPrices, setDefaultPurchase]);

  return {
    annualPrice,
    isEntitled,
    isLoadingRc,
    monthlyPrice,
    offerings,
    activeSubscription,
    purchaseSubscription,
  };
};

export const usePaywall = (context: PaywallContext, playerId: string = '') => {
  const appHost = useContext(AppHostContext);
  const { purchaseSubscription } = useRevenueCat();
  const { trackPaywallEvent } = usePaywallTracking(context, playerId);
  const [isPaywallActive, setIsPaywallActive] = useState(false);

  const handlePaywallOpen = useCallback(() => {
    if (appHost.isHosted) {
      appHost.service.notifications.showPaywall();
    } else {
      setIsPaywallActive(true);
    }
    trackPaywallEvent(PaywallEvents.PAYWALL_DISPLAYED);
  }, [appHost, trackPaywallEvent]);

  const handlePaywallClose = useCallback(() => {
    trackPaywallEvent(PaywallEvents.OFFER_PAGE_DISMISSED);
    setIsPaywallActive(false);
  }, [trackPaywallEvent]);

  const handleUnlockAccess = useCallback(
    (offering: SubscriptionOffering) => {
      purchaseSubscription(offering);
      setIsPaywallActive(false);
    },
    [purchaseSubscription]
  );

  return {
    isPaywallActive,
    handlePaywallOpen,
    handlePaywallClose,
    handleUnlockAccess,
  };
};

export const usePaywallTracking = (
  context: PaywallContext,
  playerId: string = ''
) => {
  const userStore = useContext(UserContext);
  const { playerInfo } = usePlayerPrimaryInfo(playerId);

  const userTeamIds = userStore.userTeams
    ? userStore.userTeams.map((team) => team._id)
    : [];
  const userPlayerIds = userStore.userPlayers
    ? userStore.userPlayers.map((p) => p._id)
    : [];
  const isAdmin = userPlayerIds.includes(playerId);
  const isFollower =
    playerInfo?.teams &&
    playerInfo.teams.length &&
    userStore.teamFollowerIds
      .concat(userTeamIds)
      .filter((teamId) =>
        playerInfo.teams.map((playerTeam) => playerTeam._id).includes(teamId)
      ).length;

  const userType = () => {
    let user: UserType;
    if (!userStore.authUser) {
      user = UserType.LoggedOut;
    } else if (isAdmin) {
      user = UserType.Admin;
    } else if (isFollower) {
      user = UserType.CommunityMember;
    } else {
      user = UserType.LoggedIn;
    }
    return user;
  };

  const trackPaywallEvent = (eventName: PaywallEvents, payload: Dict = {}) => {
    analytics.track(eventName, {
      context,
      userType: userType(),
      ...payload,
    });
  };

  const trackPaywallGameViewEvent = async (
    gameId: string,
    teamPlayerId: string
  ) => {
    const teamPlayerInfo = await axios
      .get(`/api/team_player/${teamPlayerId}`)
      .then((response) => {
        return response.data;
      });

    analytics.track(PaywallEvents.GAME_PAGE_VIEW_BUTTON_CLICKED, {
      context: PaywallContext.VIEW_BUTTON,
      gameId,
      playerId: teamPlayerInfo.teamPlayer._id,
      teamId: teamPlayerInfo.team._id,
      sportType: teamPlayerInfo.team.attrib_sportType,
      userType: getUserTypeRelativeToTeam(teamPlayerInfo.team._id, userStore),
    });
  };

  return { trackPaywallEvent, trackPaywallGameViewEvent };
};
