import React, { useContext, useState } from 'react';
import { Avatar, Box, Button, Card, Layer, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import axios from '../../../helpers/axios';
import { UserContext } from '../../../services/Session';
import * as Sentry from '@sentry/browser';
import { formatPhoneNumber } from 'react-phone-number-input';
import { isMobile } from '../../../helpers/browserDetect';
import { StaffMember, StaffRole } from '../types';

interface Props {
  staffMember: StaffMember;
  inEditModal: boolean;
  onEditClicked?: () => void;
  setToast?: (toast: { label: string; background: string }) => void;
  onDeleted?: () => void;
}
const StaffMemberItem: React.FC<Props> = ({
  staffMember,
  inEditModal,
  onEditClicked,
  setToast,
  onDeleted,
}) => {
  const userStore = useContext(UserContext);
  const isAdmin = userStore.userTeams
    .map((t) => t._id)
    .includes(userStore.selectedTeam?._id || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const getSubtitle = () => {
    if (staffMember.pending) {
      const phoneNumber = staffMember.phoneList?.join(', ') || '';

      return (
        <Text
          truncate
        >{`Invite sent to ${formatPhoneNumber(phoneNumber)}`}</Text>
      );
    }
    return <Text truncate>{staffMember.email}</Text>;
  };

  const deletePendingInvite = () => {
    axios
      .delete('/api/user_invites', {
        data: {
          inviteId: staffMember.inviteCode,
        },
      })
      .then(() => {
        onDeleted && onDeleted();
        setToast &&
          setToast({
            label: 'Invite deleted.',
            background: 'status-critical',
          });
        setShowDeleteConfirmation(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  };

  const deletePendingInviteModal = (
    <Layer
      onEsc={() => {
        setShowDeleteConfirmation(false);
      }}
    >
      <Box pad="medium" gap="medium">
        <Text>Are you sure you want to remove this invite?</Text>
        <Box direction="row" justify="between">
          <Button
            plain
            color="tertiary-1"
            label={<Text weight="bold">Cancel</Text>}
            onClick={() => setShowDeleteConfirmation(false)}
          />
          <Button
            primary
            color="error"
            label={<Text weight="bold">Yes, remove</Text>}
            onClick={deletePendingInvite}
          />
        </Box>
      </Box>
    </Layer>
  );

  return (
    <Card
      pad="medium"
      elevation={inEditModal || !isMobile.any() ? 'none' : 'small'}
      gap="large"
      background={staffMember.pending ? 'light-2' : 'white'}
      round={isMobile.any() ? 'small' : 'none'}
      border={
        isMobile.any()
          ? undefined
          : { color: 'light-2', size: 'xsmall', side: 'top' }
      }
    >
      <Box
        direction="row"
        gap="small"
        flex={{ grow: 1, shrink: 0 }}
        fill="horizontal"
      >
        <Avatar background="gray-2" size="medium"></Avatar>
        <Box gap="xsmall" flex>
          <Box direction="row" gap="small" align="center">
            <Text weight={'bold'} truncate>
              {staffMember.nameFirst} {staffMember.nameLast}
            </Text>
            {staffMember.pending && (
              <Box
                background={'status-warning'}
                round="small"
                pad={{ vertical: '2px', horizontal: '8px' }}
              >
                <Text weight={'bold'} size="xsmall">
                  Pending
                </Text>
              </Box>
            )}
          </Box>
          <Box gap="xsmall">{getSubtitle()}</Box>
          <Box direction="row" gap="4px" margin={{ top: '4px' }}>
            {staffMember.roles?.includes(StaffRole.Manager) && (
              <Box
                background={'tertiary-1'}
                round="small"
                pad={{ vertical: '4px', horizontal: '8px' }}
              >
                <Text weight={'bold'} size="small">
                  Manager
                </Text>
              </Box>
            )}
            {staffMember.roles?.includes(StaffRole.Coach) && (
              <Box
                background={'primary-1'}
                round="small"
                pad={{ vertical: '4px', horizontal: '8px' }}
              >
                <Text weight={'bold'} size="small">
                  Coach
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        <Box align="end" justify="center" width={{ min: '29px' }}>
          {isAdmin && !staffMember.pending && !inEditModal && (
            <Button
              plain
              label={
                <Text weight="bold" color="tertiary-1">
                  Edit
                </Text>
              }
              onClick={onEditClicked}
            />
          )}
          {isAdmin && staffMember.pending && (
            <FormClose
              onClick={() => {
                setShowDeleteConfirmation(true);
              }}
            />
          )}
        </Box>
      </Box>
      {showDeleteConfirmation && deletePendingInviteModal}
    </Card>
  );
};

export default StaffMemberItem;
