import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TEAM_SETTINGS__CAMERA,
  TEAM_SETTINGS__PUBLISHING,
  TEAM_SETTINGS__RADAR,
} from '../../constants/routes';
import SettingsMenuItem from './SettingsMenuItem';
import { Box, Grid, ResponsiveContext, Tab, Tabs } from 'grommet';
import StreamPublishing from './StreamPublishing/StreamPublishing';
import CameraSettings from './CameraSettings';
import RadarSettings from './RadarSettings';
import { BBSB_SPORTS } from '../../constants/strings';
import { UserContext } from '../../services/Session';

const StreamSettings: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const history = useHistory();
  const screenSize = useContext(ResponsiveContext);
  const userStore = useContext(UserContext);

  const pathToTabIndex: { [key: string]: number } = {
    [TEAM_SETTINGS__PUBLISHING]: 0,
    [TEAM_SETTINGS__CAMERA]: 1,
    [TEAM_SETTINGS__RADAR]: 2,
  };

  const [tabIndex, setTabIndex] = useState(pathToTabIndex[currentPath] || 0);

  const onActive = (index: number) => {
    if (index === 0) {
      history.replace(TEAM_SETTINGS__PUBLISHING);
    } else if (index === 1) {
      history.replace(TEAM_SETTINGS__CAMERA);
    } else if (index === 2) {
      history.replace(TEAM_SETTINGS__RADAR);
    }
    setTabIndex(index);
  };

  const renderMobileMenu = () => {
    let columns = '100%';
    let gap = 'none';
    if (screenSize !== 'small') {
      columns = '1/3';
      gap = 'medium';
    }

    return (
      <Box gap="medium" pad="medium">
        <Grid columns={columns} gap={gap}>
          <SettingsMenuItem
            title="Stream Publishing"
            subtitle="Publish livestreams to YouTube or Facebook."
            href={TEAM_SETTINGS__PUBLISHING}
          />
          <SettingsMenuItem
            title="Camera Settings"
            subtitle="Manage your camera."
            href={TEAM_SETTINGS__CAMERA}
          />
          {BBSB_SPORTS.includes(
            userStore.selectedTeam?.attrib_sportType || ''
          ) && (
            <SettingsMenuItem
              title="Live Pitch Velocity"
              subtitle="Show pitch velocity in your livestream"
              href={TEAM_SETTINGS__RADAR}
            />
          )}
        </Grid>
      </Box>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <Box pad={{ vertical: 'small' }}>
        <Tabs activeIndex={tabIndex} onActive={onActive}>
          <Tab title="Stream Publishing">
            <StreamPublishing />
          </Tab>
          <Tab title="Camera Settings">
            <CameraSettings />
          </Tab>
          {BBSB_SPORTS.includes(
            userStore.selectedTeam?.attrib_sportType || ''
          ) && (
            <Tab title="Live Pitch Velocity">
              <RadarSettings />
            </Tab>
          )}
        </Tabs>
      </Box>
    );
  };

  return screenSize === 'small' ? renderMobileMenu() : renderDesktopMenu();
};

export default StreamSettings;
