// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';

import { Box, CheckBox, Grommet, Text } from 'grommet';
import { GenericScoringContext } from '../context';
import ScoringButton from '../ScoringButton';

const Basketball = ({ gameEventTypes, disabled }) => {
  const scoringStore = useContext(GenericScoringContext);
  useEffect(() => {}, []);

  const gameState = scoringStore.scoreboard.gameState || {};
  const isAwayBonus = 'away_bonus' in gameState;
  const isHomeBonus = 'home_bonus' in gameState;

  return (
    <Box
      margin="medium"
      style={{
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? '0.25' : '1',
      }}
      fill="vertical"
    >
      <Box
        direction="row"
        gap="large"
        pad={{ vertical: 'small' }}
        align="center"
      >
        <Text weight="bold" size="large">
          My Team
        </Text>
        <Grommet
          theme={{
            checkBox: {
              color: '#066d99',
            },
          }}
        >
          <CheckBox
            checked={scoringStore.awayHome ? isHomeBonus : isAwayBonus}
            label={
              <Box alignSelf="center">
                <Text size="small">Bonus</Text>
              </Box>
            }
            onChange={(e) => {
              const bonusKey = scoringStore.awayHome
                ? 'home_bonus'
                : 'away_bonus';
              if (e.target.checked) {
                const eventInfo = {
                  name: bonusKey,
                  teamNAH: 1,
                  enable: true,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              } else {
                const eventInfo = {
                  name: bonusKey,
                  enable: false,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              }
            }}
          />
        </Grommet>
      </Box>
      <Box background="light-3" pad="small" gap="8px" round="8px" fill>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_2pt_fg.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_2pt_fg',
                gameEventTypes.basketball_2pt_fg
              );
            }}
          />
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_2pt_fg_miss.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_2pt_fg_miss',
                gameEventTypes.basketball_2pt_fg_miss
              );
            }}
          />
        </Box>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_3pt_fg.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_3pt_fg',
                gameEventTypes.basketball_3pt_fg
              );
            }}
          />
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_3pt_fg_miss.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_3pt_fg_miss',
                gameEventTypes.basketball_3pt_fg_miss
              );
            }}
          />
        </Box>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_ft.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_ft',
                gameEventTypes.basketball_ft
              );
            }}
          />
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_ft_miss.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_ft_miss',
                gameEventTypes.basketball_ft_miss
              );
            }}
          />
        </Box>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/2"
            label="REB"
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_reb',
                gameEventTypes.basketball_reb
              );
            }}
          />
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.basketball_to.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_to',
                gameEventTypes.basketball_to
              );
            }}
          />
        </Box>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/3"
            label={gameEventTypes.basketball_stl.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_stl',
                gameEventTypes.basketball_stl
              );
            }}
          />
          <ScoringButton
            basis="1/3"
            label={gameEventTypes.basketball_blk.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_blk',
                gameEventTypes.basketball_blk
              );
            }}
          />
          <ScoringButton
            basis="1/3"
            label={gameEventTypes.basketball_foul.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_foul',
                gameEventTypes.basketball_foul
              );
            }}
          />
        </Box>
      </Box>
      <Box
        margin={{ top: 'medium' }}
        direction="row"
        gap="large"
        pad={{ vertical: 'small' }}
        align="center"
      >
        <Text weight="bold" size="large">
          Opponent
        </Text>
        <Grommet
          theme={{
            checkBox: {
              color: '#066d99',
            },
          }}
        >
          <CheckBox
            checked={scoringStore.awayHome ? isAwayBonus : isHomeBonus}
            label={
              <Box alignSelf="center">
                <Text size="small">Bonus</Text>
              </Box>
            }
            onChange={(e) => {
              const bonusKey = scoringStore.awayHome
                ? 'away_bonus'
                : 'home_bonus';
              if (e.target.checked) {
                const eventInfo = {
                  name: bonusKey,
                  teamNAH: 1,
                  enable: true,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              } else {
                const eventInfo = {
                  name: bonusKey,
                  enable: false,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              }
            }}
          />
        </Grommet>
      </Box>
      <Box background="light-3" pad="small" gap="8px" round="8px" fill>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/3"
            label={gameEventTypes.basketball_ft_opponent.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_ft_opponent',
                gameEventTypes.basketball_ft_opponent
              );
            }}
            isOpponent
          />
          <ScoringButton
            basis="1/3"
            label={gameEventTypes.basketball_2pt_fg_opponent.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_2pt_fg_opponent',
                gameEventTypes.basketball_2pt_fg_opponent
              );
            }}
            isOpponent
          />
          <ScoringButton
            basis="1/3"
            label={gameEventTypes.basketball_3pt_fg_opponent.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'basketball_3pt_fg_opponent',
                gameEventTypes.basketball_3pt_fg_opponent
              );
            }}
            isOpponent
          />
        </Box>
      </Box>
      <Box height="64px" fill="horizontal" />
    </Box>
  );
};

export default Basketball;
