// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useState } from 'react';
import { Box, Heading, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import SHDButton from '../../components/SHD/Button';
import { UserContext } from '../../services/Session';
import axios from '../../helpers/axios';
import CourtPlayersSub from './CourtPlayersSub';

const SetStarters = ({
  starters = [],
  liberos = [],
  teamPlayers = [],
  goBack = () => {},
  toggleIsLineupSet = () => {},
  match,
}) => {
  const userStore = useContext(UserContext);
  const { gameId } = match.params;
  const team = userStore.selectedTeam || userStore.scoringTeam;
  const [startersOrder, setStartersOrder] = useState(starters);

  const [startedSub, setStartedSub] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [error, setError] = useState(false);
  const [loading, toggleLoading] = useState(false);

  const updateOrder = (order, isQuickStartMode = false) => {
    const starterIds = order.map((p) => p._id);
    const players = order.concat(
      teamPlayers.filter((p) => !starterIds.includes(p._id))
    );
    const body = {
      teamId: team._id,
      teamGameId: gameId,
      teamGameUpdate: {
        scoreOurLineup: players,
        scoreGameState: 20,
        scheduleState: 20,
        isQuickStartMode,
        liberos,
      },
    };

    // TODO: write liberos

    axios
      .put('/api/team_game', body)
      .then(() => {
        toggleIsLineupSet(true);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
  };

  // TODO: this is static for 6 player volleyball
  const positionToIndex = [3, 2, 1, 4, 5, 0].concat(
    startersOrder.slice(6).map((_, i) => i + 6)
  );
  const onPlayerSelected = (i) => {
    if (startedSub) {
      // swap positions

      const playerOut = startersOrder[positionToIndex[selectedIndex]];
      const playerIn = startersOrder[positionToIndex[i]];

      if (playerOut._id !== playerIn._id) {
        setStartersOrder(
          startersOrder.map((p) => {
            let newPlayer;
            if (p._id === playerOut._id) {
              newPlayer = playerIn;
            } else if (p._id === playerIn._id) {
              newPlayer = playerOut;
            } else {
              newPlayer = p;
            }

            return newPlayer;
          })
        );
      }

      setSelectedIndex(null);
      setStartedSub(false);
    } else {
      setSelectedIndex(i);
      setStartedSub(true);
    }
  };

  return (
    <Box pad="medium">
      <Box fill="horizontal" align="end">
        <Text onClick={goBack} weight="bold" color="tertiary-1">
          Back
        </Text>
      </Box>
      <Heading level={3}>Set positions</Heading>
      {error ? <Text color="status-critical">{error}</Text> : null}
      <Box
        pad={{ horizontal: 'medium', bottom: 'medium' }}
        margin={{ vertical: 'medium' }}
        background="light-1"
        round="small"
        fill="horizontal"
        align="center"
        justify="center"
      >
        <CourtPlayersSub
          players={startersOrder}
          liberos={liberos}
          selectedIndex={selectedIndex}
          onPlayerSelected={onPlayerSelected}
        />
      </Box>
      <Box pad="small">
        <Text>
          Tap on a player then another one to switch their places on-court.
        </Text>
      </Box>
      <Box gap="small">
        <Heading level={3}>Selected Liberos</Heading>
        <Box
          direction="row"
          align="center"
          justify="center"
          fill="horizontal"
          gap="small"
        >
          {liberos.map((libero) => (
            <Box align="center" key={libero._id}>
              <Box
                round="full"
                background="light-1"
                width="xxsmall"
                height="xxsmall"
                align="center"
                justify="center"
                border={{ color: 'status-critical', size: 'small' }}
              >
                <Text weight="bold" size="xlarge">
                  {libero.jerseyNum}
                </Text>
              </Box>
              <Box align="center" width="small" margin={{ top: 'small' }}>
                <Text size="small" truncate>
                  {libero.nameFirst} {libero.nameLast[0]}.
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        direction="row"
        margin={{ vertical: 'large' }}
        align="center"
        justify="center"
        fill="horizontal"
      >
        <SHDButton
          primary
          size="large"
          fill="horizontal"
          label={!loading ? 'Finish setup' : '...Setting'}
          onClick={() => updateOrder(startersOrder)}
          margin={{ top: 'large' }}
          color="tertiary-1"
          disabled={loading}
        />
      </Box>
    </Box>
  );
};

export default compose(withRouter)(SetStarters);
