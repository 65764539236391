import React, { useState, useCallback } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, BoxExtendedProps, Button, Layer, Text } from 'grommet';
import { LinkPrevious } from 'grommet-icons';

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: mediaWidth,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

interface ImageCropperProps {
  src: string;
  circularCrop?: boolean;
  aspect: number;
  onClose: () => void;
  onSave: (croppedImage: File | undefined) => void;
}

const ImagePickerCropper: React.FC<ImageCropperProps> = ({
  src,
  onClose,
  onSave,
  aspect,
  circularCrop = false,
}) => {
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const [crop, setCrop] = useState<Crop>(centerAspectCrop(0, 0, aspect));

  const getCroppedImage = useCallback(async () => {
    if (imageRef && crop.width && crop.height) {
      const cHeight = crop.height;
      const cWidth = crop.width;

      const scaleX = imageRef.naturalWidth / imageRef.width; // Adjust scale factor
      const scaleY = imageRef.naturalHeight / imageRef.height; // Adjust scale factor

      const canvas = document.createElement('canvas');
      canvas.width = cWidth * scaleX; // Adjust canvas size
      canvas.height = cHeight * scaleY; // Adjust canvas size

      const ctx = canvas.getContext('2d');

      ctx?.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        cWidth * scaleX,
        cHeight * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      return new Promise<File>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const file = new File([blob], 'newFile.png', { type: 'image/png' });
          resolve(file);
        }, 'image/png');
      });
    }
  }, [crop, imageRef]);

  const handleSave = async () => {
    const imageFile = await getCroppedImage();
    onSave(imageFile);
  };

  const onImageLoaded = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const image = event.currentTarget;
    setCrop(centerAspectCrop(image.width, image.height, aspect));
    setImageRef(image);
  };

  const onChangeCrop = useCallback((newCrop: Crop) => {
    setCrop(newCrop);
  }, []);

  const renderHeader = () => {
    const title = 'Edit Media';

    const justify: BoxExtendedProps['justify'] = 'between';
    const textSize = 'large';

    return (
      <Box
        direction="row"
        align={'center'}
        justify={justify}
        pad={'large'}
        color="light-5"
      >
        <Box alignSelf="start" onClick={onClose}>
          <LinkPrevious size="24px" />
        </Box>
        <Text weight="bold" size={textSize} alignSelf="center">
          {title}
        </Text>
        <Box alignSelf="end" width={'24px'} />
      </Box>
    );
  };

  return (
    <Layer
      modal
      full="vertical"
      style={{ height: '100dvh' }}
      animation={false}
      onClickOutside={onClose}
    >
      <Box>
        {renderHeader()}
        <Box pad="medium" gap="medium" overflow="scroll" color="light-5" flex>
          <ReactCrop
            crop={crop}
            ruleOfThirds
            onChange={onChangeCrop}
            circularCrop={circularCrop}
            locked={false}
            aspect={aspect === 16 / 9 ? undefined : aspect}
          >
            <img alt="Crop" src={src} onLoad={onImageLoaded} />
          </ReactCrop>
        </Box>
        <Box pad="medium" background={'white'}>
          <Button primary label="Save" onClick={handleSave} />
          <Text size="small" margin={{ top: '1rem' }} alignSelf="center">
            Photos must be under 8mb and have 100-4000 pixels/side
          </Text>
          {aspect === 1 && (
            <Text size="small" margin={{ top: '1rem' }} alignSelf="center">
              For best results use a square image.
            </Text>
          )}
        </Box>
      </Box>
    </Layer>
  );
};

export default ImagePickerCropper;
