import { useState, useEffect, useCallback } from 'react';
import axios from '../helpers/axios';
import { strogging } from '@shd/jslib/infra';

const usePermitClaimPlayersOnTeam = (
  team_id: string,
  hasUserId: string | undefined
) => {
  const [userCanClaim, setUserCanClaim] = useState<boolean>(false);
  useEffect(() => {
    const requestClaimStatus = async (teamId: string) => {
      axios
        .get(`/api/claims_check/${teamId}/permit_claim_players_on_team`)
        .then((response) => {
          setUserCanClaim(response.data.can_claim_player);
          return response.data;
        })
        .catch((error) => {
          strogging.log('Request Claim Status', error);
        });
    };
    if (hasUserId) {
      requestClaimStatus(team_id);
    } else {
      setUserCanClaim(true);
    }
  }, [team_id, hasUserId]);

  return userCanClaim;
};

const usePermitRolloverPlayer = () => {
  const checkRolloverPermission = useCallback(
    async (teamIdDst: string, teamIdSrc: string) => {
      return axios
        .get(
          `/api/claims_check/${teamIdDst}/permit_claim_rollover/${teamIdSrc}`
        )
        .then((response) => {
          return response.data.can_rollover;
        })
        .catch((error) => {
          strogging.log('Request Claim Status', error);
        });
    },
    []
  );

  return { checkRolloverPermission };
};

const usePermitClaimPlayer = (
  player_id: string,
  team_id: string | undefined,
  hasUserId?: string
) => {
  const [userCanClaim, setUserCanClaim] = useState<boolean>(false);
  useEffect(() => {
    const requestClaimPermission = (playerId: string, teamId: string) => {
      axios
        .get(
          `/api/claims_check/${playerId}/permit_claim_unclaimed_player/${teamId}`
        )
        .then((response) => {
          setUserCanClaim(response.data.can_claim_player);
          return response.data;
        })
        .catch((error) => {
          strogging.log('Request Claim Status', error);
        });
    };
    if (hasUserId && team_id) {
      requestClaimPermission(player_id, team_id);
    } else {
      setUserCanClaim(true);
    }
  }, [player_id, team_id, hasUserId]);

  return userCanClaim;
};

export {
  usePermitClaimPlayersOnTeam,
  usePermitRolloverPlayer,
  usePermitClaimPlayer,
};
