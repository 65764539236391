// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Card, Heading, Avatar } from 'grommet';
import * as Sentry from '@sentry/react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { User } from 'grommet-icons';
import axios from '../../helpers/axios';
import withLayout from '../../helpers/withLayout';
import { UserContext } from '../../services/Session';
import LiveAssetCard from '../../helpers/LiveAssetCard';
import { formatDateShort } from '../../helpers/utils';
import SHDButton from '../../components/SHD/Button';
import { BasicLoader } from '../../helpers/Loaders';

const Coach = ({ history }) => {
  const userStore = useContext(UserContext);

  const [teams, setTeams] = useState([]);
  const [fetchedGames, setFetchedGames] = useState(false);
  const [loadingGames, setLoadingGames] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(true);

  const getTeams = () => {
    axios
      .get(`/api/user/${userStore.authUser.claims.shd_user_id}/coach_teams`)
      .then((response) => {
        const respTeams = response.data.teams;
        setTeams(respTeams);
        setLoadingTeams(false);
      });
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGames = async () => {
    setLoadingGames(true);
    let newTeams = [...teams];
    // eslint-disable-next-line no-restricted-syntax
    for (const team of teams) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.get(
          `/api/team_page_secondary/${team._id}`
        );
        const { teamGames: respTeamGames, liveAssets: respLiveAssets } =
          response.data;

        newTeams = newTeams.map((t) => {
          if (t._id === team._id) {
            const updatedTeam = { ...t };
            updatedTeam.liveAssets = respLiveAssets;
            updatedTeam.games = respTeamGames
              .sort(({ J_tsStart: a }, { J_tsStart: b }) => b - a)
              .slice(0, 10);
            return updatedTeam;
          }

          return t;
        });
      } catch (respError) {
        Sentry.captureException(respError);
      }
    }

    setTeams(newTeams);
    setFetchedGames(true);
    setLoadingGames(false);
  };

  useEffect(() => {
    if (!fetchedGames && teams.length > 0) {
      getGames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const renderGameCard = (gameData) => {
    const {
      J_opponentName,
      J_tsStart,
      fb,
      yt,
      _id: teamGameId,
      scoreWeAreHome,
      awayScore,
      homeScore,
      result: gameResult,
    } = gameData;

    const scoreDiv =
      awayScore !== -1 ? (
        <Text>{`${gameResult} ${awayScore}-${homeScore}`}</Text>
      ) : null;

    return (
      <Card
        key={`game-${J_opponentName}-${J_tsStart}`}
        // margin={{ bottom: size === 'small' ? 'medium' : 'small' }}
        background="light-1"
        direction="row"
        justify="between"
      >
        <Box align="start" justify="between" gap="small" pad="medium">
          <Box direction="row" gap="medium">
            <Text weight="bold">
              {scoreWeAreHome ? `vs ${J_opponentName}` : `@ ${J_opponentName}`}
            </Text>
            <Text>{formatDateShort(J_tsStart)}</Text>
          </Box>
          {scoreDiv}
          <Box direction="row" gap="small">
            {yt && (
              <SHDButton
                primary
                color="#ff0000"
                label="Watch on YouTube"
                href={yt.cdnUrl}
                target="_blank"
              />
            )}
            {fb && (
              <SHDButton
                primary
                color="#4267B2"
                label="Watch on Facebook"
                href={fb.cdnUrl}
                target="_blank"
              />
            )}
            <SHDButton
              primary
              color="primary-1"
              label="Play-by-Play"
              onClick={() => {
                history.push(`/game/${teamGameId}`);
              }}
            />
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Box
      margin={{ left: 'auto', right: 'auto', bottom: 'xlarge' }}
      align="center"
      gap="small"
      pad="medium"
    >
      <Heading>Teams</Heading>
      <Text>You are a coach on these teams</Text>
      {loadingTeams && <BasicLoader />}
      {teams.map((team) => {
        const teamAgeText =
          team.attrib_ageU > 0
            ? `${team.attrib_ageU}U`
            : `${team.attrib_ageLevel}`;
        const teamSubtitle = `${team.attrib_sportType} - ${teamAgeText}`;
        return (
          <Box key={team._id}>
            <Card
              width="large"
              pad="medium"
              margin={{ bottom: 'medium' }}
              background="secondary-7"
              onClick={() => {
                history.push(`/${team._id}`);
              }}
            >
              {(team.liveAssets.yt || team.liveAssets.fb) && (
                <LiveAssetCard liveAsset={team.liveAssets} />
              )}
              <Box gap="small" direction="row" align="center">
                {team.image ? (
                  <Avatar src={team.image.urlT150} size="medium" />
                ) : (
                  <Avatar size="medium">
                    <User color="white" size="medium" />
                  </Avatar>
                )}
                <Box>
                  <Text weight="bold">{team.nameMed}</Text>
                  <Text>{teamSubtitle}</Text>
                </Box>
              </Box>
            </Card>
            <Box margin={{ left: 'medium' }} gap="medium">
              {loadingGames && <BasicLoader />}
              {team.games.map((game) => renderGameCard(game))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const CoachPage = compose(withLayout, withRouter)(Coach);

export default CoachPage;
