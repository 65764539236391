/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Image,
  Paragraph,
  Card,
  CardHeader,
  Text,
  Anchor,
  Button,
  ParagraphExtendedProps,
} from 'grommet';

import { withRouter } from 'react-router-dom';
import withLayout from '../../helpers/withLayout';
import { BasicLoader } from '../../helpers/Loaders';
import ClaimUserPage from './ClaimUser';
import SignInMethod from './SignInMethod';
import { GROMMETTHEME } from '../../constants/grommetTheme';
import { useQuery } from '../../helpers/utils';
import { isFacebookBrowser, isiOS } from '../../helpers/browserDetect';
import axios from '../../helpers/axios';
import {
  theFirebase,
  FirebaseAuthCredential,
} from '../../services/Firebase/firebase';
import { strogging } from '@shd/jslib/infra';
import { dtos } from '@shd/jslib/models';

const ErrorDiv = (props: ParagraphExtendedProps) => (
  <Paragraph color="status-error" margin="none" {...props} />
);

const SignInPage = () => {
  const [loading, toggleLoading] = useState(false);
  const [error, setError] = useState<{ message: React.ReactNode } | null>(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const [teamPlayer, setTeamPlayer] = useState<
    dtos.TeamPlayerGetResponse['teamPlayer'] | null
  >(null);

  const [showPromptForGoogleSignIn, setShowPromptForGoogleSignIn] =
    useState<FirebaseAuthCredential>();

  const query = useQuery();
  const claimPlayer = query.get('claim_player');
  const key = query.get('key');
  const phoneNumber = query.get('phone');
  const enableAppleSignIn = true;

  const linkAccountHandler = useCallback(
    (credential: FirebaseAuthCredential | null) => {
      if (credential) {
        setShowPromptForGoogleSignIn(credential);
      }
    },
    []
  );

  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();

  useEffect(() => {
    if (claimPlayer) {
      axios
        .get<dtos.TeamPlayerGetResponse>(`/api/team_player/${claimPlayer}`)
        .then((res) => {
          setTeamPlayer(res.data.teamPlayer);
        })
        .catch((e) => {
          setError(e.toString());
        });
    }
  }, [claimPlayer]);

  if (loading) {
    return <BasicLoader fullPage />;
  }

  if (isFacebookBrowser()) {
    return (
      <Box>
        <Image src="" />
      </Box>
    );
  }

  const facebookBrowserPrompt = (
    <Box background="white" pad="large" gap="small" fill="vertical">
      <Text weight="bold">
        Please open this page up in your default web browser.
      </Text>
      {isiOS() ? (
        <Box gap="medium">
          <Text>
            Press the share button at the bottom of the screen. Then press
            &#34;Open in Safari&#34;
          </Text>
          <Image
            width="large"
            fit="contain"
            src="ios_fb_browser_tutorial.png"
          />
        </Box>
      ) : (
        <Box gap="medium">
          <Text>
            Press the three dots at the top of the screen. Then press &#34;Open
            in Chrome&#34;
          </Text>
          <Image
            width="large"
            fit="contain"
            src="android_fb_browser_tutorial.png"
          />
        </Box>
      )}
    </Box>
  );

  const linkAccountsDiv = (
    <Box gap="small" width="100%" margin={{ bottom: 'medium' }}>
      <Paragraph>
        You are now trying to sign in with an account with the same email as
        your Google account.
      </Paragraph>
      <Paragraph>Would you like to link these accounts?</Paragraph>
      <Button
        primary
        label="Link accounts"
        onClick={() => {
          const currentUser = theFirebase().auth.currentUser;
          if (!currentUser) {
            setError({ message: 'No current user' });
            strogging.error('Trying to link accounts with no current user');
            return;
          }
          theFirebase()
            .doLinkWithPopup(currentUser, googleProvider)
            .then((result) => {
              strogging.log('successfully linked accounts', {
                userEmail: result.user.email,
              });
            })
            .catch((e) => {
              setError({ message: e.toString() });
            });
        }}
      />
    </Box>
  );

  const claimPlayerDiv = (
    <Box gap="small">
      <CardHeader>
        <Heading level={3} margin={{ bottom: 'xsmall' }}>
          {showSignIn ? 'Welcome back!' : 'Create account'}
        </Heading>
      </CardHeader>
      {!showSignIn && (
        <Text margin={{ bottom: 'medium' }}>
          Create an account to claim{' '}
          <Text weight="bold">
            {`${teamPlayer?.nameFirst} ${teamPlayer?.nameLast}`}
          </Text>
        </Text>
      )}
      {!!error && <ErrorDiv>{error.message}</ErrorDiv>}
      {showPromptForGoogleSignIn && linkAccountsDiv}
      <SignInMethod
        label={`Sign ${showSignIn ? 'in' : 'up'} with Google`}
        provider={googleProvider}
        toggleLoading={toggleLoading}
        errorHandler={setError}
        claimPlayer={claimPlayer}
        claimPlayerKey={key}
        claimPhoneNumber={phoneNumber}
        postParams={{
          sign_up: showSignIn ? 0 : 1,
        }}
      />
      <SignInMethod
        label={`Sign ${showSignIn ? 'in' : 'up'} with Facebook`}
        provider={facebookProvider}
        toggleLoading={toggleLoading}
        errorHandler={setError}
        claimPlayer={claimPlayer}
        claimPlayerKey={key}
        claimPhoneNumber={phoneNumber}
        postParams={{
          sign_up: showSignIn ? 0 : 1,
        }}
      />
      {enableAppleSignIn && (
        <SignInMethod
          label={`Sign ${showSignIn ? 'in' : 'up'} with Apple`}
          provider={appleProvider}
          toggleLoading={toggleLoading}
          errorHandler={setError}
          claimPlayer={claimPlayer}
          claimPlayerKey={key}
          claimPhoneNumber={phoneNumber}
          postParams={{
            sign_up: showSignIn ? 0 : 1,
          }}
        />
      )}
      <Box
        height="2px"
        width="full"
        background="dark-5"
        margin={{ top: 'small' }}
      />
      <Box pad={{ top: 'small' }}>
        {showSignIn ? (
          <Text size="small">
            Don&apos;t have an account yet?{' '}
            <Anchor
              onClick={() => {
                setShowSignIn(false);
              }}
            >
              Sign up here.{' '}
            </Anchor>
          </Text>
        ) : (
          <Text size="small">
            Already have an account?{' '}
            <Anchor
              onClick={() => {
                setShowSignIn(true);
              }}
            >
              Sign in here.
            </Anchor>
          </Text>
        )}
      </Box>
    </Box>
  );

  const signInDiv = (
    <Box gap="small">
      <CardHeader align="start">
        <Heading level={3}>Welcome back! 👋</Heading>
      </CardHeader>
      {!!error && <ErrorDiv>{error.message}</ErrorDiv>}
      {showPromptForGoogleSignIn && linkAccountsDiv}
      <SignInMethod
        label="Sign in with Google"
        provider={googleProvider}
        toggleLoading={toggleLoading}
        errorHandler={setError}
        claimPlayer={claimPlayer}
        claimPlayerKey={key}
        claimPhoneNumber={phoneNumber}
      />
      <SignInMethod
        label="Sign in with Facebook"
        provider={facebookProvider}
        toggleLoading={toggleLoading}
        errorHandler={setError}
        linkAccountHandler={linkAccountHandler}
        claimPlayer={claimPlayer}
        claimPlayerKey={key}
        claimPhoneNumber={phoneNumber}
      />
      {enableAppleSignIn && (
        <SignInMethod
          label="Sign in with Apple"
          provider={appleProvider}
          toggleLoading={toggleLoading}
          errorHandler={setError}
          linkAccountHandler={linkAccountHandler}
          claimPlayer={claimPlayer}
          claimPlayerKey={key}
          claimPhoneNumber={phoneNumber}
        />
      )}
      <Box
        height="2px"
        width="full"
        background="dark-5"
        margin={{ top: 'small' }}
      />
      <Box pad={{ top: 'small' }}>
        <Text size="small">
          Don&apos;t have an account yet?{' '}
          <Anchor href="/get-started">Sign up here. </Anchor>
        </Text>
      </Box>
    </Box>
  );

  const mainDiv = (
    <Card
      align="center"
      pad="large"
      gap="small"
      margin="10% auto 0 auto"
      background="white"
    >
      {claimPlayer ? claimPlayerDiv : signInDiv}
    </Card>
  );

  return (
    <Box
      background={`linear-gradient(${GROMMETTHEME.global.colors.brand.light}, ${GROMMETTHEME.global.colors.brand.dark})`}
      height="100vh"
    >
      {isFacebookBrowser() ? facebookBrowserPrompt : mainDiv}
    </Box>
  );
};

export default withRouter(withLayout(SignInPage));

export { ClaimUserPage };
