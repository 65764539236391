// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { postcodeValidator } from 'postcode-validator';
import { string } from 'yup';
import { FieldStyled, FieldWrapper, InputStyled } from './style';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';

const locationValidationScheme = string()
  .required('Field is required')
  .max(30, 'Please enter a name 30 characters or fewer.');

const FieldLocation = ({ value, handleForward }) => {
  const [isValidLocation, setIsValidLocation] = useState(false);
  const [isValidZipCode, setIsValidZipCode] = useState(false);

  const [isTouchedLocation, setIsTouchedLocation] = useState(false);
  const [isTouchedZipCode, setIsTouchedZipCode] = useState(false);

  const [errorLocation, setErrorLocation] = useState(null);
  const [errorZipCode, setErrorZipCode] = useState(null);

  const isValid = isValidLocation && isValidZipCode;

  useEffect(() => {
    locationValidationScheme
      .validate(value.nameLocation)
      .then(() => {
        setErrorLocation(null);
        setIsValidLocation(true);
      })
      .catch((reason) => {
        setIsValidLocation(false);
        if (!isTouchedLocation) {
          return;
        }

        setErrorLocation(reason.message);
      });

    if (!postcodeValidator(value.zipCode, 'US')) {
      setIsValidZipCode(false);
      if (!isTouchedZipCode) {
        return;
      }

      setErrorZipCode('Please enter a valid US zip code');
    } else {
      setIsValidZipCode(true);
      setErrorZipCode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">Where is your team based?</FieldLabel>
          <FieldTip color="dark-4">* indicates required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled
          name="nameLocation"
          style={{ marginBottom: '12px' }}
          error={errorLocation}
          onChange={() => setIsTouchedLocation(true)}
        >
          <InputStyled
            autoComplete="nope"
            name="nameLocation"
            placeholder="City, State*"
          />
        </FieldStyled>
        <FieldStyled
          style={{ width: '126px' }}
          name="zipCode"
          error={errorZipCode}
          onChange={() => setIsTouchedZipCode(true)}
        >
          <InputStyled id="zip-id" name="zipCode" placeholder="Zipcode*" />
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton
        disabled={!isValid}
        label="Next"
        onClick={handleForward}
      />
    </>
  );
};

export default FieldLocation;
