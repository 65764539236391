interface ClipLabelsCollection {
  [key: string]: ClipLabels;
}

interface ClipLabels {
  handle: string;
  labels: string[];
}

export const basketballClipLabels: ClipLabels = {
  handle: 'Basketball',
  labels: [
    '2pt made',
    '2pt missed',
    '3pt made',
    '3pt missed',
    'assist',
    'block',
    'foul',
    'ft made',
    'ft missed',
    'rebound',
    'steal',
    'turnover',
  ],
};

export const bbsbClipLabels: ClipLabels = {
  handle: 'Baseball/Softball',
  labels: [
    'double play',
    'double',
    "fielder's choice",
    'hit by pitch',
    'home run',
    'other',
    'out',
    'reach on error',
    'sac bunt',
    'sac fly',
    'single',
    'strikeout',
    'triple play',
    'triple',
    'walk',
  ],
};

export const hockeyClipLabels: ClipLabels = {
  handle: 'Hockey',
  labels: [
    'assist',
    'goal',
    'lost faceoff',
    'missed shot',
    'save',
    'shot',
    'won faceoff',
  ],
};

export const soccerClipLabels: ClipLabels = {
  handle: 'Soccer',
  labels: ['goal', 'shot', 'assist', 'save'],
};

export const vbClipLabels: ClipLabels = {
  handle: 'Volleyball',
  labels: ['kill', 'ace', 'block', 'assist', 'dig'],
};

export const clipLabels: ClipLabelsCollection = {
  basketball: basketballClipLabels,
  bbsb: bbsbClipLabels,
  hockey: hockeyClipLabels,
  soccer: soccerClipLabels,
  vb: vbClipLabels,
};
