import { shd } from '@shd/jslib/models';
import { ViewerEntitlementStatus } from '../../../components/Paywall/paywall.types';

export type Players = { [key: string]: shd.TeamPlayer };
export type Clips = { [key: string]: shd.Clip[] };

export interface InningDisplayFormat {
  players: Players;
  clips: Clips;
}
export interface PlayByPlayProps {
  gameId: string;
  players: Players;
  handleNavigation: (destination: string) => void;
  clips: Clips;
  setShowVideo: (show: boolean) => void;
  setActiveClip: (activeClip: shd.Clip) => void;
  setSelectedPlayerId: (playerId: string) => void;
  setShowUnclaimedModal: (show: boolean) => void;
  setShowLockModal: (show: boolean) => void;
  handlePaywallOpen: () => void;
  viewerEntitlementStatus: ViewerEntitlementStatus;
  trackEvent: { gameId: string; loggedIn: boolean; sport: string };
}

export interface PrimaryPlayerProps {
  outcome: string;
  taggedPrimaryPlayer: TaggedPrimaryPlayer;
  handleNavigation: (destination: string) => void;
  handleActiveClip: (activeClip: string, teamPlayerId: string) => void;
  handleUnclaimedPlayer: (playerId: string) => void;
  isViewable?: boolean;
  isDkData?: boolean;
  viewerEntitlementStatus: ViewerEntitlementStatus;
}

export interface TaggedPlayer {
  playerName: string;
  jerseyNum: string;
  destination: string;
  teamPlayerId: string;
}

export interface TaggedPrimaryPlayer {
  primaryPlayerString: string;
  destination: string;
  teamPlayerId: string;
  isViewable: boolean;
  clipId: string;
}
export interface InningPayload {
  taggedPrimaryPlayer: TaggedPrimaryPlayer;
  outcome: string;
  taggedPlayers: TaggedPlayer[];
  link: string;
  playerRole: string;
  hasDkData: boolean;
}
export interface InningDisplay {
  [x: string]: InningPayload[];
}

export const outcomeMapping = {
  // # OUT
  Kc: 'strikeout',
  Ks: 'strikeout',
  RI: 'runner interference',
  BTO: 'bunt out',
  BT: 'bunt single',
  SACBT: 'sac bunt',
  SACBTS: 'sac bunt safe',
  LD: 'line drive out',
  G: 'ground out',
  DRTO: 'strikeout',
  OFFINT: 'offensive interference',
  SF: 'sac fly',
  IF: 'infield fly',
  FO: 'flyout',
  FC: "fielder's choice",
  FANINT: 'fan interference',
  THRBAT: 'thrown bat',
  OUTOFBOX: 'out of box',
  LBP: 'left base path',

  B: 'walk',
  BB: 'walk', // note - we previously had just B.
  HBB: 'hit by ball',
  DRTS: 'base on dropped 3rd strike',
  IW: 'intentional walk',

  SB: 'stolen base',

  HBP: 'hit by pitch',
  E: 'error',
  SE: 'reach on error',
  '1B': 'single',
  '2B': 'double',
  '3B': 'triple',
  HR: 'home run',
  IPHR: 'inside the park home run',
  GRD: 'ground rule double',

  // SScore
  O: 'out',
  K: 'strikeout',

  ERR: 'reach on error',
  SACB: 'sac bunt',
  SACF: 'sac fly',
  OTHER: 'other',
  DP: 'double play',
  TP: 'triple play',
};
