export const sidelineLive = {
  fontFamily: 'Messina Sans',
};

export const liveIndicator = {
  height: '8px',
  width: '8px',
  alignSelf: 'center',
  paddingRight: '5px',
};

export const teamText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const liveCard = {
  width: '100%',
  alignContent: 'center',
  justifyContent: 'between',
  alignItems: 'center',
  padding: '.75rem',
  background: `radial-gradient(circle, #000 70%, #E44E2D 98%, #E44E2D 100%)`,
  border: '2px solid #E44E2D',
};
