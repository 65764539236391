// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { FormClose } from 'grommet-icons';
import * as Sentry from '@sentry/react';
import { Box, Text } from 'grommet';
import axios from '../../../helpers/axios';

import {
  ClipItemsRow,
  CloseButton,
  FullModal,
  GameClipsRow,
  GameClipsTitle,
  GameClipSubtitle,
  ModalBody,
  ModalButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from '../styles';

import ClipItem from './ClipItem';

export default function AddModal({
  existingClipIds,
  toggleModal,
  player,
  selectedItems,
  handleSelect,
  handleAddClips,
  clearSelected,
}) {
  const [clipData, setClipData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = React.useState(false);
  const [startAfter, setStartAfter] = React.useState(null);

  const fetchClips = (isLoadMore = false) => {
    if (isLoadMore) {
      setLoadMoreLoading(true);
    }
    axios
      .get(`/api/player/${player._id}/clips`, {
        params: {
          sport: 'all',
          limit: 50,
          startAfter,
        },
      })
      .then((response) => {
        if (isLoadMore) {
          setClipData([...clipData, ...response.data.games]);
          setLoadMoreLoading(false);
        } else {
          setClipData(response.data.games);
        }

        setStartAfter(response.data.next);
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setLoading(false);
        setLoadMoreLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearSelected(), []);

  useEffect(() => {
    fetchClips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startAfter) {
      if (clipData.length < 5) {
        fetchClips(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAfter]);

  const renderClips = () => {
    if (loading) {
      return (
        <Box>
          <Text>Loading...</Text>
        </Box>
      );
    }
    return clipData?.length ? (
      clipData.map(({ title, subtitle, clips }, index) => {
        if (clips && !clips.length) {
          return null;
        }
        return (
          <GameClipsRow key={`${title}-${index}`}>
            <GameClipsTitle>{title}</GameClipsTitle>
            <GameClipSubtitle>{subtitle}</GameClipSubtitle>
            <ClipItemsRow>
              {clips.map((clip) => (
                <ClipItem
                  key={clip.id}
                  clip={clip}
                  selectedItems={selectedItems}
                  handleSelect={handleSelect}
                  existingClipIds={existingClipIds}
                />
              ))}
            </ClipItemsRow>
          </GameClipsRow>
        );
      })
    ) : (
      <ModalButton label="No clips found" />
    );
  };

  return (
    <FullModal
      responsive
      onClickOutside={toggleModal}
      modal
      animation={false}
      full="vertical"
    >
      <CloseButton onClick={toggleModal}>
        <FormClose />
      </CloseButton>
      <ModalContent>
        <ModalHeader pad={{ top: '15px' }}>
          <ModalTitle>{`Add ${player.nameFirst}'s Clips`}</ModalTitle>
        </ModalHeader>
        <ModalBody pad={{ left: '15px' }}>{renderClips()}</ModalBody>
        <ModalFooter>
          <Box gap="small" pad="small" fill>
            {startAfter && (
              <ModalButton
                label={
                  loadMoreLoading ? (
                    <Text weight="bold">Loading...</Text>
                  ) : (
                    <Text weight="bold">Load More</Text>
                  )
                }
                onClick={() => fetchClips(true)}
              />
            )}
            {!!selectedItems.length && (
              <ModalButton
                label={`Add ${selectedItems.length} Clip${
                  selectedItems.length > 1 ? 's' : ''
                }`}
                onClick={handleAddClips}
              />
            )}
          </Box>
        </ModalFooter>
      </ModalContent>
    </FullModal>
  );
}
