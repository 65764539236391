// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import {
  Anchor,
  Box, Button, Card, DropButton, Image, Text, TextInput,
} from 'grommet';
import React, {
  useContext,
  useEffect, useState,
} from 'react';
import * as Sentry from '@sentry/browser';
import { CircleQuestion, StatusDisabled, StatusGood } from 'grommet-icons';
import Skeleton from 'react-loading-skeleton';
import axios from '../../helpers/axios';
import { useIsFeatureEnabled } from '../../helpers/utils';
import SportsRecruitsAuthorizationModal from './SportsRecruitsAuthorizationModal';
import { UserContext } from '../../services/Session';

const SRIntegration = ({ claimedPlayer }) => {
  const [linkedProfile, setLinkedProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSRInfoModal, setShowSRInfoModal] = useState(false);

  const fetchSRPlayer = () => {
    setLoading(true);
    const playerId = claimedPlayer._id;
    axios.get(`/api/sr/player/${playerId}`).then((response) => {
      setLinkedProfile(response.data);
      setLoading(false);
    }).catch((respError) => {
      if (respError.response && respError.response.status === 404) {
        setLoading(false);
      } else {
        Sentry.captureException(respError);
        throw respError;
      }
    });
  };

  useEffect(() => {
    fetchSRPlayer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLinked = () => (
    <Box gap="medium">
      <Box
        direction="row"
        align="center"
        gap="small"
        pad="xsmall"
        background="status-ok"
        border
        round
      >
        <StatusGood />
        <Text>Connected</Text>
      </Box>
      <Box direction="row" gap="small" align="center">
        <Text>Linked to: </Text>
        <Anchor href={linkedProfile.profile_url} target="_blank" rel="noopener noreferrer">
          <Text>{linkedProfile.profile_url}</Text>
        </Anchor>
      </Box>
    </Box>
  );

  const renderUnlinked = () => (
    <Box gap="medium">
      <Box
        direction="row"
        align="center"
        gap="small"
        pad="xsmall"
        background="light-3"
        border
        round
      >
        <StatusDisabled />
        <Text>Not Connected</Text>
      </Box>
      <Box direction="row" gap="small" align="center">
        <Button
          primary
          color="tertiary-1"
          label="Connect to SportsRecruits"
          onClick={() => {
            setShowSRInfoModal(true);
          }}
          fill="horizontal"
        />
      </Box>
    </Box>
  );

  return (
    <Card elevation="none" pad="small" gap="small" border={{ color: 'dark-5', size: '2px' }}>
      <Box direction="row" gap="small">
        <Image src="/sr_logo.png" width="24px" />
        <Text size="large" weight="bold">SportsRecruits</Text>
      </Box>
      {loading ? (
        <Skeleton height={44} />
      ) : linkedProfile ? renderLinked() : renderUnlinked()}
      {showSRInfoModal && (
        <SportsRecruitsAuthorizationModal
          playerId={claimedPlayer._id}
          onClose={() => {
            setShowSRInfoModal(false);
          }}
        />
      )}
    </Card>
  );
};

const PlayerIntegrations = ({
  setShowEditSuccess = () => {},
  ...props
}) => {
  const userStore = useContext(UserContext);
  const srIntegrationEnabled = useIsFeatureEnabled('show_sr_integration', userStore);

  const [error, setError] = useState();
  const [claimedPlayer, setClaimedPlayer] = useState({ ...props.claimedPlayer });
  const [dkPairingInfo, setDKPairingInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [showDKInstructions, setShowDKInstructions] = useState(false);

  const getDKPairingStatus = () => {
    setLoading(true);
    axios.get(`/api/claimed_player/${claimedPlayer._id}/pair_dk_account/${claimedPlayer.dkPlayerUuid}`)
      .then((response) => {
        setDKPairingInfo(response.data);
        setLoading(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        if (respError.response && respError.response.data.message) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. Please refresh and try again.');
        }
        setLoading(false);
      });
  };

  const renderDKPairingContent = () => (
    <Box pad="medium" gap="small">
      <Text>
        In the DK app, go to the clubhouse and click on the gear.
        Long press on your profile picture to copy your DK ID.
      </Text>
      <Box direction="row" gap="xsmall">
        <Image src="dk_uuid_settings_1.png" width="120px" fit="contain" />
        <Image src="dk_uuid_settings_2.png" width="120px" fit="contain" />
      </Box>
      <Button
        label="Close"
        primary
        color="tertiary-1"
        size="large"
        onClick={() => {
          setShowDKInstructions(false);
        }}
      />
    </Box>
  );

  const renderDKPairingInfo = () => {
    if (!dkPairingInfo) {
      return null;
    }

    return (
      <Box
        pad="medium"
        background="light-1"
        round="small"
        gap="small"
      >
        <Text size="small" color={dkPairingInfo.isPaired ? 'dark-1' : 'status-critical'}>
          {dkPairingInfo.pairingTxt}
        </Text>
        <Text size="small" color="dark-1">
          {dkPairingInfo.swingAgeTxt}
        </Text>
      </Box>
    );
  };

  const renderPairButton = () => {
    if (dkPairingInfo && dkPairingInfo.isPaired) {
      return (
        <Button
          primary
          color="status-critical"
          label="Unpair Diamond Kinetics Account"
          onClick={() => {
            setLoading(true);
            axios.delete(
              `/api/claimed_player/${claimedPlayer._id}/pair_dk_account/${claimedPlayer.dkPlayerUuid}`,
            ).then(() => {
              const newClaimedPlayer = { ...claimedPlayer, dkPlayerUuid: '' };
              setClaimedPlayer(newClaimedPlayer);
              setDKPairingInfo();
              setShowEditSuccess(newClaimedPlayer);
              setLoading(false);
              setError();
            }).catch((respError) => {
              Sentry.captureException(respError);
              setLoading(false);
            });
          }}
        />
      );
    }
    return (
      <Button
        primary
        color="tertiary-1"
        label="Pair Diamond Kinetics Account"
        onClick={() => {
          setLoading(true);
          axios.post(
            `/api/claimed_player/${claimedPlayer._id}/pair_dk_account/${claimedPlayer.dkPlayerUuid}`,
          ).then((response) => {
            const { dkUserProfile } = response.data;
            const newClaimedPlayer = { ...claimedPlayer, dkPlayerUuid: dkUserProfile.dkPlayerUuid };
            setClaimedPlayer(newClaimedPlayer);
            getDKPairingStatus();
            setShowEditSuccess(newClaimedPlayer);
            setLoading(false);
            setError();
          }).catch((respError) => {
            Sentry.captureException(respError);
            setLoading(false);

            if (respError.response && respError.response.data.message) {
              setError(respError.response.data.message);
            }
          });
        }}
      />
    );
  };

  useEffect(() => {
    if (claimedPlayer.dkPlayerUuid) {
      getDKPairingStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      height="100%"
      pad="medium"
      gap="medium"
      overflow="auto"
      id="edit-player-integrations-box"
      width="large"
    >
      <Card elevation="none" pad="small" gap="small" border={{ color: 'dark-5', size: '2px' }}>
        <Box direction="row" gap="small">
          <Image src="/dk_green_logo.png" width="24px" />
          <Text size="large" weight="bold">Diamond Kinetics</Text>
        </Box>
        <Text color="status-critical">{error}</Text>
        <TextInput
          autoComplete="nope"
          placeholder="Diamond Kinetics ID"
          onChange={(e) => {
            setClaimedPlayer({ ...claimedPlayer, dkPlayerUuid: e.target.value });
          }}
          value={claimedPlayer.dkPlayerUuid || ''}
          disabled={dkPairingInfo && dkPairingInfo.isPaired}
          plain
        />
        {loading ? <Skeleton height={64} /> : renderDKPairingInfo()}
        {loading ? <Skeleton height={22} /> : (
          <DropButton
            alignSelf="start"
            open={showDKInstructions}
            icon={<CircleQuestion />}
            label={<Text size="small">Where do I find my DK ID?</Text>}
          // onClick={() => {}}
            onOpen={() => {
              setShowDKInstructions(true);
            }}
            onClose={() => {
              setShowDKInstructions(false);
            }}
            dropContent={renderDKPairingContent()}
            dropProps={{ top: 'bottom' }}
            plain
          />
        )}
        {loading ? <Skeleton height={22} /> : renderPairButton()}
      </Card>
      {srIntegrationEnabled && <SRIntegration claimedPlayer={props.claimedPlayer} />}
    </Box>
  );
};

export default PlayerIntegrations;
