// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Image } from 'grommet';

const IconUSSSA = () => (
  <Image src="usssaIcon.svg" />
);

export default IconUSSSA;
