import { useCallback } from 'react';
import axios from '../../../helpers/axios';
import { InviteBody, StaffMember } from '../types';
import * as Sentry from '@sentry/browser';
import { shd } from '@shd/jslib/models';

const useTeamStaff = () => {
  const sendInvite = useCallback(async (body: InviteBody) => {
    return axios
      .post('/api/user_invites', body)
      .then((response) => {
        const { invites: newInvites } = response.data;
        return newInvites.map((invite: shd.Invite) => ({
          nameFirst: invite.invitedUserNameFirst || 'awaiting',
          nameLast: invite.invitedUserNameLast || ' user to accept invite',
          phoneList: [invite.invitedUserPhone],
          roles: [invite.teamRole],
          inviteCode: invite.id,
          pending: true,
          expires: invite.expires,
        }));
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          throw new Error(e.response.data.message);
        } else {
          throw new Error(e.toString());
        }
      });
  }, []);

  const fetchTeamStaff = useCallback(async (teamId: string) => {
    return axios.get(`/api/team/${teamId}/roles`).then((response) => {
      return response.data.users;
    });
  }, []);

  const updateStaff = useCallback(
    async (staffMemberId: string | undefined, payload: StaffMember) => {
      return axios
        .put(`/api/user/${staffMemberId}`, { newUser: payload })
        .then((resp) => {
          return resp;
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          throw new Error(respError.toString());
        });
    },
    []
  );

  const deleteStaff = useCallback(
    async (staffMemberId: string | undefined, payload: StaffMember) => {
      return axios
        .put(`/api/user/${staffMemberId}`, { newUser: payload })
        .then((response) => response)
        .catch((respError) => {
          Sentry.captureException(respError);
          throw new Error(respError.toString());
        });
    },
    []
  );

  return { sendInvite, fetchTeamStaff, updateStaff, deleteStaff };
};

export default useTeamStaff;
