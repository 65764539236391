import React, { useContext } from 'react';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import SignInV2 from './SignInV2';
import SignIn from './SignIn';

const SignInContainer: React.FC = () => {
  const userStore = useContext(UserContext);
  const isPhoneNumberAuth = useIsFeatureEnabled('phone_number_auth', userStore);

  return isPhoneNumberAuth ? <SignInV2 /> : <SignIn />;
};

export default SignInContainer;
