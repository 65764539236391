// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { Box, Text } from 'grommet';

const ScoringButton = ({ basis, label, isOpponent, onClick }) => (
  <Box
    basis={basis}
    background={isOpponent ? 'secondary-7' : 'accent-7'}
    height="48px"
    align="center"
    justify="center"
    border={{
      color: isOpponent ? 'secondary-5' : 'accent-1',
      size: '1px',
    }}
    onClick={onClick}
  >
    <Text weight="bold" size="large">
      {label}
    </Text>
  </Box>
);

export default ScoringButton;
