import React, { useEffect, useRef, useState } from 'react';
import { Box, Grommet, Text, TextInput } from 'grommet';
import { AuthError, AuthErrorCodes } from 'firebase/auth';

interface Props {
  error: AuthError | null;
  onChange: (code: string) => void;
}

const SixDigitCodeInput: React.FC<Props> = ({ error, onChange }) => {
  const [code, setCode] = useState('');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Focus the first input on component mount
    const timer = setTimeout(() => {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
        inputRefs.current[0].click();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    onChange(code);
  }, [code, onChange]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value.replace(/\D/g, '');
    const newCode = code.split('');

    // If it's the first input and the value is longer than 1 character,
    // it's likely the autofill. Distribute the digits across inputs.
    if (index === 0 && value.length > 1) {
      value.split('').forEach((digit, i) => {
        if (i < 6) {
          newCode[i] = digit;
        }
      });
      setCode(newCode.join(''));

      // Focus on the last filled input or the 6th one
      const lastFilledIndex = Math.min(value.length - 1, 5);
      inputRefs.current[lastFilledIndex]?.focus();
    } else {
      // Normal single-digit input handling
      newCode[index] = value.slice(0, 1);
      setCode(newCode.join(''));

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Backspace' && !code[index] && index > 0) {
      const newCode = code.split('');
      newCode[index - 1] = '';
      setCode(newCode.join(''));
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    const sanitizedPaste = pastedData.replace(/\D/g, '').slice(0, 6);
    setCode(sanitizedPaste.padEnd(6, ''));
    inputRefs.current[Math.min(sanitizedPaste.length, 5)]?.focus();
  };

  return (
    <Box gap="16px">
      <Box direction="row" gap="16px">
        {Array.from({ length: 6 }).map((_, index) => {
          const digit = code[index] || '';
          let underlineColor = digit ? 'tertiary-1' : 'gray-2';
          if (error) {
            underlineColor = 'red';
          }

          return (
            <Box
              key={`digit-container-${index}`}
              border={{
                side: 'bottom',
                color: underlineColor,
                size: '4px',
                style: 'inset',
              }}
            >
              <Grommet
                theme={{
                  global: {
                    input: {
                      padding: {
                        top: '16px',
                        bottom: '12px',
                        horizontal: '0px',
                      },
                      extend: `caret-color: transparent !important;`,
                    },
                  },
                }}
              >
                <TextInput
                  plain
                  focusIndicator={false}
                  key={index}
                  id={`code-input-${index}`}
                  ref={(el) => (inputRefs.current[index] = el)}
                  placeholder="0"
                  value={digit}
                  onChange={(event) => handleChange(event, index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  onPaste={handlePaste}
                  size="48px"
                  textAlign="center"
                  inputMode="numeric"
                />
              </Grommet>
            </Box>
          );
        })}
      </Box>
      {error && (
        <Text color="red">
          {error.code === AuthErrorCodes.INVALID_CODE
            ? 'Incorrect code. Please try again.'
            : error.message}
        </Text>
      )}
    </Box>
  );
};

export default SixDigitCodeInput;
