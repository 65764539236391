import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardHeader, Text } from 'grommet';
import PhoneNumberInputV2 from '../../helpers/PhoneNumberInputV2';
import { MoonLoader } from 'react-spinners';
import InvitedUserHeader from './InvitedUserHeader';
import ClaimPlayerHeader from './ClaimPlayerHeader';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

interface PhoneNumberEntryProps {
  phoneNumber: string;
  setPhoneNumber: (phone: string) => void;
  sendSMSLoading: boolean;
  onContinue: () => void;
  onShowAlternativeLogin: () => void;
  inviteCode: string | undefined;
  teamPlayerIdToBeClaimed: string | null;
}

export const PhoneNumberEntry: React.FC<PhoneNumberEntryProps> = ({
  phoneNumber,
  setPhoneNumber,
  sendSMSLoading,
  onContinue,
  onShowAlternativeLogin,
  inviteCode,
  teamPlayerIdToBeClaimed,
}) => {
  const [isPhoneNumberPossible, setIsPhoneNumberPossible] = useState(false);

  useEffect(() => {
    setIsPhoneNumberPossible(isPossiblePhoneNumber(phoneNumber));
  }, [phoneNumber]);
  return (
    <Card pad="large" gap="16px" background="white" fill="horizontal">
      {inviteCode && <InvitedUserHeader inviteCode={inviteCode} />}
      {teamPlayerIdToBeClaimed && (
        <ClaimPlayerHeader teamPlayerIdToBeClaimed={teamPlayerIdToBeClaimed} />
      )}
      {!inviteCode && !teamPlayerIdToBeClaimed && (
        <CardHeader align="start" direction="column" gap="8px">
          <Text weight="bold" size="20px">
            Welcome 👋
          </Text>
          <Text>Sign in or register with your phone number.</Text>
        </CardHeader>
      )}
      <Box gap="16px">
        <PhoneNumberInputV2
          placeholder="Phone Number"
          value={phoneNumber}
          successIcon={<Box />}
          onChange={(phone) => {
            setPhoneNumber(phone);
          }}
          onPossiblePhoneNumber={() => {
            setIsPhoneNumberPossible(true);
          }}
          onError={() => {
            setIsPhoneNumberPossible(false);
          }}
        />
        <Text size="small">US/Canada phone numbers only</Text>
        <Button
          primary
          icon={sendSMSLoading ? <MoonLoader size={20} /> : undefined}
          label={
            sendSMSLoading ? <Text color="transparent"></Text> : 'Continue'
          }
          onClick={onContinue}
          disabled={!isPhoneNumberPossible}
        />
        <Button
          plain
          label={
            <Text weight="bold" color={'tertiary-1'}>
              Other sign-in options
            </Text>
          }
          onClick={onShowAlternativeLogin}
        />
      </Box>
    </Card>
  );
};

export default PhoneNumberEntry;
