// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, DropButton } from 'grommet';
import React from 'react';
import { HiShare } from 'react-icons/hi';

const ShareDropdown = ({ renderShareOptions, ...rest }) => (
  // <Box align="center">
  <Box>
    <DropButton
      {...rest}
      dropContent={renderShareOptions()}
      dropProps={{ align: { top: 'bottom' } }}
      icon={<HiShare />}
    />
  </Box>
);

export default ShareDropdown;
