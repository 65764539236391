import React, { useState, useContext } from 'react';
import { Box } from 'grommet';
import { UserContext } from '../../../services/Session/context';
import Toast from '../../../components/Toast';
import { VOLLEYBALL } from '../../../constants/strings';
import { shd } from '@shd/jslib/models';
import StreamingDestinations from './StreamingDestinations';
import { isMobile } from '../../../helpers/browserDetect';
import MuteSettings from './MuteSettings';
import AdminPhoneNumbers from './AdminPhoneNumbers';
import TeamSwitcher from '../TeamSwitcher';

const StreamPublishing: React.FC = () => {
  const userStore = useContext(UserContext);

  const teamId = userStore.selectedTeam ? userStore.selectedTeam._id : null;
  const [toast, setToast] = useState<shd.Toast>();

  if (!teamId) {
    return null;
  }

  return (
    <Box
      margin={{ vertical: 'medium' }}
      gap="large"
      background={isMobile.any() ? 'light-5' : 'white'}
    >
      {isMobile.any() && <TeamSwitcher />}
      <Box
        pad="medium"
        round={!isMobile.any() ? { size: 'small' } : undefined}
        border={!isMobile.any() ? { color: 'light-2' } : undefined}
        flex
      >
        <StreamingDestinations setToast={setToast} />
        {userStore.selectedTeam?.attrib_sportType !== VOLLEYBALL.display && (
          <MuteSettings setToast={setToast} />
        )}
        <AdminPhoneNumbers setToast={setToast} />
      </Box>

      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(undefined);
          }}
        />
      )}
    </Box>
  );
};

export default StreamPublishing;
