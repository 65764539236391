// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import FieldNameLong from './Fields/FieldNameLong';
import FieldNameMed from './Fields/FieldNameMed';
import FieldTeamType from './Fields/FieldTeamType';
import FieldTeamAgeU from './Fields/FieldTeamAgeU';
import FieldTeamSeason from './Fields/FieldTeamSeason';
import FieldLocation from './Fields/FieldLocation';
import FieldTeamAge from './Fields/FieldTeamAge';
import FieldTeamAgeLevel from './Fields/FieldTeamAgeLevel';
import FieldUniqueLink from './Fields/FieldUniqueLink';

import {
  MANUAL_ENTRY_NAME_LONG,
  MANUAL_ENTRY_NAME_MED,
  MANUAL_ENTRY_NAME_TYPE,
  MANUAL_ENTRY_NAME_AGE_U,
  MANUAL_ENTRY_NAME_AGE_LEVEL,
  MANUAL_ENTRY_NAME_LOCATION,
  MANUAL_ENTRY_NAME_AGE,
  MANUAL_ENTRY_NAME_SEASON,
  MANUAL_ENTRY_NAME_LINK,
  STEPS,
} from '../steps';

const ManualEntryStep = ({ currentStepIndex, handleForward, value }) => (
  <>
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_LONG
          && <FieldNameLong value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_MED
          && <FieldNameMed value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_TYPE
          && <FieldTeamType value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_AGE_U
          && <FieldTeamAgeU value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_AGE_LEVEL
          && <FieldTeamAgeLevel value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_SEASON
          && <FieldTeamSeason value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_LOCATION
          && <FieldLocation value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_AGE
          && <FieldTeamAge value={value} handleForward={handleForward} />}
    {STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_LINK
          && <FieldUniqueLink value={value} />}
  </>
);

export default ManualEntryStep;
