import React from 'react';
import { Box, Text, Button, Layer } from 'grommet';

export interface ActionConfirmationModalProps {
  action: () => void;
  onClose: () => void;
  confirmationText: string;
  actionName: string;
}

const ActionConfirmationModal: React.FC<ActionConfirmationModalProps> = ({
  action,
  onClose,
  confirmationText,
  actionName,
}) => {
  return (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <Box pad="medium" gap="small">
        <Text>{confirmationText}</Text>
        <Box direction="row" gap="small" justify="end">
          <Button primary label={actionName} onClick={action} />
          <Button secondary label="Close" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

export default ActionConfirmationModal;
