import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import withLayout from '../../helpers/withLayout';
import { compose } from 'recompose';
import { Box } from 'grommet';
import GameHlsPlayer from '../../components/HLS/HlsPlayer';
import {
  useIsFeatureEnabled,
  useOrientation,
  useQuery,
} from '../../helpers/utils';
import { UserContext } from '../../services/Session';

interface Props extends RouteComponentProps {}
const LivestreamPage: React.FC<Props> = () => {
  const userStore = React.useContext(UserContext);
  const hlsViewerEnabled = useIsFeatureEnabled('hls_viewer', userStore);
  const query = useQuery();
  const hlsUrl = query.get('hlsurl');
  const { isPortrait } = useOrientation({});
  if (hlsViewerEnabled) {
    return (
      <Box background="light-1">
        <Box
          margin="small"
          fill={isPortrait ? 'vertical' : 'horizontal'}
          align="center"
        >
          <GameHlsPlayer
            hlsInfo={{ type: 'uri', uri: hlsUrl ?? '' }}
            fetchInfoError={hlsUrl ? undefined : 'Error: No stream supplied'}
          />
        </Box>
      </Box>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export const Livestream = compose<
  Props,
  Omit<Props, keyof RouteComponentProps>
>(
  withLayout,
  withRouter
)(LivestreamPage);
