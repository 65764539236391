import { shd } from '@shd/jslib/models';
import { Box, Heading, Button, Card, CardHeader, Text } from 'grommet';
import { Apple, Facebook, Google, Radial } from 'grommet-icons';
import { useEffect, useState } from 'react';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import { pluralize } from '../../helpers/utils';
import { AuthProvider } from 'firebase/auth';
import { theFirebase } from '../../services/Firebase/firebase';

interface UserCardProps {
  user: shd.User;
  onSelect: () => void;
  onSelectProviderId: (providerId: string) => void;
  selected: boolean;
}

const UserCard: React.FC<UserCardProps> = ({
  user,
  onSelect,
  onSelectProviderId,
  selected,
}) => {
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState<string | null>(null);

  useEffect(() => {
    const fetchAuthProvider = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/user/${user._id}/auth_provider`);
        setProvider(response.data.provider);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    fetchAuthProvider();
  }, [user._id]);

  let icon;
  if (loading) {
    icon = <BasicLoader size={24} />;
  } else if (provider === 'google.com') {
    icon = <Google color="plain" size="24px" />;
  } else if (provider === 'apple.com') {
    icon = <Apple color="plain" size="24px" />;
  } else if (provider === 'facebook.com') {
    icon = <Facebook color="plain" size="24px" />;
  } else {
    icon = <Radial size="24px" />;
  }
  const playerText = pluralize(
    user.ROLE_CLAIMED_PLAYER_ADMIN?.length || 0,
    'player',
    'players'
  );
  const teamText = pluralize(
    user.ROLE_TEAM_ADMIN?.length || 0,
    'team',
    'teams'
  );

  return (
    <Box
      border={{ side: 'all', color: selected ? 'tertiary-1' : 'light-1' }}
      direction="row"
      gap="16px"
      pad={{ horizontal: '16px', vertical: '8px' }}
      round="8px"
      flex={{ shrink: 0 }}
      onClick={() => {
        onSelect();
        provider && onSelectProviderId(provider);
      }}
    >
      <Box align="center" justify="center">
        {icon}
      </Box>
      <Box>
        <Text weight={'bold'}>
          {user.nameFirst} {user.nameLast}
        </Text>
        <Text>{user.email}</Text>
        <Text size="small">{`${playerText}, ${teamText}`}</Text>
      </Box>
    </Box>
  );
};

interface MultipleUsersForPhoneNumberProps {
  users: shd.User[];
  onBack: () => void;
  onContinue: (provider: AuthProvider) => void;
}

const MultipleUsersForPhoneNumber: React.FC<
  MultipleUsersForPhoneNumberProps
> = ({ users, onBack, onContinue }) => {
  const [selectedUser, setSelectedUser] = useState<shd.User | null>(null);
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(
    null
  );
  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();
  const selectedProvider =
    selectedProviderId === 'google.com'
      ? googleProvider
      : selectedProviderId === 'facebook.com'
        ? facebookProvider
        : selectedProviderId === 'apple.com'
          ? appleProvider
          : null;
  return (
    <Box gap="16px">
      <Card gap="8px" background="white" fill="horizontal" elevation="none">
        <CardHeader pad="16px" align="start" direction="column">
          <Heading margin="none" level={4}>
            We’ve detected multiple emails associated with this phone number.
          </Heading>
          <Text>Please sign in to the account you’re looking for.</Text>
        </CardHeader>
        <Box
          gap="8px"
          overflow={'auto'}
          pad={{ horizontal: '16px', bottom: '16px' }}
        >
          {users.map((user) => (
            <UserCard
              key={user._id}
              user={user}
              onSelect={() => setSelectedUser(user)}
              selected={selectedUser === user}
              onSelectProviderId={setSelectedProviderId}
            />
          ))}
        </Box>
      </Card>
      <Card
        background={'white'}
        pad="16px"
        elevation="none"
        justify="between"
        direction="row"
        flex={{ shrink: 0 }}
      >
        <Button secondary label="Back" onClick={onBack} />
        <Button
          primary
          label="Continue"
          onClick={() => {
            selectedProvider && onContinue(selectedProvider);
          }}
          disabled={!selectedUser}
        />
      </Card>
    </Box>
  );
};

export default MultipleUsersForPhoneNumber;
