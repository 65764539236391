import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box } from 'grommet';
import Cookies from 'universal-cookie';

import { Apple, Facebook, Google, Radial } from 'grommet-icons';

import SHDButton from '../../components/SHD/Button';
import { AuthCredential, AuthProvider } from '@firebase/auth';
import { dtos } from '@shd/jslib/models';
import useAuthentication from './authHooks';

const cookies = new Cookies();

interface Props extends RouteComponentProps {
  toggleLoading: (loading: boolean) => void;
  provider: AuthProvider;
  label: React.ReactNode;
  errorHandler: (error: { message: React.ReactNode }) => void;
  linkAccountHandler?: (credential: AuthCredential | null) => void;
  onSuccess?: (data: dtos.UserPostResponse) => void;
  postUrl?: string;
  claimPlayer?: string | null;
  claimPlayerKey?: string | null;
  claimPhoneNumber?: string | null;
  postParams?: Partial<dtos.UserPostRequest>;
}

const SignInBase: React.FC<Props> = (props) => {
  const {
    toggleLoading,
    provider,
    label,
    errorHandler,
    linkAccountHandler,
    onSuccess,
    postUrl = '/api/user',
    claimPlayer,
    claimPlayerKey: key,
    claimPhoneNumber,
    postParams,
  } = props;
  const [extraPostParams, setExtraPostParams] = useState(postParams);

  const { authenticate } = useAuthentication({
    errorHandler,
    linkAccountHandler,
    onSuccess,
    postUrl,
    claimPlayer: claimPlayer || '',
    claimPlayerKey: key || '',
    claimPhoneNumber: claimPhoneNumber || '',
    toggleLoading,
    provider,
    history: props.history,
    location: props.location,
    match: props.match,
  });

  useEffect(() => {
    const referrerUserId = localStorage.getItem('referrerUserId');
    if (referrerUserId) {
      setExtraPostParams({
        ...extraPostParams,
        referrer_user_id: referrerUserId,
      });
    }

    const referralCode = cookies.get('referralCode');
    if (referralCode) {
      setExtraPostParams({
        ...extraPostParams,
        referral_code: referralCode,
      });
    }

    const externalEmail = cookies.get('externalEmail');
    if (externalEmail) {
      setExtraPostParams({
        ...extraPostParams,
        external_email: externalEmail,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let icon;
  let color;
  if (provider.providerId === 'google.com') {
    icon = (
      <Box background={{ color: 'white' }} pad="3px">
        <Google color="plain" size="20%" />
      </Box>
    );
    color = '#4285F4';
  } else if (provider.providerId === 'facebook.com') {
    color = '#3b5998';
    icon = <Facebook color={color} />;
  } else if (provider.providerId === 'apple.com') {
    color = '#000000';
    icon = <Apple color={color} />;
  } else {
    icon = <Radial color="light-1" />;
    color = 'brand';
  }
  return (
    <SHDButton
      secondary
      icon={icon}
      gap="16px"
      color="light-1"
      label={label}
      onClick={() => {
        authenticate(undefined, undefined, extraPostParams);
      }}
      size="large"
      fill="horizontal"
    />
  );
};

const SignInMethod = compose<Props, Omit<Props, keyof RouteComponentProps>>(
  withRouter
)(SignInBase);

export default SignInMethod;
