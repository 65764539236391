import React from 'react';
import SHDButton from '../../components/SHD/Button';
import { Text } from 'grommet';
import { useHistory } from 'react-router-dom';
import { LiveEvents } from '../SidelineLive/sidelineLive.analytics';
import { SidelineLiveButtonProps } from './sidelineLive.types';

const SidelineLiveButton: React.FC<SidelineLiveButtonProps> = ({
  teamId,
  trackEvent,
}) => {
  const history = useHistory();
  const handleButtonClick = () => {
    trackEvent(LiveEvents.WATCH_LIVE, {});

    history.push(`/live/${teamId}/`);
  };
  return (
    <SHDButton
      primary
      label={
        <Text size="small" weight={'bold'}>
          {'sidelineLIVE'}
        </Text>
      }
      onClick={handleButtonClick}
      style={{ padding: '0 20px' }}
    />
  );
};

export default SidelineLiveButton;
