// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Box, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import Toast from '../../components/Toast';
import MevoSetup from './MevoSetup';
import {
  MEVO,
  GOPRO,
  SMARTPHONE,
  SMARTPHONE_LEGACY,
} from '../../constants/strings';
import SmartphoneSetup from './SmartphoneSetup';
import GoProSetup from './GoProSetup';

const ViewCapture = (props) => {
  const {
    name,
    type,
    rtmp: rtmpProp,
    captureId = null,
    onClose = () => {},
  } = props;
  const [rtmp, setRtmp] = useState(rtmpProp);
  const [loading, toggleLoading] = useState(true);
  const [rtmpError, setRtmpError] = useState(null);
  const [copied, setCopied] = useState(false);

  const isSmartphone = type === SMARTPHONE || type === SMARTPHONE_LEGACY;

  useEffect(() => {
    if (!rtmp && captureId) {
      axios
        .get(`/api/rtmp_key/${captureId}`)
        .then((response) => {
          setRtmp(response.data.rtmp || null);
          toggleLoading(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          setRtmpError(error.toString());
          toggleLoading(false);
        });
    } else {
      toggleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <BasicLoader size={50} />;
  }

  if (rtmpError) {
    return <Text color="red">{rtmpError}</Text>;
  }

  return (
    <Box fill>
      {type === MEVO && rtmp && (
        <MevoSetup name={name} rtmp={rtmp} onClose={onClose} />
      )}
      {isSmartphone && rtmp && (
        <SmartphoneSetup name={name} rtmp={rtmp} onClose={onClose} />
      )}
      {type === GOPRO && rtmp && (
        <GoProSetup name={name} rtmp={rtmp} onClose={onClose} />
      )}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};

export default ViewCapture;
