// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box, Text } from 'grommet';
import { PromptNote, PromptText, TextContainer } from '../../styles';
import { PhoneOption, PhoneRadioButton } from '../style';

const SelectingStep = ({ teamPlayer, selectedPhone, setSelectedPhone }) => (
  <Box gap="medium">
    <TextContainer>
      <PromptText color="dark-1">
        {`We found the following phone numbers.
        Choose your number and we’ll send a link to help you claim  ${teamPlayer.nameFirst} ${teamPlayer.nameLast}.`}
      </PromptText>
      <PromptNote color="dark-4">Free: message rates are on us!</PromptNote>
    </TextContainer>
    <PhoneRadioButton
      name="radio"
      className="claimPlayerRadioButton"
      options={
        teamPlayer.invitePhoneNumberList.length
          ? teamPlayer.invitePhoneNumberList.map((phone) => {
              const value = phone;
              const bottiniNum = phone.slice(-2);
              const label = `(XXX) XXX - XX${bottiniNum}`;
              return { label, value };
            })
          : []
      }
      value={selectedPhone}
      onChange={(event) => {
        setSelectedPhone(event.target.value);
      }}
    >
      {(option, { checked, hover }) => {
        let background;
        if (checked) {
          background = 'secondary-1';
        } else if (hover) {
          background = 'secondary-7';
        } else {
          background = 'secondary-7';
        }
        return (
          <PhoneOption id={option.value} background={background}>
            <Text>{option.label}</Text>
          </PhoneOption>
        );
      }}
    </PhoneRadioButton>
  </Box>
);

export default SelectingStep;
