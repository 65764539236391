// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { string } from 'yup';
import { Select } from 'grommet';
import { FieldStyled, FieldWrapper } from './style';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';
import { ageUOptions } from '../../../../../constants/teamFormOptions';

const validationScheme = string()
  .required('Field is required');

const FieldTeamAgeU = ({ value, handleForward }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validationScheme.validate(value.teamType).then(() => {
      setIsValid(true);
    }).catch(() => {
      setIsValid(false);
    });
  }, [value]);

  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">What age bracket is your team?</FieldLabel>
          <FieldTip color="dark-4">Required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled name="attrib_ageU">
          <Select
            id="age-u-id"
            name="attrib_ageU"
            placeholder="Age group"
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            options={ageUOptions}
          />
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton disabled={!isValid} label="Next" onClick={handleForward} />
    </>
  );
};

export default FieldTeamAgeU;
