// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useState, useEffect } from 'react';
import { Box, Form, Heading, Layer, Text } from 'grommet';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import {
  FieldDate,
  FieldGameTitle,
  FieldOpponentName,
  FieldTime,
} from '../../helpers/FormFields';
import axios from '../../helpers/axios';

import { BasicLoader } from '../../helpers/Loaders';

const EditGameBase = ({ onClose, ...props }) => {
  const userStore = useContext(UserContext);
  const [team, setTeam] = useState(
    userStore.selectedTeam || userStore.scoringTeam
  );
  const [error, setError] = useState();
  const [createLoading, toggleCreateLoading] = useState(false);
  const [loading, toggleLoading] = useState(true);

  const [game, setGame] = useState({
    ...props.game,
    date: DateTime.fromSeconds(props.game.scheduleTsStart).toFormat(
      'yyyy-MM-dd'
    ),
    time: DateTime.fromSeconds(props.game.scheduleTsStart).toFormat('HH:mm'),
  });

  useEffect(() => {
    setGame({
      ...game,
      scoreGameId: props.game.scoreGameId,
      title: props.game.scheduleTitle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editGame = () => {
    toggleCreateLoading(true);
    const newGame = {
      ...game,
      scheduleTitle: game.title,
    };

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const scheduleTsStart =
      DateTime.fromISO(`${newGame.date.slice(0, 10)}T${newGame.time}`).setZone(
        timeZone
      ).ts / 1000;
    newGame.scheduleTsStart = scheduleTsStart;

    // test
    // newGame.scoreOurLineup = [];

    const scoreGameId = newGame.scoreGameId
      ? newGame.scoreGameId
      : `GmSs01-${uuidv4()}`;

    axios
      .put('/api/team_game', {
        teamId: team._id,
        teamGameId: `GmSh01-${scoreGameId}`,
        teamGameUpdate: newGame,
      })
      .then((response) => {
        toggleCreateLoading(false);
        onClose(response.data.teamGame);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleCreateLoading(false);

        onClose(null);
      });
  };

  useEffect(() => {
    if (!team) {
      const scoreTeamId = localStorage.getItem('scoreTeamId');
      const scoreDelegationKey = localStorage.getItem('scoreDelegationKey');
      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
        })
        .catch((respError) => {
          if (respError.response && respError.response.status === 403) {
            setError('You are not authorized to edit a game.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      toggleLoading(false);
    }
  }, [team]);

  if (loading) {
    return <BasicLoader fullPage />;
  }

  return (
    <Layer width="large">
      <Box gap="medium" pad="large" fill="vertical">
        <Heading level="3" size="medium">
          Edit game
        </Heading>
        {error ? <Text color="status-critical">{error}</Text> : null}
        <Form
          value={game}
          onSubmit={editGame}
          onChange={(nextValue) => {
            setGame(nextValue);
          }}
        >
          <Box justify="between" fill="vertical">
            <Box gap="small">
              <FieldOpponentName name="scoreOpponentName" required />
              <FieldDate required defaultDate={game.date} />
              <FieldTime required date={game.time} />
              <FieldGameTitle />
            </Box>
            <Box
              justify="between"
              fill="horizontal"
              pad={{ vertical: 'large' }}
              direction="row"
              align="center"
              margin={{ bottom: 'xlarge' }}
            >
              <Box
                pad={{ horizontal: 'large', vertical: 'medium' }}
                justify="center"
                align="center"
                onClick={() => {
                  onClose(null);
                }}
              >
                <Text color="tertiary-1">Cancel</Text>
              </Box>
              <SHDButton
                primary
                size="large"
                label={!createLoading ? 'Save' : '...Saving'}
                disabled={createLoading || !game.scoreOpponentName}
                type="submit"
                color="tertiary-1"
              />
            </Box>
          </Box>
        </Form>
      </Box>
    </Layer>
  );
};

const EditGame = compose(withRouter)(EditGameBase);

export default EditGame;
