import React from 'react';

// Types
import { AdminToolsMergePlayersProps } from '../admin.types';

const AdminToolsMergePlayers: React.FC<AdminToolsMergePlayersProps> = ({
  onSetStep,
  primaryPlayer,
  secondaryPlayers,
}) => {
  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '1rem',
      }}
    >
      <div>
        <strong>
          Example <u>LIVE</u> player handles/IDs
        </strong>
        :
        <ul
          style={{
            fontSize: '0.9375rem',
            display: 'flex',
            listStyle: 'none',
            paddingLeft: '0',
          }}
        >
          <li>
            <ul>
              <li>
                <strong>Nick (Expos)</strong>: ql2Q-CpSh01-nic-LXDxkUDK6EEd
              </li>
              <li>
                <strong>Nick (Test)</strong>: cZvV-CpSh01-nic-MawpxKLwJkI4
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <strong>Primary</strong>: brookpaalhar427
              </li>
              <li>
                <strong>Secondary</strong>: brookepaalhar777
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <strong>Primary</strong>: emily289
              </li>
              <li>
                <strong>Secondary</strong>: 6ydn-TpSh01-77-eTv6zvhbYf
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <ul
        style={{
          display: 'flex',
          listStyle: 'none',
          marginLeft: 0,
          padding: 0,
          justifyContent: 'space-between',
        }}
      >
        <li>
          <button onClick={() => onSetStep(1)}>Step 01</button>
        </li>
        <li>
          <button onClick={() => onSetStep(2)}>Step 02</button>
        </li>
        <li>
          <button onClick={() => onSetStep(3)}>Step 03</button>
        </li>
        <li>
          <button onClick={() => onSetStep(4)}>Step 04</button>
        </li>
      </ul>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <strong>Primary player</strong>
          <ul>
            <li>
              {primaryPlayer
                ? `${primaryPlayer.nameHandleLower} (${primaryPlayer._id})`
                : 'No primary player selected'}
            </li>
          </ul>
        </div>
        <div>
          <strong>Secondary players</strong>
          <ul>
            {secondaryPlayers && secondaryPlayers?.length <= 0 ? (
              <li>No secondary players selected</li>
            ) : (
              secondaryPlayers?.map((player) => (
                <li key={player?._id}>
                  {player?.nameHandleLower} ({player?._id})
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminToolsMergePlayers;
