import React, { useState, useContext, useEffect } from 'react';
import { Box, Text } from 'grommet';
import { UserContext } from '../../../services/Session/context';
import axios from '../../../helpers/axios';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import TagInput from '../../../helpers/TagInput';
import { toJS } from 'mobx';
import { ToastProps } from '../../../components/Toast';

interface Props {
  setToast: (toast: ToastProps) => void;
}
const AdminPhoneNumbers: React.FC<Props> = ({ setToast }) => {
  const userStore = useContext(UserContext);
  const [adminPhoneNumbers, setAdminPhoneNumbers] = useState<string[]>([]);
  const [teamPhoneNumbers, setTeamPhoneNumbers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const teamId = userStore.selectedTeam?._id;

  useEffect(() => {
    axios
      .get(`/api/team/${teamId}/phone_numbers`)
      .then((response) => {
        setTeamPhoneNumbers(response.data.phoneNumbers ?? []);
        setLoading(false);
      })
      .catch((respError) => {
        setError(respError.toString());
      });

    if (
      userStore.selectedTeam &&
      'inviteAdminPhoneNumberList' in userStore.selectedTeam
    ) {
      const rawNumbers = toJS(
        userStore.selectedTeam.inviteAdminPhoneNumberList
      );

      setAdminPhoneNumbers(
        rawNumbers
          .map((number) => {
            const parsedNumberObj = parsePhoneNumber(number, {
              defaultCountry: 'US',
            });
            return parsedNumberObj ? parsedNumberObj.number : undefined;
          })
          .filter((number): number is string => number !== undefined)
      );
    }
  }, [teamId, userStore.selectedTeam]);

  const onSaveAdminPhoneNumber = (
    tags: string[],
    successfullyAdded: boolean
  ) => {
    if (!successfullyAdded) {
      // means we are at the limit
      setToast({
        label:
          'You can only have 2 phone numbers receive livestream notifications',
        background: 'status-error',
      });
    } else {
      axios
        .put(`/api/team/${userStore.selectedTeam?._id}`, {
          teamInfo: {
            inviteAdminPhoneNumberList: tags,
          },
        })
        .then(() => {
          setAdminPhoneNumbers(tags);
          setToast({
            label: 'Phone numbers saved!',
            background: 'status-ok',
          });
        })
        .catch(() => {
          setToast({
            label: 'Phone numbers saving failed!',
            background: 'status-error',
          });
        });
    }
  };

  if (!teamId) {
    return null;
  }

  return (
    <Box
      border={{ side: 'bottom', color: 'light-2' }}
      flex={{ grow: 1, shrink: 0 }}
      pad={{ vertical: '16px' }}
      gap="8px"
      skeleton={loading}
    >
      {error && <Text color="status-error">{error}</Text>}
      <Text size="small" weight={'bold'}>
        Stream notifications
      </Text>
      <Box gap="small">
        {teamPhoneNumbers.length === 1 ? (
          <Text>
            This phone number will receive stream notifications
            <Text weight="bold">{` ${formatPhoneNumber(
              teamPhoneNumbers[0]
            )}`}</Text>
          </Text>
        ) : (
          <Text>
            These phone numbers will receive stream notifications
            <Text weight="bold">
              {` ${teamPhoneNumbers
                .map((number) =>
                  formatPhoneNumber(number) ? formatPhoneNumber(number) : number
                )
                .join(', ')}`}
            </Text>
          </Text>
        )}
        <TagInput
          limit={2}
          value={adminPhoneNumbers}
          onTagAdded={onSaveAdminPhoneNumber}
          onTagRemoved={onSaveAdminPhoneNumber}
        />
        <Text size="small">You can add up to 2 additional phone numbers</Text>
      </Box>
    </Box>
  );
};

export default AdminPhoneNumbers;
