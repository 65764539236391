// Packages
import { toSentenceCase } from '../../../../helpers/utils';
import React from 'react';
import styles from '../../Admin.module.css';

// Components
import { Box, Button, Card, Heading, Text } from 'grommet';
import { LinkNext } from 'grommet-icons';
import AdminClipCard from '../../components/AdminClipCard';

// Types
import { RelabelClipsStepThreeProps } from '../../admin.types';

const RelabelClipsStepThree: React.FC<RelabelClipsStepThreeProps> = ({
  clip,
  oldLabel,
  newLabel,
  onLinkClicked,
}) => {
  return (
    <>
      <AdminClipCard payload={clip} />
      <Card
        className={`
            ${styles['admin-player-card']}
            ${styles['admin-player-card--no-hover']}
          `}
        pad="medium"
      >
        <Box
          direction="row"
          align="center"
          justify="around"
          className={styles['player-info']}
        >
          <Box>
            <Heading
              color={'#212121'}
              level={3}
              size="0.625rem"
              weight={600}
              margin={{ bottom: '0.5rem', top: '0' }}
            >
              Old Label
            </Heading>
            <Text size="1rem" weight={600}>
              {toSentenceCase(oldLabel)}
            </Text>
          </Box>

          <LinkNext />

          <Box>
            <Heading
              color={'#212121'}
              level={3}
              size="0.625rem"
              weight={600}
              margin={{ bottom: '0.5rem', top: '0' }}
            >
              New Label
            </Heading>
            <Text size="1rem" weight={600}>
              {toSentenceCase(newLabel)}
            </Text>
          </Box>
        </Box>
      </Card>
      <Button
        className={styles['admin-step-buttons-finished']}
        label="Finish"
        onClick={() => onLinkClicked && onLinkClicked('/admin')}
        primary
      />
    </>
  );
};

export default RelabelClipsStepThree;
