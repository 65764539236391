import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  ResponsiveContext,
  Text,
  Card,
  CardHeader,
  CardBody,
  Image,
  Button,
  Layer,
  Avatar,
} from 'grommet';
import { User } from 'grommet-icons';
import { HiPencil } from 'react-icons/hi';
import * as Sentry from '@sentry/browser';
import CopyToClipboard from 'react-copy-to-clipboard';
import SHDButton from '../../components/SHD/Button';
import EditPlayerProfile from './EditPlayerProfile';
import Toast from '../../components/Toast';
import ShareDropdown from '../../helpers/ShareDropdown';
import {
  SPORTS_MAP,
  COMBINED_BBSB_KEY,
  VOLLEYBALL,
  BASEBALL,
  SOFTBALL,
} from '../../constants/strings';
import { UserContext } from '../../services/Session';
import { shd } from '@shd/jslib/models';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PlayerLocker from './PlayerLocker';
import PlayerInfoCard from './PlayerInfoCard';
import { getPrivacyName } from '../../helpers/utils';

interface Props extends RouteComponentProps {
  player: shd.Player | null;
  teams: shd.Team[] | null;
  isLoading: boolean;
  claimed: boolean;
  isAdmin: boolean;
  openPlayerSettings: boolean;
  setShowSRCTAModal: (value: boolean) => void;
  setShowSRInfoModal: (value: boolean) => void;
  selectedSport: string;
}

const PlayerCardV2: React.FC<Props> = ({
  player: initialPlayer,
  teams,
  isLoading,
  claimed,
  isAdmin,
  history,
  openPlayerSettings,
  setShowSRCTAModal,
  setShowSRInfoModal,
  selectedSport,
}) => {
  const userStore = useContext(UserContext);
  const screen = useContext(ResponsiveContext);
  const [player, setPlayer] = useState(initialPlayer);
  const [showMoreTeams, setShowMoreTeams] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(
    openPlayerSettings && isAdmin
  );
  const [showEditSuccess, setShowEditSuccess] = useState(false);
  const [copied, setCopied] = useState(false);

  const privacyName = getPrivacyName(
    player?.nameFirst || '',
    player?.nameLast || '',
    player?.embedNameFirstLevel || 0,
    player?.embedNameLastLevel || 0
  );

  useEffect(() => {
    setPlayer(initialPlayer);
  }, [initialPlayer]);

  const getClaimedPlayer = (): shd.ClaimedPlayer | null =>
    claimed ? (player as shd.ClaimedPlayer) : null;
  const claimedPlayer = getClaimedPlayer();

  if (selectedSport === COMBINED_BBSB_KEY) {
    // disambiguate between baseball and softball
    const baseballTeamCount =
      teams?.filter((team) => team.attrib_sportType === BASEBALL.display)
        .length || 0;
    const softballTeamCount =
      teams?.filter((team) => team.attrib_sportType === SOFTBALL.display)
        .length || 0;
    // dont really care if someone is on the same number of baseball and softball teams
    // very rare situation, just default baseball in that case
    if (baseballTeamCount >= softballTeamCount) {
      selectedSport = BASEBALL.key;
    } else {
      selectedSport = SOFTBALL.key;
    }
  } else if (selectedSport === 'vb') {
    selectedSport = VOLLEYBALL.key;
  }

  const viewMoreTeamsClicked = () => {
    setShowMoreTeams(true);
  };

  const handleShare = async () => {
    if (navigator.share) {
      navigator
        .share({
          title: privacyName,
          text: `Check out ${privacyName} on sidelineHD`,
          url: `https://sidelinehd.com/${player?._id}`,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const renderShareOptions = () => (
    <Box pad="medium" gap="small">
      {screen === 'small' && (
        <SHDButton label="Share profile via ..." onClick={handleShare} />
      )}
      <CopyToClipboard
        text={`https://sidelinehd.com/${player?._id}?s=0`}
        onCopy={() => setCopied(true)}
      >
        <SHDButton label="Copy link to profile" />
      </CopyToClipboard>
    </Box>
  );

  const playerCardColor = claimed ? 'primary-8' : 'light-3';

  return (
    <Box skeleton={isLoading}>
      <Box gap="medium" id="playerheader" background="white">
        <Box align="center">
          <Card fill pad="medium" gap="medium" background={playerCardColor}>
            <CardHeader direction="row">
              <Box direction="row" gap="small">
                <Text id="player-name-header" size="xlarge" weight="bold">
                  {privacyName}
                </Text>
                {claimedPlayer?.srProfileUrl && (
                  <Box
                    onClick={() => {
                      if (isAdmin) {
                        // Player is already linked and the viewer is an admin
                        window.open(claimedPlayer.srProfileUrl, '_blank');
                      } else if (!isAdmin && !userStore.authUser) {
                        // Player is linked but the viewer is not logged in
                        // Show an educational modal
                        setShowSRInfoModal(true);
                      } else if (!isAdmin && userStore.authUser) {
                        // Player is already linked and the viewer is not an admin
                        // This is a CTA opportunity to link to SR
                        setShowSRCTAModal(true);
                      }
                    }}
                  >
                    <Image src="/sr_logo.png" width="24px" />
                  </Box>
                )}
              </Box>
              <Box direction="row" gap="medium" align="center">
                <ShareDropdown
                  id="share-button"
                  alignSelf="end"
                  plain
                  size="small"
                  label={
                    <Text size="small" weight="bold">
                      Share
                    </Text>
                  }
                  color="tertiary-1"
                  renderShareOptions={renderShareOptions}
                />
                {isAdmin && player ? (
                  <Box
                    pad="xsmall"
                    round="full"
                    overflow="hidden"
                    background="primary-3"
                  >
                    <Button
                      plain
                      icon={<HiPencil />}
                      onClick={() => setShowEditProfile(true)}
                    />
                  </Box>
                ) : null}
              </Box>
            </CardHeader>
            <CardBody>
              <Box
                margin={{ bottom: 'medium' }}
                gap="medium"
                align="end"
                direction="row"
              >
                <Box basis="1/3" round="medium">
                  {claimedPlayer?.DNOR_imgUrlT150 ? (
                    <Image
                      fill
                      fit="cover"
                      src={claimedPlayer.DNOR_imgUrlT150}
                      style={{ borderRadius: '8px' }}
                    />
                  ) : (
                    <Avatar
                      background={claimed ? 'dark-1' : 'background-back'}
                      size="large"
                    >
                      <User color="white" size="large" />
                    </Avatar>
                  )}
                </Box>
                <Box fill gap="medium">
                  <Card
                    pad="medium"
                    background="white"
                    onClick={() => {
                      history.push(`/${teams?.[0]?._id}`);
                    }}
                  >
                    <Text weight="bold" size="small">
                      Primary team
                    </Text>
                    <Text weight="bold" size="large">
                      {teams?.length ? (
                        <Box direction="row" gap="xsmall" align="center">
                          {SPORTS_MAP[teams[0].attrib_sportType].icon}
                          <Text>{teams[0].nameLong}</Text>
                        </Box>
                      ) : null}
                    </Text>
                  </Card>
                  {teams?.length && teams?.length > 1 ? (
                    <Button
                      primary
                      color="primary-3"
                      label={
                        <Text size="small" weight="bold" alignSelf="start">
                          View more teams
                        </Text>
                      }
                      onClick={viewMoreTeamsClicked}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box gap="medium">
                {claimedPlayer && (
                  <PlayerInfoCard
                    player={claimedPlayer}
                    selectedSport={selectedSport}
                  />
                )}
                {claimedPlayer && (
                  <PlayerLocker
                    player={claimedPlayer}
                    selectedSport={selectedSport}
                  />
                )}
              </Box>
            </CardBody>
          </Card>
        </Box>
      </Box>
      {showMoreTeams && (
        <Layer
          modal
          margin="large"
          position="bottom"
          responsive={false}
          onClickOutside={() => {
            setShowMoreTeams(false);
          }}
          onEsc={() => {
            setShowMoreTeams(false);
          }}
        >
          <Box pad="medium" gap="medium">
            <Text>{`${name} is on`}</Text>
            {teams?.map((team) => (
              <Card
                key={team._id}
                pad="large"
                background="primary-7"
                width="medium"
                onClick={() => {
                  setShowMoreTeams(false);
                  history.push(`/${team._id}`);
                }}
              >
                <Box direction="row" gap="xsmall" align="center">
                  {SPORTS_MAP[team.attrib_sportType].icon}
                  <Text weight="bold">{team.nameLong}</Text>
                </Box>
              </Card>
            ))}
          </Box>
        </Layer>
      )}
      {!!showEditProfile && (
        <EditPlayerProfile
          teams={teams}
          closeLayer={() => setShowEditProfile(false)}
          setShowEditSuccess={
            ((updatedPlayer: shd.Player) => {
              setPlayer(updatedPlayer);
              setShowEditSuccess(true);
            }) as () => void
          }
          claimedPlayer={player || {}}
        />
      )}
      {showEditSuccess && (
        <Toast
          label="Profile changes saved!"
          background="status-ok"
          duration={3000}
          onClose={() => setShowEditSuccess(false)}
        />
      )}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};
export default withRouter(PlayerCardV2);
