import React, { useState, useEffect, useContext } from 'react';
import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Grommet,
  Heading,
  Layer,
  RadioButtonGroup,
  Text,
} from 'grommet';
import * as Sentry from '@sentry/browser';
import axios from '../../../helpers/axios';
import { UserContext } from '../../../services/Session/context';
import { ToastProps } from '../../../components/Toast';
import { withFirebase } from '../../../services/Firebase';
import { Firebase } from '../../../services/Firebase/firebase';
import { Close } from 'grommet-icons';

interface MuteState {
  muteScope?: 'all' | 'fb';
  muteAutoTrigger?: boolean;
}

interface MuteLearnMoreProps {
  onClose: () => void;
}

const MuteLearnMore: React.FC<MuteLearnMoreProps> = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Livestream muting</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Should I mute my stream?</Text>
        <Text margin={{ top: 'small' }}>
          Facebook and YouTube sometimes block streams containing copyrighted
          music.
        </Text>
        <Anchor href="https://help.sidelinehd.com/a/solutions/articles/69000802906">
          Learn about copyright-related issues
        </Anchor>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Can I manually mute my stream?</Text>
        <Text margin={{ top: 'small' }}>
          SidelineSCORE users can tap the MUTE icon in the lower left corner.
        </Text>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Auto-mute during breaks in play</Text>
        <Text margin={{ top: 'small' }}>
          Select Auto-MUTE, and we&apos;ll MUTE when scoring indicates a break
          in play. (Note: Auto-MUTE starts after the first inning/set break.)
        </Text>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Which streams are muted?</Text>
        <Text margin={{ top: 'small' }}>
          You specify which streams go silent. Select Facebook only (Facebook is
          the most sensitive), or all broadcasts.
        </Text>
        <Text margin={{ top: 'small' }}>
          Highlight clips always get full audio. Hot mic alert!
        </Text>
      </Box>
    </Box>
  </Layer>
);

interface MuteSettingsProps {
  firebase: Firebase;
  setToast: (toast: ToastProps) => void;
}
const MuteSettings: React.FC<MuteSettingsProps> = ({ firebase, setToast }) => {
  const userStore = useContext(UserContext);
  const [showMuteLearnMore, setShowMuteLearnMore] = useState(false);

  const [muteState, setMuteState] = useState<MuteState>({
    muteScope: undefined,
    muteAutoTrigger: undefined,
  });

  const teamId = userStore.selectedTeam?._id;

  useEffect(() => {
    if (teamId) {
      firebase.subscribeToTeam(teamId, (snapshot) => {
        if (snapshot) {
          setMuteState({
            muteScope:
              snapshot.muteScope !== undefined
                ? (snapshot.muteScope as 'all' | 'fb')
                : 'all',
            muteAutoTrigger:
              snapshot.muteAutoTrigger !== undefined
                ? snapshot.muteAutoTrigger
                : false,
          });
        }
      });
    }
  }, [firebase, teamId]);

  if (!teamId) {
    return null;
  }

  return (
    <Box
      border={{ side: 'bottom', color: 'light-2' }}
      flex={{ grow: 1, shrink: 0 }}
      pad={{ vertical: '16px' }}
      gap="8px"
    >
      <Box fill="horizontal" direction="row" justify="between" align="center">
        <Text size="small" weight={'bold'}>
          Livestream muting
        </Text>
        <Text
          onClick={(e) => {
            e.stopPropagation();
            setShowMuteLearnMore(true);
          }}
          weight="bold"
          color="tertiary-1"
          size="small"
        >
          Learn more
        </Text>
      </Box>
      <Box gap="small">
        <Text>
          Protect streams from being blocked due to copyrighted audio.
        </Text>
        <Box margin={{ top: 'small' }} gap="small">
          <RadioButtonGroup
            name="mute-options"
            options={[
              {
                label: (
                  <Text size="small">
                    Mute controls just my Facebook broadcast
                  </Text>
                ),
                value: 'fb',
              },
              {
                label: <Text size="small">Mute controls all broadcasts</Text>,
                value: 'all',
              },
            ]}
            value={muteState && muteState.muteScope}
            onChange={(x) => {
              const newMuteScope = x.target.value as 'all' | 'fb';
              setMuteState({ ...muteState, muteScope: newMuteScope });
              axios
                .put(`api/team/${userStore.selectedTeam?._id}/mute`, {
                  muteScope: newMuteScope,
                })
                .catch((err) => {
                  Sentry.captureException(err);
                });
              setToast({
                label: `SidelineSCORE mute controls ${newMuteScope === 'fb' ? 'Facebook' : 'all'} broadcasts.`,
                background: 'status-ok',
              });
            }}
          />
          <Text size="small">
            Note: highlight clips always contain the audio feed.
          </Text>
        </Box>
        <Box margin={{ top: 'small' }} gap="small">
          <Grommet
            theme={{
              checkBox: {
                color: '#7e68c0',
              },
            }}
          >
            <CheckBox
              checked={muteState.muteAutoTrigger}
              label={
                <Text size="small">
                  Auto-mute when scoring indicates a break in play
                </Text>
              }
              onChange={(x) => {
                const newMuteAutoTrigger = x.target.checked;
                setMuteState({
                  ...muteState,
                  muteAutoTrigger: newMuteAutoTrigger,
                });
                axios
                  .put(`api/team/${userStore.selectedTeam?._id}/mute`, {
                    muteAutoTrigger: newMuteAutoTrigger,
                  })
                  .catch((err) => {
                    Sentry.captureException(err);
                  });
                setToast({
                  label: `${newMuteAutoTrigger ? 'Auto-mute' : 'SidelineSCORE mute controls'} ${
                    muteState.muteScope === 'fb' ? 'Facebook' : 'all'
                  } broadcasts${newMuteAutoTrigger ? ' during breaks in play' : ''}.`,
                  background: 'status-ok',
                });
              }}
              toggle
            />
          </Grommet>
        </Box>
      </Box>
      {showMuteLearnMore && (
        <MuteLearnMore onClose={() => setShowMuteLearnMore(false)} />
      )}
    </Box>
  );
};

export default withFirebase(MuteSettings);
