import { useEffect, useState } from 'react';

const useAppFlyer = () => {
  const ONELINK = process.env.REACT_APP_ONELINK_ACTIVITIES_URL;
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/apps-flyer/onelink-smart-script-latest.js';
    script.async = true;
    document.body.appendChild(script);
    setScriptLoaded(true);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fetchAppStoreLink = () => {
    if (!ONELINK) {
      return;
    }
    return ONELINK;
  };

  const fetchActivityLink = (activityUuId: string) => {
    if (!scriptLoaded && !ONELINK) {
      return;
    }
    const results = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: ONELINK,
      afParameters: {
        deepLinkValue: {
          keys: ['dp_dest'],
          defaultValue: encodeURIComponent(
            `activities/activity?activityUuid=${activityUuId}&&useLastActiveProfile=true`
          ),
        },
        afCustom: [{ paramKey: 'activityUuid', defaultValue: activityUuId }],
      },
    });

    if (results) {
      return results.clickURL;
    }
  };
  return {
    fetchAppStoreLink,
    fetchActivityLink,
  };
};

export default useAppFlyer;
