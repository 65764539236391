import { BorderType } from 'grommet/utils';

const borderLeft: BorderType = [
  {
    color: 'tertiary-1',
    size: 'xsmall',
    style: 'solid',
    side: 'top',
  },
  {
    color: 'tertiary-1',
    size: 'xsmall',
    style: 'solid',
    side: 'bottom',
  },
  {
    color: 'tertiary-1',
    size: 'xsmall',
    style: 'solid',
    side: 'left',
  },
];

const borderRight: BorderType = [
  {
    color: 'tertiary-1',
    size: 'xsmall',
    style: 'solid',
    side: 'top',
  },
  {
    color: 'tertiary-1',
    size: 'xsmall',
    style: 'solid',
    side: 'bottom',
  },
  {
    color: 'tertiary-1',
    size: 'xsmall',
    style: 'solid',
    side: 'right',
  },
];

const borderBottom: BorderType = [
  {
    color: '#E8E8E8',
    size: 'xsmall',
    style: 'solid',
    side: 'bottom',
  },
];

const colWidthMinMax = { max: '33.33%', min: '33.33%' };

export { borderBottom, borderLeft, borderRight, colWidthMinMax };
