import * as Sentry from '@sentry/browser';
import Compressor from 'compressorjs';

export const compressImage = async (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1920,
      success(result) {
        resolve(result);
      },
      error(error) {
        Sentry.captureException(error);
        reject(error);
      },
    });
  });
};
