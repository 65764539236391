// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, useContext } from 'react';
import Compressor from 'compressorjs';
import { compose } from 'recompose';
import {
  Anchor,
  Box,
  Button,
  Grommet,
  Heading,
  Image,
  Layer,
  Text,
  Card,
  CardBody,
  CardHeader,
  RadioButtonGroup,
  RadioButton,
  CheckBox,
  Accordion,
  AccordionPanel,
} from 'grommet';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import {
  Trash,
  Close,
  StatusDisabled,
  StatusGood,
  PhoneVertical,
  Video,
} from 'grommet-icons';
import { toJS } from 'mobx';
import { parsePhoneNumber, formatPhoneNumber } from 'react-phone-number-input';

import CameraLayer from '../SetUp/CameraLayer';
import withLayout from '../../helpers/withLayout';
import axios from '../../helpers/axios';
import CapturePanel from './CapturePanel';
import { UserContext } from '../../services/Session/context';
import RadarLayer from '../SetUp/RadarLayer';
import { BasicLoader } from '../../helpers/Loaders';
import FacebookSettings from './FacebookSettings';
import YoutubeSettings from './YoutubeSettings';
import RadarEvents from './RadarEvents';
import SHDButton from '../../components/SHD/Button';
import TagInput from '../../helpers/TagInput';
import Toast from '../../components/Toast';
import FileUpload from '../../helpers/FileUpload';
import LiveTestCard from '../LiveTest/LiveTestCard';
import ViewCapture from './ViewCapture';
import { withFirebase } from '../../services/Firebase';
import { BBSB_SPORTS, VOLLEYBALL } from '../../constants/strings';
import Referrals from '../Settings/Referrals';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';
import { useQuery } from '../../helpers/utils';
import { analytics } from '../../services/analytics';
import useZendeskWidget from '../../helpers/useZendeskWidget';

const CameraLearnMore = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Cameras</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Text>
        We suggest you use sidelineCAM! (access it on your team home page)
      </Text>
      <Text>
        Advanced users with a Mevo, GoPro, or third-party broadcasting app can
        set up here.
      </Text>
    </Box>
  </Layer>
);

const VeloLearnMore = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Live Velo</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Text>
        Show LIVE pitch velocity in your scoreboard from Pocket Radar and
        Stalker Sport radar.
      </Text>
      <Text>
        Livestreams and highlight clips show velo, exit velo, and spin rate
        (where supported). Fully automatic - no manual entry required!
      </Text>
      <Image fit="contain" src="/pro/exampleLiveVelo.png" />
      <Anchor
        href="https://help.sidelinehd.com/a/solutions/articles/69000362870"
        target="_blank"
      >
        Learn more - velo from Pocket Radar
      </Anchor>
      <Anchor
        href="https://help.sidelinehd.com/a/solutions/articles/69000829972"
        target="_blank"
      >
        Learn more - velo, exit velocity, and spin rate from Stalker Sport
      </Anchor>
    </Box>
  </Layer>
);

const MuteLearnMore = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Livestream muting</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Should I mute my stream?</Text>
        <Text margin={{ top: 'small' }}>
          Facebook and YouTube sometimes block streams containing copyrighted
          music.
        </Text>
        <Anchor href="https://help.sidelinehd.com/a/solutions/articles/69000802906">
          Learn about copyright-related issues
        </Anchor>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Can I manually mute my stream?</Text>
        <Text margin={{ top: 'small' }}>
          SidelineSCORE users can tap the MUTE icon in the lower left corner.
        </Text>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Auto-mute during breaks in play</Text>
        <Text margin={{ top: 'small' }}>
          Select Auto-MUTE, and we&apos;ll MUTE when scoring indicates a break
          in play. (Note: Auto-MUTE starts after the first inning/set break.)
        </Text>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Text weight="bold">Which streams are muted?</Text>
        <Text margin={{ top: 'small' }}>
          You specify which streams go silent. Select Facebook only (Facebook is
          the most sensitive), or all broadcasts.
        </Text>
        <Text margin={{ top: 'small' }}>
          Highlight clips always get full audio. Hot mic alert!
        </Text>
      </Box>
    </Box>
  </Layer>
);

const SponsorsLearnMore = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Sponsor Logos</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Text>
        Engage sponsors and earn team revenue! Sponsor logos are available for
        teams using sidelineHD PRO.
      </Text>
      <Image fit="contain" src="/pro/exampleSponsorBreakFullS.png" />
      <Text>
        Logos automatically rotate in the bottom corner of the stream and are
        featured at selected inning breaks.
      </Text>
      <Image fit="contain" src="/pro/exampleSponsorCornerFullS.png" />
      <Anchor
        href="https://help.sidelinehd.com/a/solutions/articles/69000800298"
        target="_blank"
      >
        Click here to learn more
      </Anchor>
    </Box>
  </Layer>
);

const InningLearnMore = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box
      width="large"
      pad="medium"
      gap="medium"
      overflow="scroll"
      margin={{ bottom: 'xlarge' }}
    >
      <Box
        align="baseline"
        direction="row"
        justify="between"
        fill="horizontal"
        style={{ minHeight: 'auto' }}
      >
        <Heading level={3}>Inning/Set Break Cards</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Box>
        <Text>
          We insert information cards at inning / set breaks. Recognize
          sponsors, share your team&apos;s socials, and (for baseball/softball)
          show upcoming batters!
        </Text>
        <Heading level={4}>Team QR and URL</Heading>
        <Image
          style={{ minHeight: '144px' }}
          fit="contain"
          src="/pro/exampleQrCard.png"
        />
        <Heading level={4}>Due-up (Baseball / Softball)</Heading>
        <Image
          style={{ minHeight: '144px' }}
          fit="contain"
          src="/pro/exampleDueUpCard.png"
        />
        <Heading level={4}>Sponsors</Heading>
        <Image
          style={{ minHeight: '144px' }}
          fit="contain"
          src="/pro/exampleSponsorCard.png"
        />
        <Heading level={4}>Team Socials</Heading>
        <Image
          style={{ minHeight: '144px' }}
          fit="contain"
          src="/pro/exampleSocialCard.png"
        />
        <Box margin="large" />
      </Box>
      <Box margin="large" />
    </Box>
  </Layer>
);

const InningCardPreview = ({ type, onClose }) => {
  let title;
  let cardSrc;
  if (type === 1) {
    title = 'Team QR and URL Card';
    cardSrc = '/pro/exampleQrCard.png';
  } else if (type === 2) {
    title = 'Due-up Card (baseball / softball only)';
    cardSrc = '/pro/exampleDueUpCard.png';
  } else if (type === 3) {
    title = 'Sponsor Card';
    cardSrc = '/pro/exampleSponsorCard.png';
  } else if (type === 4) {
    title = 'Team Socials Card';
    cardSrc = '/pro/exampleSocialCard.png';
  } else {
    return null;
  }

  return (
    <Layer onEsc={onClose} modal>
      <Box width="large" pad="medium" gap="medium">
        <Box
          align="baseline"
          direction="row"
          justify="between"
          fill="horizontal"
        >
          <Heading level={3}>{title}</Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Text>
          This card shows up in your livestream during breaks in play. Example
          card:
        </Text>
        <Image style={{ minHeight: '144px' }} fit="contain" src={cardSrc} />
      </Box>
    </Layer>
  );
};

const StreamingConfig = (props) => {
  const userStore = useContext(UserContext);
  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const [showCamera, setShowCamera] = useState(false);
  const [showPr, setShowPr] = useState(false);
  const [showStalker, setShowStalker] = useState(false);
  const [captureList, setCaptureList] = useState([]);
  const [autoCaptureSetup, setAutoCaptureSetup] = useState();
  const [radar, setRadar] = useState({ pradar: null, eventList: [] });
  const [isUserOwnsCapture, toggleUserOwnsCapture] = useState(false);

  const [teamPhoneNumbers, setTeamPhoneNumbers] = useState([]);
  const [adminPhoneNumbers, setAdminPhoneNumbers] = useState([]);

  const [sponsorLogos, setSponsorLogos] = useState([]);
  const [showDeleteLogo, setShowDeleteLogo] = useState(false);
  const [image, setImage] = useState(null);

  const [team, setTeam] = useState();

  const [showCameraLearnMore, setShowCameraLearnMore] = useState(false);
  const [showVeloLearnMore, setShowVeloLearnMore] = useState(false);
  const [showMuteLearnMore, setShowMuteLearnMore] = useState(false);
  const [showSponsorLearnMore, setShowSponsorLearnMore] = useState(false);
  const [showInningLearnMore, setShowInningLearnMore] = useState(false);

  const [showInningCardPreview, setShowInningCardPreview] = useState();

  const teamId = userStore.selectedTeam ? userStore.selectedTeam._id : null;
  const [toast, setToast] = useState(null);
  const [error, setError] = useState(null);
  const [prError, setPRError] = useState(null);

  const [facebookLinked, setFacebookLinked] = useState(false);
  const [youtubeLinked, setYoutubeLinked] = useState(false);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [fbStreamOption, setFbStreamOption] = useState('default');

  const [muteState, setMuteState] = useState({
    muteScope: 'all',
    muteAutoTrigger: false,
  });
  let showMuteState = false;

  useZendeskWidget();

  if (!teamId) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (userStore.selectedTeam) {
      setFbStreamOption(
        userStore.selectedTeam.publishCrosspostMode === ''
          ? 'default'
          : userStore.selectedTeam.publishCrosspostMode
      );
    }
  }, [userStore.selectedTeam]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    localStorage.setItem('isPlayerPortal', false);
    localStorage.setItem('userRole', 0);

    const capturePromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/team/${teamId}/capture`)
        .then((response) => {
          if (response.data.captureList.length) {
            setCaptureList(response.data.captureList);
            setLoading(false);
            if (response.data.isUserOwnsCapture) {
              toggleUserOwnsCapture(true);
            }
            resolve();
          } else {
            setCaptureList([]);
            resolve();
          }
        })
        .catch((e) => {
          setError('An error occurred. Please refresh and try again.');
          reject(e);
        });
    });

    const radarPromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/radar/${teamId}`)
        .then((response) => {
          if (response.data.pradar || response.data.pradarForUser) {
            setRadar(response.data);
          }
          resolve();
        })
        .catch((respError) => {
          setError(respError.toString());
          reject(respError);
        });
    });

    Promise.all([capturePromise, radarPromise])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    axios
      .get(`/api/team/${teamId}/phone_numbers`)
      .then((response) => {
        if ('phoneNumbers' in response.data) {
          setTeamPhoneNumbers(response.data.phoneNumbers);
        }
      })
      .catch((respError) => {
        setError(respError.toString());
      });

    if ('inviteAdminPhoneNumberList' in userStore.selectedTeam) {
      const rawNumbers = toJS(
        userStore.selectedTeam.inviteAdminPhoneNumberList
      );

      setAdminPhoneNumbers(
        rawNumbers
          .filter((number) => {
            const parsedNumberObj = parsePhoneNumber(number, {
              defaultCountry: 'US',
            });
            return parsedNumberObj;
          })
          .map((number) => {
            const parsedNumberObj = parsePhoneNumber(number, {
              defaultCountry: 'US',
            });
            return parsedNumberObj.number;
          })
      );
    }

    axios.get(`/api/image_overlays/${teamId}`).then((response) => {
      setSponsorLogos(response.data);
      setLoading(false);
    });

    axios.get(`/api/team/${teamId}`).then((response) => {
      setTeam(response.data.info);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    props.firebase.subscribeToTeam(
      userStore.selectedTeam._id,
      (snapshot, err = null) => {
        if (snapshot) {
          setMuteState({
            muteScope:
              snapshot.muteScope !== undefined ? snapshot.muteScope : 'all',
            muteAutoTrigger:
              snapshot.muteAutoTrigger !== undefined
                ? snapshot.muteAutoTrigger
                : false,
          });

          if (showMuteState) {
            setToast({
              label: `${
                snapshot.muteAutoTrigger
                  ? 'Auto-mute'
                  : 'SidelineSCORE mute controls'
              }  ${
                snapshot.muteScope === 'fb' ? 'Facebook' : 'all'
              } broadcasts${
                snapshot.muteAutoTrigger ? ' during breaks in play' : ''
              }.`,
              background: 'status-ok',
            });
          } else {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            showMuteState = true;
          }
        } else {
          Sentry.captureMessage(err);
        }
      }
    );
  }, []);

  const editCaptureLocal = (capture, index) => {
    const newCaptureList = [...captureList];
    if (capture) {
      newCaptureList.splice(index, 1, capture);
    } else {
      newCaptureList.splice(index, 1);
    }
    setCaptureList(newCaptureList);
  };

  const setRadarStatus = (isEnable) => {
    axios
      .put(`/api/radar/${teamId}`, { isEnable })
      .then((response) => setRadar(response.data))
      .catch((respError) => {
        setPRError(respError.response.data.message);
      });
  };

  const deleteRadar = () => {
    axios
      .delete(`/api/radar/${teamId}`)
      .then(() => setRadar({ pradar: null, eventList: [] }))
      .catch((respError) => {
        setError(respError);
      });
  };

  const onSaveAdminPhoneNumber = (tags, successfullyAdded) => {
    if (!successfullyAdded) {
      // means we are at the limit
      setToast({
        label:
          'You can only have 2 phone numbers receive livestream notifications',
        background: 'status-error',
      });
    } else {
      axios
        .put(`/api/team/${userStore.selectedTeam._id}`, {
          teamInfo: {
            inviteAdminPhoneNumberList: tags,
          },
        })
        .then(() => {
          setAdminPhoneNumbers(tags);
          setToast({
            label: 'Phone numbers saved!',
            background: 'status-ok',
          });
        })
        .catch(() => {
          setToast({
            label: 'Phone numbers saving failed!',
            background: 'status-error',
          });
        });
    }
  };

  if (loading) {
    return <BasicLoader size={200} fullPage />;
  }

  if (error) {
    return <Text color="red">{error}</Text>;
  }

  const noCameras = (
    <CardBody pad="medium" direction="column" justify="center" gap="medium">
      <Text color="status-warning" size="large">
        No camera connected
      </Text>
      <Box gap="small" width="medium">
        <Button
          icon={<PhoneVertical />}
          primary
          label="Stream with smartphone"
          onClick={() => {
            analytics.track('Stream with Smartphone Clicked', {
              teamId: userStore.selectedTeam._id,
            });
            setShowCamera({
              addCamera: true,
              cameraType: '2',
            });
          }}
        />
        <Button
          icon={<Video />}
          primary
          color="#FF5000"
          label="Stream with Mevo"
          onClick={() => {
            analytics.track('Stream with Mevo Clicked', {
              teamId: userStore.selectedTeam._id,
            });
            setShowCamera({
              addCamera: true,
              cameraType: '0',
            });
          }}
        />
        <Button
          icon={<Video />}
          color="#109dd9"
          primary
          label="Stream with GoPro"
          onClick={() => {
            analytics.track('Stream with GoPro Clicked', {
              teamId: userStore.selectedTeam._id,
            });
            setShowCamera({
              addCamera: true,
              cameraType: '1',
            });
          }}
        />
      </Box>
    </CardBody>
  );

  const renderCreateNewCameraSection = () => {
    if (isUserOwnsCapture) {
      return null;
    }

    return (
      <Accordion>
        <AccordionPanel label="Use my own camera">
          <Box
            fill="horizontal"
            direction="row"
            background="white"
            pad="medium"
            round="small"
          >
            <Box gap="small" width="medium">
              <Button
                icon={<PhoneVertical />}
                primary
                label="Stream with smartphone"
                onClick={() => {
                  analytics.track('Stream with Smartphone Clicked', {
                    teamId: userStore.selectedTeam._id,
                  });
                  setShowCamera({
                    addCamera: true,
                    cameraType: '2',
                  });
                }}
              />
              <Button
                icon={<Video />}
                primary
                color="#FF5000"
                label="Stream with Mevo"
                onClick={() => {
                  analytics.track('Stream with Mevo Clicked', {
                    teamId: userStore.selectedTeam._id,
                  });
                  setShowCamera({
                    addCamera: true,
                    cameraType: '0',
                  });
                }}
              />
              <Button
                icon={<Video />}
                color="#109dd9"
                primary
                label="Stream with GoPro"
                onClick={() => {
                  analytics.track('Stream with GoPro Clicked', {
                    teamId: userStore.selectedTeam._id,
                  });
                  setShowCamera({
                    addCamera: true,
                    cameraType: '1',
                  });
                }}
              />
            </Box>
          </Box>
        </AccordionPanel>
      </Accordion>
    );
  };

  const camerasCard = (
    <Card pad="none" direction="column" background="light-1" border fill>
      <CardHeader pad="medium">
        <Box fill="horizontal" direction="row" justify="between">
          <Heading margin="none" level={3}>
            Team cameras (Advanced)
          </Heading>
          <Button
            plain
            label={<Text size="small">learn more</Text>}
            color="secondary-3"
            onClick={(e) => {
              e.stopPropagation();
              setShowCameraLearnMore(true);
            }}
          />
        </Box>
      </CardHeader>
      {captureList.length ? (
        <CardBody pad="medium">
          <Box gap="small">
            {captureList.map((capture, i) => (
              <CapturePanel
                key={`capture-${i + 1}`}
                capture={capture}
                team={userStore.selectedTeam}
                editCaptureLocal={(newCapture) =>
                  editCaptureLocal(newCapture, i)
                }
                toggleUserOwnsCapture={toggleUserOwnsCapture}
                showInfo={query.get('show_camera_info') === '1'}
              />
            ))}
          </Box>
          {renderCreateNewCameraSection()}
        </CardBody>
      ) : (
        noCameras
      )}
    </Card>
  );

  const muteCard = (
    <Card pad="none" direction="column" background="light-1" border fill>
      <CardHeader pad="medium">
        <Box fill="horizontal" direction="row" justify="between">
          <Heading margin="none" level={3}>
            Livestream muting
          </Heading>
          <Button
            plain
            label={<Text size="small">learn more</Text>}
            color="secondary-3"
            onClick={(e) => {
              e.stopPropagation();
              setShowMuteLearnMore(true);
            }}
          />
        </Box>
      </CardHeader>
      <CardBody pad="medium" gap="small">
        <Text>
          Protect streams from being blocked due to copyrighted audio.
        </Text>
        <Box margin={{ top: 'small' }} gap="small">
          <RadioButtonGroup
            name="mute-options"
            options={[
              {
                label: 'Mute controls just my Facebook broadcast',
                value: 'fb',
              },
              {
                label: 'Mute controls all broadcasts',
                value: 'all',
              },
            ]}
            value={muteState && muteState.muteScope}
            onChange={(x) => {
              setMuteState({ ...muteState, muteScope: x.target.value });
              axios.put(`api/team/${userStore.selectedTeam._id}/mute`, {
                muteScope: x.target.value,
              });
            }}
          />
          <Text size="small">
            Note: highlight clips always contain the audio feed.
          </Text>
        </Box>
        <Box margin={{ top: 'small' }} gap="small">
          <Grommet
            theme={{
              checkBox: {
                color: '#7e68c0',
              },
            }}
          >
            <CheckBox
              checked={muteState.muteAutoTrigger}
              label="Auto-mute when scoring indicates a break in play"
              onChange={(x) => {
                setMuteState({
                  ...muteState,
                  muteAutoTrigger: x.target.checked,
                });
                axios.put(`api/team/${userStore.selectedTeam._id}/mute`, {
                  muteAutoTrigger: x.target.checked,
                });
              }}
              toggle
            />
          </Grommet>
        </Box>
      </CardBody>
    </Card>
  );

  const adminPhoneNumbersCard = (
    <Card pad="none" direction="column" background="light-1" border fill>
      <CardHeader pad="medium">
        <Heading margin="none" level={3}>
          Stream notifications
        </Heading>
      </CardHeader>
      <CardBody pad="medium" gap="small">
        {teamPhoneNumbers.length === 1 ? (
          <Text>
            This phone number will receive stream notifications
            <Text weight="bold">{` ${formatPhoneNumber(
              teamPhoneNumbers[0]
            )}`}</Text>
          </Text>
        ) : (
          <Text>
            These phone numbers will receive stream notifications
            <Text weight="bold">
              {` ${teamPhoneNumbers
                .map((number) =>
                  formatPhoneNumber(number) ? formatPhoneNumber(number) : number
                )
                .join(', ')}`}
            </Text>
          </Text>
        )}

        <Text weight="bold">You can add up to 2 additional phone numbers</Text>
        <TagInput
          limit={2}
          value={adminPhoneNumbers}
          onTagAdded={onSaveAdminPhoneNumber}
          onTagRemoved={onSaveAdminPhoneNumber}
        />
      </CardBody>
    </Card>
  );

  const renderRadarStatus = () => {
    if (radar.pradar) {
      if (radar.pradar.prUserId !== '' || radar.pradar.appId) {
        const radarDescription =
          radar.pradar.prUserId !== ''
            ? `Velo source is Pocket Radar (${radar.pradar.acctEmail})`
            : `Velo source is Stalker (app code: ${radar.pradar.appId})`;
        const radarType =
          radar.pradar.prUserId !== '' ? 'Pocket Radar' : 'Stalker';
        // PR / stalker connected
        return (
          <CardBody pad="medium" direction="column" gap="medium">
            <Text>{radarDescription}</Text>
            <Box gap="medium">
              {radar.pradar.isEnable ? (
                <Box gap="medium">
                  <Text size="large" color="status-ok">
                    Velo feed enabled
                  </Text>
                  <Box direction="row" gap="medium">
                    <SHDButton
                      label={`Disable ${radarType}`}
                      onClick={() => setRadarStatus(false)}
                    />
                    <SHDButton
                      icon={<Trash />}
                      mixtrack={[`Delete ${radarType}`]}
                      hoverIndicator
                      onClick={() => deleteRadar()}
                    />
                  </Box>
                </Box>
              ) : (
                <Box gap="medium">
                  <Text size="large" color="status-critical">
                    {`${radarType} disabled - your velo feed is muted`}
                  </Text>
                  <Box direction="row" gap="medium">
                    <SHDButton
                      color="red"
                      label={`Activate ${radarType}`}
                      onClick={() => setRadarStatus(true)}
                    />
                    <SHDButton
                      icon={<Trash />}
                      mixtrack={[`Delete ${radarType}`]}
                      hoverIndicator
                      onClick={() => deleteRadar()}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {radar.pradar.isEnable && (
              <RadarEvents eventList={radar.eventList} />
            )}
          </CardBody>
        );
      }

      // PR not connected - user needs to go to app
      return (
        <CardBody pad="medium" direction="column" gap="medium">
          <Card pad="small" background="light-5" margin={{ bottom: 'small' }}>
            <Text size="large" color="status-critical">
              {`Open the PR Sports app and login with ${radar.pradar.acctEmail}.
                Go to the Pocket Radar Connect menu
                and connect with sidelineHD.`}{' '}
              <Anchor
                href="https://www.pocketradar.com/pages/pocket-radar-connect"
                target="_blank"
              >
                Learn more.
              </Anchor>
            </Text>
          </Card>

          <Box gap="medium">
            {radar.pradar.isEnable ? (
              <Box gap="medium">
                <Text size="large" color="status-ok">
                  {`Pocket Radar app paired (${radar.pradar.acctEmail})`}
                </Text>
                <Box direction="row" gap="medium">
                  <SHDButton
                    label="Disable Pocket Radar"
                    onClick={() => setRadarStatus(false)}
                  />
                  <SHDButton
                    icon={<Trash />}
                    mixtrack={['Delete Pocket Radar']}
                    hoverIndicator
                    onClick={() => deleteRadar()}
                  />
                </Box>
              </Box>
            ) : (
              <Box gap="medium">
                <Text size="large" color="status-critical">
                  {`Pocket Radar disabled (${radar.pradar.acctEmail})`}
                </Text>
                <Box direction="row" gap="medium">
                  <SHDButton
                    color="red"
                    label="Activate Pocket Radar"
                    onClick={() => setRadarStatus(true)}
                  />
                  <SHDButton
                    icon={<Trash />}
                    mixtrack={['Delete Pocket Radar']}
                    hoverIndicator
                    onClick={() => deleteRadar()}
                  />
                </Box>
              </Box>
            )}
          </Box>

          {radar.pradar.isEnable && <RadarEvents eventList={radar.eventList} />}
        </CardBody>
      );
    }
    return (
      <CardBody pad="medium" gap="medium">
        <Text color="status-warning" size="large">
          No velo source selected
        </Text>
        <Box gap="medium" width="large">
          <SHDButton
            default
            color="status-warning"
            label="Pair Pocket Radar Sports app"
            onClick={() => {
              setShowPr(true);
            }}
          />
          <SHDButton
            default
            color="status-warning"
            label="Pair StalkerSport app"
            onClick={() => {
              setShowStalker(true);
            }}
          />
        </Box>
      </CardBody>
    );
  };

  const radarCard = (
    <Box id="live-velo-card" gap="small">
      <Card pad="none" direction="row" background={'light-1'} border>
        <Box style={{ flex: 1 }} direction="column">
          <CardHeader direction="column" pad="medium">
            <Box fill="horizontal" direction="row" justify="between">
              <Heading margin="none" level={3}>
                Live velo
              </Heading>
              <Button
                plain
                label={<Text size="small">learn more</Text>}
                color="secondary-3"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowVeloLearnMore(true);
                }}
              />
            </Box>
            <Text alignSelf="start">
              Show LIVE pitch velocity in your scoreboard.
            </Text>
          </CardHeader>
          {renderRadarStatus()}
          {prError && (
            <Text color="status-critical">
              {prError}{' '}
              <Anchor href="https://www.pocketradar.com/pages/pocket-radar-connect">
                Learn more.
              </Anchor>
            </Text>
          )}
        </Box>
      </Card>
    </Box>
  );

  const streamBothOption = {
    label: 'Stream to both Facebook and YouTube.',
    value: 'default',
  };

  const streamYTPostFBOption = {
    label:
      'Stream to YouTube only. Post YouTube link to Facebook once scoring begins.',
    value: 'fb-post-yt-only',
  };

  const bothLinkedCard = (
    <Card pad="none" direction="column" background="white">
      <Box pad="medium" justify="between" direction="row-reverse">
        <Button
          plain
          label={
            <Text color="secondary-1" size="small">
              Learn more
            </Text>
          }
          onClick={() => {
            setShowLearnMoreModal(true);
          }}
        />
      </Box>
      <CardBody
        pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}
        gap="medium"
      >
        <Text>
          You have linked Facebook and YouTube destinations. Choose from:
        </Text>
        <Box>
          <RadioButtonGroup
            width="inherit"
            options={[streamBothOption, streamYTPostFBOption]}
            value={fbStreamOption}
          >
            {(option, { checked }) => {
              const button = (
                <RadioButton
                  key={option.value}
                  checked={checked}
                  onChange={() => {
                    axios
                      .put(`/api/team/${userStore.selectedTeam._id}`, {
                        teamInfo: {
                          publishCrosspostMode: option.value,
                        },
                      })
                      .catch(() => {
                        setToast({
                          label: 'Update crosspost settings failed!',
                          background: 'status-error',
                        });
                      });

                    setFbStreamOption(option.value);
                  }}
                />
              );

              return (
                <Box
                  style={{ whiteSpace: 'pre-wrap' }}
                  direction="row"
                  gap="small"
                >
                  {button}
                  <Box width="medium">
                    <Text size="small">{option.label}</Text>
                  </Box>
                </Box>
              );
            }}
          </RadioButtonGroup>
        </Box>
      </CardBody>
    </Card>
  );

  const streamingCard = (
    <Card pad="none" direction="column" background="light-1" border>
      <CardHeader pad="medium">
        <Heading margin="none" level={3}>
          Livestreaming destinations
        </Heading>
      </CardHeader>
      <CardBody pad="medium" gap="medium">
        {facebookLinked && youtubeLinked && bothLinkedCard}
        <YoutubeSettings
          teamId={userStore.selectedTeam._id}
          onUpdatedLinkedChannel={(channel) => {
            if (channel) {
              setYoutubeLinked(true);
            } else {
              setYoutubeLinked(false);
            }
          }}
        />
        <FacebookSettings
          teamId={userStore.selectedTeam._id}
          onUpdatedLinkedChannel={(channel) => {
            if (channel && channel.type !== 'profile') {
              setFacebookLinked(true);
            } else {
              if (channel && channel.type === 'profile') {
                axios
                  .put(`/api/team/${userStore.selectedTeam._id}`, {
                    teamInfo: {
                      publishCrosspostMode: '',
                    },
                  })
                  .catch(() => {
                    setToast({
                      label: 'Update crosspost settings failed!',
                      background: 'status-error',
                    });
                  });
              }
              setFacebookLinked(false);
            }
          }}
        />
      </CardBody>
    </Card>
  );

  const inningBreakCard = (
    <Box id="inning-break-card" gap="small">
      <Card pad="none" direction="row" background={'light-1'} border>
        <Box style={{ flex: 1 }} direction="column">
          <CardHeader direction="column" pad="medium">
            <Box fill="horizontal" direction="row" justify="between">
              <Heading margin="none" level={3}>
                Inning / set break cards
              </Heading>
              <Button
                plain
                label={<Text size="small">learn more</Text>}
                color="secondary-3"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInningLearnMore(true);
                }}
              />
            </Box>

            <Text alignSelf="start">
              Recognize sponsors, share your team&apos;s socials, and (for
              baseball/softball) show upcoming batters!
            </Text>
          </CardHeader>
          <CardBody pad="medium" gap="small">
            <Grommet
              theme={{
                global: { colors: { 'secondary-3': '#5eabca' } },
                checkBox: {
                  color: '#024006',
                },
              }}
            >
              <Box gap="small">
                <Box direction="row" gap="small" justify="between">
                  <Box direction="row" gap="small">
                    <StatusGood color="status-ok" />
                    <Text>Team QR and URL</Text>
                  </Box>
                  <Button
                    plain
                    color="secondary-3"
                    label={<Text size="small">preview</Text>}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInningCardPreview(1);
                    }}
                  />
                </Box>
                <Box direction="row" gap="small" justify="between">
                  <Box direction="row" gap="small">
                    {BBSB_SPORTS.includes(
                      userStore.selectedTeam.attrib_sportType
                    ) ? (
                      <StatusGood color="status-ok" />
                    ) : (
                      <StatusDisabled color="gray" />
                    )}
                    <Text
                      color={
                        BBSB_SPORTS.includes(
                          userStore.selectedTeam.attrib_sportType
                        )
                          ? 'black'
                          : 'gray'
                      }
                    >
                      Due-up card (baseball / softball only)
                    </Text>
                  </Box>
                  <Button
                    plain
                    color="secondary-3"
                    label={<Text size="small">preview</Text>}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInningCardPreview(2);
                    }}
                  />
                </Box>
                <Box direction="row" gap="small" justify="between">
                  <Box direction="row" gap="small">
                    {sponsorLogos.length > 0 ? (
                      <StatusGood color="status-ok" />
                    ) : (
                      <StatusDisabled color="gray" />
                    )}
                    <Text color={sponsorLogos.length > 0 ? 'black' : 'gray'}>
                      Sponsors card
                    </Text>
                  </Box>
                  <Button
                    plain
                    color="secondary-3"
                    label={<Text size="small">preview</Text>}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInningCardPreview(3);
                    }}
                  />
                </Box>
                <Box direction="row" gap="small" justify="between">
                  <Box direction="row" gap="small">
                    {team &&
                    (team.socialFacebookUrl ||
                      team.socialInstagramHandle ||
                      team.socialTwitterHandle) ? (
                      <StatusGood color="status-ok" />
                    ) : (
                      <StatusDisabled color="gray" />
                    )}
                    <Text
                      color={
                        team &&
                        (team.socialFacebookUrl ||
                          team.socialInstagramHandle ||
                          team.socialTwitterHandle)
                          ? 'black'
                          : 'gray'
                      }
                    >
                      Team socials card
                    </Text>
                  </Box>
                  <Button
                    plain
                    color="secondary-3"
                    label={<Text size="small">preview</Text>}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInningCardPreview(4);
                    }}
                  />
                </Box>
                <Text size="xsmall">
                  Cards are auto-inserted at inning / set breaks.
                </Text>
              </Box>
            </Grommet>
          </CardBody>
        </Box>
      </Card>
    </Box>
  );

  const uploadFile = (imageFile) => {
    let imagePromise;
    if (imageFile) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          responseType: 'arraybuffer',
        },
      };

      imagePromise = new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(imageFile, {
          maxWidth: 1920,

          success(result) {
            const formData = new FormData();

            // The third parameter is required for server
            formData.append('image', result, result.name);
            formData.append('teamId', teamId);

            axios
              .post('/api/image_overlay', formData, config)
              .then((response) => resolve(response.data))
              .catch(reject);
            return true;
          },
          error(err) {
            setError(err.message);
          },
        });
      });
    } else {
      imagePromise = new Promise((resolve) => {
        resolve();
      });
    }

    imagePromise
      .then((logo) => {
        sponsorLogos.push(logo);
        setSponsorLogos(sponsorLogos);
        setToast({
          label: 'Sponsor logo upload successful',
          background: 'status-ok',
        });
        setImage(null);
      })
      .catch((respError) => {
        if (respError.response) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. Please refresh and try again.');
        }

        setImage(null);
      });
  };

  const onDeleteOverlay = (deleteIndex) => {
    const deleteLogo = sponsorLogos[deleteIndex];

    axios
      .delete('/api/image_overlay', {
        data: {
          id: deleteLogo._id,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setSponsorLogos(
            sponsorLogos.filter((logo) => logo._id !== deleteLogo._id)
          );
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleFile = (imageFile, imageError) => {
    if (imageError) {
      setError(imageError);
      return false;
    }
    setError(null);
    setImage(imageFile);

    uploadFile(imageFile);
    return true;
  };

  const deleteLogoModal = (
    <Layer modal>
      <Box pad="medium" gap="medium">
        <Text>Are you sure you want to delete this sponsor logo?</Text>
        <Box direction="row" justify="between">
          <SHDButton
            primary
            color="status-critical"
            label="Cancel"
            onClick={() => {
              setShowDeleteLogo(false);
            }}
          />
          <SHDButton
            primary
            label="Delete"
            onClick={() => {
              const deleteIndex = showDeleteLogo - 1;
              onDeleteOverlay(deleteIndex);
              setShowDeleteLogo(false);
            }}
          />
        </Box>
      </Box>
    </Layer>
  );

  const sponsorLogoCard = (
    <Box id="sponsor-logos-card" gap="small">
      <Card pad="none" direction="row" background={'light-1'} border>
        <Box style={{ flex: 1 }} direction="column">
          <CardHeader direction="column" pad="medium">
            <Box fill="horizontal" direction="row" justify="between">
              <Heading margin="none" level={3}>
                Sponsor logos
              </Heading>
              <Button
                plain
                label={<Text size="small">learn more</Text>}
                color="secondary-3"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSponsorLearnMore(true);
                }}
              />
            </Box>
            <Text alignSelf="start">
              Logos are continuously rotated during your livestream and show at
              selected breaks in play.
            </Text>
          </CardHeader>
          <CardBody pad="medium" gap="medium">
            <Box
              style={{
                display: 'block',
                whiteSpace: 'nowrap',
                pointerEvents: 'auto',
              }}
              overflow={{ horizontal: 'scroll', vertical: 'hidden' }}
              direction="row"
              wrap
            >
              {sponsorLogos.map((logo, i) => (
                <Card
                  key={i}
                  style={{ display: 'inline-block' }}
                  width="small"
                  height="small"
                  pad="small"
                  margin="small"
                >
                  <Box
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Button
                      primary
                      alignSelf="end"
                      color="white"
                      icon={<Close size="small" />}
                      onClick={() => {
                        setShowDeleteLogo(i + 1);
                      }}
                    />
                  </Box>
                  <Image
                    margin={{ top: '-28px' }}
                    fit="contain"
                    src={logo.urlT1000}
                    size="medium"
                    fill
                  />
                </Card>
              ))}
              {sponsorLogos.length < 15 && (
                <Card
                  style={{ display: 'inline-block' }}
                  width="small"
                  height="small"
                  pad="small"
                  margin="small"
                >
                  {image ? (
                    <>
                      <Box>
                        <BasicLoader />
                      </Box>
                      <Image
                        fit="contain"
                        src={URL.createObjectURL(image)}
                        size="medium"
                        fill
                      />
                    </>
                  ) : (
                    <FileUpload handleFile={handleFile}>
                      <SHDButton label="Upload logo" />
                    </FileUpload>
                  )}
                </Card>
              )}
            </Box>
            <Text size="small">
              You can upload up to 15 logos (transparent .PNG works best).
            </Text>
          </CardBody>
        </Box>
      </Card>
    </Box>
  );

  const learnMoreModal = (
    <Layer
      onEsc={() => {
        setShowLearnMoreModal(false);
      }}
      modal
    >
      <Box width="large" pad="medium" gap="medium">
        <Box
          align="baseline"
          direction="row"
          justify="between"
          fill="horizontal"
        >
          <Heading level={3}>Publish to both Facebook and YouTube</Heading>
          <Button
            icon={<Close />}
            onClick={() => {
              setShowLearnMoreModal(false);
            }}
          />
        </Box>
        <Text>
          Your team is linked to both Facebook and YouTube. You have two
          choices:
        </Text>
        <Text>
          (1) Stream to both Facebook and YouTube. This provides the best
          Facebook viewing experience.
        </Text>
        <Text margin={{ bottom: 'medium' }}>
          (2) Stream to YouTube only. Post YouTube link to Facebook once scoring
          begins. Select this option if your Facebook is blocking your streams
          due to copyrighted audio.
        </Text>
        <Text>
          Note: Option #2 is not available if you have linked to a personal
          Facebook profile.
        </Text>

        <Text margin={{ bottom: 'none' }}>
          <Anchor
            label="Read about audio copyright issues"
            href="https://help.sidelinehd.com/a/solutions/articles/69000802906"
          />
        </Text>
      </Box>
    </Layer>
  );

  const referralsCard = <Referrals showSuccesses={false} />;

  const renderCards = () => {
    const cards = [camerasCard, streamingCard];
    if (userStore.selectedTeam.attrib_sportType !== VOLLEYBALL.display) {
      cards.push(muteCard);
    }
    cards.push(adminPhoneNumbersCard);
    if (BBSB_SPORTS.includes(userStore.selectedTeam.attrib_sportType)) {
      cards.push(radarCard);
    }
    cards.push(inningBreakCard);
    cards.push(sponsorLogoCard);
    cards.push(referralsCard);
    // eslint-disable-next-line react/jsx-key
    return cards.map((card) => <Box pad="small">{card}</Box>);
  };

  return (
    <Box fill>
      {showDeleteLogo && deleteLogoModal}
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
      {showPr && (
        <Layer>
          <Box margin="medium" gap="small">
            <RadarLayer
              radarType="pr"
              setRadar={setRadar}
              teamId={teamId}
              closeLayer={() => setShowPr(false)}
            />
          </Box>
        </Layer>
      )}
      {showStalker && (
        <Layer>
          <Box margin="medium" gap="small">
            <RadarLayer
              radarType="stalker"
              setRadar={setRadar}
              teamId={teamId}
              closeLayer={() => setShowStalker(false)}
            />
          </Box>
        </Layer>
      )}
      {(showCamera || query.get('action') === 'add_camera') && (
        <CameraLayer
          teamId={userStore.selectedTeam._id}
          autoAddCaptureSetup={showCamera}
          setCapture={(capture) => {
            if (query.has('action') && query.has('camera_type')) {
              query.delete('action');
              query.delete('camera_type');
              props.history.replace({
                search: query.toString(),
              });
              setAutoCaptureSetup(capture);
            }
            setCaptureList([...captureList, capture]);
            toggleUserOwnsCapture(true);
          }}
          closeLayer={() => setShowCamera(false)}
        />
      )}
      {autoCaptureSetup && (
        <Layer margin="large" style={{ overflowY: 'auto' }}>
          <Box alignSelf="end" margin="medium">
            <SHDButton
              icon={<Close size="medium" />}
              hoverIndicator
              onClick={() => {
                setAutoCaptureSetup(null);
              }}
            />
          </Box>
          <ViewCapture
            name={autoCaptureSetup.captureName}
            type={autoCaptureSetup.equipCamera}
            captureId={autoCaptureSetup._id}
            isOwnedByActiveUser={autoCaptureSetup.isOwnedByActiveUser}
            tutorial
          />
        </Layer>
      )}
      {showCameraLearnMore && (
        <CameraLearnMore onClose={() => setShowCameraLearnMore(false)} />
      )}
      {showVeloLearnMore && (
        <VeloLearnMore onClose={() => setShowVeloLearnMore(false)} />
      )}
      {showMuteLearnMore && (
        <MuteLearnMore onClose={() => setShowMuteLearnMore(false)} />
      )}
      {showSponsorLearnMore && (
        <SponsorsLearnMore onClose={() => setShowSponsorLearnMore(false)} />
      )}
      {showInningLearnMore && (
        <InningLearnMore onClose={() => setShowInningLearnMore(false)} />
      )}
      {showInningCardPreview && (
        <InningCardPreview
          type={showInningCardPreview}
          onClose={() => setShowInningCardPreview(false)}
        />
      )}
      {showLearnMoreModal && learnMoreModal}
      <ResponsiveGrid
        fill="horizontal"
        responsive
        rows={['auto', 'auto']}
        columns={['10%', '10%', '60%', '10%', '10%']}
        areas={{
          small: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'main', start: [0, 1], end: [4, 1] },
          ],
          medium: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [3, 1] },
            { name: 'right-bar', start: [4, 1], end: [4, 1] },
          ],
          large: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [3, 1] },
            { name: 'right-bar', start: [4, 1], end: [4, 1] },
          ],
        }}
      >
        <Box gridArea="header" pad="medium">
          <LiveTestCard isPrompted />
        </Box>
        <Box gridArea="left-bar" />
        <Box gridArea="main">{renderCards()}</Box>
        <Box gridArea="right-bar" />
      </ResponsiveGrid>
    </Box>
  );
};

export default compose(withLayout, withRouter, withFirebase)(StreamingConfig);
