// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  Heading,
  Text,
  Paragraph,
  CardBody,
} from 'grommet';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import withLayout from '../../helpers/withLayout';
import SignInMethod from './SignInMethod';
import { GROMMETTHEME } from '../../constants/grommetTheme';
import { theFirebase } from '../../services/Firebase/firebase';
import { isMobile, isiOS } from '../../helpers/browserDetect';

const ErrorDiv = (props) => (
  <Paragraph color="status-error" margin="medium" {...props} />
);

const ClaimUserPage = (props) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, toggleLoading] = useState(true);
  const [userId, setUserId] = useState(useParams().userid);
  const location = useLocation();

  useEffect(() => {
    toggleLoading(true);
    setUserId(location.pathname.slice(12));
  }, [location]);

  const enableAppleSignIn = !isMobile.any() || isiOS();
  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();

  useEffect(() => {
    axios
      .get(`/api/user/${userId}`)
      .then((response) => {
        const respUser = response.data;
        if (!respUser.isPendingClaim) {
          props.history.push('/signin');
          toggleLoading(false);
        } else {
          setUser(respUser);
          toggleLoading(false);
        }
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError.toString());
        toggleLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (error) {
    return <Text color="red">{error}</Text>;
  }

  if (loading) {
    return <BasicLoader fullPage />;
  }

  return (
    <Box
      data-testid="signIn"
      background={`linear-gradient(${GROMMETTHEME.global.colors.brand.light}, ${GROMMETTHEME.global.colors.brand.dark})`}
      height="100vh"
    >
      <Card
        align="center"
        pad="xlarge"
        gap="small"
        margin="10% auto 0 auto"
        background="white"
        style={{ maxWidth: '600px' }}
        width="80vw"
      >
        <CardHeader direction="column">
          <Heading
            textAlign="center"
            level={3}
          >{`Welcome, ${user.nameFirst} ${user.nameLast}`}</Heading>
          <Text textAlign="center" size="large">
            Sign into sidelineHD to manage your existing account.
          </Text>
        </CardHeader>
        <CardBody pad="medium" gap="medium">
          {!!error && <ErrorDiv>{error}</ErrorDiv>}
          <SignInMethod
            label="Sign in with Google"
            provider={googleProvider}
            toggleLoading={toggleLoading}
            errorHandler={setError}
            postUrl={`/api/user/${userId}`}
          />
          <SignInMethod
            label="Sign in with Facebook"
            provider={facebookProvider}
            toggleLoading={toggleLoading}
            errorHandler={setError}
            postUrl={`/api/user/${userId}`}
          />
          {enableAppleSignIn && (
            <SignInMethod
              label="Sign in with Apple"
              provider={appleProvider}
              toggleLoading={toggleLoading}
              errorHandler={setError}
              postUrl={`/api/user/${userId}`}
            />
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default compose(withLayout, withRouter)(ClaimUserPage);
