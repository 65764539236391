/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Text, Box, Card, Avatar, Button, Layer } from 'grommet';
import { Facebook } from 'grommet-icons';
import Toast from '../../components/Toast';
import FacebookDestinationPicker from './FacebookDestinationPicker';
import ApiErrorDisplay from '../../helpers/ApiErrorDisplay';
import {
  FacebookChannel,
  useFacebookScopes,
  useFacebookSettings,
} from '../../helpers/facebook';
import { FacebookSettingsModal } from '../../helpers/FacebookSettingsModal';

interface Props {
  teamId: string | undefined;
  onComplete?: () => void;
  onUpdatedLinkedChannel?: (channel: FacebookChannel | null) => void;
}

const FacebookSettings: React.FC<Props> = ({
  teamId,
  onComplete = () => {},
  onUpdatedLinkedChannel = () => {},
}) => {
  const {
    linkedChannel,
    onClose,
    onSave,
    linkingLoading,
    error,
    setError,
    onUnlinkFacebookClick,
    setShowIntroModal,
    showExpiration,
    onLinkFacebookClick,
    setShowLinkingModal,
    responseFacebook,
    showIntroModal,
    showLinkingModal,
    linkingError,
  } = useFacebookSettings(teamId, onComplete, onUpdatedLinkedChannel);

  const fbScopes = useFacebookScopes();

  const emptyLinkedChannelState = (
    <Box direction="row" align="center" gap="small" pad="small">
      <Avatar background="brand">XY</Avatar>
      <Text>Link your profile, a group, or a page from below.</Text>
    </Box>
  );

  const linkedChannelState = linkedChannel ? (
    <Box direction="row" align="center" gap="small" pad="small">
      {linkedChannel.picture ? (
        <Avatar src={linkedChannel.picture.data.url} />
      ) : (
        <Avatar background="brand">
          {linkedChannel.name ? linkedChannel.name.substring(0, 2) : 'XY'}
        </Avatar>
      )}
      <Text>{`${linkedChannel.name} (${linkedChannel.type})`}</Text>
    </Box>
  ) : null;

  const destPickerModal = (
    <Layer
      onClickOutside={onClose}
      onEsc={onClose}
      modal
      style={{ height: '100dvh' }}
      animation={false}
      full="vertical"
    >
      <FacebookDestinationPicker
        teamId={teamId}
        onClose={onClose}
        onSave={onSave}
      />
    </Layer>
  );

  const signedInDiv = (
    <Card background="white" pad="small" skeleton={linkingLoading}>
      <Box gap="small" width="large">
        {linkedChannel ? (
          <Box margin="small" justify="center">
            <Text weight="bold">Streaming to: </Text>
            {linkedChannel ? linkedChannelState : emptyLinkedChannelState}
          </Box>
        ) : null}
        {error ? (
          <Toast
            label={error.toString()}
            background="status-error"
            duration={3000}
            onClose={() => {
              setError(null);
            }}
          />
        ) : null}
        <Box width="medium">
          <Button
            fill="horizontal"
            secondary
            color="status-error"
            icon={<Facebook color="plain" />}
            label="Unlink Facebook"
            onClick={onUnlinkFacebookClick}
          />
        </Box>
      </Box>
    </Card>
  );

  const signedOutDiv = (
    <Box
      direction="row"
      align="center"
      gap="small"
      fill="horizontal"
      skeleton={linkingLoading}
    >
      <Box width="medium">
        <Button
          fill="horizontal"
          primary
          color="white"
          icon={<Facebook color="plain" />}
          label="Link Facebook"
          onClick={() => {
            setShowIntroModal(true);
          }}
        />
      </Box>
    </Box>
  );

  const expirationPrompt = (
    <Box margin={{ top: 'small' }}>
      {(showExpiration.daysUntilExpiration ?? 0) < 0 ? (
        <Text color="status-critical">
          Facebook has expired your publishing link. To refresh, click UNLINK,
          then relink your destination.
        </Text>
      ) : (
        <Box>
          <Text>
            {`FB periodically expires publishing access.  This link will expire in ${showExpiration.daysUntilExpiration} days.`}
            To refresh, click UNLINK, then relink your destination.
          </Text>
        </Box>
      )}
    </Box>
  );

  const onCloseIntroModal = () => {
    setShowIntroModal(false);
  };

  return (
    <Box>
      {linkedChannel ? signedInDiv : signedOutDiv}
      {showExpiration.daysUntilExpiration && expirationPrompt}
      {showIntroModal && (
        <FacebookSettingsModal
          onLinkFacebookClick={onLinkFacebookClick}
          onCloseIntroModal={onCloseIntroModal}
          setShowLinkingModal={setShowLinkingModal}
          fbScopes={fbScopes}
          responseFacebook={responseFacebook}
        />
      )}
      {showLinkingModal ? destPickerModal : null}
      <ApiErrorDisplay
        error={linkingError}
        header={
          <Box gap="small">
            <Box direction="row" gap="small" align="center">
              <Facebook size="small" color="plain" />
              <Text weight="bold">Error via Facebook</Text>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default FacebookSettings;
