// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import loggerContext from './context';
import { strogging } from '@shd/jslib/infra';

export default function LoggerProvider({ children }) {
  const { Provider } = loggerContext;

  const [logs, setLogs] = useState([]);

  const logger = useMemo(
    () => ({
      info: (...args) => {
        strogging.log(`slcam:${args[0] || ''}`, { args });
        setLogs((prevState) => [
          ...prevState,
          {
            type: 'info',
            time: dayjs().format('DD-MM-YY HH:mm:ss'),
            message: Array.isArray(args) ? args.join(' ') : args,
          },
        ]);
      },
      error: (...args) => {
        strogging.error(`slcam:${args[0] || ''}`, { args });
        setLogs((prevState) => [
          ...prevState,
          {
            type: 'error',
            time: dayjs().format('DD-MM-YY HH:mm:ss'),
            message: Array.isArray(args) ? args.join(' ') : args,
          },
        ]);
      },
      warn: (...args) => {
        strogging.warn(`slcam:${args[0] || ''}`, { args });
        setLogs((prevState) => [
          ...prevState,
          {
            type: 'warn',
            time: dayjs().format('DD-MM-YY HH:mm:ss'),
            message: Array.isArray(args) ? args.join(' ') : args,
          },
        ]);
      },
    }),
    []
  );

  return <Provider value={{ logs, logger }}>{children}</Provider>;
}

LoggerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
