// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  Heading,
  Text,
  Paragraph,
  CardBody,
} from 'grommet';

import { compose } from 'recompose';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import withLayout from '../../helpers/withLayout';
import SignInMethod from './SignInMethod';
import { GROMMETTHEME } from '../../constants/grommetTheme';
import { UserContext } from '../../services/Session';
import signOut from '../../helpers/signOut';
import { theFirebase } from '../../services/Firebase/firebase';
import { isMobile, isiOS } from '../../helpers/browserDetect';

const ErrorDiv = (props) => (
  <Paragraph color="status-error" margin="medium" {...props} />
);

const InvitedUserPage = ({ history }) => {
  const userStore = useContext(UserContext);
  const [error, setError] = useState(null);
  const [loading, toggleLoading] = useState(true);
  const [invitingUser, setInvitingUser] = useState(null);
  const [invite, setInvite] = useState({});
  const [expiration, setExpiration] = useState({});
  const [validCode, setValidCode] = useState(true);
  const [nameToManage, setNameToManage] = useState();
  const location = useLocation();
  const inviteCode = queryString.parse(location.search).code;

  const enableAppleSignIn = !isMobile.any() || isiOS();
  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();

  const secondsToDaysAndHours = (seconds) => {
    let s = parseInt(seconds);

    const days = Math.floor(s / (3600 * 24));
    s -= days * 3600 * 24;
    const hours = Math.floor(s / 3600);
    return { days, hours };
  };

  useEffect(() => {
    if (inviteCode) {
      axios
        .get(`/api/invite/${inviteCode}`)
        .then((response) => {
          const respInvite = response.data;
          setInvite(respInvite);

          if (respInvite.invitedUserId) {
            setValidCode(false);
          } else {
            const { days, hours } = secondsToDaysAndHours(
              respInvite.expires - Date.now() / 1000
            );

            if (days >= 0 && hours >= 0) {
              setExpiration({ days, hours });
              setInvitingUser(respInvite.invitingUser);
              if (respInvite.teamName) {
                setNameToManage(respInvite.teamName);
              } else if (respInvite.playerName) {
                setNameToManage(respInvite.playerName);
              }

              if (userStore.authUser) {
                signOut(false);
              }
            } else {
              setValidCode(false);
              if (userStore.authUser) {
                history.push('/');
              }
            }
          }

          toggleLoading(false);
        })
        .catch((e) => {
          Sentry.captureException(e);
          setError(e.toString());
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <BasicLoader fullPage />;
  }

  const invitedHeaderText = invitingUser
    ? `${invitingUser.nameFirst} ${invitingUser.nameLast} invited you ${
        nameToManage
          ? `${
              invite.teamRole === 'coach' ? 'as a coach of' : 'to manage'
            } ${nameToManage}`
          : 'sidelineHD'
      }!`
    : "You've been invited to sidelineHD!";

  const invitedHeader = (
    <>
      <Heading textAlign="center" level={3}>
        {invitedHeaderText}
      </Heading>
      {expiration && (
        <Text textAlign="center" size="large">
          {`Invite expires in ${expiration.days} days and ${expiration.hours} hours`}
        </Text>
      )}
    </>
  );

  const invalidHeader = (
    <Heading textAlign="center" level={3}>
      This invitation has been claimed or is expired!
    </Heading>
  );

  return (
    <Box
      data-testid="signIn"
      background={`linear-gradient(${GROMMETTHEME.global.colors.brand.light}, ${GROMMETTHEME.global.colors.brand.dark})`}
      height="100vh"
    >
      <Card
        align="center"
        pad="small"
        gap="small"
        margin="10% auto 0 auto"
        background="white"
        style={{ maxWidth: '600px' }}
        width="80vw"
      >
        <CardHeader direction="column">
          {validCode ? invitedHeader : invalidHeader}
        </CardHeader>
        {validCode ? (
          <CardBody pad="medium" gap="medium">
            {!!error && <ErrorDiv>{error}</ErrorDiv>}
            <SignInMethod
              label="Sign in with Google"
              provider={googleProvider}
              toggleLoading={toggleLoading}
              errorHandler={setError}
              postParams={{ invite_code: inviteCode }}
            />
            <SignInMethod
              label="Sign in with Facebook"
              provider={facebookProvider}
              toggleLoading={toggleLoading}
              errorHandler={setError}
              postParams={{ invite_code: inviteCode }}
            />
            {enableAppleSignIn && (
              <SignInMethod
                label="Sign in with Apple"
                provider={appleProvider}
                toggleLoading={toggleLoading}
                errorHandler={setError}
                postParams={{ invite_code: inviteCode }}
              />
            )}
          </CardBody>
        ) : null}
      </Card>
    </Box>
  );
};

export default compose(withLayout, withRouter)(InvitedUserPage);
