// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-use-before-define */
import {
  useContext,
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useGesture } from '@use-gesture/react';
import { UserContext } from '../../../../services/Session/context';

import useLogger from '../../useLogger/index';
import {
  useEffectOnce,
  getOrientation,
  useOrientation,
  updateAggregateValues,
  useIsFeatureEnabled,
} from '../../../../helpers/utils';
import { isiOS, isMobile } from '../../../../helpers/browserDetect';

import {
  getUserMedia,
  getStreamInfo,
  updateTracks,
  updateBandWidth,
  getURL,
  updateVideoCodec,
  getDeviceLimitedResolution,
  applyConstraints,
  getCanvasStream,
  getVideoInputs,
} from './functions';

import {
  DEFAULT_STATUS,
  STATUSES,
  STREAM_HEALTH_STATUSES,
  DEFAULT_CODEC,
  H265_CODEC,
  BANDWIDTHS,
  RESOLUTIONS,
  ZOOM_MAX,
  ZOOM_MIN,
  ZOOM_PINCH_THRESHOLD,
  AUDIO_CONSTRAINTS,
  STREAM_INFO_INTERVAL_MS,
  INITIAL_SESSION_STATS,
} from './constants';
import { strogging } from '@shd/jslib/infra';

export default function useWebRTCStream({ playerRef }) {
  const userStore = useContext(UserContext);
  // Stream info states
  const [actualResolution, setActualResolution] = useState(
    RESOLUTIONS[0].value.height
  );
  // eslint-disable-next-line no-unused-expressions
  actualResolution;
  const [streamStatus, setStreamStatus] = useState(DEFAULT_STATUS);
  const [streamHealth, setStreamHealth] = useState(STREAM_HEALTH_STATUSES.good);
  const [streamInfo, setStreamInfo] = useState({
    fps: 0,
    resolution: 0,
  });
  const [streamBitRate, setStreamBitRate] = useState(0);

  const [streamErrors, setStreamErrors] = useState([]);

  const [codecList, setCodecList] = useState([DEFAULT_CODEC]);
  const [codecValue, setCodecValue] = useState(DEFAULT_CODEC);

  const [resolutionList, setResolutionList] = useState(RESOLUTIONS);
  const [resolutionValue, setResolutionValue] = useState(RESOLUTIONS[0]);

  const [videoInputsList, setVideoInputsList] = useState([]);
  const [videoInputValue, setVideoInputValue] = useState(null);

  const [bandwidthValue, setBandwidthValue] = useState(BANDWIDTHS[0]);

  const [isEnabledMicro, setIsEnabledMicro] = useState(true);

  const [cameraZoom, setCameraZoom] = useState(ZOOM_MIN);

  const [streamCapabilities, setStreamCapabilities] = useState(null);

  const [isSetting, setIsSetting] = useState(false);
  const [isReady, setIsReady] = useState(true);

  const [videoSourceStream, setVideoSourceStream] = useState(null);

  const deviceOrientationRef = useRef(getOrientation());
  const peerConnectionRef = useRef(null);
  const intervalRef = useRef(null);
  const locationRef = useRef(null);
  const streamRef = useRef(null);
  const perStreamSessionStatsRef = useRef(INITIAL_SESSION_STATS);
  const autoRestartCountRef = useRef(0);

  const sidelinecamHevcEnabled = useIsFeatureEnabled(
    'sidelinecam_hevc',
    userStore
  );

  const zoomRef = useRef(ZOOM_MIN);
  const resolutionRef = useRef(RESOLUTIONS[0]);

  const serverURL = useMemo(
    () => `${getURL()}&videocodecs=${codecValue.value}`,
    [codecValue]
  );

  const { logger } = useLogger();

  const { isPortrait } = useOrientation({});

  const handleCameraZoom = (value) => {
    if (isSetting) {
      return;
    }

    setIsSetting(true);
    const zoom = Number(value);

    setCameraZoom(zoom);
    zoomRef.current = zoom;
    setIsSetting(false);
  };

  useGesture(
    {
      // change camera zoom on pinch
      onPinch: ({ offset: [value] }) => {
        handleCameraZoom(value);
      },
    },
    {
      eventOptions: { passive: true },
      target: playerRef,
      pinch: {
        threshold: ZOOM_PINCH_THRESHOLD,
        scaleBounds: { min: ZOOM_MIN, max: ZOOM_MAX },
      },
    }
  );

  const handleToggleMicro = () => {
    const stream = playerRef.current.srcObject;
    const [track] = stream.getAudioTracks();

    setIsEnabledMicro((prevState) => {
      track.enabled = !prevState;
      return !prevState;
    });
  };

  const handleError = (...errors) => {
    logger.error(...errors);
    setStreamStatus(STATUSES.error);
    setStreamErrors(errors);
  };

  const sampleStream = (fps, bitrate, fir, nack, pli) => {
    perStreamSessionStatsRef.current = {
      ...perStreamSessionStatsRef.current,
      fps: updateAggregateValues(
        perStreamSessionStatsRef.current.fps,
        fps ?? 0
      ),
      bitrate: updateAggregateValues(
        perStreamSessionStatsRef.current.bitrate,
        bitrate ?? 0
      ),
      fir: updateAggregateValues(
        perStreamSessionStatsRef.current.fir,
        fir ?? 0
      ),
      nack: updateAggregateValues(
        perStreamSessionStatsRef.current.nack,
        nack ?? 0
      ),
      pli: updateAggregateValues(
        perStreamSessionStatsRef.current.pli,
        pli ?? 0
      ),
    };
  };

  const startStream = async (shouldRetry = false) => {
    try {
      setIsReady(false);
      logger.info('Start stream');
      const stream = playerRef.current.srcObject;
      if (!stream) {
        return false;
      }

      perStreamSessionStatsRef.current = {
        ...INITIAL_SESSION_STATS,
        startTs: Date.now(),
      };

      // find out actual camera resolution
      const settings = stream?.getVideoTracks()[0].getSettings();
      const [res] = [settings.height, settings.width].sort((a, b) => a - b);
      setActualResolution(res);

      peerConnectionRef.current = new RTCPeerConnection(null);
      const connection = peerConnectionRef.current;

      // update video codec should be before update tracks to avoid floating
      // error when initially setting codecs
      updateVideoCodec({
        connection,
        stream,
        codecValue,
        logger,
      });

      updateTracks({ stream, connection });

      // intervalRef.current = getStreamBitRate({
      //   connection, rate: 1000, setStreamBitRate, logger
      // });
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      intervalRef.current = getStreamInfo({
        connection,
        rate: STREAM_INFO_INTERVAL_MS,
        setStreamBitRate,
        setStreamHealth,
        setStreamInfo,
        restartStream,
        sampleStream,
        logger,
      });

      logger.info('Creating peer connection offer...');
      const offer = await connection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });
      // mangle sdp to add NACK support for opus
      // To add NACK in offer we have to add it manually see https://bugs.chromium.org/p/webrtc/issues/detail?id=4543 for details
      const opusCodecId = offer.sdp.match(/a=rtpmap:(\d+) opus\/48000\/2/);

      if (opusCodecId !== null) {
        offer.sdp = offer.sdp.replace(
          'opus/48000/2\r\n',
          `opus/48000/2\r\na=rtcp-fb:${opusCodecId[1]} nack\r\n`
        );
      }

      // quality encoding issue fix
      const arr = offer.sdp.split('\r\n');
      arr.forEach((str, i) => {
        if (/^a=fmtp:\d*/.test(str)) {
          arr[i] =
            `${str};x-google-max-bitrate=10000;x-google-min-bitrate=2000;x-google-start-bitrate=2000`;
        } else if (/^a=mid:(1|video)/.test(str)) {
          arr[i] += '\r\nb=AS:10000';
        }
      });

      const offerSdp = new RTCSessionDescription({
        type: 'offer',
        sdp: arr.join('\r\n'),
      });

      logger.info('Offer:', offerSdp.sdp);

      logger.info('Setting local description...');
      await connection.setLocalDescription(offerSdp);

      logger.info('Connecting URL:', serverURL);
      logger.info('Connecting...');
      if (shouldRetry) {
        setStreamStatus(STATUSES.reconnecting);
      } else {
        setStreamStatus(STATUSES.connecting);
      }

      let fetched = null;
      try {
        fetched = await fetch(serverURL, {
          method: 'POST',
          body: offer.sdp,
          headers: { 'Content-Type': 'application/sdp' },
          keepalive: true,
        });

        if (fetched.headers.get('location')) {
          locationRef.current = new URL(
            fetched.headers.get('location'),
            serverURL
          );
          logger.info('Location:', locationRef.current);
        }

        if (!fetched.ok && fetched.status === 403) {
          restartStreamTimeOut(5000).catch(handleError);
          return false;
        }

        if (!fetched.ok) {
          handleError(`Connection error (${fetched.status}). Try again.`);
          return false;
        }
      } catch (e) {
        if (shouldRetry) {
          await new Promise((r) => {
            setTimeout(r, 2000);
          });
          logger.info('Retrying...');
          await startStream(true);
        } else {
          handleError('Connection error:', e);
          return false;
        }
      }

      // Get the SDP answer
      const answer = await fetched.text();

      logger.info('Answer:', answer);

      logger.info('Setting remote description...');
      await connection.setRemoteDescription({ type: 'answer', sdp: answer });

      updateBandWidth({ connection, bandwidthValue, logger });
      setStreamStatus(STATUSES.connected);
      logger.info('Connected');
    } catch (e) {
      if (!shouldRetry) {
        handleError(e);
      }
      return false;
    }
    setIsReady(true);

    return true;
  };

  const stopStream = async (stopReason) => {
    if (!peerConnectionRef.current) {
      return false;
    }
    if (stopReason === 'auto-restart') {
      autoRestartCountRef.current += 1;
    }

    logger.info('Stop stream');
    logger.info('Stop getting statistics');
    strogging.log('stream-session-stats', {
      stopReason,
      actualResolution,
      codec: codecValue.value,
      durationSecs:
        (Date.now() - perStreamSessionStatsRef.current.startTs) / 1000,
      bitrate: perStreamSessionStatsRef.current.bitrate,
      fps: perStreamSessionStatsRef.current.fps,
      fir: perStreamSessionStatsRef.current.fir,
      nack: perStreamSessionStatsRef.current.nack,
      pli: perStreamSessionStatsRef.current.pli,
    });

    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setStreamBitRate(0);
    setStreamStatus(STATUSES.disconnecting);
    logger.info('Disconnecting...');
    setStreamErrors([]);

    if (locationRef?.current) {
      let fetched = null;
      try {
        // Send a delete request
        fetched = await fetch(locationRef.current, {
          method: 'DELETE',
          keepalive: true,
        });

        if (!fetched.ok) {
          setStreamStatus(STATUSES.error);
          logger.warn(`failed to delete session ${fetched.status}`);
        }
      } catch (error) {
        setStreamStatus(STATUSES.error);
        logger.warn(
          `failed to delete session [${locationRef.current}] with error ${error}`
        );
      }
    }
    // wait a little before pc.close to send some frames to Nimble
    // to make it handle DELETE requests
    // if we run close right after DELETE nimble will wait to ice
    // timeout and delete session only after that
    await new Promise((r) => {
      setTimeout(r, 200);
    });
    logger.info('Closing peer connection...');
    peerConnectionRef.current.close();
    peerConnectionRef.current = null;
    setStreamStatus(STATUSES.disconnected);
    logger.info('Disconnected');
    return true;
  };

  const restartStream = async (shouldRetry = false) => {
    strogging.log('restartStream');
    await stopStream('auto-restart');
    await startStream(shouldRetry);
  };

  const restartStreamTimeOut = async (time = 5000) => {
    let seconds = Math.ceil(time / 1000);
    strogging.error(`Connection Error (403).  Retrying in ${seconds}s...`);
    setStreamStatus(STATUSES.error);
    setStreamErrors([`Connection Error (403).  Retrying in ${seconds}s...`]);

    const interval = setInterval(() => {
      seconds -= 1;
      strogging.error(`Connection Error (403).  Retrying in ${seconds}s...`);
      setStreamErrors([`Connection Error (403).  Retrying in ${seconds}s...`]);
    }, 1000);

    await new Promise((resolve) => {
      setTimeout(() => resolve(), time);
    });

    clearInterval(interval);
    restartStream().catch(handleError);
  };

  // ask media permissions to app init
  const initVideoSourceDropdown = async () => {
    const videoInputs = await getVideoInputs();
    setVideoInputsList(videoInputs);

    const [track] = videoSourceStream.getVideoTracks();
    const settings = track.getSettings();
    const videoSource = videoInputs.find(
      ({ value }) => value === settings.deviceId
    );
    setVideoInputValue(videoSource);
  };

  useEffect(() => {
    if (!videoSourceStream) {
      return;
    }
    initVideoSourceDropdown().catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoSourceStream]);

  const initStream = async (deviceId) => {
    if (!playerRef?.current) {
      return;
    }

    // fix for IPhones
    playerRef.current.setAttribute('playsinline', true);
    // initial media constraints
    const constraints = {
      audio: isEnabledMicro ? AUDIO_CONSTRAINTS : false,
      video: {
        // ...RESOLUTIONS[0].value,
        width: { min: 640, ideal: 1280, max: 1280 },
        height: { min: 360, ideal: 720, max: 720 },
        facingMode: 'environment',
        frameRate: { exact: 30 },
        deviceId,
      },
    };

    // stop stream when current tab lose focus only for mobile devices
    window.onblur = () => {
      if (!peerConnectionRef.current || !isMobile.any()) {
        return;
      }

      stopStream('lost-focus').catch(logger.error);
      // TODO : should replace with modal?
      // eslint-disable-next-line no-alert
      alert(
        'Livestream disconnected.  Leave this window on top to avoid disconnects.'
      );
    };

    setIsReady(false);
    // ask permissions and get user audio and camera closest to desired resolution
    const stream = await getUserMedia({ constraints });
    setVideoSourceStream(stream);
    streamRef.current = stream;
    const [track] = stream.getVideoTracks();

    const capabilities = track.getCapabilities();
    setStreamCapabilities(capabilities);

    // determine supported video codecs
    const codecs = [
      ...RTCRtpSender.getCapabilities('video').codecs,
      ...RTCRtpReceiver.getCapabilities('video').codecs,
    ];

    const codecsMimeTypes = codecs.map((codec) => codec.mimeType);
    const uniqueCodecMimeTypes = Array.from(new Set(codecsMimeTypes));
    const is265Available = uniqueCodecMimeTypes.includes(H265_CODEC.mimeType);
    const alreadyContains = codecList.some(
      (codec) => codec.mimeType === H265_CODEC.mimeType
    );
    logger.info('Device supports video codecs:', ...uniqueCodecMimeTypes);

    if (
      isiOS() &&
      !is265Available &&
      !alreadyContains &&
      sidelinecamHevcEnabled
    ) {
      setCodecList([...codecList, { ...H265_CODEC, disabled: true }]);
    }

    if (is265Available && !alreadyContains && sidelinecamHevcEnabled) {
      setCodecList([...codecList, { ...H265_CODEC, disabled: false }]);
      setCodecValue({ ...H265_CODEC, disabled: false });
    }

    const resolutions = getDeviceLimitedResolution({
      capabilities,
      codec: is265Available ? H265_CODEC : DEFAULT_CODEC,
    });

    setResolutionList(resolutions);
    const [resolution] = resolutions;

    setResolutionValue(resolution);
    resolutionRef.current = resolution;

    const canvasStream = getCanvasStream({
      stream,
      zoomRef,
      resolutionRef,
      logger,
    });
    const [audioTrack] = stream.getAudioTracks();
    canvasStream.addTrack(audioTrack);

    strogging.log('initial applyConstraints', { resolution });
    applyConstraints({ stream: canvasStream, resolution })
      .then((res) => setActualResolution(res))
      .catch(handleError);

    // eslint-disable-next-line no-param-reassign
    playerRef.current.srcObject = canvasStream;

    setIsReady(true);
    if (is265Available) {
      logger.info(`Device supports ${H265_CODEC.label}!`);
    } else {
      logger.info(`Device does not support ${H265_CODEC.label}!`);
    }
  };

  const showHEVCHelp =
    isiOS() &&
    codecList.some(
      (codec) => codec.mimeType === H265_CODEC.mimeType && codec.disabled
    );

  const cleanupCameraAndMicrophone = useCallback(() => {
    const str2 = streamRef.current;
    if (str2) {
      strogging.log('cleanupCameraAndMicrophone');
      str2.getTracks().forEach((t) => t.stop());
    } else {
      strogging.log('cleanupCameraAndMicrophone:NO STREAM TO STOP');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamRef.current]);

  useEffect(() => {
    setIsSetting(true);
    initStream(videoInputValue?.value)
      .catch(handleError)
      .finally(() => {
        setIsReady(true);
        setIsSetting(false);
      });
    return cleanupCameraAndMicrophone;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInputValue?.value]);

  // add listener to detect device change orientation and adjust resolution
  useEffectOnce(() => {
    const onWindowResize = () => {
      if (deviceOrientationRef.current === getOrientation()) {
        return;
      }

      const stream = playerRef.current?.srcObject;
      if (!stream) {
        return;
      }

      applyConstraints({ stream, resolution: resolutionValue })
        .then((res) => setActualResolution(res))
        .catch(handleError);

      deviceOrientationRef.current = getOrientation();
    };

    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  });

  const onChangeCodec = (value) => {
    setCodecValue(value);

    const resolutions = getDeviceLimitedResolution({
      capabilities: streamCapabilities,
      codec: value,
    });

    setResolutionList(resolutions);
    const [resolution] = resolutions;
    setResolutionValue(resolution);

    const stream = playerRef.current.srcObject;

    setIsSetting(true);
    applyConstraints({ stream, resolution })
      .then((res) => setActualResolution(res))
      .catch(handleError)
      .finally(() => setIsSetting(false));

    if (value.label === 'Standard (H.264)') {
      setBandwidthValue(BANDWIDTHS[0]);
      updateBandWidth({
        connection: peerConnectionRef.current,
        bandwidthValue: BANDWIDTHS[0],
        logger,
      });
    }

    if (value.label === 'Advanced (HEVC)') {
      setBandwidthValue(BANDWIDTHS[3]);
      updateBandWidth({
        connection: peerConnectionRef.current,
        bandwidthValue: BANDWIDTHS[3],
        logger,
      });
    }
  };

  const onChangeBandwidth = (value) => {
    setBandwidthValue(value);
    const connection = peerConnectionRef.current;
    if (!connection) {
      return;
    }

    updateBandWidth({ connection, bandwidthValue: value, logger });
  };

  const onChangeResolution = (resolution) => {
    setResolutionValue(resolution);
    resolutionRef.current = resolution;

    if (!playerRef.current.srcObject) {
      return;
    }
    const stream = playerRef.current.srcObject;

    setIsSetting(true);
    applyConstraints({ stream, resolution })
      .then((res) => setActualResolution(res))
      .catch(handleError)
      .finally(() => setIsSetting(false));
  };

  const onChangeVideoInput = (value) => {
    setVideoInputValue(value);
  };

  const resetAutoRestartCount = useCallback(() => {
    autoRestartCountRef.current = 0;
  }, []);

  return {
    codecList,
    codecValue,
    onChangeCodec,
    bandwidthValue,
    onChangeBandwidth,
    isEnabledMicro,
    handleToggleMicro,
    resolutionList,
    resolutionValue,
    onChangeResolution,
    videoInputsList,
    videoInputValue,
    onChangeVideoInput,
    streamStatus,
    streamHealth,
    streamInfo,
    streamBitRate,
    streamErrors,
    isPortrait,
    cameraZoom,
    handleCameraZoom,
    isSetting,
    isReady,
    startStream,
    stopStream,
    restartStream,
    autoRestartCount: autoRestartCountRef.current,
    resetAutoRestartCount,
    showHEVCHelp,
  };
}
