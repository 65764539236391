// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import { Button } from 'grommet';

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${({ theme, disabled }) => {
    const color = disabled ? 'light-5' : 'tertiary-1';
    return theme.global.colors[color];
  }};
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.global.colors['dark-4'] : '#fff'};
`;
