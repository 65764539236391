// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Text, CheckBox, Button } from 'grommet';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Add } from 'grommet-icons';
import SetStarters from './SetStarters';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import withLayout from '../../helpers/withLayout';
import { BasicLoader } from '../../helpers/Loaders';
import QuickAddPlayer from './QuickAddPlayer';
import StartingPitcher from './StartingPitcher';
import { insertDisplayName } from '../../helpers/utils';
import axios from '../../helpers/axios';
import { VOLLEYBALL } from '../../constants/strings';

const SetLineupsBase = (props) => {
  const userStore = useContext(UserContext);

  const { match, history } = props;
  const { gameId } = match.params;
  const [team, setTeam] = useState(
    userStore.selectedTeam ? userStore.selectedTeam : userStore.scoringTeam
  );
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [starters, setStarters] = useState([]);
  const [previousStarters, setPreviousStarters] = useState([]);
  const [isStartersSet, toggleIsStartersSet] = useState(false);
  const [isLineupSet, toggleIsLineupSet] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [error, setError] = useState(false);

  const getPlayers = () => {
    axios
      .get(`/api/team/${team._id}/team_players`)
      .then((response) => {
        const teamPlayerDocs = response.data.players;
        const newTeamPlayers = teamPlayerDocs
          .filter((player) => player.isActive)
          .map(insertDisplayName);
        newTeamPlayers.sort((a, b) => {
          const aJerseyNum = parseInt(a.jerseyNum);
          const bJerseyNum = parseInt(b.jerseyNum);
          return aJerseyNum > bJerseyNum ? 1 : -1;
        });
        setTeamPlayers(newTeamPlayers);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
  };

  const getCurrentLineup = () => {
    axios.get(`/api/lineup/${team._id}`).then(({ data: lineup }) => {
      setPreviousStarters(lineup);
    });
  };

  useEffect(() => {
    if (!team) {
      const scoreTeamId = localStorage.getItem('scoreTeamId');
      const scoreDelegationKey = localStorage.getItem('scoreDelegationKey');
      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
        })
        .catch((respError) => {
          if (respError.response && respError.response.status === 403) {
            setError('You are not authorized to score for this team.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      getPlayers();
      getCurrentLineup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const selectStarter = (player) => {
    const newPlayer = { ...player };
    delete newPlayer.disabled;
    if (
      starters.filter((starter) => starter._id === newPlayer._id).length === 0
    ) {
      setStarters((prevStarters) => {
        const newStarters = [...prevStarters, newPlayer];
        return newStarters;
      });
    } else {
      setStarters((prevStarters) => {
        const newStarters = prevStarters.filter(
          (starter) => starter._id !== newPlayer._id
        );
        return newStarters;
      });
    }
  };

  const addPlayer = (player) => {
    const newPlayer = insertDisplayName(player);
    const newTeamPlayers = [...teamPlayers, newPlayer];
    setTeamPlayers(newTeamPlayers);
    selectStarter(newPlayer);
  };

  const reUseLineup = () => {
    setStarters(previousStarters);
  };

  const setOrder = (order, isQuickStartMode = false) => {
    toggleLoading(true);
    const body = {
      teamId: team._id,
      teamGameId: gameId,
      teamGameUpdate: {
        scoreOurLineup: order,
        scoreGameState: 20,
        scheduleState: 20,
        isQuickStartMode,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        toggleIsLineupSet(true);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
  };

  if (loading) {
    return <BasicLoader fullPage />;
  }

  if (isLineupSet) {
    if (team.attrib_sportType === VOLLEYBALL.display) {
      history.replace('/scoring');
      return null;
    }
    return <StartingPitcher teamPlayers={teamPlayers} />;
  }

  if (isStartersSet) {
    if (!starters.length) {
      setOrder([], true);
    } else {
      return (
        <SetStarters
          starters={starters}
          toggleIsLineupSet={toggleIsLineupSet}
          goBack={() => toggleIsStartersSet(false)}
          sportType={team.attrib_sportType === 'Volleyball' ? 'vb' : 'bbsb'}
        />
      );
    }
  }

  return (
    <Box gap="medium" pad="medium">
      <Box fill="horizontal" pad="medium" align="end">
        <Text
          weight="bold"
          onClick={() => history.replace('/scoring')}
          color="tertiary-1"
        >
          Cancel
        </Text>
      </Box>
      <Heading level="3">
        {team.attrib_sportType === 'Volleyball'
          ? 'Add starters'
          : 'Add players to lineup'}
      </Heading>
      {previousStarters.length > 0 && (
        <Box gap="small">
          <Button
            primary
            size="large"
            color="tertiary-1"
            label="Use previous lineup"
            onClick={reUseLineup}
          />
          <Text>
            This auto-selects the lineup from your most recent scored game
          </Text>
        </Box>
      )}
      {error ? <Text color="status-critical">{error}</Text> : null}
      {starters.length ? (
        <Text>
          {`You've chosen ${starters.length} ${
            team.attrib_sportType === 'Volleyball' ? 'player' : 'batter'
          }${starters.length > 1 ? 's' : ''}`}
        </Text>
      ) : (
        <Text> </Text>
      )}
      {!teamPlayers.length ? (
        <Text size="large" textAlign="center">
          No players yet. Create one to get started.
        </Text>
      ) : null}
      <Box gap="medium" height={{ min: 'initial' }}>
        {teamPlayers.map((player) => (
          <Box
            key={player._id}
            background={
              starters.map((starter) => starter._id).includes(player._id)
                ? 'secondary-1'
                : 'secondary-7'
            }
            pad="medium"
            style={{ borderRadius: '8px' }}
            onClick={() => selectStarter(player)}
          >
            <CheckBox
              checked={starters
                .map((starter) => starter._id)
                .includes(player._id)}
              label={player.displayName}
            />
          </Box>
        ))}
      </Box>
      {showAddPlayer ? (
        <QuickAddPlayer
          addPlayerLocal={addPlayer}
          onClose={() => setShowAddPlayer(false)}
        />
      ) : null}
      <Box align="start">
        <Box
          hoverIndicator
          onClick={setShowAddPlayer}
          direction="row"
          align="center"
          gap="small"
          fill={false}
          pad="medium"
        >
          <Add color="tertiary-1" />
          <Text color="tertiary-1">Create player</Text>
        </Box>
      </Box>

      <Box
        direction="row"
        fill="horizontal"
        justify="between"
        margin={{ top: 'large' }}
        align="center"
        pad="medium"
        height={{ min: 'small' }}
      >
        <Text
          onClick={() => toggleIsStartersSet(true)}
          hoverIndicator
          color="text-xweak"
          size="large"
        >
          Skip
        </Text>
        <SHDButton
          primary
          size="large"
          color="tertiary-1"
          label="Next"
          onClick={() => toggleIsStartersSet(true)}
          disabled={!starters.length}
        />
      </Box>
    </Box>
  );
};

const SetLineups = compose(withRouter, withLayout)(SetLineupsBase);

export default SetLineups;
