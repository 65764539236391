// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { Avatar, Box, Button, Heading, Image, Layer, Text } from 'grommet';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Share, DownloadOption, FormClose } from 'grommet-icons';

import Skeleton from 'react-loading-skeleton';

import * as Sentry from '@sentry/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withRouter } from 'react-router-dom';
import { MdPerson } from 'react-icons/md';
import { BarLoader } from 'react-spinners';
import {
  PlayListWrapper,
  PlayListTitle,
  ActionsPanel,
  Action,
  OwnerPanel,
  Name,
  TrackListHeaderPanel,
  ShuffleButton,
  TracksQuantity,
  VideoTracksContainer,
  CloseButton,
  FullModal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from './styles';
import axios from '../../helpers/axios';

import ShuffleIcon from './ShuffleIcon';
import VideoTrackCard from './VideoTrackCard';
import EditListButton from './EditListButton';
import AddListButton from './AddListButton/AddListButton';
import { UserContext } from '../../services/Session';
import Toast from '../../components/Toast';
import { SIGN_IN } from '../../constants/routes';
import { useReelDoc } from '../../helpers/firestoreHooks';
import { theFirebase } from '../../services/Firebase/firebase';
import PhoneNumberInput from '../../helpers/PhoneNumberInput';
import { analytics } from '../../services/analytics';

import { usePaywall } from '../../components/Paywall/paywall.hooks';
import { useIsFeatureEnabled } from '../../helpers/utils';
import Paywall from '../../components/Paywall/Paywall';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../components/Paywall/paywall.types';
import { useIsEntitledToViewPlayer } from '../Player/playerHooks';

const DownloadModal = ({ playlistId, setShowDownloadModal }) => {
  const reelDoc = useReelDoc(theFirebase().firestore, playlistId);

  const [downloadStatus, setDownloadStatus] = React.useState('');
  const [url, setUrl] = React.useState('');

  const [showPhoneNumberInput, setShowPhoneNumberInput] = React.useState(false);
  const showPhoneNumberInputRef = React.useRef(showPhoneNumberInput);
  const [phoneNumber, setPhoneNumber] = React.useState('');

  useEffect(() => {
    // Update ref value whenever showPhoneNumberInput changes
    showPhoneNumberInputRef.current = showPhoneNumberInput;
  }, [showPhoneNumberInput]);

  useEffect(() => {
    setDownloadStatus('starting');
    axios
      .get(`/api/reels/${playlistId}/download`)
      .then((response) => {
        const { data } = response;
        setUrl(data.url);

        if (!showPhoneNumberInputRef.current) {
          const a = document.createElement('a');
          a.href = data.url;
          a.download = url.split('/').pop(); // This will suggest a filename to save as, derived from the URL
          document.body.appendChild(a); // Append the element to the document
          a.click(); // Programmatically click the element to trigger the download
          document.body.removeChild(a); // Remove the element from the document
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setDownloadStatus('error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reelDoc && reelDoc[0] && reelDoc[0].downloadStatus) {
      setDownloadStatus(reelDoc[0].downloadStatus);
    }

    if (reelDoc && reelDoc[0] && reelDoc[0].needsPhoneNumber) {
      setShowPhoneNumberInput(true);
    }
  }, [reelDoc]);

  const onDownloadClicked = () => {
    window.open(url, '_blank');
  };

  const renderStatus = () => {
    if (showPhoneNumberInput) {
      return (
        <Box gap="small">
          <Text>
            We will text you the video when your reel is done processing! (~5
            mins)
          </Text>
          <PhoneNumberInput
            value={phoneNumber}
            placeholder="Your number"
            onValid={(value) => {
              setPhoneNumber(value);
            }}
            onError={() => {}}
          />
          <Button
            primary
            color="tertiary-1"
            label="Download"
            onClick={() => {
              axios
                .put(`/api/reels/${playlistId}/phone_number`, {
                  phoneNumber,
                })
                .then(() => {
                  setShowDownloadModal(false);
                })
                .catch((error) => {
                  Sentry.captureException(error);
                });
            }}
            disabled={!phoneNumber}
          />
        </Box>
      );
    }

    let text = '';
    let statusText = '';
    if (downloadStatus !== '') {
      text =
        'We are preparing your reel for download. Please keep this window open';
    }

    if (downloadStatus === 'creating') {
      statusText = 'Creating your reel...';
    } else if (downloadStatus === 'uploading') {
      statusText = 'Uploading your reel...';
    } else if (url && downloadStatus === 'ready') {
      text = '';
      statusText = 'Your reel is ready for download!';
    } else if (downloadStatus === 'error') {
      statusText =
        'There was an error creating your reel. Please try again later.';
    }

    return (
      <Box gap="small">
        <Text>{text}</Text>
        <Box margin={{ top: 'medium' }} pad="small">
          <Text>{statusText}</Text>
          <BarLoader loading={!url} />
        </Box>
        {url && (
          <Button
            primary
            color="tertiary-1"
            size="large"
            label="Download"
            onClick={onDownloadClicked}
          />
        )}
      </Box>
    );
  };

  return (
    <FullModal
      responsive
      modal
      onClickOutside={() => setShowDownloadModal(false)}
      onEsc={() => setShowDownloadModal(false)}
      animation={false}
    >
      <CloseButton onClick={() => setShowDownloadModal(false)}>
        <FormClose />
      </CloseButton>
      <ModalContent>
        <ModalHeader pad={{ top: '15px' }}>
          <ModalTitle>Download Reel</ModalTitle>
        </ModalHeader>
        <ModalBody>{renderStatus()}</ModalBody>
      </ModalContent>
    </FullModal>
  );
};

const PlaylistItems = ({
  playlistId,
  playlistData,
  playlist,
  player,
  mainTitle,
  setTrack,
  currentId,
  onReorderList,
  setIsShuffled,
  isShuffled,
  canEdit,
  onEdited,
  onOpen,
  onClose,
  history,
}) => {
  const userStore = useContext(UserContext);
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [showSignIn, setShowSignIn] = React.useState(false);

  const isMonetization = useIsFeatureEnabled('monetization', userStore);
  const { status: viewerEntitlementStatus } = useIsEntitledToViewPlayer(player);
  const isEntitled =
    viewerEntitlementStatus === ViewerEntitlementStatus.ENTITLED;

  const {
    isPaywallActive,
    handlePaywallOpen,
    handlePaywallClose,
    handleUnlockAccess,
  } = usePaywall();

  // TEMP: disable showing sign in modal
  // useEffect(() => {
  //   // set timer for 3 seconds
  //   const timer = setTimeout(() => {
  //     setShowSignIn(!userStore.authUser);
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, [userStore.authUser]);

  const link = `${process.env.REACT_APP_BASE_URL}/reels/${playlistId}?utm_source=mobile_share`;

  const reorderItems = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleShare = () => {
    if (navigator.share) {
      analytics.track('sidelineREEL Share Clicked', {
        playlistId,
        loggedIn: !!userStore.authUser,
        type: 'share via',
      });
      navigator
        .share({
          title: mainTitle,
          text: mainTitle,
          url: link,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const handleDragEnd = (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const clipList = reorderItems(
      playlist,
      result.source.index,
      result.destination.index
    );
    onReorderList(clipList);
  };

  const toggleIsShuffled = () => setIsShuffled((prevValue) => !prevValue);

  const playerDiv = player ? (
    <OwnerPanel>
      <Box
        direction="row"
        gap="small"
        align="center"
        pad="small"
        onClick={() => {
          history.push(`/${player._id}`);
        }}
      >
        <Avatar size="36px" src={player.DNOR_imgUrlT150} />
        <Name>{`${player.nameFirst} ${player.nameLast}`}</Name>
      </Box>
    </OwnerPanel>
  ) : (
    <Skeleton height={66} />
  );

  const emptyState = (
    <Box align="center">
      <Image src="/experiment.png" width="80%" />
      <Heading level={3} textAlign="center">
        {canEdit
          ? 'Add some clips to your reel!'
          : "This reel doesn't have any clips yet!"}
      </Heading>
    </Box>
  );

  const renderList = () => {
    if (!playlist || playlist.length === 0) {
      return emptyState;
    }

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppablePlaylist" direction="vertical">
          {(provided, snapshot) => (
            <VideoTracksContainer
              ref={provided.innerRef}
              style={snapshot.isDraggingOver.style}
              {...provided.droppableProps}
            >
              {playlist?.map((item, index) => {
                const [{ id, titleMain, titleSub }] = item.sources;
                return (
                  <VideoTrackCard
                    playlistData={playlistData}
                    playlistId={playlistId}
                    id={id}
                    key={id}
                    index={index}
                    item={item}
                    titleMain={titleMain}
                    titleSub={titleSub}
                    handleClick={setTrack}
                    isCurrent={id === currentId}
                    canEdit={canEdit}
                    onEdited={onEdited}
                    onOpen={onOpen}
                    onClose={onClose}
                  />
                );
              })}
              {provided.placeholder}
            </VideoTracksContainer>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const onDownloadClicked = () => {
    if (isMonetization) {
      if (!isEntitled) {
        setShowDownloadModal(true);
      } else {
        handlePaywallOpen();
      }
    } else {
      setShowDownloadModal(true);
    }
  };

  return (
    <PlayListWrapper>
      <PlayListTitle>{mainTitle}</PlayListTitle>
      <ActionsPanel>
        <Action>
          <CopyToClipboard
            text={link}
            onCopy={() => {
              analytics.track('sidelineREEL Share Clicked', {
                playlistId,
                loggedIn: !!userStore.authUser,
                type: 'copy',
              });
              setCopied(true);
            }}
          >
            <Share
              color="primary-1"
              height="24px"
              width="24px"
              onClick={handleShare}
            />
          </CopyToClipboard>
        </Action>
        <Action>
          <DownloadOption
            color="primary-1"
            height="24px"
            width="24px"
            onClick={() => {
              onDownloadClicked();
              analytics.track('sidelineREEL Download Clicked', {
                playlistId,
                loggedIn: !!userStore.authUser,
              });
            }}
          />
        </Action>
        {canEdit && (
          <EditListButton playlistData={playlistData} onEdited={onEdited} />
        )}
        {canEdit && (
          <AddListButton
            playlistId={playlistId}
            playlistData={playlistData}
            player={player}
            onClipsAdded={() => {
              window.location.reload();
            }}
            highlighted={playlist && playlist.length === 0}
            clipCount={playlist?.length}
            onOpen={onOpen}
            onClose={onClose}
          />
        )}
      </ActionsPanel>
      {playerDiv}
      <TrackListHeaderPanel>
        <ShuffleButton onClick={toggleIsShuffled} $isActive={isShuffled}>
          <ShuffleIcon />
        </ShuffleButton>
        <TracksQuantity>{`${playlist?.length} clips`}</TracksQuantity>
      </TrackListHeaderPanel>
      {renderList()}
      <Button
        primary
        color="tertiary-1"
        size="large"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
        }}
        label="Give us feedback!"
        href={`https://docs.google.com/forms/d/e/1FAIpQLSfZQOLbB01u2lNa1PpH7KxLnjsegHLflpAyMgWgNtvDC1d2BA/viewform?entry.1649161831=${playlistId}`}
        target="_blank"
      />

      <Paywall
        context={PaywallContext.PLAYLIST_PAGE}
        isOpen={isPaywallActive}
        onClose={handlePaywallClose}
        onUnlockAccess={(term) => handleUnlockAccess(term)}
      />

      {showDownloadModal && (
        <DownloadModal
          playlistId={playlistId}
          setShowDownloadModal={setShowDownloadModal}
        />
      )}
      {copied && (
        <Toast
          label="Copied link to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
      {showSignIn && (
        <Layer
          modal
          position="bottom"
          responsive={false}
          full="horizontal"
          onClickOutside={() => setShowSignIn(false)}
        >
          <Box
            gap="medium"
            pad="small"
            style={{
              backgroundImage: 'linear-gradient(to bottom, #256515, #024006)',
            }}
          >
            <Box direction="row" gap="small" align="center">
              <MdPerson size="32px" color="white" />
              <Heading level={3} color="white">
                You are signed out
              </Heading>
            </Box>

            <Text color="white">Sign in to get the best experience.</Text>
            <Text color="white">
              Once you sign in, you can edit your sidelineREEL!
            </Text>
            <Button
              margin={{ top: 'medium' }}
              secondary
              color="white"
              size="large"
              label={
                <Text weight="bold" color="white">
                  Sign in
                </Text>
              }
              onClick={() => {
                analytics.track('sidelineREEL Sign In Clicked', {
                  playlistId,
                });

                history.push(`${SIGN_IN}?redirectUrl=reels/${playlistId}`);
              }}
            />
            <Box height="80px" />
          </Box>
        </Layer>
      )}
    </PlayListWrapper>
  );
};

export default withRouter(PlaylistItems);
