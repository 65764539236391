// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Heading, Select, Text } from 'grommet';
import React, { useContext, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import axios from '../../helpers/axios';
import HelpTooltip from '../../helpers/HelpTooltip';
import Invites from '../Settings/Invites';

import PaywallCta from '../../components/Paywall/PaywallCta';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { useRevenueCat } from '../../components/Paywall/paywall.hooks';
import { BasicLoader } from '../../helpers/Loaders';
import { PaywallContext } from '../../components/Paywall/paywall.types';

const PlayerSettings = ({
  claimedPlayer = {},
  closeLayer = () => {},
  setShowEditSuccess = () => {},
  hasBaseballOrSoftball = true,
  hasBaseballOrSoftballAndAnotherSport = false,
}) => {
  const userStore = useContext(UserContext);
  const [error, setError] = useState();
  const [value, setValue] = useState({ ...claimedPlayer });
  const [loading, toggleLoading] = useState(false);

  // Paywall
  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);
  const { isEntitled, isLoadingRc } = useRevenueCat();

  const getNameStyleValue = () => {
    if (value.embedNameFirstLevel === 2 && value.embedNameLastLevel === 2) {
      return 40;
    }
    if (value.embedNameFirstLevel === 2 && value.embedNameLastLevel === 1) {
      return 30;
    }
    if (value.embedNameFirstLevel === 1 && value.embedNameLastLevel === 2) {
      return 20;
    }
    if (value.embedNameFirstLevel === 1 && value.embedNameLastLevel === 1) {
      return 10;
    }
    return 0;
  };

  const saveEdit = () => {
    toggleLoading(true);

    axios
      .put(`/api/claimed_player/${value._id}`, {
        playerInfo: value,
        isEditPrivacy: true,
      })
      .then((response) => {
        userStore.setSelectedPlayer(response.data);
        const players = [...userStore.userPlayers].map((storePlayer) => {
          if (storePlayer._id === value._id) {
            return response.data;
          }
          return storePlayer;
        });
        userStore.setUserPlayers(players);
        setShowEditSuccess(value);
        toggleLoading(false);
        closeLayer();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        if (respError.response && respError.response.data.message) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. Please refresh and try again.');
        }
        toggleLoading(false);
      });
  };

  return (
    <Box
      fill="horizontal"
      height="100%"
      pad={{ horizontal: 'medium', top: 'medium', bottom: '108px' }}
      gap="medium"
      overflow="auto"
      id="edit-player-settings-box"
    >
      <Text color="status-critical">{error}</Text>
      <Box style={{ flexShrink: 0 }}>
        <Invites
          claimedPlayerId={claimedPlayer._id}
          nameToManage={claimedPlayer.nameFirst}
          tooltip={
            <HelpTooltip content="This user would be able to edit player information and settings." />
          }
        />
      </Box>
      <Heading margin={{ bottom: 'small' }} level="5">
        Player settings
      </Heading>
      <Box gap="small" style={{ flexShrink: 0 }}>
        <Text>Name display style</Text>
        <Select
          placeholder="Select"
          labelKey="label"
          value={getNameStyleValue()}
          valueKey={{ key: 'value', reduce: true }}
          options={[
            {
              label: `${value.nameFirst} ${value.nameLast}`,
              value: 40,
            },
            {
              label: `${value.nameFirst} ${value.nameLast.slice(0, 1)}.`,
              value: 30,
            },
            {
              label: `${value.nameFirst.slice(0, 1)}. ${value.nameLast}`,
              value: 20,
            },
            {
              label: `${value.nameFirst.slice(0, 1)}.${value.nameLast.slice(0, 1)}.`,
              value: 10,
            },
            {
              label: "Don't display",
              value: 0,
            },
          ]}
          onChange={({ option }) => {
            setValue((prevValue) => {
              const newValue = { ...prevValue };
              if (option.value === 40) {
                newValue.embedNameFirstLevel = 2;
                newValue.embedNameLastLevel = 2;
              } else if (option.value === 30) {
                newValue.embedNameFirstLevel = 2;
                newValue.embedNameLastLevel = 1;
              } else if (option.value === 20) {
                newValue.embedNameFirstLevel = 1;
                newValue.embedNameLastLevel = 2;
              } else if (option.value === 10) {
                newValue.embedNameFirstLevel = 1;
                newValue.embedNameLastLevel = 1;
              } else if (option.value === 0) {
                newValue.embedNameFirstLevel = 0;
                newValue.embedNameLastLevel = 0;
                newValue.isNameOnPublicWebsite = false;
              }
              return newValue;
            });
          }}
        />
      </Box>
      {value.embedNameFirstLevel || value.embedNameLastLevel ? (
        <Box gap="small" style={{ flexShrink: 0 }}>
          <Text>Show name on public player and team pages</Text>
          <Select
            placeholder="Select"
            labelKey="label"
            value={value.isNameOnPublicWebsite}
            valueKey={{ key: 'value', reduce: true }}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={({ option }) => {
              setValue((prevValue) => {
                const newValue = { ...prevValue };
                newValue.isNameOnPublicWebsite = option.value;
                return newValue;
              });
            }}
          />
        </Box>
      ) : null}
      <Box gap="small" style={{ flexShrink: 0 }}>
        <Text>Show profile picture on public player and team pages</Text>
        <Select
          placeholder="Select"
          labelKey="label"
          value={value.isEmbedImagePublicWebsite}
          valueKey={{ key: 'value', reduce: true }}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          onChange={({ option }) => {
            setValue((prevValue) => {
              const newValue = { ...prevValue };
              newValue.isEmbedImagePublicWebsite = option.value;
              return newValue;
            });
          }}
        />
      </Box>
      <Box gap="small" style={{ flexShrink: 0 }}>
        <Text>Show profile picture on team livestreams</Text>
        <Select
          placeholder="Select"
          labelKey="label"
          value={value.isEmbedImageVideo}
          valueKey={{ key: 'value', reduce: true }}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          onChange={({ option }) => {
            setValue((prevValue) => {
              const newValue = { ...prevValue };
              newValue.isEmbedImageVideo = option.value;
              return newValue;
            });
          }}
        />
      </Box>

      {isMonetizationEnabled ? (
        <>
          {!isEntitled &&
          hasBaseballOrSoftball &&
          !hasBaseballOrSoftballAndAnotherSport ? (
            <Box gap="small" style={{ flexShrink: 0 }}>
              {isLoadingRc ? (
                <BasicLoader />
              ) : (
                <PaywallCta
                  mode="default"
                  context={PaywallContext.PLAYER_SETTINGS}
                  playerId={value._id}
                />
              )}
            </Box>
          ) : (
            <Box gap="small" style={{ flexShrink: 0 }}>
              <Box direction="row" gap="small" align="center">
                <Text>Show game clips to public</Text>
                <HelpTooltip
                  content={
                    <Box background={'light-3'} pad="small">
                      <Text>
                        This toggle only affects public visibility of clips in
                        non-baseball/softball sports unless the player has one
                        subscribed manager.
                      </Text>
                    </Box>
                  }
                />
              </Box>
              <Select
                placeholder="Select"
                labelKey="label"
                value={value.publicPublishLevel}
                valueKey={{ key: 'value', reduce: true }}
                options={[
                  { label: 'Yes', value: 10 },
                  { label: 'No', value: 5 },
                ]}
                onChange={({ option }) => {
                  setValue((prevValue) => {
                    const newValue = { ...prevValue };
                    newValue.publicPublishLevel = option.value;
                    return newValue;
                  });
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <Box gap="small" style={{ flexShrink: 0 }}>
          <Box direction="row" gap="small" align="center">
            <Text>Show game clips to public</Text>
            <HelpTooltip
              content={
                <Box background={'light-3'} pad="small">
                  <Text>
                    Allow those outside your team community to view your clips.
                  </Text>
                </Box>
              }
            />
          </Box>
          <Select
            placeholder="Select"
            labelKey="label"
            value={value.publicPublishLevel}
            valueKey={{ key: 'value', reduce: true }}
            options={[
              { label: 'Yes', value: 10 },
              { label: 'No', value: 5 },
            ]}
            onChange={({ option }) => {
              setValue((prevValue) => {
                const newValue = { ...prevValue };
                newValue.publicPublishLevel = option.value;
                return newValue;
              });
            }}
          />
        </Box>
      )}

      <Box
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%',
          zIndex: 10,
        }}
        pad={{
          horizontal: 'small',
          vertical: '22px',
        }}
        background="light-3"
      >
        <SHDButton
          primary
          color="tertiary-1"
          size="large"
          onClick={saveEdit}
          label={loading ? '...Saving' : 'Save'}
          disabled={
            JSON.stringify({ ...claimedPlayer }) === JSON.stringify(value) ||
            loading
          }
        />
      </Box>
    </Box>
  );
};

export default PlayerSettings;
