// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const defaultFormValues = {
  attrib_sportType: '',
  nameLong: '',
  nameMed: '',
  teamType: '',
  attrib_season: null,
  nameLocation: '',
  zipCode: '',
  isAge13Plus: null,
  nameHandle: '',
  isStageTeamInfoDone: false,
};

export default defaultFormValues;
