import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { Avatar, Box, Header, Nav, Text, Layer, Button, Image } from 'grommet';
import { Next, User } from 'grommet-icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserContext } from '../services/Session/context';
import * as ROUTES from '../constants/routes';
import SHDButton from './SHD/Button';
import { useIsFeatureEnabled } from '../helpers/utils';
import ImpersonateBanner from './ImpersonateBanner';
import SHDLink from '../helpers/SHDLink';
import signOut from '../helpers/signOut';
import { AppHostContext } from '../services/appHost';

// TODO: refactor this
const HeaderImg = ({ link = 'https://home.sidelinehd.com/' }) => {
  return (
    <a href={link}>
      <Image
        src="/shd_header.png"
        alt="header"
        height={'24'}
        alignSelf="center"
      />
    </a>
  );
};

const Navigation = withRouter(
  observer((props: RouteComponentProps & { showSignIn?: boolean }) => {
    const userStore = useContext(UserContext);

    if (userStore.authUser) {
      if (userStore.activeOnboardingTeam) {
        return <NavigationGenericAuth />;
      }

      if (userStore.selectedTeam || userStore.selectedPlayer) {
        if (props.history.location.state) {
          const { selectedTeamId, redirect } =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.history.location.state as any;
          if (selectedTeamId) {
            const teamIds = userStore.userTeams.map((userTeam) => userTeam._id);
            const index = teamIds.indexOf(selectedTeamId);
            const selectedTeam = userStore.userTeams[index];
            userStore.setSelectedTeam(selectedTeam);

            props.history.push(`/${redirect}`);
          }
        }
        return <NavigationBar />;
      }

      return <NavigationBar />;
    }
    return <NavigationNonAuth {...props} />;
  })
);

const NavigationBar = observer(() => {
  const userStore = useContext(UserContext);
  const isSHDAdmin = useIsFeatureEnabled('is_admin', userStore);
  const appHost = useContext(AppHostContext);

  const [showUserSettingsMenu, setShowUserSettingsMenu] = React.useState(false);

  const barColor = 'brand';
  const navAvatar = userStore.authUser?.photoURL ? (
    <Avatar
      src={userStore.authUser.photoURL}
      {...{
        onClick: () => {
          setShowUserSettingsMenu(true);
        },
      }}
    />
  ) : (
    <Avatar
      background="brand"
      {...{
        onClick: () => {
          setShowUserSettingsMenu(true);
        },
      }}
    >
      {userStore.user?.nameFirst ? userStore.user.nameFirst[0] : <User />}
    </Avatar>
  );

  return (
    <>
      <Header
        background={barColor}
        direction="row"
        pad={{ horizontal: 'medium' }}
        height={'56px'}
      >
        <Nav fill direction="row" justify="between">
          <SHDLink style={{ display: 'flex' }} to={ROUTES.HOME}>
            <Box align="center" alignSelf="center" justify="center">
              <Image src="/shd_header.png" alt="header" height={'24'} />
            </Box>
          </SHDLink>
          <Box
            justify="end"
            height={'40px'}
            width={'40px'}
            align="center"
            alignSelf="center"
          >
            {navAvatar}
          </Box>
        </Nav>
        {showUserSettingsMenu && (
          <Layer
            position="left"
            full="vertical"
            onClickOutside={() => {
              setShowUserSettingsMenu(false);
            }}
            responsive={false}
            background="white"
          >
            <Box pad="16px" width={{ max: '340px' }} flex>
              <Box flex={{ grow: 1, shrink: 0 }}>
                <Box direction="row" gap="medium" margin={{ bottom: 'large' }}>
                  {navAvatar}
                  <Box gap="small">
                    <Text weight="bold">
                      {userStore.user?.nameFirst} {userStore.user?.nameLast}
                    </Text>
                    <Text size="small">{userStore.user?.email}</Text>
                  </Box>
                </Box>
                <SHDLink to={ROUTES.SETTINGS}>
                  <Box
                    direction="row"
                    justify="between"
                    gap="16px"
                    border={{
                      color: '#E8E8E8',
                      size: '1px',
                      style: 'solid',
                      side: 'bottom',
                    }}
                    pad={{ vertical: '16px' }}
                  >
                    <Box>
                      <Text weight="bold">Manage your account</Text>
                      <Text>Change your phone number and email.</Text>
                    </Box>
                    <Box alignSelf="center">
                      <Next color="black" size="24px" />
                    </Box>
                  </Box>
                </SHDLink>
                {appHost.isHosted && (
                  <Box
                    direction="row"
                    justify="between"
                    gap="16px"
                    border={{
                      color: '#E8E8E8',
                      size: '1px',
                      style: 'solid',
                      side: 'bottom',
                    }}
                    pad={{ vertical: '16px' }}
                    onClick={() => {
                      appHost.service.notifications.navigateTo('Settings');
                    }}
                  >
                    <Box>
                      <Text weight="bold">Device Settings</Text>
                      <Text>Manage device settings.</Text>
                    </Box>
                    <Box alignSelf="center">
                      <Next color="black" size="24px" />
                    </Box>
                  </Box>
                )}
                <a
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  href={'https://help.sidelinehd.com'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    direction="row"
                    justify="between"
                    gap="16px"
                    border={{
                      color: '#E8E8E8',
                      size: '1px',
                      style: 'solid',
                      side: 'bottom',
                    }}
                    pad={{ vertical: '16px' }}
                  >
                    <Box>
                      <Text weight="bold">Help Center</Text>
                      <Text>Read resources and contact support.</Text>
                    </Box>
                    <Box alignSelf="center">
                      <Next color="black" size="24px" />
                    </Box>
                  </Box>
                </a>
                {isSHDAdmin && (
                  <Box pad={{ vertical: 'large' }}>
                    <Text size="small" weight="bold">
                      Internal Tools
                    </Text>
                  </Box>
                )}
                {isSHDAdmin && (
                  <SHDLink to={ROUTES.ADMIN}>
                    <Box
                      direction="row"
                      justify="between"
                      gap="16px"
                      border={{
                        color: '#E8E8E8',
                        size: '1px',
                        style: 'solid',
                        side: 'bottom',
                      }}
                      pad={{ vertical: '16px' }}
                      background="light-5"
                    >
                      <Box>
                        <Text weight="bold">Admin</Text>
                        <Text>User, teams, players search</Text>
                      </Box>
                      <Box alignSelf="center">
                        <Next color="black" size="24px" />
                      </Box>
                    </Box>
                  </SHDLink>
                )}
                {isSHDAdmin && (
                  <SHDLink to={ROUTES.ADMIN_STREAMLOG}>
                    <Box
                      direction="row"
                      justify="between"
                      gap="16px"
                      border={{
                        color: '#E8E8E8',
                        size: '1px',
                        style: 'solid',
                        side: 'bottom',
                      }}
                      pad={{ vertical: '16px' }}
                      background="light-5"
                    >
                      <Box>
                        <Text weight="bold">Stream Log</Text>
                      </Box>
                      <Box alignSelf="center">
                        <Next color="black" size="24px" />
                      </Box>
                    </Box>
                  </SHDLink>
                )}
                {isSHDAdmin && (
                  <SHDLink to={ROUTES.ADMIN_STREAMBOARD}>
                    <Box
                      direction="row"
                      justify="between"
                      gap="16px"
                      border={{
                        color: '#E8E8E8',
                        size: '1px',
                        style: 'solid',
                        side: 'bottom',
                      }}
                      pad={{ vertical: '16px' }}
                      background="light-5"
                    >
                      <Box>
                        <Text weight="bold">Stream Board</Text>
                      </Box>
                      <Box alignSelf="center">
                        <Next color="black" size="24px" />
                      </Box>
                    </Box>
                  </SHDLink>
                )}
              </Box>

              <Button
                plain
                label={
                  <Text color="status-critical" weight="bold">
                    Sign Out
                  </Text>
                }
                onClick={() => {
                  signOut(true, appHost.isHosted);
                }}
              />
            </Box>
          </Layer>
        )}
      </Header>

      {userStore.authUser?.claims &&
      'spoof_user_id' in userStore.authUser.claims &&
      userStore.authUser.claims.spoof_user_id &&
      userStore.authUser.claims.is_admin ? (
        <ImpersonateBanner spoofedUser={userStore.authUser.spoofedUser} />
      ) : null}
    </>
  );
});

const NavigationNonAuth: React.FC<
  RouteComponentProps & { showSignIn?: boolean }
> = ({ location, ...props }) => {
  const appHost = useContext(AppHostContext);
  const redirect = `?redirectUrl=${location.pathname.replace('/', '')}`;

  return (
    <Header background="brand">
      {props.showSignIn &&
      location.pathname !== ROUTES.SIGN_IN &&
      location.pathname !== ROUTES.PHONE_SIGN_IN &&
      location.pathname !== '/invite' &&
      location.pathname.split('/')[1] !== 'claim_user' ? (
        <Box
          direction="row"
          pad="small"
          justify="between"
          alignContent="center"
          fill="horizontal"
        >
          <HeaderImg />
          <SHDButton
            label="Sign in"
            href={
              appHost.isHosted
                ? `${ROUTES.PHONE_SIGN_IN}${redirect}`
                : `${ROUTES.SIGN_IN}${redirect}`
            }
            margin="auto 0"
          />
        </Box>
      ) : (
        <Box
          direction="row"
          pad="small"
          justify="center"
          alignContent="center"
          fill="horizontal"
        >
          <HeaderImg />
        </Box>
      )}
    </Header>
  );
};

const NavigationGenericAuth = () => {
  const userStore = useContext(UserContext);
  const appHost = useContext(AppHostContext);

  let link = '';
  if (
    userStore.isPermittedToCreateTeam &&
    userStore.activeOnboardingTeam !== null
  ) {
    link = ROUTES.ONBOARDING;
  } else if (userStore.selectedPlayer || userStore.selectedTeam) {
    link = '/';
  } else if (userStore.isPermittedToCreateTeam) {
    link = '/';
  }
  return (
    <Header
      background="brand"
      direction="row"
      pad="small"
      justify="between"
      alignContent="center"
    >
      <Box
        direction="row"
        pad="small"
        justify="between"
        alignContent="center"
        fill="horizontal"
      >
        <HeaderImg link={link} />
        <SHDButton
          label="Sign out"
          onClick={() => signOut(true, appHost.isHosted)}
        />
      </Box>
    </Header>
  );
};

export default Navigation;
