import React from 'react';
import { Box, Button, Text } from 'grommet';
import { PlayerPageProps } from './player.types';

interface PlayerHitScopeProps {
  playerId: string;
  claimed: boolean;
  history: PlayerPageProps['history'];
  setShowClaimWall: (show: boolean) => void;
}

export const PlayerHitScope: React.FC<PlayerHitScopeProps> = ({
  playerId,
  claimed,
  history,
  setShowClaimWall,
}) => {
  return (
    <Box margin="medium" pad="medium" gap="small" background="light-2" round>
      <Text weight="bold">HitScope Batting Summary</Text>
      <Text>Quickly review every batted ball.</Text>
      <Button
        primary
        label="View your HitScope"
        onClick={() => {
          if (claimed) {
            history.push(`/hitscope/${playerId}`);
          } else {
            setShowClaimWall(true);
          }
        }}
      />
    </Box>
  );
};

export default PlayerHitScope;
