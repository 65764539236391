// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import _ from 'lodash';
import { Search } from 'grommet-icons';
import { Box, Card, Text, TextInput } from 'grommet';
import { withRouter } from 'react-router-dom';
import axios from '../../helpers/axios';
import withLayout from '../../helpers/withLayout';

const HighlightedText = ({
  text = '',
  highlight = '',
  weight,
  defaultColor = 'black',
}) => {
  if (!highlight.trim()) {
    return (
      <Text weight={weight} color={defaultColor}>
        {text}
      </Text>
    );
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <Text weight={weight} color="secondary-1" key={`search-${i + 1}`}>
              {part}
            </Text>
          ) : (
            <Text weight={weight} color={defaultColor} key={`search-${i + 1}`}>
              {part}
            </Text>
          )
        )}
    </span>
  );
};

const TeamSearch = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState(null);

  const search = _.debounce((text) => {
    setSearchTerm(text);
    if (text) {
      axios
        .get('/api/team_text_search', { params: { search: text } })
        .then((response) => {
          setTeams([...response.data]);
        })
        .catch((e) => {
          setError(e.toString());
        });
    } else {
      setTeams([]);
    }
  }, 300);

  const onChange = (event) => {
    const { value: newValue } = event.target;
    search(newValue);
  };

  const cards = teams.map((team, i) => {
    const year = team.attrib_season.toString().substring(0, 4);
    const seasonIndex = team.attrib_season.toString().substring(4, 5);

    let season;
    if (seasonIndex === '1') {
      season = 'Spring';
    } else if (seasonIndex === '2') {
      season = 'Summer';
    } else if (seasonIndex === '3') {
      season = 'Fall';
    } else {
      season = '';
    }

    return (
      <Card
        pad="medium"
        key={`${team.id}-${i - 1}`}
        onClick={() => {
          props.history.push(`/${team.name_handle_lower}`);
        }}
      >
        <HighlightedText
          text={team.name_long}
          highlight={searchTerm}
          weight="bold"
        />
        <Box direction="row" gap="small" align="center">
          <Text color="dark-3">{team.attrib_sport_type}</Text>
          <Text color="dark-3">{`${season} ${year}`}</Text>
        </Box>
        <Box direction="row" gap="small" align="center">
          <HighlightedText
            text={team.name_location}
            defaultColor="dark-3"
            highlight={searchTerm}
          />
          <Text color="dark-3">{team.zip_code}</Text>
        </Box>
      </Card>
    );
  });

  const emptyState = (
    <Box align="center" gap="medium">
      <Search size="large" />
      <Text>Find a team by searching their name</Text>
    </Box>
  );

  return (
    <Box margin="auto" width="large" pad={{ top: 'large' }}>
      <Box fill="horizontal" direction="row" pad="small" round="small">
        {error ? <Text>{error}</Text> : null}
        <TextInput
          type="search"
          size="medium"
          onChange={onChange}
          placeholder="Search for your Team by team name or city"
        />
      </Box>
      <Box pad="medium" gap="small">
        {teams.length > 0 ? cards : emptyState}
      </Box>
    </Box>
  );
};

export default withRouter(withLayout(TeamSearch));
