/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Anchor,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
} from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import axios from '../../helpers/axios';
import { withFirebase } from '../../services/Firebase';

import * as Sentry from '@sentry/browser';
import ApiError from '../../helpers/ApiError';
import { dtos } from '@shd/jslib/models';
import {
  Destination,
  PAGE_TYPE,
  PROFILE_TYPE,
  Page,
  PageResponse,
  Paging,
  Profile,
  callFbApi,
  getFacebookAuthResponse,
} from '../../helpers/facebook';
import { errorUtil, strogging } from '@shd/jslib/infra';
import { analytics } from '../../services/analytics';

interface DestinationTypePickerProps {
  onClose: () => void;
  setDestinationType: (destinationType: string) => void;
  setStep: (step: number) => void;
}
const DestinationTypePicker: React.FC<DestinationTypePickerProps> = ({
  onClose,
  setDestinationType,
  setStep,
}) => {
  return (
    <Box>
      <Box direction="row" align={'center'} justify={'between'} pad={'large'}>
        <Box alignSelf="start" onClick={onClose}>
          <LinkPrevious size="24px" />
        </Box>
        <Text weight="bold" alignSelf="center">
          Stream to Facebook
        </Text>
        <Box alignSelf="end" width={'24px'} />
      </Box>
      <Box pad="medium" gap="medium">
        <Box direction="row" justify="between">
          <Text alignSelf="center" size="large" weight="bold">
            Where do you want to stream to?
          </Text>
        </Box>
        <Box gap="medium" fill="horizontal">
          <Button
            secondary
            color="dark-4"
            label="My personal profile"
            size="large"
            onClick={() => {
              setDestinationType(PROFILE_TYPE);
              setStep(2);
            }}
          />
          <Button
            secondary
            color="dark-4"
            label="A page"
            size="large"
            onClick={() => {
              setDestinationType(PAGE_TYPE);
              setStep(2);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface ProfilePickerProps {
  backClicked: () => void;
  onSave: (destination: Destination) => void;
}
const ProfilePicker: React.FC<ProfilePickerProps> = ({
  backClicked,
  onSave,
}) => {
  const [profile, setProfile] = useState<Profile>();
  const [error, setError] = useState<string | null>();

  const getProfile = () =>
    callFbApi<Profile>('/me?fields=name,email,picture,link');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        setProfile(response);
      } catch (err) {
        setError('Failed to fetch profile. Please try again.');
      }
    };

    fetchProfile();
  }, []);

  return (
    <Card round={false} elevation="none" fill skeleton={!profile}>
      <CardHeader direction="row" pad="medium" align="center" justify="between">
        <Box alignSelf="start" onClick={backClicked}>
          <LinkPrevious size="24px" />
        </Box>
        <Box justify="center" align="center">
          <Text alignSelf="center" size="large" weight="bold">
            Stream to your profile
          </Text>
        </Box>
        <Box width="24px" />
      </CardHeader>
      <CardBody fill pad="medium" gap="medium">
        <Text weight="bold">
          You will be streaming to your personal profile
        </Text>
        <Box
          direction="row"
          gap="small"
          pad="small"
          round="small"
          align="center"
        >
          {error && <Text color="status-error">{error}</Text>}
          <Box flex={{ shrink: 0, grow: 0 }}>
            {profile?.picture ? (
              <Avatar src={profile?.picture.data.url} />
            ) : (
              <Avatar background="brand">
                {profile?.name ? profile?.name.substring(0, 2) : 'XY'}
              </Avatar>
            )}
          </Box>
          <Text size="large" weight="bold">
            {profile?.name}
          </Text>
        </Box>
        <Text>
          By default, SidelineHD posts content to your Timeline that your
          Friends can see.
        </Text>
      </CardBody>
      <CardFooter
        pad={{ horizontal: 'small', vertical: 'large' }}
        background="light-2"
      >
        <Button
          fill
          primary
          size="large"
          label="Next"
          onClick={() => {
            if (profile) {
              onSave({
                type: PROFILE_TYPE,
                ...profile,
              });
            }
          }}
        />
      </CardFooter>
    </Card>
  );
};

interface PagesPickerProps {
  backClicked: () => void;
  onSave: (destination: Destination) => void;
}
const PagesPicker: React.FC<PagesPickerProps> = ({ backClicked, onSave }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>();
  const [pages, setPages] = useState<Page[]>([]);
  const [pagesPagination, setPagesPagination] = useState<Paging | null>(null);
  const [selected, setSelected] = useState<number | null>(null);

  const getPages = () =>
    callFbApi<PageResponse>('/me/accounts?fields=id,name,picture,tasks');

  useEffect(() => {
    const fetchPages = async () => {
      setLoading(true);
      try {
        const pagesResponse = await getPages();
        setPages(
          pagesResponse.data.map((page) => ({
            ...page,
            disabled: page.tasks && !page.tasks.includes('CREATE_CONTENT'),
          }))
        );
        setPagesPagination(pagesResponse.paging);
      } catch (err) {
        strogging.error('fetchPages-exception', errorUtil.errorPayload(err));
        setError('Failed to load pages. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchPages();
  }, []);

  const handleSelect = (index: number) => {
    if (selected === index) {
      setSelected(null);
    } else {
      setSelected(index);
    }
  };

  const morePagesClicked = () => {
    if (!pagesPagination) {
      return;
    }
    axios.get(pagesPagination.next).then((res) => {
      const newPages = res.data.map((page: Page) => ({
        ...page,
        label: page.name,
        value: page.id,
      }));
      setPages([...pages, ...newPages]);
      setPagesPagination(res.data.paging);
    });
  };

  return (
    <Card round={false} elevation="none" fill skeleton={loading}>
      <CardHeader direction="row" pad="small" align="center" justify="between">
        <Box alignSelf="start" onClick={backClicked}>
          <LinkPrevious size="24px" />
        </Box>
        <Box justify="center" align="center">
          <Text alignSelf="center" size="large" weight="bold">
            Stream to your page
          </Text>
        </Box>
        <Box width="24px" />
      </CardHeader>
      <CardBody fill>
        <Box
          pad="medium"
          border={{
            color: 'light-2',
            size: 'small',
            style: 'solid',
            side: 'bottom',
          }}
          flex={{ grow: 0, shrink: 0 }}
          gap="small"
        >
          <Text weight="bold">Choose a page</Text>
          <Text size="medium">
            Don&apos;t see your page? Make sure you are an Admin, not just
            Editor or Moderator.
          </Text>
        </Box>
        <Box pad="medium" overflow="auto" fill>
          {error && <Text color="status-error">{error}</Text>}
          {pages.map((page, index) => (
            <Box
              key={`option-${index + 1}`}
              border={{
                color: 'light-2',
                size: 'small',
                style: 'solid',
                side: 'bottom',
              }}
              flex={{ grow: 0, shrink: 0 }}
              pad={{ vertical: 'small' }}
            >
              <Button
                onClick={() => handleSelect(index)}
                active={selected === index}
                disabled={page.disabled}
              >
                <Box
                  direction="row"
                  gap="small"
                  pad="small"
                  round="small"
                  border={selected === index ? { color: 'brand' } : undefined}
                  align="center"
                  background={selected === index ? 'light-2' : undefined}
                >
                  <Box flex={{ shrink: 0, grow: 0 }}>
                    {page.picture ? (
                      <Avatar src={page.picture.data.url} />
                    ) : (
                      <Avatar background="brand">
                        {page.name ? page.name.substring(0, 2) : 'XY'}
                      </Avatar>
                    )}
                  </Box>
                  <Text
                    size="large"
                    color={page.disabled ? 'gray-2' : undefined}
                  >
                    {page.name}
                  </Text>
                </Box>
              </Button>
            </Box>
          ))}
          {pagesPagination?.next ? (
            <Button
              secondary
              pad="small"
              label="Load more..."
              onClick={() => {
                morePagesClicked();
              }}
            />
          ) : null}
        </Box>
      </CardBody>
      <CardFooter
        pad={{ horizontal: 'small', vertical: 'large' }}
        background="light-2"
      >
        <Button
          fill
          primary
          size="large"
          label="Next"
          onClick={() => {
            if (selected === null || selected === undefined) {
              return;
            }
            onSave({
              type: PAGE_TYPE,
              ...pages[selected],
            });
          }}
        />
      </CardFooter>
    </Card>
  );
};

interface DestinationLinkSuccessProps {
  onClose: () => void;
  destination: Destination;
  isLivestreamEligible: boolean;
}
const DestinationLinkSuccess: React.FC<DestinationLinkSuccessProps> = ({
  onClose,
  destination,
  isLivestreamEligible,
}) => {
  return (
    <Card round={false} elevation="none" fill>
      <CardHeader direction="row" pad="medium" align="center" justify="between">
        <Box alignSelf="start" onClick={() => {}}>
          <LinkPrevious size="24px" />
        </Box>
        <Box justify="center" align="center">
          <Text alignSelf="center" size="large" weight="bold">
            {`Stream to your ${destination.type}`}
          </Text>
        </Box>
        <Box width="24px" />
      </CardHeader>
      <CardBody fill pad="medium" gap="medium">
        <Text weight="bold">Success!</Text>
        {isLivestreamEligible ? (
          <Text>{`You’ve successfully connected your ${destination.type}.`}</Text>
        ) : (
          <Text>
            {`Effective June 10, 2024, Facebook's livestreaming policy will prevent new pages (less than 60 days old) and pages with fewer than 100 followers from streaming directly. No worries! We'll automatically post your sidelineLIVE stream link to your Facebook page until you reach 100 followers`}{' '}
            <Anchor href="https://www.facebook.com/business/help/216491699144904">
              Learn more.
            </Anchor>
          </Text>
        )}
        <Box
          direction="row"
          gap="small"
          pad="small"
          round="small"
          align="center"
        >
          <Box flex={{ shrink: 0, grow: 0 }}>
            {destination.picture ? (
              <Avatar src={destination.picture.data.url} />
            ) : (
              <Avatar background="brand">
                {destination.name ? destination.name.substring(0, 2) : 'XY'}
              </Avatar>
            )}
          </Box>
          <Text size="large" weight="bold">
            {destination.name}
          </Text>
        </Box>
        {isLivestreamEligible && (
          <Text>{`You can now livestream to this ${destination.type}`}</Text>
        )}
      </CardBody>
      <CardFooter
        pad={{ horizontal: 'small', vertical: 'large' }}
        background="light-2"
      >
        <Button
          fill
          primary
          size="large"
          label="Finish"
          onClick={() => {
            onClose();
          }}
        />
      </CardFooter>
    </Card>
  );
};

const PageLinkFailure: React.FC<{
  page: Destination;
  setStep: (step: number) => void;
  error: ApiError;
}> = ({ page, setStep, error }) => {
  return (
    <Card round={false} elevation="none" fill>
      <CardHeader direction="row" pad="medium" align="center" justify="between">
        <Box
          alignSelf="start"
          onClick={() => {
            setStep(2);
          }}
        >
          <LinkPrevious size="24px" />
        </Box>
        <Box justify="center" align="center">
          <Text alignSelf="center" size="large" weight="bold">
            Your page was not linked
          </Text>
        </Box>
        <Box width="24px" />
      </CardHeader>
      <CardBody fill pad="medium" gap="medium">
        <Box
          direction="row"
          gap="small"
          pad="small"
          round="small"
          align="center"
        >
          <Box flex={{ shrink: 0, grow: 0 }}>
            {page.picture ? (
              <Avatar src={page.picture.data.url} />
            ) : (
              <Avatar background="brand">
                {page.name ? page.name.substring(0, 2) : 'XY'}
              </Avatar>
            )}
          </Box>
          <Text size="large" weight="bold">
            {page.name}
          </Text>
        </Box>
        <Text>{error.message}</Text>
      </CardBody>
      <CardFooter
        pad={{ horizontal: 'small', vertical: 'large' }}
        background="light-2"
      >
        <Button
          fill
          primary
          size="large"
          label="Go back"
          onClick={() => {
            setStep(2);
          }}
        />
      </CardFooter>
    </Card>
  );
};

interface Props {
  teamId: string;
  onClose: () => void;
  onLinked: (linkedChannel: dtos.PublishFb) => void;
}

const FacebookDestinationPickerV2: React.FC<Props> = ({
  teamId,
  onClose,
  onLinked,
}) => {
  const [destinationType, setDestinationType] = useState<string | null>(null);

  const [error, setError] = useState<string | null>();
  const [linkError, setLinkError] = useState<ApiError>();

  const [destination, setDestination] = useState<Destination | null>(null);
  const [step, setStep] = useState(1); // consolidate into steps and use named constants for steps
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLivestreamEligible, setIsLivestreamEligible] = useState(false);

  const onSave = async (d: Destination) => {
    const authResponse = await getFacebookAuthResponse();

    if (authResponse) {
      axios
        .post('/api/facebook_auth', {
          authResponse,
          linkedChannel: d,
          teamId,
        })
        .then((response) => {
          analytics.track('Streaming Destination Linked', {
            destinationType: 'Facebook',
            destinationSubtype: d.type,
            teamId,
          });

          // TODO: implement some sort of warning for token expiration
          // onComplete();
          // updateLinkedChannel(selectedChannel);
          // const { accessTokenExpirationTs } = response.data;
          // const ts = Date.now();

          // if (ts / 1000 > accessTokenExpirationTs - expirationThreshold) {
          //   const now = DateTime.fromMillis(ts);
          //   const expiration = DateTime.fromSeconds(accessTokenExpirationTs);
          //   const daysUntilExpiration = Math.round(
          //     expiration.diff(now, ['days']).toObject().days
          //   );

          //   setShowExpiration({ daysUntilExpiration });
          // }
          // setLinkingError(null);
          // setLinkingLoading(false);
          setShowSuccess(true);
          setDestination(d);
          setStep(3);
          setLinkError(undefined);
          const publishFb = response.data.publishFb;
          onLinked(publishFb);
          setIsLivestreamEligible(response.data.isLivestreamEligible);
        })
        .catch((e) => {
          Sentry.captureException(e);
          const apiError = new ApiError(e);
          strogging.error('Failed to link Facebook', {
            message: apiError.message,
            details: apiError.details,
          });
          setDestination(d);
          setStep(3);
          setLinkError(apiError);
        });
    }
  };

  const backClicked = () => {
    setDestinationType(null);
    setStep(1);
  };

  useEffect(() => {
    if (destinationType === PAGE_TYPE) {
      setError(null);
    }
  }, [destinationType]);

  return (
    <Box gap="small" fill>
      <>
        {!destinationType && step === 1 && (
          <DestinationTypePicker
            onClose={onClose}
            setDestinationType={setDestinationType}
            setStep={setStep}
          />
        )}
        {destinationType === PROFILE_TYPE && step === 2 && (
          <ProfilePicker backClicked={backClicked} onSave={onSave} />
        )}
        {destinationType === PAGE_TYPE && step === 2 && (
          <PagesPicker backClicked={backClicked} onSave={onSave} />
        )}
        {showSuccess && destination && step === 3 && (
          <DestinationLinkSuccess
            destination={destination}
            onClose={onClose}
            isLivestreamEligible={isLivestreamEligible}
          />
        )}
        {linkError &&
          destination &&
          destination.type === PAGE_TYPE &&
          step === 3 && (
            <PageLinkFailure
              page={destination}
              setStep={setStep}
              error={linkError}
            />
          )}
        {error && <Text color="status-error">{JSON.stringify(error)}</Text>}
      </>
    </Box>
  );
};

export default observer(withFirebase(FacebookDestinationPickerV2));
