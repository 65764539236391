// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import {
  MdOutlineSportsBasketball,
  MdOutlineSportsBaseball,
  MdOutlineSportsHockey,
  MdOutlineSportsFootball,
  MdOutlineSportsRugby,
  MdOutlineSportsSoccer,
  MdOutlineSportsVolleyball,
} from 'react-icons/md';
import { GiWaterPolo } from 'react-icons/gi';

import LacrosseIcon from '../helpers/LacrosseIcon';

const MEVO = 'Mevo';
const MEVO_SINGLE = 'Mevo Single';
const MEVO_MULTI = 'Mevo Multicam';
const GOPRO = 'GoPro';
const SMARTPHONE = 'Smart phone / Tablet';
const SMARTPHONE_LEGACY = 'Smart phone';
const SIDELINE_APP = 'SidelineHD App';

const SIDELINE_APP_URLS = {
  team:
    process.env.NODE_ENV === 'production'
      ? 'https://sidelinehd.com/app/golive/team'
      : '/app/golive/team',
  teamCustomScheme: 'sidelinehd://golive/team',
  youtubeconnectCustomScheme: 'sidelinehd://youtubeconnect',
  appstoreLink: 'https://apps.apple.com/us/app/sidelinehd/id6469359134',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.sidelinehd.app',
};

const GOPRO_78910 = 'GoPro HERO7/8/9/10';
const GOPRO_11 = 'GoPro HERO11';
const GEO_US_CA = 'US West (CA)';
const GEO_US_TX = 'US Central (TX)';
const GEO_US_NY = 'US East (NY)';

const IPHONE = 'iPhone';
const ANDROID = 'Android';

// <IoBaseballOutline />
// sports
const BASEBALL = {
  display: 'Baseball',
  key: 'baseball',
  icon: <MdOutlineSportsBaseball />,
};
const BASKETBALL = {
  display: 'Basketball',
  key: 'basketball',
  icon: <MdOutlineSportsBasketball />,
};
const FIELD_HOCKEY = {
  display: 'Field Hockey',
  key: 'field_hockey',
  icon: <MdOutlineSportsHockey />,
};
const FOOTBALL = {
  display: 'Football',
  key: 'football',
  icon: <MdOutlineSportsFootball />,
};
const HOCKEY = {
  display: 'Hockey',
  key: 'hockey',
  icon: <MdOutlineSportsHockey />,
};
const LACROSSE = {
  display: 'Lacrosse',
  key: 'lacrosse',
  icon: <LacrosseIcon />,
};
const RUGBY = {
  display: 'Rugby',
  key: 'rugby',
  icon: <MdOutlineSportsRugby />,
};
const SOCCER = {
  display: 'Soccer',
  key: 'soccer',
  icon: <MdOutlineSportsSoccer />,
};
const SOFTBALL = {
  display: 'Softball',
  key: 'softball',
  icon: <MdOutlineSportsBaseball />,
};
const VOLLEYBALL = {
  display: 'Volleyball',
  key: 'volleyball',
  icon: <MdOutlineSportsVolleyball />,
};
const WATER_POLO = {
  display: 'Water Polo',
  key: 'water_polo',
  icon: <GiWaterPolo />,
};

const BBSB_SPORTS = [BASEBALL.display, SOFTBALL.display];
const BBSB_SPORTS_KEYS = [BASEBALL.key, SOFTBALL.key];
const LEGACY_SPORTS = [BASEBALL.display, SOFTBALL.display, VOLLEYBALL.display];

const COMBINED_BBSB_KEY = 'bbsb';

const GENERIC_SPORTS_KEYS = [
  BASKETBALL.key,
  FIELD_HOCKEY.key,
  FOOTBALL.key,
  HOCKEY.key,
  LACROSSE.key,
  RUGBY.key,
  SOCCER.key,
  VOLLEYBALL.key,
  WATER_POLO.key,
];

const ALL_SPORTS = [
  BASEBALL,
  BASKETBALL,
  FIELD_HOCKEY,
  FOOTBALL,
  HOCKEY,
  LACROSSE,
  RUGBY,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
  WATER_POLO,
];

const CURRENT_SPORTS = [
  BASEBALL,
  BASKETBALL,
  HOCKEY,
  LACROSSE,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
  WATER_POLO,
];

const SPORTS_MAP = {
  [BASEBALL.key]: BASEBALL,
  [BASKETBALL.key]: BASKETBALL,
  [FIELD_HOCKEY.key]: FIELD_HOCKEY,
  [HOCKEY.key]: HOCKEY,
  [LACROSSE.key]: LACROSSE,
  [RUGBY.key]: RUGBY,
  [SOCCER.key]: SOCCER,
  [SOFTBALL.key]: SOFTBALL,
  [VOLLEYBALL.key]: VOLLEYBALL,
  [WATER_POLO.key]: WATER_POLO,

  [BASEBALL.display]: BASEBALL,
  [BASKETBALL.display]: BASKETBALL,
  [FIELD_HOCKEY.display]: FIELD_HOCKEY,
  [HOCKEY.display]: HOCKEY,
  [LACROSSE.display]: LACROSSE,
  [RUGBY.display]: RUGBY,
  [SOCCER.display]: SOCCER,
  [SOFTBALL.display]: SOFTBALL,
  [VOLLEYBALL.display]: VOLLEYBALL,
  [WATER_POLO.display]: WATER_POLO,
};

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an email address to
  this social account already exists. Try to log in from
  that account instead.
`;

export {
  MEVO,
  MEVO_SINGLE,
  MEVO_MULTI,
  GOPRO,
  SMARTPHONE,
  SMARTPHONE_LEGACY,
  GOPRO_78910,
  GOPRO_11,
  SIDELINE_APP,
  SIDELINE_APP_URLS,
  IPHONE,
  ANDROID,
  GEO_US_CA,
  GEO_US_TX,
  GEO_US_NY,
  BASEBALL,
  BASKETBALL,
  FIELD_HOCKEY,
  FOOTBALL,
  HOCKEY,
  LACROSSE,
  RUGBY,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
  WATER_POLO,
  BBSB_SPORTS,
  BBSB_SPORTS_KEYS,
  LEGACY_SPORTS,
  SPORTS_MAP,
  GENERIC_SPORTS_KEYS,
  COMBINED_BBSB_KEY,
  ALL_SPORTS,
  CURRENT_SPORTS,
  ERROR_MSG_ACCOUNT_EXISTS,
};
