// Packages
import React from 'react';

// Types
import { AdminToolsRelabelClipsProps } from '../admin.types';

const AdminToolsRelabelClips: React.FC<AdminToolsRelabelClipsProps> = ({
  clip,
  oldLabel,
  newLabel,
  onSetStep,
}) => {
  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '1rem',
      }}
    >
      <div>
        <strong>Nick&apos;s clip IDs [Original label]</strong>:
        <ul>
          <li>4IcyTVP20PqCfGTH [single]</li>
          <li>BElgrjnpuUaGpsyh [single]</li>
          <li>rtfbEfHvriaZajaN [single]</li>
        </ul>
      </div>
      <ul
        style={{
          display: 'flex',
          listStyle: 'none',
          marginLeft: 0,
          padding: 0,
          justifyContent: 'space-between',
        }}
      >
        <li>
          <button onClick={() => onSetStep(1)}>Step 01</button>
        </li>
        <li>
          <button onClick={() => onSetStep(2)}>Step 02</button>
        </li>
        <li>
          <button onClick={() => onSetStep(3)}>Step 03</button>
        </li>
      </ul>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!clip ? (
          'No clip selected'
        ) : (
          <div>
            <ul>
              <li>
                <strong>Clip label (old)</strong>: {oldLabel}
              </li>
              <li>
                <strong>Clip label (new)</strong>: {newLabel}
              </li>
              <li>
                <strong>Clip ID (preview)</strong>: {clip.refPreview}
              </li>
              <li>
                <strong>Clip ID (full)</strong>: {clip.refFull}
              </li>
              <li>
                <strong>Clip sport</strong>: {clip.game.sportType}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminToolsRelabelClips;
