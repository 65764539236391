import React, { useCallback } from 'react';
import { Box, Text, Card, Button } from 'grommet';
import { MdAccountCircle } from 'react-icons/md';
import SHDLink from '../../../helpers/SHDLink';
import { More, View } from 'grommet-icons';
import { PlayerCardProps } from '../admin.types';
import axios from '../../../helpers/axios';
import { shd } from '@shd/jslib/models';

const PlayerCard: React.FC<PlayerCardProps> = ({
  player,
  playerManagerId,
  setShowConfirmation,
  setCurrentAction,
  setPlayers,
}) => {
  const removePlayerManager = useCallback(
    (playerId: string, managerId: string) => {
      axios
        .put(`/api/admin/player/${playerId}/remove_player_manager`, {
          user_id: managerId,
        })
        .then(async (response) => {
          if (response.status === 200) {
            setPlayers &&
              setPlayers((prevPlayers: shd.SQLUserPlayer[]) =>
                prevPlayers?.filter(
                  (p: shd.SQLUserPlayer) => p.id !== player.id
                )
              );
            setShowConfirmation(false);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const confrimRemovePlayerManager = useCallback(() => {
    setCurrentAction({
      action: () => removePlayerManager(player.id, playerManagerId),
      text: 'Are you sure you want to remove this player manager?',
      actionName: 'Unclaim Player',
    });
    setShowConfirmation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card key={player.id} pad="small" gap="small">
      <Box direction="row" justify="between">
        <Box direction="row" gap="small" align="center">
          <Box>
            <MdAccountCircle />
          </Box>
          <Text weight="bold">{player.name}</Text>
        </Box>
        <Box direction="row" gap="large">
          <SHDLink to={`/${player.handle}`} target="_blank">
            <View />
          </SHDLink>
          <SHDLink to={`/admin/player/${player.id}`} target="_blank">
            <More />
          </SHDLink>
        </Box>
      </Box>
      <Box>
        <Text size="small">{player.id}</Text>
        <Text size="small">{`/${player.handle}`}</Text>
      </Box>
      <Box>
        <Button
          primary
          label="Unclaim Player"
          onClick={confrimRemovePlayerManager}
        />
      </Box>
    </Card>
  );
};

export default PlayerCard;
