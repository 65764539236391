// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const ageUOptions = [
  { label: '18U', value: 18 },
  { label: '17U', value: 17 },
  { label: '16U', value: 16 },
  { label: '15U', value: 15 },
  { label: '14U', value: 14 },
  { label: '13U', value: 13 },
  { label: '12U', value: 12 },
  { label: '11U', value: 11 },
  { label: '10U', value: 10 },
  { label: '9U', value: 9 },
  { label: '8U', value: 8 },
  { label: '7U', value: 7 },
  { label: '6U or younger', value: 6 },
  { label: 'Adults', value: 99 },
];

const schoolAgeLevelOptions = [
  { label: 'College Varsity', value: 'College' },
  { label: 'HS Varsity', value: 'Varsity' },
  { label: 'HS Junior Varsity', value: 'Junior Varsity' },
  { label: 'HS Freshman', value: 'Freshman' },
  { label: 'Middle School', value: 'Middle School' },
  { label: 'Other', value: 'Other team' },
];

const teamTypeOptions = [
  { label: 'Travel Team', value: 'Travel team' },
  { label: 'Club Team', value: 'Club team' },
  { label: 'School Team', value: 'School team' },
  { label: 'Rec Team', value: 'Rec team' },
];

const leagueTypeOptions = [
  { label: 'Adult League', value: 'Adult League' },
  { label: 'Youth League', value: 'Youth League' },
  { label: 'Other League', value: 'Other League' },
];


const seasonOptions = [
    { label: 'Spring 2024', value: 20241 },
    { label: 'Summer 2024', value: 20242 },
    { label: 'Fall 2024', value: 20243 },
    { label: 'Winter 2024', value: 20244 },
];

const embedNameOptions = [
  { label: 'Yes', value: 2 },
  { label: 'Initial only', value: 1 },
  { label: 'No', value: 0 },
];

const embedImageOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const gradOptions = [...Array(18).keys()]
  .map((n) => {
    const year = 2021 + n;
    const yearLabel = year.toString();
    return { label: yearLabel, value: year };
  })
  .concat({ label: "Don't display", value: 0 });

const bbsbPositionOptions = [
  'P',
  'C',
  '1B',
  '2B',
  '3B',
  'SS',
  'IF',
  'OF',
  'CF',
  'LF',
  'RF',
  'UTIL',
]
  .map((pos) => ({ label: pos, value: pos }))
  .concat({ label: "Don't display", value: '' });

const vbPositionOptions = [
  'Outside Hitter',
  'Opposite Hitter',
  'Middle Blocker',
  'Setter',
  'Libero',
  'Defensive Specialist',
  'Serving Specialist',
]
  .map((pos) => ({ label: pos, value: pos }))
  .concat({ label: "Don't display", value: '' });

const basketballPositionOptions = [
  'Point Guard',
  'Shooting Guard',
  'Small Forward',
  'Power Forward',
  'Center',
]
  .map((pos) => ({ label: pos, value: pos }))
  .concat({ label: "Don't display", value: '' });

const soccerPositionOptions = [
  'Goalkeeper',
  'Center back',
  'Wing back',
  'Center Midfielder',
  'Winger',
  'Striker',
]
  .map((pos) => ({ label: pos, value: pos }))
  .concat({ label: "Don't display", value: '' });

const handednessOptions = [
  { label: 'Right', value: 'R' },
  { label: 'Left', value: 'L' },
  { label: 'Switch', value: 'S' },
  { label: "Don't display", value: '' },
];

const heightOptions = [...Array(36).keys()]
  .map((n) => {
    const inches = 84 - n;
    const feet = Math.floor(inches / 12);
    const inchesRemainder = inches - feet * 12;
    const heightString = `${feet}'${inchesRemainder}"`;
    return { label: heightString, value: heightString };
  })
  .concat({ label: "Don't display", value: '' });

const coachPositionOptions = [
  { label: 'Head coach', value: 'head' },
  { label: 'Assistant coach', value: 'assistant' },
];

const batBrands = [
  'Axe Bat',
  'B45',
  'BamBooBat',
  'Chandler',
  'DeMarini',
  'Easton Sports',
  'Louisville Slugger',
  'Marucci',
  'MaxBat',
  'Mizuno',
  'Rawlings',
  'RIP-IT',
  'Sam Bat',
  'StringKing',
  'TRUE',
  'Tucci Lumber Co',
  'Victus',
  'Warstic',
];

const mlbTeams = [
  { id: 109, name: 'Arizona Diamondbacks' },
  { id: 144, name: 'Atlanta Braves' },
  { id: 110, name: 'Baltimore Orioles' },
  { id: 111, name: 'Boston Red Sox' },
  { id: 145, name: 'Chicago White Sox' },
  { id: 112, name: 'Chicago Cubs' },
  { id: 113, name: 'Cincinnati Reds' },
  { id: 114, name: 'Cleveland Guardians' },
  { id: 115, name: 'Colorado Rockies' },
  { id: 116, name: 'Detroit Tigers' },
  { id: 117, name: 'Houston Astros' },
  { id: 118, name: 'Kansas City Royals' },
  { id: 108, name: 'Los Angeles Angels' },
  { id: 119, name: 'Los Angeles Dodgers' },
  { id: 146, name: 'Miami Marlins' },
  { id: 158, name: 'Milwaukee Brewers' },
  { id: 142, name: 'Minnesota Twins' },
  { id: 147, name: 'New York Yankees' },
  { id: 121, name: 'New York Mets' },
  { id: 133, name: 'Oakland Athletics' },
  { id: 143, name: 'Philadelphia Phillies' },
  { id: 134, name: 'Pittsburgh Pirates' },
  { id: 135, name: 'San Diego Padres' },
  { id: 137, name: 'San Francisco Giants' },
  { id: 136, name: 'Seattle Mariners' },
  { id: 138, name: 'St. Louis Cardinals' },
  { id: 139, name: 'Tampa Bay Rays' },
  { id: 140, name: 'Texas Rangers' },
  { id: 141, name: 'Toronto Blue Jays' },
  { id: 120, name: 'Washington Nationals' },
];

export {
  ageUOptions,
  schoolAgeLevelOptions,
  seasonOptions,
  embedNameOptions,
  embedImageOptions,
  gradOptions,
  bbsbPositionOptions,
  vbPositionOptions,
  basketballPositionOptions,
  soccerPositionOptions,
  handednessOptions,
  heightOptions,
  coachPositionOptions,
  batBrands,
  mlbTeams,
  teamTypeOptions,
  leagueTypeOptions
};
