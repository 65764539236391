// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box, Text } from 'grommet';

import { PromptText, TextContainer } from '../../styles';
import { PhoneOption } from '../style';

const ConfirmationStep = ({ selectedPhone }) => {
  const phoneLabel = `(XXX) XXX - XX${selectedPhone.slice(-2)}`;
  return (
    <Box gap="medium">
      <TextContainer>
        <PromptText color="dark-1">
          Let&apos;s verify your identity. Are any of the following numbers yours?
        </PromptText>
      </TextContainer>
      <PhoneOption
        background="secondary-1"
      >
        <Text>{phoneLabel}</Text>
      </PhoneOption>
    </Box>
  );
};

export default ConfirmationStep;
