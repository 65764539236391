import React, { useCallback, useState, useContext } from 'react';
import { Layer, Card, Text, FormField, TextInput, Button } from 'grommet';
import { ResponsiveContext } from 'grommet';
interface GameDeleteLayerProps {
  onClose: (close: boolean) => void;
  onDeleteGame: () => void;
}

const GameDeleteLayer: React.FC<GameDeleteLayerProps> = ({
  onClose,
  onDeleteGame,
}) => {
  const [activateDelete, setActivateDelete] = useState<boolean>(false);
  const screenSize = useContext(ResponsiveContext);
  const handleConfirmation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === 'DELETE') {
        setActivateDelete(true);
      } else {
        setActivateDelete(false);
      }
    },
    []
  );
  return (
    <Layer
      full="vertical"
      style={{
        height: '100dvh',
        justifyContent: 'center',
      }}
      animation={false}
      background={{
        color: screenSize === 'small' ? '#000' : '',
        opacity: 'medium',
      }}
    >
      <Card background="#FFF" margin="medium" pad="medium">
        <Text margin="small" weight="bold">
          Confirm your game deletion
        </Text>
        <Text margin="small" color="status-error">
          This action cannot be undone. Clips will also be deleted.
        </Text>
        <Text margin="small">To delete this game, type DELETE below.</Text>
        <FormField
          name="confirmation"
          htmlFor="confirmation-input"
          label={<Text size="small">Confirmation</Text>}
        >
          <TextInput
            id="confirmation-input"
            name="confirmation"
            placeholder="Type DELETE"
            size="medium"
            maxLength={6}
            onChange={handleConfirmation}
          />
        </FormField>
        <Button
          margin={{ vertical: 'small' }}
          disabled={!activateDelete}
          label="Delete Game"
          primary
          onClick={onDeleteGame}
        />
        <Button
          margin={{ vertical: 'small' }}
          label="Cancel"
          secondary
          onClick={() => onClose(false)}
        />
      </Card>
    </Layer>
  );
};

export default GameDeleteLayer;
