// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box } from 'grommet';

import { ButtonStyled } from './style';
import IconUSSSA from './IconUSSSA';
import IconAES from './IconAES';
import IconPerfectGame from './IconPerfectGame';
// import IconLeagueApps from './IconLeagueApps';
import { BBSB_SPORTS_KEYS, VOLLEYBALL } from '../../../../constants/strings';
import IconTeamSnap from './IconTeamSnap';

const FillButtons = ({
  value,
  setImportSource,
  setShowSearchModal,
  // setShowLALoginModal,
  setShowTSLoginModal,
}) => {
  const isUSSSA = BBSB_SPORTS_KEYS.includes(value.attrib_sportType);
  const isPerfectGame = BBSB_SPORTS_KEYS.includes(value.attrib_sportType);
  const isAES = VOLLEYBALL.key === value.attrib_sportType;

  const handleImportButton = (sourceData) => {
    setImportSource(sourceData);
    setShowSearchModal(true);
  };

  const handleTSButton = () => {
    setImportSource({ key: 'teamsnap', display: 'TeamSnap' });
    setShowTSLoginModal(true);
  };

  return (
    <Box>
      {isUSSSA && (
        <ButtonStyled
          color="#21346C"
          label="USSSA"
          icon={<IconUSSSA />}
          onClick={() => handleImportButton({ key: 'usssa', display: 'USSSA' })}
        />
      )}
      {isPerfectGame && (
        <ButtonStyled
          color="#231F20"
          label="Perfect Game"
          icon={<IconPerfectGame />}
          onClick={() =>
            handleImportButton({ key: 'pg', display: 'Perfect Game' })
          }
        />
      )}
      {/* <ButtonStyled
        color="#187D1D"
        label="LeagueApps"
        icon={<IconLeagueApps />}
        onClick={handleLAButton}
      /> */}
      {isAES && (
        <ButtonStyled
          color="#CD1F27"
          label="AES"
          icon={<IconAES />}
          onClick={() => handleImportButton({ key: 'aes', display: 'AES' })}
        />
      )}
      <ButtonStyled
        color="#222222"
        label="TeamSnap"
        icon={<IconTeamSnap />}
        onClick={handleTSButton}
      />
    </Box>
  );
};

export default FillButtons;
