/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Text, Heading } from 'grommet';
import * as Sentry from '@sentry/browser';
import {
  withRouter,
  useLocation,
  useParams,
  RouteComponentProps,
} from 'react-router-dom';
import { compose } from 'recompose';
import { UserContext } from '../../services/Session/context';
import withLayout from '../../helpers/withLayout';
import SHDButton from '../../components/SHD/Button';
import { isMobile, isiOS } from '../../helpers/browserDetect';
import { SIDELINE_APP_URLS } from '../../constants/strings';
import axios from '../../helpers/axios';
import { strogging } from '@shd/jslib/infra';
import { analytics } from '../../services/analytics';

interface Props extends RouteComponentProps {}
const AppGoliveTeamPage: React.FC<Props> = observer((props: Props) => {
  const { teamid: teamId } = useParams<{ teamid: string }>();
  const query = new URLSearchParams(useLocation().search);
  const statusKey = query.get('statusKey');

  if (isiOS() || isMobile.Android()) {
    return (
      <GoLiveMobile
        teamId={teamId}
        statusKey={statusKey}
        history={props.history}
      />
    );
  }
  return <GoLiveDesktop teamId={teamId} history={props.history} />;
});

export default compose<Props, Omit<Props, keyof RouteComponentProps>>(
  withLayout,
  withRouter
)(AppGoliveTeamPage);

const appUrl = (teamId: string, statusKey: string | null) =>
  `${SIDELINE_APP_URLS.teamCustomScheme}/${teamId}${
    statusKey ? `?statusKey=${statusKey}` : ''
  }`;

interface GoLivePlatformProps {
  teamId: string;
  statusKey?: string | null;
  history: RouteComponentProps['history'];
}
const GoLiveMobile: React.FC<GoLivePlatformProps> = ({ teamId, history }) => {
  const platform: 'ios' | 'android' = isiOS() ? 'ios' : 'android';
  const userStore = useContext(UserContext);
  const team = userStore.userTeams.find((t) => t._id === teamId);
  const teamName =
    team?.nameLong || team?.nameMed || team?.nameShort || 'your team';
  const [appInvoked, setAppInvoked] = useState(false);
  const invokeApp = async () => {
    // create a new pre-authorization just in case its been more than 30s
    // since the last one was created
    try {
      const result = await axios.post('/api/remote_login/preauthorize', {});
      window.location.href = appUrl(teamId, result.data.statusKey);
      setAppInvoked(true);
    } catch (e) {
      strogging.exception('invokeApp:exception', e);
    }
  };
  const exitPage = () => {
    history.goBack();
  };
  return (
    <Box margin="medium">
      {appInvoked ? (
        <Box direction="row" pad="large" justify="center" width="100%">
          <SHDButton
            onClick={exitPage}
            mixtrack={['Launched App Return Home', { teamId }]}
            label={`Return to ${teamName} page`}
            size="large"
            primary
          />
        </Box>
      ) : (
        <>
          <Heading color="dark-1" level="3">
            Almost there!
          </Heading>
          <Text>
            Download the sidelineHD camera app to get crystal clear streams from
            your mobile device.
          </Text>
          <Box direction="row" pad="large" justify="center" width="100%">
            <SHDButton
              onClick={() => {
                window.location.href =
                  platform === 'ios'
                    ? SIDELINE_APP_URLS.appstoreLink
                    : SIDELINE_APP_URLS.googlePlayLink;
              }}
              mixtrack={['Appstore Badge', { teamId }]}
            >
              {platform === 'ios' ? (
                <img
                  src="/app-store-badge-white.svg"
                  alt="Download on the App Store"
                  width="183px"
                  style={{
                    display: 'block',
                    paddingLeft: '2.5px',
                    paddingRight: '2.5px',
                  }}
                />
              ) : (
                <img
                  src="/en_generic_rgb_wo_60.png"
                  alt="Get it on Google Play"
                  width={172}
                  style={{
                    display: 'block',
                    paddingLeft: '2.5px',
                    paddingRight: '2.5px',
                  }}
                />
              )}
            </SHDButton>
          </Box>

          <Text>
            Once the app is installed, click the button below to start streaming{' '}
            {teamName}
          </Text>
          <Box direction="row" pad="large" justify="center" width="100%">
            <SHDButton
              onClick={invokeApp}
              mixtrack={['Launch App', { teamId }]}
              label="Connect"
              size="large"
              primary
            />
          </Box>
          <Text>
            (This app replaces sidelineCAM. Can&apos;t change right now?{' '}
            <a
              href="https://sidelinehd.com/sidelinecam"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                analytics.track('Keep using sidelineCAM');
                axios
                  .get(
                    `/api/team/${userStore.selectedTeam?._id}/browser_camera`
                  )
                  .then((response) => {
                    if (response.data.isSuccess) {
                      history.push(response.data.devWebRtcApp);
                    } else {
                      strogging.error(response.data.errorTxt);
                    }
                  })
                  .catch((e) => {
                    Sentry.captureException(e);
                  });
              }}
            >
              Click here to keep using sidelineCAM
            </a>
            )
          </Text>
        </>
      )}
    </Box>
  );
};

const GoLiveDesktop: React.FC<GoLivePlatformProps> = ({ history }) => (
  <Box margin="medium">
    <Heading color="dark-1" level="3">
      Almost there!
    </Heading>
    <Text>Currently we only support iOS and Android devices.</Text>
    <Box direction="row" pad="large" justify="center" width="100%">
      <SHDButton
        onClick={() => history.goBack()}
        mixtrack={['Go back']}
        primary
        color="tertiary-0"
        size="large"
        label="Go back"
      />
    </Box>
  </Box>
);
