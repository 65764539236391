import { dtos } from '@shd/jslib/models';
import { AxiosResponse } from 'axios';
import axios from '../helpers/axios';
import * as Sentry from '@sentry/react';
import { strogging, telemetry } from '@shd/jslib/infra';

export function initTelemetry() {
  if (
    process.env.NODE_ENV === 'production' ||
    JSON.parse(process.env.REACT_APP_ENABLE_DEV_TELEMETRY || 'false') === true
  ) {
    telemetry.enableTelemetry(sendTelemetry, 'web', {
      minLoggingLevel:
        process.env.NODE_ENV === 'production'
          ? strogging.LoggingLevel.LOG
          : strogging.LoggingLevel.DEBUG,
      frequencyMillis: process.env.NODE_ENV === 'production' ? 60_000 : 30_000,
    });
    telemetry.updateTelemetryTags({
      agent: window?.navigator?.userAgent,
    });
    strogging.log(
      process.env.NODE_ENV === 'production'
        ? 'Enabled telemetry'
        : 'Enabled dev telemetry'
    );

    document.addEventListener('visibilitychange', function logData() {
      strogging.log('visibilitychange', {
        visibilityState: document.visibilityState,
      });
      if (document.visibilityState !== 'visible') {
        telemetry.flushTelemetry(finalTelemetry);
      }
    });
    document.addEventListener('pagehide', () => {
      strogging.log('pagehide', { visibilityState: document.visibilityState });
      telemetry.flushTelemetry(finalTelemetry);
    });
  }
}
export async function sendTelemetry(request: dtos.SendTelemetryRequest) {
  try {
    const response = await axios.post<
      dtos.SendTelemetryResponse,
      AxiosResponse<dtos.SendTelemetryResponse>,
      dtos.SendTelemetryRequest
    >('/api/logging/send_telemetry', request);
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}

export function finalTelemetry(
  request: dtos.SendTelemetryRequest
): Promise<dtos.SendTelemetryResponse> {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(
      `${process.env.REACT_APP_API_BASE_URL}/api/logging/send_telemetry`,
      JSON.stringify(request)
    );
  }
  return Promise.resolve({ result: 'success' });
}
