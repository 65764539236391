// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import MakeSubstitution from './MakeSubstitution';
import { ScoringContext } from './context';

const getOnDeckIndex = (lineup, index) => {
  if (lineup.length < index + 2) {
    // on deck batter is leadoff
    return 0;
  }
  return index + 1;
};

const OnDeckBatterSelector = observer(({ onClose = () => {} }) => {
  const scoringStore = useContext(ScoringContext);
  const { awayHome, lineupQueueIndexes, lineups, isQuickStartMode } =
    scoringStore;

  const lineup = lineups[awayHome];
  const originalIndex = lineupQueueIndexes[awayHome];
  const onDeckIndex = getOnDeckIndex(lineup, originalIndex);

  if (isQuickStartMode && lineup.length < originalIndex + 2) {
    return (
      <MakeSubstitution isQuickStartModePrompt isOnDeck closeSubs={onClose} />
    );
  }

  return (
    <MakeSubstitution playerToSubOutIndex={onDeckIndex} closeSubs={onClose} />
  );
});

export default OnDeckBatterSelector;
