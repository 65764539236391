import {
  ADMIN_SEARCH,
  ADMIN_MERGE_PLAYERS,
  ADMIN_RELABEL_CLIPS,
} from '../../constants/routes';
import { AugmentedUser } from '../../services/Session/store';
import { Heading, Grid, Box } from 'grommet';
import { observer } from 'mobx-react';
import { withAuthorization } from '../../services/Session';
import AdminMenuItem from './AdminMenuItem';
import React from 'react';
import withLayout from '../../helpers/withLayout';
import styles from './Admin.module.css';

const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

const Admin: React.FC = observer(() => {
  return (
    <>
      <Box pad="medium" id="adminToolsHome" className={styles['admin-tools']}>
        <Heading color="dark-1" level="1" size="20px" margin="medium">
          Admin Tools
        </Heading>

        <Grid columns="100%" gap="0.5rem">
          <AdminMenuItem
            title="General Search"
            subtitle="Search for a player, team, or user."
            href={ADMIN_SEARCH}
          />
          <AdminMenuItem
            title="Merge Player Profiles"
            subtitle="Combine duplicate player profiles into a primary profile."
            href={ADMIN_MERGE_PLAYERS}
          />
          <AdminMenuItem
            title="Relabel Clips"
            subtitle="Change the outcome of any clip."
            href={ADMIN_RELABEL_CLIPS}
          />
        </Grid>
      </Box>
    </>
  );
});

export default withAuthorization(condition)(withLayout(Admin));
