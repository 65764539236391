// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Heading, Image, Layer, Text } from 'grommet';
import { withRouter } from 'react-router-dom';

import React, { useEffect, useState, useContext } from 'react';
import * as Sentry from '@sentry/browser';

import SHDButton from '../../components/SHD/Button';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import { isiOS } from '../../helpers/browserDetect';
import { UserContext } from '../../services/Session';
import PlayerHeader from './ClaimPlayer/PlayerHeader';
import PromptHeader from './ClaimPlayer/PromptHeader';
import PromptBody from './ClaimPlayer/PromptBody';
import PromptFooter from './ClaimPlayer/PrompFooter';

const ClaimPlayerLayer = ({ teamPlayerId, closeLayer }) => {
  const userStore = useContext(UserContext);

  const [team, setTeam] = useState();
  const [teamPlayer, setTeamPlayer] = useState();
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [isConfirmSelectedPhone, setIsConfirmSelectedPhone] = useState(false);
  const [enteredPhone, setEnteredPhone] = useState(null);

  const [error, setError] = useState();
  const [loading, toggleLoading] = useState(true);
  // 0 = not sent, 10 = sending, 20 = sent, 30 = error
  const [sendState, setSendState] = useState(0);

  useEffect(() => {
    axios
      .get(`/api/team_player/${teamPlayerId}/verify_claim_player`)
      .then(({ data: { teamPlayer: respTeamPlayer, team: respTeam } }) => {
        setTeamPlayer(respTeamPlayer);
        setTeam(respTeam);
        toggleLoading(false);

        if (
          respTeamPlayer.invitePhoneNumberList.includes(userStore.userPhone)
        ) {
          setSelectedPhone(userStore.userPhone);
        }
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        if (respError.response) {
          setError(respError);
        } else {
          setError('Something went wrong.');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset selected phone if user is entering a phone number
  useEffect(() => {
    if (enteredPhone) {
      setSelectedPhone(null);
    }
  }, [enteredPhone]);

  const sendMsg = () => {
    setSendState(10);

    let destinationPhoneNumber;
    let isRequest;

    if (selectedPhone && selectedPhone !== 'notfound') {
      destinationPhoneNumber = selectedPhone;
      isRequest = false;
    } else {
      destinationPhoneNumber = enteredPhone;
      isRequest = true;
    }

    axios
      .post(`/api/team_player/${teamPlayer._id}/verify_claim_player`, {
        teamPlayer,
        team,
        destination: destinationPhoneNumber,
        isRequest,
      })
      .then(() => setSendState(20))
      .catch((respError) => {
        Sentry.captureException(respError);
        setSendState(30);
        if (respError.response) {
          setError(respError.response.data.mesesage);
        } else {
          setError(
            "We couldn't text this phone number. Please contact your team admin to ensure your phone is entered correctly"
          );
        }
      });
  };

  const renderVerificationPrompt = () => (
    <Box fill="vertical" margin={{ top: 'large' }} pad="medium" gap="large">
      <PromptHeader teamPlayer={teamPlayer} />
      <PromptBody
        teamPlayer={teamPlayer}
        selectedPhone={selectedPhone}
        setSelectedPhone={setSelectedPhone}
        showPhoneInput={showPhoneInput}
        enteredPhone={enteredPhone}
        setEnteredPhone={setEnteredPhone}
        isConfirmSelectedPhone={isConfirmSelectedPhone}
      />
      <PromptFooter
        teamPlayer={teamPlayer}
        enteredPhone={enteredPhone}
        showPhoneInput={showPhoneInput}
        selectedPhone={selectedPhone}
        isConfirmSelectedPhone={isConfirmSelectedPhone}
        setIsConfirmSelectedPhone={setIsConfirmSelectedPhone}
        sendState={sendState}
        setShowPhoneInput={setShowPhoneInput}
        sendMsg={sendMsg}
      />
    </Box>
  );
  const sendSuccessBox = (
    <Box fill pad="medium">
      <Box pad="large">
        <Image
          fill="horizontal"
          margin={{ bottom: 'large' }}
          src="/confetti.png"
        />
        <Heading textAlign="center">
          We are sending you a verification link!
        </Heading>
        <Text textAlign="center">
          It may take a minute for us to reach you.
        </Text>
      </Box>
      <SHDButton
        primary
        color="tertiary-1"
        label="Open Messages"
        onClick={() => {
          if (isiOS()) {
            // deep link to sidelineHD convo in Messages app
            window.open('sms://+16502002250', '_blank');
          } else {
            // TODO: figure out how to deep link Android
            closeLayer();
          }
        }}
      />
    </Box>
  );

  useEffect(() => {
    if (selectedPhone) {
      setEnteredPhone(null);
    }
  }, [selectedPhone]);

  if (loading) {
    return (
      <Layer pad="large">
        <BasicLoader fullPage />
      </Layer>
    );
  }

  return (
    <Layer overflow="auto">
      <Box pad="medium">
        <PlayerHeader
          currentStep="2"
          buttonLabel="Cancel"
          buttonAction={closeLayer}
        />
      </Box>
      {!!error && <Text color="status-critical">{error}</Text>}
      {sendState !== 20 ? renderVerificationPrompt() : sendSuccessBox}
    </Layer>
  );
};

export default withRouter(ClaimPlayerLayer);
