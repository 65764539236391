// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const SELECT_SPORT = 'SELECT_SPORT';

export const FILL_INFO = 'FILL_INFO';
export const EXAMPLE_TEXT = 'EXAMPLE_TEXT';
export const EXAMPLE_CLIP = 'EXAMPLE_CLIP';

export const MANUAL_ENTRY_INDEX = 4;

export const MANUAL_ENTRY_NAME_LONG = 'MANUAL_ENTRY_NAME_LONG';
export const MANUAL_ENTRY_NAME_MED = 'MANUAL_ENTRY_NAME_MED';
export const MANUAL_ENTRY_NAME_TYPE = 'MANUAL_ENTRY_NAME_TYPE';
export const MANUAL_ENTRY_NAME_AGE_U = 'MANUAL_ENTRY_NAME_AGE_U';
export const MANUAL_ENTRY_NAME_AGE_LEVEL = 'MANUAL_ENTRY_NAME_AGE_LEVEL';
export const MANUAL_ENTRY_NAME_SEASON = 'MANUAL_ENTRY_NAME_SEASON';
export const MANUAL_ENTRY_NAME_LOCATION = 'MANUAL_ENTRY_NAME_LOCATION';
export const MANUAL_ENTRY_NAME_AGE = 'MANUAL_ENTRY_NAME_AGE';
export const MANUAL_ENTRY_NAME_LINK = 'MANUAL_ENTRY_NAME_LINK';

export const STEPS = [
  {
    index: 0,
    name: SELECT_SPORT,
  }, {
    index: 1,
    name: EXAMPLE_TEXT,
  }, {
    index: 2,
    name: EXAMPLE_CLIP,
  }, {
    index: 3,
    name: FILL_INFO,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_LONG,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_MED,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_TYPE,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_AGE_U,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_AGE_LEVEL,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_SEASON,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_LOCATION,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_AGE,
  }, {
    index: MANUAL_ENTRY_INDEX,
    name: MANUAL_ENTRY_NAME_LINK,
  }];

export const getTotalSteps = () => {
  const uniqueIndexes = new Set(STEPS.map((step) => step.index));
  return Array.from(uniqueIndexes).length;
};
