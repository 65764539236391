import {
  PaywallCtaModes,
  PaywallFeatureListItem,
  PaywallLockModes,
} from './paywall.types';

export const paywallFeaturesCopy: Array<PaywallFeatureListItem> = [
  {
    title: 'Full access',
    copy: 'for you to all player videos, including automatic highlight clipping and post-game play-by-plays across sidelineHD.',
  },
  {
    title: 'In-Game & Post-Game Alerts',
    copy: 'for up to 5 phone numbers',
  },
  {
    title: 'Public player\u0027s profile',
    copy: 'allowing anyone to watch and discover their highlights.',
  },
];

export const getPaywallCtaCopy = (
  annualPrice: string,
  mode: PaywallCtaModes
) => {
  let headline =
    'Unlock full access to all player highlights, hitscope and play-by-play clips.';
  const copy = `Only ${annualPrice}/year. Cancel anytime.`;

  if (mode === 'phone') {
    headline =
      'Unlock game time alerts and highlight texts for up to 5 phone numbers';
  } else if (mode === 'fullAccess') {
    headline =
      'Unlock all features and full access to all player highlights, and videos.';
  }
  return {
    headline,
    copy,
  };
};

export const getPaywallFinePrintCopy = (price: string) => {
  return `By subscribing you authorize Diamond Kinetics to charge ${price} on any payment method on your account, based on the terms, until you cancel. To avoid charges cancel up to 48 hours before billing date.`;
};

export const getPaywallLockCopy = (
  isPrivate: boolean,
  mode?: PaywallLockModes
) => {
  let headline =
    'Public highlights and videos are not currently available for this player.';
  const copy =
    'Pro access may be required based on your player or team affiliation.';
  const copyLede = 'Log in to your SidelineHD account to view.';
  const linkText = '';
  const linkUrl = '';

  switch (mode) {
    case 'hitscope':
      headline = 'Team HitScope is not available for public viewing.';
      break;
    case 'game':
      headline = 'Game page videos are not available when logged out.';
      break;
    default:
      break;
  }

  const paywallLockCopyIsPrivate = {
    headline,
    copy: '',
    copyLede: '',
    linkText: 'Learn more',
    linkUrl: 'https://sidelinehd.com/privacy',
  };

  const paywallLockCopyIsUnavailable = {
    headline,
    copy,
    copyLede,
    linkText,
    linkUrl,
  };

  if (isPrivate) {
    return paywallLockCopyIsPrivate;
  } else {
    return paywallLockCopyIsUnavailable;
  }
};
