// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref';

import Hls from 'hls.js';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import './clipVideoPlayer.css';

import * as Sentry from '@sentry/react';
import { isiOS } from './browserDetect';

const HLSPlayer = ({
  style = { width: '100%', maxWidth: '500px' },
  ...props
}) => {
  const [, setClips, clipsRef] = useState();
  const videoRef = useRef(null);

  const updateCurrentClip = (ts) => {
    let newClip;
    clipsRef.current.some((clip) => {
      if (ts > clip.startTs && ts < clip.endTs) {
        newClip = clip;
        props.onClipChanged(newClip);
        return true;
      }

      return false;
    });
  };

  useEffect(() => {
    setClips(props.clips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clips]);

  useEffect(() => {
    let hls;
    if (videoRef.current && props.clips.length > 0) {
      const video = videoRef.current;
      if (isiOS() && video.canPlayType('application/vnd.apple.mpegurl')) {
        const hlsMimeType = 'application/vnd.apple.mpegurl';
        video.type = hlsMimeType;
        video.src = `data:${hlsMimeType};base64,${btoa(props.m3u8Text)}`;
      } else if (Hls.isSupported()) {
        const enc = new TextEncoder('utf-8');
        const encoded = enc.encode(props.m3u8Text);
        // const blob = new Blob([encoded], { type: 'audio/mpegURL' });
        const blob = new Blob([encoded]);
        const blobUrl = URL.createObjectURL(blob);

        hls = new Hls();
        hls.loadSource(blobUrl);
        hls.attachMedia(video);
      } else {
        // eslint-disable-next-line no-console
        const error = "This is a legacy browser that doesn't support MSE";
        Sentry.captureException(error);

        const hlsMimeType = 'audio/x-mpegURL';
        video.type = hlsMimeType;
        video.src = `data:${hlsMimeType};base64,${btoa(props.m3u8Text)}`;
      }

      video.addEventListener('timeupdate', () => {
        const currentTs = video.currentTime;
        updateCurrentClip(currentTs);
      });
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, props.m3u8Text]);

  useEffect(() => {
    if (props.seekToClip) {
      videoRef.current.currentTime = props.seekToClip.startTs;
    }
  }, [props.seekToClip]);

  return <video controls playsInline ref={videoRef} style={style} />;
};

export default HLSPlayer;
