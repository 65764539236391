// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Button, Heading, Image, Layer, Text } from 'grommet';
import { Close, FormNextLink } from 'grommet-icons';
import React, { useEffect, useState } from 'react';

import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import Toast from '../../components/Toast';
import { analytics } from '../../services/analytics';

const SportsRecruitsAuthorizationModal = ({
  playerId,
  onClose,
  fromDeepLink,
}) => {
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState();
  const [srUrl, setSrUrl] = useState('');

  useEffect(() => {
    axios.get(`/api/sr/player/${playerId}/landing_page`).then((response) => {
      setSrUrl(response.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium">
        <Box direction="row" justify="between" fill="horizontal">
          <Heading level={4}>Connect to SportsRecruits</Heading>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box gap="medium">
          <Box gap="medium">
            <Text>SportsRecruits is the best way to get recruited.</Text>
            <Text>
              When you connect sidelineHD to SportsRecruits, your best plays
              will automatically show up in your SportsRecruits video library.
            </Text>
            <Box
              direction="row"
              gap="medium"
              justify="center"
              align="center"
              margin="medium"
            >
              <Image src="/logo192.png" width="48px" />
              <FormNextLink />
              <Image src="/sr_logo.png" width="48px" />
            </Box>
          </Box>
          <Text size="small">
            By continuing, you are authorizing sidelineHD to share certain
            profile info with SportsRecruits
          </Text>
          {toast && <Toast {...toast} />}
          <Button
            primary
            size="large"
            icon={loading && <BasicLoader size={32} />}
            disabled={loading}
            label="Connect to SportsRecruits"
            onClick={() => {
              // admin needs to authorize the app first
              analytics.track('Connect to SportsRecruits Clicked', {
                playerId,
                fromDeepLink,
              });

              const newWindow = window.open('', '_blank');

              setLoading(true);
              axios
                .post(`/api/sr/player/${playerId}/authorize`)
                .then((response) => {
                  setLoading(false);
                  if (response.data.isSuccess) {
                    newWindow.location = srUrl;

                    onClose();
                  } else {
                    setToast({
                      label: 'Failed to authorize. Please try again.',
                      background: 'status-critical',
                    });
                  }
                });
            }}
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default SportsRecruitsAuthorizationModal;
