// Packages
import React, { useCallback, useState } from 'react';
import { theFirebase } from '../../services/Firebase/firebase';
import * as ROUTES from '../../constants/routes';

// Components
import {
  Anchor,
  Button,
  Card,
  FormField,
  Layer,
  Text,
  TextInput,
} from 'grommet';

// Hooks
import useSettingsHooks from './settings.hooks';

// Types
import { SettingsDeleteAccountProps } from './types';

const SettingsDeleteAccount: React.FC<SettingsDeleteAccountProps> = ({
  userId,
}) => {
  // States
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [activateAccountDelete, setActivateAccountDelete] =
    useState<boolean>(false);

  const { deleteUserAccount } = useSettingsHooks();

  // Handlers
  const handleConfirmation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === 'DELETE') {
        setActivateAccountDelete(true);
      } else {
        setActivateAccountDelete(false);
      }
    },
    []
  );

  const handleClose = useCallback(() => {
    setIsDeleteModalOpen(false);
    setActivateAccountDelete(false);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteUserAccount(userId).then(async () => {
      await theFirebase().doSignOut();
      window.location.href = ROUTES.SIGN_IN;
    });
  }, [userId, deleteUserAccount]);

  return (
    <>
      <Anchor
        margin={{ top: '2rem' }}
        onClick={() => setIsDeleteModalOpen(true)}
        style={{ fontSize: '0.9375rem', opacity: 0.85 }}
      >
        Delete account
      </Anchor>

      {isDeleteModalOpen && (
        <Layer onEsc={() => handleClose()} onClickOutside={() => handleClose()}>
          <Card background="#FFF" margin="medium" pad="medium">
            <Text margin="small" weight="bold">
              Confirm the deletion of your account
            </Text>
            <Text margin="small" color="status-error">
              This action cannot be undone.
            </Text>
            <Text margin="small">
              To delete your account, type DELETE below.
            </Text>
            <FormField
              name="confirmation"
              htmlFor="confirmation-input"
              label={<Text size="small">Confirmation</Text>}
            >
              <TextInput
                id="confirmation-input"
                name="confirmation"
                placeholder="Type DELETE"
                size="medium"
                maxLength={6}
                onChange={handleConfirmation}
              />
            </FormField>
            <Button
              margin={{ vertical: 'small' }}
              disabled={!activateAccountDelete}
              label="Delete Account"
              primary
              onClick={() => handleDelete()}
            />
            <Button
              margin={{ vertical: 'small' }}
              label="Cancel"
              secondary
              onClick={() => handleClose()}
            />
          </Card>
        </Layer>
      )}
    </>
  );
};

export default SettingsDeleteAccount;
