// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {

  Text,
  TextInput,
  Box,
} from 'grommet';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import { scoringTutorialStepsAlt } from './ScoringTutorial';
import SHDButton from '../../components/SHD/Button';

const ValidateDeviceId = (props) => {
  const { onGameList } = props;
  const [deviceId, setDeviceId] = useState('');
  const [isIdValid, toggleIdValid] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [queryError, setQueryError] = useState(null);
  const [isLoading, toggleLoading] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setQueryError(null);
    if (deviceId.length === 10) {
      setValidationError(null);
      toggleIdValid(true);
    } else if (deviceId.length > 10) {
      toggleIdValid(false);
      setValidationError('Please enter your 10 character device ID.');
    } else {
      toggleIdValid(false);
    }
  }, [deviceId]);

  const validateId = () => {
    toggleLoading(true);
    axios.get(`/api/iscore/${deviceId}`)
      .then((response) => {
        toggleLoading(false);
        const { data } = response;
        if (data.isSuccess) {
          setQueryError(null);
          onGameList(deviceId, data.gameList);
        } else if (data.errors.includes('no-listed-games')) {
          setQueryError('We could not find your team. Please confirm your customer ID is correct, iScorecast is ON, and your team has at least one game.');
        } else {
          setQueryError('Could not find any games :(');
        }
      });
  };

  return (
    <Box>
      { step >= scoringTutorialStepsAlt.length - 1 && (
      <>
        <Text weight="bold" size="large">iScore customer ID</Text>
        <TextInput value={deviceId} onChange={(event) => setDeviceId(event.target.value)} />
        { validationError && <Text color="red">{validationError}</Text> }
        <BasicLoader
          size={100}
          loading={isLoading}
        />
        { queryError && <Text color="red">{queryError}</Text> }
      </>
      )}
      { scoringTutorialStepsAlt[step] }
      <Box
        direction="row"
        gap="small"
        justify="between"
        height={{ min: 'xsmall' }}
      >
        <SHDButton
          alignSelf="end"
          label="Back"
          onClick={() => setStep(step - 1)}
          style={{ visibility: step === 0 && 'hidden' }}
        />
        {step < scoringTutorialStepsAlt.length - 1 ? (
          <SHDButton
            alignSelf="end"
            primary
            label="Next"
            onClick={() => {
              window.scrollTo(0, 0);
              setStep(step + 1);
            }}
          />
        ) : (
          <SHDButton
            alignSelf="end"
            primary
            label="Submit"
            onClick={validateId}
            disabled={!isIdValid}
          />
        )}
      </Box>
    </Box>
  );
};

export default ValidateDeviceId;
