// Packages
import React from 'react';
import styles from '../Admin.module.css';

// Components
import { Box, Button } from 'grommet';

// Types
import { AdminStepButtonsProps } from '../admin.types';

const AdminStepButtons: React.FC<AdminStepButtonsProps> = ({
  backLabel,
  isFixed,
  isNextActive,
  nextLabel,
  onBack,
  onNext,
}) => {
  return (
    <Box
      className={`
      ${styles['admin-step-buttons']}
      ${isFixed ? styles['admin-step-buttons-fixed'] : ''}
      `}
    >
      <Button
        className={styles['admin-step-button']}
        label={backLabel}
        onClick={() => onBack()}
        secondary
      />

      <Button
        className={styles['admin-step-button']}
        disabled={!isNextActive}
        label={nextLabel}
        onClick={() => onNext()}
        primary
      />
    </Box>
  );
};

export default AdminStepButtons;
