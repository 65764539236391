// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { FormClose } from 'grommet-icons';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Button, Layer, Text } from 'grommet';
import axios from '../../helpers/axios';

import {
  TrackCardWrapper,
  TrackDragButton,
  TrackCardContent,
  TrackInfo,
  TrackTitle,
  TrackSub,
  TrackActions,
  Action,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from './styles';

import DragIcon from './DragIcon';
import EditClipButton from './EditClipButton';
import TrimClipButton from './TrimClipButton';

export default function VideoTrackCard({
  playlistId,
  playlistData,
  item,
  titleMain,
  titleSub,
  handleClick,
  index,
  isCurrent,
  id,
  canEdit,
  onEdited,
  onOpen,
  onClose,
}) {
  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    React.useState(false);

  const renderDraggable = (children) => (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <TrackCardWrapper
          $isCurrent={isCurrent}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          onClick={() => handleClick(id)}
        >
          <TrackDragButton>
            <DragIcon />
          </TrackDragButton>
          {children}
        </TrackCardWrapper>
      )}
    </Draggable>
  );

  const renderNonDraggable = (children) => (
    <TrackCardWrapper $isCurrent={isCurrent} onClick={() => handleClick(id)}>
      {children}
    </TrackCardWrapper>
  );

  const removeClip = (e) => {
    e.stopPropagation();
    axios.delete(`/api/reels/${playlistId}/clip/${id}`).then((response) => {
      onEdited(response.data);
    });
  };

  const closeRemoveConfirmation = () => {
    setShowRemoveConfirmation(false);
    onClose();
  };

  const renderRemoveConfirmation = () => (
    <Layer
      onEsc={closeRemoveConfirmation}
      onClickOutside={closeRemoveConfirmation}
    >
      <CloseButton onClick={closeRemoveConfirmation}>
        <FormClose />
      </CloseButton>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Remove Clip</ModalTitle>
        </ModalHeader>
        <ModalBody gap="medium">
          <Text>
            Are you sure you want to remove this clip from the playlist?
          </Text>
          <Box direction="row" gap="small">
            <Button
              color="tertiary-1"
              label="Cancel"
              onClick={closeRemoveConfirmation}
            />
            <Button
              color="status-critical"
              label="Remove"
              primary
              onClick={removeClip}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Layer>
  );

  const row = (
    <TrackCardContent>
      <TrackInfo>
        <TrackTitle level="3">{titleMain}</TrackTitle>
        <TrackSub>{titleSub}</TrackSub>
      </TrackInfo>
      <TrackActions>
        {canEdit && (
          <TrimClipButton
            playlistId={playlistId}
            // clip={item.sources[0]}
            clip={playlistData.clipList[index]}
            onEdited={onEdited}
            onOpen={onOpen}
            onClose={onClose}
          />
        )}
        {canEdit && (
          <EditClipButton
            playlistId={playlistId}
            clip={item.sources[0]}
            onEdited={onEdited}
            onOpen={onOpen}
            onClose={onClose}
          />
        )}
        {canEdit && (
          <Action>
            <FormClose
              color="primary-1"
              height="24px"
              width="24px"
              onClick={() => {
                setShowRemoveConfirmation(true);
                onOpen();
              }}
            />
          </Action>
        )}
      </TrackActions>
      {showRemoveConfirmation && renderRemoveConfirmation()}
    </TrackCardContent>
  );

  return canEdit ? renderDraggable(row) : renderNonDraggable(row);
}
