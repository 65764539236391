import React from 'react';
import useYoutubeConnection, {
  AuthStatus,
} from '../../helpers/useYoutubeConnection';
import { apis, dtos } from '@shd/jslib/models';
import { strogging } from '@shd/jslib/infra';
import { theFirebase } from '../../services/Firebase/firebase';
import axios from '../../helpers/axios';

/**
 * The state machine for authorizing youtube. Paste into a mermaid diagram
 * viewer to see the diagram. eg. https://mermaid.live
 stateDiagram-v2
    [*] --> initial
    initial --> missing_team_id: validate team id
    initial --> missing_auth_status_key: validate auth status key exists
    initial --> fetching_auth: call /api/remote_login/status
    fetching_auth --> error_fetching_auth_token: api call returns non-200
    fetching_auth --> authorization_refused: api call status != granted
    fetching_auth --> firebase_signin: api call status granted with token
    firebase_signin --> firebase_signin_error: firebase signin exception
    firebase_signin --> youtube_connection_phase: firebase signin success
    state youtube_connection_phase {
        undefined --> in_progress: initiate signin
        in_progress --> completed_success: code present
        in_progress --> completed_error: code not present
        completed_error --> [*]
        completed_success --> [*]
    }
    youtube_connection_phase --> connection_complete: useYoutubeConnection onCompletion callback
    youtube_connection_phase --> connection_failed: useYoutubeConnection onError callback
    missing_auth_status_key --> [*]: Error
    missing_team_id --> [*]: Error
    error_fetching_auth_token --> [*]: Error
    authorization_refused --> [*]: Error
    connection_complete --> [*]: Success
    connection_failed --> [*]: Error
 */
export type AuthorizeYoutubeState =
  | { name: 'initial' }
  | { name: 'missing-teamid' }
  | { name: 'missing-auth-status-key' }
  | { name: 'error-fetching-auth-token'; error: unknown }
  | {
      name: 'authorization-refused';
      authResponse: dtos.RemoteLoginStatusResponse;
    }
  | { name: 'fetching-auth' }
  | { name: 'firebase-signin'; authResponse: dtos.RemoteLoginStatusResponse }
  | { name: 'firebase-signin-error'; error: unknown }
  | { name: 'youtube-connection-phase' }
  | { name: 'connection-complete' }
  | { name: 'connection-failed' }
  | { name: 'operation-cancelled' };

export type AuthorizeYoutubeStateName = AuthorizeYoutubeState['name'];

export function isTerminalState(state: AuthorizeYoutubeState): boolean {
  const terminals: AuthorizeYoutubeStateName[] = [
    'missing-auth-status-key',
    'missing-teamid',
    'error-fetching-auth-token',
    'authorization-refused',
    'connection-complete',
  ];
  return terminals.includes(state.name);
}

export function useAuthorizeYoutube(
  teamId: string,
  authStatusKey: string | null
) {
  const [overallState, setOverallState] = React.useState<AuthorizeYoutubeState>(
    {
      name: 'initial',
    }
  );
  const onConnectionFinished = React.useCallback((authStatus: AuthStatus) => {
    const newState: AuthorizeYoutubeState = {
      name: authStatus.error ? 'connection-failed' : 'connection-complete',
    };
    setOverallState(newState);
  }, []);
  const youtubeConnection = useYoutubeConnection({
    teamId,
    onConnectionFinished,
  });
  const isInitialState = overallState.name === 'initial';
  // this is the money function that drives the overall state
  React.useEffect(() => {
    if (!teamId) {
      setOverallState({ name: 'missing-teamid' });
      return;
    } else if (!authStatusKey) {
      setOverallState({ name: 'missing-auth-status-key' });
      return;
    }
    // this routine drives the overall state
    if (isInitialState) {
      (async () => {
        strogging.log('useAuthorizeYoutube-isInitialState');
        // axios call to get the auth token
        try {
          setOverallState({ name: 'fetching-auth' });
          const response = await axios.get<dtos.RemoteLoginStatusResponse>(
            apis.getRemoteLoginStatusPath(authStatusKey)
          );
          strogging.log('remote-login-response', {
            result: response.data.status,
          });
          if (response.data.status === 'granted') {
            setOverallState({
              name: 'firebase-signin',
              authResponse: response.data,
            });
          } else {
            setOverallState({
              name: 'authorization-refused',
              authResponse: response.data,
            });
            return;
          }
          // firebase sign in
          await theFirebase().doSignInWithCustomToken(response.data.token);
          strogging.log('useAuthorizeYoutube-firebase-signin');
          // youtube connection
          setOverallState({ name: 'youtube-connection-phase' });
          // youtubeConnection.signIn();
        } catch (error) {
          setOverallState((prev) =>
            prev.name === 'fetching-auth'
              ? { name: 'error-fetching-auth-token', error }
              : prev.name === 'firebase-signin'
                ? { name: 'firebase-signin-error', error }
                : { name: 'connection-failed' }
          );
        }
      })();
    }
  }, [teamId, authStatusKey, youtubeConnection, isInitialState]);

  React.useEffect(() => {
    if (isTerminalState(overallState)) {
      strogging.log('useAuthorizeYoutube-isTerminalState', {
        name: overallState.name,
      });
    }
  }, [overallState, teamId]);

  return React.useMemo(
    () => ({
      youtubeConnection,
      overallState,
    }),
    [youtubeConnection, overallState]
  );
}
