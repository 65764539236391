import React, { useRef } from 'react';
import { Text } from 'grommet';
import { HlsInfo } from './types';
import { useHls } from './useHls';
import { strogging } from '@shd/jslib/infra';

interface Props {
  style?: React.CSSProperties;
  hlsInfo?: HlsInfo;
  fetchInfoError?: string;
}

const HlsPlayer: React.FC<Props> = ({
  style = { width: '100%', maxWidth: '800px' },
  hlsInfo,
  fetchInfoError,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hls = useHls(videoRef, hlsInfo, fetchInfoError);
  if (fetchInfoError) {
    strogging.log('SlOnPlatform - fetch error', fetchInfoError);
  }
  if (hls.error) {
    strogging.log('SlOnPlatform - player error', hls.error);
  }
  return hls.error ? (
    <Text weight="bold" textAlign="center" margin={{ top: 'medium' }}>
      Something went wrong : {hls.error}
    </Text>
  ) : (
    <video controls playsInline ref={videoRef} style={style} />
  );
};

export default HlsPlayer;
