// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Heading, Text, Box } from 'grommet';
import styled from 'styled-components';

export const FormHeading = styled(Heading)`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 56px;
`;

export const FieldLabelWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const FieldLabel = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 6px;
`;

export const FieldTip = styled(Text)`
  font-weight: 400;
  font-size: 12px;
`;
