// Packages
import React, { useState } from 'react';

// Components
import { Box, Heading, Layer, Text } from 'grommet';
import {
  PaywallButtonTerms,
  PaywallButtonUnlock,
  PaywallLegalLink,
  PaywallNotNow,
  paywallHorizontalRule,
} from './paywall.styles';
import PaywallFeatureList from './components/PaywallFeaturesList';
import { getPaywallFinePrintCopy } from './paywall.copy';

// Hooks
import { useRevenueCat, usePaywallTracking } from './paywall.hooks';

// Types
import {
  PackageName,
  PaywallEvents,
  PaywallProps,
  SubscriptionOffering,
  SubscriptionTerms,
  Terms,
} from './paywall.types';
import { Checkmark } from 'grommet-icons';
import { BasicLoader } from '../../helpers/Loaders';

const Paywall: React.FC<PaywallProps> = ({
  context,
  playerId = '',
  isOpen,
  onClose,
  onUnlockAccess,
}) => {
  // Hooks
  const { monthlyPrice, annualPrice, isLoadingRc } = useRevenueCat();
  const { trackPaywallEvent } = usePaywallTracking(context, playerId);

  // States
  const [termsSelected, setTermsSelected] = useState<SubscriptionOffering>({
    package: PackageName.PRO,
    price: monthlyPrice,
    term: Terms.MONTHLY,
  });

  // Handlers
  const handleTermsSelected = (term: SubscriptionTerms) => {
    trackPaywallEvent(PaywallEvents.OFFERING_SELECTED, {
      context: context,
      offeringSelected: term,
    });
    setTermsSelected({
      package: PackageName.PRO,
      price: term === Terms.MONTHLY ? monthlyPrice : annualPrice,
      term: term,
    });
  };

  const handleUnlockAccess = () => {
    trackPaywallEvent(PaywallEvents.UNLOCK_ACCESS_CLICKED, {
      context: context,
    });
    onUnlockAccess(termsSelected);
  };

  return (
    <>
      {isLoadingRc ? (
        <BasicLoader />
      ) : (
        <>
          {isOpen && (
            <Layer
              onEsc={() => onClose()}
              onClickOutside={() => onClose()}
              full="vertical"
              style={{ height: '100dvh' }}
            >
              <Box
                style={{
                  height: '100dvh',
                  overflowY: 'scroll',
                }}
              >
                <Box
                  className="paywall__masthead"
                  background="#183710"
                  style={{
                    height: '50dvh',
                    minHeight: '450px',
                    position: 'relative',
                  }}
                  width={'100%'}
                >
                  <Box
                    align="center"
                    className="paywall__container"
                    direction="column"
                    justify="center"
                    margin={{
                      bottom: '0',
                      left: 'auto',
                      right: 'auto',
                      top: '0',
                    }}
                    pad="large"
                    style={{
                      maxWidth: '600px',
                    }}
                  >
                    <PaywallNotNow onClick={onClose}>Not now</PaywallNotNow>

                    <Heading
                      level="1"
                      color="#CCFF50"
                      size="1.75rem"
                      weight="900"
                      style={{ lineHeight: '1.14285714' }}
                    >
                      Unlock full access to player highlights for you and your
                      family.
                    </Heading>

                    <PaywallFeatureList />
                  </Box>
                </Box>

                <Box
                  className="paywall__purchase-area"
                  background="#fff"
                  style={{
                    height: '50dvh',
                    minHeight: '425px',
                    position: 'relative',
                  }}
                  width={'100%'}
                >
                  <Box
                    align="center"
                    className="paywall__container"
                    gap="medium"
                    margin={{
                      bottom: '0',
                      left: 'auto',
                      right: 'auto',
                      top: '0',
                    }}
                    pad="medium"
                    style={{
                      maxWidth: '600px',
                    }}
                  >
                    <PaywallButtonTerms
                      isSelected={
                        termsSelected.term === Terms.MONTHLY ? true : false
                      }
                      fill={'horizontal'}
                      onClick={() => handleTermsSelected(Terms.MONTHLY)}
                    >
                      <span>{monthlyPrice} billed monthly</span>
                      {termsSelected.term === Terms.MONTHLY && (
                        <Checkmark
                          style={{
                            position: 'absolute',
                            right: '1.5rem',
                          }}
                        />
                      )}
                    </PaywallButtonTerms>

                    <PaywallButtonTerms
                      isSelected={
                        termsSelected.term === Terms.ANNUAL ? true : false
                      }
                      fill={'horizontal'}
                      onClick={() => handleTermsSelected(Terms.ANNUAL)}
                    >
                      <span>{annualPrice} billed annually</span>
                      {termsSelected.term === Terms.ANNUAL && (
                        <Checkmark
                          style={{
                            position: 'absolute',
                            right: '1.5rem',
                          }}
                        />
                      )}
                    </PaywallButtonTerms>

                    <Text
                      style={{
                        fontSize: '0.625rem',
                        lineHeight: '0.75rem',
                        textAlign: 'center',
                      }}
                    >
                      {getPaywallFinePrintCopy(
                        termsSelected.price || monthlyPrice
                      )}
                    </Text>
                  </Box>

                  <hr className="paywall__hr" style={paywallHorizontalRule} />

                  <Box
                    align="center"
                    className="paywall__container"
                    gap="medium"
                    margin={{
                      bottom: '0',
                      left: 'auto',
                      right: 'auto',
                      top: '0',
                    }}
                    pad="medium"
                    style={{
                      maxWidth: '600px',
                      width: '100%',
                    }}
                  >
                    <PaywallButtonUnlock
                      fill={'horizontal'}
                      onClick={() => handleUnlockAccess()}
                    >
                      Unlock access
                    </PaywallButtonUnlock>

                    <Text style={{ color: '#b4b4b4', fontSize: '0.75rem' }}>
                      <PaywallLegalLink href="/terms-of-service">
                        Terms of Service
                      </PaywallLegalLink>
                      &nbsp;and&nbsp;
                      <PaywallLegalLink href="/privacy">
                        Privacy Policy
                      </PaywallLegalLink>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </>
      )}
    </>
  );
};

export default Paywall;
