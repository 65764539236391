import React, { useState, useEffect, useContext } from 'react';
import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Layer,
  RadioButton,
  RadioButtonGroup,
  Text,
} from 'grommet';
import axios from '../../../helpers/axios';
import { UserContext } from '../../../services/Session/context';
import FacebookSettings from '../../StreamingConfig/FacebookSettings';
import YoutubeSettings from '../../StreamingConfig/YoutubeSettings';
import { ToastProps } from '../../../components/Toast';
// import YoutubeButton from '../../../helpers/YoutubeButton';
// import FacebookButton from '../../../helpers/FacebookButton';
import { Close } from 'grommet-icons';

const BothLinkedLearnMoreModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <Layer
      onEsc={() => {
        onClose();
      }}
      modal
    >
      <Box width="large" pad="medium" gap="medium">
        <Box
          align="baseline"
          direction="row"
          justify="between"
          fill="horizontal"
        >
          <Heading level={3}>Publish to both Facebook and YouTube</Heading>
          <Button
            icon={<Close />}
            onClick={() => {
              onClose();
            }}
          />
        </Box>
        <Text>
          Your team is linked to both Facebook and YouTube. You have two
          choices:
        </Text>
        <Text>
          (1) Stream to both Facebook and YouTube. This provides the best
          Facebook viewing experience.
        </Text>
        <Text margin={{ bottom: 'medium' }}>
          (2) Stream to YouTube only. Post YouTube link to Facebook once scoring
          begins. Select this option if your Facebook is blocking your streams
          due to copyrighted audio.
        </Text>
        <Text>
          Note: Option #2 is not available if you have linked to a personal
          Facebook profile.
        </Text>

        <Text margin={{ bottom: 'none' }}>
          <Anchor
            label="Read about audio copyright issues"
            href="https://help.sidelinehd.com/a/solutions/articles/69000802906"
          />
        </Text>
      </Box>
    </Layer>
  );
};

interface BothLinkedProps {
  setToast: (toast: ToastProps) => void;
}
const BothLinked: React.FC<BothLinkedProps> = ({ setToast }) => {
  const userStore = useContext(UserContext);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [fbStreamOption, setFbStreamOption] = useState('default');

  const streamBothOption = {
    label: 'Stream to both Facebook and YouTube.',
    value: 'default',
  };

  const streamYTPostFBOption = {
    label:
      'Stream to YouTube only. Post YouTube link to Facebook once scoring begins.',
    value: 'fb-post-yt-only',
  };

  useEffect(() => {
    if (userStore.selectedTeam) {
      setFbStreamOption(
        userStore.selectedTeam.publishCrosspostMode === ''
          ? 'default'
          : userStore.selectedTeam.publishCrosspostMode
      );
    }
  }, [userStore.selectedTeam]);

  return (
    <Card pad="none" direction="column" background="white">
      <Box pad="medium" justify="between" direction="row-reverse">
        <Button
          plain
          label={
            <Text color="tertiary-1" size="small" weight="bold">
              Learn more
            </Text>
          }
          onClick={() => {
            setShowLearnMoreModal(true);
          }}
        />
      </Box>
      <CardBody
        pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}
        gap="medium"
      >
        <Text>
          You have linked Facebook and YouTube destinations. Choose from:
        </Text>
        <Box>
          <RadioButtonGroup
            name="fb-stream-options"
            width="inherit"
            options={[streamBothOption, streamYTPostFBOption]}
            value={fbStreamOption}
          >
            {(
              option: { label: string; value: string },
              { checked }: { checked: boolean }
            ) => {
              const button = (
                <RadioButton
                  name="fb-stream-option"
                  key={option.value}
                  checked={checked}
                  onChange={() => {
                    axios
                      .put(`/api/team/${userStore.selectedTeam?._id}`, {
                        teamInfo: {
                          publishCrosspostMode: option.value,
                        },
                      })
                      .catch(() => {
                        setToast({
                          label: 'Update crosspost settings failed!',
                          background: 'status-error',
                        });
                      });

                    setFbStreamOption(option.value);
                  }}
                />
              );

              return (
                <Box
                  style={{ whiteSpace: 'pre-wrap' }}
                  direction="row"
                  gap="small"
                >
                  {button}
                  <Box width="medium">
                    <Text size="small">{option.label}</Text>
                  </Box>
                </Box>
              );
            }}
          </RadioButtonGroup>
        </Box>
      </CardBody>
      {showLearnMoreModal && (
        <BothLinkedLearnMoreModal
          onClose={() => setShowLearnMoreModal(false)}
        />
      )}
    </Card>
  );
};

interface StreamingDestinationsProps {
  setToast: (toast: ToastProps) => void;
}

const StreamingDestinations: React.FC<StreamingDestinationsProps> = ({
  setToast,
}) => {
  const userStore = useContext(UserContext);
  const [facebookLinked, setFacebookLinked] = useState(false);
  const [youtubeLinked, setYoutubeLinked] = useState(false);

  const teamId = userStore.selectedTeam?._id;
  if (!teamId) {
    return null;
  }

  return (
    <Box
      border={{ side: 'bottom', color: 'light-2' }}
      flex={{ grow: 1, shrink: 0 }}
      pad={{ vertical: '16px' }}
    >
      <Text size="small" weight="bold">
        Livestreaming destinations
      </Text>
      <CardBody pad="medium" gap="medium">
        {/* TODO: use the new YoutubeButton and FacebookButton components once they properly handle unlinking
         and for Youtube, the video settings */}
        {/* <YoutubeButton teamId={teamId} isAdmin={true} showUnlink />
        <FacebookButton teamId={teamId} isAdmin={true} /> */}
        {facebookLinked && youtubeLinked && <BothLinked setToast={setToast} />}
        <YoutubeSettings
          teamId={userStore.selectedTeam?._id}
          onUpdatedLinkedChannel={(channel) => {
            setYoutubeLinked(!!channel);
          }}
        />
        <FacebookSettings
          teamId={userStore.selectedTeam?._id}
          onUpdatedLinkedChannel={(channel) => {
            if (channel && channel.type !== 'profile') {
              setFacebookLinked(true);
            } else {
              if (channel && channel.type === 'profile') {
                axios
                  .put(`/api/team/${userStore.selectedTeam?._id}`, {
                    teamInfo: {
                      publishCrosspostMode: '',
                    },
                  })
                  .catch(() => {
                    setToast({
                      label: 'Update crosspost settings failed!',
                      background: 'status-error',
                    });
                  });
              }
              setFacebookLinked(false);
            }
          }}
        />
      </CardBody>
    </Box>
  );
};

export default StreamingDestinations;
