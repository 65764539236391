// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Heading, Layer } from 'grommet';
import SHDButton from '../../components/SHD/Button';
import useLongPress from '../../helpers/useLongPress';
import TagPlayersSelector from './TagPlayersSelector';
import RunnerOutcomeSelector from './RunnerOutcomeSelector';
import { ScoringContext } from './context';

const outcomes = {
  out: {
    label: 'Out',
    code: 'O',
    border: 'accent-1',
    background: 'accent-7',
    text: '#000000',
  },
  strikeout: {
    label: 'Strikeout',
    code: 'K',
    border: 'accent-1',
    background: 'accent-7',
    text: '#000000',
  },
  fieldersChoice: {
    label: "Fielder's choice",
    code: 'FC',
    border: 'accent-1',
    background: 'accent-7',
    isOffenseOnly: true,
    text: '#000000',
  },
  sacBunt: {
    label: 'Sac bunt',
    code: 'SACB',
    border: 'accent-1',
    background: 'accent-7',
    isOffenseOnly: true,
    text: '#000000',
  },
  sacFly: {
    label: 'Sac fly',
    code: 'SACF',
    border: 'accent-1',
    background: 'accent-7',
    isOffenseOnly: true,
    text: '#000000',
  },
  doublePlay: {
    label: 'Double play',
    code: 'DP',
    border: 'accent-1',
    background: 'accent-7',
    text: '#000000',
  },
  triplePlay: {
    label: 'Triple play',
    code: 'TP',
    border: 'accent-1',
    background: 'accent-7',
    text: '#000000',
  },
  single: {
    label: 'Single',
    code: '1B',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  double: {
    label: 'Double',
    code: '2B',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  triple: {
    label: 'Triple',
    code: '3B',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  homeRun: {
    label: 'Home run',
    code: 'HR',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  walk: {
    label: 'Walk',
    code: 'BB',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  hitByPitch: {
    label: 'Hit by pitch',
    code: 'HBP',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  error: {
    label: 'Reach on error',
    code: 'ERR',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  other: {
    label: 'Other',
    code: 'OTHER',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  stolenBase: {
    label: 'Stolen base',
    code: 'SB',
    border: 'primary-1',
    background: 'primary-8',
    text: 'primary-1',
  },
  caughtStealing: {
    label: 'Caught stealing',
    code: 'CS',
    border: 'accent-1',
    background: 'accent-7',
    text: '#000000',
  },
  pickedOff: {
    label: 'Picked off',
    code: 'PK',
    border: 'accent-1',
    background: 'accent-7',
    text: '#000000',
  },
};

const runnerOutcomeCodes = ['stolenBase', 'caughtStealing', 'pickedOff'];

const Prompt = ({
  text = '',
  subtext = '',
  type = '',
  onResponse = () => {},
  closePrompt = () => {},
  lastBatterDisplayName = '',
  firebase,
  ...rest
}) => {
  const [showAbEndPrompt, setShowAbEndPrompt] = useState(false);
  const [showResultPrompt, setShowResultPrompt] = useState(false);
  const [showTagPlayersPrompt, setShowTagPlayersPrompt] = useState(
    rest.showTagPlayersPrompt
  );

  const onLongPress = (code) => () => {
    if (firebase) {
      firebase.logAnalyticsEvent('sscore_long_press', {
        code,
      });
    }

    setShowTagPlayersPrompt(code);
  };

  const onClick = (code) => () => {
    const defensiveTaggingCodes = [
      'other',
      'out',
      'fieldersChoice',
      'sacBunt',
      'sacFly',
      'doublePlay',
      'triplePlay',
    ];

    const defense =
      defensiveTaggingCodes.includes(code) && subtext.includes('fielding');

    if (defense || runnerOutcomeCodes.includes(code)) {
      onLongPress(code)();
    } else {
      onResponse({ resp: true, result: outcomes[code].code });
      closePrompt(false);
    }
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 1000,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ResultBox = ({ code, disabled = false }) => (
    <Box
      align="center"
      pad="medium"
      justify="center"
      height="75px"
      width="75px"
      flex="shrink"
      round="medium"
      background={outcomes[code].background}
      border={{ size: 'small', color: outcomes[code].border }}
      style={{
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? '0.25' : '1',
      }}
      {...useLongPress(onLongPress(code), onClick(code), defaultOptions)}
    >
      <Text textAlign="center" color={outcomes[code].text}>
        {outcomes[code].label}
      </Text>
    </Box>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const ResultBoxGrid = (props) => {
    const scoringStore = useContext(ScoringContext);

    const gridType = props.type;
    useEffect(
      () => () => {
        setShowResultPrompt(false);
      },
      []
    );

    let grid = (
      <Box gap="large">
        <Box gap="medium">
          <Box direction="row" fill="horizontal" justify="center" gap="medium">
            <ResultBox code="triple" />
            <ResultBox code="double" />
            <ResultBox code="single" />
          </Box>
          <Box direction="row" fill="horizontal" justify="center" gap="medium">
            <ResultBox code="homeRun" />
            <ResultBox code="walk" />
          </Box>
          <Box direction="row" fill="horizontal" justify="center" gap="medium">
            <ResultBox code="hitByPitch" />
            <ResultBox code="error" />
            <ResultBox code="other" />
          </Box>
        </Box>
        <Box gap="medium">
          <Box direction="row" fill="horizontal" justify="center" gap="medium">
            <ResultBox code="strikeout" />
            <ResultBox code="out" />
          </Box>
          <Box direction="row" fill="horizontal" justify="center" gap="medium">
            <ResultBox code="fieldersChoice" />
            <ResultBox code="sacBunt" />
            <ResultBox code="sacFly" />
          </Box>
          <Box direction="row" fill="horizontal" justify="center" gap="medium">
            <ResultBox
              code="doublePlay"
              disabled={scoringStore.scoreboard.o > 1}
            />
            <ResultBox
              code="triplePlay"
              disabled={scoringStore.scoreboard.o > 0}
            />
          </Box>
        </Box>
      </Box>
    );

    if (gridType === 'runnerOutcome') {
      grid = (
        <Box gap="large">
          <Box gap="medium">
            <Box
              direction="row"
              fill="horizontal"
              justify="center"
              gap="medium"
            >
              <ResultBox code="caughtStealing" />
              <ResultBox code="pickedOff" />
            </Box>
          </Box>
        </Box>
      );
    }

    return grid;
  };

  if (showResultPrompt) {
    return (
      <Layer>
        <Box
          height={`${window.innerHeight}px`}
          pad="large"
          gap="large"
          style={{ overflow: 'auto' }}
        >
          <Box fill="horizontal" align="end">
            <Text
              color="tertiary-1"
              weight="bold"
              onClick={() => {
                onResponse({ resp: false });
                closePrompt();
              }}
            >
              Back
            </Text>
          </Box>
          <Heading level="3">{`What happened to ${
            lastBatterDisplayName || 'the last batter'
          }?`}</Heading>
          <ResultBoxGrid />
        </Box>
      </Layer>
    );
  }
  if (showAbEndPrompt) {
    return (
      <Layer responsive={false}>
        <Box pad="large" width="medium" gap="large">
          <Box fill="horizontal" align="end">
            <Text
              color="tertiary-1"
              weight="bold"
              onClick={() => {
                onResponse({ resp: false });
                closePrompt();
              }}
            >
              Back
            </Text>
          </Box>
          <Box align="center" fill gap="large" pad="medium">
            <Text size="xlarge" weight="bold">{`Did ${
              lastBatterDisplayName || 'the last batter'
            } finish their at-bat?`}</Text>
            <Box
              direction="row"
              gap="medium"
              align="center"
              justify="between"
              fill="horizontal"
            >
              <Box
                pad={{ vertical: 'medium', horizontal: 'large' }}
                align="center"
                justify="center"
                onClick={() => {
                  onResponse({ resp: true, result: null });
                  closePrompt();
                }}
              >
                <Text
                  size="large"
                  color="tertiary-1"
                  weight="bold"
                  textAlign="center"
                >
                  No
                </Text>
              </Box>
              <SHDButton
                size="large"
                primary
                label="Yes"
                onClick={() => {
                  if (type === 'endInning') {
                    setShowResultPrompt(true);
                  }
                }}
                color="tertiary-1"
              />
            </Box>
          </Box>
        </Box>
      </Layer>
    );
  }
  if (type !== 'result' && type !== 'runnerOutcome') {
    return (
      <Layer responsive={false} width="medium">
        <Box justify="center" fill gap="large" pad="large">
          <Text weight="bold" size="xlarge">
            {text}
          </Text>
          <Box direction="row" gap="medium">
            <Box
              pad={{ vertical: 'medium', horizontal: 'large' }}
              align="center"
              justify="center"
              onClick={() => {
                onResponse({ resp: false });
                closePrompt();
              }}
            >
              <Text
                size="large"
                color="tertiary-1"
                weight="bold"
                textAlign="center"
              >
                No
              </Text>
            </Box>
            <SHDButton
              size="large"
              primary
              label="Yes"
              onClick={() => {
                if (type === 'endInning') {
                  setShowAbEndPrompt(true);
                } else {
                  onResponse({ resp: true });
                  closePrompt();
                }
              }}
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Layer>
    );
  }

  if (showTagPlayersPrompt) {
    if (runnerOutcomeCodes.includes(showTagPlayersPrompt)) {
      return (
        <RunnerOutcomeSelector
          label={outcomes[showTagPlayersPrompt].label}
          onResponse={({ secondaryPlayers, highlighted }) => {
            const code = showTagPlayersPrompt;
            onResponse({
              resp: true,
              result: outcomes[code].code,
              scorerRating: highlighted ? 5 : 0,
              secondaryPlayers,
            });
            closePrompt();
          }}
          onBack={() => {
            setShowTagPlayersPrompt(false);
            if (showTagPlayersPrompt === 'stolenBase') {
              closePrompt();
            }
          }}
        />
      );
    }
    return (
      <TagPlayersSelector
        onResponse={({ secondaryPlayers, highlighted }) => {
          const code = showTagPlayersPrompt;
          onResponse({
            resp: true,
            result: outcomes[code].code,
            scorerRating: highlighted ? 5 : 0,
            secondaryPlayers,
          });
          closePrompt();
        }}
        onBack={() => {
          setShowTagPlayersPrompt(false);
        }}
      />
    );
  }

  return (
    <Layer>
      <Box
        height={`${window.innerHeight}px`}
        pad="medium"
        gap="medium"
        style={{ overflow: 'auto' }}
      >
        <Box fill="horizontal" align="end">
          <Text
            color="tertiary-1"
            weight="bold"
            onClick={() => {
              closePrompt();
            }}
          >
            Back
          </Text>
        </Box>
        <Heading level="3">{text}</Heading>
        {(type === 'result' || type === 'runnerOutcome') && (
          <Text>{subtext}</Text>
        )}
        <ResultBoxGrid type={type} />
      </Box>
    </Layer>
  );
};

export default Prompt;
