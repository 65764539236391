// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Close, Edit } from 'grommet-icons';

import { TextInput } from 'grommet';

import { isMobile } from '../../helpers/browserDetect';
import axios from '../../helpers/axios';

import {
  Action,
  BottomModal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ModalButton,
  ModalTopRight,
} from './styles';
import { analytics } from '../../services/analytics';

export default function EditListButton({ playlistData, onEdited }) {
  const [value, setValue] = useState(playlistData);
  const [isOpen, setIsOpen] = useState(false);
  const position = isMobile.any() ? 'bottom' : 'center';

  useEffect(() => {
    setValue(playlistData);
  }, [playlistData]);

  return (
    <>
      <Action
        onClick={() => {
          analytics.track('sidelineREEL Edit Clicked', {
            playlistId: playlistData._id,
          });
          setIsOpen(true);
        }}
      >
        <Edit color="primary-1" height="24px" width="24px" />
      </Action>

      {isOpen && (
        <BottomModal
          $isMobile={isMobile.any()}
          position={position}
          responsive={false}
          onClickOutside={() => setIsOpen(false)}
        >
          <ModalContent>
            <ModalHeader>
              <ModalTitle>Edit Playlist</ModalTitle>
              <ModalTopRight onClick={() => setIsOpen(false)}>
                <Close height="24px" width="24px" />
              </ModalTopRight>
            </ModalHeader>
            <ModalBody>
              <TextInput
                value={value.titleMain}
                onChange={(event) => {
                  setValue({ ...value, titleMain: event.target.value });
                }}
              />
            </ModalBody>
            <ModalFooter>
              <ModalButton
                label="Save"
                onClick={() => {
                  axios
                    .put(`/api/reels/${value._id}`, value)
                    .then((response) => {
                      setIsOpen(false);
                      onEdited(response.data);
                    });
                }}
              />
            </ModalFooter>
          </ModalContent>
        </BottomModal>
      )}
    </>
  );
}
