import React, { useCallback, useState, useEffect } from 'react';
import axios from '../../helpers/axios';
import { Box, Select, TextInput, Text, Button, FormField } from 'grommet';
import { shd } from '@shd/jslib/models';
import { errorUtil } from '@shd/jslib/infra';
import { SyncLoader } from 'react-spinners';

import UserCard from './SearchDisplay/UserCard';
import TeamCard from './SearchDisplay/TeamCard';
import ActionConfirmationModal from './SearchDisplay/ActionConfirmationModal';

import {
  SEARCH,
  SEARCH_BY_USER,
  SEARCH_BY_TEAM,
  SEARCH_BY_PLAYER,
  SEARCH_OPTIONS,
  FirebaseSearchProps,
} from './admin.types';

const FirebaseSearch: React.FC<FirebaseSearchProps> = ({ setUser }) => {
  const [searchType, setSearchType] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('');
  const [searchByOptions, setSearchOptions] = useState(SEARCH_BY_PLAYER);
  const [searchValue, setSearchValue] = useState<string | null>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [results, setResults] = useState<any[] | undefined>();
  const [error, setError] = useState<string | null>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [firebaseLoading, setFirebaseLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState<{
    action: () => void;
    text: string;
    actionName: string;
  } | null>(null);

  const handleOnSearch = useCallback(() => {
    let searchApi = `/api/firebase/user_search`;
    if (searchType === SEARCH.TEAM) {
      searchApi = `/api/firebase/team_search`;
    }
    setFirebaseLoading(true);
    axios
      .get(searchApi, {
        params: {
          searchBy: searchBy,
          searchValue: searchValue,
        },
      })
      .then((response) => {
        const result = response.data;
        setResults(result);
        setFirebaseLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        setError(errorUtil.errorMessage(e) || '');
      });
  }, [searchBy, searchValue, searchType]);

  useEffect(() => {
    let opts = SEARCH_BY_USER;
    if (searchType == SEARCH.TEAM) {
      opts = SEARCH_BY_TEAM;
    }
    if (searchType === SEARCH.PLAYER) {
      opts = SEARCH_BY_PLAYER;
    }
    setSearchOptions(opts);
  }, [searchType]);

  const handleChangeSearch = useCallback((option: string) => {
    setSearchType(option);
  }, []);

  const handleChangeSearchBy = useCallback((option: string) => {
    setSearchBy(option);
  }, []);

  const getClaimLink = (user: shd.SQLUser) => {
    return async () => {
      try {
        const response = await axios.post(
          `/api/admin/user/${user.id}/claim_link`
        );
        const claimLink = response.data.claimLink;
        if (claimLink) {
          setCurrentAction((prevAction) => {
            if (!prevAction) {
              return null;
            }
            return {
              action: prevAction.action,
              text: `Claim link: ${claimLink}`,
              actionName: prevAction.actionName,
            };
          });

          window.alert(claimLink);
        }
      } catch (e) {
        setError(errorUtil.errorMessage(e) || '');
      }
    };
  };

  const handleOnReset = useCallback(() => {
    setSearchType('');
    setSearchBy('');
    setSearchValue('');
    setResults(undefined);
    setError(null);
  }, []);

  return (
    <Box pad={'1rem'}>
      {error && <Text color="status-error">{error}</Text>}
      {showConfirmation && currentAction && (
        <ActionConfirmationModal
          action={currentAction.action}
          onClose={() => setShowConfirmation(false)}
          confirmationText={currentAction.text}
          actionName={currentAction.actionName}
        />
      )}
      <Box style={{ marginBottom: '1rem' }}>
        <Text size="large" weight={'bolder'}>
          Search (Firebase)
        </Text>
      </Box>
      <Box>
        <FormField
          name="searchType"
          htmlFor="name-long-input"
          label={<Text size="small">Search Type</Text>}
        >
          <Select
            name="searchType"
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            options={SEARCH_OPTIONS}
            value={searchType}
            onChange={(option) => handleChangeSearch(option.value)}
          />
        </FormField>
        <FormField
          name="searchBy"
          htmlFor="name-long-input"
          label={<Text size="small">Search By</Text>}
        >
          <Select
            name="searchBy"
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            options={searchByOptions}
            value={searchBy}
            onChange={(option) => handleChangeSearchBy(option.value)}
          />
        </FormField>
        <FormField
          name="nameLong"
          htmlFor="name-long-input"
          label={<Text size="small">Search For</Text>}
        >
          <Box direction="row" align="center" gap="medium">
            <TextInput
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue || ''}
            />
          </Box>
        </FormField>
        <Box
          pad="1rem"
          justify="center"
          align="center"
          fill="horizontal"
          direction="row"
        >
          <Button
            type="submit"
            label={'Search'}
            primary
            onClick={handleOnSearch}
            margin={{ right: '1rem' }}
          />
          <Button
            type="button"
            label={'Reset'}
            secondary
            onClick={handleOnReset}
          />
        </Box>
        <Box justify="center" fill="horizontal" direction="row" margin="1rem">
          <SyncLoader loading={firebaseLoading} color="#ebebeb" size={10} />
        </Box>
        {searchType === SEARCH.USER &&
          results?.map((result: shd.SQLUser, i: number) => (
            <UserCard
              key={result.id}
              user={result}
              i={i}
              setCurrentAction={setCurrentAction}
              getClaimLink={getClaimLink}
              setShowConfirmation={setShowConfirmation}
              setUser={setUser}
            />
          ))}
        {searchType === SEARCH.TEAM &&
          results?.map((result) => <TeamCard key={result.id} team={result} />)}
        {results && results?.length <= 0 && (
          <Text size="large" color="status-error">
            No results
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default FirebaseSearch;
