import React, { useState, useContext, useEffect } from 'react';
import { Box, Card, CardHeader, Text } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import SignInMethod from './SignInMethod';
import { theFirebase } from '../../services/Firebase/firebase';
import { BasicLoader } from '../../helpers/Loaders';
import { dtos } from '@shd/jslib/models';
import { isMobile } from '../../helpers/browserDetect';
import { AppHostContext } from '../../services/appHost';
import { strogging } from '@shd/jslib/infra';

interface Props {
  inviteCode?: string;
  claimPlayer?: string;
  claimPlayerKey?: string;
  claimPhoneNumber?: string;
  onBack: () => void;
}

export const AlternativeLoginOptions: React.FC<Props> = ({
  inviteCode,
  claimPlayer,
  claimPlayerKey,
  claimPhoneNumber,
  onBack,
}) => {
  const appHost = useContext(AppHostContext);
  const appHostService = appHost.isHosted ? appHost.service : undefined;
  const [loading, setLoading] = useState(false);
  const [droid, setDroid] = useState<boolean>(false);
  const [error, setError] = useState<{ message: React.ReactNode } | null>(null);
  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();
  const postParams: Partial<dtos.UserPostRequest> = {
    create_if_not_exist: true,
  };
  if (inviteCode) {
    postParams.invite_code = inviteCode;
  }

  useEffect(() => {
    const isHostedAndroid = async () => {
      const res = await appHostService?.requests.fetchOS();
      return res === 'android';
    };

    const isAndroidDevice = async () => {
      if (appHost.isHosted) {
        const test = await isHostedAndroid();
        setDroid(test);
      }

      if (isMobile.Android()) {
        strogging.log('fetchPlatformOS-res', isMobile.Android());
        setDroid(true);
      }
    };
    isAndroidDevice();
  }, [appHost.isHosted, appHostService]);

  return (
    <Card pad="large" gap="16px" background="white" fill="horizontal">
      <CardHeader align="center" justify="start" gap="8px">
        <LinkPrevious size="24px" onClick={onBack} />
        <Text weight="bold" size="20px">
          Other sign-in options
        </Text>
      </CardHeader>
      <Box gap="small">
        <Box gap="small">
          <SignInMethod
            label={
              <Text weight="bold" color={'#212121'}>
                Continue with Google
              </Text>
            }
            provider={googleProvider}
            toggleLoading={setLoading}
            errorHandler={setError}
            claimPlayer={claimPlayer}
            claimPlayerKey={claimPlayerKey}
            claimPhoneNumber={claimPhoneNumber}
            postParams={postParams}
          />
          <SignInMethod
            label={
              <Text weight="bold" color={'#212121'}>
                Continue with Facebook
              </Text>
            }
            provider={facebookProvider}
            toggleLoading={setLoading}
            errorHandler={setError}
            claimPlayer={claimPlayer}
            claimPlayerKey={claimPlayerKey}
            claimPhoneNumber={claimPhoneNumber}
            postParams={postParams}
          />
          {!droid && (
            <SignInMethod
              label={
                <Text weight="bold" color={'#212121'}>
                  Continue with Apple
                </Text>
              }
              provider={appleProvider}
              toggleLoading={setLoading}
              errorHandler={setError}
              claimPlayer={claimPlayer}
              claimPlayerKey={claimPlayerKey}
              claimPhoneNumber={claimPhoneNumber}
              postParams={postParams}
            />
          )}
        </Box>
      </Box>
      {loading && <BasicLoader />}
      {error && <Text color="status-error">{error.message}</Text>}
    </Card>
  );
};

export default AlternativeLoginOptions;
