// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useContext } from 'react';
import { Box, Text, Layer, Heading, TextInput } from 'grommet';
import { CaretUpFill, CaretDownFill } from 'grommet-icons';
import { ScoringContext } from './context';
import SHDButton from '../../components/SHD/Button';
import { getReadableInning, getInternalInning } from '../../helpers/utils';

const EditInningLayer = ({ onClose = () => {} }) => {
  const scoringStore = useContext(ScoringContext);
  const inning = getReadableInning(scoringStore.scoreboard.i);
  const [topBottom, setTopBottom] = useState(inning.topBottom);
  const [inningNum, setInningNum] = useState(inning.inning);
  const [showWarning, setShowWarning] = useState(false);
  const [error, setError] = useState();

  const commitChangeInning = () => {
    const inningNumInt = parseInt(inningNum);
    scoringStore.handleEvent(
      'changeInning',
      getInternalInning(inningNumInt, topBottom)
    );
    onClose();
  };

  const changeInning = () => {
    const inningNumInt = parseInt(inningNum);
    if (
      !Array(29)
        .fill()
        .map((x, i) => i + 1)
        .includes(inningNumInt)
    ) {
      setError('Please enter a valid inning number.');
      return true;
    }
    if (![0, 1].includes(topBottom)) {
      setError('Something went wrong. Please refresh and try again.');
      return true;
    }
    if (topBottom !== inning.topBottom) {
      setShowWarning(true);
      return true;
    }

    commitChangeInning();
    return true;
  };

  if (showWarning) {
    return (
      <Layer responsive={false}>
        <Box pad="large" gap="large" width="medium">
          <Heading level="3">Warning</Heading>
          <Text>
            <Text weight="bold">
              {topBottom
                ? scoringStore.gameInfo.teamHNameMed
                : scoringStore.gameInfo.teamANameMed}
            </Text>
            <Text>
              {' '}
              will bat after changing the inning. Are you sure you want to
              proceed?
            </Text>
          </Text>
          <Box direction="row" align="center" justify="between" gap="medium">
            <Box
              pad={{ vertical: 'medium', horizontal: 'large' }}
              align="center"
              justify="center"
              onClick={commitChangeInning}
            >
              <Text
                weight="bold"
                color="tertiary-1"
                size="large"
                textAlign="center"
                onClick={commitChangeInning}
              >
                Yes
              </Text>
            </Box>
            <SHDButton
              label="No"
              primary
              color="tertiary-1"
              size="large"
              onClick={() => setShowWarning(false)}
            />
          </Box>
        </Box>
      </Layer>
    );
  }

  return (
    <Layer responsive={false}>
      <Box pad="large" justify="center" gap="large" width="medium">
        <Box gap="medium">
          <Heading level="3">Change inning</Heading>
          {error ? <Text color="status-critical">{error}</Text> : null}
          <Box direction="row" align="center" gap="medium" justify="center">
            <Box gap="small" flex="shrink" align="start">
              <Box
                background={!topBottom ? 'secondary-1' : 'secondary-7'}
                round="medium"
                onClick={() => setTopBottom(0)}
              >
                <CaretUpFill size="large" />
              </Box>
              <Box
                background={topBottom ? 'secondary-1' : 'secondary-7'}
                round="medium"
                onClick={() => setTopBottom(1)}
              >
                <CaretDownFill size="large" />
              </Box>
            </Box>
            <Box basis="1/2">
              <TextInput
                type="number"
                textAlign="center"
                style={{ fontSize: '44px' }}
                value={inningNum}
                onChange={(event) => setInningNum(event.target.value)}
              />
            </Box>
          </Box>
          <Box
            direction="row"
            align="center"
            gap="small"
            justify="between"
            fill="horizontal"
          >
            <Box
              pad={{ vertical: 'medium', horizontal: 'large' }}
              align="center"
              justify="center"
              onClick={() => onClose(false)}
            >
              <Text color="tertiary-1" weight="bold" textAlign="center">
                Cancel
              </Text>
            </Box>
            <SHDButton
              primary
              size="large"
              label="Save"
              onClick={changeInning}
              disabled={
                inningNum === inning.inning && topBottom === inning.topBottom
              }
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default EditInningLayer;
