// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import { Anchor, Box, Button, Card, Heading, Layer, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import { Close } from 'grommet-icons';

import ValidateDeviceId from './ValidateDeviceId';
import GameListContainer from './GameListContainer';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session/context';
import ScoringImport from './ScoringImport';
import SHDButton from '../../components/SHD/Button';
import { BasicLoader } from '../../helpers/Loaders';
import { analytics } from '../../services/analytics';

const ScoringLayer = (props) => {
  const userStore = useContext(UserContext);
  const teamId = userStore.selectedTeam._id;
  const { completeStep = () => {}, closeLayer = () => {} } = props;
  const [gameList, setGameList] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [error, setError] = useState(null);
  const [isImporting, toggleIsImporting] = useState();
  const [loading, toggleLoading] = useState(false);
  const [numPlayers, setNumPlayers] = useState(0);
  const [scoreChoice, setScoreChoice] = useState(props.scoreChoice);

  useEffect(() => {
    toggleLoading(true);
    axios
      .get(`/api/team/${userStore.selectedTeam._id}/team_players`)
      .then((res) => {
        setNumPlayers(res.data.players.length);
        toggleLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGameList = (validatedDeviceId, gameListFromQuery) => {
    setDeviceId(validatedDeviceId);
    setGameList(gameListFromQuery);
  };

  const chooseScoring = (scoringApp) => {
    toggleLoading(true);
    axios
      .post(`/api/team/${teamId}/choose_scoring`, { teamId, scoringApp })
      .then(() => {
        setScoreChoice(scoringApp);
        toggleLoading(false);
      })
      .catch((respError) => {
        if (respError.response && respError.response.data.message) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. Please refresh and try again');
        }
        toggleLoading(false);
      });
  };

  const onSelectTeam = (scoreTeamId) => {
    axios
      .post(`/api/iscore/${deviceId}`, { scoreTeamId, teamId })
      .then(() => {
        analytics.track('iScore Linked', {
          teamId,
          how: 'existing',
        });
        completeStep();
        closeLayer();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError.toString());
      });
  };

  useEffect(() => {
    if (scoreChoice === 'sscore-v001') {
      completeStep();
    }
  }, [completeStep, scoreChoice]);

  if (loading) {
    return (
      <Layer>
        <Box pad="large">
          <BasicLoader fullPage />
        </Box>
      </Layer>
    );
  }

  if (error) {
    return (
      <Layer>
        <Box pad="medium">
          <Text color="red">{error}</Text>
        </Box>
      </Layer>
    );
  }

  if (!scoreChoice) {
    return (
      <Layer>
        <Box fill id="modal-container" overflow="auto">
          <Box pad="medium" alignSelf="end">
            <SHDButton
              plain
              color="tertiary-0"
              label={<Text weight="bold">Exit</Text>}
              hoverIndicator
              onClick={closeLayer}
            />
          </Box>
          <Box
            margin="0 auto"
            pad="medium"
            gap="large"
            style={{ display: 'block' }}
            overflow="auto"
          >
            <Heading textAlign="center" level="3">
              Select your scoreboard controller
            </Heading>
            <Card pad="medium" gap="medium" background="primary-8">
              <Text size="large">
                SidelineSCORE is the best way to control scoreboards and produce
                video highlights. Score on any web browser - no install
                required!
                <br />
                (If your team uses GameChanger for stats, choose this option.)
              </Text>
              <Button
                label={
                  <Text weight="bold">Use sidelineSCORE (recommended)</Text>
                }
                primary
                color="tertiary-1"
                size="large"
                onClick={() => chooseScoring('sscore-v001')}
              />
            </Card>
            <Card pad="medium" gap="medium" background="primary-8">
              <Text size="large">
                iScore is a separate scorekeeping app that pairs with
                sidelineHD. Control scoreboard overlays, get spray charts and
                see player stats.
              </Text>
              <Button
                label={<Text weight="bold">Use iScore</Text>}
                primary
                color="tertiary-1"
                size="large"
                onClick={() => chooseScoring('iscore-v001')}
              />
            </Card>
            <Box>
              <Text>Not sure which to choose?</Text>
              <Anchor
                href="https://sidelinehd.zendesk.com/hc/en-us/articles/23004368094231-Choosing-Your-Scoreboard-Controller-Baseball-Softball"
                target="_blank"
              >
                Learn more
              </Anchor>
            </Box>
          </Box>
        </Box>
      </Layer>
    );
  }

  const notImportingDiv = (
    <>
      {!gameList && <ValidateDeviceId onGameList={onGameList} />}
      {!!gameList && (
        <GameListContainer gameList={gameList} onComplete={onSelectTeam} />
      )}
    </>
  );

  const importingDiv = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {numPlayers >= 9 ? (
        <ScoringImport onComplete={onSelectTeam} />
      ) : (
        <Text color="status-warning">
          Please complete your roster before transferring your team to iScore
        </Text>
      )}
    </>
  );

  if (scoreChoice === 'iscore-v001') {
    return (
      <Layer id="tutorial-layer" style={{ overflow: 'auto' }}>
        <Box alignSelf="end">
          <SHDButton
            icon={<Close size="medium" />}
            hoverIndicator
            onClick={closeLayer}
          />
        </Box>
        <Box fill margin="0 auto" pad="medium" gap="small">
          {isImporting === undefined ? (
            <Box gap="medium">
              {!props.isSyncRoster && (
                <Anchor label="Back" onClick={() => setScoreChoice('')} />
              )}
              <SHDButton
                primary
                label="Transfer roster to iScore (recommended)"
                onClick={() => toggleIsImporting(true)}
              />
              <SHDButton
                label="Use existing iScore team"
                onClick={() => toggleIsImporting(false)}
              />
            </Box>
          ) : (
            <Box>{!isImporting ? notImportingDiv : importingDiv}</Box>
          )}
        </Box>
      </Layer>
    );
  }
  if (scoreChoice === 'sscore-v001') {
    return (
      <Layer>
        <Box alignSelf="end">
          <SHDButton
            icon={<Close size="medium" />}
            hoverIndicator
            onClick={closeLayer}
          />
        </Box>
        <Box
          margin="0 auto"
          width={{ min: '250px', max: '750px' }}
          pad="medium"
          gap="small"
        >
          <Heading textAlign="center" level="4">
            sidelineScore is activated!
          </Heading>
        </Box>
      </Layer>
    );
  }

  return null;
};

export default ScoringLayer;
