// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { PlayerHeading } from '../styles';

const PromptHeader = ({ teamPlayer }) => {
  if (teamPlayer.invitePhoneNumberList.length) {
    return (
      <PlayerHeading level={3} margin={{ top: '0px', bottom: '0px' }}>
        {`Are you ${teamPlayer.nameFirst} ${teamPlayer.nameLast}?`}
      </PlayerHeading>
    );
  }

  return (
    <PlayerHeading level={3} margin={{ top: '0px', bottom: '0px' }}>
      {`Claim ${teamPlayer.nameFirst} ${teamPlayer.nameLast}?`}
    </PlayerHeading>
  );
};

export default PromptHeader;
