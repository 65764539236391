import { useRelabelClips } from '../../admin.hooks';
import React, { useState } from 'react';

// Components
import AdminClipSearch from '../../components/AdminClipSearch';
import AdminStepButtons from '../../components/AdminStepButtons';

// Types
import { RelabelClipsStepOneProps, ClipProps } from '../../admin.types';

const RelabelClipsStepOne: React.FC<RelabelClipsStepOneProps> = ({
  onLinkClicked,
  onProgressStep,
}) => {
  // Hooks
  const { clearClips } = useRelabelClips();

  // States
  const [cardIsSelected, setCardIsSelected] = useState<boolean>(false);
  const [selectedClip, setSelectedClip] = useState<ClipProps>(null);

  // Handlers
  const handleNextClick = () => {
    if (cardIsSelected && selectedClip) {
      onProgressStep(selectedClip);
    }
  };

  const handleBackClick = () => {
    clearClips();
    setCardIsSelected(false);
    onLinkClicked && onLinkClicked('/admin');
  };

  return (
    <>
      <AdminClipSearch
        id="relabelClipsSearch"
        label="Search for clip ID"
        placeholder="Search for clip ID"
        isCardSelected={(isSelected) => {
          setCardIsSelected(isSelected);
        }}
        onCardSelected={(payload) => {
          setSelectedClip(payload);
        }}
      />
      <AdminStepButtons
        isFixed={true}
        backLabel="Cancel"
        isNextActive={cardIsSelected}
        nextLabel="Next Step"
        onBack={() => {
          handleBackClick();
        }}
        onNext={() => {
          handleNextClick();
        }}
      />
    </>
  );
};

export default RelabelClipsStepOne;
