import React from 'react';
import { Grommet, CheckBox, CheckBoxExtendedProps } from 'grommet';

const RoundedToggle: React.FC<CheckBoxExtendedProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const checkedCheckboxStyle = `
    background-color: #7e68c0;
  `;

  const height = 16;
  const width = 30;
  const knobSize = 12;
  const padding = (height - knobSize) / 2;

  const checkedKnobLeftPosition = `${width - height + padding}px`;
  const uncheckedKnobLeftPosition = `${padding}px`;

  return (
    <Grommet
      theme={{
        checkBox: {
          color: 'white',
          hover: {
            border: {
              color: 'white',
            },
          },
          border: {
            width: '0px',
          },
          size: `${height}px`,
          toggle: {
            color: 'white',
            background: '#A8A8A8',
            size: `${width}px`,
            knob: {
              extend: ({ checked: c }) =>
                `
                top: ${(height - knobSize) / 2}px; 
                width: ${knobSize}px; 
                height: ${knobSize}px; 
                left: ${c ? checkedKnobLeftPosition : uncheckedKnobLeftPosition} !important;`, // manually do the positioning
            },
            extend: ({
              checked: c,
            }) => `top: -0px; box-shadow: 0px 0px 0px 0px rgba(0,0,0,0),
              0px 0px 0px 0px rgba(0,0,0,0); border-radius: 8px; ${c ? checkedCheckboxStyle : ''}`,
          },
        },
      }}
    >
      <CheckBox
        {...props}
        checked={props.checked}
        onChange={handleChange}
        toggle
      />
    </Grommet>
  );
};

export default RoundedToggle;
