// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

export default function IconCheck() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_433_4827)">
        <path d="M22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11ZM16.5413 6.83375C16.443 6.73588 16.3261 6.65881 16.1974 6.60717C16.0687 6.55553 15.9309 6.53037 15.7923 6.53319C15.6537 6.53602 15.5171 6.56677 15.3906 6.62361C15.2641 6.68045 15.1504 6.76221 15.0562 6.864L10.2809 12.9484L7.403 10.0691C7.20751 9.88696 6.94895 9.7878 6.68178 9.79251C6.41462 9.79722 6.15971 9.90545 5.97077 10.0944C5.78183 10.2833 5.6736 10.5382 5.66888 10.8054C5.66417 11.0726 5.76334 11.3311 5.9455 11.5266L9.58375 15.1662C9.68176 15.2641 9.79848 15.3412 9.92693 15.3929C10.0554 15.4447 10.1929 15.47 10.3314 15.4675C10.4699 15.4649 10.6064 15.4345 10.7328 15.378C10.8593 15.3215 10.9731 15.2401 11.0674 15.1388L16.5564 8.2775C16.7435 8.08294 16.8469 7.82275 16.8443 7.55282C16.8417 7.28288 16.7334 7.02471 16.5426 6.83375H16.5413Z" fill="#7E68C0" />
      </g>
      <defs>
        <clipPath id="clip0_433_4827">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}
