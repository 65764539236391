import ClaimedPlayerCard from '../ClaimedPlayerCard';
import { AthletesProps } from './home';

const Athletes: React.FC<AthletesProps> = ({ players }) => {
  if (!players) {
    return null;
  }

  return players.map((player, i) => (
    <ClaimedPlayerCard key={`player-${i + 1}`} player={player} isAdmin />
  ));
};

export default Athletes;
