/* global gapi */

import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { shd } from '@shd/jslib/models';

import * as Sentry from '@sentry/browser';
import { errorUtil, strogging } from '@shd/jslib/infra';
import { AppHostContext } from '../services/appHost';
import { analytics } from '../services/analytics';
import axios from './axios';

interface AuthResponse {
  error?: string;
  code?: string;
  scope: string;
}

export interface AuthStatus {
  phase: 'previously-completed' | 'in-progress' | 'completed' | 'error';
  error?: {
    message?: string;
    link?: string;
  };
  channel?: shd.PublishYT;
}

interface Props {
  teamId: string;
  onConnectionFinished?: (authStatus: AuthStatus) => void;
}
const useYoutubeConnection = ({ teamId, onConnectionFinished }: Props) => {
  const [status, setStatus] = useState<AuthStatus>();

  const appHost = useContext(AppHostContext);

  const finalizeConnection = useCallback(
    (authStatus: AuthStatus) => {
      setStatus(authStatus);
      onConnectionFinished && onConnectionFinished(authStatus);
    },
    [onConnectionFinished]
  );

  useEffect(() => {
    axios
      .get('/api/youtube_auth', { params: { teamId } })
      .then((res) => {
        setStatus({ channel: res.data, phase: 'previously-completed' });
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, [teamId]);

  const onSuccessfulSignIn = useCallback(
    (response: AuthResponse) => {
      strogging.log('useYoutubeConnection-onSuccessfulSignIn');
      if ('code' in response) {
        strogging.log('posting to /api/youtube_auth', {
          ...response,
          teamId,
          redirectUri: window.location.origin,
        });
        axios
          .post('/api/youtube_auth', {
            ...response,
            teamId,
            redirectUri: window.location.origin,
          })
          .then((res) => {
            analytics.track('Streaming Destination Linked', {
              destinationType: 'YouTube',
              teamId,
            });

            finalizeConnection({ channel: res.data, phase: 'completed' });
          })
          .catch((e) => {
            strogging.error('useYoutubeConnection-apiYoutubeAuth-exception', {
              err: errorUtil.errorMessage(e),
            });
            if (e && e.response) {
              strogging.log('useYoutubeConnection-apiYoutubeAuth-exception', {
                data: e.response.data,
              });
              finalizeConnection({ error: e.response.data, phase: 'error' });
            } else {
              finalizeConnection({
                error: { message: errorUtil.errorMessage(e) },
                phase: 'error',
              });
            }
          });
      } else if (response.error !== 'popup_closed_by_user') {
        finalizeConnection({
          error: { message: response.error },
          phase: 'completed',
        });
      } else {
        finalizeConnection({
          error: { message: 'no code found in response' },
          phase: 'completed',
        });
      }
    },
    [teamId, finalizeConnection]
  );

  const appHostService = appHost.isHosted ? appHost.service : undefined;

  const signIn = useCallback(async () => {
    if (appHostService) {
      try {
        setStatus({ phase: 'in-progress' });
        const response = await appHostService.requests.authorizeYoutube(teamId);
        onSuccessfulSignIn(response);
      } catch (e) {
        strogging.error('apphost-signIn', {
          err: errorUtil.errorMessage(e),
          ek: Object.keys(e as object),
        });
        setStatus({
          phase: 'completed',
          error: { message: errorUtil.errorMessage(e) },
        });
      }
    } else {
      const config = {
        response_type: 'code',
        scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
        access_type: 'offline',
        prompt: 'consent',
        cookiePolicy: 'single_host_origin',
      };
      strogging.log('useYoutubeConnection-signIn');
      setStatus({ phase: 'in-progress' });
      try {
        const auth = await new Promise<typeof gapi.auth2>((resolve, reject) => {
          const handle = setTimeout(
            () => reject(new Error('gapi.auth2 not loaded')),
            15000
          );
          window.gapi.load('auth2', () => {
            clearTimeout(handle);
            resolve(gapi.auth2);
          });
        });

        auth.authorize(config, onSuccessfulSignIn);
      } catch (e) {
        strogging.exception('useYoutubeConnection-signIn', e);
      }
    }
  }, [appHostService, onSuccessfulSignIn, teamId]);

  const cancelSignin = useCallback(() => {
    strogging.log('useYoutubeConnection-cancelSignin');
    if (appHostService) {
      appHostService.notifications.userCancelledYoutubeAuth();
    }
  }, [appHostService]);

  return useMemo(
    () => ({ signIn, status, cancelSignin }),
    [cancelSignin, signIn, status]
  );
};

export default useYoutubeConnection;
