import uaParser from 'ua-parser-js';

export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return true;
    }
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  },
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () =>
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i),
  any: () =>
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows(),
};

// lazily parse the user agent string and memoize the result
let parsed: UAParser.IResult;
export const parsedUserAgent = () => {
  if (!parsed) {
    parsed = uaParser(navigator.userAgent);
  }
  return parsed;
};

export const isiOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && navigator.maxTouchPoints > 1);

export const isWebkit = () => !!navigator.userAgent.match(/WebKit/i);

export const isSafari = () =>
  !!navigator.userAgent.match(/WebKit/i) &&
  !navigator.userAgent.match(/CriOS/i);

export const isFacebookBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
};

export const isInstagramBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('Instagram') > -1;
};

export const isEmbeddedBrowser = () =>
  isInstagramBrowser() || isFacebookBrowser();
