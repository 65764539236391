// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  BASEBALL,
  BASKETBALL,
  HOCKEY,
  LACROSSE,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
  WATER_POLO,
} from '../../../../constants/strings';

const options = [
  BASEBALL,
  BASKETBALL,
  HOCKEY,
  LACROSSE,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
  WATER_POLO,
];

export default options;
