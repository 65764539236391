import React, { useState } from 'react';
import { Box, Text, Button, Card } from 'grommet';
import { More, View, Radial } from 'grommet-icons';
import axios from '../../../helpers/axios';
import { errorUtil } from '@shd/jslib/infra';
import SHDLink from '../../../helpers/SHDLink';
import { SPORTS_MAP } from '../../../constants/strings';
import ActionConfirmationModal from './ActionConfirmationModal';
import { TeamCardProps } from '../admin.types';

const TeamCard: React.FC<TeamCardProps> = ({ team }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState<string>();
  const [currentAction, setCurrentAction] = useState<{
    action: () => void;
    text: string;
    actionName: string;
  } | null>(null);

  const archiveTeam = async () => {
    try {
      await axios.put(`/api/admin/team/${team.id}/archive`);
      team.isArchived = true;
      setShowConfirmation(false);
    } catch (e) {
      setError(errorUtil.errorMessage(e));
      setShowConfirmation(false);
    }
  };

  const deleteTeam = async () => {
    try {
      await axios.delete(`/api/admin/team/${team.id}`);
      setShowConfirmation(false);
    } catch (e) {
      setError(errorUtil.errorMessage(e));
      setShowConfirmation(false);
    }
  };
  return (
    <Card key={team.id} pad="small" gap="small">
      {showConfirmation && currentAction && (
        <ActionConfirmationModal
          action={currentAction.action}
          onClose={() => setShowConfirmation(false)}
          confirmationText={currentAction.text}
          actionName={currentAction.actionName}
        />
      )}
      {error && <Text color="status-error">{error}</Text>}
      <Box direction="row" justify="between">
        <Box key={team.name} direction="row" gap="small" align="center">
          <Box>{SPORTS_MAP[team.sportType]?.icon || <Radial />}</Box>
          <Text weight="bold">{team.name}</Text>
          {team.isArchived && <Text size="xsmall">Archived</Text>}
        </Box>
        <Box direction="row" gap="large">
          <SHDLink to={`/${team.handle}`} target="_blank">
            <View />
          </SHDLink>
          <SHDLink to={`/admin/team/${team.id}`} target="_blank">
            <More />
          </SHDLink>
        </Box>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <SHDLink to={`/${team.handle}`} target="_blank">
          <Text size="small" style={{ textDecoration: 'underline' }}>
            {team.id}
          </Text>
        </SHDLink>
        <SHDLink to={`/${team.handle}`} target="_blank">
          <Text
            size="small"
            style={{ textDecoration: 'underline' }}
          >{`/${team.handle}`}</Text>
        </SHDLink>
      </Box>
      <Box direction="row" gap="xsmall">
        <Button
          secondary
          size="small"
          label="Archive"
          disabled={team.isArchived}
          onClick={() => {
            const text = `Are you sure you want to archive ${team.name}?`;
            const actionName = 'Archive';
            setCurrentAction({ action: archiveTeam, text, actionName });
            setShowConfirmation(true);
          }}
        />
        <Button
          primary
          color="status-critical"
          size="small"
          label="Delete"
          onClick={() => {
            const text = `Are you sure you want to delete ${team.name}?`;
            const actionName = 'Delete';
            setCurrentAction({ action: deleteTeam, text, actionName });
            setShowConfirmation(true);
          }}
        />
      </Box>
    </Card>
  );
};

export default TeamCard;
