// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Box, Button, Layer, Text } from 'grommet';

import { observer } from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { IconContext } from 'react-icons';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { FormNext } from 'grommet-icons';
import axios from '../../helpers/axios';
import { usePlayerPrimaryInfo } from './playerHooks';
import PlayerCard from './PlayerCard';
import { withFirebase } from '../../services/Firebase';
import ClaimPlayerLayer from './ClaimPlayerLayer';

const Player = observer(
  ({ sport, isBBSB, playerId, gameId, history, onClose }) => {
    const [labelText, setLabelText] = useState('');
    const { playerInfo: playerPrimaryInfo, error: playerInfoError } =
      usePlayerPrimaryInfo(playerId);
    const [showClaim, setShowClaim] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (playerPrimaryInfo && playerPrimaryInfo.player) {
        setLoading(false);
        if (isBBSB) {
          setLabelText(
            `View ${playerPrimaryInfo.player.nameFirst}'s clips from this game`
          );
        } else {
          const params = {
            sport,
            gameIds: gameId ? [gameId] : [],
          };

          axios.get(`/api/player/${playerId}/clips`, { params }).then((res) => {
            const { clips } = res.data;
            setLabelText(
              `View ${playerPrimaryInfo.player.nameFirst}'s ${
                clips.length
              } clip${clips.length > 1 ? 's' : ''} from this game`
            );
          });
        }
      } else if (playerPrimaryInfo && playerPrimaryInfo.player === null) {
        // we are here when playerId is the teamPlayerId of a claimed player
        onClose();
      }

      if (playerInfoError) {
        onClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerPrimaryInfo, playerInfoError]);

    const claimPrompt = (
      <Box margin="small">
        <Box
          style={{
            backgroundImage: 'linear-gradient(to right, #7e68c0, #f9dc98)',
          }}
          direction="row"
          pad="medium"
          align="center"
          justify="between"
          round="small"
          onClick={() => setShowClaim(true)}
        >
          <Box direction="row" align="center" gap="small">
            <IconContext.Provider value={{ color: 'white' }}>
              <AiFillQuestionCircle />
            </IconContext.Provider>
            <Text weight="bold" color="white" size="large">
              Is this you? Claim this player
            </Text>
          </Box>
          <Box>
            <FormNext color="white" />
          </Box>
        </Box>
      </Box>
    );

    const playerCardSkeleton = (
      <Box pad="medium" gap="medium">
        <Box gap="medium" pad="medium" background="light-3">
          <Box direction="row" gap="medium">
            <Skeleton width={100} height={100} />
            <Box direction="column" gap="small" width="medium">
              <Skeleton height={78} />
              <Skeleton height={45} />
            </Box>
          </Box>
          <Skeleton height={60} />
          <Skeleton height={60} />
        </Box>
        <Skeleton height={50} />
        <Skeleton height={50} />
      </Box>
    );

    return (
      <Layer
        position="bottom"
        responsive={false}
        onClickOutside={onClose}
        full="horizontal"
      >
        <Box fill="horizontal" align="center">
          <Box width="large" round>
            {loading && playerCardSkeleton}
            {!loading && playerPrimaryInfo && playerPrimaryInfo.player && (
              <>
                {!playerId.includes('CpSh01-') && claimPrompt}
                <PlayerCard
                  history={history}
                  player={playerPrimaryInfo.player}
                  image={playerPrimaryInfo.player.DNOR_imgUrlT150}
                  name={`${playerPrimaryInfo.player.nameFirst} ${playerPrimaryInfo.player.nameLast}`}
                  teams={playerPrimaryInfo.teams}
                />
                <Box pad="medium" gap="medium">
                  <Button
                    primary
                    fill
                    size="large"
                    color="tertiary-1"
                    label={labelText}
                    onClick={() => {
                      if (isBBSB) {
                        history.push({
                          pathname: `/${playerId}`,
                          search: `?${new URLSearchParams({
                            sport: 'bbsb',
                          }).toString()}`,
                        });
                      } else {
                        history.push({
                          pathname: `/${playerId}`,
                          search: `?${new URLSearchParams({
                            sport,
                            gameIds: [gameId],
                          }).toString()}`,
                        });
                      }
                    }}
                  />
                  <Button
                    secondary
                    fill
                    size="large"
                    color="tertiary-1"
                    label="Game summary and play-by-play"
                    onClick={onClose}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        {showClaim && (
          <ClaimPlayerLayer
            team={playerPrimaryInfo.teams[0]} // seems sus
            teamPlayerId={playerId}
            closeLayer={() => setShowClaim(false)}
          />
        )}
      </Layer>
    );
  }
);

const PlayerPreview = compose(withRouter, withFirebase)(Player);

export default PlayerPreview;
