// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import { Box, Text } from 'grommet';
import { FormPrevious } from 'grommet-icons';

import { HeaderWrapper, BackButton } from './style';
import { useQuery } from '../../../../helpers/utils';

const PlayerHeader = ({
  currentStep = 1,
  buttonLabel = 'Back',
  buttonAction,
}) => {
  const query = useQuery();
  const fromPlayerList = !!+query.get('from_player_list');

  useEffect(() => () => {
    query.delete('from_player_list');
    window.history.pushState(null, '', query.toString());
  });

  return (
    <HeaderWrapper flex direction="row" justify="between" align="center">
      <BackButton
        icon={<FormPrevious />}
        onClick={buttonAction}
        color="dark-1"
        label={<Text style={{ fontSize: '14px' }}>{buttonLabel}</Text>}
        weight="bold"
        size="medium"
      />
      <Box>
        {fromPlayerList && (
          <Text color="dark-1" size="small">{`Step ${currentStep} of 2`}</Text>
        )}
      </Box>
    </HeaderWrapper>
  );
};

export default PlayerHeader;
