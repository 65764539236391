import React from 'react';
import SLHlsPlayer from '../../components/HLS/SLHlsPlayer';
import { Box } from 'grommet';
import SidelineLiveStatus from './SidelineLiveStatusScreen';
import { CameraStatus, LiveOnPlatformProps } from './sidelineLive.types';

const SidelineContainer: React.FC<LiveOnPlatformProps> = ({
  hls_link,
  isPlayable = false,
  trackEvent,
  camera_status,
  isGameEnded = false,
}) => {
  return (
    <Box width="auto">
      {isPlayable && camera_status === CameraStatus.Live ? (
        <SLHlsPlayer
          key={`slhlsplayer`}
          hlsInfo={{ type: 'uri', uri: `${hls_link}` }}
          trackEvent={trackEvent}
        />
      ) : (
        <SidelineLiveStatus
          isPlayable={isPlayable}
          isGameEnded={isGameEnded}
          camera_status={camera_status || CameraStatus.Live}
        />
      )}
    </Box>
  );
};

export default React.memo(SidelineContainer);
