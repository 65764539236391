// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { Text, Box, Tag, Avatar, Button } from 'grommet';
import { MdOutlineSportsVolleyball } from 'react-icons/md';
import { RotateLeft, RotateRight } from 'grommet-icons';
import { observer } from 'mobx-react';
import { VBScoringContext } from './context';
import Toast from '../../components/Toast';
import { compareJerseyNum } from '../../helpers/utils';

const getDisplayName = (nameFirst, nameLast) =>
  `${nameFirst} ${nameLast ? nameLast[0] : ''}`;

const Sub = ({ player, playerOut, onSubSelected, isSmallScreen, isLibero }) => (
  <Box
    key={`${player._id}-sub`}
    basis="1/4"
    margin={{ bottom: 'xsmall' }}
    align="center"
    onClick={() => onSubSelected(player.index)}
    style={{
      pointerEvents: playerOut._id === player._id ? 'none' : 'all',
      opacity: playerOut._id === player._id ? '0.25' : '1',
    }}
  >
    {isLibero && (
      <Box
        margin={{ bottom: '-1.2rem', right: '2.5rem' }}
        style={{ zIndex: '10' }}
        background="status-critical"
        round="full"
        width="1.2rem"
        height="1.2rem"
        align="center"
        justify="center"
      >
        <Text weight="bold">L</Text>
      </Box>
    )}
    <Box
      round="full"
      background="light-1"
      width={isSmallScreen ? 'xxsmall' : '64px'}
      height={isSmallScreen ? 'xxsmall' : '64px'}
      align="center"
      justify="center"
      border={{ color: 'primary-1', size: 'small' }}
    >
      <Text weight="bold" size={isSmallScreen ? 'xlarge' : '3xl'}>
        {player.jerseyNum}
      </Text>
    </Box>
    <Text size={isSmallScreen ? 'small' : 'medium'} truncate>
      {getDisplayName(player.nameFirst, player.nameLast)}
    </Text>
  </Box>
);

const SubsContainer = ({
  isSmallScreen,
  suggestedPlayers,
  players,
  selectedIndex,
  indexToPosition,
  onSubSelected,
  closeSubModal,
}) => {
  const scoringStore = useContext(VBScoringContext);
  const playerOut = players[indexToPosition[selectedIndex]];

  let playerDisplay = `#${playerOut.jerseyNum} ${getDisplayName(
    playerOut.nameFirst,
    playerOut.nameLast
  )}`;
  if (playerOut.jerseyNum === '?' && playerOut.nameFirst === 'Placeholder') {
    playerDisplay = 'Placeholder';
  }

  // players not in suggestedPlayers
  const otherPlayers = players.filter(
    (p) => !suggestedPlayers.map((sp) => sp._id).includes(p._id)
  );

  return (
    <Box
      id="subs-container"
      full="horizontal"
      background="light-1"
      pad="xsmall"
    >
      <Box
        justify="between"
        direction="row"
        gap="medium"
        flex={{ grow: 1, shrink: 0 }}
      >
        <Text margin={{ vertical: 'none' }} alignSelf="center">
          Select a sub / swap for <Text weight="bold">{playerDisplay}</Text>
        </Text>
        <Button
          alignSelf="center"
          plain
          color="tertiary-1"
          label="Cancel"
          onClick={closeSubModal}
        />
      </Box>
      {suggestedPlayers.length > 0 && (
        <Box
          id="subs-suggested-container"
          pad={{ top: 'medium' }}
          direction="row"
          align="center"
          overflow="scroll"
          background="light-2"
          wrap
        >
          {suggestedPlayers.map((player) => (
            <Sub
              key={player._id}
              player={player}
              playerOut={playerOut}
              onSubSelected={onSubSelected}
              isSmallScreen={isSmallScreen}
              isLibero={(scoringStore.liberos || [])
                .map((p) => p._id)
                .includes(player._id)}
            />
          ))}
        </Box>
      )}
      <Box
        id="subs-sub-container"
        pad={{ top: 'medium' }}
        direction="row"
        align="center"
        overflow="scroll"
        wrap
      >
        {otherPlayers.length > 0 &&
          otherPlayers
            .slice(6)
            .filter((player) => {
              if (![0, 1, 2].includes(selectedIndex)) {
                return true;
              }
              return !(scoringStore.liberos || [])
                .map((p) => p._id)
                .includes(player._id);
            })
            .map((player) => (
              <Sub
                key={player._id}
                player={player}
                playerOut={playerOut}
                onSubSelected={onSubSelected}
                isSmallScreen={isSmallScreen}
                isLibero={(scoringStore.liberos || [])
                  .map((p) => p._id)
                  .includes(player._id)}
              />
            ))}
      </Box>
    </Box>
  );
};

const CourtPlayersScore = ({
  // eslint-disable-next-line no-unused-vars
  players,
  loggingPlayerStat,
  promptLiberoSub,
  onAddStat,
  onSkip,
  onCancel,
  isSmallScreen,
  onToggleSub,
}) => {
  const scoringStore = useContext(VBScoringContext);

  const [toast, setToast] = useState(null);

  const [startedSub, setStartedSub] = useState(false);
  const [selectedSubIndex, setSelectedSubIndex] = useState();

  const [selectedIndices, setSelectedIndices] = useState([]);

  // maps...
  // INDICES
  // 0 1 2
  // 3 4 5
  // to
  // POSITION
  // 3 2 1
  // 4 5 0

  // players array is ordered by POSITION

  const indexToPosition = [3, 2, 1, 4, 5, 0].concat(
    players.slice(6).map((_, i) => i + 6)
  );

  const rotateLineup = (isClockwise) => () => {
    scoringStore.handleEvent('rotate', { isClockwise });
  };

  const closeSubModal = () => {
    setStartedSub(false);
    setSelectedSubIndex(null);
    onToggleSub(false);
    scoringStore.promptLiberoSub = false;

    // if a libero prompt sub was cancelled, keep track of it.
    // this allows us to know if the libero serves or not
    if (promptLiberoSub) {
      scoringStore.handleEvent('cancelLiberoSub', {
        playerOut: players[indexToPosition[promptLiberoSub.subOutIndex]],
        position: indexToPosition[promptLiberoSub.subOutIndex],
      });
    }
  };

  const onSubSelected = (i) => {
    if (startedSub) {
      // swap positions
      const playerOut = players[indexToPosition[selectedSubIndex]];
      const playerIn = players[indexToPosition[i]];

      if (playerOut._id !== playerIn._id) {
        scoringStore.handleEvent('sub', {
          playerOut,
          playerIn,
          position: indexToPosition[selectedSubIndex],
        });
        setSelectedSubIndex(null);
        setStartedSub(false);
        onToggleSub(false);
      } else {
        closeSubModal();
      }
    } else {
      setSelectedSubIndex(i);
      setStartedSub(true);
    }
  };

  const onPlayerSelected = (i) => {
    if (selectedIndices.includes(i)) {
      setSelectedIndices(selectedIndices.filter((index) => index !== i));
    } else {
      setSelectedIndices([...selectedIndices, i]);
    }
  };

  useEffect(() => {
    if (startedSub) {
      const mappedPlayers = players.map((p, playerIndex) => {
        const newPlayer = { ...p, index: playerIndex };
        return newPlayer;
      });

      const onCourtPlayers = mappedPlayers.slice(0, 6);
      const benchPlayers = mappedPlayers.slice(6);

      const actualBenchPlayers = benchPlayers
        .filter((p) => {
          const isPlaceholder =
            p.jerseyNum !== '?' && p.nameFirst !== 'Placeholder';
          return isPlaceholder;
        })
        .sort(compareJerseyNum);
      const placeholderBenchPlayers = benchPlayers.filter(
        (p) => p.jerseyNum === '?' && p.nameFirst === 'Placeholder'
      );

      const reorderedPlayers = [...onCourtPlayers, ...actualBenchPlayers];
      if (placeholderBenchPlayers.length) {
        reorderedPlayers.push(placeholderBenchPlayers[0]);
      }

      let suggestedPlayers = [];
      if (
        promptLiberoSub?.suggestedPlayerIds &&
        promptLiberoSub.suggestedPlayerIds.length
      ) {
        suggestedPlayers = mappedPlayers.filter((player) => {
          const isSuggested = promptLiberoSub.suggestedPlayerIds.includes(
            player._id
          );
          const onCourtPlayerIds = onCourtPlayers.map((p) => p._id);
          const isPlayerOnCourt = onCourtPlayerIds.includes(player._id);
          return isSuggested && !isPlayerOnCourt;
        });
      }

      const subsContainer = (
        <SubsContainer
          isSmallScreen={isSmallScreen}
          suggestedPlayers={suggestedPlayers}
          players={reorderedPlayers}
          selectedIndex={selectedSubIndex}
          indexToPosition={indexToPosition}
          onSubSelected={onSubSelected}
          closeSubModal={closeSubModal}
        />
      );
      onToggleSub(subsContainer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startedSub, players]);

  useEffect(() => {
    if (scoringStore.promptLiberoSub && !startedSub && !loggingPlayerStat) {
      // find the index of the libero that needs to be prompted to be subbed out
      // should always prompt just for the player just entering the front row
      onSubSelected(scoringStore.promptLiberoSub.subOutIndex);
    }

    // else {
    //   closeSubModal();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringStore.promptLiberoSub]);

  useEffect(() => {
    if (scoringStore.liberoSubData && !loggingPlayerStat) {
      setToast({
        label: 'Libero swapped',
        background: 'status-ok',
      });

      const { playerOut, playerIn } = scoringStore.liberoSubData;

      if (playerOut._id !== playerIn._id) {
        // really annoying race condition that i do not understand
        setTimeout(() => {
          scoringStore.handleEvent('liberoAutoSub', {
            playerOut,
            playerIn,
          });
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringStore.liberoSubData]);

  const renderPlayer = (player, i) => {
    if (player === '') {
      return <Avatar background="dark-1">P</Avatar>;
    }
    const showCircleBorder =
      i === selectedSubIndex || selectedIndices.includes(i);
    const isLibero = (scoringStore.liberos || [])
      .map((p) => p._id)
      .includes(player._id);
    return (
      <Box
        height="100%"
        align="center"
        key={`${i + 1}-${player.nameLast}`}
        width="small"
        // background={scoringStore.liberos.map((p) => p._id).includes(player._id) ? 'secondary-1' : 'light-1'}
      >
        <Box
          align="center"
          key={player.name}
          onClick={() => {
            if (loggingPlayerStat) {
              if (['block', 'highlight'].includes(loggingPlayerStat.stat)) {
                onPlayerSelected(i);
              } else {
                onAddStat([i]);
              }
            } else {
              onSubSelected(i);
            }
          }}
        >
          {i === 5 && (
            <Box
              margin={{ bottom: '-1.2rem', right: '-2.5rem' }}
              style={{ zIndex: '10' }}
              background="primary-6"
              round="full"
              width="1.2rem"
              height="1.2rem"
              align="center"
              justify="center"
            >
              <MdOutlineSportsVolleyball color="white" />
            </Box>
          )}
          {isLibero && (
            <Box
              margin={{ bottom: '-1.2rem', right: '2.5rem' }}
              style={{ zIndex: '10' }}
              background="status-critical"
              round="full"
              width="1.2rem"
              height="1.2rem"
              align="center"
              justify="center"
            >
              <Text weight="bold">L</Text>
            </Box>
          )}
          <Box
            border={
              showCircleBorder ? { color: 'primary-1', size: 'small' } : false
            }
            round="full"
            background="light-1"
            width={isSmallScreen ? 'xxsmall' : '64px'}
            height={isSmallScreen ? 'xxsmall' : '64px'}
            align="center"
            justify="center"
          >
            <Text weight="bold" size={isSmallScreen ? 'xlarge' : '3xl'}>
              {player.jerseyNum}
            </Text>
          </Box>

          <Text size="small" align="center" truncate>
            {getDisplayName(player.nameFirst, player.nameLast)}
          </Text>
        </Box>
      </Box>
    );
  };

  return (
    <Box id="players-on-court" gap="xsmall" flex={{ grow: 1, shrink: 0 }}>
      <Box
        margin={{ top: 'small' }}
        pad={{ bottom: 'small' }}
        background="light-1"
        round="small"
      >
        <Tag
          size="small"
          alignSelf="center"
          background="light-1"
          margin={{ top: '-10px' }}
          pad={{ horizontal: loggingPlayerStat ? 'small' : 'xlarge' }}
          name={
            loggingPlayerStat
              ? `Tap player${
                  ['block', 'highlight'].includes(loggingPlayerStat.stat)
                    ? 's'
                    : ''
                } to tag`
              : 'NET'
          }
        />
        <Box direction="row" fill="horizontal" justify="between">
          {[players[3], players[2], players[1]].map((player, i) =>
            renderPlayer(player, i)
          )}
        </Box>
        <Box
          direction="row"
          fill="horizontal"
          justify="between"
          margin={{ top: 'small' }}
        >
          {[players[4], players[5], players[0]].map((player, i) =>
            renderPlayer(player, i + 3)
          )}
        </Box>
        {loggingPlayerStat && (
          <Box
            direction="row"
            alignSelf="center"
            gap="xsmall"
            pad={{ horizontal: 'xlarge' }}
            margin={{ top: 'small', bottom: '-24px' }}
          >
            <Button
              size="medium"
              alignSelf="center"
              color="tertiary-1"
              label="Cancel"
              onClick={onCancel}
            />
            {selectedIndices.length > 0 ? (
              <Button
                primary
                size="medium"
                alignSelf="center"
                color="tertiary-1"
                label="Tag"
                onClick={() => {
                  onAddStat(selectedIndices);
                  setSelectedIndices([]);
                }}
              />
            ) : (
              <Button
                primary
                size="medium"
                alignSelf="center"
                color="tertiary-1"
                label="Skip"
                onClick={onSkip}
              />
            )}
          </Box>
        )}
      </Box>
      <Box direction="row" justify="between" margin={{ bottom: 'small' }}>
        <Text size="xsmall">
          {loggingPlayerStat || startedSub ? '' : 'Tap a player to sub'}
        </Text>
        <Box>
          {loggingPlayerStat ? null : (
            <Box direction="row" align="center" gap="medium">
              <Button onClick={rotateLineup(true)}>
                <Box
                  background="secondary-6"
                  round="medium"
                  pad={{ horizontal: 'large', vertical: 'small' }}
                >
                  <RotateRight size="small" color="black" />
                </Box>
              </Button>
              <Button onClick={rotateLineup(false)}>
                <Box
                  background="secondary-6"
                  round="medium"
                  pad={{ horizontal: 'large', vertical: 'small' }}
                >
                  <RotateLeft size="small" color="black" />
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Box>
  );
};

export default observer(CourtPlayersScore);
