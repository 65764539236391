import styled from 'styled-components';

export const LiveList = styled.ul`
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  color: #808080;
`;

export const LiveListItem = styled.li`
  list-style: none;
  text-transform: uppercase;
  display: inline;
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:after {
    content: '\\00b7 ';
    margin: 0 0.25em;
  }

  &:last-child:after {
    content: none;
  }
`;

export const sidelineLive = {
  fontFamily: 'Messina Sans',
  color: '#1A1A1A',
  fontStyle: 'normal',
};

export const liveLinks = {
  background: '#111',
  color: '#fff',
};

export const gameInfoSm = {
  fontWeight: 'bolder',
  lineHeight: '1.75rem',
  fontSize: '1.25rem',
};

export const gameInfoLg = {
  fontWeight: 'bolder',
  lineHeight: '2rem',
  fontSize: '1.7rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
};

export const gameInfoVsSm = {
  ...gameInfoSm,
  display: 'inline',
};

export const gameInfoSubSm = {
  fontSize: '1rem',
};

export const gameInfoSubLg = {
  fontSize: '1.25rem',
};

export const gameInfoNavSm = {
  fontSize: '.75rem',
  fontWeight: 'bolder',
};

export const gameInfoNavLg = {
  fontSize: '1rem',
  fontWeight: 'bolder',
};

export const gameInfoNavSubSm = {
  fontSize: '0.75rem',
};

export const gameInfoNavSubLg = {
  fontSize: '1rem',
};

export const buttonStyle = {
  color: '#fff',
  background: '#000',
  borderRadius: '6.25rem',
  padding: '0.625rem 1rem',
};

export const stickyFooter = {
  display: 'flex',
  justifyContent: 'space-between',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  margin: '0',
  backgroundColor: '#fff',
  padding: '1.5rem',
  border: '1px solid #E5E5E5',
};

export const playerContainer = {
  justifyContent: 'center',
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
};

export const statusScreenLogo = {
  height: '68px',
  marginBottom: '12px',
  transform: 'translateX(-6px)',
  width: '69px',
};

export const statusScreen = {
  minWidth: '100%',
  width: 'auto',
  backgroundColor: '#000',
};

export const liveLinksText = {
  //   fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: '700',
  padding: '.5rem',
};
