// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';
import { Text, Layer, Heading, Box, RadioButton } from 'grommet';
import { Edit } from 'grommet-icons';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import SHDButton from '../../components/SHD/Button';
import MakeSubstitution from './MakeSubstitution';
import EditLineup from './EditLineup';
import { ScoringContext } from './context';

const BatterSelector = observer(({ onClose = () => {}, mandatory = false }) => {
  const scoringStore = useContext(ScoringContext);
  const {
    awayHome,
    lineupQueueIndexes,
    lineups,
    isQuickStartMode,
    handleEvent,
  } = scoringStore;
  const originalIndex = lineupQueueIndexes[awayHome];
  const [index, setIndex] = useState(originalIndex);
  const [showSubs, setShowSubs] = useState(false);
  const [showEditLineup, setShowEditLineup] = useState(false);

  const renderContent = () => {
    const lineup = toJS(lineups[awayHome]);

    if (isQuickStartMode && lineup.length < index + 1) {
      return (
        <MakeSubstitution
          isQuickStartModePrompt
          closeSubs={onClose}
          mandatory={mandatory}
        />
      );
    }

    if (showSubs) {
      return (
        <MakeSubstitution
          playerToSubOutIndex={index}
          closeSubs={() => setShowSubs(false)}
        />
      );
    }

    if (showEditLineup) {
      return (
        <EditLineup
          onClose={() => setShowEditLineup(false)}
          setIndexLocal={setIndex}
        />
      );
    }

    return (
      <Layer pad={{ vertical: 'medium' }}>
        <Box
          height={`${window.innerHeight}px`}
          pad="medium"
          gap="medium"
          style={{ overflow: 'auto' }}
        >
          <Box fill="horizontal" align="end" pad="medium">
            <Text color="tertiary-1" weight="bold" onClick={onClose}>
              Back
            </Text>
          </Box>
          <Heading level="3">Change current batter</Heading>
          <Box
            direction="row"
            gap="medium"
            justify="start"
            height={{ min: 'initial' }}
          >
            <Box direction="column" gap="medium">
              {lineup.map((_, i) => (
                <Box
                  key={`batter-${i + 1}`}
                  height={{ min: '48px', max: '48px' }}
                  justify="center"
                >
                  <Text weight="bold">{i + 1}</Text>
                </Box>
              ))}
            </Box>
            <Box gap="medium" height={{ min: 'initial' }} flex>
              {lineup.map((player, i) =>
                i === index ? (
                  <Box
                    direction="row"
                    align="center"
                    key={player._id}
                    border={{ color: 'secondary-1', size: 'xsmall' }}
                    style={{ borderRadius: '8px' }}
                  >
                    <Box
                      background="secondary-1"
                      pad="medium"
                      direction="row"
                      justify="between"
                      align="center"
                      style={{ borderRadius: '7px 0px 0px 7px' }}
                      flex
                    >
                      <RadioButton
                        checked
                        label={player.displayName}
                        name="batter"
                        readOnly
                      />
                    </Box>
                    <Box
                      background="background-back"
                      pad="medium"
                      direction="row"
                      justify="center"
                      align="center"
                      onClick={() => setShowSubs(true)}
                      style={{ borderRadius: '0px 7px 7px 0px' }}
                      flex="shrink"
                      fill="vertical"
                    >
                      <Text size="large" weight="bold">
                        Sub
                      </Text>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    background="secondary-7"
                    onClick={() => setIndex(i)}
                    pad="medium"
                    direction="row"
                    justify="between"
                    align="center"
                    key={player._id}
                    style={{ borderRadius: '8px' }}
                  >
                    <RadioButton
                      checked={false}
                      label={player.displayName}
                      name="batter"
                      readOnly
                    />
                  </Box>
                )
              )}
            </Box>
          </Box>
          <Box align="start" height={{ min: 'initial' }}>
            <Box
              hoverIndicator
              onClick={() => setShowEditLineup(true)}
              direction="row"
              align="center"
              gap="small"
              fill={false}
              pad="medium"
            >
              <Edit color="tertiary-1" />
              <Text color="tertiary-1">Edit lineup</Text>
            </Box>
          </Box>
          <Box pad={{ top: 'large' }} height={{ min: 'small' }}>
            <SHDButton
              label="Select"
              size="large"
              primary
              disabled={index === originalIndex}
              onClick={() => {
                handleEvent('lineupIndex', index);
                onClose();
              }}
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Layer>
    );
  };

  return renderContent();
});

export default BatterSelector;
