import React, { useCallback, useEffect, useState } from 'react';
import VBPlayerClipsSection from './VBPlayerClipsSection';
import GenericPlayerClipsSection from './GenericPlayerClipsSection';
import { GENERIC_SPORTS_KEYS } from '../../constants/strings';
import { shd } from '@shd/jslib/models';
import axios from '../../helpers/axios';
import * as Sentry from '@sentry/browser';
import BBSBPlayerClipsSection from './BBSBPlayerClipsSection';
import { PlayerClipsSectionProps } from './player.types';
import { useToast } from '../../contexts/ToastContext';

const PlayerClipsSection: React.FC<PlayerClipsSectionProps> = ({
  teams,
  selectedSport,
  playerId,
  player,
  isAdmin,
  pinClip,
  unpinClip,
  pinnedClips,
  showReelsTab,
  claimed,
  onShowClaim,
}) => {
  const { showToast } = useToast();
  const [games, setGames] = useState([]);
  const [localPinnedClips, setLocalPinnedClips] = useState(pinnedClips);

  const getNonBBSBData = useCallback(
    async (sport = '') => {
      const earliestTs = 2400000000;

      const params = {
        start_ts: earliestTs,
        role: sport, // TODO: fix this bad naming. probably needs backend work
        teamId: teams.length > 0 ? teams[0]._id : '',
      };

      try {
        // This is the existing endpoint. Don't break non-BBSB
        const response = await axios.get(`/api/player/${playerId}/games`, {
          params,
        });

        const newGames = response.data;
        setGames(newGames);
      } catch (respError) {
        // setClipsError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);

        return false;
      }

      return true;
    },
    [teams, playerId]
  );

  useEffect(() => {
    if (selectedSport !== 'bbsb') {
      getNonBBSBData(selectedSport);
    } else {
    }
  }, [getNonBBSBData, selectedSport]);

  useEffect(() => {
    setLocalPinnedClips(pinnedClips);
  }, [pinnedClips]);

  if (teams.length === 0) {
    return null;
  }

  if (selectedSport === 'vb') {
    return (
      <VBPlayerClipsSection
        {...{
          playerId,
          player,
          teamGames: games,
          pinClip: isAdmin ? pinClip : null,
          unpinClip: isAdmin ? unpinClip : null,
          pinnedClips: localPinnedClips,
          isAdmin,
          setToast: (toast: shd.Toast) => {
            showToast({ ...toast, message: toast.label });
          },
          showReelsTab,
          claimed,
        }}
      />
    );
  }

  if (GENERIC_SPORTS_KEYS.includes(selectedSport)) {
    return (
      <GenericPlayerClipsSection
        {...{
          playerId,
          player,
          sport: selectedSport,
          teamGames: games,
          pinClip: isAdmin ? pinClip : null,
          unpinClip: isAdmin ? unpinClip : null,
          pinnedClips: localPinnedClips,
          isAdmin,
          setToast: (toast: shd.Toast) => {
            showToast({ ...toast, message: toast.label });
          },
          showReelsTab,
          claimed,
        }}
      />
    );
  }

  return (
    player && (
      <BBSBPlayerClipsSection
        playerId={playerId}
        player={player}
        isAdmin={isAdmin}
        teams={teams}
        claimed={claimed}
        showReelsTab={showReelsTab}
        pinnedClips={localPinnedClips}
        pinClip={pinClip}
        unpinClip={unpinClip}
        onShowClaim={onShowClaim}
      />
    )
  );
};

export default PlayerClipsSection;
