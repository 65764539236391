// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { Box, Text, Footer, Layer, Header, Button } from 'grommet';
import './scoring.css';
import {
  CaretDownFill,
  FormClose,
  Undo,
  Video,
  Volume,
  VolumeMute,
} from 'grommet-icons';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import { AiOutlineQrcode } from 'react-icons/ai';
import { GiRoundStar } from 'react-icons/gi';
import { withFirebase } from '../../services/Firebase';
import PlusMinus from './PlusMinusV2';

import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';

import { GenericScoringContext } from './context';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';

// import GenericSubstitutions from './GenericSubstitutions';
import { useInterval } from '../../helpers/utils';
import {
  BASKETBALL,
  HOCKEY,
  LACROSSE,
  SOCCER,
  SPORTS_MAP,
  WATER_POLO,
} from '../../constants/strings';
import Soccer from './SportSpecific/Soccer';
import GenericPrompt from './GenericPromptV2';
import GenericSettings from './GenericSettings';
import Basketball from './SportSpecific/Basketball';
import Lacrosse from './SportSpecific/Lacrosse';
import Hockey from './SportSpecific/Hockey';
import WaterPolo from './SportSpecific/WaterPolo';
import ToastStack from '../../components/ToastStack';
import LivestreamLinksPage from './LivestreamLinksPage';
import { analytics } from '../../services/analytics';

const footerStyles = {
  width: '100%',
  maxWidth: '600px',
  position: 'fixed',
  bottom: 0,
  zIndex: 10,
};

const headerStyles = {
  width: '100%',
  maxWidth: '600px',
  // position: 'relative',
  position: 'fixed',
  top: 0,
};

const Container = ({
  containerRef,
  setShowExitScoring,
  undoClicked,
  tabIndex,
  setTabIndex,
  setShowQrModal,
  setShowStreamStatsModal,
  toggleMuted,
  ...containerProps
}) => (
  <Box
    ref={containerRef}
    direction="column"
    data-testid="scoring"
    justify="between"
    // height={`${window.innerHeight}px`}
    style={{ maxWidth: '600px' }}
    margin="auto"
    {...containerProps}
  >
    <Box fill="vertical">
      <Header
        background="brand"
        // pad={containerProps.isSmallScreen ? 'small' : 'medium'}
        pad={'small'}
        style={headerStyles}
      >
        <SHDButton
          id="exit-scoring-button"
          plain
          color="white"
          label={<Text color="white">Exit</Text>}
          mixtrack={['Generic Scoring Exit Clicked']}
          icon={<FormClose color="white" />}
          onClick={() => setShowExitScoring(true)}
        />
        {containerProps.streamStats && (
          <Box direction="row" align="center" fill="vertical" gap="xsmall">
            <Box
              align="center"
              gap="xsmall"
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              background={
                containerProps.streamStats.isHealthy
                  ? 'status-ok'
                  : 'status-critical'
              }
              round="small"
              direction="row"
              onClick={() => {
                setShowStreamStatsModal(true);
              }}
            >
              {/* <Wifi
                size="small"
                color={containerProps.streamStats.isHealthy ? 'status-ok' : 'status-critical'}
              />
              <Text
                size="small"
                color={containerProps.streamStats.isHealthy ? 'status-ok' : 'status-critical'}
              >
                {containerProps.streamStats.isHealthy ? 'Healthy' : 'Unhealthy'}
              </Text> */}
              <Video color="white" />
            </Box>
          </Box>
        )}
        <SHDButton
          plain
          color="white"
          label={<Text color="white">Undo</Text>}
          mixtrack={['Generic Scoring Undo']}
          icon={<Undo color="white" />}
          onClick={undoClicked}
        />
      </Header>
      {containerProps.children}
    </Box>
    <Footer background="brand" pad="small" style={footerStyles}>
      <Box style={{ position: 'absolute', left: '8px' }}>
        <Button
          icon={
            containerProps.muted ? (
              <VolumeMute size="small" />
            ) : (
              <Volume size="small" />
            )
          }
          label={
            <Text size="small">
              {containerProps.muted ? 'Muted' : 'Unmuted'}
            </Text>
          }
          plain
          onClick={toggleMuted}
        />
      </Box>
      <Box
        gap="medium"
        justify="center"
        align="center"
        direction="row"
        fill="horizontal"
      >
        <Box
          round="8px"
          background={!tabIndex ? 'primary-5' : 'primary-1'}
          align="center"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          onClick={() => setTabIndex(0)}
        >
          <Text textAlign="center">Score</Text>
        </Box>
        <Box
          className="settings-label"
          round="8px"
          background={tabIndex === 1 ? 'primary-5' : 'primary-1'}
          align="center"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          onClick={() => setTabIndex(1)}
        >
          <Text textAlign="center">Settings</Text>
        </Box>
      </Box>
      <Box
        direction="row"
        align="center"
        fill="vertical"
        gap="xsmall"
        style={{ position: 'absolute', right: '8px' }}
      >
        <Button
          plain
          size="small"
          label={<Text size="small">Share</Text>}
          icon={<AiOutlineQrcode />}
          onClick={() => {
            analytics.track('sidelineSCORE: Share Modal Opened', {
              sport: SPORTS_MAP[containerProps.team.attrib_sportType].key,
              teamId: containerProps.teamId,
              gameId: containerProps.gameId,
            });
            setShowQrModal(true);
          }}
        />
      </Box>{' '}
    </Footer>
  </Box>
);

const ScoringScreenBase = observer((props) => {
  const { firebase, match } = props;
  const containerRef = useRef(null);

  const userStore = useContext(UserContext);
  const scoringStore = useContext(GenericScoringContext);
  const [team, setTeam] = useState(
    userStore.selectedTeam || userStore.scoringTeam
  );
  const [gameEventTypes, setGameEventTypes] = useState();
  const [showAwayHomePrompt, setShowAwayHomePrompt] = useState(false);
  const [error, setError] = useState();
  const [game, setGame] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [showExitScoring, setShowExitScoring] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [showPeriodModal, setShowPeriodModal] = useState(false);
  const [streamStats, setStreamStats] = useState();
  const [muted, setMuted] = useState();
  const [showStreamStatsModal, setShowStreamStatsModal] = useState(false);
  const [toasts, setToasts] = useState([]);
  const { gameId: scoreGameId, teamId } = match.params;

  const isPeriodBreak =
    scoringStore.scoreboard && !scoringStore.scoreboard.isCurrentPeriodStarted;

  const getStreamHealth = () => {
    if (scoringStore.teamId) {
      axios
        .get(`/api/stream_health/${scoringStore.teamId}`)
        .then(({ data }) => {
          setStreamStats(data);
        })
        .catch(() => {});
    }
  };

  useInterval(getStreamHealth, 30000);

  const removeToast = useCallback((toastId) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== toastId)
    );
  }, []);

  useEffect(() => {
    if (scoringStore.teamId) {
      getStreamHealth();
      firebase.subscribeToTeam(scoringStore.teamId, (snapshot, err = null) => {
        if (snapshot) {
          setMuted(
            snapshot.muteIsMuted !== undefined ? snapshot.muteIsMuted : false
          );
        } else {
          Sentry.captureMessage(err);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringStore.teamId]);

  const exitScoring = () => {
    const body = {
      teamId,
      teamGameId: `GmSh01-${scoreGameId}`,
      teamGameUpdate: {
        scoreGameState: 40,
        scheduleState: 40,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        props.history.replace('/scoring');
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        props.history.replace('/scoring');
      });
  };

  const initGameState = () => {
    const teamGamePromise = new Promise((resolve, reject) => {
      const params = {
        isForScoringScreen: true,
      };
      axios
        .get(`/api/scoring_game/GmSh01-${scoreGameId}`, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });

    const scoreboardPromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/scoreboard/${teamId}/${scoreGameId}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });

    const gameEventTypesPromise = new Promise((resolve, reject) => {
      const sportLower = team.attrib_sportType.toLowerCase().replace(' ', '_');
      axios
        .get(`/api/scoring/game_event_types/${sportLower}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });

    Promise.all([teamGamePromise, scoreboardPromise, gameEventTypesPromise])
      .then(([teamGame, scoreboardData, gameEventTypesData]) => {
        const { scoreboard } = scoreboardData;

        setGameEventTypes(gameEventTypesData);

        if (
          !scoreboard ||
          (scoreboard.eventHistory == null &&
            scoreboard.eventHistoryCompressed == null)
        ) {
          setShowAwayHomePrompt(true);
        } else {
          // should forceResume be false except when reopening game?
          const forceResume = true;

          // TODO: look this up from strings.js instead
          const sportLower = team.attrib_sportType
            .toLowerCase()
            .replace(' ', '_');

          scoringStore.initGameState(
            sportLower,
            gameEventTypesData,
            teamGame,
            scoreboard,
            firebase,
            forceResume
          );
        }
        setGame(teamGame);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
      });
  };

  useEffect(() => {
    if (!team) {
      const scoreTeamId = localStorage.getItem('scoreTeamId');
      const scoreDelegationKey = localStorage.getItem('scoreDelegationKey');
      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
        })
        .catch((respError) => {
          if (
            respError &&
            respError.response &&
            respError.response.status === 403
          ) {
            setError('You are not authorized to score for this team.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
        });
    }

    return () => {
      if (scoringStore) {
        scoringStore.unsubscribeFromGame();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      initGameState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const setAwayHome = (awayHome) => {
    const newGame = { ...game };
    newGame.scoreWeAreHome = !!awayHome;
    const body = {
      teamId: game.scoreTeamIdOurs,
      teamGameId: `GmSh01-${game.scoreGameId}`,
      teamGameUpdate: {
        scoreWeAreHome: !!awayHome,
      },
    };
    axios
      .put('/api/team_game', body)
      .then(() => {
        // TODO: look this up from strings.js instead
        const sportLower = team.attrib_sportType
          .toLowerCase()
          .replace(' ', '_');
        scoringStore.initGameState(
          sportLower,
          gameEventTypes,
          newGame,
          null,
          firebase
        );
        setShowAwayHomePrompt(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        setShowAwayHomePrompt(false);
      });
  };

  if (showAwayHomePrompt) {
    return (
      <Layer>
        <Box align="center" justify="center" fill gap="medium" pad="medium">
          <Text textAlign="center" size="large">
            Are you the HOME or AWAY team?
          </Text>
          <Box direction="row" gap="medium">
            <SHDButton
              size="large"
              primary
              label="HOME"
              onClick={() => setAwayHome(1)}
              color="tertiary-1"
            />
            <SHDButton
              size="large"
              primary
              label="AWAY"
              onClick={() => setAwayHome(0)}
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Layer>
    );
  }

  const undoClicked = () => {
    const actionEvents = scoringStore.eventHistory.filter(
      (event) => !['startGame', 'forceRun', 'sub'].includes(event.eventType)
    );

    if (actionEvents.length === 0) {
      axios
        .delete(`/api/scoreboard/${teamId}/${scoreGameId}`)
        .then(() => {
          setShowAwayHomePrompt(true);
        })
        .catch(() => setError('Error undoing event'));
    } else {
      scoringStore.handleEvent('undo', {});
    }
  };

  if (error) {
    return (
      <Text color="status-critical" size="large" textAlign="center">
        {error}
      </Text>
    );
  }

  const renderScoringSection = () => {
    if (team.attrib_sportType === SOCCER.display) {
      return (
        <Soccer
          gameEventTypes={scoringStore.gameEventTypes}
          disabled={isPeriodBreak}
        />
      );
    }

    if (team.attrib_sportType === BASKETBALL.display) {
      return (
        <Basketball
          gameEventTypes={scoringStore.gameEventTypes}
          disabled={isPeriodBreak}
        />
      );
    }

    if (team.attrib_sportType === LACROSSE.display) {
      return (
        <Lacrosse
          gameEventTypes={scoringStore.gameEventTypes}
          disabled={isPeriodBreak}
        />
      );
    }

    if (team.attrib_sportType === HOCKEY.display) {
      return (
        <Hockey
          gameEventTypes={scoringStore.gameEventTypes}
          disabled={isPeriodBreak}
        />
      );
    }

    if (team.attrib_sportType === WATER_POLO.display) {
      return (
        <WaterPolo
          gameEventTypes={scoringStore.gameEventTypes}
          disabled={isPeriodBreak}
        />
      );
    }

    return null;
  };

  const loader = <BasicLoader fullPage />;

  const renderScoringScreen = () => {
    if (scoringStore.isInitialized) {
      return (
        <Container
          id="scoring-container"
          containerRef={containerRef}
          setShowExitScoring={setShowExitScoring}
          undoClicked={undoClicked}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          setShowQrModal={setShowQrModal}
          isBallInPlay={scoringStore.scoreboard.isBallInPlay}
          streamStats={streamStats}
          setShowStreamStatsModal={setShowStreamStatsModal}
          toggleMuted={() => {
            setMuted(!muted);
            axios.put(`api/team/${scoringStore.teamId}/mute`, {
              muteIsMuted: !muted,
            });
          }}
          muted={muted}
          teamId={scoringStore.teamId}
          gameId={scoringStore.gameId}
          team={team}
        >
          <Box
            direction="column"
            fill="vertical"
            margin={{ top: '50px', bottom: '50px' }}
          >
            <Box id="teams-scores-container" direction="row">
              <Box
                width="48px"
                background="light-3"
                align="center"
                justify="center"
                onClick={() => {
                  if (!isPeriodBreak) {
                    setShowPeriodModal(true);
                  }
                }}
              >
                <Text weight="bold">
                  {`${scoringStore.scoreboard.currentPeriodNum}`}
                </Text>
                <CaretDownFill />
              </Box>
              <Box direction="column" fill="horizontal">
                <Box direction="row" justify="between" height="48px">
                  <Box
                    justify="center"
                    pad={{ horizontal: 'medium' }}
                    background={
                      scoringStore.awayHome ? 'secondary-7' : 'accent-7'
                    }
                    fill
                  >
                    <Text size="large" weight="bold">
                      {scoringStore.gameInfo.teamANameMed}
                    </Text>
                  </Box>
                  <PlusMinus
                    display={scoringStore.gameInfo.teamANameMed}
                    handleEvent={scoringStore.handleEvent}
                    outcomeType="away"
                    currentNum={scoringStore.scoreboard.a}
                    disabled={isPeriodBreak}
                    background={
                      scoringStore.awayHome ? 'secondary-7' : 'accent-7'
                    }
                  />
                </Box>
                <Box direction="row" justify="between" height="48px">
                  <Box
                    justify="center"
                    pad={{ horizontal: 'medium' }}
                    background={
                      scoringStore.awayHome ? 'accent-7' : 'secondary-7'
                    }
                    fill
                  >
                    <Text size="large" weight="bold">
                      {scoringStore.gameInfo.teamHNameMed}
                    </Text>
                  </Box>
                  <PlusMinus
                    display={scoringStore.gameInfo.teamHNameMed}
                    handleEvent={scoringStore.handleEvent}
                    outcomeType="home"
                    currentNum={scoringStore.scoreboard.h}
                    disabled={isPeriodBreak}
                    background={
                      scoringStore.awayHome ? 'accent-7' : 'secondary-7'
                    }
                  />
                </Box>
              </Box>
            </Box>
            {isPeriodBreak &&
              scoringStore.scoreboard &&
              scoringStore.scoreboard.currentPeriodNum === 1 && (
                <Layer responsive={false}>
                  <Box pad="medium" gap="small">
                    <Button
                      primary
                      size="large"
                      color="tertiary-1"
                      label="Start Game"
                      onClick={() => {
                        scoringStore.handleEvent('startGameScoreboards', {});
                      }}
                    />
                    <Text size="xsmall">
                      Press Start Game when play begins.
                    </Text>
                  </Box>
                </Layer>
              )}
            {isPeriodBreak &&
              !showPeriodModal &&
              scoringStore.scoreboard &&
              scoringStore.scoreboard.currentPeriodNum !== 1 && (
                <Layer responsive={false}>
                  <Button
                    primary
                    size="large"
                    color="tertiary-1"
                    label={`Start Period ${scoringStore.scoreboard.currentPeriodNum}`}
                    onClick={() => {
                      scoringStore.handleEvent('startGameScoreboards', {});
                    }}
                  />
                </Layer>
              )}
            {renderScoringSection()}
          </Box>
          {scoringStore.prompt && (
            <GenericPrompt
              event={scoringStore.prompt.event}
              onResponse={(data) => {
                const { attribution } = data.event.eventInfo;
                const { taggedAttribution } = data.event.eventInfo;

                const newToasts = [...toasts];
                attribution.forEach((attributionItem, i) => {
                  const taggedPlayers =
                    taggedAttribution[attributionItem.attributionType];
                  let playerDescription = '';
                  if (taggedPlayers) {
                    playerDescription = taggedPlayers
                      .map(
                        (player) => `#${player.jerseyNum} ${player.nameLast}`
                      )
                      .join(', ');
                  } else if (!taggedPlayers && i > 0) {
                    return;
                  }

                  newToasts.push({
                    id: newToasts.length + 1,
                    label: `${attributionItem.descriptionTxt} ${playerDescription}`,
                    background: 'tertiary-1',
                    duration: 3000 + i * 1000,
                  });
                });

                setToasts(newToasts);
                scoringStore.prompt.resolve(data);
                scoringStore.setPrompt(null);
              }}
              closePrompt={() => {
                // Back button / Cancel
                scoringStore.prompt.resolve({ resp: false });
                scoringStore.setPrompt(null);
              }}
            />
          )}
          <Box
            background="accent-1"
            round="full"
            height="56px"
            width="56px"
            style={{
              position: 'fixed',
              bottom: '62px',
              right: '24px',
            }}
            elevation="large"
            align="center"
            justify="center"
            onClick={() => {
              scoringStore.handleEvent(
                'highlight',
                scoringStore.gameEventTypes.highlight
              );
            }}
          >
            <GiRoundStar size="28px" color="white" />
          </Box>
        </Container>
      );
    }
    return loader;
  };

  const renderSettingsScreen = () => (
    <Container
      setShowExitScoring={setShowExitScoring}
      undoClicked={undoClicked}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      setShowQrModal={setShowQrModal}
      streamStats={streamStats}
    >
      <GenericSettings />
    </Container>
  );

  return (
    <>
      {tabIndex ? renderSettingsScreen() : renderScoringScreen()}
      {showQrModal && (
        <LivestreamLinksPage
          sport={SPORTS_MAP[team.attrib_sportType].key}
          teamId={scoringStore.teamId}
          gameId={scoringStore.gameId}
          matchupTitle={scoringStore.gameInfo.matchupTitle}
          onClose={() => {
            setShowQrModal(false);
            analytics.track('sidelineSCORE: Share Modal Closed', {
              sport: SPORTS_MAP[team.attrib_sportType].key,
              teamId: scoringStore.teamId,
              gameId: scoringStore.gameId,
            });
          }}
        />
      )}
      {showPeriodModal && (
        <Layer
          position="bottom"
          responsive={false}
          full="horizontal"
          onClickOutside={() => {
            setShowPeriodModal(false);
          }}
        >
          <Box pad="medium" justify="center" gap="large">
            {isPeriodBreak ? (
              <Button
                primary
                size="large"
                color="tertiary-1"
                label={
                  scoringStore.scoreboard &&
                  scoringStore.scoreboard.currentPeriodNum === 1
                    ? 'Start Game'
                    : `Start Period ${scoringStore.scoreboard.currentPeriodNum}`
                }
                onClick={() => {
                  scoringStore.handleEvent('startGameScoreboards', {});
                  setShowPeriodModal(false);
                }}
              />
            ) : (
              <Button
                primary
                size="large"
                label={`End Period ${scoringStore.scoreboard.currentPeriodNum}`}
                onClick={() => {
                  scoringStore.handleEvent('endPeriod', {});
                }}
                color="tertiary-1"
              />
            )}
            <Button
              plain
              size="large"
              alignSelf="center"
              label="Cancel"
              onClick={() => {
                setShowPeriodModal(false);
              }}
              color="status-error"
            />
          </Box>
        </Layer>
      )}
      {showExitScoring && (
        <Layer
          position="bottom"
          full="horizontal"
          responsive={false}
          onClickOutside={() => setShowExitScoring(false)}
        >
          <Box pad="medium" justify="center" gap="medium">
            <Box align="center" gap="medium">
              <Text size="large" weight="bold">
                Do you want to Pause or End this game?
              </Text>
              <Box align="center" gap="small" fill>
                <SHDButton
                  primary
                  size="large"
                  label="End"
                  onClick={() => {
                    setShowExitScoring(false);
                    scoringStore.handleEvent('endGame', {});
                  }}
                  disabled={!scoringStore.isInitialized}
                  color="tertiary-1"
                  fill
                />
                <SHDButton
                  primary
                  size="large"
                  label="Pause"
                  onClick={() => {
                    setShowExitScoring(false);
                    exitScoring();
                  }}
                  disabled={!scoringStore.isInitialized}
                  color="tertiary-1"
                  fill
                />
                <SHDButton
                  primary
                  size="large"
                  label="Cancel"
                  onClick={() => setShowExitScoring(false)}
                  color="status-error"
                  fill
                />
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
      {showStreamStatsModal && (
        <Layer
          responsive={false}
          onClickOutside={() => setShowStreamStatsModal(false)}
          onEsc={() => setShowStreamStatsModal(false)}
        >
          <Box gap="small" pad="medium">
            <Text weight="bold">Stream health</Text>
            <Text color="status-critical">
              {!streamStats.isHealthy && streamStats.inAppRecommendText}
            </Text>
            <Text>{streamStats.streamHealthDescription}</Text>
            <br />
            <Text>(Info updates every 30s)</Text>
          </Box>
        </Layer>
      )}
      {toasts.length > 0 && (
        <ToastStack toasts={toasts} removeToast={removeToast} />
      )}
    </>
  );
});

const GenericScoringScreen = compose(
  withRouter,
  withFirebase
)(ScoringScreenBase);

export default GenericScoringScreen;
