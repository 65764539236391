import React, { useContext, useEffect, useState } from 'react';
import { CardHeader, Text } from 'grommet';
import axios from '../../helpers/axios';
import { secondsToDaysAndHours } from '../../helpers/utils';
import signOut from '../../helpers/signOut';
import * as Sentry from '@sentry/browser';
import { UserContext } from '../../services/Session';
import { useHistory } from 'react-router-dom';

interface InvitingUser {
  nameFirst: string;
  nameLast: string;
  email: string;
}

interface Invite {
  teamRole: string;
  expires: number;
  invitedUserId: string;
  invitingUser: InvitingUser;
  teamName: string;
  playerName: string;
}

interface Expiration {
  days: number;
  hours: number;
}

interface Props {
  inviteCode: string;
}
const InvitedUserHeader: React.FC<Props> = ({ inviteCode }) => {
  const userStore = useContext(UserContext);
  const history = useHistory();

  const [error, setError] = useState(null);
  const [loading, toggleLoading] = useState(true);
  const [invitingUser, setInvitingUser] = useState<InvitingUser | null>(null);
  const [invite, setInvite] = useState<Invite | null>(null);
  const [expiration, setExpiration] = useState<Expiration | null>(null);
  const [validCode, setValidCode] = useState(true);
  const [nameToManage, setNameToManage] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get(`/api/invite/${inviteCode}`)
      .then((response) => {
        const respInvite = response.data;
        setInvite(respInvite);

        if (respInvite.invitedUserId) {
          setValidCode(false);
        } else {
          const { days, hours } = secondsToDaysAndHours(
            respInvite.expires - Date.now() / 1000
          );

          if (days >= 0 && hours >= 0) {
            setExpiration({ days, hours });
            setInvitingUser(respInvite.invitingUser);
            if (respInvite.teamName) {
              setNameToManage(respInvite.teamName);
            } else if (respInvite.playerName) {
              setNameToManage(respInvite.playerName);
            }

            if (userStore.authUser) {
              signOut(false);
            }
          } else {
            setValidCode(false);
            if (userStore.authUser) {
              history.push('/');
            }
          }
        }

        toggleLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError(e.toString());
        toggleLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading || !invite) {
    return null;
  }
  const invitedHeaderText = invitingUser
    ? `${invitingUser.nameFirst} ${invitingUser.nameLast} invited you ${
        nameToManage
          ? `${
              invite.teamRole === 'coach' ? 'as a coach of' : 'to manage'
            } ${nameToManage}`
          : 'sidelineHD'
      }!`
    : "You've been invited to sidelineHD!";
  return (
    <CardHeader direction="column" gap="8px" align="start" skeleton={loading}>
      {error && <Text color="status-error">{error}</Text>}
      {validCode ? (
        <Text size="20px" weight={'bold'}>
          {invitedHeaderText}
        </Text>
      ) : (
        <Text>This invitation has been claimed or is expired!</Text>
      )}
      {validCode && expiration && (
        <Text>
          {`Invite expires in ${expiration.days} days and ${expiration.hours} hours`}
        </Text>
      )}
    </CardHeader>
  );
};

export default InvitedUserHeader;
