// Packages
import React, { useState } from 'react';
import styles from '../Admin.module.css';
import { toTitleCase } from '../../../helpers/utils';
import { clipLabels } from '../../../constants/clips';

// Components
import { Box, Button, Text, Card } from 'grommet';
import ClipVideoPlayer from '../../../helpers/ClipVideoPlayer';

// Types
import { AdminCardProps, ClipProps } from '../admin.types';

const AdminClipCard: React.FC<AdminCardProps<ClipProps>> = ({
  hasChangeButton,
  hasRemoveButton,
  onChangeClicked,
  onRemoveClicked,
  onSelectCard,
  payload,
}) => {
  // States
  const [isSelected, setIsSelected] = useState<boolean>(false);

  // Methods
  const handleCardClick = () => {
    if (hasChangeButton || hasRemoveButton) {
      return;
    }

    if (!isSelected) {
      setIsSelected(true);
      onSelectCard && onSelectCard(payload);
    } else {
      setIsSelected(false);
      onSelectCard && onSelectCard(null);
    }
  };

  return (
    <>
      {!payload ? (
        <p>No clip selected.</p>
      ) : (
        <Card
          className={`
            ${styles['admin-player-card']} 
            ${isSelected ? styles['admin-player-card--selected'] : ''}
            ${hasChangeButton ? styles['admin-player-card--no-hover'] : ''}
          `}
          onClick={() => handleCardClick()}
          pad="medium"
        >
          <Box
            direction="row"
            align="center"
            justify="start"
            className={styles['player-info']}
            wrap={true}
          >
            <Box width="100%">
              <ClipVideoPlayer
                id={payload.id}
                sport={payload.game.sportType}
                videoSrc={payload?.fileDownloadUrl}
              />
            </Box>
            <Box margin={{ left: '0' }}>
              <Text className={styles['player-name']}>
                {toTitleCase(payload?.textDescriptionBrief)}
              </Text>
              <Text className={styles['player-handle']}>{payload?.id}</Text>
              <Text className={styles['player-id']}>
                {clipLabels[payload?.game.sportType].handle}
              </Text>
            </Box>
          </Box>
          {hasChangeButton && (
            <Button
              className={styles['change-button']}
              label="Change"
              onClick={() => onChangeClicked && onChangeClicked()}
            />
          )}
          {hasRemoveButton && (
            <Button
              className={styles['change-button']}
              label="Remove"
              onClick={() => onRemoveClicked && onRemoveClicked()}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default AdminClipCard;
