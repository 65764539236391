import React, { useState, useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Box, Button, Text, Card } from 'grommet';
import Toast from '../../components/Toast';
import { FormNextLink } from 'grommet-icons';
import { useLiveGameInfo } from './liveHooks';
import { useStyleHooks } from './liveStyleHooks';
import { buttonStyle, LiveList, LiveListItem } from './sidelineLive.styles';
import { ShareData, sharePage, isFirefox } from '../../helpers/utils';
import { LiveEvents } from './sidelineLive.analytics';
import {
  SidelineLiveGameInfoNavProps,
  SidelineLiveGameInfoProps,
} from './sidelineLive.types';

const SidelineLiveGameInfoNav: React.FC<SidelineLiveGameInfoNavProps> = ({
  gameId,
  handleNavToGamePage,
  isLive,
  trackEvent,
}) => {
  const { gameInfoNav, gameInfoNavSub, isDesktop } = useStyleHooks();
  const handleClick = useCallback(() => {
    trackEvent(LiveEvents.GAME_PAGE_VIEW, {});
    handleNavToGamePage(gameId || '');
  }, [trackEvent, handleNavToGamePage, gameId]);

  return (
    <Card
      onClick={handleClick}
      direction="row"
      pad="medium"
      margin={{
        bottom: isDesktop ? '7rem' : '1rem',
        left: '1rem',
        right: '1rem',
      }}
      justify="between"
      width={isDesktop ? '50%' : ''}
    >
      <Box justify="center" alignContent="center">
        <Text style={gameInfoNav}>
          {isLive ? 'Play-by-Play' : 'Game Recap'}
        </Text>
        <Text style={gameInfoNavSub}>Play-by-play, highlights + more</Text>
      </Box>
      <Box>
        <FormNextLink size="large" color={'#000'} />
      </Box>
    </Card>
  );
};

const SidelineLiveGameInfo: React.FC<SidelineLiveGameInfoProps> = ({
  teamId,
  teamName,
  handleNavToGamePage,
  setGameEnded,
  trackEvent,
}) => {
  const [copied, setCopied] = useState(false);
  const currentGame = useLiveGameInfo(teamId);
  const { gameInfo, gameInfoSub, rowOrColumn } = useStyleHooks();

  const isLive = !currentGame?.isGameEnded;
  useMemo(() => setGameEnded(!isLive), [isLive, setGameEnded]);
  const gameId = currentGame?.teamGameId;

  const homeTeam =
    currentGame?.scoreHomeIsKnown && currentGame.scoreWeAreHome
      ? currentGame?.scoreOurName
      : currentGame?.scoreOpponentName;

  const awayTeam =
    currentGame?.scoreHomeIsKnown && !currentGame.scoreWeAreHome
      ? currentGame?.scoreOurName
      : currentGame?.scoreOpponentName;

  const date = () => {
    if (!homeTeam && !awayTeam) {
      const dtn = DateTime.now().toLocal();
      return dtn.toISODate();
    } else {
      const d =
        currentGame &&
        DateTime.fromMillis(currentGame?.scoreTsStart * 1000).toLocal();
      return d?.toLocaleString({ month: 'short', day: 'numeric' });
    }
  };

  const formatShareUrl = (url: string): string => {
    const ts = new Date().getTime();
    const formattedUrl = url.replace(/\/$/, '');
    return `${formattedUrl}?ts=${ts}`;
  };

  const sidelineLiveShareData: ShareData = {
    title: 'sidelineHD',
    url: formatShareUrl(window.location.href),
    text: 'Check out the live game on sidelineHD',
  };

  const handleShareClick = (): void => {
    if (navigator.canShare === undefined || isFirefox) {
      navigator.clipboard.writeText(
        `${sidelineLiveShareData.text}\n${sidelineLiveShareData.url}`
      );
      trackEvent(LiveEvents.SHARE_CLICKED, { type: 'copy' });
      setCopied(true);
    } else {
      trackEvent(LiveEvents.SHARE_CLICKED, { type: 'share via' });
      sharePage(sidelineLiveShareData);
    }
  };

  return (
    <>
      <Box
        id="sideline-live-game-info"
        direction="row"
        background={'#FFF'}
        pad="medium"
        gap="medium"
        justify="between"
      >
        {homeTeam && (
          <Box direction="column" width="70%">
            <Box direction={rowOrColumn}>
              <Box direction="row">
                <Text style={gameInfo}>{awayTeam}</Text>
                <Text margin={{ horizontal: '.25rem' }} style={gameInfo}>
                  vs.
                </Text>
              </Box>
              <Text style={gameInfo}>{homeTeam}</Text>
            </Box>
            <Box direction="row">
              <LiveList style={gameInfoSub}>
                <LiveListItem style={{ color: 'red' }}>
                  {isLive ? 'LIVE' : 'ENDED'}
                </LiveListItem>
                <LiveListItem>{date()}</LiveListItem>
                <LiveListItem>SIDELINEHD</LiveListItem>
              </LiveList>
            </Box>
          </Box>
        )}
        {!homeTeam && (
          <Box
            direction="column"
            width="70%"
            alignContent="center"
            justify="center"
          >
            <Box>
              <Text style={gameInfo}>{date()}</Text>
              <Text style={gameInfo}>{teamName}</Text>
            </Box>
          </Box>
        )}
        <Box
          direction="column"
          width="30%"
          alignContent="right"
          justify="center"
        >
          <Button
            onClick={() => handleShareClick()}
            style={buttonStyle}
            label="Share Link"
          />
        </Box>
      </Box>
      {homeTeam && (
        <SidelineLiveGameInfoNav
          gameId={gameId}
          handleNavToGamePage={handleNavToGamePage}
          isLive={isLive}
          trackEvent={trackEvent}
        />
      )}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </>
  );
};

export default React.memo(SidelineLiveGameInfo);
