import { useMemo, useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import {
  gameInfoNavSm,
  gameInfoNavLg,
  gameInfoNavSubSm,
  gameInfoNavSubLg,
  gameInfoSm,
  gameInfoLg,
  gameInfoSubSm,
  gameInfoSubLg,
  statusScreen,
} from './sidelineLive.styles';
import { DirectionType } from 'grommet/utils';

const useStyleHooks = () => {
  const screenSize = useContext(ResponsiveContext);

  const isDesktop = useMemo(() => {
    if (screenSize !== 'small') {
      return true;
    }
    return false;
  }, [screenSize]);

  const rowOrColumn: DirectionType = useMemo(() => {
    if (screenSize !== 'small') {
      return 'row';
    }
    return 'column';
  }, [screenSize]);

  const gameInfoNav = useMemo(() => {
    if (screenSize !== 'small') {
      return gameInfoNavLg;
    }
    return gameInfoNavSm;
  }, [screenSize]);

  const gameInfoNavSub = useMemo(() => {
    if (screenSize !== 'small') {
      return gameInfoNavSubLg;
    }
    return gameInfoNavSubSm;
  }, [screenSize]);

  const gameInfo = useMemo(() => {
    if (screenSize !== 'small') {
      return gameInfoLg;
    }
    return gameInfoSm;
  }, [screenSize]);

  const gameInfoSub = useMemo(() => {
    if (screenSize !== 'small') {
      return gameInfoSubLg;
    }
    return gameInfoSubSm;
  }, [screenSize]);

  const statusScreenDisplay = useMemo(() => {
    if (screenSize !== 'small') {
      return {
        ...statusScreen,
        height: '500px',
        minHeight: '500px',
      };
    }
    return {
      ...statusScreen,
      height: '200px',
      minHeight: '200x',
    };
  }, [screenSize]);

  return {
    gameInfoNav,
    gameInfoNavSub,
    gameInfo,
    gameInfoSub,
    rowOrColumn,
    isDesktop,
    statusScreenDisplay,
  };
};

export { useStyleHooks };
