// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';

import { basicGap, darkGrey } from '../../../constants/stylesConfig';

export const GreetingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GreetingTitleBlock = styled.div``;

export const GreetingTitle = styled.h2`
  text-align: center;
  margin-bottom: ${basicGap};
`;

export const GreetingSubTittle = styled.p`
  text-align: center;
  margin: ${basicGap};
  text-transform: uppercase;
  color: ${darkGrey};
`;
