import { Box, Button } from 'grommet';
import React, { useCallback, useState } from 'react';
import ImagePickerCropper from '../../../helpers/ImagePickerCropper';
import { TeamActionButtonsProps, ImageCategory } from './teamInformation.types';
import axios from '../../../helpers/axios';
import { compressImage } from './imgUploadHelper';
import * as Sentry from '@sentry/browser';

const TeamActionButtons: React.FC<TeamActionButtonsProps> = ({
  teamId,
  onUploadImage,
  setTeamLogoUploading,
}) => {
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [src, setSrc] = useState<string | null>(null);
  const [category, setCategory] = useState<ImageCategory>(ImageCategory.LOGO);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result as string);
        setShowImageCropper(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSave = useCallback(
    async (file: File | undefined) => {
      setTeamLogoUploading(true);
      setShowImageCropper(false);
      if (!file) {
        return;
      }

      const compressed = await compressImage(file);

      const formData = new FormData();
      // The third parameter is required for server
      formData.append('image', compressed, (compressed as File).name);
      formData.append('category', category);

      axios
        .post(`/api/team/${teamId}/image`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
            responseType: 'arraybuffer',
          },
        })
        .then((response) => {
          const url =
            category === ImageCategory.LOGO
              ? response.data.urlT150
              : response.data.urlOrig;

          onUploadImage(category, url);

          setTeamLogoUploading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
          setTeamLogoUploading(false);
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, category, setShowImageCropper, setTeamLogoUploading]
  );
  return (
    <Box key="team-action-buttons" justify="start" fill="horizontal">
      <Box direction="row" justify="end" gap="small">
        <Button
          size="small"
          style={{ whiteSpace: 'nowrap', height: '2rem' }}
          secondary
          label="Change Logo"
          onClick={() => {
            setCategory(ImageCategory.LOGO);
            document.getElementById('imageInput')?.click();
          }}
        />
        <Button
          size="small"
          style={{ whiteSpace: 'nowrap', height: '2rem' }}
          secondary
          label="Change Cover Photo"
          onClick={() => {
            setCategory(ImageCategory.COVER);
            document.getElementById('imageInput')?.click();
          }}
        />
        <input
          id="imageInput"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: 'none' }}
        />
        {showImageCropper && src && (
          <ImagePickerCropper
            src={src}
            circularCrop={category === ImageCategory.LOGO}
            aspect={category === ImageCategory.LOGO ? 1 : 3 / 1}
            onClose={() => {
              setShowImageCropper(false);
            }}
            onSave={onSave}
          />
        )}
      </Box>
    </Box>
  );
};

export default TeamActionButtons;
