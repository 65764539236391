// Packages
import React from 'react';
import styles from '../../Admin.module.css';

// Components
import { Heading, Button } from 'grommet';
import AdminPlayerCard from '../../components/AdminPlayerCard';

// Types
import { MergePlayersStepFourProps } from '../../admin.types';

const MergePlayersStepFour: React.FC<MergePlayersStepFourProps> = ({
  onLinkClicked,
  primaryPlayer,
}) => {
  return (
    <>
      <Heading level={2} className={styles['admin-heading']}>
        New Player Profile
      </Heading>
      <AdminPlayerCard payload={primaryPlayer} />
      <Button
        className={styles['admin-step-buttons-finished']}
        label="Finish"
        onClick={() => onLinkClicked && onLinkClicked('/admin')}
        primary
      />
    </>
  );
};

export default MergePlayersStepFour;
