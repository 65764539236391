import React, { useContext } from 'react';
import { Box, Button, Footer, Image, Layer, Text } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TeamCapture } from '@shd/jslib/models/shd';
import { isMobile } from '../../helpers/browserDetect';
import { AppHostContext } from '../../services/appHost';

interface Props {
  capture: TeamCapture;
  onClose: () => void;
}

const MevoMultiSetupModal: React.FC<Props> = ({ capture, onClose }) => {
  const [showDetailedInstructions, setShowDetailedInstructions] =
    React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const appHost = useContext(AppHostContext);
  const appHostService = appHost.isHosted ? appHost.service : undefined;

  return (
    <Layer
      full={isMobile.any() === true}
      responsive={false}
      style={{ maxHeight: '100svh' }}
      animation={false}
      background={'light-5'}
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Box height={'100%'} flex>
        <Box
          flex={{ grow: 0, shrink: 1 }}
          direction="row"
          pad="16px"
          align="center"
          justify="between"
        >
          <Box
            alignSelf="start"
            onClick={() => {
              onClose();
            }}
          >
            <LinkPrevious size="24px" />
          </Box>
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              Pair your Mevo Multicam app
            </Text>
          </Box>
          <Box width="24px" />
        </Box>
        <Box overflow={'scroll'} flex={{ grow: 1, shrink: 1 }}>
          <Box pad="medium" gap="small" flex={{ shrink: 0, grow: 1 }}>
            <Text weight="bold">Launch the Mevo Multicam app</Text>
            <Text size="medium">
              Press the button below, click on “Launch Multicam App”, and click
              on “Go Live” to get started.
            </Text>
            <Button
              primary
              label="Open in Mevo Multicam App"
              disabled={!capture.rtmp?.mevoMulticamUrl}
              onClick={() => {
                if (appHost.isHosted) {
                  appHostService?.notifications.openAppLink(
                    capture.rtmp?.mevoMulticamUrl || ''
                  );
                } else {
                  window.open(capture.rtmp?.mevoMulticamUrl || '', '_blank');
                }
              }}
            />
            <Box
              margin={{ top: '16px' }}
              direction="row"
              justify="between"
              align="center"
            >
              <Text weight="bold">Detailed Instructions</Text>
              <Button
                secondary
                label={showDetailedInstructions ? 'Collapse' : 'Expand'}
                size="small"
                onClick={() => {
                  setShowDetailedInstructions(!showDetailedInstructions);
                }}
              />
            </Box>
            {showDetailedInstructions && (
              <Box gap="16px">
                <Box gap="small">
                  <Text weight="bold">{`Click "Launch Multicam App"`}</Text>
                  <Image src="/mevo_multicam_detailed1.png" fit="contain" />
                </Box>
                <Box gap="small">
                  <Text weight="bold">{`Click “Go Live” to start streaming`}</Text>
                  <Image src="/mevo_multicam_detailed2.png" fit="contain" />
                </Box>
                <Box
                  background={'white'}
                  margin={{ top: 'small' }}
                  gap="8px"
                  pad="16px"
                  round="8px"
                  border
                >
                  <Text weight={'bold'}>Setup not working?</Text>
                  <Text>
                    Copy the URL and transfer it to your mobile device by text
                    or email.
                  </Text>
                  <Box
                    direction="row"
                    justify="between"
                    margin={{ top: '4px' }}
                    align="center"
                    gap="8px"
                    flex
                  >
                    <Text size="small" truncate>
                      {capture.rtmp?.mevoAppUrl}
                    </Text>
                    <CopyToClipboard
                      text={capture.rtmp?.mevoMulticamUrl || ''}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 3000);
                      }}
                    >
                      <Box flex={{ grow: 1, shrink: 0 }}>
                        <Button
                          secondary
                          size="small"
                          label={copied ? 'Copied!' : 'Copy to Clipboard'}
                          disabled={copied}
                        />
                      </Box>
                    </CopyToClipboard>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Footer
          pad={'16px'}
          background="white"
          border={{
            color: 'light-2',
            side: 'top',
            size: '1px',
            style: 'solid',
          }}
          flex={{ shrink: 0, grow: 0 }}
        >
          <Box fill="horizontal" align="center" gap="8px">
            <Text weight="bold">Step 1 of 1</Text>
            <Button
              fill
              primary
              size="large"
              label="Done"
              onClick={() => {
                onClose();
              }}
            />
          </Box>
        </Footer>
      </Box>
    </Layer>
  );
};

export default MevoMultiSetupModal;
