// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { Box, Card, Text, Heading, Button, DropButton } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { withRouter, useParams, Redirect } from 'react-router-dom';
import withLayout from '../../helpers/withLayout';
import * as ROUTES from '../../constants/routes';

const getRoute = (path, ...args) => {
  let count = -1;
  return path.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;
    return args[count] !== undefined ? args[count] : match;
  });
};

const TopPlayers = () => {
  const { sport, region } = useParams();

  let regionToId;
  if (sport === 'softball') {
    regionToId = {
      alabama: 'shrfLmMEcp1MbOBpp',
      california: 'shrgtQ5oY8JHiy5Dt',
      colorado: 'shrWhsjDSkHBAhc8D',
      georgia: 'shr0HDCnreULwiDfX',
      illinois: 'shry2LLT6L3yfDh62',
      indiana: 'shrX2FwFDptMesJiQ',
      kansas: 'shrOEOC1TDJUKsnBZ',
      missouri: 'shrlneaJxEOtTzKXH',
      'north-carolina': 'shr1V1jHpzSjk7ynL',
      oklahoma: 'shrSKgdUI1u2tWE2q',
      oregon: 'shredsggxJfbzj9qN',
      tennessee: 'shrikDRLbLkQBhZmw',
      texas: 'shrpzzgaH02gNJy8Z',
      washington: 'shr1OoEWeY0YP05yk',

      west: 'shrr6xmvkfyvkYDQz',
      central: 'shroEiVwR1T61Z6Mi',
      south: 'shrrZjvLnAnhgXmfm',
      east: 'shrym9sLlbeTu9yyB',
    };
  } else if (sport === 'baseball') {
    regionToId = {
      west: 'shrnlRsUi5ybGhBQT',
      south: 'shrKjCFP2JLwviTaq',
      central: 'shrVyaDcd0237pqay',
      east: 'shrVI2CCB0bxwIyZt',
    };
  } else {
    return (
      <Redirect
        to={{
          pathname: getRoute(ROUTES.TOP_PLAYERS, 'softball', 'georgia'),
        }}
      />
    );
  }

  if (!(region in regionToId)) {
    return (
      <Redirect
        to={{
          pathname: getRoute(ROUTES.TOP_PLAYERS, 'softball', 'georgia'),
        }}
      />
    );
  }

  const howItWorks1 = `
  SidelineSTARS recognizes players that are strong competitors in their respective leagues and tournaments.
  `;
  const howItWorks2 = `
  SidelineHD rates players based on the clips in our library.  We measure performance based on ~1M scorer-recorded plate appearance outcomes.  A sidelineSTARS ranking (3-5) is generated for each of the 1000s of players with clips on sidelineHD.  The rating is not affected by social media posts or interactions with sidelineHD staff.
  `;
  const howItWorks3 = `
  We rate players with a graduation year of 2027 or earlier.  To be rated, a player must have a claimed profile on sidelineHD, have their primary team be located in the region, and allow their game clips to be publicly shared.`;

  return (
    <Box margin="large">
      <Box margin={{ bottom: 'large' }} gap="medium">
        <Heading margin={{ top: 'none', bottom: 'none' }}>
          {`sidelineSTARS of ${region[0].toUpperCase() + region.substring(1)}`}
        </Heading>
        <Text>
          We tapped into our 2M clip library to find the most productive players
          of 2022.
        </Text>
        <Text>
          Click on any player&apos;s full profile to view their clip catalog.
        </Text>
        <Card
          pad="large"
          gap="medium"
          width="large"
          background="primary-7"
          direction="row"
          align="center"
        >
          <Text size="large">Are you a livestreamer?</Text>
          <Button
            primary
            label={
              <Text size="large" weight="bold">
                Stream with sidelineHD
              </Text>
            }
            href="https://home.sidelinehd.com"
            alignSelf="start"
          />
        </Card>
      </Box>
      <Box>
        <iframe
          title="title"
          className="airtable-embed"
          src={`https://airtable.com/embed/${regionToId[region]}?backgroundColor=orange&viewControls=on`}
          frameBorder="0"
          // eslint-disable-next-line react/no-unknown-property
          onMouseWheel=""
          width="100%"
          height="800px"
          // eslint-disable-next-line react/style-prop-object
          //   style="background: transparent; border: 1px solid #ccc;"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        />
      </Box>
      <Box width="medium" pad="medium">
        <DropButton
          plain
          size="small"
          icon={<CircleInformation size="medium" />}
          label={<Text size="medium">How does this work?</Text>}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropContent={
            <Box pad="medium" width="large" gap="small">
              <Text>{howItWorks1}</Text>
              <Text>{howItWorks2}</Text>
              <Text>{howItWorks3}</Text>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default withRouter(withLayout(TopPlayers));
