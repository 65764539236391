import { shd } from '@shd/jslib/models';
import { Box, Card, Text } from 'grommet';
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { formatDateShort, formatDuration } from '../../helpers/utils';
import SHDLink from '../../helpers/SHDLink';

interface HighlightCardProps {
  highlight: shd.Highlight;
}
const HighlightCard: React.FC<HighlightCardProps> = ({ highlight }) => {
  // rounded corners hack because:
  // https://github.com/cookpete/react-player/issues/872
  const [duration, setDuration] = useState(0);
  return (
    <Card>
      <Box overflow={'hidden'}>
        <ReactPlayer
          url={highlight.fileUrl720}
          style={{
            overflow: 'hidden',
            position: 'relative',
            top: '-4px',
            zIndex: 1,
          }}
          width="100%"
          height="inherit"
          controls
          onDuration={(d) => {
            setDuration(d);
          }}
          //   light
          //   playIcon={<CirclePlay />}
        />
        <Box
          direction="row"
          justify="between"
          pad={{ horizontal: 'large', bottom: '16px' }}
          height={'48px'}
          margin={{ top: `${-1 * (24 + 8 + 16)}px` }}
          style={{ zIndex: 2 }}
        >
          {/* unclear if views are working properly right now */}
          {/* <Box
            background="gray-1"
            pad={{ horizontal: 'small', vertical: 'xsmall' }}
          >
            <Text>{highlight.views} views</Text>
          </Box> */}
          <Box></Box>
          <Box
            background="gray-1"
            pad={{ horizontal: 'small', vertical: 'xsmall' }}
          >
            <Text size="small">{formatDuration(duration)}</Text>
          </Box>
        </Box>
      </Box>
      <SHDLink to={`/game/${highlight.gameIdList[0]}#highlights`}>
        <Box
          pad="medium"
          gap="xsmall"
          margin={{ top: '-4px' }}
          background={'white'}
        >
          <Text weight={'bold'}>{highlight.titleMain}</Text>
          <Text size="small">{formatDateShort(highlight.createdTs)}</Text>
        </Box>
      </SHDLink>
    </Card>
  );
};

export default HighlightCard;
