// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { FormClose } from 'grommet-icons';
import { Box, Keyboard, Text } from 'grommet';
import PhoneInput from 'react-phone-number-input/input';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  formatPhoneNumber,
} from 'react-phone-number-input';
import SHDButton from '../components/SHD/Button';

const Tag = ({ children, onRemove, disabled, ...rest }) => {
  const tag = (
    <Box
      direction="row"
      align="center"
      background="brand"
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      margin={{ vertical: 'xsmall' }}
      round="medium"
      {...rest}
    >
      <Text size="medium" margin={{ right: 'xxsmall' }}>
        {children}
      </Text>
      {onRemove && <FormClose size="small" color="white" />}
    </Box>
  );

  if (onRemove) {
    return (
      <SHDButton disabled={disabled} onClick={onRemove}>
        {tag}
      </SHDButton>
    );
  }
  return tag;
};

const TagInput = ({
  value = [],
  onChange = () => {},
  onTagAdded,
  onTagRemoved,
  disabled = false,
  ...rest
}) => {
  const [currentTag, setCurrentTag] = useState();

  const [selectedTags, setSelectedTags] = useState(value);

  useEffect(() => {
    if (JSON.stringify(selectedTags) !== JSON.stringify(value)) {
      setSelectedTags(value);
    }
  }, [value, selectedTags]);

  useEffect(() => {
    setCurrentTag('');
  }, [selectedTags]);

  const validateTag = (tagValue) =>
    isPossiblePhoneNumber(tagValue) && isValidPhoneNumber(tagValue);

  const onAddTag = (tag) => {
    if (rest.limit && selectedTags.length + 1 > rest.limit) {
      setSelectedTags(selectedTags);

      onTagAdded(selectedTags, false);
    } else {
      setSelectedTags([...selectedTags, tag]);

      onTagAdded([...selectedTags, tag], true);
    }
  };

  const onRemoveTag = (tag) => {
    const removeIndex = selectedTags.indexOf(tag);
    const newTags = [...selectedTags];
    if (removeIndex >= 0) {
      newTags.splice(removeIndex, 1);
    }
    setSelectedTags(newTags);

    onTagRemoved(newTags, true);
  };

  const updateCurrentTag = (tagValue = '') => {
    if (tagValue.slice(2).length >= 10) {
      if (validateTag(tagValue)) {
        setCurrentTag('');
        onAddTag(tagValue);
      }
    }

    if (tagValue === ',') {
      setCurrentTag('');
    } else {
      setCurrentTag(tagValue);
    }

    if (onChange) {
      onChange(tagValue);
    }
  };

  const onTagSeparator = () => {
    if (validateTag(currentTag)) {
      if (currentTag.length) {
        setCurrentTag('');
        onAddTag(currentTag);
      }
    }
  };

  const renderValue = () =>
    selectedTags.map((v, index) => (
      <Tag
        margin="xxsmall"
        key={`${v}${index + 0}`}
        onRemove={() => onRemoveTag(v)}
        disabled={disabled}
      >
        {formatPhoneNumber(v)}
      </Tag>
    ));

  const phoneInputDisabled =
    disabled || (rest.limit && selectedTags.length >= rest.limit);

  return (
    <Keyboard onEnter={onTagSeparator} onComma={onTagSeparator}>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: 'xsmall' }}
        border="all"
        wrap
      >
        {selectedTags.length > 0 && renderValue()}
        <Box flex style={{ minWidth: '120px' }}>
          <PhoneInput
            disabled={phoneInputDisabled}
            autoComplete="nope"
            type="text"
            country="US"
            value={currentTag}
            onChange={updateCurrentTag}
            {...rest}
          />
        </Box>
      </Box>
    </Keyboard>
  );
};

export default TagInput;
