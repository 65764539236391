// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { DataTable, Box, Text, Anchor } from 'grommet';
import { StatusGood } from 'grommet-icons';
import { withRouter } from 'react-router-dom';
import { compareJerseyNum, getPrivacyName } from '../../helpers/utils';
import ShareBar from './ShareBar';
import ClaimWall from '../Player/ClaimWall';

const BoxScore = ({
  players,
  data,
  columns,
  gameData,
  playerCol = 'Player',
  ...props
}) => {
  const [showClaimWall, setShowClaimWall] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();

  const transformColumns = () => {
    const newColumns = columns
      .filter((col) => col.display)
      .map((col) => ({
        property: col.key,
        header: col.label,
        align: 'end',
        render: (row) => <Text truncate>{row[col.key]}</Text>,
      }));

    newColumns.unshift({
      property: 'player',
      header: playerCol,
      align: 'start',
      render: (row) => {
        if (row.id === 'team_ours') {
          return (
            <Box direction="row" gap="xsmall">
              <Text truncate>Team</Text>
            </Box>
          );
        }

        return (
          <Box direction="row" gap="xsmall">
            <Anchor href={`/${row.player._id}`} target="_blank">
              <Text
                truncate
              >{`#${row.player.jerseyNum} ${row.player.privacyName}`}</Text>
            </Anchor>
            {row.player.claimedPlayerId !== '' && (
              <StatusGood color="primary-1" size="12px" />
            )}
          </Box>
        );
      },
      pin: true,
    });

    return newColumns;
  };

  const transformData = (transformedColumns) => {
    // const newData = data.filter((row) => row.id in players).map((row) => {
    let newData = data
      .filter((row) => row.id in players)
      .map((row) => {
        const newRow = { ...row };
        const player = players[row.id];
        newRow.player = { ...player };
        newRow.player.privacyName = getPrivacyName(
          player.nameFirst,
          player.nameLast,
          player.embedNameFirstLevel,
          player.embedNameLastLevel
        );

        if (!player.claimedPlayerId) {
          // convert data to lock emojis
          const columnStrings = transformedColumns
            .map((c) => c.property)
            .filter((x) => x !== 'player');

          columnStrings.forEach((col) => {
            newRow[col] = '🔒';
          });
        }

        return newRow;
      });

    // add team row if it exists
    const teamRows = data.filter((row) => row.id === 'team_ours');

    newData = newData.sort((a, b) => compareJerseyNum(a.player, b.player));
    if (teamRows.length) {
      newData.push(teamRows[0]);
    }

    return newData;
  };

  const transformedColumns = transformColumns();
  const transformedData = transformData(transformedColumns);

  const { J_opponentName, scoreOurName } = gameData;

  return (
    <Box gap="medium" margin={{ top: 'medium' }}>
      <Box>
        <ShareBar
          title={`${scoreOurName} vs ${J_opponentName} on sidelineHD`}
          text="Check out the box score from this game on sidelineHD."
          path={window.location.href}
        />
      </Box>
      <Box overflow="scroll" background="white">
        <DataTable
          primaryKey={false}
          background={{
            header: 'white',
            body: ['light-1', 'white'],
          }}
          columns={transformedColumns}
          data={transformedData}
          onClickRow={({ datum }) => {
            if (datum.id === 'team_ours') {
              return;
            }
            if (!datum.player.claimedPlayerId) {
              setShowClaimWall(true);
              setSelectedPlayer(datum.player);
            }
          }}
        />
      </Box>
      {showClaimWall && selectedPlayer && (
        <ClaimWall
          claimReason={`Claim ${selectedPlayer.nameFirst} to see full stats and videos!`}
          teamPlayer={selectedPlayer}
          onShowClaim={() => {
            props.history.push(`/${selectedPlayer._id}?from_claim_wall=1`);
          }}
          onClose={() => {
            setShowClaimWall(false);
          }}
        />
      )}
    </Box>
  );
};

export default withRouter(BoxScore);
