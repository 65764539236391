"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFbAuthError = exports.fbAuthErrorCodes = exports.FetchError = exports.errorPayload = exports.isFetchError = exports.isError = exports.errorStack = exports.errorStatus = exports.errorName = exports.errorCode = exports.errorMessage = void 0;
function errorMessage(e, defaultMessage = "unknown error") {
    if (e === undefined) {
        return undefined;
    }
    if (typeof e !== "object" || e === null) {
        return defaultMessage;
    }
    return e instanceof Error
        ? e.message
        : e.message?.toString() ||
            defaultMessage;
}
exports.errorMessage = errorMessage;
function errorCode(e) {
    return e.code;
}
exports.errorCode = errorCode;
function errorName(e) {
    return e.name;
}
exports.errorName = errorName;
function errorStatus(e) {
    return e.status;
}
exports.errorStatus = errorStatus;
function errorStack(e) {
    return e instanceof Error ? e.stack : undefined;
}
exports.errorStack = errorStack;
function isError(e) {
    return e instanceof Error;
}
exports.isError = isError;
function isFetchError(e) {
    return e instanceof FetchError;
}
exports.isFetchError = isFetchError;
function errorPayload(e) {
    if (e instanceof FetchError) {
        return e.toStroggingPayload();
    }
    return {
        message: errorMessage(e),
        code: errorCode(e),
        status: errorStatus(e),
        k: Object.keys(e || {}),
        isString: typeof e === "string" ? e : false,
    };
}
exports.errorPayload = errorPayload;
class FetchError extends Error {
    status;
    json;
    constructor(message, status, json) {
        super(message);
        this.status = status;
        this.json = json;
    }
    toStroggingPayload() {
        return {
            message: this.message,
            status: this.status,
            json: this.json,
        };
    }
}
exports.FetchError = FetchError;
exports.fbAuthErrorCodes = {
    accountExistsWithDifferentCredential: "auth/account-exists-with-different-credential",
    claimsTooLarge: "auth/claims-too-large",
    emailAlreadyExists: "auth/email-already-exists",
    idTokenExpired: "auth/id-token-expired",
    idTokenRevoked: "auth/id-token-revoked",
    insufficientPermission: "auth/insufficient-permission",
    internalError: "auth/internal-error",
    invalidArgument: "auth/invalid-argument",
    invalidClaims: "auth/invalid-claims",
    invalidCredential: "auth/invalid-credential",
    invalidDisplayName: "auth/invalid-display-name",
    invalidEmail: "auth/invalid-email",
    invalidEmailVerified: "auth/invalid-email-verified",
    invalidIdToken: "auth/invalid-id-token",
    invalidPageToken: "auth/invalid-page-token",
    invalidPassword: "auth/invalid-password",
    invalidPhoneNumber: "auth/invalid-phone-number",
    invalidPhotoUrl: "auth/invalid-photo-url",
    invalidUserImport: "auth/invalid-user-import",
    invalidVerificationCode: "auth/invalid-verification-code",
    operationNotAllowed: "auth/operation-not-allowed",
    phoneNumberAlreadyExists: "auth/phone-number-already-exists",
    uidAlreadyExists: "auth/uid-already-exists",
    userNotFound: "auth/user-not-found",
};
/**
 * Used for errors thrown by firebase auth
 * @param e
 * @param fbErrorCode
 * @returns
 */
function isFbAuthError(e, fbErrorCode) {
    return errorCode(e) === fbErrorCode;
}
exports.isFbAuthError = isFbAuthError;
