// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const STATUSES = {
  connecting: 'Connecting',
  connected: 'Connected',
  disconnected: 'Disconnected',
  disconnecting: 'Disconnecting',
  reconnecting: 'Reconnecting',
  error: 'Error',
};

export const DEFAULT_STATUS = STATUSES.disconnected;

export const STREAM_HEALTH_STATUSES = {
  good: 'Good',
  ok: 'OK',
  bad: 'Bad',
};

export const DEFAULT_CODEC = {
  label: 'Standard (H.264)',
  value: 'h264',
  mimeType: 'video/H264',
  disabled: false,
};

export const H265_CODEC = {
  label: 'Advanced (HEVC)',
  value: 'h265',
  mimeType: 'video/H265',
  disabled: false,
};

export const H264_LIMIT_RESOLUTION = { width: 1280, height: 720 };

export const BANDWIDTH_UNLIMITED = 'unlimited';

export const BANDWIDTHS = [
  // { label: 'Unlimited', value: BANDWIDTH_UNLIMITED },
  { label: '3500', value: 3500 },
  { label: '3000', value: 3000 },
  { label: '2500', value: 2500 },
  { label: '2000', value: 2000 },
  { label: '1750', value: 1750 },
  { label: '1500', value: 1500 },
  { label: '1250', value: 1250 },
  { label: '1000', value: 1000 },
  { label: '750', value: 750 },
  { label: '500', value: 500 },
];

export const RESOLUTIONS = [
  {
    label: '720p',
    value: { width: 1280, height: 720 },
  },
  {
    label: '540p',
    value: { width: 960, height: 540 },
  },
  {
    label: '450p', // label: '480p',
    value: { width: 800, height: 450 }, // normally 640x480
  },
  {
    label: '360p',
    value: { width: 640, height: 360 }, // normally 480x360
  },
];

export const ROTATE_TO_LANDSCAPE_WARNING = 'Please rotate to landscape.';

export const ZOOM_MAX = 1;

export const ZOOM_MIN = 0;

export const ZOOM_STEPS = 8;

export const ZOOM_PINCH_THRESHOLD = 0.05;

export const AUDIO_CONSTRAINTS = {
  channels: 2,
  autoGainControl: false,
  echoCancellation: false,
  noiseSuppression: false,
  sampleRate: 48000,
  sampleSize: 16,
  volume: 1.0,
};

export const STREAM_INFO_INTERVAL_MS = 2000;

export const INITIAL_SESSION_STATS = {
  startTs: 0,
  bitrate: {
    count: 0,
    mean: 0,
    m2: 0,
    stddev: 0,
    min: Number.MAX_VALUE,
    max: 0,
  },
  fps: {
    count: 0,
    mean: 0,
    m2: 0,
    stddev: 0,
    min: Number.MAX_VALUE,
    max: 0,
  },
  fir: {
    count: 0,
    mean: 0,
    m2: 0,
    stddev: 0,
    min: Number.MAX_VALUE,
    max: 0,
  },
  nack: {
    count: 0,
    mean: 0,
    m2: 0,
    stddev: 0,
    min: Number.MAX_VALUE,
    max: 0,
  },
  pli: {
    count: 0,
    mean: 0,
    m2: 0,
    stddev: 0,
    min: Number.MAX_VALUE,
    max: 0,
  },
};
