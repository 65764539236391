// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Heading, Layer, Text } from 'grommet';
import React, { useContext, useState } from 'react';
import SHDButton from '../../components/SHD/Button';
import { ScoringContext } from './context';
import MakeSubstitution from './MakeSubstitution';

const PlayerActionMenu = ({
  onClose = () => {},
  player = {},
  setIndexLocal = () => {},
}) => {
  const scoringStore = useContext(ScoringContext);
  const { awayHome, lineups, lineupQueueIndexes, pitchers } = scoringStore;
  const [showSubs, setShowSubs] = useState(false);
  const lineup = lineups[awayHome];
  const currentBatterId = lineups[awayHome][lineupQueueIndexes[awayHome]]
    ? lineups[awayHome][lineupQueueIndexes[awayHome]]._id
    : '';
  const currentPitcherId = pitchers[awayHome] ? pitchers[awayHome]._id : '';
  let playerLineupIndex;
  lineup.forEach((p, i) => {
    if (p._id === player._id) {
      playerLineupIndex = i;
    }
  });

  const removeFromLineup = () => {
    scoringStore.handleEvent('sub', {
      index: playerLineupIndex,
      newPlayer: null,
    });
    onClose();
  };

  const setAsBatter = () => {
    scoringStore.handleEvent('lineupIndex', playerLineupIndex);
    setIndexLocal(playerLineupIndex);
    onClose();
  };

  const setAsPitcher = () => {
    scoringStore.handleEvent('pitchingChange', player);
    onClose();
  };

  if (showSubs) {
    return (
      <MakeSubstitution
        playerToSubOutIndex={playerLineupIndex}
        closeSubs={(isSubSuccess) => {
          if (isSubSuccess) {
            setShowSubs(false);
            onClose();
          } else {
            setShowSubs(false);
          }
        }}
      />
    );
  }

  return (
    <Layer responsive={false}>
      <Box pad="medium" width="medium">
        <Text color="tertiary-1" weight="bold" onClick={onClose}>
          Back
        </Text>
        <Box margin={{ top: 'large' }}>
          <Heading textAlign="center" level="3">
            {player.displayName}
          </Heading>
          <Box gap="small">
            {player._id !== currentBatterId ? (
              <SHDButton
                size="large"
                primary
                color="secondary-7"
                label="Set as current batter"
                onClick={setAsBatter}
              />
            ) : null}
            {player._id !== currentPitcherId ? (
              <SHDButton
                size="large"
                primary
                color="secondary-7"
                label="Set as current pitcher"
                onClick={setAsPitcher}
              />
            ) : null}
            <SHDButton
              primary
              color="accent-4"
              size="large"
              label="Sub out"
              onClick={() => setShowSubs(true)}
            />
            <SHDButton
              primary
              color="accent-4"
              size="large"
              label="Remove from lineup"
              onClick={removeFromLineup}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default PlayerActionMenu;
