// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, CardHeader, Text } from 'grommet';
import { withRouter } from 'react-router-dom';
import { withAuthorization } from '../../services/Session';
import axios from '../../helpers/axios';
import { strogging } from '@shd/jslib/infra';

const Waiting = observer(() => {
  const [claimRequests, setClaimRequests] = useState([]);

  useEffect(() => {
    axios
      .get('/api/claim_request')
      .then(({ data }) => {
        setClaimRequests(data.requests);
      })
      .catch((e) => {
        strogging.exception('claim-request:exception', e);
        setClaimRequests([]);
      });
  }, []);

  if (claimRequests.length === 0) {
    return null;
  }

  return (
    <Card pad="medium" gap="medium" background="light-2">
      <CardHeader>
        <Text size="large" weight="bold">
          Player Claim Request
        </Text>
      </CardHeader>
      <CardBody>
        {claimRequests.map((claim) => (
          // eslint-disable-next-line react/jsx-key
          <Text>
            {`Your claim on ${claim.nameFirst} ${claim.nameLast} is 
          awaiting confirmation by the team admin of ${claim.teamNameLong}`}
          </Text>
        ))}
        <Text margin={{ top: 'medium' }}>
          You will be notified when it is approved.
        </Text>
      </CardBody>
    </Card>
  );
});

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(withRouter(Waiting));
