import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Box, Button, Layer, Text, Image, ButtonExtendedProps } from 'grommet';
import { useFacebookAuthResponse } from './facebook';
import { Close, Connect, Facebook } from 'grommet-icons';
import { strogging } from '@shd/jslib/infra';
import { AppHostContext } from '../services/appHost';

interface Props {
  onLinkFacebookClick: () => void;
  onCloseIntroModal: () => void;
  setShowLinkingModal: (show: boolean) => void;
  fbScopes: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseFacebook: (res: any) => Promise<void>;
}
export const FacebookSettingsModal: React.FC<Props> = ({
  onLinkFacebookClick,
  onCloseIntroModal,
  setShowLinkingModal,
  fbScopes,
  responseFacebook,
}) => {
  const appHost = React.useContext(AppHostContext);
  const authResponse = useFacebookAuthResponse();

  let loginButton;
  if (authResponse) {
    loginButton = (
      <Button
        fill="horizontal"
        primary
        color="light-2"
        icon={<Facebook color="plain" />}
        label="Link Facebook"
        onClick={() => {
          onLinkFacebookClick();
          setShowLinkingModal(true);
        }}
      />
    );
  } else if (appHost.isHosted) {
    loginButton = (
      <Button
        fill="horizontal"
        primary
        color="light-2"
        icon={<Facebook color="plain" />}
        label="Link Facebook"
        onClick={async () => {
          if (appHost.isHosted) {
            const response =
              await appHost.service.requests.authenticateFacebook();
            if (response?.accessToken) {
              onLinkFacebookClick();
              setShowLinkingModal(true);
            }
          } else {
            strogging.error('Not hosted, cannot authenticate Facebook');
          }
        }}
      />
    );
  } else {
    loginButton = (
      <FacebookLogin
        disableMobileRedirect
        version="15.0"
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        fields="name,email,picture,link"
        scope={fbScopes}
        onClick={onLinkFacebookClick}
        callback={responseFacebook}
        render={(renderProps: { onClick: ButtonExtendedProps['onClick'] }) => (
          <Button
            fill="horizontal"
            primary
            color="light-2"
            icon={<Facebook color="plain" />}
            label="Link Facebook"
            onClick={renderProps.onClick}
          />
        )}
      />
    );
  }

  return (
    <Layer
      onClickOutside={onCloseIntroModal}
      onEsc={onCloseIntroModal}
      style={{ overflowY: 'auto', overflowX: 'hidden' }} // TODO: overflowX is a hack
      // pad={{ bottom: '72px' }}
    >
      <Box gap="small" margin="small">
        <Box margin="small" direction="row" justify="between">
          <Text alignSelf="center" size="large" weight="bold">
            Link to Facebook
          </Text>
          <Button icon={<Close />} onClick={onCloseIntroModal} />
        </Box>
        <Box
          margin={{ horizontal: 'small', bottom: 'medium' }}
          overflow="scroll"
          gap="small"
        >
          <Box
            direction="row"
            justify="center"
            gap="small"
            align="center"
            margin={{ bottom: 'medium' }}
          >
            <Box width="44px">
              <Image src="/logo512.png" fit="contain" />
            </Box>
            <Connect size="28px" />
            <Facebook size="44px" color="plain" />
          </Box>
          <Text>
            Stream to your Facebook profile, a page you are an admin of, or a
            group you are an admin of.
          </Text>
          <Text>
            You will be redirected to Facebook to login when you click the
            button below.
          </Text>
        </Box>
        <Box direction="row" align="center" gap="small" fill="horizontal">
          {loginButton}
        </Box>
      </Box>
    </Layer>
  );
};
