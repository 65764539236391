import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Heading, Layer, Text } from 'grommet';
import { withRouter } from 'react-router-dom';
import { Close } from 'grommet-icons';

import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session/context';
import { BasicLoader } from '../../helpers/Loaders';
import Toast from '../../components/Toast';
import { shd } from '@shd/jslib/models';
import CaptureItem from '../GoLive/CaptureItem';
import { TeamCapture } from '@shd/jslib/models/shd';
import { CaptureStatus } from '../GoLive/GoLiveFlow';
import * as Sentry from '@sentry/browser';
import CaptureSetupInstructions from './CaptureSetupInstructions';
import { MEVO_MULTI, MEVO_SINGLE, SMARTPHONE } from '../../constants/strings';
import { analytics } from '../../services/analytics';

interface CameraLearnMoreProps {
  onClose: () => void;
}

const CameraLearnMore: React.FC<CameraLearnMoreProps> = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Cameras</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Text>
        We suggest you use sidelineCAM! (access it on your team home page)
      </Text>
      <Text>
        Advanced users with a Mevo, GoPro, or third-party broadcasting app can
        set up here.
      </Text>
    </Box>
  </Layer>
);

interface Props {}
const CameraSettings: React.FC<Props> = () => {
  const userStore = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [captures, setCaptures] = useState<TeamCapture[]>([]);

  const [showCaptureSetupInstruction, setShowCaptureSetupInstruction] =
    useState(false);
  const [newCapture, setNewCapture] = useState<TeamCapture | null>(null);

  const [showCameraLearnMore, setShowCameraLearnMore] = useState(false);

  const teamId = userStore.selectedTeam ? userStore.selectedTeam._id : null;
  const team = userStore.selectedTeam;

  const [toast, setToast] = useState<shd.Toast>();
  const [error, setError] = useState<null | string>(null);

  const userCaptures = [
    ...captures.filter((capture) => capture.isOwnedByActiveUser),
  ];

  const teamManagerCaptures = captures.filter(
    (capture) => !capture.isOwnedByActiveUser
  );

  const tokenizedName = userStore.authUser?.displayName
    ? userStore.authUser.displayName.split(' ')
    : [];
  const userFirstNamePossessive =
    tokenizedName.length > 0 ? `${tokenizedName[0]}'s` : 'Your';

  useEffect(() => {
    const fetchCaptureList = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/team/${teamId}/capture`);
        const { captureList } = response.data;
        setCaptures(captureList);
      } catch (err) {
        setError('An error occurred. Please refresh and try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchCaptureList();
  }, [teamId]);

  if (loading) {
    return <BasicLoader size={200} fullPage />;
  }

  if (error) {
    return <Text color="red">{error}</Text>;
  }

  const createCapture = (cameraType: string, cameraName: string) => {
    setLoading(true);
    axios
      .post('/api/rtmp_key', {
        cameraType,
        cameraName,
        teamId,
      })
      .then((response) => {
        if (response.data.rtmp) {
          analytics.track('Capture Setup Created', {
            cameraType,
            teamId,
          });
          const { rtmp, capture } = response.data;
          const newTeamCapture = {
            ...capture,
            rtmp,
          };
          setNewCapture(newTeamCapture);
          setShowCaptureSetupInstruction(true);

          setCaptures([...captures, newTeamCapture]);
          // setRtmpInfo(response.data);
          // setCapture(response.data.capture);
        }
        setLoading(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        // setRtmpError(error.toString());
      });
  };

  const getStatus = (capture: TeamCapture) => {
    if (!team) {
      return CaptureStatus.Disabled;
    }

    const isAnyTeamManagerCaptureLive = teamManagerCaptures.some(
      (c) => c.state_isLive && c.targetTeamId === team._id
    );
    if (capture.targetTeamId === 'disabled') {
      return CaptureStatus.Disabled;
    } else if (capture.targetTeamId !== team._id) {
      return CaptureStatus.OtherTeam;
    } else if (isAnyTeamManagerCaptureLive) {
      // this means the current team is live, but not from the user's capture
      return CaptureStatus.TeamLive;
    } else if (capture.state_isLive) {
      return CaptureStatus.Live;
    }
    return CaptureStatus.Ready;
  };

  if (!team) {
    return null;
  }

  return (
    <Box fill>
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(undefined);
          }}
        />
      )}
      {showCameraLearnMore && (
        <CameraLearnMore onClose={() => setShowCameraLearnMore(false)} />
      )}
      <Box>
        <Box pad="16px">
          <Text weight={'bold'} size="small">
            Your Camera
          </Text>
          {userCaptures.map((capture) => {
            const status = getStatus(capture);
            return (
              <CaptureItem
                key={capture._id}
                status={status}
                capture={capture}
                onSelected={() => {}}
                onUpdated={(updatedCapture, disableOthers = false) => {
                  setCaptures((prevCaptures) =>
                    prevCaptures.map((c) => {
                      if (c._id === updatedCapture._id) {
                        return updatedCapture;
                      } else if (disableOthers) {
                        return {
                          ...c,
                          targetTeamId: 'disabled',
                        };
                      } else {
                        return c;
                      }
                    })
                  );
                }}
                showEdit
              />
            );
          })}
          {userCaptures.length === 0 && (
            <Box gap="8px">
              <Text>Connect a camera below</Text>
              <Button
                secondary
                label="Mevo"
                onClick={() => {
                  const cameraName = `${userFirstNamePossessive} Mevo`;
                  createCapture(MEVO_SINGLE, cameraName);
                }}
              />
              <Button
                secondary
                label="Mevo Multicam"
                onClick={() => {
                  const cameraName = `${userFirstNamePossessive} Mevo Multicam`;
                  createCapture(MEVO_MULTI, cameraName);
                }}
              />
              <Button
                secondary
                label="Larix App"
                onClick={() => {
                  const cameraName = `${userFirstNamePossessive} Camera`;
                  createCapture(SMARTPHONE, cameraName);
                }}
              />
              <Button secondary label="GoPro" onClick={() => {}} />
              <Button
                secondary
                label="Manually enter RTMP (advanced)"
                onClick={() => {
                  const cameraName = `${userFirstNamePossessive} Camera`;
                  createCapture(SMARTPHONE, cameraName);
                }}
              />
            </Box>
          )}
        </Box>
        <Box pad="16px">
          <Text weight={'bold'} size="small">
            Team Cameras (advanced)
          </Text>
          <Box gap="8px">
            {teamManagerCaptures.map((capture) => {
              const status = getStatus(capture);
              return (
                <CaptureItem
                  key={capture._id}
                  status={status}
                  capture={capture}
                  onSelected={() => {}}
                  onUpdated={(updatedCapture, disableOthers = false) => {
                    setCaptures((prevCaptures) =>
                      prevCaptures.map((c) => {
                        if (c._id === updatedCapture._id) {
                          return updatedCapture;
                        } else if (disableOthers) {
                          return {
                            ...c,
                            targetTeamId: 'disabled',
                          };
                        } else {
                          return c;
                        }
                      })
                    );
                  }}
                  showEdit
                />
              );
            })}
          </Box>
          {teamManagerCaptures.length === 0 && (
            <Box gap="16px">
              <Text>No team cameras connected</Text>
            </Box>
          )}
        </Box>
      </Box>
      {showCaptureSetupInstruction && newCapture && (
        <CaptureSetupInstructions
          capture={newCapture}
          onClose={() => {
            setShowCaptureSetupInstruction(false);
            setNewCapture(null);
          }}
        />
      )}
    </Box>
  );
};

export default withRouter(CameraSettings);
