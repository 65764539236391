// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import { Box, RadioButtonGroup } from 'grommet';

export const PhoneRadioButton = styled(RadioButtonGroup)`
  width: 100%;
  & label {
    width: 100%;
    & div {
      width: 100%;
    }
  }
`;

export const PhoneOption = styled(Box)`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
`;

export const PhoneInputStyle = {
  height: '48px',
  border: 'none',
  borderBottom: '1px solid #066D99',
  padding: '13px 16px',
  boxSizing: 'border-box',
};
