// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  Heading,
  Tab,
  Tabs,
  DropButton,
  Layer,
  Card,
  Button,
} from 'grommet';
import { compose } from 'recompose';
import { withRouter, useLocation, useParams, Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BeatLoader } from 'react-spinners';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GameCard from './GameCard';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import LiveTestCard from '../LiveTest/LiveTestCard';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import Toast from '../../components/Toast';
import ShareDropdown from '../../helpers/ShareDropdown';
import { withFirebase } from '../../services/Firebase';
import { BBSB_SPORTS, VOLLEYBALL } from '../../constants/strings';
import { strogging } from '@shd/jslib/infra';
import { LinkPrevious } from 'grommet-icons';

const ScoringDashboardBase = ({ firebase, ...props }) => {
  const { history, onUnlink } = props;
  const userStore = useContext(UserContext);
  const location = useLocation();
  const { teamid: teamIdParam } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const scoreDelegationKeyParam = queryParams.get('key');
  const [team, setTeam] = useState(
    userStore.selectedTeam || userStore.scoringTeam
  );
  const [teamGames, setTeamGames] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState();
  const [earliestTeamGameId, setEarliestTeamGameId] = useState();
  const [scrollLoading, toggleScrollLoading] = useState(false);
  const [key, setKey] = useState();
  const [copied, setCopied] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const getTeamGames = () => {
    toggleScrollLoading(true);
    const params = { teamId: team._id, earliestTeamGameId };

    axios
      .get('/api/team_games', { params })
      .then(({ data: respTeamGames }) => {
        const filteredTeamGames = respTeamGames.filter(
          ({ _id }) => !_id.includes('GmIs01-')
        );
        if (filteredTeamGames && filteredTeamGames.length) {
          const earliestTeamGame =
            filteredTeamGames[filteredTeamGames.length - 1];
          setEarliestTeamGameId(earliestTeamGame._id);
        }
        const twoDaysAgo = Date.now() / 1000 - 2 * 24 * 60 * 60;
        const newTeamGames = [...teamGames, ...filteredTeamGames].filter(
          (game) => game.scheduleTsStart > twoDaysAgo
        );

        setTeamGames(newTeamGames);
        toggleScrollLoading(false);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleScrollLoading(false);
        toggleLoading(false);
      });
  };

  const getInviteLink = () => {
    axios
      .get(`/api/score_delegation_key/${team._id}`)
      .then(({ data: respScoreDelegationKey }) => {
        setKey(respScoreDelegationKey);
      });
  };

  useEffect(() => {
    const scoreTeamId = teamIdParam || localStorage.getItem('scoreTeamId');
    const scoreDelegationKey =
      scoreDelegationKeyParam || localStorage.getItem('scoreDelegationKey');

    if (scoreTeamId && scoreDelegationKey) {
      if (userStore.authUser) {
        strogging.log('ScoringDashboard-signing-user-out', {
          scoreTeamId,
          scoreDelegationKey,
        });
        firebase
          .doSignOut()
          .then(() => {
            localStorage.removeItem('token');
            window.location.reload();
          })
          .catch((respError) => {
            // TODO: catch error
            Sentry.captureException(respError);
          });
      }

      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
          setKey(scoreDelegationKey);
        })
        .catch((respError) => {
          if (respError.response && respError.response.status === 403) {
            setError('This link is invalid for scoring.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
          toggleScrollLoading(false);
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      getTeamGames();
      getInviteLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const scoreGame = (gameId) => {
    if (team.attrib_sportType === VOLLEYBALL.display) {
      history.push(`/scoring/vb/score/${team._id}/${gameId}`);
    } else if (BBSB_SPORTS.includes(team.attrib_sportType)) {
      history.push(`/scoring/bbsb/score/${team._id}/${gameId}`);
    } else {
      history.push(`/scoring/generic/score/${team._id}/${gameId}`);
    }
  };

  const goSetLineups = (gameId) => {
    if (team.attrib_sportType === VOLLEYBALL.display) {
      history.push(`/scoring/vb_lineups/${team._id}/${gameId}`);
    } else if (BBSB_SPORTS.includes(team.attrib_sportType)) {
      history.push(`/scoring/lineups/${team._id}/${gameId}`);
    } else {
      history.push(`/scoring/lineups_v2/${team._id}/${gameId}`);
    }
  };

  const goScheduleGame = () => {
    history.push('/scoring/schedule');
  };

  const deleteScoring = () => {
    toggleLoading(true);
    axios
      .delete(`/api/score_team/${team._id}`)
      .then(() => {
        setTeamGames([]);
        toggleLoading(false);
        onUnlink();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError.toString());
        toggleLoading(false);
      });
  };

  if (error) {
    return (
      <Text color="status-critical" size="large">
        {error}
      </Text>
    );
  }

  const renderGames = (isActive) => {
    let condition;
    let sortedTeamGames;
    if (isActive) {
      condition = (game) => game.scoreGameState !== 50;
      sortedTeamGames = teamGames.sort(
        (g1, g2) => g1.scheduleTsStart - g2.scheduleTsStart
      );
    } else {
      condition = (game) => game.scoreGameState === 50;
      sortedTeamGames = teamGames;
    }

    if (sortedTeamGames.length) {
      return (
        <Box direction="column" gap="medium">
          <InfiniteScroll
            items={sortedTeamGames}
            dataLength={sortedTeamGames.length - 1}
            next={getTeamGames}
            hasMore
            scrollThreshold={0}
          >
            {sortedTeamGames.filter(condition).map((game) => (
              <GameCard
                key={game.scoreGameId}
                game={game}
                goSetLineups={goSetLineups}
                scoreGame={scoreGame}
                onGameDeleted={(teamGameId) => {
                  setTeamGames((prevGames) => {
                    const newGames = [...prevGames];
                    return newGames.filter(({ _id }) => _id !== teamGameId);
                  });
                }}
                sportType={team.attrib_sportType}
              />
            ))}
          </InfiniteScroll>
          {!!scrollLoading && (
            <BeatLoader css={{ margin: 'auto' }} size={40} color="#949494" />
          )}
        </Box>
      );
    }
    return (
      <Text>
        No sidelineSCORE games in the last two days. Create a new game to start
        scoring
      </Text>
    );
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `sidelineScore | ${team.nameMed}`,
          text: `Keep score for ${team.nameMed} on sidelineHD`,
          url: `https://sidelinehd.com/scoring/${team._id}?key=${key}`,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const renderShareOptions = () => (
    <Box pad="medium" gap="small">
      {navigator.share ? (
        <SHDButton
          secondary
          label="Share link via..."
          mixtrack={['Share link via']}
          onClick={handleShare}
        />
      ) : null}
      <CopyToClipboard
        text={`https://sidelinehd.com/scoring/${team._id}?key=${key}`}
        onCopy={() => setCopied(true)}
      >
        <SHDButton secondary label="Copy link" />
      </CopyToClipboard>
    </Box>
  );

  const renderDropContent = () => (
    <Box width="medium" pad="medium" gap="medium">
      {key ? (
        <ShareDropdown
          primary
          color="primary-8"
          label={
            <Text weight="bold" color="primary-2">
              Invite another scorer
            </Text>
          }
          renderShareOptions={renderShareOptions}
        />
      ) : null}

      {BBSB_SPORTS.includes(team.attrib_sportType) && (
        <SHDButton
          color="primary-7"
          label={
            <Text weight="bold" color="primary-2">
              Stop using sidelineSCORE
            </Text>
          }
          mixtrack={['Stop using sidelineSCORE']}
          onClick={() => setDeleteShow(true)}
        />
      )}
    </Box>
  );

  return (
    <Box pad="medium" gap="medium" data-testid="scoring" width="large">
      {!loading ? (
        <Box gap="medium" margin={{ bottom: 'xlarge' }}>
          {(teamIdParam || localStorage.getItem('scoreTeamId')) &&
          (scoreDelegationKeyParam ||
            localStorage.getItem('scoreDelegationKey')) ? (
            <Card background="light-5" pad="small" gap="small">
              <Text weight="bold">You were shared a scoring link</Text>
              <Text>{`This means you are now scoring for ${team.nameLong}`}</Text>
              <Button
                primary
                label={`Stop scoring for ${team.nameLong}`}
                onClick={() => {
                  localStorage.removeItem('scoreDelegationKey');
                  localStorage.removeItem('scoreTeamId');
                  history.push('/signin');
                }}
              />
            </Card>
          ) : null}

          <Box direction="row" justify="between">
            <Box direction="row" gap="medium" align="center">
              <Link
                to={`/${userStore.scoringTeam?._id ?? team?._id ?? teamIdParam}`}
                style={{
                  textDecoration: 'none',
                  display: 'block-inline',
                  borderRadius: '50%',
                }}
              >
                <Box
                  round="full"
                  elevation="small"
                  pad="small"
                  background="white"
                >
                  <LinkPrevious size="24px" />
                </Box>
              </Link>
              <Heading level={3} margin="none">
                {team.nameLong}
              </Heading>
            </Box>
            <DropButton
              margin="medium"
              primary
              color="primary-8"
              label={
                <Text weight="bold" color="primary-1">
                  Settings
                </Text>
              }
              dropContent={renderDropContent()}
            />
          </Box>
          {userStore.selectedTeam ? <LiveTestCard /> : null}
          {error ? <Text color="status-critical">{error}</Text> : null}
          <Card background="primary-8" pad="medium" elevation="none">
            <Text>You are currently using sidelineSCORE</Text>
          </Card>
          <Tabs
            id="tab-header"
            alignControls="stretch"
            alignSelf="stretch"
            margin={{ bottom: 'xlarge' }}
          >
            <Tab title="Active Games">{renderGames(true)}</Tab>
            <Tab title="Past Games">{renderGames(false)}</Tab>
          </Tabs>
          <Box
            alignSelf="center"
            pad="medium"
            width="large"
            style={{ position: 'fixed', bottom: '10px', left: 'auto' }}
          >
            <SHDButton
              primary
              size="large"
              label="Create game"
              onClick={goScheduleGame}
              color="tertiary-1"
            />
          </Box>
        </Box>
      ) : (
        <BasicLoader fullPage />
      )}
      {copied ? (
        <Toast
          label="Copied scoring link to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      ) : null}
      {deleteShow ? (
        <Layer>
          <Box margin="xlarge" gap="small" align="center">
            <Heading level={4} textAlign="center">
              Are you sure you want to stop using sidelineSCORE?
            </Heading>
            <Box width="medium" margin={{ bottom: 'large' }}>
              <Text textAlign="center">
                Any sidelineSCORE link you have shared will become inactive
              </Text>
            </Box>
            <Box gap="medium">
              <SHDButton
                size="large"
                label={
                  <Text weight="bold" color="tertiary-1">
                    Yes, stop using sidelineSCORE
                  </Text>
                }
                mixtrack={['Yes stop using sidelineSCORE']}
                color="tertiary-1"
                onClick={() => {
                  deleteScoring();
                  setDeleteShow(false);
                }}
              />
              <SHDButton
                plain
                alignSelf="center"
                label={
                  <Text weight="bold" color="tertiary-1" textAlign="center">
                    Cancel
                  </Text>
                }
                mixtrack={['Cancel stop using sidelineSCORE']}
                onClick={() => setDeleteShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      ) : null}
    </Box>
  );
};

const ScoringDashboard = compose(
  withRouter,
  withFirebase
)(ScoringDashboardBase);

export default ScoringDashboard;
