// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react';
/* eslint-disable no-underscore-dangle */
import { Box, Text, Layer, Button, Footer } from 'grommet';
import { toJS } from 'mobx';
import { getDisplayName } from 'recompose';
import { Bookmark, Like } from 'grommet-icons';
import _ from 'lodash';
import { GenericScoringContext } from './context';
import { compareJerseyNum } from '../../helpers/utils';

const Player = ({ index = 0, player, selected, onSelected }) => {
  let backgroundColor;
  if (selected) {
    backgroundColor = 'primary-1';
  } else if (index % 2 === 0) {
    backgroundColor = 'light-4';
  } else {
    backgroundColor = 'light-3';
  }
  return (
    <Box
      basis="1/4"
      pad="small"
      align="center"
      onClick={() => {
        onSelected(player);
      }}
    >
      <Box
        round="full"
        background={backgroundColor}
        align="center"
        justify="center"
        border={{ color: 'primary-1', size: 'small' }}
        style={{ aspectRatio: '1/1' }}
        width="100%"
      >
        <Text weight="bold" size="large">
          {player.jerseyNum}
        </Text>
      </Box>
      <Text size="medium" truncate>
        {getDisplayName(player.nameFirst, player.nameLast)}
      </Text>
    </Box>
  );
};

const GenericPrompt = ({
  event,
  onResponse = () => {},
  closePrompt = () => {},
}) => {
  const scoringStore = useContext(GenericScoringContext);
  const [currentStep, setCurrentStep] = useState(0);
  const players = (
    scoringStore.awayHome
      ? toJS(scoringStore.homeLineup)
      : toJS(scoringStore.awayLineup)
  ).sort(compareJerseyNum);
  const [selectedPlayers, setSelectedPlayers] = useState({});

  const [selectedHighlightIndex, setSelectedHighlightIndex] = useState(null);

  const stepsCount = event.eventInfo.attribution.length;

  const nextStep = (attributionType) => {
    let s = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const attribution of event.eventInfo.attribution) {
      if (attribution.attributionType === attributionType) {
        break;
      }
      s += 1;
    }
    setCurrentStep(s + 1);
  };

  useEffect(() => {
    if (currentStep === stepsCount && currentStep >= 1) {
      const { numPlayers } = event.eventInfo.attribution[currentStep - 1];
      const isMulti = numPlayers > 1;
      if (!isMulti) {
        const newEvent = { ...event };
        newEvent.eventInfo.taggedAttribution = selectedPlayers;
        onResponse({ resp: true, event: newEvent });
      }
    } else if (currentStep >= 1) {
      const element = document.getElementById('scroll-destination');
      element.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const getRecentPlayers = () => {
    const clipEventsList = toJS(scoringStore.clipEventsList);
    const nestedAttributions = clipEventsList.map((clipEvent) => {
      const roles = Object.entries(clipEvent.attribution).map((obj) => {
        const value = obj[1];
        return value;
      });
      return roles.flat();
    });

    const taggedPlayers = nestedAttributions.flat();

    if (taggedPlayers.length === 0) {
      return [];
    }

    const uniqueTaggedPlayers = taggedPlayers.filter(
      (v, i, a) => a.findLastIndex((v2) => v2._id === v._id) === i
    );

    return uniqueTaggedPlayers.slice(-4).reverse();
  };

  const renderPlayerSelection = () =>
    event.eventInfo.attribution.map((data, stepIndex) => {
      // const data = event.eventInfo.attribution[currentStep];
      const promptPrefix = '';

      const { attributionType, prompt, numPlayers } = data;
      const isMulti = numPlayers > 1;

      const recentPlayers = getRecentPlayers();
      const recentPlayerIds = recentPlayers.map((p) => p._id);
      const restOfPlayers = players.filter(
        (p) => !recentPlayerIds.includes(p._id)
      );

      const stepSelectedPlayers = selectedPlayers[attributionType] || [];

      const showSavedTag = stepSelectedPlayers.length > 0;

      const onSelectedPlayer = (option) => {
        if (isMulti) {
          let newSelectedPlayers = [...stepSelectedPlayers, option];
          if (stepSelectedPlayers.map((p) => p._id).includes(option._id)) {
            newSelectedPlayers = newSelectedPlayers.filter(
              (p) => p._id !== option._id
            );
          }
          if (newSelectedPlayers.length <= numPlayers) {
            setSelectedPlayers({
              ...selectedPlayers,
              [attributionType]: newSelectedPlayers,
            });

            if (newSelectedPlayers.length > 0) {
              // this should show the next step if it exists,
              // but should not send the user out of the modal
              nextStep(attributionType);
            }
          }
        } else if (
          attributionType in selectedPlayers &&
          selectedPlayers[attributionType][0]._id === option._id
        ) {
          // unselecting a player
          const newSelectedPlayers = {
            ...selectedPlayers,
          };
          delete newSelectedPlayers[attributionType];
          setSelectedPlayers(newSelectedPlayers);
        } else {
          // single selecting a player
          const newSelectedPlayers = {
            ...selectedPlayers,
            [attributionType]: [option],
          };
          setSelectedPlayers(newSelectedPlayers);
          nextStep(attributionType);
        }
      };

      if (currentStep < stepIndex) {
        return null;
      }

      const shouldShowAccentedBackground =
        currentStep > 0 && currentStep === stepIndex;

      return (
        <Box
          key={stepIndex}
          pad="medium"
          gap="small"
          height={{ min: 'unset' }}
          background={shouldShowAccentedBackground ? 'accent-7' : 'white'}
          round
          fill
        >
          <Box direction="row" align="center" gap="medium">
            <Text weight="bold" size="large">{`${promptPrefix}${prompt}`}</Text>
            {showSavedTag && (
              <Box
                background="#418310"
                round
                pad={{ horizontal: 'small' }}
                align="center"
                justify="center"
              >
                <Text size="xsmall" color="white" weight="bold">
                  Saved
                </Text>
              </Box>
            )}
          </Box>
          {event.eventType === 'highlight' && (
            <Box direction="row" height={{ min: 'unset' }} gap="small">
              <Box
                direction="row"
                gap="small"
                background={
                  selectedHighlightIndex === 0 ? 'primary-1' : 'primary-8'
                }
                align="center"
                pad="medium"
                round
                border={{ color: 'primary-1', size: '2px' }}
                onClick={() => {
                  if (selectedHighlightIndex === 0) {
                    setSelectedHighlightIndex(null);
                  } else {
                    setSelectedHighlightIndex(0);
                  }
                }}
              >
                <Like />
                <Text weight="bold">Good Play</Text>
              </Box>
              <Box
                direction="row"
                gap="small"
                background={
                  selectedHighlightIndex === 1 ? 'primary-1' : 'primary-8'
                }
                align="center"
                pad="medium"
                round
                border={{ color: 'primary-1', size: '2px' }}
                onClick={() => {
                  if (selectedHighlightIndex === 1) {
                    setSelectedHighlightIndex(null);
                  } else {
                    setSelectedHighlightIndex(1);
                  }
                }}
              >
                <Bookmark />
                <Text weight="bold">Coachable Moment</Text>
              </Box>
            </Box>
          )}

          <Box gap="small" height={{ min: 'unset' }} fill>
            {recentPlayers.length > 0 && <Text>Recently tagged players</Text>}
            {recentPlayers.length > 0 && (
              <Box
                id="recent-players-container"
                direction="row"
                align="center"
                overflow={{ x: 'scroll' }}
                background="light-4"
                round="small"
                height={{ min: 'unset' }}
                fill="horizontal"
              >
                {recentPlayers.map((player, i) => (
                  <Player
                    key={player._id}
                    index={i}
                    player={player}
                    data={data}
                    selected={stepSelectedPlayers
                      .map((p) => p._id)
                      .includes(player._id)}
                    onSelected={onSelectedPlayer}
                  />
                ))}
              </Box>
            )}
            <Box
              id="rest-of-players-container"
              pad={{ top: 'medium' }}
              direction="row"
              align="center"
              overflow="scroll"
              wrap
              height={{ min: 'unset' }}
              fill="horizontal"
            >
              {restOfPlayers.map((player, i) => (
                <Player
                  key={player._id}
                  index={i}
                  player={player}
                  data={data}
                  selected={stepSelectedPlayers
                    .map((p) => p._id)
                    .includes(player._id)}
                  onSelected={onSelectedPlayer}
                />
              ))}
            </Box>
          </Box>
        </Box>
      );
    });

  if (
    scoringStore.homeLineup.length === 0 &&
    scoringStore.awayLineup.length === 0
  ) {
    const newEvent = { ...event };
    newEvent.eventInfo.taggedAttribution = selectedPlayers;
    onResponse({ resp: true, event: newEvent });
    return null;
  }

  return (
    <Layer full="vertical" modal style={{ height: '100dvh' }}>
      <Box fill>
        <Box fill overflow="auto" pad="xsmall" gap="medium">
          {renderPlayerSelection()}
          <Box height={{ min: '144px' }} />
          <Box id="scroll-destination" height={{ min: '12px' }} />
        </Box>
        <Footer pad="small" border={{ side: 'top' }}>
          <Box
            align="center"
            justify="center"
            fill="horizontal"
            onClick={() => {
              closePrompt();
            }}
          >
            <Text color="tertiary-1" textAlign="center" weight="bold">
              Cancel
            </Text>
          </Box>
          <Button
            size="large"
            fill="horizontal"
            primary
            color="tertiary-1"
            label={
              <Text weight="bold">
                {!_.isEmpty(selectedPlayers) ? 'Complete' : 'Skip'}
              </Text>
            }
            onClick={() => {
              const newEvent = { ...event };
              newEvent.eventInfo.taggedAttribution = selectedPlayers;

              if (
                event.eventType === 'highlight' &&
                selectedHighlightIndex !== null
              ) {
                const description =
                  selectedHighlightIndex === 0
                    ? 'Good Play'
                    : 'Coachable Moment';
                newEvent.eventInfo.attribution[0].descriptionTxt = description;
              }

              onResponse({ resp: true, event: newEvent });
            }}
          />
        </Footer>
      </Box>
    </Layer>
  );
};

export default GenericPrompt;
