// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box } from 'grommet';
import withLayout from '../../helpers/withLayout';

const markdown = `
# Getting started with livestreaming
### You've come to the right place!

If you're here, then you've taken the first step towards delighting 
your team's fans, parents, coaches, and players with live, professional-looking
streams and replayable highlights.


You're going to need a camera and a mount. Let's walk through the options...


## Cameras
Livestreaming cameras can range from a free phone app to a $1000 multi-cam setup.
Here are a few of the standard options that have made sidelineHD teams happy.

#### Smart phone
You can get surprisingly high quality footage using just a smart phone! All you will need
is a the [Larix Broadcaster app](https://apps.apple.com/us/app/larix-broadcaster/id1042474385). 

#### [Mevo Start](https://mevo.com/?gclid=EAIaIQobChMI9-KHiqby7gIVFAnnCh1tEw3kEAAYASAAEgILPvD_BwE) ($380)
You cannot go wrong with a Mevo Start. This camera was built for livestreaming. It offers
easy-to-use editing tools that allows for zooming, panning, and enhancing video. 

#### [GoPro Hero](https://gopro.com/en/us/shop/cameras/hero9-black/CHDHX-901-master.html?gclid=EAIaIQobChMI36PMyqXy7gIVcz6tBh2jOAOVEAAYASAAEgJ64_D_BwE&gclsrc=aw.ds) ($400)
GoPro designed its cameras for action sports, which means they're durable and have great
color-balance in bright outdoor lighting. Its wide angle lens is perfect for mounting on a
backstop and capturing the whole field of play. **Note: streaming only works with 
GoPro Hero 7 and higher.**


## Mounts
At nearly all fields, the best way to capture the action will be from behind home plate
through a fence or net. Your viewers want to see the game, not the fence, so here are
a few mounts that will let your camera get a clear shot of the action.

#### [LynkSpyder](https://lynkspyder.com/) ($50)

The LynkSpyder is an ultra-sturdy solution to your camera mounting needs. Its bulky steel frame
will ensure minimal harm or camera movement from foul tips. It also works great on netting. **Note: if using a GoPro or a smart phone
camera, make sure you purchase the appropriate mount add-ons.**

#### [The Fence Clip](https://www.thefenceclip.com/) ($60)

With a lightweight and sleek design, the fence clip works great for mounting your camera onto a chain-link
fence. You will need the additional NetCam mount if you need to work with netting. **Note: if using a 
smart phone camera, make sure you purchase the appropriate mount add-ons.**

## Optional purchases

#### Backup battery
Missing out on the extra-inning action because of a dead battery is every streamer's worst nightmare.
A backup battery will protect you from such a tragedy.

#### WiFi Hotspot
Connection at fields can be spotty, and nobody likes a grainy stream. You can boost your connection
with a hotspot from your cellular service provider.

`;

const StreamingInfoPage = () => (
  <Box pad="large" width={{ max: 'xlarge' }}>
    <ReactMarkdown source={markdown} />
  </Box>
);

export default withLayout(StreamingInfoPage);
