import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TEAM_SETTINGS__PRIVACY,
  TEAM_SETTINGS__SPONSORS,
  TEAM_SETTINGS__TEAM_INFORMATION,
} from '../../constants/routes';
import SettingsMenuItem from './SettingsMenuItem';
import { Box, Grid, ResponsiveContext, Tab, Tabs } from 'grommet';
import TeamPrivacySettings from './TeamPrivacySettings';
import TeamInformation from './TeamProfile/TeamInformation';
import SponsorLogosSettings from './SponsorLogosSettings';

interface TeamProfileSettingsProps {}
const TeamProfileSettings: React.FC<TeamProfileSettingsProps> = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const screenSize = useContext(ResponsiveContext);

  const pathToTabIndex: { [key: string]: number } = {
    [TEAM_SETTINGS__TEAM_INFORMATION]: 0,
    [TEAM_SETTINGS__SPONSORS]: 1,
    [TEAM_SETTINGS__PRIVACY]: 2,
  };

  const [tabIndex, setTabIndex] = useState(pathToTabIndex[currentPath] || 0);

  const onActive = (index: number) => {
    if (index === 0) {
      history.push(TEAM_SETTINGS__TEAM_INFORMATION);
    } else if (index === 1) {
      history.push(TEAM_SETTINGS__SPONSORS);
    } else if (index === 2) {
      history.replace(TEAM_SETTINGS__PRIVACY);
    }
    setTabIndex(index);
  };

  const renderMobileMenu = () => {
    let columns = '100%';
    let gap = 'none';
    if (screenSize !== 'small') {
      columns = '1/3';
      gap = 'medium';
    }

    return (
      <Box gap="medium" pad="medium">
        <Grid columns={columns} gap={gap}>
          <SettingsMenuItem
            title="Team Information"
            subtitle="Manage your public-facing team information."
            href={TEAM_SETTINGS__TEAM_INFORMATION}
          />
          <SettingsMenuItem
            title="Sponsor Logos"
            subtitle="Upload sponsor logos to display on stream."
            href={TEAM_SETTINGS__SPONSORS}
          />
          <SettingsMenuItem
            title="Privacy"
            subtitle="Change how much to show, or not show, to the public"
            href={TEAM_SETTINGS__PRIVACY}
          />
        </Grid>
      </Box>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <Box pad={{ vertical: 'small' }}>
        <Tabs activeIndex={tabIndex} onActive={onActive}>
          <Tab title="Team Information">
            <TeamInformation />
          </Tab>
          <Tab title="Privacy">
            <TeamPrivacySettings />
          </Tab>
          <Tab title="Sponsor Logos">
            <SponsorLogosSettings />
          </Tab>
        </Tabs>
      </Box>
    );
  };

  return screenSize === 'small' ? renderMobileMenu() : renderDesktopMenu();
};

export default TeamProfileSettings;
