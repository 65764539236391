/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo, PropsWithChildren } from 'react';
import { strogging } from '@shd/jslib/infra';
import * as Sentry from '@sentry/browser';
import { fetchAndActivate } from 'firebase/remote-config';
import { withFirebase } from '../Firebase';
import { Firebase } from '../Firebase/firebase';
import { remoteFlags } from '@shd/jslib/ui';

interface Props {
  firebase: Firebase;
}

type WebRemoteConfigValueTypes = typeof remoteFlags.baseValueTypes & {
  is_admin: undefined;
  fb_scopes: string;
  experimental_fb_scopes: string;
  hls_viewer: undefined;
  livestreamer_banner: string;
  view_all_clips_enabled: boolean;
  partner_clip_viewer_enabled: boolean;
};

export type WebRemoteConfigParam = keyof WebRemoteConfigValueTypes;

const webDefaultFlags: remoteFlags.RemoteConfigValueTypedFlags<WebRemoteConfigValueTypes> =
  {
    ...remoteFlags.defaultFlags,
    is_admin: { is_enabled: false },
    fb_scopes: {
      is_enabled: true,
      value:
        'publish_video,pages_show_list,pages_manage_posts,business_management',
    },
    experimental_fb_scopes: {
      is_enabled: false,
      value:
        'publish_video,pages_show_list,pages_manage_posts,business_management',
    },
    hls_viewer: { is_enabled: false },
    livestreamer_banner: {},
    view_all_clips_enabled: { is_enabled: false },
    partner_clip_viewer_enabled: { is_enabled: false },
  };

export const FlagsContext: remoteFlags.ReactFlagsContext<WebRemoteConfigValueTypes> =
  React.createContext({
    flags: webDefaultFlags,
    internalTeamIDs: [] as string[],
    internalUserIDs: [] as string[],
    refresh: Promise.resolve,
  });

const FlagsProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  ...rest
}) => {
  const { getAllRemoteConfig, getRemoteConfigValue, remoteConfig } =
    rest.firebase;
  const [flags, setFlags] =
    useState<
      remoteFlags.RemoteConfigValueTypedFlags<WebRemoteConfigValueTypes>
    >(webDefaultFlags);
  const [internalUserIDs, setInternalUserIDs] = useState<string[]>([]);
  const [internalTeamIDs, setInternalTeamIDs] = useState<string[]>([]);

  useEffect(() => {
    remoteConfig.defaultConfig = remoteFlags.defaultConfigFlags;
    fetchAndActivate(remoteConfig)
      .then(() => getAllRemoteConfig())
      .then((rflags) => {
        const newFlags: Partial<
          remoteFlags.RemoteConfigFlags<WebRemoteConfigValueTypes>
        > = {};
        Object.keys(rflags).forEach((key) => {
          newFlags[key as keyof WebRemoteConfigValueTypes] = JSON.parse(
            getRemoteConfigValue(key).asString()
          );
        });
        strogging.log('new Remote Flags');
        setFlags({ ...flags, ...newFlags });
        setInternalUserIDs(newFlags.internal_user_ids ?? []);
        setInternalTeamIDs(newFlags.internal_team_ids ?? []);
      })
      .catch((e) => {
        strogging.exception('fetchAndActivate:exception', e);
        Sentry.captureException(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      flags,
      internalUserIDs,
      internalTeamIDs,
      refresh: Promise.resolve, // not implemented
    }),
    [flags, internalTeamIDs, internalUserIDs]
  );
  return (
    <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>
  );
};

export default withFirebase(FlagsProvider);
