// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Text, Select } from 'grommet';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import Toast from '../../components/Toast';
import { VBScoringContext } from './context';
import { compareJerseyNum } from '../../helpers/utils';

const VBSettings = observer(() => {
  const scoringStore = useContext(VBScoringContext);
  const players = scoringStore.awayHome
    ? scoringStore.homeLineup
    : scoringStore.awayLineup;

  const liberoOptions = players
    .map((player, i) => ({
      value: player._id,
      label: `${player.jerseyNum} - ${player.nameFirst} ${player.nameLast}`,
      isDisabled:
        [1, 2, 3].includes(i) || (scoringStore.liberos || []).length >= 2,
      jerseyNum: player.jerseyNum,
    }))
    .filter((player) => {
      const isNotPlaceholder = player.value.includes('placeholder-') === false;
      return isNotPlaceholder;
    })
    .sort(compareJerseyNum);

  const [toast, setToast] = useState(null);

  const renderLiberoSelector = () => {
    const liberos = scoringStore.liberos || [];

    return (
      <Box>
        <Text weight="bold">Liberos</Text>
        {/* {liberos.map((libero) => {
          const { name, number, _id } = libero;
          return (
            <Box key={_id}>
              <Text>{`${number} - ${name}`}</Text>
            </Box>
          );
        })} */}
        <ReactSelect
          value={liberos.map((libero) => ({
            value: libero._id,
            label: `${libero.jerseyNum} - ${libero.nameFirst} ${libero.nameLast}`,
          }))}
          isMulti
          name="colors"
          options={liberoOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(selected) => {
            // for each selected, get the player object from the players array
            // and add it to the liberos array
            const newLiberos = selected.map((s) => {
              const player = players.find((p) => p._id === s.value);
              return player;
            });

            setToast({
              label: `Libero${newLiberos.length === 1 ? '' : 's'} updated!`,
              background: 'status-ok',
            });
            scoringStore.handleEvent('subLiberos', { liberos: newLiberos });
          }}
        />
      </Box>
    );
  };

  return (
    <Box pad="medium" gap="medium">
      <Box>
        <Text weight="bold">Scoreboard Position</Text>
        <Text>Which side should we show your team on?</Text>
        <Select
          labelKey="label"
          valueKey={{ key: 'key', reduce: true }}
          value={scoringStore.scoreboard.scoreboardTeamPos}
          options={[
            {
              label: 'Left',
              key: 'L',
            },
            {
              label: 'Right',
              key: 'R',
            },
          ]}
          onChange={({ option }) => {
            scoringStore.handleEvent('scoreboardPosition', {
              position: option.key,
            });
            setToast({
              label: 'Scoreboard position updated!',
              background: 'status-ok',
            });
          }}
        />
      </Box>
      <Box>{renderLiberoSelector()}</Box>
      {toast && (
        <Toast
          full="horizontal"
          label={toast.label}
          background={toast.background}
          duration={2000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Box>
  );
});

export default VBSettings;
