import React, { useState, useContext, useRef } from 'react';
import { Box, Text, RadioButtonGroup } from 'grommet';
import { UserContext } from '../../services/Session/context';
import RoundedToggle from '../../components/RoundedToggle';
import axios from '../../helpers/axios';
import { debounce } from 'lodash';
import { shd } from '@shd/jslib/models';
import * as Sentry from '@sentry/browser';
import Toast from '../../components/Toast';

const TeamPrivacySettings: React.FC = () => {
  const userStore = useContext(UserContext);
  const [team, setTeam] = useState(userStore.selectedTeam);
  const [error, setError] = useState<null | string>(null);
  const [showEditSuccess, setShowEditSuccess] = useState(false);

  const displayOptions = [
    { label: 'Willie Mays', value: 40 },
    { label: 'Willie M.', value: 30 },
    { label: 'W. Mays', value: 20 },
    { label: 'W.M.', value: 10 },
    { label: "Don't display", value: 0 },
  ];

  const getNameStyleValue = () => {
    if (team?.embedNameFirstLevel === 2 && team?.embedNameLastLevel === 2) {
      return displayOptions[0].value;
    }
    if (team?.embedNameFirstLevel === 2 && team?.embedNameLastLevel === 1) {
      return displayOptions[1].value;
    }
    if (team?.embedNameFirstLevel === 1 && team?.embedNameLastLevel === 2) {
      return displayOptions[2].value;
    }
    if (team?.embedNameFirstLevel === 1 && team?.embedNameLastLevel === 1) {
      return displayOptions[3].value;
    }
    return displayOptions[4].value;
  };

  const debouncedUpdateTeamSettings = useRef(
    debounce(
      (newTeam: shd.Team) => {
        axios
          .put(`/api/team/${newTeam?._id}`, {
            teamInfo: newTeam,
            isEditPrivacy: true,
          })
          .then(() => {
            userStore.setSelectedTeam(newTeam);
            const teams = [...userStore.userTeams].map((storeTeam) => {
              if (storeTeam._id === newTeam._id) {
                return newTeam;
              }
              return storeTeam;
            });
            userStore.setUserTeams(teams);
            setShowEditSuccess(true);
            setTimeout(() => {
              setShowEditSuccess(false);
            }, 3000);
          })
          .catch((respError) => {
            Sentry.captureException(respError);
            if (respError.response && respError.response.data.message) {
              setError(respError.response.data.message);
            }
          });
      },
      500,
      { leading: false, trailing: true }
    )
  ).current;

  const updateTeam = (newTeam: shd.Team) => {
    setTeam(newTeam);
    debouncedUpdateTeamSettings(newTeam);
  };

  if (!team) {
    return null;
  }

  return (
    <Box>
      {error && <Text color="status-error">{error}</Text>}
      {showEditSuccess && (
        <Toast
          label="Settings saved"
          background="status-ok"
          duration={3000}
          onClose={() => setShowEditSuccess(false)}
        />
      )}
      <Box pad={{ horizontal: '16px' }}>
        <Box
          pad={{ vertical: '16px' }}
          gap="16px"
          border={{ side: 'bottom', color: 'light-1' }}
        >
          <Text weight={'bold'} size="small">
            Player Name Display Style
          </Text>
          <Box direction="row" gap="16px" align="center">
            <Box gap="4px">
              <Text weight={'bold'}>There are players under the age of 13</Text>
              <Text>
                If a team has any players under the age of 13, we do not display
                full names.
              </Text>
            </Box>
            <RoundedToggle
              checked={!team.isAge13Plus}
              onChange={(x) => {
                const newTeam = { ...team };
                if (x.target.checked) {
                  // have to update embedNameFirstLevel and embedNameLastLevel to a valid value
                  newTeam.embedNameFirstLevel = 2;
                  newTeam.embedNameLastLevel = 1;
                }
                updateTeam({ ...newTeam, isAge13Plus: !x.target.checked });
              }}
            />
          </Box>
          <Box gap="8px">
            <Text weight={'bold'}>Display Style</Text>
            <RadioButtonGroup
              name="radio"
              value={getNameStyleValue()}
              options={displayOptions.filter((option) => {
                if (!team.isAge13Plus && option.value === 40) {
                  return false;
                }
                if (!team.isAge13Plus && option.value === 20) {
                  return false;
                }
                return true;
              })}
              onChange={({ target: { value } }) => {
                const newTeam = { ...team };
                if (value === '40') {
                  newTeam.embedNameFirstLevel = 2;
                  newTeam.embedNameLastLevel = 2;
                } else if (value === '30') {
                  newTeam.embedNameFirstLevel = 2;
                  newTeam.embedNameLastLevel = 1;
                } else if (value === '20') {
                  newTeam.embedNameFirstLevel = 1;
                  newTeam.embedNameLastLevel = 2;
                } else if (value === '10') {
                  newTeam.embedNameFirstLevel = 1;
                  newTeam.embedNameLastLevel = 1;
                } else if (value === '0') {
                  newTeam.embedNameFirstLevel = 0;
                  newTeam.embedNameLastLevel = 0;
                  newTeam.isNameOnPublicWebsite = false;
                }
                updateTeam(newTeam);
              }}
            />
          </Box>
        </Box>
        <Box
          pad={{ vertical: '16px' }}
          gap="16px"
          border={{ side: 'bottom', color: 'light-1' }}
        >
          <Text weight={'bold'} size="small">
            Player Privacy
          </Text>
          <Box gap="16px" flex fill="horizontal">
            <Box direction="row" justify="between" align="center" gap="16px">
              <Text>Show Player Names on Team Page</Text>
              <RoundedToggle
                checked={team.isNameOnPublicWebsite}
                onChange={(x) => {
                  updateTeam({
                    ...team,
                    isNameOnPublicWebsite: x.target.checked,
                  });
                }}
              />
            </Box>
            <Box direction="row" justify="between" align="center" gap="16px">
              <Text>
                Show player profile pictures on team page when available
              </Text>
              <RoundedToggle
                checked={team.isEmbedImagePublicWebsite}
                onChange={(x) => {
                  updateTeam({
                    ...team,
                    isEmbedImagePublicWebsite: x.target.checked,
                  });
                }}
              />
            </Box>
            <Box direction="row" justify="between" align="center" gap="16px">
              <Text>
                Show player profile pictures on livestream when available
              </Text>
              <RoundedToggle
                checked={team.isEmbedImageVideo}
                onChange={(x) => {
                  updateTeam({
                    ...team,
                    isEmbedImageVideo: x.target.checked,
                  });
                }}
              />
            </Box>
            <Box direction="row" justify="between" align="center" gap="16px">
              <Text>
                Show unclaimed players&apos; clips and team HitScope to public
                (recommended)
              </Text>
              <RoundedToggle
                checked={team.publicPublishLevel === 10}
                onChange={(x) => {
                  const publicPublishLevel = x.target.checked ? 10 : 5;
                  updateTeam({
                    ...team,
                    publicPublishLevel,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          pad={{ vertical: '16px' }}
          gap="16px"
          border={{ side: 'bottom', color: 'light-1' }}
        >
          <Text weight={'bold'} size="small">
            Live Highlights
          </Text>
          <Box direction="row" justify="between" align="center" gap="16px">
            <Text>Send LIVE player highlights via text message</Text>
            <RoundedToggle
              checked={team.isNotifyCommunityTeamPlayerUpdates}
              onChange={(x) => {
                updateTeam({
                  ...team,
                  isNotifyCommunityTeamPlayerUpdates: x.target.checked,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamPrivacySettings;
