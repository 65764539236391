// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const stepOneMarkdown = `
### Sync sidelineHD team to iScore

#### Step 1

Download the iScore mobile app from the [Apple App Store](https://apps.apple.com/us/app/iscore-baseball-and-softball/id364364675)
or from the [Google Play Store](https://play.google.com/store/apps/details?id=com.fasterthanmonkeys.iscore&hl=en_US&gl=US).

Open the app. Go to "OPTIONS". Turn on the "iScorecast" switch.

![iScore app](/iscorecast.png)

`;

const stepTwoMarkdown = `
### Sync sidelineHD team to iScore

#### Step 2
Open the app. Go to "TEAM MANAGER". Then press "DISCOVER TEAM".

![iScore team manager](/team_manager.PNG)

`;

const stepThreeMarkdown = `
### Sync sidelineHD team to iScore

#### Step 3

Select "sidelineHD" from the options listed.

![iScore discover options](/discover_options.PNG)

`;

const stepFourMarkdown = `
### Sync sidelineHD team to iScore

#### Step 4

Enter the above handle and PIN into the iScore app, and then press "Login".
When you see your sidelineHD roster, press "IMPORT".

![iScore sidelineHD team form](/shd_form.png)
`;

const stepFiveMarkdown = `
### Sync sidelineHD team to iScore

#### Step 5

Exit back out to the iScore home page, and select "GAME MANAGER". Press "ADD" at the top right of the screen.

![iScore home](/iscore_home_2.PNG)

![iScore game manager](/game_manager.jpeg)

`;

const stepSixMarkdown = `
### Sync sidelineHD team to iScore

#### Step 6

Create a fake game with your newly created team as the Home and Visitor. Leave the rest
of the options default or blank. Then press "DONE" at the top right of the screen.

![iScore new game](/new_game.PNG)

`;

const stepOneMarkdownAlt = `
### Sync sidelineHD team to iScore

Download the iScore mobile app from the [Apple App Store](https://apps.apple.com/us/app/iscore-baseball-and-softball/id364364675)
or from the [Google Play Store](https://play.google.com/store/apps/details?id=com.fasterthanmonkeys.iscore&hl=en_US&gl=US).

Open the app. Go to "OPTIONS". Turn on the "iScorecast" switch.

![iScore app](/iscorecast.png)

`;

const stepTwoMarkdownAlt = `

Within "OPTIONS", find your "CUSTOMER ID" at the bottom of the screen.
Enter this value into the field above.

![iScore app](/iscore_device_id.PNG)

`;

export {
  stepOneMarkdown, stepTwoMarkdown, stepThreeMarkdown,
  stepFourMarkdown, stepFiveMarkdown, stepSixMarkdown,
  stepOneMarkdownAlt, stepTwoMarkdownAlt,
};
