// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import FillButtons from './FillButtons';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../style';
import { ButtonGroup, ManualButton } from './style';
import { BBSB_SPORTS_KEYS, VOLLEYBALL } from '../../../../constants/strings';

const FillInfoStep = ({
  value, setImportSource, setShowSearchModal, handleForward,
  setShowLALoginModal, setShowTSLoginModal,
}) => {
  const isPrefillOption = BBSB_SPORTS_KEYS.includes(value.attrib_sportType)
  || VOLLEYBALL.key === value.attrib_sportType;
  // const isPrefillOption = true;

  return (
    <>
      <FieldLabelWrapper>
        <FieldLabel color="dark-1">Continue creating my team and roster</FieldLabel>
        {isPrefillOption && <FieldTip color="dark-4">Pre-fill my team information with:</FieldTip>}
      </FieldLabelWrapper>
      <ButtonGroup>
        <FillButtons
          value={value}
          setImportSource={setImportSource}
          setShowSearchModal={setShowSearchModal}
          setShowLALoginModal={setShowLALoginModal}
          setShowTSLoginModal={setShowTSLoginModal}
        />
      </ButtonGroup>
      <ManualButton label="I want to enter my team information" onClick={handleForward} />
    </>
  );
};

export default FillInfoStep;
