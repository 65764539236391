// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../helpers/axios';
import { strogging } from '@shd/jslib/infra';

export default function useRemoteLoginAuthorize() {
  const queryPin = new URLSearchParams(useLocation().search).get('pin');
  const authorize = React.useCallback(
    (whichAuth) => {
      axios
        .post('/api/remote_login/authorize', {
          pin: queryPin,
          authorize: whichAuth,
        })
        .then(({ data: { statusKey } }) => {
          strogging.log('remote login authorized', { statusKey });
          const now = new Date().valueOf();
          setTimeout(() => {
            if (new Date().valueOf() - now > 100) {
              strogging.log('app apparently opened');
            } else {
              strogging.log('closing activation window');
              window.close();
            }
          }, 50);

          window.location.href = `sidelinehd://authorize?statusKey=${statusKey}`;
          // if we are still here, close tab.
        })
        .catch((err) => {
          strogging.exception('remote_login_authorize:exception', err);
        });
    },
    [queryPin]
  );

  return React.useMemo(() => ({ queryPin, authorize }), [queryPin, authorize]);
}
