import React from 'react';
import { Layer } from 'grommet';
import {
  MEVO,
  GOPRO,
  SMARTPHONE,
  SMARTPHONE_LEGACY,
  MEVO_SINGLE,
  MEVO_MULTI,
} from '../../constants/strings';
import { TeamCapture } from '@shd/jslib/models/shd';
import MevoSetup from '../StreamingConfig/MevoSetup';
import MevoSingleSetupModal from './MevoSingleSetupModal';
import MevoMultiSetupModal from './MevoMultiSetupModal';
import SmartphoneSetupModal from './SmartphoneSetupModal';
import GoProSetupModal from './GoProSetupModal';

interface Props {
  capture: TeamCapture;
  onClose: () => void;
}
const CaptureSetupInstructions: React.FC<Props> = ({ capture, onClose }) => {
  const isSmartphone =
    capture.equipCamera === SMARTPHONE ||
    capture.equipCamera === SMARTPHONE_LEGACY;

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      {/* TODO: Get rid of MevoSetup after we decide how to handle legacy Mevo type  */}
      {capture.equipCamera === MEVO && (
        <MevoSetup
          name={capture.captureName}
          rtmp={capture.rtmp}
          onClose={onClose}
        />
      )}
      {capture.equipCamera === MEVO_SINGLE && (
        <MevoSingleSetupModal capture={capture} onClose={onClose} />
      )}
      {capture.equipCamera === MEVO_MULTI && (
        <MevoMultiSetupModal capture={capture} onClose={onClose} />
      )}
      {isSmartphone && (
        <SmartphoneSetupModal capture={capture} onClose={onClose} />
      )}

      {capture.equipCamera === GOPRO && (
        <GoProSetupModal capture={capture} onClose={onClose} />
      )}
    </Layer>
  );
};

export default CaptureSetupInstructions;
