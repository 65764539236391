// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  ResponsiveContext,
  Button,
  Card,
  DropButton,
  Layer,
  Image,
} from 'grommet';
import { compose } from 'recompose';
import { withRouter, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Close, DownloadOption, Toast } from 'grommet-icons';
import { HiShare } from 'react-icons/hi';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import withLayout from '../../helpers/withLayout';
import PlayerCard from '../Player/PlayerCard';
import ClipVideoPlayer from '../../helpers/ClipVideoPlayer';
import { isiOS } from '../../helpers/browserDetect';
import ShareDropdown from '../../helpers/ShareDropdown';

const Highlight = ({ history }) => {
  const publicPublishLevel = 10;
  const screen = useContext(ResponsiveContext);
  const { highlight_reel_id: highlightReelId } = useParams();

  const [player, setPlayer] = useState({});
  const [teams, setTeams] = useState({});
  const [showPlayerCard, setShowPlayerCard] = useState(false);
  const [error, setError] = useState();
  const [copied, setCopied] = useState(false);
  const [showDownloadInstructions, setShowDownloadInstructions] =
    useState(false);

  const [highlight, setHighlight] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/highlight/${highlightReelId}`)
      .then((response) => {
        const respHighlight = response.data.highlight;
        setHighlight(response.data.highlight);

        axios
          .get(`/api/player/${respHighlight.claimedPlayerId}`)
          .then((res) => {
            setPlayer(res.data.player);
            setTeams(res.data.teams);
          })
          .catch((respError) => {
            Sentry.captureException(respError);
            setError(respError);
          });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError('Something went wrong.');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: highlight.titleText,
          text: `Checkout ${highlight.titleText} on sidelineHD`,
          url: window.location.href,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const renderShareOptions = () => (
    <Box pad="medium" gap="small">
      {screen === 'small' && (
        <Button label="Share clip via ..." onClick={handleShare} />
      )}
      <CopyToClipboard
        text={window.location.href}
        onCopy={() => setCopied(true)}
      >
        <Button label="Copy link to clip" />
      </CopyToClipboard>
    </Box>
  );

  const onDownloadClicked = () => {
    setShowDownloadInstructions(true);
  };

  const downloadInstructions = (
    <Layer onEsc={() => setShowDownloadInstructions(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <Button
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowDownloadInstructions(false)}
          />
        </Box>
        <Box margin="small">
          <Text>
            Your video will appear in the download list in the top right corner
            of the screen after you press the down arrow.
          </Text>
          <Image src="/download_clip_help.png" />
        </Box>
      </Box>
    </Layer>
  );

  return (
    <Box pad="medium" width="large" gap="small" margin="0 auto">
      {error && <Text color="status-critical">{error}</Text>}
      <Card fill background="primary-8">
        <Box background="primary-1" pad="medium">
          <Text size="large">{highlight && highlight.titleText}</Text>
        </Box>
        <Box pad="medium" direction="row" justify="between" align="center">
          <Text id="player-name-header" size="xlarge" weight="bold">
            {`${player.nameFirst} ${player.nameLast} `}
          </Text>
          <Box>
            <DropButton
              label={showPlayerCard ? 'Hide' : 'View details'}
              dropAlign={{ top: 'bottom' }}
              dropProps={{ margin: 'none' }}
              dropContent={
                <Box fill="horizontal">
                  <PlayerCard
                    history={history}
                    player={player}
                    image={player.DNOR_imgUrlT150}
                    name={`${player.nameFirst} ${player.nameLast} `}
                    teams={teams}
                    claimed
                    compilation
                  />
                </Box>
              }
              onOpen={() => setShowPlayerCard(true)}
              onClose={() => setShowPlayerCard(false)}
            />
          </Box>
        </Box>
      </Card>
      {highlight ? (
        <Card id="inner" style={{ display: 'inline-block' }} round="small">
          <Box height="240px">
            <ClipVideoPlayer
              id={highlight._id}
              videoSrc={highlight.videoUrl}
              isHighlightReel
            />
          </Box>
          <Box pad="small">
            <Text weight="bold" size="medium">
              {highlight.titleText}
            </Text>
          </Box>
          <Box pad="small">
            <Box direction="row" justify="between">
              <Box
                pad="small"
                direction="row"
                gap="small"
                align="center"
                fill="horizontal"
                justify="end"
              >
                {publicPublishLevel === -1 && (
                  <Box gap="large" direction="row" align="center">
                    <Button
                      plain
                      color="secondary-1"
                      icon={<DownloadOption size="20px" color="secondary-1" />}
                      label={<Text size="small">Download</Text>}
                      id="download-clip-unclaimed"
                      onClick={onDownloadClicked}
                    />
                    <Button
                      plain
                      label={<Text size="small">Share</Text>}
                      icon={<HiShare />}
                      color="secondary-1"
                      id="share-clip-unclaimed"
                      onClick={onDownloadClicked}
                    />
                  </Box>
                )}
                {publicPublishLevel >= 10 ? (
                  <Box gap="large" direction="row" align="center">
                    <Button
                      plain
                      color="secondary-1"
                      icon={<DownloadOption size="20px" color="secondary-1" />}
                      label={<Text size="small">Download</Text>}
                      href={highlight.videoUrl}
                      id="download-clip"
                      onClick={onDownloadClicked}
                    />
                    <ShareDropdown
                      plain
                      label={<Text size="small">Share</Text>}
                      color="secondary-1"
                      renderShareOptions={renderShareOptions}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Card>
      ) : (
        <BasicLoader />
      )}
      <DropButton
        size="large"
        primary
        color="tertiary-1"
        label={<Text weight="bold">Share this Highlight Reel</Text>}
        dropAlign={{ bottom: 'top' }}
        dropContent={
          <Box pad="medium" gap="small">
            {screen === 'small' && (
              <Button label="Share page via ..." onClick={handleShare} />
            )}
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => setCopied(true)}
            >
              <Button label="Copy link to clip" />
            </CopyToClipboard>
          </Box>
        }
      />
      {copied ? (
        <Toast
          label="Copied url to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      ) : null}
      {showDownloadInstructions && isiOS() ? downloadInstructions : null}
    </Box>
  );
};

const HighlightPage = compose(withLayout, withRouter)(Highlight);

export default HighlightPage;
