// Packages
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useRevenueCat,
  usePaywallTracking,
} from '../../components/Paywall/paywall.hooks';

// Components
import { BasicLoader } from '../../helpers/Loaders';
import { Anchor, Box, Button, Heading, Layer, Text } from 'grommet';
import {
  PaywallButtonUnlock,
  PaywallLegalLink,
} from '../../components/Paywall/paywall.styles';
import { CircleInformation, Close, LinkPrevious } from 'grommet-icons';
import PaywallList from '../../components/Paywall/components/PaywallFeaturesList';
import { AppHostContext } from '../../services/appHost';
import PaywallCta from '../../components/Paywall/PaywallCta';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import {
  PaywallContext,
  PaywallEvents,
} from '../../components/Paywall/paywall.types';

const SettingsManageSubscription: React.FC = () => {
  // Hooks
  const { activeSubscription, isLoadingRc } = useRevenueCat();
  const history = useHistory();
  const appHost = useContext(AppHostContext);
  const userStore = useContext(UserContext);
  const showInternalMonetizationTests = useIsFeatureEnabled(
    'monetization_tests',
    userStore
  );
  const { trackPaywallEvent } = usePaywallTracking(
    PaywallContext.ACCOUNT_SETTINGS
  );

  // State
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState<boolean>(false);

  // Handlers
  const handleCancelAccountClick = () => {
    trackPaywallEvent(PaywallEvents.CANCEL_MEMBERSHIP_CLICKED);
    setIsCancelSubscriptionModalOpen(true);
  };

  const handleContactSupport = () => {
    const newWindow = window.open(
      'https://help.sidelinehd.com/hc/en-us/requests/new',
      '_blank'
    );
    if (newWindow) {
      newWindow.focus();
    }
    setIsCancelSubscriptionModalOpen(false);
  };

  const onShowPaywall = () => {
    if (appHost.isHosted) {
      appHost.service.notifications.showPaywall();
    }
  };

  return (
    <Box
      background="#fff"
      pad="large"
      round="0.75rem"
      direction="column"
      height={`100dvh`}
    >
      <Box
        align="center"
        direction="row"
        justify="start"
        margin={{ bottom: '1.3125rem', top: '1rem' }}
      >
        <Anchor
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LinkPrevious fill="#1a1a1a" size="24px" />
        </Anchor>
        <Heading
          color="#1a1a1a"
          level={1}
          size="1.25rem"
          style={{ margin: '0 0 0 0.5rem' }}
          weight={900}
        >
          Membership Details
        </Heading>
      </Box>

      {isLoadingRc ? (
        <BasicLoader />
      ) : (
        <Box
          background="#f5f5f5"
          direction="row"
          justify="between"
          align="center"
          round="0.75rem"
          style={{ padding: '1.5rem' }}
        >
          <Box>
            <Text size="0.875rem" color="#212121">
              {activeSubscription.productName}
            </Text>
            <Text size="0.75rem" color="#808080">
              {activeSubscription.productPrice}/
              {activeSubscription.productTerm.short}
            </Text>
          </Box>
          <Anchor
            icon={<CircleInformation fill="#000" />}
            onClick={() => handleCancelAccountClick()}
          />
        </Box>
      )}

      <Heading
        color="#1a1a1a"
        level={2}
        size="1rem"
        style={{ margin: '1rem 0 0 0' }}
        weight={900}
      >
        Your membership includes:
      </Heading>

      <PaywallList iconColor="#9341ff" />

      <Box
        className="settings__cancel-subscription"
        justify="end"
        align="center"
        margin={{ vertical: 'auto' }}
      >
        <Anchor
          color="#1a1a1a"
          size="1rem"
          onClick={() => handleCancelAccountClick()}
        >
          Cancel membership
        </Anchor>
      </Box>
      {showInternalMonetizationTests && (
        <Box gap="medium">
          <Heading level={4}>Internal Testing</Heading>
          <Box>
            <Button primary label="Show Paywall" onClick={onShowPaywall} />
            <Text size="small">
              (should only open the paywall if you are inside the app)
            </Text>
          </Box>
          <Box>
            <Text>Paywall CTA</Text>
            <PaywallCta
              mode="default"
              context={PaywallContext.ACCOUNT_SETTINGS}
            />
          </Box>
        </Box>
      )}
      {isCancelSubscriptionModalOpen && (
        <Layer
          onEsc={() => setIsCancelSubscriptionModalOpen(false)}
          onClickOutside={() => setIsCancelSubscriptionModalOpen(false)}
        >
          <Box style={{ padding: '3.5rem 1rem 1rem', position: 'relative' }}>
            <Anchor
              icon={<Close />}
              onClick={() => setIsCancelSubscriptionModalOpen(false)}
              style={{ position: 'absolute', right: '1rem', top: '1rem' }}
            />
            <Heading
              level={2}
              size="1.5rem"
              weight={900}
              style={{
                borderBottom: '1px solid #b9b9bb',
                paddingBottom: '1rem',
              }}
            >
              To cancel your subscription contact customer support
            </Heading>

            <PaywallButtonUnlock
              style={{
                maxWidth: '75%',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
              onClick={() => handleContactSupport()}
            >
              Contact Support
            </PaywallButtonUnlock>

            <Box
              direction="row"
              justify="center"
              align="center"
              margin={{ top: '1rem' }}
            >
              <Text style={{ color: '#b4b4b4', fontSize: '0.75rem' }}>
                <PaywallLegalLink href="/terms-of-service">
                  Terms of Service
                </PaywallLegalLink>
                &nbsp;and&nbsp;
                <PaywallLegalLink href="/privacy">
                  Privacy Policy
                </PaywallLegalLink>
              </Text>
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
};

export default SettingsManageSubscription;
