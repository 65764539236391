// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { Box, Layer, Text, TextInput } from 'grommet';
import { Close } from 'grommet-icons';
import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import SHDButton from '../../components/SHD/Button';
import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';

const DeleteGameLayer = ({
  game,
  teamId,
  onDelete = () => {},
  closeLayer = () => {},
}) => {
  const userStore = useContext(UserContext);
  const godMode = useIsFeatureEnabled('view_all_clips_enabled', userStore);

  const [error, setError] = useState();
  const [value, setValue] = useState('');
  const [canDelete, setCanDelete] = useState(true);
  const { J_opponentName, scoreOpponentName } = game;

  useEffect(() => {
    let tsStart;
    if ('J_tsStart' in game) {
      tsStart = game.J_tsStart;
    } else if ('scheduleTsStart' in game) {
      tsStart = game.scheduleTsStart;
    }

    setCanDelete(Date.now() / 1000 - tsStart < 30 * 24 * 60 * 60 || godMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteGame = () => {
    axios
      .delete('/api/team_game', { data: { teamId, teamGameId: game._id } })
      .then(() => {
        closeLayer();
        onDelete(game._id);
      })
      .catch((respError) => {
        if (respError.response && respError.response.data) {
          setError(respError.response.data.message);
        } else {
          setError(respError.toString());
        }
        Sentry.captureException(respError);
      });
  };

  const deleteDiv = (
    <Box pad="large" direction="column" align="center" gap="large">
      {error ? (
        <Text textAlign="center" color="status-critical">
          {error}
        </Text>
      ) : null}
      <Text textAlign="center" size="large">
        {`Players will lose all clips from your game vs ${
          J_opponentName || scoreOpponentName
        }. Are you sure you want to delete it?`}
      </Text>
      <Text textAlign="center" size="large">
        If so, type &quot;delete&quot; to confirm.
      </Text>
      <Box width="small">
        <TextInput
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
      </Box>
      <Box direction="row" gap="small">
        <SHDButton
          secondary
          label="Cancel"
          onClick={closeLayer}
          color="status-critical"
        />
        <SHDButton
          primary
          color="status-critical"
          label="Delete"
          disabled={value.toLowerCase() !== 'delete'}
          onClick={deleteGame}
        />
      </Box>
    </Box>
  );

  const contactDiv = (
    <Box pad="large" direction="column" align="center" gap="large">
      {error ? (
        <Text textAlign="center" color="status-critical">
          {error}
        </Text>
      ) : null}
      <Text textAlign="center" size="large">
        This game is over 30 days old.
      </Text>
      <Text textAlign="center" size="large">
        To delete it, please contact us at help@sidelinehd.com or file a ticket
        at https://help.sidelinehd.com
      </Text>
    </Box>
  );

  return (
    <Layer>
      <Box fill="horizontal" direction="row" justify="end">
        <SHDButton icon={<Close />} onClick={closeLayer} hoverIndicator />
      </Box>
      {canDelete ? deleteDiv : contactDiv}
    </Layer>
  );
};

export default DeleteGameLayer;
