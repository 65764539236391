import React, { useEffect } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';

const SidelineRedirect: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const history = useHistory();
  useEffect(() => {
    history.push(`/live/${teamId}/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(SidelineRedirect);
