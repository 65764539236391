// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useState } from 'react';

import { Box, Button, Text } from 'grommet';

import CopyToClipboard from 'react-copy-to-clipboard';
import * as Sentry from '@sentry/browser';
import ShareDropdown from '../../helpers/ShareDropdown';
import { isMobile } from '../../helpers/browserDetect';
import Toast from '../../components/Toast';
import { UserContext } from '../../services/Session';
import { analytics } from '../../services/analytics';

const ShareBar = ({ title, text, path }) => {
  const userStore = useContext(UserContext);
  const [copied, setCopied] = useState(false);

  const handleShare = () => {
    // const link = `${path}?s=1`;
    // TODO: figure out how to set the source
    const link = path;

    if (navigator.share) {
      analytics.track('Generic Share Clicked', {
        path,
        loggedIn: !!userStore.authUser,
        type: 'share via',
      });
      navigator
        .share({
          title,
          text,
          url: link,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const renderShareOptions = () => {
    // TODO: figure out how to set the source
    const link = path;
    return (
      <Box pad="medium" gap="small">
        {isMobile.any() && (
          <Button label="Share via ..." onClick={handleShare} />
        )}
        <CopyToClipboard
          text={link}
          onCopy={() => {
            analytics.track('Generic Share Clicked', {
              path,
              loggedIn: !!userStore.authUser,
              type: 'copy',
            });
            setCopied(true);
          }}
        >
          <Button label="Copy link to page" />
        </CopyToClipboard>
      </Box>
    );
  };

  return (
    <Box direction="row" gap="small">
      {copied && (
        <Toast
          label="Copied url to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
      <ShareDropdown
        plain
        label={<Text size="small">Share</Text>}
        color="secondary-1"
        renderShareOptions={renderShareOptions}
      />
    </Box>
  );
};

export default ShareBar;
