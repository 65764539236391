import React, { useContext, useCallback } from 'react';
import {
  useLiveConfig,
  useLiveOnPlatform,
  useLiveAssets,
} from '../../SidelineLive/liveHooks';
import { Box, Button, Text } from 'grommet';
import { Facebook, Youtube } from 'grommet-icons';
import { PrivacyStatus, YTAsset } from '../Games/games.types';
import YouTubeAccessWarningModal from '../YouTubeAccessWarningModal';
import { UserContext } from '../../../services/Session';
import SidelineLiveButton from '../../SidelineLive/SidelineLiveButton';
import { getUserTypeRelativeToTeam } from '../../../helpers/utils';
import {
  LiveEvents,
  LiveEventPayload,
} from '../../SidelineLive/sidelineLive.analytics';
import { analytics } from '../../../services/analytics';

interface Props {
  teamId: string;
  sport: string;
}

const TeamLiveCardDesktop: React.FC<Props> = ({ teamId, sport }) => {
  const [selectedYTAsset, setSelectedYTAsset] = React.useState<YTAsset | null>(
    null
  );

  const userContext = useContext(UserContext);
  const liveAssets = useLiveAssets(teamId);
  const liveOnPlatform = useLiveOnPlatform(teamId);
  const userId = userContext.authUser?.claims?.shd_user_id || '';
  const liveConfig = useLiveConfig(teamId, userId);

  const trackLiveEvent = useCallback(
    (event: LiveEvents, payload: LiveEventPayload) => {
      analytics.track(event, {
        ...payload,
        signedIn: !!userContext.authUser,
        userType: getUserTypeRelativeToTeam(teamId, userContext),
        userId: userId,
        teamId: teamId,
        source: 'sidelineLIVE',
        sport: sport,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (liveAssets.length === 0 || liveOnPlatform?.isAdvertise === false) {
    return null;
  }
  const firstLiveAsset = liveAssets[0];

  return (
    <Box
      background={'#212121'}
      pad="medium"
      gap="medium"
      direction="row"
      justify="between"
    >
      <Box direction="row" gap="medium" align="center">
        <Box
          round="4px"
          background="red"
          pad="xsmall"
          justify="center"
          height={'fit-content'}
        >
          <Text size="small" weight={'bold'}>
            LIVE
          </Text>
        </Box>
        <Text weight={'bold'}>{firstLiveAsset.cdnTitle}</Text>
      </Box>
      <Box direction="row" gap="small">
        {liveAssets.map((asset, i) => {
          if (asset.type === 'yt') {
            if (asset.cdnPrivacyStatus === 3) {
              return (
                <Button
                  key={`yt-${i}`}
                  primary
                  label={
                    <Text size="small" weight={'bold'}>
                      {'YouTube'}
                    </Text>
                  }
                  color="#FF0000"
                  icon={<Youtube color="white" size="small" />}
                  onClick={() => {
                    setSelectedYTAsset({
                      cdnUrl: asset.cdnUrl,
                      privacyStatus: PrivacyStatus.Unlisted,
                    });
                  }}
                />
              );
            } else {
              return (
                <a
                  key={`yt-${i}`}
                  href={asset.cdnUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    primary
                    label={
                      <Text size="small" weight={'bold'}>
                        {'YouTube'}
                      </Text>
                    }
                    color="#FF0000"
                    icon={<Youtube color="white" size="small" />}
                  />
                </a>
              );
            }
          } else if (asset.type === 'fb') {
            return (
              <a
                key={`fb-${i}`}
                href={asset.cdnUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  primary
                  label={
                    <Text size="small" weight={'bold'}>
                      {'Facebook'}
                    </Text>
                  }
                  color="#0766FF"
                  icon={<Facebook color="white" size="small" />}
                />
              </a>
            );
          }
          return null;
        })}
      </Box>
      {liveOnPlatform?.isAdvertise && liveConfig?.isWebclientLive && (
        <SidelineLiveButton teamId={teamId} trackEvent={trackLiveEvent} />
      )}
      {selectedYTAsset && (
        <YouTubeAccessWarningModal
          ytAsset={selectedYTAsset}
          onClose={() => setSelectedYTAsset(null)}
        />
      )}
    </Box>
  );
};

export default TeamLiveCardDesktop;
