import {
  Anchor,
  Box,
  Card,
  DropButton,
  Heading,
  Image,
  Layer,
  ResponsiveContext,
  Text,
  TextInput,
} from 'grommet';
import { Close, DownloadOption, More } from 'grommet-icons';
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from 'react';
import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LazyLoad from 'react-lazyload';
import Star from '@material-ui/icons/Star';
import Skeleton from 'react-loading-skeleton';
import * as Sentry from '@sentry/react';

import ClipVideoPlayer from '../../helpers/ClipVideoPlayer';
import {
  formatDateShort,
  formatInningNum,
  compareJerseyNum,
  useIsFeatureEnabled,
  useQuery,
} from '../../helpers/utils';
import { isiOS } from '../../helpers/browserDetect';
import SHDButton from '../../components/SHD/Button';
import ShareDropdown from '../../helpers/ShareDropdown';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import { BasicLoader } from '../../helpers/Loaders';
import { analytics } from '../../services/analytics';
import useZendeskWidget from '../../helpers/useZendeskWidget';
import { shd } from '@shd/jslib/models';
import { ClipFeedGame, ClipFeedClip } from './player.types';
import { useToast } from '../../contexts/ToastContext';

import Paywall from '../../components/Paywall/Paywall';
import { usePaywall } from '../../components/Paywall/paywall.hooks';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../components/Paywall/paywall.types';
import { useIsEntitledToViewPlayer } from './playerHooks';

const DKLogo = () => <Image src="/dk_green_logo.png" width="32px" />;

interface TeamPlayerOption extends shd.TeamPlayer {
  isDisabled?: boolean;
  value: string;
  label: string;
}
interface ReassignClipModalProps {
  game: ClipFeedGame;
  clip: ClipFeedClip;
  teamPlayers: TeamPlayerOption[];
  onReassign: (players: TeamPlayerOption[]) => void;
  onClose: () => void;
  primaryPlayer: TeamPlayerOption;
  secondaryPlayers: TeamPlayerOption[];
}
const ReassignClipModal: React.FC<ReassignClipModalProps> = ({
  game,
  clip,
  teamPlayers,
  onReassign,
  onClose,
  primaryPlayer: initialPrimaryPlayer,
  secondaryPlayers: initialSecondaryPlayers,
}) => {
  const [primaryPlayer, setPrimaryPlayer] = useState(initialPrimaryPlayer);
  const [secondaryPlayers, setSecondaryPlayers] = useState(
    initialSecondaryPlayers
  );

  const primaryRole =
    clip.playerRole === 'offense_batter' ? 'batter' : 'pitcher';
  const secondaryRole =
    clip.playerRole === 'offense_batter' ? 'base running' : 'fielding';

  return (
    <Layer onEsc={onClose}>
      <Box width="large" pad="medium" gap="medium">
        <Box direction="row" justify="end" fill="horizontal">
          <SHDButton icon={<Close />} onClick={onClose} hoverIndicator />
        </Box>
        <Heading level={3}>Tag players in clip</Heading>
        <Card gap="small" background="secondary-7" round="small">
          <Box direction="row" justify="between" pad="medium" gap="medium">
            <Box gap="medium">
              <Text size="large" weight="bold">{`${
                game ? game.scoreOurName : ''
              }`}</Text>
              <Text size="large" weight="bold">{`vs ${
                game ? game.J_opponentName : ''
              }`}</Text>
              <Text size="medium">{formatDateShort(clip.clipStartTs)}</Text>
            </Box>
          </Box>
        </Card>
        <Box gap="small" direction="row">
          <Text weight="bold" size="large">
            {`${clip.textDescriptionBrief
              .charAt(0)
              .toUpperCase()}${clip.textDescriptionBrief.slice(1)}`}
          </Text>
          <Text weight="bold" size="large">
            {`${formatInningNum(clip.inningNum)} inning`}
          </Text>
        </Box>
        <Box height="2px" background="dark-5" margin={{ bottom: 'small' }} />
        <Text>{`The ${primaryRole} is...`}</Text>
        <Select
          options={teamPlayers}
          defaultValue={primaryPlayer}
          onChange={(option) => {
            if (option) {
              setPrimaryPlayer(option);
            }
          }}
        />
        <Text>{`Great ${secondaryRole}? Give them credit!`}</Text>
        <Select
          options={
            secondaryPlayers.length < 3
              ? teamPlayers
              : teamPlayers.map((player) => {
                  const newPlayer = { ...player };
                  newPlayer.isDisabled = true;
                  return newPlayer;
                })
          }
          defaultValue={secondaryPlayers}
          onChange={(options) => {
            if (options) {
              setSecondaryPlayers([...options]);
            }
          }}
          isMulti
        />
        <SHDButton
          primary
          size="large"
          color="tertiary-0"
          label={<Text weight="bold">Done</Text>}
          onClick={() => {
            onReassign([primaryPlayer, ...secondaryPlayers]);
          }}
        />
      </Box>
    </Layer>
  );
};

interface PlayerClipProps {
  game: ClipFeedGame;
  clip: ClipFeedClip;
  active?: boolean;
  playerId: string;
  pinClip: ((clip: ClipFeedClip) => void) | null;
  unpinClip: ((clip: ClipFeedClip) => void) | null;
  isPinned?: boolean;
  isPinnedAndAtTop?: boolean;
  onChange?: (clip: ClipFeedClip) => void;
  date?: string;
  compilation?: string;
  pinnedFeed?: boolean;
  isInactive?: boolean;
  onShowClaim: () => void;
  hideBottomBar: boolean;
  fromGamePage?: boolean;
  publicPublishLevel: number;
}

type PlayerClipRef = HTMLDivElement; // Or whatever element type you're forwarding the ref to

const PlayerClip: ForwardRefRenderFunction<PlayerClipRef, PlayerClipProps> = (
  {
    game,
    clip,
    active = false,
    playerId,
    pinClip,
    unpinClip,
    isPinned = false,
    isPinnedAndAtTop = false,
    onChange,
    date,
    compilation,
    pinnedFeed,
    isInactive = false,
    onShowClaim,
    hideBottomBar,
    fromGamePage = false,
    ...props
  },
  ref
) => {
  const innerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);

  const { showToast } = useToast();

  const userStore = useContext(UserContext);
  const clipGodMode = useIsFeatureEnabled('view_all_clips_enabled', userStore);
  const partnerClipViewerEnabled = useIsFeatureEnabled(
    'partner_clip_viewer_enabled',
    userStore
  );

  const query = useQuery();

  const isEmbed = query.get('embed'); // TODO: can we pass with from the layout?
  const isMonetization = useIsFeatureEnabled('monetization', userStore);
  const { status: viewerEntitlementStatus } = useIsEntitledToViewPlayer(
    playerId,
    userStore.authUser?.claims?.shd_user_id,
    'bbsb'
  );
  const isEntitled =
    viewerEntitlementStatus === ViewerEntitlementStatus.ENTITLED;
  const {
    isPaywallActive,
    handlePaywallOpen,
    handlePaywallClose,
    handleUnlockAccess,
  } = usePaywall(PaywallContext.SINGLE_CLIP_PAGE, playerId);

  const [publicPublishLevel, setPublicPublishLevel] = useState(
    props.publicPublishLevel
  );
  const [isPreviewClip, toggleIsPreviewClip] = useState(
    clip.id === clip.refPreview
  );
  const [isUnpinAnimation] = useState(false);
  const [showDownloadInstructions, setShowDownloadInstructions] =
    useState(false);
  const [showDKInfoModal, setShowDKInfoModal] = useState(false);
  const [showUnclaimedModal, setShowUnclaimedModal] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const [showDeleteClip, setShowDeleteClip] = useState(false);
  const [showReassignClip, setShowReassignClip] = useState(false);

  const [teamPlayers, setTeamPlayers] = useState([]);
  const [reassignedPlayers, setReassignedPlayers] = useState<
    (TeamPlayerOption | undefined)[]
  >([]);

  const [deleteInputValue, setDeleteInputValue] = useState('');

  const screen = useContext(ResponsiveContext);

  const player = userStore.userPlayers.find((p) => p._id === playerId);

  const userPlayerIds = userStore.userPlayers
    ? userStore.userPlayers.map((p) => p._id)
    : [];

  const userTeamIds = userStore.userTeams
    ? userStore.userTeams.map((team) => team._id)
    : [];

  const isAdmin =
    userPlayerIds.includes(playerId) ||
    clip.J_claimedPlayerList.filter((pId) => userPlayerIds.includes(pId))
      .length > 0;

  const isFollower = userStore.teamFollowerIds
    .concat(userTeamIds)
    .includes(game.DNOR_teamId);

  const showLock =
    (!(fromGamePage || isFollower || publicPublishLevel === 10) ||
      playerId.startsWith('TpSh01-') ||
      publicPublishLevel < 10) &&
    !(ref || active);
  const showNotClaimed = !fromGamePage && playerId.startsWith('TpSh01-');

  const { showWidget, setConversationFields } = useZendeskWidget();

  useEffect(() => {
    // If clip is pinned, scroll to the first instance
    if (innerRef.current && (isPinnedAndAtTop || !isPinned)) {
      innerRef.current.scrollIntoView();
      window.scrollBy(
        0,
        document.getElementById('playerheader')
          ? -1 *
              ((document.getElementById('playerheader')?.clientHeight ?? 0) +
                50)
          : 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    if (isAdmin || isFollower) {
      setPublicPublishLevel(10);
    }

    if (clipGodMode || partnerClipViewerEnabled) {
      setPublicPublishLevel(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPublicPublishLevel(props.publicPublishLevel);
  }, [props.publicPublishLevel]);

  const getPlayers = () => {
    axios
      .get(`/api/team/${game.DNOR_teamId}/team_players`)
      .then((response) => {
        const teamPlayerDocs = response.data.players;
        const sortedTeamPlayers = teamPlayerDocs
          .filter((teamPlayer: TeamPlayerOption) => teamPlayer.isActive)
          .map((teamPlayer: TeamPlayerOption) => {
            const newPlayer = { ...teamPlayer };
            // for Select component
            newPlayer.value = newPlayer._id;

            newPlayer.name = `${newPlayer.nameFirst} ${newPlayer.nameLast}`;

            if (newPlayer.nameFirst === '' && newPlayer.nameLast === '') {
              newPlayer.name = `Player #${newPlayer.jerseyNum}`;
            }
            newPlayer.label = `#${newPlayer.jerseyNum} ${newPlayer.name}`;

            return newPlayer;
          })
          .sort(compareJerseyNum);
        setTeamPlayers(sortedTeamPlayers);

        const existingPlayers = clip.J_teamPlayerList.map((id) => {
          if (!id) {
            return undefined;
          }

          let x;
          sortedTeamPlayers.some((p: TeamPlayerOption) => {
            x = p;
            return p._id === id;
          });

          return x;
        });
        setReassignedPlayers(existingPlayers);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  };

  const sendSharePayload = (link: string) => {
    const payload = {
      message: 'share',
      clipId: clip.id,
      text: clip.description || clip.textDescription,
      src: link,
    };

    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.share
    ) {
      window.webkit.messageHandlers.share.postMessage(payload);
    }
  };

  const handleShare = () => {
    const base = 'https://sidelinehd.com/';

    let linkPlayerId;
    if (playerId) {
      linkPlayerId = playerId;
    } else {
      if (clip.J_teamPlayerList.length > 0 && clip.J_teamPlayerList[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        linkPlayerId = clip.J_teamPlayerList[0];
      } else {
        for (let i = 1; i < 5; i += 1) {
          if (
            clip.J_claimedPlayerList.length > i &&
            clip.J_claimedPlayerList[i] !== ''
          ) {
            linkPlayerId = clip.J_claimedPlayerList[i];
            break;
          }

          if (
            clip.J_teamPlayerList.length > i &&
            clip.J_teamPlayerList[i] !== ''
          ) {
            linkPlayerId = clip.J_teamPlayerList[i];
            break;
          }
        }
      }
    }

    const suffix = isPreviewClip
      ? `${linkPlayerId}/c/${clip.id}?s=1`
      : `${linkPlayerId}/c/${clip.refFull}?s=1`;
    const link = `${base}${suffix}`;

    if (isEmbed) {
      sendSharePayload(link);
    } else {
      if (navigator.share) {
        analytics.track('Player Clip Share Clicked', {
          clipId: clip.id,
          loggedIn: !!userStore.authUser,
          type: 'share via',
          sport: 'bbsb',
        });
        navigator
          .share({
            title: `${clip.textDescription}`,
            text: clip.textDescription,
            url: link,
          })
          .catch((respError) => {
            Sentry.captureException(respError);
          });
      }
    }
  };

  const renderShareOptions = () => {
    const base = 'https://sidelinehd.com/';

    let linkPlayerId;
    if (playerId) {
      linkPlayerId = playerId;
    } else {
      if (clip.J_teamPlayerList.length > 0 && clip.J_teamPlayerList[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        linkPlayerId = clip.J_teamPlayerList[0];
      } else {
        for (let i = 1; i < 5; i += 1) {
          if (
            clip.J_claimedPlayerList.length > i &&
            clip.J_claimedPlayerList[i] !== ''
          ) {
            linkPlayerId = clip.J_claimedPlayerList[i];
            break;
          }

          if (
            clip.J_teamPlayerList.length > i &&
            clip.J_teamPlayerList[i] !== ''
          ) {
            linkPlayerId = clip.J_teamPlayerList[i];
            break;
          }
        }
      }
    }

    const suffix = isPreviewClip
      ? `${linkPlayerId}/c/${clip.id}?s=0`
      : `${linkPlayerId}/c/${clip.refFull}?s=0`;
    const link = `${base}${suffix}`;

    return (
      <Box pad="medium" gap="small">
        {screen === 'small' && (
          <SHDButton label="Share clip via ..." onClick={handleShare} />
        )}
        <CopyToClipboard
          text={link}
          onCopy={() => {
            analytics.track('Player Clip Share Clicked', {
              clipId: clip.id,
              loggedIn: !!userStore.authUser,
              type: 'copy',
              sport: 'bbsb',
            });

            showToast({
              message: 'Copied to clipboard!',
              background: 'status-ok',
              duration: 3000,
            });
          }}
        >
          <SHDButton label="Copy link to clip" />
        </CopyToClipboard>
      </Box>
    );
  };

  const getStarIcon = () => {
    if (unpinClip && isPinned) {
      return (
        <SHDButton
          plain
          icon={<Star htmlColor="#ffbf00" stroke="#ffbf00" strokeWidth={1.5} />}
          label={<Text size="medium">Highlight</Text>}
          mixtrack={[
            'Player Clip Unpin Clicked',
            {
              clipId: clip.id,
              loggedIn: !!userStore.authUser,
              sport: 'bbsb',
            },
          ]}
          hoverIndicator
          onClick={() => {
            unpinClip(clip);
          }}
        />
      );
    }
    if (pinClip && !isPinned) {
      return (
        <SHDButton
          plain
          label={<Text size="medium">Highlight</Text>}
          mixtrack={[
            'Player Clip Pin Clicked',
            {
              clipId: clip.id,
              loggedIn: !!userStore.authUser,
              sport: 'bbsb',
            },
          ]}
          icon={
            <Star htmlColor="#ffffff00" stroke="#666666" strokeWidth={1.5} />
          }
          hoverIndicator
          onClick={() => {
            // TODO: design this better.
            // kind of a hack to include game within clip here...
            // eslint-disable-next-line no-param-reassign
            clip.game = game;
            pinClip(clip);
            analytics.track('Star Clip', {
              clipId: clip.id,
              sport: 'bbsb',
            });
          }}
        />
      );
    }
    if (isPinned) {
      return <Star htmlColor="#ffbf00" />;
    }
    return null;
  };

  const dkInfoModal = (
    <Layer onEsc={() => setShowDKInfoModal(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <SHDButton
            mixtrack={[
              'Player Clip DK Info Close Clicked',
              {
                clipId: clip.id,
                loggedIn: !!userStore.authUser,
                sport: 'bbsb',
              },
            ]}
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowDKInfoModal(false)}
          />
        </Box>
        <Box margin="small" gap="medium">
          <Image alignSelf="center" src="/dk_swing_info.png" width="200px" />
          <Text>
            Your <b>Maximum Barrel Speed</b> affects ball exit velo and hit ball
            distance. A typical MLB swing has 80 MPH barrel speed.
          </Text>
          <Text>
            <b>Trigger to Impact</b> measures bat quickness, and is measured in
            milliseconds (ms) from start of motion to ball impact. Shorter times
            are better.
          </Text>
          <Text>
            <b>Attack Angle</b> measures the angle of your swing plane. +
            indicates swinging UP, 0&deg; is level, and &ndash; indicates
            swinging DOWN.
          </Text>
        </Box>
        <Box margin="small" pad={{ top: 'medium' }}>
          <Anchor
            href="https://diamondkinetics.com/?utm_source=sideline-hd&utm_medium=website&utm_campaign=partner&utm_content=sideline-hd-general-website-links"
            target="_blank"
          >
            <Text size="small">Powered by Diamond Kinetics</Text>
            <Image src="/dk_green_logo.png" width="32px" />
          </Anchor>
          <Anchor href="https://help.sidelinehd.com/support/solutions/articles/69000839622">
            <Text size="small">How do I link my DK sensor?</Text>
          </Anchor>
        </Box>
      </Box>
    </Layer>
  );

  const downloadInstructions = (
    <Layer onEsc={() => setShowDownloadInstructions(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <SHDButton
            mixtrack={[
              'Player Clip Download Instructions Close Clicked',
              {
                clipId: clip.id,
                loggedIn: !!userStore.authUser,
                sport: 'bbsb',
              },
            ]}
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowDownloadInstructions(false)}
          />
        </Box>
        <Box margin="small">
          <Text>
            Your video will appear in the download list in the top right corner
            of the screen after you press the down arrow.
          </Text>
          <Image src="/download_clip_help.png" />
        </Box>
      </Box>
    </Layer>
  );

  const unclaimedModal = (
    <Layer onEsc={() => setShowUnclaimedModal(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <SHDButton
            mixtrack={[
              'Player Clip Unclaimed Modal Close Clicked',
              {
                clipId: clip.id,
                loggedIn: !!userStore.authUser,
                sport: 'bbsb',
              },
            ]}
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowUnclaimedModal(false)}
          />
        </Box>
        <Box margin="medium" gap="large">
          <Text>
            This player is not claimed yet. Claim this player to view, share, or
            download highlights
          </Text>
          <SHDButton
            primary
            color="tertiary-0"
            id="claim-this-player"
            size="large"
            mixtrack={[
              'Player Clip Claim This Player Clicked',
              {
                clipId: clip.id,
                loggedIn: !!userStore.authUser,
                sport: 'bbsb',
              },
            ]}
            label={<Text weight="bold">Claim this player</Text>}
            onClick={() => {
              setShowUnclaimedModal(false);
              onShowClaim();
            }}
          />
        </Box>
      </Box>
    </Layer>
  );

  const onSensorClicked = () => {
    setShowDKInfoModal(true);
  };

  const showDeleteOption = () => {
    const teamId = game ? game.DNOR_teamId : '';

    return userTeamIds.concat(userStore.coachTeamIds).includes(teamId);
  };

  const showHideOption = () => {
    const playerIds = clip ? clip.J_claimedPlayerList : [];
    return playerIds.filter((pId) => userPlayerIds.includes(pId)).length > 0;
  };

  const showReassignOption = () => {
    if (!userStore.authUser) {
      return false;
    }

    // TODO: implement time window later
    // let tsStart;
    // if ('J_tsStart' in game) {
    //   tsStart = game.J_tsStart;
    // } else if ('scheduleTsStart' in game) {
    //   tsStart = game.scheduleTsStart;
    // }
    // const within7Days = (Date.now() / 1000) - tsStart < (30 * 24 * 60 * 60);

    const teamId = game ? game.DNOR_teamId : '';
    const teamIds = userStore.userTeams
      .map((userTeam) => userTeam._id)
      .concat(userStore.teamFollowerIds)
      .concat(userStore.coachTeamIds);

    return (teamIds.includes(teamId) && !isInactive) || clipGodMode;
  };

  const reassignClipClicked = () => {
    getPlayers();
    setShowReassignClip(true);
    setMenuOpen(false);
  };

  const reportClipClicked = () => {
    const clipId = isPreviewClip ? clip.refPreview : clip.refFull;

    setConversationFields([
      { id: '23299865615255', value: clipId },
      { key: '23299858673815', value: playerId },
    ]);
    setTimeout(() => {
      showWidget();
      setMenuOpen(false);
    }, 10);
  };

  const deleteClipClicked = () => {
    setShowDeleteClip(true);
    setMenuOpen(false);
  };

  const hideShowClipClicked = (willHide: boolean) => () => {
    const newClip = { ...clip };
    if (willHide) {
      newClip.O_hideFromClaimedPlayerPage?.push(playerId);
    } else {
      const index = newClip.O_hideFromClaimedPlayerPage?.indexOf(playerId);
      if (index && index > -1) {
        newClip.O_hideFromClaimedPlayerPage?.splice(index, 1);
      }
    }

    // ugh
    newClip.teamId = game.DNOR_teamId;

    axios
      .put('/api/clip', { clip: newClip })
      .then((response) => {
        if (response.data.success) {
          onChange && onChange(response.data.clip);
        }
      })
      .catch((e) => Sentry.captureException(e));
    setMenuOpen(false);
  };

  const showSROption = () => player && player.srUserId;

  const addToSRClicked = () => {
    axios
      .post(`/api/sr/player/${playerId}/add_videos`, {
        clips: [clip],
      })
      .then(() => {
        showToast({
          message: 'Added to your SportsRecruits profile',
          background: 'status-ok',
        });
      })
      .catch(() => {
        showToast({
          message: 'There was an error adding to your SportsRecruits profile',
          background: 'status-critical',
        });
      });
    setMenuOpen(false);
  };

  const renderMenuItems = () => {
    const hidablePlayers = userStore.userPlayers.filter((p) =>
      clip.J_claimedPlayerList.includes(p._id)
    );

    return (
      <Box width="medium">
        {showReassignOption() && (
          <SHDButton
            plain
            margin="xsmall"
            label="Tag players"
            onClick={reassignClipClicked}
          />
        )}
        {showSROption() && (
          <SHDButton
            plain
            margin="xsmall"
            label="Add to SportsRecruits"
            onClick={addToSRClicked}
          />
        )}
        <SHDButton
          plain
          margin="xsmall"
          color="error"
          label="Report clip"
          onClick={reportClipClicked}
        />
        {showDeleteOption() && (
          <SHDButton
            plain
            margin="xsmall"
            color="error"
            label="Delete clip"
            onClick={deleteClipClicked}
          />
        )}
        {showHideOption() &&
          hidablePlayers.map((p) => {
            let hideShowText;
            let willHide;
            if (clip.O_hideFromClaimedPlayerPage?.includes(p._id)) {
              hideShowText = `Show clip on ${p.nameFirst}'s profile`;
              willHide = false;
            } else {
              hideShowText = `Hide clip from ${p.nameFirst}'s profile`;
              willHide = true;
            }

            return (
              <SHDButton
                key={p._id}
                plain
                margin="xsmall"
                color="error"
                label={hideShowText}
                onClick={hideShowClipClicked(willHide)}
              />
            );
          })}
      </Box>
    );
  };

  if (isUnpinAnimation) {
    return (
      <Box
        margin={{ horizontal: 'auto', vertical: 'large' }}
        gap="small"
        align="center"
      >
        <Skeleton height={30} duration={0.8} />
        <Skeleton duration={0.8} />
      </Box>
    );
  }

  const deleteClip = () => {
    axios
      .delete('/api/clip', { data: clip })
      .then((response) => {
        if (response.data.success) {
          setShowDeleteClip(false);
          onChange && onChange(clip);
        }
      })
      .catch((e) => Sentry.captureException(e));
  };

  const canDelete = deleteInputValue.toLowerCase() === 'delete';

  let tagIndex;
  if (clip.J_claimedPlayerList.includes(playerId)) {
    tagIndex = clip.J_claimedPlayerList.indexOf(playerId);
  } else if (clip.J_teamPlayerList.includes(playerId)) {
    tagIndex = clip.J_teamPlayerList.indexOf(playerId);
  }

  let clipTitle;
  if (tagIndex === 0) {
    // Primary player
    if (clip.playerRole === 'defense_pitcher') {
      // Defender (includes pitcher and fielders)
      if (compilation || pinnedFeed) {
        if (clip.textDescriptionBrief === 'strikeout') {
          // Pitcher
          clipTitle = `Pitched ${clip.textDescriptionBrief}`;
        } else {
          // Fielding
          clipTitle = `Fielding - ${clip.textDescriptionBrief}`;
        }
      } else {
        clipTitle = `${clip.textDescriptionBrief
          .charAt(0)
          .toUpperCase()}${clip.textDescriptionBrief.slice(1)}`;
      }
    } else {
      // Batter
      clipTitle = `${clip.textDescriptionBrief
        .charAt(0)
        .toUpperCase()}${clip.textDescriptionBrief.slice(1)}`;
    }
  } else {
    // Secondary players
    if (clip.playerRole === 'defense_pitcher') {
      // Defender -- always going to be fielders here
      clipTitle = `Fielding - ${clip.textDescriptionBrief}`;
    } else {
      // Baserunners
      clipTitle = 'Baserunning';
    }
  }

  const deleteClipModal = (
    <Layer full onEsc={() => setShowDeleteClip(false)}>
      <Box fill pad="medium" gap="medium">
        <Heading level={3}>Delete this clip</Heading>
        <Card gap="small" background="secondary-7" round="small">
          <Box direction="row" justify="between" pad="medium" gap="medium">
            <Box gap="medium">
              <Text size="large" weight="bold">{`${
                game ? game.scoreOurName : ''
              }`}</Text>
              <Text size="large" weight="bold">{`vs ${
                game ? game.J_opponentName : ''
              }`}</Text>
              <Text size="medium">{formatDateShort(clip.clipStartTs)}</Text>
            </Box>
          </Box>
        </Card>
        <Box gap="small" direction="row">
          <Text weight="bold" size="large">
            {clipTitle}
          </Text>
          <Text weight="bold" size="large">
            {`${formatInningNum(clip.inningNum)} inning`}
          </Text>
        </Box>
        <Box height="2px" background="dark-5" margin={{ bottom: 'small' }} />
        <Text color="dark-3">Enter DELETE</Text>
        <TextInput
          value={deleteInputValue}
          onChange={(event) => {
            setDeleteInputValue(event.target.value);
          }}
          placeholder="Enter DELETE"
        />
        {canDelete && (
          <Text weight="bold" color="error">
            This is a permanent action and cannot be undone
          </Text>
        )}
        <Box margin={{ top: 'xlarge' }} justify="around" direction="row">
          <SHDButton
            plain
            size="large"
            color="tertiary-0"
            label={<Text weight="bold">Cancel</Text>}
            mixtrack={[
              'Player Clip Delete Cancel Clicked',
              {
                clipId: clip.id,
                loggedIn: !!userStore.authUser,
                sport: 'bbsb',
              },
            ]}
            onClick={() => setShowDeleteClip(false)}
          />
          <SHDButton
            primary
            size="large"
            color="error"
            label={<Text weight="bold">Delete</Text>}
            mixtrack={[
              'Player Clip Delete Confirm Clicked',
              {
                clipId: clip.id,
                loggedIn: !!userStore.authUser,
                sport: 'bbsb',
              },
            ]}
            disabled={!canDelete}
            onClick={() => {
              deleteClip();
            }}
          />
        </Box>
      </Box>
    </Layer>
  );

  const reassignClip = (players: TeamPlayerOption[]) => {
    const newClip = { ...clip };
    newClip.J_claimedPlayerList = players.map((p) => {
      if (p) {
        return p.claimedPlayerId;
      }

      return '';
    });
    newClip.O_teamPlayerList = players.map((p) => {
      if (p) {
        return p._id;
      }

      return '';
    });
    newClip.J_teamPlayerList = players.map((p) => {
      if (p) {
        return p._id;
      }

      return '';
    });

    // ugh
    newClip.teamId = game.DNOR_teamId;

    axios
      .put('/api/clip', { clip: newClip })
      .then((response) => {
        if (response.data.success) {
          setShowReassignClip(false);
          onChange && onChange(response.data.clip);
          // onChange(newClip);
        }
      })
      .catch((e) => Sentry.captureException(e));
  };

  const reassignClipModal = () => (
    <>
      {reassignedPlayers.length > 0 && reassignedPlayers[0] ? (
        <ReassignClipModal
          game={game}
          clip={clip}
          teamPlayers={teamPlayers}
          primaryPlayer={reassignedPlayers[0]}
          secondaryPlayers={reassignedPlayers
            .slice(1)
            .filter((p): p is TeamPlayerOption => p !== undefined)}
          onReassign={reassignClip}
          onClose={() => {
            setShowReassignClip(false);
          }}
        />
      ) : (
        <BasicLoader />
      )}
    </>
  );

  const handleDownloadUrl = (isPreview: boolean, clipObj: ClipFeedClip) => {
    const clipUrl = isPreview
      ? clipObj.fileDownloadUrl
      : clipObj.fileDownloadUrlFull;
    if (isMonetization && !isEntitled) {
      return '#';
    } else {
      return clipUrl;
    }
  };

  const onDownloadClicked = () => {
    if (isMonetization) {
      if (isEntitled) {
        onEntitledDownloadClicked();
      } else {
        handlePaywallOpen();
      }
    } else {
      onEntitledDownloadClicked();
    }
  };

  const onEntitledDownloadClicked = () => {
    if (publicPublishLevel < 0) {
      // unclaimed player
      setShowUnclaimedModal(true);
    } else {
      analytics.track('Player Clip Download Clicked', {
        clipId: clip.id,
        loggedIn: !!userStore.authUser,
        sport: 'bbsb',
      });
      setShowDownloadInstructions(true);
    }
  };

  return (
    <Card elevation="medium" margin={{ top: 'medium' }}>
      {showDeleteClip && deleteClipModal}
      {showReassignClip && reassignClipModal()}
      <Box ref={innerRef}>
        <Box
          style={{ display: 'block' }}
          align="start"
          fill="horizontal"
          pad="medium"
        >
          <Box
            direction="row"
            fill="horizontal"
            align="center"
            justify="between"
            pad={{ bottom: 'small' }}
          >
            <Box fill="horizontal" gap="xsmall">
              <Box
                gap="small"
                direction="row"
                fill="horizontal"
                align="center"
                justify="between"
              >
                <Box fill="horizontal">
                  <Box direction="row" align="center" justify="between">
                    <Text weight="bold">{clipTitle}</Text>
                    {date && <Text size="small">{date}</Text>}
                  </Box>
                  {!date && (
                    <Text weight="bold">
                      {`${formatInningNum(clip.inningNum)} inning`}
                    </Text>
                  )}
                  {clip.game && clip.game.scoreOpponentName && (
                    <Text>{`${clip.game.scoreWeAreHome ? 'vs' : '@'} ${
                      clip.game.scoreOpponentName
                    }`}</Text>
                  )}
                </Box>
                <Box direction="row" justify="end" align="center">
                  {getStarIcon()}
                </Box>
              </Box>
              {isPinnedAndAtTop ? (
                <Box direction="row" gap="small" fill="horizontal">
                  <Text
                    color="text-xweak"
                    size="small"
                  >{`vs. ${clip.opponentName}`}</Text>
                  <Heading color="text-xweak" level={5} margin="none">
                    &#183;
                  </Heading>
                  <Text color="text-xweak" size="small">
                    {formatDateShort(clip.clipStartTs).split('@')[0].trim()}
                  </Text>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            fill="horizontal"
            basis="full"
            direction="row"
            gap="medium"
            justify="between"
          >
            {clip.fileDownloadUrl && (
              <SHDButton
                fill="horizontal"
                primary
                color={isPreviewClip ? 'secondary-1' : 'secondary-7'}
                label={<Text weight="bold">Last pitch</Text>}
                mixtrack={[
                  'Player Clip Last Pitch Preview Clicked',
                  {
                    clipId: clip.id,
                    loggedIn: !!userStore.authUser,
                    sport: 'bbsb',
                  },
                ]}
                disabled={isPreviewClip}
                onClick={() => toggleIsPreviewClip(true)}
              />
            )}
            {clip.fileDownloadUrlFull && (
              <SHDButton
                fill="horizontal"
                primary
                color={isPreviewClip ? 'secondary-7' : 'secondary-1'}
                label={<Text weight="bold">Full at bat</Text>}
                mixtrack={[
                  'Player Clip Full At-Bat Preview Clicked',
                  {
                    clipId: clip.id,
                    loggedIn: !!userStore.authUser,
                    sport: 'bbsb',
                  },
                ]}
                disabled={!isPreviewClip}
                onClick={() => toggleIsPreviewClip(false)}
              />
            )}
          </Box>
        </Box>
        <LazyLoad
          once
          placeholder={
            <Box fill="horizontal" style={{ height: '200px' }}>
              <Skeleton height={200} duration={0.8} />
            </Box>
          }
        >
          <ClipVideoPlayer
            videoSrc={
              isPreviewClip
                ? clip.fileDownloadUrl || ''
                : clip.fileDownloadUrlFull || ''
            }
            showLock={showLock}
            showNotClaimed={showNotClaimed}
            id={clip.id}
            sport="bbsb"
          />
        </LazyLoad>
        <Box pad="small">
          <Box direction="row" justify="between">
            {publicPublishLevel > 0 && (
              <DropButton
                open={menuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                dropContent={renderMenuItems()}
              >
                <More />
              </DropButton>
            )}
            <Box
              pad="small"
              direction="row"
              gap="small"
              align="center"
              fill="horizontal"
              justify="end"
            >
              {/* {(publicPublishLevel === -1 && !hideBottomBar) && (
                <Box gap="large" direction="row" align="center">
                  <SHDButton
                    plain
                    color="secondary-1"
                    icon={<DownloadOption size="20px" color="secondary-1" />}
                    label={<Text size="small">Download</Text>}
                    mixtrack={['Player Clip Download Clicked', {
                      clipId: clip.id,
                      loggedIn: !!userStore.authUser,
                      sport: 'bbsb',
                    }]}
                    id="download-clip-unclaimed"
                    onClick={onDownloadClicked}
                  />
                  { (clip.J_teamPlayerList.length > 0 || clip.J_claimedPlayerList.length > 0)
                    ? (
                      <SHDButton
                        plain
                        label={<Text size="small">Share</Text>}
                        mixtrack={['Player Clip Share Clicked', {
                          clipId: clip.id,
                          loggedIn: !!userStore.authUser,
                          sport: 'bbsb',
                        }]}
                        icon={<HiShare />}
                        color="secondary-1"
                        id="share-clip-unclaimed"
                        onClick={onDownloadClicked}
                      />
                    )
                    : null }
                </Box>
              )} */}
              {clip.isDkData && (
                <SHDButton
                  margin={{ right: 'small' }}
                  icon={<DKLogo />}
                  gap="0px"
                  plain
                  color="secondary-1"
                  label={<Text size="small">Sensor</Text>}
                  mixtrack={[
                    'Player Clip Sensor Clicked',
                    {
                      clipId: clip.id,
                      loggedIn: !!userStore.authUser,
                      sport: 'bbsb',
                    },
                  ]}
                  onClick={onSensorClicked}
                />
              )}
              {(publicPublishLevel >= 10 || ref || active) &&
                !hideBottomBar &&
                !showLock && (
                  <Box gap="large" direction="row" align="center">
                    <SHDButton
                      plain
                      color="secondary-1"
                      icon={<DownloadOption size="20px" color="secondary-1" />}
                      label={<Text size="small">Download</Text>}
                      mixtrack={[
                        'Player Clip Download Clicked',
                        {
                          clipId: clip.id,
                          loggedIn: !!userStore.authUser,
                          sport: 'bbsb',
                        },
                      ]}
                      href={handleDownloadUrl(isPreviewClip, clip)}
                      id="download-clip"
                      onClick={onDownloadClicked}
                    />
                    {clip.J_teamPlayerList.length > 0 ||
                    clip.J_claimedPlayerList.length > 0 ? (
                      <ShareDropdown
                        plain
                        label={<Text size="small">Share</Text>}
                        color="secondary-1"
                        renderShareOptions={renderShareOptions}
                      />
                    ) : null}
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      {showDownloadInstructions && isiOS() ? downloadInstructions : null}
      {showDKInfoModal && dkInfoModal}
      {showUnclaimedModal && unclaimedModal}
      <Paywall
        context={PaywallContext.SINGLE_CLIP_PAGE}
        playerId={playerId}
        isOpen={isPaywallActive}
        onClose={handlePaywallClose}
        onUnlockAccess={handleUnlockAccess}
      />
    </Card>
  );
};

export default forwardRef(PlayerClip);
