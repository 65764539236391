// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Anchor,
  Heading,
  Box,
  Card,
  Button,
  Text,
  CardHeader,
  CardBody,
  RadioButtonGroup,
  RadioButton,
  Layer,
  Image,
  CardFooter,
} from 'grommet';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';

import { Close, FormPreviousLink } from 'grommet-icons';
import AccountInfo from './AccountInfo';
import TeamInfo from './TeamInfo';
import Roster from './TeamRoster';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session/context';
import withLayout from '../../helpers/withLayout';
import { BasicLoader } from '../../helpers/Loaders';
import { useIsFeatureEnabled, useQuery } from '../../helpers/utils';
import CameraLayer from '../SetUp/CameraLayer';
import Toast from '../../components/Toast';
import OnboardingIntent from './OnboardingIntent';
import StreamingCard from './StreamingCard';
import { analytics } from '../../services/analytics';

const Onboarding = observer(() => {
  const query = useQuery();
  const fromExisting = !!query.get('from_existing');
  const userStore = useContext(UserContext);

  const [, setTeamInfo] = useState(null);

  const [importSource, setImportSource] = useState(null);

  const [showCamera, setShowCamera] = useState(false);
  const [captureList, setCaptureList] = useState([]);
  // const [isUserOwnsCapture, setIsUserOwnsCapture] = useState(false);

  const [toast, setToast] = useState(null);
  const [facebookLinked, setFacebookLinked] = useState(false);
  const [youtubeLinked, setYoutubeLinked] = useState(false);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [fbStreamOption, setFbStreamOption] = useState('default');

  const showGCImport = useIsFeatureEnabled('show_gc_import', userStore);

  const [step, setStep] = useState(-1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const makeLivestreamer = () => {
    if (userStore.authUser.claims.shd_user_id) {
      const newUser = {
        isPermittedToCreateTeam: true,
      };
      axios
        .put(`/api/user/${userStore.authUser.claims.shd_user_id}`, { newUser })
        .then(() => {
          setToast({
            label: 'User set as livestreamer',
            background: 'status-ok',
          });
          window.location.reload();
        });
    }
  };

  const LIVESTREAM_DESTINATION_STEP = 4;
  const CAPTURE_SETUP_STEP = 5;
  const TEAM_ROSTER_STEP = 3;
  const TEAM_INFO_STEP = 2;
  const TEAM_INFO_IMPORT_STEP = 1;
  const PHONE_NUMBER_STEP = 0;

  const INTENT_STEP = 10;

  useEffect(() => {
    if (userStore.activeOnboardingTeam) {
      axios.get(`/api/team/${userStore.activeOnboardingTeam}`).then((res) => {
        const resTeamInfo = res.data.info;
        setTeamInfo(resTeamInfo);

        if (
          res.data.players.length === 0 &&
          !resTeamInfo.isStageStreamingSetup
        ) {
          setStep(LIVESTREAM_DESTINATION_STEP);
        } else if (resTeamInfo.isStageStreamingSetup) {
          // show livestreaming destination
          setStep(CAPTURE_SETUP_STEP);
        } else if (
          res.data.players.length > 0 &&
          resTeamInfo.isStageTeamInfoDone
        ) {
          // show camera
          setStep(CAPTURE_SETUP_STEP);

          const properties = {
            sport: resTeamInfo.attrib_sportType,
            teamName: resTeamInfo.nameLong,
            isStageTeamRosterDone: 1,
          };
          window.sendinblue.track('setup_status', properties, {
            id: userStore.activeOnboardingTeam,
          });
        } else {
          setStep(TEAM_INFO_STEP);
        }

        setLoading(false);
      });
    } else if (userStore.userPhone && userStore.user.email) {
      if (fromExisting) {
        // TEMP
        // setStep(INTENT_STEP);
        setStep(TEAM_INFO_STEP);
        setLoading(false);
      } else {
        setStep(INTENT_STEP);
        setLoading(false);
      }
    } else {
      setStep(PHONE_NUMBER_STEP);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (step === CAPTURE_SETUP_STEP) {
  //     axios.get(`/api/capture/team/${userStore.activeOnboardingTeam}`)
  //       .then((response) => {
  //         if (response.data.captureList.length) {
  //           setCaptureList(response.data.captureList);
  //           setLoading(false);

  //           if (response.data.isUserOwnsCapture) {
  //             setIsUserOwnsCapture(true);
  //           }
  //         } else {
  //           setCaptureList([]);
  //         }
  //       })
  //       .catch(() => {
  //         setError('An error occurred. Please refresh and try again.');
  //       });
  //   } else if (step >= 0) {
  //     setLoading(false);
  //   }
  // }, [step]);

  const nextStep = () => {
    if (step === PHONE_NUMBER_STEP) {
      setStep(INTENT_STEP);
    } else if (step === INTENT_STEP) {
      setStep(TEAM_INFO_STEP);
    } else if (step === TEAM_INFO_STEP) {
      // skip roster entry
      setStep(LIVESTREAM_DESTINATION_STEP);
    } else if (step === LIVESTREAM_DESTINATION_STEP) {
      setStep(CAPTURE_SETUP_STEP);
    } else {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    if (step === CAPTURE_SETUP_STEP) {
      setStep(LIVESTREAM_DESTINATION_STEP);
    } else if (step === LIVESTREAM_DESTINATION_STEP) {
      setStep(TEAM_INFO_STEP);
    } else if (step === TEAM_INFO_STEP) {
      if (userStore.userPhone) {
        setStep(INTENT_STEP);
      } else {
        setStep(PHONE_NUMBER_STEP);
      }
    } else {
      setStep(step - 1);
    }
  };

  const finishOnboarding = () => {
    setLoading(true);

    axios
      .post('/api/onboarding', { teamId: userStore.activeOnboardingTeam })
      .then(({ data: team }) => {
        const newTeamId = userStore.activeOnboardingTeam;
        analytics.track('New Onboarding Completed', {
          importSource,
          isFirstTeam: !fromExisting,
          teamId: newTeamId,
          sport: team.attrib_sportType.toLowerCase().replace(' ', '_'),
        });

        setLoading(false);
        window.location.href = `/${newTeamId}`;
      })
      .catch((respError) => {
        setError('Something went wrong. We are working to fix this.');
        setLoading(false);
        Sentry.captureException(respError);
      });
  };

  const camerasCard = (
    <Box pad={{ horizontal: 'large' }} gap="small">
      <Card>
        <CardHeader fill background="primary-8" pad="large">
          <Box direction="row" justify="center" fill>
            <Box width="38%">
              <Image src="sidelineCAM_preview.png" fit="contain" fill />
            </Box>
            <Box width="38%">
              <Image src="scoring_device.png" fit="contain" fill />
            </Box>
          </Box>
        </CardHeader>
        <CardBody pad="medium">
          <Box pad="small" gap="small">
            <Heading level="3">Ready to go live!</Heading>
            <Text size="large">
              You will <Text weight="bold">stream</Text> from one device and{' '}
              <Text weight="bold">score</Text> with a second device.
            </Text>
            <Text size="large">
              Sign in to sidelineHD.com on both web browsers. No app required!
            </Text>
            <Text size="small">
              Advanced users can stream with Mevo, GoPro, or Larix Broadcaster –
              configure in the STREAMING page.
            </Text>
          </Box>
        </CardBody>
        <CardFooter pad="medium" fill>
          <Button
            primary
            label="Finish"
            onClick={() => {
              finishOnboarding();
            }}
            size="large"
            fill
          />
        </CardFooter>
      </Card>
    </Box>
  );

  const streamBothOption = {
    label: 'Stream to both Facebook and YouTube.',
    value: 'default',
  };

  const streamYTPostFBOption = {
    label:
      'Stream to YouTube only. Post YouTube link to Facebook once scoring begins.',
    value: 'fb-post-yt-only',
  };

  const bothLinkedCard = (
    <Card pad="none" direction="column" background="white">
      <Box pad="medium" justify="between" direction="row-reverse">
        <Button
          plain
          label={
            <Text color="secondary-1" size="small">
              Learn more
            </Text>
          }
          onClick={() => {
            setShowLearnMoreModal(true);
          }}
        />
      </Box>
      <CardBody
        pad={{ left: 'medium', right: 'medium', bottom: 'medium' }}
        gap="medium"
      >
        <Text>
          You have linked Facebook and YouTube destinations. Choose from:
        </Text>
        <Box>
          <RadioButtonGroup
            name="radio"
            width="inherit"
            options={[streamBothOption, streamYTPostFBOption]}
            value={fbStreamOption}
          >
            {(option, { checked }) => {
              const button = (
                <RadioButton
                  key={option.value}
                  checked={checked}
                  onChange={() => {
                    axios
                      .put(`/api/team/${userStore.activeOnboardingTeam}`, {
                        teamInfo: {
                          publishCrosspostMode: option.value,
                        },
                      })
                      .catch(() => {
                        setToast({
                          label: 'Update crosspost settings failed!',
                          background: 'status-error',
                        });
                      });

                    setFbStreamOption(option.value);
                  }}
                />
              );

              return (
                <Box
                  style={{ whiteSpace: 'pre-wrap' }}
                  direction="row"
                  gap="small"
                >
                  {button}
                  <Box width="medium">
                    <Text size="small">{option.label}</Text>
                  </Box>
                </Box>
              );
            }}
          </RadioButtonGroup>
        </Box>
      </CardBody>
    </Card>
  );

  const learnMoreModal = (
    <Layer
      onEsc={() => {
        setShowLearnMoreModal(false);
      }}
      modal
    >
      <Box width="large" pad="medium" gap="medium">
        <Box
          align="baseline"
          direction="row"
          justify="between"
          fill="horizontal"
        >
          <Heading level={3}>Publish to both Facebook and YouTube</Heading>
          <Button
            icon={<Close />}
            onClick={() => {
              setShowLearnMoreModal(false);
            }}
          />
        </Box>
        <Text>
          Your team is linked to both Facebook and YouTube. You have two
          choices:
        </Text>
        <Text>
          (1) Stream to both Facebook and YouTube. This provides the best
          Facebook viewing experience.
        </Text>
        <Text margin={{ bottom: 'medium' }}>
          (2) Stream to YouTube only. Post YouTube link to Facebook once scoring
          begins. Select this option if your Facebook is blocking your streams
          due to copyrighted audio.
        </Text>
        <Text>
          Note: Option #2 is not available if you have linked to a personal
          Facebook profile.
        </Text>

        <Text margin={{ bottom: 'none' }}>
          <Anchor
            label="Read about audio copyright issues"
            href="https://help.sidelinehd.com/a/solutions/articles/69000802906"
          />
        </Text>
      </Box>
    </Layer>
  );

  const inputTeamInfo = (
    <Box gap="small">
      <Card background="white" pad="large" gap="large" margin="auto">
        <Heading level="3">
          How would you like to enter your team information?
        </Heading>
        {showGCImport && (
          <Button
            primary
            size="large"
            color="primary-1"
            label={<Text weight="bold">Import from GameChanger</Text>}
            onClick={() => {
              setImportSource('gc');
              nextStep();
            }}
          />
        )}
        <Button
          primary
          size="large"
          color="#1D0E5A"
          label={<Text weight="bold">Import from USSSA</Text>}
          onClick={() => {
            setImportSource('usssa');
            nextStep();
            // history.push(`${ROUTES.USSSA_IMPORT}?from_existing=1`);
          }}
        />
        <Button
          primary
          size="large"
          color="#0f2355"
          label={<Text weight="bold">Import from Perfect Game</Text>}
          onClick={() => {
            setImportSource('pg');
            nextStep();
          }}
        />
        <Button
          size="large"
          label={<Text weight="bold">Enter it myself</Text>}
          onClick={() => {
            nextStep();
          }}
        />
      </Card>
    </Box>
  );

  if (error) {
    return (
      <Heading
        textAlign="center"
        margin="auto"
        level={4}
        color="status-critical"
      >
        {error}
      </Heading>
    );
  }

  if (loading) {
    return <BasicLoader fullPage />;
  }

  if (!userStore.isPermittedToCreateTeam && fromExisting) {
    makeLivestreamer();
    return null;
  }

  return (
    <>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          backgroundColor: '#d7f5d9',
          height: '16px',
        }}
      >
        <div
          style={{
            flexBasis: `${(step + 1) * (100 / 5) - 10}%`,
            backgroundColor: '#085905',
            height: '16px',
          }}
        />
      </div>
      {(userStore.userTeams.filter(
        (team) => team._id !== userStore.activeOnboardingTeam
      ).length ||
        userStore.userPlayers.length) &&
      step !== TEAM_INFO_STEP &&
      step !== LIVESTREAM_DESTINATION_STEP ? (
        <Box pad="medium">
          <Button plain color="status-critical" label="Cancel" href="/" />
        </Box>
      ) : null}
      {/* {(step === TEAM_INFO_STEP) && (
        <Button icon={<FormPreviousLink />} onClick={() => { previousStep(); }} />
      )} */}
      {step !== PHONE_NUMBER_STEP &&
        step !== INTENT_STEP &&
        step !== TEAM_INFO_STEP &&
        step !== LIVESTREAM_DESTINATION_STEP && (
          <Button
            icon={<FormPreviousLink />}
            onClick={() => {
              previousStep();
            }}
          />
        )}
      {step === PHONE_NUMBER_STEP && !loading ? (
        <AccountInfo currentStep={step} afterValidation={nextStep} />
      ) : null}
      {step === INTENT_STEP && !loading ? (
        <OnboardingIntent currentStep={step} afterValidation={nextStep} />
      ) : null}
      {step === TEAM_INFO_IMPORT_STEP ? inputTeamInfo : null}
      {step === TEAM_INFO_STEP ? (
        <TeamInfo
          importSource={importSource}
          afterValidation={(info) => {
            setTeamInfo(info);
            nextStep();
          }}
          // previousStep={!startStep && previousStep}
          previousStep={previousStep}
          fromExisting={fromExisting}
        />
      ) : null}
      {step === TEAM_ROSTER_STEP ? (
        <Roster
          afterValidation={(info) => {
            setTeamInfo(info);
            const properties = {
              sport: info.attrib_sportType,
              teamName: info.nameLong,
              isStageTeamRosterDone: 1,
            };
            window.sendinblue.track('setup_status', properties, {
              id: userStore.activeOnboardingTeam,
            });
            nextStep();
          }}
          previousStep={previousStep}
        />
      ) : null}
      {step === LIVESTREAM_DESTINATION_STEP ? (
        <>
          {/* temp */}
          <Anchor
            label="Skip"
            onClick={() => {
              window.location.href = `/${userStore.activeOnboardingTeam}`;
            }}
            margin={{ left: 'large', top: 'large' }}
          />
          <StreamingCard
            facebookLinked={facebookLinked}
            youtubeLinked={youtubeLinked}
            bothLinkedCard={bothLinkedCard}
            userStore={userStore}
            setFacebookLinked={setFacebookLinked}
            setYoutubeLinked={setYoutubeLinked}
            nextStep={nextStep}
          />
        </>
      ) : null}
      {step === CAPTURE_SETUP_STEP ? camerasCard : null}
      {showCamera && (
        <CameraLayer
          teamId={userStore.activeOnboardingTeam}
          autoAddCaptureSetup={showCamera}
          setCapture={(capture) => {
            setCaptureList([...captureList, capture]);
            // setIsUserOwnsCapture(true);
          }}
          closeLayer={() => setShowCamera(false)}
        />
      )}
      {showLearnMoreModal && learnMoreModal}
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </>
  );
});

export default compose(withLayout, withRouter)(Onboarding);
