import { postcodeValidator } from 'postcode-validator';

export const validateTeamName = (field: string) => {
  if (field.length === 0) {
    return {
      message: 'This field is required.',
      status: 'error',
    };
  }

  if (field.length > 30) {
    return {
      message: 'Please enter a name 30 characters or fewer.',
      status: 'error',
    };
  }
  if (field.length < 5) {
    return {
      message: 'Please enter a name 5 characters or more.',
      status: 'error',
    };
  }
};

export const validateHandle = (field: string) => {
  if (field.length === 0) {
    return {
      message: 'This field is required.',
      status: 'error',
    };
  }

  if (field.length > 20) {
    return {
      message: 'Please enter a handle 20 characters or fewer.',
      status: 'error',
    };
  }
  if (field.length < 5) {
    return {
      message: 'Please enter a handle 5 characters or more.',
      status: 'error',
    };
  }

  if (field.length === 0) {
    return {
      message: 'This field is required.',
      status: 'error',
    };
  }

  const regex = /[^a-zA-Z0-9-_]/;
  if (regex.test(field)) {
    return {
      message: 'Please remove special characters.',
      status: 'error',
    };
  }
  return { message: null, status: 'info' };
};

export const validateScoreBoardName = (field: string) => {
  if (field.length > 20) {
    return {
      message: 'Please enter a name 20 characters or fewer.',
      status: 'error',
    };
  }
  if (field.length < 5) {
    return {
      message: 'Please enter a name 5 characters or more.',
      status: 'error',
    };
  }
  return { message: null, status: 'info' };
};

export const validatePostalCode = (field: string) => {
  if (!postcodeValidator(field, 'US')) {
    return { message: 'Please enter a valid US zip code', status: 'error' };
  }
  return { message: null, status: 'info' };
};
