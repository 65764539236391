// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import { withFirebase } from '../../services/Firebase';
import axios from '../../helpers/axios';

const StopImpersonation = withFirebase((props) => {
  useEffect(() => {
    axios
      .delete('/api/impersonate', {
        data: {
          auth_user_id: props.firebase.auth.currentUser.uid,
          spoof_user_id: null,
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const user = props.firebase.auth.currentUser;

          await user.getIdTokenResult(true);
          localStorage.setItem('userRole', 0);
          window.location.href = '/admin';
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>Stop Impersonation</>;
});

export default StopImpersonation;
