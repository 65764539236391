import React, { useState } from 'react';
import { GameCardHeaderProps } from './games.types';
import { Box, Text, DropButton } from 'grommet';
import { More } from 'grommet-icons';
import { DateTime } from 'luxon';
import GameDeleteLayer from './GameDeleteLayer';

const GameCardHeader: React.FC<GameCardHeaderProps> = ({
  startTime,
  isUpcomingGame,
  teamId,
  teamGameId,
  isAdmin,
  handleDeleteGame,
}) => {
  const [gameOptionsOpen, setGameOptionsOpen] = useState<boolean>(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);

  const date = DateTime.fromMillis(startTime * 1000).toLocal();
  const d = date.toLocaleString(DateTime.DATE_MED);
  const t = date.toLocaleString(DateTime.TIME_SIMPLE);
  let dateDisplay = `${d}, ${t}`;
  if (isUpcomingGame) {
    dateDisplay = `Upcoming - ${dateDisplay}`;
  }

  const onDeleteGame = () => {
    handleDeleteGame(teamId, teamGameId);
    setConfirmDeleteModal(false);
  };
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad={{
        bottom: '.25rem',
      }}
    >
      <Text size="small" weight={'bold'}>
        {dateDisplay}
      </Text>

      {isAdmin && (
        <DropButton
          open={gameOptionsOpen}
          onClick={() => setGameOptionsOpen(!gameOptionsOpen)}
          plain
          icon={<More size="16px" />}
          dropContent={
            <Box
              pad={{ horizontal: 'small', vertical: 'small' }}
              margin={{ horizontal: 'small' }}
              onClick={() => {
                setGameOptionsOpen(false);
                setConfirmDeleteModal(true);
              }}
            >
              <Text>Delete Game</Text>
            </Box>
          }
          dropProps={{
            align: { top: 'bottom', right: 'right' },
          }}
        />
      )}
      {confirmDeleteModal && (
        <GameDeleteLayer
          onClose={setConfirmDeleteModal}
          onDeleteGame={onDeleteGame}
        />
      )}
    </Box>
  );
};

export default GameCardHeader;
