// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState, useRef } from 'react';

import Joyride, { STATUS } from 'react-joyride';

import {
  Box,
  Text,
  Footer,
  Layer,
  Header,
  Button,
  Heading,
  Grommet,
  CheckBox,
} from 'grommet';
import './scoring.css';
import { Close, Radial, Undo, Volume, VolumeMute, Wifi } from 'grommet-icons';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import { AiOutlineQrcode } from 'react-icons/ai';
import { MdOutlineSportsVolleyball } from 'react-icons/md';

import { withFirebase } from '../../services/Firebase';

import { UserContext } from '../../services/Session';

import { VBScoringContext } from './context';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';

import CourtPlayersScore from './CourtPlayersScore';
import Toast from '../../components/Toast';
import { getMatchScores, useInterval } from '../../helpers/utils';
import VBSettings from './VBSettings';
import GrommetContainer from '../../helpers/GrommetContainer';
import { GROMMETTHEME } from '../../constants/grommetTheme';
import LivestreamLinksPage from './LivestreamLinksPage';
import { analytics } from '../../services/analytics';

const footerStyles = {
  position: 'fixed',
  bottom: 0,
  zIndex: 10,
  width: '100%',
  maxWidth: '600px',
};

const headerStyles = {
  position: 'fixed',
  top: 0,
  zIndex: 10,
  width: '100%',
  maxWidth: '600px',
};

const Container = ({
  containerRef,
  setShowExitScoring,
  undoClicked,
  tabIndex,
  setTabIndex,
  setShowQrModal,
  setShowStreamStatsModal,
  toggleMuted,
  isBallInPlay,
  ...containerProps
}) => {
  const [blink, setBlink] = useState(false);

  let backgroundColor = 'white';
  if (isBallInPlay) {
    backgroundColor = blink ? '#FFF2EF' : '#FDECE9';
  }

  useInterval(() => {
    if (isBallInPlay) {
      setBlink((lastValue) => !lastValue);
    }
  }, 1000);

  return (
    <Box
      ref={containerRef}
      direction="column"
      justify="between"
      height="100vh"
      style={{
        maxWidth: '600px',
      }}
      margin="auto"
      background={backgroundColor}
      {...containerProps}
    >
      <Box
        fill="vertical"
        pad={{ top: containerProps.isSmallScreen ? '38px' : '50px' }}
      >
        <Header background="brand" pad={'small'} style={headerStyles}>
          <Button
            id="exit-scoring-button"
            secondary
            color="#ffffff"
            label={<Text color="white">Exit scoring</Text>}
            onClick={() => setShowExitScoring(true)}
          />
          {containerProps.streamStats && (
            <Box direction="row" align="center" fill="vertical" gap="xsmall">
              <Box
                align="center"
                gap="xsmall"
                pad={{ vertical: 'xsmall', horizontal: 'small' }}
                background="white"
                round="small"
                direction="row"
                onClick={() => {
                  setShowStreamStatsModal(true);
                }}
              >
                <Wifi
                  size="small"
                  color={
                    containerProps.streamStats.isHealthy
                      ? 'status-ok'
                      : 'status-critical'
                  }
                />
                <Text
                  size="small"
                  color={
                    containerProps.streamStats.isHealthy
                      ? 'status-ok'
                      : 'status-critical'
                  }
                >
                  {containerProps.streamStats.isHealthy
                    ? 'Healthy'
                    : 'Unhealthy'}
                </Text>
              </Box>
            </Box>
          )}
          <Button
            plain
            color="white"
            label={<Text color="white">Undo</Text>}
            icon={<Undo color="white" />}
            onClick={undoClicked}
          />
        </Header>
        {containerProps.children}
      </Box>
      <Footer background="brand" pad="small" style={footerStyles}>
        <Box style={{ position: 'absolute', left: '8px' }}>
          <Button
            icon={
              containerProps.muted ? (
                <VolumeMute size="small" />
              ) : (
                <Volume size="small" />
              )
            }
            label={
              <Text size="small">
                {containerProps.muted ? 'Muted' : 'Unmuted'}
              </Text>
            }
            plain
            onClick={toggleMuted}
          />
        </Box>
        <Box
          gap="medium"
          justify="center"
          align="center"
          direction="row"
          fill="horizontal"
        >
          <Box
            round="8px"
            background={!tabIndex ? 'primary-5' : 'primary-1'}
            align="center"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            onClick={() => setTabIndex(0)}
          >
            <Text textAlign="center">Score</Text>
          </Box>
          <Box
            className="settings-label"
            id="players-on-court"
            round="8px"
            background={tabIndex === 1 ? 'primary-5' : 'primary-1'}
            align="center"
            pad={{ horizontal: 'medium', vertical: 'small' }}
            onClick={() => setTabIndex(1)}
          >
            <Text textAlign="center">Settings</Text>
          </Box>
        </Box>
        <Box
          direction="row"
          align="center"
          fill="vertical"
          gap="xsmall"
          style={{ position: 'absolute', right: '8px' }}
        >
          <Button
            plain
            size="small"
            label={<Text size="small">Share</Text>}
            icon={<AiOutlineQrcode />}
            onClick={() => {
              analytics.track('sidelineSCORE: Share Modal Opened', {
                sport: 'vb',
                teamId: containerProps.teamId,
                gameId: containerProps.gameId,
              });
              setShowQrModal(true);
            }}
          />
        </Box>
      </Footer>
    </Box>
  );
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const BlinkingCircle = ({ isBallInPlay = false }) => {
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    if (isBallInPlay) {
      setInterval(() => {
        setBlink((lastValue) => !lastValue);
      }, 1000);
    }
  }, [isBallInPlay]);

  return <Radial color={blink ? 'status-error' : 'white'} />;
};

const scoringStats = ['ace', 'kill', 'block'];

const ScoringScreenBase = observer((props) => {
  const { firebase, match } = props;
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const containerRef = useRef(null);

  const userStore = useContext(UserContext);
  const scoringStore = useContext(VBScoringContext);
  const [team, setTeam] = useState(
    userStore.selectedTeam || userStore.scoringTeam
  );
  const [showAwayHomePrompt, setShowAwayHomePrompt] = useState(false);
  const [error, setError] = useState();
  const [game, setGame] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [startedSub, setStartedSub] = useState(false);
  const [showExitScoring, setShowExitScoring] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [streamStats, setStreamStats] = useState();
  const [muted, setMuted] = useState();
  const [showStreamStatsModal, setShowStreamStatsModal] = useState(false);
  const { gameId: scoreGameId, teamId } = match.params;

  const [loggingPlayerStat, setLoggingPlayerStat] = useState(false);
  const [tempRotate, setTempRotate] = useState(false);
  const [toast, setToast] = useState(null);
  const [runProductTour, setRunProductTour] = useState(true);

  const [allowShowToast, setAllowShowToast] = useState(false);

  const [scoreModalInfo, setScoreModalInfo] = useState({
    show: false,
    onYes: () => {},
    onNo: () => {},
    counter: 0,
  });

  const ourLineup = scoringStore.awayHome
    ? scoringStore.homeLineup
    : scoringStore.awayLineup;

  const scoringTeam = scoringStore.awayHome ? 'home' : 'away';
  const opposingTeam = scoringStore.awayHome ? 'away' : 'home';

  const gameState = (scoringStore.scoreboard || {}).gameState || {};
  const isTimeout = 'timeout' in gameState;
  const isAwayTimeout = isTimeout && gameState.timeout.teamNAH === 1;
  const isHomeTimeout = isTimeout && gameState.timeout.teamNAH === 2;

  const getStreamHealth = () => {
    if (scoringStore.teamId) {
      axios
        .get(`/api/stream_health/${scoringStore.teamId}`)
        .then(({ data }) => {
          setStreamStats(data);
        })
        .catch(() => {});
    }
  };

  useInterval(getStreamHealth, 30000);

  useEffect(() => {
    if (scoringStore.teamId) {
      getStreamHealth();
      firebase.subscribeToTeam(scoringStore.teamId, (snapshot, err = null) => {
        if (snapshot) {
          setMuted(
            snapshot.muteIsMuted !== undefined ? snapshot.muteIsMuted : false
          );

          if (muted !== snapshot.muteIsMuted) {
            setToast({
              label: snapshot.muteIsMuted
                ? 'Stream is muted'
                : 'Stream is unmuted',
              background: 'status-ok',
              icon: snapshot.muteIsMuted ? <VolumeMute /> : <Volume />,
            });
          }
        } else {
          Sentry.captureMessage(err);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringStore.teamId]);

  const exitScoring = () => {
    const body = {
      teamId,
      teamGameId: `GmSh01-${scoreGameId}`,
      teamGameUpdate: {
        scoreGameState: 40,
        scheduleState: 40,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        props.history.replace('/scoring');
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        props.history.replace('/scoring');
      });
  };

  const initGameState = () => {
    const teamGamePromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/scoring_game/GmSh01-${scoreGameId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });

    const scoreboardPromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/scoreboard/${teamId}/${scoreGameId}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });

    Promise.all([teamGamePromise, scoreboardPromise])
      .then(([teamGame, scoreboardData]) => {
        // lineup refers to starting lineup
        const { scoreboard, lineup } = scoreboardData;

        if (
          !scoreboard ||
          (scoreboard.eventHistory == null &&
            scoreboard.eventHistoryCompressed == null)
        ) {
          setShowAwayHomePrompt(true);
        } else {
          // should forceResume be false except when reopening game?
          const forceResume = true;

          // HACK: add numWrites to teamGame so we can have it in state
          const newTeamGame = {
            ...teamGame,
            numWrites: scoreboard.numWrites || 0,
          };
          scoringStore.initGameState(
            newTeamGame,
            scoreboard,
            firebase,
            forceResume,
            lineup
          );
        }
        setGame(teamGame);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
      });
  };

  useEffect(() => {
    if (!team) {
      const scoreTeamId = localStorage.getItem('scoreTeamId');
      const scoreDelegationKey = localStorage.getItem('scoreDelegationKey');
      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
        })
        .catch((respError) => {
          if (
            respError &&
            respError.response &&
            respError.response.status === 403
          ) {
            setError('You are not authorized to score for this team.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
        });
    }

    return () => {
      if (scoringStore) {
        scoringStore.unsubscribeFromGame();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      initGameState();
      setTimeout(() => {
        setAllowShowToast(true);
        setToast(null);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  useEffect(() => {
    // if (containerRef.current) {
    //   console.log(containerRef.current.clientHeight);
    // }

    if (containerRef.current && containerRef.current.clientHeight < 760) {
      setIsSmallScreen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  useEffect(() => {
    if (scoringStats.includes(loggingPlayerStat.stat)) {
      const a =
        scoringStore.scoreboard.teamServingNAH === 2 &&
        scoringStore.awayHome === 0;
      const b =
        scoringStore.scoreboard.teamServingNAH === 1 &&
        scoringStore.awayHome === 1;
      if (a || b) {
        // console.log('serve just changed!');
        setTempRotate(true);
      } else {
        // console.log('same team serving');
        setTempRotate(false);
      }
    } else {
      setTempRotate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingPlayerStat]);

  const setAwayHome = (awayHome) => {
    const newGame = { ...game };
    newGame.scoreWeAreHome = !!awayHome;
    const body = {
      teamId: game.scoreTeamIdOurs,
      teamGameId: `GmSh01-${game.scoreGameId}`,
      teamGameUpdate: {
        scoreWeAreHome: !!awayHome,
      },
    };
    axios
      .put('/api/team_game', body)
      .then(() => {
        scoringStore.initGameState(newGame, null, firebase);
        setShowAwayHomePrompt(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        setShowAwayHomePrompt(false);
      });
  };

  if (showAwayHomePrompt) {
    return (
      <Layer>
        <Box align="center" justify="center" fill gap="medium" pad="medium">
          <Text textAlign="center" size="large">
            Are you the AWAY or HOME team?
          </Text>
          <Box direction="row" gap="medium">
            <Button
              size="large"
              primary
              label="AWAY"
              onClick={() => setAwayHome(0)}
              color="tertiary-1"
            />
            <Button
              size="large"
              primary
              label="HOME"
              onClick={() => setAwayHome(1)}
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Layer>
    );
  }

  const undoClicked = () => {
    const actionEvents = scoringStore.eventHistory.filter(
      (event) => !['startGame', 'forceRun', 'sub'].includes(event.eventType)
    );

    if (actionEvents.length === 0) {
      axios
        .delete(`/api/scoreboard/${teamId}/${scoreGameId}`)
        .then(() => {
          setShowAwayHomePrompt(true);
        })
        .catch(() => setError('Error undoing event'));
    } else {
      scoringStore.handleEvent('undo', {});
    }
  };

  const closeScoreModal = () => {
    setScoreModalInfo({
      ...scoreModalInfo,
      show: false,
    });
  };

  const renderScoreModal = () => (
    <Layer
      responsive={false}
      full={false}
      onEsc={closeScoreModal}
      onClickOutside={closeScoreModal}
    >
      <Box width="medium">
        <Box direction="row" justify="end" fill="horizontal">
          <Button icon={<Close />} onClick={closeScoreModal} hoverIndicator />
        </Box>
        <Box pad="small" gap="small">
          <Text weight="bold">Do you want to change the score? </Text>
          <Text>
            {`You haven't put the ball in play yet! To record highlights,
            mark the start of play by tapping "Ball Served".`}
          </Text>
          <Box direction="row" gap="small" justify="end" fill="horizontal">
            <Button
              primary
              label="Yes"
              onClick={scoreModalInfo.onYes}
              hoverIndicator
            />
            <Button label="No" onClick={scoreModalInfo.onNo} hoverIndicator />
          </Box>
        </Box>
      </Box>
    </Layer>
  );

  if (error) {
    return (
      <Text color="status-critical" size="large" textAlign="center">
        {error}
      </Text>
    );
  }

  const renderBottomHalf = (currentSet) => {
    const hasSubs =
      scoringStore.eventHistory.filter((e) => e.eventType === 'sub').length > 0;
    const isInitialState =
      scoringStore.scoreboard.a === 0 &&
      scoringStore.scoreboard.h === 0 &&
      scoringStore.scoreboard.isBallInPlay === false &&
      !hasSubs;
    const weAreServing =
      (scoringStore.scoreboard.teamServingNAH === 2 && scoringStore.awayHome) ||
      (scoringStore.scoreboard.teamServingNAH === 1 && !scoringStore.awayHome);

    if (startedSub) {
      return startedSub;
    }

    return (
      <Box
        className="score-container"
        style={{
          opacity: loggingPlayerStat ? '0.25' : '1',
          pointerEvents: loggingPlayerStat ? 'none' : 'all',
        }}
        fill
        direction="column"
        gap="small"
        margin={{ top: isSmallScreen ? 'none' : 'small' }}
      >
        {/* row 1 */}
        <Box direction="row" justify="between" style={{ minHeight: 'auto' }}>
          {/* scoreboard 1 */}
          <Box width="47%">
            <Box margin={{ bottom: 'small' }}>
              <Text
                size={isSmallScreen ? 'large' : 'large'}
                weight="bold"
                style={{ textOverflow: 'ellipsis' }}
              >
                {scoringStore.awayHome
                  ? scoringStore.gameInfo.teamHNameMed
                  : scoringStore.gameInfo.teamANameMed}
              </Text>
              <Grommet
                theme={{
                  checkBox: {
                    color: '#066d99',
                    size: '16px',
                  },
                }}
              >
                <CheckBox
                  checked={
                    scoringStore.awayHome ? isHomeTimeout : isAwayTimeout
                  }
                  label={
                    <Box alignSelf="center">
                      <Text size="xsmall">Timeout</Text>
                    </Box>
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      const eventInfo = {
                        name: 'timeout',
                        teamNAH: scoringStore.awayHome ? 2 : 1,
                        enable: true,
                      };
                      scoringStore.handleEvent('changeGameState', eventInfo);
                    } else {
                      const eventInfo = {
                        name: 'timeout',
                        enable: false,
                      };
                      scoringStore.handleEvent('changeGameState', eventInfo);
                    }
                  }}
                />
              </Grommet>
            </Box>
            <Box id="left-score-container">
              <Box
                direction="row"
                justify="between"
                gap="small"
                fill="horizontal"
                background="light-1"
                margin={{ bottom: '-1.25rem' }}
                style={{ zIndex: '10' }}
                pad={{ horizontal: 'small', top: 'small' }}
              >
                <Button
                  onClick={() => {
                    scoringStore.handleEvent('toggleServe', {
                      teamClickedNAH: scoringStore.awayHome ? 2 : 1,
                    });
                  }}
                >
                  <Box
                    background={
                      (scoringStore.scoreboard.teamServingNAH === 2 &&
                        scoringStore.awayHome) ||
                      (scoringStore.scoreboard.teamServingNAH === 1 &&
                        !scoringStore.awayHome)
                        ? 'tertiary-1'
                        : 'dark-4'
                    }
                    round="full"
                    width="1.5rem"
                    height="1.5rem"
                    align="center"
                    justify="center"
                  >
                    <MdOutlineSportsVolleyball color="white" />
                  </Box>
                </Button>
                <Text size="large" weight="bolder">
                  {scoringStore.awayHome
                    ? scoringStore.scoreboard.teamHMatchScore
                    : scoringStore.scoreboard.teamAMatchScore}
                </Text>
              </Box>
              <Box
                id="left-score"
                background="light-1"
                round="small"
                justify="center"
              >
                <Box align="center" pad={{ top: '0.85rem', bottom: '0.4rem' }}>
                  <Text size={isSmallScreen ? '2xl' : '3xl'} weight="bold">
                    {scoringStore.awayHome
                      ? scoringStore.scoreboard.h
                      : scoringStore.scoreboard.a}
                  </Text>
                </Box>
              </Box>
              <Box direction="row" gap="small" margin={{ top: 'small' }}>
                <Box basis="1/4" height="100%">
                  <Button
                    primary
                    color="secondary-6"
                    alignSelf="center"
                    label={<Text weight="bolder">-</Text>}
                    onClick={() => {
                      scoringStore.handleEvent(scoringTeam, { isPlus: false });
                    }}
                    fill
                  />
                </Box>
                <Box
                  basis="3/4"
                  // height={isSmallScreen ? '1.5rem' : '2rem'}
                  height="100%"
                >
                  <Button
                    primary
                    color="secondary-6"
                    label={<Text weight="bolder">+</Text>}
                    onClick={() => {
                      if (!scoringStore.scoreboard.isBallInPlay) {
                        if (scoreModalInfo.counter < 3) {
                          setScoreModalInfo({
                            ...scoreModalInfo,
                            show: true,
                            onYes: () => {
                              scoringStore.handleEvent(scoringTeam, {
                                isPlus: true,
                                isManual: true,
                              });
                              closeScoreModal();
                              setScoreModalInfo({
                                ...scoreModalInfo,
                                counter: scoreModalInfo.counter + 1,
                              });
                            },
                            onNo: closeScoreModal,
                          });
                        } else {
                          scoringStore.handleEvent(scoringTeam, {
                            isPlus: true,
                            isManual: true,
                          });
                        }
                      } else {
                        scoringStore.handleEvent(scoringTeam, { isPlus: true });
                      }
                    }}
                    fill
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* scoreboard 2 */}
          <Box width="47%">
            <Box margin={{ bottom: 'small' }}>
              <Text
                size={isSmallScreen ? 'large' : 'large'}
                weight="bold"
                style={{ textOverflow: 'ellipsis' }}
              >
                {scoringStore.awayHome
                  ? scoringStore.gameInfo.teamANameMed
                  : scoringStore.gameInfo.teamHNameMed}
              </Text>
              <Grommet
                theme={{
                  checkBox: {
                    color: '#066d99',
                    size: '16px',
                  },
                }}
              >
                <CheckBox
                  checked={
                    scoringStore.awayHome ? isAwayTimeout : isHomeTimeout
                  }
                  label={
                    <Box alignSelf="center">
                      <Text size="xsmall">Timeout</Text>
                    </Box>
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      const eventInfo = {
                        name: 'timeout',
                        teamNAH: scoringStore.awayHome ? 1 : 2,
                        enable: true,
                      };
                      scoringStore.handleEvent('changeGameState', eventInfo);
                    } else {
                      const eventInfo = {
                        name: 'timeout',
                        enable: false,
                      };
                      scoringStore.handleEvent('changeGameState', eventInfo);
                    }
                  }}
                />
              </Grommet>
            </Box>
            <Box id="right-score-container">
              <Box
                direction="row"
                justify="between"
                gap="small"
                fill="horizontal"
                background="light-1"
                margin={{ bottom: '-1.25rem' }}
                style={{ zIndex: '10' }}
                pad={{ horizontal: 'small', top: 'small' }}
              >
                <Button
                  onClick={() => {
                    scoringStore.handleEvent('toggleServe', {
                      teamClickedNAH: scoringStore.awayHome ? 1 : 2,
                    });
                  }}
                >
                  <Box
                    background={
                      (scoringStore.scoreboard.teamServingNAH === 1 &&
                        scoringStore.awayHome) ||
                      (scoringStore.scoreboard.teamServingNAH === 2 &&
                        !scoringStore.awayHome)
                        ? 'tertiary-1'
                        : 'dark-4'
                    }
                    round="full"
                    width="1.5rem"
                    height="1.5rem"
                    align="center"
                    justify="center"
                  >
                    <MdOutlineSportsVolleyball color="white" />
                  </Box>
                </Button>
                <Text size="large" weight="bolder">
                  {scoringStore.awayHome
                    ? scoringStore.scoreboard.teamAMatchScore
                    : scoringStore.scoreboard.teamHMatchScore}
                </Text>
              </Box>
              <Box
                id="right-score"
                background="light-1"
                round="small"
                justify="center"
              >
                <Box align="center" pad={{ top: '0.85rem', bottom: '0.4rem' }}>
                  <Text size={isSmallScreen ? '2xl' : '3xl'} weight="bold">
                    {scoringStore.awayHome
                      ? scoringStore.scoreboard.a
                      : scoringStore.scoreboard.h}
                  </Text>
                </Box>
              </Box>
              <Box direction="row" gap="small" margin={{ top: 'small' }}>
                <Box
                  basis="1/4"
                  // height={isSmallScreen ? '1.5rem' : '2rem'}
                  height="100%"
                >
                  <Button
                    primary
                    color="primary-7"
                    alignSelf="center"
                    label={<Text weight="bolder">-</Text>}
                    onClick={() => {
                      scoringStore.handleEvent(opposingTeam, { isPlus: false });
                    }}
                    fill
                  />
                </Box>
                <Box
                  basis="3/4"
                  // height={isSmallScreen ? '1.5rem' : '2rem'}
                  height="100%"
                >
                  <Button
                    primary
                    color="primary-7"
                    label={<Text weight="bolder">+</Text>}
                    onClick={() => {
                      if (!scoringStore.scoreboard.isBallInPlay) {
                        if (scoreModalInfo.counter < 3) {
                          setScoreModalInfo({
                            ...scoreModalInfo,
                            show: true,
                            onYes: () => {
                              scoringStore.handleEvent(opposingTeam, {
                                isPlus: true,
                                isManual: true,
                              });

                              closeScoreModal();
                            },
                            onNo: closeScoreModal,
                          });
                        } else {
                          scoringStore.handleEvent(opposingTeam, {
                            isPlus: true,
                            isManual: true,
                          });
                        }
                      } else {
                        scoringStore.handleEvent(opposingTeam, {
                          isPlus: true,
                        });
                      }
                    }}
                    fill
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* row 2 */}
        <Box id="row-2" direction="column" gap="small" fill="horizontal">
          <Box
            className="action-row-1"
            direction="row"
            gap="small"
            justify="between"
            height={{ min: '40px', max: '100%' }}
          >
            <Button
              disabled={
                scoringStore.scoreboard.teamServingNAH !== 0 &&
                (!scoringStore.scoreboard.isBallInPlay ||
                  (scoringStore.scoreboard.isBallInPlay && !weAreServing))
              }
              onClick={() => {
                const stat = 'ace';
                const player = ourLineup[0];
                scoringStore.handleEvent(scoringTeam, {
                  isPlus: true,
                  isManual: false,
                  stat,
                  player,
                });

                setToast({
                  label: `${stat.charAt(0).toUpperCase() + stat.slice(1)}: ${
                    player.nameFirst
                  } ${player.nameLast[0]}.`,
                  background: 'status-ok',
                  icon: <MdOutlineSportsVolleyball />,
                });
              }}
              fill="horizontal"
            >
              <Box
                background="secondary-6"
                height="100%"
                round="small"
                align="center"
                justify="center"
                pad="small"
              >
                <Text weight="bold">+ Ace</Text>
              </Box>
            </Button>
            <Button
              disabled={!scoringStore.scoreboard.isBallInPlay}
              onClick={() => {
                setLoggingPlayerStat({
                  stat: 'kill',
                });
                setToast({
                  label: 'Credit a player above with the kill',
                  background: 'status-ok',
                  icon: <MdOutlineSportsVolleyball />,
                });
                scoringStore.handleEvent(scoringTeam, {
                  isPlus: true,
                  isManual: false,
                  isPendingData: true,
                });
              }}
              fill="horizontal"
            >
              <Box
                background="secondary-6"
                height="100%"
                round="small"
                align="center"
                justify="center"
                pad="small"
              >
                <Text weight="bold">+ Kill</Text>
              </Box>
            </Button>
            <Button
              disabled={!scoringStore.scoreboard.isBallInPlay}
              onClick={() => {
                setLoggingPlayerStat({
                  stat: 'block',
                });
                setToast({
                  label: 'Credit players above with the block',
                  background: 'status-ok',
                  icon: <MdOutlineSportsVolleyball />,
                });
                scoringStore.handleEvent(scoringTeam, {
                  isPlus: true,
                  isManual: false,
                  isPendingData: true,
                });
              }}
              fill="horizontal"
            >
              <Box
                background="secondary-6"
                height="100%"
                round="small"
                align="center"
                justify="center"
                pad="small"
              >
                <Text weight="bold">+ Block</Text>
              </Box>
            </Button>
          </Box>
          <Box
            className="play-tagging"
            direction="row"
            gap="small"
            justify="between"
            height={{ min: '40px', max: '100%' }}
          >
            <Button
              disabled={isInitialState}
              fill="horizontal"
              onClick={() => {
                setLoggingPlayerStat({
                  stat: 'assist',
                });
              }}
            >
              <Box
                background="secondary-6"
                height="100%"
                round="small"
                align="center"
                justify="center"
                pad="small"
              >
                <Text weight="bold">Assist</Text>
              </Box>
            </Button>
            <Button
              disabled={isInitialState}
              fill="horizontal"
              onClick={() => {
                setLoggingPlayerStat({
                  stat: 'dig',
                });
              }}
            >
              <Box
                background="secondary-6"
                height="100%"
                round="small"
                align="center"
                justify="center"
                pad="small"
              >
                <Text weight="bold">Dig</Text>
              </Box>
            </Button>
            <Button
              disabled={isInitialState}
              fill="horizontal"
              onClick={() => {
                setLoggingPlayerStat({
                  stat: 'highlight',
                });
              }}
            >
              <Box
                background="secondary-6"
                height="100%"
                round="small"
                align="center"
                justify="center"
                pad="small"
              >
                <Text weight="bold">Highlight</Text>
              </Box>
            </Button>
          </Box>
        </Box>
        <Box direction="row" gap="small" height={{ min: '40px' }}>
          <Box className="end-set" basis="1/3" height="inherit">
            <Button
              fill
              color="tertiary-1"
              disabled={
                scoringStore.scoreboard.isBallInPlay ||
                isInitialState ||
                loggingPlayerStat
              }
              onClick={() => {
                scoringStore.handleEvent('endSet', {});
              }}
              label={
                <Text alignSelf="center" color="tertiary-1" weight="bold">
                  {`End Set ${currentSet}`}
                </Text>
              }
            />
          </Box>
          <Box basis="2/3" height="inherit">
            <Button
              fill
              className="next-point"
              primary
              disabled={
                scoringStore.scoreboard.isBallInPlay || loggingPlayerStat
              }
              onClick={() => {
                scoringStore.handleEvent('startNextPoint', {});
                setLoggingPlayerStat(false);
              }}
              label={
                <Text alignSelf="center" color="white" weight="bold">
                  Ball Served
                </Text>
              }
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const loader = <BasicLoader fullPage />;

  const positionToIndex = [3, 2, 1, 4, 5, 0].concat(
    ourLineup.slice(6).map((_, i) => i + 6)
  );

  const renderScoringScreen = () => {
    if (scoringStore.isInitialized) {
      const { teamAMatchScore, teamHMatchScore } = getMatchScores(
        scoringStore.scoreboard.teamASetScores,
        scoringStore.scoreboard.teamHSetScores
      );
      const currentSet = teamAMatchScore + teamHMatchScore + 1;

      const hasSubs =
        scoringStore.eventHistory.filter((e) => e.eventType === 'sub').length >
        0;
      const isInitialState =
        scoringStore.scoreboard.a === 0 &&
        scoringStore.scoreboard.h === 0 &&
        scoringStore.scoreboard.isBallInPlay === false &&
        !hasSubs;

      let topText = '';
      if (isInitialState) {
        topText = '';
      } else if (loggingPlayerStat) {
        topText = `Tag ${loggingPlayerStat.stat}`;
      } else if (startedSub) {
        topText = 'Make a sub';
      } else if (!isSmallScreen) {
        topText = scoringStore.scoreboard.isBallInPlay
          ? 'Ball in play'
          : 'Score Point';
      } else {
        topText = '';
      }

      const welcomeContent = (
        <GrommetContainer noMinHeight>
          <Box height={{ min: 'none' }}>
            <Heading level={4}>Welcome to sidelineSCORE! </Heading>
            <Box gap="small">
              <Text>
                Take this quick tour so you can have the most success scoring
                your game and generating automatic highlights for the team
              </Text>
              <Box gap="small" align="center">
                <Text>
                  Tap on the volleyball to indicate who has first serve!
                </Text>
                <Box
                  background="tertiary-1"
                  round="full"
                  width="1.5rem"
                  height="1.5rem"
                  align="center"
                  justify="center"
                >
                  <MdOutlineSportsVolleyball color="white" />
                </Box>
              </Box>
            </Box>
          </Box>
        </GrommetContainer>
      );

      const ballServedContent = (
        <GrommetContainer noMinHeight>
          <Box>
            <Heading level={4}>Clip every point</Heading>
            <Text>
              {`Press "Ball Served" right before the point begins. 
              This ensures proper timing on highlight clips. 
              Similar to pressing "Record" on a video camera, 
              “Ball Served” and a + to increment the score will clip every point of the match! 
              It’s a great place to start for beginners!`}
            </Text>
          </Box>
        </GrommetContainer>
      );

      const scoringContent = (
        <GrommetContainer noMinHeight>
          <Box>
            <Heading level={4}>Scoring & Tagging Actions</Heading>
            <Text>
              Ace, Kill, Block, add a point to the score and prompt for player
              credit.
            </Text>
          </Box>
        </GrommetContainer>
      );

      const taggingContent = (
        <GrommetContainer noMinHeight>
          <Box>
            <Heading level={4}>Tagging more actions</Heading>
            <Box gap="small">
              <Text>
                Assist, Dig and Highlight, can be tagged during or after a
                rally, they <b>DO NOT</b> advance the score.
              </Text>
              <Text>
                {`Highlight is a great option, for tagging a fun rally,
                block or nice swing that didn't score!
                For Highlight you can edit the title of the clip post match right from the play-by-play!`}
              </Text>
            </Box>
          </Box>
        </GrommetContainer>
      );

      const subsContent = (
        <GrommetContainer noMinHeight>
          <Box>
            <Heading level={4}>Sub Players & Set Libero</Heading>
            <Text>
              Sub a player by tapping on the number or placeholder! Designate or
              change a libero via <b>Settings</b> to automate libero swaps on
              and off the court!
            </Text>
          </Box>
        </GrommetContainer>
      );

      const clipsContent = (
        <GrommetContainer noMinHeight>
          <Box>
            <Heading level={4}>In Game Stats + Automatic Highlights</Heading>
            <Box gap="small">
              <Text>
                Use <b>End Set</b>, to trigger on screen stats, sponsor logos
                and more between sets!
              </Text>
              <Text>
                End Set and <b>Exit Scoring</b>, at the conclusion of the match!
                Automatic highlight texts will be on the way to your team.
              </Text>
            </Box>
          </Box>
        </GrommetContainer>
      );

      // const allPlaceholders = ourLineup.slice(0, 6).filter((p) => p.jerseyNum === '?').length === 6;

      const tourSteps = [
        {
          target: '#scoring-container',
          content: welcomeContent,
          disableBeacon: true,
          placement: 'center',
        },
        {
          target: '.next-point',
          content: ballServedContent,
        },
        {
          target: '.action-row-1',
          content: scoringContent,
        },
        {
          target: '.play-tagging',
          content: taggingContent,
        },
        {
          target: '#players-on-court',
          content: subsContent,
          placement: 'top',
        },
        {
          target: '.end-set',
          content: clipsContent,
        },
      ];

      const joyride = (
        <Joyride
          callback={(data) => {
            const { action, status, lifecycle, index } = data;
            const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

            if (finishedStatuses.includes(status) || action === 'close') {
              setRunProductTour(false);
            }

            if (index === 0) {
              return;
            }

            if (lifecycle === 'ready' || lifecycle === 'init') {
              let tourStep = index;
              if (lifecycle === 'init') {
                tourStep += 1;
              }
              analytics.track('VB sScore Product Tour View', {
                tourStep,
              });
            }
          }}
          continuous
          disableScrolling
          locale={{ last: 'Start scoring!' }}
          run={!showExitScoring && runProductTour}
          showProgress
          showSkipButton
          steps={tourSteps}
          styles={{
            options: {
              primaryColor: GROMMETTHEME.global.colors['tertiary-1'],
            },
          }}
        />
      );

      return (
        <Container
          id="scoring-container"
          containerRef={containerRef}
          setShowExitScoring={setShowExitScoring}
          undoClicked={undoClicked}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          setShowQrModal={setShowQrModal}
          isBallInPlay={scoringStore.scoreboard.isBallInPlay}
          isSmallScreen={isSmallScreen}
          streamStats={streamStats}
          setShowStreamStatsModal={setShowStreamStatsModal}
          toggleMuted={() => {
            setMuted(!muted);
            axios.put(`api/team/${scoringStore.teamId}/mute`, {
              muteIsMuted: !muted,
            });
          }}
          muted={muted}
          teamId={scoringStore.teamId}
          gameId={scoringStore.gameId}
        >
          {scoreModalInfo.show &&
            scoreModalInfo.counter < 3 &&
            renderScoreModal()}
          {isInitialState && currentSet === 1 ? joyride : null}
          <Box direction="column" margin={{ horizontal: 'medium' }}>
            <Box
              direction="row"
              gap="small"
              align="center"
              alignSelf="center"
              margin={{ bottom: 'small' }}
              pad="medium"
            >
              {/* {scoringStore.scoreboard.isBallInPlay && (
              <BlinkingCircle isBallInPlay={scoringStore.scoreboard.isBallInPlay} />
              )} */}
              <Text weight="bold" size={isSmallScreen ? 'xlarge' : 'xxl'}>
                {topText}
              </Text>
            </Box>
            <CourtPlayersScore
              players={
                tempRotate
                  ? [ourLineup[5], ...ourLineup.slice(0, 5)].concat(
                      ourLineup.slice(6)
                    )
                  : ourLineup
              }
              loggingPlayerStat={loggingPlayerStat}
              isBallInPlay={scoringStore.scoreboard.isBallInPlay}
              promptLiberoSub={scoringStore.promptLiberoSub}
              onAddStat={(indices) => {
                const { stat } = loggingPlayerStat;
                const lineup = tempRotate
                  ? [ourLineup[5], ...ourLineup.slice(0, 5)].concat(
                      ourLineup.slice(6)
                    )
                  : ourLineup;
                const players = indices.map((i) => lineup[positionToIndex[i]]);

                setLoggingPlayerStat(false);
                scoringStore.handleEvent('addStat', {
                  stat,
                  players,
                });

                const names = players
                  .map((p) => {
                    if (p.nameLast) {
                      return `#${p.jerseyNum} ${p.nameLast}`;
                    }
                    return '';
                  })
                  .join(', ');
                setToast({
                  // label: `${stat.charAt(0).toUpperCase() + stat.slice(1)}: ${player.nameLast}`,
                  label: `${
                    stat.charAt(0).toUpperCase() + stat.slice(1)
                  }: ${names}`,
                  background: 'status-ok',
                  icon: <MdOutlineSportsVolleyball />,
                });
              }}
              onToggleSub={(subbing) => {
                setStartedSub(subbing);
              }}
              onSkip={() => {
                const { stat } = loggingPlayerStat;
                scoringStore.handleEvent('addStat', {
                  stat,
                  player: {
                    _id: scoringStore.awayHome ? 'homePlayer' : 'awayPlayer',
                  },
                });
                setLoggingPlayerStat(false);
              }}
              onCancel={() => {
                if (!['assist', 'dig'].includes(loggingPlayerStat.stat)) {
                  undoClicked();
                }
                setLoggingPlayerStat(false);
              }}
              isSmallScreen={isSmallScreen}
            />
            {/* container */}
            {renderBottomHalf(currentSet)}
          </Box>
        </Container>
      );
    }
    return loader;
  };

  const renderSettingsScreen = () => (
    <Container
      setShowExitScoring={setShowExitScoring}
      undoClicked={undoClicked}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      setShowQrModal={setShowQrModal}
      isSmallScreen={isSmallScreen}
      streamStats={streamStats}
    >
      <VBSettings />
    </Container>
  );

  return (
    <>
      {tabIndex ? renderSettingsScreen() : renderScoringScreen()}
      {showQrModal && (
        <LivestreamLinksPage
          sport="vb"
          teamId={scoringStore.teamId}
          gameId={scoringStore.gameId}
          matchupTitle={scoringStore.gameInfo.matchupTitle}
          onClose={() => {
            setShowQrModal(false);
            analytics.track('sidelineSCORE: Share Modal Closed', {
              sport: 'vb',
              teamId: scoringStore.teamId,
              gameId: scoringStore.gameId,
            });
          }}
        />
      )}
      {showExitScoring && (
        <Layer responsive={false}>
          <Box pad="large" justify="center" gap="medium">
            <Box fill="horizontal" align="end">
              <Text
                color="tertiary-1"
                weight="bold"
                onClick={() => setShowExitScoring(false)}
              >
                Cancel
              </Text>
            </Box>
            <Box align="center" gap="medium">
              <Text size="large" weight="bold">
                Do you want to PAUSE OR END this game?
              </Text>
              <Box direction="row" align="center" gap="small">
                <Button
                  primary
                  size="large"
                  label="END"
                  onClick={() => {
                    setShowExitScoring(false);
                    scoringStore.handleEvent('endGame', {});
                  }}
                  disabled={!scoringStore.isInitialized}
                  color="tertiary-1"
                />
                <Button
                  primary
                  size="large"
                  label="PAUSE"
                  onClick={() => {
                    setShowExitScoring(false);
                    exitScoring();
                  }}
                  disabled={!scoringStore.isInitialized}
                  color="tertiary-1"
                />
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
      {showStreamStatsModal && (
        <Layer
          responsive={false}
          onClickOutside={() => setShowStreamStatsModal(false)}
          onEsc={() => setShowStreamStatsModal(false)}
        >
          <Box gap="small" pad="medium">
            <Text weight="bold">Stream health</Text>
            <Text color="status-critical">
              {!streamStats.isHealthy && streamStats.inAppRecommendText}
            </Text>
            <Text>{streamStats.streamHealthDescription}</Text>
            <br />
            <Text>(Info updates every 30s)</Text>
          </Box>
        </Layer>
      )}
      {toast && allowShowToast && (
        <Toast
          label={toast.label}
          background={toast.background}
          icon={toast.icon}
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
          full="horizontal"
        />
      )}
    </>
  );
});

const VBScoringScreen = compose(withRouter, withFirebase)(ScoringScreenBase);

export default VBScoringScreen;
