/* eslint-disable no-underscore-dangle */

import React, { useContext, useState, useEffect } from 'react';
import { Box, Form, FormField, Heading, Text } from 'grommet';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';

import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import withLayout from '../../helpers/withLayout';
import {
  FieldDate,
  FieldGameTitle,
  FieldOpponentName,
  FieldTime,
} from '../../helpers/FormFields';
import { round5 } from '../../helpers/utils';
import axios from '../../helpers/axios';

import { BasicLoader } from '../../helpers/Loaders';
import {
  BASEBALL,
  BASKETBALL,
  BBSB_SPORTS,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
} from '../../constants/strings';
import CustomSelect from '../../components/CustomSelect';
import { shd } from '@shd/jslib/models';
import { analytics } from '../../services/analytics';

const ScheduleGameBase: React.FC<RouteComponentProps> = ({ history }) => {
  const userStore = useContext(UserContext);
  const [team, setTeam] = useState(
    userStore.selectedTeam || userStore.scoringTeam
  );
  const [error, setError] = useState<string>();
  const [createLoading, toggleCreateLoading] = useState(false);
  const [loading, toggleLoading] = useState(true);

  let hours: string | number = parseInt(new Date().toTimeString().slice(0, 2));
  let minutes: string | number = round5(
    parseInt(new Date().toTimeString().slice(3, 5))
  );
  if (minutes === 60) {
    minutes = 0;
    if (hours === 23) {
      hours = 0;
    } else {
      hours += 1;
    }
  }
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  hours = hours < 10 ? `0${hours}` : `${hours}`;

  const [game, setGame] = useState<Partial<shd.TeamGame>>({
    date: DateTime.now().toFormat('yyyy-MM-dd'),
    time: `${hours}:${minutes}`,
    opponent: '',
    title: '',
    scheduleTsStart: undefined,
  });
  const scheduleGame = () => {
    toggleCreateLoading(true);
    let newGame = { ...game };
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const scheduleTsStart = DateTime.fromISO(`${newGame.date}T${newGame.time}`)
      .setZone(timeZone)
      .toSeconds();
    newGame.scheduleTsStart = scheduleTsStart;

    const scoreGameId = `GmSs01-${uuidv4()}`;

    let sportType: string;
    if (team?.attrib_sportType === VOLLEYBALL.display) {
      sportType = 'vb';
    } else if (BBSB_SPORTS.includes(team?.attrib_sportType ?? '')) {
      sportType = 'bbsb';
    } else {
      sportType = (team?.attrib_sportType ?? '')
        .toLowerCase()
        .replace(' ', '_');
    }

    newGame = {
      ...newGame,
      DNOR_teamId: team?._id,
      teamId: team?._id,
      scoreTeamIdOurs: team?._id,
      scoreGameId,
      _id: `GmSh01-${scoreGameId}`,

      modifiedUser: (userStore.authUser?.claims?.shd_user_id as string) ?? '',

      sportType,

      scheduleTitle: newGame.title,
      scheduleOpponentName: newGame.opponent,
      scheduleState: 10,
      scheduleTsStart,

      scoreGameState: 10,
      scoreHomeIsKnown: true,
      scoreOpponentName: newGame.opponent,
      scoreOurName: team?.nameMed,
    };

    axios
      .post('/api/team_game', {
        teamId: team?._id,
        teamGameId: `GmSh01-${scoreGameId}`,
        teamGameDoc: newGame,
      })
      .then(() => {
        analytics.track('Create sScore Game', {
          gameId: `GmSh01-${scoreGameId}`,
          teamId: team?._id,
          sport: sportType.toLowerCase().replace(' ', '_'),
        });
        toggleCreateLoading(false);
        history.replace('/scoring');
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleCreateLoading(false);
      });
  };

  useEffect(() => {
    if (!team) {
      const scoreTeamId = localStorage.getItem('scoreTeamId') || '';
      const scoreDelegationKey =
        localStorage.getItem('scoreDelegationKey') || '';
      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
        })
        .catch((respError) => {
          if (respError.response && respError.response.status === 403) {
            setError('You are not authorized to create a game.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      toggleLoading(false);
    }
  }, [team]);

  if (loading) {
    return <BasicLoader fullPage />;
  }

  let options: { value: string; label: string }[] = [];
  if (team?.attrib_sportType === BASKETBALL.display) {
    options = [{ value: 'teammate', label: 'Teammate' }];
  } else if (team?.attrib_sportType === SOCCER.display) {
    options = [{ value: '3v3live', label: '3v3 Live' }];
  } else if (team?.attrib_sportType === BASEBALL.display) {
    options = [
      { value: 'perfectgame_bb', label: 'Perfect Game' },
      { value: 'little_league_bb', label: 'Little League' },
      { value: 'babe_ruth_bb', label: 'Babe Ruth League / Cal Ripken' },
      { value: 'usssa_bb', label: 'USSSA' },
      { value: 'pony_bb', label: 'PONY' },
      { value: 'american_legion_bb', label: 'American Legion' },
      { value: 'aau_bb', label: 'AAU' },
    ];
  } else if (team?.attrib_sportType === SOFTBALL.display) {
    options = [
      { value: 'perfectgame_sb', label: 'Perfect Game' },
      { value: 'little_league_sb', label: 'Little League' },
      { value: 'usa_softball_sb', label: 'USA Softball' },
      { value: 'babe_ruth_sb', label: 'Babe Ruth League / Cal Ripken' },
      { value: 'usssa_sb', label: 'USSSA' },
      { value: 'pony_sb', label: 'PONY' },
      { value: 'pgf_bb', label: 'Premier Girls Fastpitch' },
      { value: 'alliance_sb', label: 'Alliance FastPitch' },
      { value: 'top_gun_sb', label: 'Top Gun' },
      { value: 'top_club_sb', label: 'Top Club' },
      { value: 'triple_crown_sb', label: 'Triple Crown' },
    ];
  }
  options.push({ value: 'none', label: 'None' });

  return (
    <Box gap="medium" pad="large" fill="vertical">
      <Heading level="3" size="medium">
        Create a new game
      </Heading>
      {error ? <Text color="status-critical">{error}</Text> : null}
      <Form
        value={game}
        onSubmit={scheduleGame}
        onChange={(nextValue) => {
          setGame(nextValue);
        }}
        style={{ height: '100%' }}
      >
        <Box justify="between" fill="vertical">
          <Box gap="small">
            <FieldOpponentName required />
            <FieldDate defaultDate={game.date} required />
            <FieldTime required />
            <FieldGameTitle />
            {options.length > 0 && (
              <FormField
                label={`${
                  BBSB_SPORTS.includes(team?.attrib_sportType ?? '')
                    ? 'Sanctioning'
                    : 'Tournament'
                } Partner (they’ll get your highlights!)`}
              >
                <CustomSelect
                  placeholder={`Select a ${
                    BBSB_SPORTS.includes(team?.attrib_sportType ?? '')
                      ? 'Sanctioning'
                      : 'Tournament'
                  } Partner`}
                  options={options}
                  onSelectedOption={(optionValue: string) => {
                    if (optionValue) {
                      setGame({ ...game, tournamentName: optionValue });
                    }
                  }}
                />
              </FormField>
            )}
            <Box fill="horizontal" height="240px" />
          </Box>
          <Box
            style={{
              position: 'fixed',
              left: 0,
              bottom: 0,
              width: '100%',
              zIndex: 10,
            }}
            pad={{
              horizontal: 'small',
              top: 'medium',
              bottom: '12px',
            }}
            background="light-3"
            gap="medium"
          >
            <SHDButton
              primary
              size="large"
              label={!createLoading ? 'Create game' : '...Creating'}
              disabled={createLoading || !game.opponent}
              type="submit"
              color="tertiary-1"
            />
            <Box
              pad={{ horizontal: 'large', vertical: 'medium' }}
              justify="center"
              align="center"
              onClick={() => history.replace('/scoring')}
            >
              <Text color="status-error">Cancel</Text>
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

const ScheduleGame = compose<RouteComponentProps, object>(
  withRouter,
  withLayout
)(ScheduleGameBase);

export default ScheduleGame;
