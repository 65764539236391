// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */

import _ from 'lodash';
import * as Sentry from '@sentry/browser';

function EventHistoryParser(
  eventHistory,
  gameInfoImportant,
  startingLineups,
  pitchers,
  promptUser,
  isQuickStartMode
) {
  const gameInfoLegacy = {
    renderSettings: {
      scoreboardIsBasic: false,
      scoreboardIsClock: false,
      scoreboardLocationLrPct: 50,
      scoreboardIsOpqaue: false,
      scoreboardIsField: true,
      scoreboardIsOnGreenScreen: false,
      scoreboardOverlaysToCombine: [],
    },
    scoreboardType: 310,
    text1: '',
    text2: '',
    timeDate: '',
    timeHourMin: '',
  };

  const gameInfo = { ...gameInfoImportant, ...gameInfoLegacy };

  const awayHome = gameInfo.teamHScoreTeamId ? 1 : 0;
  this.pitchers = pitchers;
  this.lineups = startingLineups;
  this.lineupQueueIndexes = [0, 0];
  this.isQuickStartMode = isQuickStartMode;
  this.activeScoreboard = {
    balls: 0,
    strikes: 0,
    outs: 0,
    curOffensePos: [
      this.lineups[0][0] ? this.lineups[0][0].jerseyNum : '',
      '',
      '',
      '',
    ],
    curOffensePosByScorePlayerId: [
      this.lineups[0][0] ? this.lineups[0][0]._id : '',
      '',
      '',
      '',
    ],
    inningNum: 1.0,
    teamHScore: 0,
    teamAScore: 0,
    curDefensePos: [
      '',
      awayHome && this.pitchers[awayHome]
        ? this.pitchers[awayHome].jerseyNum
        : '',
    ], // Only report pitcher if shd team is home and the pitcher exists
    curDefensePosByScorePlayerId: [
      '',
      awayHome && this.pitchers[awayHome] ? this.pitchers[awayHome]._id : '',
    ],
    curBatterQueue: this.lineups[0].map((player) => player.jerseyNum),
    curBatterQueueByScorePlayerId: this.lineups[0].map((player) => player._id),
    curBatterQueueIndex: 0,
    isGameEnd: false,
    pitcherNumPitches: null,
    scoreboardTs: 0,
  };

  this.activePlateAppearance = {
    startTs: 0,
    endTs: 0,
    events: [],
    result: '',
    scorerRating: 0,
    secondaryPlayers: [],
  };

  this.scoreboardList = [];
  this.clipEventsList = [];
  this.plateAppearanceList = [];
  this.eventHistory = _.cloneDeep(eventHistory);

  const outTypes = ['O', 'K', 'SACB', 'SACF', 'FC', 'PK', 'CS'];

  this.isHomeBatting = () => (this.activeScoreboard.inningNum % 1 ? 1 : 0);

  this.isShdTeamBatting = () => {
    if (gameInfo.teamHScoreTeamId && this.isHomeBatting()) {
      return true;
    }
    if (gameInfo.teamAScoreTeamId && !this.isHomeBatting()) {
      return true;
    }
    return false;
  };

  this.recomputeBatterQueue = () => {
    const lineup = this.lineups[this.isHomeBatting()];
    const pastPlateAppearances = this.plateAppearanceList.filter(
      (pa) => pa.inningNum === this.activeScoreboard.inningNum
    );
    const pastBatterQueue = pastPlateAppearances.map((pa) => pa.playerNum);
    const pastBatterQueueId = pastPlateAppearances.map(
      (pa) => pa.scorePlayerId
    );
    const battingTeamLineupQueueIndex =
      this.lineupQueueIndexes[this.isHomeBatting()];
    const futureBatterQueue = lineup.slice(
      battingTeamLineupQueueIndex,
      lineup.length
    );
    const futureBatterQueueJerseyNum = futureBatterQueue.map(
      (player) => player.jerseyNum
    );
    const futureBatterQueueId = futureBatterQueue.map((player) => player._id);

    let curBatterQueue = [...pastBatterQueue, ...futureBatterQueueJerseyNum];
    let curBatterQueueByScorePlayerId = [
      ...pastBatterQueueId,
      ...futureBatterQueueId,
    ];
    // When lineup is complete, repeat it one more time for "infinite lineup scroll"
    if (!this.isQuickStartMode) {
      const extraBatterQueue = this.lineups[0].map(
        (player) => player.jerseyNum
      );
      const extraBatterQueueId = this.lineups[0].map((player) => player._id);
      curBatterQueue = [
        ...pastBatterQueue,
        ...futureBatterQueueJerseyNum,
        ...extraBatterQueue,
      ];
      curBatterQueueByScorePlayerId = [
        ...pastBatterQueueId,
        ...futureBatterQueueId,
        ...extraBatterQueueId,
      ];
    }
    const curBatterQueueIndex = pastBatterQueue.length;
    this.activeScoreboard.curBatterQueue = curBatterQueue;
    this.activeScoreboard.curBatterQueueByScorePlayerId =
      curBatterQueueByScorePlayerId;
    this.activeScoreboard.curBatterQueueIndex = curBatterQueueIndex;
    [this.activeScoreboard.curOffensePos[0]] = futureBatterQueueJerseyNum;
    this.activeScoreboard.curOffensePosByScorePlayerId[0] =
      futureBatterQueueId[0] || '';
  };

  this.endPlateAppearance = (isForLastHalfInning = false) => {
    let paInningNum = this.activeScoreboard.inningNum;
    let isShdTeamBatting = !!this.isShdTeamBatting();
    let isHomeBatting = this.isHomeBatting();

    // handle corner case where an inning ending out is not last touch in pa but the pa ended inning
    if (isForLastHalfInning) {
      paInningNum -= 0.5;
      isShdTeamBatting = !isShdTeamBatting;
      isHomeBatting = isHomeBatting ? 0 : 1;
    }
    const isHomePitching = isHomeBatting ? 0 : 1;

    const lineupIndex = isShdTeamBatting
      ? this.lineupQueueIndexes[isHomeBatting]
      : null;
    const currentHitter = isShdTeamBatting
      ? this.lineups[isHomeBatting][lineupIndex]
      : { jerseyNum: '', _id: '' };
    const currentPitcher = !isShdTeamBatting
      ? this.pitchers[isHomePitching]
      : { jerseyNum: '', _id: '' };

    const scoreTeamId = isHomeBatting
      ? gameInfo.teamHScoreTeamId
      : gameInfo.teamAScoreTeamId;
    const awayHomeIndex = isHomeBatting;

    const newPlateAppearance = {
      scoreGameId: gameInfo.scoreGameId,
      inningNum: paInningNum,
      startTs: this.activePlateAppearance.startTs,
      endTs: this.activePlateAppearance.endTs,
      scorerRating: this.activePlateAppearance.scorerRating,
      scoreTeamId,
      scorePlayerId: currentHitter ? currentHitter._id : '',
      scorePlayerOffenseList: isShdTeamBatting
        ? [
            currentHitter ? currentHitter._id : '',
            ...this.activePlateAppearance.secondaryPlayers.map(
              (player) => player._id
            ),
          ]
        : [],
      scorePlayerDefenseList: !isShdTeamBatting
        ? [
            currentPitcher._id,
            ...this.activePlateAppearance.secondaryPlayers.map(
              (player) => player._id
            ),
          ]
        : [],
      playerNum: currentHitter ? currentHitter.jerseyNum : '',
      lineupIndex,
      awayHomeIndex,
    };
    this.activePlateAppearance.startTs = this.activePlateAppearance.endTs;

    const paEventList = [];
    this.activePlateAppearance.events.forEach((event) => {
      const newEvent = {
        ts: event.ts,
        pitcher: {
          scorePlayerId: currentPitcher ? currentPitcher._id : '',
          playerNum: currentPitcher ? currentPitcher.jerseyNum : '',
        },
        result: '',
      };
      if (event.eventType === 'strike') {
        newEvent.result = 'S';
      } else if (event.eventType === 'ball') {
        newEvent.result = 'B';
      } else if (event.eventType === 'foul') {
        newEvent.result = 'F';
      }
      paEventList.push(newEvent);
    });

    const resultPitch = {
      ts: this.activePlateAppearance.endTs,
      pitcher: {
        scorePlayerId: currentPitcher ? currentPitcher._id : '',
        playerNum: currentPitcher ? currentPitcher.jerseyNum : '',
      },
      result: this.activePlateAppearance.result,
    };

    if (
      ['BB', 'K'].includes(this.activePlateAppearance.result) &&
      paEventList.length
    ) {
      // 3rd strike and 4th ball should have the PA result
      paEventList[paEventList.length - 1] = resultPitch;
    } else {
      paEventList.push(resultPitch);
    }
    newPlateAppearance.pitches = paEventList;
    this.plateAppearanceList.push(newPlateAppearance);

    // reset active PA attributes
    this.activePlateAppearance.events = [];
    this.activePlateAppearance.secondaryPlayers = [];
    this.activePlateAppearance.scorerRating = 0;
  };

  this.advanceBatter = (isForLastHalfInning = false) => {
    let isHomeBatting = this.isHomeBatting();

    // handle corner case where an inning ending out is not last touch in pa but the pa ended inning
    if (isForLastHalfInning) {
      isHomeBatting = isHomeBatting ? 0 : 1;
    }

    this.activeScoreboard.balls = 0;
    this.activeScoreboard.strikes = 0;
    const lineup = this.lineups[isHomeBatting];

    if (
      this.lineupQueueIndexes[isHomeBatting] + 1 < lineup.length ||
      this.isQuickStartMode
    ) {
      this.lineupQueueIndexes[isHomeBatting] += 1;
    } else {
      // TOP OF THE ORDER
      this.lineupQueueIndexes[isHomeBatting] = 0;
    }
    this.recomputeBatterQueue();
  };

  this.handleBB = () => {
    const b1 = !!this.activeScoreboard.curOffensePos[1];
    const b2 = !!this.activeScoreboard.curOffensePos[2];

    if (b1 && b2) {
      this.activeScoreboard.curOffensePos[3] = '-1';
    } else if (b1) {
      this.activeScoreboard.curOffensePos[2] = '-1';
    } else if (!b1) {
      this.activeScoreboard.curOffensePos[1] = '-1';
    }
  };

  this.handleResult = (result) => {
    if (['1B', 'BB', 'HBP', 'ERR'].includes(result)) {
      this.handleBB();
    } else if (result === '2B') {
      this.handleBB();
      this.handleBB();

      // remove person from 1st base
      this.activeScoreboard.curOffensePos[1] = '';
      this.activeScoreboard.curOffensePosByScorePlayerId[1] = '';
    } else if (result === '3B') {
      // clear bases
      for (let i = 1; i < 3; i += 1) {
        this.activeScoreboard.curOffensePos[i] = '';
        this.activeScoreboard.curOffensePosByScorePlayerId[i] = '';
      }

      // put person on third
      this.activeScoreboard.curOffensePos[3] = '-1';
      this.activeScoreboard.curOffensePosByScorePlayerId[3] = '';
    } else if (result === 'HR') {
      for (let i = 1; i < 4; i += 1) {
        this.activeScoreboard.curOffensePos[i] = '';
        this.activeScoreboard.curOffensePosByScorePlayerId[i] = '';
      }
    } else if (result === 'DP') {
      this.handleOut();
      this.handleOut();
    } else if (result === 'TP') {
      this.handleOut();
      this.handleOut();
      this.handleOut();
    }
  };

  this.handleOut = () => {
    if (this.activeScoreboard.outs === 2) {
      this.flipInning();
    } else {
      this.activeScoreboard.outs += 1;
    }
  };

  this.recomputeDefensePos = () => {
    const newPitcher = this.pitchers[this.isHomeBatting() ? 0 : 1] || {
      _id: '',
      jerseyNum: '',
    };
    this.activeScoreboard.curDefensePos[1] = newPitcher.jerseyNum;
    this.activeScoreboard.curDefensePosByScorePlayerId[1] = newPitcher._id;
  };

  this.flipInning = (newInningNum) => {
    if (newInningNum) {
      this.activeScoreboard.inningNum = newInningNum;
      this.activePlateAppearance.events = [];
    } else {
      this.activeScoreboard.inningNum += 0.5;
    }
    this.activeScoreboard.outs = 0;
    this.activeScoreboard.balls = 0;
    this.activeScoreboard.strikes = 0;
    this.activeScoreboard.curOffensePos[1] = '';
    this.activeScoreboard.curOffensePos[2] = '';
    this.activeScoreboard.curOffensePos[3] = '';

    this.recomputeDefensePos();
    this.recomputeBatterQueue();
  };

  this.undoEvent = () => {
    const lastEvent = _.last(this.eventHistory);
    this.eventHistory.splice(-1, 1);
    if (
      ['resultPromptResponse', 'wipePlateAppearance'].includes(
        lastEvent.eventType
      )
    ) {
      // Users do not perceive these events as events
      this.eventHistory.splice(-1, 1);
    } else if (
      lastEvent.eventType === 'sub' &&
      lastEvent.eventInfo.index === -1 &&
      this.isQuickStartMode &&
      lastEvent.eventInfo.isQuickStartModePrompt &&
      !lastEvent.eventInfo.isOnDeck
    ) {
      this.undoEvent();
    } else if (lastEvent.eventType === 'forceRun') {
      this.undoEvent();
    }
  };

  this.makeClipEvent = (event) => {
    let scorePlayerOffenseList = [];
    let scorePlayerDefenseList = [];
    const playerIds = event.eventExtraInfo.secondaryPlayers.map((p) => p._id);
    const isOffense = this.isShdTeamBatting();
    if (isOffense) {
      scorePlayerOffenseList = playerIds;
    } else {
      scorePlayerDefenseList = ['', ...playerIds];
    }

    let { startTs } = this.activePlateAppearance;
    if (this.activePlateAppearance.events.length > 0) {
      const lastPAEvent = _.last(this.activePlateAppearance.events);
      startTs = lastPAEvent.ts;
    }
    const clipEvent = {
      _sport: 'bbsb',
      _ver: 100,
      // ### clipEvent awayHomeIndex IDs the DEFENDER.
      //  In this case the away team is the defender,
      // which makes sesne as we are in the bot of the inning
      awayHomeIndex: awayHome,
      description: '',
      endTs: event.ts,
      inningNum: this.activeScoreboard.inningNum,
      isOffenseOutcome: isOffense,
      scoreGameId: gameInfo.scoreGameId,
      scorePlayerDefenseList,
      scorePlayerOffenseList,
      scoreResult: event.eventInfo,
      scorerRating: 0,
      startTs,
    };

    this.clipEventsList.push(clipEvent);

    if (outTypes.includes(event.eventInfo)) {
      // TODO: prompt for this inning ending-out
      this.handleOut();
    }
  };

  this.parseEventHistory = () => {
    const promptPromise = new Promise((resolve) => {
      const lastEvent = _.last(this.eventHistory);
      if (lastEvent.eventType === 'undo' && this.eventHistory.length > 2) {
        this.eventHistory.splice(-1, 1); // remove "undo" event
        this.undoEvent();
        this.generateGameInfo();
        resolve(true);
        return;
      }

      this.generateGameInfo();

      let onPromptResponse = ({ resp }) => {
        if (resp) {
          return resolve(true);
        }
        this.eventHistory.splice(-1, 1);
        return resolve(false);
      };

      if (lastEvent.eventType === 'home' && !this.isHomeBatting()) {
        const text =
          "Home team isn't batting. Are you sure you want to change their score?";
        const type = 'scoreChange';
        promptUser(text, '', type, onPromptResponse);
        return;
      }

      if (lastEvent.eventType === 'away' && this.isHomeBatting()) {
        const text =
          "Away team isn't batting. Are you sure you want to change their score?";
        const type = 'scoreChange';
        promptUser(text, '', type, onPromptResponse);
        return;
      }

      if (
        lastEvent.eventType === 'strike' &&
        this.activeScoreboard.outs === 0 &&
        this.activeScoreboard.strikes === 0 &&
        lastEvent.eventInfo.isPlus
      ) {
        const text = 'End half inning?';
        const type = 'endInningStrikeout';
        onPromptResponse = ({ resp }) => {
          if (resp) {
            return resolve(true);
          }
          this.eventHistory.splice(-1, 1);
          return resolve(false);
        };
        promptUser(text, '', type, onPromptResponse);
        return;
      }

      // this.activeScoreboard.outs === 0 because the inning has already advanced?
      if (
        lastEvent.eventType === 'out' &&
        lastEvent.eventInfo.isPlus &&
        this.activeScoreboard.outs === 0
      ) {
        const text = 'End half inning?';
        const type = 'endInning';
        onPromptResponse = ({ resp, result }) => {
          if (resp) {
            // Inning has ended
            if (result) {
              // Last PA has ended
              const newEvent = {
                ts: lastEvent.ts,
                eventType: 'resultPromptResponse',
                eventInfo: result,
                isForLastHalfInning: true,
              };
              this.eventHistory = [...this.eventHistory, newEvent];

              this.activePlateAppearance.result = result;
              this.activePlateAppearance.endTs = lastEvent.ts;
              this.endPlateAppearance(true);
              this.advanceBatter(true);
              this.scoreboardList.push({
                ..._.cloneDeep(this.activeScoreboard),
                ...gameInfo,
              });
            } else {
              // wipe activePA clean
              const newEvent = {
                ts: lastEvent.ts,
                eventType: 'wipePlateAppearance',
                eventInfo: {},
              };
              this.eventHistory = [...this.eventHistory, newEvent];
              this.activePlateAppearance.events = [];
            }
            return resolve(true);
          }
          this.eventHistory.splice(-1, 1);
          return resolve(false);
        };

        // Switch awayHome index because game state is next half inning already
        const currentBatter =
          this.lineups[this.isHomeBatting() ? 0 : 1][
            this.lineupQueueIndexes[this.isHomeBatting() ? 0 : 1]
          ];
        const lastBatterDisplayName =
          currentBatter && currentBatter.displayName
            ? currentBatter.displayName
            : '';
        promptUser(text, '', type, onPromptResponse, lastBatterDisplayName);
        return;
      }

      // IDK why i have to do this weird hack to set onPromptResponse
      if (
        lastEvent.eventType === 'nextBatter' ||
        lastEvent.eventType === 'runnerOutcome'
      ) {
        onPromptResponse = ({ result, ...rest }) => {
          if (lastEvent.eventType === 'nextBatter') {
            this.eventHistory = [
              ...this.eventHistory,
              {
                ts: lastEvent.ts,
                eventType: 'resultPromptResponse',
                eventInfo: result,
                eventExtraInfo: {
                  scorerRating: rest.scorerRating ? rest.scorerRating : 0,
                  secondaryPlayers: rest.secondaryPlayers
                    ? rest.secondaryPlayers
                    : [],
                },
              },
            ];

            this.activePlateAppearance.result = result;
            this.activePlateAppearance.endTs = lastEvent.ts;
            this.activePlateAppearance.scorerRating = rest.scorerRating;
            this.activePlateAppearance.secondaryPlayers =
              rest.secondaryPlayers || [];
            this.endPlateAppearance();
            this.advanceBatter();
            if (outTypes.includes(result)) {
              // TODO: prompt for this inning ending-out
              this.handleOut();
            }

            this.handleResult(result);

            this.scoreboardList.push({
              ..._.cloneDeep(this.activeScoreboard),
              ...gameInfo,
            });
          } else {
            const newEvent = {
              ts: lastEvent.ts,
              eventType: 'resultPromptResponse',
              eventInfo: result,
              eventExtraInfo: {
                scorerRating: rest.scorerRating ? rest.scorerRating : 0,
                secondaryPlayers: rest.secondaryPlayers
                  ? rest.secondaryPlayers
                  : [],
              },
            };
            this.eventHistory = [...this.eventHistory, newEvent];

            this.makeClipEvent(newEvent);

            this.scoreboardList.push({
              ..._.cloneDeep(this.activeScoreboard),
              ...gameInfo,
            });
          }

          return resolve(true);
        };
      }
      if (lastEvent.eventType === 'nextBatter') {
        let displayName = 'the last batter';
        if (this.isShdTeamBatting()) {
          const currentBatter =
            this.lineups[this.isHomeBatting()][
              this.lineupQueueIndexes[this.isHomeBatting()]
            ];
          displayName =
            currentBatter && currentBatter.displayName
              ? currentBatter.displayName
              : 'the last batter';
        }
        const text = `What happened to ${displayName}?`;
        const subtext = `Great ${
          this.isShdTeamBatting() ? 'baserunning' : 'fielding'
        }? Tap and hold to give credit.`;
        const type = 'result';

        promptUser(text, subtext, type, onPromptResponse);
        return;
      }

      if (lastEvent.eventType === 'runnerOutcome') {
        const text = 'What happened to the baserunner?';
        const subtext = '';
        const type = 'runnerOutcome';

        promptUser(text, subtext, type, onPromptResponse);
        return;
      }

      if (lastEvent.eventType === 'forceRun') {
        resolve(true);
        return;
      }

      resolve(true);
    })
      .then((resp) => {
        if (resp) {
          const latestScoreboard = _.last(this.scoreboardList);
          return {
            scoreboardList: this.scoreboardList,
            clipEventsList: this.clipEventsList,
            latestScoreboard,
            plateAppearanceList: this.plateAppearanceList,
            lineups: this.lineups,
            pitchers: this.pitchers,
            lineupQueueIndexes: this.lineupQueueIndexes,
            eventHistory: this.eventHistory,
            isQuickStartMode: this.isQuickStartMode,
          };
        }
        return { eventHistory: this.eventHistory };
      })
      .catch((parseErr) => {
        console.log('parse error', parseErr);
        Sentry.captureException(parseErr);
      });

    return promptPromise;
  };

  this.generateGameInfo = () => {
    this.eventHistory.forEach((event) => {
      this.activeScoreboard.scoreboardTs = event.ts;
      if (
        event.eventInfo.isPlus &&
        ['strike', 'ball', 'foul'].includes(event.eventType)
      ) {
        // Add event to PA if event is an actual pitch
        this.activePlateAppearance.events.push(event);
      }
      if (event.eventType === 'ball') {
        if (event.eventInfo.isPlus && this.activeScoreboard.balls === 3) {
          this.activeScoreboard.balls = 0;
          this.activeScoreboard.strikes = 0;
          this.handleBB();
          this.activePlateAppearance.result = 'BB';
          this.activePlateAppearance.endTs = event.ts;
          this.endPlateAppearance();
          this.advanceBatter();
        } else if (event.eventInfo.isPlus) {
          this.activeScoreboard.balls += 1;
        } else if (!event.eventInfo.isPlus && this.activeScoreboard.balls > 0) {
          this.activeScoreboard.balls -= 1;
        }
      } else if (event.eventType === 'strike') {
        if (event.eventInfo.isPlus && this.activeScoreboard.strikes === 2) {
          this.activeScoreboard.balls = 0;
          this.activeScoreboard.strikes = 0;
          this.activePlateAppearance.result = 'K';
          this.activePlateAppearance.endTs = event.ts;
          this.endPlateAppearance();
          this.advanceBatter();
          this.handleOut();
        } else if (event.eventInfo.isPlus) {
          this.activeScoreboard.strikes += 1;
        } else if (
          !event.eventInfo.isPlus &&
          this.activeScoreboard.strikes > 0
        ) {
          this.activeScoreboard.strikes -= 1;
        }
      } else if (
        event.eventType === 'foul' &&
        this.activeScoreboard.strikes < 2
      ) {
        this.activeScoreboard.strikes += 1;
      } else if (event.eventType === 'out') {
        if (event.eventInfo.isPlus) {
          this.handleOut();
        } else {
          this.activeScoreboard.outs -= 1;
        }
      } else if (event.eventType === 'base') {
        this.activeScoreboard.curOffensePos[event.eventInfo.base] = event
          .eventInfo.isPlus
          ? '-1'
          : '';
      } else if (event.eventType === 'home') {
        if (event.eventInfo.isPlus) {
          this.activeScoreboard.teamHScore += 1;
        } else if (
          !event.eventInfo.isPlus &&
          this.activeScoreboard.teamHScore > 0
        ) {
          this.activeScoreboard.teamHScore -= 1;
        }
      } else if (event.eventType === 'away') {
        if (event.eventInfo.isPlus) {
          this.activeScoreboard.teamAScore += 1;
        } else if (
          !event.eventInfo.isPlus &&
          this.activeScoreboard.teamAScore > 0
        ) {
          this.activeScoreboard.teamAScore -= 1;
        }
      } else if (event.eventType === 'startGame') {
        this.activePlateAppearance.startTs = event.ts;
      } else if (event.eventType === 'endGame') {
        this.activeScoreboard.isGameEnd = true;
      } else if (event.eventType === 'lineupIndex') {
        const newIndex = event.eventInfo;
        const isShdTeamHome = gameInfo.teamHScoreTeamId ? 1 : 0;
        this.lineupQueueIndexes[isShdTeamHome] = newIndex;
        this.recomputeBatterQueue();
      } else if (event.eventType === 'sub') {
        const subIndex = event.eventInfo.index;
        const subPlayer = event.eventInfo.newPlayer;
        const isShdTeamHome = gameInfo.teamHScoreTeamId ? 1 : 0;
        const newShdLineup = [...this.lineups[isShdTeamHome]];

        if (subPlayer && subIndex >= 0) {
          newShdLineup[subIndex] = subPlayer;
        } else if (!subPlayer) {
          // Remove without replacement
          newShdLineup.splice(subIndex, 1);
          if (
            this.lineupQueueIndexes[isShdTeamHome] + 1 >=
            newShdLineup.length
          ) {
            this.lineupQueueIndexes[isShdTeamHome] = newShdLineup.length - 1;
          }
        } else if (subIndex === -1) {
          // Append to end of lineup
          newShdLineup.push(subPlayer);
        }
        this.lineups[isShdTeamHome] = newShdLineup;
        this.recomputeBatterQueue();
      } else if (event.eventType === 'pitchingChange') {
        const isShdTeamHome = gameInfo.teamHScoreTeamId ? 1 : 0;
        this.pitchers[isShdTeamHome] = event.eventInfo;
        this.recomputeDefensePos();
      } else if (event.eventType === 'setLineup') {
        const { lineup, index } = event.eventInfo;
        const isShdTeamHome = gameInfo.teamHScoreTeamId ? 1 : 0;
        this.lineups[isShdTeamHome] = lineup;
        this.lineupQueueIndexes[isShdTeamHome] = index;
        this.recomputeBatterQueue();
      } else if (event.eventType === 'wrapLineup') {
        this.isQuickStartMode = false;
        const isShdTeamHome = gameInfo.teamHScoreTeamId ? 1 : 0;
        if (
          this.lineups[isShdTeamHome].length <=
          this.lineupQueueIndexes[isShdTeamHome]
        ) {
          this.lineupQueueIndexes[isShdTeamHome] = 0;
        }
        this.recomputeBatterQueue();
      } else if (event.eventType === 'resultPromptResponse') {
        if (event.eventInfo) {
          this.activePlateAppearance.result = event.eventInfo;
          if (event.eventExtraInfo) {
            this.activePlateAppearance.scorerRating =
              event.eventExtraInfo.scorerRating || 0;
            // eslint-disable-next-line max-len
            this.activePlateAppearance.secondaryPlayers =
              event.eventExtraInfo.secondaryPlayers || [];
          }

          if (['CS', 'PK', 'SB'].includes(event.eventInfo)) {
            this.makeClipEvent(event);
          } else {
            this.activePlateAppearance.endTs = event.ts;
            this.endPlateAppearance(event.isForLastHalfInning);
            this.advanceBatter(event.isForLastHalfInning);

            if (
              outTypes.includes(event.eventInfo) &&
              !event.isForLastHalfInning
            ) {
              this.handleOut();
            }
          }
          this.handleResult(event.eventInfo);
        } else {
          this.activePlateAppearance.events = [];
        }
      } else if (event.eventType === 'wipePlateAppearance') {
        this.activePlateAppearance.events = [];
      } else if (event.eventType === 'changeInning') {
        const newInningNum = event.eventInfo / 2 + 1; // index to float notation
        this.flipInning(newInningNum);
      }
      this.scoreboardList.push({
        ..._.cloneDeep(this.activeScoreboard),
        ...gameInfo,
      });
    });
  };
}

// define setter and getter methods for the property name
Object.defineProperty(EventHistoryParser.prototype, 'lineups', {
  set(val) {
    // save the value to the cache variable
    this._lineups = val;
    // run_listener_function_here()
    // alert(`New value: ${val}`);
  },
  get() {
    // return value from the cache variable
    return this._lineups;
  },
});

export default EventHistoryParser;
