// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import { Button, Box } from 'grommet';

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${({ color }) => color};
  color: #fff;
  font-weight: 700;
  font-size: 16px;
`;

export const ButtonGroup = styled(Box)`
  margin-bottom: 40px;
`;

export const ManualButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  height: 48px;
  color: ${({ theme }) => theme.global.colors['secondary-1']};
`;
