import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Link } from 'react-router-dom';
import { GameStreamsProps } from './games.types';
import { colWidthMinMax } from './games.style';
import PrivacyAwareYTLink from '../../../components/PrivacyAwareYTLink';

const GameStreams: React.FC<GameStreamsProps> = ({
  teamId,
  gameId,
  fb,
  yt,
  recap,
}) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      margin={{ bottom: '.5rem' }}
    >
      <Box direction="column" width={colWidthMinMax}>
        {fb && (
          <Button
            href={fb?.cdnUrl}
            fill
            label={
              <Text
                size="small"
                weight={'bold'}
                color={'tertiary-1'}
                textAlign="center"
              >
                Facebook
              </Text>
            }
          />
        )}
      </Box>
      <Box direction="column" width={colWidthMinMax}>
        {recap && (
          <Link to={`/game/${gameId}/recap`}>
            <Button
              fill
              label={
                <Text size="small" weight={'bold'} color={'tertiary-1'}>
                  Recap
                </Text>
              }
            />
          </Link>
        )}
      </Box>
      <Box direction="column" width={colWidthMinMax}>
        {yt && (
          <PrivacyAwareYTLink
            key={gameId}
            teamId={teamId}
            gameId={gameId}
            ytAsset={yt || { cdnUrl: '' }}
          >
            <Button
              fill
              label={
                <Text size="small" weight={'bold'} color={'tertiary-1'}>
                  YouTube
                </Text>
              }
            />
          </PrivacyAwareYTLink>
        )}
      </Box>
    </Box>
  );
};

export default GameStreams;
