import { shd } from '@shd/jslib/models';

export enum ImageCategory {
  LOGO = '100',
  COVER = '102',
}

export enum TeamType {
  CLUB = 'Club team',
  SCHOOL = 'School team',
  REC = 'Rec team',
  TRAVEL = 'Travel team',
}

export interface TeamActionButtonsProps {
  teamId: string | undefined;
  onUploadImage: (category: string, url: string) => void;
  setTeamLogoUploading: (uploading: boolean) => void;
}

export interface TeamInfoFormProps {
  selectedTeam: (shd.Team & { teamType?: string }) | null;
  onFormUpdate: (updatedData: FormValues) => void;
}
export interface FormValues {
  nameLong: string;
  nameHandle?: string;
  nameMed?: string;
  zipCode?: string;
  attrib_ageU?: number;
  attrib_season?: number;
  attrib_sportType?: string;
  attrib_ageLevel?: string;
  socialFacebookUrl?: string;
  socialInstagramUrl?: string;
  socialXUrl?: string;
  colorTeamPrimary?: string;
  teamType?: string;
}

export const defaultFormValues: FormValues = {
  nameLong: '',
  nameHandle: '',
  nameMed: '',
  attrib_sportType: '',
  zipCode: '',
  socialFacebookUrl: '',
};
