// Packages
import React from 'react';
import styles from '../Admin.module.css';

// Types
import { AdminAvatarProps } from '../admin.types';

// Components
import { Box, Image } from 'grommet';
import { User } from 'grommet-icons';

const AdminAvatar: React.FC<AdminAvatarProps> = ({ src }) => {
  return (
    <Box background="brand" className={styles['avatar']}>
      {src ? <Image fit="cover" src={src} /> : <User />}
    </Box>
  );
};

export default AdminAvatar;
