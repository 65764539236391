import { useContext, useEffect } from 'react';
import { UserContext } from '../services/Session';
import { ResponsiveContext } from 'grommet';

interface Props {
  show?: boolean;
}
const useZendeskWidget = ({ show = true }: Props = {}) => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);
  const teamId = userStore.selectedTeam?._id || '';

  const showWidget = () => {
    if (window.zE) {
      window.zE('messenger', 'show');
    }
  };

  const hideWidget = () => {
    if (window.zE) {
      window.zE('messenger', 'hide');
    }
  };

  const setConversationFields = (
    conversationFields: Record<string, string>[]
  ) => {
    if (window.zE) {
      window.zE('messenger:set', 'conversationFields', conversationFields);
    }
  };

  useEffect(() => {
    const teamIds = userStore.userTeams.map((userTeam) => userTeam._id);
    const isAdmin = teamIds.includes(teamId);

    if (isAdmin && screenSize !== 'small' && show) {
      showWidget();
    } else {
      hideWidget();
    }

    return () => {
      hideWidget();
    };
  }, [userStore, screenSize, teamId, show]);

  return {
    showWidget,
    hideWidget,
    setConversationFields,
  };
};

export default useZendeskWidget;
