// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Text } from 'grommet';
import React, { useState, useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Clone } from 'grommet-icons';

import { UserContext } from '../../services/Session/context';
import { scoringTutorialSteps } from './ScoringTutorial';
import axios from '../../helpers/axios';
import Toast from '../../components/Toast';
import SHDButton from '../../components/SHD/Button';
import { analytics } from '../../services/analytics';

const ScoringImport = ({ onComplete }) => {
  const userStore = useContext(UserContext);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);

  const checkStatus = (onNoGames, onError) => {
    axios
      .get(`/api/iscore_import/${userStore.selectedTeam._id}`)
      .then((response) => {
        if (response.data.games && response.data.games.length) {
          onComplete(response.data.scoreTeamId);
        } else {
          onNoGames();
        }

        analytics.track('iScore Linked', {
          teamId: userStore.selectedTeam._id,
          how: 'transfer',
        });
      })
      .catch(onError);
  };
  const advanceStep = () => {
    if (step === 3) {
      // check if there is already a game for score team id
      // if so, avoid asking user to schedule game
      checkStatus(
        () => {
          setError(null);
          setStep((prevStep) => prevStep + 1);
        },
        () => {}
      );
    } else if (step === 5) {
      // score team id should have a game by now
      // be strict if they don't
      checkStatus(
        () => {
          setStep(3);
          setError(
            'We could not find your team. Please confirm you imported your team, iScorecast is ON, and your team has at least one game.'
          );
        },
        (respError) => {
          setError(respError.toString());
        }
      );
    } else {
      setError(null);
      setStep((prevStep) => prevStep + 1);
    }
    window.scrollTo(0, 0);
  };
  return (
    <Box pad={{ left: 'medium', right: 'medium' }}>
      {error && <Text color="status-critical">{error}</Text>}
      {step === 3 && (
        <Box gap="large" margin="large">
          <Box gap="small">
            <Text weight="bold">Team handle</Text>
            <Box direction="row" align="center" justify="between">
              <Text>{userStore.selectedTeam.nameHandle}</Text>
              <CopyToClipboard
                text={userStore.selectedTeam.nameHandle}
                onCopy={() => setCopied(true)}
              >
                <SHDButton icon={<Clone color="black" />} hoverIndicator />
              </CopyToClipboard>
            </Box>
          </Box>
          <Box gap="small">
            <Text weight="bold">Team PIN</Text>
            <Box direction="row" align="center" justify="between">
              <Text>{userStore.selectedTeam.pin}</Text>
              <CopyToClipboard
                text={userStore.selectedTeam.pin}
                onCopy={() => setCopied(true)}
              >
                <SHDButton icon={<Clone color="black" />} hoverIndicator />
              </CopyToClipboard>
            </Box>
          </Box>
        </Box>
      )}
      <Box overflow="scroll">{scoringTutorialSteps[step]}</Box>
      <Box
        direction="row"
        justify="between"
        height={{ min: 'xsmall' }}
        style={{ marginBottom: 120 }}
      >
        <SHDButton
          label="Back"
          onClick={() => setStep(step - 1)}
          margin="auto"
          size="large"
          style={{ visibility: step === 0 && 'hidden' }}
        />
        <SHDButton
          primary
          label="Next"
          onClick={advanceStep}
          margin="auto"
          size="large"
        />
      </Box>
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};

export default ScoringImport;
