import React, { useContext, useState } from 'react';
import { Avatar, Box, Button, Layer, Text } from 'grommet';
import { UserContext } from '../../services/Session';
import { Checkmark } from 'grommet-icons';
import { SPORTS_MAP } from '../../constants/strings';

interface TeamModalProps {
  onClose: () => void;
}
const TeamModal: React.FC<TeamModalProps> = ({ onClose }) => {
  const userStore = useContext(UserContext);

  // sort userTeams so that selectedTeam is first, then order by name alphabetically
  const sortedUserTeams = [...userStore.userTeams]
    .sort((a, b) => a.nameMed.localeCompare(b.nameMed))
    .sort((a) => {
      if (a._id === userStore.selectedTeam?._id) {
        return -1;
      }
      return 1;
    });
  return (
    <Layer
      modal
      responsive={false}
      position="bottom"
      onClickOutside={onClose}
      onEsc={onClose}
      full="horizontal"
      background="transparent"
    >
      <Box
        round={{ corner: 'top', size: 'medium' }}
        background="white"
        height={{ max: '80vh' }}
      >
        <Box
          pad="16px"
          direction="row"
          justify="between"
          align="center"
          flex={{ grow: 1, shrink: 0 }}
        >
          <Text weight="bold">Select team to manage</Text>
          <Button onClick={onClose} secondary size="small" label="Cancel" />
        </Box>
        <Box overflow="auto">
          {sortedUserTeams.map((team) => (
            <Box
              key={team._id}
              pad={{ horizontal: '16px', vertical: '8px' }}
              align="center"
              onClick={() => {
                const teamIds = userStore.userTeams.map(
                  (userTeam) => userTeam._id
                );
                const index = teamIds.indexOf(team._id);
                userStore.setSelectedTeam(userStore.userTeams[index]);
                localStorage.setItem('selectedTeamIndex', `${index}`);
                onClose();
                window.location.reload();
              }}
              direction="row"
              flex={{ grow: 1, shrink: 0 }}
              justify="between"
            >
              <Box direction="row" align="center" gap="16px">
                <Avatar background={team.colorTeamPrimary} />
                <Text weight="bold">{team.nameMed}</Text>
                {SPORTS_MAP[team.attrib_sportType].icon}
              </Box>
              {userStore.selectedTeam?._id === team._id && (
                <Box>
                  <Checkmark />
                </Box>
              )}
            </Box>
          ))}
          <Box height="16px" />
        </Box>
      </Box>
    </Layer>
  );
};

const TeamSwitcher: React.FC = () => {
  const { selectedTeam } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: '16px', vertical: '8px' }}
    >
      <Box direction="row" align="center" gap="medium">
        {selectedTeam?.image && (
          <Box width="48px" height="48px">
            <Avatar src={selectedTeam.image.urlT150} />
          </Box>
        )}
        <Box>
          <Text size="small" weight="bold">
            Managing Team
          </Text>
          <Text size="medium">{selectedTeam?.nameMed}</Text>
        </Box>
      </Box>
      <Button
        label="Switch Teams"
        onClick={() => setShowModal(true)}
        secondary
        size="small"
      />
      {showModal && (
        <TeamModal
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default TeamSwitcher;
