// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Layer, Box, Button, Heading, Text, TextInput } from 'grommet';
import { Close } from 'grommet-icons';

import axios from '../../helpers/axios';
import { analytics } from '../../services/analytics';

const CreateNewReelModal = ({
  playerId,
  setShowCreateNewReelModal,
  history,
}) => {
  const [titleMain, setTitleMain] = useState('New Reel');

  return (
    <Layer
      onEsc={() => setShowCreateNewReelModal(false)}
      onClickOutside={() => setShowCreateNewReelModal(false)}
    >
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <Button
            icon={<Close />}
            onClick={() => setShowCreateNewReelModal(false)}
            hoverIndicator
          />
        </Box>
        <Box pad="medium" margin={{ bottom: 'large' }}>
          <Heading level={3}>Create New Reel</Heading>
          <Box gap="medium">
            <Box gap="small">
              <Text weight="bold">Reel Title</Text>
              <TextInput
                value={titleMain}
                onChange={(e) => setTitleMain(e.target.value)}
              />
            </Box>
            <Button
              primary
              label="Create Reel"
              onClick={() => {
                setShowCreateNewReelModal(false);

                axios
                  .post(`/api/player/${playerId}/reels`, { titleMain })
                  .then((response) => {
                    const newReel = response.data;
                    analytics.track('Create New Reel Saved', {
                      playerId,
                      title: titleMain,
                      id: newReel._id,
                    });
                    history.push(`/reels/${newReel._id}`);
                  });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default CreateNewReelModal;
