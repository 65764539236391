"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enableTelemetry = exports.flushTelemetry = exports.updateTelemetryTags = void 0;
const strogging_1 = require("./strogging");
let defaultUploadTelemetryFn = undefined;
// this is so that we don't log the telemetry itself
let blockReentrantFlush = false;
let tags = {};
function updateTelemetryTags(newTags, removeTags = []) {
    tags = { ...tags, ...newTags };
    removeTags.forEach((tag) => {
        delete tags[tag];
    });
}
exports.updateTelemetryTags = updateTelemetryTags;
// telemetry listener listens to log entries, and puts them in a
// queue. Every so often, the queue is flushed to the server. But also
// if there are a certain number of entries, the queue is flushed.
//
// Also, we double check that entries don't contain goofy amounts of data
// or circular data structures.
let cfg = {
    frequencyMillis: 60 * 1000,
    maxEntries: 100,
    maxEntryLength: 5000,
    minLoggingLevel: strogging_1.LoggingLevel.LOG,
    source: "web",
    getTags: () => tags,
};
let entryQueue = [];
function flushTelemetry(uploadTelemetryFn = defaultUploadTelemetryFn) {
    if (blockReentrantFlush) {
        return;
    }
    if (entryQueue.length > 0 && uploadTelemetryFn) {
        for (let i = 0; i < entryQueue.length; i += 1) {
            const entry = entryQueue[i];
            let str;
            try {
                str = JSON.stringify(entry);
            }
            catch (e) {
                // if we have an entry that is too long or unserializable, we just drop it
                entryQueue[i].payload = { entry: "UNSERIALIZABLE ENTRY" };
            }
            if (str && str.length > cfg.maxEntryLength) {
                entryQueue[i].payload = {
                    truncatedPayload: str.substring(0, cfg.maxEntryLength),
                };
            }
        }
        if (entryQueue.length > 0) {
            const request = {
                entries: entryQueue,
            };
            entryQueue = [];
            blockReentrantFlush = true;
            uploadTelemetryFn(request)
                .then((response) => {
                (0, strogging_1.debug)("flushTelemetry", response);
                blockReentrantFlush = false;
            })
                .catch((e) => {
                blockReentrantFlush = false;
                // Sentry.captureException(e);
            });
        }
    }
    else if (entryQueue.length > 0) {
        entryQueue = [];
    }
    setupTimer();
}
exports.flushTelemetry = flushTelemetry;
let timeoutHandle = undefined;
function setupTimer() {
    if (timeoutHandle) {
        clearTimeout(timeoutHandle);
    }
    timeoutHandle = setTimeout(flushTelemetry, cfg.frequencyMillis);
}
const telemetryListener = (level, title, payload) => {
    // confusing, but logging levels are in descending order
    if (level <= cfg.minLoggingLevel) {
        const payloadWithTags = typeof payload === "object"
            ? { ...payload, tags: cfg.getTags() }
            : { value: payload, tags: cfg.getTags() };
        const entry = {
            title,
            payload: payloadWithTags,
            level,
            source: cfg.source,
            ts: new Date().getTime(),
        };
        entryQueue.push(entry);
        if (entryQueue.length >= cfg.maxEntries) {
            flushTelemetry();
        }
    }
};
function enableTelemetry(uploadFn, source, options) {
    defaultUploadTelemetryFn = uploadFn;
    cfg = { ...cfg, ...options, source };
    (0, strogging_1.setOptions)({ listener: telemetryListener });
    setupTimer();
}
exports.enableTelemetry = enableTelemetry;
