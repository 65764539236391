import React from 'react';
import { Box, Button, Footer, Layer, Text } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import { TeamCapture } from '@shd/jslib/models/shd';
import { isMobile } from '../../helpers/browserDetect';
import { GEO_US_TX } from '../../constants/strings';
import GoProRTMPInfo from '../GoLive/GoProRTMPInfo';
import GoProSelectServer from './GoProSelectServer';

interface GoProSetupModalProps {
  capture: TeamCapture;
  onClose: () => void;
}

const GoProSetupModal: React.FC<GoProSetupModalProps> = ({
  capture,
  onClose,
}) => {
  const [goProOption, setGoProOption] = React.useState(GEO_US_TX);

  const [step, setStep] = React.useState(1);

  return (
    <Layer
      full={isMobile.any() === true}
      responsive={false}
      style={{ maxHeight: '100svh' }}
      animation={false}
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Box height={'100%'} flex>
        <Box
          flex={{ grow: 0, shrink: 1 }}
          direction="row"
          pad="16px"
          align="center"
          justify="between"
        >
          <Box
            alignSelf="start"
            onClick={() => {
              if (step === 1) {
                onClose();
              } else {
                setStep(step - 1);
              }
            }}
          >
            <LinkPrevious size="24px" />
          </Box>
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              Pair your GoPro
            </Text>
          </Box>
          <Box width="24px" />
        </Box>
        <Box overflow={'scroll'} flex={{ grow: 1, shrink: 1 }}>
          {step === 1 && (
            <GoProSelectServer
              goProOption={goProOption}
              setGoProOption={setGoProOption}
            />
          )}
          {step === 2 && (
            <GoProRTMPInfo goProOption={goProOption} capture={capture} />
          )}
        </Box>
        <Footer
          pad={'16px'}
          background="white"
          border={{
            color: 'light-2',
            side: 'top',
            size: '1px',
            style: 'solid',
          }}
          flex={{ shrink: 0, grow: 0 }}
        >
          <Box fill="horizontal" align="center" gap="8px">
            <Text weight="bold">Step {step} of 2</Text>
            <Button
              fill
              primary
              size="large"
              label={step === 1 ? 'Next' : 'Done'}
              onClick={() => {
                if (step === 2) {
                  onClose();
                } else {
                  setStep(step + 1);
                }
              }}
            />
          </Box>
        </Footer>
      </Box>
    </Layer>
  );
};

export default GoProSetupModal;
