// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Form, Layer, Heading, Text } from 'grommet';
import React, { useContext, useState } from 'react';
import axios from '../../helpers/axios';
import {
  FieldJerseyNum,
  FieldNameFirst,
  FieldNameLast,
} from '../../helpers/FormFields';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';

const QuickAddPlayer = ({
  addPlayerLocal = () => {},
  onClose = () => {},
  fullPage = false,
}) => {
  const userStore = useContext(UserContext);
  const team = userStore.selectedTeam || userStore.scoringTeam;
  const [player, setPlayer] = useState({
    nameFirst: '',
    nameLast: '',
    jerseyNum: '',
  });
  const [loading, toggleLoading] = useState(false);

  const addPlayer = () => {
    toggleLoading(true);
    axios
      .post(`/api/team/${team._id}/team_players`, {
        teamId: team._id,
        player,
        noInvite: true,
      })
      .then((response) => {
        toggleLoading(false);
        addPlayerLocal(response.data.player);
        onClose();
      });
  };

  return (
    <Layer responsive={fullPage}>
      <Form
        onSubmit={addPlayer}
        value={player}
        onChange={(nextPlayer) => setPlayer(nextPlayer)}
      >
        <Box pad="medium" gap="medium" width="medium" margin="medium">
          <Heading level="3">Create a player</Heading>
          <FieldNameFirst />
          <FieldNameLast required={false} />
          <FieldJerseyNum />
          <Box
            direction="row"
            align="center"
            fill="horizontal"
            justify="between"
            margin={{ top: 'large' }}
            gap="small"
          >
            <Text onClick={onClose} color="tertiary-1" size="large">
              Cancel
            </Text>
            <SHDButton
              primary
              label="Create player"
              size="large"
              type="submit"
              disabled={
                loading ||
                !(player.nameFirst || player.nameLast || player.jerseyNum)
              }
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Form>
    </Layer>
  );
};

export default QuickAddPlayer;
