// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
} from 'grommet';

import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session/context';

const EnableLivestreamer = () => {
  const userStore = useContext(UserContext);

  const makeLivestreamer = () => {
    const newUser = {
      isPermittedToCreateTeam: true,
    };
    axios.put(`/api/user/${userStore.authUser.claims.shd_user_id}`, { newUser }).then(() => {
      window.location.href = '/';
    });
  };

  useEffect(() => {
    makeLivestreamer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box>Enabling you to become a livestreamer...</Box>
    </>
  );
};

export default withRouter(EnableLivestreamer);
