import { Box, Button, Layer, Text } from 'grommet';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useContext, useEffect, useState } from 'react';
import axios from './axios';
import { Facebook } from 'grommet-icons';
import { dtos } from '@shd/jslib/models';
import FacebookDestinationPickerV2 from '../features/StreamingConfig/FacebookDestinationPickerV2';
import * as Sentry from '@sentry/browser';
import { useFacebookAuthResponse, useFacebookScopes } from './facebook';
import { miscUtil, strogging } from '@shd/jslib/infra';
import { AppHostContext } from '../services/appHost';

interface FacebookLoginResponse {
  status: string;
}
interface Props {
  teamId: string;
  isAdmin: boolean;
}

type FacebookPublishState =
  | 'connected'
  | 'authenticated'
  | 'non-admin'
  | 'hosted-unauthenticated'
  | 'unauthenticated';

const FacebookButton: React.FC<Props> = ({ teamId, isAdmin }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [publishFB, setPublishFB] = useState<dtos.PublishFb>();
  const fbAuthResponse = useFacebookAuthResponse();
  const authResponse = isAdmin ? fbAuthResponse : null;

  useEffect(() => {
    axios
      .get<dtos.PublishFb>('/api/facebook_auth', { params: { teamId } })
      .then((res) => {
        setLoading(false);

        if (res.data) {
          setPublishFB(res.data);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError('Failed to fetch Facebook connection');
      });
  }, [teamId]);

  const responseFacebook = async (res: FacebookLoginResponse) => {
    if (res.status && res.status === 'unknown') {
      return;
    }
    setShowModal(true);
  };
  const appHost = useContext(AppHostContext);
  const fbScopes = useFacebookScopes();
  const fbPublishState: FacebookPublishState = publishFB
    ? 'connected'
    : authResponse
      ? 'authenticated'
      : isAdmin
        ? appHost.isHosted
          ? 'hosted-unauthenticated'
          : 'unauthenticated'
        : 'non-admin';

  return (
    <Box>
      <Box skeleton={loading}>
        {miscUtil.coverEveryCase(fbPublishState, {
          authenticated: (
            <Button
              secondary
              color="#0766FF"
              icon={<Facebook size="small" color="plain" />}
              label={
                <Text color="#0766FF" size="small" weight={'bold'}>
                  Connect Facebook
                </Text>
              }
              onClick={() => {
                setShowModal(true);
              }}
            />
          ),
          connected: (
            <Button
              primary
              color="#0766FF"
              icon={<Facebook size="small" color="white" />}
              label={
                <Text size="small" weight={'bold'}>
                  {publishFB?.destNodeName}
                </Text>
              }
              href={`https://www.facebook.com/${publishFB?.destNodeId}`}
              target="_blank"
            />
          ),
          'hosted-unauthenticated': (
            <Button
              secondary
              color="#0766FF"
              icon={<Facebook size="small" color="plain" />}
              label={
                <Text color="#0766FF" size="small" weight={'bold'}>
                  Connect Facebook
                </Text>
              }
              onClick={async () => {
                if (appHost.isHosted) {
                  const response =
                    await appHost.service.requests.authenticateFacebook();
                  if (response?.accessToken) {
                    setShowModal(true);
                  }
                } else {
                  strogging.error('Not hosted, cannot authenticate Facebook');
                }
              }}
            />
          ),
          unauthenticated: (
            <FacebookLogin
              disableMobileRedirect
              version="15.0"
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture,link"
              scope={fbScopes}
              callback={responseFacebook}
              render={(renderProps: { onClick: () => void }) => (
                <Button
                  secondary
                  color="#0766FF"
                  icon={<Facebook size="small" color="plain" />}
                  label={
                    <Text color="#0766FF" size="small" weight={'bold'}>
                      Connect Facebook
                    </Text>
                  }
                  onClick={renderProps.onClick}
                />
              )}
            />
          ),
          'non-admin': null,
        })}
      </Box>
      {showModal && (
        <Layer
          onClickOutside={() => {
            setShowModal(false);
          }}
          onEsc={() => {
            setShowModal(false);
          }}
          modal
          style={{ height: '100dvh' }}
          animation={false}
          full="vertical"
        >
          <FacebookDestinationPickerV2
            teamId={teamId}
            onClose={() => {
              setShowModal(false);
            }}
            onLinked={(p: dtos.PublishFb) => {
              setPublishFB(p);
            }}
          />
        </Layer>
      )}
      {/* TODO: figure out what to do when there is an error */}
      {error && <Text color="status-error">{error}</Text>}
    </Box>
  );
};

export default FacebookButton;
