import { Add } from 'grommet-icons';
import { Box, Button, Grid, ResponsiveContext, Select, Text } from 'grommet';
import { useQuery } from '../../helpers/utils';
import { CURRENT_SPORTS } from '../../constants/strings';
import { observer } from 'mobx-react';
import { setCardColumns } from '../../helpers/grommetUtils';
import { grommet, shd } from '@shd/jslib/models';
import { UserContext } from '../../services/Session';
import * as ROUTES from '../../constants/routes';
import Athletes from './Home/Athletes';
import axios from '../../helpers/axios';
import React, { useContext, useEffect, useState } from 'react';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';
import SportOption from './Home/SportsOption';
import Teams from './Home/Teams';
import withLayout from '../../helpers/withLayout';
import GenericMessageModal from '../../components/GenericMessageModal';

const HomePage: React.FC<Record<string, never>> = observer(() => {
  const screenSize = useContext(ResponsiveContext);
  const userStore = useContext(UserContext);
  const [coachTeams, setCoachTeams] = useState<shd.Team[]>([]);
  const [loadingCoachTeams, setLoadingCoachTeams] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const cardColumns = setCardColumns(screenSize as grommet.GrommetScreenSize);
  const [message, setMessage] = useState<{ message: string; title: string }>();
  const query = useQuery();

  useEffect(() => {
    const msg = query.get('msg');
    const title = query.get('title');
    if (msg && title) {
      setMessage({ message: msg, title });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStore.coachTeamIds.length > 0) {
      setLoadingCoachTeams(true);

      if (!userStore.authUser || !userStore.authUser.claims) {
        return;
      }

      axios
        .get(`/api/user/${userStore.authUser?.claims.shd_user_id}/coach_teams`)
        .then((response) => {
          setCoachTeams(response.data.teams);
          setLoadingCoachTeams(false);
        });
    }
  }, [userStore.coachTeamIds, userStore.authUser]);

  return (
    <Box fill background="light-5">
      <ResponsiveGrid
        fill
        responsive
        rows={['flex']}
        columns={['12.5%', '75%', '12.5%']}
        areas={{
          small: [{ name: 'center', start: [0, 0], end: [2, 0] }],
          medium: [{ name: 'center', start: [0, 0], end: [2, 0] }],
          large: [
            { name: 'left', start: [0, 0], end: [0, 0] },
            { name: 'center', start: [1, 0], end: [1, 0] },
            { name: 'right', start: [2, 0], end: [2, 0] },
          ],
        }}
      >
        <Box gridArea="left" background="light-5" />
        <Box gridArea="center" background="white" width={{ max: '1080px' }}>
          <Box pad="medium">
            {userStore.userPlayers.length > 0 && (
              <Box gap="8px">
                <Box direction="row">
                  <Text alignSelf="center" size="small" weight="bold">
                    My Athletes
                  </Text>
                </Box>
                <Grid columns={cardColumns} gap="small">
                  <Athletes players={userStore.userPlayers} />
                </Grid>
              </Box>
            )}
            <Box gap="8px" pad={{ top: 'medium' }}>
              <Box
                direction="row"
                gap="small"
                justify={screenSize === 'small' ? 'between' : 'stretch'}
              >
                <Text alignSelf="center" size="small" weight="bold">
                  My Teams
                </Text>
                <Button
                  plain
                  icon={<Add size="24px" />}
                  href={`${ROUTES.ONBOARDING}?from_existing=1`}
                />
              </Box>
              {userStore.userTeams.length === 0 && (
                <Box gap="small">
                  <Text>{`You don't have any teams yet.`}</Text>
                  <Text>
                    {`Press the "+" button above to create a new team `}
                  </Text>
                </Box>
              )}
              {userStore.userTeams.length > 0 && (
                <Box width={{ max: '132px' }}>
                  <Select
                    multiple
                    size="small"
                    closeOnChange={false}
                    placeholder={<Text size="small">Show All Teams</Text>}
                    selected={selected}
                    options={CURRENT_SPORTS}
                    onChange={({ selected: nextSelected }) => {
                      setSelected(nextSelected);
                    }}
                  >
                    {(option: { display: string }, index: number) => (
                      <SportOption
                        option={option}
                        selected={selected.indexOf(index) !== -1}
                      />
                    )}
                  </Select>
                </Box>
              )}
              {userStore.userTeams.length > 0 && (
                <Grid columns={cardColumns} gap="small">
                  <Teams teams={userStore.userTeams} selected={selected} />
                </Grid>
              )}
            </Box>
            {userStore.coachTeamIds.length > 0 && (
              <Box skeleton={loadingCoachTeams}>
                <Box gap="8px" pad={{ top: 'medium' }}>
                  <Text size="small" weight="bold">
                    Teams I Coach
                  </Text>
                  <Grid columns={cardColumns} gap="small">
                    <Teams teams={coachTeams} selected={selected} />
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box gridArea="right" background="light-5" />
      </ResponsiveGrid>
      {message && (
        <GenericMessageModal payload={message} closeModal={setMessage} />
      )}
    </Box>
  );
});

export default withLayout(HomePage);
