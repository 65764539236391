import { useState, useEffect } from 'react';
import axios from '../helpers/axios';
import * as Sentry from '@sentry/browser';

const useClipData = (clipId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [clipData, setClipData] = useState<any | undefined>();
  useEffect(() => {
    axios
      .get(`/api/clip/${clipId}`)
      .then((response) => {
        setClipData(response.data);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [clipId]);

  return clipData;
};

const useClipViewed = () => {
  const setClipViewed = (clipId: string) => {
    axios.post(`/api/clip_view/${clipId}`).catch((error) => {
      Sentry.captureException(error);
    });
  };
  return { setClipViewed };
};

export { useClipData, useClipViewed };
