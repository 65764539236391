import React from 'react';
import { Box, Text, Image } from 'grommet';
import { shd } from '@shd/jslib/models';
import { BBSB_SPORTS_KEYS } from '../../constants/strings';

interface Props {
  player: shd.ClaimedPlayer;
  selectedSport: string;
}

const Equipment: React.FC<{ bat: string }> = ({ bat }) => (
  <Box direction="row" gap="small">
    <Text size="large" weight="bold">
      Swings:{' '}
    </Text>
    <Text size="large">{bat}</Text>
  </Box>
);

const FavoriteTeam: React.FC<{ team: { id: string; name: string } }> = ({
  team,
}) => (
  <Box direction="row" gap="small" align="center">
    <Text size="large" weight="bold">
      Favorite Team:{' '}
    </Text>
    <Box height="20px" width="20px">
      <Image
        fit="contain"
        src={`https://midfield.mlbstatic.com/v1/team/${team.id}/spots`}
      />
    </Box>
    <Text size="large">{team.name}</Text>
  </Box>
);

const FavoritePlayer: React.FC<{ name: string }> = ({ name }) => (
  <Box direction="row" gap="small">
    <Text size="large" weight="bold">
      Favorite Player:{' '}
    </Text>
    <Text size="large">{name}</Text>
  </Box>
);

const PlayerLocker: React.FC<Props> = ({ player, selectedSport }) => {
  if (!player) {
    return null;
  }

  const isBBSBSport = BBSB_SPORTS_KEYS.includes(selectedSport);
  const equipment = isBBSBSport ? player.equipment?.bat : null;
  const favoriteTeam = isBBSBSport
    ? player.favoriteTeams?.[selectedSport]
    : null;
  const favoritePlayer = player.playerHero?.[selectedSport];

  const hasContent = equipment || favoriteTeam || favoritePlayer;

  if (!hasContent) {
    return null;
  }

  return (
    <Box pad="medium" background="white" round="medium">
      <Box gap="small">
        {equipment && <Equipment bat={equipment} />}
        {favoriteTeam && <FavoriteTeam team={favoriteTeam} />}
        {favoritePlayer && <FavoritePlayer name={favoritePlayer} />}
      </Box>
    </Box>
  );
};

export default PlayerLocker;
