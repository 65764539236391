import React, { useContext } from 'react';
import { Box, Text, Image, Button, DropButton, Layer } from 'grommet';
import { MoreVertical, StatusGoodSmall } from 'grommet-icons';
import {
  GOPRO,
  MEVO,
  MEVO_MULTI,
  MEVO_SINGLE,
  SMARTPHONE,
  SMARTPHONE_LEGACY,
} from '../../constants/strings';
import { TeamCapture } from '@shd/jslib/models/shd';
import { CaptureStatus } from './GoLiveFlow';
import EditCapture from '../StreamingConfig/EditCapture';
import AdvancedSettings from '../Settings/AdvancedSettings';
import CaptureSetupInstructions from '../Settings/CaptureSetupInstructions';
import PointCameraLayer from '../StreamingConfig/PointCameraLayer';
import axios from '../../helpers/axios';
import * as Sentry from '@sentry/browser';
import { UserContext } from '../../services/Session';
import { analytics } from '../../services/analytics';

interface CaptureStatusIndicatorProps {
  capture: TeamCapture;
  status: CaptureStatus;
}

const CaptureStatusIndicator: React.FC<CaptureStatusIndicatorProps> = ({
  status,
  capture,
}) => {
  const capStatuses: Record<
    CaptureStatus,
    { color: string; statusText: React.ReactNode }
  > = {
    [CaptureStatus.Ready]: {
      color: '#42AF21',
      statusText: <Text size="small">Ready</Text>,
    },
    [CaptureStatus.Disabled]: {
      color: 'gray-2',
      statusText: <Text size="small">Disabled</Text>,
    },
    [CaptureStatus.OtherTeam]: {
      color: 'tertiary-1',
      statusText: (
        <Text size="small">
          Currently enabeled for{' '}
          <Text size="small" weight={'bold'}>
            {capture.targetTeamName}
          </Text>
        </Text>
      ),
    },
    [CaptureStatus.Live]: {
      color: 'status-critical',
      statusText: <Text size="small">Live</Text>,
    },
    [CaptureStatus.TeamLive]: {
      color: 'status-critical',
      statusText: <Text size="small">Live</Text>,
    },
  };

  const { color, statusText } = capStatuses[status];

  return (
    <Box direction="row" gap="xsmall" align="center">
      <StatusGoodSmall size="8px" color={color} />
      {statusText}
    </Box>
  );
};

interface CaptureItemProps {
  status: CaptureStatus;
  capture: TeamCapture;
  onSelected: (capture: TeamCapture) => void;
  showEdit: boolean;
  onUpdated?: (updatedCapture: TeamCapture, disableOthers?: boolean) => void;
}

const CaptureItem: React.FC<CaptureItemProps> = ({
  status,
  capture,
  onSelected,
  showEdit,
  onUpdated = () => {},
}) => {
  const userStore = useContext(UserContext);
  const [showEditMenu, setShowEditMenu] = React.useState(false);
  const [showReconfigure, setShowReconfigure] = React.useState(false);
  const [showEditCamera, setShowEditCamera] = React.useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false);
  const [showPointCameraModal, setShowPointCameraModal] = React.useState(false);
  const [pointToTeamId, setPointToTeamId] = React.useState<string>('');
  const [statusLoading, setStatusLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>();

  let imageSrc = '/logo192.png';
  if (capture.equipCamera === MEVO) {
    imageSrc = '/capture_icons/mevo.png';
  } else if (capture.equipCamera === MEVO_SINGLE) {
    imageSrc = '/capture_icons/mevo_single.png';
  } else if (capture.equipCamera === MEVO_MULTI) {
    imageSrc = '/capture_icons/mevo_multi.png';
  } else if (
    capture.equipCamera &&
    [SMARTPHONE, SMARTPHONE_LEGACY].includes(capture.equipCamera)
  ) {
    imageSrc = '/capture_icons/larix.png'; // a bit annoying that some current users will show as larix even when they're using another RTMP streaming app
  } else if (capture.equipCamera === GOPRO) {
    imageSrc = '/capture_icons/gopro.png';
  } else if (capture.equipCamera === 'RTMP') {
    imageSrc = '/capture_icons/rtmp.png';
  }

  const pointCapture = (targetTeamId: string) => {
    const newCapture = { ...capture };
    newCapture.targetTeamId = targetTeamId;
    setStatusLoading(true);
    axios
      .put(`/api/capture/${newCapture._id}`, {
        captureInfo: newCapture,
        force: true,
      })
      .then((response) => {
        onUpdated(response.data, true);
        setStatusLoading(false);
        setError(undefined);
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError('Could not edit camera. Please try again.');
        }
        setStatusLoading(false);
      });
  };

  const editCapture = (editedCapture: TeamCapture) => {
    setStatusLoading(true);
    axios
      .put(`/api/capture/${editedCapture._id}`, {
        captureInfo: editedCapture,
      })
      .then(() => {
        setStatusLoading(false);
        setError(undefined);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError('Could not edit camera. Please try again.');
        setStatusLoading(false);
      });

    onUpdated(editedCapture);
  };

  return (
    <Box
      pad="8px"
      round="8px"
      border={{ color: 'light-2', size: '1px' }}
      gap="8px"
      background={'white'}
    >
      <Box
        direction="row"
        gap="medium"
        align="center"
        onClick={() => onSelected(capture)}
        justify="between"
      >
        {showReconfigure && (
          <CaptureSetupInstructions
            capture={capture}
            onClose={() => {
              setShowReconfigure(false);
              setShowEditMenu(false);
            }}
          />
        )}
        <Box direction="row" align="center" gap="medium">
          <Box height={'48px'} width={'48px'} round="8px" overflow={'hidden'}>
            <Image src={imageSrc} fit="cover" />
          </Box>
          <Box gap="xsmall">
            <Text weight={'bold'}>{capture.captureName}</Text>
            <Box skeleton={statusLoading}>
              <CaptureStatusIndicator capture={capture} status={status} />
            </Box>
          </Box>
        </Box>
        {showEdit && (
          <Box align="end">
            <DropButton
              open={showEditMenu}
              onOpen={() => setShowEditMenu(true)}
              onClose={() => setShowEditMenu(false)}
              icon={<MoreVertical size="16px" />}
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropContent={
                <Box pad="small" gap="small">
                  <Button
                    label="Reconfigure Camera"
                    onClick={() => {
                      setShowReconfigure(true);
                      setShowEditMenu(false);
                    }}
                  />
                  {capture.isOwnedByActiveUser && (
                    <Button
                      label="Edit Camera"
                      onClick={() => {
                        setShowEditCamera(true);
                        setShowEditMenu(false);
                      }}
                    />
                  )}
                  <Button
                    label="Advanced Settings"
                    onClick={() => {
                      setShowAdvancedSettings(true);
                      setShowEditMenu(false);
                    }}
                  />
                  {status === CaptureStatus.Disabled ? (
                    <Button
                      label="Enable Camera"
                      onClick={() => {
                        const teamId = userStore.selectedTeam?._id || '';
                        pointCapture(teamId);
                        setShowEditMenu(false);

                        const eventName = showEdit
                          ? 'Go Live: Enable Camera'
                          : 'Settings: Enable Camera Clicked';
                        analytics.track(eventName, {
                          teamId: teamId,
                          pointToTeamId: teamId,
                        });
                      }}
                    />
                  ) : (
                    <Button
                      label="Disable Camera"
                      onClick={() => {
                        setPointToTeamId('disabled');
                        setShowPointCameraModal(true);
                        setShowEditMenu(false);
                      }}
                    />
                  )}
                </Box>
              }
            />
          </Box>
        )}
        {showEditCamera && (
          <Layer>
            <EditCapture
              editCapture={(editedCapture) => {
                editCapture(editedCapture);
              }}
              closeLayer={() => {
                setShowEditCamera(false);
              }}
              capture={capture}
            />
          </Layer>
        )}
        {showAdvancedSettings && (
          <AdvancedSettings
            capture={capture}
            onClose={() => {
              setShowAdvancedSettings(false);
            }}
            onSaved={(savedCapture) => {
              onUpdated(savedCapture);
              setShowAdvancedSettings(false);
            }}
          />
        )}
        {showPointCameraModal && (
          <PointCameraLayer
            capture={capture}
            closeLayer={() => {
              setShowPointCameraModal(false);
            }}
            pointCapture={() => {
              pointCapture(pointToTeamId);
            }}
          />
        )}
      </Box>
      {status === CaptureStatus.OtherTeam && (
        <Box>
          <Button
            secondary
            size="small"
            label={`Stream to ${userStore.selectedTeam?.nameMed} instead`}
            onClick={() => {
              pointCapture(userStore.selectedTeam?._id || '');
            }}
            disabled={statusLoading}
          />
        </Box>
      )}
      {error && <Text color="status-critical">{error}</Text>}
    </Box>
  );
};

export default CaptureItem;
