import { FormValues } from './teamInformation.types';

const getObjectDiff = (
  obj1: FormValues,
  obj2: FormValues,
  compareRef = false
): string[] => {
  return Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if (
      obj1[key as keyof FormValues] === obj2[key as keyof FormValues]
    ) {
      const resultKeyIndex = result.indexOf(key);

      if (
        compareRef &&
        obj1[key as keyof FormValues] !== obj2[key as keyof FormValues]
      ) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};

export const fetchPayload = (value: FormValues, currentValues: FormValues) => {
  if (value.teamType?.toLowerCase() !== 'travel team') {
    value.attrib_ageU = 0;
  }
  const diff = getObjectDiff(currentValues, value);
  return diff.reduce((acc, x) => {
    return {
      ...acc,
      [x]: value[x as keyof FormValues],
    };
  }, {});
};
