/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import './clipVideoPlayer.css';
import { Box, Text } from 'grommet';
import { Lock } from 'grommet-icons';
import * as Sentry from '@sentry/browser';
import { API } from '../constants/baseUrls';

import { analytics } from '../services/analytics';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useClipData, useClipViewed } from './useClipPlayer';

interface Props extends RouteComponentProps {
  videoSrc: string;
  id: string;
  isHighlightReel?: boolean;
  sport?: string;
  showNotClaimed?: boolean;
  showLock?: boolean;
  lockReason?: string;
  isOnBoarding?: boolean;
}
const ClipVideoPlayer: React.FC<Props> = ({
  videoSrc,
  id,
  isHighlightReel,
  sport = 'bbsb',
  showNotClaimed,
  history,
  isOnBoarding = false,
  ...props
}) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const viewedRef = useRef<boolean>(false);

  const [isReady, toggleIsReady] = useState(false);
  const [player, setPlayer] = useState<
    ReturnType<typeof videojs> | undefined
  >();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showLock, setShowLock] = useState(props.showLock);
  const [, setIsEnd] = useState(false);
  const clip = useClipData(id);
  const { setClipViewed } = useClipViewed();

  useEffect(() => {
    setShowLock(props.showLock);
  }, [props.showLock]);

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }

    const videoJsOptions = {
      autoplay: false,
      muted: false,
      controls: !props.showLock,
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
      poster: !isOnBoarding
        ? `${API}/api/thumbnail/clip/${id}?keep_aspect=1`
        : '',
    };

    const initPlayer = videojs(playerRef.current, videoJsOptions, () => {
      toggleIsReady(true);
    });

    initPlayer.on('ended', () => {
      setIsEnd(true);
      // initPlayer.exitFullscreen();
    });

    playerRef.current.onplay = () => {
      // hack: parse the current location to get
      // the context in which the clip is being played
      // (e.g. player page, game page, single clip page, etc.)
      const pathname = history.location.pathname;

      let source = '';
      if (pathname.includes('/game/')) {
        source = 'game';
      } else if (pathname.includes('/c/')) {
        source = 'single clip';
      } else {
        // this is maybe imperfect...
        source = 'player page';
      }

      analytics.track('Clip Play', {
        clipId: id,
        sport,
        source,
        isHighlightReel,
      });
    };

    playerRef.current.onpause = async () => {
      const currentTime = initPlayer.currentTime() ?? 0;
      if (currentTime > 2) {
        // watching more than 2 seconds counts as a view
        if (!viewedRef.current) {
          setClipViewed(id);
          viewedRef.current = true;
        }
      }
    };

    setPlayer(initPlayer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReady) {
      let video = `${videoSrc}`;
      if (isHighlightReel || isOnBoarding) {
        video = `${videoSrc}#t=4`;
      }
      player?.src({
        src: video,
        type: 'video/mp4',
      });

      const clipElement = document.getElementById(id);
      if (clipElement) {
        const playButtonArr = document
          .getElementById(id)
          ?.getElementsByClassName('vjs-big-play-button');
        if (playButtonArr?.length) {
          const playButton = playButtonArr[0];
          playButton.addEventListener(
            'touchend',
            () => {
              if (
                !document.fullscreenElement &&
                player &&
                document.fullscreenEnabled
              ) {
                const fs = player.requestFullscreen();
                if (fs) {
                  fs.catch((respError) => {
                    Sentry.captureException(respError);
                  });
                }
              }
              if (player) {
                player.play()?.catch((respError) => {
                  Sentry.captureException(respError);
                });
              }
            },
            false
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, videoSrc]);

  const renderLock = () => {
    const lockStyle: React.CSSProperties = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      opacity: '0.4',
    };

    let lockReason = '';
    if (props.lockReason) {
      lockReason = props.lockReason;
    } else if (showNotClaimed) {
      lockReason = 'Player not claimed';
    } else {
      lockReason = 'Please login as a player, coach, or team manager.';
    }
    return (
      <>
        <div style={lockStyle} />
        <Box
          gap="medium"
          align="center"
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Lock color="white" size="large" />
          <Text>{lockReason}</Text>
        </Box>
      </>
    );
  };

  return (
    <Box id={id} fill>
      <Box data-vjs-player>
        <video
          ref={playerRef}
          className={`video-js vjs-theme-fantasy vjs-16-9
            ${showLock ? 'video-js-disabled' : ''}`}
          style={{ width: '100%', height: 'inherit' }}
          playsInline
        />
        {showLock && renderLock()}
        {/* {isEnd && renderEndScreen()} */}
      </Box>
      <Box pad="xsmall">
        {clip && (
          <Text size="xsmall">{`${clip.views ? clip.views : 0} views`}</Text>
        )}
      </Box>
    </Box>
  );
};

export default withRouter(ClipVideoPlayer);
