// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { Box, Heading, Text, CardBody, Card, CardHeader } from 'grommet';
import { GROMMETTHEME } from '../../constants/grommetTheme';
import withLayout from '../../helpers/withLayout';
import SHDButton from '../../components/SHD/Button';

const BetaInfoPage = () => (
  <Box
    data-testid="signIn"
    background={`linear-gradient(${GROMMETTHEME.global.colors.brand.light}, ${GROMMETTHEME.global.colors.brand.dark})`}
    height="100vh"
  >
    <Card
      align="center"
      pad="xlarge"
      gap="small"
      margin="auto"
      background="white"
      width={{ max: '500px' }}
    >
      <CardHeader pad="small">
        <Heading level={3}>We&apos;re invite-only</Heading>
      </CardHeader>
      <CardBody pad="medium" justify="center" gap="large">
        <Text textAlign="center" size="large">
          Thanks for your interest in sidelineHD! Although our platform is
          currently invite-only, we can&apos;t wait to bring you and your team
          onboard!
        </Text>
        <Text textAlign="center" size="large">
          If you&apos;re eager to get started, consider applying for an invite.
        </Text>
        <SHDButton
          textAlign="center"
          size="large"
          primary
          label="Apply for an invite"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfQVT7JIEg0sqa1GwFagnU2qzGbNR9r7lmoZnIFbCV7Dx79gA/viewform"
          target="_blank"
          margin="0 auto"
        />
      </CardBody>
    </Card>
  </Box>
);

export default withLayout(BetaInfoPage);
