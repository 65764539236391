/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Accordion,
  AccordionPanel,
  Text,
  Grid,
  ResponsiveContext,
  Layer,
} from 'grommet';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DateTime, Duration } from 'luxon';
import { withFirebase } from '../../services/Firebase';
import { withAuthorization } from '../../services/Session';
import withLayout from '../../helpers/withLayout';
import {
  useTeamDoc,
  useCaptureDoc,
  useUserDoc,
  useVeWorkers,
} from '../../helpers/firestoreHooks';
import { getVeWorkerOverlayUrl, renderAsync } from '../../helpers/utils';
import { theFirebase } from '../../services/Firebase/firebase';
import { errorUtil, strogging } from '@shd/jslib/infra';
import { FirebaseComponentProps } from '../../services/Firebase/context';
import { docStorage } from '@shd/jslib/models';
import { AugmentedUser } from '../../services/Session/store';
import GameHlsPlayer from '../../components/HLS/HlsPlayer';
import { compose } from 'recompose';
import SHDButton from '../../components/SHD/Button';
import { HlsInfo } from '../../components/HLS/types';

const compareVengine = (
  a: docStorage.VideoEngineWorkerDoc,
  b: docStorage.VideoEngineWorkerDoc
) => {
  const avalue = `${a.hostname}:${a.workerIndex}`;
  const bvalue = `${b.hostname}:${b.workerIndex}`;
  return avalue.localeCompare(bvalue);
};

type FormatDataType = 'datetime' | 'heartbeat' | 'duration';

interface DocsInfo<T> {
  main: T;
  capture?: docStorage.CaptureDoc;
  team?: docStorage.TeamDoc;
  user?: docStorage.UserDoc;
}

// interface FieldDesc<T,K extends keyof DocsInfo<T>=keyof DocsInfo<T>> {
//   label:string;
//   doc?: K;
//   propGet: (data:DocsInfo<T>[K]) => string | number | undefined;
//   dtype?: FormatDataType;
// }
// type FieldDescBase<T> = {
//   [K in keyof DocsInfo<T>]: {
//     label: string;
//     doc: K;
//     propGet: (data: Required<DocsInfo<T>>[K]) => string | number | undefined;
//     dtype?: FormatDataType;
//   };
// }[keyof DocsInfo<T>];

// type FieldDesc<T> = Exclude<FieldDescBase<T>, undefined>;
type FieldDesc<T> = {
  label: string;
  adminOnly?: boolean;
  dtype?: FormatDataType | undefined;
} & (
  | {
      doc: 'main';
      propGet: (data: T) => string | number | undefined;
    }
  | {
      doc: 'capture';
      propGet: (data: docStorage.CaptureDoc) => string | number | undefined;
    }
  | {
      doc: 'user';
      propGet: (data: docStorage.UserDoc) => string | number | undefined;
    }
  | {
      doc: 'team';
      propGet: (data: docStorage.TeamDoc) => string | number | undefined;
    }
);

type PropGetData<T, TFD extends FieldDesc<T>> = TFD extends { doc: infer K }
  ? K extends keyof DocsInfo<T>
    ? (data: Required<DocsInfo<T>>[K]) => ReturnType<TFD['propGet']>
    : never
  : never;

type PropGetDataArg<T, TFD extends FieldDesc<T>> = TFD extends { doc: infer K }
  ? K extends keyof DocsInfo<T>
    ? Required<DocsInfo<T>>[K]
    : never
  : never;

type VengineFieldDesc = FieldDesc<docStorage.VideoEngineWorkerDoc>;
type StreamFieldDesc = FieldDesc<docStorage.models.StreamInfo>;

interface StreamBoardProps extends FirebaseComponentProps {
  shdpro?: boolean;
}

const StreamBoard = (props: StreamBoardProps) => {
  const { shdpro = false } = props;
  const [result, loading, error] = useVeWorkers(props.firebase.firestore);
  const gateways = React.useMemo(
    () =>
      (result ?? []).filter((worker) =>
        (worker.capabilities ?? []).includes('gateway')
      ),
    [result]
  );
  const vengines = React.useMemo(
    () =>
      (result ?? [])
        .filter((worker) => (worker.capabilities ?? []).includes('vengine'))
        .sort(compareVengine),
    [result]
  );

  const [hlsUrl, setHlsUrl] = React.useState<string>();
  const hlsInfo = React.useMemo((): HlsInfo | undefined => {
    return hlsUrl ? { type: 'uri', uri: hlsUrl } : undefined;
  }, [hlsUrl]);
  return (
    <Box pad="large">
      <Heading level={1}>{shdpro && 'Pro'} Stream Board</Heading>
      {renderAsync(loading, error, result, {
        loading: () => <div>Loading...</div>,
        error: (e) => (
          <div>
            Error:
            {errorUtil.errorMessage(e)}
          </div>
        ),
        result: () => (
          <div>
            {!shdpro && <AllGateways data={gateways} shdpro={shdpro} />}
            <AllVengines
              data={vengines}
              shdpro={shdpro}
              setHlsUrl={setHlsUrl}
            />
          </div>
        ),
      })}
      {hlsInfo && (
        <Layer
          onEsc={() => setHlsUrl(undefined)}
          onClickOutside={() => setHlsUrl(undefined)}
        >
          <GameHlsPlayer
            hlsInfo={hlsInfo}
            fetchInfoError={hlsUrl ? undefined : 'Error: No stream supplied'}
          />
        </Layer>
      )}
    </Box>
  );
};

const AllGateways: React.FC<{
  data: docStorage.VideoEngineWorkerDoc[];
  shdpro: boolean;
}> = ({ data, shdpro }) => (
  <Accordion>
    <Heading level={2}>Gateways</Heading>
    {data.map((gw) => (
      <Gateway key={gw._id} data={gw} shdpro={shdpro} />
    ))}
  </Accordion>
);

interface DataElementProps<T> {
  docs: DocsInfo<T>;
  field: FieldDesc<T>;
}

function DataElement<T, TField>({ docs, field }: DataElementProps<T>) {
  if (field === undefined) {
    return null;
  }
  const data = docs[field.doc];
  if (data === undefined) {
    return null;
  }
  type PropArg = PropGetDataArg<T, typeof field>;
  const propGetter = field.propGet as PropGetData<T, typeof field>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const propValue = propGetter(data as any);
  return (
    <Box pad="small">
      <Text size="small" weight="lighter">
        {field.label}
      </Text>
      <Text size="medium">
        {propValue !== undefined
          ? field.dtype === 'datetime'
            ? DateTime.fromMillis((propValue as number) * 1000)
                .toLocal()
                .toLocaleString(DateTime.DATETIME_MED)
            : field.dtype === 'heartbeat'
              ? `${Math.floor(
                  DateTime.now().diff(
                    DateTime.fromMillis((propValue as number) * 1000),
                    'seconds'
                  ).seconds
                )}s ago`
              : field.dtype === 'duration'
                ? Duration.fromMillis(propValue as number).toFormat('hh:mm:ss')
                : propValue
          : '(unset)'}
      </Text>
    </Box>
  );
}

const gatewayStreamFields: StreamFieldDesc[] = [
  {
    label: 'Id',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data.streamName,
  },
  {
    label: 'Capture Id',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data.captureId,
  },
  {
    label: 'Capture Name',
    doc: 'capture',
    propGet: (data) => data.captureName,
  },
  { label: 'Team Id', doc: 'main', propGet: (data) => data.teamId },
  { label: 'Team Name', doc: 'team', propGet: (data) => data.nameLong },
  { label: 'User', doc: 'user', propGet: (data) => data.email },
  {
    label: 'StreamBwIn',
    doc: 'main',
    propGet: (data) =>
      `${Math.round((data.pollData?.streamBwIn ?? 0) / 1000) || 0}K`,
  },
  {
    label: 'Stream Meta',
    doc: 'main',
    propGet: (data) => data.pollData?.streamMetaAvTxt,
  },
  {
    label: 'Publishing',
    doc: 'main',
    propGet: (data) => data.pollData?.isPublishing?.toString(),
  },
  {
    label: 'Recording',
    doc: 'main',
    propGet: (data) => data.pollData?.isRecording?.toString(),
  },
  { label: 'Stream Up', doc: 'main', propGet: (data) => data.isUp?.toString() },
  {
    label: 'Connection Time',
    doc: 'main',
    propGet: (data) => data.pollData?.connectionTimeMs || 0,
    dtype: 'duration',
  },
  {
    label: 'Source IP',
    doc: 'main',
    propGet: (data) => data.pollData?.raw?.publisher_ip,
  },
  {
    label: 'Resolution',
    doc: 'main',
    propGet: (data) => data.pollData?.raw?.resolution,
  },
  {
    label: 'Video codec',
    doc: 'main',
    propGet: (data) => data.pollData?.raw?.vcodec,
  },
  {
    label: 'Audio coded',
    doc: 'main',
    propGet: (data) => data.pollData?.raw?.acodec,
  },
];

const GatewayStream: React.FC<{
  data: docStorage.models.StreamInfo;
  shdpro: boolean;
}> = ({ data, shdpro }) => {
  const teamHook = useTeamDoc(theFirebase().firestore, data.teamId);
  const captureDoc = useCaptureDoc(theFirebase().firestore, data.captureId);
  const userDoc = useUserDoc(
    theFirebase().firestore,
    captureDoc[0]?.userId || captureDoc[0]?.modifiedUser
  );
  const equipCameraString = captureDoc[0]?.equipCamera
    ? ` - ${captureDoc[0]?.equipCamera}`
    : '';
  return (
    <AccordionPanel
      label={
        <Text margin="small">
          {`${teamHook[0]?.nameLong || data.streamName}: ${
            data.isUp ? 'up' : 'down'
          } : ${Duration.fromMillis(
            data.pollData?.connectionTimeMs || 0
          ).toFormat('hh:mm:ss')}${equipCameraString}`}
        </Text>
      }
    >
      <Box pad="medium" background="light-2">
        <ResponsiveContext.Consumer>
          {(size) => {
            let columns;
            if (size === 'small') {
              columns = ['auto', 'auto'];
            } else if (size === 'medium') {
              columns = ['auto', 'auto', 'auto'];
            } else {
              columns = ['auto', 'auto', 'auto', 'auto'];
            }

            return (
              <Grid columns={columns} gap="small">
                {gatewayStreamFields
                  .filter((f) => !shdpro || !f.adminOnly)
                  .map((field) => (
                    <DataElement
                      key={field.label}
                      docs={{
                        main: data,
                        capture: captureDoc[0],
                        team: teamHook[0],
                        user: userDoc[0],
                      }}
                      field={field}
                    />
                  ))}
              </Grid>
            );
          }}
        </ResponsiveContext.Consumer>
      </Box>
    </AccordionPanel>
  );
};

const gatewayFields: VengineFieldDesc[] = [
  { label: 'Id', adminOnly: true, doc: 'main', propGet: (data) => data._id },
  {
    label: 'Worker Id',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data._workerId,
  },
  {
    label: 'Created',
    doc: 'main',
    propGet: (data) => data.createdTs,
    dtype: 'datetime',
  },
  {
    label: 'Heartbeat',
    doc: 'main',
    propGet: (data) => data.tsHeartbeat,
    dtype: 'heartbeat',
  },
  {
    label: 'Host IP',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data.hostIpExternal,
  },
];

const Gateway: React.FC<{
  data: docStorage.VideoEngineWorkerDoc;
  shdpro: boolean;
}> = ({ data, shdpro }) => {
  // need to make streams stable by sorting by id, otherwise they jump around
  const streams = React.useMemo(
    () =>
      Object.values(data.streamsByHandle || {}).sort((a, b) =>
        a.streamName.localeCompare(b.streamName)
      ),
    [data.streamsByHandle]
  );
  const streamsUpCount = React.useMemo(
    () => streams.filter((stream) => stream.isUp).length,
    [streams]
  );
  const streamsDownCount = React.useMemo(
    () => streams.filter((stream) => !stream.isUp).length,
    [streams]
  );
  const [copied, setCopied] = React.useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 5000);
    }
  }, [copied]);
  return (
    <AccordionPanel
      key={data._id}
      label={
        <Text margin="small">{`${data.hostname} (${streamsUpCount} up / ${streamsDownCount} down)`}</Text>
      }
    >
      <Box pad="medium" background="light-2">
        <ResponsiveContext.Consumer>
          {(size) => {
            let columns;
            if (size === 'small') {
              columns = ['auto', 'auto'];
            } else if (size === 'medium') {
              columns = ['auto', 'auto', 'auto'];
            } else {
              columns = ['auto', 'auto', 'auto', 'auto'];
            }

            return (
              <Grid columns={columns} gap="small">
                {gatewayFields
                  .filter((f) => !shdpro || !f.adminOnly)
                  .map((field) => (
                    <DataElement
                      key={field.label}
                      docs={{ main: data }}
                      field={field}
                    />
                  ))}
              </Grid>
            );
          }}
        </ResponsiveContext.Consumer>
        <Heading level={5}>Streams</Heading>
        <Accordion>
          {streams.map((stream) => (
            <GatewayStream
              key={stream.streamName}
              data={stream}
              shdpro={shdpro}
            />
          ))}
        </Accordion>
        <Box pad="medium" gap="small" justify="start" direction="row">
          <CopyToClipboard
            text={JSON.stringify(data)}
            onCopy={() => setCopied(true)}
          >
            <Button
              primary
              label={copied ? 'Copied' : 'Copy JSON'}
              style={{ width: 'auto' }}
            />
          </CopyToClipboard>
        </Box>
      </Box>
    </AccordionPanel>
  );
};

const AllVengines: React.FC<{
  data: docStorage.VideoEngineWorkerDoc[];
  shdpro: boolean;
  setHlsUrl: (url: string) => void;
}> = ({ data, shdpro, setHlsUrl }) => (
  <Accordion>
    <Heading level={2}>VEngines</Heading>
    {data.map((vengine) => (
      <Vengine
        key={vengine._id}
        data={vengine}
        shdpro={shdpro}
        setHlsUrl={setHlsUrl}
      />
    ))}
  </Accordion>
);

const vengineFields: VengineFieldDesc[] = [
  { label: 'Id', adminOnly: true, doc: 'main', propGet: (data) => data._id },
  {
    label: 'Worker Id',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data._workerId,
  },
  {
    label: 'Created',
    doc: 'main',
    propGet: (data) => data.createdTs,
    dtype: 'datetime',
  },
  {
    label: 'Heartbeat',
    doc: 'main',
    propGet: (data) => data.tsHeartbeat,
    dtype: 'heartbeat',
  },
  {
    label: 'Capture Id',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data.assignedCaptureId,
  },
  {
    label: 'Capture Name',
    doc: 'capture',
    propGet: (data) => data.equipCamera,
  },
  { label: 'Team Id', doc: 'main', propGet: (data) => data.assignedTeamId },
  { label: 'Team Name', doc: 'team', propGet: (data) => data.nameLong },
  { label: 'User', doc: 'user', propGet: (data) => data.email },
  { label: 'Hls Url', doc: 'main', propGet: (data) => data.assignedUrlHls },
];

const Vengine: React.FC<{
  data: docStorage.VideoEngineWorkerDoc;
  shdpro: boolean;
  setHlsUrl: (url: string) => void;
}> = ({ data, shdpro, setHlsUrl }) => {
  // need to make streams stable by sorting by id, otherwise they jump around
  const streams = React.useMemo(
    () =>
      Object.values(data.streamsByHandle || {}).sort((a, b) =>
        a.streamName.localeCompare(b.streamName)
      ),
    [data.streamsByHandle]
  );
  const teamHook = useTeamDoc(theFirebase().firestore, data.assignedTeamId);
  const captureDoc = useCaptureDoc(
    theFirebase().firestore,
    data.assignedCaptureId
  );
  const userDoc = useUserDoc(
    theFirebase().firestore,
    captureDoc[0]?.userId || captureDoc[0]?.modifiedUser
  );
  const { assignedUrlOvl, nginxAddrLocal, nginxAddrExternal } = data;
  const overlayUrl = getVeWorkerOverlayUrl(
    assignedUrlOvl,
    nginxAddrLocal,
    nginxAddrExternal
  );

  const teamLink = data.assignedTeamId ? (
    <Link to={`/${data.assignedTeamId}`}>
      {teamHook[0]?.nameHandle} ({teamHook[0]?.attrib_sportType})
    </Link>
  ) : null;
  const [copied, setCopied] = React.useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 5000);
    }
  }, [copied]);
  return (
    <AccordionPanel
      key={data._id}
      label={
        <Box direction="row" gap="small" justify="between" flex="grow">
          <Text margin="small">
            {`${data.hostname}:${data.workerIndex}`}
            {streams[0] ? ` (${streams[0]?.isUp ? 'up' : 'down'})` : ''}
            {data.assignedTeamId ? ' - ' : ''}
            {teamLink}
            {captureDoc[0]?.equipCamera ? ' - ' : ''}
            {captureDoc[0]?.equipCamera}
          </Text>
          {data.assignedUrlHlsProxy && (
            <Box direction="row">
              <SHDButton
                label="Watch Live"
                hoverIndicator={true}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setHlsUrl(data.assignedUrlHlsProxy ?? '');
                }}
              />

              <SHDButton
                label="Watch on SidelineHD"
                hoverIndicator={true}
                onClick={(ev) => {
                  window.open(`/live/${data.assignedTeamId}/`, '_blank');
                }}
              />
            </Box>
          )}
        </Box>
      }
    >
      <Box pad="medium" background="light-2">
        <ResponsiveContext.Consumer>
          {(size) => {
            let columns;
            if (size === 'small') {
              columns = ['auto', 'auto'];
            } else if (size === 'medium') {
              columns = ['auto', 'auto', 'auto'];
            } else {
              columns = ['auto', 'auto', 'auto', 'auto'];
            }

            return (
              <Grid columns={columns} gap="small">
                {vengineFields
                  .filter((f) => !shdpro || !f.adminOnly)
                  .map((field) => (
                    <DataElement
                      key={field.label}
                      docs={{
                        main: data,
                        team: teamHook[0],
                        capture: captureDoc[0],
                        user: userDoc[0],
                      }}
                      field={field}
                    />
                  ))}
              </Grid>
            );
          }}
        </ResponsiveContext.Consumer>
        {streams.length > 0 && (
          <Heading level={5} margin="medium">
            Stream Info
          </Heading>
        )}
        <Accordion>
          {streams.map((stream) => (
            <VengineStream
              key={stream.streamName}
              data={stream}
              shdpro={shdpro}
            />
          ))}
        </Accordion>
        <Box pad="medium" gap="small" justify="start" direction="row">
          <CopyToClipboard
            text={JSON.stringify(data)}
            onCopy={() => setCopied(true)}
          >
            <Button
              primary
              label={copied ? 'Copied' : 'Copy JSON'}
              style={{ width: 'auto' }}
            />
          </CopyToClipboard>
        </Box>{' '}
      </Box>
    </AccordionPanel>
  );
};

const vengineStreamFields: StreamFieldDesc[] = [
  {
    label: 'Id',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data.streamName,
  },
  {
    label: 'StreamBwIn',
    adminOnly: true,
    doc: 'main',
    propGet: (data) =>
      `${Math.round((data.pollData?.streamBwIn ?? 0) / 1000) || 0}K`,
  },
  {
    label: 'Stream Meta',
    doc: 'main',
    propGet: (data) => data.pollData?.streamMetaAvTxt,
  },
  {
    label: 'Publishing',
    doc: 'main',
    propGet: (data) => data.pollData?.isPublishing?.toString(),
  },
  {
    label: 'Recording',
    doc: 'main',
    propGet: (data) => data.pollData?.isRecording?.toString(),
  },
  { label: 'Stream Up', doc: 'main', propGet: (data) => data.isUp?.toString() },
  {
    label: 'Connection Time',
    doc: 'main',
    propGet: (data) => data.pollData?.connectionTimeMs || 0,
    dtype: 'duration',
  },

  {
    label: 'Source IP',
    adminOnly: true,
    doc: 'main',
    propGet: (data) => data.pollData?.sourceIp,
  },
];

const VengineStream: React.FC<{
  data: docStorage.models.StreamInfo;
  shdpro: boolean;
}> = ({ data, shdpro }) => (
  <AccordionPanel
    label={
      <Text margin="small">
        {`${data.isUp ? 'up' : 'down'} : ${Duration.fromMillis(
          data.pollData?.connectionTimeMs || 0
        ).toFormat('hh:mm:ss')}`}
      </Text>
    }
  >
    <Box pad="medium" background="light-2">
      <ResponsiveContext.Consumer>
        {(size) => {
          let columns;
          if (size === 'small') {
            columns = ['auto', 'auto'];
          } else if (size === 'medium') {
            columns = ['auto', 'auto', 'auto'];
          } else {
            columns = ['auto', 'auto', 'auto', 'auto'];
          }

          return (
            <Grid columns={columns} gap="small">
              {vengineStreamFields
                .filter((f) => !shdpro || !f.adminOnly)
                .map((field) => (
                  <DataElement
                    key={field.label}
                    docs={{ main: data }}
                    field={field}
                  />
                ))}
            </Grid>
          );
        }}
      </ResponsiveContext.Consumer>
    </Box>
  </AccordionPanel>
);

const adminCondition = (authUser: AugmentedUser | null) =>
  authUser?.claims?.is_admin;

const shdproCondition = (authUser: AugmentedUser | null) =>
  authUser?.claims?.is_shdpro ||
  authUser?.email?.endsWith('@diamondkinetics.com');

export const AdminStreamboard = withAuthorization(adminCondition)(
  withLayout(withFirebase(StreamBoard))
);

const withShdpro =
  (Component: React.ComponentType<StreamBoardProps>) =>
  (props: StreamBoardProps) => <Component {...props} shdpro={true} />;

export const ShdproStreamboard = compose<
  StreamBoardProps,
  Omit<StreamBoardProps, keyof FirebaseComponentProps>
>(
  withShdpro,
  withAuthorization(shdproCondition),
  withLayout,
  withFirebase
)(StreamBoard);
