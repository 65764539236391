// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// React component to render a playlist

import { Box } from 'grommet';
import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import videojs from 'video.js';
import videojsPlaylistPlugin from 'videojs-playlist';
import 'videojs-overlay';
import ReactDOMServer from 'react-dom/server';
import * as Sentry from '@sentry/browser';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';

import axios from '../../helpers/axios';

import './playlist.css';
import { analytics } from '../../services/analytics';

const PlaylistPlayer = ({ key, options, playlist }) => {
  const container = useRef();
  const player = useRef();

  const getOverlay = (currentIndex) => {
    if (currentIndex >= playlist.length) {
      window.location.reload();
    }
    const currentItem = playlist[currentIndex + 1];

    if (currentItem) {
      const overlayData = currentItem.sources[0].data;
      const html = ReactDOMServer.renderToString(
        <div className="info-card">
          <h1 style={{ fontSize: '36px', textAlign: 'center' }}>
            {overlayData.matchupName}
          </h1>
          <h1 style={{ textAlign: 'center' }}>{overlayData.subtitle}</h1>
        </div>
      );

      return html;
    }
    return 'Overlay';
  };

  useEffect(() => {
    videojs.registerPlugin('playlist', videojsPlaylistPlugin);
    // videojs.registerPlugin('overlay', videojsOverlayPlugin);
    const initPlayer = videojs(container.current, options);

    initPlayer.playlist(playlist);
    initPlayer.playlist.autoadvance(4);

    player.current = initPlayer;

    player.current.onplay = () => {
      analytics.track('Playlist Play', {
        playlistId: key,
      });
    };

    player.current.on('error', () => {
      window.location.reload();
    });

    player.current.ready(() => {
      const promise = player.current.play();

      if (promise !== undefined) {
        promise
          .then(() => {
            // Autoplay started!
          })
          .catch((error) => {
            Sentry.captureException(error);
          });
      }
    });

    return () => {
      player.current.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const handleEnded = () => {
    player.current.overlay({
      content: 'Default overlay content',
      // debug: true,
      overlays: [
        {
          content: getOverlay(player.current.playlist.currentItem()),
          start: 'ended',
          end: 'play',
          align: 'top',
        },
      ],
    });
  };

  return (
    <div data-vjs-player key={key}>
      <video
        style={{
          width: '100%',
          height: '100%',
          // objectFit: 'cover',
        }}
        ref={container}
        className="video-js vjs-theme-fantasy vjs-16-9"
        onEnded={handleEnded}
        playsInline
      />
    </div>
  );
};

const Playlist = () => {
  const [playlist, setPlaylist] = useState();

  const { playlistId } = useParams();

  useEffect(
    () => {
      axios
        .get(`/api/playlist/${playlistId}`)
        .then((response) => {
          setPlaylist(response.data.playlistSources);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const videoJsOptions = {
    // autoplay: true,
    muted: true,
    controls: true,
    responsive: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
    preload: 'metadata',
  };

  return (
    <Box>
      {playlist && (
        <PlaylistPlayer
          key={playlistId}
          options={videoJsOptions}
          playlist={playlist}
        />
      )}
    </Box>
  );
};

export default Playlist;
