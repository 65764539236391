// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import useWebRTCStream from './useWebRTCStream';

import {
  Container,
  PlayerWrapper,
  PlayerHeader,
  PlayerTitle,
  SelectRow,
  SelectLabel,
  SelectControl,
  PlayerSelectGroup,
  PlayerControlBar,
  Video,
  PlayerPlaceholder,
  ControlsWrapper,
  LiveModeZoomContainer,
} from '../styles';

import { ON_CLOSE, TITLE } from '../../../constants/urlKeysConstants';
import StreamControlButton from './StreamControlButton/index';
import {
  STATUSES,
  BANDWIDTHS,
  ZOOM_MAX,
  ZOOM_MIN,
  ZOOM_STEPS,
} from './useWebRTCStream/constants';
import Toast from '../../../components/Toast';

import StreamStatusBar from './StreamStatusBar/index';
import RangeSlider from './RangeSlider/index';
import CodecHelpButton from './CodecHelpButton/index';
import MicrophoneButton from './MicrophoneButton/index';
import CloseButton from './CloseButton/index';
import { strogging } from '@shd/jslib/infra';

function StreamPlayer() {
  const [toast, setToast] = useState(null);

  const playerRef = useRef(null);

  const {
    codecList,
    codecValue,
    onChangeCodec,
    bandwidthValue,
    onChangeBandwidth,
    isEnabledMicro,
    handleToggleMicro,
    resolutionList,
    resolutionValue,
    onChangeResolution,
    videoInputsList,
    videoInputValue,
    onChangeVideoInput,
    streamStatus,
    streamHealth,
    streamInfo,
    streamBitRate,
    streamErrors,
    isPortrait,
    cameraZoom,
    handleCameraZoom,
    isSetting,
    isReady,
    startStream,
    stopStream,
    restartStream,
    autoRestartCount,
    resetAutoRestartCount,
    showHEVCHelp,
  } = useWebRTCStream({ playerRef });

  useEffect(() => {
    if (autoRestartCount >= 5) {
      setToast({
        label: 'Poor network connection. Video quality will be affected.',
        background: 'status-critical',
      });
      resetAutoRestartCount();
    }
  }, [autoRestartCount, resetAutoRestartCount]);

  const appTitle =
    new URL(document.location).searchParams.get(TITLE) ?? 'sidelineCAM';
  const onCloseURL = new URL(document.location).searchParams.get(ON_CLOSE);
  const isAdvanced = true; // new URL(document.location).searchParams.get(ADVANCED) === '1';

  const isDisabled =
    streamStatus !== STATUSES.disconnected && streamStatus !== STATUSES.error;
  const showControls = streamStatus === STATUSES.disconnected;
  const isShowCodecDropdown = codecList.length > 1 || isAdvanced;
  const sliderStep = Number((ZOOM_MAX - ZOOM_MIN) / ZOOM_STEPS);
  const history = useHistory();

  const onCloseHandler = () => {
    stopStream('close-cam')
      .then(() => {
        history.replace(onCloseURL);
      })
      .catch((e) => strogging.exception('close-cam:exception', e));
  };

  return (
    <Container>
      <PlayerWrapper>
        {isPortrait && <PlayerPlaceholder />}
        <Video muted autoPlay playsinline ref={playerRef} />
      </PlayerWrapper>
      <ControlsWrapper>
        <PlayerHeader show={showControls}>
          <PlayerTitle>{appTitle}</PlayerTitle>
          {onCloseURL && <CloseButton onClick={onCloseHandler} />}
        </PlayerHeader>
        <LiveModeZoomContainer show={!showControls}>
          {!showControls && (
            <SelectRow>
              <SelectLabel
                style={{
                  backgroundColor: '#eeeeee60',
                  padding: '10px',
                  borderRadius: 999,
                }}
              >
                Zoom
              </SelectLabel>
              <RangeSlider
                isDisabled={!isReady || isSetting}
                value={cameraZoom}
                min={ZOOM_MIN}
                max={ZOOM_MAX}
                step={sliderStep}
                onInput={handleCameraZoom}
              />
            </SelectRow>
          )}
        </LiveModeZoomContainer>
        <PlayerSelectGroup show={showControls}>
          {isPortrait && (
            <SelectRow>
              <SelectLabel>Zoom</SelectLabel>
              <RangeSlider
                isDisabled={!isReady || isSetting}
                value={cameraZoom}
                min={ZOOM_MIN}
                max={ZOOM_MAX}
                step={sliderStep}
                onInput={handleCameraZoom}
              />
            </SelectRow>
          )}
          {isAdvanced && (
            <SelectRow>
              <SelectLabel>Max kbps</SelectLabel>
              <SelectControl
                isDisabled={!isReady || isSetting}
                isSearchable={false}
                value={bandwidthValue}
                options={BANDWIDTHS}
                onChange={onChangeBandwidth}
              />
            </SelectRow>
          )}
          {isShowCodecDropdown && (
            <>
              <SelectRow>
                {showHEVCHelp && <CodecHelpButton />}
                <SelectLabel>Codec</SelectLabel>
                <SelectControl
                  isDisabled={!isReady || isSetting || isDisabled}
                  isSearchable={false}
                  value={codecValue}
                  options={codecList}
                  isOptionDisabled={(option) => option.disabled}
                  onChange={onChangeCodec}
                />
              </SelectRow>
            </>
          )}
          {isAdvanced && (
            <SelectRow>
              <SelectLabel>Max Resolution</SelectLabel>
              <SelectControl
                isDisabled={!isReady || isSetting}
                isSearchable={false}
                value={resolutionValue}
                options={resolutionList}
                onChange={onChangeResolution}
              />
            </SelectRow>
          )}
          <SelectRow>
            {!isPortrait && (
              <>
                <SelectLabel>Zoom</SelectLabel>
                <RangeSlider
                  isDisabled={!isReady || isSetting}
                  value={cameraZoom}
                  min={ZOOM_MIN}
                  max={ZOOM_MAX}
                  step={sliderStep}
                  onInput={handleCameraZoom}
                />
              </>
            )}
            <SelectLabel style={{ marginLeft: '15px' }}>Camera</SelectLabel>
            <SelectControl
              isDisabled={!isReady || isSetting || isDisabled}
              isSearchable={false}
              value={videoInputValue}
              options={videoInputsList}
              onChange={onChangeVideoInput}
            />
          </SelectRow>
        </PlayerSelectGroup>
        <PlayerControlBar>
          <StreamStatusBar
            streamStatus={streamStatus}
            streamHealth={streamHealth}
            streamInfo={streamInfo}
            codecValue={codecValue.label}
            streamBitRate={streamBitRate}
            streamErrors={streamErrors}
            isAdvanced={isAdvanced}
          />
          <MicrophoneButton
            isEnabled={isEnabledMicro}
            handleToggleMicro={handleToggleMicro}
          />
          <StreamControlButton
            isDisabled={!isReady || isSetting || isPortrait}
            status={streamStatus}
            onStart={startStream}
            onStop={() => stopStream('user-stop')}
            onError={restartStream}
          />
        </PlayerControlBar>
      </ControlsWrapper>
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={6000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Container>
  );
}

export default StreamPlayer;
