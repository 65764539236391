import { Avatar, Box, Button, Footer, Layer, Text } from 'grommet';
import useYoutubeConnection, { AuthStatus } from './useYoutubeConnection';
import { useEffect, useState } from 'react';
import { LinkPrevious, Youtube } from 'grommet-icons';

interface YoutubeLinkModalProps {
  status: AuthStatus;
  onClose: () => void;
}
const YoutubeLinkModal: React.FC<YoutubeLinkModalProps> = ({
  status,
  onClose,
}) => {
  const title = status.channel
    ? 'Stream to YouTube'
    : 'Your account was not linked';

  const getSubtitle = () => {
    let subtitle;
    if (status.channel) {
      subtitle = `Success!`;
    } else if (status.error) {
      subtitle = status.error.message;
    }
    return subtitle;
  };

  const getDescription = () => {
    let description;
    if (status.channel) {
      description = 'You have successfully connected your YouTube channel.';
    }
    return description;
  };
  return (
    <Layer onClickOutside={onClose}>
      <Box direction="row" align={'center'} justify={'between'} pad={'large'}>
        <Box alignSelf="start" onClick={onClose}>
          <LinkPrevious size="24px" />
        </Box>
        <Text weight="bold" alignSelf="center">
          {title}
        </Text>
        <Box alignSelf="end" width={'24px'} />
      </Box>
      <Box pad="medium" gap="small">
        <Text weight="bold">{getSubtitle()}</Text>
        <Text>{getDescription()}</Text>
        {status.channel && (
          <Box gap="medium">
            <Box direction="row" align="center" gap="medium">
              <Avatar
                src={status.channel.channelInfo.snippet.thumbnails.default.url}
              />
              <Text weight={'bold'}>
                {status.channel.channelInfo.snippet.title}
              </Text>
            </Box>
            <Text>You can now livestream to this channel</Text>
          </Box>
        )}
        {status.error && (
          <Box>
            <Button
              secondary
              label="Help"
              href={status.error.link}
              target="_blank"
            />
          </Box>
        )}
      </Box>
      <Footer background={'light-1'}>
        <Box pad="medium" fill>
          <Button
            label={
              <Text weight="bold">{status.channel ? 'Finish' : 'Back'}</Text>
            }
            onClick={onClose}
          />
        </Box>
      </Footer>
    </Layer>
  );
};

interface Props {
  teamId: string;
  isAdmin: boolean;
  showUnlink?: boolean;
}
const YoutubeButton: React.FC<Props> = ({
  teamId,
  isAdmin,
  showUnlink = false,
}) => {
  const [showModal, setShowModal] = useState(false);

  const { signIn, cancelSignin, status } = useYoutubeConnection({ teamId });
  useEffect(() => {
    if (status?.phase === 'completed') {
      setShowModal(true);
    }
  }, [status]);

  return (
    <Box skeleton={!status}>
      <Box direction="row" justify="between" align="center">
        {status?.channel ? (
          <a
            href={`https://www.youtube.com/channel/${status.channel.credsChannelId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              primary
              label={
                <Text size="small" weight={'bold'}>
                  {status?.channel.credsChannelName}
                </Text>
              }
              color="#FF0000"
              icon={<Youtube color="white" size="small" />}
            />
          </a>
        ) : (
          isAdmin && (
            <Button
              secondary
              label={
                <Text color="#FF0000" size="small" weight={'bold'}>
                  {status?.phase === 'in-progress'
                    ? 'Cancel connect'
                    : 'Connect YouTube'}
                </Text>
              }
              color="#FF0000"
              icon={<Youtube color="#FF0000" size="small" />}
              onClick={() => {
                status?.phase === 'in-progress' ? cancelSignin() : signIn();
              }}
            />
          )
        )}
        {showUnlink && status?.channel && (
          <Button secondary size="small" label="Unlink" onClick={() => {}} />
        )}
      </Box>
      {showModal && status && (
        <YoutubeLinkModal
          status={status}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default YoutubeButton;
