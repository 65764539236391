import React from 'react';
import { Box, Button } from 'grommet';
import {
  BASEBALL,
  COMBINED_BBSB_KEY,
  SOFTBALL,
  VOLLEYBALL,
  SPORTS_MAP,
} from '../../constants/strings';

interface SportToggleProps {
  teams: Array<{ attrib_sportType: string }>;
  selectedSport: string;
  onSportChange: (sport: string) => void;
}

const PlayerSportToggle: React.FC<SportToggleProps> = ({
  teams,
  selectedSport,
  onSportChange,
}) => {
  const sports = Array.from(
    new Set(teams.map((team) => team.attrib_sportType))
  );
  const bbIndex = sports.indexOf(BASEBALL.display);
  if (bbIndex !== -1) {
    sports[bbIndex] = COMBINED_BBSB_KEY;
  }

  const sbIndex = sports.indexOf(SOFTBALL.display);
  if (sbIndex !== -1) {
    sports[sbIndex] = COMBINED_BBSB_KEY;
  }

  const fixedSports = Array.from(new Set(sports));

  return (
    <Box margin="medium" direction="row" gap="medium">
      <Box direction="row" gap="small">
        {fixedSports.map((sport) => {
          if (sport === COMBINED_BBSB_KEY) {
            return (
              <Button
                key={sport}
                primary={selectedSport === COMBINED_BBSB_KEY}
                icon={<Box color="brand">{BASEBALL.icon}</Box>}
                onClick={() => onSportChange(COMBINED_BBSB_KEY)}
              />
            );
          }

          if (sport === VOLLEYBALL.display) {
            return (
              <Button
                key={sport}
                primary={selectedSport === 'vb'}
                icon={<Box color="brand">{VOLLEYBALL.icon}</Box>}
                onClick={() => onSportChange('vb')}
              />
            );
          }

          return (
            <Button
              key={sport}
              primary={selectedSport === sport.toLowerCase().replace(' ', '_')}
              icon={<Box color="brand">{SPORTS_MAP[sport].icon}</Box>}
              onClick={() =>
                onSportChange(sport.toLowerCase().replace(' ', '_'))
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default PlayerSportToggle;
