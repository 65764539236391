// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import HasPhoneNumber from './HasPhoneNumber/index';
import NoPhoneNumber from './NoPhoneNumber';

const PromptBody = ({
  teamPlayer,
  selectedPhone,
  setSelectedPhone,
  showPhoneInput,
  enteredPhone,
  setEnteredPhone,
  isConfirmSelectedPhone,
}) => (
  teamPlayer.invitePhoneNumberList.length && !showPhoneInput
    ? (
      <HasPhoneNumber
        selectedPhone={selectedPhone}
        setSelectedPhone={setSelectedPhone}
        teamPlayer={teamPlayer}
        isConfirmSelectedPhone={isConfirmSelectedPhone}
      />
    )
    : (
      <NoPhoneNumber
        setSelectedPhone={setSelectedPhone}
        setEnteredPhone={setEnteredPhone}
        enteredPhone={enteredPhone}
      />
    )
);

export default PromptBody;
