// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { StyledRangeInput, StyledRangeInputContainer } from '../../styles';

export default function RangeSlider({
  onInput, value, isDisabled, max, min, step,
}) {
  const handleChange = useCallback(({ target }) => onInput(target.value), [onInput]);

  return (
    <StyledRangeInputContainer>
      <StyledRangeInput
        id="camera-zoom-range-input"
        type="range"
        onChange={handleChange}
        isDisabled={isDisabled}
        max={max}
        min={min}
        value={value}
        step={step}
      />
    </StyledRangeInputContainer>
  );
}

RangeSlider.propTypes = {
  onInput: PropTypes.func.isRequired,
  value: PropTypes.number,
  isDisabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
};

RangeSlider.defaultProps = {
  isDisabled: false,
  value: '0',
  max: 100,
  min: 0,
  step: 1,
};
