// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */

/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */

import React, { useContext, useEffect } from 'react';
import useState from 'react-usestateref';

import { branch, compose } from 'recompose';
import { withRouter, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  RadioButtonGroup,
  Text,
  Spinner,
  Layer,
  Heading,
  Anchor,
} from 'grommet';
import {
  Calendar,
  Close,
  DownloadOption,
  FormClose,
  StatusGood,
} from 'grommet-icons';
import { IoBaseballOutline } from 'react-icons/io5';
import { DateTime } from 'luxon';

import ClickableDataTable from '../../helpers/ClickableDataTable';
import HLSPlayer from '../../helpers/HLSPlayer';
import withLayout from '../../helpers/withLayout';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import { withFirebase } from '../../services/Firebase/context';
import { analytics } from '../../services/analytics';
import useZendeskWidget from '../../helpers/useZendeskWidget';

import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { useIsEntitledToViewTeam } from '../../features/TeamPage/teamHooks';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../components/Paywall/paywall.types';

import PaywallLock from '../../components/Paywall/PaywallLock';
import PaywallCta from '../../components/Paywall/PaywallCta';

const day = 24 * 60 * 60;

const LAST_7 = 'last 7 days';
const LAST_MONTH = 'last month';

const ALL_HITS = 'hits';
const BEST_HITS = 'best hits';

const NoHitScope = () => {
  return (
    <Box gap="medium" color="transparent" pad="medium">
      <Text size="large" weight={'bold'}>
        No HitScope... yet!
      </Text>
      <Text>
        HitScope is a summary of your team’s batted balls all in one highlight
        video!
      </Text>
    </Box>
  );
};

const TeamsHitScope = ({
  firebase,
  teamIdsString: propTeamIds,
  isNewLivestreamerExperience = false,
}) => {
  const { teamids: urlTeamIds } = useParams();
  const teamIds = urlTeamIds || propTeamIds;

  const [loading, setLoading] = useState(false);

  const [teams, setTeams] = useState([]);
  const [isConvertingClips, setIsConvertingClips] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState({
    time: LAST_MONTH,
    type: BEST_HITS,
  });
  const [clips, setClips] = useState([]);
  const [m3u8Text, setM3u8Text] = useState();
  const [selectedClip, setSelectedClip] = useState();
  const [manualSelectedClip, setManualSelectedClip] = useState();

  const [toast, setToast] = useState(null);
  const [error, setError] = useState(null);

  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const [endAtTs, setEndAtTs] = useState(
    new Date().getTime() / 1000 - 31 * day
  );

  const userStore = useContext(UserContext);
  const paywallEnabled = useIsFeatureEnabled('monetization', userStore);

  const { status: entitlementStatus, loading: entitlementLoading } =
    useIsEntitledToViewTeam(teamIds);

  useZendeskWidget();

  const getClips = (shouldFetch = true) => {
    setLoading(true);
    if (shouldFetch) {
      axios
        .get(
          `/api/hitscope?team_ids=${teamIds}&ts=${endAtTs}&good=${
            selectedQuery.type === BEST_HITS
          }&qtype=${selectedQuery.type}`
        )
        .then((response) => {
          const { data } = response;

          setClips(
            response.data.clips.map((clip) => {
              const date = DateTime.fromMillis(
                clip.clipStartTs * 1000
              ).toLocal();
              const opponent = `${clip.teamNameMed[0]} @ ${clip.teamNameMed[1]}`;
              return {
                id: clip.id,
                date,
                opponent,
                outcome: clip.textDescriptionBrief,
                more: 'More',
                startTs: clip.startTs,
                endTs: clip.endTs,
                fileDownloadUrl: clip.fileDownloadUrl,
                playerId: clip.playerId,
              };
            })
          );
          setM3u8Text(data.m3u8_text);

          if (response.data.clips.length > 0) {
            setSelectedClip(response.data.clips[0]);
          }

          setIsConvertingClips(response.data.converting);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setError(err.response.data.message);
          } else {
            setError('Something went wrong. Please try again.');
          }
        });
    } else if (selectedQuery.time === LAST_MONTH) {
      console.log('TODO');
    } else if (selectedQuery.time === LAST_7) {
      console.log('TODO');
    }
  };

  const getTeams = async () => {
    const localTeams = [];
    for (const teamId of teamIds.split(',')) {
      // eslint-disable-next-line no-await-in-loop
      const res = await axios.get(`/api/team/${teamId}`);
      localTeams.push(res.data);
    }
    setTeams(localTeams);
  };

  useEffect(() => {
    getClips();

    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endAtTs, selectedQuery]);

  useEffect(() => {
    analytics.track('HitScope View', {
      type: 'Team',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClipChanged = (newClip) => {
    setSelectedClip(newClip);
  };

  if (!teamIds) {
    return <BasicLoader size={200} fullPage />;
  }

  const queryGrid = (
    <Box>
      <Box align="center" direction="row" gap="small">
        <Calendar />
        <RadioButtonGroup
          disabled={loading}
          name="radio"
          direction="row"
          gap="xsmall"
          options={[LAST_7, LAST_MONTH]}
          value={selectedQuery.time}
          onChange={(event) => {
            analytics.track('HitScope Quick Date Clicked', {
              type: 'team',
              teamIds,
            });
            if (event.target.value === LAST_7) {
              setEndAtTs(new Date().getTime() / 1000 - 7 * day);
            } else if (event.target.value === LAST_MONTH) {
              setEndAtTs(new Date().getTime() / 1000 - 31 * day);
            }
            setSelectedQuery({
              ...selectedQuery,
              time: event.target.value,
            });
            firebase.logAnalyticsEvent('hitscope_query_changed', {
              type: event.target.value,
            });
          }}
        >
          {(option, { checked, focus }) => {
            let background;
            if (checked) {
              background = 'brand';
            } else if (focus) {
              background = 'primary-1';
            } else {
              background = 'light-2';
            }
            return (
              <Box
                margin="xsmall"
                pad="small"
                background={background}
                label={option}
                round
              >
                <Text
                  margin={{ left: 'small', right: 'small' }}
                  size="small"
                  weight={checked ? 'bold' : 'normal'}
                >
                  {option}
                </Text>
              </Box>
            );
          }}
        </RadioButtonGroup>
      </Box>
      <Box align="center" direction="row" gap="small">
        <IoBaseballOutline style={{ height: '24px', width: '24px' }} />
        <RadioButtonGroup
          disabled={loading}
          name="radio"
          direction="row"
          gap="xsmall"
          options={[ALL_HITS, BEST_HITS]}
          value={selectedQuery.type}
          onChange={(event) => {
            analytics.track('HitScope Quick Outcome Clicked', {
              type: 'team',
              teamIds,
            });
            setSelectedQuery({
              ...selectedQuery,
              type: event.target.value,
            });
            firebase.logAnalyticsEvent('hitscope_query_changed', {
              type: event.target.value,
            });
          }}
        >
          {(option, { checked, focus }) => {
            let background;
            if (checked) {
              background = 'tertiary-1';
            } else if (focus) {
              background = 'primary-1';
            } else {
              background = 'light-2';
            }
            return (
              <Box
                margin="small"
                pad="small"
                background={background}
                label={option}
                round
              >
                <Text
                  margin={{ left: 'small', right: 'small' }}
                  size="small"
                  weight={checked ? 'bold' : 'normal'}
                >
                  {option}
                </Text>
              </Box>
            );
          }}
        </RadioButtonGroup>
      </Box>
    </Box>
  );

  const clipsEmptyState = <Box>No clips in the selected time period</Box>;

  const renderClipsList = () => {
    if (loading) {
      return (
        <Box gap="small">
          <Spinner
            size="medium"
            message="Start Built-in Spinner Announcement"
          />
          {selectedQuery.time === LAST_MONTH ? (
            <Text>
              Loading all HitScope clips from the last month... Hang tight! this
              can take up to 15 seconds.
            </Text>
          ) : (
            <Text>Loading HitScope clips...</Text>
          )}
        </Box>
      );
    }

    if (clips.length > 0 && selectedClip) {
      return (
        <ClickableDataTable
          data={clips}
          selectedId={selectedClip.id}
          onSelected={(clip) => {
            analytics.track('HitScope Timestamp Clicked', {
              type: 'team',
              teamIds,
            });
            setManualSelectedClip(clip);
          }}
        />
      );
    }

    if (isConvertingClips) {
      return (
        <Box>Converting clips... Refresh page to start using HitScope!</Box>
      );
    }

    return clipsEmptyState;
  };

  const videoLoading = (
    <Box fill="horizontal" gap="small">
      <Spinner size="medium" message="Start Built-in Spinner Announcement" />
      <Text>Loading HitScope video...</Text>
    </Box>
  );

  const learnMoreModal = (
    <Layer
      onEsc={() => {
        setShowLearnMoreModal(false);
      }}
      modal
    >
      <Box width="large" pad="medium" gap="medium">
        <Box
          align="baseline"
          direction="row"
          justify="between"
          fill="horizontal"
        >
          <Heading level={3}>HitScope BETA</Heading>
          <Button
            icon={<Close />}
            onClick={() => {
              setShowLearnMoreModal(false);
            }}
          />
        </Box>
        <Text>
          HitScope BETA is a quick way to view all of a player’s batted ball
          events. In just 2 minutes, you can catch up on every hit from last
          week’s tournament.
        </Text>
        <Text margin={{ bottom: 'medium' }}>
          HitScope videos are updated as your player’s clip catalog grows!
          Viewers can filter the video by recent games or by productive at-bats.
        </Text>

        <Text margin={{ bottom: 'none' }}>Why are some clips missing?</Text>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Text>Your player must be claimed.</Text>
          </li>
          <li>
            <Text>Your team must be using sidelineHD team PRO.</Text>
          </li>
          <li>
            <Text>
              The at-bat clip must be assigned to your player. Assigned clips
              show on your player’s page.
            </Text>
          </li>
          <li>
            <Text>
              Our system detects ball contact events. To do this, the clip needs
              to have good quality video and audio.
            </Text>
          </li>
        </ul>
      </Box>
    </Layer>
  );

  const downloadToast = (
    <Layer
      position="bottom"
      modal={false}
      margin={{ vertical: 'medium' }}
      onEsc={() => {
        setToast(null);
      }}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad="medium"
        background="status-ok"
      >
        <Box gap="small">
          <Box align="center" direction="row" gap="small">
            <StatusGood />
            {toast && <Text>{toast.label}</Text>}
          </Box>
          {toast && toast.url ? (
            <Anchor href={toast.url} target="_blank">
              Download here
            </Anchor>
          ) : null}
        </Box>

        <Button
          icon={<FormClose />}
          onClick={() => {
            setToast(null);
          }}
          plain
        />
      </Box>
    </Layer>
  );

  const onDownloadClicked = () => {
    analytics.track('Download HitScope Clicked', {
      type: 'Team',
    });

    const toastData = {
      label: `We’re preparing your video from ${clips.length} at-bat${
        clips.length > 1 ? 's' : ''
      }.
      This may take a bit - please keep this page open!`,
      background: 'status-ok',
      duration: 60 * 1000,
    };
    setToast(toastData);
    axios
      .get(
        `/api/hitscope?download=1&team_ids=${teamIds}&ts=${endAtTs}&good=${
          selectedQuery.type === BEST_HITS
        }&qtype=${selectedQuery.type}`,
        {
          // responseType: 'blob',
          timeout: 600 * 1000,
        }
      )
      .then((response) => {
        setToast({
          label: 'Your HitScope video is ready!',
          url: response.data.url,
        });
      })
      .catch(() => {
        setToast({
          label: 'Uh oh something went wrong.',
        });
      });
  };

  return (
    <Box gap="large" width="xlarge" margin="0 auto" pad="medium">
      {!isNewLivestreamerExperience && (
        <Box justify="between" direction="row">
          <img
            src="/HitScopeLogo.svg"
            alt="hitscope logo"
            style={{ width: '50%', marginTop: '2vh' }}
          />
          <Button
            plain
            label={
              <Text color="secondary-1" size="small">
                How does this work?
              </Text>
            }
            onClick={() => {
              setShowLearnMoreModal(true);
            }}
          />
        </Box>
      )}
      {!isNewLivestreamerExperience && (
        <Box direction="row">
          <Text>
            {teams.length > 1 ? 'Combined video for ' : 'HitScope video for'}
            {teams.map((team) => (
              <Box key={team.info.nameHandle} direction="row">
                <Anchor
                  label={`${team.info.nameLong.trim()}`}
                  href={`${process.env.REACT_APP_BASE_URL}/${team.info.nameHandle}`}
                />
              </Box>
            ))}
          </Text>
        </Box>
      )}

      {paywallEnabled ? (
        <>
          {entitlementLoading ? (
            <BasicLoader />
          ) : (
            <>
              {entitlementStatus ===
                ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT && (
                <PaywallLock isPrivate={false} mode="hitscope" />
              )}
              {entitlementStatus ===
                ViewerEntitlementStatus.ENTITLED_BUT_PRIVATE && (
                <PaywallLock isPrivate={true} mode="hitscope" />
              )}
              {entitlementStatus ===
                ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN && (
                <PaywallCta context={PaywallContext.TEAM_HITSCOPE} />
              )}
              {entitlementStatus === ViewerEntitlementStatus.ENTITLED && (
                <>
                  {clips.length >= 1 ? (
                    <>
                      {m3u8Text ? (
                        <HLSPlayer
                          m3u8Text={m3u8Text}
                          clips={clips}
                          style={{ width: '100%' }}
                          onClipChanged={onClipChanged}
                          seekToClip={manualSelectedClip}
                        />
                      ) : (
                        videoLoading
                      )}
                      <Box direction="row" justify="between">
                        <Button
                          plain
                          color="secondary-1"
                          icon={
                            <DownloadOption size="20px" color="secondary-1" />
                          }
                          label={<Text size="small">Download</Text>}
                          id="download-clip-unclaimed"
                          onClick={onDownloadClicked}
                        />
                      </Box>

                      <Text>
                        Have feedback?{' '}
                        <Anchor
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeNT87DcBq1fMOrfDKLl_sBEmJxN8gRZI-rTIqpOSI3BW7jKw/viewform?usp=sf_link"
                          target="_blank"
                        >
                          Let us know!
                        </Anchor>
                      </Text>
                      {queryGrid}
                      <Box gap="small">
                        <Text>Jump to any AB by clicking on it.</Text>
                        {renderClipsList()}
                      </Box>
                      {showLearnMoreModal && learnMoreModal}
                      {error && <Text color="red">{error}</Text>}
                      {toast ? downloadToast : null}
                    </>
                  ) : (
                    <NoHitScope />
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {clips.length >= 1 ? (
            <>
              {m3u8Text ? (
                <HLSPlayer
                  m3u8Text={m3u8Text}
                  clips={clips}
                  style={{ width: '100%' }}
                  onClipChanged={onClipChanged}
                  seekToClip={manualSelectedClip}
                />
              ) : (
                videoLoading
              )}
              <Box direction="row" justify="between">
                <Button
                  plain
                  color="secondary-1"
                  icon={<DownloadOption size="20px" color="secondary-1" />}
                  label={<Text size="small">Download</Text>}
                  id="download-clip-unclaimed"
                  onClick={onDownloadClicked}
                />
              </Box>

              <Text>
                Have feedback?{' '}
                <Anchor
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeNT87DcBq1fMOrfDKLl_sBEmJxN8gRZI-rTIqpOSI3BW7jKw/viewform?usp=sf_link"
                  target="_blank"
                >
                  Let us know!
                </Anchor>
              </Text>
              {queryGrid}
              <Box gap="small">
                <Text>Jump to any AB by clicking on it.</Text>
                {renderClipsList()}
              </Box>
              {showLearnMoreModal && learnMoreModal}
              {error && <Text color="red">{error}</Text>}
              {toast ? downloadToast : null}
            </>
          ) : (
            <NoHitScope />
          )}
        </>
      )}
    </Box>
  );
};

export default compose(
  withFirebase,
  withRouter,
  branch(
    ({ isNewLivestreamerExperience }) => !isNewLivestreamerExperience,
    withLayout
  )
)(TeamsHitScope);
