import { Box, ResponsiveContext, Text } from 'grommet';
import React, { useContext } from 'react';
import { colWidthMinMax } from './games.style';
import { GameInfoProps } from './games.types';

import GameScore from './GameScore';

const GameInfo: React.FC<GameInfoProps> = ({ game, team, isUpcomingGame }) => {
  const screenSize = useContext(ResponsiveContext);

  let awayTeamName = game.J_opponentName;
  let homeTeamName = team?.nameMed;

  if (!game.scoreWeAreHome) {
    awayTeamName = team?.nameMed;
    homeTeamName = game.J_opponentName;
  }

  return (
    <Box
      direction="row"
      alignContent="center"
      flex
      justify="between"
      basis={screenSize === 'small' ? '2/3' : '60%'}
    >
      <Box direction="column" width={colWidthMinMax} justify="center">
        <Text weight={'bold'} size="medium" textAlign="start" truncate>
          {awayTeamName}
        </Text>
      </Box>
      <Box
        direction="column"
        width={colWidthMinMax}
        alignContent="center"
        justify="center"
      >
        {isUpcomingGame ? (
          <Text alignSelf="center" style={{ padding: '5px' }}>
            vs
          </Text>
        ) : (
          <GameScore
            homeScore={game.homeScore || 0}
            awayScore={game.awayScore || 0}
            teamHSetScores={game.teamHSetScores}
            teamASetScores={game.teamASetScores}
            sportType={game.sportType}
          />
        )}
      </Box>
      <Box direction="column" width={colWidthMinMax} justify="center">
        <Text weight={'bold'} size="medium" textAlign="end" truncate>
          {homeTeamName}
        </Text>
      </Box>
    </Box>
  );
};

export default GameInfo;
