// Packages
import React from 'react';
import {
  useRevenueCat,
  usePaywallTracking,
} from '../../components/Paywall/paywall.hooks';
import * as ROUTES from '../../constants/routes';
import SHDLink from '../../helpers/SHDLink';

// Components
import { BasicLoader } from '../../helpers/Loaders';
import { Box, Text } from 'grommet';
import { FormNext } from 'grommet-icons';
import PaywallCta from '../../components/Paywall/PaywallCta';

// Types
import { SettingsRevenueCatProps } from './types';
import {
  PaywallContext,
  PaywallEvents,
} from '../../components/Paywall/paywall.types';

const SettingsRevenueCat: React.FC<SettingsRevenueCatProps> = () => {
  const { isEntitled, activeSubscription, isLoadingRc } = useRevenueCat();
  const { trackPaywallEvent } = usePaywallTracking(
    PaywallContext.ACCOUNT_SETTINGS
  );

  return (
    <>
      {isLoadingRc ? (
        <BasicLoader />
      ) : (
        <Box id="settingsRevenueCat" margin={{ top: '1rem' }}>
          {!isEntitled ? (
            <PaywallCta
              mode="fullAccess"
              context={PaywallContext.ACCOUNT_SETTINGS}
            />
          ) : (
            <SHDLink
              to={ROUTES.SETTINGS__SUBSCRIPTION}
              onClick={() =>
                trackPaywallEvent(PaywallEvents.VIEW_MEMBERSHIP_DETAILS_CLICKED)
              }
            >
              <Box
                background="#f5f5f5"
                direction="row"
                justify="between"
                align="center"
                round="0.75rem"
                style={{ padding: '1.5rem' }}
              >
                <Box>
                  <Text size="0.875rem" color="#212121">
                    {activeSubscription.productName}
                  </Text>
                  <Text size="0.75rem" color="#808080">
                    {activeSubscription.productPrice}/
                    {activeSubscription.productTerm.short}
                  </Text>
                </Box>

                <FormNext fill="#000" />
              </Box>
            </SHDLink>
          )}
        </Box>
      )}
    </>
  );
};

export default SettingsRevenueCat;
