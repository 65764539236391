// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';

import { string } from 'yup';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import { FieldStyled, InputStyled, FieldWrapper } from './style';
import OnboardingButton from '../../../OnboardingButton';

const validationScheme = string()
  .required('Field is required')
  .min(5, 'Please enter a name 5 characters or more.')
  .max(18, 'Please enter a name 18 characters or fewer.');

const FieldNameMed = ({ value, handleForward }) => {
  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [error, setError] = useState(null);

  const validateOnEnter = useCallback(() => {
    validationScheme
      .validate(value.nameMed)
      .then(() => {
        setError(null);
        setIsValid(true);
        handleForward();
      })
      .catch((reason) => {
        setIsValid(false);
        setError(reason.message);
      });
  }, [value, handleForward]);

  useEffect(() => {
    validationScheme
      .validate(value.nameMed)
      .then(() => {
        setError(null);
        setIsValid(true);
      })
      .catch((reason) => {
        setIsValid(false);
        if (!isTouched) {
          return;
        }
        setError(reason.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">
            What nickname do you want to show on your teams’ livestreams?
          </FieldLabel>
          <FieldTip color="dark-4">* indicates required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled
          name="nameMed"
          error={error}
          info="18 characters or fewer."
          onChange={setIsTouched}
        >
          <InputStyled
            autoComplete="nope"
            name="nameMed"
            placeholder="Team nickname*"
            onKeyDown={(e) => {
              if (['Enter', 'NumpadEnter'].includes(e.key)) {
                e.preventDefault();
                validateOnEnter();
              }
            }}
          />
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton
        disabled={!isValid}
        label="Next"
        onClick={validateOnEnter}
      />
    </>
  );
};

export default FieldNameMed;
