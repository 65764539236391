// Packages
import React, { useState } from 'react';
import styles from '../Admin.module.css';

// Components
import { Box, Button, Text, Card } from 'grommet';
import AdminAvatar from './AdminAvatar';

// Types
import { AdminCardProps, ClaimedPlayerProps } from '../admin.types';

const AdminPlayerCard: React.FC<AdminCardProps<ClaimedPlayerProps>> = ({
  hasChangeButton,
  hasRemoveButton,
  onChangeClicked,
  onRemoveClicked,
  onSelectCard,
  payload,
}) => {
  // States
  const [isSelected, setIsSelected] = useState<boolean>(false);

  // Methods
  const handleCardClick = () => {
    if (hasChangeButton || hasRemoveButton) {
      return;
    }

    if (!isSelected) {
      setIsSelected(true);
      onSelectCard && onSelectCard(payload);
    } else {
      setIsSelected(false);
      onSelectCard && onSelectCard(null);
    }
  };

  return (
    <>
      {!payload ? (
        <p>No player selected.</p>
      ) : (
        <Card
          className={`
            ${styles['admin-player-card']} 
            ${isSelected ? styles['admin-player-card--selected'] : ''}
            ${hasChangeButton ? styles['admin-player-card--no-hover'] : ''}
          `}
          onClick={() => handleCardClick()}
          pad="medium"
        >
          <Box
            direction="row"
            align="center"
            justify="start"
            className={styles['player-info']}
          >
            <AdminAvatar src={payload.DNOR_imgUrlT150} />
            <Box margin={{ left: '1rem' }}>
              <Text className={styles['player-name']}>
                {payload.nameFirst} {payload.nameLast}
              </Text>

              <Text className={styles['player-handle']}>
                /{payload.nameHandle}
              </Text>
              <Text className={styles['player-id']}>{payload._id}</Text>
            </Box>
          </Box>
          {hasChangeButton && (
            <Button
              className={styles['change-button']}
              label="Change"
              onClick={() => onChangeClicked && onChangeClicked()}
            />
          )}
          {hasRemoveButton && (
            <Button
              className={styles['change-button']}
              label="Remove"
              onClick={() => onRemoveClicked && onRemoveClicked()}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default AdminPlayerCard;
