// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import Select from 'react-select';

import {
  basicRadius,
  basicGap,
  secondaryGrey,
  primaryColor,
  infoColor,
  dangerColor,
} from '../../constants/stylesConfig';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (orientation: landscape) {
  }
  @media screen and (orientation: portrait) {
  }
`;

export const PlayerWrapper = styled.div`
  @media screen and (orientation: landscape) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  // aspect-ratio: 16/9;
`;

export const PlayerPlaceholder = styled.div`
  position: absolute;
  top: 20px;
  left: 40px;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  background-color: #fff;
  background-image: url('/landscape-mode.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  aspect-ratio: 16/9;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  touch-action: none;
  object-fit: cover;
`;

export const LiveModeZoomContainer = styled.div`
  position: absolute;
  top: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  @media screen and (orientation: landscape) {
    z-index: 100;
  }
`;

export const PlayerHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: opacity 0.3s ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  @media screen and (orientation: landscape) {
    z-index: 100;
    background-color: #ffffff80;
  }
`;

export const PlayerTitle = styled.h3`
  flex: 1;
  color: black;
  margin-left: 10px;
  text-align: center;
`;

export const ControlsWrapper = styled.div`
  @media screen and (orientation: landscape) {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const PlayerSelectGroup = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: ${basicGap};
  background-color: #ffffff80;
  transition: opacity 0.3s ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${basicGap};
`;

export const SelectLabel = styled.label`
  color: black;
  display: block;
  margin-right: ${basicGap};
`;
export const SelectControl = styled(Select)`
  margin-right: 15px;
  min-width: 200px;
  border-radius: ${basicRadius};
  flex-grow: 1;
`;

export const CodecLink = styled.a`
  display: block;
  margin-right: 15px;
  @media screen and (orientation: portrait) {
    font-size: 12px;
    margin-left: 10px;
    line-height: 1.2;
    flex-shrink: 1;
  }
`;

export const PlayerControlBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff80;
  padding-top: ${basicGap};
  padding-bottom: ${basicGap};
`;

export const StyledStatusBar = styled.div`
  padding: 10px;
  color: black;
  flex: 1;
  margin-right: ${basicGap};
  margin-left: ${basicGap};
`;

const buttonColors = {
  primary: primaryColor,
  secondary: secondaryGrey,
  info: infoColor,
  danger: dangerColor,
};

const getButtonColor = (color) => buttonColors[color] ?? buttonColors.secondary;

export const PlayerButton = styled.button`
  padding: 10px 20px;
  background: ${({ color }) => getButtonColor(color)};
  border-radius: ${basicRadius};
  font-weight: bold;
  min-width: 150px;
  cursor: pointer;
  margin-left: ${basicGap};
  margin-right: ${basicGap};
  border: 0px solid transparent;
`;

export const MicButton = styled.button`
  border: 0px solid transparent;
  height: 37px;
  width: 37px;
  border-radius: 50%;
  background: ${({ isEnabled }) => (isEnabled ? primaryColor : secondaryGrey)};
  cursor: pointer;
  margin-right: ${basicGap};
`;

export const StyledRangeInput = styled.input`
  width: 100%;
`;

export const StyledRangeInputContainer = styled.div`
  box-sizing: border-box;
  width: 200px;
  margin-right: 15px;
`;
