// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { UserContext } from './context';
import { withFirebase } from '../Firebase';

const withAuthorization = (condition) => (Component) =>
  compose(
    withRouter,
    withFirebase
  )(
    observer((props) => {
      const userStore = useContext(UserContext);
      return condition(userStore.authUser) ? <Component {...props} /> : null;
    })
  );

export default withAuthorization;
