// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box, Text } from 'grommet';
import { FormPrevious } from 'grommet-icons';

import { HeaderWrapper, BackButton } from './style';

const FormHeader = ({
  currentStep = 0, totalSteps = 1, buttonLabel = 'Back', buttonAction, isShownBackBtn = true,
}) => (
  <HeaderWrapper flex direction="row" justify="between" align="center">
    <Box>
      {
            isShownBackBtn && (
            <BackButton
              icon={<FormPrevious />}
              onClick={buttonAction}
              color="dark-1"
              label={<Text style={{ fontSize: '14px' }}>{buttonLabel}</Text>}
              weight="bold"
              size="medium"
            />
            )
        }
    </Box>
    <Box>
      <Text color="dark-1" size="small">
        {`Step ${currentStep} of ${totalSteps}`}
      </Text>
    </Box>
  </HeaderWrapper>
);

export default FormHeader;
