import React from 'react';
import { CardHeader, Text } from 'grommet';
import { useTeamPlayer } from '../Player/playerHooks';

interface Props {
  teamPlayerIdToBeClaimed: string;
}
const ClaimPlayerHeader: React.FC<Props> = ({ teamPlayerIdToBeClaimed }) => {
  const { teamPlayer, error, loading } = useTeamPlayer(teamPlayerIdToBeClaimed);

  return (
    <CardHeader
      direction="column"
      gap="8px"
      align="start"
      skeleton={loading || !teamPlayer}
    >
      {error && <Text color="status-error">{error}</Text>}
      <Text size="20px" weight={'bold'}>
        Claim your player
      </Text>
      <Text>
        Sign in or register with your phone number to claim{' '}
        <Text weight={'bold'}>
          {teamPlayer?.nameFirst} {teamPlayer?.nameLast}
        </Text>
      </Text>
    </CardHeader>
  );
};

export default ClaimPlayerHeader;
