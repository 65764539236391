// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { STATUSES, STREAM_HEALTH_STATUSES } from '../useWebRTCStream/constants';
import { ERRORS_MESSAGES } from './constants';
import { StyledStatusBar } from '../../styles';

export default function StreamStatusBar({
  streamStatus,
  streamHealth,
  streamInfo,
  codecValue,
  streamBitRate,
  streamErrors,
  isAdvanced,
}) {
  if (streamStatus === STATUSES.disconnected) {
    return <StyledStatusBar>{streamStatus}</StyledStatusBar>;
  }

  if (streamStatus === STATUSES.error && streamErrors.length) {
    // eslint-disable-next-line no-console
    console.log(streamErrors);
    const errorMessage =
      ERRORS_MESSAGES[streamErrors[0]] ?? streamErrors.join(' ');
    return <StyledStatusBar>{errorMessage}</StyledStatusBar>;
  }

  const bitrate = isAdvanced ? `${streamBitRate} kbit/s` : '';

  let streamHealthIcon = '🟢';
  if (streamHealth === STREAM_HEALTH_STATUSES.bad) {
    streamHealthIcon = '🔴';
  } else if (streamHealth === STREAM_HEALTH_STATUSES.ok) {
    streamHealthIcon = '🟡';
  }

  return (
    <StyledStatusBar>
      {streamHealthIcon} {codecValue}{' '}
      {streamInfo.resolution ? `${streamInfo.resolution}p` : ''}{' '}
      {streamInfo.fps}
      fps {bitrate}
    </StyledStatusBar>
  );
}

StreamStatusBar.propTypes = {
  streamStatus: PropTypes.string,
  streamHealth: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  streamInfo: PropTypes.object,
  codecValue: PropTypes.string,
  streamBitRate: PropTypes.number,
  // streamErrors: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  streamErrors: PropTypes.any,
  isAdvanced: PropTypes.bool,
};

StreamStatusBar.defaultProps = {
  streamStatus: '',
  streamHealth: '',
  streamInfo: {},
  codecValue: '',
  streamBitRate: 0,
  streamErrors: [],
  isAdvanced: false,
};
