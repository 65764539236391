// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { string } from 'yup';
import { FieldStyled, FieldWrapper, RadioGroupStyled } from './style';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';

const seasonOptions = [
  { label: 'Spring 2024', value: 20241 },
  { label: 'Summer 2024', value: 20242 },
  { label: 'Fall 2024', value: 20243 },
  { label: 'Winter 2024', value: 20244 },
];

const validationScheme = string().required('Field is required');

const FieldTeamSeason = ({ value, handleForward }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validationScheme
      .validate(value.attrib_season)
      .then(() => {
        setIsValid(true);
      })
      .catch(() => {
        setIsValid(false);
      });
  }, [value]);

  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">
            What season is your team playing in?
          </FieldLabel>
          <FieldTip color="dark-4">Required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled name="attrib_season">
          <RadioGroupStyled
            name="attrib_season"
            valueKey={{ key: 'value', reduce: true }}
            placeholder="Select"
            options={seasonOptions}
          />
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton
        disabled={!isValid}
        label="Next"
        onClick={handleForward}
      />
    </>
  );
};

export default FieldTeamSeason;
