import { AugmentedUser } from '../../../services/Session/store';
import { Box, Heading } from 'grommet';
import { Firebase } from '../../../services/Firebase/firebase';
import { observer } from 'mobx-react';
import { shd } from '@shd/jslib/models';
import { StopFill } from 'grommet-icons';
import { useImpersonateUser } from '../admin.hooks';
import { UserContext } from '../../../services/Session/context';
import { withAuthorization } from '../../../services/Session';
import { withFirebase } from '../../../services/Firebase';
import FirebaseSearch from '../FirebaseSearch';
import React, { useContext, useState } from 'react';
import SHDButton from '../../../components/SHD/Button';
import Toast from '../../../components/Toast';
import UserSearch from '../UserSearch';
import withLayout from '../../../helpers/withLayout';

interface AdminSearchProps {
  firebase: Firebase;
}

interface Toast {
  label: string;
  background: string;
}

const AdminSearch: React.FC<AdminSearchProps> = observer(({ firebase }) => {
  const userStore = useContext(UserContext);
  const [toast, setToast] = useState<Toast | null>(null);
  const { impersonateStart, impersonateStop } = useImpersonateUser();

  const stopImpersonation = (
    <SHDButton
      primary
      size="medium"
      label="Stop impersonation"
      margin="medium"
      icon={<StopFill color="white" />}
      onClick={() => {
        impersonateStop(firebase);
      }}
    />
  );

  return (
    <div data-testid="admin">
      <Heading color="dark-1" level="1" margin="medium">
        AdminSearch Tools
      </Heading>
      {userStore.authUser?.spoofedUser ? (
        stopImpersonation
      ) : (
        <Box gap="small" margin="medium" direction="row">
          <UserSearch
            setUser={(user: shd.SQLUser) => impersonateStart(user, firebase)}
          />
        </Box>
      )}
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
      <FirebaseSearch
        setUser={(user: shd.SQLUser) => impersonateStart(user, firebase)}
      />
    </div>
  );
});

const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

export default withAuthorization(condition)(
  withLayout(withFirebase(AdminSearch))
);
