import React from 'react';
import { Box, Text } from 'grommet';
import { liveLinks } from './sidelineLive.styles';
import { useLiveAssets } from './liveHooks';
import YoutubeButton from '../../helpers/YoutubeButton';
import FacebookButton from '../../helpers/FacebookButton';
import { SidelineLiveLinksProps } from './sidelineLive.types';
import { liveLinksText } from './sidelineLive.styles';
import { useStyleHooks } from './liveStyleHooks';

const OnlyOnSidelineHD: React.FC = () => {
  return (
    <Box style={liveLinksText}>
      <Text size="small">Only available on sidelineHD</Text>
    </Box>
  );
};

export const SidelineLiveLinks: React.FC<SidelineLiveLinksProps> = ({
  teamId,
}) => {
  const { isDesktop } = useStyleHooks();
  const liveAssets = useLiveAssets(teamId);
  const facebook = liveAssets.find((asset) => asset.type === 'fb');
  const youtube = liveAssets.find((asset) => asset.type === 'yt');

  return (
    <Box
      style={liveLinks}
      direction="row"
      justify={isDesktop ? 'start' : 'between'}
      pad={'.5rem'}
    >
      <Box alignContent="center" justify="center" style={liveLinksText}>
        <Text size="small">Watch On:</Text>
      </Box>
      <Box direction="row" gap="small">
        {!facebook && !youtube && <OnlyOnSidelineHD />}
        {facebook && <FacebookButton teamId={teamId} isAdmin={false} />}
        {youtube && (
          <YoutubeButton teamId={teamId} isAdmin={false} showUnlink={false} />
        )}
      </Box>
    </Box>
  );
};
