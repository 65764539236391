import React from 'react';
import {
  Box,
  Text,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
} from 'grommet';

import { Apple, Facebook, Google, More } from 'grommet-icons';

import { formatPhoneNumber } from 'react-phone-number-input';

import SHDLink from '../../../helpers/SHDLink';
import SHDButton from '../../../components/SHD/Button';
import axios from '../../../helpers/axios';

import PlayerCard from './PlayerCard';
import TeamCard from './TeamCard';
import { UserCardProps } from '../admin.types';
import { apis, dtos, shd } from '@shd/jslib/models';
import { AxiosResponse } from 'axios';

const UserCard: React.FC<UserCardProps> = ({
  user,
  i,
  setCurrentAction,
  getClaimLink,
  setShowConfirmation,
  setUser,
}) => {
  const [players, setPlayers] = React.useState<shd.SQLUserPlayer[]>(
    user.players
  );
  const [testNotificationResponse, setTestNotificationResponse] =
    React.useState<dtos.NotificationTestMessageResponse>();
  const sendTestNotification = React.useCallback(async () => {
    const response = await axios.post<
      dtos.NotificationTestMessageResponse,
      AxiosResponse<dtos.NotificationTestMessageResponse>,
      dtos.NotificationTestMessageRequest
    >(apis.apiNotificationTestMessagePost(), {
      user_id: user.id,
      message: 'Test Message',
      title: 'Test Title',
      subtitle: 'Test Subtitle',
    });
    setTestNotificationResponse(response.data);
  }, [user.id]);
  return (
    <Card key={`${user.id}-${i - 1}`} margin="small">
      <CardHeader
        align="start"
        pad="medium"
        direction="column"
        background="light-3"
        gap="small"
      >
        <Box direction="row" justify="between" fill="horizontal">
          <Box direction="row" gap="small" align="center">
            <Heading level={4} margin={{ vertical: 'none' }}>
              {`${user.name}`}
            </Heading>
            {user.providers.map((provider) => {
              if (provider === 'google.com') {
                return <Google key={provider} size="small" />;
              }
              if (provider === 'facebook.com') {
                return <Facebook key={provider} size="small" />;
              }
              if (provider === 'apple.com') {
                return <Apple key={provider} size="small" />;
              }
              return null;
            })}
          </Box>
          <SHDLink to={`/admin/user/${user.id}`} target="_blank">
            <More />
          </SHDLink>
        </Box>
        <Text size="small">{user.email}</Text>
        <Box direction="row" gap="small" align="center">
          {user.phoneList.map((phone) => (
            <Text key={phone} size="small">
              {formatPhoneNumber(phone)}
            </Text>
          ))}
        </Box>
        <Box gap="medium" direction="row">
          <SHDButton
            primary
            label="Impersonate"
            onClick={() => {
              setUser(user);
            }}
          />
          <Button
            secondary
            label="Get Claim Link"
            onClick={() => {
              setCurrentAction({
                action: getClaimLink(user),
                text: 'Are you sure you want to get claim link? This will delete the user`s auth record.',
                actionName: 'Get Claim Link',
              });
              setShowConfirmation(true);
            }}
          />
          <Button
            secondary
            label="Send Test Notification"
            onClick={() => {
              sendTestNotification();
            }}
          />
        </Box>
        <Text
          size="small"
          color={
            testNotificationResponse?.result === 'success'
              ? 'status-ok'
              : 'error'
          }
        >
          {testNotificationResponse
            ? testNotificationResponse?.result === 'success'
              ? 'notification sent'
              : 'error'
            : ''}
        </Text>
      </CardHeader>
      <CardBody pad="small" gap="small">
        <Box gap="small">
          {user.teams.filter((team) => team.name).length > 0 && (
            <Text weight="bold">Teams</Text>
          )}
          {user.teams
            .filter((team) => team.name)
            .map((team) => (
              <TeamCard key={team.id} team={team} />
            ))}
        </Box>
        <Box gap="small">
          {players.filter((player) => player.name).length > 0 && (
            <Text weight="bold">Players</Text>
          )}
          {players
            .filter((player) => player.name)
            .map((player) => (
              <PlayerCard
                key={player.id}
                player={player}
                playerManagerId={user.id}
                setShowConfirmation={setShowConfirmation}
                setCurrentAction={setCurrentAction}
                setPlayers={setPlayers}
              />
            ))}
        </Box>
      </CardBody>
    </Card>
  );
};

export default UserCard;
