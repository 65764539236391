import React from 'react';
import ApiError from './ApiError';
import { Anchor, Box, Text } from 'grommet';

interface ApiErrorDisplayProps {
  error: ApiError | null;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const ApiErrorDisplay: React.FC<ApiErrorDisplayProps> = ({
  error,
  header = null,
  footer = null,
}) => {
  if (!error) {
    return null;
  }

  return (
    <Box
      pad="small"
      gap="small"
      border={{
        side: 'all',
        color: 'status-critical',
      }}
      round="small"
    >
      {header}
      <Box>
        <Text>{error.message}</Text>
        {error.details?.helpUrl && error.details?.helpText && (
          <Anchor href={error.details.helpUrl} target="_blank">
            {error.details.helpText}
          </Anchor>
        )}
      </Box>
      {footer}
      {/* {error.helpDocLink && <a href={error.helpDocLink}>Help Document</a>} */}
    </Box>
  );
};

export default ApiErrorDisplay;
