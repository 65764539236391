// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { Text, Form, Box, FormField, TextInput } from 'grommet';
import { Close } from 'grommet-icons';
import * as Sentry from '@sentry/browser';
import { FieldEmail, FieldVeloRange } from '../../helpers/FormFields';
import axios from '../../helpers/axios';
import SHDButton from '../../components/SHD/Button';

const RadarLayer = (props) => {
  const { setRadar, closeLayer, teamId, radarType } = props;
  const [value, setValue] = useState({
    email: '',
    appCode: '',
    version: 2,
    ignoreVeloUnder: 40,
    ignoreVeloOver: 90,
    radarType,
  });
  const [error, setError] = useState(null);

  const validateRadar = () => {
    axios
      .post(`/api/radar/${teamId}`, value)
      .then((response) => {
        if (response.data.pradar) {
          setRadar(response.data);
          closeLayer();
        } else if (response.data.message) {
          setError(response.data.message);
        }
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        if (respError.response.data) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. Please try again.');
        }
      });
  };

  return (
    <Box>
      <Box
        fill="horizontal"
        align="end"
        pad={{ top: '16px', horizontal: '16px' }}
      >
        <Close size="medium" onClick={closeLayer} />
      </Box>
      <Box pad="16px">
        <Form
          value={value}
          onChange={(nextValue) => {
            setValue(nextValue);
          }}
          onReset={() => setValue({})}
          onSubmit={validateRadar}
        >
          <Box gap="8px">
            {error && <Text color="red">{error}</Text>}
            {radarType === 'pr' ? (
              <FieldEmail label="Pocket Radar account email" />
            ) : (
              <Box>
                <FormField
                  name="appCode"
                  htmlFor="text-input-id"
                  label="StalkerSport app code"
                >
                  <Text>
                    To find your app code, go to SETTINGS &gt;&gt; sidelineHD in
                    the StalkerSport app.
                  </Text>
                  <TextInput
                    id="text-input-id"
                    name="appCode"
                    placeholder="xxxxx-xxxxx-xxxxx"
                  />
                </FormField>
              </Box>
            )}

            <FieldVeloRange
              ignoreVeloUnder={value.ignoreVeloUnder}
              ignoreVeloOver={value.ignoreVeloOver}
              handleChange={(event, [ignoreVeloUnder, ignoreVeloOver]) => {
                const newValue = { ...value };
                newValue.ignoreVeloUnder = ignoreVeloUnder;
                newValue.ignoreVeloOver = ignoreVeloOver;
                setValue(newValue);
              }}
            />
          </Box>
          <Box margin={{ top: '16px' }}>
            <SHDButton
              primary
              type="submit"
              label="Save"
              disabled={!value.email && !value.appCode}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default RadarLayer;
