// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const TITLE = 'title';

export const ON_CLOSE = 'onClose';

export const ADVANCED = 'advanced';

export const CONNECTION_URL = 'connectionUrl';

export const PIN = 'pin';
