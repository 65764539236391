// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-destructuring */
/* eslint-disable no-lonely-if */
/* eslint-disable no-underscore-dangle */

import { Box, Button, Card, Text, TextInput } from 'grommet';
import { Close } from 'grommet-icons';
import React, { useState } from 'react';
import Select from 'react-select';
import { formatDateShort } from '../../helpers/utils';

const VBReassignClip = ({
  game,
  clip,
  teamPlayers,
  onReassign,
  onClose,
  actionTitle,
  ...props
}) => {
  const [servingPlayer, setServingPlayer] = useState(
    clip.playerScorePlayerList[0].includes('TpSh01')
      ? teamPlayers.filter((p) => p._id === clip.playerScorePlayerList[0])[0]
      : null
  );

  let spotlightPlayer;
  let defaultStat;
  const _highlightPlayers = [];
  Object.entries(clip.teamPlayerActionDict).forEach(([key, value]) => {
    if (key.includes('TpSh01')) {
      if (Object.keys(value).includes('ace')) {
        spotlightPlayer = teamPlayers.filter((p) => p._id === key)[0];
        defaultStat = {
          value: 'A',
          label: 'Ace',
        };
      } else if (Object.keys(value).includes('block')) {
        spotlightPlayer = teamPlayers.filter((p) => p._id === key)[0];
        defaultStat = {
          value: 'B',
          label: 'Block',
        };
      } else if (Object.keys(value).includes('kill')) {
        spotlightPlayer = teamPlayers.filter((p) => p._id === key)[0];
        defaultStat = {
          value: 'K',
          label: 'Kill',
        };
      }

      if (Object.keys(value).includes('highlight')) {
        _highlightPlayers.push(teamPlayers.filter((p) => p._id === key)[0]);
      }
    }
  });

  const [primaryPlayer, setPrimaryPlayer] = useState(spotlightPlayer);
  const [highlightPlayers, setHighlightPlayers] = useState(_highlightPlayers);
  const _assistPlayers = Object.entries(clip.teamPlayerActionDict)
    .filter(([key, value]) => {
      if (key.includes('TpSh01')) {
        if (Object.keys(value).includes('assist')) {
          if (teamPlayers.filter((p) => p._id === key).length > 0) {
            return true;
          }
        }
      }
      return false;
    })
    .map(([key]) => {
      const players = teamPlayers.filter((p) => p._id === key);
      return players[0];
    });

  const _digPlayers = Object.entries(clip.teamPlayerActionDict)
    .filter(([key, value]) => {
      if (key.includes('TpSh01')) {
        if (Object.keys(value).includes('dig')) {
          if (teamPlayers.filter((p) => p._id === key).length > 0) {
            return true;
          }
        }
      }
      return false;
    })
    .map(([key]) => {
      const players = teamPlayers.filter((p) => p._id === key);
      return players[0];
    });
  const [assistPlayers, setAssistPlayers] = useState(_assistPlayers);
  const [digPlayers, setDigPlayers] = useState(_digPlayers);
  const [stat, setStat] = useState(defaultStat);
  const [highlightDescription, setHighlightDescription] = useState(
    clip.highlightDescription || ''
  );
  const ogPlayer = primaryPlayer;

  const actionTitleDiv = (
    <Box gap="xsmall" height={{ min: 'unset' }}>
      <Text weight="bold" size="large">
        {`${actionTitle} - Set ${clip.setNum} (${clip.teamAScore}-${clip.teamHScore})`}
      </Text>
      <Text margin={{ top: 'medium' }}>Change the statistic for the clip</Text>
      <Select
        options={[
          { value: 'K', label: 'Kill' },
          { value: 'B', label: 'Block' },
          { value: 'A', label: 'Ace' },
          { value: 'N', label: 'None' },
        ]}
        defaultValue={defaultStat}
        onChange={(option) => {
          setStat(option);
        }}
        isDisabled={!stat || stat.value === 'N'}
      />
      <Text
        margin={{ top: 'medium' }}
      >{`Great ${actionTitle}? Give some credit!`}</Text>
      <Select
        placeholder="Select player..."
        options={teamPlayers}
        defaultValue={primaryPlayer}
        onChange={(option) => {
          setPrimaryPlayer(option);
        }}
      />
    </Box>
  );

  const noStatDiv = (
    <Box height={{ min: 'unset' }}>
      <Text weight="bold" size="large">
        {`Set ${clip.setNum}`}
      </Text>
      <Text margin={{ top: 'medium' }}>Add a statistic to the clip</Text>
      <Select
        options={[
          { value: 'K', label: 'Kill' },
          { value: 'B', label: 'Block' },
          { value: 'A', label: 'Ace' },
          { value: 'N', label: 'None' },
        ]}
        defaultValue={{ value: '', label: 'None' }}
        onChange={(option) => {
          setStat(option);
        }}
      />
      <Text margin={{ top: 'medium' }}>Give credit for the clip!</Text>
      <Select
        placeholder="Select player..."
        options={teamPlayers}
        defaultValue={primaryPlayer}
        onChange={(option) => {
          setPrimaryPlayer(option);
        }}
        isDisabled={!stat || stat.value === 'N'}
      />
    </Box>
  );

  const headerDiv = (
    <Box as="header" gap="medium" flex={{ grow: 0, shrink: 0 }}>
      <Box direction="row" justify="between" fill="horizontal">
        <Text size="2xl" weight="bold">
          Tag players in clip
        </Text>
        {props.showClose && (
          <Button icon={<Close />} onClick={onClose} hoverIndicator />
        )}
      </Box>
      <Card gap="small" background="secondary-7" round="small">
        <Box direction="row" justify="between" pad="medium" gap="medium">
          <Box>
            <Text size="large" weight="bold">{`${
              game ? game.scoreOurName : ''
            }`}</Text>
            <Text size="large" weight="bold">{`vs ${
              game ? game.J_opponentName : ''
            }`}</Text>
            <Text size="medium">{formatDateShort(clip.clipStartTs)}</Text>
          </Box>
        </Box>
      </Card>
    </Box>
  );

  const highlightDiv = (
    <Box height={{ min: 'unset' }} gap="xsmall">
      <Text weight="bold" size="large">
        Highlight
      </Text>
      <Text margin={{ top: 'medium' }}>Add a highlight to the clip</Text>
      <TextInput
        placeholder="Highlight description"
        value={highlightDescription}
        onChange={(e) => setHighlightDescription(e.target.value)}
      />
      <Select
        placeholder="Select players..."
        options={teamPlayers}
        defaultValue={highlightPlayers}
        onChange={(option) => {
          setHighlightPlayers(option);
        }}
        isMulti
      />
    </Box>
  );

  return (
    <Box width="large" pad="medium" gap="medium" fill="vertical">
      {headerDiv}
      <Box gap="medium" overflow="scroll" fill="vertical" flex>
        {actionTitle ? actionTitleDiv : noStatDiv}
        {highlightDiv}
        <Box gap="small" height={{ min: 'unset' }}>
          <Text weight="bold" size="large">
            Server
          </Text>
          <Select
            placeholder="Select player..."
            options={teamPlayers}
            defaultValue={servingPlayer}
            onChange={(option) => {
              setServingPlayer(option);
            }}
          />
        </Box>
        {/* <Box height="2px" background="dark-5" margin={{ bottom: '' }} /> */}
        <Box gap="small" height={{ min: 'unset' }}>
          <Text weight="bold" size="large">
            Assists
          </Text>
          <Select
            placeholder="Select players..."
            options={teamPlayers}
            defaultValue={assistPlayers}
            onChange={(options) => {
              setAssistPlayers(options);
            }}
            isMulti
          />
        </Box>
        {/* <Box height="2px" background="dark-5" margin={{ bottom: '' }} /> */}
        <Box gap="small" height={{ min: 'unset' }} margin={{ bottom: '240px' }}>
          <Text weight="bold" size="large">
            Digs
          </Text>
          <Select
            placeholder="Select players..."
            options={teamPlayers}
            defaultValue={digPlayers}
            onChange={(options) => {
              setDigPlayers(options);
            }}
            // onClick={executeScroll}
            isMulti
          />
        </Box>
        {/* <Box height="large" background="primary-1" fill="horizontal" /> */}
      </Box>

      <Box as="footer">
        <Button
          primary
          size="large"
          color="tertiary-0"
          label={<Text weight="bold">Done</Text>}
          onClick={() => {
            onReassign({
              primaryPlayer,
              stat,
              ogPlayer,
              assistPlayers,
              digPlayers,
              servingPlayer,
              highlightDescription,
              highlightPlayers,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default VBReassignClip;
