import React from 'react';
import {
  collection,
  limit,
  query,
  where,
  doc,
  QuerySnapshot,
  FirestoreError,
  Firestore,
  CollectionReference,
  DocumentReference,
} from 'firebase/firestore';
import {
  useCollection,
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import { strogging } from '@shd/jslib/infra';
import { docStorage } from '@shd/jslib/models';

export const useFirstItemFromQueryResult = <T>(
  queryHook: [QuerySnapshot<T> | undefined, boolean, FirestoreError | undefined]
) => {
  const [snapshot, loading, error] = queryHook;
  if (error) {
    strogging.error('useFirstItemFromQueryResult', { err: error.message });
  }
  return React.useMemo(
    () => [snapshot?.docs[0]?.data(), loading, error],
    [snapshot, loading, error]
  );
};
export const useVeWorkerByAssignedTeamId = (
  fs: Firestore,
  teamId: string | undefined
) => {
  const q = teamId
    ? query(
        collection(fs, 'veworker-v1'),
        where('assignedTeamId', '==', teamId),
        limit(1)
      )
    : undefined;
  return useFirstItemFromQueryResult(useCollection(q));
};

export const useCaptureDoc = (fs: Firestore, docId: string | undefined) =>
  useDocumentData(
    docId
      ? (doc(
          fs,
          'capture02',
          docId
        ) as DocumentReference<docStorage.CaptureDoc>)
      : undefined
  );
export const useUserDoc = (fs: Firestore, docId: string | undefined) =>
  useDocumentData(
    docId
      ? (doc(fs, 'user01', docId) as DocumentReference<docStorage.UserDoc>)
      : undefined
  );
export const useTeamDoc = (fs: Firestore, docId: string | undefined) =>
  useDocumentData(
    docId
      ? (doc(fs, 'team01', docId) as DocumentReference<docStorage.TeamDoc>)
      : undefined
  );
export const useClaimedPlayerDoc = (fs: Firestore, docId: string | undefined) =>
  useDocumentData(
    docId
      ? (doc(
          fs,
          'claimed-player',
          docId
        ) as DocumentReference<docStorage.ClaimedPlayerDoc>)
      : undefined
  );
export const useVeWorkers = (fs: Firestore) =>
  useCollectionData(
    collection(
      fs,
      'veworker-v1'
    ) as CollectionReference<docStorage.VideoEngineWorkerDoc>
  );
export const useReelDoc = (fs: Firestore, docId: string | undefined) =>
  useDocumentData(docId ? doc(fs, 'assets-playlist-v1', docId) : undefined);
export const useStreamlogQueries = (fs: Firestore) =>
  useCollectionData(
    collection(
      fs,
      'streamlog-query'
    ) as CollectionReference<docStorage.StreamlogQueryDoc>
  );

export const useFbGroupInstallDoc = (
  fs: Firestore,
  docId: string | undefined
) =>
  useDocumentData(
    docId
      ? (doc(
          fs,
          'fb-group-installs',
          docId
        ) as DocumentReference<docStorage.FbGroupInstallDoc>)
      : undefined
  );
