// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { ResponsiveContext } from 'grommet';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useIsFeatureEnabled, useQuery } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import axios from '../../helpers/axios';

export default function useGame(history, match, gameType, propsGameData) {
  const { hash } = useLocation();
  const userStore = useContext(UserContext);
  const gameGodMode = useIsFeatureEnabled('view_all_clips_enabled', userStore);
  const partnerClipViewerEnabled = useIsFeatureEnabled(
    'partner_clip_viewer_enabled',
    userStore
  );
  const size = useContext(ResponsiveContext);
  const query = useQuery();
  const showOpponentStatus = parseInt(query.get('opponent'));
  const highlightPlayerId = query.get('highlight_player_id');
  useEffect(() => {
    if (highlightPlayerId) {
      history.replace({
        ...history.location,
        search: `?highlight_player_id=${highlightPlayerId}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightPlayerId]);

  const [showPlayerPreview, setShowPlayerPreview] =
    useState(!!highlightPlayerId);
  const isEmbed = query.get('embed'); // TODO: can we pass with from the layout?
  const { gameId } = match.params;

  const [activeIndex, setActiveIndex] = useState([0]);

  const [gameData, setGameData] = useState(propsGameData);
  const [clips, setClips] = useState();
  const [recapData, setRecapData] = useState();
  const [boxScoreData, setBoxScoreData] = useState();
  const [players, setPlayers] = useState({});
  const [activeClip, setActiveClip] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [toast, setToast] = useState();
  const [gameSummaryTabIndex, setGameSummaryTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [gameSummaries, setGameSummaries] = useState();
  const [showReassignClip, setShowReassignClip] = useState(false);
  const [removeRecapTab, setRemoveRecapTab] = useState(false);
  const [hlsResponse, setHlsResponse] = useState();

  const vodEnabled = useIsFeatureEnabled('game_vod', userStore, [
    gameData.DNOR_teamId,
  ]);

  const accordionRef = useRef();

  const availableTabs = useMemo(() => {
    const retval = removeRecapTab ? [] : ['recap'];
    retval.push('boxScore', 'pbp');
    if (gameType === 'bbsb') {
      retval.push('highlights');
    }

    if (vodEnabled && hlsResponse?.isSuccess) {
      retval.push('vod');
    }
    return retval;
  }, [gameType, removeRecapTab, vodEnabled, hlsResponse]);

  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    const initialTab = hash.slice(1) || 'pbp';
    const index = availableTabs.indexOf(initialTab);
    if (index !== -1) {
      return index;
    }
    if (availableTabs.includes('pbp')) {
      return availableTabs.indexOf('pbp');
    }
    return 0;
  });

  useEffect(() => {
    const hashValue = availableTabs[activeTabIndex];
    if (hashValue) {
      history.replace({
        ...history.location,
        hash: `#${availableTabs[activeTabIndex]}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  useEffect(() => {
    const adjustedTabIndex = availableTabs.indexOf(hash.slice(1));
    if (adjustedTabIndex !== -1) {
      setActiveTabIndex(adjustedTabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTabs]);

  useEffect(() => {
    if (showOpponentStatus) {
      history.replace({
        ...history.location,
        search: `?opponent=${showOpponentStatus}`,
      });
    }
    if (showOpponentStatus === 2) {
      setGameSummaryTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOpponentStatus]);

  const isTeamAdmin = () => {
    if (gameGodMode || isEmbed || partnerClipViewerEnabled) {
      return true;
    }

    if (!userStore.authUser) {
      return false;
    }

    const teamId = gameData.DNOR_teamId;
    const teamIds = userStore.userTeams
      .map((userTeam) => userTeam._id)
      .concat(userStore.teamFollowerIds)
      .concat(userStore.coachTeamIds);

    return teamIds.includes(teamId);
  };

  useEffect(() => {
    setLoading(true);
    if (gameData) {
      axios
        .get(`/api/team/${gameData.DNOR_teamId}/team_players`, {
          params:
            gameType === 'vb'
              ? {
                  useClaimedPlayerPrivacy: true,
                }
              : undefined,
        })
        .then((response) => {
          const teamPlayerDocs = response.data.players;
          const playersDict = Object.assign(
            {},
            ...teamPlayerDocs.map((player) => ({ [player._id]: player }))
          );
          setPlayers(playersDict);
        })
        .catch((respError) => {
          // setError('Something went wrong. Please refresh and try again.');
          Sentry.captureException(respError);
        });
    }
  }, [gameData, gameType]);

  useEffect(() => {
    if (gameType === 'bbsb') {
      axios
        .get(`/api/game_summary_video/${gameId}`)
        .then((response) => {
          setGameSummaries(response.data);
          setLoading(false);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
    if( gameType !== 'bbsb'){
    axios
      .get(`/api/team_game/${gameId}/clips`)
      .then((response) => {
        setClips(response.data.clips);
        setLoading(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(`/api/team_game/${gameId}/box_score`)
      .then((response) => {
        setBoxScoreData(response.data);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vodEnabled) {
      axios
        .get(`/api/team_game/${gameId}/hls`)
        .then((response) => {
          setHlsResponse(response.data);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  }, [gameId, vodEnabled]);

  useEffect(() => {
    axios
      .get(`/api/team_game/${gameId}/recap`)
      .then((response) => {
        if ('recapText' in response.data.recap) {
          setRecapData(response.data);
        } else {
          setRemoveRecapTab(true);
        }
      })
      .catch((respError) => {
        setRemoveRecapTab(true);
        Sentry.captureException(respError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeVideo = () => {
    setShowVideo(false);
    if (gameType === 'vb') {
      setShowReassignClip(false);
    }
  };

  return {
    players,
    isEmbed,
    showOpponentStatus,
    activeClip,
    setActiveClip,
    setShowVideo,
    gameId,
    userStore,
    activeIndex,
    accordionRef,
    setActiveIndex,
    closeVideo,
    isTeamAdmin,
    clips,
    gameData,
    setClips,
    setGameData,
    loading,
    showPlayerPreview,
    highlightPlayerId,
    setShowPlayerPreview,
    size,
    gameSummaries,
    gameSummaryTabIndex,
    setGameSummaryTabIndex,
    activeTabIndex,
    setActiveTabIndex,
    availableTabs,
    boxScoreData,
    recapData,
    showVideo,
    toast,
    setToast,
    showReassignClip,
    setShowReassignClip,
    hlsResponse,
  };
}
