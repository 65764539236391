import React from 'react';
import { Box, Text } from 'grommet';
import { analytics } from '../../services/analytics';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { FormNext } from 'grommet-icons';

const PlayerClaimPrompt: React.FC<{ setShowClaim: (val: boolean) => void }> = ({
  setShowClaim,
}) => {
  return (
    <Box margin="small">
      <Box
        style={{
          backgroundImage: 'linear-gradient(to right, #7e68c0, #f9dc98)',
        }}
        direction="row"
        pad="medium"
        align="center"
        justify="between"
        round="small"
        onClick={() => {
          analytics.track('Claim this Player Clicked');
          setShowClaim(true);
        }}
      >
        <Box direction="row" align="center" gap="small">
          <IconContext.Provider value={{ color: 'white' }}>
            <AiFillQuestionCircle />
          </IconContext.Provider>
          <Text weight="bold" color="white" size="large">
            Is this you? Claim this player
          </Text>
        </Box>
        <Box>
          <FormNext color="white" />
        </Box>
      </Box>
    </Box>
  );
};
export default PlayerClaimPrompt;
