// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';

import {
  Heading,
  Card,
  Text,
  CardHeader,
  Box,
} from 'grommet';

export const CardHeading = styled(Heading)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
`;

export const CardStyled = styled(Card)`
  height: 248px;
  border-radius: 12px;
  margin-bottom: 50px;
`;

export const CardHeaderStyled = styled(CardHeader)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
`;

export const CardTitle = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const CardSubTitle = styled(Text)`
  font-weight: 400;
  font-size: 12px;
`;

export const SettingsStyled = styled(Box)`
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
`;

export const ButtonLinkStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 12px;
`;

export const LinkTextWrapper = styled(Box)`
  margin-left: 16px;
  font-weight: 400;
  font-size: 16px;
`;

export const LinkTitle = styled(Text)`
  font-weight: 600;
`;

export const MainHeading = styled(Heading)`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px;
`;

export const CardText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px;
`;
