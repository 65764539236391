import React, { useEffect, useState } from 'react';
import { Box, Image, Text } from 'grommet';
import { useParams } from 'react-router-dom';
import axios from '../../helpers/axios';

import LessonCard from './LessonCard';
import CoachesCard from './CoachesCard';
import { backgroundImage } from './dkplayerRecommendations.styles';
import { AxiosResponse } from 'axios';
import useAppFlyer from '../../helpers/useAppsFlyer';

import { Weekly } from './dkplayerRecommentdations.types';
import GetDKCard from './GetDKCard';

const DKPlayerRecommendations: React.FC = () => {
  const { playerId } = useParams<{ playerId: string }>();
  const [payload, setPayload] = useState<Weekly>();
  const [loading, setLoading] = useState(payload === undefined);
  const { fetchActivityLink, fetchAppStoreLink } = useAppFlyer();

  useEffect(() => {
    axios
      .get(`/api/claimed_player/${playerId}/activity_recommendations`)
      .then((response: AxiosResponse) => {
        if (response.data.errorList.length <= 0) {
          setPayload(response?.data);
        } else {
          //   setError(response.data.errorList[0]);
        }
      });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box id="recommendations" background={'#1A1A1A'} skeleton={loading}>
      {payload && (
        <Box style={backgroundImage}>
          <Box
            direction="row"
            gap="1rem"
            align="center"
            pad={{ vertical: '28px', horizontal: '20px' }}
            margin={{ bottom: '1rem' }}
          >
            <Image src="/dk_green_logo.png" width="32px" />
            <Text weight="bold">Lessons</Text>
          </Box>

          <Box margin={'1rem'}>
            <Text size="xxlarge" weight={'bolder'} color={'white'}>
              Hi {payload.nameFirst} &#x1F44B;
            </Text>
            <Text size="medium" weight="bold" color={'white'}>
              {payload.ctaText}
            </Text>
            <Text size={'small'} style={{ opacity: '0.5', marginTop: '1rem' }}>
              Powered by Diamond Kinetics
            </Text>
          </Box>
          <LessonCard
            recommendations={payload.activityRecommendList}
            category={payload.activityRecommendCategory}
            sport={payload.sportType}
            gradYear={payload.yearGraduation}
            fetchActivityLink={fetchActivityLink}
          />
          <GetDKCard
            sport={payload?.sportType}
            gradYear={payload?.yearGraduation}
            appStoreLink={fetchAppStoreLink()}
          />
        </Box>
      )}
      {payload && <CoachesCard sport={payload?.sportType} />}
    </Box>
  );
};

export default DKPlayerRecommendations;
