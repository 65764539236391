import React from 'react';
import { Box, Text } from 'grommet';
import { shd } from '@shd/jslib/models';
import { MdLocationOn } from 'react-icons/md';
import { FaGraduationCap } from 'react-icons/fa';
import {
  BBSB_SPORTS_KEYS,
  BASKETBALL,
  SOCCER,
  VOLLEYBALL,
} from '../../constants/strings';

interface SportInfoProps {
  player: shd.ClaimedPlayer;
  selectedSport: string;
}

const Position: React.FC<{ position: string }> = ({ position }) => (
  <Text size="large" weight="bold">
    {position}
  </Text>
);

const Handedness: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <Box direction="row" gap="xsmall">
    <Text size="large">{label}:&nbsp;</Text>
    <Text size="large" weight="bold">
      {value}
    </Text>
  </Box>
);

const BBSBInfo: React.FC<{ player: shd.ClaimedPlayer }> = ({ player }) => (
  <>
    <Position position={player.positionPrimary} />
    <Box gap="small" direction="row">
      {player.handednessThrowing && (
        <Handedness label="Throws" value={player.handednessThrowing} />
      )}
      {player.handednessBatting && (
        <Handedness label="Bats" value={player.handednessBatting} />
      )}
    </Box>
  </>
);

const VolleyballInfo: React.FC<{ player: shd.ClaimedPlayer }> = ({
  player,
}) => (
  <>
    <Position position={player.positionPrimaryVB} />
    {player.handedness && (
      <Handedness label="Handedness" value={player.handedness} />
    )}
  </>
);

const BasketballInfo: React.FC<{ player: shd.ClaimedPlayer }> = ({
  player,
}) => (
  <>
    <Position position={player.positionPrimaryBasketball} />
    {player.handedness && (
      <Handedness label="Handedness" value={player.handedness} />
    )}
  </>
);

const SoccerInfo: React.FC<{ player: shd.ClaimedPlayer }> = ({ player }) => (
  <>
    <Position position={player.positionPrimarySoccer} />
    {player.handedness && (
      <Handedness label="Footedness" value={player.handedness} />
    )}
  </>
);

const PlayerSportInfo: React.FC<SportInfoProps> = ({
  player,
  selectedSport,
}) => {
  if (!player) {
    return null;
  }

  let SportSpecificInfo: React.FC<{ player: shd.ClaimedPlayer }> | null = null;

  if (BBSB_SPORTS_KEYS.includes(selectedSport)) {
    SportSpecificInfo = BBSBInfo;
  } else if (selectedSport === VOLLEYBALL.key) {
    SportSpecificInfo = VolleyballInfo;
  } else if (selectedSport === BASKETBALL.key) {
    SportSpecificInfo = BasketballInfo;
  } else if (selectedSport === SOCCER.key) {
    SportSpecificInfo = SoccerInfo;
  }

  if (!SportSpecificInfo) {
    return null;
  }

  return (
    <Box gap="medium" direction="row">
      <SportSpecificInfo player={player} />
    </Box>
  );
};

const CollegeCommitStatus: React.FC<{ player: shd.ClaimedPlayer }> = ({
  player,
}) => {
  if (
    !player.collegeCommitStatus ||
    player.collegeCommitStatus === 0 ||
    player.collegeCommitStatus === 20
  ) {
    return null;
  }

  const commitText =
    player.collegeCommitStatus === 5 ? 'Uncommitted' : 'Committed';

  return (
    <Box direction="row" gap="small">
      <FaGraduationCap />
      <Box>
        <Text size="large" weight="bold">
          {commitText}
        </Text>
        {player.collegeCommitStatus === 10 && player.collegeCommitName && (
          <Text>{player.collegeCommitName}</Text>
        )}
      </Box>
    </Box>
  );
};

interface PlayerInfoCardProps {
  player: shd.ClaimedPlayer;
  selectedSport: string;
}

const PlayerInfoCard: React.FC<PlayerInfoCardProps> = ({
  player,
  selectedSport,
}) => {
  const hasInfo =
    player?.hometown ||
    player?.yearGraduation ||
    player?.positionPrimary ||
    player?.positionPrimaryVB ||
    player?.handednessThrowing ||
    player?.handednessBatting ||
    player?.height ||
    player?.weight ||
    player?.gpa;

  if (!hasInfo) {
    return null;
  }

  return (
    <Box
      pad="medium"
      gap="small"
      background={{ color: 'white' }}
      round="medium"
    >
      <Box gap="medium" direction="row">
        {player.yearGraduation === 0}
        {player.hometown && (
          <>
            <MdLocationOn />
            <Text size="large">{player.hometown}</Text>
          </>
        )}
        {player.yearGraduation !== 0 && (
          <>
            <Text size="large">Class of&nbsp;</Text>
            <Text size="large" weight="bold">
              {player.yearGraduation}
            </Text>
          </>
        )}
      </Box>
      <PlayerSportInfo player={player} selectedSport={selectedSport} />
      <Box gap="medium" direction="row">
        {player.height && (
          <Text size="large" weight="bold">
            {player.height}
          </Text>
        )}
        {player.weight && (
          <>
            <Text size="large" weight="bold">
              {player.weight}
            </Text>
            <Text size="large">lbs</Text>
          </>
        )}
        {player.gpa && (
          <>
            <Text size="large" weight="bold">
              {player.gpa}
            </Text>
            <Text size="large">&nbsp;GPA</Text>
          </>
        )}
      </Box>
      <CollegeCommitStatus player={player} />
    </Box>
  );
};

export default PlayerInfoCard;
