// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Form,
  Heading,
  ResponsiveContext,
  Text,
} from 'grommet';
import * as Sentry from '@sentry/browser';
import { Camera } from 'grommet-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import Toast from '../../components/Toast';
import { FieldEmail } from '../../helpers/FormFields';
import SHDButton from '../../components/SHD/Button';
import PhoneNumberInput from '../../helpers/PhoneNumberInput';
import { secondsToDaysAndHours } from '../../helpers/utils';

const Invites = ({
  teamId = '',
  claimedPlayerId = '',
  nameToManage = '',
  tooltip = null,
}) => {
  const [loading, toggleLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [coAdmins, setCoAdmins] = useState([]);
  const [invitesRemaining, setInvitesRemaining] = useState(0);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState();
  const [invited, toggleInvited] = useState(false);
  const [sending, toggleSending] = useState(false);

  const size = useContext(ResponsiveContext);

  const getUserInvites = () => {
    axios
      .get(
        `/api/user_invites${teamId ? `?teamId=${teamId}` : ''}${
          claimedPlayerId ? `?claimedPlayerId=${claimedPlayerId}` : ''
        }`
      )
      .then((response) => {
        setInvites(response.data.invites);
        setInvitedUsers(response.data.invitedUsers);
        setCoAdmins(response.data.coAdmins);
        setInvitesRemaining(response.data.remaining);
        toggleLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError(e.toString());
        toggleLoading(false);
      });
  };

  useEffect(() => {
    getUserInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCode = () => {
    toggleSending(true);
    const body = {
      invitedUserEmail: email.trim(),
      invitedUserPhone: phoneNumber.trim(),
      teamId,
      claimedPlayerId,
    };
    axios
      .post('/api/user_invites', body)
      .then((response) => {
        const { invites: newInvites, remaining } = response.data;
        toggleSending(false);
        if (newInvites) {
          setInvites([...invites, ...newInvites]);
        }

        if (remaining) {
          setInvitesRemaining(remaining);
        }
        toggleInvited(email);
        setEmail('');
        setPhoneNumber('');
        setError(null);
      })
      .catch((e) => {
        toggleSending(false);
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError(e.toString());
        }
      });
  };

  const onValidPhone = (phoneValue) => {
    if (phoneValue) {
      setPhoneNumber(phoneValue);
      setPhoneError(null);
    } else {
      setPhoneNumber('');
    }
  };

  const onErrorPhone = (respError) => {
    if (respError) {
      setPhoneError(true);
      setPhoneNumber('');
    } else {
      setPhoneError(null);
    }
  };

  if (loading) {
    return <BasicLoader size={100} />;
  }

  const invitesEmptyState = (
    <Card background="light-2" elevation="none">
      <CardBody>
        <Box pad="medium">
          <Text>
            Click above to generate an invite link.
          </Text>
        </Box>
      </CardBody>
    </Card>
  );

  const invitedUsersEmptyState = (
    <Card background="light-2" elevation="none">
      <CardBody>
        <Box pad="medium">
          <Text>
            No invited users yet. When someone accepts your invite, they will
            appear here!
          </Text>
        </Box>
      </CardBody>
    </Card>
  );

  const acceptedInvitesBox = (
    <Box>
      {invites.length || invitedUsers.length ? (
        <>
          <Heading color="dark-1" level="6" margin={{ bottom: 'small' }}>
            Accepted invites
          </Heading>
          <Box gap="small">
            {invitedUsers.length === 0
              ? invitedUsersEmptyState
              : invitedUsers.map((invitedUser, i) => (
                  <Card
                    key={`invited-user-${i + 1}`}
                    background="light-2"
                    elevation="none"
                  >
                    <CardBody>
                      <Box direction="row" pad="small" gap="small">
                        <Avatar
                          src={invitedUser.photoUrl}
                          referrerPolicy="no-referrer"
                          placeholder={<Camera />}
                        />
                        <Text alignSelf="center">{`${invitedUser.nameFirst} ${invitedUser.nameLast}`}</Text>
                      </Box>
                    </CardBody>
                  </Card>
                ))}
          </Box>
        </>
      ) : null}
    </Box>
  );

  const adminsBox = (
    <Box>
      <Heading color="dark-1" level="6" margin={{ bottom: 'small' }}>
        {`${teamId ? 'Team' : 'Player'} managers`}
      </Heading>
      <Box gap="small">
        {coAdmins.map((admin, i) => (
          <Card key={`co-admin-${i + 1}`} background="light-2" elevation="none">
            <CardBody>
              <Box pad="medium">
                <Text>{`${admin.nameFirst} ${admin.nameLast} (${admin.email})`}</Text>
              </Box>
            </CardBody>
          </Card>
        ))}
      </Box>
    </Box>
  );

  if (
    !invitesRemaining &&
    !invites.length &&
    !invitedUsers.length &&
    !coAdmins.length
  ) {
    // This means we have not enabled invites for this user yet
    return null;
  }

  let headingText = '';
  if (teamId) {
    headingText = `Invite a user to help manage ${nameToManage}`;
  } else if (claimedPlayerId) {
    headingText = `Invite a user to help manage ${nameToManage}'s profile`;
  } else {
    headingText = 'Invite a livestreamer to sidelineHD';
  }

  return (
    <Box id="test-test">
      <Box>
        {error && <Text color="status-critical">{error}</Text>}
        <Box gap="small" width="large">
          <Box gap="small" direction="row" align="center">
            <Heading color="dark-1" level="5" margin={{ vertical: 'small' }}>
              {headingText}
            </Heading>
            {tooltip}
          </Box>
          <Text>{`You have ${invitesRemaining || 'no'} invite${
            invitesRemaining !== 1 ? 's' : ''
          } remaining.`}</Text>

          {teamId || claimedPlayerId ? (
            <Box gap="small">
              <PhoneNumberInput
                id="text-input"
                placeholder="Phone number"
                onValid={onValidPhone}
                onError={onErrorPhone}
              />
              {phoneError ? (
                <Text color="status-critical">
                  Please enter a valid phone number.
                </Text>
              ) : null}
              <Box width="medium">
                <SHDButton
                  onClick={generateCode}
                  label={!sending ? 'Invite this person' : '...Sending'}
                  primary
                  disabled={
                    invitesRemaining <= 0 ||
                    !phoneNumber ||
                    phoneError ||
                    sending
                  }
                />
              </Box>
            </Box>
          ) : (
            <Form
              value={{ email }}
              onChange={(nextValue) => setEmail(nextValue.email)}
              onSubmit={generateCode}
            >
              <FieldEmail displayLabel={false} />
              <Box width="medium">
                <SHDButton
                  type="submit"
                  label={!sending ? 'Invite this livestreamer' : '...Sending'}
                  primary
                  disabled={invitesRemaining <= 0 || !email || sending}
                />
              </Box>
            </Form>
          )}
          <Heading color="dark-1" level="6" margin={{ bottom: 'small' }}>
            Pending invites
          </Heading>
          {invites.length === 0
            ? invitesEmptyState
            : invites.map((invite) => {
                const now = Date.now() / 1000;
                const { days } = secondsToDaysAndHours(invite.expires - now);
                const isExpired = now > invite.expires;
                let expiresText;
                if (isExpired) {
                  expiresText = <Text color="dark-4">Expired</Text>;
                } else if (days === 1) {
                  expiresText = <Text>{`Expires in ${days} day`}</Text>;
                } else {
                  expiresText = <Text>{`Expires in ${days} days`}</Text>;
                }

                const inviteLink = `https://sidelinehd.com/invite?code=${invite.id}`;
                const cardBody = (
                  <CardBody>
                    <Box
                      direction={size === 'small' ? 'column' : 'row'}
                      pad="medium"
                      gap="small"
                      justify="between"
                    >
                      <Text color={isExpired ? 'dark-4' : 'dark-1'}>
                        {invite.invitedUserEmail || invite.invitedUserPhone}
                      </Text>
                      {expiresText}
                    </Box>
                  </CardBody>
                );
                return (
                  <Card
                    key={`existing-invite-${invite.id}`}
                    background="light-2"
                    elevation="none"
                  >
                    {isExpired || invite.invitedUserEmail ? (
                      cardBody
                    ) : (
                      <CopyToClipboard
                        text={inviteLink}
                        onCopy={() => {
                          setCopied(true);
                        }}
                      >
                        {cardBody}
                      </CopyToClipboard>
                    )}
                  </Card>
                );
              })}
          {teamId || claimedPlayerId ? adminsBox : acceptedInvitesBox}
        </Box>
      </Box>
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
      {invited && (
        <Toast
          label={`Invited ${invited}`}
          background="status-ok"
          duration={3000}
          onClose={() => {
            toggleInvited(false);
          }}
        />
      )}
    </Box>
  );
};

export default Invites;
