import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Box } from 'grommet';
import { StatusGood } from 'grommet-icons';

interface PhoneNumberInputV2Props {
  value: string;
  onChange: (value: string) => void;
  onPossiblePhoneNumber: (value: string) => void;
  onError: (error: boolean | null) => void;
  placeholder?: string;
  successIcon?: JSX.Element;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const PhoneNumberInputV2: React.FC<PhoneNumberInputV2Props> = ({
  value,
  onChange,
  onPossiblePhoneNumber,
  onError,
  placeholder,
  successIcon = null,
  style = {},
  disabled = false,
}) => {
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(value);
  const [showCheck, setShowCheck] = useState<boolean>(false);

  const onInputChange = (val: string = '') => {
    setPhoneNumberValue(val);

    if (isPossiblePhoneNumber(val)) {
      onPossiblePhoneNumber(val);
    } else {
      onError(true);
    }

    setShowCheck(isPossiblePhoneNumber(val));
    onChange(val);
  };

  const defaultStyle = {
    border: '1px solid #272727',
    borderRadius: '4px',
    padding: '8px',
    color: disabled ? '#686868' : '#333333',
    fontSize: '16px',
    width: '100%',
    opacity: disabled ? 0.5 : 1,
  };

  return (
    <Box direction="row" style={{ position: 'relative' }}>
      <Input
        style={{
          ...defaultStyle,
          ...style,
        }}
        defaultCountry="US"
        international={false}
        placeholder={placeholder}
        value={phoneNumberValue}
        key="phone-input"
        onChange={onInputChange}
        disabled={disabled}
      />
      {showCheck && (
        <Box
          style={{
            position: 'absolute',
            right: '4px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {successIcon ? successIcon : <StatusGood color="status-ok" />}
        </Box>
      )}
    </Box>
  );
};

export default PhoneNumberInputV2;
