// app colors
export const darkGrey = '#6e6e6e';
export const secondaryGrey = '#eee';
export const primaryColor = '#2eff2e';
export const infoColor = '#eeeaaa';
export const dangerColor = '#ff5555';
// app element sizes
export const basicRadius = '6px';
export const basicGap = '10px';
export const containerMaxWidth = '1200px';

// grid breakpoints
export const BREAKPOINT = {
  md: '768px',
};
