// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, Heading, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import { Share } from 'grommet-icons';

import axios from '../../helpers/axios';
import Toast from '../../components/Toast';

import CopiableText from '../../helpers/CopiableText';
import { UserContext } from '../../services/Session';
import { BasicLoader } from '../../helpers/Loaders';
import { analytics } from '../../services/analytics';

const Referrals = ({ cardBackground = 'light-1', showSuccesses = true }) => {
  const userStore = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);

  const [referralLink, setReferralLink] = useState(null);
  const [referralCount, setReferralCount] = useState(null);

  const getReferrals = () => {
    axios.get(`/api/user/${user._id}/referrals`).then((res) => {
      setReferralCount(res.data.referredUsers.length);
    });
  };

  const generateCode = () => {
    axios
      .put(`/api/user/${user._id}`, {
        newUser: {
          generateReferralLink: true,
        },
      })
      .then((res) => {
        setReferralLink(res.data.user.referralLink);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError.toString());
      });
  };

  useEffect(() => {
    if (user) {
      if (user.referralLink) {
        setReferralLink(user.referralLink);
      } else {
        generateCode();
      }
      getReferrals();
    } else if (userStore.authUser && userStore.authUser.claims) {
      axios
        .get(`/api/user/${userStore.authUser.claims.shd_user_id}`)
        .then((response) => {
          const respUser = response.data;
          setUser(respUser);
          setLoading(false);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleShare = () => {
    analytics.track('Share Referral Link Clicked');
    if (navigator.share) {
      navigator
        .share({
          title: 'sidelineHD',
          text: 'I think you would enjoy livestreaming with sidelineHD!',
          url: referralLink,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    } else {
      navigator.clipboard.writeText(referralLink).then(() => {
        setCopied(true);
      });
    }
  };

  if (loading) {
    return <BasicLoader />;
  }

  return (
    <Box margin={{ top: 'large' }} width="large">
      <Card background={cardBackground} pad="medium" gap="small">
        <Heading style={{ maxWidth: '100%' }} color="dark-1" level="4">
          Know a team missing out on the magic of sidelineHD? Send an invite!
        </Heading>
        <Box>Invite them by sharing the link below.</Box>
        <Box gap="xsmall">
          <CopiableText
            label="Your link:"
            value={referralLink}
            onCopy={() => setCopied(true)}
          />
          <Button
            primary
            label="Share referral link"
            icon={<Share size="small" />}
            onClick={() => {
              handleShare();
            }}
          />
        </Box>
        <Box margin={{ top: 'xlarge' }}>
          <Text>
            Invite a user and you will be entered into a raffle for a $100 gift
            card!
          </Text>
        </Box>
        {showSuccesses && (
          <Box margin={{ top: 'xlarge' }}>
            <Text>{`${referralCount} invited user(s) have successfully scored and streamed a game.`}</Text>
          </Box>
        )}
      </Card>

      {copied && (
        <Toast
          label="Copied referral link to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
      {error && <Text>{error}</Text>}
    </Box>
  );
};

export default Referrals;
