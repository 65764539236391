import { shd } from '@shd/jslib/models';
import { Box, Grid, ResponsiveContext, Text } from 'grommet';
import axios from '../../helpers/axios';
import React, { useContext, useEffect, useState } from 'react';
import HighlightCard from './HighlightCard';
import { strogging } from '@shd/jslib/infra';

interface Props {
  team: shd.Team;
  isAdmin: boolean;
}

const NoHighlights: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  return (
    <Box gap="medium" color="transparent" pad="medium">
      <Text size="large" weight={'bold'}>
        No Highlights... yet!
      </Text>
      <Text>
        Game Highlights will show up here!
        {isAdmin
          ? 'Stream and score a game to receive an autogenerated highlight video of each game!'
          : ''}
      </Text>
    </Box>
  );
};

const HighlightsList = ({
  highlights,
  screenSize,
  loading,
}: {
  highlights: shd.Highlight[];
  screenSize: string;
  loading: boolean;
}) => {
  let cardColumns = '100%';
  if (screenSize === 'medium') {
    cardColumns = '1/2';
  } else if (screenSize === 'large') {
    cardColumns = '1/3';
  }

  return (
    <Box skeleton={loading}>
      <Grid columns={cardColumns} gap="large">
        {highlights.map(
          (highlight) =>
            highlight.isEnable && (
              <HighlightCard key={highlight._id} highlight={highlight} />
            )
        )}
      </Grid>
    </Box>
  );
};

const Highlights: React.FC<Props> = ({ team, isAdmin }) => {
  const screenSize = useContext(ResponsiveContext);

  const [highlights, setHighlights] = useState<shd.Highlight[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (team?._id) {
      setLoading(true);
      axios
        .get(`/api/team/${team._id}/game_summary_videos`)
        .then((res) => {
          setHighlights(res.data.highlights);
          setLoading(false);
        })
        .catch((e) => {
          strogging.exception('getHighlights', e);
          setLoading(false);
        });
    }
  }, [team]);

  return (
    <Box
      pad={{
        vertical: 'medium',
        horizontal: screenSize === 'small' ? 'medium' : 'none',
      }}
    >
      {highlights.length === 0 && !loading && (
        <NoHighlights isAdmin={isAdmin} />
      )}
      <HighlightsList
        loading={loading}
        highlights={highlights}
        screenSize={screenSize}
      />
    </Box>
  );
};

export default Highlights;
