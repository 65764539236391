// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Button, Heading, Layer, Text } from 'grommet';
import React, { useState } from 'react';
import axios from '../../helpers/axios';
import ShareBar from './ShareBar';

const Recap = ({ data, canEdit }) => {
  const [showHideRecapWarning, setShowHideRecapWarning] = useState(false);

  const submitFeedbackClicked = () => {
    const recapId = data.recap._id;
    const url = `https://docs.google.com/forms/d/e/1FAIpQLSfXQ6iptUwjxGd3wp2TWhUb-jj4luzopz1ZGMb1V_yekfoR_Q/viewform?entry.2101975893=${recapId}`;
    window.open(url, '_blank');
  };

  const closeHideRecapModal = () => {
    setShowHideRecapWarning(false);
  };

  const hideRecapModal = (
    <Layer modal onEsc={closeHideRecapModal}>
      <Box margin="large" gap="medium">
        <Text>Are you sure you want to hide this recap?</Text>
        <Box direction="row" gap="medium">
          <Button
            primary
            label="Yes"
            onClick={() => {
              axios
                .put(`/api/game_recap/${data.recap._id}/hide`)
                .then((response) => {
                  if (response.status === 200) {
                    window.location.reload();
                  }
                });
            }}
          />
          <Button secondary label="No" onClick={closeHideRecapModal} />
        </Box>
      </Box>
    </Layer>
  );
  return (
    <Box margin={{ top: 'large' }} gap="medium" fill>
      {showHideRecapWarning && hideRecapModal}
      <Box
        background="white"
        pad="large"
        gap="large"
        flex={{ grow: 0, shrink: 0 }}
        round
      >
        <Box direction="column" flex={{ grow: 0, shrink: 0 }}>
          <Heading fill level="2">
            {data.recap.titleMain}
          </Heading>
          <Box margin={{ bottom: 'medium' }}>
            <Text weight="bold">By sidelineHD</Text>
            <Text color="dark-4">{data.recap.titleDateTxt}</Text>
          </Box>
          <ShareBar
            title="Game Recap on sidelineHD"
            text={data.recap.titleMain}
            path={window.location.href}
          />
        </Box>
        <Box flex={{ grow: 0, shrink: 0 }}>
          <Text style={{ lineHeight: '24px', whiteSpace: 'pre-line' }}>
            {data.recap.recapText}
          </Text>
        </Box>
      </Box>
      <Box
        background="white"
        pad="large"
        gap="medium"
        flex={{ grow: 0, shrink: 0 }}
        round
      >
        <Box direction="column" flex={{ grow: 0, shrink: 0 }}>
          <Text color="dark-3" weight="bold">
            Help us improve game recaps!
          </Text>
        </Box>
        <Box direction="row" gap="small" flex={{ grow: 0, shrink: 0 }}>
          <Button
            primary
            label="Submit Feedback"
            onClick={submitFeedbackClicked}
          />
          {canEdit && (
            <Button
              secondary
              label="Hide Recap"
              onClick={() => {
                setShowHideRecapWarning(true);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Recap;
