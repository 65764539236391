// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { ButtonStyled } from './style';

const OnboardingButton = ({
  label, disabled, onClick, ...props
}) => {
  const color = disabled ? 'light-5' : 'tertiary-1';

  return (
    <ButtonStyled
      onClick={onClick}
      disabled={disabled}
      color={color}
      label={label}
      {...props}
    />
  );
};

export default OnboardingButton;
