import { shd } from '@shd/jslib/models';

export interface GameProps {
  team: shd.Team;
  isAdmin: boolean;
}

export interface GameCardProps {
  game: shd.DnorGame;
  team: shd.Team;
  handleDeleteGame: (teamId: string, gameId: string) => void;
  isAdmin: boolean;
}

export interface GameCardHeaderProps {
  startTime: number;
  isUpcomingGame: boolean;
  teamId: string;
  teamGameId: string;
  handleDeleteGame: (teamId: string, gameId: string) => void;
  isAdmin: boolean;
}
export interface GameInfoProps {
  game: shd.DnorGame;
  team: shd.Team;
  isUpcomingGame: boolean;
}

export interface GameScoreProps {
  homeScore: number;
  awayScore: number;
  teamHSetScores?: number[] | null;
  teamASetScores?: number[] | null;
  sportType: string | null;
}
export interface GameStreamsProps {
  fb: { cdnUrl: string } | null;
  yt: { cdnUrl: string } | null;
  recap: boolean;
  gameId: string;
  teamId: string;
}

export enum ScheduleState {
  UNKNOWN = 0,
  SCHEDULED = 10,
  BROADCAST_OR_SCORING = 20,
  STARTED = 30,
  TIMEOUT = 40,
  ENDED = 50,
}

export enum PrivacyStatus {
  Public = 'public',
  Unlisted = 'unlisted',
  Private = 'private',
  NotPresent = 'not_present',
  Error = 'error',
}

export interface YTAsset {
  cdnUrl: string;
  privacyStatus?: PrivacyStatus;
}
