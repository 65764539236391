// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Heading, Layer, Text, RadioButton } from 'grommet';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Add } from 'grommet-icons';
import { BasicLoader } from '../../helpers/Loaders';
import { insertDisplayName } from '../../helpers/utils';
import SHDButton from '../../components/SHD/Button';
import { ScoringContext } from './context';
import QuickAddPlayer from './QuickAddPlayer';
import axios from '../../helpers/axios';

const MakeSubstitution = observer((props) => {
  const {
    playerToSubOutIndex,
    mandatory = false,
    closeSubs = () => {},
    isQuickStartModePrompt = false,
    isOnDeck = false,
  } = props;
  const scoringStore = useContext(ScoringContext);
  const { lineups, awayHome, handleEvent } = scoringStore;
  const lineup = lineups[awayHome];
  const [benchPlayers, setBenchPlayers] = useState([]);
  const [selectedSub, setSelectedSub] = useState();
  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState();
  const [showAddPlayer, setShowAddPlayer] = useState(false);

  const makeSubstitution = (player) => {
    if (playerToSubOutIndex || playerToSubOutIndex === 0) {
      handleEvent('sub', {
        index: playerToSubOutIndex,
        newPlayer: player,
      });
    } else {
      handleEvent('sub', {
        index: -1,
        newPlayer: player,
        isOnDeck,
        isQuickStartModePrompt,
      });
    }
    closeSubs(true);
  };

  useEffect(() => {
    axios
      .get(`/api/team/${scoringStore.teamId}/team_players`)
      .then((response) => {
        const teamPlayerDocs = response.data.players;
        const lineupPlayerIds = lineup.map((player) => player._id);
        const newBenchPlayers = teamPlayerDocs
          .filter(
            (player) => !lineupPlayerIds.includes(player._id) && player.isActive
          )
          .map(insertDisplayName);
        newBenchPlayers.sort((a, b) => {
          const aJerseyNum = parseInt(a.jerseyNum);
          const bJerseyNum = parseInt(b.jerseyNum);
          return aJerseyNum > bJerseyNum ? 1 : -1;
        });
        setBenchPlayers(newBenchPlayers);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPlayer = (player) => {
    const newPlayer = insertDisplayName(player);
    const newBenchPlayers = [...benchPlayers, newPlayer];
    setBenchPlayers(newBenchPlayers);
    setSelectedSub(newPlayer._id);
  };

  if (loading) {
    return (
      <Layer>
        <BasicLoader fullPage />
      </Layer>
    );
  }

  if (showAddPlayer) {
    return (
      <QuickAddPlayer
        fullPage
        addPlayerLocal={addPlayer}
        onClose={() => setShowAddPlayer(false)}
      />
    );
  }

  let headerText = '';
  let submitButtonText = '';
  if (playerToSubOutIndex || playerToSubOutIndex === 0) {
    headerText = `Sub out ${lineup[playerToSubOutIndex].displayName}`;
    submitButtonText = 'Make substitution';
  } else if (!isQuickStartModePrompt) {
    headerText = 'Add to lineup';
    submitButtonText = 'Add batter to lineup';
  } else if (!isOnDeck) {
    headerText = 'Choose current batter';
    submitButtonText = 'Choose batter';
  } else {
    headerText = 'Choose on-deck batter';
    submitButtonText = 'Choose batter';
  }

  return (
    <Layer pad={{ vertical: 'medium' }}>
      <Box
        height={`${window.innerHeight}px`}
        pad="medium"
        gap="medium"
        style={{ overflow: 'auto' }}
      >
        {error ? <Text color="status-critical">{error}</Text> : null}
        {!mandatory ? (
          <Box fill="horizontal" align="end" pad="large">
            <Text color="tertiary-1" weight="bold" onClick={closeSubs}>
              Back
            </Text>
          </Box>
        ) : null}
        <Heading level="3">{headerText}</Heading>
        {lineup.length >= 4 && isQuickStartModePrompt ? (
          <SHDButton
            label={`Wrap to ${lineup[0].displayName}`}
            primary
            onClick={() => {
              handleEvent('wrapLineup');
              closeSubs();
            }}
            size="large"
            color="tertiary-1"
          />
        ) : null}
        {benchPlayers.length ? (
          <Box gap="medium" height={{ min: 'initial' }}>
            {benchPlayers.map((player) => (
              <Box
                background={
                  selectedSub === player._id ? 'secondary-1' : 'secondary-7'
                }
                pad="medium"
                style={{ borderRadius: '8px' }}
                onClick={() => setSelectedSub(player._id)}
                key={player._id}
              >
                <RadioButton
                  checked={selectedSub === player._id}
                  label={player.displayName}
                  readOnly
                  name="benchPlayer"
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Text size="large" textAlign="center">
            No players yet. Create one to get started.
          </Text>
        )}

        <Box align="start" height={{ min: 'initial' }}>
          <Box
            hoverIndicator
            onClick={() => setShowAddPlayer(true)}
            direction="row"
            align="center"
            gap="small"
            fill={false}
            pad="medium"
          >
            <Add color="tertiary-1" />
            <Text color="tertiary-1">Create player</Text>
          </Box>
        </Box>
        <Box pad={{ top: 'large' }} height={{ min: 'small' }}>
          <SHDButton
            primary
            size="large"
            label={submitButtonText}
            onClick={() => {
              const sub = benchPlayers.filter(
                (player) => player._id === selectedSub
              )[0];
              makeSubstitution(sub);
            }}
            disabled={!selectedSub}
            color="tertiary-1"
          />
        </Box>
      </Box>
    </Layer>
  );
});

export default MakeSubstitution;
