// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Button, Heading, Tabs, Tab, Text } from 'grommet';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQueryParams, ArrayParam, withDefault } from 'use-query-params';
import InfiniteScroll from 'react-infinite-scroll-component';
import { New } from 'grommet-icons';
import axios from '../../helpers/axios';
import VBPlayerClip from './VBPlayerClip';
import { BasicLoader } from '../../helpers/Loaders';
import ReelsList from './ReelsList';

const MyFiltersParam = withDefault(ArrayParam, []);

// eslint-disable-next-line no-unused-vars
const VBPlayerClipsSection = ({
  playerId,
  player,
  teamGames,
  pinClip,
  unpinClip,
  pinnedClips = [],
  isAdmin,
  showReelsTab,
  claimed,
  ...props
}) => {
  const vbPinnedClips = pinnedClips.filter((clip) => {
    const visible = !clip.O_hideFromClaimedPlayerPage.includes(playerId);
    const isVB = clip.game.sportType === 'vb';

    return visible && isVB;
  });
  const teamGamesDict = Object.assign(
    {},
    ...teamGames
      .filter((game) => game.sportType && game.sportType === 'vb')
      .map((game) => ({ [game._id]: game }))
  );

  const getGameOptions = () =>
    teamGames
      .filter((game) => game.sportType && game.sportType === 'vb')
      .sort(({ J_tsStart: a }, { J_tsStart: b }) => b - a)
      .map((game) => {
        const date = DateTime.fromMillis(game.J_tsStart * 1000).toLocal();
        return {
          label: `${game.scoreOurName} ${game.scoreWeAreHome ? 'vs' : '@'} ${
            game.J_opponentName
          } - ${date.month}/${date.day}/${date.year}`, // include date maybe?
          value: game._id,
        };
      });

  const [gameOptions, setGameOptions] = useState(getGameOptions());

  const [clips, setClips] = useState([]);
  const [tabsIndex, setTabsIndex] = useState(vbPinnedClips.length ? 1 : 2);
  const [startAfter, setStartAfter] = useState();
  const [loading, setLoading] = useState(false);
  const [, setLoadingMore] = useState(false);

  const [pendingFilters, setPendingFilters] = useState({
    sport: 'vb',
    gameIds: [],
    playTypes: [],
  });

  const [filters, setFilters] = useQueryParams({
    sport: 'vb',
    gameIds: MyFiltersParam,
    playTypes: MyFiltersParam,
  });

  const applyFilters = () => {
    setFilters(pendingFilters);
  };

  const getNextPage = () => {
    setLoadingMore(true);
    const params = {
      ...filters,
      sport: 'vb',
      startAfter,
    };
    axios.get(`/api/player/${playerId}/clips`, { params }).then((res) => {
      setClips([...clips, ...res.data.clips]);
      setStartAfter(res.data.next);
      setLoadingMore(false);
    });
  };

  useEffect(() => {
    setPendingFilters(filters);
    setLoading(true);
    const params = {
      ...filters,
      sport: 'vb',
    };
    axios.get(`/api/player/${playerId}/clips`, { params }).then((res) => {
      setClips(res.data.clips);
      setStartAfter(res.data.next);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setGameOptions(getGameOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamGames]);

  const renderClips = () => {
    if (teamGames.length === 0 || loading) {
      return null;
    }

    const filteredClips = clips.filter((clip) => {
      const inGames =
        filters.gameIds.includes(clip.gameId) || filters.gameIds.length === 0;
      const inPlayTypes =
        filters.playTypes.includes(clip.playType) ||
        filters.playTypes.length === 0;

      return inGames && inPlayTypes;
    });

    return (
      <Box gap="small">
        <Box gap="small">
          <InfiniteScroll
            dataLength={filteredClips.length}
            next={getNextPage}
            scrollThreshold={0.5}
            loader={
              <Box
                direction="row"
                margin={{ vertical: 'medium' }}
                align="center"
                gap="small"
              >
                <BasicLoader size={20} />
                <Text>Loading...</Text>
              </Box>
            }
            endMessage={
              <Box direction="row" margin={{ vertical: 'medium' }}>
                <Text>That is all!</Text>
              </Box>
            }
            hasMore={startAfter}
          >
            {filteredClips.map((clip) => {
              const game = teamGamesDict[clip.gameId];
              if (game) {
                game.scoreOurName = ''; // TODO: populate this
                game.scoreOpponentName = game.J_opponentName;
                // eslint-disable-next-line prefer-destructuring
                game.DNOR_teamId = clip.teamId;
                // eslint-disable-next-line no-param-reassign
                clip.game = game;

                return (
                  <VBPlayerClip
                    key={clip.id}
                    clip={clip}
                    game={game}
                    playerId={playerId}
                    teamPlayerName="teamPlayerName"
                    publicPublishLevel={
                      player.publicPublishLevel !== 0
                        ? player.publicPublishLevel
                        : 10
                    }
                    onChange={(newClip) => {
                      setClips(
                        clips.map((c) => {
                          if (c.id === newClip.id) {
                            return newClip;
                          }
                          return c;
                        })
                      );
                    }}
                    pinClip={isAdmin ? pinClip : null}
                    unpinClip={isAdmin ? unpinClip : null}
                    isPinned={!!pinnedClips.map((c) => c.id).includes(clip.id)}
                    setToast={props.setToast}
                    // hideBottomBar={!hasAccess()}
                  />
                );
              }
              return null;
            })}
          </InfiniteScroll>
          {/* {filteredClips.map((clip) => {
            const game = teamGamesDict[clip.gameId];
            if (game) {
              game.scoreOurName = ''; // TODO: populate this
              game.scoreOpponentName = game.J_opponentName;
              // eslint-disable-next-line prefer-destructuring
              game.DNOR_teamId = clip.scoreTeamIdList[0];
              // eslint-disable-next-line no-param-reassign
              clip.game = game;

              return (
                <VBPlayerClip
                  clip={clip}
                  game={game}
                  playerId={playerId}
                  teamPlayerName="teamPlayerName"
                  publicPublishLevel={10}
                  onChange={(newClip) => {
                    setClips(clips.map((c) => {
                      if (c.id === newClip.id) {
                        return newClip;
                      }
                      return c;
                    }));
                  }}
                  // hideBottomBar={!hasAccess()}
                />
              );
            }
            return null;
          })} */}
          {/* {filteredClips.length !== 0
            && (
            <Button
              label={loadingMore ? 'Loading...' : 'Load more...'}
              icon={loadingMore ? <Radial /> : null}
              onClick={getNextPage}
            />
            )} */}
          {filteredClips.length === 0 && (
            <Box>
              <Text>No clips match these filters.</Text>
              <Text>Adjust the filters above and press Apply</Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const getPlayTypes = () => [
    { label: 'Kill', value: 'kill' },
    { label: 'Block', value: 'block' },
    { label: 'Ace', value: 'ace' },
    { label: 'Assist', value: 'assist' },
    { label: 'Dig', value: 'dig' },
    { label: 'Serve', value: 'serve' },
    { label: 'Highlight', value: 'highlight' },
  ];

  const getGameValue = () =>
    gameOptions.filter((option) =>
      pendingFilters.gameIds.includes(option.value)
    );

  const getPlayTypeValue = () => {
    const playTypes = getPlayTypes();
    return playTypes.filter((option) =>
      pendingFilters.playTypes.includes(option.value)
    );
  };

  const renderPinnedClips = () => {
    const filteredPinnedClips = pinnedClips.filter((clip) => {
      const visible = !clip.O_hideFromClaimedPlayerPage.includes(playerId);
      const isVB = clip.game.sportType === 'vb';

      return visible && isVB;
    });

    if (filteredPinnedClips.length === 0) {
      return (
        <Heading textAlign="center" color="text-xweak" level={4}>
          {isAdmin
            ? 'Add highlights from your clips'
            : "This player hasn't added a highlight yet"}
        </Heading>
      );
    }

    return (
      <Box>
        {filteredPinnedClips.map((clip) => (
          <VBPlayerClip
            key={clip.id}
            clip={clip}
            game={clip.game}
            publicPublishLevel={10}
            teamPlayerName="name"
            playerId={playerId}
            pinClip={isAdmin ? pinClip : null}
            unpinClip={isAdmin ? unpinClip : null}
            // TODO: check if clip ids need hash here because pinned clips
            isPinned={
              !!(
                pinnedClips.map((c) => c.id).includes(clip.refPreview) ||
                pinnedClips.map((c) => c.id).includes(clip.refFull)
              )
            }
            setToast={props.setToast}
          />
        ))}
      </Box>
    );
  };

  const renderClipsFeed = () => (
    <Box>
      <Box id="filters-container" gap="small">
        <Heading level="4" margin={{ vertical: 'none' }}>
          Filters
        </Heading>
        <Select
          value={getGameValue()}
          placeholder="All games..."
          options={gameOptions}
          onChange={(option) => {
            // setFilters(
            //   { ...filters, gameIds: option.map((game) => game.value) },
            //   'push',
            // );
            setPendingFilters(
              {
                ...filters,
                ...pendingFilters,
                gameIds: option.map((game) => game.value),
              },
              'push'
            );
          }}
          isMulti
        />
        <Select
          value={getPlayTypeValue()}
          placeholder="All play types..."
          options={getPlayTypes()}
          onChange={(option) => {
            // setFilters({ ...filters, playTypes: option.map((game) => game.value) }, 'push');
            setPendingFilters(
              {
                ...filters,
                ...pendingFilters,
                playTypes: option.map((game) => game.value),
              },
              'push'
            );
          }}
          isMulti
        />
        <Button
          primary
          color="tertiary-1"
          label="Apply"
          onClick={applyFilters}
        />
      </Box>
      {renderClips()}
      {loading && <BasicLoader />}
    </Box>
  );

  return (
    <Box pad="medium" gap="small">
      <Tabs
        activeIndex={tabsIndex}
        onActive={(activeTabIndex) => {
          setTabsIndex(activeTabIndex);
        }}
      >
        {claimed && showReelsTab && (
          <Tab title="Reels" icon={<New size="small" />}>
            <ReelsList playerId={playerId} />
          </Tab>
        )}
        <Tab title="Pinned">{renderPinnedClips()}</Tab>
        <Tab title="Clips">{renderClipsFeed()}</Tab>
      </Tabs>
    </Box>
  );
};

export default withRouter(VBPlayerClipsSection);
