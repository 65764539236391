// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  ResponsiveContext,
  Button,
  Card,
  DropButton,
  Layer,
} from 'grommet';
import { compose } from 'recompose';
import { withRouter, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Close, FormNext, StatusGood } from 'grommet-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import Toast from '../../components/Toast';
import withLayout from '../../helpers/withLayout';
import PlayerClip from '../Player/PlayerClip';
import {
  formatDateShort,
  useIsFeatureEnabled,
  useQuery,
} from '../../helpers/utils';
import PlayerCard from '../Player/PlayerCard';
import { UserContext } from '../../services/Session';
import MakeHighlightLayer from './MakeHighlightLayer';
import { withFirebase } from '../../services/Firebase';

const Compilation = ({ history, ...props }) => {
  const userStore = useContext(UserContext);
  const allowHighlightRequests = useIsFeatureEnabled(
    'allow_highlight_requests',
    userStore
  );

  const screen = useContext(ResponsiveContext);
  const { compilation_id: compilationId } = useParams();
  const query = useQuery();
  const [userId, setUserId] = useState(query.get('user_id'));
  // eslint-disable-next-line no-unused-vars
  const [cancelledId, setCancelledId] = useState(query.get('cancelled_id'));
  const [purchased, setPurchased] = useState(query.get('purchased'));
  // eslint-disable-next-line no-unused-vars
  const [checkoutSessionId] = useState(query.get('session_id'));

  const [title, setTitle] = useState('');
  const [player, setPlayer] = useState({});
  const [teams, setTeams] = useState({});
  const [clips, setClips] = useState([]);
  const [showPlayerCard, setShowPlayerCard] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);

  useEffect(() => {
    if (!userId) {
      if (userStore.authUser && userStore.authUser.claims) {
        setUserId(userStore.authUser.claims.shd_user_id);
      }
    }

    window.addEventListener('scroll', () => {
      const el = document.getElementById('container');
      if (el && el.getBoundingClientRect().bottom <= window.innerHeight) {
        setShowForm(true);
      }
    });

    setLoading(true);

    axios
      .get(`/api/compilation/${compilationId}`)
      .then((response) => {
        setClips(response.data.clips);
        setTitle(response.data.titleText);

        axios
          .get(`/api/player/${response.data.claimedPlayerId}`)
          .then((res) => {
            setPlayer(res.data.player);
            setTeams(res.data.teams);
          })
          .catch((respError) => {
            Sentry.captureException(respError);
            setError(respError);
          });

        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError('Something went wrong.');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cancelledId) {
      props.firebase.logAnalyticsEvent('highlight_abandoned_checkout', {
        compilation_id: compilationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelledId]);

  useEffect(() => {
    if (checkoutSessionId) {
      props.firebase.logAnalyticsEvent('highlight_completed_checkout', {
        compilation_id: compilationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId]);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title,
          text: `Checkout ${title} on sidelineHD`,
          url: window.location.href,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const onCloseHighlightModal = () => {
    setShowHighlight(false);
    setCancelledId(false);
  };

  if (error) {
    return (
      <Text color="status-critical" size="large">
        {error}
      </Text>
    );
  }

  if (loading || !player) {
    return <BasicLoader fullPage />;
  }

  const makeHighlightModal = (
    <MakeHighlightLayer
      compilationId={compilationId}
      player={player}
      clips={clips}
      cancelledHighlightId={cancelledId}
      onCloseHighlightModal={onCloseHighlightModal}
    />
  );

  const purchaseSuccessModal = (
    <Layer modal style={{ overflow: 'auto' }}>
      <Box flex={false} alignSelf="end" margin="medium">
        <Button
          icon={<Close size="medium" />}
          hoverIndicator
          onClick={() => {
            setPurchased(false);
            history.push(`/cp/${compilationId}`);
          }}
        />
      </Box>
      <Box
        fill="horizontal"
        overflow="auto"
        flex={false}
        pad={{ left: 'large', right: 'large' }}
        gap="medium"
        margin={{ bottom: '120px' }}
      >
        <Text>
          Thank you for purchasing a highlight reel. It will appear on your
          player&apos;s page within 24 hours.
          <br />
          <br />
          We&apos;ll send you an email when it&apos;s ready!
        </Text>
        <Box alignSelf="center">
          <StatusGood color="status-ok" size="large" />
        </Box>
      </Box>
    </Layer>
  );
  return (
    <Box
      width={screen === 'small' ? '100%' : '425px'}
      margin={{ left: 'auto', right: 'auto', bottom: 'xlarge' }}
      align="center"
      gap="small"
      pad="medium"
    >
      <Card fill background="primary-8">
        <Box background="primary-1" pad="medium">
          <Text size="large">{title}</Text>
        </Box>
        <Box
          pad="medium"
          direction="row"
          justify="between"
          align="center"
          onClick={() => {
            history.push(`/${player._id}`);
          }}
        >
          <Text id="player-name-header" size="xlarge" weight="bold">
            {`${player.nameFirst} ${player.nameLast} `}
          </Text>
          <Box>
            <DropButton
              label={showPlayerCard ? 'Hide' : 'View details'}
              dropAlign={{ top: 'bottom' }}
              dropProps={{ margin: 'none' }}
              dropContent={
                <Box fill="horizontal">
                  <PlayerCard
                    history={history}
                    player={player}
                    image={player.DNOR_imgUrlT150}
                    name={`${player.nameFirst} ${player.nameLast} `}
                    teams={teams}
                    claimed
                    compilation
                  />
                </Box>
              }
              onOpen={(e) => {
                e.stopPropagation();
                setShowPlayerCard(true);
              }}
              onClose={(e) => {
                e.stopPropagation();
                setShowPlayerCard(false);
              }}
            />
          </Box>
        </Box>
      </Card>
      {allowHighlightRequests && (
        <Box
          fill="horizontal"
          style={{
            backgroundImage: 'linear-gradient(to right, #7e68c0, #f9dc98)',
          }}
          direction="row"
          pad="medium"
          align="center"
          justify="between"
          round="small"
          onClick={() => {
            setShowHighlight(true);
            props.firebase.logAnalyticsEvent('open_highlight_creation', {
              user_id: userId,
              compilation_id: compilationId,
            });
          }}
        >
          <Box direction="row" align="center" gap="small">
            <Text weight="bold" color="white" size="large">
              Turn this into a highlight reel!
            </Text>
          </Box>
          <Box>
            <FormNext color="white" />
          </Box>
        </Box>
      )}
      <Box fill="horizontal">
        {clips.map((clip) => (
          <PlayerClip
            compilation
            playerId={player._id}
            key={clip.id}
            clip={clip}
            game={clip.game}
            teamPlayerName={`${player.nameFirst} ${player.nameLast}`}
            publicPublishLevel={10}
            date={formatDateShort(clip.clipStartTs)}
          />
        ))}
      </Box>
      <Box
        justify="center"
        width="inherit"
        style={{ position: 'fixed', bottom: '10px' }}
        pad="large"
      >
        {showForm && <></>}
        {allowHighlightRequests ? (
          <Button
            size="large"
            primary
            color="tertiary-1"
            label={<Text weight="bold">Turn this into a highlight reel!</Text>}
            onClick={() => setShowHighlight(true)}
          />
        ) : (
          <DropButton
            size="large"
            primary
            color="tertiary-1"
            label={<Text weight="bold">Share this Page</Text>}
            dropAlign={{ bottom: 'top' }}
            dropContent={
              <Box pad="medium" gap="small">
                {screen === 'small' && (
                  <Button label="Share page via ..." onClick={handleShare} />
                )}
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setCopied(true)}
                >
                  <Button label="Copy link to clip" />
                </CopyToClipboard>
              </Box>
            }
          />
        )}
      </Box>
      {(showHighlight || cancelledId) && makeHighlightModal}
      {purchased && checkoutSessionId && purchaseSuccessModal}
      {copied ? (
        <Toast
          label="Copied url to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      ) : null}
    </Box>
  );
};

const CompilationPage = compose(
  withFirebase,
  withLayout,
  withRouter
)(Compilation);

export default CompilationPage;
