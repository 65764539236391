import { Box, CheckBox, Text } from 'grommet';
import { SportOptionProps } from './home';

const SportOption: React.FC<SportOptionProps> = ({ option, selected }) => (
  <Box direction="row" gap="8px" pad="8px" align="center">
    <CheckBox checked={selected} onChange={() => {}} />
    <Text>{option.display}</Text>
  </Box>
);

export default SportOption;
