// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Text, Box, Tag } from 'grommet';

// 3 2 1
// 4 5 0

const CourtPlayersSub = ({
  players,
  liberos,
  selectedIndex,
  onPlayerSelected,
  isSmallScreen,
}) => {
  const topRow = [players[3], players[2], players[1]];
  const bottomRow = [players[4], players[5], players[0]];

  return (
    <Box gap="xsmall" flex={{ grow: 1, shrink: 0 }}>
      <Box
        align="center"
        border={{ color: 'primary-3', size: 'small' }}
        round="small"
      >
        <Tag
          size="small"
          alignSelf="center"
          background="primary-3"
          margin={{ top: '-12px', bottom: 'small' }}
          pad={{ horizontal: 'xlarge' }}
          name="NET"
        />
        <Box
          direction="row"
          fill="horizontal"
          justify="between"
          pad={{ horizontal: 'small' }}
        >
          {topRow.map((player, i) => {
            const isLibero = (liberos || [])
              .map((p) => p._id)
              .includes(player._id);
            return (
              <Box
                align="center"
                key={player._id}
                onClick={() => {
                  onPlayerSelected(i);
                }}
              >
                {isLibero && (
                  <Box
                    margin={{ bottom: '-1.2rem', right: '2.5rem' }}
                    style={{ zIndex: '10' }}
                    background="status-critical"
                    round="full"
                    width="1.2rem"
                    height="1.2rem"
                    align="center"
                    justify="center"
                  >
                    <Text weight="bold">L</Text>
                  </Box>
                )}
                <Box
                  round="full"
                  background="light-1"
                  width={isSmallScreen ? 'xxsmall' : 'xsmall'}
                  height={isSmallScreen ? 'xxsmall' : 'xsmall'}
                  align="center"
                  justify="center"
                  border={
                    selectedIndex === i
                      ? { color: 'primary-1', size: 'small' }
                      : null
                  }
                >
                  <Text weight="bold" size={isSmallScreen ? 'xlarge' : '3xl'}>
                    {player.jerseyNum}
                  </Text>
                </Box>
                <Box align="center" width="small" margin={{ top: 'small' }}>
                  <Text size={isSmallScreen ? 'small' : 'medium'} truncate>
                    {player.nameFirst} {player.nameLast[0]}.
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          direction="row"
          fill="horizontal"
          justify="between"
          pad={{ horizontal: 'small' }}
          margin={{ vertical: 'small' }}
        >
          {bottomRow.map((player, i) => {
            const isLibero = (liberos || [])
              .map((p) => p._id)
              .includes(player._id);

            return (
              <Box
                align="center"
                key={player._id}
                onClick={() => {
                  onPlayerSelected(i + 3);
                }}
              >
                {isLibero && (
                  <Box
                    margin={{ bottom: '-1.2rem', right: '2.5rem' }}
                    style={{ zIndex: '10' }}
                    background="status-critical"
                    round="full"
                    width="1.2rem"
                    height="1.2rem"
                    align="center"
                    justify="center"
                  >
                    <Text weight="bold">L</Text>
                  </Box>
                )}
                <Box
                  round="full"
                  background="light-1"
                  width={isSmallScreen ? 'xxsmall' : 'xsmall'}
                  height={isSmallScreen ? 'xxsmall' : 'xsmall'}
                  align="center"
                  justify="center"
                  border={
                    selectedIndex === i + 3
                      ? { color: 'primary-1', size: 'small' }
                      : null
                  }
                >
                  <Text weight="bold" size={isSmallScreen ? 'xlarge' : '3xl'}>
                    {player.jerseyNum}
                  </Text>
                </Box>
                <Box align="center" width="small" margin={{ top: 'small' }}>
                  <Text size={isSmallScreen ? 'small' : 'medium'} truncate>
                    {player.nameFirst} {player.nameLast[0]}.
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* <Box align="center" margin={{ top: 'small' }}>
        <Text size="medium" weight="bold">Rotate</Text>
        <Box margin={{ top: 'xsmall' }} direction="row" gap="small" justify="center">
          <Button onClick={rotateLineup(true)}>
            <Box background="secondary-6" round={{ left: 'medium', right: false }} pad="medium">
              <RotateRight color="black" />
            </Box>
          </Button>
          <Button onClick={rotateLineup(false)}>
            <Box background="secondary-6" round={{ left: 'medium', right: false }} pad="medium">
              <RotateLeft color="black" />
            </Box>
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default CourtPlayersSub;
