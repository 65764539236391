// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Image } from 'grommet';

const IconAES = () => (
  <Image src="aesIcon.svg" />
);

export default IconAES;
