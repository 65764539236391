import { Avatar, Box, Card, Text } from 'grommet';
import { getTeamAgeType } from '../../helpers/utils';
import { Location } from 'grommet-icons';
import { SPORTS_MAP } from '../../constants/strings';
import { TeamCardProps } from './teamCard.d';
import React from 'react';
import SHDLink from '../../helpers/SHDLink';
import styles from './TeamCard.module.css';

const TeamCard: React.FC<TeamCardProps> = ({ team, skeleton = false }) => {
  return (
    <SHDLink to={`/${team._id}`} className={styles['team-card-link']}>
      <Card
        pad="medium"
        direction="row"
        skeleton={skeleton}
        className={styles['team-card-body']}
      >
        <Box
          direction="row"
          gap="small"
          flex={{ grow: 1, shrink: 0 }}
          fill={true}
        >
          {team.image?.urlT150 ? (
            <Avatar src={team.image?.urlT150} />
          ) : (
            <Avatar background="gray-2" size="medium"></Avatar>
          )}
          <Box gap="xsmall" flex>
            <Text weight="bold">{team.nameLong}</Text>
            <Text>
              {`${getTeamAgeType(team.attrib_ageU, team.attrib_ageLevel)} ${team.attrib_sportType} Team`}
            </Text>
            <Box direction="row" gap="small" align="center">
              <Location color="tertiary-1" size="small" />
              <Text size="small">{team.nameLocation}</Text>
            </Box>
          </Box>
          <Box>{SPORTS_MAP[team.attrib_sportType].icon}</Box>
        </Box>
      </Card>
    </SHDLink>
  );
};

export default TeamCard;
