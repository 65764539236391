// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Image,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Select,
} from 'grommet';
import { Close } from 'grommet-icons';

import Toast from '../../components/Toast';
import CopiableText from '../../helpers/CopiableText';
import { GEO_US_CA, GEO_US_TX, GEO_US_NY } from '../../constants/strings';
import { analytics } from '../../services/analytics';

const GoProSetup = ({ rtmp, onClose = () => {} }) => {
  const [goProOption, setGoProOption] = useState(GEO_US_TX);

  const [copied, setCopied] = useState(false);
  const [step, setStep] = useState(0);

  const stepOne = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Select
        options={[GEO_US_NY, GEO_US_TX, GEO_US_CA]}
        value={goProOption}
        onChange={({ option }) => {
          setGoProOption(option);
        }}
      />
    </Box>
  );

  const goProUrls = {
    [GEO_US_NY]: rtmp.fullUrl_GoPro_NY,
    [GEO_US_TX]: rtmp.fullUrl_GoPro_TX,
    [GEO_US_CA]: rtmp.fullUrl_GoPro_CA,
  };

  const stepTwo = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Box background="light-1" gap="small" pad="medium">
        <Box pad="medium">
          <Text weight="bold">GoPro RTMP URL</Text>
          <CopiableText
            label=""
            value={goProUrls[goProOption]}
            onCopy={() => {}}
          />
        </Box>
      </Box>
      <Text>
        Enter the above RTMP URL and the other information into the correct
        fields. Then, press Set up Live Stream.
      </Text>
      <Box>
        <Image fit="contain" src="gopro_rtmpinfo.png" />
      </Box>
    </Box>
  );

  const renderStep = () => {
    let title = '';
    let buttonText = '';
    let buttonAction = () => {};
    if (step === 0) {
      title = 'GoPro: Pick the closest server';
      buttonText = 'Next';
      buttonAction = () => {
        analytics.track('GoPro Region Selected', {
          region: goProOption,
        });
        setStep(1);
      };
    } else if (step === 1) {
      title = 'Pair your GoPro';
      buttonText = 'Done';
      buttonAction = () => {
        onClose();
      };
    }

    return (
      <Card round={false} elevation="none" fill>
        <CardHeader
          direction="row"
          pad="small"
          align="center"
          justify="between"
        >
          <Box width="24px" />
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              {title}
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} justify="end" />
        </CardHeader>
        <CardBody fill>
          {step === 0 && stepOne}
          {step === 1 && stepTwo}
        </CardBody>
        {buttonText && (
          <CardFooter
            pad={{ horizontal: 'small', vertical: 'large' }}
            background="light-2"
            margin={{ bottom: '64px' }}
          >
            <Button
              fill
              primary
              size="large"
              label={buttonText}
              onClick={buttonAction}
            />
          </CardFooter>
        )}
      </Card>
    );
  };

  return (
    <Box fill>
      {renderStep()}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};

export default GoProSetup;
