import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ToastMessage {
  message: string;
  background?: string;
  icon?: React.ReactNode;
  duration?: number;
}

interface ToastContextType {
  showToast: (toast: ToastMessage) => void;
  toast: ToastMessage | null;
  hideToast: () => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toast, setToast] = useState<ToastMessage | null>(null);

  const showToast = (t: ToastMessage) => {
    setToast(t);
  };

  const hideToast = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ showToast, toast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
