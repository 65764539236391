/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Text, Box, Layer, Image } from 'grommet';
import FacebookDestinationPicker from '../../StreamingConfig/FacebookDestinationPicker';
import {
  SettingsStyled,
  ButtonLinkStyled,
  LinkTitle,
  LinkTextWrapper,
} from './style';
import SHDButton from '../../../components/SHD/Button';
import SuccessIcon from './SuccessIcon';
import Toast from '../../../components/Toast';
import ApiErrorDisplay from '../../../helpers/ApiErrorDisplay';
import {
  FacebookChannel,
  useFacebookScopes,
  useFacebookSettings,
} from '../../../helpers/facebook';
import { FacebookSettingsModal } from '../../../helpers/FacebookSettingsModal';

interface Props {
  teamId: string | undefined;
  onComplete?: () => void;
  onUpdatedLinkedChannel?: (channel: FacebookChannel | null) => void;
}

const FacebookSettings: React.FC<Props> = ({
  teamId,
  onComplete = () => {},
  onUpdatedLinkedChannel = () => {},
}) => {
  const {
    linkedChannel,
    onClose,
    onSave,
    error,
    setError,
    onUnlinkFacebookClick,
    onCloseIntroModal,
    setShowIntroModal,
    showExpiration,
    onLinkFacebookClick,
    setShowLinkingModal,
    responseFacebook,
    showIntroModal,
    showLinkingModal,
    linkingError,
  } = useFacebookSettings(teamId, onComplete, onUpdatedLinkedChannel);

  const fbScopes = useFacebookScopes();

  const destPickerModal = (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <FacebookDestinationPicker
        teamId={teamId}
        onClose={onClose}
        onSave={onSave}
      />
    </Layer>
  );

  const expirationPrompt = (
    <Box margin={{ top: 'small' }}>
      {(showExpiration.daysUntilExpiration ?? 0) < 0 ? (
        <Text color="status-critical">
          Facebook has expired your publishing link. To refresh, click UNLINK,
          then relink your destination.
        </Text>
      ) : (
        <Box>
          <Text>
            {`FB periodically expires publishing access.  This link will expire in ${showExpiration.daysUntilExpiration} days.`}
            To refresh, click UNLINK, then relink your destination.
          </Text>
        </Box>
      )}
    </Box>
  );

  return (
    <SettingsStyled>
      <ButtonLinkStyled>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Image src="facebookIcon.svg" width="40px" height="40px" />
          <LinkTextWrapper>
            <LinkTitle>Facebook</LinkTitle>
            {linkedChannel && (
              <Text>{`${linkedChannel.name} (${linkedChannel.type})`}</Text>
            )}
          </LinkTextWrapper>
        </Box>
        <SHDButton
          style={{
            minWidth: '75px',
            width: 'min-content',
            height: '40px',
            border: linkedChannel
              ? '1px solid transparent'
              : '1px solid #6750A4',
            color: linkedChannel ? '#fff' : '#6750A4',
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 500,
            backgroundColor: linkedChannel ? '#418310' : '#FFF',
          }}
          fill="horizontal"
          primary
          icon={linkedChannel ? <SuccessIcon /> : undefined}
          color="white"
          label={linkedChannel ? 'Linked' : 'Link'}
          onClick={() => {
            if (!linkedChannel) {
              setShowIntroModal(true);
            } else {
              onUnlinkFacebookClick();
            }
          }}
        />
      </ButtonLinkStyled>
      {showExpiration.daysUntilExpiration && expirationPrompt}
      {showIntroModal && (
        <FacebookSettingsModal
          onLinkFacebookClick={onLinkFacebookClick}
          onCloseIntroModal={onCloseIntroModal}
          setShowLinkingModal={setShowLinkingModal}
          fbScopes={fbScopes}
          responseFacebook={responseFacebook}
        />
      )}
      {showLinkingModal ? destPickerModal : null}
      {error ? (
        <Toast
          label={error.toString()}
          background="status-error"
          duration={3000}
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
      <ApiErrorDisplay error={linkingError} />
    </SettingsStyled>
  );
};

export default FacebookSettings;
