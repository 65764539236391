import React, { useRef } from 'react';
import { Text } from 'grommet';
import { HlsInfo } from './types';
import { useHls } from './useHls';
import { strogging } from '@shd/jslib/infra';
import {
  TrackEvent,
  LiveEvents,
} from '../../features/SidelineLive/sidelineLive.analytics';
import { track } from 'mixpanel-browser';

interface Props {
  style?: React.CSSProperties;
  hlsInfo?: HlsInfo;
  fetchInfoError?: string;
  trackEvent: TrackEvent;
}

const SLHlsPlayer: React.FC<Props> = ({
  style = {
    width: 'inherit',
    height: 'inherit',
  },
  hlsInfo,
  fetchInfoError,
  trackEvent,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hls = useHls(videoRef, hlsInfo, fetchInfoError);

  if (fetchInfoError) {
    strogging.log('LiveVideo - fetch error', fetchInfoError);
  }
  if (hls.error) {
    strogging.log('LiveVideo - player error', hls.error);
    track(LiveEvents.HLS_PLAYER_ERROR, { error: hls.error });
  }

  if (hls.playerEvent === 'hlsMediaAttached') {
    videoRef.current?.play();
  }

  videoRef.current?.addEventListener('play', (e) => {
    trackEvent(LiveEvents.VIEWER_JOINED, { autoplay: true });
    strogging.log('LiveVideo - Play Event', e);
  });

  videoRef.current?.addEventListener('pause', (e) => {
    trackEvent(LiveEvents.VIEWER_PAUSED, {});
    strogging.log('LiveVideo - Pause Event', e);
  });

  videoRef.current?.addEventListener('volumechange', (e) => {
    trackEvent(LiveEvents.VIEWER_MUTE, {});
    strogging.log('LiveVideo - Volume Event', e);
  });

  videoRef.current?.addEventListener('fullscreenchange', (e) => {
    trackEvent(LiveEvents.VIEWER_FULLSCREEN, {});
    strogging.log('LiveVideo - Full Screen', e);
  });

  return hls.error ? (
    <Text weight="bold" textAlign="center" margin={{ top: 'medium' }}>
      Something went wrong : {hls.error}
    </Text>
  ) : (
    <video
      controls
      playsInline
      ref={videoRef}
      style={style}
      muted
      preload="auto"
    />
  );
};

export default React.memo(SLHlsPlayer);
