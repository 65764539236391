// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import { Text, Layer, Box } from 'grommet';
import * as Sentry from '@sentry/browser';
import { observer } from 'mobx-react';
import { Close } from 'grommet-icons';

import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import ViewCapture from '../StreamingConfig/ViewCapture';
import { BasicLoader } from '../../helpers/Loaders';
import CreateCapture from './CreateCapture';
import SHDButton from '../../components/SHD/Button';
import { GOPRO, MEVO, SMARTPHONE } from '../../constants/strings';
import { analytics } from '../../services/analytics';

const CameraLayer = observer(({ teamId, ...props }) => {
  const userStore = useContext(UserContext);
  const {
    setCapture = () => {},
    completeStep = () => {},
    closeLayer = () => {},
    autoAddCaptureSetup,
  } = props;
  const { authUser } = userStore;
  const [rtmpInfo, setRtmpInfo] = useState({
    rtmp: null,
    cameraName: null,
  });
  const [loading, toggleLoading] = useState(false);
  const [rtmpError, setRtmpError] = useState(null);

  // TODO: use capture ID for merged user in store to make this request

  // useEffect(() => {
  //   axios.get('/api/rtmp_key')
  //     .then((response) => {
  //       if (response.data.rtmp) {
  //         setCapture(response.data);
  //       }
  //       toggleLoading(false);
  //     })
  //     .catch((error) => {
  //       setRtmpError(error);
  //     });
  // }, []);

  const createCapture = (type, name) => {
    toggleLoading(true);
    axios
      .post('/api/rtmp_key', {
        cameraType: type,
        cameraName: name,
        teamId,
      })
      .then((response) => {
        if (response.data.rtmp) {
          analytics.track('Capture Setup Created', {
            cameraType: type,
            teamId,
          });
          setRtmpInfo(response.data);
          setCapture(response.data.capture);
        }
        toggleLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setRtmpError(error.toString());
      });
  };

  useEffect(() => {
    if (autoAddCaptureSetup.addCamera) {
      let cameraType;
      if (autoAddCaptureSetup.cameraType === '0') {
        cameraType = MEVO;
      } else if (autoAddCaptureSetup.cameraType === '1') {
        cameraType = GOPRO;
      } else if (autoAddCaptureSetup.cameraType === '2') {
        cameraType = SMARTPHONE;
      }

      const tokenizedName = userStore.authUser.displayName
        ? userStore.authUser.displayName.split(' ')
        : ['', ''];

      const userFirstNamePossessive =
        tokenizedName.length > 0 ? `${tokenizedName[0]}'s` : 'Your';
      const cameraTypeLabel =
        cameraType === SMARTPHONE ? 'smart phone' : cameraType;
      const cameraName = `${userFirstNamePossessive} ${cameraTypeLabel}`;

      createCapture(cameraType, cameraName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Layer>
        <Box margin="medium">
          <BasicLoader fullPage />
        </Box>
      </Layer>
    );
  }

  return (
    <Layer style={{ overflowY: 'auto' }}>
      <Box alignSelf="end" margin="medium">
        <SHDButton
          icon={<Close size="medium" />}
          hoverIndicator
          onClick={() => {
            if (rtmpInfo.rtmp) {
              completeStep();
            }
            closeLayer(false);
          }}
        />
      </Box>
      <Box margin="medium" gap="small">
        {rtmpInfo.rtmp ? (
          <ViewCapture
            name={rtmpInfo.cameraName}
            type={rtmpInfo.cameraType}
            rtmp={rtmpInfo.rtmp}
            closeLayer={() => {
              completeStep();
              closeLayer(false);
            }}
            tutorial
          />
        ) : (
          <CreateCapture onCreate={createCapture} authUser={authUser} />
        )}
        {rtmpError && <Text color="status-error">{rtmpError}</Text>}
      </Box>
    </Layer>
  );
});

export default CameraLayer;
