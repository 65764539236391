// Packages
import { SyncLoader } from 'react-spinners';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

// Components
import { Box, Text } from 'grommet';
import AdminClipCard from './AdminClipCard';

// Types
import { AdminCardListProps, ClipProps } from '../admin.types';

const AdminClipCardList: React.FC<AdminCardListProps<ClipProps>> = ({
  debouncing,
  loading,
  searchResults,
  onCardSelected,
}) => {
  return loading || debouncing ? (
    <Box gap="medium" fill>
      {loading && <Text size="large">Searching...</Text>}
      {debouncing && <SyncLoader color="#ebebeb" size={20} />}
      <Skeleton height={160} count={12} />
    </Box>
  ) : (
    searchResults?.map((result, i) => {
      return (
        <AdminClipCard
          hasChangeButton={false}
          key={i}
          payload={result}
          onSelectCard={(card) => {
            if (card === null) {
              onCardSelected && onCardSelected(false, null);
            } else {
              onCardSelected && onCardSelected(true, card);
            }
          }}
        />
      );
    })
  );
};

export default AdminClipCardList;
