// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { Add } from 'grommet-icons';

import { Box, Text } from 'grommet';
import { Action } from '../styles';
import axios from '../../../helpers/axios';

import AddModal from './AddModal';
import { analytics } from '../../../services/analytics';

export default function AddListButton({
  playlistId,
  playlistData,
  player,
  onClipsAdded,
  highlighted,
  clipCount,
  onOpen,
  onClose,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
    if (!isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleSelect = (clip) => {
    setSelectedItems((prevState) => {
      if (prevState.map((c) => c.id).includes(clip.id)) {
        return prevState.filter((c) => c.id !== clip.id);
      }
      return [...prevState, clip];
    });
  };

  const clearSelected = () => setSelectedItems([]);

  const handleAddClips = () => {
    axios
      .post(`/api/reels/${playlistId}/clips`, {
        clips: selectedItems,
      })
      .then(() => {
        onClipsAdded();
      });
    toggleModal();
  };

  const addButton = (
    <Add color="primary-1" size={highlighted ? '32px' : '24px'} />
  );

  const renderAddButton = () => {
    if (highlighted) {
      return (
        <Box
          direction="row"
          align="center"
          animation={{
            type: 'pulse',
            duration: 800,
          }}
        >
          <Box background="dark-5" round="full" pad="xsmall">
            {addButton}
          </Box>
          <Text color="dark-1" margin={{ left: 'small' }}>
            Add Clips!
          </Text>
        </Box>
      );
    }
    return addButton;
  };

  return (
    <>
      <Action
        onClick={() => {
          analytics.track('sidelineREEL Add Clips Clicked', {
            playlistId,
            clipCount,
          });
          toggleModal();
        }}
      >
        {renderAddButton()}
      </Action>

      {isOpen && (
        <AddModal
          toggleModal={toggleModal}
          handleAddClips={handleAddClips}
          handleSelect={handleSelect}
          player={player}
          selectedItems={selectedItems}
          existingClipIds={playlistData.clipList.map((c) => c.clipId)}
          clearSelected={clearSelected}
        />
      )}
    </>
  );
}
