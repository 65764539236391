// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';

import { Box, Heading, Text, Layer, Button, Card, Footer } from 'grommet';

export const VideoPlayListContainer = styled(Box)`
  display: grid;
  grid-template-rows: auto 3fr;
  height: 100vh;
  overflow: hidden;
  max-width: 1000px;

  @media (orientation: landscape) {
    max-width: 100%;
    height: 100%;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr; // creates two equal-width columns
    overflow: auto;
  }
`;

export const PlaylistVideo = styled(Box)`
  max-height: max-content;
  position: relative;
`;

export const PlayListWrapper = styled(Box)`
  padding: 12px;
  flex: 1 1 auto;
`;

export const PlayListTitle = styled(Heading)`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

export const ActionsPanel = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.global.colors['light-3']};
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  padding: 4px 0;
`;

export const Action = styled(Box)`
  margin-right: 10px;
  cursor: pointer;
`;

export const OwnerPanel = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.global.colors['light-3']};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  padding: 4px 0;
`;

export const Avatar = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 10px;
`;

export const Name = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: #000;
`;

export const TrackListHeaderPanel = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  padding: 12px 0;
`;

export const VideoTracksContainer = styled(Box)`
  overflow-x: hidden;
  padding-bottom: 80px;
`;

export const ShuffleButton = styled(Box)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: ${({ $isActive, theme }) =>
    $isActive ? theme.global.colors['primary-1'] : '#000'};
`;

export const TracksQuantity = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.global.colors['dark-4']};
`;

export const TrackCardWrapper = styled(Box)`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${({ $isCurrent, theme }) =>
    $isCurrent ? theme.global.colors['light-3'] : '#FFF'};
  border: 1px solid #e4e4e4;
  border-radius: 10px;
`;

export const TrackDragButton = styled(Box)`
  margin: 0 10px;
`;

export const TrackCardContent = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
  box-shadow: none;
`;

export const TrackInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TrackTitle = styled(Heading)`
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin: 0;
`;

export const TrackSub = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  color: #808080;
`;

export const TrackActions = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BottomModal = styled(Layer)`
  width: ${({ $isMobile }) => ($isMobile ? '100%' : '450px')};
  height: 80svh;
  border-radius: 12px 12px
    ${({ $isMobile }) => ($isMobile ? '0 0' : '12px 12px')};
`;

export const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 8px;
`;

export const ModalHeader = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.global.colors['dark-5']};
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
`;

export const ModalTitle = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
`;

export const ModalTopRight = styled(Box)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalBody = styled(Box)`
  display: flex;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 60px;
`;

export const ModalFooter = styled(Footer)`
  padding-bottom: 12px;
`;

export const ModalButton = styled(Button)`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.global.colors['tertiary-1']};
  border: 1px solid ${({ theme }) => theme.global.colors['tertiary-1']};
`;

export const FullModal = styled(Layer)`
  background-color: ${({ theme }) => theme.global.colors['dark-5']};
  position: relative;
  max-width: 500px;
  height: 100svh;
`;

export const CloseButton = styled(Box)`
  width: 32px;
  height: 32px;
  padding: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 32px;
    height: 32px;
    fill: #000;
    stroke: #000;
  }
`;

export const GameClipsRow = styled(Box)`
  margin: 15px 0;
  max-width: fit-content;
  flex-shrink: 0;
`;

export const GameClipsTitle = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const GameClipSubtitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
`;

export const ClipItemsRow = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 15px;
  flex-shrink: 0;
`;

export const ClipCard = styled(Card)`
  width: 156px;
  height: 126px;
  background-color: #fff;
  margin-right: 15px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
`;

export const CardBody = styled(Box)`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export const CardFooter = styled(Footer)`
  padding: 10px;
  ${(props) => props.disabled && `background-color: rgba(0, 0, 0, 0.5);`}
`;

export const CardTitle = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
`;

export const CardAction = styled(Box)`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const FloatingButton = styled(Box)`
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  padding: 0 15px;

  & button {
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.4);
  }
`;
