import React from 'react';
import { Button, ButtonExtendedProps } from 'grommet';
import { analytics, Dict } from '../../services/analytics';
import { strogging } from '@shd/jslib/infra';

interface Props extends ButtonExtendedProps {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  mixtrack?: [string, Dict?];
  label?: React.ReactNode;
}

const SHDButton: React.FC<Props> = ({
  onClick = () => {},
  mixtrack = undefined,
  ...props
}) => {
  const log = () => {
    const trackables = mixtrack ?? [];
    const label = trackables[0] || props.label;
    if (!label || typeof label !== 'string') {
      return;
    }
    analytics.track(label, trackables[1]);
    strogging.log('button-click', { label, trackables });
  };

  return (
    <Button
      {...props}
      onClick={(e) => {
        log();
        onClick(e);
      }}
    />
  );
};

export default SHDButton;
