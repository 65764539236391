// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Box, Text } from 'grommet';
import { Add, Subtract } from 'grommet-icons';

const PlusMinus = ({
  handleEvent,
  outcomeType,
  currentNum,
  disabled = false,
  background = 'secondary-7',
}) => (
  <Box
    direction="row"
    align="center"
    style={{
      pointerEvents: disabled ? 'none' : 'all',
      opacity: disabled ? '0.25' : '1',
    }}
  >
    <Box background={background} pad="medium">
      <Subtract
        onClick={() =>
          currentNum > 0 && handleEvent(outcomeType, { isPlus: false })
        }
        disabled
        color="#000000"
      />
    </Box>
    <Box
      pad={{ vertical: 'small', horizontal: 'medium' }}
      margin={{ horizontal: 'small' }}
    >
      <Text
        size="xxlarge"
        weight="bold"
        style={{ width: '1em', textAlign: 'center' }}
      >
        {currentNum}
      </Text>
    </Box>
    <Box background={background} pad="medium">
      <Add
        color="#000000"
        onClick={() => handleEvent(outcomeType, { isPlus: true })}
      />
    </Box>
  </Box>
);

export default PlusMinus;
