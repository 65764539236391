import React, { useContext } from 'react';
import { UserContext } from '../../services/Session/context';
import { useIsFeatureEnabled } from '../../helpers/utils';
import SettingsSocialAuth from './SettingsSocialAuth';
import SettingsPhoneAuth from './SettingsPhoneAuth';

const SettingsPage: React.FC = () => {
  const userStore = useContext(UserContext);
  const isPhoneNumberAuth = useIsFeatureEnabled('phone_number_auth', userStore);

  return isPhoneNumberAuth ? <SettingsPhoneAuth /> : <SettingsSocialAuth />;
};

export default SettingsPage;
