// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect } from 'react';

import { Box, Text } from 'grommet';
import { GenericScoringContext } from '../context';
import ScoringButton from '../ScoringButton';

const Soccer = ({ gameEventTypes, disabled }) => {
  const scoringStore = useContext(GenericScoringContext);
  useEffect(() => {}, []);

  return (
    <Box
      margin="medium"
      style={{
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? '0.25' : '1',
      }}
      fill="vertical"
    >
      <Box
        direction="row"
        gap="large"
        pad={{ vertical: 'small' }}
        align="center"
      >
        <Text weight="bold" size="large">
          My Team
        </Text>
      </Box>
      <Box background="light-3" pad="small" gap="8px" round="8px" fill>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.soccer_goal.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'soccer_goal',
                gameEventTypes.soccer_goal
              );
            }}
          />
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.soccer_missed_shot.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'soccer_missed_shot',
                gameEventTypes.soccer_missed_shot
              );
            }}
          />
        </Box>
        <ScoringButton
          basis="1/1"
          label={gameEventTypes.soccer_save.uiName}
          onClick={() => {
            scoringStore.handleEvent('soccer_save', gameEventTypes.soccer_save);
          }}
        />
      </Box>
      <Box
        margin={{ top: 'medium' }}
        direction="row"
        gap="large"
        pad={{ vertical: 'small' }}
        align="center"
      >
        <Text weight="bold" size="large">
          Opponent
        </Text>
      </Box>
      <Box background="light-3" pad="small" gap="8px" round="8px" fill>
        <ScoringButton
          basis="1/1"
          label={gameEventTypes.soccer_opponent_goal.uiName}
          onClick={() => {
            scoringStore.handleEvent(
              'soccer_opponent_goal',
              gameEventTypes.soccer_opponent_goal
            );
          }}
          isOpponent
        />
      </Box>
    </Box>
  );
};

export default Soccer;
