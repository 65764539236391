import { Box, Button, Card, ResponsiveContext, Text } from 'grommet';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClipFeedClip } from './player.types';
import axios from '../../helpers/axios';
import { formatDateShort, hashClipFilenameToKey } from '../../helpers/utils';
import * as Sentry from '@sentry/browser';
import VBPlayerClip from './VBPlayerClip';
import GenericPlayerClip from './GenericPlayerClip';
import PlayerClip from './PlayerClip';
import { usePlayerPrimaryInfo, useVideoDimensions } from './playerHooks';
import { FormNext } from 'grommet-icons';
import { shd } from '@shd/jslib/models';
import withLayout from '../../helpers/withLayout';
import SHDButton from '../../components/SHD/Button';
import ClaimPlayerLayer from './ClaimPlayerLayer';
import { useToast } from '../../contexts/ToastContext';

interface Props extends RouteComponentProps {
  handle: string;
  clipId: string;
}

const SingleClipPage: React.FC<Props> = ({ handle, clipId, history }) => {
  const screen = useContext(ResponsiveContext);
  const { showToast } = useToast();

  const [playerId, setPlayerId] = useState<string | null>(null);
  const [clip, setClip] = useState<ClipFeedClip | null>(null);
  const [loading, setLoading] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const videoDimensions = useVideoDimensions();
  const {
    playerInfo: playerPrimaryInfo,
    error: playerInfoError,
    loading: playerInfoLoading,
  } = usePlayerPrimaryInfo(playerId ?? undefined);

  const getPlayerIdFromText = (text: string) => {
    const url = new URL(text, 'http://dummy.com');

    const handleWithoutQueryParams = url.pathname.replace('/', '');
    return handleWithoutQueryParams;
  };

  const getUser = useCallback(async () => {
    if (handle.includes('TpSh01-') || handle.includes('CpSh01-')) {
      setPlayerId(getPlayerIdFromText(handle));
      setLoading(false);
    } else {
      try {
        const { data: respPlayerId } = await axios.get(
          `/api/player_handle/${handle}`
        );
        setPlayerId(respPlayerId);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    }
  }, [handle]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (playerPrimaryInfo?.player) {
      if (shd.isClaimedPlayer(playerPrimaryInfo.player)) {
        history.replace({
          pathname: `/${playerPrimaryInfo.player.nameHandle}${`/c/${clipId}`}`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPrimaryInfo]);

  useEffect(() => {
    if (playerPrimaryInfo?.claimedPlayerId) {
      // url in the form of /:team_player_id/c/:clip_id.
      // need to redirect to /:claimed_player_id/c/:clip_id
      history.replace({
        pathname: `/${playerPrimaryInfo.claimedPlayerId}${`/c/${clipId}`}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPrimaryInfo?.claimedPlayerId]);

  const getClip = useCallback((id: string) => {
    setLoading(true);
    let clipKey;
    if (id.length > 16) {
      clipKey = hashClipFilenameToKey(id);
    } else {
      clipKey = id;
    }

    axios
      .get(`/api/clip/${clipKey}`)
      .then((response) => {
        setClip(response.data);
        setLoading(false);
      })
      .catch((respError) => {
        // setClipsError('You struckout! Make sure your link is correct.');
        Sentry.captureException(respError);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (clipId) {
      getClip(clipId);
    }
  }, [clipId, getClip]);

  return (
    <Box
      margin="auto"
      width={screen === 'small' ? '100%' : `${videoDimensions.width}px`}
      gap="medium"
      pad={{ vertical: 'medium' }}
    >
      <Card
        pad="medium"
        gap="medium"
        background="primary-8"
        onClick={() => {
          history.push(`/${handle}`);
        }}
      >
        <Box direction="row" justify="between">
          <Box skeleton={playerInfoLoading}>
            {playerPrimaryInfo?.player && (
              <Text id="player-name-header" size="xlarge" weight="bold">
                {playerPrimaryInfo?.player?.nameFirst}{' '}
                {playerPrimaryInfo?.player?.nameLast}
              </Text>
            )}
            {playerInfoError && (
              <Text size="small" color="status-error">
                {playerInfoError}
              </Text>
            )}
          </Box>
          <Button
            alignSelf="center"
            justify="end"
            plain
            reverse
            gap="xxsmall"
            label={<Text size="small">More videos</Text>}
            icon={<FormNext />}
            onClick={() => {
              history.push(`/${handle}`);
            }}
          />
        </Box>
      </Card>
      <Box skeleton={loading}>
        <Card gap="xsmall" background="secondary-7" round="small">
          <Box direction="row" justify="between" pad="medium" gap="medium">
            <Box fill="horizontal" gap="xsmall">
              <Text weight="bold">{`${clip?.game?.scoreOurName}`}</Text>
              <Box
                direction="row"
                gap="medium"
                align="center"
                justify="between"
              >
                <Box>
                  <Text weight="bold">{`vs ${clip?.game?.scoreOpponentName}`}</Text>
                  {clip?.clipStartTs && (
                    <Text size="small">
                      {formatDateShort(clip?.clipStartTs)}
                    </Text>
                  )}
                </Box>
                <Button
                  primary
                  color="primary-1"
                  label="Play-by-Play"
                  onClick={() => {
                    history.push(`/game/${clip?.game?.id}`);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Card>
        {clip?.clipType === 'ClipEventVb' && (
          <VBPlayerClip
            {...{
              active: true,
              key: clip.id,
              clip: clip,
              game: clip.game,
              playerId: playerId,
              publicPublishLevel: 10,
              videoDimensions: videoDimensions,
              onShowClaim: () => {
                setShowClaim(true);
              },
              setToast: (toast: shd.Toast) => {
                showToast({ ...toast, message: toast.label });
              },
            }}
          />
        )}
        {clip?.clipType && clip?.clipType.includes('clip_') && (
          <GenericPlayerClip
            {...{
              active: true,
              key: clip.id,
              sport: clip?.clipType.replace('clip_', ''),
              clip: clip,
              game: clip.game,
              playerId: playerId,
              publicPublishLevel: 10,
              videoDimensions: videoDimensions,
              onShowClaim: () => {
                setShowClaim(true);
              },
              setToast: (toast: shd.Toast) => {
                showToast({ ...toast, message: toast.label });
              },
            }}
          />
        )}
        {clip &&
          clip.game &&
          clip?.clipType !== 'ClipEventVb' &&
          clip?.clipType &&
          !clip?.clipType.includes('clip_') && (
            <PlayerClip
              active
              key={clip?.id}
              clip={clip}
              game={clip?.game}
              playerId={playerId ?? ''}
              pinClip={null}
              unpinClip={null}
              hideBottomBar={false}
              publicPublishLevel={10}
              onShowClaim={() => {
                setShowClaim(true);
              }}
            />
          )}
        <Box margin={{ top: 'medium' }} gap="medium">
          {clip?.J_claimedPlayerList && clip?.J_claimedPlayerList.length > 0 ? (
            <SHDButton
              primary
              color="tertiary-0"
              id="see-more-game"
              size="large"
              label={
                <Text weight="bold">
                  {playerPrimaryInfo?.player
                    ? `See ${playerPrimaryInfo.player.nameFirst}'s clips from this game`
                    : 'See more from this game'}
                </Text>
              }
              mixtrack={[
                'See More Clips From This Game Clicked',
                {
                  'Player ID': playerId,
                  'Game ID': clip?.game?.id,
                },
              ]}
              onClick={() => {
                if (clip?.clipType === 'ClipEventVb') {
                  history.push(
                    `/${playerId}?sport=vb&gameIds=${clip?.game?.id}`
                  );
                } else if (clip?.clipType && clip?.clipType.includes('clip_')) {
                  const sport = clip?.clipType.replace('clip_', '');
                  history.push(
                    `/${playerId}?sport=${sport}&gameIds=${clip?.game?.id}`
                  );
                } else {
                  // BBSB only
                  history.push(
                    `/${playerId}#${clip?.game?.id}-${clip?.playerRole}`
                  );
                }
              }}
            />
          ) : (
            <SHDButton
              primary
              color="tertiary-0"
              id="claim-this-player"
              size="large"
              label={<Text weight="bold">Claim this player</Text>}
              mixtrack={['Claim this Player Clicked']}
              onClick={() => {
                setShowClaim(true);
              }}
            />
          )}
        </Box>
      </Box>
      {showClaim &&
        playerPrimaryInfo?.teams &&
        playerPrimaryInfo.teams.length > 0 && (
          <ClaimPlayerLayer
            {...{
              team: playerPrimaryInfo.teams[0],
              teamPlayerId: playerId,
              closeLayer: () => setShowClaim(false),
            }}
          />
        )}
    </Box>
  );
};

export default withLayout(withRouter(SingleClipPage));
