import React from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { PrivacyStatus, YTAsset } from './Games/games.types';

interface YouTubeAccessWarningModalProps {
  ytAsset: YTAsset;
  onClose: () => void;
}

const YouTubeAccessWarningModal: React.FC<YouTubeAccessWarningModalProps> = ({
  ytAsset,
  onClose,
}) => {
  const { privacyStatus } = ytAsset;

  return (
    <Layer onEsc={onClose} onClickOutside={onClose}>
      <Box pad="medium" gap="medium">
        <Box direction="row" align="center" justify="between" gap="medium">
          <Box width={'24px'} />
          {privacyStatus === PrivacyStatus.NotPresent ||
          privacyStatus === PrivacyStatus.Private ? (
            <Text weight="bold">Redirecting to YouTube...</Text>
          ) : (
            <Text weight="bold">YouTube Video Access</Text>
          )}
          <Close size="24px" onClick={onClose} />
        </Box>

        {privacyStatus === PrivacyStatus.Error && (
          <Box gap="small">
            <Text>
              We are unable to find this video on YouTube. Please try back
              later.
            </Text>
          </Box>
        )}

        {privacyStatus === PrivacyStatus.Unlisted && (
          <Box gap="small">
            <Text>This YouTube broadcast is unlisted.</Text>
            <Text>Access is restricted to logged in team members.</Text>
            <Text>
              You can request the link from a team manager, coach, or family
              member.
            </Text>
          </Box>
        )}

        {(privacyStatus === PrivacyStatus.NotPresent ||
          privacyStatus === PrivacyStatus.Private) && (
          <Box gap="small">
            <Text>
              This YouTube broadcast has been removed by the channel admin or
              has been set private.
            </Text>
            <Text>
              You may be able to watch this video if your YouTube account is on
              the access list.
            </Text>
          </Box>
        )}
        {(privacyStatus === PrivacyStatus.NotPresent ||
          privacyStatus === PrivacyStatus.Private) && (
          <Box gap="small">
            <Button
              primary
              label="Continue"
              href={ytAsset.cdnUrl}
              target="_blank"
            />
            <Button
              secondary
              label={'Go Back'}
              onClick={() => {
                onClose();
              }}
            />
          </Box>
        )}
      </Box>
    </Layer>
  );
};

export default YouTubeAccessWarningModal;
