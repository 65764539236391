// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import {
  Box, Card, Heading, Text,
} from 'grommet';
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';
import OnboardingButton from './OnboardingButton';
import ClipVideoPlayer from '../../helpers/ClipVideoPlayer';
import { BBSB_SPORTS_KEYS } from '../../constants/strings';

const ExampleClipStep = ({ value, handleForward }) => {
  const baseballClip = {
    title: 'Jaden home run',
    subtitle: 'top 6th',
    opponent: 'vs Ohio Heat',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FBaseballOnboardingVideo.mp4?alt=media&token=593734ae-0729-4612-9ac2-4e2bb99b4f1f',
  };

  const softballClip = {
    title: 'Addi home run',
    subtitle: 'bot 3rd',
    opponent: 'vs Western',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FSoftballOnboardingVideo.mp4?alt=media&token=8c057c9d-90aa-48ca-986b-629ec6eecd9e',
  };

  const volleyballClip = {
    title: 'Katie Kill',
    subtitle: 'Set 1 (2-4)',
    opponent: 'vs Fieldhouse 12U-Toa (15-25, 10-25)',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FVolleyballOnboardingVideo.mp4?alt=media&token=2d6b7c31-35b1-4325-85a9-bee239b61ca5',
  };

  const basketballClip = {
    title: 'Trae 2pt shot',
    subtitle: '2nd period',
    opponent: 'vs Team HQ 2032 (13-36)',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FBasketballOnboardingVideo.mp4?alt=media&token=3ded35af-0f05-410e-9faf-3a9f50edb575',
  };

  const soccerClip = {
    title: 'Aubrey goal',
    subtitle: '2nd half',
    opponent: 'vs Eagles (3-0)',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FSoccerOnboardingVideo.mp4?alt=media&token=27ba9bca-2e84-4da9-93a6-c77ec0c5f550',
  };

  const hockeyClip = {
    title: 'Braxton goal',
    subtitle: '1st period',
    opponent: 'vs MC1 (6-1)',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FHockeyOnboardingVideo.mp4?alt=media&token=3d3f1686-b520-4126-93a8-9b606f227af0',
  };

  const lacrosseClip = {
    title: 'Brady goal',
    subtitle: '2nd period',
    opponent: 'vs True OH Black 2027 (3-4)',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FLacrosseOnboardingVideo.mp4?alt=media&token=684b6672-8046-45d6-a5e8-f41bce8755de',
  };

  const waterPoloClip = {
    title: 'Ryan P goal',
    subtitle: '1st period',
    opponent: 'vs Vanguard 10u (6-4)',
    fileDownloadUrl: 'https://firebasestorage.googleapis.com/v0/b/shd-001-app/o/OnboardingVideo%2FWaterPoloOnboardingVideo.mp4?alt=media&token=7eba8b31-1a64-4e2b-bc45-047fbd90acd7',
  };

  const clips = {
    baseball: baseballClip,
    softball: softballClip,
    volleyball: volleyballClip,
    basketball: basketballClip,
    soccer: soccerClip,
    hockey: hockeyClip,
    lacrosse: lacrosseClip,
    water_polo: waterPoloClip,
  };

  const clip = clips[value.attrib_sportType];

  return (
    <Box gap="medium">
      <Heading level={3}>
        {`Every member of your ${value.attrib_sportType} team will receive personalized highlights like this one.`}
      </Heading>
      <Box>
        <Card elevation="medium" margin={{ top: 'medium' }}>
          <Box>
            <Box
              style={{ display: 'block' }}
              align="start"
              fill="horizontal"
              pad="medium"
            >
              <Box
                direction="row"
                fill="horizontal"
                align="center"
                justify="between"
                pad={{ bottom: 'small' }}
              >
                <Box fill="horizontal" gap="xsmall">
                  <Box gap="small" direction="row" fill="horizontal" align="center" justify="between">
                    <Box fill="horizontal">
                      <Box direction="row" align="center" justify="between">
                        <Text weight="bold">
                          {clip.title}
                        </Text>
                      </Box>
                      <Text weight="bold">
                        {clip.subtitle}
                      </Text>
                      <Text>{clip.opponent}</Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <LazyLoad
              once
              placeholder={
                <Box fill="horizontal" style={{ height: '200px' }}>
                  <Skeleton height={200} duration={0.8} />
                </Box>
              }
            >
              <ClipVideoPlayer
                videoSrc={clip.fileDownloadUrl}
                showLock={false}
                showNotClaimed={false}
                id={`${value.attrib_sportType}-example`}
                sport={[
                  BBSB_SPORTS_KEYS.includes(value.attrib_sportType)
                    ? 'bbsb'
                    : value.attrib_sportType,
                ]}
                isOnBoarding={true}
              />
            </LazyLoad>
          </Box>
        </Card>
      </Box>
      <OnboardingButton
        label="Next"
        // disabled={disabledButton}
        onClick={handleForward}
      />
    </Box>
  );
};

export default ExampleClipStep;
