import React from 'react';
import { Grommet, ThemeType } from 'grommet';
import { GROMMETTHEME } from '../constants/grommetTheme';

interface Props {}

const GrommetContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return (
    <Grommet full="min" theme={GROMMETTHEME as unknown as ThemeType} {...props}>
      {children}
    </Grommet>
  );
};

export default GrommetContainer;
