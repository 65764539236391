import React from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Layer,
  Text,
} from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TeamCapture } from '@shd/jslib/models/shd';

interface Props {
  capture: TeamCapture;
  onClose: () => void;
}

const SmartphoneSetupModal: React.FC<Props> = ({ capture, onClose }) => {
  // TODO: Handle bad state where capture.rtmp is null
  const [copied, setCopied] = React.useState(false);
  return (
    <Layer
      modal
      full="vertical"
      style={{ height: '100dvh' }}
      animation={false}
      background={'light-5'}
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Card round={false} elevation="none" fill>
        <CardHeader direction="row" pad="16px" align="center" justify="between">
          <Box alignSelf="start" onClick={onClose}>
            <LinkPrevious size="24px" />
          </Box>
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              Pair your phone camera
            </Text>
          </Box>
          <Box width="24px" />
        </CardHeader>
        <CardBody fill>
          <Box pad="medium" flex={{ grow: 0, shrink: 0 }} gap="small">
            <Text weight="bold">Ensure you are on your streaming device</Text>
            <Text size="medium">
              The device you are holding is the device you are setting up.
            </Text>
            <Text>When prompted to import settings, press OK.</Text>
            {/* TODO: get android asset */}
            <Box round="4px" overflow="hidden" height={{ max: '240px' }}>
              <Image src="/camera_setup/larix_import.png" fit="contain" />
            </Box>
            <Button
              primary
              label="Open in Larix App"
              onClick={() => {
                window.open(capture.rtmp?.larixUrl || '', '_blank');
              }}
            />
            <Box
              margin={{ top: 'small' }}
              gap="8px"
              pad="16px"
              round="8px"
              border
            >
              <Text weight={'bold'}>Setup not working?</Text>
              <Text>You can manually copy the Larix URL below:</Text>
              <Box
                direction="row"
                justify="between"
                margin={{ top: '4px' }}
                align="center"
                flex
              >
                <Text size="small" truncate>
                  {capture.rtmp?.larixUrl}
                </Text>
                <CopyToClipboard
                  text={capture.rtmp?.larixUrl || ''}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 3000);
                  }}
                >
                  <Box flex={{ grow: 1, shrink: 0 }}>
                    <Button
                      secondary
                      size="small"
                      label={copied ? 'Copied!' : 'Copy to Clipboard'}
                      disabled={copied}
                    />
                  </Box>
                </CopyToClipboard>
              </Box>
            </Box>
          </Box>
        </CardBody>
        <CardFooter
          pad={'16px'}
          background="white"
          border={{
            color: 'light-2',
            side: 'top',
            size: '1px',
            style: 'solid',
          }}
        >
          <Box fill="horizontal" align="center" gap="8px">
            <Text weight="bold">Step 1 of 1</Text>
            <Button fill primary size="large" label="Done" onClick={() => {}} />
          </Box>
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default SmartphoneSetupModal;
