import React, { useState } from 'react';
import { Box, Button, CheckBox, Text, Layer, Anchor } from 'grommet';
import axios from '../helpers/axios';

interface PhoneNumberConsentProps {
  acceptButtonProps: React.ComponentProps<typeof Button>;
}

const PhoneNumberConsent: React.FC<PhoneNumberConsentProps> = ({
  acceptButtonProps,
}) => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attemptedToProceed, setAttemptedToProceed] = useState<boolean>(false);

  const handleAccept = (e: React.MouseEvent<HTMLElement>) => {
    if (!accepted) {
      setAttemptedToProceed(true);
    } else {
      acceptButtonProps.onClick?.(e as React.MouseEvent<HTMLButtonElement>);
      axios.post('/api/user_sms_opt_in');
    }
  };

  return (
    <Box gap="small">
      <CheckBox
        label={
          <Text size="xsmall">
            I consent for sidelineHD to send SMS messages for login support,
            delivery of highlight videos, and livestream status messages.
          </Text>
        }
        checked={accepted}
        onChange={(event) => setAccepted(event.target.checked)}
      />
      {!accepted && attemptedToProceed && (
        <Text color="status-critical">
          This must be checked to use sidelineHD BETA.{' '}
          <Text
            color="brand"
            style={{ textDecoration: 'underline' }}
            onClick={() => setShowModal(true)}
          >
            Learn more
          </Text>
        </Text>
      )}
      <Text size="xsmall">
        {`We may send SMS messages when you login to our app or website, when your
        athlete or team is playing, or when video highlights are ready. For HELP
        visit help.sidelineHD.com. To unsubscribe, send STOP. Standard message
        and data rates may apply. By clicking Accept, you agree to Diamond
        Kinetics' `}
        <Anchor href="https://sidelinehd.com/terms-of-service">
          Terms of Service
        </Anchor>{' '}
        and{' '}
        <Anchor href="https://sidelinehd.com/privacy">Privacy Policy</Anchor>.
      </Text>

      <Button {...acceptButtonProps} onClick={handleAccept} />
      {showModal && (
        <Layer
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
        >
          <Box pad="medium" gap="small">
            <Text>
              The sidelineHD service requires text messages for login support,
              receiving video highlights, receiving invitations to manage a team
              or player, and livestream status messages.
            </Text>
            <Text>
              A textable phone number is required to livestream and manage a
              team.
            </Text>
            <Text>
              If you do not consent to use of texts but wish to use sidelineHD
              BETA, contact support@sidelineHD.com.
            </Text>
            <Button secondary label="OK" onClick={() => setShowModal(false)} />
          </Box>
        </Layer>
      )}
    </Box>
  );
};

export default PhoneNumberConsent;
