// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { getRequestFullScreenKey } from '../../../helpers/utils';
import {
  GreetingWrapper, GreetingTitle, GreetingSubTittle, GreetingTitleBlock,
} from './style';

export default function GreetingScreen({ onInit }) {
  const handleClick = useCallback(() => {
    // request for full screen mode
    document.documentElement[getRequestFullScreenKey()]?.();
    // to lock orientation the app need to be in a full screen mode
    // eslint-disable-next-line no-restricted-globals
    screen.orientation
      .lock('landscape')
      // eslint-disable-next-line no-console
      .catch(console.warn)
      .finally(() => onInit?.(true));
  }, [onInit]);

  return (
    <GreetingWrapper onClick={handleClick}>
      <GreetingTitleBlock>
        <GreetingTitle>sidelineCAM</GreetingTitle>
        <GreetingSubTittle>tap to continue</GreetingSubTittle>
      </GreetingTitleBlock>
    </GreetingWrapper>
  );
}

GreetingScreen.propTypes = {
  onInit: PropTypes.func,
};

GreetingScreen.defaultProps = {
  onInit: null,
};
