import React from 'react';
import { Anchor, Box, Image, Card, Text } from 'grommet';
import { LiveEvents } from './sidelineLive.analytics';
import { SidelineLiveBetaProps } from './sidelineLive.types';

const SidelineLiveBeta: React.FC<SidelineLiveBetaProps> = ({ trackEvent }) => {
  const handleReadMore = () => {
    trackEvent(LiveEvents.READ_MORE, {});
    window.open(
      'https://help.sidelinehd.com/hc/en-us/articles/23960588341655-sidelineLIVE-Watch-on-sidelineHD',
      '_blank'
    );
  };
  return (
    <Card
      pad="medium"
      margin={{ bottom: '8rem', horizontal: 'medium' }}
      elevation="none"
      border
    >
      <Image src="/live_beta.png" />
      <Box margin={{ left: '.5rem', top: '1rem' }}>
        <Text size="1rem">sidelineLIVE is currently in beta.</Text>
        <Anchor size="1rem" onClick={handleReadMore}>
          Read more
        </Anchor>
      </Box>
    </Card>
  );
};

export default SidelineLiveBeta;
