// Packages
import React, { useState } from 'react';
import styles from '../../Admin.module.css';

// Types
import { MergePlayersStepTwoProps } from '../../admin.types';

// Components
import { Add } from 'grommet-icons';
import { Heading, Button, Text } from 'grommet';
import AdminPlayerCard from '../../components/AdminPlayerCard';
import AdminSecondaryIdModal from '../../components/AdminSecondaryIdModal';
import AdminStepButtons from '../../components/AdminStepButtons';

const MergePlayersStepTwo: React.FC<MergePlayersStepTwoProps> = ({
  onAddSecondaryPlayer,
  onProgressStep,
  onRemovePrimaryPlayer,
  onRemoveSecondaryPlayer,
  primaryPlayer,
  secondaryPlayers,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const isReadyForNextStep =
    primaryPlayer && secondaryPlayers && secondaryPlayers.length > 0
      ? true
      : false;

  return (
    <>
      <Heading level={2} className={styles['admin-heading']}>
        Primary Player
      </Heading>
      <AdminPlayerCard
        payload={primaryPlayer}
        hasChangeButton={true}
        onChangeClicked={() => onRemovePrimaryPlayer && onRemovePrimaryPlayer()}
      />

      <Heading
        level={2}
        className={styles['admin-heading']}
        margin={{ top: '1.5rem!important' }}
      >
        Secondary IDs
      </Heading>
      {secondaryPlayers?.map((player, i) => (
        <AdminPlayerCard
          payload={player}
          key={i}
          hasChangeButton={false}
          hasRemoveButton={true}
          onRemoveClicked={() => {
            player && onRemoveSecondaryPlayer(player);
          }}
        />
      ))}

      <Button
        className={styles['admin-add-player']}
        onClick={() => setShowModal(true)}
      >
        <Add />
        <Text>Add a player</Text>
      </Button>

      <AdminStepButtons
        isFixed={true}
        backLabel="Back"
        isNextActive={isReadyForNextStep}
        nextLabel="Next Step"
        onBack={() => {
          onRemovePrimaryPlayer && onRemovePrimaryPlayer();
        }}
        onNext={() => {
          onProgressStep && onProgressStep();
        }}
      />

      {showModal && (
        <AdminSecondaryIdModal
          onCloseModal={() => setShowModal(false)}
          onAddSecondaryPlayer={(player) => {
            onAddSecondaryPlayer(player);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default MergePlayersStepTwo;
