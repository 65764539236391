import React from 'react';
import { Box, Text, Anchor } from 'grommet';
import { useRemoteConfigValue } from './utils';
import { WebRemoteConfigParam } from '../services/FlagsProvider/FlagsProvider';

type Banner = {
  title: string;
  subtitle?: string;
  ctaText?: string;
  ctaLink?: string;
};
interface Props {
  name: WebRemoteConfigParam;
}
const DynamicBanner: React.FC<Props> = ({ name }) => {
  const banner = useRemoteConfigValue(name) as Banner | undefined;

  if (!banner) {
    return null;
  }

  return (
    <Box background="status-warning" pad="medium" fill="horizontal">
      <Text>
        {banner.title}{' '}
        {banner.subtitle && (
          <Text size="small" weight="normal">
            {banner.subtitle}
          </Text>
        )}
        {banner.ctaLink && banner.ctaText && (
          <Anchor href={banner.ctaLink} target="_blank">
            {banner.ctaText}
          </Anchor>
        )}
      </Text>
    </Box>
  );
};

export default DynamicBanner;
