// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box, Card, CardBody, CardHeader, Heading, Text,
} from 'grommet';
import useRemoteLoginAuthorize from './useRemoteLoginAuthorize';
import SHDButton from '../../components/SHD/Button';
import { useOrientation } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import withLayout from '../../helpers/withLayout';

const RemoteLoginAuthorizePage = () => {
  const userStore = React.useContext(UserContext);
  const { queryPin, authorize } = useRemoteLoginAuthorize();
  const { isPortrait } = useOrientation({ });
  const isLandscape = !isPortrait;
  return (
    <Box
      background="primary-1"
      height="100vh"
      width="100vw"
    >
      <Card
        align="center"
        pad={isLandscape ? 'small' : 'large'}
        gap="small"
        margin={isLandscape ? '5%' : '10%'}
        background="white"
      >
        <CardHeader gap="small">
          {queryPin
            ? (
              <Heading level={4}>
                Activation Code:
                {' '}
                {queryPin}
              </Heading>
            )
            : <Heading level={3}>Authorize</Heading>}
        </CardHeader>
        <CardBody
          gap="medium"
          direction={isLandscape ? 'row' : 'column'}
          align={isLandscape ? 'center' : undefined}
        >

          <Text style={{ textAlign: isLandscape ? 'start' : 'center' }}>
            The sidelineHD application is requesting to login as
            {' '}
            {userStore.authUser.email}
          </Text>

          <Box gap="small">
            <SHDButton
              fill
              primary
              color="primary-1"
              label="Activate"
              mixtrack={['Remote Login Authorize']}
              onClick={() => authorize('granted')}
              size="large"
            />
          </Box>
          <Box gap="small">
            <SHDButton
              color="primary-1"
              label="Cancel"
              mixtrack={['Remote Login Cancel']}
              onClick={() => authorize('denied')}
              size="large"
            />
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};

export default withRouter(withLayout(RemoteLoginAuthorizePage));
