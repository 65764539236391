// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Avatar, Box, Card, CardBody, CardHeader, Image, Text } from 'grommet';
import { MdFavorite } from 'react-icons/md';
import axios from '../helpers/axios';
import { useQuery } from '../helpers/utils';

const MLBScoresOverlay = () => {
  const query = useQuery();
  const teamId = query.get('teamId');

  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [likedBy, setLikedBy] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScores = async () => {
      const params = {};
      if (teamId) {
        params.team_id = teamId;
      }
      try {
        const response = await axios.get('/api/overlays/mlb_scores', {
          params,
        });
        const { data } = response;
        setGames(data.games);
        setTeams(data.teams);
        setSelectedTeam(data.selectedTeam);
        setLikedBy(data.likedBy);
      } catch (e) {
        setError(e);
      }
    };
    fetchScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showScoreStates = ['In Progress', 'Final'];

  const renderSHDPlayerSection = () => (
    <Box
      margin={{ horizontal: '40px' }}
      background="primary-8"
      pad="small"
      gap="small"
    >
      <Box direction="row" align="center" gap="small">
        <MdFavorite size="28px" color="red" />
        <Text>{`liked by ${likedBy.length} player${
          likedBy.length > 1 ? 's' : ''
        }`}</Text>
      </Box>
      <Box direction="row" align="center" gap="small">
        {likedBy.map((player) => (
          <Avatar key={player._id} size="64px" src={player.DNOR_imgUrlT150} />
        ))}
      </Box>
    </Box>
  );

  const renderGameCards = () => {
    if (!(teams && games.length)) {
      return null;
    }

    return (
      <Box
        direction="row"
        gap="large"
        align="center"
        justify="start"
        pad={{ horizontal: '40px', top: '12px', bottom: '12px' }}
      >
        {games.map((game, index) => {
          const inputDate = DateTime.fromISO(game.game_datetime, {
            zone: 'utc',
          });
          const easternDate = inputDate.setZone('America/New_York');

          const formattedDate = easternDate.toFormat('LLLL d');
          const formattedTime = easternDate.toFormat('h:mm a');

          return (
            <Card
              key={`card-${index + 1}`}
              fill
              background="white"
              elevation="small"
            >
              <CardHeader
                pad="small"
                border={{
                  color: 'dark-5',
                  size: 'xsmall',
                  side: 'bottom',
                }}
              >
                <Text size="small" weight="bold">
                  {game.status === 'In Progress'
                    ? `${game.inning_state.toUpperCase()} ${
                        game.current_inning
                      }`
                    : game.status.toUpperCase()}
                </Text>
                <Text size="small">
                  {`${formattedDate} - ${formattedTime} ET`}
                </Text>
              </CardHeader>
              <CardBody pad="xsmall" gap="small">
                {[game.away_id, game.home_id].map((_teamId, idx) => {
                  const teamName =
                    idx === 0 ? game.away_team_short : game.home_team_short;
                  let score = '';
                  if (showScoreStates.includes(game.status)) {
                    score = idx === 0 ? game.away_score : game.home_score;
                  } else {
                    score = `${teams[_teamId].w} - ${teams[_teamId].l}`;
                  }
                  return (
                    <Box
                      key={_teamId}
                      direction="row"
                      justify="between"
                      align="center"
                      margin={{ horizontal: 'small' }}
                    >
                      <Box direction="row" align="center" gap="medium">
                        <Image
                          src={`/mlb/logos/team_${_teamId}.svg`}
                          alt={`${teamName} logo`}
                          height="36px"
                          width="36px"
                          fit="contain"
                        />
                        <Text size="xlarge" weight="bold">
                          {teamName}
                        </Text>
                      </Box>
                      <Text size="xlarge" weight="bold">
                        {score}
                      </Text>
                    </Box>
                  );
                })}
              </CardBody>
            </Card>
          );
        })}
      </Box>
    );
  };

  // const renderFooter = () => {
  //   const footerStyles = {
  //     position: 'fixed',
  //     bottom: 0,
  //     zIndex: 10,
  //     width: '100%',
  //   };

  //   return (
  //     <Footer
  //       border="top"
  //       style={footerStyles}
  //     >
  //       <Box
  //         direction="row"
  //         justify="start"
  //         pad="medium"
  //       >
  //         <Image src="/mlb/mlb_logo.png" height="52px" fit="contain" />
  //         <Image src="/dk_logo.png" height="52px" fit="contain" />
  //       </Box>

  //     </Footer>
  //   );
  // };

  if (error) {
    return (
      <Box
        width="900px"
        height="480px"
        gap="small"
        background="primary-8"
        pad="xlarge"
        align="center"
      >
        <Box>
          <Text size="xxl" weight="bold">
            Livestream brought to you by sidelineHD
          </Text>
        </Box>
      </Box>
    );
  }

  if (!selectedTeam) {
    return null;
  }

  // temporarily hard code height and width
  return (
    <Box width={{ max: '900px' }} height={{ max: '480px' }} fill gap="small">
      <Box
        direction="row"
        gap="medium"
        align="center"
        justify="start"
        pad={{ horizontal: '40px', vertical: '12px' }}
        border="bottom"
      >
        <Box height="70px" width="70px" pad="small" round="full" border>
          <Image
            src={`/mlb/logos/team_${selectedTeam.id}.svg`}
            alt={`${selectedTeam.id} logo`}
            fit="contain"
          />
        </Box>

        <Text size="2xl" weight="bold">
          {selectedTeam.name}
        </Text>
      </Box>
      {likedBy.length > 0 && renderSHDPlayerSection()}
      {renderGameCards()}
      {/* {renderFooter()} */}
    </Box>
  );
};

export default MLBScoresOverlay;
