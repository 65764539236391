import React, { useState, useContext, useCallback } from 'react';
import { Box, Layer, Text, Button, CheckBox, Form } from 'grommet';
import { isMobile } from '../../../helpers/browserDetect';
import {
  StaffLimit,
  StaffRole,
  InviteBody,
  StaffModalProps,
  StaffMode,
  StaffMember,
} from '../types';
import AddStaff from './AddStaff';
import { UserContext } from '../../../services/Session';
import EditStaff from './EditStaff';

const StaffModal: React.FC<StaffModalProps> = ({
  mode,
  team,
  staff,
  onUpdateStaff,
  onInviteSent,
  onRemoveStaff,
  onClose,
  selectedStaffMember = null,
}) => {
  const godMode = false;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState('');

  const [name, setName] = useState('');
  const [manager, setManager] = useState(
    selectedStaffMember?.roles?.includes(StaffRole.Manager)
  );
  const [coach, setCoach] = useState(
    selectedStaffMember?.roles?.includes(StaffRole.Coach)
  );
  const [formValue, setFormValue] = useState({});

  const userStore = useContext(UserContext);

  const isSelf =
    selectedStaffMember?._id === userStore.authUser?.claims?.shd_user_id;

  const noChanges =
    manager === selectedStaffMember?.roles?.includes(StaffRole.Manager) &&
    coach === selectedStaffMember?.roles?.includes(StaffRole.Coach);

  const isOverManagerLimit = () => {
    const managerCount = staff.filter((user) =>
      user.roles?.includes(StaffRole.Manager)
    ).length;

    return managerCount >= StaffLimit.Admin && !godMode;
  };

  const isOverCoachLimit = () => {
    const coachCount = staff.filter((user) =>
      user.roles?.includes(StaffRole.Coach)
    ).length;

    return coachCount >= StaffLimit.Coach && !godMode;
  };

  const handleSendInvite = async () => {
    const inviteBody: InviteBody = {
      invitedUserPhone: phoneNumber.trim(),
      invitedUserName: name,
      teamId: team._id,
      teamRole: manager ? StaffRole.Manager : StaffRole.Coach,
    };
    onInviteSent(inviteBody);
    setPhoneNumber('');
    setError(undefined);
    setLoading(false);
  };

  const handlePhoneNumber = useCallback(
    (phoneNumberValue: string) => {
      setPhoneNumber(phoneNumberValue);
      setFormValue({ ...formValue, phoneNumber: phoneNumberValue });
    },
    [formValue]
  );

  const handleUpdateStaff = useCallback(() => {
    const updatedUser: StaffMember = {
      ...selectedStaffMember,
      updateRoles: true,
      teamId: team._id,
    };
    updatedUser.roles = [];
    if (manager) {
      updatedUser.roles.push(StaffRole.Manager);
    }
    if (coach) {
      updatedUser.roles.push(StaffRole.Coach);
    }
    onUpdateStaff(updatedUser);
  }, [onUpdateStaff, selectedStaffMember, coach, manager, team]);

  const handleDeleteStaffMember = useCallback(() => {
    onRemoveStaff({
      ...selectedStaffMember,
      updateRoles: true,
      teamId: team._id,
      roles: [],
    });
  }, [selectedStaffMember, onRemoveStaff, team]);

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
      responsive={false}
      margin={isMobile.any() ? 'large' : 'none'}
      full={isMobile.any() === true}
    >
      <Box flex={{ shrink: 0, grow: 0 }}>
        <Box
          pad="16px"
          border={{ side: 'bottom', color: 'light-1', size: '1px' }}
        >
          <Text size="xlarge" weight="bold">
            {mode} Staff Member
          </Text>
        </Box>
        <Box pad="16px" fill>
          {error && <Text color="status-critical">{error}</Text>}
          <Form
            value={formValue}
            onChange={(nextValue) => {
              setFormValue(nextValue);
            }}
            onSubmit={handleSendInvite}
          >
            {mode === StaffMode.ADD && (
              <AddStaff
                phoneNumber={phoneNumber}
                setPhoneNumber={handlePhoneNumber}
                name={name}
                setName={setName}
              />
            )}
            {mode === StaffMode.EDIT && selectedStaffMember && (
              <EditStaff
                staff={staff}
                selectedStaffMember={selectedStaffMember}
                isSelf={isSelf}
                handleDeleteStaffMember={handleDeleteStaffMember}
              />
            )}
            <Box margin={{ top: 'medium' }}>
              <Box gap="8px">
                <Text weight={'bold'}>Assign Role</Text>
              </Box>
              <Box gap="8px">
                <Box
                  direction="row"
                  gap="16px"
                  margin={{ top: 'small' }}
                  round="8px"
                  border={{ side: 'all', color: 'light-1', size: '1px' }}
                  pad="16px"
                >
                  <CheckBox
                    checked={manager}
                    onChange={(event) => {
                      setManager(event.target.checked);
                      if (event.target.checked) {
                        setCoach(false);
                      }
                    }}
                    disabled={isOverManagerLimit() || isSelf || coach}
                  />
                  <Box>
                    <Text
                      weight={'bold'}
                      color={isOverManagerLimit() ? 'gray-2' : 'black'}
                    >
                      Manager
                    </Text>
                    <Text
                      size="small"
                      color={isOverManagerLimit() ? 'gray-2' : 'black'}
                    >
                      Team Managers (limit 3) have full access to edit, score,
                      and stream for your team
                    </Text>
                    {isOverManagerLimit() && (
                      <Text
                        margin={{ top: 'small' }}
                        size="small"
                        color="status-critical"
                      >
                        All Manager spots taken
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box
                  direction="row"
                  gap="16px"
                  margin={{ top: 'small' }}
                  round="8px"
                  border={{ side: 'all', color: 'light-1', size: '1px' }}
                  pad="16px"
                >
                  <CheckBox
                    checked={coach}
                    onChange={(event) => {
                      setCoach(event.target.checked);
                      if (event.target.checked) {
                        setManager(false);
                      }
                    }}
                    disabled={isOverCoachLimit() || manager}
                  />
                  <Box>
                    <Text
                      weight={'bold'}
                      color={isOverCoachLimit() ? 'gray-2' : 'black'}
                    >
                      Coach
                    </Text>
                    <Text
                      size="small"
                      color={isOverCoachLimit() ? 'gray-2' : 'black'}
                    >
                      Coaches (limit 2) can view games and reassign clips.
                    </Text>
                    {isOverCoachLimit() && (
                      <Text
                        margin={{ top: 'small' }}
                        size="small"
                        color="status-critical"
                      >
                        All Coach spots taken
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box direction="row" gap="medium" margin={{ top: 'medium' }} flex>
              <Box flex={{ grow: 1, shrink: 0 }}>
                <Button secondary label="Cancel" onClick={onClose} />
              </Box>
              {mode === StaffMode.ADD && (
                <Box flex={{ grow: 1, shrink: 0 }}>
                  <Button
                    type="submit"
                    primary
                    label={loading ? 'Sending...' : 'Send Invitation'}
                    disabled={(!coach && !manager) || loading}
                    style={{ whiteSpace: 'nowrap' }}
                  />
                </Box>
              )}
              {mode === StaffMode.EDIT && (
                <Box flex={{ grow: 1, shrink: 0 }}>
                  <Button
                    primary
                    label={loading ? 'Saving...' : 'Save'}
                    disabled={loading || noChanges}
                    onClick={handleUpdateStaff}
                  />
                </Box>
              )}
            </Box>
          </Form>
        </Box>
      </Box>
    </Layer>
  );
};

export default StaffModal;
