// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import './clipVideoPlayer.css';
import { Box, Text } from 'grommet';
import * as Sentry from '@sentry/browser';

import axios from './axios';
import { analytics } from '../services/analytics';

const HighlightVideoPlayer = ({
  videoSrc,
  id,
  category,
  hvid,
  autoplay,
  location,
  isHitMatch,
}) => {
  const playerRef = useRef();
  const [isReady, toggleIsReady] = useState(false);
  const [player, setPlayer] = useState();
  // const [hvid, setHvid] = useState(props.hvid);
  let viewed = false;

  const videoJsOptions = {
    autoplay,
    muted: autoplay,
    controls: true,
    responsive: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
    preload: 'metadata',
  };

  useEffect(() => {
    if (playerRef.current) {
      const initPlayer = videojs(playerRef.current, videoJsOptions, () => {
        toggleIsReady(true);
      });

      playerRef.current.onplay = () => {
        analytics.track('Highlight Video Play', {
          hvidId: id,
          category,
          location,
          sport: 'bbsb',
        });
      };

      playerRef.current.onpause = () => {
        const currentTime = initPlayer.currentTime();
        if (currentTime > 2) {
          // watching more than 2 seconds counts as a view
          if (!viewed) {
            axios.post(`/api/hvid_view/${id}`).then(() => {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              viewed = true;
            });
          }
        }
      };

      setPlayer(initPlayer);

      return () => {
        playerRef.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (isReady) {
      const thumbnailTs = 0.01;
      player.src({
        src: `${videoSrc}#t=${thumbnailTs}`,
        type: 'video/mp4',
      });

      const clipElement = document.getElementById(id);
      if (clipElement) {
        const playButtonArr = document
          .getElementById(id)
          .getElementsByClassName('vjs-big-play-button');
        if (playButtonArr.length) {
          const playButton = playButtonArr[0];
          playButton.addEventListener(
            'touchend',
            () => {
              if (
                !document.fullscreenElement &&
                player &&
                document.fullscreenEnabled
              ) {
                const fs = player.requestFullscreen();
                if (fs) {
                  fs.catch((respError) => {
                    Sentry.captureException(respError);
                  });
                }
              }
              player.play().catch((respError) => {
                Sentry.captureException(respError);
              });
            },
            false
          );
        }
      }
    }

    return () => {
      playerRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, videoSrc]);

  return (
    <Box id={id} height={{ min: isHitMatch ? '200px' : '280px' }} fill>
      <Box data-vjs-player>
        <video
          ref={playerRef}
          className="video-js vjs-theme-fantasy vjs-16-9"
          style={{ width: '100%' }}
          playsInline
        />
        {/* {(isEnd) ? (
          <>
            <Box style={{
              position: 'absolute',
              width: '100%',
              height: '50%',
              backgroundColor: 'black',
              opacity: '0.4',
            }}
            >
              <Button
                margin="auto"
                alignSelf="center"
                plain
                color="primary-1"
                label="Share!"
                onClick={() => { handleShare(); }}
              />
            </Box>
          </>
        ) : null} */}
      </Box>
      <Box pad="xsmall">
        {hvid && (
          <Text size="xsmall">{`${hvid.views ? hvid.views : 0} views`}</Text>
        )}
      </Box>
    </Box>
  );
};

export default HighlightVideoPlayer;
