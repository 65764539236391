// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';
import { FormDown, FormUp, Hide, User, View } from 'grommet-icons';
import {
  Accordion,
  AccordionPanel,
  Anchor,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormField,
  Heading,
  ResponsiveContext,
  Text,
  TextInput,
} from 'grommet';

import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import withLayout from '../../helpers/withLayout';
import axios from '../../helpers/axios';
import SignInMethod from '../SignIn/SignInMethod';
import { BasicLoader } from '../../helpers/Loaders';
import { UserContext } from '../../services/Session';
import { withFirebase } from '../../services/Firebase';
import DynamicBanner from '../../helpers/DynamicBanner';
import { theFirebase } from '../../services/Firebase/firebase';
import { useQuery } from '../../helpers/utils';
import { isMobile, isiOS } from '../../helpers/browserDetect';
import { analytics } from '../../services/analytics';

const GameChanger = ({ history, ...props }) => {
  const query = useQuery();
  const fromExisting = !!query.get('from_existing');
  const userStore = useContext(UserContext);
  const size = useContext(ResponsiveContext);

  const startStep = 0;
  const [step, setStep] = useState(startStep);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [revealPassword, setRevealPassword] = useState(false);
  const [userCreds, setUserCreds] = useState({});
  const [user, setUser] = useState(null);
  const [teams, setTeams] = useState([]);
  const [activeTeamIndex, setActiveTeamIndex] = useState(null);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [selectedPlayerIndices, setSelectedPlayerIndices] = useState([]);

  const nextStep = () => {
    setError('');
    if (step === 3 && !loading) {
      setLoading(true);
      const team = teams[selectedTeamIndex];
      team.players = team.players.filter((_, i) => selectedPlayerIndices[i]);

      axios
        .post('/api/gc/shd_account', { user, team })
        .then(({ data }) => {
          setLoading(false);
          const { team: shdTeam } = data;
          userStore.setUserTeams([shdTeam, ...userStore.userTeams]);
          userStore.setSelectedTeam(shdTeam);
          userStore.setActiveOnboardingTeam(null);

          props.firebase.logAnalyticsEvent('team_import_complete', {
            method: 'gamechanger',
            team_id: shdTeam._id,
            user_id: user.userId,
          });

          history.push(`/${shdTeam._id}`);
        })
        .catch((respError) => {
          setError('Something went wrong. We are working to fix this.');
          setLoading(false);
          Sentry.captureException(respError);
        });
    } else if (fromExisting && step === 1) {
      setStep(3);

      // TODO: fix hack
      setUser({
        userId: userStore.authUser.claims.shd_user_id,
        user: {
          nameFirst: userStore.authUser.displayName,
          nameLast: '',
        },
      });
    } else {
      setStep(step + 1);
    }
  };
  const enableAppleSignIn = !isMobile.any() || isiOS();
  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();

  const loginToTeamManager = () => {
    setLoading(true);
    axios
      .post('/api/gc/login', userCreds)
      .then((response) => {
        setStep(step + 1);
        const sortedTeams = response.data.teams
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .sort((a, b) => (a.season_year < b.season_year ? 1 : -1));
        setTeams(sortedTeams);
        setLoading(false);

        props.firebase.logAnalyticsEvent('login_to_gamechanger');
      })
      .catch((respError) => {
        if (respError && respError.response && respError.response.data) {
          let { message } = respError.response.data;
          if (respError.response.data.gcClassic) {
            message +=
              '\nUse your credentials for the new GameChanger app, not GameChanger Classic';
          }
          setError(message);
        } else if (respError && respError.response) {
          setError(JSON.stringify(respError.response));
        } else {
          setError(
            'We had trouble logging you in as we are having server issues. Please go back and try importing from USSSA or inputting your team info manually. Sorry for the inconvenience.'
          );
        }

        setLoading(false);
      });
  };

  const teamsPanels = teams.map((team, i) => {
    const panelHeader = (
      <Box
        background={i === selectedTeamIndex ? 'secondary-5' : 'secondary-7'}
        direction="row"
        align="center"
        pad="medium"
        gap="small"
        justify="between"
      >
        <Box>
          <strong>
            <Text>{team.name}</Text>
          </strong>

          <Box
            direction={size === 'small' ? 'column' : 'row'}
            gap="small"
            align={size === 'small' ? 'start' : 'center'}
          >
            <Text color="dark-3">
              {team.sport.charAt(0).toUpperCase() + team.sport.slice(1)}
            </Text>
            <Text color="dark-3">
              {`${size === 'small' ? '' : '-'} ${
                team.season_name.charAt(0).toUpperCase() +
                team.season_name.slice(1)
              } ${team.season_year}`}
            </Text>
          </Box>
        </Box>
        <Box direction="row" gap="medium">
          <Button
            primary
            label="Select Team"
            onClick={(e) => {
              e.stopPropagation();

              setSelectedTeamIndex(i);
            }}
          />
          <Text color="brand">
            {activeTeamIndex === i ? <FormUp /> : <FormDown />}
          </Text>
        </Box>
      </Box>
    );

    const playersDivs = team.players.map((player) => (
      // eslint-disable-next-line react/jsx-key
      <Text>
        <b>{`${player.first_name} ${player.last_name}`}</b>{' '}
        {`${player.number && '#'}${player.number}`}
      </Text>
    ));

    return (
      // eslint-disable-next-line react/jsx-key
      <AccordionPanel header={panelHeader}>
        <Box gap="small" pad="small">
          {playersDivs.length ? playersDivs : null}
        </Box>
      </AccordionPanel>
    );
  });

  const gcLogin = (
    <Box gap="small">
      <DynamicBanner name="gc_status" />
      <Heading>Import your team from GameChanger</Heading>
      <Text>One-click setup! No need to type player names or team info.</Text>
      <Text>
        Your credentials are for your GameChanger account, not GameChanger
        Classic account.
      </Text>
      <Form
        value={userCreds}
        onChange={(nextUserCreds) => {
          setUserCreds(nextUserCreds);
        }}
        onSubmit={loginToTeamManager}
      >
        <FormField name="username" htmlfor="username-field">
          <TextInput
            plain
            type="email"
            autoComplete="nope"
            id="username-field"
            name="username"
            placeholder="GC Team Manager email..."
          />
        </FormField>
        <FormField
          name="password"
          htmlfor="password-field"
          validate={(field) => {
            if (!field || !field.length) {
              return { message: 'Please enter a password.', status: 'error' };
            }
            return { message: null, status: 'info' };
          }}
        >
          <Box direction="row">
            <TextInput
              plain
              type={revealPassword ? 'text' : 'password'}
              id="password-field"
              name="password"
              placeholder="GC Team Manager password..."
            />
            <Button
              icon={
                revealPassword ? <View size="medium" /> : <Hide size="medium" />
              }
              onClick={() => setRevealPassword(!revealPassword)}
            />
          </Box>
        </FormField>
        <Button
          primary
          color="primary-1"
          type="submit"
          label="Login with GameChanger"
        />
      </Form>
      <Text size="small">
        We do not store your Team Manager password. By logging in, you will be
        retrieving your data from a third-party site. You will have an
        opportunity to review and confirm your data before transferring it to
        your account on sidelineHD. GAMECHANGER is a trademark of GAMECHANGER
        MEDIA, INC. SidelineHD is not affiliated with and operates independently
        from GameChanger.
      </Text>
      {loading && (
        <>
          <BasicLoader />
          <Text alignSelf="center">This usually takes about 15 seconds</Text>
        </>
      )}
    </Box>
  );

  const renderGCSelectTeam = () => {
    let selectedTeamText;
    if (selectedTeamIndex === null) {
      selectedTeamText = 'No team selected.';
    } else {
      selectedTeamText = `You have selected: ${teams[selectedTeamIndex].name}`;
    }

    return (
      <Box pad="small">
        <Heading level="3">Select your Team Manager team</Heading>
        <Text>Select a team to import into sidelineHD</Text>
        <Box
          margin={{ top: 'small', bottom: 'large' }}
          background="dark-5"
          height="2px"
          width="100%"
        />
        <Box alignSelf="center">
          <Text weight="bold">{selectedTeamText}</Text>
        </Box>
        <Box margin={{ top: 'large', bottom: 'large' }} gap="small">
          {teams.length > 0 && (
            <Accordion
              activeIndex={activeTeamIndex}
              onActive={(newActiveTeamIndex) => {
                setActiveTeamIndex(newActiveTeamIndex[0]);
              }}
            >
              {teamsPanels}
            </Accordion>
          )}
        </Box>
        <Box direction="row" justify="between">
          <Button
            plain
            label={<Text color="tertiary-1">Back</Text>}
            onClick={() => {
              setStep(step - 1);
            }}
          />
          <Button
            disabled={selectedTeamIndex === null}
            primary
            label="Next"
            onClick={() => {
              props.firebase.logAnalyticsEvent('gamechanger_selected_team');
              setSelectedPlayerIndices(
                teams[selectedTeamIndex].players.map(() => true)
              );
              nextStep();
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderGCConfirmImport = () => {
    const team = teams[selectedTeamIndex];
    const { players: gcPlayers } = team;

    return (
      <Box pad="small">
        <Box>
          <Heading>Confirm team import</Heading>
        </Box>
        <Box margin={{ bottom: 'large' }}>
          <Text>
            <b>Name:</b>
            {` ${team.name}`}
          </Text>
          <Text>
            <b>Sport:</b>
            {` ${team.sport}`}
          </Text>
          <Text>
            <b>Location:</b>
            {` ${team.city}, ${team.state}`}
          </Text>
          <Text>
            <b>Season:</b>
            {` ${team.season_name} ${team.season_year}`}
          </Text>
        </Box>
        <Box direction="column" gap="small">
          <Text weight="bold">Roster</Text>
          {gcPlayers.map((player, i) => (
            <Card
              key={`active-player-${i + 1}`}
              background="light-1"
              direction="row"
            >
              {/* <CardBody pad="small">
                <Grommet theme={{
                  checkBox: {
                    color: '#066d99',
                  },
                }}
                >
                  <CheckBox
                    checked={selectedPlayerIndices[i]}
                    onChange={(event) => {
                      setSelectedPlayerIndices(selectedPlayerIndices.map((el, j) => {
                        if (i === j) {
                          return event.target.checked;
                        }
                        return el;
                      }));
                    }}
                    label={(
                      content
                  )}
                  />

                </Grommet>

              </CardBody> */}
              <Box direction="row" justify="start" align="center">
                <Box
                  direction="column"
                  pad="medium"
                  gap="small"
                  fill="horizontal"
                >
                  <Box direction="row" align="center" gap="medium">
                    <Box justify="end">
                      {player.profile_photo_url ? (
                        <Avatar src={player.profile_photo_url} size="medium" />
                      ) : (
                        <Avatar background="dark-1" size="medium">
                          <User color="white" size="medium" />
                        </Avatar>
                      )}
                    </Box>
                    {player.first_name || player.last_name ? (
                      <Text weight="bold">
                        {`${player.first_name} ${player.last_name} ${
                          player.number && '#'
                        }${player.number}`}
                      </Text>
                    ) : (
                      <Text weight="bold">{`#${player.number}`}</Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Card>
          ))}

          <Box margin={{ top: 'large' }} gap="medium">
            <Text>
              I have reviewed this information and confirm that it is mine. I
              agree to transfer it to my account on sidelineHD.
            </Text>
            <Box direction="row" justify="between">
              <Button
                plain
                label={<Text color="tertiary-1">Back</Text>}
                onClick={() => {
                  if (fromExisting) {
                    setStep(step - 2);
                  } else {
                    setStep(step - 1);
                  }
                }}
              />
              <Button
                alignSelf="end"
                primary
                label="Confirm"
                onClick={() => {
                  nextStep();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const shdSignUp = (
    <>
      <Card
        align="center"
        pad="large"
        gap="small"
        // margin="0 auto 0 auto"
        style={{ maxWidth: '600px' }}
      >
        <CardHeader direction="column">
          <Heading textAlign="center" level={3}>
            Welcome to sidelineHD
          </Heading>
          <Text textAlign="center" size="large">
            Sign up for a sidelineHD account to finish importing your team.
          </Text>
        </CardHeader>
        <CardBody pad="small" gap="medium">
          <SignInMethod
            label="Sign up with Google"
            provider={googleProvider}
            toggleLoading={setLoading}
            errorHandler={setError}
            onSuccess={(userData) => {
              window.sendinblue.identify(userData.user.email);
              window.sendinblue.track('livestreamer_signup', {
                socialSource: 'Google',
                importSource: 'GC',
              });

              analytics.identify(userData.userId, {
                socialSource: 'Google',
              });
              analytics.track('Livestreamer Sign Up', {
                socialSource: 'Google',
                importSource: 'GC',
                sport: 'bbsb',
              });

              props.firebase.logAnalyticsEvent('sign_up_success', {
                method: 'gamechanger',
                provider: 'google',
                user_id: userData.userId,
              });
              setUser(userData);
              setLoading(false);
              nextStep();
            }}
            postParams={{
              import_source: 'gamechanger',
            }}
          />
          <SignInMethod
            label="Sign up with Facebook"
            provider={facebookProvider}
            toggleLoading={setLoading}
            errorHandler={setError}
            onSuccess={(userData) => {
              window.sendinblue.identify(userData.user.email);
              window.sendinblue.track('livestreamer_signup', {
                socialSource: 'Facebook',
                importSource: 'GC',
              });

              analytics.identify(userData.userId, {
                socialSource: 'Facebook',
              });
              analytics.track('Livestreamer Sign Up', {
                socialSource: 'Facebook',
                importSource: 'GC',
                sport: 'bbsb',
              });

              props.firebase.logAnalyticsEvent('sign_up_success', {
                method: 'gamechanger',
                provider: 'facebook',
                user_id: userData.userId,
              });
              setUser(userData);
              setLoading(false);
              nextStep();
            }}
            postParams={{
              import_source: 'gamechanger',
            }}
          />
          {enableAppleSignIn && (
            <SignInMethod
              label="Sign up with Apple"
              provider={appleProvider}
              toggleLoading={setLoading}
              errorHandler={setError}
              onSuccess={(userData) => {
                window.sendinblue.identify(userData.user.email);
                window.sendinblue.track('livestreamer_signup', {
                  socialSource: 'Apple',
                  importSource: 'GC',
                });

                analytics.identify(userData.userId, {
                  socialSource: 'Apple',
                });
                analytics.track('Livestreamer Sign Up', {
                  socialSource: 'Apple',
                  importSource: 'GC',
                  sport: 'bbsb',
                });

                props.firebase.logAnalyticsEvent('sign_up_success', {
                  method: 'gamechanger',
                  provider: 'apple',
                  user_id: userData.userId,
                });
                setUser(userData);
                setLoading(false);
                nextStep();
              }}
              postParams={{
                import_source: 'gamechanger',
              }}
            />
          )}
        </CardBody>
      </Card>
      <Box margin={{ top: 'large' }} direction="row">
        <Text size="small">
          If you already have a sidelineHD account, please sign in at{' '}
          <Anchor
            size="small"
            href="https://sidelinehd.com/signin"
            label="https://sidelinehd.com/signin"
          />
        </Text>
      </Box>
      <Box margin={{ top: 'large' }}>
        <Button
          plain
          label={<Text color="tertiary-1">Back</Text>}
          onClick={() => {
            setStep(step - 1);
          }}
        />
      </Box>
      {loading && <BasicLoader />}
    </>
  );

  return (
    <Box margin="auto" width="large" pad="medium">
      {error && <Text color="status-critical">{error}</Text>}
      {step === 0 && gcLogin}
      {step === 1 && renderGCSelectTeam()}
      {step === 2 && shdSignUp}
      {step === 3 && renderGCConfirmImport()}
    </Box>
  );
};

export default withRouter(withLayout(withFirebase(GameChanger)));
