import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useParams } from 'react-router-dom';
import { useTeam } from '../TeamPage/teamHooks';
import { withAuthorization } from '../../services/Session';
import { AugmentedUser } from '../../services/Session/store';

const AdminTeamPage: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const { team, error, loading } = useTeam(teamId);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error loading team</div>;
  }

  return (
    <Box pad="medium">
      <Box>
        <Heading level="3">{team?.nameMed}</Heading>
      </Box>
      <Box gap="small">
        <Text weight={'bold'}>data</Text>
        <Box background={'light-2'}>
          <Text size="8px">{JSON.stringify(team)}</Text>
        </Box>
        <Text>
          Paste data into{' '}
          <a
            href="https://jsonviewer.stack.hu/"
            target="_blank"
            rel="noreferrer"
          >
            https://jsonviewer.stack.hu/
          </a>{' '}
          to view
        </Text>
      </Box>

      {/* {Object.entries(team || {}).map(([key, value]) => (
        <Box key={key}>
          <Heading level="4">{key}</Heading>
          <Box>{JSON.stringify(value)}</Box>
        </Box>
      ))} */}
    </Box>
  );
};

const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

export default withAuthorization(condition)(AdminTeamPage);
