// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Button, Heading, Tab, Tabs, Text } from 'grommet';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  useQueryParams,
  ArrayParam,
  withDefault,
  StringParam,
} from 'use-query-params';
import InfiniteScroll from 'react-infinite-scroll-component';
import { New } from 'grommet-icons';
import axios from '../../helpers/axios';
import GenericPlayerClip from './GenericPlayerClip';
import { BasicLoader } from '../../helpers/Loaders';
import ReelsList from './ReelsList';

const MyFiltersParam = withDefault(ArrayParam, []);

// eslint-disable-next-line no-unused-vars
const GenericPlayerClipsSection = ({
  playerId,
  teamGames,
  sport,
  pinClip,
  unpinClip,
  pinnedClips = [],
  isAdmin,
  showReelsTab,
  claimed,
  ...props
}) => {
  const genericPinnedClips = pinnedClips.filter((clip) => {
    const visible = !clip.O_hideFromClaimedPlayerPage.includes(playerId);
    const isGeneric =
      clip.game.sportType && !['vb', 'bbsb'].includes(clip.game.sportType);

    return visible && isGeneric;
  });

  const teamGamesDict = Object.assign(
    {},
    ...teamGames
      .filter((game) => game.sportType && game.sportType === sport)
      .map((game) => ({ [game._id]: game }))
  );

  const getGameOptions = () =>
    teamGames
      .filter((game) => game.sportType && game.sportType === sport)
      .sort(({ J_tsStart: a }, { J_tsStart: b }) => b - a)
      .map((game) => {
        const date = DateTime.fromMillis(game.J_tsStart * 1000).toLocal();
        return {
          label: `${game.scoreOurName} ${game.scoreWeAreHome ? 'vs' : '@'} ${
            game.J_opponentName
          } - ${date.month}/${date.day}/${date.year}`, // include date maybe?
          value: game._id,
        };
      });

  const [gameOptions, setGameOptions] = useState(getGameOptions());
  const [playTypeOptions, setPlayTypeOptions] = useState([]);

  const [clips, setClips] = useState([]);
  const [tabsIndex, setTabsIndex] = useState(genericPinnedClips.length ? 1 : 2);

  const [startAfter, setStartAfter] = useState();
  const [loading, setLoading] = useState(false);
  const [, setLoadingMore] = useState(false);

  // hacky
  const [hydratedPinnedClips, setHydratedPinnedClips] =
    useState(genericPinnedClips);

  const [pendingFilters, setPendingFilters] = useState({
    sport,
    gameIds: [],
    playTypes: [],
  });

  const [filters, setFilters] = useQueryParams({
    sport: withDefault(StringParam, sport),
    gameIds: MyFiltersParam,
    playTypes: MyFiltersParam,
  });

  const applyFilters = () => {
    setFilters(pendingFilters);
  };

  const getNextPage = () => {
    setLoadingMore(true);
    const params = {
      ...filters,
      startAfter,
    };
    axios.get(`/api/player/${playerId}/clips`, { params }).then((res) => {
      setClips([...clips, ...res.data.clips]);
      setStartAfter(res.data.next);
      setLoadingMore(false);
    });
  };

  useEffect(() => {
    axios.get(`/api/scoring/game_event_types/${sport}`).then((response) => {
      const gameEventTypes = response.data;

      const descriptions = [];
      Object.entries(gameEventTypes).forEach((entry) => {
        const [, value] = entry;
        value.attribution.forEach((a) => {
          descriptions.push(a.descriptionTxt);
        });
      });
      setPlayTypeOptions(
        [...new Set(descriptions)].map((description) => ({
          value: description,
          label: description.charAt(0).toUpperCase() + description.substring(1),
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPendingFilters(filters);
    setLoading(true);
    const params = {
      ...filters,
    };
    axios.get(`/api/player/${playerId}/clips`, { params }).then((res) => {
      setClips(res.data.clips);
      setStartAfter(res.data.next);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setGameOptions(getGameOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamGames]);

  useEffect(() => {
    // "hyrdate" generic pinned clips by fetching from api/clips/:id
    const fetchPinnedClips = async () => {
      const hydratedClips = await Promise.all(
        genericPinnedClips.map((clip) => axios.get(`/api/clip/${clip.id}`))
      );
      setHydratedPinnedClips(hydratedClips.map((clip) => clip.data));
    };
    fetchPinnedClips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedClips]);

  const renderClips = () => {
    const filteredClips = clips.filter((clip) => {
      const inGames =
        filters.gameIds.includes(clip.gameId) || filters.gameIds.length === 0;
      const inPlayTypes =
        filters.playTypes.includes(clip.playType) ||
        filters.playTypes.length === 0;

      return inGames && inPlayTypes;
    });

    return (
      <Box gap="small">
        <Heading level="4" margin={{ bottom: 'none' }}>
          Video clips
        </Heading>
        <Box gap="small">
          <InfiniteScroll
            dataLength={filteredClips.length}
            next={getNextPage}
            scrollThreshold={0.5}
            loader={
              <Box
                direction="row"
                margin={{ vertical: 'medium' }}
                align="center"
                gap="small"
              >
                <BasicLoader size={20} />
                <Text>Loading...</Text>
              </Box>
            }
            endMessage={
              <Box direction="row" margin={{ vertical: 'medium' }}>
                <Text>That is all!</Text>
              </Box>
            }
            hasMore={startAfter}
          >
            {filteredClips.map((clip) => {
              const game = teamGamesDict[clip.gameId];
              if (game) {
                game.scoreOurName = ''; // TODO: populate this
                game.scoreOpponentName = game.J_opponentName;
                // eslint-disable-next-line prefer-destructuring
                game.DNOR_teamId = clip.scoreTeamIdList[0];
                // eslint-disable-next-line no-param-reassign
                clip.game = game;

                return (
                  <GenericPlayerClip
                    sport={sport}
                    key={clip.id}
                    clip={clip}
                    game={game}
                    playerId={playerId}
                    teamPlayerName="teamPlayerName"
                    publicPublishLevel={10}
                    onChange={(newClip) => {
                      setClips(
                        clips.map((c) => {
                          if (c.id === newClip.id) {
                            return newClip;
                          }
                          return c;
                        })
                      );
                    }}
                    pinClip={isAdmin ? pinClip : null}
                    unpinClip={isAdmin ? unpinClip : null}
                    isPinned={!!pinnedClips.map((c) => c.id).includes(clip.id)}
                    setToast={props.setToast}
                    // hideBottomBar={!hasAccess()}
                  />
                );
              }
              return null;
            })}
          </InfiniteScroll>
          {filteredClips.length === 0 && (
            <Box>
              <Text>No clips match these filters.</Text>
              <Text>Adjust the filters above and press Apply</Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const getGameValue = () =>
    gameOptions.filter((option) =>
      pendingFilters.gameIds.includes(option.value)
    );

  const getPlayTypeValue = () =>
    playTypeOptions.filter((option) =>
      pendingFilters.playTypes.includes(option.value)
    );

  if (playTypeOptions.length === 0) {
    return <BasicLoader />;
  }

  const renderPinnedClips = () => {
    if (hydratedPinnedClips.length === 0) {
      return (
        <Heading textAlign="center" color="text-xweak" level={4}>
          {isAdmin
            ? 'Add highlights from your clips'
            : "This player hasn't added a highlight yet"}
        </Heading>
      );
    }

    return (
      <Box>
        {hydratedPinnedClips.map((clip) => {
          const game = teamGamesDict[clip.game._id];
          if (game) {
            game.scoreOurName = ''; // TODO: populate this
            game.scoreOpponentName = game.J_opponentName;
            // eslint-disable-next-line prefer-destructuring
            game.DNOR_teamId = ''; // clip.scoreTeamIdList[0];
            // eslint-disable-next-line no-param-reassign
            clip.game = game;

            return (
              <GenericPlayerClip
                sport={sport}
                key={clip.id}
                clip={clip}
                game={game}
                playerId={playerId}
                teamPlayerName="teamPlayerName"
                publicPublishLevel={10}
                onChange={(newClip) => {
                  setClips(
                    clips.map((c) => {
                      if (c.id === newClip.id) {
                        return newClip;
                      }
                      return c;
                    })
                  );
                }}
                pinClip={isAdmin ? pinClip : null}
                unpinClip={isAdmin ? unpinClip : null}
                isPinned={!!pinnedClips.map((c) => c.id).includes(clip.id)}
                setToast={props.setToast}
              />
            );
          }
          return null;
        })}
      </Box>
    );
  };

  const renderClipsFeed = () => (
    <Box>
      <Box id="filters-container" gap="small">
        <Heading level="4" margin={{ vertical: 'none' }}>
          Filters
        </Heading>
        <Select
          value={getGameValue()}
          placeholder="All games..."
          options={gameOptions}
          onChange={(option) => {
            // setFilters(
            //   { ...filters, gameIds: option.map((game) => game.value) },
            //   'push',
            // );
            setPendingFilters(
              { ...filters, gameIds: option.map((game) => game.value) },
              'push'
            );
          }}
          isMulti
        />
        <Select
          value={getPlayTypeValue()}
          placeholder="All play types..."
          options={playTypeOptions}
          onChange={(option) => {
            // setFilters({ ...filters, playTypes: option.map((game) => game.value) }, 'push');
            setPendingFilters(
              { ...filters, playTypes: option.map((game) => game.value) },
              'push'
            );
          }}
          isMulti
        />
        <Button
          primary
          color="tertiary-1"
          label="Apply"
          onClick={applyFilters}
        />
      </Box>
      {teamGames.length && !loading && renderClips()}
      {loading && <BasicLoader />}
    </Box>
  );

  return (
    <Box pad="medium" gap="small">
      <Tabs
        activeIndex={tabsIndex}
        onActive={(activeTabIndex) => {
          setTabsIndex(activeTabIndex);
        }}
      >
        {claimed && showReelsTab && (
          <Tab title="Reels" icon={<New size="small" />}>
            <ReelsList playerId={playerId} />
          </Tab>
        )}
        <Tab title="Pinned">{renderPinnedClips()}</Tab>
        <Tab title="Clips">{renderClipsFeed()}</Tab>
      </Tabs>
    </Box>
  );
};

export default withRouter(GenericPlayerClipsSection);
