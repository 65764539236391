// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import { TextInput, FormField, Box, RadioButtonGroup, Text } from 'grommet';

export const InputStyled = styled(TextInput)`
  background-color: ${({ theme }) => theme.global.colors['light-3']};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.global.colors['secondary-1']};
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  outline: none;
`;

export const FieldStyled = styled(FormField)`
  margin-bottom: 36px;
  & > div {
    border-bottom: 0;
  }

  & span {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FieldWrapper = styled(Box)`
  margin-bottom: 16px;
`;

export const RadioGroupStyled = styled(RadioButtonGroup)`
  & label {
    height: 48px;
    font-size: 16px;
    font-weight: 400;
    & > div {
      margin-right: 15px;
      & > div {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const FieldUniqueWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FieldUniqueLabel = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  margin-right: 8px;
`;
