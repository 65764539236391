import { RecommendWeekly } from '@shd/jslib/models/generated/docStorage/model/recommendWeekly';

import { RecommendedActivity } from '@shd/jslib/models/generated/docStorage/model/recommendedActivity';

export interface Weekly extends RecommendWeekly {
  ctaText: string;
  sportType?: string;
  yearGraduation?: number;
  nameFirst: string;
  errorList: string[];
  isShowCta: boolean;
}

export interface LessonCardListProps {
  category: string | undefined;
  recommendations: RecommendedActivity[] | undefined;
  sport: string | undefined;
  gradYear?: number | 0;
  fetchActivityLink: (activityUuId: string) => string | undefined;
}

export interface LessonCardProps {
  recommendation: RecommendedActivity;
  category: string | undefined;
  sport: string | undefined;
  gradYear?: number | 0;
  link?: string;
}

export interface CoachInfo {
  name: string;
  displayName: string;
  handle?: string;
  link?: string;
  sport?: string;
}

export interface GetDKCardProps {
  appStoreLink?: string | undefined;
  sport?: string | undefined;
  gradYear?: number | 0;
}

export const coaches: CoachInfo[] = [
  {
    name: 'rac',
    displayName: 'Coach RAC',
    handle: 'coach.rac',
    link: 'https://www.instagram.com/coach.rac/',
    sport: 'Baseball',
  },
  {
    name: 'ian',
    displayName: 'Coach Ian Jenkins',
    handle: 'ianjenkins_hitting',
    link: 'https://www.instagram.com/ianjenkins_hitting/',
    sport: 'Baseball',
  },
  {
    name: 'cam',
    displayName: 'Coach Cam',
    handle: '_thecoachcam_',
    link: 'https://www.instagram.com/_thecoachcam_/',
    sport: 'Baseball',
  },
  {
    name: 'jp',
    displayName: 'Coach JP',
    handle: 'heyitscoachjp',
    link: 'https://www.instagram.com/heyitscoachjp/',
    sport: 'Softball',
  },
  {
    name: 'alliance',
    displayName: 'Alliance Fastpitch',
    sport: 'Softball',
  },
];
