// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useState } from 'react';
import { Box, Text, Card, Button, DropButton } from 'grommet';
import * as Sentry from '@sentry/browser';
import { CaretDownFill, CaretUpFill, More } from 'grommet-icons';
import { withRouter } from 'react-router-dom';

import axios from '../../helpers/axios';
import EditGame from './EditGame';
import Toast from '../../components/Toast';
import DeleteGameLayer from '../TeamPage/DeleteGameLayer';
import {
  BBSB_SPORTS,
  LEGACY_SPORTS,
  VOLLEYBALL,
} from '../../constants/strings';
import { analytics } from '../../services/analytics';

const GameCard = withRouter(
  ({ goSetLineups, scoreGame, onGameDeleted, sportType, ...props }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [game, setGame] = useState(props.game);
    const [showDeleteGame, setShowDeleteGame] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    let gameResult;
    let gameColor = 'text';
    if (game.scoreGameState > 20) {
      const [awayScore, homeScore] = game.scoresAwayHome || [-1, -1];
      if (
        (homeScore > awayScore && !!game.scoreWeAreHome) ||
        (awayScore > homeScore && !game.scoreWeAreHome)
      ) {
        gameResult = 'W';
        gameColor = 'status-ok';
      } else if (
        (homeScore > awayScore && !game.scoreWeAreHome) ||
        (awayScore > homeScore && !!game.scoreWeAreHome)
      ) {
        gameResult = 'L';
        gameColor = 'status-critical';
      } else {
        gameResult = 'T';
      }
    }

    const quickStartGame = () => {
      const body = {
        teamId: game.DNOR_teamId,
        teamGameId: game._id,
        teamGameUpdate: {
          scoreOurLineup: [],
          scoreGameState: 20,
          scheduleState: 20,
          isQuickStartMode: true,
        },
      };

      axios
        .put('/api/team_game', body)
        .then(() => {
          analytics.track('Start sScore Game', {
            gameId: `GmSh01-${game.scoreGameId}`,
            teamId: game.DNOR_teamId,
            sport: sportType.toLowerCase().replace(' ', '_'),
          });
          scoreGame(game.scoreGameId);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    };

    const onDelete = (teamGameId) => {
      onGameDeleted(teamGameId);
      setShowDeleteSuccess(true);
    };

    const renderScheduledState = () => (
      <Box fill="horizontal" direction="row" gap="xsmall">
        {LEGACY_SPORTS.includes(sportType) && (
          <Button
            style={{ width: '40%' }}
            primary
            color="secondary-2"
            label={
              <Text weight="bold" color="white">
                Set Lineup
              </Text>
            }
            onClick={() => goSetLineups(game._id)}
            disabled={
              BBSB_SPORTS.includes(sportType) && game.scoreOurLineup.length > 0
            }
          />
        )}
        <Button
          style={{ width: '40%' }}
          color="secondary-2"
          label={
            <Text weight="bold" color="dark-1">
              Start Game
            </Text>
          }
          onClick={quickStartGame}
        />
      </Box>
    );

    const renderScoringState = () => (
      <Box direction="row" gap="xsmall" fill="horizontal">
        {LEGACY_SPORTS.includes(sportType) && (
          <Button
            style={{ width: '40%' }}
            primary
            color="secondary-2"
            label={
              <Text weight="bold" color="white">
                Set Lineup
              </Text>
            }
            onClick={() => goSetLineups(game._id)}
            disabled={
              BBSB_SPORTS.includes(sportType) && game.scoreOurLineup.length > 0
            }
          />
        )}
        <Button
          style={{ width: '40%' }}
          color="secondary-2"
          label={
            <Text weight="bold" color="dark-2">
              Start Game
            </Text>
          }
          disabled={
            game.scoreOurLineup.length === 0 &&
            (BBSB_SPORTS.includes(sportType) ||
              sportType === VOLLEYBALL.display)
          }
          onClick={() => {
            analytics.track('Start sScore Game', {
              gameId: `GmSh01-${game.scoreGameId}`,
              teamId: game.DNOR_teamId,
              sport: sportType.toLowerCase().replace(' ', '_'),
            });
            scoreGame(game.scoreGameId);
          }}
        />
      </Box>
    );

    const renderStartedState = () => (
      <Box direction="column" gap="small" fill="horizontal">
        <Button
          primary
          color="secondary-2"
          label={
            <Text weight="bold" color="white">
              Continue Scoring
            </Text>
          }
          onClick={() => scoreGame(game.scoreGameId)}
        />
      </Box>
    );

    return (
      <Box margin={{ top: 'medium', bottom: 'medium' }}>
        <Card
          gap="xsmall"
          background={game.scoreGameState === 50 ? 'light-5' : 'secondary-7'}
          round="small"
        >
          <Box direction="row" justify="between" pad="medium" gap="medium">
            <Box gap="xsmall" fill="horizontal">
              <Box align="center" direction="row" justify="between">
                <Text weight="bold">
                  {game.scoreWeAreHome ? 'vs. ' : '@ '}
                  {game.scoreOpponentName}
                </Text>
                <DropButton
                  icon={<More size="small" />}
                  dropContent={
                    <Box width="small" pad="small" gap="small">
                      {game.scoreGameState === 50 ? (
                        <Button
                          primary
                          color="secondary-7"
                          label={<Text weight="bold">Reopen</Text>}
                          onClick={() => scoreGame(game.scoreGameId)}
                        />
                      ) : (
                        <Button
                          primary
                          color="secondary-7"
                          label={<Text weight="bold">Edit</Text>}
                          onClick={() => setShowEdit(true)}
                        />
                      )}

                      <Button
                        label={<Text weight="bold">Delete</Text>}
                        color="secondary-7"
                        onClick={() => setShowDeleteGame(true)}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box direction="row" justify="between">
                <Text>
                  {new Date(game.scheduleTsStart * 1000)
                    .toLocaleDateString('en-US')
                    .split('/')
                    .slice(0, 2)
                    .join('/')}{' '}
                  {new Date(game.scheduleTsStart * 1000).toLocaleTimeString(
                    'en-US'
                  )}
                </Text>
                {game.scoreInningNum > 0 && (
                  <Box direction="row" justify="around" gap="xsmall">
                    <Box direction="row">
                      {!game.scoreInningNum.toString().includes('.') ? (
                        <CaretUpFill />
                      ) : (
                        <CaretDownFill />
                      )}
                      <Text>{game.scoreInningNum}</Text>
                    </Box>
                    <Text>|</Text>
                    {game.scoreGameState === 50 ? (
                      <Text color={gameColor}>
                        {`${game.scoresAwayHome[0]} - ${game.scoresAwayHome[1]} ${gameResult}`}
                      </Text>
                    ) : (
                      <Text>
                        {`${game.scoresAwayHome[0]} - ${game.scoresAwayHome[1]}`}
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box direction="row" pad="medium" justify="between">
            {game.scoreGameState === 10 && renderScheduledState()}
            {
              // start game
              game.scoreGameState === 20 && renderScoringState()
            }
            {
              // continue scoring
              (game.scoreGameState === 40 || game.scoreGameState === 30) &&
                renderStartedState()
            }
          </Box>
        </Card>
        {showEdit && (
          <EditGame
            game={game}
            onClose={(g) => {
              if (g) {
                setGame(g);
              }
              setShowEdit(false);
            }}
          />
        )}
        {showDeleteGame && (
          <DeleteGameLayer
            game={game}
            teamId={game.DNOR_teamId}
            teamGameId={game._id}
            onDelete={onDelete}
            closeLayer={() => setShowDeleteGame(false)}
          />
        )}
        {showDeleteSuccess && (
          <Toast
            label="Game deleted"
            background="status-ok"
            duration={3000}
            onClose={() => setShowDeleteSuccess(false)}
          />
        )}
      </Box>
    );
  }
);

export default GameCard;
