// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { useContext, useState } from 'react';
import { Box, Grommet, Layer, Text } from 'grommet';
import { Close, Link, ShareOption } from 'grommet-icons';
import QRCode from 'react-qr-code';
import { UserContext } from '../services/Session';
import Toast from '../components/Toast';
import { GROMMETTHEME } from '../constants/grommetTheme';

const QrModal = ({ onClose, team, source = null }) => {
  const userStore = useContext(UserContext);
  const referrerUserId = userStore.authUser
    ? userStore.authUser.claims.shd_user_id
    : null;

  const qrCodeSize = 176;
  const [copied, setCopied] = useState(false);

  const teamQr = () => {
    if (!team) {
      return null;
    }

    const displayUrl = `sidelinehd.com/${team.nameHandle}`;
    const url = new URL(`https://${displayUrl}`);
    if (referrerUserId) {
      url.searchParams.append('from', referrerUserId);
    }
    url.searchParams.append('campaign', 'qr_scan');

    if (source) {
      url.searchParams.append('s', source);
    }

    return (
      <Box gap="large" align="center" justify="center">
        <Box
          align="center"
          round="small"
          border={{
            color: 'white',
            size: '32px',
            style: 'solid',
            side: 'all',
          }}
        >
          <QRCode size={qrCodeSize} value={url.toString()} />
        </Box>
        <Text weight="bold" size="large" color={'white'}>
          {displayUrl}
        </Text>
        <Box direction="row" gap="xsmall" flex fill="horizontal">
          <Box
            pad="small"
            gap="medium"
            round="small"
            background={'white'}
            align="center"
            fill
            onClick={() => {
              if (navigator.share) {
                navigator.share({
                  title: `${team.nameMed} on sidelineHD`,
                  text: `View ${team.nameMed} on sidelineHD`,
                  url: url.toString(),
                });
              } else {
                navigator.clipboard.writeText(url.toString());
                setCopied(true);
              }
            }}
          >
            <ShareOption size="24px" />
            <Text size="small" weight={'bold'} color="gray-1">
              Share Profile
            </Text>
          </Box>
          <Box
            pad="small"
            gap="medium"
            round="small"
            background={'white'}
            align="center"
            fill
            onClick={() => {
              navigator.clipboard.writeText(url.toString());
              setCopied(true);
            }}
          >
            <Link size="24px" />
            <Text size="small" weight={'bold'} color="gray-1">
              Copy Link
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const customLayerTheme = {
    ...GROMMETTHEME,
    layer: {
      background: 'transparent',
    },
  };

  return (
    <Grommet theme={customLayerTheme}>
      <Layer
        onEsc={onClose}
        responsive={false}
        full="vertical"
        style={{ height: '100dvh' }}
        animation={false}
      >
        <Box fill="vertical" width={'240px'} justify="center">
          {teamQr()}
        </Box>
        <Box align="center" margin={'medium'}>
          <Box
            background={'white'}
            round="full"
            pad="small"
            onClick={onClose}
            align="center"
            justify="center"
            direction="row"
            height={'56px'}
            width={'56px'}
          >
            <Close />
          </Box>
        </Box>
        {copied ? (
          <Toast
            label="Copied to clipboard!"
            background="status-ok"
            duration={3000}
            onClose={() => {
              setCopied(false);
            }}
          />
        ) : null}
      </Layer>
    </Grommet>
  );
};

export default QrModal;
