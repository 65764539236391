// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Box, Layer, Text,
} from 'grommet';
import { FormClose, StatusGood } from 'grommet-icons';
import React, { useEffect } from 'react';
import SHDButton from '../components/SHD/Button';

const StatusGoodBox = ({ label, onClose, ...rest }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 3000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layer
      position="bottom"
      modal={false}
      margin={{ vertical: 'large', horizontal: 'auto' }}
      onEsc={onClose}
      responsive={false}
      width={{ min: 'large' }}
      height={{ min: 'small' }}
      plain
      {...rest}
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        background="status-ok"
      >
        <Box align="center" direction="row" gap="xsmall">
          <StatusGood />
          <Text>{label}</Text>
        </Box>
        <SHDButton
          icon={<FormClose />}
          onClick={onClose}
          plain
        />
      </Box>
    </Layer>
  );
};

export default StatusGoodBox;
