import { useContext, useEffect, useState, useCallback } from 'react';
import { Box, Button, Text } from 'grommet';
import { AddCircle } from 'grommet-icons';
import { UserContext } from '../../../services/Session';
import Toast from '../../../components/Toast';
import { BasicLoader } from '../../../helpers/Loaders';
import { isMobile } from '../../../helpers/browserDetect';
import { InviteBody, StaffMember, StaffMode } from '../types';

import StaffModal from './StaffModal';
import StaffMemberItem from './StaffMemberItem';
import useTeamStaff from './useTeamStaff';

const TeamStaff: React.FC = () => {
  const userStore = useContext(UserContext);
  const team = userStore.selectedTeam;
  const { fetchTeamStaff, updateStaff, sendInvite, deleteStaff } =
    useTeamStaff();

  const [staff, setStaff] = useState<StaffMember[]>([]);

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState<StaffMode | undefined>();

  const [selectedStaffMember, setSelectedStaffMember] =
    useState<StaffMember | null>(null);

  const [toast, setToast] = useState<{
    label: string;
    background: string;
  } | null>(null);

  useEffect(() => {
    if (team) {
      fetchTeamStaff(team._id).then((st: StaffMember[]) => {
        setStaff(st);
        setLoading(false);
      });
    }
  }, [team, fetchTeamStaff]);

  const onInviteSent = useCallback(
    async (inviteBody: InviteBody) => {
      const newInvites = await sendInvite(inviteBody);
      if (newInvites) {
        setStaff([...staff, ...newInvites]);
        setShowModal(undefined);
        setToast({
          label: 'Staff member invited',
          background: 'status-ok',
        });
      }
    },
    [staff, sendInvite]
  );

  const onUpdateStaff = useCallback(
    async (updatedStaffMember: StaffMember) => {
      const update = await updateStaff(
        selectedStaffMember?._id,
        updatedStaffMember
      );
      if (update) {
        setStaff(
          staff.map((s) =>
            s._id === updatedStaffMember._id ? updatedStaffMember : s
          )
        );
        setShowModal(undefined);
        setSelectedStaffMember(null);
        setToast({
          label: 'Staff member updated',
          background: 'status-ok',
        });
      }
    },
    [staff, selectedStaffMember, updateStaff]
  );

  const onRemoveStaff = useCallback(
    async (updatedStaffMember: StaffMember) => {
      const deleteUser = await deleteStaff(
        selectedStaffMember?._id,
        updatedStaffMember
      );
      if (deleteUser) {
        setStaff(staff.filter((s) => s._id !== selectedStaffMember?._id));
        setShowModal(undefined);
        setSelectedStaffMember(null);
        setToast({
          label: 'Staff member removed',
          background: 'status-ok',
        });
      }
    },
    [staff, selectedStaffMember, deleteStaff]
  );

  if (!team) {
    return null;
  }

  return (
    <Box gap="16px" pad="large">
      {!isMobile.any() && (
        <Text size="xlarge" weight="bold">
          Team Staff
        </Text>
      )}
      <Box
        background={isMobile.any() ? undefined : 'white'}
        gap={isMobile.any() ? 'large' : 'none'}
        border={
          isMobile.any() ? undefined : { color: 'light-2', size: 'xsmall' }
        }
        round={isMobile.any() ? 'none' : '8px'}
      >
        <Box
          align="start"
          pad={isMobile.any() ? 'none' : 'large'}
          justify="between"
          direction="row"
        >
          {!isMobile.any() && (
            <Text size="large" weight="bold">
              All Staff
            </Text>
          )}
          <Button
            plain
            icon={<AddCircle color="tertiary-1" />}
            label={
              <Text weight={'bold'} color={'tertiary-1'}>
                Add Staff
              </Text>
            }
            onClick={() => {
              setShowModal(StaffMode.ADD);
            }}
          />
        </Box>
        <Box gap={isMobile.any() ? '8px' : 'none'}>
          {loading && <BasicLoader />}
          {/* skeleton not really working like this */}
          {staff.map((staffMember) => (
            <Box key={staffMember._id} skeleton={loading}>
              <StaffMemberItem
                staffMember={staffMember}
                onEditClicked={() => {
                  setSelectedStaffMember(staffMember);
                  setShowModal(StaffMode.EDIT);
                }}
                setToast={setToast}
                onDeleted={() => {
                  setStaff(
                    staff.filter((s) => s.inviteCode !== staffMember.inviteCode)
                  );
                }}
                inEditModal={false}
              />
            </Box>
          ))}
        </Box>
        {team && showModal && (
          <StaffModal
            mode={showModal}
            team={team}
            staff={staff}
            onClose={() => setShowModal(undefined)}
            selectedStaffMember={selectedStaffMember}
            onInviteSent={onInviteSent}
            onUpdateStaff={onUpdateStaff}
            onRemoveStaff={onRemoveStaff}
          />
        )}
        {toast && !showModal && (
          <Toast
            label={toast.label}
            background={toast.background}
            onClose={() => {
              setToast(null);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default TeamStaff;
