import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Anchor, Box, Button, Heading, Image, Layer, Text } from 'grommet';
import { withRouter } from 'react-router-dom';
import { Trash, Close, AddCircle } from 'grommet-icons';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session/context';
import { BasicLoader } from '../../helpers/Loaders';
import SHDButton from '../../components/SHD/Button';
import Toast from '../../components/Toast';
import { shd } from '@shd/jslib/models';
import { ImgOlay } from '@shd/jslib/models/generated/docStorage/model/imgOlay';
import ImagePickerCropper from '../../helpers/ImagePickerCropper';
import { compressImage } from './TeamProfile/imgUploadHelper';

interface SponsorsLearnMoreProps {
  onClose: () => void;
}
const SponsorsLearnMore: React.FC<SponsorsLearnMoreProps> = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Sponsor Logos</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Text>
        Engage sponsors and earn team revenue! Sponsor logos are available for
        teams using sidelineHD PRO.
      </Text>
      <Image fit="contain" src="/pro/exampleSponsorBreakFullS.png" />
      <Text>
        Logos automatically rotate in the bottom corner of the stream and are
        featured at selected inning breaks.
      </Text>
      <Image fit="contain" src="/pro/exampleSponsorCornerFullS.png" />
      <Anchor
        href="https://help.sidelinehd.com/a/solutions/articles/69000800298"
        target="_blank"
      >
        Click here to learn more
      </Anchor>
    </Box>
  </Layer>
);

interface SponsorLogosSettingsProps {}

const SponsorLogosSettings: React.FC<SponsorLogosSettingsProps> = () => {
  const userStore = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const teamId = userStore.selectedTeam?._id;
  const [sponsorLogos, setSponsorLogos] = useState<ImgOlay[]>([]);
  const [selectedLogoToDelete, setSelectedLogoToDelete] = useState<
    number | null
  >(null);
  const [sponsorLogoUploading, setSponsorLogoUploading] = useState(false);
  const [src, setSrc] = useState<string | null>(null);
  const [showImageCropper, setShowImageCropper] = useState(false);

  const [toast, setToast] = useState<shd.Toast>();
  const [error, setError] = useState(null);

  const [showSponsorLearnMore, setShowSponsorLearnMore] = useState(false);

  useEffect(() => {
    axios.get(`/api/image_overlays/${teamId}`).then((response) => {
      setSponsorLogos(response.data);
      setLoading(false);
    });
  }, [teamId]);

  const onDeleteOverlay = () => {
    if (selectedLogoToDelete === null) {
      return;
    }
    const deleteLogo = sponsorLogos[selectedLogoToDelete];

    axios
      .delete('/api/image_overlay', {
        data: {
          id: deleteLogo._id,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setSponsorLogos(
            sponsorLogos.filter((logo) => logo._id !== deleteLogo._id)
          );
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result as string);
        setShowImageCropper(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteLogoModal = (
    <Layer modal>
      <Box pad="medium" gap="medium">
        <Text>Are you sure you want to delete this sponsor logo?</Text>
        <Box direction="row" justify="between">
          <SHDButton
            primary
            color="status-critical"
            label="Cancel"
            onClick={() => {
              setSelectedLogoToDelete(null);
            }}
          />
          <SHDButton
            primary
            label="Delete"
            onClick={() => {
              onDeleteOverlay();
              setSelectedLogoToDelete(null);
            }}
          />
        </Box>
      </Box>
    </Layer>
  );

  const onSave = useCallback(
    async (file: File | undefined) => {
      setSponsorLogoUploading(true);
      setShowImageCropper(false);
      if (!file) {
        return;
      }

      if (!teamId) {
        // Handle the undefined case, e.g., by setting an error state or returning early.
        return;
      }

      const compressed = await compressImage(file);

      const formData = new FormData();
      // The third parameter is required for server
      formData.append('image', compressed, (compressed as File).name);
      formData.append('teamId', teamId);

      axios
        .post(`/api/image_overlay`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
            responseType: 'arraybuffer',
          },
        })
        .then((response) => {
          setSponsorLogos([...sponsorLogos, response.data]);
          setSponsorLogoUploading(false);
        })
        .catch((err) => {
          setError(err.message);
          setSponsorLogoUploading(false);
        });
    },
    [teamId, sponsorLogos]
  );

  if (loading) {
    return <BasicLoader size={200} fullPage />;
  }

  if (error) {
    return <Text color="red">{error}</Text>;
  }

  return (
    <Box background="light-5" flex>
      <Box pad="16px" gap="8px">
        <Text>
          Logos are continuously rotated during your livestream and show at
          selected breaks in play.
        </Text>
        <Button
          plain
          label={
            <Text color="tertiary-1" size="small" weight="bold">
              Learn More
            </Text>
          }
          onClick={() => setShowSponsorLearnMore(true)}
        />
        <Text size="small">Transparent .PNG files work best.</Text>
        <Box direction="row" justify="between" align="center">
          <Button
            disabled={sponsorLogos.length >= 15}
            plain
            icon={
              <AddCircle
                color={sponsorLogos.length < 15 ? 'tertiary-1' : '#ccc'}
              />
            }
            label={
              <Text
                weight={'bold'}
                color={sponsorLogos.length < 15 ? 'tertiary-1' : '#ccc'}
              >
                Add Logo
              </Text>
            }
            onClick={() => {
              document.getElementById('imageInput')?.click();
            }}
          />
          <Text>{sponsorLogos.length}/15 logos</Text>
        </Box>
        <Box direction="row" wrap>
          {sponsorLogos.map((logo, i) => (
            <Box key={i} basis="1/2" pad="small" align="center">
              <Box
                pad="16px"
                gap="16px"
                background={'white'}
                overflow={'hidden'}
                round="8px"
                border
              >
                <Box width={'100%'}>
                  <Image fit="contain" src={logo.urlT1000} fill />
                </Box>
                <Button
                  plain
                  alignSelf="center"
                  icon={<Trash color="status-critical" size="18px" />}
                  onClick={() => {
                    setSelectedLogoToDelete(i);
                  }}
                />
              </Box>
            </Box>
          ))}
          <Box
            basis="1/2"
            pad="small"
            align="center"
            skeleton={sponsorLogoUploading}
          ></Box>
        </Box>
      </Box>
      {selectedLogoToDelete !== null && deleteLogoModal}
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(undefined);
          }}
        />
      )}
      {showSponsorLearnMore && (
        <SponsorsLearnMore onClose={() => setShowSponsorLearnMore(false)} />
      )}
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: 'none' }}
      />
      {showImageCropper && src && (
        <ImagePickerCropper
          src={src}
          circularCrop={false}
          aspect={16 / 9}
          onClose={() => {
            setShowImageCropper(false);
          }}
          onSave={onSave}
        />
      )}
    </Box>
  );
};

export default withRouter(SponsorLogosSettings);
