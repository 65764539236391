import React, { useEffect } from 'react';
import { useToast } from '../contexts/ToastContext';
import { Box, Button, Layer, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

const Toast: React.FC = () => {
  const { toast, hideToast } = useToast();

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        hideToast();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toast, hideToast]);

  if (!toast) {
    return null;
  }

  return (
    <Layer
      position="bottom"
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      onEsc={hideToast}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad="medium"
        background={toast.background || 'status-ok'}
      >
        <Box align="center" direction="row" gap="small">
          {toast.icon}
          <Text>{toast.message}</Text>
        </Box>
        <Button icon={<FormClose />} onClick={hideToast} plain />
      </Box>
    </Layer>
  );
};

export default Toast;
