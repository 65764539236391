/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
// Generate a React component called FacebookDestinationPicker

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Anchor,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Text,
} from 'grommet';
import { Close, Facebook, FormPreviousLink } from 'grommet-icons';
import { doc, getDoc } from 'firebase/firestore';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import { withFirebase } from '../../services/Firebase';
import { theFirebase } from '../../services/Firebase/firebase';
import { errorUtil, strogging } from '@shd/jslib/infra';
import {
  FacebookChannel,
  Page,
  PageResponse,
  Paging,
  Profile,
  callFbApi,
  PAGE_TYPE,
  PROFILE_TYPE,
  DestinationBase,
  GROUP_TYPE,
} from '../../helpers/facebook';

interface Props {
  teamId: string | undefined;
  onClose: () => void;
  onSave: (selectedChannel: FacebookChannel) => Promise<void>;
}
interface FBGroup extends DestinationBase {
  [key: string]: unknown;
}

const FacebookDestinationPicker: React.FC<Props> = withFirebase(
  observer<React.FC<Props>>(({ onClose, onSave }) => {
    const [profile, setProfile] = useState<Profile>();

    const [groups, setGroups] = useState<FBGroup[]>([]);
    const [groupsPagination, setGroupsPagination] = useState<Paging | null>(
      null
    );

    const [pages, setPages] = useState<Page[]>([]);
    const [pagesPagination, setPagesPagination] = useState<Paging | null>(null);

    const [loading, setLoading] = useState(true);
    const [destinationType, setDestinationType] = useState<string | null>(null);
    const [showGroups, setShowGroups] = useState(false);
    const [learnHowClicked, setLearnHowClicked] = useState(false);

    const [error, setError] = useState<unknown | null>(null);
    const [selected, setSelected] = useState<number | null>(null);

    // const getPagePicture = (pageId) => {
    //   const res = new Promise((resolve) => {
    //     window.FB.api(`${pageId}/picture?redirect=false`, (response) => {
    //       resolve(response);
    //     });
    //   });

    //   return res;
    // };

    const backClicked = () => {
      setSelected(null);
      setDestinationType(null);
    };

    const getProfile = () => {
      return callFbApi<Profile>('/me?fields=name,email,picture,link');
    };

    const getGroups = () => {
      return callFbApi('/me/groups?limit=10&admin_only=true');
    };

    const getPages = () => {
      return callFbApi<PageResponse>('/me/accounts?');
    };

    const morePagesClicked = () => {
      if (!pagesPagination?.next) {
        strogging.error('morePagesClicked: no next page');
        return;
      }
      axios.get(pagesPagination.next).then((res) => {
        const newPages = res.data.map((page: Page) => ({
          ...page,
          label: page.name,
          value: page.id,
        }));
        setPages([...pages, ...newPages]);
        setPagesPagination(res.data.paging);
      });
    };

    const moreGroupsClicked = () => {
      if (!groupsPagination?.next) {
        strogging.error('moreGroupsClicked: no next page');
        return;
      }
      axios.get(groupsPagination.next).then(async (res) => {
        const responseGroups = res.data.data || [];
        const newGroups = await Promise.all(
          responseGroups.map(async (group: FBGroup) => {
            const docRef = doc(
              theFirebase().fbGroupInstallsCollection,
              group.id as string
            );

            const document = await getDoc(docRef);
            const disabled = !document.exists;

            return {
              ...group,
              disabled,
              label: group.name,
              value: group.id,
            };
          })
        );

        setGroups([...groups, ...newGroups]);
        setGroupsPagination(res.data.paging);
      });
    };

    // const getPagePictures = () => {
    //   pages.map(async (page) => {
    //     const pageData = await getPagePicture(page.id);
    //     return { ...page, picture: pageData };
    //   });
    // };

    useEffect(() => {
      if (destinationType === PAGE_TYPE) {
        setError(null);
      }
    }, [destinationType]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      (async () => {
        try {
          const profileResponse = await getProfile();
          setProfile(profileResponse);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const groupsResponse = (await getGroups()) as any;

          const formattedGroups = await Promise.all(
            groupsResponse.data.map(async (group: FBGroup) => {
              let disabled;
              if (process.env.NODE_ENV !== 'development') {
                const docRef = doc(
                  theFirebase().fbGroupInstallsCollection,
                  group.id as string
                );

                const document = await getDoc(docRef);
                disabled = !document.exists;
              } else {
                disabled = false;
              }

              return {
                ...group,
                disabled,
                label: group.name,
                value: group.id,
              };
            })
          );
          setGroups(formattedGroups);
          setGroupsPagination(groupsResponse.paging);

          const pagesResponse = await getPages();
          setPages(
            pagesResponse.data.map((page) => ({
              ...page,
              disabled: page.tasks && !page.tasks.includes('CREATE_CONTENT'),
              label: page.name,
              value: page.id,
            }))
          );
          setPagesPagination(pagesResponse.paging);

          setLoading(false);
        } catch (e) {
          strogging.error('FacebookDestinationPicker', {
            err: errorUtil.errorMessage(e),
          });
          setError(e);
          setLoading(false);
        }
      })();
    }, []);

    const handleSelect = (index: number) => {
      if (selected === index) {
        setSelected(null);
      } else {
        setSelected(index);
      }
    };

    const renderOptions = (
      options: FBGroup[] | Page[] | Profile[],
      optionsPagination: Paging | null = null
    ) => {
      const canLoadMore =
        optionsPagination &&
        optionsPagination.next &&
        destinationType !== PROFILE_TYPE;

      if (!showGroups && destinationType === GROUP_TYPE) {
        return null;
      }

      return (
        <Box pad="medium" gap="medium" overflow="auto" fill>
          {options.map((option, index) => (
            <Button
              key={`option-${index + 1}`}
              onClick={() => handleSelect(index)}
              active={selected === index}
              disabled={option.disabled as boolean}
            >
              <Box
                direction="row"
                gap="small"
                pad="small"
                round="small"
                border={selected === index ? { color: 'brand' } : undefined}
                align="center"
                background={selected === index ? 'light-2' : undefined}
              >
                <Box flex={{ shrink: 0, grow: 0 }}>
                  {option.picture ? (
                    <Avatar src={option.picture.data.url} />
                  ) : (
                    <Avatar background="brand">
                      {option.name ? option.name.substring(0, 2) : 'XY'}
                    </Avatar>
                  )}
                </Box>
                <Text
                  size="large"
                  color={option.disabled ? 'gray-2' : undefined}
                >
                  {option.name}
                </Text>
              </Box>
            </Button>
          ))}
          {canLoadMore ? (
            <Button
              pad="small"
              label="Load more..."
              onClick={() => {
                if (destinationType === PAGE_TYPE) {
                  morePagesClicked();
                } else if (destinationType === GROUP_TYPE) {
                  moreGroupsClicked();
                }
              }}
            />
          ) : null}
        </Box>
      );
    };

    const renderDestinationPicker = () => {
      let list: FBGroup[] | Page[] | Profile[];
      let listPagination;
      let descriptionDiv;
      if (destinationType === PAGE_TYPE) {
        list = pages;
        listPagination = pagesPagination;
        descriptionDiv = (
          <Box
            pad="medium"
            border={{
              color: 'light-2',
              size: 'small',
              style: 'solid',
              side: 'bottom',
            }}
            flex={{ grow: 0, shrink: 0 }}
            gap="small"
          >
            <Text size="large">
              Don&apos;t see your page? Make sure you are an Admin, not just
              Editor or Moderator.
            </Text>
          </Box>
        );
      } else if (destinationType === GROUP_TYPE) {
        list = groups;
        listPagination = groupsPagination;
        descriptionDiv = (
          <Box
            pad="medium"
            border={{
              color: 'light-2',
              size: 'small',
              style: 'solid',
              side: 'bottom',
            }}
            flex={{ grow: 0, shrink: 0 }}
            gap="medium"
          >
            {showGroups ? (
              <Box gap="medium">
                <Text>
                  Don't forget to add the sidelineHD app to your Facebook group!
                  (This must be done on a web browser, not the Facebook app.)
                </Text>
                <Anchor
                  href="https://sidelinehd.freshdesk.com/support/solutions/articles/69000339722"
                  target="_blank"
                >
                  Learn More
                </Anchor>
                <Text size="large">
                  Don&apos;t see your group? Make sure you are an Admin.
                </Text>
              </Box>
            ) : (
              <Box gap="medium">
                <Text size="large" weight={'bold'}>
                  Add the sidelineHD app to your Facebook group. Here is how:
                </Text>
                <Text>Go to facebook.com/groups and select your group.</Text>
                <Text>Click Group Settings in the left menu</Text>
                <Text>
                  Scroll to the very bottom of the page and you should see this:
                </Text>
                <Box width={{ max: '480px' }}>
                  <Image fill src="/fb_group_settings.png" />
                </Box>
                <Text>Click on the pencil button next to "Apps"</Text>
                <Text>
                  Search for "SidelineHD" and add the app to your group. Be sure
                  to scroll to the bottom and hit save!
                </Text>

                <Box margin={{ top: 'large' }}>
                  <Button
                    primary
                    size="large"
                    color="light-2"
                    icon={<Facebook color="plain" />}
                    label={<Text weight={'bold'}>Learn how</Text>}
                    onClick={() => {
                      setLearnHowClicked(true);
                    }}
                    href="https://sidelinehd.freshdesk.com/support/solutions/articles/69000339722"
                    target="_blank"
                  />
                </Box>
              </Box>
            )}
          </Box>
        );
      } else {
        list = profile ? [profile] : [];
      }

      return (
        <Card round={false} elevation="none" fill>
          <CardHeader
            direction="row"
            pad="small"
            align="center"
            justify="between"
          >
            <Button
              icon={<FormPreviousLink />}
              onClick={backClicked}
              justify="start"
            />
            <Box justify="center" align="center">
              <Text alignSelf="center" size="large" weight="bold">
                {`Stream to your ${destinationType}`}
              </Text>
            </Box>
            <Box width="24px" />
          </CardHeader>
          <CardBody fill>
            {descriptionDiv}
            {renderOptions(list, listPagination)}
          </CardBody>
          <CardFooter
            pad={{ horizontal: 'small', top: 'large', bottom: '96px' }}
            background="light-2"
          >
            {showGroups || destinationType !== GROUP_TYPE ? (
              <Button
                fill
                primary
                size="large"
                label="Save"
                onClick={() => {
                  onSave({
                    type: destinationType ?? '',
                    ...list[selected || 0],
                  } as FacebookChannel); // this is totally broken, but not more than before
                }}
              />
            ) : (
              <Button
                fill
                primary
                size="large"
                label="Next"
                onClick={() => {
                  setShowGroups(true);
                }}
                disabled={!learnHowClicked && false}
              />
            )}
          </CardFooter>
        </Card>
      );
    };

    const renderDestinationTypePicker = () => (
      <Box pad="medium">
        <Box margin="small" direction="row" justify="between">
          <Text alignSelf="center" size="large" weight="bold">
            Where do you want to stream to?
          </Text>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        {loading ? (
          <BasicLoader />
        ) : (
          <Box gap="medium" fill="horizontal">
            <Button
              secondary
              color="dark-4"
              label="My personal profile"
              size="large"
              onClick={() => {
                setDestinationType(PROFILE_TYPE);
                setSelected(0);
              }}
            />
            <Button
              secondary
              color="dark-4"
              label="A page"
              size="large"
              onClick={() => setDestinationType(PAGE_TYPE)}
            />
          </Box>
        )}
      </Box>
    );

    return (
      <Box gap="small" fill>
        {loading ? (
          <Box fill pad="large">
            <Text>Loading your Facebook information...</Text>
            <BasicLoader />
          </Box>
        ) : (
          <>
            {!destinationType && renderDestinationTypePicker()}
            {destinationType && renderDestinationPicker()}
            {error && <Text color="status-error">{JSON.stringify(error)}</Text>}
          </>
        )}
      </Box>
    );
  })
);

export default FacebookDestinationPicker;
