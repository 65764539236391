// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import {
  OptionStyled,
  RadioGroupStyled,
  OptionLabel,
  OptionIcon,
  FieldStyled,
} from './style';

import options from './options';

const SportsRadioField = () => (
  <FieldStyled required component={SportRadioGroup} />
);

export default SportsRadioField;

const SportRadioGroup = ({ value, onChange }) => (
  <RadioGroupStyled
    id="asdf"
    name="attrib_sportType"
    value={value}
    onChange={onChange}
    options={options.map(({ icon, key, display }) => ({
      label: display,
      value: key,
      icon,
    }))}
  >
    {({ label, icon }, { checked }) => (
      <OptionStyled checked={checked}>
        <OptionIcon checked={checked}>{icon}</OptionIcon>
        <OptionLabel checked={checked}>{label}</OptionLabel>
      </OptionStyled>
    )}
  </RadioGroupStyled>
);
