// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import { Card, Text } from 'grommet';

export const ErrorWrapper = styled(Card)`
  margin-bottom: 28px;
`;

export const ErrorHeading = styled(Text)`
  font-weight: 700;
  font-size: 16px;
`;

export const ErrorMessage = styled(Text)`
  font-size: 14px;
`;
