// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { Text, Layer, Heading, Box } from 'grommet';
import * as Sentry from '@sentry/browser';
import Select from 'react-select';
import SHDButton from '../../components/SHD/Button';
import { insertDisplayName } from '../../helpers/utils';
import { BasicLoader } from '../../helpers/Loaders';
import { ScoringContext } from './context';
import axios from '../../helpers/axios';

const RunnerOutcomeSelector = ({
  label,
  onResponse = () => {},
  onBack = () => {},
}) => {
  const scoringStore = useContext(ScoringContext);
  const isOffense = scoringStore.scoreboard.i % 2 === scoringStore.awayHome;

  const [players, setPlayers] = useState([]);
  const [secondaryPlayers, setSecondaryPlayers] = useState([]);

  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    axios
      .get(`/api/team/${scoringStore.teamId}/team_players`)
      .then((response) => {
        const teamPlayerDocs = response.data.players;
        const newPlayers = teamPlayerDocs
          .filter((player) => player.isActive)
          .map((player) => {
            const newPlayer = insertDisplayName(player);
            // for Select component
            newPlayer.value = newPlayer._id;
            newPlayer.label = newPlayer.displayName;
            return newPlayer;
          });

        newPlayers.sort((a, b) => {
          const aJerseyNum = parseInt(a.jerseyNum);
          const bJerseyNum = parseInt(b.jerseyNum);
          return aJerseyNum > bJerseyNum ? 1 : -1;
        });

        setPlayers(newPlayers);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Layer>
        <BasicLoader fullPage />
      </Layer>
    );
  }

  return (
    <Layer pad={{ vertical: 'medium' }} full>
      <Box
        pad="medium"
        gap="medium"
        style={{ overflow: 'auto', display: 'block' }}
        fill
      >
        <Box fill="horizontal" align="end" pad="medium">
          <Text color="tertiary-1" weight="bold" onClick={onBack}>
            Back
          </Text>
        </Box>
        {error ? <Text color="status-critical">{error}</Text> : null}
        <Heading level="3">{label}</Heading>
        <Box gap="medium" height={{ min: 'initial' }}>
          <Text>{`Give the ${
            isOffense ? 'baserunner' : 'defenders'
          } credit!`}</Text>
          <Select
            options={
              secondaryPlayers.length < 3
                ? players
                : players.map((player) => {
                    const newPlayer = { ...player };
                    newPlayer.isDisabled = true;
                    return newPlayer;
                  })
            }
            defaultValue={secondaryPlayers}
            onChange={(option) => {
              setSecondaryPlayers(option);
            }}
            isMulti
          />
        </Box>
        <Box pad={{ top: 'large' }} height={{ min: 'small' }} gap="medium">
          <SHDButton
            size="large"
            label="Tag Players"
            primary
            onClick={() => {
              onResponse({
                secondaryPlayers,
              });
            }}
            color="tertiary-1"
          />
          <SHDButton
            size="large"
            label="Skip"
            secondary
            onClick={() => {
              onResponse({
                secondaryPlayers: [],
              });
            }}
            color="tertiary-1"
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default RunnerOutcomeSelector;
