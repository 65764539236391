import React, { useState, useEffect, useContext } from 'react';
import {
  Anchor,
  Box,
  Button,
  Heading,
  Image,
  Layer,
  Text,
  Card,
  CardBody,
} from 'grommet';
import { withRouter } from 'react-router-dom';
import { Trash, Close } from 'grommet-icons';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session/context';
import RadarLayer from '../SetUp/RadarLayer';
import { BasicLoader } from '../../helpers/Loaders';
import SHDButton from '../../components/SHD/Button';
import Toast from '../../components/Toast';
import RadarEvents from '../StreamingConfig/RadarEvents';
import { shd } from '@shd/jslib/models';

interface VeloLearnMoreProps {
  onClose: () => void;
}

const VeloLearnMore: React.FC<VeloLearnMoreProps> = ({ onClose }) => (
  <Layer onEsc={onClose} modal>
    <Box width="large" pad="medium" gap="medium">
      <Box align="baseline" direction="row" justify="between" fill="horizontal">
        <Heading level={3}>Live Velo</Heading>
        <Button icon={<Close />} onClick={onClose} />
      </Box>
      <Text>
        Show LIVE pitch velocity in your scoreboard from Pocket Radar and
        Stalker Sport radar.
      </Text>
      <Text>
        Livestreams and highlight clips show velo, exit velo, and spin rate
        (where supported). Fully automatic - no manual entry required!
      </Text>
      <Image fit="contain" src="/pro/exampleLiveVelo.png" />
      <Anchor
        href="https://help.sidelinehd.com/a/solutions/articles/69000362870"
        target="_blank"
      >
        Learn more - velo from Pocket Radar
      </Anchor>
      <Anchor
        href="https://help.sidelinehd.com/a/solutions/articles/69000829972"
        target="_blank"
      >
        Learn more - velo, exit velocity, and spin rate from Stalker Sport
      </Anchor>
    </Box>
  </Layer>
);

interface RadarSettingsProps {}

const RadarSettings: React.FC<RadarSettingsProps> = () => {
  const userStore = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const [showPr, setShowPr] = useState(false);
  const [showStalker, setShowStalker] = useState(false);

  const [radar, setRadar] = useState<{
    pradar: shd.Radar | null;
    eventList: shd.VeloEvent[];
  }>({
    pradar: null,
    eventList: [],
  });

  const [showVeloLearnMore, setShowVeloLearnMore] = useState(false);

  const teamId = userStore.selectedTeam?._id;
  const [toast, setToast] = useState<shd.Toast>();
  const [error, setError] = useState(null);
  const [prError, setPRError] = useState(null);

  useEffect(() => {
    const radarPromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/radar/${teamId}`)
        .then((response) => {
          if (response.data.pradar || response.data.pradarForUser) {
            setRadar(response.data);
          }
          resolve(undefined);
        })
        .catch((respError) => {
          setError(respError.toString());
          reject();
        });
    });

    Promise.all([radarPromise])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [teamId]);

  const setRadarStatus = (isEnable: boolean) => {
    axios
      .put(`/api/radar/${teamId}`, { isEnable })
      .then((response) => setRadar(response.data))
      .catch((respError) => {
        setPRError(respError.response.data.message);
      });
  };

  const deleteRadar = () => {
    axios
      .delete(`/api/radar/${teamId}`)
      .then(() => setRadar({ pradar: null, eventList: [] }))
      .catch((respError) => {
        setError(respError);
      });
  };

  if (loading) {
    return <BasicLoader size={200} fullPage />;
  }

  if (error) {
    return <Text color="red">{error}</Text>;
  }

  const renderRadarStatus = () => {
    if (radar.pradar) {
      if (radar.pradar.prUserId !== '' || radar.pradar.appId) {
        const radarDescription =
          radar.pradar.prUserId !== ''
            ? `Velo source is Pocket Radar (${radar.pradar.acctEmail})`
            : `Velo source is Stalker (app code: ${radar.pradar.appId})`;
        const radarType =
          radar.pradar.prUserId !== '' ? 'Pocket Radar' : 'Stalker';
        // PR / stalker connected
        return (
          <CardBody gap="medium">
            <Text>{radarDescription}</Text>
            <Box gap="medium">
              {radar.pradar.isEnable ? (
                <Box gap="medium">
                  <Text size="large" color="status-ok">
                    Velo feed enabled
                  </Text>
                  <Box direction="row" gap="medium" justify="between">
                    <SHDButton
                      secondary
                      label={`Disable ${radarType}`}
                      onClick={() => setRadarStatus(false)}
                    />
                    <SHDButton
                      icon={<Trash />}
                      mixtrack={[`Delete ${radarType}`]}
                      hoverIndicator
                      onClick={() => deleteRadar()}
                    />
                  </Box>
                </Box>
              ) : (
                <Box gap="medium">
                  <Text size="large" color="status-critical">
                    {`${radarType} disabled - your velo feed is muted`}
                  </Text>
                  <Box direction="row" gap="medium" justify="between">
                    <SHDButton
                      secondary
                      label={`Activate ${radarType}`}
                      onClick={() => setRadarStatus(true)}
                    />
                    <SHDButton
                      icon={<Trash />}
                      mixtrack={[`Delete ${radarType}`]}
                      hoverIndicator
                      onClick={() => deleteRadar()}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {radar.pradar.isEnable && (
              <RadarEvents eventList={radar.eventList} />
            )}
          </CardBody>
        );
      }

      // PR not connected - user needs to go to app
      return (
        <CardBody pad="medium" direction="column" gap="medium">
          <Card pad="small" background="light-5" margin={{ bottom: 'small' }}>
            <Text size="large" color="status-critical">
              {`Open the PR Sports app and login with ${radar.pradar.acctEmail}.
                Go to the Pocket Radar Connect menu
                and connect with sidelineHD.`}{' '}
              <Anchor
                href="https://www.pocketradar.com/pages/pocket-radar-connect"
                target="_blank"
              >
                Learn more.
              </Anchor>
            </Text>
          </Card>

          <Box gap="medium">
            {radar.pradar.isEnable ? (
              <Box gap="medium">
                <Text size="large" color="status-ok">
                  {`Pocket Radar app paired (${radar.pradar.acctEmail})`}
                </Text>
                <Box direction="row" gap="medium">
                  <Button
                    secondary
                    label="Disable Pocket Radar"
                    onClick={() => setRadarStatus(false)}
                  />
                  <SHDButton
                    icon={<Trash />}
                    mixtrack={['Delete Pocket Radar']}
                    hoverIndicator
                    onClick={() => deleteRadar()}
                  />
                </Box>
              </Box>
            ) : (
              <Box gap="medium">
                <Text size="large" color="status-critical">
                  {`Pocket Radar disabled (${radar.pradar.acctEmail})`}
                </Text>
                <Box direction="row" gap="medium">
                  <SHDButton
                    color="red"
                    label="Activate Pocket Radar"
                    onClick={() => setRadarStatus(true)}
                  />
                  <SHDButton
                    icon={<Trash />}
                    mixtrack={['Delete Pocket Radar']}
                    hoverIndicator
                    onClick={() => deleteRadar()}
                  />
                </Box>
              </Box>
            )}
          </Box>

          {radar.pradar.isEnable && <RadarEvents eventList={radar.eventList} />}
        </CardBody>
      );
    }
    return (
      <Box gap="8px">
        <Text size="large">No velo source selected</Text>
        <Box gap="8px">
          <Button
            secondary
            label="Pair Pocket Radar Sports app"
            onClick={() => {
              setShowPr(true);
            }}
          />
          <SHDButton
            secondary
            label="Pair StalkerSport app"
            onClick={() => {
              setShowStalker(true);
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box fill>
      <Box pad="16px">
        <Box
          direction="row"
          justify="between"
          align="center"
          pad={{ bottom: '8px' }}
        >
          <Text weight={'bold'} size="small">
            Live Velocity Source
          </Text>
          <Button
            plain
            label={
              <Text size="small" weight="bold" color={'tertiary-1'}>
                Learn more
              </Text>
            }
            onClick={() => setShowVeloLearnMore(true)}
          />
        </Box>
        {renderRadarStatus()}
        {prError && (
          <Text color="status-critical">
            {prError}{' '}
            <Anchor href="https://www.pocketradar.com/pages/pocket-radar-connect">
              Learn more.
            </Anchor>
          </Text>
        )}
      </Box>
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(undefined);
          }}
        />
      )}
      {showPr && (
        <Layer>
          <RadarLayer
            radarType="pr"
            setRadar={setRadar}
            teamId={teamId}
            closeLayer={() => setShowPr(false)}
          />
        </Layer>
      )}
      {showStalker && (
        <Layer>
          <RadarLayer
            radarType="stalker"
            setRadar={setRadar}
            teamId={teamId}
            closeLayer={() => setShowStalker(false)}
          />
        </Layer>
      )}
      {showVeloLearnMore && (
        <VeloLearnMore onClose={() => setShowVeloLearnMore(false)} />
      )}
    </Box>
  );
};

export default withRouter(RadarSettings);
