// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import {
  Box, Heading, Image,
} from 'grommet';
import OnboardingButton from './OnboardingButton';

const ExampleTextStep = ({ value, handleForward }) => (
  <Box>
    <Heading level={3}>
      We text you when your player has a great outcome.
      Let’s get started!
    </Heading>
    <Box width={{ max: '300px' }} align="center">
      <Image
        src={`/onboarding/${value.attrib_sportType}_text_graphic.png`}
        fill
      />
    </Box>
    <OnboardingButton
      label="Next"
        // disabled={disabledButton}
      onClick={handleForward}
    />
  </Box>
);

export default ExampleTextStep;
