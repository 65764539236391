// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Card,
  CardBody,
  Form,
  FormField,
  Heading,
  Layer,
  Text,
} from 'grommet';
import {
  Add,
  Phone,
  Close,
  Trash,
  Alert,
  Checkmark,
  Refresh,
} from 'grommet-icons';
import { formatPhoneNumber } from 'react-phone-number-input';
import { v4 as uuidv4 } from 'uuid';

import axios from '../../helpers/axios';
import TagInput from '../../helpers/TagInput';
import SHDButton from '../../components/SHD/Button';
import Toast from '../../components/Toast';
import {
  FieldCoachPosition,
  FieldJerseyNum,
  FieldNameFirst,
  FieldNameLast,
} from '../../helpers/FormFields';
import HelpTooltip from '../../helpers/HelpTooltip';
import { UserContext } from '../../services/Session';
import { BasicLoader } from '../../helpers/Loaders';
import PhoneNumberInput from '../../helpers/PhoneNumberInput';
import { compareJerseyNum } from '../../helpers/utils';
import RolloverPlayerLayer from '../../components/RolloverPlayerLayer';
import { BBSB_SPORTS } from '../../constants/strings';
import { analytics } from '../../services/analytics';

const RosterEmptyCard = ({ text }) => (
  <Card
    key="active-roster-empty"
    background="light-2"
    elevation="none"
    width="large"
  >
    <CardBody>
      <Box direction="column" pad="medium" gap="small">
        <Text weight="bold">{text}</Text>
      </Box>
    </CardBody>
  </Card>
);

const Roster = (props) => {
  const userStore = useContext(UserContext);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedCoachPhone, setSelectedCoachPhone] = useState('');
  const [teamInfo, setTeamInfo] = useState(null);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [error, setError] = useState();
  const [coachPhoneError, setCoachPhoneError] = useState('');
  const [toast, setToast] = useState(null);
  const [pageLoading, togglePageLoading] = useState(true);
  const [saveLoading, toggleSaveLoading] = useState(false);
  const [deleteLoading, toggleDeleteLoading] = useState(false);
  const [showRolloverPlayer, setShowRolloverPlayer] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`/api/team/${userStore.activeOnboardingTeam}`)
      .then((response) => {
        const { info, players } = response.data;
        setTeamInfo(info);
        setCoaches(info.coaches ? info.coaches : []);
        setTeamPlayers(players);
        togglePageLoading(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        togglePageLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = () => {
    props.afterValidation(teamInfo);
  };

  const onPhoneNumberChanged = (value) => {
    selectedPlayer.invitePhoneNumberList = value;
  };

  const onClose = () => {
    setSelectedPlayer(null);
    setSelectedCoach(null);
    setSelectedCoachPhone('');
  };

  const onSavePlayer = () => {
    toggleSaveLoading(true);
    axios
      .post(`/api/team/${userStore.activeOnboardingTeam}/team_players`, {
        teamId: userStore.activeOnboardingTeam,
        player: selectedPlayer,
        noInvite: true,
      })
      .then((response) => {
        const { player: respPlayer } = response.data;
        if (selectedPlayer._id) {
          const newTeamPlayers = teamPlayers.map((teamPlayer) => {
            if (teamPlayer._id === respPlayer._id) {
              return respPlayer;
            }
            return teamPlayer;
          });
          setTeamPlayers(newTeamPlayers);
        } else {
          const newTeamPlayers = [respPlayer, ...teamPlayers];
          setTeamPlayers(newTeamPlayers);
        }
        setError('');
        setSelectedPlayer(null);
        setToast({
          label: 'Player saved!',
          background: 'status-ok',
        });
        setError('');
        toggleSaveLoading(false);

        analytics.track('Team Player Added', {
          source: 'manual',
          isOnboarding: true,
          addedPhoneNumber: respPlayer.invitePhoneNumberList.length > 0,
          teamId: userStore.activeOnboardingTeam,
        });
      })
      .catch((respError) => {
        setError('Could not save player. Please refresh and try again.');
        toggleSaveLoading(false);
        Sentry.captureException(respError);
      });
  };

  const onSaveCoach = () => {
    toggleSaveLoading(true);
    let newCoaches = [];
    if (selectedCoach._id) {
      newCoaches = coaches.map((coach) => {
        if (coach._id === selectedCoach._id) {
          return selectedCoach;
        }
        return coach;
      });
    } else {
      const newSelectedCoach = { ...selectedCoach };
      newSelectedCoach._id = uuidv4();
      newCoaches = [...coaches, newSelectedCoach];
    }
    axios
      .put(`/api/team/${userStore.activeOnboardingTeam}`, {
        teamInfo: {
          coaches: newCoaches,
        },
      })
      .then(() => {
        setCoaches(newCoaches);
        toggleSaveLoading(false);
        setSelectedCoach(null);
        setSelectedCoachPhone('');
        setError('');
        setToast({
          label: 'Coach saved!',
          background: 'status-ok',
        });
      })
      .catch((respError) => {
        toggleSaveLoading(false);
        setError('Could not save coach. Please refresh and try again.');
        Sentry.captureException(respError);
      });

    const body = {
      invitedUserPhone: selectedCoach.phoneNumber,
      invitedUserName: `${selectedCoach.nameFirst} ${selectedCoach.nameLast}`,
      teamId: userStore.activeOnboardingTeam,
      teamRole: 'coach',
    };
    axios
      .post('/api/user_invites', body)
      .then(() => {
        setToast({
          label: 'Coach invited!',
          background: 'status-ok',
        });
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError(e.toString());
        }
      });
  };

  const onDeletePlayer = () => {
    toggleDeleteLoading(true);
    axios
      .delete(`/api/team_player/${selectedPlayer._id}`, {
        data: {
          teamId: userStore.activeOnboardingTeam,
          player: { _id: selectedPlayer._id },
        },
      })
      .then(() => {
        const newTeamPlayers = teamPlayers.filter(
          (player) => player._id !== selectedPlayer._id
        );
        setTeamPlayers(newTeamPlayers);
        toggleDeleteLoading(false);
        setSelectedPlayer(null);
        setToast({
          label: 'Player deleted!',
          background: 'status-ok',
        });
        setError('');
      })
      .catch((respError) => {
        toggleDeleteLoading(false);
        setError('Could not delete player. Please refresh and try again.');
        Sentry.captureException(respError);
      });
  };

  const onDeleteCoach = () => {
    toggleDeleteLoading(true);
    if (selectedCoach._id) {
      const newCoaches = coaches.filter(
        (coach) => coach._id !== selectedCoach._id
      );

      axios
        .put(`/api/team/${userStore.activeOnboardingTeam}`, {
          teamInfo: {
            coaches: newCoaches,
          },
        })
        .then(() => {
          setCoaches(newCoaches);
          toggleDeleteLoading(false);
          setSelectedCoach(null);
          setSelectedCoachPhone('');
          setError('');
          setToast({
            label: 'Coach deleted!',
            background: 'status-ok',
          });
        });
    } else {
      toggleDeleteLoading(false);
      setSelectedCoach(null);
      setSelectedCoachPhone('');
      setError('');
    }
  };

  const onUpdatedPlayers = (updatedPlayers, isDeletion = false) => {
    const updatedPlayerIds = updatedPlayers.map((player) => player._id);
    const updatedPlayersById = Object.assign(
      {},
      ...updatedPlayers.map((player) => ({ [player._id]: player }))
    );

    setSelectedPlayer(null);
    if (isDeletion) {
      // p._id !== player._id
      setTeamPlayers(
        teamPlayers.filter((p) => !updatedPlayerIds.includes(p._id))
      );
      setToast({
        label: 'Player deleted!',
        background: 'status-ok',
      });
    } else {
      analytics.track('Team Player Added', {
        source: 'rollover',
        isOnboarding: true,
        teamId: userStore.activeOnboardingTeam,
      });

      setSelectedPlayer(null);
      setToast({
        label: 'Roster saved!',
        background: 'status-ok',
      });

      if (!teamPlayers.filter((p) => updatedPlayerIds.includes(p._id)).length) {
        const newPlayers = [...teamPlayers, ...updatedPlayers].sort(
          compareJerseyNum
        );
        setTeamPlayers(newPlayers);
      } else {
        setTeamPlayers(
          teamPlayers.map((p) => {
            const player = updatedPlayersById[p._id];
            if (updatedPlayerIds.includes(p._id)) {
              // get the new player
              return player;
            }

            if (
              p.nameFirst === player.nameFirst &&
              p.nameLast === player.nameLast &&
              p.jerseyNum === player.jerseyNum
            ) {
              // when creating a new player, make sure
              // we update players state with the hydrated player.
              // safe to match on name and jerseyNum here
              // as a sanity check
              return player;
            }
            return p;
          })
        );
      }
    }
  };

  const activeCoaches = coaches
    .filter(
      (coach) =>
        !('isActive' in coach) || ('isActive' in coach && coach.isActive)
    )
    .map((coach, i) => (
      <Card
        key={`active-coach-${i + 1}`}
        background="light-2"
        elevation="none"
        width="large"
        onClick={() => {
          setSelectedCoach({ ...coach });
          setSelectedCoachPhone(coach.phoneNumber);
        }}
      >
        <CardBody>
          <Box direction="row" align="center" justify="between">
            <Box direction="column" pad="medium" gap="small">
              {' '}
              <Box direction="row">
                <Text weight="bold">{`${coach.nameFirst} ${coach.nameLast}`}</Text>
              </Box>
              <Box direction="row">
                {coach.phoneNumber ? (
                  <>
                    <Phone size="16px" />
                    <Text
                      key={`number-${coach.phoneNumber}`}
                      margin={{ left: 'small' }}
                    >
                      {formatPhoneNumber(coach.phoneNumber)}
                    </Text>
                  </>
                ) : (
                  <Text color="status-critical">
                    Will not receive an invite
                  </Text>
                )}
              </Box>
            </Box>
            {!coach.phoneNumber ? (
              <Box
                margin="medium"
                pad="small"
                onClick={() => {}}
                hoverIndicator
              >
                <Alert color="status-critical" />
              </Box>
            ) : (
              <Box margin="medium" pad="small">
                <Checkmark color="status-ok" />
              </Box>
            )}
          </Box>
        </CardBody>
      </Card>
    ));

  const activeRoster = teamPlayers
    .filter(
      (player) =>
        !('isActive' in player) || ('isActive' in player && player.isActive)
    )
    .map((player, i) => (
      <Card
        key={`active-player-${i + 1}`}
        background="light-2"
        elevation="none"
        width="large"
        onClick={() => {
          setSelectedPlayer({ ...player });
        }}
      >
        <CardBody>
          <Box direction="row" align="center" justify="between">
            <Box direction="column" pad="medium" gap="small">
              <Box direction="row">
                <Text weight="bold">{`${player.nameFirst} ${player.nameLast}`}</Text>
                <Text margin={{ left: 'small' }}>{`#${player.jerseyNum}`}</Text>
              </Box>
              {!BBSB_SPORTS.includes(teamInfo.attrib_sportType) ? null : (
                <Box direction="row">
                  {player.invitePhoneNumberList.length ? (
                    <>
                      <Phone size="16px" />
                      {player.invitePhoneNumberList.map((number) => (
                        <Text
                          key={`number-${number}`}
                          margin={{ left: 'small' }}
                        >
                          {formatPhoneNumber(number)}
                        </Text>
                      ))}
                    </>
                  ) : (
                    <Text color="status-critical">
                      Will not receive an invite
                    </Text>
                  )}
                </Box>
              )}
            </Box>
            {!player.invitePhoneNumberList.length ? (
              <Box
                margin="medium"
                pad="small"
                onClick={() => {}}
                hoverIndicator
              >
                {!BBSB_SPORTS.includes(teamInfo.attrib_sportType) ? null : (
                  <Alert color="status-critical" />
                )}
              </Box>
            ) : (
              <Box margin="medium" pad="small">
                <Checkmark color="status-ok" />
              </Box>
            )}
          </Box>
        </CardBody>
      </Card>
    ));

  const coachEditor = (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <Box gap="small" pad="small" width="large">
        <Box margin="small" direction="row" justify="end">
          <SHDButton icon={<Close />} onClick={onClose} />
        </Box>
        <Box margin={{ bottom: 'medium' }}>
          <Heading alignSelf="center" level="3">
            Add and invite a coach
          </Heading>
          {selectedCoach && selectedCoach._id && !selectedCoach.phoneNumber ? (
            <Text color="status-critical" textAlign="center">
              Coaches without a phone number cannot receive an invite to your
              team.
            </Text>
          ) : null}
          <Box margin={{ top: 'small' }} size="xlarge" justify="center">
            <Form
              value={selectedCoach}
              onChange={(nextCoach) => {
                setSelectedCoach(nextCoach);
              }}
            >
              <FieldNameFirst />
              <FieldNameLast required={false} />
              <Box direction="row" align="center">
                <Text margin={{ horizontal: 'small' }}>Phone number</Text>
                <HelpTooltip
                  content={
                    <Box background={'light-3'} pad="small">
                      The following number will receive an invite to your team.
                    </Box>
                  }
                />
              </Box>
              {coachPhoneError ? (
                <Text color="status-critical" margin={{ horizontal: 'small' }}>
                  Please enter a valid phone number.
                </Text>
              ) : null}
              <FormField>
                <PhoneNumberInput
                  value={selectedCoachPhone}
                  id="text-input"
                  plain
                  placeholder="Phone number"
                  onValid={(phoneNumber) => {
                    if (phoneNumber) {
                      setSelectedCoach({ ...selectedCoach, phoneNumber });
                      setCoachPhoneError(null);
                    } else {
                      setSelectedCoach({ ...selectedCoach, phoneNumber: '' });
                    }
                  }}
                  onError={(phoneError) => {
                    if (phoneError) {
                      setCoachPhoneError(true);
                    } else {
                      setCoachPhoneError(null);
                    }
                  }}
                />
              </FormField>

              <FieldCoachPosition />
            </Form>
          </Box>
        </Box>
        <Box direction="row" gap="small" justify="between">
          {selectedCoach && selectedCoach._id ? (
            <SHDButton
              primary
              color="status-critical"
              icon={<Trash />}
              alignSelf="end"
              label={deleteLoading ? '...Deleting' : 'Delete'}
              onClick={onDeleteCoach}
              gap="small"
              disabled={deleteLoading}
            />
          ) : (
            <></>
          )}
          <SHDButton
            primary
            label={saveLoading ? '...Saving' : 'Save'}
            gap="small"
            onClick={onSaveCoach}
            disabled={saveLoading || coachPhoneError}
          />
        </Box>
      </Box>
    </Layer>
  );

  const playerEditor = (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <Box gap="small" pad="small" width="large">
        <Box margin="small" direction="row" justify="end">
          <SHDButton icon={<Close />} onClick={onClose} />
        </Box>
        <Box margin={{ bottom: 'medium' }}>
          <Heading alignSelf="center" level="3">
            Add and invite a player
          </Heading>
          {selectedPlayer &&
          selectedPlayer._id &&
          !selectedPlayer.invitePhoneNumberList.length ? (
            <Text color="status-critical" textAlign="center">
              Players without a phone number cannot receive an invite to your
              team.
            </Text>
          ) : null}
          <Box margin={{ top: 'small' }} size="xlarge" justify="center">
            <Form
              value={selectedPlayer}
              onChange={(nextPlayer) => {
                setSelectedPlayer(nextPlayer);
              }}
              onReset={() => setSelectedPlayer(selectedPlayer)}
            >
              <FieldNameFirst />
              <FieldNameLast required={false} />
              <FieldJerseyNum />
              {teamInfo &&
              !BBSB_SPORTS.includes(teamInfo.attrib_sportType) ? null : (
                <>
                  <Box direction="row" align="center">
                    <Text margin={{ horizontal: 'small' }}>Phone numbers</Text>
                    <HelpTooltip
                      content={
                        <Box background={'light-3'} pad="small">
                          The following numbers will be invited to claim this
                          player and receive highlights via text.
                        </Box>
                      }
                    />
                  </Box>
                  <TagInput
                    value={
                      selectedPlayer && selectedPlayer.invitePhoneNumberList
                    }
                    onTagAdded={onPhoneNumberChanged}
                    onTagRemoved={onPhoneNumberChanged}
                  />
                </>
              )}
            </Form>
          </Box>
        </Box>
        <Box direction="row" gap="small" justify="between">
          <SHDButton
            primary
            color="status-critical"
            icon={<Trash />}
            alignSelf="end"
            label={deleteLoading ? '...Deleting' : 'Delete'}
            onClick={onDeletePlayer}
            gap="small"
            disabled={deleteLoading}
          />
          <SHDButton
            primary
            label={saveLoading ? '...Saving' : 'Save'}
            gap="small"
            onClick={onSavePlayer}
            disabled={saveLoading}
          />
        </Box>
      </Box>
    </Layer>
  );

  const newPlayer = {
    nameFirst: '',
    nameLast: '',
    jerseyNum: '',
    invitePhoneNumberList: [],
  };

  const newCoach = {
    nameFirst: '',
    nameLast: '',
    phoneNumber: '',
    isHeadCoach: null,
  };

  if (pageLoading) {
    return <BasicLoader fullPage />;
  }

  return (
    <Box
      justify="center"
      width="large"
      margin="0 auto"
      align="center"
      pad="medium"
      gap="large"
    >
      <Box pad="small" gap="small">
        <Heading level="3">Add and invite your team</Heading>
        <Text size="large">
          Give your team familes custom player overlays and video highlights
          texted directly to them.
        </Text>
      </Box>
      <Box>
        <img
          src={
            !BBSB_SPORTS.includes(teamInfo.attrib_sportType)
              ? '/player_cards.png'
              : '/cp_olay_teaser.png'
          }
          alt="Player overlay preview"
          style={{ maxWidth: '100%' }}
        />
      </Box>
      {error ? <Text color="status-critical">{error}</Text> : null}
      <Box gap="small">
        {coaches.length > 0 ? (
          activeCoaches
        ) : (
          <RosterEmptyCard text="Add a coach to get started." />
        )}
        <SHDButton
          primary
          label="Add coach"
          icon={<Add />}
          onClick={() => {
            setSelectedCoach(newCoach);
            setSelectedCoachPhone('');
          }}
          disabled={coaches.length >= 4}
        />
      </Box>
      <Box gap="small">
        {teamPlayers.length > 0 ? (
          activeRoster
        ) : (
          <RosterEmptyCard text="Add a player to get started." />
        )}
        <SHDButton
          primary
          label="Add player"
          icon={<Add />}
          onClick={() => {
            setSelectedPlayer(newPlayer);
          }}
        />
        <SHDButton
          label="Roll over existing players"
          icon={<Refresh />}
          onClick={() => {
            setShowRolloverPlayer(true);
          }}
        />
      </Box>

      <Box direction="row" justify="between" align="center" fill="horizontal">
        <SHDButton label="Back" onClick={props.previousStep} />
        {teamPlayers.length ? (
          <SHDButton primary label="Next" onClick={validate} />
        ) : (
          <Text
            onClick={() => {
              props.afterValidation(teamInfo);
            }}
            hoverIndicator
            color="text-xweak"
          >
            Skip
          </Text>
        )}
      </Box>
      {selectedPlayer ? playerEditor : null}
      {selectedCoach ? coachEditor : null}
      {showRolloverPlayer && (
        <RolloverPlayerLayer
          existingPlayerIds={teamPlayers
            .filter((player) => player.claimedPlayerId !== '')
            .map((player) => player.claimedPlayerId)}
          targetTeamId={userStore.activeOnboardingTeam}
          onCloseModal={() => setShowRolloverPlayer(false)}
          onUpdatedPlayers={onUpdatedPlayers}
        />
      )}
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Box>
  );
};

export default Roster;
