import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { shd } from '@shd/jslib/models';
import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { ViewerEntitlementStatus } from '../../components/Paywall/paywall.types';
import { Purchases } from '@revenuecat/purchases-js';
// import { strogging } from '@shd/jslib/infra';

const useTeam = (teamId: string) => {
  const [team, setTeam] = useState<shd.Team>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!teamId) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/team/${teamId}`)
      .then((response) => {
        setTeam(response.data.info);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setTeam(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamId]);

  return { team, error, loading };
};

const useTeamLiveAssets = (teamId: string) => {
  const [liveAssets, setLiveAssets] = useState<shd.LiveAsset[]>([]);
  useEffect(() => {
    axios
      .get(`/api/team/${teamId}/live_assets`)
      .then((response) => {
        setLiveAssets(response.data.liveAssets);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [teamId]);

  return liveAssets;
};

const useTeamByIdOrHandle = (teamId: string) => {
  const [team, setTeam] = useState<shd.Team>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!teamId) {
      return;
    }
    setLoading(true);
    if (teamId.includes('TmSh01-')) {
      axios
        .get(`/api/team/${teamId}`)
        .then((response) => {
          setTeam(response.data.info);
          window.history.pushState(
            '',
            '',
            `/live/${response.data.info.nameHandle}`
          );
          setError(null);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          setError(respError);
          setTeam(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .get(`/api/team_handle/${teamId}`)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          return axios.get(`/api/team/${response}`);
        })
        .then((response) => {
          setTeam(response.data.info);
          setError(null);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          setError(respError);
          setTeam(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [teamId]);

  return { team, error, loading };
};

const useIsEntitledToViewTeam = (teamId: string) => {
  const userStore = useContext(UserContext);
  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);

  const [status, setStatus] = useState<ViewerEntitlementStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkViewEntitlement = async () => {
      if (!isMonetizationEnabled) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      // If logged out, show paywall
      if (!userStore.authUser) {
        setStatus(ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT);
        setLoading(false);
        return;
      }

      // check subscriber
      try {
        setLoading(true);

        let userIsEntitled = false;
        const customerInfo =
          await Purchases.getSharedInstance().getCustomerInfo();
        const activeEntitlements = customerInfo.entitlements.active;
        userIsEntitled = activeEntitlements['Pro Access'] !== undefined;

        if (userIsEntitled) {
          setStatus(ViewerEntitlementStatus.ENTITLED);
        } else {
          setStatus(ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
        setStatus(null);
      } finally {
        setLoading(false);
      }

      // check team manager or coach
      const teamIds = userStore.userTeams
        .map((userTeam) => userTeam._id)
        .concat(userStore.coachTeamIds);

      if (teamIds.includes(teamId)) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
      }
    };

    checkViewEntitlement();
  }, [
    teamId,
    isMonetizationEnabled,
    userStore.userTeams,
    userStore.coachTeamIds,
    userStore.authUser,
  ]);

  return { status, loading, error };
};

export {
  useTeam,
  useTeamLiveAssets,
  useTeamByIdOrHandle,
  useIsEntitledToViewTeam,
};
