// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useRef, useEffect, forwardRef } from 'react';
import videoJs from 'video.js';
import playListPlugin from 'videojs-playlist';
import overlayPlugin from 'videojs-overlay';
import ReactDOMServer from 'react-dom/server';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import 'videojs-overlay/dist/videojs-overlay.css';
import './styles.css';
import { analytics } from '../../services/analytics';

const options = {
  muted: true,
  controls: true,
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  preload: 'auto',
  inactivityTimeout: 0,
  fluid: true,
};

const PlaylistPlayer = forwardRef(
  (
    {
      myKey,
      playList,
      setCurrentId,
      shouldAutoplay = true,
      forcePause = false,
    },
    ref
  ) => {
    const [hasInteracted, setHasInteracted] = React.useState(false);

    const videoRef = useRef(null);
    const timerRef = useRef(null);

    const playListRef = useRef(playList);

    useEffect(() => {
      if (forcePause) {
        if (ref && ref.current) {
          ref.current.pause();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forcePause]);

    useEffect(() => {
      playListRef.current = playList;
    }, [playList]);

    const getOverlay = (nextIndex) => {
      const currentItem = playList[nextIndex];
      if (!currentItem) {
        return '';
      }

      const { titleMain, titleSub } = currentItem.sources[0];
      const html = ReactDOMServer.renderToString(
        <div className="info-card">
          <h1 style={{ fontSize: '36px', textAlign: 'center' }}>{titleMain}</h1>
          <h1 style={{ textAlign: 'center' }}>{titleSub}</h1>
        </div>
      );

      return html;
    };

    useEffect(() => {
      videoJs.registerPlugin('playList', playListPlugin);
      videoJs.registerPlugin('overlay', overlayPlugin);

      // eslint-disable-next-line no-param-reassign
      ref.current = videoJs(videoRef.current, options);
      ref.current.playlist(playList);

      ref.current.ready(() => {
        ref.current.playlist.autoadvance(0);
        ref.current.playlist.repeat(true);
      });

      ref.current.on('playlistitem', (e) => {
        const { clipStartOffset } =
          playListRef.current[e.target.player.playlist.currentIndex()]
            .sources[0];

        ref.current.one('canplaythrough', () => {
          ref.current.currentTime(clipStartOffset);
          const { id } =
            playListRef.current[e.target.player.playlist.currentIndex()]
              .sources[0];
          setCurrentId(id);
        });

        clearTimeout(timerRef.current);
      });

      ref.current.on('timeupdate', (e) => {
        const list = ref.current.playlist();
        const currentIndex = e.target.player.playlist
          ? e.target.player.playlist.currentIndex()
          : ref.current.playlist.currentIndex();
        const { clipStopOffset, clipDuration } = list[currentIndex].sources[0];
        if (ref.current.currentTime() >= clipDuration - clipStopOffset) {
          ref.current.playlist.next();
        }
      });

      videoRef.current.onplay = () => {
        setHasInteracted(true);
        analytics.track('sidelineREEL Play', {
          playlistId: myKey,
        });
      };

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
        ref.current.dispose();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function sortToOrder(a, b) {
      const getIndex = (item) =>
        playList.findIndex(
          (sourceItem) => item.sources[0].id === sourceItem.sources[0].id
        );

      return getIndex(a) - getIndex(b);
    }

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      ref.current.playlist.sort(sortToOrder);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playList]);

    const onLoadStart = () => {
      if (ref.current.isFullscreen()) {
        return;
      }

      ref.current.pause();

      ref.current.overlay({
        content: 'Default overlay content',
        // debug: true,
        overlays: [
          {
            content: getOverlay(ref.current.playlist.currentIndex()),
            start: 'show_overlay',
            end: 'hide_overlay', // hack? somehow need a custom event to hide the overlay because ...
            // iOS Safari doesn't like ref.current.play() in onLoadStart
            align: 'top',
          },
        ],
      });

      ref.current.trigger('show_overlay');

      // show overlay card for 3s time duration and then start to play the video
      timerRef.current = setTimeout(() => {
        if (ref && ref.current) {
          ref.current.trigger('hide_overlay');
          ref.current.play();
          if (!hasInteracted && !shouldAutoplay) {
            ref.current.pause();
          }
        }
      }, 3000);
    };

    return (
      <div data-vjs-player key={myKey}>
        <video
          className="video-js vjs-16-9 vjs-theme-fantasy"
          style={{ width: '100%' }}
          playsInline
          muted
          ref={videoRef}
          onLoadStart={onLoadStart}
        />
      </div>
    );
  }
);

export default PlaylistPlayer;
