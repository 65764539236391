// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Heading, TextInput, Button, Box, Text,
} from 'grommet';

import styled from 'styled-components';

export const PlayerHeading = styled(Heading)`
  fontSize: 24px;
  margin: 16px 6px;
`;

export const InputWrapper = styled(Box)`
  margin: 16px 6px;
  position: sticky;
  top: 16px
`;

export const SearchInput = styled(TextInput)`
  padding: 13px;
  height: 48px;
  fontSize: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.global.colors['secondary-1']};
  box-sizing: border-box;
`;

export const SearchButton = styled(Button)`
  background-color: ${({ theme }) => theme.global.colors['tertiary-1']};
  border-radius: 8px;
  padding-inline: 16px;
  margin-left: 18px;
  height: 48px;
`;

export const PromptText = styled(Text)`
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const PromptNote = styled(Text)`
  font-weight: 400;
  font-size: 12px;
`;

export const TextContainer = styled(Box)`
  margin-inline: 6px;
  margin-bottom: 14px;
`;
