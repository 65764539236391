import React from 'react';
import { Box, Button } from 'grommet';
import { Link } from 'react-router-dom';
import {
  TEAM_SETTINGS,
  TEAM_SETTINGS__STREAM_MENU,
} from '../../constants/routes';

interface TeamActionButtonsProps {
  isAdmin: boolean;
  setShowQrModal: (show: boolean) => void;
  screenSize?: string;
}

const TeamActionButtons: React.FC<TeamActionButtonsProps> = ({
  isAdmin,
  setShowQrModal,
  screenSize,
}) => {
  return (
    <Box key="team-action-buttons" justify="start">
      <Box direction="row" justify="end" gap="small" fill="horizontal">
        <Button
          size="small"
          secondary
          label="Share Profile"
          onClick={() => {
            setShowQrModal(true);
          }}
        />
        {isAdmin && (
          <Link
            to={
              screenSize === 'small'
                ? TEAM_SETTINGS
                : TEAM_SETTINGS__STREAM_MENU
            }
          >
            <Button size="small" secondary label="Settings" />
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default TeamActionButtons;
