// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { YouTube } from '@material-ui/icons';
import { Box, Card, DropButton, Text } from 'grommet';
import { Channel, Facebook, Device } from 'grommet-icons';
import React from 'react';
import SHDButton from '../components/SHD/Button';
import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useIsFeatureEnabled } from './utils';
import { UserContext } from '../services/Session/context';

const renderWatchOptions = (ytCdnUrl, fbCdnUrl) => (
  <Box pad="medium" gap="small">
    <SHDButton
      primary
      color="#ff0000"
      icon={<YouTube color="#FF0000" />}
      label="Watch LIVE"
      href={ytCdnUrl}
      target="_blank"
    />
    <SHDButton
      primary
      color="#3b5998"
      icon={<Facebook />}
      label="Watch LIVE"
      href={fbCdnUrl}
      target="_blank"
    />
  </Box>
);

const LiveAssetCard = ({ liveAsset }) => {
  const userStore = React.useContext(UserContext);
  const hlsViewerEnabled = useIsFeatureEnabled('hls_viewer', userStore);
  return (
    <Card
      direction="row"
      align="center"
      gap="large"
      background="dark-1"
      pad="medium"
    >
      <Text weight="bold" size="large" color="white">
        Playing NOW
      </Text>
      {liveAsset.yt && liveAsset.fb ? (
        <>
          <DropButton
            dropContent={renderWatchOptions(
              liveAsset.yt.cdnUrl,
              liveAsset.fb.cdnUrl
            )}
            dropProps={{ align: { top: 'bottom' } }}
            primary
            color="#ff0000"
            icon={<Channel />}
            label="Watch LIVE"
          />
          {liveAsset.hlslive && hlsViewerEnabled && (
            <Link
              to={`${ROUTES.LIVESTREAM}?hlsurl=${liveAsset.hlslive.liveM3u8Url}`}
            >
              <SHDButton
                margin={{ left: 'small' }}
                primary
                color="#ff0000"
                icon={<Device color="light-1" />}
                label="Watch LIVE"
                target="_blank"
              />
            </Link>
          )}
        </>
      ) : (
        <Box gap="small">
          {liveAsset.yt && (
            <SHDButton
              primary
              color="#ff0000"
              icon={<YouTube color="#FF0000" />}
              label="Watch LIVE"
              href={liveAsset.yt.cdnUrl}
              target="_blank"
            />
          )}
          {liveAsset.fb && (
            <SHDButton
              primary
              color="#3b5998"
              icon={<Facebook />}
              label="Watch LIVE"
              href={liveAsset.fb.cdnUrl}
              target="_blank"
            />
          )}
          {liveAsset.hlslive && hlsViewerEnabled && (
            <Link
              to={`${ROUTES.LIVESTREAM}?hlsurl=${liveAsset.hlslive.liveM3u8Url}`}
            >
              <SHDButton
                primary
                color="tertiary-0"
                icon={<Device color="light-1" />}
                label="Watch LIVE"
                target="_blank"
              />
            </Link>
          )}
        </Box>
      )}
    </Card>
  );
};

export default LiveAssetCard;
