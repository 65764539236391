// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Text } from 'grommet';
import { Drag, Next } from 'grommet-icons';

const DragAndDropLineup = ({
  setLineup = () => {},
  onSelectPlayer,
  lineup = [],
  currentBatterId,
}) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(lineup);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLineup(items);
  };

  return (
    <Box
      direction="row"
      gap="medium"
      justify="start"
      height={{ min: 'initial' }}
    >
      <Box direction="column" gap="small">
        {lineup.map((_, index) => (
          <Box key={index} height="48px" justify="center">
            <Text weight="bold">{index + 1}</Text>
          </Box>
        ))}
      </Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="starters">
          {(provided) => (
            <Box
              align="center"
              width="100%"
              className="starters"
              gap="small"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ maxWidth: '400px' }}
            >
              {lineup.map(({ displayName, _id }, index) => (
                <Draggable
                  className="draggable-card"
                  style={{ borderStyle: 'solid' }}
                  key={_id}
                  draggableId={_id}
                  index={index}
                >
                  {(innerProvided) => (
                    <Box
                      background={
                        _id === currentBatterId ? 'secondary-1' : 'secondary-7'
                      }
                      pad="medium"
                      width="100%"
                      round="8px"
                      justify="between"
                      direction="row"
                      height="48px"
                      ref={innerProvided.innerRef}
                      align="center"
                      onClick={
                        onSelectPlayer
                          ? () => onSelectPlayer({ displayName, _id })
                          : () => {}
                      }
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                    >
                      <Box gap="medium" direction="row" align="center">
                        <Text>{displayName}</Text>
                        {onSelectPlayer ? (
                          <Next
                            size="small"
                            color={
                              _id === currentBatterId
                                ? 'secondary-7'
                                : 'secondary-1'
                            }
                          />
                        ) : null}
                      </Box>
                      <Drag
                        color={
                          _id === currentBatterId
                            ? 'secondary-7'
                            : 'secondary-1'
                        }
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default DragAndDropLineup;
