// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 25px;
`;

export default function CloseButton({ onClick }) {
  return (
    <StyledButton onClick={onClick}>
      <FontAwesomeIcon icon={faXmark} color="black" />
    </StyledButton>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  onClick: null,
};
