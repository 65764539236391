import React from 'react';
import { Text, Button, Box, Image } from 'grommet';
import { buttonStyle, overlayCard } from './dkplayerRecommendations.styles';
import {
  onMixpanelActivity,
  MixpanelActivityEvents,
} from './dkplayerRecommendations.mixpanel';
import { GetDKCardProps } from './dkplayerRecommentdations.types';

const GetDKCard: React.FC<GetDKCardProps> = ({
  appStoreLink,
  sport,
  gradYear,
}) => {
  const handleOnClick = () => {
    onMixpanelActivity(MixpanelActivityEvents.GET_DK_CARD, {
      sport: sport,
      gradYear: gradYear,
    });
  };

  return (
    <Box
      id="get-dk-card"
      round="medium"
      direction="row"
      style={{ ...overlayCard, position: 'fixed' }}
    >
      <Box direction="row">
        <Box id="get-dk-icon">
          <Box
            round={true}
            overflow="hidden"
            style={{ height: '3.5rem', width: '3.5rem' }}
          >
            <Image src={'/dk_icon.png'} />
          </Box>
        </Box>
        <Box id="get-dk-text" alignSelf="center" margin={{ left: '.5rem' }}>
          <Text weight="bold">Diamond Kinetics</Text>
          <Text size="medium" style={{ opacity: '0.5' }}>
            Take Full Lessons
          </Text>
        </Box>
      </Box>
      <Box id="get-dk-button" alignSelf="center">
        <Button
          href={appStoreLink}
          onClick={handleOnClick}
          style={{ ...buttonStyle, backgroundColor: '#4FFF42' }}
          label={'Get'}
        />
      </Box>
    </Box>
  );
};

export default GetDKCard;
