// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Heading,
  Image,
  Paragraph,
  Card,
  CardHeader,
  Text,
  Anchor,
  CardBody,
  Button,
} from 'grommet';

import { withRouter } from 'react-router-dom';

import { observer } from 'mobx-react';
import Cookies from 'universal-cookie';
import withLayout from '../../helpers/withLayout';
import { BasicLoader } from '../../helpers/Loaders';
import SignInMethod from './SignInMethod';
import { useQuery } from '../../helpers/utils';
import {
  isFacebookBrowser,
  isMobile,
  isiOS,
} from '../../helpers/browserDetect';
import { withFirebase } from '../../services/Firebase';
import * as ROUTES from '../../constants/routes';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import { theFirebase } from '../../services/Firebase/firebase';
import { analytics } from '../../services/analytics';

const cookies = new Cookies();

const SignUpPage = observer((props) => {
  const query = useQuery();
  const aesId = query.get('aesId');

  const userStore = useContext(UserContext);

  const [aesTeam, setAesTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [partnerReferrer, setPartnerReferrer] = useState(null);
  const enableAppleSignIn = !isMobile.any() || isiOS();
  const { googleProvider, facebookProvider, appleProvider } =
    theFirebase().getAllAuthProviders();

  const trackForPros = () => {
    axios
      .post('/api/tgs', {
        referrerUserId: query.get('from'),
        url: window.location.href,
      })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    axios
      .get(`/api/referral_code/${cookies.get('referralCode')}`)
      .then((response) => {
        setPartnerReferrer(response.data);
      });
  }, []);

  useEffect(() => {
    if (query.get('flyer') && query.get('from')) {
      trackForPros();
    }

    props.firebase.logAnalyticsEvent('get_started_view');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (aesId) {
      axios
        .get(`/api/3p/team/${aesId}`, { params: { source: 'aes' } })
        .then((response) => {
          setAesTeam(response.data);
        });
    }
  }, [aesId]);

  useEffect(() => {
    if (userStore.referrerCampaign) {
      props.firebase.logAnalyticsEvent('qr_scanned', {
        campaign: userStore.referrerCampaign,
        url: window.location.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.referrerCampaign]);

  if (loading) {
    return <BasicLoader fullPage size={200} />;
  }

  if (isFacebookBrowser()) {
    return (
      <Box>
        <Image src="" />
      </Box>
    );
  }

  const facebookBrowserPrompt = (
    <Box background="white" pad="large" gap="small" fill="vertical">
      <Text weight="bold">
        Please open this page up in your default web browser.
      </Text>
      {isiOS() ? (
        <Box gap="medium">
          <Text>
            Press the share button at the bottom of the screen. Then press
            &#34;Open in Safari&#34;
          </Text>
          <Image
            width="large"
            fit="contain"
            src="ios_fb_browser_tutorial.png"
          />
        </Box>
      ) : (
        <Box gap="medium">
          <Text>
            Press the three dots at the top of the screen. Then press &#34;Open
            in Chrome&#34;
          </Text>
          <Image
            width="large"
            fit="contain"
            src="android_fb_browser_tutorial.png"
          />
        </Box>
      )}
    </Box>
  );

  const renderReferrerInfo = () => {
    if (partnerReferrer) {
      return (
        <Box
          align="center"
          margin={{ horizontal: 'auto', bottom: 'large' }}
          style={{ maxWidth: '400px' }}
        >
          <Card pad="medium" background="white" gap="small">
            <Box direction="row" gap="small">
              <Image src={partnerReferrer.imageUrl} width="100px" />
              <Heading level={4}>
                {`${partnerReferrer.title} invited you to start livestreaming!`}
              </Heading>
            </Box>
            <Text>{partnerReferrer.subtitle}</Text>
          </Card>
        </Box>
      );
    }
    if (userStore.referrerUser) {
      return (
        <Box align="center" margin={{ bottom: 'large' }}>
          <Text weight="bold">{`${userStore.referrerUser.nameFirst} ${userStore.referrerUser.nameLast} invited you to livestream!`}</Text>
        </Box>
      );
    }
    return null;
  };

  const mainDiv = (
    <Box gap="medium" margin={{ vertical: 'large' }}>
      {renderReferrerInfo()}
      <Card
        pad="large"
        gap="small"
        background="white"
        style={{ maxWidth: '400px' }}
        margin={{ horizontal: 'auto' }}
        fill="vertical"
      >
        <CardHeader direction="column" align="start" pad="small">
          <Heading level={3}>
            {aesTeam
              ? `Welcome to sidelineHD, ${aesTeam.name}!`
              : 'Welcome to sidelineHD 👋'}
          </Heading>
        </CardHeader>
        <CardBody pad="small" gap="medium" height="100%">
          <Text textAlign="center" size="large">
            {aesId
              ? 'When you sign up for a sidelineHD account, we will automatically import your team!'
              : ''}
          </Text>
          <SignInMethod
            label="Sign up with Google"
            provider={googleProvider}
            toggleLoading={setLoading}
            errorHandler={setError}
            onSuccess={(userData) => {
              analytics.identify(userData.userId, {
                socialSource: 'Google',
              });
              analytics.track('User Sign Up', {
                socialSource: 'Google',
                importSource: 'None',
              });

              props.firebase.logAnalyticsEvent('sign_up_success', {
                method: 'self',
                provider: 'google',
                user_id: userData.userId,
              });

              //   userStore.setPermissionToCreateTeam(true);
              props.history.push({
                pathname: ROUTES.ONBOARDING,
                state: {
                  importSource: userData.importSource,
                  aesId,
                },
              });

              cookies.remove('referralCode');
            }}
            postParams={{
              sign_up: 1,
              //   import_source: importSource,
            }}
          />
          <SignInMethod
            label="Sign up with Facebook"
            provider={facebookProvider}
            toggleLoading={setLoading}
            errorHandler={setError}
            onSuccess={(userData) => {
              analytics.identify(userData.userId, {
                socialSource: 'Facebook',
              });
              analytics.track('User Sign Up', {
                socialSource: 'Facebook',
                importSource: 'None',
                sport: 'bbsb',
              });
              props.firebase.logAnalyticsEvent('sign_up_success', {
                method: 'self',
                provider: 'facebook',
                user_id: userData.userId,
              });

              //   userStore.setPermissionToCreateTeam(true);
              props.history.push({
                pathname: ROUTES.ONBOARDING,
                state: {
                  importSource: userData.importSource,
                  aesId,
                },
              });

              cookies.remove('referralCode');
            }}
            postParams={{
              sign_up: 1,
              //   import_source: importSource,
            }}
          />
          {enableAppleSignIn && (
            <SignInMethod
              label="Sign up with Apple"
              provider={appleProvider}
              toggleLoading={setLoading}
              errorHandler={setError}
              onSuccess={(userData) => {
                analytics.identify(userData.userId, {
                  socialSource: 'Apple',
                });
                analytics.track('User Sign Up', {
                  socialSource: 'Apple',
                  importSource: 'None',
                });
                props.firebase.logAnalyticsEvent('sign_up_success', {
                  method: 'self',
                  provider: 'apple',
                  user_id: userData.userId,
                });

                //   userStore.setPermissionToCreateTeam(true);
                props.history.push({
                  pathname: ROUTES.ONBOARDING,
                  state: {
                    importSource: userData.importSource,
                    aesId,
                  },
                });

                cookies.remove('referralCode');
              }}
              postParams={{
                sign_up: 1,
                //   import_source: importSource,
              }}
            />
          )}
          {!!error && (
            <Paragraph color="status-error" margin="medium">
              {error.message || error.toString()}
            </Paragraph>
          )}
          <Box flex={{ grow: 1, shrink: 0 }} gap="small">
            <Box height="2px" background="dark-5" />
            <Text size="small">Looking for your existing team or player?</Text>
            <Box width="small">
              <Button
                color="primary-1"
                label={<Text size="small">Search</Text>}
                onClick={() => {
                  cookies.remove('referralCode');
                  props.firebase.logAnalyticsEvent('find_existing_team_player');
                  props.history.push(ROUTES.TEAM_SEARCH);
                }}
              />
            </Box>
          </Box>
          <Box flex={{ grow: 1, shrink: 0 }} gap="small">
            <Box height="2px" background="dark-5" />
            <Text size="small">
              If you already have a sidelineHD account, please sign in at{' '}
              <Anchor
                size="small"
                href="https://sidelinehd.com/signin"
                label="https://sidelinehd.com/signin"
              />
            </Text>
          </Box>
        </CardBody>
      </Card>
      <Card
        pad="large"
        gap="small"
        elevation="none"
        style={{ maxWidth: '400px' }}
        margin="auto"
      >
        <Box gap="small">
          <Text color="dark-3">
            One-click import your team and roster after signing up from...
          </Text>
          <Box gap="xsmall">
            <Box height="40px">
              <Image fill fit="contain" src="aes-logo.png" />
            </Box>
            <Box height="40px">
              <Image fit="contain" src="usssa-logo.png" />
            </Box>
            <Box height="40px">
              <Image fit="contain" src="pg-logo.png" />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );

  return (
    <Box background="primary-8">
      {isFacebookBrowser() ? facebookBrowserPrompt : mainDiv}
    </Box>
  );
});

export default withRouter(withLayout(withFirebase(SignUpPage)));
