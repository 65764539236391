// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box } from 'grommet';
import {
  stepOneMarkdown,
  stepTwoMarkdown,
  stepThreeMarkdown,
  stepFourMarkdown,
  stepFiveMarkdown,
  stepSixMarkdown,
  stepOneMarkdownAlt,
  stepTwoMarkdownAlt,
} from './scoringTutorialMarkdown';

const MyImage = (props) => (
  <img alt={props.alt} src={props.src} style={{ maxWidth: '100%' }} />
);

const renderers = {
  // This custom renderer changes how images are rendered
  // we use it to constrain the max width of an image to its container
  img: MyImage,
};

const StepOne = () => (
  <Box width={{ max: 'xlarge' }}>
    <ReactMarkdown
      id="asdf"
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepOneMarkdown}
    </ReactMarkdown>
  </Box>
);

const StepTwo = () => (
  <Box pad={{ left: 'large', right: 'large' }} width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepTwoMarkdown}
    </ReactMarkdown>
  </Box>
);

const StepThree = () => (
  <Box pad={{ left: 'large', right: 'large' }} width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepThreeMarkdown}
    </ReactMarkdown>
  </Box>
);

const StepFour = () => (
  <Box pad={{ left: 'large', right: 'large' }} width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepFourMarkdown}
    </ReactMarkdown>
  </Box>
);

const StepFive = () => (
  <Box pad={{ left: 'large', right: 'large' }} width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepFiveMarkdown}
    </ReactMarkdown>
  </Box>
);

const StepSix = () => (
  <Box pad={{ left: 'large', right: 'large' }} width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepSixMarkdown}
    </ReactMarkdown>
  </Box>
);

const StepOneAlt = () => (
  <Box width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepOneMarkdownAlt}
    </ReactMarkdown>
  </Box>
);

const StepTwoAlt = () => (
  <Box width={{ max: 'xlarge' }}>
    <ReactMarkdown
      components={renderers}
      urlTransform={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.PUBLIC_URL}${uri}`
      }
    >
      {stepTwoMarkdownAlt}
    </ReactMarkdown>
  </Box>
);

const scoringTutorialSteps = [
  <StepOne key="StepOne" />,
  <StepTwo key="StepTwo" />,
  <StepThree key="StepThree" />,
  <StepFour key="StepFour" />,
  <StepFive key="StepFive" />,
  <StepSix key="StepSix" />,
];

const scoringTutorialStepsAlt = [
  <StepOneAlt key="StepOneAlt" />,
  <StepTwoAlt key="StepTwoAlt" />,
];

export { scoringTutorialSteps, scoringTutorialStepsAlt };
