interface IErrorDetails {
  [key: string]: string;
}

interface IErrorResponse {
  response?: {
    data?: {
      message: string;
      details?: IErrorDetails;
    };
  };
  message: string;
}

class ApiError extends Error {
  message: string;
  details: IErrorDetails | undefined;
  constructor(error: IErrorResponse | string) {
    if (typeof error === 'string') {
      super(error);
      this.message = error;
      return;
    }
    super(error.message);

    if (error.response && error.response.data) {
      this.message = error.response.data.message;
    } else {
      this.message = error.toString();
    }

    this.details = error.response?.data?.details;
  }
}

export default ApiError;
