import React from 'react';
import { Text, Button, Box, Avatar } from 'grommet';
import { stickyFooter, buttonStyle } from './sidelineLive.styles';
import { getTeamAgeType } from '../../helpers/utils';
import { SidelineLiveTeamCardProps } from './sidelineLive.types';

export const SidelineLiveTeamCard: React.FC<SidelineLiveTeamCardProps> = ({
  handleBackToTeamPage,
  team,
}) => {
  const a = (team && team.age) || 0;
  const l = (team && team.ageLevel) || '';
  const teamLevel = `${getTeamAgeType(a, l)} ${team?.sport} Team`;

  return (
    <Box
      id="live-team-card"
      direction="row"
      style={{ ...stickyFooter, position: 'fixed' }}
    >
      <Box direction="row">
        <Box id="live-team-logo">
          {team?.teamLogo?.urlT150 ? (
            <Avatar src={team?.teamLogo?.urlT150} />
          ) : (
            <Avatar background="gray-2" size="medium"></Avatar>
          )}
        </Box>
        <Box id="live-team-info" alignSelf="center" margin={{ left: '.5rem' }}>
          <Text weight="bold">{team?.teamName}</Text>
          <Text size="medium" style={{ opacity: '0.5' }}>
            {teamLevel}
          </Text>
        </Box>
      </Box>
      <Box id="live-team-page-button" alignSelf="center">
        <Button
          style={buttonStyle}
          label="View"
          onClick={handleBackToTeamPage}
        />
      </Box>
    </Box>
  );
};

export default SidelineLiveTeamCard;
