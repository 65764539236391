// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* global gapi */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Text, Box, Anchor, Button, Layer, Image } from 'grommet';
import { Close, Connect, Youtube } from 'grommet-icons';
import * as Sentry from '@sentry/browser';

import axios from '../../../helpers/axios';
import SHDButton from '../../../components/SHD/Button';

import {
  SettingsStyled,
  ButtonLinkStyled,
  LinkTitle,
  LinkTextWrapper,
} from './style';

import SuccessIcon from './SuccessIcon';
import { analytics } from '../../../services/analytics';

const YoutubeSettings = ({
  teamId,
  onComplete = () => {},
  onUpdatedLinkedChannel = () => {},
  autoLink,
}) => {
  const [, setSignedIn] = useState(false);
  const [linkedChannel, setLinkedChannel] = useState(null);
  const [error, setError] = useState(null);
  const [showIntroModal, setShowIntroModal] = useState(false);

  const [auth2, setAuth2] = useState(null);

  const updateLinkedChannel = (channel) => {
    setLinkedChannel(channel);
    onUpdatedLinkedChannel(channel);
  };

  const onSuccess = (response) => {
    if ('code' in response) {
      axios
        .post('/api/youtube_auth', {
          ...response,
          teamId,
          redirectUri: window.location.origin,
        })
        .then((res) => {
          analytics.track('Streaming Destination Linked', {
            destinationType: 'YouTube',
            teamId,
          });

          updateLinkedChannel(res.data);
          onComplete();
          if (res.data) {
            setSignedIn(true);
          }
          setError(null);
        })
        .catch((e) => {
          if (e && e.response) {
            setError(e.response.data);
          }
        });
    } else if (response.error !== 'popup_closed_by_user') {
      setError(response.error);
    } else {
      axios
        .get('/api/youtube_auth', {
          params: { teamId },
        })
        .then((res) => {
          updateLinkedChannel(res.data);
          setSignedIn(true);
          setError(null);
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  };

  useEffect(() => {
    axios
      .get('/api/youtube_auth', { params: { teamId } })
      .then((res) => {
        const channelInfo = res.data;

        if (channelInfo) {
          updateLinkedChannel(channelInfo);
          setSignedIn(true);
        } else {
          updateLinkedChannel(null);

          if (autoLink) {
            axios
              .post('/api/youtube_auth', {
                autoLink: 1,
                teamId,
              })
              .then((response) => {
                setSignedIn(true);
                updateLinkedChannel(response.data);

                analytics.track('Streaming Destination Linked', {
                  destinationType: 'YouTube',
                  teamId,
                  autoLink: true,
                });
              });
          }
        }
        setError(null);
      })
      .catch((e) => {
        if (e.response && e.response.data) {
          setError(e.response.data);
        }
        setSignedIn(true);
        Sentry.captureException(e);
      });

    window.gapi.load('auth2', () => {
      setAuth2(gapi.auth2);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // hack for google chrome incognito not working for youtube sign in
    // https://github.com/google/google-api-javascript-client/issues/783
    // https://github.com/pwa-builder/PWABuilder/issues/3286#issuecomment-1219798658
    window.addEventListener('message', (e) => {
      let message;
      try {
        message = JSON.parse(e.data);
      } catch (err) {
        return;
      }

      if ('params' in message && message.params.type === 'authResult') {
        const response = {
          code: message.params.authResult.code,
        };

        onSuccess(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = () => {
    const config = {
      response_type: 'code',
      scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      access_type: 'offline',
      prompt: 'consent',
      cookiePolicy: 'single_host_origin',
    };

    auth2.authorize(config, onSuccess);
  };

  const onLinkYoutubeClick = () => {
    analytics.track('Link Streaming Destination Clicked', {
      destinationType: 'YouTube',
      teamId,
    });
    signIn();
    setShowIntroModal(false);
  };

  const onUnlinkYoutubeClick = () => {
    axios.delete('/api/youtube_auth', { data: { teamId } });
    updateLinkedChannel(null);
    setSignedIn(false);
    setError(null);
  };

  const errorDiv = error && error.message && (
    <Box direction="row" align="center" pad="small">
      <Box direction="column">
        <Text>{error.message}</Text>
        {error.link ? (
          <Anchor href={error.link} target="_blank">
            Learn More
          </Anchor>
        ) : null}
      </Box>
    </Box>
  );

  const onCloseIntroModal = () => {
    setShowIntroModal(false);
  };

  const renderModal = () => {
    const tempHeader = (
      <Box margin="small" direction="row" justify="between">
        <Text alignSelf="center" size="large" weight="bold">
          Link to YouTube
        </Text>
        <Button icon={<Close />} onClick={onCloseIntroModal} />
      </Box>
    );

    return (
      <Layer
        onClickOutside={onCloseIntroModal}
        onEsc={onCloseIntroModal}
        style={{ overflowY: 'auto', overflowX: 'hidden' }} // TODO: overflowX is a hack
        pad={{ bottom: '72px' }}
      >
        <Box
          gap="small"
          margin="small"
          width="large"
          style={{ display: 'block' }}
        >
          {tempHeader}
          <Box
            margin={{ horizontal: 'small', bottom: 'medium' }}
            overflow="scroll"
            gap="small"
          >
            <Box
              direction="row"
              justify="center"
              gap="small"
              align="center"
              margin={{ bottom: 'medium' }}
            >
              <Box width="44px">
                <Image src="/logo512.png" fit="contain" />
              </Box>
              <Connect size="28px" />
              <Youtube size="44px" color="#FF0000" />
            </Box>
            <Text>Stream to your YouTube channel.</Text>
            <Text>
              You will be redirected to YouTube to login when you click the
              button below.
            </Text>
          </Box>
          <Box direction="row" align="center">
            <SHDButton
              primary
              fill="horizontal"
              color="light-2"
              icon={<Youtube color="#FF0000" />}
              label="Link YouTube"
              onClick={onLinkYoutubeClick}
            />
          </Box>
        </Box>
      </Layer>
    );
  };

  return (
    <SettingsStyled>
      {errorDiv}
      <ButtonLinkStyled>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Image src="youtubeIcon.svg" width="40px" height="40px" />
          <LinkTextWrapper>
            <LinkTitle>YouTube</LinkTitle>
            {linkedChannel && (
              <Text>{linkedChannel.channelInfo.snippet.title}</Text>
            )}
          </LinkTextWrapper>
        </Box>
        <SHDButton
          style={{
            minWidth: '75px',
            width: 'min-content',
            height: '40px',
            border: linkedChannel
              ? '1px solid transparent'
              : '1px solid #6750A4',
            color: linkedChannel ? '#fff' : '#6750A4',
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: 500,
            backgroundColor: linkedChannel ? '#418310' : '#FFF',
          }}
          primary
          fill="horizontal"
          icon={linkedChannel ? <SuccessIcon /> : null}
          color="white"
          label={linkedChannel ? 'Linked' : 'Link'}
          onClick={() => {
            if (!linkedChannel) {
              setShowIntroModal(true);
            } else {
              onUnlinkYoutubeClick();
            }
          }}
        />
      </ButtonLinkStyled>
      {showIntroModal && renderModal()}
    </SettingsStyled>
  );
};

export default YoutubeSettings;
