import { TrackEvent } from './sidelineLive.analytics';

export interface SidelineLiveBetaProps {
  trackEvent: TrackEvent;
}

export interface TeamCard {
  teamName: string | undefined;
  teamLogo: { urlT150: string } | undefined;
  sport: string | undefined;
  age: number | undefined;
  ageLevel: string | undefined;
}
export interface SidelineLiveTeamCardProps {
  handleBackToTeamPage: () => void;
  team: TeamCard | undefined;
}

export interface SidelineLiveButtonProps {
  teamId: string;
  trackEvent: TrackEvent;
}

export interface SidelineLiveGameInfoProps {
  teamId: string;
  teamName: string | undefined;
  handleNavToGamePage: (gameId: string) => void;
  setGameEnded: (gameEnded: boolean) => void;
  trackEvent: TrackEvent;
}

export interface SidelineLiveGameInfoNavProps {
  gameId: string | undefined;
  handleNavToGamePage: (gameId: string) => void;
  isLive: boolean;
  trackEvent: TrackEvent;
}

export interface SidelineLiveLinksProps {
  teamId: string;
}

export enum CameraStatus {
  Live = 'LIVE',
  Down = 'DOWN',
  Wait = 'WAIT_RECONNECT',
}

export enum EndStatus {
  Ended = 'ENDED',
  Processing = 'PROCESSING',
  Available = 'AVAILABLE',
}

type Mode = CameraStatus | EndStatus | 'empty';

export interface SidelineLiveStatusScreenProps {
  body?: string;
  mode: Mode;
  title?: string;
}

export type Props = SidelineLiveStatusScreenProps;

export interface SidelineLiveStatusProps {
  isPlayable: boolean;
  camera_status: CameraStatus | string;
  isGameEnded: boolean;
}

export interface LiveOnPlatform {
  camera_isUp?: boolean;
  camera_status?: string;
  hls_link: string | undefined;
  hls_isUrlValid?: boolean;
  isPlayable?: boolean;
  isAdvertise?: boolean;
}

export interface LiveOnPlatformProps extends LiveOnPlatform {
  trackEvent: TrackEvent;
  isGameEnded?: boolean;
}

export interface LiveConfig {
  isAppLive: boolean;
  isAppVod: boolean;
  isWebclientLive: boolean;
  isWebclientVod: boolean;
}

type RedirectTo = 'team' | 'game' | 'none';
export interface LivePermalink {
  teamGameId: string;
  redirectTo: RedirectTo;
}

export interface LiveGameInfo {
  teamGameId: string;
  isGameEnded: boolean;
  scoreTsStart: number;
  scoreOurName: string;
  scoreOpponentName: string;
  scoreHomeIsKnown: boolean;
  scoreWeAreHome: boolean;
}

export enum pollIntervals {
  THIRTY_SECONDS = 3000,
  TWO_MINUTES = 12000,
  SIXTY_SECONDS = 6000,
}
