/* global gapi */

import React, { useState, useEffect, useContext } from 'react';
import {
  Text,
  Box,
  Card,
  Avatar,
  Anchor,
  Button,
  Layer,
  Image,
  TextInput,
  TextArea,
  Select,
  Accordion,
  AccordionPanel,
  Grommet,
  ThemeType,
} from 'grommet';
import { Close, Connect, Youtube } from 'grommet-icons';
import * as Sentry from '@sentry/browser';

import axios from '../../helpers/axios';
import SHDButton from '../../components/SHD/Button';
import { useTeam } from '../TeamPage/teamHooks';
import RoundedToggle from '../../components/RoundedToggle';
import { UserContext } from '../../services/Session';
import { GROMMETTHEME } from '../../constants/grommetTheme';
import { analytics } from '../../services/analytics';
import { shd } from '@shd/jslib/models';
import { AppHostContext } from '../../services/appHost';
import { errorUtil, strogging } from '@shd/jslib/infra';
import styled from 'styled-components';

interface PrivacyModalProps {
  onUpdateUnlistedSetting: (unlisted: boolean) => void;
  onClose: () => void;
}
const PrivacyModal: React.FC<PrivacyModalProps> = ({
  onUpdateUnlistedSetting,
  onClose,
}) => {
  const userStore = useContext(UserContext);
  const team = userStore.selectedTeam;

  const [loading, setLoading] = useState(false);

  return (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <Box
        direction="row"
        pad="medium"
        align="center"
        justify="between"
        gap="medium"
      >
        <Box width="24px" />
        <Text weight="bold">Change Privacy Settings</Text>
        <Close size="24px" onClick={onClose} />
      </Box>
      <Box pad={{ horizontal: 'medium' }} gap="medium">
        <Text>
          {`Future YouTube broadcasts for ${team?.nameMed} will start as public and are set unlisted when the broadcast finishes.`}
        </Text>
        <Text>
          The channel manager can change the privacy setting of any broadcast at
          any time on YouTube.
        </Text>
        <Box>
          <Text>Unlisted broadcasts:</Text>
          <Text>{` • Won't be searchable on YouTube`}</Text>
          <Text>
            {` • Only team members that have logged in to sidelineHD (families, coaches, team managers) can see these links`}
          </Text>
        </Box>
        <Box margin={{ bottom: 'large' }} gap="medium">
          <Button primary label="Keep broadcasts public" onClick={onClose} />
          <Button
            disabled={loading}
            secondary
            label="Auto-set to unlisted after broadcast finishes"
            onClick={() => {
              setLoading(true);
              onUpdateUnlistedSetting(true);
            }}
          />
        </Box>
      </Box>
    </Layer>
  );
};

interface VideoSettingsProps {
  teamId: string | undefined;
  onClose: () => void;
}
const VideoSettings: React.FC<VideoSettingsProps> = ({ teamId, onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [privacy, setPrivacy] = useState('public');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { team } = useTeam(teamId ?? '');

  const privacyOptions = [
    { label: 'Public', value: 'public' },
    { label: 'Public (not listed)', value: 'unlisted' },
    { label: 'Private', value: 'private' },
  ];

  useEffect(() => {
    if (team) {
      axios.get('/api/youtube_auth', { params: { teamId } }).then((res) => {
        const channelInfo = res.data;

        setLoading(false);

        if (channelInfo) {
          const defaultTeamTitle = team.nameMed;
          const defaultDescription = `See highlights at https://sidelineHD.com/${team.nameHandle} \n\n#sidelineHD #${team.attrib_sportType} #sidelineHDLIVE`;
          setTitle(channelInfo.nextTitle || defaultTeamTitle);
          setDescription(channelInfo.nextDescription || defaultDescription);
          setPrivacy(channelInfo.nextPrivacy || 'public');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const onSave = () => {
    setLoading(true);
    axios
      .put('/api/youtube_auth', {
        data: {
          nextTitle: title,
          nextDescription: description,
          nextPrivacy: privacy,
        },
        teamId,
      })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
        setError(e.response.data.message);
      });
  };

  return (
    <Layer modal onClickOutside={onClose}>
      <Box margin="small">
        <Box margin="small" direction="row" justify="between">
          <Text alignSelf="center" size="large" weight="bold">
            YouTube Settings
          </Text>
          <Close onClick={onClose} />
        </Box>
        <Box>
          {error && <Text color="status-error">{error}</Text>}
          {loading ? (
            <Text>Loading...</Text>
          ) : (
            <Box gap="small">
              <Text>For your next broadcast...</Text>
              <TextInput
                placeholder="Title"
                value={title}
                onChange={(e) => {
                  setError(null);
                  setTitle(e.target.value);
                }}
              />
              <Box height="200px">
                <TextArea
                  placeholder="Description"
                  value={description}
                  onChange={(e) => {
                    setError(null);
                    setDescription(e.target.value);
                  }}
                  fill
                />
              </Box>
              <Box>
                <Text>Visibility of the video:</Text>
                <Select
                  options={privacyOptions}
                  value={privacy}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  onChange={({ option }) => setPrivacy(option.value)}
                />
              </Box>
              <Box margin={{ top: 'mediium' }}>
                <Button
                  primary
                  color="tertiary-1"
                  label="Save"
                  onClick={onSave}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layer>
  );
};

interface AuthResponse {
  error?: string;
  code?: string;
  scope?: string;
}

interface YoutubeSettingsProps {
  teamId: string | undefined;
  onComplete?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdatedLinkedChannel?: (channel: any) => void;
  autoLink?: boolean;
}
const YoutubeSettings: React.FC<YoutubeSettingsProps> = ({
  teamId,
  onComplete = () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onUpdatedLinkedChannel = (channel = null) => {},
  autoLink = false,
}) => {
  const [isSignedIn, setSignedIn] = useState(false);
  const [linkedChannel, setLinkedChannel] = useState<shd.PublishYT | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showVideoSettings, setShowVideoSettings] = useState(false);

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const [auth2, setAuth2] = useState<typeof gapi.auth2 | null>(null);

  const updateLinkedChannel = (channel: shd.PublishYT | null) => {
    setLinkedChannel(channel);
    onUpdatedLinkedChannel(channel);
  };

  const onSuccess = (response: AuthResponse) => {
    if ('code' in response) {
      axios
        .post('/api/youtube_auth', {
          ...response,
          teamId,
          redirectUri: window.location.origin,
        })
        .then((res) => {
          analytics.track('Streaming Destination Linked', {
            destinationType: 'YouTube',
            teamId,
          });

          updateLinkedChannel(res.data);
          onComplete();
          if (res.data) {
            setSignedIn(true);
          }
          setError(null);
        })
        .catch((e) => {
          if (e && e.response) {
            setError(e.response.data);
          }
        });
    } else if (response.error !== 'popup_closed_by_user') {
      setError(response.error ?? null);
    } else {
      axios
        .get('/api/youtube_auth', {
          params: { teamId },
        })
        .then((res) => {
          updateLinkedChannel(res.data);
          setSignedIn(true);
          setError(null);
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  };

  useEffect(() => {
    axios
      .get('/api/youtube_auth', { params: { teamId } })
      .then((res) => {
        const channelInfo = res.data;

        if (channelInfo) {
          updateLinkedChannel(channelInfo);
          setSignedIn(true);
        } else {
          updateLinkedChannel(null);

          if (autoLink) {
            axios
              .post('/api/youtube_auth', {
                autoLink: 1,
                teamId,
              })
              .then((response) => {
                setSignedIn(true);
                updateLinkedChannel(response.data);

                analytics.track('Streaming Destination Linked', {
                  destinationType: 'YouTube',
                  teamId,
                  autoLink: true,
                });
              });
          }
        }
        setError(null);
      })
      .catch((e) => {
        if (e.response && e.response.data) {
          setError(e.response.data);
        }
        setSignedIn(true);
        Sentry.captureException(e);
      });

    window.gapi.load('auth2', () => {
      setAuth2(gapi.auth2);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // hack for google chrome incognito not working for youtube sign in
    // https://github.com/google/google-api-javascript-client/issues/783
    // https://github.com/pwa-builder/PWABuilder/issues/3286#issuecomment-1219798658
    window.addEventListener('message', (e) => {
      let message;
      try {
        message = JSON.parse(e.data);
      } catch (err) {
        return;
      }

      if ('params' in message && message.params.type === 'authResult') {
        const response = {
          code: message.params.authResult.code,
        };

        onSuccess(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUnlistedSetting = (unlisted: boolean) => {
    axios
      .put('/api/youtube_auth', {
        teamId,
        data: {
          isUnlistedOnConclude: unlisted,
        },
      })
      .then(() => {
        setLinkedChannel({
          ...linkedChannel,
          isUnlistedOnConclude: unlisted,
        } as shd.PublishYT);
        setShowPrivacyModal(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };
  const appHost = useContext(AppHostContext);

  const appHostService = appHost.isHosted ? appHost.service : undefined;
  const signIn = async () => {
    if (appHostService) {
      try {
        const response = await appHostService.requests.authorizeYoutube(
          teamId ?? ''
        );
        onSuccess(response);
      } catch (e) {
        strogging.error('apphost-signIn', {
          err: errorUtil.errorMessage(e),
          ek: Object.keys(e as object),
        });
        setError(errorUtil.errorMessage(e) ?? null);
      }
    } else {
      const config = {
        response_type: 'code',
        scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        access_type: 'offline',
        prompt: 'consent',
        cookiePolicy: 'single_host_origin',
      };

      auth2?.authorize(config as gapi.auth2.AuthorizeConfig, onSuccess);
    }
  };

  const onLinkYoutubeClick = () => {
    analytics.track('Link Streaming Destination Clicked', {
      destinationType: 'YouTube',
      teamId,
    });
    signIn();
    setShowIntroModal(false);
  };

  const onUnlinkYoutubeClick = () => {
    axios.delete('/api/youtube_auth', { data: { teamId } });
    updateLinkedChannel(null);
    setSignedIn(false);
    setError(null);
  };

  // this makes no sense, but I just want to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorStruct = error as any;
  const errorDiv = errorStruct && errorStruct.message && (
    <Box direction="row" align="center" pad="small">
      <Box direction="column">
        <Text>{errorStruct.message}</Text>
        {errorStruct.link ? (
          <Anchor href={errorStruct.link} target="_blank">
            Learn More
          </Anchor>
        ) : null}
      </Box>
    </Box>
  );

  const signedInDiv = (
    <Card background="white" pad="small">
      <Box direction="column" width="medium" gap="small">
        {linkedChannel ? (
          <>
            <Text weight="bold">Streaming to: </Text>
            <Box direction="row" align="center" gap="small" pad="small">
              <Avatar
                src={linkedChannel.channelInfo.snippet.thumbnails.default.url}
              />
              <Text>{linkedChannel.channelInfo.snippet.title}</Text>
              <Anchor
                href={`https://youtube.com/channel/${linkedChannel.channelInfo.id}`}
                target="_blank"
              >
                view channel
              </Anchor>
            </Box>
          </>
        ) : null}
        <SHDButton
          secondary
          fill="horizontal"
          color="status-error"
          icon={<Youtube color="#FF0000" />}
          label="Unlink YouTube"
          onClick={onUnlinkYoutubeClick}
        />
        <Box>
          {linkedChannel && (
            <RoundedToggle
              checked={linkedChannel.isUnlistedOnConclude}
              label={
                <Text weight={'bold'} size="small">
                  Make unlisted (team community only) after broadcast finishes
                </Text>
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setShowPrivacyModal(true);
                } else {
                  updateUnlistedSetting(false);
                }
              }}
            />
          )}
        </Box>
        <Grommet
          style={{ minHeight: 0 }}
          theme={
            {
              ...GROMMETTHEME,
              accordion: {
                border: { color: 'transparent' },
                icons: { color: 'light-6' },
              },
            } as unknown as ThemeType
          }
        >
          <Accordion margin={{ top: 'small' }}>
            <AccordionPanel
              label={
                <Text size="8px" color="gray">
                  Advanced Settings
                </Text>
              }
            >
              <Button
                secondary
                color="light-2"
                label="Video Settings"
                onClick={() => {
                  setShowVideoSettings(true);
                }}
              />
            </AccordionPanel>
          </Accordion>
        </Grommet>
      </Box>
    </Card>
  );

  const signedOutDiv = (
    <Box direction="row" align="center">
      <Box width="medium">
        <SHDButton
          primary
          fill="horizontal"
          color="white"
          icon={<Youtube color="#FF0000" />}
          label="Link YouTube"
          onClick={() => {
            setShowIntroModal(true);
          }}
        />
      </Box>
    </Box>
  );

  const onCloseIntroModal = () => {
    setShowIntroModal(false);
  };

  const StyledTempHeader = styled(Box)`
    @media (max-width: 576px) {
      padding-top: 2rem;
    }
  `;

  const renderModal = () => {
    const tempHeader = (
      <StyledTempHeader margin="small" direction="row" justify="between">
        <Text alignSelf="center" size="large" weight="bold">
          Link to YouTube
        </Text>
        <Button icon={<Close />} onClick={onCloseIntroModal} />
      </StyledTempHeader>
    );

    return (
      <Layer
        onClickOutside={onCloseIntroModal}
        onEsc={onCloseIntroModal}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingBottom: '72px',
        }} // TODO: overflowX is a hack
      >
        <Box
          gap="small"
          margin="small"
          width="large"
          style={{ display: 'block' }}
        >
          {tempHeader}
          <Box
            margin={{ horizontal: 'small', bottom: 'medium' }}
            overflow="scroll"
            gap="small"
          >
            <Box
              direction="row"
              justify="center"
              gap="small"
              align="center"
              margin={{ bottom: 'medium' }}
            >
              <Box width="44px">
                <Image src="/logo512.png" fit="contain" />
              </Box>
              <Connect size="28px" />
              <Youtube size="44px" color="#FF0000" />
            </Box>
            <Text>Stream to your YouTube channel.</Text>
            <Text>
              You will be redirected to YouTube to login when you click the
              button below.
            </Text>
          </Box>
          <Box direction="row" align="center">
            <SHDButton
              primary
              fill="horizontal"
              color="light-2"
              icon={<Youtube color="#FF0000" />}
              label="Link YouTube"
              onClick={onLinkYoutubeClick}
            />
          </Box>
        </Box>
      </Layer>
    );
  };

  return (
    <Box>
      {errorDiv}
      {isSignedIn ? signedInDiv : signedOutDiv}
      {showIntroModal && renderModal()}
      {showVideoSettings && (
        <VideoSettings
          teamId={teamId}
          onClose={() => {
            setShowVideoSettings(false);
          }}
        />
      )}
      {showPrivacyModal && (
        <PrivacyModal
          onUpdateUnlistedSetting={updateUnlistedSetting}
          onClose={() => {
            setShowPrivacyModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default YoutubeSettings;
