/* eslint-disable no-underscore-dangle */
import { makeAutoObservable } from 'mobx';

import { createBrowserHistory } from 'history';
import { ParsedToken, User } from 'firebase/auth';
import axios from '../../helpers/axios';
import * as ROUTES from '../../constants/routes';
import { theFirebase } from '../Firebase/firebase';
import { docStorage, dtos, shd } from '@shd/jslib/models';
import { AxiosResponse } from 'axios';
import { errorUtil, strogging } from '@shd/jslib/infra';

const history = createBrowserHistory();

export interface AugmentedUser extends User {
  claims?: ParsedToken & {
    shd_user_id?: string;
  };
  spoofedUser?: docStorage.UserDoc | null;
}

type SetUserStatusOpResult =
  | {
      sendToOnboarding: boolean;
    }
  | PromiseLike<
      | {
          sendToOnboarding: boolean;
        }
      | undefined
    >
  | undefined;

class UserStore {
  authUser: AugmentedUser | null = null;

  userTeams: shd.Team[] = [];

  selectedTeam: shd.Team | null = null;

  scoringTeam: dtos.ScoreDelegationKeyResponse | null = null;

  activeOnboardingTeam: string | null = null;

  userPlayers: shd.ClaimedPlayer[] = [];

  teamFollowerIds: string[] = [];

  coachTeamIds: string[] = [];

  selectedPlayer: shd.ClaimedPlayer | null = null;

  isReturned = false;

  lastCallTs = 0;

  userPhone = '';

  user: shd.User = {};

  isPermittedToCreateTeam = false;

  referrerUser: unknown | null = null;

  referrerCampaign: string | null = null;

  isCoach = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthUser(user: AugmentedUser | null) {
    this.authUser = user;
  }

  setUserTeams(teams: shd.Team[]) {
    this.userTeams = teams;
  }

  setUserPlayers(players: shd.ClaimedPlayer[]) {
    this.userPlayers = players;
  }

  setSelectedTeam(team: shd.Team | null) {
    this.selectedTeam = team;

    if (team) {
      const teamIds = this.userTeams.map((t) => t._id);
      const index = teamIds.indexOf(team._id);
      localStorage.setItem('selectedTeamIndex', `${index}`);
    }
  }

  async setScoringTeam(
    teamIdParam: string,
    scoreDelegationKeyParam: string
  ): Promise<dtos.ScoreDelegationKeyResponse> {
    const teamId = localStorage.getItem('scoreTeamId') || teamIdParam;
    const scoreDelegationKey =
      localStorage.getItem('scoreDelegationKey') || scoreDelegationKeyParam;

    return new Promise((resolve, reject) => {
      if (!(teamId && scoreDelegationKey)) {
        reject(new Error('Missing teamId or scoreDelegationKey'));
      }
      axios
        .post<
          dtos.ScoreDelegationKeyResponse,
          AxiosResponse<dtos.ScoreDelegationKeyResponse>,
          dtos.ScoreDelegationKeyRequest
        >('/api/score_delegation_key', { teamId, key: scoreDelegationKey })
        .then(({ data: newTeam }) => {
          this.scoringTeam = newTeam;
          localStorage.setItem('scoreDelegationKey', scoreDelegationKey);
          localStorage.setItem('scoreTeamId', newTeam._id);
          resolve(newTeam);
        })
        .catch(reject);
    });
  }

  setActiveOnboardingTeam(teamId: string | null) {
    this.activeOnboardingTeam = teamId;
  }

  setSelectedPlayer(player: shd.ClaimedPlayer) {
    this.selectedPlayer = player;
  }

  toggleIsReturned(value: boolean) {
    this.isReturned = value;
  }

  setTeamFollowerIds(teamIds: string[]) {
    this.teamFollowerIds = teamIds;
  }

  setCoachTeamIds(coachTeamIds: string[]) {
    this.coachTeamIds = coachTeamIds;
  }

  setUserPhone(phone: string) {
    this.userPhone = phone;
  }

  setUser(user: shd.User) {
    this.user = user;
  }

  setPermissionToCreateTeam(isPermit: boolean) {
    this.isPermittedToCreateTeam = isPermit;
  }

  setReferrerUser(user: unknown) {
    this.referrerUser = user;
  }

  setReferrerCampaign(campaign: string | null) {
    this.referrerCampaign = campaign;
  }

  clearStore() {
    this.authUser = null;
    this.userTeams = [];
    this.selectedTeam = null;
    this.selectedPlayer = null;
    this.scoringTeam = null;
    this.activeOnboardingTeam = null;
    this.userTeams = [];
    this.userPlayers = [];
    this.userPhone = '';
    this.isPermittedToCreateTeam = false;
    this.referrerUser = null;
    this.referrerCampaign = null;
  }

  fetchTeam(teamId: string) {
    return axios
      .get(`/api/team/${teamId}`)
      .then((response) => response.data.info)
      .catch((respError) => {
        throw respError;
      });
  }

  async apiSetTeams(
    force = false
  ): Promise<{ sendToOnboarding: boolean } | undefined> {
    if (theFirebase().auth.currentUser) {
      if (Date.now() - this.lastCallTs < 1000 && !force) {
        if (this.selectedTeam || this.selectedPlayer) {
          return undefined;
        }
      } else {
        // APPHOST : this should request from the apphost if present
        const response =
          await axios.get<dtos.UserStatusResponse>('/api/user_status');
        const result = await this.setFromUserStatusResponse(response.data);
        this.lastCallTs = Date.now();
        return result;
      }
    } else {
      return undefined;
    }
  }

  async setFromUserStatusResponse(
    response: dtos.UserStatusResponse
  ): Promise<SetUserStatusOpResult> {
    const {
      teams,
      claimedPlayers: players,
      teamFollowerIds: respTeamFollowerIds,
      coachTeamIds,
      phone,
      isPermittedToCreateTeam,
      activeOnboardingTeam,
      user,
    } = response;
    this.setUser(user);
    this.setUserPhone(phone);
    this.setActiveOnboardingTeam(activeOnboardingTeam);
    this.setPermissionToCreateTeam(isPermittedToCreateTeam);

    // TODO: fix this hacky logic. Should directly return if we should send to onboarding!
    // this var will be true when on first sign in from invited user
    // and/or if user is still in process of creating team
    const sendToOnboarding: boolean =
      isPermittedToCreateTeam &&
      ((!teams.length && !players.length) || !!activeOnboardingTeam) &&
      coachTeamIds.length === 0;
    const forceSendToOnboarding =
      isPermittedToCreateTeam && activeOnboardingTeam !== null;

    if (
      forceSendToOnboarding &&
      window.location.pathname !== ROUTES.ONBOARDING
    ) {
      history.push(ROUTES.ONBOARDING);
    }

    const teamIndex = parseInt(
      localStorage.getItem('selectedTeamIndex') || '0'
    );

    this.setUserTeams(teams);
    if (!this.selectedTeam) {
      const selectedTeam = teams[teamIndex < teams.length ? teamIndex : 0];
      if (selectedTeam) {
        try {
          // Fetch the selected team data from the server
          const teamData = await this.fetchTeam(selectedTeam._id);

          // Augment the selected team with the fetched data
          this.setSelectedTeam({
            ...selectedTeam,
            ...teamData,
          });
        } catch (error) {
          strogging.error('Failed to fetch team data:', {
            err: errorUtil.errorMessage(error),
            id: selectedTeam._id,
          });
        }
      }
    }

    // TODO: figure out why this was ever here?!
    if (teams.length > 0 && players.length === 0) {
      localStorage.setItem('userRole', '0');
    }

    if (players.length > 0 && teams.length === 0) {
      localStorage.setItem('userRole', '1');
    }

    // else {
    //   localStorage.setItem('userRole', 2);
    // }
    const playerIndex = parseInt(
      localStorage.getItem('selectedPlayerIndex') || '0'
    );
    this.setUserPlayers(players);
    if (!this.selectedPlayer) {
      this.setSelectedPlayer(
        players[playerIndex < players.length ? playerIndex : 0]
      );
    }
    this.setTeamFollowerIds(respTeamFollowerIds);
    this.setCoachTeamIds(coachTeamIds);
    this.toggleIsReturned(true);
    return {
      sendToOnboarding,
    };
  }

  setHelpWidgetAuth() {
    window.zE(
      'messenger',
      'loginUser',
      async function (callback: (arg0: string) => void) {
        const response = await axios.get('/api/zendesk/jwt_token');
        const token = response.data;
        callback(token);
      }
    );
  }
}

export default UserStore;
