// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { Text, Layer, Heading, Box, RadioButton } from 'grommet';
import * as Sentry from '@sentry/browser';
import { Add } from 'grommet-icons';
import SHDButton from '../../components/SHD/Button';
import { insertDisplayName } from '../../helpers/utils';
import { BasicLoader } from '../../helpers/Loaders';
import QuickAddPlayer from './QuickAddPlayer';
import { ScoringContext } from './context';
import axios from '../../helpers/axios';

const PitcherSelectorBase = ({ onClose = () => {} }) => {
  const scoringStore = useContext(ScoringContext);
  const { pitchers, awayHome, handleEvent } = scoringStore;
  const [players, setPlayers] = useState([]);
  const [pitcher, setPitcher] = useState(
    pitchers[awayHome] ? pitchers[awayHome]._id : undefined
  );
  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState();
  const [showAddPlayer, setShowAddPlayer] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/team/${scoringStore.teamId}/team_players`)
      .then((response) => {
        const teamPlayerDocs = response.data.players;
        const newPlayers = teamPlayerDocs
          .filter((player) => player.isActive)
          .map(insertDisplayName);

        newPlayers.sort((a, b) => {
          const aJerseyNum = parseInt(a.jerseyNum);
          const bJerseyNum = parseInt(b.jerseyNum);
          return aJerseyNum > bJerseyNum ? 1 : -1;
        });

        setPlayers(newPlayers);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPlayer = (player) => {
    const newPlayer = insertDisplayName(player);
    const newPlayers = [...players, newPlayer];
    setPlayers(newPlayers);
    setPitcher(newPlayer._id);
  };

  if (loading) {
    return (
      <Layer>
        <BasicLoader fullPage />
      </Layer>
    );
  }

  if (showAddPlayer) {
    return (
      <QuickAddPlayer
        fullPage
        addPlayerLocal={addPlayer}
        onClose={() => setShowAddPlayer(false)}
      />
    );
  }

  return (
    <Layer pad={{ vertical: 'medium' }}>
      <Box
        pad="medium"
        gap="medium"
        style={{ overflow: 'auto', display: 'block' }}
      >
        <Box fill="horizontal" align="end" pad="medium">
          <Text
            color="tertiary-1"
            weight="bold"
            onClick={() => {
              if (!pitcher) {
                handleEvent('pitchingChange', {});
              }
              onClose();
            }}
          >
            Back
          </Text>
        </Box>
        {error ? <Text color="status-critical">{error}</Text> : null}
        <Heading level="3">
          {pitchers[awayHome] ? 'Change pitcher' : 'Choose pitcher'}
        </Heading>
        {pitcher ? (
          <SHDButton
            secondary
            label="Clear pitcher"
            onClick={() => {
              setPitcher(undefined);
            }}
            color="secondary-1"
          />
        ) : null}
        <Box gap="medium" height={{ min: 'initial' }}>
          {players.map((player) => (
            <Box
              background={
                pitcher === player._id ? 'secondary-1' : 'secondary-7'
              }
              pad="medium"
              style={{ borderRadius: '8px' }}
              onClick={() => setPitcher(player._id)}
              key={player._id}
            >
              <RadioButton
                checked={pitcher === player._id}
                label={player.displayName}
                readOnly
              />
            </Box>
          ))}
        </Box>
        <Box align="start">
          <Box
            hoverIndicator
            onClick={() => setShowAddPlayer(true)}
            direction="row"
            align="center"
            gap="small"
            fill={false}
            pad="medium"
          >
            <Add color="tertiary-1" />
            <Text color="tertiary-1">Create player</Text>
          </Box>
        </Box>
        <Box pad={{ top: 'large' }} height={{ min: 'small' }}>
          <SHDButton
            size="large"
            label={
              pitchers[awayHome] && pitchers[awayHome]._id && !pitcher
                ? 'Clear'
                : 'Select'
            }
            primary
            disabled={pitchers[awayHome] && pitchers[awayHome]._id === pitcher}
            onClick={() => {
              handleEvent(
                'pitchingChange',
                players.filter((player) => player._id === pitcher)[0] || {}
              );
              onClose();
            }}
            color="tertiary-1"
          />
        </Box>
      </Box>
    </Layer>
  );
};

const PitcherSelector = PitcherSelectorBase;

export default PitcherSelector;
