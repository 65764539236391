import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Heading, Tab, Tabs, Text } from 'grommet';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  BBSBPlayerClipsSectionProps,
  BBSBPlayerClipsSectionTabData,
  PinnedClipsFeedProps,
} from './player.types';
import PlayerGameClipsFeed from './PlayerGameClipsFeed';
import PlayerClip from './PlayerClip';
import ReelsList from './ReelsList';
import { UserContext } from '../../services/Session';
import { withRouter } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const REELS_TAB_INDEX = 0;
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const PINNED_TAB_INDEX = 1;
const OFFENSE_TAB_INDEX = 2;
const DEFENSE_TAB_INDEX = 3;

const PinnedClipsFeed: React.FC<PinnedClipsFeedProps> = ({
  player,
  teams,
  isAdmin,
  pinnedClips,
  pinClip,
  unpinClip,
  onShowClaim,
}) => {
  const userStore = useContext(UserContext);
  const [localPinnedClips, setLocalPinnedClips] = useState(pinnedClips);

  useEffect(() => {
    setLocalPinnedClips(pinnedClips);
  }, [pinnedClips]);

  const userTeamIds = userStore.userTeams
    ? userStore.userTeams.map((team) => team._id)
    : [];

  const isFollower =
    teams.length &&
    userStore.teamFollowerIds
      .concat(userTeamIds)
      .filter((teamId) =>
        teams.map((playerTeam) => playerTeam._id).includes(teamId)
      ).length;

  const bbsbPinnedClips = localPinnedClips.filter((clip) => {
    const visible =
      !clip.O_hideFromClaimedPlayerPage?.includes(player._id) ||
      clip.O_hideFromClaimedPlayerPage?.length === 0;
    const isBBSB = clip.game?.sportType === 'bbsb';

    return visible && isBBSB;
  });

  if (bbsbPinnedClips.length === 0) {
    return (
      <Heading textAlign="center" color="text-xweak" level={4}>
        {isAdmin
          ? 'Add highlights from your clips'
          : "This player hasn't added a highlight yet"}
      </Heading>
    );
  }

  const showBottomBar =
    isAdmin || isFollower || player.publicPublishLevel >= 10;
  return (
    <Box pad="medium">
      {bbsbPinnedClips.map((clip) => {
        if (!clip.game) {
          return null;
        }
        return (
          <PlayerClip
            key={clip.id}
            game={clip.game}
            clip={clip}
            publicPublishLevel={
              isAdmin || isFollower ? 10 : player.publicPublishLevel
            }
            ref={null}
            playerId={player._id}
            pinClip={isAdmin ? pinClip : null}
            unpinClip={isAdmin ? unpinClip : null}
            isPinned={
              !!(
                localPinnedClips.map((c) => c.id).includes(clip.refPreview) ||
                localPinnedClips.map((c) => c.id).includes(clip.refFull)
              )
            }
            onShowClaim={onShowClaim}
            hideBottomBar={!showBottomBar}
            pinnedFeed
          />
        );
      })}
    </Box>
  );
};

const BBSBPlayerClipsSection: React.FC<BBSBPlayerClipsSectionProps> = ({
  playerId,
  player,
  isAdmin,
  teams,
  claimed,
  showReelsTab,
  pinnedClips,
  pinClip,
  unpinClip,
  onShowClaim,
  location,
}) => {
  const [activeTab, setActiveTab] = useState(OFFENSE_TAB_INDEX);
  const [tabsData, setTabsData] = useState<{
    [key: string]: BBSBPlayerClipsSectionTabData;
  }>({
    offense: { games: [], hasMore: true, nextStartAfterTs: null },
    defense: { games: [], hasMore: true, nextStartAfterTs: null },
  });
  const [selectedGameId, setSelectedGameId] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [gamesError, setGamesError] = useState<string | null>(null);

  const [localPinnedClips, setLocalPinnedClips] = useState(pinnedClips);

  const getBBSBGames = useCallback(
    async (tabKey: string) => {
      try {
        const response = await axios.get(`/api/player/${playerId}/games_v2`, {
          params: {
            startAfterTs: tabsData[tabKey].nextStartAfterTs || 2400000000,
            role: tabKey === 'offense' ? 'offense_batter' : 'defense_pitcher',
          },
        });
        const { games: newGames, nextStartAfterTs: newNextStartAfterTs } =
          response.data;

        setTabsData((prevData) => ({
          ...prevData,
          [tabKey]: {
            games: [...prevData[tabKey].games, ...newGames],
            hasMore: newNextStartAfterTs !== null,
            nextStartAfterTs: newNextStartAfterTs,
          },
        }));
      } catch (error) {
        setGamesError('Error loading games');
      }
    },
    [playerId, tabsData]
  );

  useEffect(() => {
    let currentTabKey;
    if (activeTab === OFFENSE_TAB_INDEX) {
      currentTabKey = 'offense';
    } else if (activeTab === DEFENSE_TAB_INDEX) {
      currentTabKey = 'defense';
    }
    if (currentTabKey && tabsData[currentTabKey].games.length === 0) {
      getBBSBGames(currentTabKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, getBBSBGames]);

  useEffect(() => {
    if (location.hash) {
      const tokenized = location.hash.split('-');
      const gameId = tokenized
        .splice(0, tokenized.length - 1)
        .join('-')
        .substring(1);
      const role = tokenized[0];
      setSelectedGameId(gameId);
      setSelectedRole(role);

      if (role === 'defense_pitcher') {
        setActiveTab(DEFENSE_TAB_INDEX);
      } else if (role === 'offense_batter') {
        setActiveTab(OFFENSE_TAB_INDEX);
      }
    }
  }, [location.hash]);

  const getElementByIdAsync = (
    id: string,
    maxAttempts = 50
  ): Promise<HTMLElement | null> =>
    new Promise((resolve) => {
      let attempts = 0;
      const getElement = () => {
        attempts++;
        const element = document.getElementById(id);
        if (element) {
          resolve(element);
        } else if (attempts < maxAttempts) {
          setTimeout(getElement, 100);
        } else {
          resolve(null);
        }
      };
      getElement();
    });

  useEffect(() => {
    const scrollToGameAsync = async (elementId: string) => {
      const el = await getElementByIdAsync(elementId);
      if (el) {
        el.scrollIntoView(true);
      }
    };
    if (selectedGameId && selectedRole) {
      const elementId = `${selectedGameId}-${selectedRole}`;
      scrollToGameAsync(elementId);
    }
  }, [selectedGameId, selectedRole]);

  useEffect(() => {
    setLocalPinnedClips(pinnedClips);
  }, [pinnedClips]);

  const renderTabContent = useCallback(
    (tabKey: string) => {
      const { games, hasMore } = tabsData[tabKey];

      if (games.length === 0) {
        return (
          <Text
            style={{
              left: '1.5rem',
              position: 'relative',
              top: '1.5rem',
            }}
          >
            No games found.
          </Text>
        );
      }

      if (gamesError) {
        return <Text>{gamesError}</Text>;
      }

      return (
        <InfiniteScroll
          dataLength={games.length}
          next={() => getBBSBGames(tabKey)}
          hasMore={hasMore}
          loader={<BasicLoader />}
        >
          <Box pad={'medium'} gap="small">
            {games.map((game) => (
              <PlayerGameClipsFeed
                key={game.id}
                playerId={playerId}
                role={
                  tabKey === 'offense' ? 'offense_batter' : 'defense_pitcher'
                }
                game={game}
                isAdmin={isAdmin}
                pinnedClips={localPinnedClips}
                pinClip={isAdmin ? pinClip : null}
                unpinClip={isAdmin ? unpinClip : null}
                onShowClaim={onShowClaim}
                showClips={selectedGameId === game.id}
              />
            ))}
          </Box>
        </InfiniteScroll>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playerId, tabsData, getBBSBGames, localPinnedClips]
  );

  return (
    <Tabs
      alignControls="stretch"
      alignSelf="stretch"
      activeIndex={activeTab}
      onActive={setActiveTab}
    >
      {claimed && showReelsTab && (
        <Tab title="Reels">
          <ReelsList {...{ playerId }} />
        </Tab>
      )}
      {claimed && (
        <Tab title="Pinned">
          <PinnedClipsFeed
            player={player}
            teams={teams}
            isAdmin={isAdmin}
            pinnedClips={localPinnedClips}
            pinClip={pinClip}
            unpinClip={unpinClip}
            onShowClaim={onShowClaim}
          />
        </Tab>
      )}
      <Tab title="Offense">{renderTabContent('offense')}</Tab>
      <Tab title="Defense">{renderTabContent('defense')}</Tab>
    </Tabs>
  );
};

export default withRouter(BBSBPlayerClipsSection);
