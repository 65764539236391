// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, {
  useRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {
  Box,
  Text,
  TextInput,
  Layer,
  Button,
  ResponsiveContext,
  AccordionPanel,
  Accordion,
  Card,
  Select,
} from 'grommet';
import * as Sentry from '@sentry/browser';
import { Close } from 'grommet-icons';
import { withRouter } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import { BasicLoader } from '../../helpers/Loaders';
import { withFirebase } from '../../services/Firebase';
import { SPORTS_MAP, VOLLEYBALL } from '../../constants/strings';
import OnboardingForm from './TeamInfoForm';
import defaultFormValues from './TeamInfoForm/defaultValues';
import { useInterval } from '../../helpers/utils';
import IconTeamSnap from './TeamInfoForm/FillInfoStep/IconTeamSnap';

const useDebouncedCallback = (callback, delay, opts) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    _.debounce(
      (text) => {
        if (callbackRef.current) {
          callbackRef.current(text);
        }
      },
      delay,
      opts
    ),
    []
  );
};

const TeamInfo = observer((props) => {
  const userStore = useContext(UserContext);
  const size = useContext(ResponsiveContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [value, setValue] = useState(defaultFormValues);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [importSource, setImportSource] = useState();

  const [searchValue, setSearchValue] = useState('');
  const [aesParams, setAesParams] = useState({});
  const [showSearchLoading, setShowSearchLoading] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showLALoginModal, setShowLALoginModal] = useState(false);
  const [showTSLoginModal, setShowTSLoginModal] = useState(false);
  const [showTSLoading, setShowTSLoading] = useState(false);
  const [searchTeams, setSearchTeams] = useState([]);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [activeTeamIndex, setActiveTeamIndex] = useState(null);

  const [error, setError] = useState(null);
  const [importError, setImportError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [rosterLoading, setRosterLoading] = useState(false);

  const checkTSAuthed = () => {
    if (showTSLoading) {
      axios
        .get(`/api/teamsnap/user/${userStore.authUser.claims.shd_user_id}`)
        .then(({ data }) => {
          setSearchTeams(data.teams);
          setShowTSLoading(false);
          setShowSearchModal(true);
        })
        .catch(() => {});
    }
  };

  useInterval(checkTSAuthed, 1000);

  // let importSourceLong;
  // if (importSource === 'pg') {
  //   importSourceLong = 'Perfect Game';
  // } else if (importSource === 'gc') {
  //   importSourceLong = 'GameChanger';
  // } else if (importSource === 'usssa') {
  //   importSourceLong = 'USSSA';
  // } else {
  //   importSourceLong = 'USSSA';
  // }

  const cancelOnboarding = async () => {
    if (userStore.activeOnboardingTeam) {
      try {
        await axios.delete(`/api/team/${userStore.activeOnboardingTeam}`, {
          data: {
            teams: userStore.userTeams,
          },
        });
        userStore.setActiveOnboardingTeam(null);
        // setValue(null);
      } catch (respError) {
        Sentry.captureException(respError);
      }
    }
  };

  const importAesTeam = (aesId) => {
    axios
      .get(`/api/3p/team/${aesId}`, { params: { source: 'aes' } })
      .then(async (response) => {
        // setAesTeam(response.data);
        const user = {
          userId: userStore.authUser.claims.shd_user_id,
          user: {
            nameFirst: userStore.authUser.displayName,
            nameLast: '',
          },
        };
        const team = response.data;

        await cancelOnboarding();

        axios
          .post('/api/3p/team', { user, team, source: 'aes' })
          .then((res) => {
            const { team: shdTeam } = res.data;

            setValue({
              ...shdTeam,
              attrib_sportType: VOLLEYBALL,
            });
            userStore.setActiveOnboardingTeam(shdTeam._id);
            setShowSearchModal(false);
            setSearchValue('');

            setSearchTeams([]);
            setSelectedTeamIndex(null);
            setActiveTeamIndex(null);

            setImportError(null);

            props.firebase.logAnalyticsEvent('team_import_complete', {
              method: importSource,
              team_id: shdTeam._id,
              user_id: user.userId,
            });
          })
          .catch(() => {
            setSearchLoading(false);
            setImportError(
              "We are having trouble reaching AES's servers. Please manually enter your team info below."
            );
            setShowSearchModal(false);
          });
      })
      .catch(() => {
        setImportError(
          "We are having trouble reaching AES's servers. Please manually enter your team info below."
        );
      });
  };

  useEffect(() => {
    if (props.history.location.state?.aesId) {
      importAesTeam(props.history.location.state?.aesId);
    }
    // importAesTeam(144644);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To test AES import
  // useEffect(() => {
  //   props.history.push({
  //     state: {
  //       aesId: 144644,
  //     },
  //   });
  // }, []);

  const search = useDebouncedCallback(
    (text) => {
      if (text) {
        setShowSearchLoading(true);
        axios
          .get('/api/3p/search', {
            params: {
              search: text,
              source: importSource.key,
              ...aesParams,
            },
          })
          .then((response) => {
            setShowSearchLoading(false);
            const { hits } = response.data;

            setSearchTeams(hits);
          })
          .catch(() => {
            setImportError(
              `We are having trouble reaching ${importSource.display}'s servers. Please manually enter your team info below.`
            );
            setShowSearchModal(false);
          });
      } else {
        setSearchTeams([]);
        setSelectedTeamIndex(null);
        setActiveTeamIndex(null);
      }
    },
    1000,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    if (searchValue && aesParams) {
      search(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aesParams]);

  const onChange = (event) => {
    const { value: newValue } = event.target;
    setSearchValue(newValue);
    search(newValue);
  };

  useEffect(() => {
    if (userStore.activeOnboardingTeam) {
      setLoading(true);
      axios
        .get(`/api/team/${userStore.activeOnboardingTeam}`)
        .then((response) => {
          const { info } = response.data;
          const sportType = SPORTS_MAP[info.attrib_sportType];

          setValue({
            ...info,
            attrib_sportType: sportType,
          });
          setLoading(false);
          window.scrollTo(0, 0);
        })
        .catch((respError) => {
          setLoading(false);
          Sentry.captureException(respError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamDetails = (team) => {
    if (!['leagueapps', 'teamsnap'].includes(importSource.key)) {
      setRosterLoading(true);
      const url = `/api/3p/team/${team.id}/players`;
      axios
        .get(url, {
          params: { source: importSource.key },
        })
        .then((response) => {
          setRosterLoading(false);
          setSearchTeams(
            searchTeams.map((t) => {
              const newTeam = { ...t };
              if (newTeam.id === team.id) {
                newTeam.players = response.data;
              }

              return newTeam;
            })
          );
        })
        .catch(() => {
          setImportError(
            `We are having trouble reaching ${importSource.display}'s servers. Please manually enter your team info below.`
          );
        });
    }
  };

  useEffect(() => {
    if (activeTeamIndex || activeTeamIndex === 0) {
      const team = searchTeams[activeTeamIndex];
      getTeamDetails(team);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeamIndex]);

  const validate = () => {
    const fixedValue = { ...value };

    fixedValue.attrib_sportType =
      fixedValue.attrib_sportType.display ??
      SPORTS_MAP[fixedValue.attrib_sportType].display;

    if (fixedValue.nameHandleLower) {
      fixedValue.nameHandleLower = fixedValue.nameHandle.toLowerCase();
    }

    fixedValue.isStageTeamInfoDone = true;

    setLoading(true);
    axios
      .post('/api/teams', { teamInfo: fixedValue })
      .then((response) => {
        userStore.setActiveOnboardingTeam(response.data);
        setLoading(false);
        props.afterValidation(fixedValue);
      })
      .catch((respError) => {
        if (respError.response && respError.response.data) {
          setError(respError.response.data.message);
        } else {
          setError('Something went wrong. We are working to fix this.');
        }
        window.scrollTo(0, 0);
        setLoading(false);
        Sentry.captureException(respError);
      });
  };

  if (loading) {
    return <BasicLoader fullPage />;
  }

  const teamsPanels = searchTeams.map((team, i) => {
    let season;
    let location;
    if (importSource.key === 'gc') {
      season = `- ${
        team.team_season.season.charAt(0).toUpperCase() +
        team.team_season.season.slice(1)
      } ${team.team_season.year}`;
      location = `${team.location ? team.location.city : ''}, ${
        team.location ? team.location.state : ''
      }`;
    } else if (importSource.key === 'pg') {
      season = team.season;
      location = `${team.city ? team.city : ''}, ${
        team.state ? team.state : ''
      }`;
    } else if (importSource.key === 'usssa') {
      season = team.season;
      location = `${team.city ? team.city : ''}, ${
        team.state ? team.state : ''
      }`;
    } else if (importSource.key === 'leagueapps') {
      season = '2023';
      location = '';
    } else if (importSource.key === 'aes') {
      season = '2023';
      location = '';
    } else if (importSource.key === 'teamsnap') {
      season = '2023';
      location = '';
    }

    const panelHeader = (
      <Box pad="medium" background="secondary-7">
        <Box direction="row" align="center" gap="small" justify="between">
          <Box>
            <strong>
              <Text>{team.name}</Text>
            </strong>
            <Box direction="row" gap="small" align="center">
              <Text size="small" color="dark-3">
                {team.sport
                  ? team.sport.charAt(0).toUpperCase() + team.sport.slice(1)
                  : ''}
              </Text>
              <Text size="small" color="dark-3">
                {season}
              </Text>
            </Box>
            <Text size="small" color="dark-3">
              {location}
            </Text>
          </Box>
          <Box direction="column" gap="medium">
            <Button
              primary
              size="small"
              label={<Text>Import</Text>}
              onClick={(e) => {
                e.stopPropagation();

                setSelectedTeamIndex(i);
                getTeamDetails(team);
              }}
            />
            <Button
              secondary
              size="small"
              label={
                <Text>
                  {activeTeamIndex === i ? 'Hide Roster' : 'Show Roster'}
                </Text>
              }
              onClick={(e) => {
                e.stopPropagation();

                if (activeTeamIndex === null) {
                  setActiveTeamIndex(i);
                } else {
                  setActiveTeamIndex(null);
                }
              }}
            />
          </Box>
        </Box>
        <Box gap="xsmall">
          {team.staff && team.staff.length > 0 && (
            <Card elevation="none" background="light-3" pad="small">
              <Text weight="bold" size="xsmall">
                Staff
              </Text>
              <Box direction="row" wrap>
                <Text size="small">{team.staff.join(', ')}</Text>
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    );

    const playersDivs =
      team.players &&
      team.players.map((player) => (
        // eslint-disable-next-line react/jsx-key
        <Text>
          <b>{`${player.first_name} ${player.last_name}`}</b>{' '}
          {`${player.number ? '#' : ''}${player.number ? player.number : ''}`}
        </Text>
      ));

    const noPlayers = <Text>This team has no players</Text>;

    const playersDiv =
      playersDivs && playersDivs.length ? playersDivs : noPlayers;

    return (
      <AccordionPanel key={`panel-${i + 1}`} header={panelHeader}>
        <Box gap="small" pad="small">
          {rosterLoading ? <BasicLoader /> : playersDiv}
        </Box>
      </AccordionPanel>
    );
  });

  const importTeam = async () => {
    const user = {
      userId: userStore.authUser.claims.shd_user_id,
      user: {
        nameFirst: userStore.authUser.displayName,
        nameLast: '',
      },
    };
    const team = searchTeams[selectedTeamIndex];

    // handle error case!
    await cancelOnboarding();

    setSearchLoading(true);
    axios
      .post('/api/3p/team', { user, team, source: importSource.key })
      .then((response) => {
        setSearchLoading(false);
        const { team: shdTeam } = response.data;

        // get the attrib_sportType object
        const sportType = SPORTS_MAP[shdTeam.attrib_sportType];

        setValue({
          ...shdTeam,
          attrib_sportType: sportType,
        });
        userStore.setActiveOnboardingTeam(shdTeam._id);
        setShowSearchModal(false);

        setSearchValue('');

        setSearchTeams([]);
        setSelectedTeamIndex(null);
        setActiveTeamIndex(null);

        // which step should we go to?!
        // setCurrentStepIndex((prevState) => prevState + 1);
        props.afterValidation({
          ...shdTeam,
          attrib_sportType: sportType,
        });

        props.firebase.logAnalyticsEvent('team_import_complete', {
          method: importSource,
          team_id: shdTeam._id,
          user_id: user.userId,
        });
      })
      .catch(() => {
        setSearchLoading(false);
        setImportError(
          `We are having trouble reaching ${importSource.display}'s servers. Please manually enter your team info below.`
        );
        setShowSearchModal(false);
      });
  };

  const confirmTeamImport = () => {
    if (selectedTeamIndex === null) {
      return null;
    }

    const team = searchTeams[selectedTeamIndex];
    return (
      <Box height={{ min: size === 'small' ? '200px' : '180px' }}>
        <Card elevation="none" background="light-3" pad="medium" gap="small">
          <Text weight="bold">Confirm import</Text>
          <Text>{`Are you sure you want to import ${
            team ? team.name : ''
          }?`}</Text>
          {importSource === 'gc' && (
            <Text size="xsmall" color="dark-3">
              By importing, you will be retrieving your data from a third-party
              site. You will have an opportunity to review and confirm your data
              before transferring it to your account on sidelineHD. GAMECHANGER
              is a trademark of GAMECHANGER MEDIA, INC. SidelineHD is not
              affiliated with and operates independently from GameChanger.
            </Text>
          )}
          <Box direction="row" justify="end" gap="small">
            <Button
              primary
              disabled={searchLoading}
              label={searchLoading ? 'Importing...' : 'Yes, import'}
              onClick={importTeam}
            />
            <Button
              label="No"
              disabled={searchLoading}
              onClick={() => {
                setSelectedTeamIndex(null);
              }}
            />
          </Box>
        </Card>
      </Box>
    );
  };

  const leagueAppsLoader = (
    <Box margin="auto">
      <BounceLoader size={200} color="white" cssOverride={{ margin: 'auto' }} />
      <Text size="large" color="white" margin={{ top: 'small' }}>
        Loading your team info...
      </Text>
    </Box>
  );

  const renderLeagueAppsModal = () => (
    <Layer background="#32751E">
      <Box width="large">
        <Box
          height={{ min: '24px' }}
          direction="row"
          justify="end"
          fill="horizontal"
        >
          <Button
            hoverIndicator
            icon={<Close color="white" />}
            onClick={() => {
              setImportError(null);
              setActiveTeamIndex(null);
              setSelectedTeamIndex(null);
              setShowSearchModal(false);
              setShowLALoginModal(false);
            }}
          />
        </Box>
        <Box pad="medium" gap="small">
          <Text size="large" weight="bold">
            Login to LeagueApps
          </Text>
          <Text weight="bold">
            NOTE: This is the account you use to sign in to manage your
            kid&apos;s profile on your team or club&apos;s website. If you use
            the LeagueApps Play app, use that account info. If you are a club
            director, this will not work.
          </Text>
          <Text margin={{ bottom: 'xlarge' }}>
            Importing a team will automatically fill in your team&apos;s info
            and roster
          </Text>
          <TextInput
            type="email"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            value={username}
            placeholder="LeagueApps Email"
          />
          <TextInput
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            placeholder="LeagueApps Password"
          />
          <Button
            size="large"
            color="white"
            label={
              <Text color="white" weight="bold">
                Login to LeagueApps
              </Text>
            }
            onClick={() => {
              setLoginLoading(true);
              axios
                .post('/api/la/login', {
                  username,
                  password,
                })
                .then((response) => {
                  const { teams } = response.data;
                  setSearchTeams(teams);
                  setShowLALoginModal(false);
                  setShowSearchModal(true);
                  setLoginLoading(false);
                })
                .catch(() => {
                  setLoginLoading(false);
                  setLoginError(
                    'There was an error logging into LeagueApps. Please try again.'
                  );
                });
            }}
          />
          <Text size="xsmall">
            You will be securely logged into your LeagueApps account so we can
            import your team. We do not store your LeagueApps credentials.
          </Text>
          {loginLoading && leagueAppsLoader}
          {loginError && (
            <Box margin={{ top: 'xlarge' }}>
              <Text color="red">{loginError}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Layer>
  );

  const renderTeamSnapModal = () => (
    <Layer background="#D6E4F1">
      <Box width="large">
        <Box
          height={{ min: '24px' }}
          direction="row"
          justify="end"
          fill="horizontal"
        >
          <Button
            hoverIndicator
            icon={<Close color="#222222" />}
            onClick={() => {
              setImportError(null);
              setActiveTeamIndex(null);
              setSelectedTeamIndex(null);
              setShowSearchModal(false);
              setShowTSLoginModal(false);
            }}
          />
        </Box>
        <Box pad="medium" gap="small">
          <Text size="large" weight="bold">
            Import from TeamSnap
          </Text>
          <Text margin={{ bottom: 'xlarge' }}>
            Importing a team will automatically fill in your team&apos;s info
            and roster
          </Text>
          <Text margin={{ bottom: 'xlarge' }}>
            When you press the button below, you will be redirected to TeamSnap
            to login.
          </Text>
          <Button
            primary
            size="large"
            color="#222222"
            icon={<IconTeamSnap />}
            label={
              <Text color="white" weight="bold">
                Login to TeamSnap
              </Text>
            }
            onClick={() => {
              if (searchTeams.length) {
                setShowTSLoginModal(false);
                setShowSearchModal(true);
              } else {
                const clientId = 'c9tmk_K3KQ3OJ3hdrN9_F2fxcMERdT2uxO6l1j77gFw';
                const baseUrl = process.env.REACT_APP_BASE_URL.replace(
                  'http://',
                  'https://'
                );
                const redirectUri = `${baseUrl}/teamsnap/callback`;
                const tsUrl = `https://auth.teamsnap.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token`;
                window.open(tsUrl, '_blank');

                setShowTSLoginModal(false);
                setShowTSLoading(true);
              }
            }}
          />
        </Box>
      </Box>
    </Layer>
  );

  const renderSearchModal = () => (
    <Layer>
      <Box width="large">
        <Box
          height={{ min: '24px' }}
          direction="row"
          justify="end"
          fill="horizontal"
        >
          <Button
            hoverIndicator
            icon={<Close />}
            onClick={() => {
              setImportError(null);
              setActiveTeamIndex(null);
              setSelectedTeamIndex(null);
              setShowSearchModal(false);
              setSearchValue('');
              setSearchTeams([]);
            }}
          />
        </Box>
        <Box pad="medium" gap="small">
          {!['leagueapps', 'teamsnap'].includes(importSource.key) && (
            <Text weight="bold">
              {`Search for your ${importSource.display} team.`}
            </Text>
          )}
          <Text>
            Importing a team will automatically fill in your team&apos;s info
            and roster
          </Text>
          {importSource.key === 'aes' && (
            <Select
              placeholder="Age group"
              options={[
                {
                  key: 12,
                  label: '12 & Under',
                },
                {
                  key: 13,
                  label: '13 & Under',
                },
                {
                  key: 14,
                  label: '14 & Under',
                },
                {
                  key: 15,
                  label: '15 & Under',
                },
                {
                  key: 16,
                  label: '16 & Under',
                },
                {
                  key: 17,
                  label: '17 & Under',
                },
                {
                  key: 18,
                  label: '18 & Under',
                },
              ]}
              labelKey="label"
              valueKey={{ key: 'key', reduce: true }}
              onChange={({ value: nextValue }) => {
                setAesParams({
                  ...aesParams,
                  ageGroup: nextValue,
                });
              }}
            />
          )}
          {!['leagueapps', 'teamsnap'].includes(importSource.key) && (
            <TextInput
              type="search"
              onChange={onChange}
              placeholder="Tulsa Drillers 18U"
              value={searchValue}
            />
          )}
          {confirmTeamImport()}
          {showSearchLoading && <BasicLoader />}
          {searchTeams.length > 0 && (
            <Box overflow="auto">
              <Accordion
                activeIndex={activeTeamIndex}
                onActive={(newActiveTeamIndex) => {
                  setActiveTeamIndex(newActiveTeamIndex[0]);
                }}
              >
                {teamsPanels}
              </Accordion>
            </Box>
          )}
        </Box>
      </Box>
    </Layer>
  );

  const cancelTeamInfo = () => {
    props.previousStep();
    cancelOnboarding().catch((err) => {
      Sentry.captureException(err);
    });
  };

  return (
    <Box
      // direction="row-responsive"
      justify="center"
      gap="medium"
      pad={{ horizontal: 'large' }}
    >
      <Box gap="medium" width="large">
        <OnboardingForm
          validate={validate}
          cancelTeamInfo={cancelTeamInfo}
          userStore={userStore}
          value={value}
          setValue={setValue}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          setImportSource={setImportSource}
          setShowSearchModal={setShowSearchModal}
          importError={importError}
          error={error}
          setShowLALoginModal={setShowLALoginModal}
          setShowTSLoginModal={setShowTSLoginModal}
        />
      </Box>
      {showSearchModal && renderSearchModal()}
      {showLALoginModal &&
        importSource.key === 'leagueapps' &&
        renderLeagueAppsModal()}
      {showTSLoginModal && renderTeamSnapModal()}
      {showTSLoading && <BasicLoader />}
    </Box>
  );
});

export default withRouter(withFirebase(TeamInfo));
