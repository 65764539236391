import { Box, Layer, Text } from 'grommet';
import React, { useEffect, useState } from 'react';
import SHDButton from '../../components/SHD/Button';
import { TeamCapture } from '@shd/jslib/models/shd';

interface Props {
  capture: TeamCapture;
  closeLayer: () => void;
  pointCapture: () => void;
}

const PointCameraLayer: React.FC<Props> = ({
  capture,
  closeLayer,
  pointCapture,
}) => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (capture.targetTeamId === 'disabled') {
      pointCapture();
      closeLayer();
    } else {
      setShowWarning(true);
    }
  }, [capture.targetTeamId, closeLayer, pointCapture]);

  if (showWarning) {
    return (
      <Layer>
        <Box direction="column" gap="medium" pad="large" align="center">
          <Text textAlign="center" weight="bold">
            {`${capture.captureName} will not be able to stream games for ${capture.targetTeamName}.`}
          </Text>
          <Text textAlign="center">Are you sure you want to continue?</Text>
          <Box direction="row" gap="small" justify="center">
            <SHDButton
              color="status-warning"
              onClick={() => {
                closeLayer();
              }}
              label="Cancel"
            />
            <SHDButton
              primary
              color="status-warning"
              onClick={() => {
                pointCapture();
                closeLayer();
              }}
              label="Yes, I'm sure"
            />
          </Box>
        </Box>
      </Layer>
    );
  }

  return null;
};

export default PointCameraLayer;
