// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Box, Text, Button, Card, Layer, Heading, CheckBox } from 'grommet';
import { Close, LinkPrevious } from 'grommet-icons';
import axios from '../helpers/axios';
import { UserContext } from '../services/Session';
import { BasicLoader } from '../helpers/Loaders';
import { compareJerseyNum } from '../helpers/utils';
import { usePermitRolloverPlayer } from '../hooks/usePlayerClaims';
import GenericMessageModal from './GenericMessageModal';

const RolloverPlayerLayer = ({
  existingPlayerIds = [],
  targetTeamId,
  onCloseModal,
  onUpdatedPlayers,
}) => {
  const userStore = useContext(UserContext);

  const [step, setStep] = useState(1);
  const [selectedIndices, setSelectedIndices] = useState([]);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [players, setPlayers] = useState([]);
  const [rolloverEliglible, setRolloverEliglible] = useState();
  const [rolloverMessage, setRolloverMessage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [messageModal, setMessageModal] = useState();
  const { checkRolloverPermission } = usePermitRolloverPlayer();

  const handleMessageModal = useCallback(() => {
    setMessageModal();
    onCloseModal();
    setLoading(false);
  }, [onCloseModal]);

  const getPlayers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/team/${selectedTeam._id}/team_players`
      );
      const respPlayers = response.data.players;

      const rollOvers = await checkRolloverPermission(
        targetTeamId,
        selectedTeam._id
      );

      if (rollOvers.length !== respPlayers.length) {
        setRolloverMessage(true);
      }
      setRolloverEliglible(rollOvers);

      const validPlayers = respPlayers
        .filter((player) => player.isActive)
        .sort(compareJerseyNum);
      setLoading(false);
      setPlayers(validPlayers);
      setSelectedIndices(validPlayers.map(() => false));
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (step === 2 && selectedTeam) {
      setPlayers([]);
      getPlayers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, selectedTeam]);

  const onContinue = async () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      setLoading(true);
      const updatedPlayers = [];
      let rolloverErrors = [];
      // eslint-disable-next-line no-restricted-syntax

      for await (const player of players.filter((_, x) => selectedIndices[x])) {
        const newPlayer = { ...player };
        delete newPlayer._id;

        await axios
          .post(`/api/team/${targetTeamId}/team_players`, {
            teamId: targetTeamId,
            player: newPlayer,
            isRollover: true,
          })
          .then((response) => {
            updatedPlayers.push(response.data.player);
          })
          .catch((err) => {
            if (err.response.status === 400) {
              rolloverErrors = [
                ...rolloverErrors,
                {
                  title: 'Claim Limit Reached',
                  message: err.response.data.message,
                },
              ];
            } else {
              setError(err.response.data.message);
            }
          });
      }
      if (rolloverErrors.length > 0) {
        setMessageModal(rolloverErrors);
        onUpdatedPlayers(updatedPlayers);
      } else {
        onUpdatedPlayers(updatedPlayers);
        onCloseModal();
      }
      //   onCloseModal
    }
  };

  const teamsList = (
    <>
      <Heading margin={{ top: 'none' }} level={3}>
        Roll over existing players
      </Heading>
      <Text>Add players from one of your existing teams</Text>
      {loading && <BasicLoader />}
      <Box>
        {userStore.userTeams
          .filter((team) => team._id !== targetTeamId)
          .sort((a, b) =>
            a.nameMed.toLowerCase() > b.nameMed.toLowerCase() ? 1 : -1
          )
          .map((team) => {
            const teamAgeText =
              team.attrib_ageU > 0
                ? `${team.attrib_ageU}U`
                : `${team.attrib_ageLevel}`;
            const teamSubtitle = `${team.attrib_sportType} - ${teamAgeText}`;

            return (
              <Card
                key={team._id}
                pad="medium"
                width="100%"
                margin={{ top: 'medium' }}
                gap="xsmall"
                background="secondary-7"
                round="small"
                onClick={() => {
                  setSelectedTeam(team);
                  onContinue();
                }}
              >
                <Box direction="row" justify="between" gap="medium">
                  <Box gap="xsmall">
                    <Text weight="bold">{`${team.nameLong}`}</Text>
                    <Text size="small">{teamSubtitle}</Text>
                  </Box>
                </Box>
              </Card>
            );
          })}
      </Box>
    </>
  );

  const playersList = (
    <>
      <Heading margin={{ top: 'none' }} level={3}>
        {`Select players from ${selectedTeam ? selectedTeam.nameMed : 'team'}`}
      </Heading>
      <Text>The players you select will be added to your team.</Text>
      {rolloverMessage && (
        <Text size="small" color="red" margin={{ top: '.5rem' }}>
          {
            'Manager Claim Limit Exceeded. Some Players are not Eligible to Rollover.'
          }
        </Text>
      )}
      {loading && <BasicLoader />}
      <Box>
        {players.map((player, i) => {
          const playerTitle = `${player.nameFirst} ${player.nameLast} #${player.jerseyNum}`;
          return (
            <Card
              key={player.claimedPlayerId || i}
              pad="medium"
              width="100%"
              margin={{ top: 'medium' }}
              gap="xsmall"
              background={selectedIndices[i] ? 'secondary-1' : 'secondary-7'}
              round="small"
            >
              <CheckBox
                disabled={
                  existingPlayerIds.includes(player.claimedPlayerId) ||
                  !rolloverEliglible.includes(player._id)
                }
                checked={selectedIndices[i]}
                onChange={(event) => {
                  setSelectedIndices(
                    selectedIndices.map((el, j) => {
                      if (i === j) {
                        return event.target.checked;
                      }
                      return el;
                    })
                  );
                }}
                label={
                  <Box direction="row" justify="between" gap="medium">
                    <Box gap="xsmall">
                      <Text weight="bold">{`${playerTitle}`}</Text>
                    </Box>
                  </Box>
                }
              />
            </Card>
          );
        })}
      </Box>
      {players.length === 0 && !loading && (
        <Text margin={{ top: 'medium' }}>This team does not have players</Text>
      )}
    </>
  );

  return (
    <Layer id="outer-layer" modal full="vertical" style={{ overflow: 'auto' }}>
      <Box
        direction="row"
        alignSelf={step === 1 && 'end'}
        justify="between"
        flex={false}
        margin="medium"
      >
        {step > 1 && (
          <Button
            icon={<LinkPrevious size="medium" />}
            onClick={() => {
              setStep(step - 1);
            }}
          />
        )}
        <Button icon={<Close size="medium" />} onClick={onCloseModal} />
      </Box>
      <Box
        fill="horizontal"
        overflow="auto"
        flex={false}
        pad={{ left: 'large', right: 'large' }}
        gap="medium"
        margin={{ bottom: '120px' }}
      >
        {step === 1 && teamsList}
        {step === 2 && players && playersList}
        {error && <Text color="status-critical">{error}</Text>}
        <Box margin={{ top: 'medium' }}>
          {step === 2 && (
            <Button
              size="large"
              primary
              color="tertiary-1"
              disabled={
                players.length === 0 ||
                selectedIndices.filter((i) => i).length === 0 ||
                loading
              }
              label={<Text weight="bold">Add players</Text>}
              onClick={onContinue}
            />
          )}
        </Box>
      </Box>

      {messageModal && (
        <GenericMessageModal
          multilinePayload={messageModal}
          closeModal={handleMessageModal}
        />
      )}
    </Layer>
  );
};

export default RolloverPlayerLayer;
