import { analytics } from '../../services/analytics';

export enum MixpanelActivityEvents {
  LESSONS_FOR_YOU = 'Lessons You for Button Clicked',
  ACTIVITY_VIEWED = 'Activity Viewed',
  START_ACTIVITY = 'Start Activity Clicked',
  GET_DK_CARD = 'Get DK Card Clicked',
}

interface MixpanelActivityProps {
  activityUuid?: string;
  activityEngineType?: string;
  activityName?: string;
  sport?: string;
  activityType?: string;
  gradYear?: number;
  claimedPlayerId?: string;
}

export const onMixpanelActivity = (
  event: MixpanelActivityEvents,
  properties: MixpanelActivityProps
) => {
  analytics.track(event, {
    source: 'DK Weekly Recommendations',
    ...properties,
  });
};
