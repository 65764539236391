// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Image } from 'grommet';

const IconPerfectGame = () => (
  <Image style={{ width: '44px', height: '43px' }} src="perfectGameIcon.svg" />
);

export default IconPerfectGame;
