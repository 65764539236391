import React, { ReactNode, useState, useContext } from 'react';
import { Box } from 'grommet';
import YouTubeAccessWarningModal from '../features/TeamPage/YouTubeAccessWarningModal';
import { PrivacyStatus, YTAsset } from '../features/TeamPage/Games/games.types';
import axios from '../helpers/axios';
import { UserContext } from '../services/Session';
import { AppHostContext } from '../services/appHost';

interface PrivacyAwareYTLinkProps {
  teamId?: string;
  gameId: string;
  children: ReactNode;
  ytAsset: YTAsset;
}

const PrivacyAwareYTLink: React.FC<PrivacyAwareYTLinkProps> = ({
  teamId,
  gameId,
  children,
  ytAsset,
}) => {
  const userStore = useContext(UserContext);
  const appHost = useContext(AppHostContext);
  const appHostService = appHost.isHosted ? appHost.service : undefined;

  const userTeamIds = userStore.userTeams
    ? userStore.userTeams.map((team) => team._id)
    : [];
  teamId = teamId || userStore.selectedTeam?._id || '';

  const isAdmin = userTeamIds.includes(teamId);

  const isFollower = userStore.teamFollowerIds
    .concat(userTeamIds)
    .includes(teamId);

  const claimedPlayerTeamIds = Array.from(
    new Set(userStore.userPlayers.flatMap((p) => p.DNOR_teamIdPriority))
  );
  const [selectedYTAsset, setSelectedYTAsset] = useState<YTAsset | null>(null);
  const [loading, setLoading] = useState(false);

  const handleLinkClick = () => {
    setLoading(true);
    axios.get(`/api/game/${gameId}/youtube_stream_privacy`).then((resp) => {
      setLoading(false);

      const { privacyStatus } = resp.data;

      if (privacyStatus === PrivacyStatus.Public) {
        if (appHost.isHosted) {
          appHostService?.notifications.openAppLink(ytAsset.cdnUrl);
        } else {
          window.location.href = ytAsset.cdnUrl;
        }
        return;
      } else if (privacyStatus === PrivacyStatus.Unlisted) {
        // if current user is logged in team manager, coach, claimedPlayer admin of a player on the team,
        // then open the link
        // else show a modal that says it's restricted

        if (
          isAdmin ||
          isFollower ||
          (teamId && claimedPlayerTeamIds.includes(teamId))
        ) {
          if (appHost.isHosted) {
            appHostService?.notifications.openAppLink(ytAsset.cdnUrl);
          } else {
            window.location.href = ytAsset.cdnUrl;
          }
          setSelectedYTAsset(null);
          return;
        }
      }
      setSelectedYTAsset({ ...(ytAsset || {}), privacyStatus });
    });
  };

  return (
    <>
      <Box onClick={handleLinkClick}>
        {React.cloneElement(
          React.Children.only(children) as React.ReactElement,
          {
            style: {
              ...((React.isValidElement(children)
                ? children.props.style
                : {}) ?? {}),
              opacity: loading ? 0.5 : 1,
            },
          }
        )}
      </Box>
      {selectedYTAsset && (
        <YouTubeAccessWarningModal
          ytAsset={selectedYTAsset}
          onClose={() => setSelectedYTAsset(null)}
        />
      )}
    </>
  );
};

export default PrivacyAwareYTLink;
