import React from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import { shd } from '@shd/jslib/models';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { analytics } from '../../services/analytics';
import { TEAM_SETTINGS__PUBLISHING } from '../../constants/routes';

export enum GoLiveWarningErrorType {
  NO_ROSTER = 'no_roster',
  NO_DESTINATION = 'no_destination',
}

interface Props extends RouteComponentProps {
  team: shd.Team;
  errorType: GoLiveWarningErrorType;
  onClose: () => void;
  onBypassWarning: () => void;
}
const GoLiveWarningModal: React.FC<Props> = ({
  team,
  history,
  errorType,
  onClose,
  onBypassWarning,
}) => {
  return (
    <Layer onEsc={onClose} onClickOutside={onClose} modal>
      <Box pad="16px" gap={'8px'}>
        {errorType === GoLiveWarningErrorType.NO_ROSTER && (
          <Text weight={'bold'} color={'status-critical'}>
            No roster added
          </Text>
        )}
        {errorType === 'no_destination' && (
          <Text weight={'bold'} color={'status-critical'}>
            No Stream Destination
          </Text>
        )}
        {errorType === GoLiveWarningErrorType.NO_ROSTER && (
          <Text>
            {`If you stream with no roster, you’ll be unable to create individual player highlight clips. Add a roster below!`}
          </Text>
        )}
        {errorType === 'no_destination' && (
          <Text>
            If you stream with no destination, your stream will not go anywhere.
            Link a destination below!
          </Text>
        )}
        <Box pad={{ top: '8px' }} gap="8px">
          {errorType === GoLiveWarningErrorType.NO_ROSTER && (
            <Button
              primary
              label="Add Roster"
              onClick={() => {
                analytics.track('Go Live Warning: Add Roster Clicked', {
                  errorType: errorType,
                  teamId: team._id,
                });
                // Note: when this is in the new LS experience, redirect to roster tab
                onClose();
              }}
            />
          )}
          {errorType === GoLiveWarningErrorType.NO_ROSTER && (
            <Button
              secondary
              label="Stream Without Roster"
              onClick={() => {
                analytics.track(
                  'Go Live Warning: Stream Without Roster Clicked',
                  {
                    teamId: team._id,
                    errorType: errorType,
                  }
                );
                localStorage.setItem(`${team._id}_streamWithoutRoster`, 'true');
                onClose();
                onBypassWarning();
              }}
            />
          )}
          {errorType === GoLiveWarningErrorType.NO_DESTINATION && (
            <Button
              primary
              label="Link Destination"
              onClick={() => {
                analytics.track('Go Live Warning: Link Destination Clicked', {
                  teamId: team._id,
                  errorType: errorType,
                });
                history.push(TEAM_SETTINGS__PUBLISHING);
              }}
            />
          )}
          {errorType === GoLiveWarningErrorType.NO_DESTINATION && (
            <Button
              secondary
              label="Stream Without Destination"
              onClick={() => {
                analytics.track(
                  'Go Live Warning: Stream Without Destination Clicked',
                  {
                    teamId: team._id,
                    errorType: errorType,
                  }
                );
                localStorage.setItem(
                  `${team._id}_streamWithoutDestination`,
                  'true'
                );
                onClose();
                onBypassWarning();
              }}
            />
          )}
          <Button secondary label="Cancel" onClick={onClose} />
        </Box>
      </Box>
    </Layer>
  );
};

export default withRouter(GoLiveWarningModal);
