// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';

import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import './videoPlaylist.css';

import PlaylistPlayer from './PlaylistPlayer';
import PlaylistItems from './PlaylistItems';

import { VideoPlayListContainer, PlaylistVideo } from './styles';
import axios from '../../helpers/axios';
import withLayout from '../../helpers/withLayout';
import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';

const VideoPlaylist = () => {
  const userStore = useContext(UserContext);
  const userPlayerIds = userStore.userPlayers
    ? userStore.userPlayers.map((player) => player._id)
    : [];

  const isGodMode = useIsFeatureEnabled('view_all_clips_enabled', userStore);

  const { playlistId } = useParams();
  const playerRef = useRef(null);

  const [canEdit, setCanEdit] = useState(isGodMode);

  const [claimedPlayerId, setClaimedPlayerId] = useState();
  const [player, setPlayer] = useState();

  const [playlistData, setPlaylistData] = useState(null);
  const [clipList, setClipList] = useState(null);

  const [currentId, setCurrentId] = useState(null);
  const [isShuffled, setIsShuffled] = useState(false);

  const [forcePause, setForcePause] = useState(false);

  function mapClipList(sources) {
    return sources?.map(
      ({ fileDownloadUrl, titleMain, titleSub, clipId, ...rest }) => ({
        sources: [
          {
            src: fileDownloadUrl,
            titleMain,
            titleSub,
            id: clipId,
            type: 'video/mp4',
            ...rest,
          },
        ],
      })
    );
  }

  useEffect(() => {
    if (claimedPlayerId) {
      axios.get(`/api/player/${claimedPlayerId}`).then((response) => {
        setPlayer(response.data.player);
      });
    }
  }, [claimedPlayerId]);

  useEffect(
    () => {
      axios
        .get(`/api/reels/${playlistId}`)
        .then((response) => {
          const filteredData = {
            ...response.data,
            clipList: response.data.clipList.filter(Boolean),
          };
          // eslint-disable-next-line camelcase
          const { category, J_claimedPlayerId } = response.data;
          if (category === 'claimedPlayer') {
            setClaimedPlayerId(J_claimedPlayerId);
            setCanEdit(userPlayerIds.includes(J_claimedPlayerId) || isGodMode);
          }
          setPlaylistData(filteredData);
          setClipList(mapClipList(filteredData.clipList));
          const { clipId } = filteredData.clipList[0];
          setCurrentId(clipId);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function shuffleArray(list) {
    return list
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  const playList = useMemo(
    () => (isShuffled ? shuffleArray(clipList) : clipList),
    [clipList, isShuffled]
  );

  const handleReorderPlaylist = (list) => {
    setClipList(list);
  };

  const setTrack = (id) => {
    const itemIndex = playList?.findIndex(
      ({ sources }) => sources[0].id === id
    );
    playerRef.current.playlist.currentItem(itemIndex);
  };

  return (
    <VideoPlayListContainer>
      <PlaylistVideo>
        {clipList && (
          <PlaylistPlayer
            ref={playerRef}
            myKey={playlistId}
            playList={playList}
            setCurrentId={setCurrentId}
            forcePause={forcePause}
          />
        )}
      </PlaylistVideo>
      <PlaylistItems
        playlistId={playlistId}
        isShuffled={isShuffled}
        setIsShuffled={setIsShuffled}
        playlistData={playlistData}
        mainTitle={playlistData?.titleMain}
        player={player}
        playlist={playList}
        onReorderList={handleReorderPlaylist}
        setTrack={setTrack}
        currentId={currentId}
        canEdit={canEdit}
        onEdited={(editedPlaylist) => {
          const newPlaylistData = { ...editedPlaylist };
          setPlaylistData(newPlaylistData);
          setClipList(mapClipList(newPlaylistData.clipList));
        }}
        onOpen={() => {
          setForcePause(true);
        }}
        onClose={() => {
          setForcePause(false);
        }}
      />
    </VideoPlayListContainer>
  );
};

export default withLayout(VideoPlaylist);
