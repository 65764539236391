"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryParams = exports.noopAsync = exports.noop = exports.wait = exports.coverCases = exports.coverEveryCase = void 0;
function coverEveryCase(arg, cases) {
    const foundCase = cases[arg];
    const result = typeof foundCase === 'function' ? foundCase() : foundCase;
    return result;
}
exports.coverEveryCase = coverEveryCase;
function coverCases(arg, cases, defaultCase) {
    const foundCase = cases[arg];
    if (foundCase) {
        const result = typeof foundCase === 'function' ? foundCase() : foundCase;
        return result;
    }
    return defaultCase();
}
exports.coverCases = coverCases;
async function wait(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
exports.wait = wait;
function noop() { }
exports.noop = noop;
function noopAsync() {
    return Promise.resolve();
}
exports.noopAsync = noopAsync;
function getQueryParams(url) {
    const queryParams = {};
    // Extract the query string from the URL
    const queryString = url.split('?')[1];
    if (!queryString) {
        return queryParams;
    }
    // Split the query string into individual key-value pairs
    const pairs = queryString.split('&');
    // Iterate over each key-value pair and add it to the result object
    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    }
    return queryParams;
}
exports.getQueryParams = getQueryParams;
