import React from 'react';
import { Box, Select, Text } from 'grommet';
import { GEO_US_CA, GEO_US_NY, GEO_US_TX } from '../../constants/strings';

interface Props {
  goProOption: string;
  setGoProOption: (option: string) => void;
}

const GoProSelectServer: React.FC<Props> = ({
  goProOption,
  setGoProOption,
}) => {
  return (
    <Box pad="medium" gap="16px" flex={{ shrink: 0, grow: 1 }}>
      <Text weight="bold">Pick the closest server</Text>
      <Box>
        <Text size="small" weight="bold">
          Server location
        </Text>
        <Select
          options={[GEO_US_NY, GEO_US_TX, GEO_US_CA]}
          value={goProOption}
          onChange={({ option }) => {
            setGoProOption(option);
          }}
        />
      </Box>
    </Box>
  );
};

export default GoProSelectServer;
