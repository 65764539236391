// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { makeAutoObservable } from 'mobx';
import zlib from 'browserify-zlib';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import EventHistoryParser from '../../scripts/parseEventHistoryGeneric';
import axios from '../../helpers/axios';
import { getCurrentPeriodScoreAndIndex } from '../../helpers/utils';
import { strogging } from '@shd/jslib/infra';
import { inflate, deflate } from './scoringUtil';

const history = createBrowserHistory();

const generateInfo = (scoreboard) => {
  let now = new Date(scoreboard.scoreboardTs);
  now = now.toLocaleString();
  const { teamHPeriodScores, teamAPeriodScores } = scoreboard;
  const { currentScore: teamHScore } =
    getCurrentPeriodScoreAndIndex(teamHPeriodScores);

  const { currentScore: teamAScore } =
    getCurrentPeriodScoreAndIndex(teamAPeriodScores);

  const infoString = `${now} | Home (${parseInt(
    teamHScore
  )}) - Away (${parseInt(teamAScore)})`;
  return infoString;
};

class GenericScoringStore {
  firebase = null;

  prompt = null;

  teamId = null;

  gameId = null;

  scoreGameId = null;

  scoreGameKey = null;

  eventHistory = [];

  sportType = null;

  gameEventTypes = null;

  gameInfo = null;

  scoreboard = null;

  homeLineup = [];

  homeStartingLineup = [];

  awayLineup = [];

  awayStartingLineup = [];

  clipEventsList = [];

  isQuickStartMode = false;

  awayHome = null;

  isInitialized = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setFirebase(firebase) {
    this.firebase = firebase;
  }

  setPrompt(prompt) {
    this.prompt = prompt;
  }

  setTeamId(teamId) {
    this.teamId = teamId;
  }

  setGameId(gameId) {
    this.gameId = gameId;
  }

  setScoreGameId(gameId) {
    this.scoreGameId = gameId;
  }

  setScoreGameKey(gameKey) {
    this.scoreGameKey = gameKey;
  }

  setAwayHome(awayHome) {
    this.awayHome = awayHome;
  }

  toggleIsInitialized(bool) {
    this.isInitialized = bool;
  }

  setEventHistory(eventHistory) {
    this.eventHistory = eventHistory;
  }

  setSportType(sportType) {
    this.sportType = sportType;
  }

  setGameEventTypes(data) {
    this.gameEventTypes = data;
  }

  setGameInfo(gameInfo) {
    this.gameInfo = gameInfo;
  }

  setScoreboard(scoreboard) {
    this.scoreboard = scoreboard;
  }

  setHomeLineup(lineup) {
    this.homeLineup = lineup;
  }

  setHomeStartingLineup(lineup) {
    this.homeStartingLineup = lineup;
  }

  setAwayLineup(lineup) {
    this.awayLineup = lineup;
  }

  setAwayStartingLineup(lineup) {
    this.awayStartingLineup = lineup;
  }

  setClipEventsList(list) {
    this.clipEventsList = list;
  }

  toggleIsQuickStartMode(bool) {
    this.isQuickStartMode = bool;
  }

  setGeoCoords() {
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    })
      .then((location) => {
        const { coords } = location;
        const newGameInfo = { ...this.gameInfo };
        const lat = coords.latitude;
        const lng = coords.longitude;
        const latLng = [lat, lng].map((coord) => {
          if (coord >= 0) {
            return `+${coord.toString()}`;
          }
          return coord.toString();
        });
        [newGameInfo.locationLat, newGameInfo.locationLng] = latLng;
        this.setGameInfo(newGameInfo);
      })
      .catch(() => {});
  }

  onGameEnd() {
    const body = {
      teamId: this.teamId,
      teamGameId: this.gameId,
      teamGameUpdate: {
        scoreGameState: 50,
        scheduleState: 50,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        history.push('/scoring');
        window.location.reload();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getStateScoreboard = (scoreboardJson) => {
    const {
      teamHPeriodScores,
      teamAPeriodScores,
      currentPeriodNum,
      isCurrentPeriodStarted,
      scoreboardTeamPos,
      gameState,
    } = JSON.parse(scoreboardJson);

    const stateScoreboard = {
      h: teamHPeriodScores.reduce((partialSum, a) => partialSum + a, 0),
      a: teamAPeriodScores.reduce((partialSum, a) => partialSum + a, 0),

      teamHPeriodScores,
      teamAPeriodScores,

      currentPeriodNum,
      isCurrentPeriodStarted,

      scoreboardTeamPos,
      gameState,
    };

    return stateScoreboard;
  };

  promptUser = async (event, onResponse) =>
    new Promise((resolve) => {
      this.setPrompt({
        event,
        resolve,
      });
    })
      .then(onResponse)
      .catch((err) => Sentry.captureException(err));

  subscribeToGame() {
    this.firebase.subscribeToGame(
      this.teamId,
      this.scoreGameId,
      async (snapshot, err = null) => {
        if (snapshot) {
          const {
            scoreboardJson,
            homeLineup,
            homeLineupCompressed,
            awayLineup,
            awayLineupCompressed,
            eventHistory,
            eventHistoryCompressed,
            isQuickStartMode,
          } = snapshot;

          if (!scoreboardJson) {
            strogging.error('scoreboardJson is null', snapshot);
            return;
          }

          const stateScoreboard = this.getStateScoreboard(scoreboardJson);

          let resolvedHomeLineup;
          if (homeLineupCompressed) {
            const homeLineupCompressedBuffer = Buffer.from(
              homeLineupCompressed,
              'base64'
            );
            const decompressedHomeLineupBuffer = await inflate(
              homeLineupCompressedBuffer
            );
            const decompressedHomeLineup =
              decompressedHomeLineupBuffer.toString('utf8');
            resolvedHomeLineup = JSON.parse(decompressedHomeLineup);
          } else {
            resolvedHomeLineup = homeLineup;
          }

          let resolvedAwayLineup;
          if (awayLineupCompressed) {
            const awayLineupCompressedBuffer = Buffer.from(
              awayLineupCompressed,
              'base64'
            );
            const decompressedAwayLineupBuffer = await inflate(
              awayLineupCompressedBuffer
            );
            const decompressedAwayLineup =
              decompressedAwayLineupBuffer.toString('utf8');
            resolvedAwayLineup = JSON.parse(decompressedAwayLineup);
          } else {
            resolvedAwayLineup = awayLineup;
          }

          this.setHomeLineup(resolvedHomeLineup);
          this.setAwayLineup(resolvedAwayLineup);
          this.setScoreboard(stateScoreboard);
          let resolvedEventHistory;
          if (eventHistoryCompressed) {
            try {
              const eventHistoryCompressedBuffer = Buffer.from(
                eventHistoryCompressed,
                'base64'
              );
              const decompressedEventHistoryBuffer = await inflate(
                eventHistoryCompressedBuffer
              );
              const decompressedEventHistory =
                decompressedEventHistoryBuffer.toString('utf8');
              resolvedEventHistory = JSON.parse(decompressedEventHistory);
            } catch (e) {
              Sentry.captureException(e);
              if (Array.isArray(eventHistoryCompressed)) {
                // weird. fixes issue where eventHistoryCompressed is somehow not compressed
                resolvedEventHistory = eventHistoryCompressed;
              }
            }
          } else {
            resolvedEventHistory = eventHistory;
          }
          this.setEventHistory(resolvedEventHistory);
          this.toggleIsQuickStartMode(isQuickStartMode);
        } else {
          Sentry.captureMessage(err);
        }
      }
    );
  }

  unsubscribeFromGame() {
    if (this.firebase) {
      this.firebase.unsubscribeFromGame(this.teamId, this.scoreGameId);
    }
    this.clearStore();
  }

  createScoreboard = (
    scoreboardList,
    clipEventsList,
    latestScoreboard,
    homeLineup,
    awayLineup,
    eventHistory,
    isQuickStartMode
  ) => {
    this.setClipEventsList(clipEventsList);
    console.log('clipEventsList', clipEventsList);
    // console.log('scoreboardList', scoreboardList);
    // console.log('latestScoreboard', latestScoreboard);
    const scoreboardListJson = Buffer.from(
      JSON.stringify(scoreboardList),
      'utf8'
    );
    const clipEventsListJson = Buffer.from(
      JSON.stringify(clipEventsList),
      'utf8'
    );
    const eventHistoryJson = Buffer.from(JSON.stringify(eventHistory), 'utf8');
    const homeLineupJson = Buffer.from(JSON.stringify(homeLineup), 'utf8');
    const awayLineupJson = Buffer.from(JSON.stringify(awayLineup), 'utf8');

    const scoreboardListPromise = new Promise((resolve, reject) => {
      zlib.deflate(scoreboardListJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const clipEventsListPromise = new Promise((resolve, reject) => {
      zlib.deflate(clipEventsListJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const eventHistoryJsonPromise = new Promise((resolve, reject) => {
      zlib.deflate(eventHistoryJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const homeLineupPromise = new Promise((resolve, reject) => {
      zlib.deflate(homeLineupJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const awayLineupPromise = new Promise((resolve, reject) => {
      zlib.deflate(awayLineupJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    Promise.all([
      scoreboardListPromise,
      clipEventsListPromise,
      eventHistoryJsonPromise,
      homeLineupPromise,
      awayLineupPromise,
    ]).then((values) => {
      const scoreboardListCompressed = values[0];
      const clipEventsListCompressed = values[1];
      const eventHistoryCompressed = values[2];
      const homeLineupCompressed = values[3];
      const awayLineupCompressed = values[4];

      const newScoreboardDoc = {
        scoreTeamId: this.teamId,
        scoreGameId: this.scoreGameId,
        shdTeamLineup: this.awayHome
          ? this.homeStartingLineup.slice(0, 6)
          : this.awayStartingLineup.slice(0, 6),
        doc: {
          info: generateInfo(latestScoreboard),
          ts: latestScoreboard.scoreboardTs,
          teamId: this.teamId,
          localTzString: Intl.DateTimeFormat().resolvedOptions().timeZone,
          localTzSource: 'profile',
          scoreGameId: this.scoreGameId,
          scoreTeamIdList: [
            this.gameInfo.teamAScoreTeamId,
            this.gameInfo.teamHScoreTeamId,
          ],
          teamIdIsHome: !!this.awayHome,
          scorePlayerIdList: null, // get this
          scoreboardJson: JSON.stringify(latestScoreboard),
          scoreboardListCompressed,
          clipEventsListCompressed,

          // sport type
          sportType: this.sportType,

          // Sscore specific fields
          isQuickStartMode,
          eventHistoryCompressed,
          homeLineupCompressed,
          awayLineupCompressed,
          isGameEnd: latestScoreboard.isGameEnd,
        },
      };

      this.firebase.createScoreboard(
        this.scoreGameKey,
        newScoreboardDoc,
        (err = null) => {
          if (err) {
            Sentry.captureMessage(err);
          } else if (newScoreboardDoc.doc.isGameEnd) {
            this.onGameEnd();
          }
        }
      );

      // Remove this write as scoreTsEnd update is handled by daemon process
      // basically need to update scoreTsEnd on the game for the live test to work
      // const game = {
      //  scoreTsEnd: plateAppearanceEndTs,
      // };
      // this.firebase.updateGame(this.teamId, this.gameId, game);
    });
  };

  async handleEvent(eventType, eventInfo = {}) {
    const now = Date.now() / 1000;
    const newEvent = { ts: now, eventType, eventInfo };
    const newEventHistory = [...this.eventHistory, newEvent];

    // console.log(this.homeStartingLineup, this.awayStartingLineup);

    const parser = new EventHistoryParser(
      newEventHistory,
      this.gameInfo,
      _.cloneDeep(this.homeStartingLineup),
      _.cloneDeep(this.awayStartingLineup),
      this.gameEventTypes,
      this.promptUser,
      this.isQuickStartMode
    );

    try {
      const {
        scoreboardList,
        clipEventsList,
        latestScoreboard,
        homeLineup,
        awayLineup,
        eventHistory,
        isQuickStartMode,
      } = await parser.parseEventHistory();
      // console.log('scoreboardList', scoreboardList);
      // console.log('clipEventsList', clipEventsList);
      // console.log('latestScoreboard', latestScoreboard);
      // console.log('homeLineup', homeLineup);
      // console.log('awayLineup', awayLineup);
      // console.log('eventHistory', eventHistory);
      // console.log('isQuickStartMode', isQuickStartMode);

      if (scoreboardList) {
        this.createScoreboard(
          scoreboardList,
          clipEventsList,
          latestScoreboard,
          homeLineup,
          awayLineup,
          eventHistory,
          isQuickStartMode
        );
      } else {
        const eventHistoryJson = Buffer.from(
          JSON.stringify(eventHistory),
          'utf8'
        );
        const eventHistoryCompressedBuffer = await deflate(eventHistoryJson);
        const eventHistoryCompressed =
          eventHistoryCompressedBuffer.toString('base64');
        this.firebase.updateEventHistory(
          this.teamId,
          this.scoreGameKey,
          eventHistoryCompressed,
          (err) => {
            Sentry.captureException(err);
          }
        );
      }
    } catch (err) {
      strogging.error('Error in handleEvent', {
        err,
        eventHistory: newEventHistory,
      });
      Sentry.captureException(err);
    }
  }

  async initGameState(
    sportType,
    gameEventTypes,
    game,
    scoreboard,
    firebase,
    forceResume = false
  ) {
    this.setSportType(sportType);
    this.setGameEventTypes(gameEventTypes);
    this.setFirebase(firebase);
    this.setTeamId(game.scoreTeamIdOurs);
    this.setScoreGameId(game.scoreGameId);
    this.setScoreGameKey(game.scoreGameKey);
    this.setGameId(`GmSh01-${game.scoreGameId}`);

    const gameInfo = {
      sportType,
      scoreGameId: game.scoreGameId,
      locationLat: '',
      locationLng: '',
      teamHNameMed: game.scoreWeAreHome
        ? game.scoreOurName
        : game.scoreOpponentName,
      teamHShortName: 'HM',
      teamANameMed: !game.scoreWeAreHome
        ? game.scoreOurName
        : game.scoreOpponentName,
      teamAShortName: 'AWY',
      teamHScoreTeamId: game.scoreWeAreHome ? game.scoreTeamIdOurs : '',
      teamAScoreTeamId: !game.scoreWeAreHome ? game.scoreTeamIdOurs : '',
      matchupTitle: `${game.scoreOurName} ${
        game.scoreWeAreHome ? 'vs.' : '@'
      } ${game.scoreOpponentName}`,
    };

    const now = Date.now() / 1000;
    let eventHistory = [{ eventType: 'startGame', eventInfo: {}, ts: now }];

    let stateScoreboard = {
      h: 0,
      a: 0,

      teamHMatchScore: 0,
      teamAMatchScore: 0,
    };

    if (scoreboard) {
      const {
        eventHistory: respEventHistory,
        eventHistoryCompressed: respEventHistoryCompressed,
        scoreboardJson,
      } = scoreboard;

      if (respEventHistoryCompressed) {
        try {
          const eventHistoryCompressedBuffer = Buffer.from(
            respEventHistoryCompressed,
            'base64'
          );
          const decompressedEventHistoryBuffer = await inflate(
            eventHistoryCompressedBuffer
          );
          const decompressedEventHistory =
            decompressedEventHistoryBuffer.toString('utf8');
          eventHistory = JSON.parse(decompressedEventHistory);
        } catch (err) {
          Sentry.captureException(err);
          if (Array.isArray(respEventHistoryCompressed)) {
            // weird. fixes issue where eventHistoryCompressed is somehow not compressed
            eventHistory = respEventHistoryCompressed;
          }
        }
      } else {
        // backwards compatibility with old eventHistory
        eventHistory = respEventHistory;
      }

      if (respEventHistory) {
        // delete eventHistory from firebase
        this.firebase.deleteEventHistory(this.teamId, this.scoreGameKey);
      }

      if (forceResume) {
        // remove endGame event
        eventHistory = eventHistory.filter(
          (event) => event.eventType !== 'endGame'
        );
      }

      stateScoreboard = this.getStateScoreboard(scoreboardJson);
    }

    this.setAwayHome(game.scoreWeAreHome ? 1 : 0);

    const scoreOurLineup = [...game.scoreOurLineup];

    // concat team players so that they can be subbed in if so desired
    const teamPlayers = game.teamPlayers.map((player) => ({
      _id: player._id,
      nameFirst: player.nameFirst,
      nameLast: player.nameLast,
      jerseyNum: player.jerseyNum,
    }));
    const scoreOurLineupIds = scoreOurLineup.map((p) => p._id);
    teamPlayers.forEach((p) => {
      if (!scoreOurLineupIds.includes(p._id)) {
        scoreOurLineup.push(p);
      }
    });

    this.setHomeLineup(game.scoreWeAreHome ? scoreOurLineup : []);
    this.setAwayLineup(game.scoreWeAreHome ? [] : scoreOurLineup);

    // TODO: Not implemented
    this.setHomeStartingLineup(_.cloneDeep(this.homeLineup));
    this.setAwayStartingLineup(_.cloneDeep(this.awayLineup));

    this.setEventHistory(eventHistory);
    this.setGameInfo(gameInfo);

    this.scoreboard = stateScoreboard;

    this.toggleIsQuickStartMode(game.isQuickStartMode);

    this.toggleIsInitialized(true);
    this.handleEvent('forceRun', {});
    this.setGeoCoords();

    const body = {
      teamId: game.scoreTeamIdOurs,
      teamGameId: `GmSh01-${game.scoreGameId}`,
      teamGameUpdate: {
        scoreGameState: 30,
        scheduleState: 30,
      },
    };

    axios.put('/api/team_game', body).catch((respError) => {
      Sentry.captureException(respError);
    });

    this.subscribeToGame();
  }

  clearStore() {
    this.firebase = null;
    this.setPrompt(null);
    this.teamId = null;
    this.gameId = null;
    this.scoreGameId = null;
    this.scoreGameKey = null;
    this.eventHistory = [];
    this.gameInfo = null;
    this.scoreboard = null;
    this.awayHome = null;
    this.isInitialized = false;
    this.isQuickStartMode = false;
  }
}

export default GenericScoringStore;
