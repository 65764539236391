import zlib from 'browserify-zlib';

export function deflate(data: unknown) {
  return new Promise((resolve, reject) => {
    zlib.deflate(data, (err: Error | null, buffer: unknown) => {
      if (err) {
        reject(err);
      } else {
        resolve(buffer);
      }
    });
  });
}

export function inflate(data: unknown) {
  return new Promise((resolve, reject) => {
    zlib.inflate(data, (err: Error | null, buffer: unknown) => {
      if (err) {
        reject(err);
      } else {
        resolve(buffer);
      }
    });
  });
}
