import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { strogging } from '@shd/jslib/infra';
import { shd } from '@shd/jslib/models';
import {
  LiveConfig,
  LiveOnPlatform,
  LivePermalink,
  LiveGameInfo,
  pollIntervals,
} from './sidelineLive.types';

const useLiveConfig = (teamId: string, userId: string | undefined) => {
  const [liveConfig, setLiveConfig] = useState<LiveConfig>();
  useEffect(() => {
    let uid = `/api/team/${teamId}/config/${userId}`;
    if (!userId) {
      uid = `/api/team/${teamId}/config`;
    }
    axios
      .get(uid)
      .then((response) => {
        setLiveConfig(response.data);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [teamId, userId]);

  return liveConfig;
};

const useLiveOnPlatform = (teamId: string) => {
  const [liveStatus, setLiveStatus] = useState<LiveOnPlatform>();

  useEffect(() => {
    const fetchStatus = () =>
      axios
        .get(`/api/team/${teamId}/live_on_platform`)
        .then((response) => {
          setLiveStatus(response.data);
        })
        .catch((respError) => {
          strogging.log('SlOnPlatform - fetching live status error', respError);
          Sentry.captureException(respError);
        });
    const interval = setInterval(() => {
      fetchStatus();
    }, pollIntervals.THIRTY_SECONDS);

    return () => clearInterval(interval);
  }, [teamId]);

  return liveStatus;
};

const useLiveResolvePermalink = (
  teamId: string,
  params: {
    game?: string | undefined;
    ts?: string | undefined;
  }
) => {
  const [livePermalink, setLivePermalink] = useState<LivePermalink>();

  useEffect(() => {
    let url = `/api/team/${teamId}/permalink`;
    if (params.game) {
      url = url + `?game=${params.game}`;
    }
    if (params.ts) {
      url = url + `?ts=${params.ts}`;
    }
    strogging.log('SlOnPlatform - fetching live permalink', url);
    axios
      .get(url)
      .then((response) => {
        setLivePermalink(response.data);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [teamId, params.game, params.ts]);

  return livePermalink;
};

const useLiveGameInfo = (teamId: string, poll = pollIntervals.TWO_MINUTES) => {
  const [liveGameInfo, setLiveGameInfo] = useState<LiveGameInfo>();

  useEffect(() => {
    const fetchStatus = () =>
      axios
        .get(`/api/team/${teamId}/live_game_info`)
        .then((response) => {
          setLiveGameInfo(response.data);
        })
        .catch((respError) => {
          strogging.log(
            'SlOnPlatform - fetching live game info error',
            respError
          );
          Sentry.captureException(respError);
        });
    const interval = setInterval(() => {
      fetchStatus();
    }, poll);

    return () => clearInterval(interval);
  }, [teamId, poll]);

  return liveGameInfo;
};

const useLiveAssets = (teamId: string) => {
  const [liveAssets, setLiveAssets] = useState<shd.LiveAsset[]>([]);
  useEffect(() => {
    const fetchLiveAssest = () =>
      axios
        .get(`/api/team/${teamId}/live_assets`)
        .then((response) => {
          setLiveAssets(response.data.liveAssets);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    const interval = setInterval(() => {
      fetchLiveAssest();
    }, pollIntervals.SIXTY_SECONDS);

    return () => clearInterval(interval);
  }, [teamId]);

  return liveAssets;
};

export {
  useLiveOnPlatform,
  useLiveConfig,
  useLiveResolvePermalink,
  useLiveGameInfo,
  useLiveAssets,
};
