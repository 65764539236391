// Packages
import React, { useState } from 'react';

// Components
import { FormField, TextInput } from 'grommet';
import AdminClipCardList from './AdminClipCardList';

// Helpers and utils
import { useRelabelClips } from '../admin.hooks';
import { useDebouncedCallback } from '../../../helpers/useDebounce';

// Types
import { AdminSearchProps, ClipProps } from '../admin.types';

const AdminClipSearch: React.FC<AdminSearchProps<ClipProps>> = ({
  id,
  isCardSelected,
  label,
  onCardSelected,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncing, setDebouncing] = useState(false);

  const { clearClips, searchClips, searchClipsLoading, searchClipsResults } =
    useRelabelClips();

  const search = useDebouncedCallback(
    (text) => {
      isCardSelected(false);
      setDebouncing(false);
      if (text) {
        searchClips(text);
      } else {
        clearClips();
      }
    },
    800,
    { leading: false, trailing: true }
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setDebouncing(true);
    search(e.target.value);
  };

  const handleOnCardSelected = (isSelected: boolean, payload: ClipProps) => {
    isCardSelected(isSelected);
    onCardSelected && onCardSelected(payload);
  };

  return (
    <>
      <FormField id={id} justify="between" label={label}>
        <TextInput
          onChange={onChange}
          placeholder={placeholder}
          type="search"
          value={searchValue}
        />
      </FormField>
      <AdminClipCardList
        debouncing={debouncing}
        loading={searchClipsLoading}
        searchResults={searchClipsResults}
        onCardSelected={(isSelected, payload) => {
          handleOnCardSelected(isSelected, payload);
        }}
      />
    </>
  );
};

export default AdminClipSearch;
