// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from 'react';

import loggerContext from './context';

export default function useLogger() {
  const context = useContext(loggerContext);

  if (context === undefined) {
    throw new Error('useLogger must be used within a LoggerProvider');
  }

  return context;
}
