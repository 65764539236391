// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { ErrorHeading, ErrorMessage, ErrorWrapper } from './style';

const ErrorBox = ({ error }) => (
  <ErrorWrapper
    width="full"
    background="status-critical"
    pad="small"
    gap="small"
  >
    <ErrorHeading weight="bold">
      Sorry for the inconvenience.
    </ErrorHeading>
    <ErrorMessage>
      {error}
    </ErrorMessage>
  </ErrorWrapper>
);

export default ErrorBox;
