// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import { string } from 'yup';

import { FieldStyled, FieldWrapper, InputStyled } from './style';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';

const validationScheme = string()
  .required('Field is required')
  .min(5, 'Please enter a name 5 characters or more.')
  .max(30, 'Please enter a name 30 characters or fewer.');

const FieldNameLong = ({ value, handleForward }) => {
  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [error, setError] = useState(null);

  const validateOnEnter = useCallback(() => {
    validationScheme
      .validate(value.nameLong)
      .then(() => {
        setError(null);
        setIsValid(true);
        handleForward();
      })
      .catch((reason) => {
        setIsValid(false);
        setError(reason.message);
      });
  }, [value, handleForward]);

  useEffect(() => {
    validationScheme
      .validate(value.nameLong)
      .then(() => {
        setError(null);
        setIsValid(true);
      })
      .catch((reason) => {
        setIsValid(false);
        if (!isTouched) {
          return;
        }
        setError(reason.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">What is your team name?</FieldLabel>
          <FieldTip color="dark-4">* indicates required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled
          name="nameLong"
          required
          info="30 characters or fewer."
          error={error}
          onChange={() => setIsTouched(true)}
        >
          <InputStyled
            autoComplete="nope"
            placeholder="Team name*"
            name="nameLong"
            onKeyDown={(e) => {
              if (['Enter', 'NumpadEnter'].includes(e.key)) {
                e.preventDefault();
                validateOnEnter();
              }
            }}
          />
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton
        disabled={!isValid}
        label="Next"
        onClick={validateOnEnter}
      />
    </>
  );
};

export default FieldNameLong;
