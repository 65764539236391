"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiTeamIdLiveAssetsGet = exports.apiSendTelemetryPost = exports.apiNotificationUpdateUserPost = exports.apiNotificationTestMessagePost = exports.apiFacebookAuthGet = void 0;
const apiFacebookAuthGet = () => "/api/facebook_auth";
exports.apiFacebookAuthGet = apiFacebookAuthGet;
const apiNotificationTestMessagePost = () => "/api/notification/test_message";
exports.apiNotificationTestMessagePost = apiNotificationTestMessagePost;
const apiNotificationUpdateUserPost = () => "/api/notification/update_user";
exports.apiNotificationUpdateUserPost = apiNotificationUpdateUserPost;
const apiSendTelemetryPost = () => "/api/send_telemetry";
exports.apiSendTelemetryPost = apiSendTelemetryPost;
const apiTeamIdLiveAssetsGet = () => "/api/{teamId}/live_assets";
exports.apiTeamIdLiveAssetsGet = apiTeamIdLiveAssetsGet;
