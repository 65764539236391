import React, { useContext, useEffect } from 'react';
import { Box, Card, Heading, Text } from 'grommet';
import { useLocation } from 'react-router-dom';
import { MdIosShare } from 'react-icons/md';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { HiDotsHorizontal, HiDotsVertical } from 'react-icons/hi';

import Navigation from '../components/Navigation';
import { useQuery } from './utils';
import {
  isEmbeddedBrowser,
  isFacebookBrowser,
  isInstagramBrowser,
  isiOS,
} from './browserDetect';
import axios from './axios';
import { UserContext } from '../services/Session';
import { analytics } from '../services/analytics';

const withLayout =
  <Props extends object>(Component: React.ComponentType<Props>) =>
  (props: Props) => {
    const showBanner = isEmbeddedBrowser();
    const query = useQuery();
    const referrerUserId = query.get('from');
    const campaign = query.get('campaign');
    const source = query.get('s');
    const isEmbed = query.get('embed');
    const smsDestination = query.get('sms');

    const userStore = useContext(UserContext);

    // TODO: get these api calls out of here!
    const getUser = (userId: string | null) => {
      // TODO: fix response for this call -- return only name?
      axios
        .get(`/api/user/${userId}`)
        .then((res) => {
          userStore.setReferrerUser(res.data);
        })
        .catch(() => {});
    };

    const trackCampaign = () => {
      axios
        .post('/api/track_campaign', {
          campaign,
          source,
          referrerUserId,
          url: window.location.href,
        })
        .then(() => {
          userStore.setReferrerCampaign(campaign);
          window.history.pushState(
            {},
            document.title,
            window.location.pathname
          );
        })
        .catch(() => {});
    };

    useEffect(() => {
      if (smsDestination) {
        analytics.identify();
        analytics.setUserProperties({
          $phone: smsDestination,
        });
      }
    }, [smsDestination]);

    useEffect(() => {
      if (referrerUserId || localStorage.getItem('referrerUserId')) {
        const localReferrerUserId =
          referrerUserId || localStorage.getItem('referrerUserId');
        localStorage.setItem('referrerUserId', localReferrerUserId || '');
        getUser(localReferrerUserId);
      }

      if (campaign) {
        trackCampaign();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const conditions =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLocation().pathname.includes('/get-started') ||
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useLocation().pathname.includes('/signin');

    const renderEmbeddedBrowserWarning = () => {
      if (conditions) {
        let content = <></>;
        if (isiOS() && isFacebookBrowser()) {
          content = (
            <Box fill>
              <Heading level={3} margin={{ bottom: 'large' }}>
                To continue, open this page in your phone&apos;s default web
                browser.
              </Heading>
              <ArcherElement
                id="root"
                relations={[
                  {
                    targetId: 'element2',
                    targetAnchor: 'top',
                    sourceAnchor: 'bottom',
                  },
                ]}
              >
                <Card background="light-5" pad="small" gap="small">
                  <Box direction="row" gap="small" align="center">
                    <Text>First, press the Share icon</Text>
                    <Box>
                      <MdIosShare size="24px" />
                    </Box>
                  </Box>

                  <Box width="max-content">
                    <Text>
                      Then, press &quot;Open in Safari&quot;
                      {'   '}
                    </Text>
                  </Box>
                </Card>
              </ArcherElement>
              <Box>
                <ArcherElement id="element2">
                  <Box
                    width="xsmall"
                    style={{ position: 'absolute', right: 0, bottom: 48 }}
                  />
                </ArcherElement>
              </Box>
            </Box>
          );
        } else if (isFacebookBrowser() || (isInstagramBrowser() && isiOS())) {
          // non-iOS FB browser, iOS IG browser
          content = (
            <Box fill>
              <ArcherElement id="element2">
                <Box
                  width="xsmall"
                  style={{ position: 'absolute', right: 0, top: 20 }}
                />
              </ArcherElement>
              <ArcherElement
                id="root"
                relations={[
                  {
                    targetId: 'element2',
                    targetAnchor: 'left',
                    sourceAnchor: 'top',
                  },
                ]}
              >
                <Card
                  background="light-5"
                  pad="small"
                  gap="small"
                  margin={{ top: '64px' }}
                >
                  <Box direction="row" gap="small" align="center">
                    <Text>First, press this icon</Text>
                    <Box>
                      {isFacebookBrowser() ? (
                        <HiDotsVertical size="24px" />
                      ) : (
                        <HiDotsHorizontal size="24px" />
                      )}
                    </Box>
                  </Box>
                  <Box width="max-content">
                    <Text>
                      {`Then, press "Open in ${isiOS() ? 'Safari' : 'Chrome'}"`}
                    </Text>
                  </Box>
                </Card>
              </ArcherElement>
              <Heading level={3} margin={{ bottom: 'large' }}>
                To continue, open this page in your phone&apos;s default web
                browser.
              </Heading>
            </Box>
          );
        } else {
          content = (
            <Box fill>
              <Heading level={3} margin={{ bottom: 'large' }}>
                To continue, open this page in your phone&apos;s default web
                browser.
              </Heading>
              <Card background="light-5" pad="small" gap="small">
                <Box direction="row" gap="small" align="center">
                  <Text>Find and press the menu icon.</Text>
                </Box>
                <Box width="max-content">
                  <Text>
                    Then, press the button to open this page in your default web
                    browser;
                  </Text>
                </Box>
              </Card>
            </Box>
          );
        }
        return (
          <Box height="100vh" pad="medium" background="primary-7">
            <ArcherContainer strokeColor="#024006" style={{ height: '100%' }}>
              {content}
            </ArcherContainer>
          </Box>
        );
      }
      return (
        <>
          <Box pad="medium" background="secondary-7">
            For a better experience, please open this in your phone&#39;s
            default browser
          </Box>
          <Component {...props} />
        </>
      );
    };

    return (
      <Box id="container" fill="vertical">
        {!isEmbed && <Navigation showSignIn={!showBanner} />}
        {showBanner ? renderEmbeddedBrowserWarning() : <Component {...props} />}
      </Box>
    );
  };

export default withLayout;
