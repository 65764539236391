// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import { useEffect, useState } from 'react';
import { Box, CheckBox, Heading, Text } from 'grommet';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import SHDButton from '../../components/SHD/Button';

const SetLiberos = ({
  teamPlayers = [],
  goBack = () => {},
  updateLiberos,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  match: _match,
  ...props
}) => {
  const [liberos, setLiberos] = useState(props.liberos);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectLibero = (player) => {
    const newPlayer = { ...player };
    delete newPlayer.disabled;
    if (liberos.filter((libero) => libero._id === newPlayer._id).length === 0) {
      setLiberos((prevLiberos) => {
        const newLiberos = [...prevLiberos, newPlayer];
        return newLiberos;
      });
    } else {
      setLiberos((prevLiberos) => {
        const newLiberos = prevLiberos.filter(
          (libero) => libero._id !== newPlayer._id
        );
        return newLiberos;
      });
    }
  };

  return (
    <Box pad="medium">
      <Box fill="horizontal" align="end">
        <Text onClick={goBack} weight="bold" color="tertiary-1">
          Back
        </Text>
      </Box>
      <Heading level="3">Choose Liberos</Heading>
      <Box gap="medium" height={{ min: 'initial' }}>
        {teamPlayers.map((player) => (
          <Box
            key={player._id}
            background={
              liberos.map((p) => p._id).includes(player._id)
                ? 'secondary-1'
                : 'secondary-7'
            }
            pad="medium"
            style={{ borderRadius: '8px' }}
            onClick={() => {
              if (
                liberos.length !== 2 ||
                liberos.map((p) => p._id).includes(player._id)
              ) {
                selectLibero(player);
              }
            }}
          >
            <CheckBox
              checked={liberos.map((p) => p._id).includes(player._id)}
              label={player.displayName}
            />
          </Box>
        ))}
      </Box>

      <Box
        direction="row"
        fill="horizontal"
        justify="between"
        margin={{ vertical: 'large' }}
        align="center"
        height={{ min: 'small' }}
      >
        <SHDButton
          primary
          fill="horizontal"
          size="large"
          color="tertiary-1"
          label="Next"
          onClick={() => {
            updateLiberos(liberos);
          }}
        />
      </Box>
    </Box>
  );
};

export default compose(withRouter)(SetLiberos);
