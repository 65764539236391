// Packages
import React from 'react';
import { getPaywallLockCopy } from './paywall.copy';

// Components
import { Box, Heading, Image } from 'grommet';
import { PaywallCopy, PaywallLink } from './paywall.styles';

// Types
import { PaywallLockProps } from './paywall.types';

const PaywallLock: React.FC<PaywallLockProps> = ({ isPrivate, mode }) => {
  const { headline, copy, copyLede, linkText, linkUrl } = getPaywallLockCopy(
    isPrivate,
    mode
  );

  return (
    <>
      <Box align="center" className="paywall-lock" direction="column">
        <Image
          alt="Paywall Lock Image"
          height="154px"
          src="/paywall-lock.svg"
          width="154px"
          margin={{
            bottom: '-0.5rem',
            left: '0',
            right: '0',
            top: '0',
          }}
        />

        <Heading
          level={3}
          margin={{
            bottom: '0.625rem',
            left: '0',
            right: '0',
            top: '0',
          }}
          size="1.125rem"
          style={{ lineHeight: '1.333' }}
          textAlign="center"
          weight="bold"
        >
          {headline}
        </Heading>

        {copy !== '' && (
          <PaywallCopy
            color="#4a4a4a"
            margin="0 0 0.625rem"
            size="0.75rem"
            style={{ lineHeight: '1.333' }}
            textAlign="center"
          >
            {copyLede !== '' && (
              <>
                <strong>{copyLede}</strong>&nbsp;
              </>
            )}
            {copy}
          </PaywallCopy>
        )}

        {linkText !== '' && linkUrl !== '' && (
          <PaywallLink
            color="#1a1a1a"
            href={linkUrl}
            label={linkText}
            margin="0"
            size="0.75rem"
            style={{
              lineHeight: '1',
              opacity: '0.75',
              transition: 'opacity 0.1s linear',
            }}
            weight="400"
          />
        )}
      </Box>
    </>
  );
};

export default PaywallLock;
