import { useCallback, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { shd } from '@shd/jslib/models';
import { COMBINED_BBSB_KEY } from '../../constants/strings';
import { ClipFeedClip } from './player.types';
import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { Purchases } from '@revenuecat/purchases-js';
import { ViewerEntitlementStatus } from '../../components/Paywall/paywall.types';

const useTeamPlayer = (teamPlayerId: string) => {
  const [teamPlayer, setTeamPlayer] = useState<shd.TeamPlayer>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!teamPlayerId) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/team_player/${teamPlayerId}`)
      .then((response) => {
        setTeamPlayer(response.data.teamPlayer);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setTeamPlayer(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamPlayerId]);

  return { teamPlayer, error, loading };
};

export interface PlayerPrimaryInfo {
  player: shd.Player;
  teams: shd.Team[];
  claimedPlayerId?: string;
  claimedPlayerHandle?: string;
}
const usePlayerPrimaryInfo = (playerId: string | undefined) => {
  const [playerInfo, setPlayerInfo] = useState<PlayerPrimaryInfo | null>(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!playerId) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/player/${playerId}`)
      .then((response) => {
        setPlayerInfo(response.data);
        setLoading(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setLoading(false);
      });
  }, [playerId]);

  return { playerInfo, error, loading };
};

const usePlayerLiveAssets = (playerId: string) => {
  const [liveAssets, setLiveAssets] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/player/${playerId}/live_assets`)
      .then((response) => {
        setLiveAssets(response.data);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [playerId]);

  return liveAssets;
};

const useVideoDimensions = () => {
  let width = window.innerWidth;
  if (width > 425) {
    width = 425;
  }
  const height = width * (9 / 16);
  return { width: width, height: height };
};

const usePlayerReels = (playerId: string) => {
  const [reels, setReels] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/player/${playerId}/reels`)
      .then((response) => {
        setReels(response.data || []);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [playerId]);

  return reels;
};

const usePlayerGameSummaries = (playerId: string, selectedSport: string) => {
  const [gameSummaries, setGameSummaries] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (selectedSport !== COMBINED_BBSB_KEY) {
      setGameSummaries([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(`/api/player/${playerId}/game_summary_videos`)
      .then((response) => {
        setGameSummaries(response.data.gameSummaries || []);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setGameSummaries([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [playerId, selectedSport]);

  return { gameSummaries, error, loading };
};

const usePinnedClips = (playerId: string | null, claimed: boolean) => {
  const [pinnedClips, setPinnedClips] = useState<ClipFeedClip[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!playerId || !claimed) {
      setPinnedClips([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(`/api/player/${playerId}/pinned_clips`)
      .then((response) => {
        setPinnedClips(response.data || []);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setPinnedClips([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [playerId, claimed]);

  return { pinnedClips, error, loading };
};

const useIsEntitledToViewPlayer = (
  playerId: string,
  userId: string | undefined,
  selectedSport?: string
) => {
  const userStore = useContext(UserContext);
  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);

  const [status, setStatus] = useState<ViewerEntitlementStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getIsPlayerPublic = useCallback(async () => {
    const response = await axios.get(`/api/player/${playerId}/is_public`);
    return response.data.isPublic;
  }, [playerId]);

  useEffect(() => {
    const checkViewEntitlement = async () => {
      // When the `monetization` feature flag is off, everyone is entitled to view the clips, etc
      if (!isMonetizationEnabled) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      // Non-BBSB sports are still free.
      if (selectedSport !== COMBINED_BBSB_KEY) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      if (!playerId) {
        setStatus(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        let userIsEntitled = false;
        if (userId) {
          try {
            const customerInfo =
              await Purchases.getSharedInstance().getCustomerInfo();
            const activeEntitlements = customerInfo.entitlements.active;
            userIsEntitled = activeEntitlements['Pro Access'] !== undefined;

            const isPublic = await getIsPlayerPublic();
            if (userIsEntitled && isPublic) {
              setStatus(ViewerEntitlementStatus.ENTITLED);
            } else if (userIsEntitled && !isPublic) {
              setStatus(ViewerEntitlementStatus.ENTITLED_BUT_PRIVATE);
            }
          } catch (e) {
            Sentry.captureException(e);
          }
        }

        if (!userIsEntitled) {
          const response = await axios.get(
            `/api/player/${playerId}/is_entitled_to_view`
          );
          setStatus(response.data.status);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
        setStatus(null);
      } finally {
        setLoading(false);
      }
    };

    checkViewEntitlement();
  }, [
    playerId,
    userId,
    isMonetizationEnabled,
    getIsPlayerPublic,
    selectedSport,
  ]);

  return { status, loading, error };
};

export {
  useTeamPlayer,
  usePlayerPrimaryInfo,
  usePlayerLiveAssets,
  useVideoDimensions,
  usePlayerReels,
  usePlayerGameSummaries,
  usePinnedClips,
  useIsEntitledToViewPlayer,
};
