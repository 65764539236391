// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// react component that redirects to landing page
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';

const cookies = new Cookies();

const PartnerReferral = () => {
  const referralCode = useParams().referralcode;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    axios.get(`/api/referral_code/${referralCode}`).then((response) => {
      if (response.data) {
        cookies.set('referralCode', referralCode, {
          path: '/',
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 90), // 90 days
        });

        // Capture email of DK account (works generally for any external email)
        const email = queryParams.get('email');
        if (email) {
          cookies.set('externalEmail', email, {
            path: '/',
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 90), // 90 days
          });
        }

        window.location.href = response.data.redirectUrl;
      } else {
        window.location.href = '/';
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BasicLoader />;
};

export default PartnerReferral;
