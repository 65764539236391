export enum LiveEvents {
  STREAM_FETCH_ERROR = 'Stream Fetch Error',
  HLS_PLAYER_ERROR = 'HLS Player Error',
  VIEWER_JOINED = 'Viewer Joined',
  VIEWER_LEFT = 'Viewer Left',
  WATCH_LIVE = 'Watch LIVE',
  SHARE_CLICKED = 'Clicked Share',
  READ_MORE = 'Clicked Read More',
  VIEW_RECAP = 'Clicked View Game Recap Button',
  WATCH_HIGHLIGHTS = 'Clicked Watch Highlights Button',
  VIEWER_PAUSED = 'Viewer paused',
  VIEWER_FULLSCREEN = 'Viewer went fullscreen',
  VIEWER_MUTE = 'Viewer clicked mute/unmute',
  GAME_PAGE_VIEW = 'Game Page View',
}

export interface LiveEventPayload {
  error?: string;
  streamId?: string;
  signedIn?: boolean;
  userType?: string;
  userId?: string;
  teamId?: string;
  autoplay?: boolean;
  gameId?: string;
  sport?: string;
  type?: string;
}

export type TrackEvent = (event: LiveEvents, payload: LiveEventPayload) => void;
