import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Box } from 'grommet';
import {
  useHistory,
  withRouter,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useUnload } from '../../helpers/useUnload';
import queryString from 'query-string';
import {
  useLiveOnPlatform,
  useLiveConfig,
  useLiveResolvePermalink,
} from './liveHooks';
import { useTeamByIdOrHandle } from '../TeamPage/teamHooks';

import { UserContext } from '../../services/Session';
import { BasicLoader } from '../../helpers/Loaders';
import SidelineLiveContainer from './SidelineLiveContainer';
import SidelineLiveGameInfo from './SidelineLiveGameInfo';
import SidelineLiveBeta from './SidelineLiveBeta';

import { sidelineLive, playerContainer } from './sidelineLive.styles';
import { SidelineLiveTeamCard } from './SidelineLiveTeamCard';
import { SidelineLiveLinks } from './SidelineLiveLinks';
import { LiveEventPayload, LiveEvents } from './sidelineLive.analytics';
import { getUserTypeRelativeToTeam } from '../../helpers/utils';
import { analytics } from '../../services/analytics';
import { useStyleHooks } from './liveStyleHooks';

const SidelineLive: React.FC = () => {
  const [gameEnded, setGameEnded] = useState<boolean>(false);
  const { rowOrColumn, isDesktop } = useStyleHooks();
  const { teamId: teamIdOrHandle } = useParams<{
    teamId: string;
  }>();
  const team = useTeamByIdOrHandle(teamIdOrHandle);
  const teamId = team?.team?._id || '';

  const location = useLocation();
  const params = queryString.parse(location.search);

  const history = useHistory();
  const userContext = useContext(UserContext);
  const onPlatformStatus = useLiveOnPlatform(teamId);
  const permalink = useLiveResolvePermalink(teamId, params);

  const userId = userContext.authUser?.claims?.shd_user_id;
  const liveConfig = useLiveConfig(teamId, userId);

  const t = {
    teamName: team?.team?.nameMed,
    teamLogo: team?.team?.image,
    sport: team?.team?.attrib_sportType,
    age: team?.team?.attrib_ageU,
    ageLevel: team?.team?.attrib_ageLevel,
  };

  useUnload(() => {
    trackLiveEvent(LiveEvents.VIEWER_LEFT, {});
  });

  useEffect(() => {
    if (permalink?.redirectTo === 'team') {
      history.push(`/${teamId}`);
    }
    if (permalink?.redirectTo === 'game') {
      history.push(`/game/${permalink?.teamGameId}`);
    }
  }, [permalink, teamId, history]);

  const handleBackToTeamPage = useCallback(() => {
    history.push(`/${teamId}`);
  }, [teamId, history]);

  const handleNavToGamePage = useCallback(
    (gameId: string) => {
      history.push(`/game/${gameId}#pbp`);
    },
    [history]
  );

  const trackLiveEvent = useCallback(
    (event: LiveEvents, payload: LiveEventPayload) => {
      analytics.track(event, {
        ...payload,
        signedIn: !!userContext.authUser,
        userType: getUserTypeRelativeToTeam(teamId, userContext),
        userId: userId,
        teamId: teamId,
        source: 'sidelineLIVE',
        sport: t.sport,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {liveConfig?.isWebclientLive ? (
        <Box id="sideline-live" direction={rowOrColumn}>
          <Box
            id="sideline-live-main"
            style={sidelineLive}
            width={isDesktop ? '70%' : '100%'}
          >
            <Box
              id="sl-player-container"
              background={'#000'}
              style={playerContainer}
            >
              <SidelineLiveContainer
                key={`sliv-${teamId}`}
                hls_link={onPlatformStatus?.hls_link}
                trackEvent={trackLiveEvent}
                isPlayable={onPlatformStatus?.isPlayable}
                camera_status={onPlatformStatus?.camera_status}
                isGameEnded={gameEnded && !onPlatformStatus?.isPlayable}
              />
            </Box>
            <SidelineLiveLinks teamId={teamId} />
            <SidelineLiveGameInfo
              teamId={teamId}
              teamName={t.teamName}
              handleNavToGamePage={handleNavToGamePage}
              setGameEnded={setGameEnded}
              trackEvent={trackLiveEvent}
            />
            {!isDesktop && <SidelineLiveBeta trackEvent={trackLiveEvent} />}
            <SidelineLiveTeamCard
              handleBackToTeamPage={handleBackToTeamPage}
              team={t}
            />
          </Box>
          {isDesktop && (
            <Box id="sideline-live-desktop-right" width={'30%'} margin={'1rem'}>
              <SidelineLiveBeta trackEvent={trackLiveEvent} />
            </Box>
          )}
        </Box>
      ) : (
        <BasicLoader fullPage />
      )}
    </>
  );
};

export default withRouter(SidelineLive);
