// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box } from 'grommet';

import PlayerCard from './PlayerCard';
import { SearchInfo } from './styles';

const SearchResult = ({ results, searching, error }) => {
  const isNoResult = !searching && !results.length;

  if (searching) {
    return (
      <Box>
        <SearchInfo color="dark-1">Searching...</SearchInfo>
      </Box>
    );
  }

  if (isNoResult) {
    return (
      <Box>
        <SearchInfo color="dark-1">No search result</SearchInfo>
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <SearchInfo color="status-error">{error}</SearchInfo>
      </Box>
    );
  }

  return (
    <Box pad="small" gap="small">
      {results.map((player) => <PlayerCard key={player.id} player={player} />)}
    </Box>
  );
};

export default SearchResult;
