import { CircleQuestion } from 'grommet-icons';
import React from 'react';
import { Box, Tip } from 'grommet';

interface HelpTooltipProps {
  content: React.ReactNode;
}
const HelpTooltip: React.FC<HelpTooltipProps> = ({ content }) => (
  <Tip
    plain
    dropProps={{ align: { bottom: 'top' }, round: 'none' }}
    content={
      <Box
        width="medium"
        border={{ color: 'dark-3', side: 'all', size: 'small' }}
      >
        {content}
      </Box>
    }
  >
    <CircleQuestion color="dark-3" />
  </Tip>
);

export default HelpTooltip;
