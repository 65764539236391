// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Anchor, Box, Button, Card, Heading, Text } from 'grommet';
import React, { useState, useContext } from 'react';
import * as Sentry from '@sentry/browser';

import axios from '../../helpers/axios';
import SHDButton from '../../components/SHD/Button';
import { UserContext } from '../../services/Session';
import { BasicLoader } from '../../helpers/Loaders';
import { analytics } from '../../services/analytics';

const GO_LIVE_STEP = 1;
const STREAMING_DESTINATION_STEP = 2;
const SCORING_STEP = 3;

const GuidedLiveTest = ({ endTest = () => {}, onClose = () => {} }) => {
  const userStore = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const onContinue = () => {
    let trackingName = '';
    if (step === GO_LIVE_STEP) {
      trackingName = 'Live Test Go Live Success';
    } else if (step === STREAMING_DESTINATION_STEP) {
      trackingName = 'Live Test Streaming Destination Success';
    } else if (step === SCORING_STEP) {
      trackingName = 'Live Test Scoring Success';
    }

    analytics.track(trackingName, {
      teamId: userStore.selectedTeam._id,
      sport: userStore.selectedTeam.attrib_sportType
        .toLowerCase()
        .replace(' ', '_'),
    });

    setError('');
    setStep(step + 1);
  };
  const errorHandler = (errorString, link) =>
    setError({ message: errorString, link });

  const contactSupportClicked = () => {
    analytics.track('Live Test Contact Support Clicked', {
      teamId: userStore.selectedTeam._id,
      sport: userStore.selectedTeam.attrib_sportType
        .toLowerCase()
        .replace(' ', '_'),
      step,
    });

    window.open('http://m.me/sidelinehd', '_blank');
  };

  const stepOne = (
    <Box overflow="scroll" gap="small">
      <Text color="red">
        Tip: Use a device other than this one to operate your camera.
      </Text>
      <Box margin={{ top: 'medium' }}>
        <Text>Start streaming via your second device:</Text>
        <ul
          style={{
            'margin-top': '0px',
            'margin-bottom': '0px',
            'line-height': '100%',
          }}
        >
          <li style={{ 'margin-top': '3px' }}>
            <Text>Log in to sidelineHD</Text>
          </li>
          <li style={{ 'margin-top': '4px' }}>
            <Text>
              On your team page, click either Go Live button and follow the
              directions to start streaming
            </Text>
          </li>
        </ul>
        <img
          src="/click_go_live.png"
          alt="click go live"
          display="block"
          style={{ width: '75%', marginTop: '2vh' }}
        />
      </Box>
      <Box margin={{ top: 'medium' }}>
        <Text>
          About 30 seconds after starting your stream, you should receive an UP
          text message from sidelineHD.
        </Text>
        <Anchor href="https://sidelinehd.zendesk.com/hc/en-us/articles/23007139605143-Starting-your-livestream-Setting-up-your-camera">
          <Text size="small">How do I start my livestream?</Text>
        </Anchor>
      </Box>
      <Box
        fill="horizontal"
        margin={{ top: 'large' }}
        // direction="row"
        gap="medium"
        // justify="between"
      >
        <Button
          primary
          size="large"
          label="I got the text"
          onClick={() => {
            setLoading(true);
            axios
              .get(
                `/api/live_test_validation/${userStore.selectedTeam._id}/step/1`
              )
              .then(() => {
                setLoading(false);
                onContinue();
              })
              .catch((respError) => {
                setLoading(false);
                let errorMessage = '';
                if (respError.response && respError.response.data.message) {
                  errorMessage = respError.response.data.message;
                  errorHandler(errorMessage, respError.response.data.link);
                } else {
                  errorHandler('Something went wrong. Please contact support.');
                  Sentry.captureException(respError);
                }

                analytics.track('Live Test Go Live Failure', {
                  teamId: userStore.selectedTeam._id,
                  sport: userStore.selectedTeam.attrib_sportType
                    .toLowerCase()
                    .replace(' ', '_'),
                  errorMessage,
                });
              });
          }}
        />
        <Button
          secondary
          size="large"
          label="Contact support"
          target="_blank"
          onClick={contactSupportClicked}
        />
      </Box>
    </Box>
  );

  const stepTwo = (
    <Box gap="small">
      <Text color="red">
        Tip: Use a device other than this one to operate your camera.
      </Text>
      <Box margin={{ top: 'small' }}>
        <Text>
          In about a minute, you will receive another text message with a link
          to your stream on Facebook / YouTube.
        </Text>
        <img
          src="/example_up_yt.png"
          alt="text messages"
          display="block"
          style={{ width: '75%', marginTop: '5px' }}
        />
      </Box>
      <Box margin={{ top: 'small' }}>
        <Text>
          Click the link to view your posted livestream. (The stream won&apos;t
          have scoreboards yet.)
        </Text>
      </Box>
      <Box
        fill="horizontal"
        margin={{ top: 'medium' }}
        direction="row"
        gap="medium"
        justify="between"
      >
        <Button
          secondary
          label="Contact support"
          target="_blank"
          onClick={contactSupportClicked}
        />
        <Button
          primary
          label="I see my stream"
          onClick={() => {
            setLoading(true);
            axios
              .get(
                `/api/live_test_validation/${userStore.selectedTeam._id}/step/2`
              )
              .then(() => {
                setLoading(false);
                onContinue();
              })
              .catch((respError) => {
                setLoading(false);
                let errorMessage = '';
                if (respError.response && respError.response.data.message) {
                  errorMessage = respError.response.data.message;
                  errorHandler(errorMessage, respError.response.data.link);
                } else {
                  errorHandler('Something went wrong. Please contact support.');
                  Sentry.captureException(respError);
                }

                analytics.track('Live Test Streaming Destination Failure', {
                  teamId: userStore.selectedTeam._id,
                  sport: userStore.selectedTeam.attrib_sportType
                    .toLowerCase()
                    .replace(' ', '_'),
                  errorMessage,
                });
              });
          }}
        />
      </Box>
    </Box>
  );

  const stepThree = (
    <Box gap="small">
      <Text color="red">
        Tip: Use a device other than this one to operate your camera.
      </Text>
      <Box margin={{ top: 'small' }} gap="small">
        <Text>
          Create and start a game against a team called &quot;test&quot;. Score
          at least one play in that game, and then come back here!
        </Text>
        <Button
          primary
          color="tertiary-1"
          label="Start scoring"
          onClick={() => {
            window.open('/scoring/schedule');
          }}
        />
        <Box gap="small" margin={{ top: 'medium' }}>
          <Text>
            About a minute after you start scoring, your livestream should show
            the scoreboard.
          </Text>
          <Anchor
            href="https://sidelinehd.zendesk.com/hc/en-us/articles/23004429658903-The-Scoreboard-Isn-t-Showing-Up"
            target="_blank"
          >
            <Text size="small">
              I waited a minute and don&apos;t see the scoreboard.
            </Text>
          </Anchor>
        </Box>
      </Box>
      <Box
        fill="horizontal"
        margin={{ top: 'medium' }}
        direction="row"
        gap="medium"
        justify="between"
      >
        <Button
          secondary
          label="Contact support"
          target="_blank"
          onClick={contactSupportClicked}
        />
        <Button
          primary
          label="I see the scoreboard"
          onClick={() => {
            setLoading(true);
            axios
              .get(
                `/api/live_test_validation/${userStore.selectedTeam._id}/step/2`
              )
              .then(() => {
                setLoading(false);
                onContinue();
              })
              .catch((respError) => {
                setLoading(false);
                let errorMessage = '';
                if (respError.response && respError.response.data.message) {
                  errorMessage = respError.response.data.message;
                  errorHandler(errorMessage, respError.response.data.link);
                } else {
                  errorHandler('Something went wrong. Please contact support.');
                  Sentry.captureException(respError);
                }

                analytics.track('Live Test Scoring Failure', {
                  teamId: userStore.selectedTeam._id,
                  sport: userStore.selectedTeam.attrib_sportType
                    .toLowerCase()
                    .replace(' ', '_'),
                  errorMessage,
                });
              });
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      style={{ display: 'block' }}
      overflow="scroll"
      pad={{ horizontal: 'medium' }}
      margin={{ bottom: 'large' }}
    >
      {loading && <BasicLoader />}
      <Heading level="3">
        Live Test Step
        {'  '}
        {step}
      </Heading>
      {Object.keys(error).length ? (
        <Card pad="small" background="light-1" margin={{ bottom: 'small' }}>
          <Box gap="small" fill>
            <Text color="status-critical">{error.message}</Text>
            {error.link ? (
              <Anchor
                color="status-critical"
                label="See help"
                href={error.link}
                target="_blank"
              />
            ) : null}
          </Box>
        </Card>
      ) : null}
      {step === 1 ? stepOne : null}
      {step === 2 ? stepTwo : null}
      {step === 3 ? stepThree : null}
      {step === 4 ? (
        <Box gap="small">
          <Heading level="3">Congratulations!</Heading>
          <Text>You are ready for gameday!</Text>
          <SHDButton
            label="End live test"
            primary
            onClick={() => {
              endTest();
              onClose();
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default GuidedLiveTest;
