import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Image, Layer, Text } from 'grommet';
import axios from '../../helpers/axios';
import { LiveAsset } from '@shd/jslib/models/shd';
import { Bookmark, Copy, Facebook, Share, Youtube } from 'grommet-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as Sentry from '@sentry/react';
import { analytics } from '../../services/analytics';

interface GameLiveAsset {
  cdnUrl: string;
  cdnTitle: string;
  destName: string;
  type: 'game';
}

const LinksItem: React.FC<{
  sport: string;
  liveAsset: LiveAsset | GameLiveAsset;
}> = ({ sport, liveAsset }) => {
  const [copied, setCopied] = useState(false);
  const [copiedBecauseShareFailed, setCopiedBecauseShareFailed] =
    useState(false);

  const onCopyLivestreamLink = useCallback(() => {
    analytics.track('Livestream Link Copied', {
      sport,
      destination: liveAsset.type,
    });
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [sport, liveAsset.type]);

  return (
    <Box pad="16px" gap="6px" flex={{ grow: 1, shrink: 0 }}>
      <Box direction="row" gap="small" align="center" margin="4px">
        <Box
          pad="small"
          round="8px"
          align="center"
          justify="center"
          border={{ color: 'border' }}
          background={liveAsset.type === 'game' ? 'primary-3' : 'light-3'}
        >
          {liveAsset.type === 'yt' && <Youtube color="#FF0000" />}
          {liveAsset.type === 'fb' && <Facebook color="#0766FF" />}
          {liveAsset.type === 'game' && <Bookmark color="white" />}
        </Box>
        <Box>
          <Text weight={'bold'}>
            {liveAsset.type === 'yt' && 'YouTube'}
            {liveAsset.type === 'fb' && 'Facebook'}
            {liveAsset.type === 'game' && liveAsset.cdnTitle}
          </Text>
          <Text size="small" color="dark-4">
            {liveAsset.destName}
          </Text>
        </Box>
      </Box>
      <Box
        background={'light-3'}
        round="8px"
        border={{ color: 'rgba(0, 0, 0, 0.10)' }}
        pad={{ vertical: '10px', horizontal: '16px' }}
      >
        <Text truncate>{liveAsset.cdnUrl}</Text>
      </Box>
      <CopyToClipboard text={liveAsset.cdnUrl} onCopy={onCopyLivestreamLink}>
        <Button
          primary
          icon={<Copy size="16px" />}
          label={copied ? 'Copied!' : 'Copy Link'}
        />
      </CopyToClipboard>
      <Button
        primary
        icon={<Share size="16px" />}
        label={copiedBecauseShareFailed ? 'Copied!' : 'Share Link'}
        onClick={async () => {
          analytics.track('Livestream Share Button Clicked', {
            sport,
            destination: liveAsset.type,
          });
          if (navigator.share) {
            try {
              await navigator.share({
                title: liveAsset.cdnTitle,
                url: liveAsset.cdnUrl,
              });
            } catch (err) {
              if (err instanceof Error && err.name === 'AbortError') {
                analytics.track('Livestream Share Canceled', {
                  sport,
                  destination: liveAsset.type,
                });
              } else {
                Sentry.captureException(err);
              }
            }
          } else {
            analytics.track('Livestream Share Fallback', {
              sport,
              destination: liveAsset.type,
            });
            navigator.clipboard.writeText(liveAsset.cdnUrl);
            setCopiedBecauseShareFailed(true);
            setTimeout(() => {
              setCopiedBecauseShareFailed(false);
            }, 5000);
          }
        }}
      />
    </Box>
  );
};

interface LivestreamLinksPageProps {
  sport: string;
  teamId: string;
  gameId: string;
  matchupTitle: string;
  onClose: () => void;
}

const LivestreamLinksPage: React.FC<LivestreamLinksPageProps> = ({
  sport,
  teamId,
  gameId,
  matchupTitle,
  onClose,
}) => {
  const gamePageLiveAsset: GameLiveAsset = {
    cdnUrl: `${process.env.REACT_APP_BASE_URL}/game/${gameId}?`,
    cdnTitle: 'Game Page',
    destName: matchupTitle,
    type: 'game',
  };
  const [liveAssets, setLiveAssets] = useState<LiveAsset[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchShareLinks = async () => {
      try {
        const response = await axios.get(`/api/team/${teamId}/live_assets`, {
          params: {
            include_publish_doc: true,
          },
        });
        setLiveAssets(response.data.liveAssets);
        setLoading(false);
      } catch (err) {
        setError('Failed to load share links');
        setLoading(false);
      }
    };

    fetchShareLinks();
  }, [teamId]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text color="status-error">{error}</Text>;
  }

  return (
    <Layer
      modal
      responsive={false}
      position="bottom"
      onClickOutside={onClose}
      onEsc={onClose}
      full="horizontal"
      background="transparent"
    >
      <Box
        round={{ corner: 'top', size: 'medium' }}
        background="white"
        height={{ max: '80vh' }}
      >
        <Box
          pad="16px"
          direction="row"
          justify="between"
          align="center"
          flex={{ grow: 1, shrink: 0 }}
        >
          <Text weight="bold">Share livestream link</Text>
          <Button onClick={onClose} secondary size="small" label="Done" />
        </Box>
        <Box gap="small" overflow="scroll">
          {liveAssets.map((link) => (
            <LinksItem key={link.cdnUrl} sport={sport} liveAsset={link} />
          ))}
          {(!liveAssets || liveAssets.length === 0) && (
            <Box
              pad="16px"
              align="center"
              gap="16px"
              flex={{ grow: 1, shrink: 0 }}
            >
              <Image src="/pointing_down.png" width={'64px'} />
              <Text>{`No livestream links available for ${matchupTitle}`}</Text>
              <Box
                round="8px"
                border={{ color: 'primary-3' }}
                background={'primary-8'}
                pad={{ horizontal: '16px', vertical: '8px' }}
              >
                <Text>
                  Links to each connected livestreaming destination will be here
                  once there is an active live stream.
                </Text>
              </Box>
            </Box>
          )}
          <LinksItem sport={sport} liveAsset={gamePageLiveAsset} />
        </Box>
      </Box>
    </Layer>
  );
};

export default LivestreamLinksPage;
