import React from 'react';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';

import ApiError from './ApiError';
import { dtos } from '@shd/jslib/models';
import axios from './axios';
import { errorUtil, strogging } from '@shd/jslib/infra';
import { UserContext } from '../services/Session';
import { useIsFeatureEnabledValue } from './utils';
import { analytics } from '../services/analytics';
import useZendeskWidget from './useZendeskWidget';
import { theGlobalAppHost } from '../services/appHost';

export interface FacebookChannel {
  id: string;
  name: string;
  label: string;
  type: string;
  picture?: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
}

const expirationThreshold = 60 * 60 * 24 * 20; // show expiration warning 30 days out

export function useFacebookSettings(
  teamId: string | undefined,
  onComplete: () => void,
  onUpdatedLinkedChannel: (channel: FacebookChannel | null) => void
) {
  // TODO: decide if we need this - possibly no
  const [, setProfile] = React.useState(null);

  const [prevLinkedChannel, setPrevLinkedChannel] =
    React.useState<FacebookChannel | null>(null);
  const [linkedChannel, setLinkedChannel] =
    React.useState<FacebookChannel | null>(null);

  const [showIntroModal, setShowIntroModal] = React.useState(false);
  const [showLinkingModal, setShowLinkingModal] = React.useState(false);

  const [error, setError] = React.useState<Error | null>(null);
  const [linkingError, setLinkingError] = React.useState<ApiError | null>(null);
  const [linkingLoading, setLinkingLoading] = React.useState(false);
  const [showExpiration, setShowExpiration] = React.useState<{
    daysUntilExpiration?: number;
  }>({});

  const { showWidget, hideWidget } = useZendeskWidget();

  const onCloseIntroModal = () => {
    setShowIntroModal(false);
  };

  React.useEffect(() => {
    if (showIntroModal || showLinkingModal) {
      setLinkingError(null);
      hideWidget();
    } else {
      showWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIntroModal, showLinkingModal]);

  const updateLinkedChannel = React.useCallback(
    (channel: FacebookChannel | null) => {
      setLinkedChannel(channel);
      onUpdatedLinkedChannel(channel);
    },
    [onUpdatedLinkedChannel]
  );

  React.useEffect(() => {
    axios
      .get<dtos.PublishFb>('/api/facebook_auth', { params: { teamId } })
      .then((res) => {
        const fbPublish = res.data;

        if (fbPublish && 'destNodeName' in fbPublish) {
          updateLinkedChannel({
            id: fbPublish.destNodeId ?? '',
            name: fbPublish.destNodeName ?? '',
            label: fbPublish.destNodeId ?? '',
            type: fbPublish.destNodeType ?? '',
          });
        } else {
          updateLinkedChannel(null);
        }

        if (!fbPublish) {
          return;
        }

        if (
          fbPublish.accessTokenExpirationTs === 0 ||
          !fbPublish.accessTokenExpirationTs
        ) {
          // update expiration ts if it doesn't exist
          const tokenDebugUrl = `https://graph.facebook.com/debug_token?input_token=${fbPublish.accessToken}&access_token=${process.env.REACT_APP_FACEBOOK_APP_ID}|${process.env.REACT_APP_FACEBOOK_APP_SECRET}`;
          axios.get(tokenDebugUrl).then((response) => {
            const { data } = response.data;
            axios
              .put('/api/facebook_auth', {
                id: fbPublish._id,
                accessTokenExpirationTs: data.data_access_expires_at,
              })
              .then(() => {
                setError(null);
              })
              .catch((e) => {
                Sentry.captureException(e);
                setError(e);
              });
          });
        } else {
          const ts = Date.now();

          if (
            ts / 1000 >
            fbPublish.accessTokenExpirationTs - expirationThreshold
          ) {
            const now = DateTime.fromMillis(ts);
            const expiration = DateTime.fromSeconds(
              fbPublish.accessTokenExpirationTs
            );
            const daysUntilExpiration = Math.round(
              expiration.diff(now, ['days']).toObject().days ?? 0
            );

            setShowExpiration({ daysUntilExpiration });
          }
        }

        setError(null);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError(e);
      });
  }, [teamId, updateLinkedChannel]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseFacebook = async (res: any) => {
    if (res.status && res.status === 'unknown') {
      return;
    }
    setProfile({ ...res, label: res.name, value: res.id });
    setShowLinkingModal(true);
  };

  const onLinkFacebookClick = () => {
    analytics.track('Link Streaming Destination Clicked', {
      destinationType: 'Facebook',
      teamId,
    });
    setPrevLinkedChannel(linkedChannel);

    setShowIntroModal(false);
  };

  const onUnlinkFacebookClick = () => {
    axios.delete('/api/facebook_auth', { data: { teamId } });
    updateLinkedChannel(null);
    setProfile(null);
    setShowExpiration({});
  };

  const onClose = () => {
    updateLinkedChannel(prevLinkedChannel);
    setShowLinkingModal(false);
  };

  const onSave = async (selectedChannel: FacebookChannel) => {
    setShowLinkingModal(false);
    setShowIntroModal(false);
    try {
      const authResponse = await getFacebookAuthResponse();

      if (selectedChannel && authResponse && authResponse.accessToken) {
        setLinkingLoading(true);
        axios
          .post('/api/facebook_auth', {
            authResponse,
            linkedChannel: selectedChannel,
            teamId,
          })
          .then((response) => {
            analytics.track('Streaming Destination Linked', {
              destinationType: 'Facebook',
              destinationSubtype: selectedChannel.type,
              teamId,
            });

            onComplete();
            updateLinkedChannel(selectedChannel);
            const { accessTokenExpirationTs } = response.data;
            const ts = Date.now();

            if (ts / 1000 > accessTokenExpirationTs - expirationThreshold) {
              const now = DateTime.fromMillis(ts);
              const expiration = DateTime.fromSeconds(accessTokenExpirationTs);
              const daysUntilExpiration = Math.round(
                expiration.diff(now, ['days']).toObject().days ?? 0
              );

              setShowExpiration({ daysUntilExpiration });
            }
            setLinkingError(null);
            setLinkingLoading(false);
          })
          .catch((e) => {
            Sentry.captureException(e);
            const apiError = new ApiError(e);
            strogging.error('Failed to link Facebook', {
              message: apiError.message,
              details: apiError.details,
            });
            setLinkingError(apiError);
            setLinkingLoading(false);
          });
      } else {
        setLinkingError(new ApiError('No access token found'));
      }
    } catch (e) {
      strogging.error('Failed to link Facebook', {
        message: errorUtil.errorMessage(e),
      });
      setLinkingError(
        new ApiError(errorUtil.errorMessage(e) || 'Unknown error')
      );
    }
  };

  return {
    linkedChannel,
    onClose,
    onSave,
    linkingLoading,
    error,
    setError,
    onUnlinkFacebookClick,
    setShowIntroModal,
    showExpiration,
    onLinkFacebookClick,
    setShowLinkingModal,
    responseFacebook,
    showIntroModal,
    showLinkingModal,
    linkingError,
    onCloseIntroModal,
  };
}

export function useFacebookScopes(): string {
  const userStore = React.useContext(UserContext);
  const experimentalScopes = useIsFeatureEnabledValue(
    'experimental_fb_scopes',
    userStore
  );
  const scopes = useIsFeatureEnabledValue('fb_scopes', userStore);
  return experimentalScopes.enabled
    ? experimentalScopes.value
    : scopes.enabled
      ? scopes.value
      : 'publish_video,pages_show_list,pages_manage_posts,business_management';
}

export interface FacebookAuthResponse {
  // these dont appear to be used, so commenting out
  // userID: string;
  // signedRequest: string;
  // expiresIn: string;
  accessToken: string;
  data_access_expiration_time?: number;
}

export async function getFacebookAuthResponse(): Promise<FacebookAuthResponse | null> {
  try {
    const appHost = theGlobalAppHost();
    if (appHost.isHosted) {
      return await appHost.service.requests.getFacebookAuthResponse();
    }

    const result = window.FB.getAuthResponse();
    strogging.log('getFacebookAuthResponse', {
      k: Object.keys(result ?? {}),
    });
    return result;
  } catch (e) {
    strogging.error('getFacebookAuthResponse', {
      err: errorUtil.errorMessage(e),
    });
    return null;
  }
}

export function useFacebookAuthResponse() {
  const [authResponse, setAuthResponse] =
    React.useState<FacebookAuthResponse | null>(null);
  React.useEffect(() => {
    getFacebookAuthResponse()
      .then((response) => {
        setAuthResponse(response);
      })
      .catch((e) => {
        strogging.exception('Failed to get Facebook auth response', e);
      });
  }, []);

  return React.useMemo(() => authResponse, [authResponse]);
}

export interface ApiResponse {
  error?: string;
}

export const PROFILE_TYPE = 'profile';
export const PAGE_TYPE = 'page';
export const GROUP_TYPE = 'group';

type DestinationName = 'page' | 'profile';

export interface DestinationBase {
  id: string;
  name: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
}

export interface Profile extends ApiResponse, DestinationBase {
  email: string;
  link?: string;
  disabled?: boolean;
}

export interface Destination extends DestinationBase {
  type: DestinationName;
}

export interface Paging {
  cursors: {
    before: string;
    after: string;
  };
  next: string;
}

export interface Page extends DestinationBase {
  tasks: string[];
  disabled?: boolean;
}

export interface PageResponse extends ApiResponse {
  data: Page[];
  paging: Paging;
}

export async function callFbApi<TResult extends ApiResponse>(
  path: string,
  method: string = 'GET',
  params: Record<string, string> = {}
): Promise<TResult> {
  // if we are hosted, then use the access token from the app host
  const appHost = theGlobalAppHost();
  if (appHost.isHosted) {
    const authResponse =
      await appHost.service.requests.getFacebookAuthResponse();
    if (!authResponse) {
      throw new Error('No Facebook auth response');
    }
    params.access_token = authResponse.accessToken;
  }

  return new Promise((resolve, reject) => {
    window.FB.api(path, method, params, (response: TResult) => {
      if (!response || response.error) {
        reject(response?.error);
      } else {
        resolve(response);
      }
    });
  });
}
