"use strict";
/* eslint-disable no-console */
Object.defineProperty(exports, "__esModule", { value: true });
exports.timePromise = exports.timeValue = exports.time = exports.stopTiming = exports.startTiming = exports.write = exports.exception = exports.errorValue = exports.error = exports.warnValue = exports.warn = exports.logValue = exports.log = exports.debugValue = exports.debug = exports.verboseValue = exports.verbose = exports.setOptions = exports.getLoggingLevel = exports.setLoggingLevel = exports.LoggingLevel = void 0;
var LoggingLevel;
(function (LoggingLevel) {
    LoggingLevel[LoggingLevel["NONE"] = 0] = "NONE";
    LoggingLevel[LoggingLevel["ERROR"] = 1] = "ERROR";
    LoggingLevel[LoggingLevel["WARN"] = 2] = "WARN";
    LoggingLevel[LoggingLevel["LOG"] = 3] = "LOG";
    LoggingLevel[LoggingLevel["DEBUG"] = 4] = "DEBUG";
    LoggingLevel[LoggingLevel["VERBOSE"] = 5] = "VERBOSE";
})(LoggingLevel || (exports.LoggingLevel = LoggingLevel = {}));
let cfg = {
    loggingLevel: LoggingLevel.LOG,
    includeTimestamp: false,
    callConsole: true,
    listener: undefined,
};
const setLoggingLevel = (level) => {
    console.log(`setLoggingLevel:${level}`);
    cfg.loggingLevel = level;
};
exports.setLoggingLevel = setLoggingLevel;
const getLoggingLevel = () => cfg.loggingLevel;
exports.getLoggingLevel = getLoggingLevel;
const setOptions = (options) => {
    cfg = { ...cfg, ...options };
};
exports.setOptions = setOptions;
function getTimestamp() {
    return cfg.includeTimestamp ? new Date().toISOString() : undefined;
}
const verbose = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.VERBOSE) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.info(JSON.stringify({
                verbose: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.VERBOSE, title, payload);
        }
    }
};
exports.verbose = verbose;
const verboseValue = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.VERBOSE) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.info(JSON.stringify({
                verbose: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.VERBOSE, title, payload);
        }
    }
    return payload;
};
exports.verboseValue = verboseValue;
const debug = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.DEBUG) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.info(JSON.stringify({
                debug: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.DEBUG, title, payload);
        }
    }
};
exports.debug = debug;
const debugValue = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.DEBUG) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.info(JSON.stringify({
                debug: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.DEBUG, title, payload);
        }
    }
    return payload;
};
exports.debugValue = debugValue;
const log = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.LOG) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.log(JSON.stringify({
                log: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.LOG, title, payload);
        }
    }
};
exports.log = log;
const logValue = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.LOG) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.log(JSON.stringify({
                log: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.LOG, title, payload);
        }
    }
    return payload;
};
exports.logValue = logValue;
const warn = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.WARN) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.warn(JSON.stringify({
                warn: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.WARN, title, payload);
        }
    }
};
exports.warn = warn;
const warnValue = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.WARN) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.warn(JSON.stringify({
                warn: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.WARN, title, payload);
        }
    }
    return payload;
};
exports.warnValue = warnValue;
const error = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.ERROR) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.error(JSON.stringify({
                error: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.ERROR, title, payload);
        }
    }
};
exports.error = error;
const errorValue = (title, payload) => {
    if (cfg.loggingLevel >= LoggingLevel.ERROR) {
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.error(JSON.stringify({
                error: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.ERROR, title, payload);
        }
    }
    return payload;
};
exports.errorValue = errorValue;
const exception = (message, e) => {
    const ex = e;
    if (cfg.loggingLevel >= LoggingLevel.ERROR) {
        const title = message + "-exception";
        const payload = {
            message: ex?.message || "unknown error",
            code: e ? e.code : undefined,
        };
        if (cfg.callConsole) {
            const ts = getTimestamp();
            console.error(JSON.stringify({
                error: title,
                ts,
                payload,
            }));
        }
        if (cfg.listener) {
            cfg.listener(LoggingLevel.ERROR, title, payload);
        }
    }
};
exports.exception = exception;
const write = (level, title, payload) => {
    switch (level) {
        case LoggingLevel.VERBOSE:
            (0, exports.verbose)(title, payload);
            break;
        case LoggingLevel.DEBUG:
            (0, exports.debug)(title, payload);
            break;
        case LoggingLevel.LOG:
            (0, exports.log)(title, payload);
            break;
        case LoggingLevel.WARN:
            (0, exports.warn)(title, payload);
            break;
        case LoggingLevel.ERROR:
            (0, exports.error)(title, payload);
            break;
        default:
    }
};
exports.write = write;
const inprogressTimingStack = [];
const startTiming = (name) => {
    const startTime = Date.now();
    inprogressTimingStack.push({ startTime, name });
};
exports.startTiming = startTiming;
const stopTiming = (name) => {
    // Maybe some timings got left behind, because the timing
    // did not account for exceptions or something. Close those off
    // so our stack doesn't get out of sync.
    while (inprogressTimingStack.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const timing = inprogressTimingStack.pop();
        (0, exports.log)("timing", {
            name: timing.name,
            duration: Date.now() - timing.startTime,
        });
        if (timing.name === name) {
            break;
        }
    }
};
exports.stopTiming = stopTiming;
const time = (name, action) => {
    (0, exports.startTiming)(name);
    try {
        action();
    }
    finally {
        (0, exports.stopTiming)(name);
    }
};
exports.time = time;
const timeValue = (name, action) => {
    (0, exports.startTiming)(name);
    try {
        return action();
    }
    finally {
        (0, exports.stopTiming)(name);
    }
};
exports.timeValue = timeValue;
const timePromise = async (name, action) => {
    (0, exports.startTiming)(name);
    try {
        return await action();
    }
    finally {
        (0, exports.stopTiming)(name);
    }
};
exports.timePromise = timePromise;
