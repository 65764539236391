// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'recompose';
import {
  Box,
  Layer,
  Heading,
  Card,
  Tabs,
  Text,
  Button,
  Tab,
  DropButton,
} from 'grommet';
import * as Sentry from '@sentry/browser';
import { CaretDownFill, CaretUpFill, Clone, LinkPrevious } from 'grommet-icons';
import { useParams, withRouter, useLocation } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from '../../helpers/axios';
import ScoringLayer from '../SetUp/ScoringLayer';
import { UserContext } from '../../services/Session/context';
import { BasicLoader } from '../../helpers/Loaders';
import SHDButton from '../../components/SHD/Button';
import LiveTestCard from '../LiveTest/LiveTestCard';
import ScoringDashboard from '../Scoring/ScoringDashboard';
import Toast from '../../components/Toast';
import withLayout from '../../helpers/withLayout';
import useZendeskWidget from '../../helpers/useZendeskWidget';
import { useIsFeatureEnabled } from '../../helpers/utils';

const ScoringConfig = ({ history }) => {
  const userStore = useContext(UserContext);
  const isNewLivestreamerExperience = useIsFeatureEnabled(
    'new_livestreamer_experience',
    userStore
  );
  const { teamid: teamId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const scoreDelegationKey = queryParams.get('key');
  const [show, setShow] = useState(false);
  const [showSyncRoster, setShowSyncRoster] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState(null);
  const [games, setGames] = useState([]);
  const [force, setForce] = useState(false);
  const [isSscorePaired, setIsSscorePaired] = useState(false);
  const [isIscorePaired, setIsIscorePaired] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showPin, setShowPin] = useState(false);

  useZendeskWidget();

  useEffect(() => {
    localStorage.setItem('userRole', 0);
    if (userStore.selectedTeam) {
      axios
        .get(`/api/score_team/${userStore.selectedTeam._id}`)
        .then((response) => {
          const { taggerPullerId, iScoreData } = response.data;
          if (taggerPullerId === 'iscore-v001' && userStore.selectedTeam) {
            setIsIscorePaired(true);

            const { gameList } = iScoreData;
            if (gameList && gameList.length) {
              setGames(gameList);
            } else {
              setGames([]);
            }
          } else if (taggerPullerId === 'sscore-v001') {
            setIsSscorePaired(true);
            setIsIscorePaired(false);
          }
          toggleLoading(false);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          if (respError.response && respError.response.status !== 406) {
            setError(respError.toString());
          }
          toggleLoading(false);
        });
    } else if ((teamId && scoreDelegationKey) || userStore.scoringTeam) {
      setIsSscorePaired(true);
      setIsIscorePaired(false);
      toggleLoading(false);
    } else {
      history.push('/signin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [force]);

  const deleteScoring = () => {
    toggleLoading(true);
    axios
      .delete(`/api/score_team/${userStore.selectedTeam._id}`)
      .then(() => {
        setGames([]);
        toggleLoading(false);
        setIsIscorePaired(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError.toString());
        toggleLoading(false);
      });
  };

  if (loading) {
    return <BasicLoader fullPage />;
  }

  const renderGame = (game) => (
    <Card>
      <Box
        pad="xsmall"
        direction="row"
        justify="between"
        background="secondary-1"
      >
        <Text>{game.teamAName}</Text>
        <Text>{game.teamAScore}</Text>
      </Box>
      <Box
        pad="xsmall"
        direction="row"
        justify="between"
        background="secondary-1"
      >
        <Text>{game.teamHName}</Text>
        <Text>{game.teamHScore}</Text>
      </Box>
      <Box pad="xsmall" gap="medium" background="secondary-7">
        <Text>{`${game.scoreboardTs_elapsed} ago`}</Text>
        <Box direction="row" justify="around">
          <Box direction="row">
            {game.statusUpdate.isTop ? <CaretUpFill /> : <CaretDownFill />}
            <Text>{game.statusUpdate.inning}</Text>
          </Box>
          <Text>{`${game.statusUpdate.balls} balls`}</Text>
          <Text>{`${game.statusUpdate.strikes} strikes`}</Text>
          <Text>{`${game.statusUpdate.outs} outs`}</Text>
        </Box>
      </Box>
    </Card>
  );

  const activeGames = (
    <Box direction="column" gap="medium">
      {games.length && games.map((game) => renderGame(game))}
    </Box>
  );

  const iScoreIsController = (
    <>
      <Box align="center">
        <Card pad="medium" gap="medium" background="primary-8">
          <Text size="large">
            You have chosen iScore as your scoreboard controller
          </Text>
          <Button
            label={<Text weight="bold">Re-sync Roster</Text>}
            primary
            color="tertiary-1"
            size="large"
            onClick={() => setShowSyncRoster(true)}
          />
        </Card>
      </Box>
      {showPin ? (
        <Box margin={{ top: 'large' }}>
          <Card
            direction="row"
            gap="small"
            pad="medium"
            align="center"
            background="primary-8"
          >
            <Text>Hide PIN and handle</Text>
            <SHDButton
              icon={<CaretUpFill color="black" />}
              hoverIndicator
              onClick={() => setShowPin(false)}
              mixtrack={['iScore Hide Pin and Handle']}
            />
          </Card>
          <Box width="medium" pad="small">
            <Box gap="small">
              <Text weight="bold">Team handle</Text>
              <Box direction="row" align="center" justify="between">
                <Text>{userStore.selectedTeam.nameHandle}</Text>
                <CopyToClipboard
                  text={userStore.selectedTeam.nameHandle}
                  onCopy={() => setCopied(true)}
                >
                  <SHDButton icon={<Clone color="black" />} hoverIndicator />
                </CopyToClipboard>
              </Box>
            </Box>
            <Box gap="small">
              <Text weight="bold">Team PIN</Text>
              <Box direction="row" align="center" justify="between">
                <Text>{userStore.selectedTeam.pin}</Text>
                <CopyToClipboard
                  text={userStore.selectedTeam.pin}
                  onCopy={() => setCopied(true)}
                >
                  <SHDButton icon={<Clone color="black" />} hoverIndicator />
                </CopyToClipboard>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Card
          direction="row"
          align="center"
          gap="small"
          pad="medium"
          margin={{ top: 'large' }}
          background="primary-8"
        >
          <Text>Show PIN and handle</Text>
          <SHDButton
            icon={<CaretDownFill color="black" />}
            hoverIndicator
            onClick={() => setShowPin(true)}
            mixtrack={['iScore Show PIN and handle']}
          />
        </Card>
      )}
      <Tabs
        margin={{ top: 'medium' }}
        id="tab-header"
        alignControls="stretch"
        alignSelf="stretch"
      >
        <Tab title="Past Games">
          {games.length ? (
            activeGames
          ) : (
            <Text size="large" color="status-warning">
              You have no iScore games in the past two days. Please start a game
              and then refresh the page.
            </Text>
          )}
        </Tab>
      </Tabs>
    </>
  );

  const noController = (
    <Card pad="medium" gap="medium" background="primary-8">
      <Text size="large">No scoreboard controller selected</Text>
      <Button
        label={<Text weight="bold">Select scoreboard controller</Text>}
        primary
        color="tertiary-1"
        size="large"
        onClick={() => setShow(true)}
      />
    </Card>
  );

  const renderDropContent = () => {
    if (isIscorePaired) {
      return (
        <Box width="medium" pad="medium">
          <Button
            primary
            color="primary-8"
            label={
              <Text weight="bold" color="primary-2">
                Unpair iScore
              </Text>
            }
            onClick={() => setDeleteShow(true)}
          />
        </Box>
      );
    }
    return <></>;
  };

  if (!isIscorePaired && isSscorePaired) {
    return (
      <Box margin="0 auto">
        <ScoringDashboard onUnlink={() => setIsSscorePaired(false)} />
      </Box>
    );
  }

  if (userStore.selectedTeam) {
    return (
      <Box
        pad={{ left: 'large', right: 'large' }}
        justify="center"
        width="large"
        margin="0 auto"
      >
        <LiveTestCard />
        <Box direction="row" justify="between">
          <Box direction="row" gap="medium" align="center">
            {isNewLivestreamerExperience && (
              <Box
                round="full"
                elevation="small"
                pad="small"
                background="white"
                onClick={() => {
                  history.goBack();
                }}
              >
                <LinkPrevious size="24px" />
              </Box>
            )}
            <Heading level={3} margin="medium">
              {userStore.selectedTeam.nameLong}
            </Heading>
          </Box>
          {(isIscorePaired || isSscorePaired) && (
            <DropButton
              margin="medium"
              primary
              color="primary-8"
              label={
                <Text weight="bold" color="primary-1">
                  Settings
                </Text>
              }
              dropContent={renderDropContent()}
            />
          )}
        </Box>
        {isIscorePaired && !isSscorePaired ? iScoreIsController : noController}
        {show ? (
          <ScoringLayer
            completeStep={() => {
              setShow(false);
              toggleLoading(false);
              setForce((prevState) => !prevState);
            }}
            closeLayer={() => setShow(false)}
          />
        ) : null}
        {showSyncRoster && (
          <ScoringLayer
            isSyncRoster
            scoreChoice="iscore-v001"
            completeStep={() => {
              setShow(false);
              toggleLoading(false);
              setForce((prevState) => !prevState);
            }}
            closeLayer={() => setShowSyncRoster(false)}
          />
        )}
        {deleteShow ? (
          <Layer>
            <Box margin="xlarge" gap="small" align="center">
              <Heading level={4} textAlign="center">
                Are you sure you want to unpair iScore?
              </Heading>
              <Box width="medium" margin={{ bottom: 'large' }}>
                <Text textAlign="center">
                  Unpairing will not cause you to lose iScore data.
                </Text>
              </Box>
              <Box gap="medium">
                <SHDButton
                  size="large"
                  label={
                    <Text weight="bold" color="tertiary-1">
                      Yes, unpair iScore
                    </Text>
                  }
                  mixtrack={['Unpair iScore']}
                  color="tertiary-1"
                  onClick={() => {
                    deleteScoring();
                    setDeleteShow(false);
                  }}
                />
                <SHDButton
                  plain
                  alignSelf="center"
                  label={
                    <Text weight="bold" color="tertiary-1" textAlign="center">
                      Cancel
                    </Text>
                  }
                  mixtrack={['Cancel unpair iScore']}
                  onClick={() => setDeleteShow(false)}
                />
              </Box>
            </Box>
          </Layer>
        ) : null}
        {copied ? (
          <Toast
            label="Copied to clipboard!"
            background="status-ok"
            duration={3000}
            onClose={() => {
              setCopied(false);
            }}
          />
        ) : null}
        {error && <Text color="status-critical">{error}</Text>}
      </Box>
    );
  }

  return null;
};

export default compose(withLayout, withRouter)(ScoringConfig);
