// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Anchor, Box, Button, Footer, Text } from 'grommet';
import { StopFill } from 'grommet-icons';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../services/Session';
import axios from '../helpers/axios';
import { withFirebase } from '../services/Firebase';

const ImpersonateBanner = withRouter(
  withFirebase(({ spoofedUser, ...props }) => {
    const USER_TYPE = 1;
    const TEAM_TYPE = 2;
    const PLAYER_TYPE = 3;

    const userStore = useContext(UserContext);
    const team = userStore.selectedTeam;
    const player = userStore.selectedPlayer;
    const [loading, setLoading] = React.useState(false);

    const stopImpersonationButton = (
      <Button
        primary
        color="white"
        label="Stop impersonation"
        margin="medium"
        icon={<StopFill />}
        onClick={() => {
          setLoading(true);
          axios
            .delete('/api/impersonate', {
              data: {
                auth_user_id: props.firebase.auth.currentUser.uid,
                spoof_user_id: null,
              },
            })
            .then(async (response) => {
              if (response.status === 200) {
                const user = props.firebase.auth.currentUser;

                await user.getIdTokenResult(true);
                localStorage.setItem('userRole', 0);
                window.location.href = '/admin';
              }
            });
        }}
      />
    );

    const renderSection = (type, recordId) => {
      let metabaseData = '';
      let linkText = '';
      let detailText = '';
      let extraInfo;
      if (type === USER_TYPE) {
        metabaseData = `{"name":null,"dataset_query":{"query":{"source-table":5,"filter":["and",["=",["field",37,null],"${recordId}"]]},"type":"query","database":2},"display":"table","visualization_settings":{}}`;
        linkText = `${spoofedUser.nameFirst} ${spoofedUser.nameLast}`;
        detailText = ` (id: ${spoofedUser._id})`;
        extraInfo = (
          <Box margin={{ top: 'xsmall' }}>
            <Text>
              {spoofedUser.email}{' '}
              {spoofedUser.phoneList.length ? spoofedUser.phoneList[0] : ''}
            </Text>
          </Box>
        );
      } else if (type === TEAM_TYPE) {
        metabaseData = `{"name":null,"dataset_query":{"query":{"source-table":28,"filter":["and",["=",["field",468,null],"${recordId}"]]},"type":"query","database":2},"display":"table","visualization_settings":{}}`;
        linkText = `${team.nameLong}`;
        detailText = ` (id: ${team._id})`;
      } else if (type === PLAYER_TYPE) {
        metabaseData = `{"name":null,"dataset_query":{"query":{"source-table":9,"filter":["and",["=",["field",105,null],"${recordId}"]]},"type":"query","database":2},"display":"table","visualization_settings":{}}`;
        linkText = `${player.nameFirst} ${player.nameLast}`;
        detailText = ` (id: ${player._id})`;
      }
      const metabaseDataEncoded = btoa(metabaseData);
      const metabaseUrl = `http://metabase.sidelinehd.com/question#${metabaseDataEncoded}`;

      return (
        <Text>
          <Anchor href={metabaseUrl} target="_blank" color="white">
            {linkText}
          </Anchor>
          <Text>{detailText}</Text>
          {extraInfo}
        </Text>
      );
    };

    return (
      <Footer
        background="status-critical"
        pad="small"
        direction="column"
        align="start"
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {renderSection(USER_TYPE, spoofedUser._id)}
            {team && renderSection(TEAM_TYPE, team._id)}
            {player && renderSection(PLAYER_TYPE, player._id)}
            {stopImpersonationButton}
          </>
        )}
      </Footer>
    );
  })
);

export default ImpersonateBanner;
