// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import SelectingStep from './SlectingStep';
import ConfirmationStep from './ConfirmationStep';

const HasPhoneNumber = ({
  teamPlayer, selectedPhone, setSelectedPhone, isConfirmSelectedPhone,
}) => (
  !isConfirmSelectedPhone
    ? (
      <SelectingStep
        teamPlayer={teamPlayer}
        setSelectedPhone={setSelectedPhone}
        selectedPhone={selectedPhone}
      />
    )
    : <ConfirmationStep selectedPhone={selectedPhone} />
);

export default HasPhoneNumber;
