// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Card, CardBody, Heading } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import LiveTest from './LiveTest';
import SHDButton from '../../components/SHD/Button';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import { calculateTimeLeft } from '../../helpers/utils';

const LiveTestCard = ({ isPrompted = false, ...props }) => {
  const userStore = useContext(UserContext);
  const [loading, toggleLoading] = useState(false);
  const [showLiveTest, setShowLiveTest] = useState(false);
  const [endTs, setEndTs] = useState(props.endTs || -1);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    if (endTs === -1) {
      toggleLoading(true);
      axios
        .get(`/api/live_test/${userStore.selectedTeam._id}`)
        .then(({ data: liveTestModeExpirationTs }) => {
          setEndTs(liveTestModeExpirationTs);
          setTimeLeft(calculateTimeLeft(liveTestModeExpirationTs));
          toggleLoading(false);
        })
        .catch((respError) => {
          toggleLoading(false);
          Sentry.captureException(respError);
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (endTs) {
      const timer = setTimeout(() => {
        const newTimeLeft = calculateTimeLeft(endTs);
        setTimeLeft(newTimeLeft);
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (Object.keys(timeLeft).length) {
      setTimeLeft({});
    }

    return () => {};
  });

  if (loading) {
    return null;
  }

  if (!Object.keys(timeLeft).length && !isPrompted) {
    return null;
  }
  if (!Object.keys(timeLeft).length && isPrompted) {
    return (
      <Card background="light-2">
        <CardBody pad="medium" direction="row" justify="between" align="center">
          <Heading level={5}>Do a LIVE test...</Heading>
          <SHDButton
            primary
            label="Begin test"
            onClick={() => setShowLiveTest(true)}
          />
        </CardBody>
        {showLiveTest ? (
          <LiveTest
            onClose={() => setShowLiveTest(false)}
            onStartTest={(newTs) => {
              setEndTs(newTs);
              setTimeLeft(calculateTimeLeft(newTs));
            }}
          />
        ) : null}
      </Card>
    );
  }
  return (
    <Card background="light-2">
      <CardBody pad="medium" direction="row" justify="between" align="center">
        <Heading level={5}>You&apos;re in a LIVE test...</Heading>
        <SHDButton
          primary
          label="View test"
          onClick={() => setShowLiveTest(true)}
        />
      </CardBody>
      {showLiveTest ? (
        <LiveTest
          onClose={() => setShowLiveTest(false)}
          endTs={endTs}
          onEndTest={() => {
            setEndTs(0);
            setTimeLeft({});
          }}
          onExtendTest={(newTs) => {
            setEndTs(newTs);
            setTimeLeft(calculateTimeLeft(newTs));
          }}
        />
      ) : null}
    </Card>
  );
};

export default LiveTestCard;
