import { shd } from '@shd/jslib/models';
import * as Sentry from '@sentry/react';
import { Box, ResponsiveContext, Text } from 'grommet';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from '../../../helpers/axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GameProps, ScheduleState } from './games.types';

import GameCard from './GameCard';
import RoundedToggle from '../../../components/RoundedToggle';

const Games: React.FC<GameProps & RouteComponentProps> = ({
  team,
  isAdmin,
}) => {
  const screenSize = useContext(ResponsiveContext);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState<shd.DnorGame[]>([]);
  const [toggleUpcoming, setToggleUpcoming] = useState<boolean>(false);
  const [upcomingGames, setUpcomingGames] = useState<shd.DnorGame[]>([]);

  useEffect(() => {
    axios
      .get(`api/team/${team._id}/games_details`)
      .then((res) => {
        const { teamGames } = res.data;
        const uGames = teamGames.filter((game: shd.DnorGame) => {
          const gameDate = game.J_tsStart;
          const today = Math.floor(new Date().getTime() / 1000);

          return (
            game.scheduleState === ScheduleState.SCHEDULED && gameDate >= today
          );
        });

        const pastGames = teamGames.filter((game: shd.DnorGame) => {
          return game.scheduleState !== ScheduleState.SCHEDULED;
        });

        setGames(pastGames);
        setUpcomingGames(uGames);
        setLoading(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, [team._id]);

  const handleDeleteGame = useCallback(
    (teamId: string, gameId: string) => {
      axios
        .delete('/api/team_game', {
          data: {
            teamId: teamId,
            teamGameId: gameId,
          },
        })
        .then(() => {
          setGames(games.filter((g) => g._id !== gameId));
          setUpcomingGames(upcomingGames.filter((g) => g._id !== gameId));
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    },
    [games, upcomingGames]
  );

  const renderGames = () => {
    if (games.length === 0) {
      return (
        <Text weight="bold" style={{ fontStyle: 'italic' }}>
          No Games to display
        </Text>
      );
    }
    return games
      .sort(({ J_tsStart: a }, { J_tsStart: b }) => b - a)
      .map((game) => {
        return (
          <GameCard
            key={game._id}
            game={game}
            team={team}
            handleDeleteGame={handleDeleteGame}
            isAdmin={isAdmin}
          />
        );
      });
  };

  const renderUpcomingGames = () => {
    if (upcomingGames.length === 0) {
      return (
        <Text weight="bold" style={{ fontStyle: 'italic' }}>
          No upcoming Games
        </Text>
      );
    }
    return upcomingGames
      .sort(({ J_tsStart: a }, { J_tsStart: b }) => b - a)
      .map((uGame) => (
        <GameCard
          key={uGame._id}
          game={uGame}
          team={team}
          handleDeleteGame={handleDeleteGame}
          isAdmin={isAdmin}
        />
      ));
  };

  return (
    <Box
      skeleton={loading}
      fill
      gap="medium"
      pad={{
        vertical: 'medium',
        horizontal: screenSize === 'small' ? 'medium' : 'none',
      }}
    >
      <Box direction="row" gap="medium" justify="between" align="center">
        <Text weight={'bold'}>Show Upcoming Games</Text>
        <RoundedToggle
          checked={toggleUpcoming}
          onChange={() => {
            setToggleUpcoming(!toggleUpcoming);
          }}
        />
      </Box>
      <Box gap="medium" skeleton={loading}>
        {toggleUpcoming && renderUpcomingGames()}
        {renderGames()}
      </Box>
    </Box>
  );
};

export default withRouter(Games);
