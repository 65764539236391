// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { shd } from '@shd/jslib/models';
import { Avatar, Box, Button, Card, Image, Text } from 'grommet';
import React, { useEffect, useState } from 'react';
import axios from '../../helpers/axios';
import SHDLink from '../../helpers/SHDLink';
import EditPlayerProfile from '../Player/EditPlayerProfile';

interface Props {
  player: shd.ClaimedPlayer;
  isAdmin: boolean;
}

const ClaimedPlayerCard: React.FC<Props> = ({ player, isAdmin }) => {
  const [fullPlayer, setFullPlayer] = useState<shd.ClaimedPlayer>(player);
  const [teams, setTeams] = useState<shd.Team[]>([]);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const isDKPaired = fullPlayer.dkPlayerUuid;

  useEffect(() => {
    const fetchPlayer = async () => {
      const response = await axios.get(`/api/player/${player._id}`);
      if (response.data) {
        setFullPlayer(response.data.player);
        setTeams(response.data.teams);
      }
    };
    fetchPlayer();
  }, [player._id]);

  const getTitle = () => {
    // join together the positions
    const positions = [];
    if (fullPlayer.positionPrimary) {
      positions.push(fullPlayer.positionPrimary);
    }
    if (fullPlayer.positionPrimaryVB) {
      positions.push(fullPlayer.positionPrimaryVB);
    }
    if (fullPlayer.positionPrimaryBasketball) {
      positions.push(fullPlayer.positionPrimaryBasketball);
    }
    if (fullPlayer.positionPrimarySoccer) {
      positions.push(fullPlayer.positionPrimarySoccer);
    }

    if (positions.length > 0) {
      return positions.join(', ');
    }
    return '-';
  };

  const getSubtitle = () => {
    if (fullPlayer.yearGraduation) {
      return `Class of ${fullPlayer.yearGraduation}`;
    } else if (fullPlayer.collegeCommitName) {
      return `Committed: ${fullPlayer.collegeCommitName}`;
    }
    return '';
  };

  return (
    <Card pad="medium" elevation="small" gap="large">
      <Box
        direction="row"
        gap="small"
        flex={{ grow: 1, shrink: 0 }}
        fill="horizontal"
      >
        <SHDLink to={`/${player._id}`}>
          {fullPlayer.DNOR_imgUrlT150 ? (
            <Avatar src={fullPlayer.DNOR_imgUrlT150} size="medium" />
          ) : (
            <Avatar background="gray-2" size="medium"></Avatar>
          )}
        </SHDLink>
        <SHDLink
          to={`/${player._id}`}
          style={{
            flex: '1 1',
            minWidth: '0px',
          }}
        >
          <Box gap="xsmall" flex>
            <Box direction="row" gap="small" align="center">
              <Text weight={'bold'} truncate>
                {fullPlayer.nameFirst} {fullPlayer.nameLast}
              </Text>
              {isDKPaired && (
                <Box height="16px" width="16px" round="full">
                  <Image src="dk_icon.png" fit="contain" />
                </Box>
              )}
            </Box>
            <Box gap="xsmall">
              <Text truncate>{getTitle()}</Text>
              <Text truncate size="small">
                {getSubtitle()}
              </Text>
            </Box>
          </Box>
        </SHDLink>
        <Box align="end" justify="end" width={{ min: '29px' }}>
          {isAdmin && (
            <Button
              plain
              label={
                <Text weight="bold" color="tertiary-1">
                  Edit
                </Text>
              }
              onClick={() => {
                setShowEditProfile(true);
              }}
            />
          )}
        </Box>
      </Box>
      {showEditProfile && (
        <EditPlayerProfile
          teams={teams}
          closeLayer={() => {
            setShowEditProfile(false);
          }}
          setShowEditSuccess={(updatedPlayer) => {
            setFullPlayer(updatedPlayer);
          }}
          claimedPlayer={fullPlayer}
        />
      )}
    </Card>
  );
};

export default ClaimedPlayerCard;
