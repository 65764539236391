// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CONNECTION_URL } from '../../../../constants/urlKeysConstants';
import {
  BANDWIDTH_UNLIMITED,
  DEFAULT_CODEC,
  H264_LIMIT_RESOLUTION,
  RESOLUTIONS,
  STREAM_HEALTH_STATUSES,
} from './constants';

import BitRateQueue from './BitRateQueue';

import { isPortraitMode } from '../../../../helpers/utils';
import { strogging } from '@shd/jslib/infra';

export const getUserMedia = ({ constraints }) => {
  if (!navigator.mediaDevices?.getUserMedia) {
    return Promise.reject(new Error("getUserMedia doesn't exist"));
  }
  return navigator.mediaDevices.getUserMedia(constraints);
};

export const getStreamInfo = ({
  connection,
  rate,
  setStreamBitRate,
  setStreamHealth,
  setStreamInfo,
  restartStream,
  sampleStream,
  logger,
}) => {
  let timestampPrev = 0;
  let bytesPrevSent = 0;
  let bitrate = 0;

  let firCountPrev = 0;
  let nackCountPrev = 0;
  let pliCountPrev = 0;

  const bitRateQueue = new BitRateQueue({ length: 4 });
  const firRateQueue = new BitRateQueue({ length: 4 });
  const nackRateQueue = new BitRateQueue({ length: 4 });
  const pliRateQueue = new BitRateQueue({ length: 4 });

  return setInterval(
    () =>
      connection
        .getStats()
        .then((result) => {
          const videoStats = [...result.values()].find(
            (stat) => stat.type === 'outbound-rtp' && stat.kind === 'video'
          );
          // const audioStats = [...result.values()].find(
          //   (stat) => stat.type === 'outbound-rtp' && stat.kind === 'audio',
          // );

          if (!videoStats) {
            return;
          }
          const {
            bytesSent,
            timestamp,
            totalEncodeTime,
            nackCount,
            pliCount,
            firCount,
            framesPerSecond,
            frameHeight,
          } = videoStats;

          const timestampDelta = timestamp - timestampPrev;
          bitrate = timestampPrev
            ? Math.floor((8 * (bytesSent - bytesPrevSent)) / timestampDelta)
            : bitrate;

          timestampPrev = timestamp;
          bytesPrevSent = bytesSent;

          bitRateQueue.pushItem(bitrate);
          const bitRateMedian = bitRateQueue.getMedian();
          setStreamBitRate(bitRateMedian);

          // heuristic for stream down
          if (bitrate < 50 && bitRateMedian < 50 && totalEncodeTime > 5.0) {
            if (framesPerSecond > 25) {
              strogging.log('not restarting stream because fps is high', {
                bitrate,
                bitRateMedian,
                totalEncodeTime,
                framesPerSecond,
              });
            } else {
              strogging.warn('restart stream', {
                bitrate,
                bitRateMedian,
                totalEncodeTime,
              });
              restartStream(true);
            }
          }

          const thresholds = {
            bitrate: { weight: 0.25, good: 1000, ok: 500 }, // Assume this is in Kbps
            firRate: { weight: 0.2, good: 0.5, ok: 0.75 }, // FIRs per second - adjust as needed
            nackRate: { weight: 0.2, good: 5, ok: 10 }, // NACKs per second - adjust as needed
            pliRate: { weight: 0.3, good: 0.5, ok: 0.75 }, // PLIs per second - adjust as needed
          };

          // Calculate the metrics.
          const timestampDeltaSeconds = timestampDelta / 1000;

          const firCountDelta = firCount - firCountPrev;
          const nackCountDelta = nackCount - nackCountPrev;
          const pliCountDelta = pliCount - pliCountPrev;

          firCountPrev = firCount;
          nackCountPrev = nackCount;
          pliCountPrev = pliCount;

          const firRate = firCountDelta / timestampDeltaSeconds; // FIRs per second
          const nackRate = nackCountDelta / timestampDeltaSeconds; // NACKs per second
          const pliRate = pliCountDelta / timestampDeltaSeconds; // PLIs per second

          sampleStream(framesPerSecond, bitrate, firRate, nackRate, pliRate);

          firRateQueue.pushItem(firRate);
          nackRateQueue.pushItem(nackRate);
          pliRateQueue.pushItem(pliRate);

          const firRateMedian = firRateQueue.getMean();
          const nackRateMedian = nackRateQueue.getMean();
          const pliRateMedian = pliRateQueue.getMean();

          let totalScore = 0;
          totalScore +=
            (bitrate > thresholds.bitrate.good * timestampDeltaSeconds
              ? 1
              : bitrate > thresholds.bitrate.ok * timestampDeltaSeconds
                ? 0.5
                : 0) * thresholds.bitrate.weight;
          totalScore +=
            (firRateMedian < thresholds.firRate.good * timestampDeltaSeconds
              ? 1
              : firRateMedian < thresholds.firRate.ok * timestampDeltaSeconds
                ? 0.5
                : 0) * thresholds.firRate.weight;
          totalScore +=
            (nackRateMedian < thresholds.nackRate.good * timestampDeltaSeconds
              ? 1
              : nackRateMedian < thresholds.nackRate.ok * timestampDeltaSeconds
                ? 0.5
                : 0) * thresholds.nackRate.weight;
          totalScore +=
            (pliRateMedian < thresholds.pliRate.good * timestampDeltaSeconds
              ? 1
              : pliRateMedian < thresholds.pliRate.ok * timestampDeltaSeconds
                ? 0.5
                : 0) * thresholds.pliRate.weight;

          let healthStatus;
          if (totalScore >= 0.75) {
            healthStatus = STREAM_HEALTH_STATUSES.good;
          } else if (totalScore >= 0.5) {
            healthStatus = STREAM_HEALTH_STATUSES.ok;
          } else {
            healthStatus = STREAM_HEALTH_STATUSES.bad;
          }
          const memoryStats = window.performance?.memory
            ? {
                totalJSHeapSize: window.performance.memory.totalJSHeapSize,
                usedJSHeapSize: window.performance.memory.usedJSHeapSize,
                jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
              }
            : {};

          strogging.log('getStreamInfo', {
            videoStats: {
              codecId: videoStats.codecId,
              frameHeight: videoStats.frameHeight,
              framesPerSecond: videoStats.framesPerSecond,
              targetBitrate: videoStats.targetBitrate,
            },
            // audioStats,
            streamHealth: {
              bitrate,
              firRateMedian,
              nackRateMedian,
              pliRateMedian,
              totalScore,
              healthStatus,
            },
            memoryStats,
          });
          setStreamHealth(healthStatus);

          // fps, resolution, and any other info we want to add later
          setStreamInfo({
            fps: framesPerSecond,
            resolution: frameHeight,
          });
        })
        .catch(logger.error),
    rate
  );
};

export const updateTracks = ({ stream, connection }) => {
  const audioTrack = stream.getTracks().find(({ kind }) => kind === 'audio');
  audioTrack.contentHint = 'music';
  connection.addTrack(audioTrack, stream);

  const videoTrack = stream.getTracks().find(({ kind }) => kind === 'video');
  videoTrack.contentHint = 'motion';
  connection.addTrack(videoTrack, stream);
};

export const getCanvasStream = ({ stream, zoomRef, resolutionRef, logger }) => {
  const video = document.createElement('video');
  video.muted = true;
  // playsInline is needed for IOS
  video.playsInline = true;
  video.autoplay = false;
  video.loop = true;

  const canvasFrameRate = 60;
  const frameInterval = Math.round(1000 / canvasFrameRate);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  function drawCanvasFrame() {
    if (video.paused || video.ended) {
      return;
    }

    canvas.width = resolutionRef.current.value.width;
    canvas.height = resolutionRef.current.value.height;

    // make digital zoom by scaling canvas image
    const centerX = (canvas.width / 2) * zoomRef.current;
    const centerY = (canvas.height / 2) * zoomRef.current;
    context.translate(-centerX, -centerY);
    context.scale(zoomRef.current + 1, zoomRef.current + 1);

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    context.restore();
  }

  video.addEventListener('play', () =>
    setInterval(drawCanvasFrame, frameInterval)
  );
  video.srcObject = stream;

  if (video.paused) {
    video
      .play()
      .then(() => logger.info('Start canvas video'))
      .catch(logger.error);
  }

  return canvas.captureStream(30);
};

export const updateBandWidth = ({ connection, bandwidthValue, logger }) => {
  connection.getSenders().forEach((sender) => {
    if (sender.track.kind === 'video') {
      const parameters = sender.getParameters();

      if (!parameters.encodings || undefined === parameters.encodings[0]) {
        parameters.encodings = [{}]; // old safari need this
      }

      if (bandwidthValue.value === BANDWIDTH_UNLIMITED) {
        delete parameters.encodings[0].maxBitrate;
      } else {
        parameters.encodings[0].maxBitrate = bandwidthValue.value * 1000;
      }

      parameters.degradationPreference = 'maintain-framerate';

      sender
        .setParameters(parameters)
        .then(() => {
          logger.info('bandwidth limit is set', bandwidthValue.label);
        })
        .catch(logger.error);
    }
  });
};

export const updateVideoCodec = ({
  connection,
  stream,
  codecValue,
  logger,
}) => {
  const videoTransceiver = connection.addTransceiver('video', {
    streams: [stream],
  });
  if (!videoTransceiver.setCodecPreferences) {
    return;
  }

  const codecs = [
    ...RTCRtpSender.getCapabilities('video').codecs,
    ...RTCRtpReceiver.getCapabilities('video').codecs,
  ].filter(({ mimeType }) => mimeType === codecValue.mimeType);

  logger.info('Video codec set to:', codecValue.mimeType);
  videoTransceiver.setCodecPreferences(codecs);
};

// eslint-disable-next-line max-len
export const getURL = () =>
  new URL(document.location).searchParams.get(CONNECTION_URL) ??
  'put here WebRTC URL to the server (Nimble)';

export const getDeviceLimitedResolution = ({ capabilities, codec }) => {
  const resolutions = RESOLUTIONS.filter(
    (res) => res.value.height <= capabilities.height.max
  );
  if (codec.value !== DEFAULT_CODEC.value) {
    return resolutions;
  }

  // browser Web RTC implementation supports 720p maximum resolution for H.264 codec
  return resolutions.filter(
    (res) => res.value.height <= H264_LIMIT_RESOLUTION.height
  );
};

export const applyConstraints = ({ stream, resolution }) =>
  new Promise((resolve, reject) => {
    const [track] = stream.getVideoTracks();

    // to maintain 16/9 aspect ratio of camera resolution
    const resolutionTuple = [resolution.value.width, resolution.value.height];
    const [width, height] = isPortraitMode()
      ? resolutionTuple.reverse()
      : resolutionTuple;

    strogging.log('applyConstraints', { width, height });
    track
      .applyConstraints({ width, height })
      .catch(reject)
      .then(() => {
        const settings = stream?.getVideoTracks()[0].getSettings();
        const [res] = [settings.height, settings.width].sort((a, b) => a - b);
        resolve(res);
      });
  });

export const getVideoInputs = async () => {
  // to get list of media devices need to have permissions
  try {
    if (navigator.permissions && navigator.permissions.query) {
      const cameraPermission = await navigator.permissions.query({
        name: 'camera',
      });
      if (cameraPermission.state !== 'granted') {
        return [];
      }
    } else {
      strogging.log(
        'getVideoInputs',
        'navigator.permissions.query is not supported'
      );
      return [
        {
          label: 'Update your software to view available cameras',
          value: 'invalid-device',
        },
      ];
    }

    const mediaInputs = await navigator.mediaDevices.enumerateDevices();
    strogging.log('getVideoInputs', { mediaInputs });

    return mediaInputs
      .filter(({ kind }) => kind === 'videoinput')
      .map(({ label, deviceId }) => ({ label, value: deviceId }));
  } catch (e) {
    strogging.exception('getVideoInputs', e);
    return [];
  }
};
