/* eslint-disable quotes */
interface ButtonExtendProps {
  cta?: boolean;
  colorValue?: string;
}

export const GROMMETTHEME = {
  name: 'shd-001-v1',
  rounding: 8,
  spacing: 24,
  defaultMode: 'light',
  global: {
    colors: {
      brand: {
        dark: '#085905',
        light: '#193710',
      },
      playerNav: {
        dark: '#085905',
        light: '#085905',
      },
      // ---------
      'primary-1': '#024006',
      'primary-2': '#085203',
      'primary-3': '#256515',
      'primary-4': '#46772f',
      'primary-5': '#678a4c',
      'primary-6': '#879c6a',
      'primary-7': '#d2deb6',
      'primary-8': '#f1f8e3',

      'secondary-1': '#066d99',
      'secondary-2': '#3999bf',
      'secondary-3': '#5eabca',
      'secondary-4': '#7fbbd5',
      'secondary-5': '#9dcbdf',
      'secondary-6': '#b9dbea',
      'secondary-7': '#d3eaf5',

      'tertiary-0': '#604aa1',
      'tertiary-1': '#7e68c0',
      'tertiary-2': '#a38de5',
      'tertiary-3': '#c2a8ee',
      'tertiary-4': '#dbc2f5',
      'tertiary-5': '#efdcfa',
      'tertiary-6': '#f7e7fc',
      'tertiary-7': '#fdf2ff',

      'dark-1': '#1c2128',
      'dark-2': '#282e3d',
      'dark-3': '#556370',
      'dark-4': '#8e9aa6',
      'dark-5': '#c7cfd9',

      'gray-1': '#272727',
      'gray-2': '#A8A8A8',

      'light-1': '#e8e8e8',
      'light-2': '#ebebf0',
      'light-3': '#f2f2f5',
      'light-4': '#fafafc',
      'light-5': '#f8f8f8',
      'light-6': '#E3E4EB',

      error: '#de1c1c',

      'accent-0': '#ce8414',
      'accent-1': '#efb21e',
      'accent-2': '#f2c14d',
      'accent-3': '#f6cf76',
      'accent-4': '#f9dc98',
      'accent-5': '#fde6b5',
      'accent-6': '#fff0cd',
      'accent-7': '#fff9e1',

      // ---------
      background: {
        dark: '#111111',
        light: '#FFFFFF',
      },
      'background-back': {
        dark: '#111111',
        light: '#EEEEEE',
      },
      'background-front': {
        dark: '#222222',
        light: '#FFFFFF',
      },
      'background-contrast': {
        dark: '#FFFFFF11',
        light: '#11111111',
      },
      text: {
        dark: '#EEEEEE',
        light: '#333333',
      },
      'text-strong': {
        dark: '#FFFFFF',
        light: '#000000',
      },
      'text-weak': {
        dark: '#CCCCCC',
        light: '#444444',
      },
      'text-xweak': {
        dark: '#999999',
        light: '#666666',
      },
      border: {
        dark: '#444444',
        light: '#CCCCCC',
      },
      control: 'brand',
      'active-background': 'background-contrast',
      'active-text': 'text-strong',
      'selected-background': 'brand',
      'selected-text': 'text-strong',
      'status-critical': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#00C781',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      'graph-0': 'brand',
      'graph-1': 'status-warning',
      focus: '#00FFFF',
      placeholder: 'dark-4',
    },
    focus: {
      outline: {
        size: '0px',
      },
    },
    font: {
      family: 'DMSans',
      size: '18px',
      height: '24px',
      maxWidth: '432px',
    },
    active: {
      background: 'active-background',
      color: 'active-text',
    },
    hover: {
      background: 'active-background',
      color: 'active-text',
    },
    selected: {
      background: 'selected-background',
      color: 'selected-text',
    },
    control: {
      border: {
        radius: '8px',
      },
    },
    drop: {
      border: {
        radius: '6px',
      },
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '3px',
      large: '9px',
      xlarge: '18px',
    },
    breakpoints: {
      // xsmall: {
      //   value: 650,
      // },
      small: {
        value: 576,
        // borderSize: {
        //   xsmall: '1px',
        //   small: '2px',
        //   medium: '3px',
        //   large: '5px',
        //   xlarge: '9px',
        // },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '4px',
          small: '8px',
          medium: '12px',
          large: '16px',
          xlarge: '24px',
        },
        // size: {
        //   xxsmall: '18px',
        //   xsmall: '36px',
        //   small: '72px',
        //   medium: '144px',
        //   large: '288px',
        //   xlarge: '576px',
        //   full: '100%',
        // },
      },
      medium: {
        value: 1152,
      },
      large: {},
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '2px',
      xsmall: '4px',
      small: '8px',
      medium: '16px',
      large: '18px',
      xlarge: '24px',
      responsiveBreakpoint: 'small',
    },
    input: {
      padding: {
        horizontal: '16px',
        vertical: '8px',
      },
      weight: 400,
      extend: {
        backgroundColor: '#f2f2f5',
        borderRadius: '0px',
      },
    },
    spacing: '18px',
    size: {
      xxsmall: '36px',
      xsmall: '72px',
      small: '144px',
      medium: '288px',
      large: '576px',
      xlarge: '864px',
      xxlarge: '1152px',
      full: '100%',
    },
  },
  chart: {},
  dataTable: {
    header: {
      bacgkround: 'white',
      border: 'bottom',
      font: {
        weight: 'bold',
      },
    },
  },
  diagram: {
    line: {},
  },
  meter: {},
  button: {
    active: {
      primary: {
        background: '#68569F',
      },
    },
    border: {
      width: '2px',
      radius: '6px',
    },
    disabled: {
      primary: {
        background: {
          color: '#A8A8A8',
        },
        secondary: {
          color: '#A8A8A8',
          background: {
            color: 'black',
          },
        },
        color: 'white',
        opacity: '0.5',
      },
      secondary: {
        border: {
          color: '#A8A8A8',
          width: '2px',
        },
        color: '#A8A8A8',
        opacity: '0.5',
      },
      default: {
        opacity: '0.5',
      },
      opacity: '1',
    },
    hover: {
      primary: {
        extend: (props: ButtonExtendProps) => {
          if (!props.colorValue) {
            return {
              background: '#A08BE1',
            };
          }
        },
        color: 'white',
      },
      secondary: {
        border: {
          width: '2px',
        },
      },
    },
    padding: {
      horizontal: '10px',
      vertical: '2px',
    },
    size: {
      small: {
        border: {
          radius: '360px',
        },
        pad: {
          horizontal: '16px',
          vertical: '8px',
        },
      },
      medium: {
        border: {
          radius: '8px',
        },
        pad: {
          horizontal: '16px',
          vertical: '8px',
        },
      },
      large: {
        border: {
          radius: '8px',
        },
        pad: {
          horizontal: '32px',
          vertical: '15px',
        },
      },
    },
    default: {
      border: {
        color: 'light-1',
      },
      color: 'brand',
    },
    primary: {
      background: {
        color: 'tertiary-1',
      },
      font: {
        weight: 'bold',
      },
      extend: (props: ButtonExtendProps) => {
        if (props.cta) {
          return {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
          };
        }
        return {};
      },
    },
    secondary: {
      border: {
        color: 'tertiary-1',
        width: '1px',
      },
      color: 'tertiary-1',
      font: {
        weight: 'bold',
      },
    },
  },
  checkBox: {
    check: {
      radius: '2px',
    },
    toggle: {
      radius: '4px',
      size: '36px',
      background: 'light-3',
    },
    border: {
      color: 'tertiary-1',
    },
    size: '16px',
    color: 'tertiary-1',
    gap: '8px',
  },
  layer: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
  },
  radioButton: {
    size: '16px',
    check: {
      radius: '100%',
      color: 'tertiary-1',
    },
    container: {
      width: '100%',
      extend: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    border: {
      color: '#686868',
    },
    color: 'tertiary-1',
  },
  formField: {
    border: false,
    content: {
      pad: 'small',
    },
    focus: {
      background: {
        color: 'brand',
      },
    },
    disabled: {
      background: {
        color: 'red',
        opacity: '0.5',
      },
    },
    error: {
      color: 'status-critical',
      margin: {
        vertical: 'xsmall',
        horizontal: 'small',
      },
    },
    help: {
      color: 'dark-3',
      margin: {
        start: 'small',
      },
    },
    info: {
      color: 'text-xweak',
      margin: {
        vertical: 'xsmall',
        horizontal: 'small',
      },
      bacgkround: 'brand',
    },
    label: {
      margin: {
        vertical: 'xsmall',
        horizontal: 'none',
      },
      color: 'black',
      size: 'xsmall',
      weight: 'bold',
    },
    margin: {
      bottom: 'small',
    },
    round: '0px',
  },
  textInput: {
    extend: ({ focus }: { focus: boolean }) => ({
      backgroundColor: 'white',
      borderRadius: '4px',
      color: '#686868',
      borderColor: focus ? '#7e68c0' : 'black',
    }),
    disabled: {
      opacity: '0.5',
    },
  },
  calendar: {
    small: {
      fontSize: '10.5px',
      lineHeight: 1.375,
      daySize: '20.57px',
    },
    medium: {
      fontSize: '13.5px',
      lineHeight: 1.45,
      daySize: '41.14px',
    },
    large: {
      fontSize: '22.5px',
      lineHeight: 1.11,
      daySize: '82.29px',
    },
  },
  clock: {
    analog: {
      hour: {
        width: '6px',
        size: '18px',
      },
      minute: {
        width: '3px',
        size: '9px',
      },
      second: {
        width: '2px',
        size: '7px',
      },
      size: {
        small: '54px',
        medium: '72px',
        large: '108px',
        xlarge: '162px',
        huge: '216px',
      },
    },
    digital: {
      text: {
        xsmall: {
          size: '8px',
          height: 1.5,
        },
        small: {
          size: '12px',
          height: 1.43,
        },
        medium: {
          size: '14px',
          height: 1.375,
        },
        large: {
          size: '16px',
          height: 1.167,
        },
        xlarge: {
          size: '20px',
          height: 1.1875,
        },
        xxlarge: {
          size: '26px',
          height: 1.125,
        },
      },
    },
  },
  heading: {
    level: {
      1: {
        small: {
          size: '26px',
          height: '30px',
          maxWidth: '459px',
        },
        medium: {
          size: '38px',
          height: '42px',
          maxWidth: '675px',
        },
        large: {
          size: '62px',
          height: '66px',
          maxWidth: '1107px',
        },
        xlarge: {
          size: '86px',
          height: '90px',
          maxWidth: '1539px',
        },
      },
      2: {
        small: {
          size: '23px',
          height: '27px',
          maxWidth: '405px',
        },
        medium: {
          size: '32px',
          height: '36px',
          maxWidth: '567px',
        },
        large: {
          size: '41px',
          height: '45px',
          maxWidth: '729px',
        },
        xlarge: {
          size: '50px',
          height: '54px',
          maxWidth: '891px',
        },
      },
      3: {
        small: {
          size: '20px',
          height: '24px',
          maxWidth: '405px',
        },
        medium: {
          size: '26px',
          height: '30px',
          maxWidth: '459px',
        },
        large: {
          size: '32px',
          height: '36px',
          maxWidth: '567px',
        },
        xlarge: {
          size: '38px',
          height: '42px',
          maxWidth: '675px',
        },
      },
      4: {
        small: {
          size: '17px',
          height: '21px',
          maxWidth: '405px',
        },
        medium: {
          size: '20px',
          height: '24px',
          maxWidth: '405px',
        },
        large: {
          size: '23px',
          height: '27px',
          maxWidth: '405px',
        },
        xlarge: {
          size: '26px',
          height: '30px',
          maxWidth: '459px',
        },
      },
      5: {
        small: {
          size: '14px',
          height: '20px',
          maxWidth: '405px',
        },
        medium: {
          size: '17px',
          height: '21px',
          maxWidth: '405px',
        },
        large: {
          size: '20px',
          height: '24px',
          maxWidth: '405px',
        },
        xlarge: {
          size: '23px',
          height: '27px',
          maxWidth: '405px',
        },
      },
      6: {
        small: {
          size: '11px',
          height: '15px',
          maxWidth: '405px',
        },
        medium: {
          size: '14px',
          height: '18px',
          maxWidth: '405px',
        },
        large: {
          size: '17px',
          height: '22px',
          maxWidth: '405px',
        },
        xlarge: {
          size: '20px',
          height: '25px',
          maxWidth: '405px',
        },
      },
    },
  },
  paragraph: {
    small: {
      size: '12px',
      height: '17px',
      maxWidth: '216px',
    },
    medium: {
      size: '14px',
      height: '18px',
      maxWidth: '243px',
    },
    large: {
      size: '17px',
      height: '21px',
      maxWidth: '297px',
    },
    xlarge: {
      size: '20px',
      height: '24px',
      maxWidth: '351px',
    },
    xxlarge: {
      size: '26px',
      height: '30px',
      maxWidth: '459px',
    },
  },
  text: {
    xsmall: {
      size: '10px',
      height: '13px',
      maxWidth: '189px',
    },
    small: {
      size: '12px',
      height: '16px',
      maxWidth: '216px',
    },
    medium: {
      size: '16px',
      height: '20px',
      maxWidth: '243px',
    },
    large: {
      size: '17px',
      height: '21px',
      maxWidth: '297px',
    },
    xlarge: {
      size: '20px',
      height: '24px',
      maxWidth: '351px',
    },
    xxlarge: {
      size: '26px',
      height: '30px',
      maxWidth: '459px',
    },
  },
  scale: 1,
  tabs: {
    background: 'none',
    header: {
      alignSelf: 'start',
    },
  },
  tab: {
    color: 'text',
    active: {
      background: 'none',
      color: 'tertiary-1',
    },
    hover: {
      background: 'none',
      color: 'tertiary-1',
    },
    border: {
      side: 'bottom',
      color: 'none',
      active: {
        color: 'tertiary-1',
      },
      hover: {
        color: 'none',
      },
    },
    background: 'none',
    pad: { horizontal: 'medium', vertical: 'small' },
    margin: 'none',
    extend: () => {
      return {
        fontWeight: 'bold',
      };
    },
  },
  table: {
    body: {},
    footer: {
      align: 'start',
      border: true,
      pad: { horizontal: 'large', vertical: 'small' },
      verticalAlign: 'bottom',
    },
    header: {
      align: 'start',
      border: 'bottom',
      fill: 'horizontal',
      verticalAlign: 'bottom',
    },
  },
  anchor: {
    textDecoration: 'underline',
    fontWeight: 400,
    hover: {
      fontWeight: 1000,
    },
  },
  select: {
    // container: {
    //   extend: ({ focus }: { focus: boolean }) => ({
    //     backgroundColor: 'white',
    //     borderRadius: '4px',
    //     color: '#686868',
    //     borderColor: focus ? '#7e68c0' : 'black',
    //   }),
    // },
  },
  grommet: {
    extend: ({ noMinHeight }: { noMinHeight: boolean }) => {
      if (noMinHeight) {
        return `
          min-height: 0;
        `;
      }
      return `
        min-height: 100svh;
      `;
    },
  },
};
