import React, { useState } from 'react';
import { Box, FormField, TextInput, Text } from 'grommet';
import PhoneNumberInput from '../../../helpers/PhoneNumberInput';

interface AddStaffProps {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  name: string;
  setName: (name: string) => void;
}

const AddStaff: React.FC<AddStaffProps> = ({
  phoneNumber,
  setPhoneNumber,
  name,
  setName,
}) => {
  const [phoneError, setPhoneError] = useState(false);
  const onValidPhone = (phoneValue: string) => {
    if (phoneValue) {
      setPhoneNumber(phoneValue);
      setPhoneError(false);
    } else {
      setPhoneNumber('');
    }
  };
  const onErrorPhone = (respError: boolean) => {
    if (respError) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  return (
    <>
      <FormField
        label={
          <Box direction="row" gap="xsmall">
            <Text size="xsmall">Phone Number</Text>
            {phoneError && (
              <Text size="xsmall" color="status-critical">
                Invalid
              </Text>
            )}
          </Box>
        }
        name="phoneNumber"
        required
      >
        <PhoneNumberInput
          value={phoneNumber}
          width="100%"
          placeholder="XXX-XXX-XXXX"
          onValid={onValidPhone}
          onError={onErrorPhone}
          successIcon={null}
        />
      </FormField>
      <FormField label="Name" htmlFor="name" name="name">
        <TextInput
          id="name"
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder="First Last (optional)"
        />
      </FormField>
    </>
  );
};

export default AddStaff;
