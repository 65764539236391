// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useMemo } from 'react';
import { Box, Text } from 'grommet';
import PlayerCard from './PlayerCard';

const RecentPlayers = () => {
  const players = useMemo(() => {
    const viewedPlayersJSON = localStorage.getItem('viewedPlayers');
    if (!viewedPlayersJSON) {
      return [];
    }

    return Object.values(JSON.parse(viewedPlayersJSON));
  }, []);

  if (!players.length) {
    return (
      <Box>
        <Text color="dark-1">No recently viewed players</Text>
      </Box>
    );
  }

  return (
    <Box pad="small" gap="small">
      {players.slice(0, 5).map((player) => (
        <PlayerCard key={player.id} player={player} />
      ))}
    </Box>
  );
};

export default RecentPlayers;
