"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bytesAtBitrateToSeconds = exports.secondsAtBitrateToBytes = exports.kbitsToBytes = exports.bytesToKbits = exports.bytesOverTimeToKbps = exports.updateMeanValues = exports.INITIAL_MEAN_VALUES = exports.updateAggregateValues = exports.INITIAL_AGGREGATE_VALUES = void 0;
exports.INITIAL_AGGREGATE_VALUES = {
    count: 0,
    mean: 0,
    m2: 0,
    stddev: 0,
    min: Number.MAX_VALUE,
    max: 0,
};
function updateAggregateValues(stats, value) {
    if (value === null || value === undefined) {
        return stats;
    }
    let { count, mean, m2, stddev, min, max } = stats;
    if (value < min) {
        min = value;
    }
    if (value > max) {
        max = value;
    }
    count += 1;
    const delta = value - mean;
    mean += delta / count;
    const delta2 = value - mean;
    m2 += delta * delta2;
    if (count < 2) {
        stddev = 0;
    }
    else {
        stddev = Math.sqrt(m2 / (count - 1));
    }
    return {
        count,
        mean,
        m2,
        stddev,
        min,
        max,
    };
}
exports.updateAggregateValues = updateAggregateValues;
exports.INITIAL_MEAN_VALUES = {
    count: 0,
    mean: 0,
};
function updateMeanValues(stats, value) {
    if (value === null || value === undefined) {
        return stats;
    }
    let { count, mean } = stats;
    count += 1;
    const delta = value - mean;
    mean += delta / count;
    return {
        count,
        mean,
    };
}
exports.updateMeanValues = updateMeanValues;
function bytesOverTimeToKbps(bytes, elapsedMs) {
    return (bytes * 8) / (elapsedMs / 1000) / 1000;
}
exports.bytesOverTimeToKbps = bytesOverTimeToKbps;
function bytesToKbits(bytes) {
    return (bytes * 8) / 1000;
}
exports.bytesToKbits = bytesToKbits;
function kbitsToBytes(kbps) {
    return (kbps * 1000) / 8;
}
exports.kbitsToBytes = kbitsToBytes;
function secondsAtBitrateToBytes(seconds, kbps) {
    return (seconds * kbps * 1000) / 8;
}
exports.secondsAtBitrateToBytes = secondsAtBitrateToBytes;
function bytesAtBitrateToSeconds(bytes, kbps) {
    return (bytes * 8) / ((kbps > 0 ? kbps : 1) * 1000);
}
exports.bytesAtBitrateToSeconds = bytesAtBitrateToSeconds;
