import * as Sentry from '@sentry/browser';
import axios from './axios';
import * as ROUTES from '../constants/routes';
import { theFirebase } from '../services/Firebase/firebase';
import { strogging } from '@shd/jslib/infra';

const signOut = async (redirect = true, isHosted = false) => {
  try {
    await axios.post('/api/signout', {
      auth_user_id: theFirebase().auth.currentUser?.uid,
    });

    strogging.log('Initiating sign out');
    await theFirebase().doSignOut();

    strogging.log('Sign out successful');
    window.zE && window.zE('messenger', 'logoutUser');

    if (redirect) {
      if (isHosted) {
        window.location.href = ROUTES.PHONE_SIGN_IN;
      } else {
        window.location.href = ROUTES.SIGN_IN;
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    strogging.error('Sign out failed', error);
  }
};

export default signOut;
