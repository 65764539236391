import React, { useState, useCallback, useRef } from 'react';
import _ from 'lodash';

import { Search } from 'grommet-icons';
import { Box, Select, Text, TextInput } from 'grommet';
import Skeleton from 'react-loading-skeleton';
import { SyncLoader } from 'react-spinners';
import { dtos, shd } from '@shd/jslib/models';
import axios from '../../helpers/axios';
import UserCard from './SearchDisplay/UserCard';
import { errorUtil } from '@shd/jslib/infra';
import ActionConfirmationModal from './SearchDisplay/ActionConfirmationModal';

const SEARCH_OPTIONS = [
  { value: 'user', label: 'User' },
  { value: 'player', label: 'Player' },
  { value: 'team', label: 'Team' },
];

const useDebouncedCallback = (
  callback: (arg: string) => void,
  delay: number,
  opts: _.DebounceSettings
) => {
  const callbackRef = useRef<(arg: string) => void>();
  callbackRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    _.debounce(
      (text) => {
        if (callbackRef.current) {
          callbackRef.current(text);
        }
      },
      delay,
      opts
    ),
    []
  );
};
interface Props {
  setUser: (user: shd.SQLUser) => void;
}

const UserSearch: React.FC<Props> = (props) => {
  const { setUser } = props;
  const [searchType, setSearchType] = useState(SEARCH_OPTIONS[0].value);
  const [searchValue, setSearchValue] = useState('');
  const [debouncing, setDebouncing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<shd.SQLUser[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentAction, setCurrentAction] = useState<{
    action: () => void;
    text: string;
    actionName: string;
  } | null>(null);

  const search = useDebouncedCallback(
    (text) => {
      setDebouncing(false);
      if (text) {
        setLoading(true);
        axios
          .get<dtos.UserTextSearchResponse>('/api/user_text_search', {
            params: { search: text, searchType },
          })
          .then((response) => {
            setUsers([...response.data]);
            setError(null);
            setLoading(false);
          })
          .catch((e) => {
            setError(e.toString());
            setLoading(false);
          });
      } else {
        setUsers([]);
        setLoading(false);
      }
    },
    800,
    { leading: false, trailing: true }
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncing(true);
    const { value: newValue } = event.target;
    search(newValue);
    setSearchValue(newValue);
  };

  const getClaimLink = (user: shd.SQLUser) => {
    return async () => {
      try {
        const response = await axios.post(
          `/api/admin/user/${user.id}/claim_link`
        );
        const claimLink = response.data.claimLink;
        if (claimLink) {
          setCurrentAction((prevAction) => {
            if (!prevAction) {
              return null;
            }
            return {
              action: prevAction.action,
              text: `Claim link: ${claimLink}`,
              actionName: prevAction.actionName,
            };
          });

          window.alert(claimLink);
        }
      } catch (e) {
        setError(errorUtil.errorMessage(e) || '');

        setCurrentAction((prevAction) => {
          if (!prevAction) {
            return null;
          }
          return {
            action: prevAction.action,
            text: `${prevAction.text}\n\nError: ${errorUtil.errorMessage(e)}`,
            actionName: prevAction.actionName,
          };
        });
      }
    };
  };

  const cards =
    loading || debouncing ? (
      <Box gap="medium" fill>
        {loading && <Text size="large">Searching...</Text>}
        {debouncing && <SyncLoader color="#ebebeb" size={20} />}
        <Skeleton height={160} count={12} />
      </Box>
    ) : (
      users.map((user, i) => (
        <UserCard
          key={user.id}
          user={user}
          i={i}
          setCurrentAction={setCurrentAction}
          getClaimLink={getClaimLink}
          setShowConfirmation={setShowConfirmation}
          setUser={setUser}
        />
      ))
    );

  return (
    <Box gap="medium">
      <Box width="large" pad="small" round="small" gap="large">
        {showConfirmation && currentAction && (
          <ActionConfirmationModal
            action={currentAction.action}
            onClose={() => setShowConfirmation(false)}
            confirmationText={currentAction.text}
            actionName={currentAction.actionName}
          />
        )}
        {error ? <Text>{error}</Text> : null}
        <Box style={{ marginBottom: '1rem' }}>
          <Text size="large" weight={'bolder'}>
            General Search (SQL)
          </Text>
        </Box>
        <Box>
          <Select
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            options={SEARCH_OPTIONS}
            value={searchType}
            onChange={(option) => {
              setSearchType(option.value);
              setUsers([]);
              setSearchValue('');
            }}
          />
        </Box>
        <Box direction="row" align="center" gap="medium">
          <Search color="brand" />
          <TextInput
            type="search"
            onChange={onChange}
            value={searchValue}
            placeholder={`search by ${searchType} name`}
          />
        </Box>
      </Box>
      {cards}
    </Box>
  );
};

export default UserSearch;
