// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { AddCircle } from 'grommet-icons';

import ReactPlayer from 'react-player';
import IconCheck from './IconCheck';

import {
  ClipCard,
  CardBody,
  CardFooter,
  CardTitle,
  CardAction,
} from '../styles';

export default function ClipItem({
  clip,
  selectedItems,
  handleSelect,
  existingClipIds,
}) {
  const existsInPlaylist = existingClipIds.includes(clip.id);

  const renderIcon = () => {
    let Icon;
    if (selectedItems.map((c) => c.id).includes(clip.id)) {
      Icon = IconCheck;
    } else if (existsInPlaylist) {
      return null;
    } else {
      Icon = AddCircle;
    }

    return <Icon weight="22px" height="22px" color="tertiary-1" />;
  };

  return (
    <ClipCard>
      <CardBody>
        <ReactPlayer
          key={clip.src}
          width="100%"
          height="100%"
          url={clip.src}
          playsinline
          controls
          playIcon
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </CardBody>
      <CardFooter disabled={existsInPlaylist}>
        <CardTitle>{clip.previewTitle}</CardTitle>
        <CardAction
          onClick={() => {
            if (!existsInPlaylist) {
              handleSelect(clip);
            }
          }}
        >
          {renderIcon()}
        </CardAction>
      </CardFooter>
    </ClipCard>
  );
}
