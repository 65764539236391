// Packages
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import React, { useState } from 'react';
import styles from '../Admin.module.css';

// Utils and helpers
import { AugmentedUser } from '../../../services/Session/store';
import { useRelabelClips } from '../admin.hooks';
import { withAuthorization } from '../../../services/Session';
import withLayout from '../../../helpers/withLayout';

// Components
import { Box } from 'grommet';
import AdminHeader from '../components/AdminHeader';
import AdminToolsRelabelClips from '../components/AdminToolsRelabelClips';
import RelabelClipsStepOne from './views/RelabelClipsStepOne';
import RelabelClipsStepThree from './views/RelabelClipsStepThree';
import RelabelClipsStepTwo from './views/RelabelClipsStepTwo';

// Types
import { ClipProps, RELABEL_CLIPS_HEADER_TEXT } from '../admin.types';

const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

const RelabelClips: React.FC = observer(() => {
  // Hooks
  const history = useHistory();
  const { relabelClips } = useRelabelClips();

  const [clip, setClip] = useState<ClipProps>(null);
  const [step, setStep] = useState<number>(1);
  const [oldLabel, setOldLabel] = useState<string>('');
  const [newLabel, setNewLabel] = useState<string>('');
  const [showAdminTools, setShowAdminTools] = useState<boolean>(false);

  // Show and hide admin tools for testing.
  useHotkeys('ctrl+d+k', () => {
    setShowAdminTools(!showAdminTools);
  });

  const handleClipSelection = (selectedClip: ClipProps) => {
    setClip(selectedClip);
    selectedClip?.textDescriptionBrief &&
      setOldLabel(selectedClip.textDescriptionBrief);
  };

  const handleRelabelClip = async (selectedLabel: string) => {
    setNewLabel(selectedLabel);

    if (oldLabel !== selectedLabel && clip?.refPreview) {
      const relabelSuccess = await relabelClips(clip.refPreview, selectedLabel);
      if (relabelSuccess) {
        setStep(3);
      }
    }
  };

  return (
    <Box
      className={styles['admin-tools']}
      id="adminToolsRelabelClips"
      pad="medium"
    >
      {showAdminTools && (
        <AdminToolsRelabelClips
          clip={clip}
          oldLabel={oldLabel}
          newLabel={newLabel}
          onSetStep={(num: number) => setStep(num)}
        />
      )}
      <AdminHeader
        body={RELABEL_CLIPS_HEADER_TEXT[step].body}
        id="relabelClipsHeader"
        title={RELABEL_CLIPS_HEADER_TEXT[step].title}
        toolName="Relabel Clips"
      />

      {step === 1 && (
        <RelabelClipsStepOne
          onLinkClicked={(url) => history.push(url)}
          onProgressStep={(selectedClip: ClipProps) => {
            handleClipSelection(selectedClip);
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <RelabelClipsStepTwo
          clip={clip}
          oldLabel={oldLabel}
          onBack={() => {
            setStep(1);
          }}
          onRelabelClip={(selectedLabel: string) => {
            handleRelabelClip(selectedLabel);
          }}
        />
      )}
      {step === 3 && (
        <RelabelClipsStepThree
          clip={clip}
          newLabel={newLabel}
          oldLabel={oldLabel}
          onLinkClicked={(url) => history.push(url)}
        />
      )}
    </Box>
  );
});

export default withAuthorization(condition)(withLayout(RelabelClips));
