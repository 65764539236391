import React, { useEffect, useState, useCallback } from 'react';
import { Box, Accordion, AccordionPanel } from 'grommet';
import usePlayByPlay from './usePlayByPlay';
import { formatHalfInningNum } from '../../../helpers/utils';
import { PlayByPlayProps, InningDisplay } from './playByPlay.types';
import { analytics } from '../../../services/analytics';
import {
  PrimaryPlayer,
  SecondaryPlayers,
  PanelHeader,
} from './playByPlay.components';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../../components/Paywall/paywall.types';
import { usePaywallTracking } from '../../../components/Paywall/paywall.hooks';

const PlayByPlay: React.FC<PlayByPlayProps> = ({
  gameId,
  players,
  handleNavigation,
  setShowVideo,
  setActiveClip,
  setSelectedPlayerId,
  setShowUnclaimedModal,
  setShowLockModal,
  handlePaywallOpen,
  viewerEntitlementStatus,
  trackEvent,
  clips,
}) => {
  const { trackPaywallGameViewEvent } = usePaywallTracking(
    PaywallContext.GAME_PAGE
  );

  const { formatInningDisplay } = usePlayByPlay();
  const [innings, setInnings] = useState<InningDisplay>({});
  const [activeIndex, setActiveIndex] = useState([0]);

  const handleActiveClip = useCallback(
    (clipId: string, teamPlayerId: string) => {
      if (
        viewerEntitlementStatus ===
        ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN
      ) {
        handlePaywallOpen();
        return;
      }

      if (
        viewerEntitlementStatus ===
        ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT
      ) {
        setShowLockModal(true);
        return;
      }
      const findInning = Object.entries(clips).find(([, clip]) => {
        const clipsByInning = clip.find((c) => c.id === clipId);
        if (clipsByInning) {
          return clipsByInning;
        }
      });

      const activeClip =
        findInning && findInning[1].find((clip) => clip.id === clipId);

      if (activeClip) {
        setActiveClip(activeClip);
        setShowVideo(true);
        analytics.track('Game Page Clip Clicked', {
          ...trackEvent,
          clipId: clipId,
        });
      }
      trackPaywallGameViewEvent(gameId, teamPlayerId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUnclaimedPlayer = useCallback((playerId: string) => {
    setSelectedPlayerId(playerId);
    setShowUnclaimedModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clips && players) {
      const result = formatInningDisplay(clips, players);
      setInnings(result);
    }
  }, [clips, players, formatInningDisplay]);

  return (
    <Accordion
      activeIndex={activeIndex}
      onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
      margin={{ bottom: '3rem' }}
    >
      {Object.entries(innings).map(([inning, plays]) => (
        <AccordionPanel
          header={
            <PanelHeader
              title={formatHalfInningNum(inning)}
              active={true}
              inningHasDkClip={plays.some((play) => play.hasDkData)}
            />
          }
          key={`inning-${inning}`}
        >
          {plays.map((play, x) => (
            <Box
              key={`row-${x + 1}`}
              pad="medium"
              gap="small"
              background="white"
              border={{
                color: 'light-2',
                size: 'medium',
                side: 'bottom',
              }}
            >
              <PrimaryPlayer
                outcome={play.outcome}
                taggedPrimaryPlayer={play.taggedPrimaryPlayer}
                handleNavigation={handleNavigation}
                isViewable={play.taggedPrimaryPlayer.isViewable}
                handleActiveClip={handleActiveClip}
                handleUnclaimedPlayer={handleUnclaimedPlayer}
                isDkData={play.hasDkData}
                viewerEntitlementStatus={viewerEntitlementStatus}
              />
              {play.taggedPlayers.length > 1 && (
                <SecondaryPlayers
                  taggedPlayers={play.taggedPlayers.slice(1)}
                  playerRole={play.playerRole}
                  handleNavigation={handleNavigation}
                />
              )}
            </Box>
          ))}
        </AccordionPanel>
      ))}
    </Accordion>
  );
};

export default PlayByPlay;
