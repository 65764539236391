// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { string } from 'yup';
import {
  FieldStyled,
  FieldUniqueLabel,
  FieldUniqueWrapper,
  FieldWrapper,
  InputStyled,
} from './style';

import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';

const validationScheme = string()
  .required('Field is required')
  .min(5, 'Please enter a name 5 characters or more.')
  .max(20, 'Please enter a name 20 characters or fewer.')
  .matches(/^[a-zA-Z0-9-_]+$/, {
    message: 'Please remove special characters.',
    excludeEmptyString: true,
  });

const FieldUniqueLink = ({ value }) => {
  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    validationScheme
      .validate(value.nameHandle)
      .then(() => {
        setError(null);
        setIsValid(true);
      })
      .catch((reason) => {
        setIsValid(false);
        if (!isTouched) {
          return;
        }

        setError(reason.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">
            Create a unique link for your team:
          </FieldLabel>
          <FieldTip color="dark-4">* indicates required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled
          name="nameHandle"
          onChange={() => setIsTouched(true)}
          error={error}
        >
          <FieldUniqueWrapper>
            <FieldUniqueLabel>sidelineHD.com/</FieldUniqueLabel>
            <InputStyled
              plain
              autoComplete="nope"
              name="nameHandle"
              placeholder="uniquelink*"
            />
          </FieldUniqueWrapper>
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton disabled={!isValid} type="submit" label="Submit" />
    </>
  );
};

export default FieldUniqueLink;
