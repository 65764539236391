import React, { useCallback, useEffect, useState } from 'react';
import { Box, Text } from 'grommet';
import { FormNextLink, StatusGoodSmall } from 'grommet-icons';
import { useHistory } from 'react-router-dom';
import {
  useLiveGameInfo,
  useLiveOnPlatform,
} from '../../SidelineLive/liveHooks';
import { DateTime } from 'luxon';
import {
  liveIndicator,
  teamText,
  liveCard,
  sidelineLive,
} from './liveCard.style';
import {
  LiveEventPayload,
  LiveEvents,
} from '../../SidelineLive/sidelineLive.analytics';
import { pollIntervals } from '../../SidelineLive/sidelineLive.types';
interface LiveCardProps {
  teamId: string;
  teamName: string;
  trackEvent: (event: LiveEvents, payload: LiveEventPayload) => void;
}

const LiveCard: React.FC<LiveCardProps> = ({
  teamId,
  teamName,
  trackEvent,
}) => {
  const [teamNameDisplay, setTeamNameDisplay] = useState<string>(teamName);
  const [gameDateDisplay, setGameDateDisplay] = useState<string>(`00:00:00`);
  const history = useHistory();
  const gameInfo = useLiveGameInfo(teamId, pollIntervals.THIRTY_SECONDS);
  const liveOnPlatform = useLiveOnPlatform(teamId);

  const handleOnClick = useCallback(() => {
    trackEvent(LiveEvents.WATCH_LIVE, {});
    history.push(`/live/${teamId}`);
  }, [teamId, history, trackEvent]);

  useEffect(() => {
    if (gameInfo?.scoreTsStart) {
      const diff = DateTime.now().diff(
        DateTime.fromSeconds(Math.floor(gameInfo.scoreTsStart))
      );
      let d = diff.toFormat('hh:mm:ss');
      if (d.includes('00:')) {
        d = d.slice(3);
      }
      setGameDateDisplay(d);
    }
  }, [gameInfo?.scoreTsStart]);

  useEffect(() => {
    if (gameInfo?.scoreOpponentName) {
      setTeamNameDisplay(`vs. ${gameInfo.scoreOpponentName}`);
    }
  }, [gameInfo?.scoreOpponentName]);

  return (
    <>
      {liveOnPlatform?.isAdvertise && gameInfo?.scoreOurName ? (
        <Box
          fill="horizontal"
          direction="column"
          width="100%"
          alignContent="center"
          justify="center"
          align="center"
          style={sidelineLive}
          margin={{ vertical: 'small' }}
        >
          <Box margin={{ bottom: '.5rem' }}>
            <Text weight="bolder">Watch Live Game</Text>
          </Box>
          <Box round="small" style={liveCard} direction="row">
            <Box width="25%" direction="row" alignContent="center">
              <StatusGoodSmall color="#E44E2D" style={liveIndicator} />
              <Text color="white">{gameDateDisplay}</Text>
            </Box>
            <Box width="50%" align="center">
              <Text style={teamText} color="white">
                {teamNameDisplay}
              </Text>
            </Box>
            <Box width="25%" align="end">
              <FormNextLink color="white" onClick={handleOnClick} />
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default LiveCard;
