// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { Box, Heading, Layer, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { ScoringContext } from './context';
import DragAndDropLineup from './DragAndDropLineup';
import MakeSubstitution from './MakeSubstitution';
import PlayerActionMenu from './PlayerActionMenu';

const EditLineup = observer(
  ({
    onClose = () => {},
    setIndexLocal = () => {},
    isFromMainScreen = false,
  }) => {
    const scoringStore = useContext(ScoringContext);
    const {
      lineups,
      awayHome,
      lineupQueueIndexes,
      handleEvent = () => {},
    } = scoringStore;
    const lineup = lineups[awayHome];
    const currentBatterId = lineup[lineupQueueIndexes[awayHome]]
      ? lineup[lineupQueueIndexes[awayHome]]._id
      : '';
    const [showAddPlayer, setShowAddPlayer] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState();

    const setLineup = (newLineup) => {
      let newIndex;
      newLineup.forEach((player, i) => {
        if (player._id === currentBatterId) {
          newIndex = i;
        }
      });
      handleEvent('setLineup', { lineup: newLineup, index: newIndex });
      setIndexLocal(newIndex);
      onClose();
    };

    const Container = (props) => {
      if (isFromMainScreen) {
        return (
          <Box
            height={`${window.innerHeight}px`}
            pad="medium"
            gap="medium"
            style={{ overflow: 'auto' }}
          >
            {props.children}
          </Box>
        );
      }
      return (
        <Layer>
          <Box
            height={`${window.innerHeight}px`}
            pad="medium"
            gap="medium"
            style={{ overflow: 'auto' }}
          >
            <Box fill="horizontal" align="end" pad="large">
              <Text color="tertiary-1" weight="bold" onClick={onClose}>
                Back
              </Text>
            </Box>
            {props.children}
          </Box>
        </Layer>
      );
    };

    if (showAddPlayer) {
      return <MakeSubstitution closeSubs={() => setShowAddPlayer(false)} />;
    }

    if (selectedPlayer) {
      return (
        <PlayerActionMenu
          player={selectedPlayer}
          onClose={() => setSelectedPlayer()}
          currentBatterId={currentBatterId}
          setIndexLocal={setIndexLocal}
        />
      );
    }

    return (
      <Container>
        <Box pad="medium" height={{ min: 'initial' }}>
          <Heading level="3">Edit lineup</Heading>
          <DragAndDropLineup
            lineup={lineup}
            setLineup={setLineup}
            currentBatterId={currentBatterId}
            onSelectPlayer={setSelectedPlayer}
          />
        </Box>
        <Box align="start">
          <Box
            hoverIndicator
            onClick={setShowAddPlayer}
            direction="row"
            align="center"
            gap="small"
            fill={false}
            pad="medium"
          >
            <Add color="tertiary-1" />
            <Text color="tertiary-1">Add hitter</Text>
          </Box>
        </Box>
      </Container>
    );
  }
);

export default EditLineup;
