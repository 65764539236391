import styled from 'styled-components';

export const MetricList = styled.ul`
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  color: #b4b4b4;
`;

export const ListItem = styled.li`
  list-style: none;
  display: inline;
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:after {
    content: '\\00b7 ';
    margin: 0 0.25em;
  }

  &:last-child:after {
    content: none;
  }
`;

export const SocialHandle = styled.div`
  vertical-align: middle;
  font-family: 'Messina Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  color: white;

  &:before {
    content: url(/dk_insta.png);
    display: inline-block;
    height: 14px;
    width: 14px;
    padding-right: 0.25rem;
    vertical-align: -15%;
  }
`;

export const titleStyle = {
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: `1.25rem`,
};

export const iconStyle = {
  height: '3.5rem',
  width: '3.5rem',
  border: `2px solid rgba(255, 255, 255, 0.10)`,
};

export const backgroundImage = {
  color: '#fff',
  background: `linear-gradient(0deg, rgba(26,26,26,1) 85%, rgba(0,0,0,0) 95%), url('/dk_app_icon_bg.png') no-repeat`,
  backgroundSize: '100%',
};

export const bbBackgroundImage = {
  color: '#fff',
  backgroundImage: `linear-gradient(0deg, rgba(26,26,26,1) 20%, rgba(0,0,0,0) 95%), url('/dk_bb_background3x.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
};

export const sbBackgroundImage = {
  color: '#fff',
  backgroundImage: `linear-gradient(0deg, rgba(26,26,26,1) 20%, rgba(0,0,0,0) 95%), url('/dk_sb_background3x.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
};

export const buttonStyle = {
  color: '#000',
  background: '#fff',
  height: '1.75rem',
  width: '4.625rem',
  borderRadius: '1.5rem',
  fontSize: '0.875rem',
  padding: '0.25rem 1.25rem',
};

export const coachText = {
  fontFamily: 'Messina Sans',
  fontStyle: 'italic',
  fontWeight: 'bolder',
  lineHeight: '1.5rem',
};

export const coachFrame = {
  width: '3.8125rem',
  height: '6.22338rem',
  margin: '0.5rem',
};

export const overlayCard = {
  display: 'flex',
  justifyContent: 'space-between',
  bottom: 16,
  left: 0,
  right: 0,
  zIndex: 10,
  margin: '0 1rem',
  backgroundColor: '#363636cc',
  padding: '1.5rem',
};
