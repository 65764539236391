// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext } from 'react';
import {
  Text,
  Box,
  CheckBox,
  ResponsiveContext,
  Grommet,
} from 'grommet';

const Game = (props) => {
  const {
    game, selectedTeam, onSelectTeam, displayOnly,
  } = props;
  const screenSize = useContext(ResponsiveContext);
  return (
    <Grommet theme={{
      checkBox: {
        color: '#066d99',
      },
    }}
    >
      {(!displayOnly || screenSize === 'small') ? (
        <Box direction="column" gap="small" align="center">
          <Box direction="row" gap="small">
            <Box basis="1/3" direction="row" gap="small">
              { !displayOnly && (
              <CheckBox
                checked={game.teamHScoreTeamId === selectedTeam}
                onChange={(e) => {
                  if (e) {
                    onSelectTeam(game.teamHScoreTeamId);
                  }
                }}
              />
              )}
              <Text textAlign="start" onClick={() => onSelectTeam(game.teamHScoreTeamId)} weight={game.teamHScoreTeamId === selectedTeam && 'bold'}>{game.teamHName}</Text>
            </Box>
            <Box basis="1/3" align="center">
              <Text textAlign="center">{` (${game.teamHScore}) - (${game.teamAScore}) `}</Text>
            </Box>
            <Box basis="1/3" direction="row" gap="small">
              <Text textAlign="end" onClick={() => onSelectTeam(game.teamAScoreTeamId)} weight={game.teamAScoreTeamId === selectedTeam && 'bold'}>{game.teamAName}</Text>
              { !displayOnly && (
              <CheckBox
                checked={game.teamAScoreTeamId === selectedTeam}
                onChange={(e) => {
                  if (e) {
                    onSelectTeam(game.teamAScoreTeamId);
                  }
                }}
              />
              )}
            </Box>
          </Box>
          <Box direction="row" gap="small">
            { game.scoreboardTs_elapsed !== 'never' && <Text>{`${game.inningText} ${game.balls}b ${game.strikes}s ${game.outs}o`}</Text> }
            { game.scoreboardTs_elapsed !== 'never' ? <Text>{`${game.scoreboardTs_elapsed} ago`}</Text> : <Text>Not started</Text> }
          </Box>
        </Box>

      ) : (
        <Box direction="row" gap="small">
          <Text onClick={() => onSelectTeam(game.teamHScoreTeamId)} weight={game.teamHScoreTeamId === selectedTeam && 'bold'}>{game.teamHName}</Text>
          <Text>{` (${game.teamHScore}) - (${game.teamAScore}) `}</Text>
          <Text onClick={() => onSelectTeam(game.teamAScoreTeamId)} weight={game.teamAScoreTeamId === selectedTeam && 'bold'}>{game.teamAName}</Text>
          { game.scoreboardTs_elapsed !== 'never' && <Text>{`${game.inningText} ${game.balls}b ${game.strikes}s ${game.outs}o`}</Text> }
          { game.scoreboardTs_elapsed !== 'never' ? <Text>{`${game.scoreboardTs_elapsed} ago`}</Text> : <Text>Not started</Text> }
        </Box>
      )}
    </Grommet>
  );
};

export default Game;
