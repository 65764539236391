import React, { useState } from 'react';
import styles from '../Admin.module.css';

// Types
import { shd } from '@shd/jslib/models';
import { AdminSecondaryIdModalProps } from '../admin.types';

// Components
import { Box, Heading, Layer } from 'grommet';
import AdminPlayerSearch from './AdminPlayerSearch';
import AdminStepButtons from './AdminStepButtons';

const AdminSecondaryIdModal: React.FC<AdminSecondaryIdModalProps> = ({
  onAddSecondaryPlayer,
  onCloseModal,
}) => {
  const [cardIsSelected, setCardIsSelected] = useState<boolean>(false);
  const [selectedPlayer, setSelectedPlayer] =
    useState<shd.ClaimedPlayer | null>(null);

  const handleNextClick = () => {
    if (cardIsSelected && selectedPlayer) {
      onAddSecondaryPlayer(selectedPlayer);
    }
  };

  return (
    <Layer
      className={styles['admin-modal-secondary-id']}
      onEsc={() => onCloseModal()}
      onClickOutside={() => onCloseModal()}
    >
      <Heading level={2} className={styles['modal-title']}>
        Add a secondary ID
      </Heading>
      <Box className={styles['modal-content']}>
        <AdminPlayerSearch
          id="mergePlayersSearchSecondary"
          label="Search for player ID"
          placeholder="Search for player ID"
          isCardSelected={(isSelected) => {
            setCardIsSelected(isSelected);
          }}
          onCardSelected={(user) => {
            setSelectedPlayer(user);
          }}
        />
      </Box>
      <Box className={styles['modal-buttons']}>
        <AdminStepButtons
          isFixed={false}
          backLabel="Cancel"
          isNextActive={cardIsSelected}
          nextLabel="Add"
          onBack={() => {
            onCloseModal();
          }}
          onNext={() => {
            handleNextClick();
          }}
        />
      </Box>
    </Layer>
  );
};

export default AdminSecondaryIdModal;
