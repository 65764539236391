import React from 'react';
import { Box } from 'grommet';
import { GameCardProps, ScheduleState } from './games.types';

import GameInfo from './GameInfo';
import GameStreams from './GameStreams';
import GameCardHeader from './GameCardHeader';
import { borderBottom } from './games.style';

const GameCard: React.FC<GameCardProps> = ({
  game,
  team,
  isAdmin,
  handleDeleteGame,
}) => {
  const isUpcomingGame = game.scheduleState === ScheduleState.SCHEDULED;

  return (
    <Box border={borderBottom}>
      <GameCardHeader
        startTime={game.J_tsStart}
        isUpcomingGame={isUpcomingGame}
        teamId={team._id}
        teamGameId={game._id}
        handleDeleteGame={handleDeleteGame}
        isAdmin={isAdmin}
      />
      <GameInfo game={game} team={team} isUpcomingGame={isUpcomingGame} />
      {!isUpcomingGame && (
        <GameStreams
          teamId={team._id}
          gameId={game._id}
          fb={game.fb}
          yt={game.yt}
          recap={true}
        />
      )}
    </Box>
  );
};

export default GameCard;
