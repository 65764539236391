import React, { useContext } from 'react';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import InvitedUser from './InvitedUser';
import SignInV2 from './SignInV2';

const InvitedUserContainer: React.FC = () => {
  const userStore = useContext(UserContext);
  const isPhoneNumberAuth = useIsFeatureEnabled('phone_number_auth', userStore);

  return isPhoneNumberAuth ? <SignInV2 /> : <InvitedUser />;
};

export default InvitedUserContainer;
