// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  Card,
  Layer,
  Heading,
  TextInput,
  CheckBox,
} from 'grommet';
import { Close } from 'grommet-icons';
import { withRouter } from 'react-router-dom';
import axios from '../../helpers/axios';
import { formatDateShort } from '../../helpers/utils';
import { UserContext } from '../../services/Session';
import ClipVideoPlayer from '../../helpers/ClipVideoPlayer';
import { withFirebase } from '../../services/Firebase';

const MakeHighlightLayer = withFirebase(
  withRouter(
    ({
      cancelledHighlightId,
      compilationId,
      player,
      clips,
      onCloseHighlightModal,
      ...props
    }) => {
      const userStore = useContext(UserContext);

      const [step, setStep] = useState(1);
      const [title, setTitle] = useState('');
      const [email, setEmail] = useState(
        userStore.authUser && userStore.authUser.claims
          ? userStore.authUser.email
          : ''
      );
      const [selectedIndices, setSelectedIndices] = useState(
        clips.map(() => false)
      );

      const [exampleHighlights, setExampleHighlights] = useState([]);

      // eslint-disable-next-line no-unused-vars
      const [survey] = useState({
        q1: '', // existing highlight link
        q2: '', // highlight type
        q3: '', // what are you going to do
      });

      const [error, setError] = useState(null);

      useEffect(() => {
        if (cancelledHighlightId) {
          axios
            .get(`/api/highlight/${cancelledHighlightId}`)
            .then((response) => {
              const respHighlight = response.data.highlight;
              setTitle(respHighlight.titleText);
              setEmail(respHighlight.requesterEmail);
              const clipIds = respHighlight.clipIdList;

              setSelectedIndices(
                clips.map((clip) => clipIds.includes(clip.id))
              );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [cancelledHighlightId]);

      useEffect(() => {
        window.scrollTo(0, 0);
      }, [exampleHighlights]);

      const validate = () => {
        if (step === 1) {
          const clipIdsLength = clips.filter(
            (_, i) => selectedIndices[i]
          ).length;

          if (clipIdsLength < 1) {
            setError('You must select at least 1 clip!');
            return false;
          }

          if (email === '') {
            setError('Please enter your email');
            return false;
          }

          if (title === '') {
            setError('Please enter a title!');
            return false;
          }
        }

        setError(null);
        return true;
      };

      const getExampleHighlightReels = () => {
        axios.get('/api/highlights').then((response) => {
          setExampleHighlights(response.data.highlights);
        });
      };

      const onContinue = () => {
        if (!validate()) {
          return;
        }

        if (step < 2) {
          props.firebase.logAnalyticsEvent('highlight_selected_clips', {
            compilation_id: compilationId,
          });
          getExampleHighlightReels();
          setStep(step + 1);
        } else {
          props.firebase.logAnalyticsEvent('highlight_start_checkout', {
            compilation_id: compilationId,
          });
          const clipIds = clips
            .filter((_, i) => selectedIndices[i])
            .map((clip) => clip.id);
          axios
            .post('/api/highlight', {
              player,
              compilationId,
              survey,
              title,
              email,
              clipIds,
            })
            .then(() => {
              onCloseHighlightModal();
            })
            .catch(() => {
              setError('An unkown error occurred');
            });
        }
      };

      const emailInput = (
        <>
          <Text>{`Who should we email ${player.nameFirst}'s highlight reel to?`}</Text>
          <Box margin={{ bottom: 'large' }} width="large">
            <TextInput
              placeholder="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Box>
        </>
      );

      const stepOne = (
        <>
          <Heading margin={{ top: 'none' }} level={3}>
            {`Make ${player.nameFirst}'s highlight reel`}
          </Heading>
          <Text>Title your highlight reel</Text>
          <Box margin={{ bottom: 'large' }} width="large">
            <TextInput
              placeholder={`ex: ${player.nameFirst} ${player.nameLast}'s dingers...`}
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </Box>
          {!(userStore.authUser && userStore.authUser.claims) && emailInput}
          <Text>Select some highlights</Text>
          <Box>
            {clips.map((clip, i) => {
              const clipTitle =
                clip.playerRole === 'defense_pitcher'
                  ? `Pitched ${clip.textDescriptionBrief}`
                  : `${clip.textDescriptionBrief
                      .charAt(0)
                      .toUpperCase()}${clip.textDescriptionBrief.slice(1)}`;

              return (
                <Card
                  key={i} // i isnt a great key but I dont know a better one
                  pad="medium"
                  width="100%"
                  margin={{ top: 'medium' }}
                  gap="xsmall"
                  background={
                    selectedIndices[i] ? 'secondary-1' : 'secondary-7'
                  }
                  round="small"
                >
                  <CheckBox
                    checked={selectedIndices[i]}
                    onChange={(event) => {
                      if (
                        event.target.checked &&
                        clips.filter((_, x) => selectedIndices[x]).length > 4
                      ) {
                        setError('Please select 5 or fewer clips!');
                      } else {
                        setSelectedIndices(
                          selectedIndices.map((el, j) => {
                            if (i === j) {
                              return event.target.checked;
                            }
                            return el;
                          })
                        );
                      }
                    }}
                    label={
                      <Box direction="row" justify="between" gap="medium">
                        <Box gap="xsmall">
                          <Text weight="bold">{`${clipTitle}`}</Text>
                          <Text weight="bold">{`vs ${clip.game.scoreOpponentName}`}</Text>
                          <Text size="small">
                            {formatDateShort(clip.clipStartTs)}
                          </Text>
                        </Box>
                      </Box>
                    }
                  />
                </Card>
              );
            })}
          </Box>
          <Box margin={{ top: 'medium' }}>
            <Button
              size="large"
              primary
              color="tertiary-1"
              label={
                <Text weight="bold">
                  {step === 1 ? 'Continue' : 'Send it!'}
                </Text>
              }
              onClick={onContinue}
            />
          </Box>
        </>
      );

      const stepTwo = (
        <Box gap="medium">
          <Heading margin={{ top: 'none' }} level={3}>
            {`Get ${player.nameFirst}'s custom highlight reel!`}
          </Heading>
          <Box margin={{ bottom: 'medium' }}>
            <Text>
              We take the clips you selected and create a custom highlight reel.
            </Text>
          </Box>

          <Button
            size="large"
            primary
            color="tertiary-1"
            label={
              <Text weight="bold">{`Buy ${player.nameFirst}'s for $4.99`}</Text>
            }
            onClick={() => {
              const clipIds = clips
                .filter((_, i) => selectedIndices[i])
                .map((clip) => clip.id);
              axios
                .post('/api/highlight_checkout', {
                  existingHighlightId: cancelledHighlightId,
                  player,
                  compilationId,
                  survey,
                  title,
                  email,
                  clipIds,
                })
                .then((response) => {
                  window.location.href = response.data.url;
                });
            }}
          />
          <Text size="small">{`Your highlight reel will be sent to ${email}`}</Text>

          <Box gap="small" pad={{ top: 'large', bottom: 'medium' }}>
            <Text>See highlight reels that others have made!</Text>
            {exampleHighlights.map((highlight) => (
              <Card
                key={highlight._id}
                style={{ display: 'inline-block' }}
                round="small"
              >
                <Box height="200px">
                  <ClipVideoPlayer
                    id={highlight._id}
                    videoSrc={highlight.videoUrl}
                    isHighlightReel
                  />
                </Box>
                <Box pad="small">
                  <Text weight="bold" size="medium">
                    {highlight.titleText}
                  </Text>
                </Box>
              </Card>
            ))}
          </Box>
        </Box>
      );

      return (
        <Layer
          id="outer-layer"
          modal
          full="vertical"
          style={{ overflow: 'auto' }}
        >
          <Box
            fill="horizontal"
            flex={false}
            pad="large"
            direction="row"
            justify="between"
          >
            {step === 2 ? (
              <Button
                plain
                label="Back"
                onClick={() => {
                  setStep(1);
                }}
              />
            ) : (
              <Box />
            )}
            <Button
              icon={<Close size="medium" />}
              hoverIndicator
              onClick={onCloseHighlightModal}
            />
          </Box>
          <Box
            fill="horizontal"
            overflow="auto"
            flex={false}
            pad={{ left: 'large', right: 'large' }}
            gap="medium"
            margin={{ bottom: '120px' }}
          >
            {step === 1 && stepOne}
            {step === 2 && stepTwo}
            {error && <Text color="status-critical">{error}</Text>}
          </Box>
        </Layer>
      );
    }
  )
);

export default MakeHighlightLayer;
