// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import {
  Box,
  Card,
  CardBody,
  Heading,
  Layer,
  ResponsiveContext,
} from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { Close } from 'grommet-icons';
import * as Sentry from '@sentry/browser';

import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import GuidedLiveTest from './GuidedLiveTest';
import { calculateTimeLeft } from '../../helpers/utils';
import { analytics } from '../../services/analytics';

const LiveTest = ({
  onClose = () => {},
  onEndTest = () => {},
  onStartTest = () => {},
  onExtendTest = () => {},
  ...props
}) => {
  const screen = useContext(ResponsiveContext);
  const userStore = useContext(UserContext);
  const [camera, setCamera] = useState();
  const [endTs, setEndTs] = useState(props.endTs);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTs));
  const [loading, toggleLoading] = useState(false);

  const getCameraPointedAtTeam = (cs) => {
    let pointed;
    cs.some((capture) => {
      if (capture.targetTeamId === userStore.selectedTeam._id) {
        pointed = capture;
        return true;
      }
      return false;
    });

    return pointed;
  };

  const getCameras = async () => {
    try {
      const res = await axios.get(
        `/api/team/${userStore.selectedTeam._id}/capture`
      );
      return res.data.captureList;
    } catch (e) {
      Sentry.captureException(e);
      return [];
    }
  };

  const setup = async () => {
    const cameras = await getCameras();
    const c = getCameraPointedAtTeam(cameras);
    setCamera(c);
  };

  useEffect(() => {
    if (!props.endTs) {
      toggleLoading(true);
      axios
        .post(`/api/live_test/${userStore.selectedTeam._id}`)
        .then(({ data: liveTestModeExpirationTs }) => {
          onStartTest(liveTestModeExpirationTs);
          setEndTs(liveTestModeExpirationTs);
          setTimeLeft(calculateTimeLeft(liveTestModeExpirationTs));
          toggleLoading(false);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          toggleLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!camera) {
      setup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera]);

  useEffect(() => {
    if (endTs) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTs));
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  });

  const endTest = () => {
    analytics.track('Live Test Ended', {
      teamId: userStore.selectedTeam._id,
      sport: userStore.selectedTeam.attrib_sportType
        .toLowerCase()
        .replace(' ', '_'),
    });

    axios
      .delete(`/api/live_test/${userStore.selectedTeam._id}`)
      .then(() => {
        setEndTs(0);
        onEndTest();
        onClose();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        onClose();
      });
  };

  const extendTest = () => {
    axios
      .put(`/api/live_test/${userStore.selectedTeam._id}`, {
        secToExtend: 5 * 60,
      })
      .then(({ data: liveTestModeExpirationTs }) => {
        onExtendTest(liveTestModeExpirationTs);
        setEndTs(liveTestModeExpirationTs);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  };

  const statusCard = () => {
    if (!loading) {
      return (
        <Card background="light-2" fill>
          {Object.keys(timeLeft).length && endTs ? (
            <CardBody pad="medium" gap="medium">
              <Heading level={6} margin="none">
                {`LIVE test ends in ${timeLeft.minutes}:${
                  timeLeft.seconds >= 10
                    ? timeLeft.seconds
                    : `0${timeLeft.seconds}`
                }`}
              </Heading>
              <Box align="center" gap="small" direction="row">
                <SHDButton
                  label="End test"
                  primary
                  color="status-critical"
                  onClick={endTest}
                />
                <SHDButton label="Add 5 min" primary onClick={extendTest} />
              </Box>
            </CardBody>
          ) : (
            <CardBody
              pad="medium"
              direction="row"
              justify="between"
              align="center"
              gap="medium"
            >
              <Heading level={6}>Your LIVE test has ended.</Heading>
              <Box justify="end" align="center" gap="small" direction="row">
                <SHDButton label="Extend test" primary onClick={extendTest} />
              </Box>
            </CardBody>
          )}
        </Card>
      );
    }
    return null;
  };

  return (
    <Layer margin={screen === 'small' ? 'none' : 'large'}>
      <Box justify="between" margin="medium" direction="row">
        {statusCard()}
        <SHDButton
          mixtrack={['LiveTest Close']}
          icon={<Close size="medium" />}
          hoverIndicator
          onClick={onClose}
        />
      </Box>
      <Box fill="vertical" overflow="scroll">
        <GuidedLiveTest camera={camera} endTest={endTest} onClose={onClose} />
      </Box>
    </Layer>
  );
};

export default LiveTest;
