// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import {
  Accordion,
  Box,
  Text,
  Button,
  Card,
  AccordionPanel,
  Layer,
  Anchor,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Heading,
  Tabs,
  Tab,
} from 'grommet';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Close, FormDown, FormUp, LinkPrevious } from 'grommet-icons';
// import CopyToClipboard from 'react-copy-to-clipboard';

import GenericPlayerClip from '../Player/GenericPlayerClip';
import {
  formatDateShort,
  formatInningNum,
  getPrivacyName,
} from '../../helpers/utils';
import { withFirebase } from '../../services/Firebase';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';
import BoxScore from './BoxScore';
import Recap from './Recap';
import ShareBar from './ShareBar';
import Referrals from '../Settings/Referrals';
import PlayerPreview from '../Player/PlayerPreview';
import { BasicLoader } from '../../helpers/Loaders';
import Toast from '../../components/Toast';
import useGame from './useGame';
import HlsPlayer from '../../components/HLS/HlsPlayer';
import PrivacyAwareYTLink from '../../components/PrivacyAwareYTLink';
import { analytics } from '../../services/analytics';

const Game = ({ history, match, ...props }) => {
  const {
    size,
    gameData,
    players,
    setActiveClip,
    setShowVideo,
    gameId,
    userStore,
    activeIndex,
    accordionRef,
    setActiveIndex,
    activeClip,
    closeVideo,
    clips,
    setClips,
    setToast,
    activeTabIndex,
    setActiveTabIndex,
    boxScoreData,
    availableTabs,
    recapData,
    toast,
    isTeamAdmin,
    showVideo,
    showPlayerPreview,
    highlightPlayerId,
    setShowPlayerPreview,
    hlsResponse,
  } = useGame(history, match, 'generic', props.gameData);

  const renderPanelHeader = (title, active) => (
    <Box
      id="123"
      key={title}
      background="secondary-7"
      direction="row"
      align="center"
      pad="medium"
      gap="small"
      justify="between"
    >
      <strong>
        <Text>{title}</Text>
      </strong>
      <Text color="brand">{active ? <FormUp /> : <FormDown />}</Text>
    </Box>
  );

  const renderScoringByPeriod = (data) => {
    const {
      J_opponentName,
      scoreOurName,
      scoreWeAreHome,
      homeScore,
      awayScore,
      teamHPeriodScores,
      teamAPeriodScores,
    } = data;

    let homeTeamName;
    let awayTeamName;

    if (scoreWeAreHome) {
      homeTeamName = scoreOurName;
      awayTeamName = J_opponentName;
    } else {
      homeTeamName = J_opponentName;
      awayTeamName = scoreOurName;
    }

    let headers;
    if (teamHPeriodScores.length > teamAPeriodScores.length) {
      headers = ['']
        .concat(teamHPeriodScores.map((_, inning) => inning + 1))
        .concat(['T']);
    } else {
      headers = ['']
        .concat(teamAPeriodScores.map((_, inning) => inning + 1))
        .concat(['T']);
    }

    const homeRow = [homeTeamName]
      .concat(teamHPeriodScores.map((runs) => runs))
      .concat([homeScore]);
    const awayRow = [awayTeamName]
      .concat(teamAPeriodScores.map((runs) => runs))
      .concat([awayScore]);

    const scoresTable = (
      <Table>
        <TableHeader>
          <TableRow>
            {headers.map((period) => (
              <TableCell
                border="bottom"
                key={`period-header-${period}`}
                scope="col"
              >
                <Text size="small" weight="bold">
                  {period}
                </Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow key="away-row">
            {awayRow.map((x, i) => {
              const isBold = i === awayRow.length - 1 && i !== 0;
              const color = i === 0 || i === awayRow.length - 1 ? '' : 'dark-4';
              let label;
              if (size === 'small' && i === 0 && !scoreWeAreHome) {
                label = (
                  <Anchor href={`/${gameData.DNOR_teamId}`}>
                    <Text
                      size="small"
                      weight={isBold ? 'bold' : 'normal'}
                      color={color}
                    >
                      {x}
                    </Text>
                  </Anchor>
                );
              } else {
                label = (
                  <Text
                    size="small"
                    weight={isBold ? 'bold' : 'normal'}
                    color={color}
                  >
                    {x}
                  </Text>
                );
              }
              return (
                <TableCell border={false} key={`runs-${i + 1}`}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow key="home-row">
            {homeRow.map((x, i) => {
              const isBold = i === homeRow.length - 1 && i !== 0;
              const color = i === 0 || i === homeRow.length - 1 ? '' : 'dark-4';
              let label;
              if (size === 'small' && i === 0 && scoreWeAreHome) {
                label = (
                  <Anchor href={`/${gameData.DNOR_teamId}`}>
                    <Text
                      size="small"
                      weight={isBold ? 'bold' : 'normal'}
                      color={color}
                    >
                      {x}
                    </Text>
                  </Anchor>
                );
              } else {
                label = (
                  <Text
                    size="small"
                    weight={isBold ? 'bold' : 'normal'}
                    color={color}
                  >
                    {x}
                  </Text>
                );
              }
              return (
                <TableCell border={false} key={`runs-${i + 1}`}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    );

    return <Box margin={{ top: 'medium' }}>{scoresTable}</Box>;
  };

  const renderSummary = (clipsByPeriod) => {
    const periodKeys = Array.from(Array(15).keys()).map((i) => i + 1);

    const periodDivs = [];

    let maxPeriod = 1;
    if (clipsByPeriod) {
      maxPeriod =
        Math.max(
          ...Object.keys(clipsByPeriod)
            .map((periodStr) => parseInt(periodStr))
            .sort()
        ) + 1;
    }

    periodKeys.forEach((period, i) => {
      if (period < maxPeriod) {
        const periodClips = clipsByPeriod[period.toFixed(1)] || [];
        const sortedClips = periodClips.sort(
          (a, b) => a.clipStartTs - b.clipStartTs
        );

        const clipsDivs = sortedClips.map((clip) => {
          const taggedPlayers = clip.J_teamPlayerList.filter(
            (pId) => pId !== ''
          ).map((pId) => players[pId]);

          const outcome = clip.textDescriptionBrief;

          let primaryPlayer;
          if (taggedPlayers.length > 0 && taggedPlayers[0]) {
            const playerName =
              taggedPlayers.length > 0
                ? getPrivacyName(
                    taggedPlayers[0].nameFirst,
                    taggedPlayers[0].nameLast,
                    taggedPlayers[0].embedNameFirstLevel,
                    taggedPlayers[0].embedNameLastLevel
                  )
                : '';
            const clipText = `#${
              taggedPlayers.length > 0
                ? taggedPlayers[0].jerseyNum
                : clip.playerNumText
            } ${playerName}`;
            primaryPlayer = (
              <Box direction="row" gap="small">
                <Button
                  plain
                  color="secondary-2"
                  label={clipText}
                  onClick={() => {
                    history.push(
                      `/${taggedPlayers[0]._id}?sport=${gameData.sportType}&gameIds=${clip.J_teamGameId}`
                    );
                  }}
                />
                {!clip.isMapped && <BasicLoader size={20} />}
              </Box>
            );
          }

          // TODO: not sure this will generalize to all sports
          let secondaryText = '';
          let secondaryPlayers = [];
          if (taggedPlayers.slice(1).filter((x) => x).length > 0) {
            secondaryPlayers = taggedPlayers.slice(1).map((player, j) => {
              if (!(player._id in clip.attributions)) {
                return null;
              }
              secondaryText = clip.attributions[player._id][0].descriptionTxt;
              let playerName = 'Name';
              let jerseyNum = 0;
              if (player) {
                playerName = getPrivacyName(
                  player.nameFirst,
                  player.nameLast,
                  player.embedNameFirstLevel,
                  player.embedNameLastLevel
                );
                jerseyNum = player.jerseyNum;
              }

              return (
                <Button
                  key={period}
                  plain
                  margin={{ right: 'xsmall' }}
                  color="secondary-2"
                  label={
                    <Text size="medium">
                      {`#${jerseyNum} ${playerName}${
                        j < taggedPlayers.length - 2 ? ',' : ''
                      }`}
                    </Text>
                  }
                  onClick={() => {
                    history.push(
                      `/${player._id}?sport=${gameData.sportType}&gameIds=${clip.J_teamGameId}`
                    );
                  }}
                />
              );
            });
          }

          const viewButton = (
            <Button
              plain
              color="secondary-2"
              label="View"
              onClick={() => {
                setActiveClip(clip);
                setShowVideo(true);
                analytics.track('Game Page Clip Clicked', {
                  gameId,
                  clipId: clip.id,
                  loggedIn: !!userStore.authUser,
                  sport: gameData.sportType,
                });
              }}
            />
          );

          return (
            <Box
              key={period}
              pad="medium"
              gap="small"
              background="white"
              border={{
                color: 'light-2',
                size: 'medium',
                side: 'bottom',
              }}
            >
              <Box direction="row" justify="between" align="baseline">
                <Box direction="row" gap="medium" align="baseline">
                  <Text key={clip.id} weight="bold">
                    {outcome.charAt(0).toUpperCase() + outcome.slice(1)}
                  </Text>
                  {primaryPlayer}
                </Box>
                {(clip.J_claimedPlayerList.length > 0 ||
                  clip.J_teamPlayerList.length === 0) &&
                  viewButton}
              </Box>
              {secondaryPlayers.length > 0 && (
                <Card elevation="none" background="light-3" pad="small">
                  <Text size="xsmall">
                    {secondaryText.charAt(0).toUpperCase() +
                      secondaryText.slice(1)}
                  </Text>
                  <Box direction="row" wrap>
                    {secondaryPlayers}
                  </Box>
                </Card>
              )}
            </Box>
          );
        });

        const formattedPeriod = formatInningNum(period);
        const noClips = (
          <Box pad="medium">
            <Text>No clips for this period.</Text>
          </Box>
        );

        periodDivs.push(
          <AccordionPanel
            key={period}
            header={renderPanelHeader(formattedPeriod, activeIndex.includes(i))}
          >
            <Box>{clipsDivs.length ? clipsDivs : noClips}</Box>
          </AccordionPanel>
        );
      }
    });

    if (periodDivs.length === 0) {
      return (
        <Box
          direction="row"
          justify="center"
          align="center"
          height={{ min: 'large' }}
          fill
        >
          <Text>No clips available for this game.</Text>
        </Box>
      );
    }

    const { J_opponentName, scoreOurName } = gameData;

    const accordion = (
      <Box gap="medium">
        <ShareBar
          title={`${scoreOurName} vs ${J_opponentName} on sidelineHD`}
          text="Check out the play-by-play highlights from this game on sidelineHD."
          path={window.location.href}
        />
        <Accordion
          ref={accordionRef}
          activeIndex={activeIndex}
          onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
        >
          {periodDivs}
        </Accordion>
      </Box>
    );

    return accordion;
  };

  const renderVideoLayer = () => {
    const taggedPlayers = activeClip.J_teamPlayerList.map(
      (pId) => players[pId]
    ).filter((x) => x);
    // const inactivePlayerIds = Object.values(players).filter(
    //   (player) => !player.isActive,
    // ).map((player) => player.claimedPlayerId);
    // const userPlayerIds = userStore.userPlayers.map((p) => p._id);
    // const isInactive = inactivePlayerIds.filter((_id) => userPlayerIds.includes(_id)).length > 0;

    return (
      <Layer onEsc={closeVideo}>
        <Box width="large">
          <Box direction="row" justify="end" fill="horizontal">
            <Button icon={<Close />} onClick={closeVideo} hoverIndicator />
          </Box>
          <GenericPlayerClip
            sport={gameData.sportType}
            key={activeClip.id}
            clip={activeClip}
            game={gameData}
            playerId={activeClip.J_claimedPlayerList[0]}
            teamPlayerName={
              taggedPlayers.length > 0
                ? getPrivacyName(
                    taggedPlayers[0].nameFirst,
                    taggedPlayers[0].nameLast,
                    taggedPlayers[0].embedNameFirstLevel,
                    taggedPlayers[0].embedNameLastLevel
                  )
                : ''
            }
            publicPublishLevel={10}
            onChange={(newClip) => {
              const clipsByPeriod = {};
              // eslint-disable-next-line no-restricted-syntax
              for (const [period, clipsInPeriod] of Object.entries(clips)) {
                const newClips = clipsInPeriod.map((clip) => {
                  if (clip.id === newClip.id) {
                    return newClip;
                  }
                  return clip;
                });
                clipsByPeriod[period] = newClips;
              }

              setClips(clipsByPeriod);
              closeVideo();
            }}
            setToast={setToast}
          />
        </Box>
      </Layer>
    );
  };

  const renderHeader = (data) => {
    const {
      J_opponentName,
      J_tsStart,
      fb,
      yt,
      scoreOurName,
      teamWinnerNAH,
      scoreWeAreHome,
      homeScore,
      awayScore,
    } = data;

    const awayTeamDiv = (
      <Box
        direction="row"
        alignSelf="center"
        align="center"
        justify="end"
        gap="medium"
        margin={{ right: 'medium' }}
        width="200px"
      >
        {scoreWeAreHome ? (
          <Text
            weight="bold"
            size="xlarge"
            alignSelf="center"
            margin={{ right: 'small' }}
          >
            {scoreWeAreHome ? J_opponentName : scoreOurName}
          </Text>
        ) : (
          <Anchor
            label={
              <Text
                weight="bold"
                size="xlarge"
                alignSelf="center"
                margin={{ right: 'small' }}
              >
                {scoreWeAreHome ? J_opponentName : scoreOurName}
              </Text>
            }
            href={`/${gameData.DNOR_teamId}`}
          />
        )}
        <Heading color={teamWinnerNAH !== 1 ? 'dark-4' : ''}>
          {awayScore}
        </Heading>
        {/* {teamWinnerNAH === 1 && <CaretLeftFill />} */}
      </Box>
    );

    const homeTeamDiv = (
      <Box
        direction="row"
        alignSelf="center"
        align="center"
        gap="medium"
        margin={{ left: 'medium' }}
        width="200px"
      >
        {/* {teamWinnerNAH === 2 && <CaretRightFill />} */}
        <Heading color={teamWinnerNAH !== 2 ? 'dark-4' : ''}>
          {homeScore}
        </Heading>
        {scoreWeAreHome ? (
          <Anchor
            label={
              <Text
                weight="bold"
                size="xlarge"
                alignSelf="center"
                margin={{ left: 'small' }}
              >
                {scoreWeAreHome ? scoreOurName : J_opponentName}
              </Text>
            }
            href={`/${gameData.DNOR_teamId}`}
          />
        ) : (
          <Text
            weight="bold"
            size="xlarge"
            alignSelf="center"
            margin={{ left: 'small' }}
            onClick={() => {
              if (scoreWeAreHome) {
                history.push(`/${gameData.DNOR_teamId}`);
              }
            }}
          >
            {scoreWeAreHome ? scoreOurName : J_opponentName}
          </Text>
        )}
      </Box>
    );

    return (
      <Box>
        <Box pad={{ top: '16px', left: '16px' }} margin={{ bottom: '-24px' }}>
          <Box
            alignSelf="start"
            round="full"
            elevation="small"
            pad="small"
            background="white"
            onClick={() => history.goBack()}
          >
            <LinkPrevious size="24px" />
          </Box>
        </Box>
        <Box direction="row" gap="medium" margin="0 auto">
          {size !== 'small' && awayTeamDiv}
          <Box pad={{ top: 'small' }}>
            <Box direction="row" gap="small">
              <Text>{formatDateShort(J_tsStart)}</Text>
              <Text weight="bold">
                {data.scoreGameState === 50 ? 'FINAL' : 'Live'}
              </Text>
            </Box>
            {data.scoreGameState > 10 && renderScoringByPeriod(data)}
          </Box>
          {size !== 'small' && homeTeamDiv}
        </Box>
        <Box margin="0 auto" pad="small">
          <Box direction="row" gap="small">
            {yt && (
              <PrivacyAwareYTLink
                teamId={gameData.DNOR_teamId}
                gameId={gameId}
                ytAsset={yt}
              >
                <Button primary color="#ff0000" label="Watch on YouTube" />
              </PrivacyAwareYTLink>
            )}
            {fb && (
              <Button
                primary
                color="#4267B2"
                label="Watch on Facebook"
                href={fb.cdnUrl}
                target="_blank"
              />
            )}
          </Box>
        </Box>
        <Tabs
          activeIndex={activeTabIndex}
          onActive={(nextIndex) => {
            setActiveTabIndex(nextIndex);
          }}
        >
          {availableTabs.map((tab) => {
            switch (tab) {
              case 'boxScore':
                return <Tab title="Box Score" />;
              case 'pbp':
                return <Tab title="Play-by-Play" />;
              case 'recap':
                return <Tab title="Recap" />;
              case 'vod':
                return <Tab title="VOD" />;
              default:
                return null;
            }
          })}
        </Tabs>
      </Box>
    );
  };

  const renderBoxScoreData = () => {
    if (boxScoreData.boxScore.length === 0) {
      return (
        <Box
          direction="row"
          justify="center"
          align="center"
          height={{ min: 'large' }}
          fill
        >
          <Text>No players were tagged in this game</Text>
        </Box>
      );
    }

    return (
      <BoxScore
        players={players}
        columns={boxScoreData.columns}
        data={boxScoreData.boxScore}
        gameData={gameData}
      />
    );
  };

  const renderMain = () => (
    <Box height="100%">
      {availableTabs[activeTabIndex] === 'recap' && (
        <Box background="light-1">
          {recapData && players && (
            <Recap players={players} data={recapData} canEdit={isTeamAdmin()} />
          )}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'boxScore' && (
        <Box background="light-1">
          {boxScoreData && players && renderBoxScoreData()}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'pbp' && (
        <Box background="light-1">
          <Box margin="small" fill="vertical">
            {/* {(clips && gameData) ? renderSummary(clips) : <BasicLoader />} */}
            {renderSummary(clips)}
          </Box>
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'vod' && (
        <Box background="light-1">
          <Box margin="small" fill="vertical">
            <HlsPlayer
              hlsInfo={{ type: 'data', data: hlsResponse.m3u8 }}
              gameId={gameId}
            />
          </Box>
        </Box>
      )}
      {isTeamAdmin() && Object.entries(clips || {}).length > 0 && (
        <Referrals cardBackground="white" showSuccesses={false} />
      )}
    </Box>
  );

  return (
    <Box fill>
      <ResponsiveGrid
        fill="horizontal"
        responsive
        rows={['auto', 'auto']}
        columns={['10%', '10%', '60%', '10%', '10%']}
        areas={{
          small: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'main', start: [0, 1], end: [4, 1] },
          ],
          medium: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [3, 1] },
            { name: 'right-bar', start: [4, 1], end: [4, 1] },
          ],
          large: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [1, 1] },
            { name: 'main', start: [2, 1], end: [2, 1] },
            { name: 'right-bar', start: [3, 1], end: [4, 1] },
          ],
        }}
      >
        <Box gridArea="header">{gameData && renderHeader(gameData)}</Box>
        <Box gridArea="left-bar" background="light-1" />
        <Box gridArea="main" background="light-1">
          {gameData && renderMain()}
        </Box>
        <Box gridArea="right-bar" background="light-1" />
      </ResponsiveGrid>
      {showVideo && renderVideoLayer()}
      {showPlayerPreview && (
        <PlayerPreview
          sport={gameData.sportType}
          playerId={highlightPlayerId}
          gameId={gameId}
          onClose={() => {
            setShowPlayerPreview(false);
          }}
        />
      )}
      {toast && (
        <Toast
          icon={toast.icon}
          full="horizontal"
          label={toast.text}
          background="tertiary-0"
          duration={3000}
          onClose={() => {
            setToast(null);
          }}
        />
      )}
    </Box>
  );
};

const GenericGamePage = compose(withRouter, withFirebase)(Game);

export default GenericGamePage;
