// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { CodecLink } from '../../styles';

export default function CodecHelpButton() {
  return (
    <CodecLink
      href="https://help.sidelinehd.com/a/solutions/articles/69000839873"
      target="_blank"
    >
      Enable HEVC for better quality
    </CodecLink>
  );
}
