// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { Box } from 'grommet';
import {
  FieldLabel, FieldLabelWrapper, FieldTip, FormHeading,
} from '../style';

import SportsRadioField from '../SportsRadioField';
import OnboardingButton from '../../OnboardingButton';

const SelectSportStep = ({ disabledButton, handleSaveSport }) => (
  <Box width={{ max: '414px' }}>
    <FormHeading color="dark-1">You’re on your way to livestreaming!</FormHeading>
    <FieldLabelWrapper>
      <FieldLabel color="dark-1">Tell us about your team</FieldLabel>
      <FieldTip color="dark-4">Choose one</FieldTip>
    </FieldLabelWrapper>
    <SportsRadioField />
    <OnboardingButton
      label="Next"
      disabled={disabledButton}
      onClick={handleSaveSport}
    />
  </Box>
);

export default SelectSportStep;
