// Packages
import React from 'react';
import styled from 'styled-components';

// Components
import { Anchor, Button, Text } from 'grommet';

/**
 *   Paywall → Globals
 * ------------------------------------------------------ */

export const colors = {
  black: '#000',
  blue: '#007aff',
  darkGray: '#1a1a1a',
  lightGray: '#b9b9bb',
  lightGray2: '#b3b3b3',
  offWhite: '#f5f5f5',
  sidelineGreen: '#183710',
  softballYellow: '#ccff50',
  white: '#fff',
};

/**
 *   Inline styles
 * ------------------------------------------------------ */

export const paywallHorizontalRule: React.CSSProperties = {
  background: colors.lightGray,
  border: '0',
  height: '1px',
  width: '100%',
};

export const paywallCtaBadge: React.CSSProperties = {
  backgroundColor: colors.softballYellow,
  borderRadius: '0.5rem',
  lineHeight: '1.333',
  padding: '0.5rem 1rem',
  textTransform: 'uppercase',
};

/**
 *   Paywall → Globals
 * ------------------------------------------------------ */
export const PaywallCopyStrong = styled.strong`
  color: ${colors.darkGray};
  font-weight: bold;
`;

export const PaywallCopy = styled(Text)`
  ${PaywallCopyStrong}
`;

export const PaywallLink = styled(Anchor)`
  :hover {
    font-weight: inherit;
    opacity: 1;
  }
`;

/**
 *   Paywall Lock
 * ------------------------------------------------------ */
export const PaywallCtaButton = styled(Button)`
  background: ${colors.white};
  border-color: transparent;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;
  color: ${colors.sidelineGreen};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.333;
  text-align: center;
  :hover {
    background: ${colors.sidelineGreen};
    border-color: ${colors.white};
    color: ${colors.white};
  }
`;

/**
 *   Paywall
 * ------------------------------------------------------ */
export const PaywallButtonTerms = styled(Button)<{ isSelected: boolean }>`
  background: ${colors.offWhite};
  border-radius: 0.75rem;
  border-style: solid;
  border-width: 0.09375rem;
  border-color: ${({ isSelected }) =>
    isSelected ? colors.darkGray : 'transparent'};
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 1.25rem 0;
  position: relative;
  :hover {
    border-color: ${({ isSelected }) =>
      isSelected ? colors.darkGray : colors.lightGray2};
  }
`;

export const PaywallButtonUnlock = styled(Button)`
  background: ${colors.darkGray};
  border-radius: 0.75rem;
  border: 0.09375rem solid ${colors.black};
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  padding: 1.25rem 0;
  position: relative;
  text-align: center;
  :hover {
    background: ${colors.white};
    color: ${colors.darkGray};
  }
`;

export const PaywallLegalLink = styled(Anchor)`
  color: ${colors.darkGray};
  font-size: 0.75rem;
  font-weight: 400;
  text-decoration: none;
  :hover {
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: underline;
  }
`;

export const PaywallNotNow = styled(Text)`
  align-self: flex-end;
  color: ${colors.blue};
  font-size: 1.0625rem;
  font-weight: 500;
  margin-bottom: 1.3125rem;
  margin-top: 1.125rem;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
