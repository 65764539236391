// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */

import _ from 'lodash';

function EventHistoryParser(
  eventHistory,
  gameInfoImportant,
  homeLineup,
  awayLineup,
  gameEventTypes,
  promptUser,
  isQuickStartMode
) {
  const gameInfoLegacy = {
    scoreboardType: 400,
  };

  const gameInfo = { ...gameInfoImportant, ...gameInfoLegacy };
  const weAreHome = gameInfo.teamHScoreTeamId ? 1 : 0;

  this.homeLineup = homeLineup;
  this.awayLineup = awayLineup;

  this.isQuickStartMode = isQuickStartMode;
  this.activeScoreboard = {
    teamHPeriodScores: [0],
    teamAPeriodScores: [0],
    currentPeriodNum: 1,
    isCurrentPeriodStarted: false,

    teamWinnerNAH: 0,

    isGameEnd: false,

    scoreboardTeamPos: weAreHome ? 'R' : 'L',
    gameState: {},
  };

  this.activeClip = {
    _sport: gameInfo.sportType,
    _ver: 100,

    // Clip info
    clipType: 'completePlay', // at this point, only completePlay is supported;
    description: '',
    scorerRating: 0,

    // These specify the clip location
    scoreGameId: gameInfo.scoreGameId,
    startTs: 0,
    endTs: 0,

    teamHScore: 0, // the overall result
    teamAScore: 0,
    period: 1,

    // These identify the team and work in conjunction with the scoreDoc home/away fields
    awayHomeIndex: weAreHome, // we are tracking 0=away, 1=home
    teamScoredNAH: 0, // team that scored 0=unknown or not applicable, 1=away, 2=home

    userEnteredName: null,
    userEnteredDuration: null,
  };

  this.scoreboardList = [];
  this.clipEventsList = [];
  this.eventHistory = _.cloneDeep(eventHistory);

  this.undoEvent = () => {
    const lastEvent = _.last(this.eventHistory);

    this.eventHistory.splice(-1, 1);
    if (
      ['resultPromptResponse', 'wipePlateAppearance', 'tagPlayers'].includes(
        lastEvent.eventType
      )
    ) {
      // Users do not perceive these events as events
      this.eventHistory.splice(-1, 1);
    } else if (
      lastEvent.eventType === 'sub' &&
      lastEvent.eventInfo.index === -1 &&
      this.isQuickStartMode &&
      lastEvent.eventInfo.isQuickStartModePrompt &&
      !lastEvent.eventInfo.isOnDeck
    ) {
      this.undoEvent();
    } else if (lastEvent.eventType === 'forceRun') {
      this.undoEvent();
    }
  };

  this.parseEventHistory = () => {
    const promptPromise = new Promise((resolve) => {
      const lastEvent = _.last(this.eventHistory);
      if (lastEvent.eventType === 'undo' && this.eventHistory.length > 2) {
        this.eventHistory.splice(-1, 1); // remove "undo" event
        this.undoEvent();
        this.generateGameInfo();
        resolve(true);
        return;
      }
      this.generateGameInfo();

      const onPromptResponse = ({ resp, event }) => {
        if (resp) {
          const newEvent = {
            ts: lastEvent.ts,
            eventType: 'tagPlayers',
            eventInfo: event,
          };
          this.eventHistory = [...this.eventHistory, newEvent];

          this.endClip(event);
          this.clipEventsList.push({ ...this.activeClip });
          return resolve(true);
        }
        this.eventHistory.splice(-1, 1);
        return resolve(false);
      };

      // eslint-disable-next-line no-unused-vars
      const promptEventTypes = Object.entries(gameEventTypes)
        .filter(([, value]) => {
          const { attribution } = value;
          return attribution.length > 0;
        })
        .map(([key]) => key);
      if (promptEventTypes.includes(lastEvent.eventType)) {
        promptUser(lastEvent, onPromptResponse);
        return;
      }

      if (lastEvent.eventType === 'forceRun') {
        resolve(true);
      }

      resolve(true);
    })
      .then((resp) => {
        if (resp) {
          const latestScoreboard = _.last(this.scoreboardList);
          return {
            scoreboardList: this.scoreboardList,
            clipEventsList: this.clipEventsList,
            latestScoreboard,
            homeLineup: this.homeLineup,
            awayLineup: this.awayLineup,
            eventHistory: this.eventHistory,
            isQuickStartMode: this.isQuickStartMode,
          };
        }
        return { eventHistory: this.eventHistory };
      })
      .catch((parseErr) => {
        console.log('parseErr', parseErr);
      });

    return promptPromise;
  };

  this.endClip = (event) => {
    const { clipData, taggedAttribution, scoreIncrement, attribution } =
      event.eventInfo;

    this.activeClip.startTs = event.ts - clipData.secondsBefore;
    this.activeClip.endTs = event.ts + clipData.secondsAfter;
    this.activeClip.teamAScore = this.activeScoreboard.teamAPeriodScores.reduce(
      (a, b) => a + b,
      0
    );
    this.activeClip.teamHScore = this.activeScoreboard.teamHPeriodScores.reduce(
      (a, b) => a + b,
      0
    );
    this.activeClip.period = this.activeScoreboard.isCurrentPeriodStarted
      ? this.activeScoreboard.currentPeriodNum
      : this.activeScoreboard.currentPeriodNum - 1;

    if (scoreIncrement) {
      this.activeClip.teamScoredNAH = weAreHome ? 2 : 1;
    }

    this.activeClip.tagType = event.eventType;
    this.activeClip.attribution = taggedAttribution || [];

    // should i be making an exception for highlights here?
    if (
      event.eventType === 'highlight' &&
      attribution[0].descriptionTxt !== ''
    ) {
      this.activeClip.userEnteredName = attribution[0].descriptionTxt;
    }
  };

  this.generateGameInfo = () => {
    // eslint-disable-next-line no-unused-vars
    const scoringEventTypes = Object.entries(gameEventTypes)
      .filter(([, value]) => {
        const { scoreIncrement } = value;
        return scoreIncrement > 0;
      })
      .map(([key]) => key);

    this.eventHistory.forEach((event) => {
      this.activeScoreboard.scoreboardTs = event.ts;
      if (event.eventType === 'home') {
        const currentIndex = this.activeScoreboard.currentPeriodNum - 1;
        if (event.eventInfo.isPlus) {
          this.activeScoreboard.teamHPeriodScores[currentIndex] += 1;
        } else {
          const currentScore =
            this.activeScoreboard.teamHPeriodScores[currentIndex];
          if (currentScore > 0) {
            this.activeScoreboard.teamHPeriodScores[currentIndex] -= 1;
          } else {
            // subtract from previous period
            this.activeScoreboard.teamHPeriodScores[currentIndex - 1] -= 1;
          }
        }
      } else if (event.eventType === 'away') {
        const currentIndex = this.activeScoreboard.currentPeriodNum - 1;
        if (event.eventInfo.isPlus) {
          this.activeScoreboard.teamAPeriodScores[currentIndex] += 1;
        } else {
          const currentScore =
            this.activeScoreboard.teamAPeriodScores[currentIndex];
          if (currentScore > 0) {
            this.activeScoreboard.teamAPeriodScores[currentIndex] -= 1;
          } else {
            // subtract from previous period
            this.activeScoreboard.teamAPeriodScores[currentIndex - 1] -= 1;
          }
        }
      } else if (scoringEventTypes.includes(event.eventType)) {
        if (this.activeScoreboard.isCurrentPeriodStarted === false) {
          this.activeScoreboard.isCurrentPeriodStarted = true;
        }

        const currentIndex = this.activeScoreboard.currentPeriodNum - 1;
        // TODO: is there a cleaner way to do opponent stuff
        if (event.eventType.includes('_opponent')) {
          if (weAreHome) {
            this.activeScoreboard.teamAPeriodScores[currentIndex] +=
              event.eventInfo.scoreIncrement;
          } else {
            this.activeScoreboard.teamHPeriodScores[currentIndex] +=
              event.eventInfo.scoreIncrement;
          }
        } else if (weAreHome) {
          this.activeScoreboard.teamHPeriodScores[currentIndex] +=
            event.eventInfo.scoreIncrement;
        } else {
          this.activeScoreboard.teamAPeriodScores[currentIndex] +=
            event.eventInfo.scoreIncrement;
        }
      } else if (event.eventType === 'tagPlayers') {
        this.endClip(event.eventInfo);
        this.clipEventsList.push({ ...this.activeClip });
      } else if (event.eventType === 'changeGameState') {
        const gameStateType = event.eventInfo.name;
        if (event.eventInfo.enable) {
          // add game state
          this.activeScoreboard.gameState[gameStateType] = {
            teamNAH: event.eventInfo.teamNAH,
            startTs: event.ts,
          };
        } else {
          // remove game state
          delete this.activeScoreboard.gameState[gameStateType];
        }
      } else if (event.eventType === 'scoreboardPosition') {
        this.activeScoreboard.scoreboardTeamPos = event.eventInfo.position;
      } else if (event.eventType === 'endPeriod') {
        this.activeScoreboard.currentPeriodNum += 1;
        this.activeScoreboard.isCurrentPeriodStarted = false;

        this.activeScoreboard.teamHPeriodScores.push(0);
        this.activeScoreboard.teamAPeriodScores.push(0);
      } else if (event.eventType === 'resultPromptResponse') {
        const actualEvent = event.eventInfo;
        this.endClip(actualEvent);
        this.clipEventsList.push({ ...this.activeClip });
      } else if (event.eventType === 'startGameScoreboards') {
        this.activeScoreboard.isCurrentPeriodStarted = true;
      } else if (event.eventType === 'endGame') {
        this.activeScoreboard.isGameEnd = true;

        const teamAScore = this.activeScoreboard.teamAPeriodScores.reduce(
          (a, b) => a + b,
          0
        );
        const teamHScore = this.activeScoreboard.teamHPeriodScores.reduce(
          (a, b) => a + b,
          0
        );

        if (teamHScore > teamAScore) {
          this.activeScoreboard.teamWinnerNAH = 2;
        } else if (teamAScore > teamHScore) {
          this.activeScoreboard.teamWinnerNAH = 1;
        } else {
          this.activeScoreboard.teamWinnerNAH = 0;
        }
      }
      this.scoreboardList.push({
        ..._.cloneDeep(this.activeScoreboard),
        ...gameInfo,
      });
    });
  };
}

export default EventHistoryParser;
