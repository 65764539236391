// Packages
import React from 'react';
import styles from '../../Admin.module.css';

// Components
import { Heading } from 'grommet';
import AdminPlayerCard from '../../components/AdminPlayerCard';
import AdminStepButtons from '../../components/AdminStepButtons';

// Types
import { MergePlayersStepThreeProps } from '../../admin.types';

const MergePlayersStepThree: React.FC<MergePlayersStepThreeProps> = ({
  onMergeProfiles,
  onProgressStep,
  primaryPlayer,
  secondaryPlayers,
}) => {
  return (
    <>
      <Heading level={2} className={styles['admin-heading']}>
        Primary Player
      </Heading>
      <AdminPlayerCard payload={primaryPlayer} />

      <Heading
        level={2}
        className={styles['admin-heading']}
        margin={{ top: '1.5rem!important' }}
      >
        Merging With
      </Heading>
      {secondaryPlayers?.map((player, i) => (
        <AdminPlayerCard payload={player} key={i} />
      ))}

      <AdminStepButtons
        isFixed={true}
        backLabel="Back"
        isNextActive={true}
        nextLabel="Merge Profiles"
        onBack={() => {
          onProgressStep && onProgressStep(2);
        }}
        onNext={() => {
          onMergeProfiles && onMergeProfiles();
        }}
      />
    </>
  );
};

export default MergePlayersStepThree;
