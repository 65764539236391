// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';

import { Box, Heading } from 'grommet';
import GamePage from './BBSBGame';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import VBGamePage from './VBGame';
import { FullScreenSkeleton } from '../../helpers/Loaders';
import withLayout from '../../helpers/withLayout';
import GenericGamePage from './GenericGame';
import { analytics } from '../../services/analytics';

const GameSwitch = ({ history, match }) => {
  const { gameId } = match.params;
  const userStore = useContext(UserContext);
  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Team ID in path doesn't actually matter
    axios
      .get(`/api/team_game/${gameId}`)
      .then((response) => {
        // Game Page View for Mixpanel
        analytics.track('Game Page View', {
          gameId,
          loggedIn: !!userStore.authUser,
          sport: response.data.sportType,
        });
        setGameData(response.data);
      })
      .catch((respError) => {
        if (respError.response && respError.response.data) {
          setError(respError.response.data.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Box align="center" justify="center">
        <Heading level={3} textAlign="center" color="text-xweak">
          You struckout!
          <br />
          {error}
          <br />
          Make sure your link is correct.
        </Heading>
      </Box>
    );
  }

  if (!gameData) {
    return <FullScreenSkeleton />;
  }

  return (
    <>
      {gameData.sportType === 'vb' && (
        <VBGamePage gameData={gameData} history={history} match={match} />
      )}
      {(!gameData.sportType || gameData.sportType === 'bbsb') && (
        <GamePage gameData={gameData} history={history} match={match} />
      )}
      {gameData.sportType &&
        gameData.sportType !== 'vb' &&
        gameData.sportType !== 'bbsb' && (
          <GenericGamePage
            gameData={gameData}
            history={history}
            match={match}
          />
        )}
    </>
  );
};

export default withLayout(GameSwitch);
