// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { Box, Heading } from 'grommet';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { compose } from 'recompose';
import { BasicLoader } from '../helpers/Loaders';
import { withFirebase } from '../services/Firebase';
import axios from '../helpers/axios';
import TeamPage from '../features/TeamPage/TeamPage';
import { UserContext } from '../services/Session';
import PlayerPage from '../features/Player/PlayerPage';
import { useQuery } from '../helpers/utils';

const Handle = (props) => {
  const query = useQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fromInvite, setFromInvite] = useState(query.get('from_invite'));

  const userStore = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [teamId, setTeamId] = useState();
  const [claimedPlayerId, setClaimedPlayerId] = useState();
  const [teamPlayerId, setTeamPlayerId] = useState();
  const { handle, clipId = null } = useParams();
  const [activeHandle, setActiveHandle] = useState(handle);
  const [isFailed, toggleIsFailed] = useState(false);
  const location = useLocation();

  const getPlayerIdFromText = (text) => {
    const url = new URL(text, 'http://dummy.com');

    const handleWithoutQueryParams = url.pathname.replace('/', '');
    return handleWithoutQueryParams;
  };

  const getUser = useCallback(async () => {
    if (handle.includes('TpSh01-')) {
      const playerId = getPlayerIdFromText(handle);
      setTeamPlayerId(playerId);
      setClaimedPlayerId(null);
      setTeamId(null);
    } else if (handle.includes('CpSh01-')) {
      const playerId = getPlayerIdFromText(handle);
      setClaimedPlayerId(playerId);
      setTeamPlayerId(null);
      setTeamId(null);
    } else if (handle.includes('TmSh01-')) {
      const selectedTeam = userStore.userTeams.find(
        (team) => team._id === handle
      );
      userStore.setSelectedTeam(selectedTeam);
      setTeamId(handle);
      setClaimedPlayerId(null);
      setTeamPlayerId(null);
    } else {
      const teamPromise = new Promise((resolve, reject) => {
        axios.get(`/api/team_handle/${handle}`).then(resolve).catch(reject);
      });

      const claimedPlayerPromise = new Promise((resolve, reject) => {
        axios.get(`/api/player_handle/${handle}`).then(resolve).catch(reject);
      });

      Promise.all([teamPromise, claimedPlayerPromise])
        .then(([{ data: respTeamId }, { data: respClaimedPlayerId }]) => {
          if (respClaimedPlayerId) {
            setClaimedPlayerId(respClaimedPlayerId);
            setTeamId(null);
            toggleIsFailed(false);
          }

          if (respTeamId) {
            const selectedTeam = userStore.userTeams.find(
              (team) => team._id === respTeamId
            );
            userStore.setSelectedTeam(selectedTeam);
            setTeamId(respTeamId);
            setClaimedPlayerId(null);
            toggleIsFailed(false);
          }
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          toggleIsFailed(true);
          setLoading(false);
        });
    }
  }, [handle]);

  useEffect(() => {
    getUser();
  }, [activeHandle]);

  useEffect(() => {
    if (
      (props.history.action === 'POP' || props.history.action === 'PUSH') &&
      props.match.params.handle !== activeHandle
    ) {
      setActiveHandle(props.match.params.handle);
    }
  }, [location]);

  useEffect(() => {
    if (teamPlayerId || teamId || claimedPlayerId) {
      setLoading(false);
    }
  }, [teamPlayerId, teamId, claimedPlayerId]);

  return (
    <>
      {isFailed && (
        <Box align="center" justify="center">
          <Heading level={3} textAlign="center" color="text-xweak">
            You struckout!
            <br />
            Make sure your link is correct.
          </Heading>
        </Box>
      )}
      {loading && <BasicLoader fullPage />}
      {teamId && (
        <TeamPage
          key={`team-${teamId}`}
          teamId={teamId}
          fromInvite={fromInvite}
        />
      )}
      {claimedPlayerId && (
        <PlayerPage claimed playerId={claimedPlayerId} clipId={clipId} />
      )}
      {teamPlayerId && <PlayerPage playerId={teamPlayerId} clipId={clipId} />}
    </>
  );
};

export default compose(withFirebase, withRouter)(Handle);
