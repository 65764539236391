import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useParams } from 'react-router-dom';
import { withFirebase } from '../../services/Firebase';
import { FirebaseComponentProps } from '../../services/Firebase/context';
import { useUserDoc } from '../../helpers/firestoreHooks';
import { AugmentedUser } from '../../services/Session/store';
import { withAuthorization } from '../../services/Session';

const AdminUserPage: React.FC<FirebaseComponentProps> = ({ firebase }) => {
  const { userId } = useParams<{ userId: string }>();
  const userHook = useUserDoc(firebase.firestore, userId);
  const user = userHook[0];

  return (
    <Box pad="medium">
      <Box>
        <Heading level="3">{`${user?.nameFirst} ${user?.nameLast}`}</Heading>
      </Box>
      <Box gap="small">
        <Text weight={'bold'}>data</Text>
        <Box background={'light-2'}>
          <Text size="8px">{JSON.stringify(user)}</Text>
        </Box>
        <Text>
          Paste data into{' '}
          <a
            href="https://jsonviewer.stack.hu/"
            target="_blank"
            rel="noreferrer"
          >
            https://jsonviewer.stack.hu/
          </a>{' '}
          to view
        </Text>
      </Box>
    </Box>
  );
};

const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

export default withAuthorization(condition)(withFirebase(AdminUserPage));
