import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  Box,
  FormField,
  ResponsiveContext,
  Text,
  TextInput,
  Button,
} from 'grommet';
import { UserContext } from '../../services/Session';

import { useHistory } from 'react-router-dom';
import withLayout from '../../helpers/withLayout';
import { Facebook, Google, LinkPrevious } from 'grommet-icons';
import * as Sentry from '@sentry/browser';

import axios from '../../helpers/axios';
import { shd } from '@shd/jslib/models';
import PhoneNumberInputV2 from '../../helpers/PhoneNumberInputV2';
import { formatPhoneNumber } from 'react-phone-number-input';
import * as EmailValidator from 'email-validator';

import SettingsDeleteAccount from './SettingsDeleteAccount';
import useSettingsHooks from './settings.hooks';
import SettingsRevenueCat from './SettingsRevenueCat';

import { useIsFeatureEnabled } from '../../helpers/utils';

interface Props {}

const SettingsPhoneAuth: React.FC<Props> = () => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);
  const history = useHistory();

  const paywallEnabled = useIsFeatureEnabled('monetization', userStore);

  const [user, setUser] = useState<shd.User>();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [saving, setSaving] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<{
    message: string;
    error: boolean;
  }>({
    message: '',
    error: false,
  });

  const { updateEmail } = useSettingsHooks();

  const isPhoneAuthed =
    userStore.authUser?.providerData[0].providerId === 'phone';

  const onSave = useCallback(async () => {
    setSaving(true);
    if (!EmailValidator.validate(email)) {
      setEmailMessage({
        message: 'Please enter a valid email address.',
        error: true,
      });
    } else {
      if (userStore.authUser && userStore.authUser.claims) {
        const req = await updateEmail(
          email,
          userStore.authUser.claims.shd_user_id
        ).catch((e) => setError(e));
        if (req) {
          setSaving(false);
          setEmailMessage({
            message: 'Your email address has been updated.',
            error: false,
          });
        }
      }
    }
  }, [email, updateEmail, userStore]);

  useEffect(() => {
    if (userStore.authUser && userStore.authUser.claims) {
      axios
        .get(`/api/user/${userStore.authUser.claims.shd_user_id}`)
        .then((response) => {
          setLoading(false);
          const respUser = response.data;
          setUser(respUser);
          setEmail(respUser.email);
        })
        .catch((respError) => {
          setError('Cannot find user. Please sign out and try again.');
          setLoading(false);
          Sentry.captureException(respError);
        });
    } else {
      setLoading(false);
      history.push('/signin');
    }
  }, [userStore.authUser, history]);

  let phoneNumber = '';
  if (user?.phoneList?.[0]) {
    phoneNumber = user?.phoneList?.[0];
  }

  return (
    <Box
      style={{ position: 'relative' }}
      margin={{ horizontal: screenSize === 'medium' ? 'medium' : '0px' }}
      flex
    >
      <Box
        alignSelf="start"
        round="full"
        elevation="small"
        pad="small"
        background="white"
        margin={'1rem'}
        onClick={() => history.goBack()}
      >
        <LinkPrevious size="24px" />
      </Box>
      <Box pad="small" gap="small" skeleton={loading}>
        {error && <Text color="status-error">{error}</Text>}
        <Box gap="small" margin={{ vertical: 'medium' }}>
          <Text size="xlarge" weight="bold">
            {user?.nameFirst} {user?.nameLast}
          </Text>
          <Box direction="row" gap="small" align="center">
            <Text size="small">
              Signed in with{' '}
              {userStore.authUser?.providerData[0].providerId === 'phone' && (
                <Text size="small" weight="bold">
                  {formatPhoneNumber(phoneNumber)}
                </Text>
              )}
            </Text>
            {userStore.authUser?.providerData.map((provider) => {
              const icons = [];
              if (provider.providerId === 'google.com') {
                icons.push(<Google color="plain" size="12px" />);
              } else if (provider.providerId === 'facebook.com') {
                icons.push(<Facebook color="plain" size="12px" />);
              }
              return <Box key={provider.providerId}>{icons}</Box>;
            })}
          </Box>
        </Box>
        <FormField name="email" label="Email">
          <TextInput
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled={!isPhoneAuthed}
          />
          {isPhoneAuthed && emailMessage.message && (
            <Text size="small" color={emailMessage.error ? 'red' : 'green'}>
              {emailMessage.message}
            </Text>
          )}
        </FormField>
        {user?.phoneList && user?.phoneList.length > 0 && (
          <Box>
            <Text size="xsmall" weight="bold">
              Phone Number
            </Text>
            <PhoneNumberInputV2
              value={user.phoneList[0] || ''}
              onChange={() => {}}
              onPossiblePhoneNumber={() => {}}
              onError={() => {}}
              disabled
            />
            <Text size="small">
              Please contact support if you need to change your phone number
            </Text>
          </Box>
        )}
        {isPhoneAuthed && (
          <Box flex={{ grow: 1, shrink: 0 }} margin={{ top: '1rem' }}>
            <Button
              primary
              label={saving ? 'Saving...' : 'Save'}
              onClick={onSave}
            />
          </Box>
        )}

        {paywallEnabled && <SettingsRevenueCat />}
        <SettingsDeleteAccount userId={user?._id ? user._id : null} />
      </Box>
    </Box>
  );
};

export default withLayout(SettingsPhoneAuth);
