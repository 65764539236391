// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { Box, Heading, Form } from 'grommet';
import { FieldCameraType, FieldCaptureName } from '../../helpers/FormFields';
import SHDButton from '../../components/SHD/Button';
import { SMARTPHONE } from '../../constants/strings';

const CreateCapture = (props) => {
  const { onCreate, authUser } = props;
  const [value, setValue] = useState({ equipCamera: null, captureName: '' });
  const tokenizedName = authUser.displayName
    ? authUser.displayName.split(' ')
    : ['', ''];

  const userFirstNamePossessive =
    tokenizedName.length > 0 ? `${tokenizedName[0]}'s` : 'Your';

  const onSubmit = () => {
    onCreate(value.equipCamera, value.captureName);
  };

  return (
    <Box direction="column" gap="medium" width={{ max: 'large' }}>
      <Heading level={3}>Choose your livestreaming camera</Heading>
      <Form
        value={value}
        onChange={(nextValue) => {
          const newNextValue = { ...nextValue };
          if (value.equipCamera !== newNextValue.equipCamera) {
            // TODO: use enum pls
            const cameraTypeLabel =
              newNextValue.equipCamera === SMARTPHONE
                ? 'smart phone'
                : newNextValue.equipCamera;
            newNextValue.captureName = `${userFirstNamePossessive} ${cameraTypeLabel}`;
          }
          setValue(newNextValue);
        }}
        onReset={() => setValue({ equipCamera: null, captureName: '' })}
        onSubmit={onSubmit}
      >
        <FieldCameraType />
        <FieldCaptureName />
        <SHDButton
          type="submit"
          alignSelf="end"
          label="Next"
          primary
          disabled={!(value.equipCamera && value.captureName)}
        />
      </Form>
    </Box>
  );
};

export default CreateCapture;
