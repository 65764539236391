// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Form, Box } from 'grommet';

import * as Sentry from '@sentry/browser';
import FormHeader from '../FormHeader';
import axios from '../../../helpers/axios';

import {
  EXAMPLE_CLIP,
  EXAMPLE_TEXT,
  FILL_INFO, getTotalSteps, MANUAL_ENTRY_INDEX,
  MANUAL_ENTRY_NAME_AGE_LEVEL,
  MANUAL_ENTRY_NAME_AGE_U, MANUAL_ENTRY_NAME_SEASON, MANUAL_ENTRY_NAME_TYPE, SELECT_SPORT, STEPS,
} from './steps';

import SelectSportStep from './SelectSportStep';
import FillInfoStep from './FillInfoStep';
import ErrorBox from './ErrorBox';
import ManualEntryStep from './ManualEntryStep';
import { BBSB_SPORTS_KEYS, VOLLEYBALL } from '../../../constants/strings';
import ExampleTextStep from '../ExampleTextStep';
import ExampleClipStep from '../ExampleClipStep';

const OnboardingForm = ({
  validate,
  userStore,
  value,
  setValue,
  setImportSource,
  currentStepIndex,
  setCurrentStepIndex,
  setShowSearchModal,
  importError,
  error,
  setShowLALoginModal,
  setShowTSLoginModal,
  cancelTeamInfo,
}) => {
  const handleBack = () => {
    // TODO: add logic to handle going back from Example Text and Example Clip
    if (currentStepIndex === 0) {
      cancelTeamInfo();
    }

    const isTravelTeam = STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_SEASON && value.teamType === 'Travel team';
    const isSchoolTeam = STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_AGE_LEVEL && value.teamType === 'School team';
    const isNotImportable = !BBSB_SPORTS_KEYS.includes(value.attrib_sportType)
      && value.attrib_sportType !== VOLLEYBALL.key
      && STEPS[currentStepIndex].index === MANUAL_ENTRY_INDEX;

    if (isTravelTeam || isSchoolTeam || isNotImportable) {
      setCurrentStepIndex((prevState) => prevState - 2);
    } else {
      setCurrentStepIndex((prevState) => prevState - 1);
    }
  };

  const handleForward = () => {
    const isTravelTeam = STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_AGE_U && value.teamType === 'Travel team';
    const isSchoolTeam = STEPS[currentStepIndex].name === MANUAL_ENTRY_NAME_TYPE && value.teamType === 'School team';
    if (isTravelTeam || isSchoolTeam) {
      setCurrentStepIndex((prevState) => prevState + 2);
    } else {
      setCurrentStepIndex((prevState) => prevState + 1);
    }
  };

  const totalSteps = getTotalSteps();

  const handleSaveSport = () => {
    axios.post('/api/sib/contacts', {
      email: userStore.authUser.email,
      sport: value.attrib_sportType,
    }).then(() => {

    }).catch((err) => {
      Sentry.captureException(err);
    });

    axios.post('/api/onboarding/sms', {
      sport: value.attrib_sportType,
    }).then(() => {
    }).catch((err) => {
      Sentry.captureException(err);
    });

    setCurrentStepIndex((prevState) => prevState + 1);
  };

  const handleFinishExample = () => {
    setCurrentStepIndex((prevState) => prevState + 1);
  };

  return (
    <Box>
      <FormHeader
        totalSteps={totalSteps}
        currentStep={STEPS[currentStepIndex].index + 1}
        buttonAction={handleBack}
      />
      {importError && <ErrorBox error={importError} />}
      {error && <ErrorBox error={error} />}
      <Form
        value={value}
        onChange={(nextValue) => setValue(nextValue)}
        onReset={() => setValue({})}
        onSubmit={validate}
      >
        {
          STEPS[currentStepIndex].name === SELECT_SPORT
            && (
            <SelectSportStep
              disabledButton={!value.attrib_sportType}
              handleSaveSport={handleSaveSport}
            />
            )
        }
        {
          STEPS[currentStepIndex].name === EXAMPLE_TEXT
            && (
            <ExampleTextStep value={value} handleForward={handleForward} />
            )
        }
        {
          STEPS[currentStepIndex].name === EXAMPLE_CLIP
            && (
            <ExampleClipStep value={value} handleForward={handleFinishExample} />
            )
        }
        {
          STEPS[currentStepIndex].name === FILL_INFO
            && (
            <FillInfoStep
              value={value}
              setImportSource={setImportSource}
              setShowSearchModal={setShowSearchModal}
              handleForward={handleForward}
              setShowLALoginModal={setShowLALoginModal}
              setShowTSLoginModal={setShowTSLoginModal}
            />
            )
        }
        {
          STEPS[currentStepIndex].index === MANUAL_ENTRY_INDEX
            && (
            <ManualEntryStep
              handleForward={handleForward}
              currentStepIndex={currentStepIndex}
              value={value}
            />
            )
        }
      </Form>
    </Box>
  );
};

export default OnboardingForm;
