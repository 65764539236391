// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, ResponsiveContext } from 'grommet';
import React from 'react';
import Game from './Game';

const GameList = (props) => {
  const {
    gameList, selectedTeam = '', onSelectTeam = () => {}, displayOnly = false,
  } = props;
  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <Box gap={responsive === 'small' ? 'xlarge' : 'small'}>
          {
            gameList.map((game) => (
              <Game
                game={game}
                selectedTeam={selectedTeam}
                onSelectTeam={onSelectTeam}
                key={game.scoreGameId}
                displayOnly={displayOnly}
              />
            ))
          }
        </Box>
      )}
    </ResponsiveContext.Consumer>

  );
};

export default GameList;
