// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-destructuring */
/* eslint-disable no-lonely-if */
/* eslint-disable no-underscore-dangle */

import * as Sentry from '@sentry/react';

import { Box, Button, Text } from 'grommet';
import { Bookmark, Close, Like } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { getDisplayName } from 'recompose';
import { arraysEqual } from '../../helpers/utils';
import axios from '../../helpers/axios';

const Player = ({ index = 0, player, selected, onSelected }) => {
  let backgroundColor;
  if (selected) {
    backgroundColor = 'primary-1';
  } else if (index % 2 === 0) {
    backgroundColor = 'light-4';
  } else {
    backgroundColor = 'light-3';
  }
  return (
    <Box
      basis="1/4"
      pad="small"
      align="center"
      onClick={() => {
        onSelected(player);
      }}
    >
      <Box
        round="full"
        background={backgroundColor}
        align="center"
        justify="center"
        border={{ color: 'primary-1', size: 'small' }}
        style={{ aspectRatio: '1/1' }}
        width="100%"
      >
        <Text weight="bold" size="large">
          {player.jerseyNum}
        </Text>
      </Box>
      <Text size="medium" truncate>
        {getDisplayName(player.nameFirst, player.nameLast)}
      </Text>
    </Box>
  );
};

const GenericReassignClip = ({
  game,
  clip,
  teamPlayers,
  onReassign,
  onClose,
  ...props
}) => {
  const initialSelectedPlayers = {};
  Object.entries(clip.teamPlayerActionDict).forEach(([key, value]) => {
    const attributionList = value.attributionList || [];

    attributionList.forEach((x) => {
      if (initialSelectedPlayers[x]) {
        initialSelectedPlayers[x].push(teamPlayers.find((p) => p._id === key));
      } else {
        initialSelectedPlayers[x] = [teamPlayers.find((p) => p._id === key)];
      }
    });
  });

  const [selectedPlayers, setSelectedPlayers] = useState(
    initialSelectedPlayers
  );

  const [gameEventTypes, setGameEventTypes] = useState();

  let initialSelectedHighlightIndex;
  if (clip.textDescriptionBrief === 'Good Play') {
    initialSelectedHighlightIndex = 0;
  } else if (clip.textDescriptionBrief === 'Coachable Moment') {
    initialSelectedHighlightIndex = 1;
  }
  const [selectedHighlightIndex, setSelectedHighlightIndex] = useState(
    initialSelectedHighlightIndex
  );

  const highlightDescriptions = ['Good Play', 'Coachable Moment'];

  useEffect(() => {
    const sportLower = game.sportType;
    axios
      .get(`/api/scoring/game_event_types/${sportLower}`)
      .then(({ data }) => {
        setGameEventTypes(data);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPlayerSection = () => {
    const x = gameEventTypes[clip.tagType];

    return x.attribution.map((data) => {
      const { attributionType, prompt, numPlayers } = data;
      const isMulti = numPlayers > 1;

      const stepSelectedPlayers = selectedPlayers[attributionType] || [];
      const steSelectedPlayerIds = stepSelectedPlayers.map((p) => p._id);
      const initialSelectedPlayerIds = (
        initialSelectedPlayers[attributionType] || []
      ).map((p) => p._id);
      // console.log('stepSelectedPlayers', stepSelectedPlayers,
      // initialSelectedPlayers[attributionType],
      //   arraysEqual(steSelectedPlayerIds, initialSelectedPlayerIds));

      const showSavedTag =
        stepSelectedPlayers.length > 0 &&
        !arraysEqual(steSelectedPlayerIds, initialSelectedPlayerIds);

      const isHighlight = clip.tagType === 'highlight';

      const onSelectedPlayer = (option) => {
        if (isMulti) {
          let newSelectedPlayers = [...stepSelectedPlayers, option];
          if (stepSelectedPlayers.map((p) => p._id).includes(option._id)) {
            newSelectedPlayers = newSelectedPlayers.filter(
              (p) => p._id !== option._id
            );
          }
          if (newSelectedPlayers.length <= numPlayers) {
            setSelectedPlayers({
              ...selectedPlayers,
              [attributionType]: newSelectedPlayers,
            });
          }
        } else if (
          attributionType in selectedPlayers &&
          selectedPlayers[attributionType][0]._id === option._id
        ) {
          // unselecting a player
          const newSelectedPlayers = {
            ...selectedPlayers,
          };
          delete newSelectedPlayers[attributionType];
          setSelectedPlayers(newSelectedPlayers);
        } else {
          // single selecting a player
          const newSelectedPlayers = {
            ...selectedPlayers,
            [attributionType]: [option],
          };
          setSelectedPlayers(newSelectedPlayers);
        }
      };

      return (
        // eslint-disable-next-line react/jsx-key
        <Box
          pad="medium"
          gap="small"
          height={{ min: 'unset' }}
          background="white"
          round
          fill
        >
          <Box direction="row" align="center" gap="medium">
            <Text weight="bold" size="large">{`${prompt}`}</Text>
            {showSavedTag && (
              <Box
                background="#418310"
                round
                pad={{ horizontal: 'small' }}
                align="center"
                justify="center"
              >
                <Text size="xsmall" color="white" weight="bold">
                  Saved
                </Text>
              </Box>
            )}
          </Box>
          {isHighlight && (
            <Box direction="row" height={{ min: 'unset' }} gap="small">
              <Box
                direction="row"
                gap="small"
                background={
                  selectedHighlightIndex === 0 ? 'primary-1' : 'primary-8'
                }
                align="center"
                pad="medium"
                round
                border={{ color: 'primary-1', size: '2px' }}
                onClick={() => {
                  if (selectedHighlightIndex === 0) {
                    setSelectedHighlightIndex(null);
                  } else {
                    setSelectedHighlightIndex(0);
                  }
                }}
              >
                <Like />
                <Text weight="bold">Good Play</Text>
              </Box>
              <Box
                direction="row"
                gap="small"
                background={
                  selectedHighlightIndex === 1 ? 'primary-1' : 'primary-8'
                }
                align="center"
                pad="medium"
                round
                border={{ color: 'primary-1', size: '2px' }}
                onClick={() => {
                  if (selectedHighlightIndex === 1) {
                    setSelectedHighlightIndex(null);
                  } else {
                    setSelectedHighlightIndex(1);
                  }
                }}
              >
                <Bookmark />
                <Text weight="bold">Coachable Moment</Text>
              </Box>
            </Box>
          )}

          <Box gap="small" height={{ min: 'unset' }} fill>
            <Box
              id="rest-of-players-container"
              pad={{ top: 'medium' }}
              direction="row"
              align="center"
              overflow="scroll"
              wrap
              height={{ min: 'unset' }}
              fill="horizontal"
            >
              {teamPlayers.map((player, i) => (
                <Player
                  key={player._id}
                  index={i}
                  player={player}
                  selected={stepSelectedPlayers
                    .map((p) => p._id)
                    .includes(player._id)}
                  onSelected={onSelectedPlayer}
                />
              ))}
            </Box>
          </Box>
        </Box>
      );
    });
  };

  const headerDiv = (
    <Box as="header" gap="medium" flex={{ grow: 0, shrink: 0 }}>
      <Box direction="row" justify="between" fill="horizontal">
        <Text size="2xl" weight="bold">
          Tag players in clip
        </Text>
        {props.showClose && (
          <Button icon={<Close />} onClick={onClose} hoverIndicator />
        )}
      </Box>
    </Box>
  );

  return (
    <Box width="large" pad="medium" gap="medium" fill="vertical">
      {headerDiv}
      <Box gap="medium" overflow="scroll" fill="vertical">
        {gameEventTypes && renderPlayerSection()}
      </Box>

      <Box as="footer">
        <Button
          primary
          size="large"
          color="tertiary-0"
          label={<Text weight="bold">Done</Text>}
          onClick={() => {
            const newClip = { ...clip };

            // HACK: i think this is a FE only thing.
            // Look in GenericGame for clip.attributions
            // this is created in game.py for SOME REASON
            const gameEvent = gameEventTypes[clip.tagType];
            const gameEventAttributions = gameEvent.attribution;
            const gameEventAttributionDict = {};
            gameEventAttributions.forEach((x) => {
              gameEventAttributionDict[x.attributionType] = x;
            });

            const newTeamPlayerActionDict = {};
            const attributionTypeToPlayerIds = {};
            const newAttributions = {};
            Object.entries(selectedPlayers).forEach(
              ([attributionType, players]) => {
                players.forEach((player) => {
                  if (!(player._id in newTeamPlayerActionDict)) {
                    const newList = {
                      attributionList: [attributionType],
                    };
                    newTeamPlayerActionDict[player._id] = newList;
                    newAttributions[player._id] = [
                      gameEventAttributionDict[attributionType],
                    ];
                  } else {
                    newTeamPlayerActionDict[player._id].attributionList.push(
                      attributionType
                    );
                    newAttributions[player._id].push(
                      gameEventAttributionDict[attributionType]
                    );
                  }

                  if (!(attributionType in attributionTypeToPlayerIds)) {
                    attributionTypeToPlayerIds[attributionType] = [player._id];
                  } else {
                    attributionTypeToPlayerIds[attributionType].push(
                      player._id
                    );
                  }
                });
              }
            );
            newClip.teamPlayerActionDict = newTeamPlayerActionDict;
            newClip.attributions = newAttributions;

            // need to update playerScorePlayerList so we can remap the clip
            const newPlayerScorePlayerList = [];
            gameEvent.attribution.forEach((attribution) => {
              attributionTypeToPlayerIds[attribution.attributionType].forEach(
                (playerId) => {
                  newPlayerScorePlayerList.push(playerId);
                }
              );
            });
            newClip.playerScorePlayerList = newPlayerScorePlayerList;

            // HACK: guess at new J_teamPlayerList so frontend can reflect changes
            newClip.J_teamPlayerList = newPlayerScorePlayerList;

            if (
              clip.tagType === 'highlight' &&
              selectedHighlightIndex !== null
            ) {
              newClip.textDescriptionBrief =
                highlightDescriptions[selectedHighlightIndex];
            }

            // ugh
            newClip.teamId = game.DNOR_teamId;
            axios
              .put('/api/clip', { clip: newClip })
              .then((response) => {
                if (response.data.success) {
                  onReassign(response.data.clip);
                  // onClose();
                }
              })
              .catch((e) => {
                Sentry.captureException(e);
              });
          }}
        />
      </Box>
    </Box>
  );
};

export default GenericReassignClip;
