import React, { useCallback, useContext } from 'react';
import {
  Avatar,
  Box,
  BoxExtendedProps,
  Grid,
  Image,
  ResponsiveContext,
  Text,
} from 'grommet';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';
import { UserContext } from '../../services/Session';
import withLayout from '../../helpers/withLayout';
import { LinkPrevious } from 'grommet-icons';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import SettingsMenuItem from '../Settings/SettingsMenuItem';
import { compose } from 'recompose';
import {
  TEAM_SETTINGS,
  TEAM_SETTINGS__CAMERA,
  TEAM_SETTINGS__PRIVACY,
  TEAM_SETTINGS__PUBLISHING,
  TEAM_SETTINGS__RADAR,
  TEAM_SETTINGS__SPONSORS,
  TEAM_SETTINGS__STREAM_MENU,
  TEAM_SETTINGS__TEAM_INFORMATION,
  TEAM_SETTINGS__TEAM_PROFILE_MENU,
  TEAM_SETTINGS__TEAM_STAFF,
} from '../../constants/routes';
import StreamSettings from '../Settings/StreamSettings';
import TeamStaff from '../Settings/TeamStaff/TeamStaff';
import TeamProfileSettings from '../Settings/TeamProfileSettings';
import StreamPublishing from '../Settings/StreamPublishing/StreamPublishing';
import CameraSettings from '../Settings/CameraSettings';
import RadarSettings from '../Settings/RadarSettings';
import TeamInformation from '../Settings/TeamProfile/TeamInformation';
import { shd } from '@shd/jslib/models';
import TeamPrivacySettings from '../Settings/TeamPrivacySettings';
import SponsorLogosSettings from '../Settings/SponsorLogosSettings';

const streamSettingsPaths = [
  TEAM_SETTINGS__STREAM_MENU,
  TEAM_SETTINGS__PUBLISHING,
  TEAM_SETTINGS__CAMERA,
  TEAM_SETTINGS__RADAR,
];

const teamProfileSettingsPaths = [
  TEAM_SETTINGS__TEAM_PROFILE_MENU,
  TEAM_SETTINGS__TEAM_INFORMATION,
  TEAM_SETTINGS__SPONSORS,
  TEAM_SETTINGS__PRIVACY,
];

interface TeamSettingsMenuProps {
  team: shd.Team | null;
}

const TeamSettingsMenu: React.FC<TeamSettingsMenuProps> = ({ team }) => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);
  const currentPath = window.location.pathname;

  const columns = '100%';
  const gap = 'none';
  const background = screenSize === 'small' ? 'white' : 'light-5';

  return (
    <Box gap="medium" pad="medium" background={background}>
      <Box direction="row" gap="medium">
        <Avatar size="48px">
          <Image
            fit="contain"
            src={team?.image?.urlT150 || 'image_default.jpg'}
          />
        </Avatar>
        <Box gap="xsmall">
          <Text size="small" weight="bold">
            Managing Team
          </Text>
          <Text>{userStore.selectedTeam?.nameMed}</Text>
        </Box>
      </Box>
      <Grid columns={columns} gap={gap}>
        <SettingsMenuItem
          title="Stream Settings"
          subtitle="Manage camera and broadcast settings"
          href={TEAM_SETTINGS__STREAM_MENU}
          selected={streamSettingsPaths.includes(currentPath)}
        />
        <SettingsMenuItem
          title="Team Staff"
          subtitle="Add, remove, and manage team staff"
          href={TEAM_SETTINGS__TEAM_STAFF}
          selected={currentPath === TEAM_SETTINGS__TEAM_STAFF}
        />
        <SettingsMenuItem
          title="Team Profile"
          subtitle="Team info, socials, and privacy"
          href={TEAM_SETTINGS__TEAM_PROFILE_MENU}
          selected={teamProfileSettingsPaths.includes(currentPath)}
        />
      </Grid>
    </Box>
  );
};
interface MainContentProps {
  team: shd.Team | null;
  currentPath: string;
}
const MainContent: React.FC<MainContentProps> = React.memo(
  ({ team, currentPath }) => {
    const screenSize = useContext(ResponsiveContext);
    if (!team) {
      return null;
    }

    if (screenSize === 'small') {
      if (currentPath === TEAM_SETTINGS) {
        return <TeamSettingsMenu team={team} />;
      } else if (currentPath === TEAM_SETTINGS__STREAM_MENU) {
        return <StreamSettings />;
      } else if (currentPath === TEAM_SETTINGS__PUBLISHING) {
        return <StreamPublishing />;
      } else if (currentPath === TEAM_SETTINGS__CAMERA) {
        return <CameraSettings />;
      } else if (currentPath === TEAM_SETTINGS__RADAR) {
        return <RadarSettings />;
      } else if (currentPath === TEAM_SETTINGS__TEAM_STAFF) {
        return <TeamStaff />;
      } else if (currentPath === TEAM_SETTINGS__TEAM_PROFILE_MENU) {
        return <TeamProfileSettings />;
      } else if (currentPath === TEAM_SETTINGS__TEAM_INFORMATION) {
        return <TeamInformation />;
      } else if (currentPath === TEAM_SETTINGS__SPONSORS) {
        return <SponsorLogosSettings />;
      } else if (currentPath === TEAM_SETTINGS__PRIVACY) {
        return <TeamPrivacySettings />;
      }
    } else {
      if (streamSettingsPaths.includes(currentPath)) {
        return <StreamSettings />;
      } else if (teamProfileSettingsPaths.includes(currentPath)) {
        return <TeamProfileSettings />;
      } else if (currentPath === TEAM_SETTINGS__TEAM_STAFF) {
        return <TeamStaff />;
      }
    }

    return null;
  }
);

interface TeamSettingsProps extends RouteComponentProps {}

const TeamSettings: React.FC<TeamSettingsProps> = (props) => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);

  const location = useLocation();
  const currentPath = location.pathname;
  const team = userStore.selectedTeam;

  const clickSettingsBack = useCallback(() => {
    if (props.history.location.pathname === '/team_settings' && team) {
      props.history.replace(team?.nameHandle);
    } else {
      props.history.goBack();
    }
  }, [team, props.history]);

  const renderHeader = () => {
    let title = 'Team Settings';

    if (currentPath === TEAM_SETTINGS__PUBLISHING) {
      title = 'Stream Publishing';
    } else if (currentPath === TEAM_SETTINGS__CAMERA) {
      title = 'Camera Settings';
    } else if (currentPath === TEAM_SETTINGS__RADAR) {
      title = 'Radar Settings';
    } else if (streamSettingsPaths.includes(currentPath)) {
      title = 'Stream Settings';
    } else if (currentPath === TEAM_SETTINGS__TEAM_STAFF) {
      title = 'Team Staff';
    } else if (teamProfileSettingsPaths.includes(currentPath)) {
      title = 'Team Information';
    }

    let border: { side: 'bottom'; color: string } | undefined = {
      side: 'bottom',
      color: 'light-1',
    };
    let justify: BoxExtendedProps['justify'] = 'start';
    let textSize = 'xlarge';
    let gap = 'medium';
    let additionalBackButtonProps: Partial<BoxExtendedProps> = {
      round: 'full',
      elevation: 'small',
      pad: 'small',
    };

    if (screenSize === 'small') {
      border = undefined;
      justify = 'between';
      textSize = 'large';
      gap = 'none';
      additionalBackButtonProps = {};
    }

    return (
      <Box
        direction="row"
        align={'center'}
        justify={justify}
        pad={'large'}
        border={border}
        gap={gap}
        background={'light-5'}
      >
        <Box
          alignSelf="start"
          onClick={() => clickSettingsBack()}
          {...additionalBackButtonProps}
        >
          <LinkPrevious size="24px" />
        </Box>
        <Text weight="bold" size={textSize} alignSelf="center">
          {title}
        </Text>
        <Box alignSelf="end" width={'24px'} />
      </Box>
    );
  };

  const renderLeftSidebar = () => {
    if (screenSize !== 'small') {
      return <TeamSettingsMenu {...props} team={team} />;
    }
    return null;
  };

  return (
    <Box>
      <ResponsiveGrid
        fill
        rows={['auto', 'flex']}
        columns={['auto', 'flex']}
        areas={{
          small: [
            { name: 'header', start: [0, 0], end: [1, 0] },
            { name: 'main', start: [0, 1], end: [1, 1] },
          ],
          medium: [
            { name: 'header', start: [0, 0], end: [1, 0] },
            { name: 'left', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [1, 1] },
          ],
          large: [
            { name: 'header', start: [0, 0], end: [1, 0] },
            { name: 'left', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [1, 1] },
          ],
        }}
      >
        <Box
          style={{ position: 'sticky', top: 0, zIndex: 1 }}
          gridArea="header"
        >
          {renderHeader()}
        </Box>
        <Box gridArea="left" background={'#F8F8F8'}>
          {renderLeftSidebar()}
        </Box>
        <Box gridArea="main" background={'#F8F8F8'}>
          <MainContent team={team} currentPath={currentPath} />
        </Box>
      </ResponsiveGrid>
    </Box>
  );
};

const withConditionalLayout = <P extends object>(
  Component: React.FC<P>
): React.FC<P> => {
  const ConditionalLayoutComponent: React.FC<P> = (props) => {
    const screenSize = useContext(ResponsiveContext);

    if (screenSize === 'small') {
      // Don't apply layout for small screens
      return <Component {...props} />;
    } else {
      // Apply layout for non-small screens
      const LayoutComponent = withLayout(Component);
      return <LayoutComponent {...props} />;
    }
  };

  return ConditionalLayoutComponent;
};

export default compose<
  TeamSettingsProps,
  Omit<TeamSettingsProps, keyof RouteComponentProps>
>(
  withConditionalLayout,
  withRouter
)(TeamSettings);
