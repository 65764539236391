import React, { useContext } from 'react';
import {
  Box,
  Layer,
  Text,
  Button,
  CardFooter,
  ResponsiveContext,
} from 'grommet';
import { shd } from '@shd/jslib/models';
import { StatusWarning } from 'grommet-icons';
import { TeamCapture } from '@shd/jslib/models/shd';
import CaptureItem from './CaptureItem';
import { isMobile } from '../../helpers/browserDetect';
import { SIDELINE_APP } from '../../constants/strings';
import { analytics } from '../../services/analytics';
import { TEAM_SETTINGS__CAMERA } from '../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppHostContext } from '../../services/appHost';

export enum CaptureStatus {
  Ready = 'ready',
  Disabled = 'disabled',
  Live = 'live',
  TeamLive = 'team_live',
  OtherTeam = 'other_team',
}

interface GoLiveFlowProps extends RouteComponentProps {
  team: shd.Team;
  captures: TeamCapture[];
  onClose: () => void;
  onCaptureSelected: (capture: TeamCapture) => void;
}
const GoLiveFlow: React.FC<GoLiveFlowProps> = ({
  team,
  captures: initialCaptures,
  onClose,
  onCaptureSelected,
  history,
}) => {
  const screenSize = useContext(ResponsiveContext);
  const appHost = useContext(AppHostContext);

  const sidelineHDAppCapture: Partial<TeamCapture> = {
    _id: 'sidelinehd-app',
    captureName: SIDELINE_APP,
    equipCamera: 'app',
    state_isLive: false,
    targetTeamId: team._id,
    targetTeamName: team.nameMed,
    isOwnedByActiveUser: true,
  };

  const [captures, setCaptures] = React.useState(initialCaptures);

  const userCaptures = [
    sidelineHDAppCapture as TeamCapture,
    ...captures.filter((capture) => capture.isOwnedByActiveUser),
  ];

  const teamManagerCaptures = captures.filter(
    (capture) => !capture.isOwnedByActiveUser
  );

  const getStatus = (capture: TeamCapture) => {
    const isAnyTeamManagerCaptureLive = teamManagerCaptures.some(
      (c) => c.state_isLive && c.targetTeamId === team._id
    );
    if (capture.targetTeamId === 'disabled') {
      return CaptureStatus.Disabled;
    } else if (capture.targetTeamId !== team._id) {
      return CaptureStatus.OtherTeam;
    } else if (isAnyTeamManagerCaptureLive) {
      // this means the current team is live, but not from the user's capture
      return CaptureStatus.TeamLive;
    } else if (capture.state_isLive) {
      return CaptureStatus.Live;
    }
    return CaptureStatus.Ready;
  };

  return (
    <Layer
      position={screenSize !== 'large' ? 'bottom' : 'center'}
      responsive={false}
      onClickOutside={onClose}
      full="horizontal"
      background={'transparent'}
    >
      <Box
        background={'white'}
        pad="medium"
        gap="small"
        height={{ max: '624px' }}
        round={{ corner: 'top', size: '8px' }}
        width={screenSize !== 'large' ? '100%' : '480px'}
        alignSelf="center"
        flex
      >
        <Box gap="medium">
          <Text size="xlarge" weight="bold">
            Get ready to go live
          </Text>
          <Text>
            Choose a camera to stream from. You can also edit your camera’s in
            Settings.
          </Text>
          <Box gap="large" margin={{ vertical: 'medium' }} overflow={'auto'}>
            {!isMobile.any() ||
              (appHost.isHosted && (
                <Box
                  direction="row"
                  pad="medium"
                  gap="medium"
                  border={{ color: 'tertiary-1', size: '2px' }}
                  round="8px"
                >
                  <Box align="center" alignSelf="center">
                    <StatusWarning color="tertiary-1" />
                  </Box>
                  <Box gap="small">
                    <Text weight={'bold'}>You cannot go live from desktop</Text>
                    <Text>
                      You can enable and point cameras, but must go live from a
                      mobile device.
                    </Text>
                  </Box>
                </Box>
              ))}
            <Box id="your-cameras-container" gap="8px" flex={{ grow: 1 }}>
              <Box direction="row" justify="between" align="center">
                <Text size="small" weight={'bold'}>
                  Your Cameras
                </Text>
                <Button
                  secondary
                  size="small"
                  label="Edit My Cameras"
                  onClick={() => {
                    analytics.track('Edit My Cameras Clicked', {
                      teamId: team._id,
                      userCaptureCount: userCaptures.length,
                      teamManagerCaptureCount: teamManagerCaptures.length,
                    });
                    // window.location.href = '/team_settings/camera';
                    history.push(TEAM_SETTINGS__CAMERA);
                  }}
                />
              </Box>
              <Box id="your-cameras" gap="8px" flex>
                {userCaptures.map((capture) => {
                  const status = getStatus(capture);
                  return (
                    <CaptureItem
                      key={capture._id}
                      status={status}
                      capture={capture}
                      onSelected={onCaptureSelected}
                      showEdit={false}
                      onUpdated={(enabledCapture) => {
                        // we always "force point" the capture, so we disable any other captures
                        const newCaptures = captures.map((c) => {
                          if (c._id === enabledCapture._id) {
                            return enabledCapture;
                          }
                          if (c.targetTeamId === team._id) {
                            return {
                              ...c,
                              targetTeamId: 'disabled',
                            };
                          }
                          return c;
                        });
                        setCaptures(newCaptures);
                      }}
                    />
                  );
                })}
                {userCaptures.length === 1 && (
                  <Button
                    secondary
                    label="Add Advanced Camera"
                    onClick={() => {
                      analytics.track('Add Advanced Camera Clicked', {
                        teamId: team._id,
                      });
                      history.push(TEAM_SETTINGS__CAMERA);
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box id="team-manager-cameras-container" gap="8px">
              <Text size="small" weight={'bold'}>
                Team Manager Cameras
              </Text>
              <Box id="team-cameras" gap="8px" flex={{ grow: 1 }}>
                {teamManagerCaptures.map((capture) => {
                  const status = getStatus(capture);
                  return (
                    <CaptureItem
                      key={capture._id}
                      status={status}
                      capture={capture}
                      onSelected={onCaptureSelected}
                      showEdit={false}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
          <CardFooter margin={{ bottom: 'small' }}>
            <Button fill secondary label="Cancel" onClick={onClose} />
          </CardFooter>
        </Box>
      </Box>
    </Layer>
  );
};

export default withRouter(GoLiveFlow);
