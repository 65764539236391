// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-mixed-operators */

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import './clipTrimmerSlider.css';
import { TbChevronCompactLeft, TbChevronCompactRight } from 'react-icons/tb';

import { Box, Image } from 'grommet';
import ReactSlider from 'react-slider';
import { BarLoader } from 'react-spinners';
import { FormCut } from 'grommet-icons';
import { generateVideoThumbnails } from './videoThumbnailsGenerator';

const ClipTrimmer = ({ clip, onChange }) => {
  const playerRef = useRef();

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState();

  const [sliderValues, setSliderValues] = useState([0, 0, 100]);

  const [loadingThumbnails, setLoadingThumbnails] = useState(true);
  const [error, setError] = useState();
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    generateVideoThumbnails(clip.fileDownloadUrl, 4, 'url')
      .then((res) => {
        setThumbnails(res);
        setLoadingThumbnails(false);
      })
      .catch((err) => {
        setError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (duration) {
      const start = (100.0 * clip.clipStartOffset) / duration;
      const end = (100.0 * (duration - clip.clipStopOffset)) / duration;
      setSliderValues([start, start, end]);
      playerRef.current.seekTo(start / 100.0, 'fraction');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  const renderThumbnailsLoading = () => (
    <BarLoader
      cssOverride={{
        display: 'block',
        margin: '0 auto',
        top: '22px',
      }}
      loading={loadingThumbnails}
    />
  );

  const renderSliderPlaceholder = () => (
    <Box fill="horizontal" height="48px" background="gray" />
  );

  return (
    <Box>
      <ReactPlayer
        ref={playerRef}
        width="100%"
        height="inherit"
        url={clip.fileDownloadUrl}
        playing={isPlaying}
        playsinline
        controls
        onProgress={(x) => {
          if (x.played !== 0 && x.played < sliderValues[2] / 100.0) {
            const current = 100.0 * x.played;
            setSliderValues((prev) => [prev[0], current, prev[2]]);
          } else if (x.played > sliderValues[2] / 100.0) {
            setIsPlaying(false);
            playerRef.current.seekTo(sliderValues[0] / 100.0, 'fraction');
          }
        }}
        onDuration={(d) => {
          setDuration(d);
        }}
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
      />
      <Box direction={loadingThumbnails ? 'column' : 'row'} fill="horizontal">
        {!loadingThumbnails && (
          <Box
            width={{ min: '44px' }}
            height="48px"
            background="black"
            justify="center"
            align="center"
          >
            <FormCut color="white" />
          </Box>
        )}
        <Box>
          {loadingThumbnails ? (
            renderSliderPlaceholder()
          ) : (
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="slider-thumb"
              trackClassName="slider-track"
              defaultValue={sliderValues}
              value={sliderValues}
              ariaLabelledby={[
                'first-slider-label',
                'second-slider-label',
                'third-slider-label',
              ]}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              renderThumb={(props, state) => (
                <div {...props}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 'inherit',
                    }}
                  >
                    {state.index === 0 && <TbChevronCompactLeft size={22} />}
                    {state.index === 1 && <div className="current-thumb" />}
                    {state.index === 2 && <TbChevronCompactRight size={22} />}
                  </div>
                </div>
              )}
              onChange={([start, current, end], changedIndex) => {
                if (changedIndex === 0) {
                  playerRef.current.seekTo(start / 100.0, 'fraction');
                  setSliderValues([start, start, end]);
                } else if (changedIndex === 1) {
                  playerRef.current.seekTo(current / 100.0, 'fraction');
                  setSliderValues([start, current, end]);
                } else if (changedIndex === 2) {
                  playerRef.current.seekTo(end / 100.0, 'fraction');
                  setSliderValues([start, end, end]);
                }

                const startOffset = (duration / 100.0) * start;
                const endOffset = duration - (duration / 100.0) * end;
                onChange(startOffset, endOffset);
              }}
              pearling
            />
          )}
          <Box
            style={{ position: 'relative', top: '-48px' }}
            direction="row"
            width="100%"
            height="48px"
            pad={{ left: '22px', right: '22px' }}
            background="gray"
          >
            {loadingThumbnails
              ? renderThumbnailsLoading()
              : thumbnails.map((thumb, i) => (
                  <Image
                    key={`preview-thumbnail-${i + 1}`}
                    src={thumb}
                    fit="contain"
                  />
                ))}
            {error && <Box>{error.message}</Box>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClipTrimmer;
