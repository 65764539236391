import React, { useState } from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import StaffMemberItem from './StaffMemberItem';
import { StaffMember } from '../types';

interface EditStaffProps {
  selectedStaffMember: StaffMember;
  staff: StaffMember[];
  isSelf: boolean;
  handleDeleteStaffMember: () => void;
}

interface DeleteConrfirmationModalProps {
  handleDeleteStaffMember: () => void;
  setShowDeleteUser: (show: boolean) => void;
}

const DeleteConfirmationModal: React.FC<DeleteConrfirmationModalProps> = ({
  handleDeleteStaffMember,
  setShowDeleteUser,
}) => {
  return (
    <Layer
      onEsc={() => {
        setShowDeleteUser(false);
      }}
    >
      <Box pad="medium" gap="medium">
        <Text>Are you sure you want to remove this user?</Text>
        <Box direction="row" justify="between">
          <Button
            plain
            color="tertiary-1"
            label={<Text weight="bold">Cancel</Text>}
            onClick={() => setShowDeleteUser(false)}
          />
          <Button
            primary
            color="error"
            label={<Text weight="bold">Yes, remove</Text>}
            onClick={() => handleDeleteStaffMember()}
          />
        </Box>
      </Box>
    </Layer>
  );
};

const EditStaff: React.FC<EditStaffProps> = ({
  selectedStaffMember,
  staff,
  isSelf,
  handleDeleteStaffMember,
}) => {
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  return (
    <Box>
      <StaffMemberItem staffMember={selectedStaffMember} inEditModal />
      {staff.length >= 1 && !isSelf && (
        <Button
          secondary
          label="Remove Staff Member"
          onClick={() => setShowDeleteUser(true)}
        />
      )}
      {showDeleteUser && (
        <DeleteConfirmationModal
          handleDeleteStaffMember={handleDeleteStaffMember}
          setShowDeleteUser={setShowDeleteUser}
        />
      )}
    </Box>
  );
};

export default EditStaff;
