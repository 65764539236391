// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Box, Button, DropButton, Layer, Text } from 'grommet';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import CopyToClipboard from 'react-copy-to-clipboard';
import { Close, More } from 'grommet-icons';
import * as Sentry from '@sentry/react';
import CreateNewReelModal from './CreateNewReelModal';
import { usePlayerReels } from './playerHooks';
import PlaylistPlayer from '../VideoPlaylist/PlaylistPlayer';
import { formatDateShort, useIsFeatureEnabled } from '../../helpers/utils';
import { isMobile } from '../../helpers/browserDetect';
import { UserContext } from '../../services/Session';
import Toast from '../../components/Toast';
import axios from '../../helpers/axios';
import ShareDropdown from '../../helpers/ShareDropdown';
import { analytics } from '../../services/analytics';

import { useIsEntitledToViewPlayer } from './playerHooks';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../components/Paywall/paywall.types';
import PaywallCta from '../../components/Paywall/PaywallCta';
import PaywallLock from '../../components/Paywall/PaywallLock';
import { BasicLoader } from '../../helpers/Loaders';

const DeleteReelModal = ({ reelId, setShowDeleteModal }) => {
  const deleteReel = () => {
    axios.delete(`/api/reels/${reelId}`).then(() => {
      setShowDeleteModal(false);

      // TODO: should actually remove the reel from the list
      // and not refresh the whole page
      window.location.reload();
    });
  };

  return (
    <Layer
      onEsc={() => setShowDeleteModal(false)}
      onClickOutside={() => setShowDeleteModal(false)}
      width={{ min: 'large' }}
    >
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <Button
            icon={<Close />}
            onClick={() => setShowDeleteModal(false)}
            hoverIndicator
          />
        </Box>
        <Box pad="medium" margin={{ bottom: 'large' }}>
          <Box gap="medium">
            <Box gap="small">
              <Text weight="bold">
                Are you sure you want to delete this reel?
              </Text>
              <Text>
                This action only deletes the reel. The clips inside the reel
                will not be modified.
              </Text>
            </Box>
            <Button
              primary
              color="error"
              label="Delete Reel"
              onClick={deleteReel}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

const ReelPreview = ({ reel, canEdit, history }) => {
  const userStore = useContext(UserContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [copied, setCopied] = React.useState(false);

  const mapClipList = (sources) =>
    sources?.map(
      ({ fileDownloadUrl, titleMain, titleSub, clipId, ...rest }) => ({
        sources: [
          {
            src: fileDownloadUrl,
            titleMain,
            titleSub,
            id: clipId,
            type: 'video/mp4',
            ...rest,
          },
        ],
      })
    );
  const playerRef = useRef(null);

  const { clipList } = reel;
  const hasClips = clipList && clipList.length > 0;
  const playList = mapClipList(clipList);

  const link = `${process.env.REACT_APP_BASE_URL}/reels/${reel._id}?utm_source=mobile_share`;

  const handleShare = () => {
    if (navigator.share) {
      analytics.track('sidelineREEL Share Clicked', {
        playlistId: reel._id,
        loggedIn: !!userStore.authUser,
        type: 'share via',
        from: 'tab',
      });
      navigator
        .share({
          title: reel.titleMain,
          text: reel.titleMain,
          url: link,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const renderMenuItems = () => (
    <Box width="medium" margin="small">
      {canEdit && (
        <Button
          plain
          color="error"
          label="Delete"
          onClick={() => {
            analytics.track('Delete Reel Clicked', {
              reelId: reel._id,
              from: 'tab',
            });
            setShowDeleteModal(true);
            setMenuOpen(false);
          }}
        />
      )}
    </Box>
  );

  const renderShareOptions = () => (
    <Box pad="medium" gap="small">
      {isMobile.any() && <Button label="Share via ..." onClick={handleShare} />}
      <CopyToClipboard
        text={link}
        onCopy={() => {
          analytics.track('sidelineREEL Share Clicked', {
            playlistId: reel._id,
            loggedIn: !!userStore.authUser,
            type: 'copy',
            from: 'tab',
          });
          setCopied(true);
        }}
      >
        <Button label="Copy link to reel" />
      </CopyToClipboard>
    </Box>
  );
  return (
    <Box
      key={reel._id}
      pad="medium"
      justify="between"
      gap="medium"
      margin="small"
      round="small"
      elevation="small"
    >
      {hasClips && (
        // very hacky to use this component like this
        // could probably use some refactoring
        <PlaylistPlayer
          ref={playerRef}
          myKey={reel._id}
          playList={playList}
          setCurrentId={() => {}}
          shouldAutoplay={false}
        />
      )}
      <Box direction="row" gap="small" align="center" justify="between">
        <Text weight="bold" size="large">
          {reel.titleMain}
        </Text>
        <Text color="gray" size="large">
          {formatDateShort(reel.modifiedTs)}
        </Text>
      </Box>
      <Box direction="row" gap="small">
        <Button
          primary
          color="primary-1"
          label="View Details"
          onClick={() => {
            analytics.track('View Reel Details Clicked', {
              reelId: reel._id,
              from: 'tab',
            });
            history.push(`/reels/${reel._id}`);
          }}
        />
      </Box>
      <Box direction="row" justify="between">
        <DropButton
          open={menuOpen}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          dropContent={renderMenuItems()}
        >
          <More />
        </DropButton>
        <Box direction="row" align="center" gap="small">
          <ShareDropdown
            plain
            label={<Text size="small">Share</Text>}
            color="secondary-1"
            renderShareOptions={renderShareOptions}
          />
        </Box>
      </Box>
      {copied && (
        <Toast
          label="Copied link to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteReelModal
          reelId={reel._id}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
    </Box>
  );
};

const ReelsList = ({ playerId, history }) => {
  const userStore = useContext(UserContext);
  const isGodMode = useIsFeatureEnabled('view_all_clips_enabled', userStore);
  const reels = usePlayerReels(playerId);
  const [showCreateNewReelModal, setShowCreateNewReelModal] = useState(false);
  const [canEdit, setCanEdit] = useState(isGodMode);

  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);

  const { status: viewerEntitlementStatus, loading: isEntitledToViewLoading } =
    useIsEntitledToViewPlayer(
      playerId,
      userStore.authUser?.claims?.shd_user_id
    );

  useEffect(() => {
    const userPlayerIds = userStore.userPlayers
      ? userStore.userPlayers.map((player) => player._id)
      : [];
    if (reels.length) {
      const { category, J_claimedPlayerId } = reels[0];
      if (category === 'claimedPlayer') {
        setCanEdit(userPlayerIds.includes(J_claimedPlayerId) || isGodMode);
      }
    } else {
      setCanEdit(userPlayerIds.includes(playerId) || isGodMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reels]);

  const reelsEmptyState = () => (
    <Box
      pad="medium"
      margin="small"
      justify="center"
      align="center"
      round="small"
      elevation="small"
    >
      <Text size="large" weight="bold">
        No reels yet!
      </Text>
      {canEdit && (
        <Box>
          <Text size="large">Create a reel to get started.</Text>
          <Box margin="small">
            <Button
              fill
              primary
              color="primary-1"
              label="Create New Reel"
              size="large"
              onClick={() => {
                analytics.track('Create New Reel Clicked', {
                  playerId,
                  from: 'tab',
                  reelsCount: reels.length,
                });
                setShowCreateNewReelModal(true);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );

  const renderReels = () => {
    if (!reels || reels.length === 0) {
      return reelsEmptyState();
    }
    const sortedReels = [...reels].sort(
      (a, b) => new Date(b.modifiedTs) - new Date(a.modifiedTs)
    );

    const reelsDivs = sortedReels.map((reel) => (
      // eslint-disable-next-line react/jsx-key
      <ReelPreview reel={reel} canEdit={canEdit} history={history} />
    ));

    return (
      <Box>
        {canEdit && (
          <Box margin="small" pad={{ vertical: 'medium' }}>
            <Button
              fill
              primary
              color="primary-1"
              label="Create New Reel"
              size="large"
              onClick={() => {
                analytics.track('Create New Reel Clicked', {
                  playerId,
                  from: 'tab',
                  reelsCount: reels.length,
                });
                setShowCreateNewReelModal(true);
              }}
            />
          </Box>
        )}
        {reelsDivs}
      </Box>
    );
  };

  return (
    <Box overflow="auto">
      {!isMonetizationEnabled ? (
        <>
          {renderReels()}
          {showCreateNewReelModal && (
            <CreateNewReelModal
              playerId={playerId}
              setShowCreateNewReelModal={setShowCreateNewReelModal}
              history={history}
            />
          )}
        </>
      ) : (
        <>
          {isEntitledToViewLoading ? (
            <BasicLoader />
          ) : (
            <>
              {viewerEntitlementStatus ===
                ViewerEntitlementStatus.ENTITLED_BUT_PRIVATE && (
                <PaywallLock isPrivate={true} />
              )}

              {viewerEntitlementStatus ===
                ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT && (
                <PaywallLock isPrivate={false} />
              )}

              {viewerEntitlementStatus ===
                ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN && (
                <PaywallCta
                  mode="default"
                  context={PaywallContext.REELS_PAGE}
                  playerId={playerId}
                />
              )}

              {viewerEntitlementStatus === ViewerEntitlementStatus.ENTITLED && (
                <>
                  {renderReels()}
                  {showCreateNewReelModal && (
                    <CreateNewReelModal
                      playerId={playerId}
                      setShowCreateNewReelModal={setShowCreateNewReelModal}
                      history={history}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default withRouter(ReelsList);
