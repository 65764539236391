import axios from 'axios';
import { theFirebase } from '../services/Firebase/firebase';
import { strogging } from '@shd/jslib/infra';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30 * 1000,
});

instance.interceptors.request.use(async (config) => {
  let token;
  if (!config.url?.includes('send_telemetry')) {
    strogging.log('api-request', { url: config.url, method: config.method });
  }
  try {
    token = await theFirebase().auth.currentUser?.getIdToken();
  } catch (error) {
    strogging.exception('axios-interceptor:exception', error);
  }

  const configCopy = { ...config };
  configCopy.headers.Authorization = token ? `Bearer ${token}` : '';
  return configCopy;
});

export type StandardHook<T> = SWRResponse<T, Error>;

export function useGetApi<TResponse>(
  key: string | null | undefined,
  config?: SWRConfiguration<TResponse>
): StandardHook<TResponse> {
  return useSWR<TResponse, Error, string | null>(
    key ?? null,
    (k) => instance.get<TResponse>(k).then((r) => r.data),
    config
  );
}

export default instance;
