/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withLayout from '../../helpers/withLayout';
import {
  Box,
  DateInput,
  Form,
  FormExtendedEvent,
  FormField,
  Heading,
  TextInput,
  Text,
} from 'grommet';
import { AugmentedUser } from '../../services/Session/store';
import { withAuthorization } from '../../services/Session';
import { useStreamlogQueries } from '../../helpers/firestoreHooks';
import { theFirebase } from '../../services/Firebase/firebase';
import { docStorage } from '@shd/jslib/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

interface QueryParams {
  capture_id: string;
  user_id: string;
  start_time: string;
  end_time: string;
}
interface BaseProps {}

type Props = RouteComponentProps<object, object, QueryParams> & BaseProps;

// Functional Component
const StreamLog: React.FC<Props> = ({ location, history }) => {
  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  const captureId = queryParams.get('capture_id') || '';
  const userId = queryParams.get('user_id') || '';
  const startTime =
    queryParams.get('start_time') ||
    new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
  const endTime = queryParams.get('end_time') || new Date().toISOString();

  const [formValue, setFormValue] = React.useState<QueryParams>({
    capture_id: captureId,
    user_id: userId,
    start_time: startTime,
    end_time: endTime,
  });

  React.useEffect(() => {
    history.replace({
      search: `?capture_id=${formValue.capture_id}&user_id=${formValue.user_id}&start_time=${formValue.start_time}&end_time=${formValue.end_time}`,
    });
  }, [
    formValue.capture_id,
    formValue.end_time,
    formValue.start_time,
    formValue.user_id,
    history,
  ]);

  const formSubmit = React.useCallback(
    (ev: FormExtendedEvent<QueryParams>) => {},
    []
  );

  const formChange = React.useCallback((nextValue: QueryParams) => {
    setFormValue(nextValue);
  }, []);

  const startTimeStrings = React.useMemo(
    () => extractDateAndTime(formValue.start_time),
    [formValue.start_time]
  );
  const endTimeStrings = React.useMemo(
    () => extractDateAndTime(formValue.end_time),
    [formValue.end_time]
  );

  const [result, loading, error] = useStreamlogQueries(theFirebase().firestore);

  type GroupedQueries = { [category: string]: docStorage.StreamlogQueryDoc[] };

  const groupedQueries: GroupedQueries = React.useMemo(() => {
    return (result ?? []).reduce<GroupedQueries>((acc, query) => {
      const displayCategory = query.displayCategory;
      if (!acc[displayCategory]) {
        acc[displayCategory] = [query];
      } else {
        acc[displayCategory].push(query);
      }
      return acc;
    }, {});
  }, [result]);

  return (
    <Box pad="large">
      <Heading level={1}>Stream Log</Heading>
      <Heading level={2}>Parameters</Heading>
      <Form
        value={formValue}
        onSubmit={formSubmit}
        onChange={formChange}
        style={{ height: '100%', border: '1px solid black' }}
      >
        <Box
          direction="row"
          gap="medium"
          pad="small"
          fill="horizontal"
          wrap={true}
        >
          <Box
            direction="row"
            flex={{ grow: 1, shrink: 1 }}
            pad="small"
            gap="medium"
          >
            <FormField
              style={{ flex: 1 }}
              name={'capture_id'}
              htmlfor="capture-id"
              label="Capture ID"
              required
              validate={(field: string) => {
                if (!field) {
                  return 'enter a capture id';
                }
                return { message: null, status: 'info' };
              }}
            >
              <TextInput
                placeholder="Capture ID"
                name={'capture_id'}
                id="capture-id"
              />
            </FormField>
            <FormField
              style={{ flex: 1 }}
              name={'user_id'}
              htmlfor="user-id"
              label="User ID"
              required
              validate={(field: string) => {
                if (!field) {
                  return 'enter a user id';
                }
                return { message: null, status: 'info' };
              }}
            >
              <TextInput placeholder="User ID" name={'user_id'} id="user-id" />
            </FormField>
          </Box>
          <Box
            direction="row"
            flex={{ grow: 1, shrink: 1 }}
            pad="small"
            gap="medium"
          >
            <Box>
              <Text
                as="label"
                margin={{ vertical: 'xsmall' }}
                size="medium"
                weight="bold"
              >
                Start Date (UTC)
              </Text>
              <DateInput
                name="start-date"
                id="start-date"
                format="mm/dd/yyyy"
                value={startTimeStrings.dateStr}
                onChange={({ value }) => {
                  setFormValue((prev) => ({
                    ...prev,
                    start_time: insertDate(prev.start_time, value as string),
                  }));
                }}
              />
            </Box>
            <Box>
              <Text
                as="label"
                margin={{ vertical: 'xsmall' }}
                size="medium"
                weight="bold"
              >
                Start Time (UTC)
              </Text>
              <TextInput
                name="start-time"
                id="start-time"
                type="time"
                value={startTimeStrings.timeStr}
                onChange={(ev) => {
                  setFormValue((prev) => ({
                    ...prev,
                    start_time: insertTime(prev.start_time, ev.target.value),
                  }));
                }}
              />
            </Box>
          </Box>
          <Box
            direction="row"
            flex={{ grow: 1, shrink: 1 }}
            pad="small"
            gap="medium"
          >
            <Box>
              <Text
                as="label"
                margin={{ vertical: 'xsmall' }}
                size="medium"
                weight="bold"
              >
                End Date (UTC)
              </Text>
              <DateInput
                name="end-date"
                id="end-date"
                format="mm/dd/yyyy"
                value={endTimeStrings.dateStr}
                onChange={({ value }) => {
                  setFormValue((prev) => ({
                    ...prev,
                    end_time: insertDate(prev.end_time, value as string),
                  }));
                }}
              />
            </Box>
            <Box>
              <Text
                as="label"
                margin={{ vertical: 'xsmall' }}
                size="medium"
                weight="bold"
              >
                End Time (UTC)
              </Text>
              <TextInput
                name="end-time"
                id="end-time"
                type="time"
                value={endTimeStrings.timeStr}
                onChange={(ev) => {
                  setFormValue((prev) => ({
                    ...prev,
                    end_time: insertTime(prev.end_time, ev.target.value),
                  }));
                }}
              />
            </Box>
          </Box>
        </Box>
      </Form>
      <Heading level={2}>Links</Heading>
      <Box
        direction="row"
        gap="medium"
        pad="small"
        fill="horizontal"
        wrap={true}
      >
        {Object.keys(groupedQueries).map((cat) => (
          <Box key={cat} flex={{ grow: 1, shrink: 1 }} pad="small" gap="medium">
            <Heading level={3} margin={{ vertical: 'xxsmall' }}>
              {cat}
            </Heading>
            {groupedQueries[cat].map((query) => (
              <Box key={query.displayName} pad={{ left: 'large' }}>
                <a
                  href={renderQueryUrl(query.uriTemplate, formValue)}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  <Heading
                    level={4}
                    margin={{ horizontal: 'large', vertical: 'xxsmall' }}
                  >
                    {query.displayName}
                  </Heading>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </a>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

export default compose<Props, Omit<Props, keyof RouteComponentProps>>(
  withAuthorization(condition),
  withRouter,
  withLayout
)(StreamLog);

function extractDateAndTime(isoString: string) {
  const dateStr = isoString.slice(0, 10);
  const timeStr = isoString.slice(11, -1).slice(0, 12);
  return { dateStr, timeStr };
}

function insertDate(isoString: string, dateStr: string): string {
  return `${dateStr}T${isoString.slice(11, -1)}Z`;
}

function insertTime(isoString: string, timeStr: string): string {
  return `${isoString.slice(0, 11)}${timeStr}Z`;
}

function renderQueryUrl(uriTemplate: string, queryParams: QueryParams) {
  return uriTemplate
    .replaceAll('{{capture_id}}', queryParams.capture_id)
    .replaceAll('{{user_id}}', queryParams.user_id)
    .replaceAll('{{start_time}}', queryParams.start_time)
    .replaceAll('{{end_time}}', queryParams.end_time);
}
