// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from 'grommet';

import SHDButton from '../../../../components/SHD/Button';

const PromptFooter = ({
  selectedPhone,
  showPhoneInput,
  teamPlayer,
  setShowPhoneInput,
  sendState,
  enteredPhone,
  sendMsg,
  isConfirmSelectedPhone,
  setIsConfirmSelectedPhone,
}) => {
  const isShowNotListedButton = !selectedPhone
      && !showPhoneInput
      && teamPlayer.invitePhoneNumberList.length;

  const isDisabledSendButton = (!selectedPhone && !enteredPhone) || sendState === 10;

  const isShowSendMessageButton = selectedPhone && isConfirmSelectedPhone;

  const history = useHistory();

  const handleSendMessage = () => {
    sendMsg();
    history.replace({ search: '' });
  };

  const handleConfirmSelected = () => {
    setIsConfirmSelectedPhone(true);
  };

  const onSendVerification = selectedPhone && !enteredPhone ? handleConfirmSelected : sendMsg;

  if (isShowNotListedButton) {
    return (
      <Box gap="medium">
        <Button
          primary
          color="tertiary-1"
          label="My phone number is not listed"
          style={{ height: '48px' }}
          onClick={() => setShowPhoneInput(true)}
        />
      </Box>
    );
  }

  if (isShowSendMessageButton) {
    return (
      <Box gap="medium">
        <Button
          primary
          color="tertiary-1"
          label="Send message"
          style={{ height: '48px' }}
          onClick={handleSendMessage}
        />
      </Box>
    );
  }

  return (
    <SHDButton
      primary
      color={isDisabledSendButton ? 'light-5' : 'tertiary-1'}
      label={sendState === 10 ? '...Sending' : 'Send verification link'}
      disabled={isDisabledSendButton}
      onClick={onSendVerification}
      style={{ height: '48px' }}
    />
  );
};

export default PromptFooter;
