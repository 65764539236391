// Packages
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import React, { useState } from 'react';
import styles from '../Admin.module.css';

// Utils and helpers
import { AugmentedUser } from '../../../services/Session/store';
import { useMergePlayers } from '../admin.hooks';
import { withAuthorization } from '../../../services/Session';
import withLayout from '../../../helpers/withLayout';

// Types
import { MERGE_PLAYERS_HEADER_TEXT, ClaimedPlayerProps } from '../admin.types';
import { shd } from '@shd/jslib/models';

// Components
import { Box } from 'grommet';
import AdminHeader from '../components/AdminHeader';
import AdminToolsMergePlayers from '../components/AdminToolsMergePlayers';
import MergePlayersStepFour from './views/MergePlayersStepFour';
import MergePlayersStepOne from './views/MergePlayersStepOne';
import MergePlayersStepThree from './views/MergePlayersStepThree';
import MergePlayersStepTwo from './views/MergePlayersStepTwo';

// Variables and constants
const condition = (authUser: AugmentedUser) =>
  authUser && authUser.claims?.is_admin;

const MergePlayers: React.FC = observer(() => {
  // Hooks
  const { mergePlayers } = useMergePlayers();
  const history = useHistory();

  // States
  const [step, setStep] = useState<number>(1);
  const [showAdminTools, setShowAdminTools] = useState<boolean>(false);
  const [primaryPlayer, setPrimaryPlayer] = useState<ClaimedPlayerProps>(null);
  const [secondaryPlayers, setSecondaryPlayers] = useState<shd.ClaimedPlayer[]>(
    []
  );

  // Show and hide admin tools for testing.
  useHotkeys('ctrl+d+k', () => {
    setShowAdminTools(!showAdminTools);
  });

  const handleRemoveSecondaryPlayer = (player: shd.ClaimedPlayer) => {
    const newSecondaryPlayers = secondaryPlayers.filter(
      (p) => p._id !== player._id
    );
    setSecondaryPlayers(newSecondaryPlayers);
  };

  const handleMergeProfiles = async () => {
    if (primaryPlayer && secondaryPlayers && secondaryPlayers.length > 0) {
      const isMergeSuccess: unknown = await mergePlayers(
        primaryPlayer,
        secondaryPlayers
      );
      if (isMergeSuccess) {
        setStep(4);
      }
    }
  };

  return (
    <Box
      className={styles['admin-tools']}
      id="adminToolsMergePlayers"
      pad="medium"
    >
      {showAdminTools && (
        <AdminToolsMergePlayers
          onSetStep={(num) => setStep(num)}
          primaryPlayer={primaryPlayer}
          secondaryPlayers={secondaryPlayers}
        />
      )}

      <AdminHeader
        body={MERGE_PLAYERS_HEADER_TEXT[step].body}
        id="mergePlayersHeader"
        title={MERGE_PLAYERS_HEADER_TEXT[step].title}
        toolName="Merge Player Profiles"
      />

      {step === 1 && (
        <MergePlayersStepOne
          onLinkClicked={(url) => history.push(url)}
          onProgressStep={(payload) => {
            setPrimaryPlayer(payload);
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <MergePlayersStepTwo
          onAddSecondaryPlayer={(player) => {
            setSecondaryPlayers([...secondaryPlayers, player]);
          }}
          onProgressStep={() => {
            setStep(3);
          }}
          onRemovePrimaryPlayer={() => {
            setPrimaryPlayer(null);
            setStep(1);
          }}
          onRemoveSecondaryPlayer={(player) => {
            handleRemoveSecondaryPlayer(player);
          }}
          primaryPlayer={primaryPlayer}
          secondaryPlayers={secondaryPlayers}
        />
      )}
      {step === 3 && (
        <MergePlayersStepThree
          onMergeProfiles={() => {
            handleMergeProfiles();
          }}
          onProgressStep={(newStep) => {
            setStep(newStep);
          }}
          primaryPlayer={primaryPlayer}
          secondaryPlayers={secondaryPlayers}
        />
      )}
      {step === 4 && (
        <MergePlayersStepFour
          onLinkClicked={(url) => history.push(url)}
          primaryPlayer={primaryPlayer}
        />
      )}
    </Box>
  );
});

export default withAuthorization(condition)(withLayout(MergePlayers));
