// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';
import {
  Button, Text, Card, Heading,
} from 'grommet';

export const CardStyled = styled(Card)`
  height: 66px;
  border-radius: 8px;
  padding: 13px 12px;
`;

export const CardButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.global.colors['secondary-1']};
  border-radius: 8px;
  width: 85px;
  height: 40px;
  color: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
`;

export const PlayerName = styled(Text)`
 font-size: 16px;
 font-weight: 700;
`;

export const TeamName = styled(Text)`
 font-size: 14px;
 font-weight: 400;
`;

export const SearchInfo = styled(Text)`
 font-size: 14px;
 font-weight: 400;
 margin-inline: 6px;
`;

export const PlayersHeading = styled(Heading)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
  margin-inline: 6px;
`;
