import { Box, Button, ResponsiveContext, Text } from 'grommet';
import React, { useState, useEffect, useContext } from 'react';
import axios from '../../helpers/axios';
import { shd } from '@shd/jslib/models';
import PlayerClaimRequestsModal from './PlayerClaimRequestsModal';

interface Props {
  teamId: string;
}
const ClaimRequests: React.FC<Props> = ({ teamId }) => {
  const screen = useContext(ResponsiveContext);

  const [claimRequests, setClaimRequests] = useState<shd.ClaimRequest[]>([]);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const visibleClaimRequests = claimRequests.filter((claim) => claim.isVisible);

  useEffect(() => {
    axios.get(`/api/team/${teamId}/claim_requests`).then(({ data }) => {
      setClaimRequests(data.requests);
    });
  }, [teamId]);

  if (!visibleClaimRequests.length) {
    return null;
  }

  return (
    <Box
      pad={
        screen !== 'large'
          ? { vertical: '8px', horizontal: '16px' }
          : { vertical: '8px', horizontal: '10%' }
      }
      background={'tertiary-1'}
      direction="row"
      justify="between"
      align="center"
      flex
    >
      <Box flex={{ grow: 0, shrink: 1 }}>
        <Text
          weight={'bold'}
        >{`You have ${visibleClaimRequests.length} pending player claim request${
          visibleClaimRequests.length > 1 ? 's' : ''
        }`}</Text>
      </Box>
      <Box
        flex={
          screen === 'small' ? { grow: 1, shrink: 0 } : { grow: 0, shrink: 1 }
        }
      >
        <Button
          secondary
          size="small"
          color={'white'}
          label={
            <Text size="small" color={'white'}>
              View Requests
            </Text>
          }
          onClick={() => setShowRequestModal(true)}
        />
      </Box>
      {showRequestModal && (
        <PlayerClaimRequestsModal
          claimRequests={claimRequests}
          onClose={() => setShowRequestModal(false)}
        />
      )}
    </Box>
  );
};

export default ClaimRequests;
