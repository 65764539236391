import mixpanel from 'mixpanel-browser';
import { theGlobalAppHost } from './appHost';
import { strogging } from '@shd/jslib/infra';

export enum UserType {
  LoggedOut = 'logged out',
  Admin = 'admin',
  CommunityMember = 'community member',
  LoggedIn = 'logged in',
}

export type Dict = {
  [key: string]: unknown;
};
function track(eventName: string, payload?: Dict) {
  const appHost = theGlobalAppHost();
  if (appHost.isHosted) {
    appHost.service.notifications.trackEvent(eventName, payload);
  } else {
    strogging.log('mixpanel-track', { eventName, payload });
    mixpanel.track(eventName, payload);
  }
}
function identify(unique_id?: string): void {
  const appHost = theGlobalAppHost();
  if (appHost.isHosted) {
    //  appHost.service.notifications.identifyUser(unique_id);
  } else {
    strogging.log('mixpanel-identify', { unique_id });
    mixpanel.identify(unique_id);
  }
}

function setUserProperties(prop: Dict): void {
  const appHost = theGlobalAppHost();
  if (appHost.isHosted) {
    // appHost.service.notifications.setUserProperties({ ...prop });
  } else {
    strogging.log('mixpanel-setUserProperties', { prop });
    mixpanel.people.set(prop);
  }
}

export const analytics = {
  track,
  identify,
  setUserProperties,
};
