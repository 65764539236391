// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Keyboard } from 'grommet';
import { Search } from 'grommet-icons';

import withLayout from '../../../helpers/withLayout';

import {
  PlayerHeading,
  SearchInput,
  SearchButton,
  InputWrapper,
} from './styles';

import PlayerList from './PlayerList';
import axios from '../../../helpers/axios';
import PlayerHeader from './PlayerHeader';

const ClaimPlayer = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchError, setSearchError] = useState(false);

  const isShowSearchHeading = !searching && !searchResults.length;

  const handleSearch = () => {
    if (!searchValue) {
      return;
    }

    setSearching(true);
    axios
      .get('/api/player_text_search', { params: { search: searchValue } })
      .then((response) => {
        setSearching(false);
        setSearchResults([...response.data]);
        setSearchError(null);
      })
      .catch((e) => {
        setSearchError(e.toString());
      });
  };

  const onInputChange = ({ target }) => {
    setSearchValue(target.value);

    if (!target.value.length) {
      setSearchResults([]);
    }
  };

  const handleBack = () => {
    props.history.goBack();
  };

  return (
    <Box pad="medium">
      <PlayerHeader
        currentStep="1"
        buttonLabel="Back"
        buttonAction={handleBack}
      />
      {isShowSearchHeading && (
        <PlayerHeading color="dark-1" level={2}>
          Find my player
        </PlayerHeading>
      )}
      <InputWrapper direction="row" justify="between" align="center">
        <Keyboard onEnter={handleSearch}>
          <SearchInput
            plain
            type="search"
            placeholder="Search by player name"
            color="dark-4"
            value={searchValue}
            onChange={onInputChange}
          />
        </Keyboard>
        <SearchButton
          justify="center"
          icon={<Search color="#fff" />}
          onClick={handleSearch}
        />
      </InputWrapper>
      <PlayerList
        searching={searching}
        searchResults={searchResults}
        searchError={searchError}
      />
    </Box>
  );
};

export default withLayout(withRouter(ClaimPlayer));
