// Packages
import React from 'react';
import parse from 'html-react-parser';
import { usePaywall, usePaywallTracking, useRevenueCat } from './paywall.hooks';
import { getPaywallCtaCopy } from './paywall.copy';

// Components
import { Box, Heading } from 'grommet';
import {
  PaywallCopy,
  PaywallCtaButton,
  paywallCtaBadge,
} from './paywall.styles';
import Paywall from './Paywall';
import { BasicLoader } from '../../helpers/Loaders';

// Types
import { PaywallCtaProps, PaywallEvents } from './paywall.types';

const PaywallCta: React.FC<PaywallCtaProps> = ({
  context,
  mode,
  playerId = '',
}) => {
  // Hooks
  const { annualPrice, isLoadingRc } = useRevenueCat();
  const { trackPaywallEvent } = usePaywallTracking(context, playerId);

  const {
    isPaywallActive,
    handlePaywallOpen,
    handlePaywallClose,
    handleUnlockAccess,
  } = usePaywall(context, playerId);

  // Constants
  const { headline, copy } = getPaywallCtaCopy(annualPrice, mode);

  const handleCtaClick = () => {
    trackPaywallEvent(PaywallEvents.VIEW_PLANS_CLICKED, {
      context: context,
    });
    handlePaywallOpen();
  };

  if (isPaywallActive) {
    trackPaywallEvent(PaywallEvents.OFFER_PAGE_VIEWED, {
      context: context,
    });
  }

  return (
    <>
      {isLoadingRc ? (
        <BasicLoader />
      ) : (
        <>
          <Box
            align="center"
            background="#183710"
            className="paywall-cta"
            direction="column"
            flex={true}
            pad="1.5rem 1rem"
            round="0.75rem"
          >
            <Heading
              level={4}
              color="#183710"
              margin="0 0 1rem"
              size="0.75rem"
              style={paywallCtaBadge}
              weight={900}
            >
              Pro Access
            </Heading>

            <Heading
              color="#ccff50"
              level={3}
              margin={{
                bottom: '0.625rem',
                left: '0',
                right: '0',
                top: '0',
              }}
              size="1.375rem"
              style={{ lineHeight: '1.272727' }}
              textAlign="center"
              weight="bold"
            >
              {headline}
            </Heading>

            <PaywallCopy
              color="#e7e7e7"
              margin={{ bottom: '0' }}
              size="0.875rem"
            >
              {parse(copy)}
            </PaywallCopy>

            <PaywallCtaButton
              fill="horizontal"
              label="View Plans"
              margin={{ top: '2rem' }}
              pad="0.75rem"
              onClick={() => handleCtaClick()}
            />
          </Box>

          <Paywall
            context={context}
            playerId={playerId}
            isOpen={isPaywallActive}
            onClose={() => handlePaywallClose()}
            onUnlockAccess={(term) => handleUnlockAccess(term)}
          />
        </>
      )}
    </>
  );
};

export default PaywallCta;
