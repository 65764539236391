import React from 'react';
import { Box, Text } from 'grommet';
import { borderLeft, borderRight } from './games.style';
import { GameScoreProps } from './games.types';

const GameScore: React.FC<GameScoreProps> = ({
  awayScore,
  homeScore,
  teamHSetScores,
  teamASetScores,
  sportType,
}) => {
  let awayTeamScore = awayScore === -1 ? 0 : awayScore;
  let homeTeamScore = homeScore === -1 ? 0 : homeScore;

  if (sportType === 'vb' && teamHSetScores && teamASetScores) {
    let AScore = 0;
    let HScore = 0;

    for (let i = teamHSetScores.length; i > 0; i -= 1) {
      if (i - 1 >= 0 && i !== -1) {
        if (teamHSetScores[i - 1] > teamASetScores[i - 1]) {
          HScore += 1;
        }
        if (teamHSetScores[i - 1] < teamASetScores[i - 1]) {
          AScore += 1;
        }
      }
    }

    homeTeamScore = HScore;
    awayTeamScore = AScore;
  }

  // Hack for some poorly formatted scores
  if ((homeTeamScore as number) < 0) {
    homeTeamScore = 0;
  }

  if ((awayTeamScore as number) < 0) {
    awayTeamScore = 0;
  }

  return (
    <Box alignSelf="center" direction="row">
      <Box
        border={borderLeft}
        round={{ size: 'small', corner: 'left' }}
        background={awayTeamScore > homeTeamScore ? 'tertiary-1' : undefined}
      >
        <Text size="medium" style={{ padding: '5px', textAlign: 'center' }}>
          {awayTeamScore}
        </Text>
      </Box>
      <Box
        border={borderRight}
        round={{ size: 'small', corner: 'right' }}
        background={homeTeamScore > awayTeamScore ? 'tertiary-1' : undefined}
      >
        <Text size="medium" style={{ padding: '5px', textAlign: 'center' }}>
          {homeTeamScore}
        </Text>
      </Box>
    </Box>
  );
};

export default GameScore;
