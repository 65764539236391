import { shd } from '@shd/jslib/models';

// Types
export type AdminPlayerCardModes = 'primary' | 'secondary';
export type AdminSearchModes = 'player' | 'clip';
export type AdminSearchPayloads = shd.ClaimedPlayer | shd.Clip | null;
export type ClaimedPlayerProps = shd.ClaimedPlayer | null;
export type ClipProps = shd.Clip | null;

// Interfaces
export interface ActionConfirmationModalProps {
  action: () => void;
  onClose: () => void;
  confirmationText: string;
  actionName: string;
}

export interface AdminAvatarProps {
  src: string;
}

export interface AdminCardProps<T> {
  hasChangeButton?: boolean;
  hasRemoveButton?: boolean;
  payload: T;
  onChangeClicked?: () => void;
  onRemoveClicked?: () => void;
  onSelectCard?: (payload: T) => void;
}

export interface AdminCardListProps<T> {
  debouncing: boolean;
  loading: boolean;
  onCardSelected?: (isSelected: boolean, payload: T) => void;
  searchResults: T[];
}

export interface AdminHeaderProps {
  body?: string;
  id: string;
  title: string;
  toolName: string;
  icon?: string;
}

export interface AdminHeaderCopyProps {
  body?: string;
  title: string;
  icon?: string;
}

export interface AdminNewClipLabelModalProps {
  oldLabel: string;
  sport: string;
  onCloseModal: () => void;
  onChangeClipLabel: (newLabel: RelabelClipsOptions) => void;
}

export interface AdminSearchProps<T> {
  id: string;
  label: string;
  placeholder: string;
  isCardSelected: (isSelected: boolean) => void;
  onCardSelected?: (payload: T) => void;
}

export interface AdminSecondaryIdModalProps {
  onCloseModal: () => void;
  onAddSecondaryPlayer: (player: shd.ClaimedPlayer) => void;
}

export interface AdminStepButtonsProps {
  isFixed: boolean;
  backLabel: string;
  isNextActive: boolean;
  nextLabel: string;
  onBack: () => void;
  onNext: () => void;
}

export interface AdminToolsMergePlayersProps {
  onSetStep: (step: number) => void;
  primaryPlayer: ClaimedPlayerProps;
  secondaryPlayers: ClaimedPlayerProps[];
}

export interface AdminToolsRelabelClipsProps {
  clip: ClipProps;
  oldLabel: string;
  newLabel: string;
  onSetStep: (step: number) => void;
}

export interface FirebaseSearchProps {
  setUser: (user: shd.SQLUser) => void;
}

export interface MergePlayersStepOneProps {
  onProgressStep: (player: shd.ClaimedPlayer) => void;
  onLinkClicked?: (url: string) => void;
}

export interface MergePlayersStepFourProps {
  primaryPlayer: shd.ClaimedPlayer | null;
  onLinkClicked?: (url: string) => void;
}

export interface MergePlayersStepThreeProps {
  primaryPlayer: ClaimedPlayerProps;
  secondaryPlayers: ClaimedPlayerProps[];
  onProgressStep: (step: number) => void;
  onMergeProfiles: () => void;
}

export interface MergePlayersStepTwoProps {
  primaryPlayer: ClaimedPlayerProps;
  secondaryPlayers: ClaimedPlayerProps[];
  onAddSecondaryPlayer: (player: shd.ClaimedPlayer) => void;
  onRemoveSecondaryPlayer: (player: shd.ClaimedPlayer) => void;
  onRemovePrimaryPlayer: () => void;
  onProgressStep: () => void;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface PlayerCardProps {
  player: shd.SQLUserPlayer;
  playerManagerId: string;
  setShowConfirmation: (show: boolean) => void;
  setCurrentAction: (val: any) => void;
  setPlayers?: ((players: any) => void) | undefined;
}

export interface RelabelClipsOptions {
  label: string;
  value: string;
}

export interface RelabelClipsStepOneProps {
  onLinkClicked?: (url: string) => void;
  onProgressStep: (clip: ClipProps) => void;
}

export interface RelabelClipsStepTwoProps {
  clip: ClipProps;
  oldLabel: string;
  onBack: () => void;
  onRelabelClip: (selectedLabel: string) => void;
}

export interface RelabelClipsStepThreeProps {
  clip: ClipProps;
  oldLabel: string;
  newLabel: string;
  onLinkClicked?: (url: string) => void;
}

export interface TeamCardProps {
  team: shd.SQLUserTeam;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface UserCardProps {
  user: shd.SQLUser;
  i: number;
  setCurrentAction: (val: any) => void;
  getClaimLink: (val: any) => void;
  setShowConfirmation: (show: boolean) => void;
  setUser: (user: shd.SQLUser) => void;
}

export interface UserSearchProps {
  setUser: (user: shd.SQLUser) => void;
}

// Constants
export const MERGE_PLAYERS_HEADER_TEXT: Array<AdminHeaderCopyProps> = [
  {
    title: '',
    body: '',
  },
  {
    title: `Add primary player ID`,
    body: `Select the player ID that duplicate profiles will be merged into.`,
  },
  {
    title: `Add secondary player IDs`,
    body: `Search for and add player IDs that will be merged with the primary player.`,
  },
  {
    title: `Review merge`,
    body: `All secondary player IDs will be merged with the primary ID. Please ensure all selected player IDs are correct.`,
  },
  {
    title: `Merge successful`,
    body: `All secondary IDs have now been removed, and the primary player ID will be used moving forward to identify this profile.`,
  },
];

export const RELABEL_CLIPS_HEADER_TEXT: Array<AdminHeaderCopyProps> = [
  {
    title: '',
    body: '',
    icon: '',
  },
  {
    title: `Search for a clip`,
    body: `Enter the clip ID, which can be found at the end of the clip URL.`,
    icon: 'LinkPrevious',
  },
  {
    title: `Relabel Clip`,
    body: ``,
    icon: '',
  },
  {
    title: `Relabel successful`,
    body: `The clip below has been relabeled.`,
    icon: '',
  },
];

export enum SEARCH {
  USER = 'user',
  PLAYER = 'player',
  TEAM = 'team',
}

export const SEARCH_BY_PLAYER = [{ value: 'email', label: 'Email' }];

export const SEARCH_BY_TEAM = [
  { value: 'name', label: 'Team Name' },
  { value: 'handle', label: 'Handle' },
];

export const SEARCH_BY_USER = [
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone Number' },
  { value: 'id', label: 'User ID' },
  { value: 'name', label: 'Last Name' },
];

export const SEARCH_OPTIONS = [
  { value: 'user', label: 'User' },
  //   { value: 'player', label: 'Player' },
  { value: 'team', label: 'Team' },
];
