// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box, Image } from 'grommet';

const IconTeamSnap = () => (
  <Box height={{ max: '29px' }} width={{ max: '29px' }}>
    <Image src="/teamsnapIcon.svg" fill="vertical" fit="contain" />
  </Box>
);

export default IconTeamSnap;
