// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { makeAutoObservable } from 'mobx';
import zlib from 'browserify-zlib';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import EventHistoryParser from '../../scripts/parseEventHistory';
import axios from '../../helpers/axios';
import {
  reinitializeFirebase,
  theFirebase,
} from '../../services/Firebase/firebase';
import { inflate, deflate } from './scoringUtil';
import { strogging } from '@shd/jslib/infra';

const history = createBrowserHistory();

const generateInfo = (scoreboard) => {
  let now = new Date(scoreboard.scoreboardTs);
  now = now.toLocaleString();
  const { balls, strikes, outs, inningNum, teamHScore, teamAScore } =
    scoreboard;
  const infoString = `${now} | Home (${parseInt(
    teamHScore
  )}) - Away (${parseInt(teamAScore)}) \
    | ${inningNum}i ${balls}b ${strikes}s ${outs}o`;
  return infoString;
};

class ScoringStore {
  firebase = null;

  prompt = null;

  teamId = null;

  gameId = null;

  scoreGameId = null;

  scoreGameKey = null;

  eventHistory = [];

  gameInfo = null;

  scoreboard = null;

  lineupQueueIndexes = [];

  lineups = [[], []];

  startingLineups = [[], []];

  pitchers = [];

  startingPitchers = [];

  isQuickStartMode = false;

  isQuickStartModeOriginal = false;

  scheduleTitle = '';

  awayHome = null;

  isInitialized = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPrompt(prompt) {
    this.prompt = prompt;
  }

  toggleIsInitialized(bool) {
    this.isInitialized = bool;
  }

  setEventHistory(eventHistory) {
    this.eventHistory = eventHistory;
  }

  setGameInfo(gameInfo) {
    this.gameInfo = gameInfo;
  }

  setScoreboard(scoreboard) {
    this.scoreboard = scoreboard;
  }

  setLineups(lineups) {
    this.lineups = lineups;
  }

  setStartingLineups(startingLineups) {
    this.startingLineups = startingLineups;
  }

  setPitchers(pitchers) {
    this.pitchers = pitchers;
  }

  // setStartingPitchers(startingPitchers) {
  //   this.startingPitcherss = startingPitchers;
  // }

  setScheduleTitle(title) {
    this.scheduleTitle = title;
  }

  setAwayHome(awayHome) {
    this.awayHome = awayHome;
  }

  toggleIsQuickStartMode(bool) {
    this.isQuickStartMode = bool;
  }

  setLineupQueueIndexes(lineupQueueIndexes) {
    this.lineupQueueIndexes = lineupQueueIndexes;
  }

  async setGeoCoords() {
    const getPosition = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

    try {
      const location = await getPosition();
      const { coords } = location;
      const newGameInfo = { ...this.gameInfo };
      const lat = coords.latitude;
      const lng = coords.longitude;
      const latLng = [lat, lng].map((coord) => {
        if (coord >= 0) {
          return `+${coord.toString()}`;
        }
        return coord.toString();
      });
      [newGameInfo.locationLat, newGameInfo.locationLng] = latLng;
      this.setGameInfo(newGameInfo);
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  onGameEnd() {
    const body = {
      teamId: this.teamId,
      teamGameId: this.gameId,
      teamGameUpdate: {
        scoreGameState: 50,
        scheduleState: 50,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        history.push('/scoring');
        window.location.reload();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }

  promptUser = async (text, subtext, type, onResponse, lastBatterDisplayName) =>
    new Promise((resolve) => {
      this.setPrompt({
        text,
        subtext,
        type,
        resolve,
        lastBatterDisplayName,
      });
    })
      .then(onResponse)
      .catch((err) => Sentry.captureException(err));

  subscribeToGame() {
    this.firebase.subscribeToGame(
      this.teamId,
      this.scoreGameId,
      async (snapshot) => {
        if (snapshot) {
          const {
            scoreboardJson,
            eventHistory,
            eventHistoryCompressed,
            shdTeamLineup,
            shdTeamPitcher,
            lineupQueueIndexes,
            isQuickStartMode,
          } = snapshot;

          if (!scoreboardJson) {
            strogging.error('scoreboardJson is null', snapshot);
            return;
          }

          const {
            balls,
            strikes,
            outs,
            curOffensePos,
            inningNum,
            teamHScore,
            teamAScore,
          } = JSON.parse(scoreboardJson);

          const h = teamHScore;
          const a = teamAScore;
          const b1 = !!curOffensePos[1];
          const b2 = !!curOffensePos[2];
          const b3 = !!curOffensePos[3];
          const stateScoreboard = {
            b: balls,
            s: strikes,
            o: outs,
            b1,
            b2,
            b3,
            // turn from float to index notation
            i: (inningNum - 1) * 2,
            h,
            a,
          };

          const oppTeamLineup = Array(9).fill({
            displayName: '',
            jerseyNum: '',
            _id: '',
          });
          const lineups = this.awayHome
            ? [oppTeamLineup, shdTeamLineup]
            : [shdTeamLineup, oppTeamLineup];
          const pitchers = this.awayHome
            ? [{ displayName: '', jerseyNum: '' }, shdTeamPitcher]
            : [shdTeamPitcher, { displayName: '', jerseyNum: '' }];
          this.setScoreboard(stateScoreboard);
          this.setLineupQueueIndexes(lineupQueueIndexes);
          this.setLineups(lineups);
          this.setPitchers(pitchers);
          let resolvedEventHistory;
          if (eventHistoryCompressed) {
            try {
              const eventHistoryCompressedBuffer = Buffer.from(
                eventHistoryCompressed,
                'base64'
              );
              const decompressedEventHistoryBuffer = await inflate(
                eventHistoryCompressedBuffer
              );
              const decompressedEventHistory =
                decompressedEventHistoryBuffer.toString('utf8');
              resolvedEventHistory = JSON.parse(decompressedEventHistory);
            } catch (e) {
              Sentry.captureException(e);
              if (Array.isArray(eventHistoryCompressed)) {
                // weird. fixes issue where eventHistoryCompressed is somehow not compressed
                resolvedEventHistory = eventHistoryCompressed;
              }
            }
          } else {
            resolvedEventHistory = eventHistory;
          }
          this.setEventHistory(resolvedEventHistory);
          this.toggleIsQuickStartMode(isQuickStartMode);
        } else {
          Sentry.captureMessage('subscribeToGame failed');
        }
      }
    );
  }

  unsubscribeFromGame() {
    if (this.firebase) {
      this.firebase.unsubscribeFromGame(this.teamId, this.scoreGameId);
    }
    this.clearStore();
  }

  createScoreboard(
    scoreboardList,
    clipEventsList,
    plateAppearanceList,
    latestScoreboard,
    eventHistory,
    lineups,
    lineupQueueIndexes,
    pitchers,
    isQuickStartMode
  ) {
    console.log('clipEventsList', clipEventsList);
    console.log('eventHistory', eventHistory);

    let plateAppearanceStartTs = null;
    let plateAppearanceEndTs = null;
    if (plateAppearanceList.length) {
      plateAppearanceStartTs = plateAppearanceList[0].startTs;
      plateAppearanceEndTs =
        plateAppearanceList[plateAppearanceList.length - 1].endTs;
    }

    const scoreboardListJson = Buffer.from(
      JSON.stringify(scoreboardList),
      'utf8'
    );
    const clipEventsListJson = Buffer.from(
      JSON.stringify(clipEventsList),
      'utf8'
    );
    const plateAppearanceListJson = Buffer.from(
      JSON.stringify(plateAppearanceList),
      'utf8'
    );
    const eventHistoryJson = Buffer.from(JSON.stringify(eventHistory), 'utf8');

    const scoreboardListPromise = new Promise((resolve, reject) => {
      zlib.deflate(scoreboardListJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const clipEventsListPromise = new Promise((resolve, reject) => {
      zlib.deflate(clipEventsListJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const plateAppearanceListPromise = new Promise((resolve, reject) => {
      zlib.deflate(plateAppearanceListJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const eventHistoryJsonPromise = new Promise((resolve, reject) => {
      zlib.deflate(eventHistoryJson, (err, buffer) => {
        if (err) {
          return reject(err);
        }
        resolve(buffer.toString('base64'));
        return true;
      });
    });

    const shdTeamLineup = lineups[this.awayHome];
    const shdTeamPitcher = pitchers[this.awayHome] || null;

    Promise.all([
      scoreboardListPromise,
      clipEventsListPromise,
      plateAppearanceListPromise,
      eventHistoryJsonPromise,
    ]).then((values) => {
      const scoreboardListCompressed = values[0];
      const clipEventsListCompressed = values[1];
      const plateAppearancesCompressed = values[2];
      const eventHistoryCompressed = values[3];

      const newScoreboardDoc = {
        scoreTeamId: this.teamId,
        shdTeamLineup,
        scoreGameId: this.scoreGameId,
        doc: {
          info: generateInfo(latestScoreboard),
          ts: latestScoreboard.scoreboardTs,
          teamId: this.teamId,
          localTzString: Intl.DateTimeFormat().resolvedOptions().timeZone,
          localTzSource: 'profile',
          scoreGameId: this.scoreGameId,
          scoreTeamIdList: [
            this.gameInfo.teamAScoreTeamId,
            this.gameInfo.teamHScoreTeamId,
          ],
          teamIdIsHome: !!this.awayHome,
          scorePlayerIdList: null, // get this
          plateAppearanceScorePlayerIdList: null, // get this
          scoreboardJson: JSON.stringify(latestScoreboard),
          scoreboardListCompressed,
          clipEventsListCompressed,
          plateAppearancesCompressed,
          piiPlayerInfoByScorePlayerIdCompressed: null, // get this
          plateAppearanceStartTs,
          plateAppearanceEndTs,

          scheduleTitle: this.scheduleTitle,

          // sport type
          sportType: 'bbsb',

          // Sscore specific fields
          isQuickStartMode,
          eventHistoryCompressed,
          shdTeamLineup,
          shdTeamPitcher,
          lineupQueueIndexes,
          isGameEnd: latestScoreboard.isGameEnd,
        },
      };
      this.firebase.createScoreboard(
        this.scoreGameKey,
        newScoreboardDoc,
        (err = null) => {
          if (err) {
            Sentry.captureMessage(err);
            // restart or re-initialize firebase here
            this.firebase = theFirebase();
            this.subscribeToGame();
          } else if (newScoreboardDoc.doc.isGameEnd) {
            this.onGameEnd();
          }
        }
      );

      // Remove this write as scoreTsEnd update is handled by daemon process
      // basically need to update scoreTsEnd on the game for the live test to work
      // const game = {
      //  scoreTsEnd: plateAppearanceEndTs,
      // };
      // this.firebase.updateGame(this.teamId, this.gameId, game);
    });
  }

  async handleEvent(eventType, eventInfo = {}) {
    const now = Date.now() / 1000;
    const newEvent = { ts: now, eventType, eventInfo };
    const newEventHistory = [...this.eventHistory, newEvent];

    try {
      const parser = new EventHistoryParser(
        newEventHistory,
        this.gameInfo,
        _.cloneDeep(this.startingLineups),
        _.cloneDeep(this.startingPitchers),
        this.promptUser,
        this.isQuickStartModeOriginal
      );

      const {
        scoreboardList,
        clipEventsList,
        latestScoreboard,
        plateAppearanceList,
        lineups,
        pitchers,
        lineupQueueIndexes,
        eventHistory,
        isQuickStartMode,
      } = await parser.parseEventHistory();

      if (scoreboardList) {
        this.createScoreboard(
          scoreboardList,
          clipEventsList,
          plateAppearanceList,
          latestScoreboard,
          eventHistory,
          lineups,
          lineupQueueIndexes,
          pitchers,
          isQuickStartMode
        );
      } else {
        const eventHistoryJson = Buffer.from(
          JSON.stringify(eventHistory),
          'utf8'
        );
        const eventHistoryCompressedBuffer = await deflate(eventHistoryJson);
        const eventHistoryCompressed =
          eventHistoryCompressedBuffer.toString('base64');
        this.firebase.updateEventHistory(
          this.teamId,
          this.scoreGameKey,
          eventHistoryCompressed,
          (err) => {
            Sentry.captureException(err);
          }
        );
      }
    } catch (err) {
      strogging.error('Error in handleEvent', {
        err,
        eventHistory: newEventHistory,
      });
      Sentry.captureException(err);
    }
  }

  async hardRefresh() {
    try {
      await this.firebase.firestore.terminate();
      console.log('terminate firestore');
      await this.firebase.firestore.clearPersistence();
      console.log('clearPersistence');
    } catch (err) {
      Sentry.captureException(err);

      reinitializeFirebase();
      this.firebase = theFirebase();
      this.subscribeToGame();
    }

    return true;
  }

  async initGameState(game, scoreboard, firebase, forceResume = false) {
    this.firebase = firebase;
    this.teamId = game.scoreTeamIdOurs;
    this.scoreGameId = game.scoreGameId;
    this.scoreGameKey = game.scoreGameKey;
    this.gameId = `GmSh01-${game.scoreGameId}`;
    const gameInfo = {
      scoreGameId: game.scoreGameId,
      locationLat: '',
      locationLng: '',
      matchupTitle: `${game.scoreOurName} ${
        game.scoreWeAreHome ? 'vs.' : '@'
      } ${game.scoreOpponentName}`,
      teamHNameMed: game.scoreWeAreHome
        ? game.scoreOurName
        : game.scoreOpponentName,
      teamHShortName: 'HM',
      teamANameMed: !game.scoreWeAreHome
        ? game.scoreOurName
        : game.scoreOpponentName,
      teamAShortName: 'AWY',
      teamHScoreTeamId: game.scoreWeAreHome ? game.scoreTeamIdOurs : '',
      teamAScoreTeamId: !game.scoreWeAreHome ? game.scoreTeamIdOurs : '',
    };

    const now = Date.now() / 1000;
    let eventHistory = [{ eventType: 'startGame', eventInfo: {}, ts: now }];

    let stateScoreboard = {
      b: 0,
      s: 0,
      o: 0,
      i: 0,
      b1: false,
      b2: false,
      b3: false,
      h: 0,
      a: 0,
    };

    let { lineupQueueIndexes } = this;

    if (scoreboard) {
      const {
        eventHistory: respEventHistory,
        eventHistoryCompressed: respEventHistoryCompressed,
        scoreboardJson,
        lineupQueueIndexes: respLineupQueueIndexes,
      } = scoreboard;

      if (respEventHistoryCompressed) {
        try {
          const eventHistoryCompressedBuffer = Buffer.from(
            respEventHistoryCompressed,
            'base64'
          );
          const decompressedEventHistoryBuffer = await inflate(
            eventHistoryCompressedBuffer
          );
          const decompressedEventHistory =
            decompressedEventHistoryBuffer.toString('utf8');
          eventHistory = JSON.parse(decompressedEventHistory);
        } catch (err) {
          Sentry.captureException(err);
          if (Array.isArray(respEventHistoryCompressed)) {
            // weird. fixes issue where eventHistoryCompressed is somehow not compressed
            eventHistory = respEventHistoryCompressed;
          }
        }
      } else {
        // backwards compatibility with old eventHistory
        eventHistory = respEventHistory;
      }

      if (respEventHistory) {
        // delete eventHistory from firebase
        this.firebase.deleteEventHistory(this.teamId, this.scoreGameKey);
      }

      if (forceResume) {
        // remove endGame event
        eventHistory = eventHistory.filter(
          (event) => event.eventType !== 'endGame'
        );
      }
      lineupQueueIndexes = respLineupQueueIndexes;

      const {
        balls,
        strikes,
        outs,
        curOffensePos,
        inningNum,
        teamHScore,
        teamAScore,
      } = JSON.parse(scoreboardJson);

      stateScoreboard = {
        b: balls,
        s: strikes,
        o: outs,
        // turn from float to index notation
        i: (inningNum - 1) * 2,
        b1: !!curOffensePos[1],
        b2: !!curOffensePos[2],
        b3: !!curOffensePos[3],
        h: teamHScore,
        a: teamAScore,
      };
    }

    // const shdTeamLineup = (game.scoreOurLineup.length === 0 && lineup.length > 0)
    //   ? lineup : game.scoreOurLineup;
    const shdTeamLineup = game.scoreOurLineup;
    const shdTeamPitcher = game.scoreOurPitcher;
    const oppTeamLineup = Array(9).fill({ displayName: '', jerseyNum: '' });
    const oppTeamPitcher = { displayName: '', jerseyNum: '' };
    const lineups = game.scoreWeAreHome
      ? [oppTeamLineup, shdTeamLineup]
      : [shdTeamLineup, oppTeamLineup];
    const pitchers = game.scoreWeAreHome
      ? [oppTeamPitcher, shdTeamPitcher]
      : [shdTeamPitcher, oppTeamPitcher];

    this.eventHistory = eventHistory;
    this.gameInfo = gameInfo;
    this.scoreboard = stateScoreboard;
    this.lineups = lineups;
    this.pitchers = pitchers;

    this.startingLineups = _.cloneDeep(lineups);
    this.startingPitchers = _.cloneDeep(pitchers);

    this.isQuickStartMode = game.isQuickStartMode;
    this.isQuickStartModeOriginal = game.isQuickStartMode;
    this.lineupQueueIndexes = lineupQueueIndexes;
    this.awayHome = game.scoreWeAreHome ? 1 : 0;

    this.scheduleTitle = game.scheduleTitle;

    this.toggleIsInitialized(true);
    this.handleEvent('forceRun', {});
    this.setGeoCoords();

    const body = {
      teamId: game.scoreTeamIdOurs,
      teamGameId: `GmSh01-${game.scoreGameId}`,
      teamGameUpdate: {
        scoreGameState: 30,
        scheduleState: 30,
      },
    };

    axios.put('/api/team_game', body).catch((respError) => {
      Sentry.captureException(respError);
    });

    this.subscribeToGame();
  }

  clearStore() {
    this.firebase = null;
    this.setPrompt(null);
    this.teamId = null;
    this.gameId = null;
    this.scoreGameId = null;
    this.scoreGameKey = null;
    this.eventHistory = [];
    this.gameInfo = null;
    this.scoreboard = null;
    this.lineupQueueIndexes = [];
    this.lineups = [[], []];
    this.startingLineups = [[], []];
    this.pitchers = [];
    this.startingPitchers = [];
    this.awayHome = null;
    this.isInitialized = false;
    this.isQuickStartMode = false;
  }
}

export default ScoringStore;
