import { Firebase } from '../../services/Firebase/firebase';
import { shd } from '@shd/jslib/models';
import { strogging } from '@shd/jslib/infra';
import { useState } from 'react';
import axios from '../../helpers/axios';
import { ClaimedPlayerProps } from './admin.types';

export const useImpersonateUser = () => {
  const impersonateStart = async (
    targetUser: shd.SQLUser,
    firebase: Firebase
  ) => {
    await axios
      .post('/api/impersonate', {
        auth_user_id: firebase.auth.currentUser?.uid,
        spoof_user_id: targetUser.id,
      })
      .then(async (response) => {
        if (response.status === 200) {
          const numTeams = targetUser.teams.filter(
            (team) => !!team.name
          ).length;
          const user = firebase.auth.currentUser;

          if (!user) {
            strogging.log('Firebase auth user is null');
            return;
          }

          await user.getIdTokenResult(true);
          numTeams > 0
            ? localStorage.setItem('userRole', '0')
            : localStorage.setItem('userRole', '1');
          window.location.href = '/';
        }
      });
  };

  const impersonateStop = async (firebase: Firebase) => {
    await axios
      .delete('/api/impersonate', {
        data: {
          auth_user_id: firebase.auth.currentUser?.uid,
          spoof_user_id: null,
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const user = firebase.auth.currentUser;
          if (!user) {
            strogging.log('Firebase auth user is null');
            return;
          }
          await user.getIdTokenResult(true);
          localStorage.setItem('userRole', '0');
          window.location.reload();
        }
      });
  };

  return { impersonateStart, impersonateStop };
};

export const useMergePlayers = () => {
  const [searchPlayersResults, setSearchPlayersResults] = useState<
    ClaimedPlayerProps[]
  >([]);
  const [searchPlayersLoading, setSearchPlayersLoading] =
    useState<boolean>(false);
  const [searchPlayersError, setSearchPlayersError] = useState<string | null>(
    null
  );

  const searchPlayers = async (playerHandle: string) => {
    setSearchPlayersLoading(true);
    await axios
      .get(`/api/admin/player/${playerHandle}`)
      .then((response) => {
        setSearchPlayersResults([]);
        if (response.status === 200 && response.data.isSuccess) {
          setSearchPlayersResults([response.data.claimedPlayerDoc]);
        }
        setSearchPlayersError(null);
        setSearchPlayersLoading(false);
      })
      .catch((e) => {
        setSearchPlayersError(e.toString());
        setSearchPlayersLoading(false);
      });
  };

  const mergePlayers = async (
    primaryPlayer: shd.ClaimedPlayer,
    secondaryPlayers: shd.ClaimedPlayer[]
  ) => {
    const mergePromises = secondaryPlayers.map(async (player) => {
      try {
        const response = await axios.put(`/api/admin/player/merge`, {
          dst_player_handle: '',
          dst_player_id: primaryPlayer._id,
          src_player_handle: '',
          src_player_id: player._id,
        });
        return response.status === 200;
      } catch (error) {
        strogging.log('Error merging player:', error);
        return false;
      }
    });

    const results = await Promise.all(mergePromises);
    return results.every((result) => result);
  };

  const clearPlayers = async () => {
    setSearchPlayersResults([]);
  };

  return {
    clearPlayers,
    mergePlayers,
    searchPlayers,
    searchPlayersError,
    searchPlayersLoading,
    searchPlayersResults,
  };
};

export const useRelabelClips = () => {
  const [searchClipsResults, setSearchClipsResults] = useState<shd.Clip[]>([]);
  const [searchClipsLoading, setSearchClipsLoading] = useState<boolean>(false);
  const [searchClipsError, setSearchClipsError] = useState<string | null>(null);

  const searchClips = async (clipId: string) => {
    setSearchClipsLoading(true);
    await axios
      .get(`/api/clip/${clipId}`)
      .then((response) => {
        setSearchClipsResults([]);
        if (response.status === 200) {
          setSearchClipsResults([response.data]);
        }
        setSearchClipsError(null);
        setSearchClipsLoading(false);
      })
      .catch((e) => {
        setSearchClipsError(e.toString());
        setSearchClipsLoading(false);
      });
  };

  const relabelClips = async (clipId: string, newLabel: string) => {
    if (clipId === null) {
      strogging.log('Error relabeling clips: Clip is null');
      return false;
    }
    try {
      const response = await axios.put(`/api/admin/clip/relabel`, {
        clip_id: clipId,
        clip_new_label: newLabel,
      });
      return response.status === 200;
    } catch (error) {
      strogging.log('Error merging player:', error);
      return false;
    }
  };

  const clearClips = async () => {
    setSearchClipsResults([]);
  };

  return {
    clearClips,
    relabelClips,
    searchClips,
    searchClipsError,
    searchClipsLoading,
    searchClipsResults,
  };
};
