import React from 'react';
import { Box, Text, Image, Anchor } from 'grommet';
import {
  coachText,
  coachFrame,
  SocialHandle,
  bbBackgroundImage,
  sbBackgroundImage,
} from './dkplayerRecommendations.styles';

import { coaches, CoachInfo } from './dkplayerRecommentdations.types';

const CoachesCard: React.FC<{ sport?: string | null }> = ({
  sport = 'Baseball',
}) => {
  const backgroundImage =
    sport === 'Baseball' ? bbBackgroundImage : sbBackgroundImage;

  const filteredCoaches = coaches.filter((x) =>
    !sport ? x.sport === 'Baseball' : x.sport === sport
  );

  return (
    <Box style={backgroundImage}>
      <Box
        direction="column"
        flex
        justify="center"
        margin={{ top: '8rem', bottom: '8rem' }}
      >
        <Box
          style={{ width: '3.40275rem', height: '1.99725rem' }}
          alignSelf="center"
        >
          <Image fit="cover" src="/dk_subtract.png" />
        </Box>
        <Box alignContent="center" pad=".75rem">
          <Text
            style={{ fontFamily: 'Impact' }}
            size="1.5rem"
            textAlign="center"
          >
            Lessons
          </Text>
          <Text size="0.875rem" textAlign="center" margin=".5rem">
            Take swings with instructions from our world-class coaches. Watch
            demos, measure your swings, and receive instructions; all at the
            same time. A private lesson in your backyard.
          </Text>
        </Box>
        <Box margin="1rem">
          {filteredCoaches.map((coach: CoachInfo, index: number) => {
            if (index % 2 === 0) {
              return (
                <CoachLeft
                  key={index}
                  name={coach.name}
                  handle={coach.handle}
                  displayName={coach.displayName}
                  link={coach.link}
                />
              );
            }
            return (
              <CoachRight
                key={index}
                name={coach.name}
                handle={coach.handle}
                displayName={coach.displayName}
                link={coach.link}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const CoachLeft: React.FC<CoachInfo> = ({
  name,
  handle,
  link,
  displayName,
}) => {
  return (
    <Anchor
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#fff', textDecoration: 'none' }}
    >
      <Box id={`coach-${name}`} direction="row" fill="horizontal">
        <Box style={coachFrame}>
          <Image fit="cover" src={`/dk_${name}3x.png`} />
        </Box>
        <Box justify="center">
          <Text style={coachText}>{displayName}</Text>
          {handle && <SocialHandle>@{handle}</SocialHandle>}
        </Box>
      </Box>
    </Anchor>
  );
};

const CoachRight: React.FC<CoachInfo> = ({
  name,
  handle,
  link,
  displayName,
}) => {
  return (
    <Anchor
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#fff', textDecoration: 'none' }}
    >
      <Box id={`coach-${name}`} direction="row" justify="end">
        <Box justify="center">
          <Text textAlign="end" style={coachText}>
            {displayName}
          </Text>
          {handle && <SocialHandle>@{handle}</SocialHandle>}
        </Box>
        <Box style={coachFrame}>
          <Image fit="cover" src={`/dk_${name}3x.png`} />
        </Box>
      </Box>
    </Anchor>
  );
};

export default CoachesCard;
