/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { FullScreenSkeleton } from '../../helpers/Loaders';
import { UserContext } from './context';
import axios from '../../helpers/axios';
import TermsOfServiceLayer from '../../helpers/TermsOfServiceLayer';
import { theFirebase } from '../Firebase/firebase';
import { AugmentedUser } from './store';
import { strogging, telemetry } from '@shd/jslib/infra';
import { docStorage } from '@shd/jslib/models';
import { analytics } from '../analytics';
import { Purchases } from '@revenuecat/purchases-js';
import { getRevenueCatUserId, useIsFeatureEnabled } from '../../helpers/utils';

const withAuthentication = (
  Component: React.ComponentType<RouteComponentProps>
) =>
  withRouter((props: RouteComponentProps) => {
    const userStore = useContext(UserContext);
    const isMonetizationEnabled = useIsFeatureEnabled(
      'monetization',
      userStore
    );

    const [loading, toggleLoading] = useState(true);
    const [tsTosAccept, setTsTosAccept] = useState<number>();

    const onAcceptTos = () => {
      setTsTosAccept(new Date().getTime() / 1000);
      axios.post('/api/user_tos');
    };

    useEffect(() => {
      telemetry.updateTelemetryTags({
        uid: userStore.authUser?.uid || 'not_logged_in',
        shd_user_id: userStore.authUser?.claims?.shd_user_id,
      });
      Sentry.setUser({
        id: userStore.authUser?.claims?.shd_user_id as string | undefined,
      });
      if (userStore.authUser) {
        const shdUserId = userStore.authUser?.claims?.shd_user_id as string;
        if (shdUserId) {
          theFirebase().setAnalyticsUserId(shdUserId);
          theFirebase().setAnalyticsUserProperties({ shd_user_id: shdUserId });
          analytics.identify(shdUserId);
        }
        userStore.setHelpWidgetAuth();
        axios
          .get('/api/user_tos')
          .then((response) => {
            if (response.data !== false) {
              setTsTosAccept(response.data);
            }
          })
          .catch((e) => {
            strogging.exception('Failed to get user tos', e);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.authUser]);

    useEffect(
      () =>
        theFirebase().auth.onIdTokenChanged(async (user) => {
          if (user) {
            const result = await user.getIdTokenResult();
            const modifiedUser: AugmentedUser = user;
            modifiedUser.claims = result.claims;

            const isStopImpersonation =
              props.location.pathname.includes('stop_impersonation');

            modifiedUser.spoofedUser = null;
            if (
              'is_admin' in result.claims &&
              result.claims.is_admin &&
              'spoof_user_id' in result.claims &&
              result.claims.spoof_user_id !== null &&
              result.claims.spoof_user_id !== '' &&
              !isStopImpersonation
            ) {
              try {
                const response = await axios.get<docStorage.UserDoc>(
                  `/api/user/${result.claims.spoof_user_id}`
                );
                const spoofedUser = response.data;
                modifiedUser.spoofedUser = spoofedUser;
              } catch (e) {
                Sentry.captureException(e);
              }
            }

            if (Math.random() < 0.2) {
              // some of the time, track identity with sendinblue
              window.sendinblue.identify(modifiedUser.email);
            }

            if (isMonetizationEnabled) {
              await Purchases.configure(
                process.env.REACT_APP_REVENUECAT_BILLING_KEY || '',
                getRevenueCatUserId(modifiedUser.claims.shd_user_id)
              );

              // update the environment for the user
              // ok if this fails
              axios.post(
                `/api/revenuecat/customers/${getRevenueCatUserId(
                  modifiedUser.claims.shd_user_id
                )}/environment`,
                { environment: process.env.NODE_ENV }
              );
            }

            userStore.setAuthUser(modifiedUser);
            userStore
              .apiSetTeams()
              .then(() => {
                toggleLoading(false);
              })
              .catch((e) => {
                Sentry.captureException(e);
                toggleLoading(false);
              });
          } else {
            userStore.setAuthUser(null);
            toggleLoading(false);
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isMonetizationEnabled]
    );
    if (loading) {
      return <FullScreenSkeleton />;
    }

    return (
      <>
        <Component {...props} />
        {tsTosAccept !== undefined &&
          tsTosAccept !== 0 &&
          tsTosAccept < 1615241826 &&
          !(
            userStore.authUser &&
            userStore.authUser.claims &&
            'claims' in userStore.authUser &&
            'spoof_user_id' in userStore.authUser.claims &&
            userStore.authUser.claims.spoof_user_id &&
            userStore.authUser.claims.is_admin
          ) && <TermsOfServiceLayer onAcceptTos={onAcceptTos} />}
      </>
    );
  });

export default withAuthentication;
