// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box } from 'grommet';

import FormHeader from '../FormHeader';
import { CardText, MainHeading } from './style';
import OnboardingButton from '../OnboardingButton';

const SetUpScreen = ({ nextStep }) => (
  <Box pad={{ horizontal: 'large' }}>
    <FormHeader currentStep="4" totalSteps="5" isShownBackBtn={false} />
    <Box pad="small" gap="small" width="large" margin={{ bottom: 'small' }}>
      <MainHeading>Your team is created!</MainHeading>
      <CardText>We’ll guide you through the set up to start your first livestream.</CardText>
      <OnboardingButton label="Set up my livestream" onClick={nextStep} />
    </Box>
  </Box>
);

export default SetUpScreen;
