// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import { useState, useContext } from 'react';
import { Text, Box, Heading, Layer, Button } from 'grommet';
import * as Sentry from '@sentry/browser';
import { Videocam } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { StatusCritical, StatusGood } from 'grommet-icons';
import axios from '../../helpers/axios';
import EditCapture from './EditCapture';
import ViewCapture from './ViewCapture';
import { UserContext } from '../../services/Session/context';
import SHDButton from '../../components/SHD/Button';
import PointCameraLayer from './PointCameraLayer';
import Toast from '../../components/Toast';
import AdvancedSettings from '../Settings/AdvancedSettings';

const CapturePanel = observer(({ team, ...props }) => {
  const userStore = useContext(UserContext);
  const {
    capture,
    editCaptureLocal = () => {},
    toggleUserOwnsCapture = () => {},
  } = props;
  const [captureSetup, setCaptureSetup] = useState(capture);
  const [loading, toggleLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(props.showInfo);
  const [showEdit, setShowEdit] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [pointShow, setPointShow] = useState(false);
  const [pointToTeamId, setPointToTeamId] = useState();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);

  const editCapture = (captureInfo) => {
    toggleLoading(true);
    axios
      .put(`/api/capture/${captureInfo._id}`, {
        captureInfo,
      })
      .then((response) => {
        editCaptureLocal(response.data);
        setCaptureSetup(response.data);
        toggleLoading(false);
        setShowInfo(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError('Could not edit camera. Please try again.');
        toggleLoading(false);
      });
  };

  const deleteCapture = () => {
    toggleLoading(true);
    axios
      .delete(`/api/capture/${captureSetup._id}`)
      .then(() => {
        editCaptureLocal(null);
        toggleUserOwnsCapture(false);
        toggleLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        setError('Could not delete camera. Please try again.');
        toggleLoading(false);
      });
  };

  const pointCapture = (newTeamId) => {
    const newCapture = { ...captureSetup };
    newCapture.targetTeamId = newTeamId;
    toggleLoading(true);
    axios
      .put(`/api/capture/${newCapture._id}`, {
        captureInfo: newCapture,
      })
      .then((response) => {
        editCaptureLocal(response.data);
        setCaptureSetup(response.data);
        toggleLoading(false);
        setError('');
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response && e.response.data) {
          setError(e.response.data.message);
        } else {
          setError('Could not edit camera. Please try again.');
        }
        toggleLoading(false);
      });
  };

  const renderPanelTitle = () => (
    <Box
      direction="row"
      align="center"
      gap="medium"
      pad={{ horizontal: 'small' }}
    >
      <Videocam
        style={{
          fill: team && captureSetup.targetTeamId !== team._id && '#bababa',
        }}
      />
      <Heading level={4}>{captureSetup.captureName}</Heading>
      {captureSetup.targetTeamId === team._id ? (
        <StatusGood color="status-ok" />
      ) : (
        <StatusCritical color="status-critical" />
      )}
    </Box>
  );

  return (
    <>
      {userStore.authUser.spoofedUser && <Text>{captureSetup._id}</Text>}
      <Box background="white" round="small">
        {renderPanelTitle()}
        <Box pad="medium" gap="small">
          {captureSetup.targetTeamId === team._id ? (
            <Box gap="small" width="medium">
              <Button
                primary
                label="Camera info"
                onClick={() => setShowInfo(true)}
              />
              <Box
                onClick={() => {
                  if (captureSetup.isOwnedByActiveUser) {
                    setShowEdit(true);
                  } else {
                    setToast({
                      label: `Only ${captureSetup.ownerNameFirst} can edit this camera.`,
                      background: 'status-warning',
                    });
                  }
                }}
              >
                <Button
                  primary
                  label="Edit camera"
                  disabled={!captureSetup.isOwnedByActiveUser}
                />
              </Box>
              <Button
                primary
                label="Advanced settings"
                onClick={() => setShowAdvancedSettings(true)}
              />
              <SHDButton
                default
                color="status-critical"
                label={!loading ? 'Disable camera' : '...Disabling'}
                onClick={() => {
                  setPointToTeamId('disabled');
                  setPointShow(true);
                }}
                disabled={loading}
              />
            </Box>
          ) : (
            <Box gap="medium">
              {captureSetup.targetTeamId !== 'disabled' ? (
                <Text>
                  This camera currently streams to
                  <Text weight="bold">{` ${captureSetup.targetTeamName}`}</Text>
                </Text>
              ) : null}
              <SHDButton
                primary
                color="tertiary-1"
                label={
                  !loading ? (
                    <Text>
                      Stream to
                      <Text weight="bold">{` ${team.nameLong} `}</Text>
                      instead
                    </Text>
                  ) : (
                    '...Pointing'
                  )
                }
                mixtrack={['Repoint Camera']}
                onClick={() => {
                  setPointToTeamId(team._id);
                  setPointShow(true);
                }}
                disabled={loading}
              />
            </Box>
          )}
          {error ? <Text color="status-error">{error}</Text> : null}
        </Box>
      </Box>
      {showEdit && (
        <Layer>
          <EditCapture
            editCapture={editCapture}
            closeLayer={() => {
              setPointToTeamId('');
              setShowEdit(false);
            }}
            capture={captureSetup}
            teamId={team._id}
          />
        </Layer>
      )}
      {showInfo && (
        <Layer margin="large" style={{ overflowY: 'auto' }}>
          <ViewCapture
            name={captureSetup.captureName}
            type={captureSetup.equipCamera}
            captureId={captureSetup._id}
            isOwnedByActiveUser={captureSetup.isOwnedByActiveUser}
            onClose={() => setShowInfo(false)}
          />
        </Layer>
      )}
      {deleteShow && (
        <Layer>
          <Box margin="xlarge" gap="small" justify="center" align="center">
            <Text
              textAlign="center"
              color="status-critical"
            >{`Are you sure you want to remove ${captureSetup.captureName}?`}</Text>
            <Box direction="row" gap="small">
              <SHDButton
                primary
                label="Delete camera"
                color="status-critical"
                onClick={() => {
                  deleteCapture();
                  setDeleteShow(false);
                }}
              />
              <SHDButton
                default
                label="Exit"
                color="status-critical"
                onClick={() => setDeleteShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}
      {pointShow ? (
        <PointCameraLayer
          capture={captureSetup}
          closeLayer={() => setPointShow(false)}
          pointCapture={() => pointCapture(pointToTeamId)}
        />
      ) : null}
      {showAdvancedSettings && (
        <AdvancedSettings
          capture={capture}
          onClose={() => setShowAdvancedSettings(false)}
          onSaved={(c) => {
            editCaptureLocal(c);
            setCaptureSetup(c);
            setShowAdvancedSettings(false);
          }}
        />
      )}
      {toast && (
        <Toast
          label={toast.label}
          background={toast.background}
          duration={3000}
          onClose={() => setToast(null)}
        />
      )}
    </>
  );
});

export default CapturePanel;
