// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-lonely-if */
/* eslint-disable no-underscore-dangle */

import {
  Anchor,
  Box,
  Button,
  Card,
  DropButton,
  Heading,
  Image,
  Layer,
  ResponsiveContext,
  Text,
  TextInput,
} from 'grommet';
import { Close, DownloadOption, More } from 'grommet-icons';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LazyLoad from 'react-lazyload';
import Star from '@material-ui/icons/Star';
import Skeleton from 'react-loading-skeleton';
import * as Sentry from '@sentry/react';

import ClipVideoPlayer from '../../helpers/ClipVideoPlayer';
import Toast from '../../components/Toast';
import {
  formatDateShort,
  useIsFeatureEnabled,
  compareJerseyNum,
} from '../../helpers/utils';
import { isiOS } from '../../helpers/browserDetect';
import ShareDropdown from '../../helpers/ShareDropdown';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import GenericReassignClip from './GenericReassignClip';
import { BasicLoader } from '../../helpers/Loaders';
import { analytics } from '../../services/analytics';
import useZendeskWidget from '../../helpers/useZendeskWidget';

const PlayerClip = forwardRef(
  (
    {
      sport,
      game,
      clip,
      playerId,
      pinClip,
      unpinClip,
      isPinned = false,
      isPinnedAndAtTop = false,
      onChange = () => {},
      date,
      onShowClaim,
      inModal = false,
      setToast,
      ...props
    },
    ref
  ) => {
    const userStore = useContext(UserContext);
    const clipGodMode = useIsFeatureEnabled(
      'view_all_clips_enabled',
      userStore
    );
    const partnerClipViewerEnabled = useIsFeatureEnabled(
      'partner_clip_viewer_enabled',
      userStore
    );

    const userPlayer = userStore.userPlayers.find((p) => p._id === playerId);

    const [copied, setCopied] = useState(false);
    const [publicPublishLevel, setPublicPublishLevel] = useState(
      props.publicPublishLevel
    );
    // eslint-disable-next-line no-unused-vars
    const [isUnpinAnimation] = useState(false);
    const [showDownloadInstructions, setShowDownloadInstructions] =
      useState(false);
    const [showUnclaimedModal, setShowUnclaimedModal] = useState(false);

    const [showDeleteClip, setShowDeleteClip] = useState(false);
    const [showReassignClip, setShowReassignClip] = useState(
      props.showReassignClip || false
    );

    const [isMoreOpened, setIsMoreOpened] = useState(false);

    const [teamPlayers, setTeamPlayers] = useState([]);
    const [reassignedPlayers, setReassignedPlayers] = useState([]);

    const [deleteInputValue, setDeleteInputValue] = useState('');
    const screen = useContext(ResponsiveContext);

    const { showWidget, setConversationFields } = useZendeskWidget();

    const userPlayerIds = userStore.userPlayers
      ? userStore.userPlayers.map((player) => player._id)
      : [];

    const userTeamIds = userStore.userTeams
      ? userStore.userTeams.map((team) => team._id)
      : [];

    const isAdmin =
      userPlayerIds.includes(playerId) ||
      clip.J_claimedPlayerList.filter((pId) => userPlayerIds.includes(pId))
        .length > 0;

    const isFollower = userStore.teamFollowerIds
      .concat(userTeamIds)
      .includes(game.DNOR_teamId);

    useEffect(() => {
      // If clip is pinned, scroll to the first instance
      if (ref && ref.current && (isPinnedAndAtTop || !isPinned)) {
        ref.current.scrollIntoView();
        window.scrollBy(
          0,
          document.getElementById('playerheader')
            ? -1 * (document.getElementById('playerheader').clientHeight + 50)
            : 0
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    useEffect(() => {
      if (isAdmin || isFollower) {
        setPublicPublishLevel(10);
      }

      if (clipGodMode || partnerClipViewerEnabled) {
        setPublicPublishLevel(10);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setPublicPublishLevel(props.publicPublishLevel);
    }, [props.publicPublishLevel]);

    const handleShare = () => {
      const base = 'https://sidelinehd.com/';

      let linkPlayerId;
      if (playerId) {
        linkPlayerId = playerId;
      } else {
        if (
          clip.J_teamPlayerList.length > 0 &&
          clip.J_teamPlayerList[0] !== ''
        ) {
          // eslint-disable-next-line prefer-destructuring
          linkPlayerId = clip.J_teamPlayerList[0];
        } else {
          for (let i = 1; i < 5; i += 1) {
            if (
              clip.J_claimedPlayerList.length > i &&
              clip.J_claimedPlayerList[i] !== ''
            ) {
              linkPlayerId = clip.J_claimedPlayerList[i];
              break;
            }

            if (
              clip.J_teamPlayerList.length > i &&
              clip.J_teamPlayerList[i] !== ''
            ) {
              linkPlayerId = clip.J_teamPlayerList[i];
              break;
            }
          }
        }
      }

      const suffix = `${linkPlayerId}/c/${clip.id}?s=1`;
      const link = `${base}${suffix}`;

      if (navigator.share) {
        analytics.track('Player Clip Share Clicked', {
          clipId: clip.id,
          loggedIn: !!userStore.authUser,
          type: 'share via',
          sport,
        });
        navigator
          .share({
            title: `${clip.textDescription}`,
            text: clip.textDescription,
            url: link,
          })
          .catch((respError) => {
            Sentry.captureException(respError);
          });
      }
    };

    const getPlayers = () => {
      axios
        .get(`/api/team/${game.DNOR_teamId}/team_players`)
        .then((response) => {
          const teamPlayerDocs = response.data.players;
          const sortedTeamPlayers = teamPlayerDocs
            .filter((player) => player.isActive)
            .map((player) => {
              const newPlayer = { ...player };
              // for Select component
              newPlayer.value = newPlayer._id;

              if ('name' in player) {
                newPlayer.label = `#${newPlayer.jerseyNum} ${newPlayer.name}`;
                return newPlayer;
              }

              newPlayer.name = `${player.nameFirst} ${player.nameLast}`;
              if (newPlayer.nameFirst === '' && newPlayer.nameLast === '') {
                newPlayer.name = `Player #${newPlayer.jerseyNum}`;
              }
              newPlayer.label = `#${newPlayer.jerseyNum} ${newPlayer.name}`;

              return newPlayer;
            })
            .sort(compareJerseyNum);
          setTeamPlayers(sortedTeamPlayers);

          const existingPlayers = clip.J_teamPlayerList.map((id) => {
            if (!id) {
              return undefined;
            }

            let x;
            sortedTeamPlayers.some((p) => {
              x = p;
              return p._id === id;
            });

            return x;
          });
          setReassignedPlayers(existingPlayers);
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    };

    const renderShareOptions = () => {
      const base = 'https://sidelinehd.com/';

      let linkPlayerId;
      if (playerId) {
        linkPlayerId = playerId;
      } else {
        if (
          clip.J_teamPlayerList.length > 0 &&
          clip.J_teamPlayerList[0] !== ''
        ) {
          // eslint-disable-next-line prefer-destructuring
          linkPlayerId = clip.J_teamPlayerList[0];
        } else {
          for (let i = 1; i < 5; i += 1) {
            if (
              clip.J_claimedPlayerList.length > i &&
              clip.J_claimedPlayerList[i] !== ''
            ) {
              linkPlayerId = clip.J_claimedPlayerList[i];
              break;
            }

            if (
              clip.J_teamPlayerList.length > i &&
              clip.J_teamPlayerList[i] !== ''
            ) {
              linkPlayerId = clip.J_teamPlayerList[i];
              break;
            }
          }
        }
      }

      const suffix = `${linkPlayerId}/c/${clip.id}?s=0`;
      const link = `${base}${suffix}`;

      return (
        <Box pad="medium" gap="small">
          {screen === 'small' && (
            <Button label="Share clip via ..." onClick={handleShare} />
          )}
          <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
            <Button label="Copy link to clip" />
          </CopyToClipboard>
        </Box>
      );
    };

    const getStarIcon = () => {
      if (unpinClip && isPinned) {
        return (
          <Button
            plain
            icon={
              <Star htmlColor="#ffbf00" stroke="#ffbf00" strokeWidth={1.5} />
            }
            label={<Text size="medium">Highlight</Text>}
            hoverIndicator
            onClick={() => {
              unpinClip(clip);
            }}
          />
        );
      }
      if (pinClip && !isPinned) {
        return (
          <Button
            plain
            label={<Text size="medium">Highlight</Text>}
            icon={
              <Star htmlColor="#ffffff00" stroke="#666666" strokeWidth={1.5} />
            }
            hoverIndicator
            onClick={() => {
              // TODO: design this better.
              // kind of a hack to include game within clip here...
              // eslint-disable-next-line no-param-reassign
              clip.game = game;
              pinClip(clip);
              analytics.track('Star Clip', {
                clipId: clip.id,
                sport,
              });
            }}
          />
        );
      }
      if (isPinned) {
        return <Star htmlColor="#ffbf00" />;
      }
      return null;
    };

    const downloadInstructions = (
      <Layer onEsc={() => setShowDownloadInstructions(false)}>
        <Box>
          <Box direction="row" justify="end" fill="horizontal">
            <Button
              hoverIndicator
              icon={<Close />}
              onClick={() => setShowDownloadInstructions(false)}
            />
          </Box>
          <Box margin="small">
            <Text>
              Your video will appear in the download list in the top right
              corner of the screen after you press the down arrow.
            </Text>
            <Image src="/download_clip_help.png" />
          </Box>
        </Box>
      </Layer>
    );

    const unclaimedModal = (
      <Layer onEsc={() => setShowUnclaimedModal(false)}>
        <Box>
          <Box direction="row" justify="end" fill="horizontal">
            <Button
              hoverIndicator
              icon={<Close />}
              onClick={() => setShowUnclaimedModal(false)}
            />
          </Box>
          <Box margin="medium" gap="large">
            <Text>
              This player is not claimed yet. Claim this player to view, share,
              or download highlights
            </Text>
            <Button
              primary
              color="tertiary-0"
              id="claim-this-player"
              size="large"
              label={<Text weight="bold">Claim this player</Text>}
              onClick={() => {
                setShowUnclaimedModal(false);
                onShowClaim();
              }}
            />
          </Box>
        </Box>
      </Layer>
    );

    const onDownloadClicked = () => {
      if (publicPublishLevel < 0) {
        // unclaimed player
        setShowUnclaimedModal(true);
      } else {
        setShowDownloadInstructions(true);
      }
    };

    const showDeleteOption = () => {
      const teamId = game ? game.DNOR_teamId : '';

      return userTeamIds.concat(userStore.coachTeamIds).includes(teamId);
    };

    const showHideOption = () => {
      const playerIds = clip ? clip.J_claimedPlayerList : [];
      return playerIds.filter((pId) => userPlayerIds.includes(pId)).length > 0;
    };

    const showReassignOption = () => {
      if (!userStore.authUser) {
        return false;
      }

      const teamId = game ? game.DNOR_teamId : '';
      const teamIds = userStore.userTeams
        .map((userTeam) => userTeam._id)
        .concat(userStore.teamFollowerIds)
        .concat(userStore.coachTeamIds);

      // return (teamIds.includes(teamId) && !isInactive) || clipGodMode;
      return teamIds.includes(teamId) || clipGodMode;
    };

    const reassignClipClicked = () => {
      getPlayers();
      setShowReassignClip(true);
      setIsMoreOpened(false);
    };

    const reportClipClicked = () => {
      const clipId = clip.id;
      setConversationFields([
        { id: '23299865615255', value: clipId },
        { key: '23299858673815', value: playerId },
      ]);
      setTimeout(() => {
        showWidget();
      }, 10);
    };

    const deleteClipClicked = () => {
      setShowDeleteClip(true);
      setIsMoreOpened(false);
    };

    const hideShowClipClicked = (willHide) => () => {
      const newClip = { ...clip };
      if (willHide) {
        newClip.O_hideFromClaimedPlayerPage.push(playerId);
      } else {
        const index = newClip.O_hideFromClaimedPlayerPage.indexOf(playerId);
        if (index > -1) {
          newClip.O_hideFromClaimedPlayerPage.splice(index, 1);
        }
      }

      // ugh
      newClip.teamId = game.DNOR_teamId;

      axios
        .put('/api/clip', { clip: newClip })
        .then((response) => {
          if (response.data.success) {
            onChange(response.data.clip);
          }
        })
        .catch((e) => Sentry.captureException(e));

      setIsMoreOpened(false);
    };

    const showSROption = () => userPlayer && userPlayer.srUserId;

    const addToSRClicked = () => {
      axios
        .post(`/api/sr/player/${playerId}/add_videos`, {
          clips: [clip],
        })
        .then(() => {
          setToast({
            text: 'Added to your SportsRecruits profile',
          });
        })
        .catch(() => {
          setToast({
            text: 'There was an error adding to your SportsRecruits profile',
            background: 'status-critical',
          });
        });

      setIsMoreOpened(false);
    };

    const renderMenuItems = () => {
      const hidablePlayers = userStore.userPlayers.filter(
        (player) =>
          clip.J_claimedPlayerList.includes(player._id) &&
          player._id === clip.playerId
      );

      return (
        <Box width="medium">
          {showReassignOption() && (
            <Button
              plain
              margin="xsmall"
              label="Tag players"
              onClick={reassignClipClicked}
            />
          )}
          {showSROption() && (
            <Button
              plain
              margin="xsmall"
              label="Add to SportsRecruits"
              onClick={addToSRClicked}
            />
          )}
          <Button
            plain
            margin="xsmall"
            color="error"
            label="Report clip"
            onClick={reportClipClicked}
          />
          {showDeleteOption() && (
            <Button
              plain
              margin="xsmall"
              color="error"
              label="Delete clip"
              onClick={deleteClipClicked}
            />
          )}
          {showHideOption() &&
            hidablePlayers.map((player) => {
              let hideShowText;
              let willHide;
              if (clip.O_hideFromClaimedPlayerPage.includes(player._id)) {
                hideShowText = `Show clip on ${player.nameFirst}'s profile`;
                willHide = false;
              } else {
                hideShowText = `Hide clip from ${player.nameFirst}'s profile`;
                willHide = true;
              }

              return (
                <Button
                  key={player._id}
                  plain
                  margin="xsmall"
                  color="error"
                  label={hideShowText}
                  onClick={hideShowClipClicked(willHide)}
                />
              );
            })}
        </Box>
      );
    };

    if (isUnpinAnimation) {
      return (
        <Box
          margin={{ horizontal: 'auto', vertical: 'large' }}
          gap="small"
          align="center"
        >
          <Skeleton height={30} duration={0.8} />
          <Skeleton duration={0.8} />
        </Box>
      );
    }

    const deleteClip = () => {
      axios
        .delete('/api/clip', { data: clip })
        .then((response) => {
          if (response.data.success) {
            setShowDeleteClip(false);
            onChange(clip.id);
          }
        })
        .catch((e) => Sentry.captureException(e));
    };

    const canDelete = deleteInputValue.toLowerCase() === 'delete';

    //   let tagIndex;
    //   if (clip.J_claimedPlayerList.includes(playerId)) {
    //     tagIndex = clip.J_claimedPlayerList.indexOf(playerId);
    //   } else if (clip.J_teamPlayerList.includes(playerId)) {
    //     tagIndex = clip.J_teamPlayerList.indexOf(playerId);
    //   }

    const clipTitle = `Period ${clip.period} (${clip.teamAScore} - ${clip.teamHScore})`;

    const deleteClipModal = (
      <Layer full onEsc={() => setShowDeleteClip(false)}>
        <Box fill pad="medium" gap="medium">
          <Heading level={3}>Delete this clip</Heading>
          <Card gap="small" background="secondary-7" round="small">
            <Box direction="row" justify="between" pad="medium" gap="medium">
              <Box gap="medium">
                <Text size="large" weight="bold">{`${
                  game ? game.scoreOurName : ''
                }`}</Text>
                <Text size="large" weight="bold">
                  {`vs ${game ? game.J_opponentName : ''}`}
                </Text>
                <Text size="medium">{formatDateShort(clip.clipStartTs)}</Text>
              </Box>
            </Box>
          </Card>
          <Box gap="small" direction="row">
            <Text weight="bold" size="large">
              {clipTitle}
            </Text>
            <Text weight="bold" size="large">
              {`Period ${clip.period}`}
            </Text>
          </Box>
          <Box height="2px" background="dark-5" margin={{ bottom: 'small' }} />
          <Text color="dark-3">Enter DELETE</Text>
          <TextInput
            value={deleteInputValue}
            onChange={(event) => {
              setDeleteInputValue(event.target.value);
            }}
            placeholder="Enter DELETE"
          />
          {canDelete && (
            <Text weight="bold" color="error">
              This is a permanent action and cannot be undone
            </Text>
          )}
          <Box margin={{ top: 'xlarge' }} justify="around" direction="row">
            <Button
              plain
              size="large"
              color="tertiary-0"
              label={<Text weight="bold">Cancel</Text>}
              onClick={() => setShowDeleteClip(false)}
            />
            <Button
              primary
              size="large"
              color="error"
              label={<Text weight="bold">Delete</Text>}
              disabled={!canDelete}
              onClick={() => {
                deleteClip();
              }}
            />
          </Box>
        </Box>
      </Layer>
    );

    const reassignClipModal = () => {
      if (reassignedPlayers.length === 0) {
        return <BasicLoader />;
      }

      const reassignDiv = (
        <GenericReassignClip
          game={game}
          clip={clip}
          teamPlayers={teamPlayers}
          onReassign={(newClip) => {
            setShowReassignClip(false);
            onChange(newClip);
          }}
          actionTitle="actionTitle"
          onClose={() => {
            setShowReassignClip(false);
          }}
          showClose
        />
      );

      if (inModal) {
        // considered not having a double modal and rendering the reassigment modal
        // directly in the video modal. ultimately stuck with double modal
        // return reassignDiv;
        return (
          <Layer
            onClickOutside={() => setShowReassignClip(false)}
            onEsc={() => setShowReassignClip(false)}
            style={{ touchAction: 'none', height: '100dvh' }}
            animation={false}
            full="vertical"
            modal
          >
            {reassignDiv}
          </Layer>
        );
      }
      return (
        <Layer
          onClickOutside={() => setShowReassignClip(false)}
          onEsc={() => setShowReassignClip(false)}
          style={{ touchAction: 'none', height: '100dvh' }}
          animation={false}
          full="vertical"
          modal
        >
          {reassignDiv}
        </Layer>
      );
    };

    return (
      <Card elevation="medium" margin={{ top: 'medium' }}>
        {showDeleteClip && deleteClipModal}
        {showReassignClip && reassignClipModal()}
        <Box ref={ref}>
          <Box
            style={{ display: 'block' }}
            align="start"
            fill="horizontal"
            pad="medium"
          >
            <Box
              direction="row"
              fill="horizontal"
              align="center"
              justify="between"
              pad={{ bottom: 'small' }}
            >
              <Box fill="horizontal" gap="xsmall">
                <Box
                  gap="small"
                  direction="row"
                  fill="horizontal"
                  align="center"
                  justify="between"
                >
                  <Box fill="horizontal">
                    <Box direction="row" align="center" justify="between">
                      <Text size="large" weight="bold">
                        {clipTitle}
                      </Text>
                      {date && <Text size="small">{date}</Text>}
                    </Box>
                    <Text size="medium" weight="bold">
                      {clip.playType
                        ? clip.playType.charAt(0).toUpperCase() +
                          clip.playType.substring(1)
                        : 'Action'}
                    </Text>
                    {clip.game && clip.game.scoreOpponentName && (
                      <Anchor
                        size="large"
                        weight="bold"
                        href={`/game/${game._id}`}
                      >
                        {`${clip.game.scoreWeAreHome ? 'vs' : '@'} ${
                          clip.game.scoreOpponentName
                        }`}
                      </Anchor>
                    )}
                  </Box>
                  <Box direction="row" justify="end" align="center">
                    {getStarIcon()}
                  </Box>
                </Box>
                {isPinnedAndAtTop ? (
                  <Box direction="row" gap="small" fill="horizontal">
                    <Text
                      color="text-xweak"
                      size="small"
                    >{`vs. ${clip.opponentName}`}</Text>
                    <Heading color="text-xweak" level={5} margin="none">
                      &#183;
                    </Heading>
                    <Text color="text-xweak" size="small">
                      {formatDateShort(clip.clipStartTs).split('@')[0].trim()}
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
          <LazyLoad
            once
            placeholder={
              <Box fill="horizontal" style={{ height: '200px' }}>
                <Skeleton height={200} duration={0.8} />
              </Box>
            }
          >
            <ClipVideoPlayer
              videoSrc={clip.fileDownloadUrl}
              showLock={false}
              id={clip.id}
              sport={sport}
            />
          </LazyLoad>
          <Box pad="small">
            <Box direction="row" justify="between">
              {publicPublishLevel > 0 && (
                <DropButton
                  open={isMoreOpened}
                  onOpen={() => setIsMoreOpened(true)}
                  onClose={() => setIsMoreOpened(false)}
                  dropContent={renderMenuItems()}
                >
                  <More />
                </DropButton>
              )}
              <Box
                pad="small"
                direction="row"
                gap="small"
                align="center"
                fill="horizontal"
                justify="end"
              >
                <Button
                  plain
                  color="secondary-1"
                  icon={<DownloadOption size="20px" color="secondary-1" />}
                  label={<Text size="small">Download</Text>}
                  href={clip.fileDownloadUrl}
                  id="download-clip"
                  onClick={onDownloadClicked}
                />
                <ShareDropdown
                  plain
                  label={<Text size="small">Share</Text>}
                  color="secondary-1"
                  renderShareOptions={renderShareOptions}
                />
              </Box>
            </Box>
          </Box>
          {copied && (
            <Toast
              label="Copied to clipboard!"
              background="status-ok"
              duration={3000}
              onClose={() => {
                setCopied(false);
              }}
            />
          )}
        </Box>
        {showDownloadInstructions && isiOS() ? downloadInstructions : null}
        {showUnclaimedModal && unclaimedModal}
      </Card>
    );
  }
);

export default PlayerClip;
