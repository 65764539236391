// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  FormField,
  TextInput,
  Select,
  Box,
  Text,
  Button,
  Layer,
  Image,
  DateInput,
} from 'grommet';
import { postcodeValidator } from 'postcode-validator';
import * as EmailValidator from 'email-validator';
import {
  Close,
  Facebook,
  Hide,
  Instagram,
  StatusInfoSmall,
  Twitter,
  View,
} from 'grommet-icons';
import Slider from '@material-ui/core/Slider';
import { CompactPicker } from 'react-color';
import colleges from '../data/colleges.json';
import {
  ageUOptions,
  schoolAgeLevelOptions,
  seasonOptions,
  embedNameOptions,
  embedImageOptions,
  gradOptions,
  bbsbPositionOptions,
  vbPositionOptions,
  basketballPositionOptions,
  soccerPositionOptions,
  handednessOptions,
  heightOptions,
  coachPositionOptions,
  batBrands,
  mlbTeams,
} from '../constants/teamFormOptions';
import { GROMMETTHEME } from '../constants/grommetTheme';
import SHDButton from '../components/SHD/Button';
import {
  BASEBALL,
  BASKETBALL,
  BBSB_SPORTS,
  GOPRO,
  HOCKEY,
  LACROSSE,
  MEVO,
  MEVO_MULTI,
  MEVO_SINGLE,
  SMARTPHONE,
  SOCCER,
  SOFTBALL,
  VOLLEYBALL,
  WATER_POLO,
} from '../constants/strings';

const FieldNameLong = () => (
  <FormField
    name="nameLong"
    htmlfor="team-name-long-id"
    label="Team name"
    required
    validate={(field) => {
      if (field.length > 30) {
        return {
          message: 'Please enter a name 30 characters or fewer.',
          status: 'error',
        };
      }
      if (field.length < 5) {
        return {
          message: 'Please enter a name 5 characters or more.',
          status: 'error',
        };
      }
      // const regex = /^[a-z0-9./_ '?#!$%&+-]+$/i;
      // if (!regex.test(field)) {
      //   return { message: 'Please remove special characters.', status: 'error' };
      // }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="team-name-long-id"
      name="nameLong"
      placeholder="Tulsa Drillers 18U"
    />
  </FormField>
);

const FieldNameMed = () => (
  <FormField
    name="nameMed"
    htmlfor="team-name-id"
    label="Scoreboard name (18 characters or fewer)"
    required
    validate={(field) => {
      if (field.length > 18) {
        return {
          message: 'Please enter a name 18 characters or fewer.',
          status: 'error',
        };
      }
      if (field.length < 5) {
        return {
          message: 'Please enter a name 5 characters or more.',
          status: 'error',
        };
      }
      // const regex = /^[a-z0-9./_ '?#!$%&+-]+$/i;
      // if (!regex.test(field)) {
      //   return { message: 'Please remove special characters.', status: 'error' };
      // }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="team-name-id"
      name="nameMed"
      placeholder="Drillers"
    />
  </FormField>
);

const FieldNameLocation = ({ isTeam = true }) => (
  <FormField
    name={isTeam ? 'nameLocation' : 'hometown'}
    htmlfor={isTeam ? 'team-location-id' : 'hometown-id'}
    label={isTeam ? 'Team location' : 'Hometown'}
    required={isTeam}
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      if (field.length > 30) {
        return {
          message: 'Please enter a location 30 characters or fewer.',
          status: 'error',
        };
      }
      // const regex = /^[a-z0-9./_ '?#!,$%&+-]+$/i;
      // if (!regex.test(field)) {
      //   return { message: 'Please remove special characters.', status: 'error' };
      // }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id={isTeam ? 'team-location-id' : 'hometown-id'}
      name={isTeam ? 'nameLocation' : 'hometown'}
      placeholder="Tulsa, OK"
    />
  </FormField>
);

const FieldTeamColor = ({ currentColor, label = true }) => {
  const [color, setColor] = useState(currentColor || '#024006');
  const [showPicker, setShowPicker] = useState(false);
  const displayLabel = label ? 'Team Color' : '';

  const closePicker = () => {
    setShowPicker(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const PickerLayer = ({ onChange }) => (
    <Layer onEsc={closePicker}>
      <Box alignSelf="end">
        <SHDButton
          icon={<Close size="medium" />}
          hoverIndicator
          onClick={closePicker}
        />
      </Box>
      <Box align="center" pad="medium">
        <CompactPicker
          color={color}
          onChangeComplete={(x) => {
            setColor(x.hex);
            onChange({ value: x.hex });
          }}
        />
      </Box>
    </Layer>
  );

  return (
    <FormField
      name="colorTeamPrimary"
      htmlfor="color-id"
      label={displayLabel}
      // eslint-disable-next-line react/no-unstable-nested-components
      component={(props) => (
        <>
          <Button
            plain
            alignSelf="start"
            label={color}
            icon={<StatusInfoSmall color={color} />}
            onClick={() => {
              setShowPicker(true);
            }}
          />
          {showPicker && <PickerLayer onChange={props.onChange} />}
        </>
      )}
    />
  );
};

const FieldNameShort = () => (
  <FormField
    name="nameShort"
    htmlfor="team-name-short-id"
    label="Team abbreviation"
    required
    validate={(field) => {
      if (field.length > 5) {
        return {
          message: 'Please enter a name 5 characters or fewer.',
          status: 'error',
        };
      }
      const regex = /^[a-z0-9./_ '?#!$%&+-]+$/i;
      if (!regex.test(field)) {
        return {
          message: 'Please remove special characters.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="team-name-short-id"
      name="nameShort"
      placeholder="TLS"
    />
  </FormField>
);

const FieldNameHandle = ({
  label = 'Team handle',
  placeholder = 'tulsadrillers18u',
  onChange,
}) => (
  <FormField
    name="nameHandle"
    htmlfor="team-name-handle-id"
    label={label}
    required
    validate={(field) => {
      if (field.length > 20) {
        return {
          message: 'Please enter a handle 20 characters or fewer.',
          status: 'error',
        };
      }
      if (field.length < 5) {
        return {
          message: 'Please enter a handle 5 characters or more.',
          status: 'error',
        };
      }
      const regex = /[^a-zA-Z0-9-_]/;
      if (regex.test(field)) {
        return {
          message: 'Please remove special characters.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
    onChange={onChange}
  >
    <Box direction="row" align="center" pad={{ horizontal: 'small' }}>
      <Text color="text-weak">sidelineHD.com/</Text>
      <TextInput
        plain
        autoComplete="nope"
        id="team-name-handle-id"
        name="nameHandle"
        placeholder={placeholder}
      />
    </Box>
  </FormField>
);

const FieldTeamType = () => (
  <FormField name="teamType" htmlfor="team-type-id" label="Team type" required>
    <Select
      id="team-type-id"
      name="teamType"
      placeholder="Select"
      options={['Travel team', 'School team', 'Rec team']}
    />
  </FormField>
);

const FieldTeamTypeGeneric = () => (
  <FormField name="teamType" htmlfor="team-type-id" label="Team type" required>
    <Select
      id="team-type-id"
      name="teamType"
      placeholder="Select"
      options={['Club team', 'School team', 'Rec team']}
    />
  </FormField>
);

const FieldAgeU = () => (
  <FormField name="attrib_ageU" htmlfor="age-u-id" label="Age bracket" required>
    <Select
      id="age-u-id"
      name="attrib_ageU"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={ageUOptions}
    />
  </FormField>
);

const Field13Plus = () => (
  <FormField
    name="isAge13Plus"
    htmlfor="over-13-id"
    label="Are there any players under the age of 13?"
    validate={(field) => {
      if (field || field === false) {
        return { message: null, status: 'info' };
      }
      return { message: 'Required', status: 'error' };
    }}
  >
    <Select
      id="over-13-id"
      name="isAge13Plus"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={[
        { label: 'Yes', value: false },
        { label: 'No', value: true },
      ]}
    />
  </FormField>
);

const FieldAgeLevel = ({ teamType }) => {
  if (teamType === 'rec') {
    return (
      <FormField
        name="attrib_ageLevel"
        htmlfor="age-level-id"
        label="League"
        required
      >
        <Select
          id="age-level-id"
          name="attrib_ageLevel"
          placeholder="Select"
          options={['Adult league', 'Youth league', 'Other league']}
        />
      </FormField>
    );
  }
  if (teamType === 'school') {
    return (
      <FormField
        name="attrib_ageLevel"
        htmlfor="age-level-id"
        label="Level"
        required
      >
        <Select
          id="age-level-id"
          name="attrib_ageLevel"
          placeholder="Select"
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
          options={schoolAgeLevelOptions}
        />
      </FormField>
    );
  }
  return null;
};

const FieldSport = () => {
  const sports = [BASEBALL.display, SOFTBALL.display, VOLLEYBALL.display];
  return (
    <FormField
      name="attrib_sportType"
      htmlfor="sport-id"
      label="Sport"
      required
    >
      <Select
        id="sport-id"
        name="attrib_sportType"
        placeholder="Select"
        options={sports}
      />
    </FormField>
  );
};

const FieldSportV2 = () => {
  // const sports = [
  //   BASEBALL.display,
  //   BASKETBALL.display,
  //   FIELD_HOCKEY.display,
  //   FOOTBALL.display,
  //   HOCKEY.display,
  //   LACROSSE.display,
  //   RUGBY.display,
  //   SOCCER.display,
  //   SOFTBALL.display,
  //   VOLLEYBALL.display,
  //   WATER_POLO.display,
  // ];
  const sports = [
    BASEBALL,
    BASKETBALL,
    HOCKEY,
    LACROSSE,
    SOCCER,
    SOFTBALL,
    VOLLEYBALL,
    WATER_POLO,
  ];
  return (
    <FormField
      name="attrib_sportType"
      htmlfor="sport-id"
      label="Sport"
      required
    >
      <Select
        id="sport-id"
        name="attrib_sportType"
        placeholder="Select"
        options={sports}
        // eslint-disable-next-line react/no-unstable-nested-components
        labelKey={(option) => (
          <Box pad="xsmall" direction="row" align="center" gap="xsmall">
            {option.icon}
            {option.display}
          </Box>
        )}
        valueKey="key"
      >
        {(option) => (
          <Box pad="xsmall" direction="row" align="center" gap="xsmall">
            {option.icon}
            {option.display}
          </Box>
        )}
      </Select>
    </FormField>
  );
};

const FieldSeason = () => (
  <FormField name="attrib_season" htmlfor="season-id" label="Season" required>
    <Select
      id="season-id"
      name="attrib_season"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={seasonOptions}
    />
  </FormField>
);

const FieldZipCode = () => (
  <FormField
    name="zipCode"
    htmlfor="zip-id"
    label="Team zipcode"
    required
    validate={(field) => {
      if (!postcodeValidator(field, 'US')) {
        return { message: 'Please enter a valid US zip code', status: 'error' };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput id="zip-id" name="zipCode" placeholder="Zipcode" />
  </FormField>
);

const FieldEmbedFName = () => (
  <FormField
    name="embedNameFirstLevel"
    htmlfor="embed-fname-id"
    label="Show player first names"
    required
  >
    <Select
      id="embed-fname-id"
      name="embedNameFirstLevel"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={embedNameOptions}
    />
  </FormField>
);

const FieldEmbedLName = ({ team }) => (
  <FormField
    name="embedNameLastLevel"
    htmlfor="embed-fname-id"
    label="Show player last names"
    required
    validate={(field, value) => {
      if (!value.isAge13Plus && field >= 2) {
        return {
          message:
            'You may not display full last names of players under the age of 13.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <Select
      id="embed-lname-id"
      name="embedNameLastLevel"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={
        !team.isAge13Plus
          ? embedNameOptions.filter((level) => level.value < 2)
          : embedNameOptions
      }
    />
  </FormField>
);

const FieldEmbedImageVideo = () => (
  <FormField
    name="isEmbedImageVideo"
    htmlfor="embed-image-video-id"
    label="Show claimed player headshots on the livestream"
    validate={(field) => {
      if (field || field === false) {
        return { message: null, status: 'info' };
      }
      return { message: 'Required', status: 'error' };
    }}
  >
    <Select
      id="embed-image-video-id"
      name="isEmbedImageVideo"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      disabledKey={(option) => option.value}
      options={embedImageOptions}
    />
  </FormField>
);

const FieldEmbedImagePublicWebsite = () => (
  <FormField
    name="isEmbedImagePublicWebsite"
    htmlfor="embed-image-web-id"
    label="Show claimed player headshots on team page"
    validate={(field) => {
      if (field || field === false) {
        return { message: null, status: 'info' };
      }
      return { message: 'Required', status: 'error' };
    }}
  >
    <Select
      id="embed-image-web-id"
      name="isEmbedImagePublicWebsite"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      disabledKey={(option) => option.value}
      options={embedImageOptions}
    />
  </FormField>
);

const FieldNameOnPublicWebsite = () => (
  <FormField
    name="isEmbedImagePublicWebsite"
    htmlfor="embed-image-web-id"
    label="Show player names to the public"
    validate={(field) => {
      if (field || field === false) {
        return { message: null, status: 'info' };
      }
      return { message: 'Required', status: 'error' };
    }}
  >
    <Select
      id="embed-image-web-id"
      name="isEmbedImagePublicWebsite"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      disabledKey={(option) => option.value}
      options={embedImageOptions}
    />
  </FormField>
);

const FieldSMSNotifs = () => (
  <FormField
    name="isNotifyCommunityTeamPlayerUpdates"
    htmlfor="sms-notifs-id"
    label="Send LIVE player highlights via text message"
  >
    <Select
      id="sms-notifs-id"
      name="isNotifyCommunityTeamPlayerUpdates"
      placeholder="Select"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
    />
  </FormField>
);

const FieldTargetTeam = ({ teamList }) => (
  <FormField
    name="targetTeamId"
    htmlfor="taget-team-id"
    label="Points to"
    required
  >
    <Select
      id="taget-team-id"
      name="targetTeamId"
      placeholder="Change team"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={teamList}
    />
  </FormField>
);

const FieldCaptureName = () => (
  <FormField
    name="captureName"
    htmlfor="capture-name-id"
    label="Camera name"
    required
    validate={(field) => {
      if (field.length > 30) {
        return {
          message: 'Please enter a name 20 characters or fewer.',
          status: 'error',
        };
      }
      const regex = /^[a-z0-9./_ '?#!$%&+-]+$/i;
      if (!regex.test(field)) {
        return {
          message: 'Please remove special characters.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput id="capture-name-id" name="captureName" placeholder="Name" />
  </FormField>
);

const FieldCameraType = ({ equipCamera }) => {
  let options;
  if (equipCamera === MEVO) {
    options = [MEVO, MEVO_SINGLE, MEVO_MULTI, GOPRO, SMARTPHONE];
  } else {
    // Don't let users select generic MEVO anymore!
    options = [MEVO_SINGLE, MEVO_MULTI, GOPRO, SMARTPHONE];
  }
  return (
    <FormField
      name="equipCamera"
      htmlFor="equip-camera-id"
      label="Camera type"
      required
    >
      <Select
        id="equip-camera-id"
        name="equipCamera"
        placeholder="Choose camera"
        options={options}
      />
    </FormField>
  );
};

const FieldEmail = ({ label = 'Email address' }) => (
  <FormField
    name="email"
    htmlfor="email-id"
    label={label}
    required
    validate={(field) => {
      if (field.includes(',')) {
        return { message: null, status: 'info' };
      }

      if (!EmailValidator.validate(field)) {
        return { message: 'Please enter a valid email.', status: 'error' };
      }
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(field)) {
        return {
          message: 'Please remove special characters.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput id="email-id" name="email" placeholder="Email" />
  </FormField>
);

const FieldPassword = () => {
  const [reveal, setReveal] = React.useState(false);

  return (
    <FormField
      name="password"
      htmlfor="password-id"
      label="Password"
      required
      validate={(field) => {
        if (!field) {
          return { message: 'Please enter a password.', status: 'error' };
        }
        return { message: null, status: 'info' };
      }}
    >
      <Box width="medium" direction="row" align="center" round="small" border>
        <TextInput
          plain
          type={reveal ? 'text' : 'password'}
          id="password-id"
          name="password"
        />
        <SHDButton
          icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
          mixtrack={[reveal ? 'Hide Password' : 'Show Password']}
          onClick={() => setReveal(!reveal)}
        />
      </Box>
    </FormField>
  );
};

const marks = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 100,
    label: '100',
  },
];

const FieldVeloRange = ({ ignoreVeloUnder, ignoreVeloOver, handleChange }) => (
  <Box>
    <Text size="xsmall" weight="bold">
      Velocity range
    </Text>
    <Box pad={{ horizontal: '8px' }}>
      <Slider
        value={[ignoreVeloUnder, ignoreVeloOver]}
        onChange={handleChange}
        valueLabelDisplay="off"
        aria-labelledby="range-slider"
        marks={marks}
        step={5}
        style={{ color: GROMMETTHEME.global.colors['secondary-1'] }}
        min={20}
        max={100}
      />
    </Box>
  </Box>
);

const FieldNameFirst = (props) => (
  <FormField
    name="nameFirst"
    htmlfor="first-name-id"
    label="First Name"
    required
    validate={(field) => {
      if (!field || !field.length) {
        return { message: 'Please enter a first name.', status: 'error' };
      }
      if (field.length > 30) {
        return {
          message: 'Please enter a name 30 characters or fewer.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      {...props}
      autoComplete="nope"
      id="first-name-id"
      name="nameFirst"
      placeholder="First Name"
    />
  </FormField>
);

const FieldNameLast = ({ required = true, ...props }) => (
  <FormField
    name="nameLast"
    htmlfor="last-name-id"
    label="Last Name"
    required={required}
    validate={(field) => {
      if (!field || !field.length) {
        return { message: 'Please enter a last name.', status: 'error' };
      }
      if (field.length > 30) {
        return {
          message: 'Please enter a name 30 characters or fewer.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      {...props}
      autoComplete="nope"
      id="last-name-id"
      name="nameLast"
      placeholder="Last Name"
    />
  </FormField>
);

const FieldJerseyNum = (props) => (
  <FormField
    {...props}
    name="jerseyNum"
    htmlfor="jersey-num-id"
    label="Jersey Number"
    required
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      if (field.length > 3) {
        return {
          message: 'Please enter a jersey number 3 digits or fewer.',
          status: 'error',
        };
      }
      const regex = /^\d{1,3}$/;
      if (!regex.test(field)) {
        return {
          message: 'Please enter a valid jersey number.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="jersey-num-id"
      name="jerseyNum"
      placeholder="Jersey Number"
    />
  </FormField>
);

const FieldGradYear = (props) => (
  <FormField
    {...props}
    name="yearGraduation"
    htmlfor="grad-id"
    label="Graduation year"
  >
    <Select
      id="grad-id"
      name="yearGraduation"
      placeholder="Choose year"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={gradOptions}
    />
  </FormField>
);

const FieldPlayerPosition = ({
  isPrimary = true,
  sportType = 'Softball',
  ...rest
}) => {
  let label = '';
  let tag = '';
  let attribute = '';
  let positions = [];

  if (isPrimary) {
    label = `${sportType} Primary Position`;
    tag = `${sportType}-pos-primary-id`;
    if (sportType === VOLLEYBALL.display) {
      attribute = 'positionPrimaryVB';
    } else if (sportType === BASKETBALL.display) {
      attribute = 'positionPrimaryBasketball';
    } else if (sportType === SOCCER.display) {
      attribute = 'positionPrimarySoccer';
    } else {
      attribute = 'positionPrimary';
    }
  } else {
    label = `${sportType} Secondary Position`;
    tag = `${sportType}-pos-secondary-id`;
    if (sportType === VOLLEYBALL.display) {
      attribute = 'positionSecondaryVB';
    } else if (sportType === BASKETBALL.display) {
      attribute = 'positionSecondaryBasketball';
    } else if (sportType === SOCCER.display) {
      attribute = 'positionSecondarySoccer';
    } else {
      attribute = 'positionSecondary';
    }
  }

  if (BBSB_SPORTS.includes(sportType)) {
    positions = bbsbPositionOptions;
  } else if (sportType === VOLLEYBALL.display) {
    positions = vbPositionOptions;
  } else if (sportType === BASKETBALL.display) {
    positions = basketballPositionOptions;
  } else if (sportType === SOCCER.display) {
    positions = soccerPositionOptions;
  }

  return (
    <FormField {...rest} name={attribute} htmlfor={tag} label={label}>
      <Select
        id={tag}
        name={attribute}
        placeholder="Choose position"
        labelKey="label"
        valueKey={{ key: 'value', reduce: true }}
        options={positions}
      />
    </FormField>
  );
};

const FieldVBPlayerPosition = ({ isPrimary = true, ...rest }) => (
  <FormField
    {...rest}
    name={isPrimary ? 'positionPrimary' : 'positionSecondary'}
    htmlfor={isPrimary ? 'pos-primary-id' : 'pos-secondary-id'}
    label={isPrimary ? 'Primary position' : 'Secondary position'}
  >
    <Select
      id={isPrimary ? 'pos-primary-id' : 'pos-secondary-id'}
      name={isPrimary ? 'positionPrimary' : 'positionSecondary'}
      placeholder="Choose position"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={vbPositionOptions}
    />
  </FormField>
);

const FieldBBSBHandedness = ({ isBatting = true, ...rest }) => (
  <FormField
    {...rest}
    name={isBatting ? 'handednessBatting' : 'handednessThrowing'}
    htmlfor={isBatting ? 'hand-bat-id' : 'hand-throw-id'}
    label={isBatting ? 'Batting hand' : 'Throwing hand'}
  >
    <Select
      id={isBatting ? 'hand-bat-id' : 'hand-throw-id'}
      name={isBatting ? 'handednessBatting' : 'handednessThrowing'}
      placeholder="Right/Left/Switch"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={handednessOptions}
      clear
    />
  </FormField>
);

const FieldHandedness = ({ ...rest }) => (
  <FormField
    {...rest}
    name="handedness"
    htmlfor="hand-id"
    label="Dominant Hand"
  >
    <Select
      id="hand-id"
      name="handedness"
      placeholder="Right/Left"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={handednessOptions}
      clear
    />
  </FormField>
);

// const FieldHeight = (props) => {
//   console.log('heightOptions', heightOptions);
//   return (
//     <FormField
//       {...props}
//       name="height"
//       htmlfor="height-id"
//       label="Height"
//     >
//       <Select
//         id="height-id"
//         name="height"
//         placeholder="Choose height"
//         labelKey="label"
//         valueKey={{ key: 'value', reduce: true }}
//         options={heightOptions}
//         dropHeight="xlarge"
//         clear
//       />
//     </FormField>
//   );
// };

// const FieldHeight = ({ player, ...props }) => {
//   console.log('props', props, player);
//   let heightFeet = '';
//   let heightInches = '';
//   if (player.height) {
//     heightFeet = player.height.split("'")[0];
//     heightInches = player.height.split("'")[1].replace('"', '');
//   }
//   console.log(heightFeet, heightInches);
//   return (
//     <FormField
//       {...props}
//       // name="height"
//       htmlfor="height-id"
//       label="Height"
//       validate={(field) => {
//         console.log(field);
//         if (!field || !field.length) {
//           return { message: null, status: 'info' };
//         }
//         const regex = /^[1-9]{1}\d{1,2}$/;
//         if (!regex.test(field)) {
//           return { message: 'Please enter a valid weight in pounds.', status: 'error' };
//         }
//         return { message: null, status: 'info' };
//       }}
//     >
//       {/* <Select
//         id="height-id"
//         name="height"
//         placeholder="Choose height"
//         labelKey="label"
//         valueKey={{ key: 'value', reduce: true }}
//         options={heightOptions}
//         dropHeight="xlarge"
//         clear
//       /> */}
//       <Box
//         direction="row"
//         gap="xsmall"
//         align="center"
//         pad={{ horizontal: 'small' }}
//       >
//         <TextInput
//           autoComplete="nope"
//           id="height-feet-id"
//           placeholder="5"
//           // name="heightFeet"
//           value={heightFeet}
//           plain
//         />
//         <Text color="text-weak">ft</Text>
//         <TextInput
//           autoComplete="nope"
//           id="height-inches-id"
//           placeholder="5"
//           // name="heightInches"
//           value={heightInches}
//           plain
//         />
//         <Text color="text-weak">in</Text>
//       </Box>
//     </FormField>
//   );
// };
const FieldHeight = (props) => (
  <FormField
    {...props}
    name="height"
    htmlfor="height-id"
    label="Height"
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      const regex = /^[1-9]{1}\d{1,2}$/;
      if (!regex.test(field)) {
        return {
          message: 'Please enter a valid weight in pounds.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <Select
      id="height-id"
      name="height"
      placeholder="Choose height"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={heightOptions}
      dropHeight="xlarge"
      clear
    />
  </FormField>
);

const FieldWeight = (props) => (
  <FormField
    {...props}
    name="weight"
    htmlfor="weight-id"
    label="Weight"
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      const regex = /^[1-9]{1}\d{1,2}$/;
      if (!regex.test(field)) {
        return {
          message: 'Please enter a valid weight in pounds.',
          status: 'error',
        };
      }
      return { message: null, status: 'info' };
    }}
  >
    <Box direction="row" align="center" pad={{ horizontal: 'small' }}>
      <TextInput
        autoComplete="nope"
        id="weight-id"
        name="weight"
        placeholder="OPTIONAL"
        plain
      />
      <Text color="text-weak">lbs.</Text>
    </Box>
  </FormField>
);

const FieldSAT = (props) => (
  <FormField
    {...props}
    name="sat"
    htmlfor="sat-id"
    label="SAT score"
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      const regex1 = /^[4-9]{1}\d{1}0{1}$/;
      const regex2 = /^1{1}[0-5]{1}\d{1}0{1}$/;
      const regex3 = /^1600$/;
      if (!(regex1.test(field) || regex2.test(field) || regex3.test(field))) {
        return { message: 'Please enter a valid SAT score.', status: 'error' };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="sat-id"
      name="sat"
      placeholder="Enter score"
    />
  </FormField>
);

const FieldACT = (props) => (
  <FormField
    {...props}
    name="act"
    htmlfor="act-id"
    label="ACT score"
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      const regex1 = /^[1-9]{1}\d?$/;
      if (!regex1.test(field)) {
        return { message: 'Please enter a valid ACT score.', status: 'error' };
      }
      if (parseInt(field) > 36) {
        return { message: 'Please enter a valid ACT score.', status: 'error' };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="act-id"
      name="act"
      placeholder="Enter score"
    />
  </FormField>
);

const FieldGPA = (props) => (
  <FormField
    {...props}
    name="gpa"
    htmlfor="gpa-id"
    label="GPA"
    validate={(field) => {
      if (!field || !field.length) {
        return { message: null, status: 'info' };
      }
      const regex1 = /^[0-6]{1}.\d{1,2}$/;
      if (!regex1.test(field)) {
        return { message: 'Please enter a valid GPA.', status: 'error' };
      }
      return { message: null, status: 'info' };
    }}
  >
    <TextInput
      autoComplete="nope"
      id="gpa-id"
      name="gpa"
      placeholder="Enter GPA"
    />
  </FormField>
);

const FieldCoachPosition = (props) => (
  <FormField
    {...props}
    name="coachPosition"
    htmlfor="coach-pos-id"
    label="Coach type"
    required
  >
    <Select
      id="coach-pos-id"
      name="coachPosition"
      placeholder="Choose"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={coachPositionOptions}
      clear
    />
  </FormField>
);

const FieldCommitStatus = (props) => (
  <FormField
    {...props}
    name="collegeCommitStatus"
    htmlfor="commit-status-id"
    label="College commitment status"
  >
    <Select
      id="commit-status-id"
      name="collegeCommitStatus"
      placeholder="Choose status"
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      options={[
        { label: 'Committed', value: 10 },
        { label: 'Uncommitted', value: 5 },
        { label: 'Not interested', value: 0 },
        { label: 'Current collegiate player', value: 20 },
      ]}
      clear
    />
  </FormField>
);

const FieldCollege = (props) => {
  const dedupeColleges = Array.from(new Set(colleges)).sort();
  const [options, setOptions] = useState(dedupeColleges);

  return (
    <FormField
      {...props}
      name="collegeCommitName"
      htmlfor="commited-college-id"
      label="College"
      required
    >
      <Select
        name="collegeCommitName"
        id="commited-college-id"
        placeholder="Choose college"
        options={options}
        dropHeight="small"
        onClose={() => setOptions(dedupeColleges)}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setOptions(dedupeColleges.filter((o) => exp.test(o)));
        }}
      />
    </FormField>
  );
};

const FieldOpponentName = ({ name = 'opponent', ...props }) => (
  <FormField
    name={name}
    htmlfor="opponent-id"
    label="Opponent Name"
    validate={(field) => {
      if (!field) {
        return 'Please provide an opponent';
      }
      if (field.length > 20) {
        return 'Please enter a team name with fewer than 21 characters.';
      }
      return { message: null, status: 'info' };
    }}
    {...props}
  >
    <TextInput placeholder="Opponent team name" name={name} id="opponent-id" />
  </FormField>
);

const FieldDate = (props) => {
  return (
    <FormField
      name="date"
      htmlfor="date-id"
      label="Game Date"
      // validate={(field) => {
      //   const inputtedDate = new Date(field);
      //   const date = new Date().toJSON().slice(0, 10);
      //   const time = new Date(0).toJSON().slice(10, 25);
      //   const now = date + time;
      //   if (!field) {
      //     return 'Select a date';
      //   }
      //   if (inputtedDate < now) {
      //     return 'Please select a date in the future.';
      //   }
      //   return { message: null, status: 'info' };
      // }}
      // {...props}
      required={props.required}
    >
      <DateInput
        name="date"
        id="date-id"
        format="mm/dd/yyyy"
        value={props.defaultDate}
      />
    </FormField>
  );
};

const FieldTime = (props) => (
  <FormField name="time" htmlfor="time-id" label="Game time" {...props}>
    <TextInput name="time" id="time-id" type="time" value={props.time} />
  </FormField>
);

const FieldGameTitle = ({ name = 'title', ...props }) => (
  <FormField
    name={name}
    htmlfor="title-id"
    label="Game title (optional)"
    validate={(field) => {
      if (!field) {
        return { message: null, status: 'info' };
      }
      if (field.length > 60) {
        return 'Please input a title with 60 or fewer characters';
      }
      if (field.length < 4) {
        return 'Please input a title with 4 or more characters';
      }
      return { message: null, status: 'info' };
    }}
    {...props}
  >
    <TextInput
      placeholder="Memorial Day Classic - Semifinal"
      name="title"
      id="title-id"
    />
  </FormField>
);

const FieldSocialHandle = ({
  socialType,
  placeholder = 'tulsadrillers18u',
  onChange,
  showLabel = true,
  ...props
}) => {
  let icon;
  let label;
  let urlBase;
  let name;
  if (socialType === 'facebook') {
    icon = <Facebook />;
    label = 'Facebook URL';
    urlBase = 'facebook.com/';
    name = 'socialFacebookUrl';
  } else if (socialType === 'instagram') {
    icon = <Instagram />;
    label = 'Instagram';
    urlBase = 'instagram.com/';
    name = 'socialInstagramHandle';
  } else if (socialType === 'twitter') {
    icon = <Twitter />;
    label = 'Twitter';
    urlBase = 'twitter.com/';
    name = 'socialTwitterHandle';
  } else if (socialType === 'tiktok') {
    // icon = <Tiktok />;
    label = 'TikTok';
    urlBase = 'tiktok.com/@';
    name = 'socialTiktokHandle';
  } else {
    icon = <Instagram />;
    label = 'Instagram';
    urlBase = 'instagram.com/';
    name = 'socialInstagramHandle';
  }

  return (
    <FormField
      name={name}
      htmlfor="team-social-handle-id"
      label={showLabel && label}
      validate={() => ({ message: null, status: 'info' })}
      onChange={onChange}
    >
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: 'small' }}
        gap="small"
      >
        {icon}
        <Text color="text-weak">{urlBase}</Text>
        <TextInput
          plain
          autoComplete="nope"
          id="team-social-handle-id"
          name={name}
          placeholder={placeholder}
          {...props}
        />
      </Box>
    </FormField>
  );
};

const FieldHero = (props) => {
  const defaultHero = {
    baseball: 'Babe Ruth',
    softball: 'Jennie Finch',
    basketball: 'Michael Jordan',
    football: 'Tom Brady',
    soccer: 'Lionel Messi',
    volleyball: 'Kerri Walsh Jennings',
    lacrosse: 'Paul Rabil',
    hockey: 'Wayne Gretzky',
  };

  let playerHero = {};
  if (props.player.playerHero) {
    playerHero = props.player.playerHero;
  }

  const [hero, setHero] = useState(playerHero[props.sportType] || '');

  return (
    <FormField
      name={`${props.sportType}-hero`}
      htmlfor={`${props.sportType}-hero-id`}
      label={`${props.sportDisplay} Hero`}
      validate={(field) => {
        if (!field || !field.length) {
          return { message: null, status: 'info' };
        }
        if (field.length > 50) {
          return {
            message: 'Please enter a name with 50 characters or fewer.',
            status: 'error',
          };
        }
        return { message: null, status: 'info' };
      }}
    >
      <TextInput
        autoComplete="nope"
        id={`${props.sportType}-hero-id`}
        name={`${props.sportType}-hero`}
        placeholder={defaultHero[props.sportType] || 'Enter a name'}
        value={hero}
        onChange={(event) => {
          setHero(event.target.value);
        }}
      />
    </FormField>
  );
};

const FieldBat = (props) => {
  const [options, setOptions] = useState(batBrands);
  const [value, setValue] = useState(
    props.player.equipment ? props.player.equipment.bat : ''
  );

  return (
    <FormField name="bat" htmlfor="bat-id" label="Bat">
      <Select
        name="bat"
        placeholder="Select Bat Brand"
        value={value}
        options={options}
        onChange={({ option }) => setValue(option)}
        onClose={() => setOptions(options)}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setOptions(batBrands.filter((o) => exp.test(o)));
        }}
        clear={{
          position: 'top',
          label: 'Remove Bat Brand',
        }}
        {...props}
      />
    </FormField>
  );
};

const FieldMLBTeam = (props) => {
  const [teamOptions, setTeamOptions] = useState(mlbTeams);
  const [value, setValue] = useState(
    props.player.favoriteTeams ? props.player.favoriteTeams.baseball : null
  );

  return (
    <FormField
      name="favoriteMLBTeam"
      htmlfor="favorite-mlb-team-id"
      label="Favorite MLB Team"
    >
      <Select
        name="favoriteMLBTeam"
        placeholder="Favorite MLB Team"
        value={
          value ? (
            <Box direction="row" gap="xsmall" pad="xsmall" align="center">
              <Box height="24px">
                <Image fit="contain" src={`/mlb/logos/team_${value.id}.svg`} />
              </Box>
              <Text>{value.name}</Text>
            </Box>
          ) : null
        }
        options={teamOptions}
        onChange={({ option }) => {
          setValue(option);
        }}
        onClose={() => setTeamOptions(teamOptions)}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setTeamOptions(mlbTeams.filter((o) => exp.test(o.name)));
        }}
        clear={{
          position: 'top',
          label: 'Remove Favorite MLB Team',
        }}
        {...props}
      >
        {(option) => (
          <Box direction="row" gap="xsmall" pad="xsmall" align="center">
            <Box height="24px">
              <Image
                fit="contain"
                src={`https://midfield.mlbstatic.com/v1/team/${option.id}/spots`}
              />
            </Box>
            <Text>{option.name}</Text>
          </Box>
        )}
      </Select>
    </FormField>
  );
};

export {
  FieldNameMed,
  FieldNameLong,
  FieldNameLocation,
  FieldTeamColor,
  FieldNameShort,
  FieldNameHandle,
  FieldTeamType,
  FieldTeamTypeGeneric,
  FieldAgeU,
  Field13Plus,
  FieldAgeLevel,
  FieldSport,
  FieldSportV2,
  FieldSeason,
  FieldZipCode,
  FieldEmbedFName,
  FieldEmbedLName,
  FieldEmbedImageVideo,
  FieldEmbedImagePublicWebsite,
  FieldTargetTeam,
  FieldCaptureName,
  FieldCameraType,
  FieldEmail,
  FieldPassword,
  FieldVeloRange,
  FieldNameFirst,
  FieldNameLast,
  FieldJerseyNum,
  FieldSMSNotifs,
  FieldNameOnPublicWebsite,
  FieldGradYear,
  FieldPlayerPosition,
  FieldHandedness,
  FieldBBSBHandedness,
  FieldHeight,
  FieldWeight,
  FieldSAT,
  FieldACT,
  FieldGPA,
  FieldCoachPosition,
  FieldCommitStatus,
  FieldCollege,
  FieldOpponentName,
  FieldDate,
  FieldTime,
  FieldGameTitle,
  FieldSocialHandle,
  FieldVBPlayerPosition,
  FieldHero,
  FieldBat,
  FieldMLBTeam,
};
