// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { Select } from 'grommet';

const CustomSelect = ({ options, onSelectedOption, ...props }) => {
  const [selectedOption, setSelectedOption] = useState();

  const handleChange = ({ value: nextValue }) => {
    setSelectedOption(nextValue);
    onSelectedOption(nextValue);
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      labelKey="label"
      valueKey={{ key: 'value', reduce: true }}
      onChange={handleChange}
      {...props}
    />
  );
};

export default CustomSelect;
