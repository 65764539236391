// Packages
import React from 'react';
import styles from '../Admin.module.css';

// Components
import { Box, Heading, Text } from 'grommet';
import { LinkPrevious } from 'grommet-icons';

// Types
import { AdminHeaderProps } from '../admin.types';

const AdminHeader: React.FC<AdminHeaderProps> = ({
  body,
  id,
  title,
  icon,
  toolName,
}) => {
  const placeIcon = () => {
    if (!icon) {
      return null;
    }
    switch (icon) {
      case 'LinkPrevious':
        return <LinkPrevious className={styles['icon']} />;
      default:
        return;
    }
  };

  return (
    <Box id={id} className={styles['admin-header']}>
      <Heading className={styles['tool']} color="dark-1" level="1">
        {toolName}
      </Heading>
      <Heading className={styles['step']} color="dark-1" level="1">
        {placeIcon()}
        {title}
      </Heading>
      {body && <Text>{body}</Text>}
    </Box>
  );
};

export default AdminHeader;
