import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Text, Heading, Image } from 'grommet';
import {
  withRouter,
  useLocation,
  useParams,
  RouteComponentProps,
} from 'react-router-dom';
import { compose } from 'recompose';
import withLayout from '../../helpers/withLayout';
import {
  AuthorizeYoutubeStateName,
  isTerminalState,
  useAuthorizeYoutube,
} from './useAuthorizeYoutube';
import { errorUtil, miscUtil } from '@shd/jslib/infra';
import SHDButton from '../../components/SHD/Button';
import { Connect, Youtube } from 'grommet-icons';
import { SIDELINE_APP_URLS } from '../../constants/strings';

const statusLabels: Record<AuthorizeYoutubeStateName, string> = {
  initial: 'initial',
  'missing-teamid': 'Error',
  'missing-auth-status-key': 'Error',
  'error-fetching-auth-token': 'Error: Unable to validate application identity',
  'fetching-auth': 'In progress...',
  'firebase-signin': 'Signing in...',
  'youtube-connection-phase': 'Connecting to YouTube...',
  'authorization-refused': 'Authorization refused',
  'firebase-signin-error': 'Error signing in',
  'connection-complete': 'Success!',
  'connection-failed': 'YouTube connection failed',
  'operation-cancelled': 'Operation cancelled',
};

interface Props extends RouteComponentProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AuthorizeYoutubeTeam: React.FC<Props> = observer((_props) => {
  const { teamId } = useParams<{ teamId: string }>();
  const query = new URLSearchParams(useLocation().search);
  const statusKey = query.get('statusKey');

  const [showConnectButton, setShowConnectButton] = useState(false);

  useEffect(() => {
    window.gapi.load('auth2', () => {
      setShowConnectButton(true);
    });
  }, []);

  const hook = useAuthorizeYoutube(teamId, statusKey);
  return (
    <Box margin="medium">
      <Heading color="dark-1" level="3">
        {statusLabels[hook.overallState.name]}
      </Heading>
      <Text>
        {miscUtil.coverEveryCase(hook.overallState.name, {
          initial: '',
          'missing-teamid': 'Missing team ID',
          'missing-auth-status-key': 'Cannot authenticate without a status key',
          'error-fetching-auth-token': `${errorUtil.errorMessage(
            hook.overallState.name === 'error-fetching-auth-token'
              ? hook.overallState.error
              : undefined
          )}`,
          'fetching-auth': 'Validating application identity...',
          'firebase-signin': 'Signing in...',
          'youtube-connection-phase': '',
          'authorization-refused': `${
            hook.overallState.name === 'authorization-refused'
              ? hook.overallState.authResponse.status
              : ''
          }`,
          'firebase-signin-error': `${errorUtil.errorMessage(
            hook.overallState.name === 'firebase-signin-error'
              ? hook.overallState.error
              : undefined
          )}`,
          'connection-complete': 'Your YouTube account is now linked.',
          'connection-failed': `${hook.youtubeConnection.status?.error?.message}`,
          'operation-cancelled': undefined,
        })}
      </Text>
      {!isTerminalState(hook.overallState) && showConnectButton && (
        <Box gap="small">
          <Box
            margin={{ horizontal: 'small', bottom: 'medium' }}
            overflow="scroll"
            gap="small"
          >
            <Box
              direction="row"
              justify="center"
              gap="small"
              align="center"
              margin={{ bottom: 'medium' }}
            >
              <Box width="44px">
                <Image src="/logo512.png" fit="contain" />
              </Box>
              <Connect size="28px" />
              <Youtube size="44px" color="#FF0000" />
            </Box>
            <Text>Stream to your YouTube channel.</Text>
            <Text>
              You will be redirected to YouTube to login when you click the
              button below.
            </Text>
          </Box>
          <Box direction="row" align="center">
            <SHDButton
              primary
              fill="horizontal"
              color="light-2"
              icon={<Youtube color="#FF0000" />}
              label="Link YouTube"
              onClick={() => hook.youtubeConnection.signIn()}
            />
          </Box>
        </Box>
      )}
      {isTerminalState(hook.overallState) && (
        <SHDButton
          onClick={() => {
            if (hook.overallState.name === 'connection-complete') {
              window.location.href = appUrl(teamId, 'success');
            } else {
              window.location.href = appUrl(teamId, 'failure');
            }
          }}
          mixtrack={['Back to app', { teamId }]}
          label={`Back to app`}
          size="large"
          primary
        />
      )}
    </Box>
  );
});

const appUrl = (teamId: string, result: 'success' | 'failure') =>
  `${SIDELINE_APP_URLS.youtubeconnectCustomScheme}/${teamId}/${result}`;

export default compose<Props, Omit<Props, keyof RouteComponentProps>>(
  withLayout,
  withRouter
)(AuthorizeYoutubeTeam);
