import React from 'react';
import { Box, Text } from 'grommet';

interface EditPlayerTooltipContentProps {
  isMonetizationEnabled: boolean;
}

const EditPlayerTooltipContent: React.FC<EditPlayerTooltipContentProps> = ({
  isMonetizationEnabled,
}) => {
  return (
    <Box
      style={{
        width: '300px',
        padding: '10px',
        backgroundColor: 'white',
        border: '1px solid #e5e5e5',
        borderRadius: '5px',
      }}
    >
      <Text margin={{ bottom: '1rem' }}>
        Add up to 5 phone numbers to receive in-game and post-game highlight
        texts of your player!
      </Text>
      {isMonetizationEnabled && (
        <Text>
          Additional phone numbers will receive texts for baseball/softball
          clips only if the player has at least one subscribed manager.
        </Text>
      )}
    </Box>
  );
};

export default EditPlayerTooltipContent;
