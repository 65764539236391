import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Text } from 'grommet';
import { formatDateShort } from '../../helpers/utils';
import { FormDown, FormUp } from 'grommet-icons';
import axios from '../../helpers/axios';
import PlayerClip from './PlayerClip';
import { ClipFeedClip, PlayerGameClipsContainerProps } from './player.types';
import { withRouter } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

const PlayerGameClipsFeed: React.FC<PlayerGameClipsContainerProps> = ({
  playerId,
  game,
  role,
  isAdmin,
  pinnedClips,
  pinClip,
  unpinClip,
  onShowClaim,
  showClips: initialShowClips = false,
  history,
}) => {
  const activeClipRef = useRef<HTMLDivElement>(null);
  const firstClipRef = useRef<HTMLDivElement>(null);

  const clipId = '';
  const publicPublishLevel = 10;

  const [showClips, setShowClips] = useState(initialShowClips);
  const [loadingClips, setLoadingClips] = useState(false);
  const [clips, setClips] = useState<ClipFeedClip[]>([]);

  const [localPinnedClips, setLocalPinnedClips] = useState<ClipFeedClip[]>([]);

  useEffect(() => {
    setLocalPinnedClips(pinnedClips);
  }, [pinnedClips]);

  useEffect(() => {
    const fetchClips = async () => {
      if (clips.length === 0) {
        try {
          setLoadingClips(true);
          const response = await axios.get(
            `/api/player/${playerId}/game/${game.id}/clips`
          );
          const filteredClips = response.data.clips.filter(
            (clip: ClipFeedClip) => clip.playerRole === role
          );
          setClips(filteredClips);
          setLoadingClips(false);
        } catch (error) {
          setLoadingClips(false);
        }
      }
    };

    if (showClips) {
      fetchClips();
    }
  }, [showClips, playerId, game.id, clips.length, role]);

  useEffect(() => {
    if (showClips && clips.length > 0 && firstClipRef.current) {
      firstClipRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [showClips, clips]);

  const getRef = (clip: ClipFeedClip) => {
    return clipId === clip.refFull || clipId === clip.refPreview
      ? activeClipRef
      : null;
  };

  const icon = showClips ? (
    <FormUp size="small" color="secondary-1" />
  ) : (
    <FormDown size="small" color="secondary-1" />
  );

  return (
    <Box id={`${game.id}-${role}`}>
      <Card
        key={game.id}
        gap="medium"
        background="secondary-7"
        round="small"
        onClick={() => {
          setShowClips(!showClips);
        }}
      >
        <Box direction="row" justify="between" pad="medium" gap="medium">
          <Box gap="medium">
            <Box direction="row" gap="medium" align="center">
              <Text
                size="large"
                weight="bold"
              >{`vs ${game.scoreOpponentName}`}</Text>
            </Box>
            <Text size="medium">{formatDateShort(game.scoreTsStart)}</Text>
          </Box>
          <Box gap="small" alignSelf="end">
            <Button
              primary
              color="primary-1"
              label="Play-by-Play"
              onClick={() => {
                history.push(`/game/${game.id}`);
              }}
            />
            <Button
              label="My Clips"
              icon={icon}
              reverse
              onClick={() => {
                setShowClips(!showClips);
              }}
            />
          </Box>
        </Box>
      </Card>
      {showClips && (
        <Box fill="horizontal">
          {loadingClips && (
            <Box justify="center" align="center">
              <MoonLoader size={30} color="#024006" />
            </Box>
          )}
          {clips.map((clip, i) => (
            <PlayerClip
              key={clip.id}
              game={game}
              clip={clip}
              publicPublishLevel={
                clip.J_claimedPlayerList.length > 0 || i === 0
                  ? publicPublishLevel
                  : -1
              }
              ref={i === 0 ? firstClipRef : getRef(clip)}
              playerId={playerId}
              pinClip={isAdmin ? pinClip : null}
              unpinClip={isAdmin ? unpinClip : null}
              isPinned={
                !!(
                  localPinnedClips.map((c) => c.id).includes(clip.refPreview) ||
                  localPinnedClips.map((c) => c.id).includes(clip.refFull)
                )
              }
              onShowClaim={onShowClaim}
              hideBottomBar={publicPublishLevel < 10}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default withRouter(PlayerGameClipsFeed);
