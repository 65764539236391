// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BetaInfoPage from './BetaInfoPage';
import StreamingInfoPage from './StreamingInfoPage';

const staticRedirects = [
  /** ******************  EXTERNAL SITES / RESOURCES  ******************* */
  // { type: 302, source: '/', destination: 'https://home.sidelinehd.com/' },
  {
    type: 302,
    source: '/yt',
    destination:
      'https://www.youtube.com/channel/UCKDbP5mxZdgocKfY0O8mGxw/videos',
  },
  {
    type: 302,
    source: '/insta',
    destination: 'https://www.instagram.com/sidelinehd/',
  },
  {
    type: 302,
    source: '/fb',
    destination: 'https://www.facebook.com/sidelineHD',
  },
  { type: 302, source: '/twitch', destination: 'https://twitch.tv/sidelineHD' },
  {
    type: 302,
    source: '/baseballirl',
    destination: 'https://twitch.tv/baseball_irl',
  },
  {
    type: 302,
    source: '/softballirl',
    destination: 'https://twitch.tv/fastpitch_irl',
  },
  {
    type: 302,
    source: '/fastpitchirl',
    destination: 'https://twitch.tv/fastpitch_irl',
  },

  {
    type: 302,
    source: '/standup',
    destination: 'https://meet.google.com/kst-hsmb-yfj',
  },
  {
    type: 302,
    source: '/dugout',
    destination: 'https://meet.google.com/iqd-obni-ivt',
  },
  {
    type: 302,
    source: '/centerfield',
    destination: 'https://meet.google.com/jpk-updn-yqy',
  },
  {
    type: 302,
    source: '/ownersbox',
    destination: 'https://meet.google.com/cye-ztjv-jhq',
  },

  /** ******************  DOCUMENTATION  ******************* */
  {
    type: 302,
    source: '/doc/info',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vSa5a73fqnf-9RoWNDKK-pjcn-GI8pc16RUajJXM0Q_c518LGCMzX04tdRZnox9nV8omHKBt5-oCGhL/pub',
  },
  {
    type: 302,
    source: '/doc/mobilemevo',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vRUV_Np3R3oIxgtWtZUUUh4wvdf8SO_j3bMOUsS6-GJvXxZvjMXOGPMumI6KKuIR5_BgNuHsvs6f_9H/pub',
  },
  {
    type: 302,
    source: '/doc/basic',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vSBwR4Y3N6O6weUGjxbzG-1CpZI8WIY_qQGV6TzfXoylL2-1nTCZIEIZa4YZnEbl0-IcNgdqUQ48Evs/pub',
  },
  {
    type: 302,
    source: '/doc/equip',
    destination:
      'https://docs.google.com/spreadsheets/d/15N_xtL_lKV8cs0KJb-BX1DKu7B3Ppig9LYI9eRTszlA/edit#gid=0',
  },
  {
    type: 302,
    source: '/doc/clip',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vRSOfOnejyhXC9CsSU3UNN-OA9kcIll6fiBvlOm-MqA-yQhx5b5BuYPH40QegxkNE83T7GJmFsTT91c/pub',
  },

  /** cameras * */
  {
    type: 302,
    source: '/doc/mevo',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vQqPXHRHzoVPVLYF6Wh46NeOg_4q1Zls1GCyaqUPnWWIXtDbPe2WC14ulUotdWqCIeTypIMFEy9SDxB/pub',
  },
  {
    type: 302,
    source: '/doc/gocoder',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vQWfumGOLXfkL7ValjWUw7p5lCEPnICpvk4bsgMJmW-_Q8BfqaVtbeeDWIGcbpObnG8x_5tKxh1A0K9/pub',
  },
  {
    type: 302,
    source: '/doc/larix',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vSs8A7ELSN1IuL-mUayHCFtu87VwjbBysB480UqqDZgjvG_CIBnAPOwNWqvRUU2CTX-YX8xN7z1isna/pub',
  },
  {
    type: 302,
    source: '/doc/gopro',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vSlxwdi3QKK9YpcVydxFxbjWtPDHSn4TO91DXIzz9BQlvwxA7YiROCBdBRspGKgHao-W4uxwiCJBO1D/pub',
  },

  /** data sources * */
  {
    type: 302,
    source: '/doc/pradar',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vR-hIT9QsmbpJfUFwR7uvuLTaP8-pHbyJtKpOmsmkJXLKl3zOfIgPayof9tbhxUrXbRnhvJWKnSj9vE/pub',
  },
  {
    type: 302,
    source: '/doc/iscore',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vQ62vufvss93C1QZyyW43dx7PutbT4unJ_dwkNZjxm419UjuFk8MrRjTUJ75LMtzP0e2mFc-sFaPZ3u/pub',
  },

  /** resources * */
  {
    type: 302,
    source: '/doc/pro',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vSch8B4PE5-1tgM6SCKakA_zpEs7K9za3pUCwBNHAu6KY12-B-xHQ_rBozm1r12TGCdIrD8xoxfo5up/pub',
  },
  {
    type: 302,
    source: '/doc/restream',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vT-Dz5lBgJwh2clmeluzz3sOH37jUstPE3LzLFRftX1j2RySZ_RGWTJ9YxuOPSgjdzMCm1_d6hSXjVu/pub',
  },
  {
    type: 302,
    source: '/doc/yt',
    destination:
      'https://docs.google.com/document/d/e/2PACX-1vRCmuRlwEu9HcJb4MbWTb7z7SFsC7uVdMmQkzMvENcXG747m-IMwzhT-1gDWHG3duYeZv2RR4wjeI-L/pub',
  },

  /** ******************  FORMS  ******************* */
  { type: 302, source: '/doc/beta', destination: '/get-started' },
  { type: 302, source: '/refer/sarahc', destination: '/doc/info' },

  /** ******************  LANDING PAGES ******************* */
  {
    type: 302,
    source: '/hitscope',
    destination: 'https://home.sidelinehd.com/hitscope',
  },
  {
    type: 302,
    source: '/iscore',
    destination: 'https://home.sidelinehd.com/iscore',
  },
  {
    type: 302,
    source: '/gamechanger',
    destination:
      'https://home.sidelinehd.com/why-choose-sidelinehd-over-gamechanger/',
  },
  { type: 302, source: '/survey', destination: 'https://home.sidelinehd.com/' },
  {
    type: 302,
    source: '/volleyball',
    destination: 'https://home.sidelinehd.com/volleyball',
  },
  {
    type: 302,
    source: '/vb',
    destination: 'https://home.sidelinehd.com/volleyball',
  },
  {
    type: 302,
    source: '/soccer',
    destination: 'https://home.sidelinehd.com/soccer',
  },
  {
    type: 302,
    source: '/basketball',
    destination: 'https://home.sidelinehd.com/basketball',
  },
  {
    type: 302,
    source: '/hockey',
    destination: 'https://home.sidelinehd.com/hockey',
  },
  {
    type: 302,
    source: '/waterpolo',
    destination: 'https://home.sidelinehd.com/waterpolo',
  },
  {
    type: 302,
    source: '/lacrosse',
    destination: 'https://home.sidelinehd.com/lacrosse',
  },
  {
    type: 302,
    source: '/softball',
    destination: 'https://home.sidelinehd.com/softball',
  },
  {
    type: 302,
    source: '/baseball',
    destination: 'https://home.sidelinehd.com/baseball',
  },
  { type: 302, source: '/gc', destination: 'https://home.sidelinehd.com/gc' },

  {
    type: 302,
    source: '/comparison',
    destination: 'https://home.sidelinehd.com/comparison',
  },

  /** ******************  SHORTENED  ******************* */
  { type: 302, source: '/info', destination: '/doc/info' },
  { type: 302, source: '/clip', destination: '/doc/clip' },
  { type: 302, source: '/beta', destination: '/get-started' },
  {
    type: 302,
    source: '/streamwarn',
    destination: 'http://help.sidelinehd.com/a/solutions/articles/69000690360',
  },
  // { type: 302, source: '/streamcompat', destination: 'https://help.sidelinehd.com//a/solutions/articles/69000772079' },
  {
    type: 302,
    source: '/fbStreamError',
    destination: 'https://help.sidelinehd.com/a/solutions/articles/69000802163',
  },
];

const youtubeRedirects = [
  {
    type: 302,
    source: '/s/:fa',
    destination: 'https://www.youtube.com/results?search_query=%23:fa',
  },
  {
    type: 302,
    source: '/s/:fa/:fb',
    destination: 'https://www.youtube.com/results?search_query=%23:fa +%23:fb',
  },
  {
    type: 302,
    source: '/s/:fa/:fb/:fc',
    destination:
      'https://www.youtube.com/results?search_query=%23:fa +%23:fb +%23:fc',
  },
];

const StaticRedirects = () => (
  <Switch>
    {staticRedirects.map((redirect) => (
      <Route
        key={redirect.source}
        exact
        path={redirect.source}
        component={() => {
          window.location.href = redirect.destination;
          return null;
        }}
      />
    ))}
  </Switch>
);

const YouTubeRedirect = () => (
  <Switch>
    {youtubeRedirects.map((redirect) => (
      <Route
        key={redirect.source}
        exact
        path={redirect.source}
        component={(props) => {
          const url = `https://www.youtube.com/hashtag/${props.match.params.fa}`;
          window.location.href = url;
          return null;
        }}
      />
    ))}
  </Switch>
);

export default StaticRedirects;

export { BetaInfoPage, StreamingInfoPage, YouTubeRedirect };
