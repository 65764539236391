import React, { useState, useCallback, useEffect } from 'react';
import { Box, FormField, Select, TextInput, Text, Button, Form } from 'grommet';
import axios from '../../../helpers/axios';
import {
  ageUOptions,
  seasonOptions,
  teamTypeOptions,
  leagueTypeOptions,
  schoolAgeLevelOptions,
} from '../../../constants/teamFormOptions';
import { FieldTeamColor } from '../../../helpers/FormFields';
import { fetchPayload } from './teamInformation.helpers';

import {
  validateHandle,
  validateScoreBoardName,
  validateTeamName,
  validatePostalCode,
} from './teamInformation.validation';
import {
  TeamInfoFormProps,
  defaultFormValues,
  FormValues,
  TeamType,
} from './teamInformation.types';

import { BBSB_SPORTS } from '../../../constants/strings';

const TeamInfoForm: React.FC<TeamInfoFormProps> = ({
  selectedTeam,
  onFormUpdate,
}) => {
  const [value, setValue] = useState<FormValues>(defaultFormValues);
  const [currentValues, setCurrentValues] =
    useState<FormValues>(defaultFormValues);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [ageLevelOptions, setAgeLevelOptions] = useState(schoolAgeLevelOptions);
  const [ageLevelLabel, setAgeLevelLabel] = useState<string>('Level');
  const [teamTypeList, setTeamTypeList] = useState(teamTypeOptions);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTeam) {
      let tl = teamTypeOptions.filter((t) => t.value !== TeamType.TRAVEL);
      if (BBSB_SPORTS.includes(selectedTeam.attrib_sportType)) {
        tl = teamTypeOptions.filter((t) => t.value !== TeamType.CLUB);
      }
      setTeamTypeList(tl);
      handleTeamTypeChange(selectedTeam.teamType);

      setValue({
        nameLong: selectedTeam.nameLong,
        nameHandle: selectedTeam.nameHandle,
        nameMed: selectedTeam.nameMed,
        attrib_sportType: selectedTeam.attrib_sportType,
        attrib_ageU: selectedTeam.attrib_ageU,
        attrib_season: selectedTeam.attrib_season,
        attrib_ageLevel: selectedTeam.attrib_ageLevel,
        zipCode: selectedTeam.zipCode,
        socialFacebookUrl: selectedTeam.socialFacebookUrl,
        colorTeamPrimary: selectedTeam.colorTeamPrimary,
        teamType: selectedTeam.teamType,
      });
      setCurrentValues({
        nameLong: selectedTeam.nameLong,
        nameHandle: selectedTeam.nameHandle,
        nameMed: selectedTeam.nameMed,
        attrib_sportType: selectedTeam.attrib_sportType,
        attrib_ageU: selectedTeam.attrib_ageU,
        attrib_season: selectedTeam.attrib_season,
        attrib_ageLevel: selectedTeam.attrib_ageLevel,
        zipCode: selectedTeam.zipCode,
        socialFacebookUrl: selectedTeam.socialFacebookUrl,
        colorTeamPrimary: selectedTeam.colorTeamPrimary,
        teamType: selectedTeam.teamType,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTeam]);

  const onSave = useCallback(async () => {
    setSaving(true);
    const newValue = fetchPayload(value, currentValues);
    try {
      const resp = await axios.put(`/api/team/${selectedTeam?._id}`, {
        teamInfo: newValue,
        isEditPrivacy: false,
      });
      if (resp.status === 200) {
        onFormUpdate(resp.data);
        setSaving(false);
        setHasChanges(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setValidationError(error.response.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedTeam?._id, currentValues]);

  const onReset = useCallback(() => {
    setValue(currentValues);
    setHasChanges(false);
    setValidationError(undefined);
  }, [currentValues]);

  const handleTeamTypeChange = useCallback((teamType?: string) => {
    switch (teamType) {
      case TeamType.SCHOOL:
        setAgeLevelLabel('Level');
        setAgeLevelOptions(schoolAgeLevelOptions);
        break;
      case TeamType.REC:
        setAgeLevelLabel('League');
        setAgeLevelOptions(leagueTypeOptions);
        break;
      default:
        setAgeLevelLabel('Age Bracket');
        break;
    }
  }, []);

  const onChange = useCallback((nextValue: FormValues) => {
    handleTeamTypeChange(nextValue.teamType);
    setHasChanges(true);
    setValue(nextValue);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box pad={'medium'}>
      <Form
        value={value}
        onChange={onChange}
        onReset={onReset}
        onSubmit={() => onSave()}
      >
        <Box pad={{ bottom: 'large' }}>
          <Box
            margin="small"
            direction="row"
            flex
            alignContent="center"
            justify="between"
          >
            <Box>
              <Text size="medium" weight="bold">
                Team Color
              </Text>
            </Box>
            <Box>
              {value?.colorTeamPrimary && (
                <FieldTeamColor
                  currentColor={value?.colorTeamPrimary}
                  label={false}
                />
              )}
            </Box>
          </Box>
          <FormField
            name="nameLong"
            htmlFor="name-long-input"
            label={<Text size="small">Team Name</Text>}
            validate={validateTeamName}
          >
            <TextInput
              id="name-long-input"
              name="nameLong"
              placeholder="E.g. San Jose Rabbits"
              size="medium"
            />
          </FormField>
          <FormField
            name="nameHandle"
            htmlFor="name-handle-input"
            label={
              <Box flex direction="row" justify="between">
                <Text size="small">Team Handle</Text>
                <Text size="small">{value?.nameHandle?.length || 0}/20</Text>
              </Box>
            }
            info={
              <Text size="small">
                This is your team profile link, e.g. sidelinehd.com/yourteam
              </Text>
            }
            validate={validateHandle}
          >
            <TextInput
              id="name-handle-input"
              name="nameHandle"
              placeholder="No Spaces"
              size="medium"
              maxLength={20}
              minLength={0}
            />
          </FormField>
          <FormField
            name="nameMed"
            htmlFor="name-med-input"
            label={
              <Box flex direction="row" justify="between">
                <Text size="small">Scoreboard Name</Text>
                <Text size="small">{value?.nameMed?.length || 0}/20</Text>
              </Box>
            }
            info={
              <Text size="small" onChange={() => {}}>
                This is what will display on the scoreboard when streaming.
              </Text>
            }
            validate={validateScoreBoardName}
          >
            <TextInput
              id="name-med-input"
              name="nameMed"
              placeholder="Scoreboard Display"
              size="medium"
              maxLength={20}
              minLength={0}
            />
          </FormField>
          <FormField
            name="teamType"
            htmlFor="team-type-input"
            label={<Text size="small">Team Type</Text>}
            placeholder="Select Team Type"
          >
            <Select
              name="teamType"
              id="team-type-input"
              options={teamTypeList}
              valueKey={{ key: 'value', reduce: true }}
              labelKey="label"
              size="medium"
            />
          </FormField>
          {value.teamType === TeamType.TRAVEL && (
            <FormField
              name="attrib_ageU"
              htmlFor="attrib-agebracket-input"
              label={<Text size="small">Age Bracket</Text>}
              placeholder="Select Age Bracket"
            >
              <Select
                name="attrib_ageU"
                id="attrib-agebracket-input"
                options={ageUOptions}
                valueKey={{ key: 'value', reduce: true }}
                labelKey="label"
                size="medium"
              />
            </FormField>
          )}
          {value.teamType !== TeamType.TRAVEL && (
            <FormField
              name="attrib_ageLevel"
              htmlFor="attrib-agelevel-input"
              label={<Text size="small">{ageLevelLabel}</Text>}
              placeholder="Select Age Level"
            >
              <Select
                name="attrib_ageLevel"
                id="attrib-agelevel-input"
                options={ageLevelOptions}
                valueKey={{ key: 'value', reduce: true }}
                labelKey="label"
                size="medium"
              />
            </FormField>
          )}
          <FormField
            name="attrib_season"
            htmlFor="attrib-season-input"
            label={<Text size="small">Season</Text>}
            placeholder="Select Season"
          >
            <Select
              name="attrib_season"
              id="attrib-season-input"
              options={seasonOptions}
              valueKey={{ key: 'value', reduce: true }}
              labelKey="label"
              size="medium"
            />
          </FormField>
          <FormField
            name="zipCode"
            htmlFor="zipcode-input"
            label={<Text size="small">Team Zip Code</Text>}
            validate={validatePostalCode}
          >
            <TextInput
              id="zipcode-input"
              name="zipCode"
              placeholder="Zip Code"
              size="medium"
            />
          </FormField>
        </Box>
        <Box
          gap="medium"
          border={{
            side: 'top',
            size: 'xsmall',
            color: '#dddddd',
            style: 'solid',
          }}
          pad={{ top: 'large', bottom: 'large' }}
          alignContent="center"
        >
          <FormField label={<Text size="small">Team Socials</Text>}>
            <Box
              margin="small"
              direction="row"
              flex
              alignContent="center"
              justify="between"
            >
              <Box style={{ marginTop: '.75rem' }}>
                <Text size="large">facebook.com/</Text>
              </Box>
              <Box>
                <TextInput
                  name="socialFacebookUrl"
                  placeholder="FB User"
                  width="small"
                />
              </Box>
            </Box>
            <Box
              direction="row"
              margin="small"
              justify="between"
              flex
              alignContent="center"
            >
              <Box style={{ marginTop: '.75rem' }}>
                <Text size="large">instagram.com/</Text>
              </Box>
              <Box>
                <TextInput
                  name="socialInstagramUrl"
                  placeholder="IG User"
                  width="small"
                />
              </Box>
            </Box>
            <Box
              direction="row"
              margin="small"
              justify="between"
              flex
              alignContent="center"
            >
              <Box style={{ marginTop: '.75rem' }}>
                <Text size="large">x.com/</Text>
              </Box>
              <Box>
                <TextInput
                  name="socialXUrl"
                  placeholder="X User"
                  width="small"
                />
              </Box>
            </Box>
          </FormField>
        </Box>
        <Box direction="row" gap="medium" width="100%" justify="center">
          <Text color="status-error">{validationError}</Text>
        </Box>
        <Box direction="row" gap="medium" width="100%" justify="center">
          <Button
            type="submit"
            primary
            label={saving ? '...Saving' : 'Save'}
            disabled={!hasChanges}
          />
          <Button type="reset" secondary label="Reset" disabled={!hasChanges} />
        </Box>
      </Form>
    </Box>
  );
};

export default TeamInfoForm;
