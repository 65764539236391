// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Heading, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Edit } from 'grommet-icons';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { UserContext } from '../../services/Session/context';
import withLayout from '../../helpers/withLayout';
import PhoneNumberInput from '../../helpers/PhoneNumberInput';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import StatusGoodBox from '../../helpers/StatusGoodBox';
import SHDButton from '../../components/SHD/Button';
import { useIsFeatureEnabled } from '../../helpers/utils';

import SettingsDeleteAccount from './SettingsDeleteAccount';
import SettingsRevenueCat from './SettingsRevenueCat';

const SettingsPage = observer((props) => {
  const userStore = useContext(UserContext);
  const [dbUser, setDbUser] = useState(null);
  const [newDbUser, setNewDbUser] = useState(null);
  const [editPhone, setEditPhone] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [error, setError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [show, setShow] = useState(false);

  const paywallEnabled = useIsFeatureEnabled('monetization', userStore);

  useEffect(() => {
    if (userStore.authUser && userStore.authUser.claims) {
      axios
        .get(`/api/user/${userStore.authUser.claims.shd_user_id}`)
        .then((response) => {
          const respUser = response.data;
          setDbUser(_.cloneDeep(respUser));
          setNewDbUser(_.cloneDeep(respUser));
          toggleLoading(false);
        })
        .catch((respError) => {
          setError('Cannot find user. Please sign out and try again.');
          toggleLoading(false);
          Sentry.captureException(respError);
        });
    } else {
      toggleLoading(false);
      props.history.push('/signin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editUser = () => {
    axios
      .put(`/api/user/${userStore.authUser.claims.shd_user_id}`, {
        newUser: newDbUser,
      })
      .then(() => {
        setShow(true);
        setEditPhone(false);
        setDbUser(_.cloneDeep(newDbUser));
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError.toString());
      });
  };

  const onValidPhone = (value) => {
    if (value) {
      setNewDbUser((prevUser) => {
        const newUser = { ...prevUser };
        newUser.phoneList[0] = value;
        return newUser;
      });
      setPhoneError(null);
    }
  };

  const onErrorPhone = () => {
    setPhoneError(true);
  };

  if (loading) {
    return <BasicLoader fullPage size={200} />;
  }
  const phoneNumberEditing = (
    <>
      {error && <Text color="status-critical">{error}</Text>}
      <Box
        gap="medium"
        direction="row"
        align="center"
        justify="between"
        width={{ max: 'large' }}
      >
        <Text size="large">Phone</Text>
        {!editPhone || !newDbUser.phoneList ? (
          <Box direction="row" gap="small" align="center">
            <Text size="large">
              {formatPhoneNumber(newDbUser.phoneList[0])}
            </Text>
            <SHDButton
              icon={<Edit />}
              onClick={() => setEditPhone(true)}
              mixtrack={['Edit Phone']}
            />
          </Box>
        ) : (
          <Box direction="column">
            <PhoneNumberInput
              value={newDbUser.phoneList[0]}
              id="text-input"
              placeholder="Your number"
              onValid={onValidPhone}
              onError={onErrorPhone}
            />
            {phoneError && (
              <Text color="status-critical">
                Please enter a valid phone number.
              </Text>
            )}
          </Box>
        )}
        <Box direction="row" margin="medium" alignSelf="end">
          <SHDButton
            primary
            size="large"
            onClick={editUser}
            label="Save"
            disabled={
              JSON.stringify(dbUser) === JSON.stringify(newDbUser) || phoneError
            }
          />
        </Box>
      </Box>
      {show && (
        <StatusGoodBox
          label="Phone number saved!"
          onClose={() => setShow(false)}
          position="top"
        />
      )}
    </>
  );

  return (
    <div data-testid="settings">
      <Box margin="medium" height={`75dvh`} direction="column">
        <Heading color="dark-1" level="3">
          Account:&nbsp;
          {userStore.authUser.email}
        </Heading>
        {phoneNumberEditing}
        <Box
          gap="medium"
          direction="row"
          margin={{ vertical: 'auto', horizontal: '0' }}
          width={{ max: 'large' }}
          justify="center"
        >
          {paywallEnabled && <SettingsRevenueCat />}
          <SettingsDeleteAccount
            userId={userStore.authUser.claims.shd_user_id}
          />
        </Box>
      </Box>
    </div>
  );
});

export default compose(withLayout, withRouter)(SettingsPage);
