import React, { useEffect } from 'react';
import { FormClose, StatusGood } from 'grommet-icons';
import { Box, Layer, Text } from 'grommet';
import SHDButton from './SHD/Button';

export interface ToastProps {
  label: string;
  background: string;
  duration?: number;
  onClose?: () => void;
  icon?: React.ReactNode;
}

const Toast: React.FC<ToastProps> = ({
  label,
  background,
  duration,
  onClose,
  icon = <StatusGood />,
  ...rest
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose && onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  return (
    <Layer
      position="bottom"
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      onEsc={onClose}
      responsive={false}
      plain
      {...rest}
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad="medium"
        background={background}
      >
        <Box align="center" direction="row" gap="small">
          {icon}
          <Text>{label}</Text>
        </Box>
        <SHDButton
          icon={<FormClose />}
          onClick={onClose}
          plain
          mixtrack={undefined}
        />
      </Box>
    </Layer>
  );
};

export default Toast;
