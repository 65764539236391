// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { string } from 'yup';
import { Select } from 'grommet';
import { FieldStyled, FieldWrapper } from './style';
import { FieldLabel, FieldLabelWrapper, FieldTip } from '../../style';
import OnboardingButton from '../../../OnboardingButton';
import { schoolAgeLevelOptions } from '../../../../../constants/teamFormOptions';

const validationScheme = string()
  .required('Field is required');

const FieldTeamAgeLevel = ({ value, handleForward }) => {
  const [isValid, setIsValid] = useState(false);

  let selectComponent = <></>;
  if (value.teamType === 'School team') {
    selectComponent = (
      <Select
        id="age-level-id"
        name="attrib_ageLevel"
        placeholder="Select"
        labelKey="label"
        valueKey={{ key: 'value', reduce: true }}
        options={schoolAgeLevelOptions}
      />
    );
  } else if (value.teamType === 'Rec team') {
    selectComponent = (
      <Select
        id="age-level-id"
        name="attrib_ageLevel"
        placeholder="Select"
        options={['Adult league', 'Youth league', 'Other league']}
      />
    );
  }

  useEffect(() => {
    validationScheme.validate(value.teamType).then(() => {
      setIsValid(true);
    }).catch(() => {
      setIsValid(false);
    });
  }, [value]);

  return (
    <>
      <FieldWrapper>
        <FieldLabelWrapper>
          <FieldLabel color="dark-1">What level is your team?</FieldLabel>
          <FieldTip color="dark-4">Required information.</FieldTip>
        </FieldLabelWrapper>
        <FieldStyled name="attrib_ageLevel">
          {selectComponent}
        </FieldStyled>
      </FieldWrapper>
      <OnboardingButton disabled={!isValid} label="Next" onClick={handleForward} />
    </>
  );
};

export default FieldTeamAgeLevel;
