import React, { useEffect } from 'react';
import axios from '../../helpers/axios';
import { AxiosResponse } from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FirebaseComponentProps } from '../../services/Firebase/context';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { withFirebase } from '../../services/Firebase';
import * as ROUTES from '../../constants/routes';
import {
  theFirebase,
} from '../../services/Firebase/firebase';
import { compose } from 'recompose';
import { History } from 'history';
import { dtos } from '@shd/jslib/models';

interface Props {
  history: History;
}

const FirebaseTokenExchangeBase: React.FC<Props> = (props) => {
  const {
    user,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const postUser = async (idToken: IdToken) => {
    const email = idToken?.email ?? '';
    const first_name = idToken?.name?.split(' ')[0] ?? '';
    const last_name = idToken?.name?.split(' ')[1] ?? '';
    const auth_user_id = idToken?.sub;
    const photo_url = idToken?.picture;
    const sign_up = 1;
    await axios.post<dtos.UserPostResponse,AxiosResponse<dtos.UserPostResponse>,dtos.UserPostRequest>(
      '/api/user',
      {
        email,
        first_name,
        last_name,
        auth_user_id,
        photo_url,
        sign_up,
      }
    );
  };

  useEffect(() => {
    const setFirebaseCustomToken = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: 'openid profile email',
        },
      });
      
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth0/token`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      const data = await response.json();
      await theFirebase().doSignInWithCustomToken(data.token);
      const idToken = await getIdTokenClaims();
      if (idToken) {
        await postUser(idToken);
        props.history.push(ROUTES.ONBOARDING);
      }
    };

    if (user) {
      setFirebaseCustomToken();
    }
  }, [user, getIdTokenClaims, getAccessTokenSilently, props.history]);

  return null;
}

const FirebaseTokenExchange = compose<
  Props,
  Omit<Props, keyof RouteComponentProps | keyof FirebaseComponentProps>
>(
  withRouter,
  withFirebase
)(FirebaseTokenExchangeBase);

export default FirebaseTokenExchange;