import React from 'react';
import { Box, Button, Text, Card, Image } from 'grommet';
import { FormUp, FormDown, FormLock } from 'grommet-icons';
import { TaggedPlayer, PrimaryPlayerProps } from './playByPlay.types';

const DKLogo = () => <Image src="/dk_green_logo.png" width="24px" />;

const PanelHeader: React.FC<{
  title: string;
  active: boolean;
  inningHasDkClip: boolean;
}> = ({ title, active, inningHasDkClip = false }) => {
  return (
    <Box
      id="play=by-play-header"
      key={title}
      background="secondary-7"
      direction="row"
      align="center"
      pad="medium"
      gap="small"
      justify="between"
    >
      <Box direction="row" gap="medium" align="center">
        <Text weight="bold">{title}</Text>
        {inningHasDkClip && (
          <Box background="white" pad="4px" round>
            <DKLogo />
          </Box>
        )}
      </Box>
      <Text color="brand">{active ? <FormUp /> : <FormDown />}</Text>
    </Box>
  );
};

const PrimaryPlayer: React.FC<PrimaryPlayerProps> = ({
  outcome,
  taggedPrimaryPlayer,
  handleNavigation,
  handleActiveClip,
  handleUnclaimedPlayer,
  isDkData = false,
}) => {
  return (
    <Box direction="row" justify="between" align="baseline">
      <Box direction="row" gap="medium" align="baseline">
        <Text weight="bold">
          {outcome.charAt(0).toUpperCase() + outcome.slice(1)}
        </Text>
        <Button
          plain
          color="secondary-2"
          label={taggedPrimaryPlayer.primaryPlayerString}
          onClick={() =>
            handleNavigation(`/${taggedPrimaryPlayer.destination}`)
          }
        />
        {isDkData && (
          <Box alignSelf="center">
            <DKLogo />
          </Box>
        )}
      </Box>
      {taggedPrimaryPlayer.isViewable ? (
        <ViewButton
          handleActiveClip={handleActiveClip}
          clipId={taggedPrimaryPlayer.clipId}
          teamPlayerId={taggedPrimaryPlayer.teamPlayerId}
        />
      ) : (
        <LockButton
          handleUnclaimedPlayer={handleUnclaimedPlayer}
          teamPlayerId={taggedPrimaryPlayer.teamPlayerId}
        />
      )}
    </Box>
  );
};

const SecondaryPlayers: React.FC<{
  taggedPlayers: TaggedPlayer[];
  playerRole: string;
  handleNavigation: (destination: string) => void;
}> = ({ taggedPlayers, playerRole, handleNavigation }) => {
  return (
    <Card elevation="none" background="light-3" pad="small">
      <Text size="xsmall">
        {playerRole === 'offense_batter' ? 'Baserunners' : 'Fielders'}
      </Text>
      <Box direction="row" wrap>
        {taggedPlayers.map((p: TaggedPlayer, j: number) => (
          <Button
            key={`player-${j + 1}`}
            plain
            margin={{ right: 'xsmall' }}
            color="secondary-2"
            label={
              <Text size="medium">
                {`#${p.jerseyNum} ${p.playerName}${
                  j < taggedPlayers.length - 2 ? ',' : ''
                }`}
              </Text>
            }
            onClick={() => {
              handleNavigation(`/${p.destination}`);
            }}
          />
        ))}
      </Box>
    </Card>
  );
};

const ViewButton: React.FC<{
  handleActiveClip: (activeClip: string, teamPlayerId: string) => void;
  clipId: string;
  teamPlayerId: string;
}> = ({ handleActiveClip, clipId, teamPlayerId }) => (
  <Button
    plain
    color="secondary-2"
    label="View"
    onClick={() => {
      handleActiveClip(clipId, teamPlayerId);
    }}
  />
);

const LockButton: React.FC<{
  handleUnclaimedPlayer: (playerId: string) => void;
  teamPlayerId: string;
}> = ({ handleUnclaimedPlayer, teamPlayerId }) => (
  <Button
    plain
    color="secondary-2"
    icon={<FormLock />}
    onClick={() => {
      handleUnclaimedPlayer(teamPlayerId);
    }}
  />
);

const NoClips = () => (
  <Box pad="medium">
    <Text>No clips for this half-inning.</Text>
  </Box>
);

export {
  PanelHeader,
  PrimaryPlayer,
  SecondaryPlayers,
  ViewButton,
  LockButton,
  NoClips,
};
