// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Footer, Layer, Header, Button } from 'grommet';
import './scoring.css';
import {
  CaretUpFill,
  CaretDownFill,
  Next,
  Undo,
  Wifi,
  VolumeMute,
  Volume,
} from 'grommet-icons';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import { AiOutlineQrcode } from 'react-icons/ai';
import { toJS } from 'mobx';
import { withFirebase } from '../../services/Firebase';
import PlusMinus from './PlusMinus';
import Prompt from './Prompt';
import BatterSelector from './BatterSelector';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import PitcherSelector from './PitcherSelector';
import OnDeckBatterSelector from './OnDeckBatterSelector';
import { ScoringContext } from './context';
import { BasicLoader } from '../../helpers/Loaders';
import EditLineup from './EditLineup';
import EditInningLayer from './EditInningLayer';
import axios from '../../helpers/axios';
import { getReadableInning, useInterval } from '../../helpers/utils';
import TagPlayersSelector from './TagPlayersSelector';
import LivestreamLinksPage from './LivestreamLinksPage';
import { analytics } from '../../services/analytics';

const footerStyles = {
  width: '100%',
  position: 'relative',
};

const headerStyles = {
  width: '100%',
  position: 'relative',
};

const Container = ({
  setShowExitScoring,
  undoClicked,
  tabIndex,
  setTabIndex,
  setShowQrModal,
  setShowStreamStatsModal,
  toggleMuted,
  ...containerProps
}) => (
  <Box
    id="scoring-container"
    direction="column"
    data-testid="scoring"
    justify="between"
    height={`${window.innerHeight}px`}
    style={{ maxWidth: '600px' }}
    margin="auto"
  >
    <Box fill="vertical">
      <Header background="brand" pad="medium" style={headerStyles}>
        <SHDButton
          secondary
          color="#ffffff"
          label={<Text color="white">Exit scoring</Text>}
          mixtrack={['BBSB Exit Scoring Clicked']}
          onClick={() => setShowExitScoring(true)}
        />
        {containerProps.streamStats && (
          <Box direction="row" align="center" fill="vertical" gap="xsmall">
            <Box
              align="center"
              gap="xsmall"
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              background="white"
              round="small"
              direction="row"
              onClick={() => {
                setShowStreamStatsModal(true);
              }}
            >
              <Wifi
                size="small"
                color={
                  containerProps.streamStats.isHealthy
                    ? 'status-ok'
                    : 'status-critical'
                }
              />
              <Text
                size="small"
                color={
                  containerProps.streamStats.isHealthy
                    ? 'status-ok'
                    : 'status-critical'
                }
              >
                {containerProps.streamStats.isHealthy ? 'Healthy' : 'Unhealthy'}
              </Text>
            </Box>
          </Box>
        )}
        <SHDButton
          plain
          color="white"
          label="Undo"
          icon={<Undo color="white" />}
          onClick={undoClicked}
        />
      </Header>
      {containerProps.children}
    </Box>
    <Footer background="brand" pad="small" style={footerStyles}>
      <Box style={{ position: 'absolute', left: '8px' }}>
        <Button
          icon={
            containerProps.muted ? (
              <VolumeMute size="small" />
            ) : (
              <Volume size="small" />
            )
          }
          label={
            <Text size="small">
              {containerProps.muted ? 'Muted' : 'Unmuted'}
            </Text>
          }
          plain
          onClick={toggleMuted}
        />
      </Box>
      <Box
        gap="medium"
        justify="center"
        align="center"
        direction="row"
        fill="horizontal"
      >
        <Box
          round="8px"
          background={!tabIndex ? 'primary-5' : 'primary-1'}
          align="center"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          onClick={() => setTabIndex(0)}
        >
          <Text textAlign="center">Score</Text>
        </Box>
        <Box
          round="8px"
          background={tabIndex === 1 ? 'primary-5' : 'primary-1'}
          align="center"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          onClick={() => setTabIndex(1)}
        >
          <Text textAlign="center">Lineup</Text>
        </Box>
      </Box>
      <Box
        direction="row"
        align="center"
        fill="vertical"
        gap="xsmall"
        style={{ position: 'absolute', right: '8px' }}
      >
        <Button
          plain
          size="small"
          label={<Text size="small">Share</Text>}
          icon={<AiOutlineQrcode />}
          onClick={() => {
            analytics.track('sidelineSCORE: Share Modal Opened', {
              sport: 'bbsb',
              teamId: containerProps.teamId,
              gameId: containerProps.gameId,
            });
            setShowQrModal(true);
          }}
        />
      </Box>
    </Footer>
  </Box>
);

const Bases = ({ handleEvent, b1, b2, b3 }) => (
  // const isMobile = useContext(ResponsiveContext) === 'small';
  // eslint-disable-next-line implicit-arrow-linebreak
  <Box
    className="base-container"
    // style={isMobile ? { transform: 'scale(1)' } : { transform: 'scale(1.5)' }}
    style={{ transform: 'scale(1)' }}
    margin={{ vertical: 'medium' }}
  >
    <Box
      className="base third"
      pad="xsmall"
      onClick={() => handleEvent('base', { base: 3, isPlus: !b3 })}
      border={{ color: 'accent-0', size: 'xsmall' }}
    >
      {b3 ? <Box background="accent-2" flex /> : null}
    </Box>
    <Box
      className="base second"
      pad="xsmall"
      onClick={() => handleEvent('base', { base: 2, isPlus: !b2 })}
      border={{ color: 'accent-0', size: 'xsmall' }}
    >
      {b2 ? <Box background="accent-2" flex /> : null}
    </Box>
    <Box
      className="base first"
      pad="xsmall"
      onClick={() => handleEvent('base', { base: 1, isPlus: !b1 })}
      border={{ color: 'accent-0', size: 'xsmall' }}
    >
      {b1 ? <Box background="accent-2" flex /> : null}
    </Box>
  </Box>
);

const CurrentBatterCard = observer(({ setShowBatterSelector }) => {
  const scoringStore = useContext(ScoringContext);

  const lineup = scoringStore.lineups[scoringStore.awayHome];
  const index = scoringStore.lineupQueueIndexes[scoringStore.awayHome];

  const atBatPlayer = lineup[index];

  if (lineup.length > index && index >= 0 && atBatPlayer) {
    return (
      <Box
        background="accent-7"
        pad="medium"
        border={{ color: 'accent-0', size: 'xsmall', side: 'top' }}
        justify="between"
        direction="row"
        fill="horizontal"
        align="center"
        onClick={() => {
          setShowBatterSelector(true);
        }}
      >
        <Box direction="row" align="center" gap="medium">
          <Box background="accent-0" pad="small" round="4px">
            <Text color="white" weight="bold">
              At bat
            </Text>
          </Box>
          <Text weight="bold">{atBatPlayer.displayName}</Text>
        </Box>
        <Next color="accent-0" />
      </Box>
    );
  }
  return (
    <Box
      background="accent-7"
      pad="medium"
      border={{ color: 'accent-0', size: 'xsmall', side: 'top' }}
      justify="between"
      direction="row"
      fill="horizontal"
      align="center"
      onClick={() => {
        setShowBatterSelector(true);
      }}
    >
      <Text weight="bold">Choose batter</Text>
      <Next color="accent-0" />
    </Box>
  );
});

const OnDeckBatterCard = observer(({ setShowOnDeckBatterSelector }) => {
  const scoringStore = useContext(ScoringContext);

  const lineupLength = scoringStore.lineups[scoringStore.awayHome].length;
  const lineup = scoringStore.lineups[scoringStore.awayHome];
  const index = scoringStore.lineupQueueIndexes[scoringStore.awayHome];

  if (index + 1 < lineupLength || !scoringStore.isQuickStartMode) {
    return (
      <Box
        background="accent-7"
        pad="medium"
        border={{ color: 'accent-0', size: 'xsmall', side: 'top' }}
        justify="between"
        direction="row"
        fill="horizontal"
        align="center"
        onClick={() => {
          setShowOnDeckBatterSelector(true);
        }}
      >
        <Box direction="row" align="center" gap="medium">
          <Box background="accent-0" pad="small" round="4px">
            <Text color="white" weight="bold">
              On deck
            </Text>
          </Box>
          <Text weight="bold">
            {index + 1 < lineupLength ? (
              <Text>{lineup[index + 1].displayName}</Text>
            ) : (
              <Text>{lineup[0].displayName}</Text>
            )}
          </Text>
        </Box>
        <Next color="accent-0" />
      </Box>
    );
  }
  if (index + 1 === lineupLength) {
    return (
      <Box
        background="accent-7"
        pad="medium"
        border={{ color: 'accent-0', size: 'xsmall', side: 'top' }}
        justify="between"
        direction="row"
        fill="horizontal"
        align="center"
        onClick={() => {
          setShowOnDeckBatterSelector(true);
        }}
      >
        <Text weight="bold">Choose on-deck batter</Text>
        <Next color="accent-0" />
      </Box>
    );
  }
  return null;
});

const CurrentPitcherCard = observer(({ setShowPitcherSelector }) => {
  const scoringStore = useContext(ScoringContext);

  if (scoringStore.pitchers[scoringStore.awayHome]) {
    return (
      <Box
        background="accent-7"
        pad="medium"
        border={{ color: 'accent-0', size: 'xsmall', side: 'top' }}
        justify="between"
        direction="row"
        fill="horizontal"
        align="center"
        onClick={() => {
          setShowPitcherSelector(true);
        }}
      >
        <Box direction="row" align="center" gap="medium">
          <Box background="accent-0" pad="small" round="4px">
            <Text color="white" weight="bold">
              Pitching
            </Text>
          </Box>
          <Text weight="bold">
            {scoringStore.pitchers[scoringStore.awayHome].displayName}
          </Text>
        </Box>
        <Next color="accent-0" />
      </Box>
    );
  }
  return (
    <Box
      background="accent-7"
      pad="medium"
      border={{ color: 'accent-0', size: 'xsmall', side: 'top' }}
      justify="between"
      direction="row"
      fill="horizontal"
      align="center"
      onClick={() => {
        setShowPitcherSelector(true);
      }}
    >
      <Text weight="bold">Choose pitcher</Text>
      <Next color="accent-0" />
    </Box>
  );
});

const ScoringScreenBase = observer((props) => {
  const { firebase, match } = props;
  const userStore = useContext(UserContext);
  const scoringStore = useContext(ScoringContext);
  const [team, setTeam] = useState(
    userStore.selectedTeam || userStore.scoringTeam
  );
  const [showBatterSelector, setShowBatterSelector] = useState(false);
  const [showOnDeckBatterSelector, setShowOnDeckBatterSelector] =
    useState(false);
  const [showPitcherSelector, setShowPitcherSelector] = useState(false);
  const [hidePitcherSelector, setHidePitcherSelector] = useState(false);
  const [showPlayerTagger, setShowPlayerTagger] = useState(false);
  const [showAwayHomePrompt, setShowAwayHomePrompt] = useState(false);
  const [error, setError] = useState();
  const [game, setGame] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [showExitScoring, setShowExitScoring] = useState(false);
  const [showEditInning, setShowEditInning] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [streamStats, setStreamStats] = useState();
  const [muted, setMuted] = useState();
  const [showStreamStatsModal, setShowStreamStatsModal] = useState(false);
  const { gameId: scoreGameId, teamId } = match.params;

  const getStreamHealth = () => {
    if (scoringStore.teamId) {
      axios
        .get(`/api/stream_health/${scoringStore.teamId}`)
        .then(({ data }) => {
          setStreamStats(data);
        })
        .catch(() => {});
    }
  };

  useInterval(getStreamHealth, 30000);

  useEffect(() => {
    if (scoringStore.teamId) {
      getStreamHealth();

      firebase.subscribeToTeam(scoringStore.teamId, (snapshot, err = null) => {
        if (snapshot) {
          setMuted(
            snapshot.muteIsMuted !== undefined ? snapshot.muteIsMuted : false
          );
        } else {
          Sentry.captureMessage(err);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringStore.teamId]);

  const exitScoring = () => {
    const body = {
      // teamId: team._id,
      teamId,
      teamGameId: `GmSh01-${scoreGameId}`,
      teamGameUpdate: {
        scoreGameState: 40,
        scheduleState: 40,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        props.history.replace('/scoring');
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        props.history.replace('/scoring');
      });
  };

  const initGameState = () => {
    const teamGamePromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/scoring_game/GmSh01-${scoreGameId}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });

    const scoreboardPromise = new Promise((resolve, reject) => {
      axios
        .get(`/api/scoreboard/${teamId}/${scoreGameId}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });

    Promise.all([teamGamePromise, scoreboardPromise])
      .then(([teamGame, { scoreboard, lineup }]) => {
        if (
          !scoreboard ||
          (scoreboard.eventHistory == null &&
            scoreboard.eventHistoryCompressed == null)
        ) {
          setShowAwayHomePrompt(true);
        } else {
          // should forceResume be false except when reopening game?
          const forceResume = true;
          scoringStore.initGameState(
            teamGame,
            scoreboard,
            firebase,
            forceResume,
            lineup
          );
        }
        setGame(teamGame);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
      });
  };

  useEffect(() => {
    if (!team) {
      const scoreTeamId = localStorage.getItem('scoreTeamId');
      const scoreDelegationKey = localStorage.getItem('scoreDelegationKey');
      userStore
        .setScoringTeam(scoreTeamId, scoreDelegationKey)
        .then((newTeam) => {
          setTeam(newTeam);
        })
        .catch((respError) => {
          if (
            respError &&
            respError.response &&
            respError.response.status === 403
          ) {
            setError('You are not authorized to score for this team.');
          } else {
            setError('Something went wrong. Please refresh and try again.');
            Sentry.captureException(respError);
          }
        });
    }

    return () => {
      if (scoringStore) {
        scoringStore.unsubscribeFromGame();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      initGameState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const setAwayHome = (awayHome) => {
    const newGame = { ...game };
    newGame.scoreWeAreHome = !!awayHome;
    const body = {
      teamId: game.scoreTeamIdOurs,
      teamGameId: `GmSh01-${game.scoreGameId}`,
      teamGameUpdate: {
        scoreWeAreHome: !!awayHome,
      },
    };
    axios
      .put('/api/team_game', body)
      .then(() => {
        scoringStore.initGameState(newGame, null, firebase);
        setShowAwayHomePrompt(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        setShowAwayHomePrompt(false);
      });
  };

  const closePrompt = () => {
    scoringStore.setPrompt(null);
  };

  if (showAwayHomePrompt) {
    return (
      <Layer>
        <Box align="center" justify="center" fill gap="medium" pad="medium">
          <Text textAlign="center" size="large">
            Are you the AWAY or HOME team?
          </Text>
          <Box direction="row" gap="medium">
            <SHDButton
              size="large"
              primary
              label="AWAY"
              onClick={() => setAwayHome(0)}
              color="tertiary-1"
            />
            <SHDButton
              size="large"
              primary
              label="HOME"
              onClick={() => setAwayHome(1)}
              color="tertiary-1"
            />
          </Box>
        </Box>
      </Layer>
    );
  }

  const undoClicked = () => {
    const actionEvents = scoringStore.eventHistory.filter(
      (event) => !['startGame', 'forceRun', 'sub'].includes(event.eventType)
    );

    if (actionEvents.length === 0) {
      axios
        .delete(`/api/scoreboard/${teamId}/${scoreGameId}`)
        .then(() => {
          setShowAwayHomePrompt(true);
        })
        .catch(() => setError('Error undoing event'));
    } else {
      scoringStore.handleEvent('undo', {});
    }
  };

  if (error) {
    return (
      <Text color="status-critical" size="large" textAlign="center">
        {error}
      </Text>
    );
  }

  const loader = <BasicLoader fullPage />;

  const renderScoringScreen = () => {
    if (scoringStore.isInitialized) {
      return (
        <Container
          id="scoring-container"
          setShowExitScoring={setShowExitScoring}
          undoClicked={undoClicked}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          setShowQrModal={setShowQrModal}
          streamStats={streamStats}
          setShowStreamStatsModal={setShowStreamStatsModal}
          toggleMuted={() => {
            setMuted(!muted);
            axios.put(`api/team/${scoringStore.teamId}/mute`, {
              muteIsMuted: !muted,
            });
          }}
          muted={muted}
          teamId={scoringStore.teamId}
          gameId={scoringStore.gameId}
        >
          <Box direction="column" fill="vertical">
            <Box>
              <Box direction="row" justify="between" align="center" gap="none">
                <Box
                  direction="row"
                  justify="center"
                  align="center"
                  pad="medium"
                  flex="shrink"
                  onClick={() => setShowEditInning(true)}
                >
                  {!!getReadableInning(scoringStore.scoreboard.i).topBottom && (
                    <CaretDownFill color="#000000" />
                  )}
                  {!getReadableInning(scoringStore.scoreboard.i).topBottom && (
                    <CaretUpFill color="#000000" />
                  )}
                  <Text size="xxlarge" weight="bold">
                    {getReadableInning(scoringStore.scoreboard.i).inning}
                  </Text>
                </Box>
                <Box direction="column" flex>
                  <Box
                    direction="row"
                    border={{ color: 'secondary-7', size: 'xsmall' }}
                    justify="between"
                  >
                    <Box
                      justify="center"
                      pad={{ horizontal: 'medium' }}
                      background="secondary-7"
                      flex
                    >
                      <Text size="large" weight="bold">
                        {scoringStore.gameInfo.teamANameMed}
                      </Text>
                    </Box>
                    <PlusMinus
                      display={scoringStore.gameInfo.teamANameMed}
                      handleEvent={scoringStore.handleEvent}
                      outcomeType="away"
                      currentNum={scoringStore.scoreboard.a}
                    />
                  </Box>
                  <Box
                    direction="row"
                    border={{ color: 'secondary-7', size: 'xsmall' }}
                    justify="between"
                  >
                    <Box
                      justify="center"
                      pad={{ horizontal: 'medium' }}
                      background="secondary-7"
                      flex
                    >
                      <Text size="large" weight="bold">
                        {scoringStore.gameInfo.teamHNameMed}
                      </Text>
                    </Box>
                    <PlusMinus
                      display={scoringStore.gameInfo.teamHNameMed}
                      handleEvent={scoringStore.handleEvent}
                      outcomeType="home"
                      currentNum={scoringStore.scoreboard.h}
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="column">
                <Box gap="small" margin={{ bottom: 'small' }}>
                  {scoringStore.scoreboard.i % 2 === scoringStore.awayHome ? (
                    <Box>
                      <OnDeckBatterCard
                        setShowOnDeckBatterSelector={
                          setShowOnDeckBatterSelector
                        }
                      />
                      <CurrentBatterCard
                        setShowBatterSelector={setShowBatterSelector}
                      />
                      <Box
                        background="accent-0"
                        onClick={() =>
                          scoringStore.handleEvent('nextBatter', {
                            isOffense: true,
                          })
                        }
                        pad="medium"
                        fill="horizontal"
                        justify="center"
                        align="center"
                      >
                        <Text color="white" weight="bold">
                          Next batter
                        </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <CurrentPitcherCard
                        setShowPitcherSelector={setShowPitcherSelector}
                      />
                      <Box
                        background="accent-0"
                        onClick={() =>
                          scoringStore.handleEvent('nextBatter', {
                            isOffense: false,
                          })
                        }
                        pad="medium"
                        fill="horizontal"
                        justify="center"
                        align="center"
                      >
                        <Text color="white" weight="bold">
                          Next batter
                        </Text>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box justify="center">
                <Bases
                  handleEvent={scoringStore.handleEvent}
                  b1={scoringStore.scoreboard.b1}
                  b2={scoringStore.scoreboard.b2}
                  b3={scoringStore.scoreboard.b3}
                />
              </Box>
            </Box>
            <Box>
              <Box direction="column">
                <Box
                  direction="row"
                  border={{
                    color: 'secondary-7',
                    size: 'xsmall',
                    side: 'horizontal',
                  }}
                >
                  <Box
                    basis="1/3"
                    justify="center"
                    pad={{ horizontal: 'medium' }}
                  >
                    <Text size="xlarge" weight="bold">
                      Balls
                    </Text>
                  </Box>
                  <PlusMinus
                    display="Balls"
                    handleEvent={scoringStore.handleEvent}
                    outcomeType="ball"
                    currentNum={scoringStore.scoreboard.b}
                  />
                  {scoringStore.scoreboard.i % 2 === scoringStore.awayHome && (
                    <Box
                      onClick={() =>
                        scoringStore.handleEvent('runnerOutcome', {
                          isOffense: true,
                        })
                      }
                      background="secondary-6"
                      align="center"
                      justify="center"
                      flex
                    >
                      <Text
                        size="small"
                        weight="bold"
                        style={{ textAlign: 'center' }}
                      >
                        Steal base
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box direction="row">
                  <Box
                    basis="1/3"
                    justify="center"
                    pad={{ horizontal: 'medium' }}
                  >
                    <Text size="xlarge" weight="bold">
                      Strikes
                    </Text>
                  </Box>
                  <PlusMinus
                    display="Strikes"
                    handleEvent={scoringStore.handleEvent}
                    outcomeType="strike"
                    currentNum={scoringStore.scoreboard.s}
                  />
                  <Box
                    onClick={() =>
                      scoringStore.handleEvent('foul', { isPlus: true })
                    }
                    background="secondary-6"
                    align="center"
                    justify="center"
                    flex
                  >
                    <Text size="small" weight="bold">
                      Foul
                    </Text>
                  </Box>
                </Box>
                <Box
                  direction="row"
                  border={{
                    color: 'secondary-7',
                    size: 'xsmall',
                    side: 'horizontal',
                  }}
                >
                  <Box
                    basis="1/3"
                    justify="center"
                    pad={{ horizontal: 'medium' }}
                  >
                    <Text size="xlarge" weight="bold">
                      Outs
                    </Text>
                  </Box>
                  <PlusMinus
                    display="Outs"
                    handleEvent={scoringStore.handleEvent}
                    outcomeType="out"
                    currentNum={scoringStore.scoreboard.o}
                  />
                  {!(
                    scoringStore.scoreboard.i % 2 ===
                    scoringStore.awayHome
                  ) && (
                    <Box
                      onClick={() =>
                        scoringStore.handleEvent('runnerOutcome', {
                          isOffense: false,
                        })
                      }
                      background="secondary-6"
                      align="center"
                      justify="center"
                      flex
                    >
                      <Text size="small" weight="bold">
                        Runner out
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
              {showExitScoring && (
                <Layer responsive={false}>
                  <Box pad="large" justify="center" gap="medium">
                    <Box fill="horizontal" align="end">
                      <Text
                        color="tertiary-1"
                        weight="bold"
                        onClick={() => setShowExitScoring(false)}
                      >
                        Cancel
                      </Text>
                    </Box>
                    <Box align="center" gap="medium">
                      <Text size="large" weight="bold">
                        Do you want to PAUSE OR END this game?
                      </Text>
                      <Box direction="row" align="center" gap="small">
                        <SHDButton
                          primary
                          size="large"
                          label="END"
                          onClick={() => {
                            setShowExitScoring(false);
                            scoringStore.handleEvent('endGame', {});
                          }}
                          disabled={!scoringStore.isInitialized}
                          color="tertiary-1"
                        />
                        <SHDButton
                          primary
                          size="large"
                          label="PAUSE"
                          onClick={() => {
                            setShowExitScoring(false);
                            exitScoring();
                          }}
                          disabled={!scoringStore.isInitialized}
                          color="tertiary-1"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Layer>
              )}
              {showEditInning && (
                <EditInningLayer onClose={() => setShowEditInning(false)} />
              )}
              {scoringStore.prompt && (
                <Prompt
                  firebase={firebase}
                  text={scoringStore.prompt.text}
                  subtext={scoringStore.prompt.subtext}
                  type={scoringStore.prompt.type}
                  onResponse={scoringStore.prompt.resolve}
                  lastBatterDisplayName={
                    scoringStore.prompt.lastBatterDisplayName
                  }
                  closePrompt={closePrompt}
                  showTagPlayersPrompt={
                    scoringStore.prompt.type === 'runnerOutcome' &&
                    scoringStore.scoreboard.i % 2 === scoringStore.awayHome
                      ? 'stolenBase'
                      : false
                  }
                />
              )}
              {showBatterSelector && (
                <BatterSelector
                  onClose={() => {
                    setShowBatterSelector(false);
                  }}
                />
              )}
              {scoringStore.lineups[scoringStore.awayHome].length <=
                scoringStore.lineupQueueIndexes[scoringStore.awayHome] &&
                scoringStore.scoreboard.i % 2 === scoringStore.awayHome && (
                  <BatterSelector
                    onClose={() => {
                      setShowBatterSelector(false);
                    }}
                    mandatory
                  />
                )}
              {showOnDeckBatterSelector && (
                <OnDeckBatterSelector
                  onClose={() => {
                    setShowOnDeckBatterSelector(false);
                  }}
                />
              )}
              {(showPitcherSelector ||
                (!scoringStore.pitchers[scoringStore.awayHome] &&
                  scoringStore.scoreboard.i % 2 !== scoringStore.awayHome &&
                  !hidePitcherSelector)) && (
                <PitcherSelector
                  onClose={() => {
                    setShowPitcherSelector(false);
                    setHidePitcherSelector(true);
                  }}
                />
              )}
              {showPlayerTagger && (
                <TagPlayersSelector
                  onClose={() => {
                    setShowPlayerTagger(false);
                  }}
                />
              )}
            </Box>
          </Box>
        </Container>
      );
    }
    return loader;
  };

  const renderEditLineups = () => (
    <Container
      setShowExitScoring={setShowExitScoring}
      undoClicked={undoClicked}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      setShowQrModal={setShowQrModal}
      streamStats={streamStats}
    >
      <EditLineup isFromMainScreen />
      <Box pad="medium">
        <Button
          primary
          color="status-critical"
          label="Hard Refresh"
          onClick={async () => {
            const scoringStoreCopy = toJS(scoringStore);
            const payload = {
              firestore: {
                ...scoringStoreCopy.firebase.firestore,
              },
              awayHome: scoringStoreCopy.awayHome,
              gameId: scoringStoreCopy.gameId,
              gameInfo: {
                ...scoringStoreCopy.gameInfo,
              },
              isInitialized: scoringStoreCopy.isInitialized,
              isQuickStartMode: scoringStoreCopy.isQuickStartMode,
            };
            axios.post('api/logging/slack/log-stuck-bug', payload);
            await scoringStore.hardRefresh();

            window.location.reload();
          }}
        />
      </Box>
    </Container>
  );

  return (
    <>
      {tabIndex ? renderEditLineups() : renderScoringScreen()}
      {showQrModal && (
        <LivestreamLinksPage
          sport="bbsb"
          teamId={scoringStore.teamId}
          gameId={scoringStore.gameId}
          matchupTitle={scoringStore.gameInfo.matchupTitle}
          onClose={() => {
            setShowQrModal(false);
            analytics.track('sidelineSCORE: Share Modal Closed', {
              sport: 'bbsb',
              teamId: scoringStore.teamId,
              gameId: scoringStore.gameId,
            });
          }}
        />
      )}
      {showStreamStatsModal && (
        <Layer
          responsive={false}
          onClickOutside={() => setShowStreamStatsModal(false)}
          onEsc={() => setShowStreamStatsModal(false)}
        >
          <Box gap="small" pad="medium">
            <Text weight="bold">Stream health</Text>
            <Text color="status-critical">
              {!streamStats.isHealthy && streamStats.inAppRecommendText}
            </Text>
            <Text>{streamStats.streamHealthDescription}</Text>
            <br />
            <Text>(Info updates every 30s)</Text>
          </Box>
        </Layer>
      )}
    </>
  );
});

const ScoringScreen = compose(withRouter, withFirebase)(ScoringScreenBase);

export default ScoringScreen;
