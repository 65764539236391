// Packages
import React, { useState } from 'react';

// Components
import { FormField, TextInput } from 'grommet';
import AdminPlayerCardList from './AdminPlayerCardList';

// Helpers and utils
import { useMergePlayers } from '../admin.hooks';
import { useDebouncedCallback } from '../../../helpers/useDebounce';

// Types
import { AdminSearchProps, ClaimedPlayerProps } from '../admin.types';

const AdminPlayerSearch: React.FC<AdminSearchProps<ClaimedPlayerProps>> = ({
  id,
  isCardSelected,
  label,
  onCardSelected,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncing, setDebouncing] = useState(false);

  const {
    clearPlayers,
    searchPlayers,
    searchPlayersLoading,
    searchPlayersResults,
  } = useMergePlayers();

  const search = useDebouncedCallback(
    (text) => {
      isCardSelected(false);
      setDebouncing(false);
      if (text) {
        searchPlayers(text);
      } else {
        clearPlayers();
      }
    },
    800,
    { leading: false, trailing: true }
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setDebouncing(true);
    search(e.target.value);
  };

  const handleOnCardSelected = (
    isSelected: boolean,
    payload: ClaimedPlayerProps
  ) => {
    isCardSelected(isSelected);
    onCardSelected && onCardSelected(payload);
  };

  return (
    <>
      <FormField id={id} justify="between" label={label}>
        <TextInput
          onChange={onChange}
          placeholder={placeholder}
          type="search"
          value={searchValue}
        />
      </FormField>
      <AdminPlayerCardList
        debouncing={debouncing}
        loading={searchPlayersLoading}
        searchResults={searchPlayersResults}
        onCardSelected={(isSelected, payload) => {
          handleOnCardSelected(isSelected, payload);
        }}
      />
    </>
  );
};

export default AdminPlayerSearch;
