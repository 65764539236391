// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from 'styled-components';

import {
  Box, RadioButtonGroup, Text, FormField,
} from 'grommet';

export const FieldStyled = styled(FormField)`
  width: 100%;
  & > div {
    border-bottom: none;
  }
`;

export const RadioGroupStyled = styled(RadioButtonGroup)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 58px;
  & label {
    flex-basis: 50%;
  }
`;

export const OptionStyled = styled(Box)`
  box-sizing: border-box;
  width: 164px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 13px 14px;
  border:  1px solid ${({ theme }) => theme.global.colors['primary-1']};
  background-color: ${({ theme, checked }) => {
    const color = checked ? 'primary-1' : 'primary-8';
    return theme.global.colors[color];
  }};
  margin-bottom: 12px;
`;

export const OptionLabel = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme, checked }) => (checked ? '#fff' : theme.global.colors['primary-1'])};
`;

export const OptionIcon = styled(Text)`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  color: ${({ theme, checked }) => (checked ? '#fff' : theme.global.colors['primary-1'])};
  
  & svg {
    fill: ${({ theme, checked }) => (checked ? '#fff' : theme.global.colors['primary-1'])};
    width: 100%;
    height: 100%;
  }
`;
