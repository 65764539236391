// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { PlayersHeading } from './styles';
import RecentPlayers from './RecentPlayers';
import SearchResult from './SearchResult';

export default function PlayerList({ searchResults, searching, searchError }) {
  const isShowRecentPlayers = !searching && !searchResults.length;
  const heading = isShowRecentPlayers ? 'Recently viewed' : 'Search results';

  return (
    <>
      <PlayersHeading color="dark-1">{heading}</PlayersHeading>
      {
        isShowRecentPlayers
          ? <RecentPlayers />
          : <SearchResult searching={searching} results={searchResults} error={searchError} />
      }
    </>
  );
}
