import React from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0Login: React.FC = () => {
  const {
    loginWithRedirect,
  } = useAuth0();

  loginWithRedirect();

  return null;
}

export default withRouter(Auth0Login);