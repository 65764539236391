// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Box } from 'grommet';
import Toast from './Toast';

// TODO: need to actually reimplement Toast because you can't stack the Layers
const ToastStack = ({ toasts, removeToast }) => (
  <Box>
    {toasts.map((toast) => (
      <Toast
        key={toast.id}
        label={toast.label}
        background={toast.background}
        duration={toast.duration}
        onClose={() => removeToast(toast.id)}
        icon={toast.icon}
        full="horizontal"
      />
    ))}
  </Box>
);

export default ToastStack;
