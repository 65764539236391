import React, { useEffect, useState } from 'react';
import { Box, Heading, Image, Text } from 'grommet';
import { CircleAlert } from 'grommet-icons';
import { FadeLoader } from 'react-spinners';
import { statusScreenLogo } from './sidelineLive.styles';
import {
  CameraStatus,
  EndStatus,
  Props,
  SidelineLiveStatusProps,
  SidelineLiveStatusScreenProps,
} from './sidelineLive.types';
import { useStyleHooks } from './liveStyleHooks';

export const SidelineLiveStatusScreen: React.FC<Props> = (content) => {
  const { body, mode, title } = content;
  const { statusScreenDisplay } = useStyleHooks();

  return (
    <Box align="center" justify="center" style={statusScreenDisplay}>
      {(mode === CameraStatus.Live || mode === 'empty') && (
        <Image
          src="/sidelinehd_dk_logo.png"
          style={{ ...statusScreenLogo, position: 'relative' }}
        />
      )}

      {(mode === CameraStatus.Wait || mode === 'empty') && (
        <FadeLoader color="#ffffff" />
      )}

      {mode === CameraStatus.Down && <CircleAlert size="4rem" color="white" />}

      {mode === 'empty' && <Text color="white"></Text>}

      <Heading
        level="2"
        size="1.375rem"
        textAlign="center"
        margin={{
          bottom: 'small',
          top: 'small',
        }}
        color="white"
        weight="900"
        style={{
          padding: '0 1rem',
          verticalAlign: 'center',
        }}
      >
        {title}
      </Heading>
      <Text
        size="0.875rem"
        textAlign="center"
        color="white"
        style={{
          maxWidth: '80%',
          padding: '0 1rem',
          verticalAlign: 'center',
        }}
      >
        {body}
      </Text>
    </Box>
  );
};

const SidelineLiveStatus: React.FC<SidelineLiveStatusProps> = ({
  isPlayable,
  camera_status,
  isGameEnded,
}) => {
  const [content, setContent] = useState<SidelineLiveStatusScreenProps>({
    body: 'Just a moment while we get the livestream ready',
    mode: CameraStatus.Live,
    title: 'Welcome to sidelineLIVE',
  });
  useEffect(() => {
    if (!isPlayable && camera_status === CameraStatus.Live) {
      setContent({
        body: 'Just a moment while we get the livestream ready',
        mode: CameraStatus.Live,
        title: 'Welcome to sidelineLIVE',
      });
    }

    if (!isPlayable && camera_status === CameraStatus.Wait) {
      setContent({
        body: 'Camera connection is unstable - attempting to reconnect',
        mode: CameraStatus.Wait,
        title: 'Camera disconnected',
      });
    }

    if (isPlayable && camera_status === CameraStatus.Wait) {
      setContent({
        body: '',
        mode: CameraStatus.Wait,
        title: 'Livestream is buffering',
      });
    }

    if (!isPlayable && camera_status === CameraStatus.Down) {
      if (!isGameEnded) {
        setContent({
          body: 'The stream has ended due to camera connection.',
          mode: CameraStatus.Down,
          title: 'Livestream has ended',
        });
      } else {
        setContent({
          mode: EndStatus.Ended,
          title: 'Livestream has ended',
        });
      }
    }
  }, [isPlayable, camera_status, isGameEnded]);

  return <SidelineLiveStatusScreen {...content} />;
};
export default SidelineLiveStatus;
