// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */

import React, { useContext, useState } from 'react';
import { Box, Heading, Text } from 'grommet';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import SHDButton from '../../components/SHD/Button';
import DragAndDropLineup from './DragAndDropLineup';
import { UserContext } from '../../services/Session';
import axios from '../../helpers/axios';

const SetStarters = ({
  starters = [],
  goBack = () => {},
  toggleIsLineupSet = () => {},
  match,
  sportType,
}) => {
  const userStore = useContext(UserContext);
  const { gameId } = match.params;
  const team = userStore.selectedTeam || userStore.scoringTeam;
  const [startersOrder, setStartersOrder] = useState(starters);
  const [error, setError] = useState(false);
  const [loading, toggleLoading] = useState(false);

  let loadingText;
  if (sportType === 'vb') {
    loadingText = 'Set starters';
  } else {
    loadingText = 'Set batting order';
  }

  const updateOrder = (order, isQuickStartMode = false) => {
    let lineupKey;
    if (sportType === 'vb') {
      lineupKey = 'scoreOurLineup';
    } else {
      lineupKey = 'scoreOurLineup';
    }

    const body = {
      teamId: team._id,
      teamGameId: gameId,
      teamGameUpdate: {
        [lineupKey]: order,
        scoreGameState: 20,
        scheduleState: 20,
        isQuickStartMode,
      },
    };

    axios
      .put('/api/team_game', body)
      .then(() => {
        toggleIsLineupSet(true);
        toggleLoading(false);
      })
      .catch((respError) => {
        setError('Something went wrong. Please refresh and try again.');
        Sentry.captureException(respError);
        toggleLoading(false);
      });
  };

  return (
    <Box pad="medium">
      <Box fill="horizontal" align="end">
        <Text onClick={goBack} weight="bold" color="tertiary-1">
          Back
        </Text>
      </Box>
      <Heading level={3}>{loadingText}</Heading>
      {error ? <Text color="status-critical">{error}</Text> : null}
      <DragAndDropLineup lineup={startersOrder} setLineup={setStartersOrder} />
      <Box direction="row" align="center" justify="center" fill="horizontal">
        <SHDButton
          primary
          size="large"
          label={!loading ? loadingText : '...Setting'}
          onClick={() => updateOrder(startersOrder)}
          margin={{ top: 'large' }}
          color="tertiary-1"
          disabled={loading}
        />
      </Box>
    </Box>
  );
};

export default compose(withRouter)(SetStarters);
