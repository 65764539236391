// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import styled from 'styled-components';

import { isiOS, isMobile } from '../../helpers/browserDetect';
import StreamPlayer from './StreamPlayer';
import GreetingScreen from './Greeting';
import LoggerProvider from './useLogger/LoggerProvider';
import { containerMaxWidth } from '../../constants/stylesConfig';

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  height: 100vh;
`;

function SidelineCam() {
  const [isInit, setIsInit] = useState(false);

  if (isMobile.any() && !isInit && !isiOS()) {
    return <GreetingScreen onInit={setIsInit} />;
  }

  return (
    <LoggerProvider>
      <Container>
        <StreamPlayer />
      </Container>
    </LoggerProvider>
  );
}

export default SidelineCam;
