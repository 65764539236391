import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '../../helpers/utils';
import { Box, ResponsiveContext, Text } from 'grommet';
import { UserContext } from '../../services/Session';
import {
  useIsEntitledToViewPlayer,
  usePinnedClips,
  usePlayerGameSummaries,
  usePlayerPrimaryInfo,
  useVideoDimensions,
} from './playerHooks';
import PlayerCardV2 from './PlayerCardV2';
import { shd } from '@shd/jslib/models';
import PlayerSportToggle from './PlayerSportToggle';
import {
  BASEBALL,
  COMBINED_BBSB_KEY,
  SOFTBALL,
  VOLLEYBALL,
} from '../../constants/strings';
import PlayerClipsSection from './PlayerClipsSection';
import { ClipFeedClip, PlayerPageProps } from './player.types';
import axios from '../../helpers/axios';
import * as Sentry from '@sentry/browser';
import GameSummaryCarousel from './GameSummaryCarousel';
import ClaimWall from './ClaimWall';
import ClaimPlayerLayer from './ClaimPlayerLayer';
import { useToast } from '../../contexts/ToastContext';
import { HiSparkles } from 'react-icons/hi';
import { UserType, analytics } from '../../services/analytics';
import { isMobile } from '../../helpers/browserDetect';
import { toJS } from 'mobx';
import PlayerRecommendationsCard from './PlayerRecommendationsCard';
import withLayout from '../../helpers/withLayout';
import PlayerClaimPrompt from './PlayerClaimPrompt';
import PlayerHitScope from './PlayerHitScope';
import PaywallLock from '../../components/Paywall/PaywallLock';
import PaywallCta from '../../components/Paywall/PaywallCta';
import { usePermitClaimPlayer } from '../../hooks/usePlayerClaims';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../components/Paywall/paywall.types';

const PlayerPage: React.FC<PlayerPageProps> = ({
  claimed,
  playerId,
  history,
}) => {
  const query = useQuery();
  const openPlayerSettings = Boolean(query?.get('open_settings') || 0);
  const [localPlayerId, setLocalPlayerId] = useState<string>(playerId);
  const [isClaimed, setIsClaimed] = useState<boolean>(claimed);

  const screen = useContext(ResponsiveContext);
  const userStore = useContext(UserContext);
  const { showToast } = useToast();
  const videoDimensions = useVideoDimensions();
  const {
    playerInfo: playerPrimaryInfo,
    loading: playerInfoLoading,
    error: playerInfoError,
  } = usePlayerPrimaryInfo(localPlayerId);

  const userTeamIds = userStore.userTeams
    ? userStore.userTeams.map((team) => team._id)
    : [];

  const userPlayerIds = userStore.userPlayers
    ? userStore.userPlayers.map((p) => p._id)
    : [];

  const loggedInUserId = userStore.authUser?.claims?.shd_user_id;
  const userCanClaim = usePermitClaimPlayer(
    playerId,
    playerPrimaryInfo?.teams?.[0]?._id,
    loggedInUserId
  );

  const isAdmin = userPlayerIds.includes(playerId);

  const [selectedSport, setSelectedSport] = useState(
    query.get('sport') || COMBINED_BBSB_KEY
  );

  const {
    status: viewerEntitlementStatus,
    loading: isEntitledToViewLoading,
    error: isEntitledToViewError,
  } = useIsEntitledToViewPlayer(
    playerId,
    userStore.authUser?.claims?.shd_user_id,
    selectedSport
  );

  const { pinnedClips: initialPinnedClips, loading: pinnedClipsLoading } =
    usePinnedClips(
      viewerEntitlementStatus === ViewerEntitlementStatus.ENTITLED
        ? localPlayerId
        : null,
      isClaimed
    );
  const [localPinnedClips, setLocalPinnedClips] = useState<ClipFeedClip[]>([]);

  const isFollower =
    playerPrimaryInfo?.teams &&
    playerPrimaryInfo.teams.length &&
    userStore.teamFollowerIds
      .concat(userTeamIds)
      .filter((teamId) =>
        playerPrimaryInfo.teams
          .map((playerTeam) => playerTeam._id)
          .includes(teamId)
      ).length;

  const {
    gameSummaries,
    error: gameSummariesError,
    loading: gameSummariesLoading,
  } = usePlayerGameSummaries(playerId, selectedSport);

  const [localPlayer, setLocalPlayer] = useState<shd.Player | null>(null);
  const [teams, setTeams] = useState<shd.Team[] | null>(null);

  const openClaimModal = Boolean(query?.get('open_claim_modal') || 0);
  const fromClaimWall = Boolean(query?.get('from_claim_wall') || 0);
  const [showClaimWall, setShowClaimWall] = useState(false);
  const [showClaim, setShowClaim] = useState(openClaimModal || fromClaimWall);

  useEffect(() => {
    const determineUserType = () => {
      let userType;
      if (!userStore.authUser) {
        userType = UserType.LoggedOut;
      } else if (isAdmin) {
        userType = UserType.Admin;
      } else if (isFollower) {
        userType = UserType.CommunityMember;
      } else {
        userType = UserType.LoggedIn;
      }
      return userType;
    };

    if (localPlayer) {
      analytics.track('Player Page View', {
        playerId: localPlayer._id,
        sport: selectedSport,
        hasRecommendation: displayLessonsForYou(),
        userType: determineUserType(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPlayer, selectedSport]);

  useEffect(() => {
    if (playerPrimaryInfo?.player && playerPrimaryInfo.teams) {
      setLocalPlayer(playerPrimaryInfo.player);
      setTeams(playerPrimaryInfo.teams);

      // set selected sport based on what teams the player is on
      const sports = playerPrimaryInfo.teams.map(
        (team) => team.attrib_sportType
      );
      const isBBSB =
        sports.includes(BASEBALL.display) || sports.includes(SOFTBALL.display);
      if (playerPrimaryInfo.teams.length) {
        if (selectedSport === COMBINED_BBSB_KEY && !isBBSB) {
          const nextSport = playerPrimaryInfo.teams[0].attrib_sportType
            .toLowerCase()
            .replace(' ', '_');
          if (nextSport === VOLLEYBALL.key) {
            setSelectedSport('vb');
          } else {
            setSelectedSport(nextSport);
          }
        }
      }
    } else if (playerPrimaryInfo?.claimedPlayerId) {
      setLocalPlayerId(playerPrimaryInfo.claimedPlayerId);
      setIsClaimed(true);
      history.replace({
        pathname: playerPrimaryInfo.claimedPlayerHandle,
        search: location.search,
        hash: location.hash,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPrimaryInfo]);

  useEffect(() => {
    if (!pinnedClipsLoading) {
      setLocalPinnedClips(initialPinnedClips);
    }
  }, [initialPinnedClips, pinnedClipsLoading]);

  const displayLessonsForYou = useCallback(() => {
    // are we on an iOS device?
    if (!isMobile.iOS()) {
      return false;
    }

    // do we have a player
    if (!playerPrimaryInfo?.player) {
      return false;
    }
    // check if current User has this player in userPlayersList
    const userPlayer = toJS(
      userStore.userPlayers.find((p) => p._id === playerId)
    );
    const isUserManager = userPlayer !== undefined;

    // does this person manage the user?
    if (!isUserManager) {
      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPrimaryInfo]);

  const pinClip = (clip: ClipFeedClip) => {
    const body = {
      claimedPlayerId: playerId,
      refPreview: clip.refPreview,
      id: clip.id,
    };
    axios
      .post(`/api/clip/${clip.id}/pin`, body)
      .then((response) => {
        if (response.data.sentToSportsRecruits) {
          showToast({
            icon: <HiSparkles />,
            message: 'Added to your SportsRecruits profile',
          });
        }
      })
      .catch((respErr) => {
        Sentry.captureException(respErr);
      });
    const newClip = { ...clip };
    const regex = /vs (.*)/;
    const matchArr = newClip.textDescription?.match(regex) || [];
    let opponentName = '';
    if (matchArr.length >= 1) {
      [, opponentName] = matchArr;
    }
    newClip.opponentName = opponentName;
    const newPinnedClips = localPinnedClips.concat(newClip);
    const sortedNewPinnedClips = newPinnedClips.sort(
      (c1, c2) => c2.clipStartTs - c1.clipStartTs
    );
    setLocalPinnedClips(sortedNewPinnedClips);

    showToast({
      icon: <HiSparkles />,
      message: 'Added to your highlights',
    });
  };

  const unpinClip = (clip: ClipFeedClip) => {
    const body = {
      playerId,
      refPreview: clip.refPreview,
      id: clip.id,
    };
    axios
      .delete(`/api/clip/${clip.id}/pin`, { data: body })
      .catch((respErr) => {
        Sentry.captureException(respErr);
      });

    // TODO: pinnedClips may need to ids hashed
    const newPinnedClips = localPinnedClips.filter((c) => c.id !== clip.id);
    setLocalPinnedClips(newPinnedClips);

    showToast({
      icon: <HiSparkles />,
      message: 'Removed from your highlights',
    });
  };

  return (
    <Box
      fill="vertical"
      margin="auto"
      width={screen === 'small' ? '100%' : `${videoDimensions.width}px`}
    >
      {isClaimed && (
        <PlayerSportToggle
          teams={teams || []}
          selectedSport={selectedSport}
          onSportChange={(sport) => {
            const newParams = new URLSearchParams();
            newParams.set('sport', sport);

            history.push({
              search: newParams.toString(),
            });

            setSelectedSport(sport);
          }}
        />
      )}
      {!isClaimed && userCanClaim && (
        <PlayerClaimPrompt setShowClaim={setShowClaim} />
      )}
      <Box pad="medium" skeleton={playerInfoLoading}>
        <PlayerCardV2
          player={localPlayer}
          teams={teams}
          isLoading={playerInfoLoading}
          claimed={isClaimed}
          isAdmin={isAdmin}
          openPlayerSettings={openPlayerSettings}
          setShowSRCTAModal={() => {}}
          setShowSRInfoModal={() => {}}
          selectedSport="bbsb"
        />
        {playerInfoError && <Text>Error loading player info</Text>}
      </Box>
      <Box skeleton={playerInfoLoading || isEntitledToViewLoading}>
        {viewerEntitlementStatus === ViewerEntitlementStatus.ENTITLED && (
          <Box>
            <Box skeleton={gameSummariesLoading}>
              {!gameSummariesError && (
                <GameSummaryCarousel gameSummaries={gameSummaries} />
              )}
            </Box>
            {(isAdmin ||
              isFollower ||
              localPlayer?.publicPublishLevel === 10) &&
              selectedSport === COMBINED_BBSB_KEY && (
                <PlayerHitScope
                  playerId={playerId}
                  claimed={claimed}
                  history={history}
                  setShowClaimWall={setShowClaimWall}
                />
              )}
            {localPlayer && displayLessonsForYou() && (
              <PlayerRecommendationsCard
                player={
                  localPlayer as {
                    nameFirst: string;
                    _id: string;
                    yearGraduation: number;
                  }
                }
                selectedSport={selectedSport}
              />
            )}
            {teams && teams.length > 0 && (
              <PlayerClipsSection
                teams={teams}
                selectedSport={selectedSport}
                playerId={playerId}
                player={localPlayer}
                claimed={isClaimed}
                isAdmin={isAdmin}
                pinClip={pinClip}
                unpinClip={unpinClip}
                pinnedClips={localPinnedClips}
                showReelsTab={true}
                onShowClaim={() => {
                  setShowClaim(true);
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <Box pad="medium" skeleton={playerInfoLoading || isEntitledToViewLoading}>
        {viewerEntitlementStatus ===
          ViewerEntitlementStatus.ENTITLED_BUT_PRIVATE && (
          <PaywallLock isPrivate={true} />
        )}

        {viewerEntitlementStatus ===
          ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN &&
          claimed && (
            <PaywallCta
              mode="default"
              context={PaywallContext.PLAYER_PAGE}
              playerId={playerId}
            />
          )}

        {viewerEntitlementStatus ===
          ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN &&
          !claimed && <PaywallLock isPrivate={false} />}

        {viewerEntitlementStatus ===
          ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT && (
          <PaywallLock isPrivate={false} />
        )}

        {isEntitledToViewError}
        {isEntitledToViewError && <Box>Error loading subscription status</Box>}
      </Box>
      {showClaimWall && (
        <ClaimWall
          {...{
            teamPlayer: localPlayer,
            claimReason: `Claim ${localPlayer?.nameFirst} to see their content`,
            onShowClaim: () => {
              setShowClaimWall(false);
              setShowClaim(true);
            },
            onClose: () => {
              setShowClaimWall(false);
            },
          }}
        />
      )}
      {showClaim &&
        playerPrimaryInfo?.teams &&
        playerPrimaryInfo.teams.length > 0 && (
          <ClaimPlayerLayer
            {...{
              team: playerPrimaryInfo.teams[0],
              teamPlayerId: playerId,
              closeLayer: () => setShowClaim(false),
            }}
          />
        )}
    </Box>
  );
};

export default withLayout(withRouter(PlayerPage));
