// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Box, Heading,
} from 'grommet';
import { useLocation } from 'react-router-dom';
import axios from '../../helpers/axios';

const TeamSnapCallback = () => {
  const location = useLocation();

  const [error, setError] = useState(null);

  useEffect(() => {
    const getAccessToken = () => {
      const query = new URLSearchParams(location.hash.substr(1));
      const accessToken = query.get('access_token');
      return accessToken;
    };

    const postAccessToken = async (accessToken) => {
      const exampleUrl = '/api/teamsnap/access_token';
      axios.post(exampleUrl, { access_token: accessToken }).then(() => {
        setTimeout(() => {
          window.close();
        }, 500);
      }).catch(() => {
        setError('TeamSnap login failed.');
      });
    };

    const accessToken = getAccessToken();
    if (accessToken) {
      postAccessToken(accessToken);
    }
  }, [location]);

  return (
    <Box
      justify="center"
      width="large"
      margin="0 auto"
      align="center"
      pad="medium"
      gap="large"
    >
      <Box pad="small" gap="small">
        <Heading level="3">Redirecting you back to sign up...</Heading>
        {error && (
        <Heading level="3">
          Error:
          {' '}
          {error}
          {' '}
          Please try again
        </Heading>
        )}
      </Box>
    </Box>
  );
};

export default TeamSnapCallback;
