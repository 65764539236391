// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-lonely-if */
/* eslint-disable no-underscore-dangle */

import { Box, Card, Text } from 'grommet';
import { Tag } from 'grommet-icons';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import HighlightVideoPlayer from '../../helpers/HighlightVideoPlayer';

const GameSummaryCard = withRouter(({ gameSummary, ...props }) => {
  if (!gameSummary) {
    return null;
  }

  return (
    <Card
      id="inner"
      style={{ display: 'inline-block' }}
      width={{ min: '240px', max: '240px' }}
      round="small"
      margin="small"
    >
      <Box height="147px">
        <HighlightVideoPlayer
          id={gameSummary._id}
          hvid={gameSummary}
          category={gameSummary.category}
          videoSrc={gameSummary.fileUrl720}
          autoplay={false}
          location="player_page"
        />
      </Box>
      <Box
        pad="small"
        onClick={() => {
          if (gameSummary.category === 'game') {
            props.history.push(`/game/${gameSummary.gameIdList[0]}`);
          } else if (gameSummary.category === 'player') {
            // hitmatch
            props.history.push(`/hitmatch/${gameSummary._id}`);
          }
        }}
      >
        <Text weight="bold" size="medium" truncate>
          {gameSummary.titleMain}
        </Text>
      </Box>
    </Card>
  );
});

const GameSummaryCarousel = ({ gameSummaries }) => {
  useEffect(() => {}, []);
  return (
    <Box margin="medium">
      {gameSummaries && gameSummaries.length > 0 ? (
        <Box>
          <Box
            direction="row"
            align="center"
            gap="small"
            margin={{ left: 'small' }}
          >
            <Tag />
            <Text weight="bold">Tagged Game Highlights</Text>
          </Box>
          <Box
            style={{
              whiteSpace: 'nowrap',
            }}
            overflow={{ horizontal: 'scroll', vertical: 'hidden' }}
            direction="row"
          >
            {gameSummaries.map((gameSummary, i) => (
              <GameSummaryCard
                key={`game-summary-${i}`}
                gameSummary={gameSummary}
              />
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default GameSummaryCarousel;
