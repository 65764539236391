// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect } from 'react';

import { Box, CheckBox, Grommet, Text } from 'grommet';
import { GenericScoringContext } from '../context';
import ScoringButton from '../ScoringButton';

const WaterPolo = ({ gameEventTypes, disabled }) => {
  const scoringStore = useContext(GenericScoringContext);
  useEffect(() => {}, []);

  const gameState = scoringStore.scoreboard.gameState || {};
  const isPowerPlay = 'power_play' in gameState;
  const isHomePowerPlay = isPowerPlay && gameState.power_play.teamNAH === 2;
  const isAwayPowerPlay = isPowerPlay && gameState.power_play.teamNAH === 1;

  return (
    <Box
      margin={{ horizontal: 'small', top: 'medium' }}
      flex={{ grow: 0, shrink: 1 }}
      style={{
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? '0.25' : '1',
      }}
    >
      <Box
        direction="row"
        gap="large"
        pad={{ vertical: 'small' }}
        align="center"
      >
        <Text weight="bold" size="large">
          My Team
        </Text>
        <Grommet
          theme={{
            checkBox: {
              color: '#066d99',
            },
          }}
        >
          <CheckBox
            checked={scoringStore.awayHome ? isHomePowerPlay : isAwayPowerPlay}
            label={
              <Box alignSelf="center">
                <Text size="small">Power Play</Text>
              </Box>
            }
            onChange={(e) => {
              if (e.target.checked) {
                const eventInfo = {
                  name: 'power_play',
                  teamNAH: scoringStore.awayHome ? 2 : 1,
                  enable: true,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              } else {
                const eventInfo = {
                  name: 'power_play',
                  enable: false,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              }
            }}
          />
        </Grommet>
      </Box>
      <Box background="light-3" pad="small" gap="8px" round="8px" fill>
        <Box direction="row" gap="small">
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.water_polo_goal.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'water_polo_goal',
                gameEventTypes.water_polo_goal
              );
            }}
          />
          <ScoringButton
            basis="1/2"
            label={gameEventTypes.water_polo_missed_shot.uiName}
            onClick={() => {
              scoringStore.handleEvent(
                'water_polo_missed_shot',
                gameEventTypes.water_polo_missed_shot
              );
            }}
          />
        </Box>
        <ScoringButton
          basis="1/1"
          label={gameEventTypes.water_polo_save.uiName}
          onClick={() => {
            scoringStore.handleEvent(
              'water_polo_save',
              gameEventTypes.water_polo_save
            );
          }}
        />
      </Box>
      <Box
        margin={{ top: 'medium' }}
        direction="row"
        gap="large"
        pad={{ vertical: 'small' }}
        align="center"
      >
        <Text weight="bold" size="large">
          Opponent
        </Text>
        <Grommet
          theme={{
            checkBox: {
              color: '#066d99',
            },
          }}
        >
          <CheckBox
            checked={scoringStore.awayHome ? isAwayPowerPlay : isHomePowerPlay}
            label={
              <Box alignSelf="center">
                <Text size="small">Power Play</Text>
              </Box>
            }
            onChange={(e) => {
              if (e.target.checked) {
                const eventInfo = {
                  name: 'power_play',
                  teamNAH: scoringStore.awayHome ? 1 : 2,
                  enable: true,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              } else {
                const eventInfo = {
                  name: 'power_play',
                  enable: false,
                };
                scoringStore.handleEvent('changeGameState', eventInfo);
              }
            }}
          />
        </Grommet>
      </Box>
      <Box background="light-3" pad="small" gap="8px" round="8px" fill>
        <ScoringButton
          basis="1/1"
          label={gameEventTypes.water_polo_opponent_goal.uiName}
          onClick={() => {
            scoringStore.handleEvent(
              'water_polo_opponent_goal',
              gameEventTypes.water_polo_opponent_goal
            );
          }}
          isOpponent
        />
      </Box>
    </Box>
  );
};

export default WaterPolo;
