import React from 'react';
import { DropButton, Text, Box } from 'grommet';
import { AddCircle } from 'grommet-icons';

interface AddPlayerProps {
  addPlayerOpen: boolean;
  setAddPlayerOpen: (open: boolean) => void;
  setShowCreatePlayerModal: (open: boolean) => void;
  setShowRolloverPlayerModal: (open: boolean) => void;
  numberOfTeams: number;
}

const AddPlayer: React.FC<AddPlayerProps> = ({
  addPlayerOpen,
  setAddPlayerOpen,
  setShowCreatePlayerModal,
  setShowRolloverPlayerModal,
  numberOfTeams,
}) => {
  return (
    <DropButton
      open={addPlayerOpen}
      onClick={() => setAddPlayerOpen(!addPlayerOpen)}
      plain
      icon={<AddCircle color="tertiary-1" />}
      label={
        <Text weight={'bold'} color={'tertiary-1'}>
          Add Player
        </Text>
      }
      dropContent={
        <Box>
          <Box
            pad={{ horizontal: 'small', vertical: 'medium' }}
            onClick={() => {
              setShowCreatePlayerModal(true);
              setAddPlayerOpen(false);
            }}
          >
            <Text weight={'bold'}>Create New Player</Text>
          </Box>
          {numberOfTeams >= 2 && (
            <Box
              pad={{ horizontal: 'small', vertical: 'medium' }}
              onClick={() => {
                setShowRolloverPlayerModal(true);
                setAddPlayerOpen(false);
              }}
            >
              <Text weight={'bold'}>Rollover Player</Text>
            </Box>
          )}
        </Box>
      }
      dropProps={{
        align: { top: 'bottom' },
      }}
    />
  );
};
export default AddPlayer;
