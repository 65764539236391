// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from 'react';
import { Box, Text } from 'grommet';
import { CaretDownFill, CaretUpFill } from 'grommet-icons';
import SHDButton from '../../components/SHD/Button';

const RadarEvents = ({ eventList }) => {
  const [show, setShow] = useState(false);

  return (
    <Box>
      <Box direction="column">
        {show ? (
          <>
            <Box
              direction="row"
              gap="small"
              align="center"
              justify="between"
              fill="horizontal"
            >
              <Text size="small" weight="bold">
                Hide recent IN GAME radar velocities
              </Text>
              <SHDButton
                icon={<CaretUpFill color="black" />}
                hoverIndicator
                onClick={() => setShow(false)}
              />
            </Box>
            {eventList.map((event, i) => (
              <Box key={`radar-event-${i}`} gap="medium" direction="row">
                <Text>{event.velocityTxt}</Text>
                <Text>{`${event.ageTxt} ago`}</Text>
                {event.veloDoc.tsRaw ? (
                  <Text>{`(latency ${
                    Math.round(
                      (event.veloDoc.commCaptureTs - event.veloDoc.tsRaw) * 10
                    ) / 10
                  }s)`}</Text>
                ) : (
                  <Text>{`(latency ${
                    Math.round(
                      (event.veloDoc.commCaptureTs - event.veloDoc.ts) * 10
                    ) / 10
                  }s)`}</Text>
                )}
              </Box>
            ))}
            {eventList.length === 0 && (
              <Text>No recent IN GAME radar velocities</Text>
            )}
          </>
        ) : (
          <Box
            direction="row"
            align="center"
            gap="small"
            justify="between"
            fill="horizontal"
          >
            <Text size="small" weight="bold">
              Show recent IN GAME radar velocities
            </Text>
            <SHDButton
              icon={<CaretDownFill color="black" />}
              hoverIndicator
              onClick={() => setShow(true)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RadarEvents;
