import React from 'react';
import { Layer, Card, CardHeader, Button, Text } from 'grommet';

interface MessageModalPayload {
  message: string;
  title: string;
}
interface GenericMessageModalProps {
  payload: MessageModalPayload;
  closeModal: (arg: MessageModalPayload | undefined) => void;
  multilinePayload?: MessageModalPayload[];
}

const GenericMessageModal: React.FC<GenericMessageModalProps> = ({
  payload,
  closeModal,
  multilinePayload,
}) => {
  return (
    <Layer
      modal
      responsive={true}
      full="vertical"
      background={'transparent'}
      onEsc={() => closeModal(undefined)}
    >
      <Card
        background="white"
        style={{
          margin: '1rem',
          padding: '1rem',
          verticalAlign: 'middle',
          alignContent: 'center',
        }}
      >
        <CardHeader
          alignContent="end"
          justify="between"
          margin={{ bottom: '1rem' }}
        >
          <Text weight="bolder" size="medium" color="status-critical">
            {payload && payload.title}
            {multilinePayload &&
              multilinePayload.length > 1 &&
              multilinePayload[0].title}
          </Text>
        </CardHeader>
        {payload?.message && <Text>{payload.message}</Text>}
        {multilinePayload &&
          multilinePayload.map((msg, index) => (
            <Text key={index} margin={{ vertical: '.5rem' }}>
              {msg.message}
            </Text>
          ))}
        <Button
          margin={{ top: '1rem' }}
          primary
          fill="horizontal"
          label="OK"
          onClick={() => closeModal(undefined)}
        ></Button>
      </Card>
    </Layer>
  );
};

export default GenericMessageModal;
