// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import withLayout from '../helpers/withLayout';

const IFrameEmbed = ({ src }) => (
  <div>
    <iframe
      src={src}
      title="file"
      width="100%"
      height="1000"
    />
  </div>
);

export default withLayout(IFrameEmbed);
