import React, { useEffect, useState } from 'react';
import { Box, Card, Image, Text } from 'grommet';
import { FormNextLink } from 'grommet-icons';
import SHDLink from '../../helpers/SHDLink';
import {
  onMixpanelActivity,
  MixpanelActivityEvents,
} from '../DK/dkplayerRecommendations.mixpanel';
import axios from '../../helpers/axios';
import { AxiosResponse } from 'axios';
import { Weekly } from '../DK/dkplayerRecommentdations.types';

interface PlayerLessonsCardProps {
  selectedSport: string;
  player: {
    nameFirst: string;
    _id: string;
    yearGraduation: number;
  };
}

const PlayerRecommendationsCard: React.FC<PlayerLessonsCardProps> = ({
  player,
  selectedSport,
}) => {
  const [payload, setPayload] = useState<Weekly>();
  useEffect(() => {
    axios
      .get(`/api/claimed_player/${player._id}/activity_recommendations`)
      .then((response: AxiosResponse) => {
        if (response.data.errorList.length <= 0) {
          setPayload(response.data);
        } else {
          setPayload(undefined);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = () => {
    onMixpanelActivity(MixpanelActivityEvents.LESSONS_FOR_YOU, {
      sport: selectedSport,
      claimedPlayerId: player._id,
      gradYear: player.yearGraduation,
    });
  };
  // for testing : CpSh01-ale-MRNn4pBstB1LxYEa
  return (
    <>
      {payload && payload.isShowCta ? (
        <SHDLink
          to={`/player/${player._id}/recommendations`}
          onClick={handleOnClick}
        >
          <Box pad="medium">
            <Card
              fill="horizontal"
              background={'#1A1A1A'}
              style={{ position: 'relative' }}
              pad={{ horizontal: '16px', top: '22px', bottom: '16px' }}
            >
              <Box direction="row" gap="24px" style={{ zIndex: 1 }}>
                <Box gap="4px">
                  <Text weight="bold">Lessons For You</Text>
                  <Text size="14px" margin={{ bottom: '12px' }}>
                    {payload?.ctaText}
                  </Text>
                </Box>
                <Box flex={{ shrink: 0 }} alignSelf="end">
                  <Box
                    height={'40px'}
                    width={'40px'}
                    background={'white'}
                    round="full"
                    align="center"
                    justify="center"
                  >
                    <FormNextLink size="20px" color="black" />
                  </Box>
                </Box>
              </Box>
              <Image
                style={{ position: 'absolute', top: 0, left: '50%', zIndex: 0 }}
                src="/dk_app_icon_bg.png"
                width={'50%'}
              />
            </Card>
          </Box>
        </SHDLink>
      ) : (
        <> </>
      )}
    </>
  );
};

export default PlayerRecommendationsCard;
