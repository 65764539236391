import React from 'react';
import { Box, Text } from 'grommet';
import { Location } from 'grommet-icons';
import { getTeamAgeType } from '../../helpers/utils';
import { shd } from '@shd/jslib/models';

interface TeamInfoProps {
  team: shd.Team;
}

const TeamInfo: React.FC<TeamInfoProps> = ({ team }) => {
  return (
    <Box key="team-info" gap="xsmall">
      <Text size="xlarge" weight={'bold'}>
        {team.nameLong}
      </Text>

      <Text size="medium">
        {`${getTeamAgeType(team.attrib_ageU, team.attrib_ageLevel)} ${team.attrib_sportType} Team`}
      </Text>

      <Box direction="row" gap="small" align="center">
        {team.nameLocation && <Location size="small" color="black" />}
        <Text size="small" weight={'bold'}>
          {team.nameLocation}
        </Text>
      </Box>
    </Box>
  );
};

export default TeamInfo;
