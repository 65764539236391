// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Image,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'grommet';
import { Close } from 'grommet-icons';
import Toast from '../../components/Toast';
import CopiableText from '../../helpers/CopiableText';

const MevoSetup = ({ name, rtmp, onClose = () => {} }) => {
  const [copied, setCopied] = useState(false);
  const [step, setStep] = useState(0);

  const stepOne = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text>
        Do these steps on the iOS / Android device controlling your Mevo(s).
      </Text>
      <Text>Power on your Mevo before continuing.</Text>
      <Text />
      <Text />
      <Box width="small" alignSelf="center">
        <Image fit="contain" src="/mevo_apps_to_shd.png" />
      </Box>
    </Box>
  );

  const stepTwo = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Button
        primary
        size="large"
        label="Logitech Mevo app"
        onClick={() => {
          setStep(2);
        }}
      />
      <Button
        primary
        size="large"
        label="Logitech Mevo Multicam app"
        onClick={() => {
          setStep(5);
        }}
      />
      <Button
        secondary
        size="large"
        label="Manually enter RTMP (advanced)"
        onClick={() => {
          setStep(4);
        }}
      />
    </Box>
  );

  const mevoSingle1 = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text>
        If you are on your mobile device, add sidelineHD as a streaming
        destination.
      </Text>
      <Box width="medium" gap="xsmall">
        <Button
          primary
          size="large"
          label="Pair with Mevo app"
          onClick={() => {
            window.open(rtmp.mevoAppUrl, '_blank');
          }}
        />
        <Text size="small" color="dark-6">
          (You may get many prompts – approve with YES / INSTALL / SAVE / LAUNCH
          / DOWNLOAD / etc.)
        </Text>
      </Box>
      <Box margin={{ top: 'large' }} gap="medium">
        <Text>
          Alternately, copy the URL. Transfer it to your mobile device by text
          or email.
        </Text>
        <Box width="large">
          <CopiableText
            label={<Text weight="bold">Setup URL</Text>}
            value={rtmp.mevoAppUrl}
            onCopy={() => setCopied(true)}
          />
        </Box>
      </Box>
    </Box>
  );

  const mevoSingle2 = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text weight="bold">Click the red RECORD button.</Text>
      <Box width="medium" alignSelf="center">
        <Image fit="contain" src="/mevo_redbutton.png" width="75" height="75" />
      </Box>
      <Text />
      <Text />
      <Text weight="bold">Start a RTMP stream to sidelineHD:</Text>
      <ol>
        <li>
          <Text>Select RTMP. (Do not select Facebook or YouTube.)</Text>
        </li>
        <li>
          <Text>Select your sidelineHD camera.</Text>
        </li>
        <li>
          <Text>Set resolution to 720p or lower.</Text>
        </li>
        <li>
          <Text>Press GO LIVE.</Text>
        </li>
      </ol>
      <Box width="medium" alignSelf="center">
        <Image fit="contain" src="/mevo_app_go_live2.png" />
      </Box>
    </Box>
  );

  const manualRtmp = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text>Enter sidelineHD as a streaming destination.</Text>
      <Box gap="medium" width={{ max: 'large' }}>
        <CopiableText
          label={<Text weight="bold">Name</Text>}
          value={name}
          onCopy={() => setCopied(true)}
        />
        <CopiableText
          label={<Text weight="bold">Stream URL</Text>}
          value={rtmp.streamUrl}
          onCopy={() => setCopied(true)}
        />
        <CopiableText
          label={<Text weight="bold">Stream Key</Text>}
          value={rtmp.rtmpKey}
          onCopy={() => setCopied(true)}
        />
      </Box>
      <Box gap="medium">
        <Text>
          {`Enter the above information into the "Name", "Stream URL", and
        "Stream Key" fields`}
        </Text>
        <Box width="medium" alignSelf="center">
          <Image fit="contain" src="/mevo_rtmpinfo.png" />
        </Box>
      </Box>
    </Box>
  );

  const mevoMulti1 = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text>
        If you are on your mobile device, add sidelineHD as a streaming
        destination.
      </Text>
      <Box width="medium" gap="small">
        <Button
          primary
          size="large"
          label="Pair with Mevo Multicam app"
          onClick={() => {
            window.open(rtmp.mevoMulticamUrl, '_blank');
          }}
        />
        <Text size="small" color="dark-6">
          (You may get many prompts – approve with YES / INSTALL / SAVE / LAUNCH
          / DOWNLOAD / etc.)
        </Text>
      </Box>
      <Box margin={{ top: 'large' }} gap="medium">
        <Text>
          Alternately, copy the URL. Transfer it to your mobile device by text
          or email.
        </Text>
        <Box width="large">
          <CopiableText
            label={<Text weight="bold">Setup URL</Text>}
            value={rtmp.mevoMulticamUrl}
            onCopy={() => setCopied(true)}
          />
        </Box>
      </Box>
    </Box>
  );

  const mevoMulti2 = (
    <Box pad="medium" gap="medium" overflow="auto" fill>
      <Text weight="bold">Click on LIVE.</Text>
      <Box height={{ min: '80px' }}>
        <Image fit="contain" src="/mevo_multicam_go_live_2a.jpg" />
      </Box>
      <Text weight="bold">
        Swipe left on the destination list until you see RTMP. (Do not select
        Facebook or YouTube.)
      </Text>
      <Box height={{ min: '120px' }}>
        <Image fit="contain" src="/mevo_multicam_go_live_2b.png" />
      </Box>
      <Text />
      <Text />
      <Text weight="bold">Configure your RTMP stream.</Text>
      <ol>
        <li>
          <Text>In Choose Destination, click on RTMP.</Text>
        </li>
        <li>
          <Text>Select your sidelineHD camera.</Text>
        </li>
        <li>
          <Text>Set resolution to 720p or lower.</Text>
        </li>
        <li>
          <Text>Press GO LIVE.</Text>
        </li>
      </ol>
      <Box fill="horizontal" height={{ min: '400px' }}>
        <Image fit="contain" src="/mevo_multicam_go_live_2c.png" />
      </Box>
    </Box>
  );

  const renderStep = () => {
    let title = '';
    let buttonText = '';
    let buttonAction = () => {};
    if (step === 0) {
      title = 'Pair your Mevo camera';
      buttonText = 'Next';
      buttonAction = () => {
        setStep(1);
      };
    } else if (step === 1) {
      title = 'Pair with Mevo app';
    } else if (step === 2) {
      title = 'Pair with Mevo app (1/2)';
      buttonText = 'I finished this step';
      buttonAction = () => {
        setStep(3);
      };
    } else if (step === 3) {
      title = 'Pair with Mevo app (2/2)';
      buttonText = 'Done';
      buttonAction = () => {
        onClose();
      };
    } else if (step === 4) {
      title = 'Manually enter RTMP';
      buttonText = 'Done';
      buttonAction = () => {
        onClose();
      };
    } else if (step === 5) {
      title = 'Pair with Mevo Multicam app (1/2)';
      buttonText = 'I finished this step';
      buttonAction = () => {
        setStep(6);
      };
    } else if (step === 6) {
      title = 'Pair with Mevo Multicam app (2/2)';
      buttonText = 'Done';
      buttonAction = () => {
        onClose();
      };
    }

    return (
      <Card round={false} elevation="none" fill>
        <CardHeader
          direction="row"
          pad="small"
          align="center"
          justify="between"
        >
          <Box width="24px" />
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              {title}
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} justify="end" />
        </CardHeader>
        <CardBody fill>
          {step === 0 && stepOne}
          {step === 1 && stepTwo}

          {/* Mevo one-click setup */}
          {step === 2 && mevoSingle1}
          {step === 3 && mevoSingle2}

          {/* manual RTMP setup */}
          {step === 4 && manualRtmp}

          {/* Mevo Multicam one-click setup */}
          {step === 5 && mevoMulti1}
          {step === 6 && mevoMulti2}
        </CardBody>
        {buttonText && (
          <CardFooter
            pad={{ horizontal: 'small', vertical: 'large' }}
            background="light-2"
            margin={{ bottom: '64px' }}
          >
            <Button
              fill
              primary
              size="large"
              label={buttonText}
              onClick={buttonAction}
            />
          </CardFooter>
        )}
      </Card>
    );
  };

  return (
    <Box fill>
      {renderStep()}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
    </Box>
  );
};

export default MevoSetup;
